import { find_to_use_NPR } from '@/store/localstorage/preferences';
import { nepaliNumberFormatter, numberDecimalFormatter } from '@/utils/numberFormatter';

export const tableExportColumns = [
  {
    label: 'Product',
    dataIndex: 'product_name',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Location',
    dataIndex: 'location_name',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Unit',
    dataIndex: 'unit_name',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Base Unit Quantity',
    dataIndex: 'base_unit_quantity',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Quantity',
    dataIndex: 'quantity',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Unit Purchase Price',
    dataIndex: 'unit_price',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Lot',
    dataIndex: 'lot_id',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Grade',
    dataIndex: 'grade',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Total Amount',
    dataIndex: 'total_amount',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      base_unit_quantity: numberDecimalFormatter(record.base_unit_quantity),
      quantity: numberDecimalFormatter(record.quantity),
      unit_price: nepaliNumberFormatter(record.unit_price),
      lot_id: numberDecimalFormatter(record.lot_id),
      total_amount: nepaliNumberFormatter(record.total_amount)
    };
  }) as any;
}
