import moment from 'moment';
import {
  get_account_journal_lines_credit_debit_sum,
  get_account_history_credit_debit_sum,
  get_trial_balance_list
} from '../../../services/accounts/queries';
import {
  IAccountHistoryCreditDebitSum,
  IAccountTypeResponseData
} from '../../../services/accounts/types';

export const addTransactionToTrialBalance = async (
  locationId: number | string,
  startDate: string,
  endDate: string,
  currentBalanceSortBy: string,
  parentIds?: number[],
  skip?: number,
  count?: number
) => {
  const response = await get_trial_balance_list(
    locationId,
    startDate,
    endDate,
    currentBalanceSortBy,
    parentIds,
    skip,
    count
  );
  const accountIds = response.results.map((acc) => acc.id);
  const today = moment().startOf('d');
  const isEndDateToday = moment(endDate).isSameOrAfter(today, 'day');
  let historySum: IAccountHistoryCreditDebitSum[] = [];
  let linesSum: IAccountHistoryCreditDebitSum[] = [];
  if (accountIds.length > 0) {
    if (isEndDateToday) {
      historySum = (
        await get_account_history_credit_debit_sum({
          startDate: moment(startDate).utc().format(),
          endDate: today.utc().format(),
          ids: accountIds
        })
      ).results;
      linesSum = (
        await get_account_journal_lines_credit_debit_sum({
          startDate: today.utc().format(),
          endDate: today.endOf('d').utc().format(),
          ids: accountIds
        })
      ).results;
    } else {
      historySum = (
        await get_account_history_credit_debit_sum({
          startDate,
          endDate,
          ids: accountIds
        })
      ).results;
    }
  }
  const results = response.results.map((acc) => {
    const transaction = {
      accountId: acc.id,
      totalDebit: 0,
      totalCredit: 0
    };
    const history = historySum.find((sum) => sum.accountId === transaction.accountId);
    if (history) {
      if (history.totalCredit)
        transaction.totalCredit +=
          typeof history.totalCredit === 'string'
            ? parseFloat(history.totalCredit)
            : history.totalCredit;
      if (history.totalDebit)
        transaction.totalDebit +=
          typeof history.totalDebit === 'string'
            ? parseFloat(history.totalDebit)
            : history.totalDebit;
    }

    const line = linesSum.find((sum) => sum.accountId === transaction.accountId);
    if (line) {
      if (line.totalCredit)
        transaction.totalCredit +=
          typeof line.totalCredit === 'string' ? parseFloat(line.totalCredit) : line.totalCredit;
      if (line.totalDebit)
        transaction.totalDebit +=
          typeof line.totalDebit === 'string' ? parseFloat(line.totalDebit) : line.totalDebit;
    }
    return {
      ...acc,
      transaction
    };
  });
  return { results, total: response.total };
};

export const addTransactionForExportAll = async (
  response: IAccountTypeResponseData[],
  startDate: string,
  endDate: string,
  ids: number[] = []
) => {
  const today = moment().startOf('d');
  const isEndDateToday = moment(endDate).isSameOrAfter(today, 'day');
  let historySum: IAccountHistoryCreditDebitSum[] = [];
  let linesSum: IAccountHistoryCreditDebitSum[] = [];
  if (ids.length > 0) {
    if (isEndDateToday) {
      historySum = (
        await get_account_history_credit_debit_sum({
          startDate: moment(startDate).utc().format(),
          endDate: today.utc().format(),
          ids: ids
        })
      ).results;
      linesSum = (
        await get_account_journal_lines_credit_debit_sum({
          startDate: today.utc().format(),
          endDate: today.endOf('d').utc().format(),
          ids: ids
        })
      ).results;
    } else {
      historySum = (
        await get_account_history_credit_debit_sum({
          startDate,
          endDate,
          ids: ids
        })
      ).results;
    }
  }

  const results = response.map((acc) => {
    const transaction = {
      accountId: acc.id,
      totalDebit: 0,
      totalCredit: 0
    };
    const history = historySum.find((sum) => sum.accountId === transaction.accountId);
    if (history) {
      if (history.totalCredit)
        transaction.totalCredit +=
          typeof history.totalCredit === 'string'
            ? parseFloat(history.totalCredit)
            : history.totalCredit;
      if (history.totalDebit)
        transaction.totalDebit +=
          typeof history.totalDebit === 'string'
            ? parseFloat(history.totalDebit)
            : history.totalDebit;
    }

    const line = linesSum.find((sum) => sum.accountId === transaction.accountId);
    if (line) {
      if (line.totalCredit)
        transaction.totalCredit +=
          typeof line.totalCredit === 'string' ? parseFloat(line.totalCredit) : line.totalCredit;
      if (line.totalDebit)
        transaction.totalDebit +=
          typeof line.totalDebit === 'string' ? parseFloat(line.totalDebit) : line.totalDebit;
    }
    return {
      ...acc,
      transaction
    };
  });

  return results;
};
