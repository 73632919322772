import { getCustomer, getLocationByDetail, getUser } from '@/services';

export async function getFonePayRemark(locationId: number, customerId: number, totalItems: number) {
  const [location, customer] = await Promise.all([
    getLocationByDetail(locationId),
    getCustomer(customerId)
  ]);

  let customerUsername = customer?.user?.name;
  if (!customerUsername) {
    if (customer?.userId) {
      const customerUserDetails = await getUser(customer.userId);
      customerUsername = customerUserDetails?.name;
    }
  }

  // Determine locationCode (use locationId if code is not present or too long)
  const locationCode = location.code.length > 4 ? locationId : location.code;

  // Trim or format the customer name
  let customerName = customerUsername.replace(/\s+/g, ''); // Remove all spaces
  if (customerName.length > 15) {
    customerName = customerName.slice(0, 15); // Trim if name exceeds 15 characters
  }

  // Ensure totalItems is a string with max length of 4 chars
  const itemsString = totalItems.toString().slice(0, 4);
  const remark1 = `${locationCode}-${customerName}-${itemsString}`;
  return remark1;
}
