import { WithReportPaginationProps } from '@/components/Common/Report/withReportPagination';
import { IProductDailyIdentifierWiseTotalReport } from '@/services/report/types';
import { ExtendedInitialValues } from '.';
import { useState } from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import { Table, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { getTableExportColumns, getUpdatedData } from './columns.export';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportAllData from '@/components/Common/ExportAll';
import ExportCurrent from '@/components/Common/ExportCurrent';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { DEFAULT_DATE_FORMAT } from '@/constants';

interface Props
  extends Pick<WithReportPaginationProps, 'onPagination' | 'onChange' | 'pagination'> {
  data: { total: number; data: IProductDailyIdentifierWiseTotalReport[] };
  onSearch: (values: ExtendedInitialValues) => void;
  onSearchAll: () => Promise<IProductDailyIdentifierWiseTotalReport[]>;
  isSaleIdentifier: boolean;
  isPurchaseIdentifier: boolean;
}

function TableData({
  data,
  pagination,
  isSaleIdentifier,
  isPurchaseIdentifier,
  ...handlers
}: Props) {
  const [sortedInfo, setSortedInfo] = useState<
    SorterResult<IProductDailyIdentifierWiseTotalReport>
  >({});

  const handleChange: TableProps<IProductDailyIdentifierWiseTotalReport>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<IProductDailyIdentifierWiseTotalReport>);
  };

  const total = data.data.reduce(
    (acc, curr) => {
      acc.amount += parseFloat(curr['Total Amount']);
      acc.qty += parseFloat(curr['Total Qty']);
      return acc;
    },
    { amount: 0, qty: 0 }
  );

  const columns: ColumnsType<IProductDailyIdentifierWiseTotalReport> = [
    {
      title: 'SN',
      key: 'SN',
      width: 30,
      render: (text, record, index) => {
        return <TableCell>{(pagination.page - 1) * pagination.size + (index + 1)}</TableCell>;
      }
    },

    {
      title: 'Product',
      dataIndex: 'Product',
      key: 'Product',
      width: 120,
      sorter: (a, b) => a.Product.localeCompare(b.Product),
      sortOrder: sortedInfo.columnKey === 'Product' ? sortedInfo.order : null
    },
    {
      title: 'Unit',
      dataIndex: 'Unit',
      key: 'Unit',
      width: 40,
      sorter: (a, b) => a.Unit.localeCompare(b.Unit),
      sortOrder: sortedInfo.columnKey === 'Unit' ? sortedInfo.order : null
    },
    ...(isSaleIdentifier
      ? [
          {
            title: 'Customer Name',
            dataIndex: 'customer_name',
            key: 'CustomerName',
            width: 120,
            sorter: (
              a: IProductDailyIdentifierWiseTotalReport,
              b: IProductDailyIdentifierWiseTotalReport
            ) => (a?.customer_name || '').localeCompare(b.customer_name || ''),
            sortOrder: sortedInfo.columnKey === 'CustomerName' ? sortedInfo.order : null
          }
        ]
      : isPurchaseIdentifier
      ? [
          {
            title: 'Supplier Name',
            dataIndex: 'supplier_name',
            key: 'SupplierName',
            width: 120,
            sorter: (
              a: IProductDailyIdentifierWiseTotalReport,
              b: IProductDailyIdentifierWiseTotalReport
            ) => (a?.supplier_name || '').localeCompare(b.supplier_name || ''),
            sortOrder: sortedInfo.columnKey === 'SupplierName' ? sortedInfo.order : null
          }
        ]
      : []),

    {
      title: 'Location',
      dataIndex: 'Location',
      key: 'Location',
      width: 80,
      sorter: (a, b) => a.Location.localeCompare(b.Location),
      sortOrder: sortedInfo.columnKey === 'Location' ? sortedInfo.order : null
    },
    {
      title: 'Total Qty',
      key: 'Total_Qty',
      width: 40,
      sorter: (a, b) => {
        const aValue = a['Total Qty'] || '';
        const bValue = b['Total Qty'] || '';
        return aValue.localeCompare(bValue);
      },
      sortOrder: sortedInfo.columnKey === 'Total_Qty' ? sortedInfo.order : null,
      render: (record: IProductDailyIdentifierWiseTotalReport) => (
        <TableCell>{nepaliNumberFormatter(parseFloat(record['Total Qty']))}</TableCell>
      )
    },
    {
      title: 'Total Amount',
      key: 'Total_Amount',
      width: 40,
      sorter: (a, b) => {
        const aValue = a['Total Amount'] || '';
        const bValue = b['Total Amount'] || '';
        return aValue.localeCompare(bValue);
      },
      sortOrder: sortedInfo.columnKey === 'Total_Amount' ? sortedInfo.order : null,
      render: (record: IProductDailyIdentifierWiseTotalReport) => (
        <TableCell>{nepaliNumberFormatter(parseFloat(record['Total Amount']))}</TableCell>
      )
    },
    {
      title: 'Date',
      key: 'date',
      width: 80,
      sorter: (a, b) => a.Date.localeCompare(b.Date),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (record: IProductDailyIdentifierWiseTotalReport) => (
        <TableCell>{convertUTCStringtoLocalString(record.Date, DEFAULT_DATE_FORMAT)}</TableCell>
      )
    }
  ];

  const updatedData = getUpdatedData(data.data);
  const { tableExportColumns, excelExportColumns } = getTableExportColumns(
    isSaleIdentifier ? 'sale' : isPurchaseIdentifier ? 'purchase' : 'other'
  );

  return (
    <div>
      <CustomizeTable
        columns={columns}
        data={data.data}
        notshowPagination={true}
        toSort={handleChange}
        customScroll={{ x: 1000, y: '75vh' }}
        tableSummary={
          <Table.Summary>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={5} className="text-center">
                Total
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>{nepaliNumberFormatter(total.qty)}</Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                {nepaliNumberFormatter(total.amount)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        }
        buttons={
          <>
            <ExportAllData
              title={'Product Avg Price Report (All)'}
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              getInfo={handlers.onSearchAll}
            />

            <ExportCurrent
              data={{ total: data.total, results: updatedData }}
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              title="Product Avg Price Report"
            />
          </>
        }
        paginationDatas={{
          page: pagination.page,
          total: data.total,
          size: pagination.size,
          onPagination: (pageNo, currentSize) => {
            currentSize == pagination.size
              ? handlers.onPagination(handlers.onSearch, pageNo)
              : handlers.onPagination(handlers.onSearch, pageNo, currentSize);
          }
        }}
      />
    </div>
  );
}

export default TableData;
