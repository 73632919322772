export const generateExpenseReportColumn = (val: any) => {
  const columns: {
    title: string;
    dataIndex: string;
    key: string;
  }[] = [];
  for (const [key, value] of Object.entries(val)) {
    const col = {
      title: '',
      dataIndex: key,
      key: key
    };
    const splitKey = key.split('_');
    if (splitKey.length > 1) {
      if (splitKey[0] === 'f' || splitKey[0] === 'expenses' || splitKey[0] === 'expense') {
        col.title = capitalizeFirstLetter(splitKey.slice(1));
      } else if (splitKey[0] == 'n' && splitKey[1] === 'f') {
        col.title = capitalizeFirstLetter(splitKey.slice(2));
      } else {
        col.title = capitalizeFirstLetter(splitKey);
      }
      columns.push(col);
    }
  }
  return columns;
};

const capitalizeFirstLetter = (arr: string[]) => {
  let val = '';
  arr.forEach((element) => {
    if (element !== '') {
      element = element.toLowerCase();
      if (val) val = val + ' ';
      val += element.charAt(0).toUpperCase() + element.slice(1);
    }
  });
  return val;
};
