import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { ILocationCreateRequest, ILocations } from './types';

export const create_location_mutation = async (values: ILocationCreateRequest) => {
  const response: AxiosResponse<ILocations, any> = await http.store('locations', values);
  return response;
};

export const update_location_mutation = async (values: ILocations) => {
  const response: AxiosResponse<ILocations, any> = await http.update(
    'locations/' + values.id,
    values
  );
  return response;
};

/* Delete location
 ** @@ params: id
 */
export const delete_location = async (id: number) => {
  const response = await http.remove('locations/' + id);
  return response;
};
