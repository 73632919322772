import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Menu, Tooltip, Form, message, TableProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate } from 'react-router-dom';
import AppContent from '@/components/Common/Content';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';

import TableFilter from '@/components/FliterTable';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { get_vehicles_list_filter } from '@/services/vehicle/queries';
import { SorterResult } from 'antd/lib/table/interface';
import { ConvertObjectToURL } from '@/utils/converturl';
import { delete_vehicle } from '@/services/vehicle/mutations';
import HistoryIcon from '../icons/History.svg';
import LiveIcon from '../icons/Live.svg';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { IVehicleData, IVehicleList } from '@/services/vehicle/types';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';

const VehicleList: React.FC = () => {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(true);
  const [vehicleList, setVehicleList] = useState<IVehicleList>({ count: 0, vehicles: [] });

  const [form] = Form.useForm();

  const [sortedInfo, setSortedInfo] = useState<SorterResult<IVehicleData>>({});
  const handleChange: TableProps<IVehicleData>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<IVehicleData>);
  };

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);

  const breadcrumbItems = [
    {
      label: 'Vehicle',
      link: '/vehicle'
    }
  ];

  const deleteVehicleMutation = useMutation(delete_vehicle, {
    onSuccess: () => {
      message.success('Vehicle deleted successfully');
      onPagination();
    }
  });
  const getInfo = async (filter = '') => {
    setIsloading(true);
    console.log('filter', filter);
    const response = await get_vehicles_list_filter(filter);
    setVehicleList(response.data);
    setIsloading(false);
  };
  const onSubmitFilter = async (val: string) => {
    const values = form.getFieldsValue();
    values.skip = 0;
    values.count = 50;
    const url = ConvertObjectToURL(values);
    await getInfo(url);
    setPage(1);
    setSize(50);
  };
  const onPagination = async (pageNo = 1, totalSize = 50, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    delete values.dateCustom;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    // const response = await get_sell_lists_v2(url);
    // setallSellList(response.data);
    await getInfo(url);
    // setIsloading(false);
    // ProductsDB.addProducts(productResponse.data.results);
  };
  const columns: ColumnsType<IVehicleData> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 2,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 10,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <Link to={`/vehicle/view/${record.id}`} color="black">
            <TableCell>{record.name}</TableCell>
          </Link>
        );
      }
    },
    {
      title: 'IMEI',
      key: 'imei',
      width: 10,
      sorter: (a, b) => a.imei.localeCompare(b.imei),
      sortOrder: sortedInfo.columnKey === 'imei' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/vehicle/${record.id}`} color="black">
          <TableCell>{record.imei}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Number',
      key: 'number',
      width: 10,
      sorter: (a, b) => a.number.localeCompare(b.number),
      sortOrder: sortedInfo.columnKey === 'number' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/vehicle/${record.id}`} color="black">
          <TableCell>{record.number}</TableCell>
          // </Link>
        );
      }
    },

    {
      title: 'Actions',
      key: 'actions',
      width: 3,
      fixed: 'right',
      render: (record) => {
        const confirmFunction = () => {
          deleteVehicleMutation.mutate(record.id);
        };
        const menu = (
          <Menu
            items={[
              {
                key: '1',
                label: <CustomViewIcon link={`/vehicle/view/${record.id}`} />
              },
              {
                key: '2',
                label: <CustomUpdateIcon link={`/vehicle/${record.id}`} />
              },
              {
                key: '3',
                label: (
                  <Tooltip title="Live" color="blue">
                    <Link to={`/vehicle/live/${record.id}`} color="black">
                      <img src={LiveIcon} className="historyandliveicon" />
                      {/* <EyeFilled style={{ transform: 'scale(1.4)', width: '100%' }} /> */}
                    </Link>
                  </Tooltip>
                )
              },
              {
                key: '6',
                label: (
                  <Tooltip title="History" color="blue">
                    <Link to={`/vehicle/history/${record.id}`} color="black">
                      <img src={HistoryIcon} className="historyandliveicon" />
                      {/* <EyeFilled style={{ transform: 'scale(1.4)', width: '100%' }} /> */}
                    </Link>
                  </Tooltip>
                )
              },
              {
                key: '4',
                label: <DiscussionChatModal slug="vehicle" rreference="other" id={record.id} />
              }
              /* {
                key: '5',
                label: (
                  <Tooltip title="Delete" color="red">
                    <DeleteConfirmModal FunctionAfterOk={confirmFunction} />
                  </Tooltip>
                )
              } */
            ]}
          />
        );
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'ID',
          dataIndex: 'id',
          width: 100
        },
        {
          title: 'Name',
          width: 250,
          dataIndex: 'name'
        },
        {
          title: 'IMEI',
          width: 200,
          dataIndex: 'imei'
        },
        {
          title: 'Number',
          width: 200,
          dataIndex: 'number'
        }
      ];
      if (vehicleList && vehicleList.vehicles.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = vehicleList.vehicles.map((item, index: number) => {
        return {
          ...item,
          SN: index + 1
        };
      });
      exportExcel(columns, dataUpdated, 'Vehicle List');
    } catch (err) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  // console.log('vehicle List', vehicleList);
  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <TableFilter
                form={form}
                dateCustom={false}
                defaultValues={{}}
                initial={true}
                styleforbuttons="flex items-center justify-end"
                onSubmit={onSubmitFilter}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                buttons={
                  <>
                    <CustomButton
                      onClick={() => navigate(`/vehicle/new`)}
                      text="Add"
                      backgroundColor="#1890ff"
                      Linkto="/vehicle/new"
                    />
                    <CustomButton text="Export" backgroundColor="#1890ff" onClick={handleExport} />
                  </>
                }></TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          columns={columns}
          data={vehicleList.vehicles}
          usersLoading={isloading}
          notshowPagination={true}
          // toshowcustomPagintion={true}
          // onPagination={onPagination}
          customScroll={{ y: '75vh', x: 700 }}
          paginationDatas={{
            page: page,
            total: vehicleList.count,
            size: size,
            onPagination
          }}
          tableName={'vehicle-list'}
          toSort={handleChange}
        />
      </AppContent>
    </div>
  );
};

export default VehicleList;
