import { PlusCircleFilled } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Input, Button, Card, message, Tooltip } from 'antd';
// import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DeleteConfirmModal from '../../../../../components/Common/Deleteconfirm/DeleteConfirmModal';
import { delete_invoice_layout_mutation } from '../../../../../services/settings/mutations';
import { get_invoices_list } from '../../../../../services/settings/queries';
import { ICreateInvoiceResponse, IInvoicetype } from '../../../../../services/settings/types';
import CustomUpdateIcon from '../../../../../components/Common/CustomIcons/CustomUpdateIcon';

const List = () => {
  const navigate = useNavigate();

  const {
    data: invoiceLayouts,
    isLoading: invoiceLoading,
    refetch
  } = useQuery(['invoiceLayouts'], async () => get_invoices_list());

  const deleteInvoicesLayoutsMutation = useMutation(delete_invoice_layout_mutation, {
    onSuccess: () => {
      message.success('Invoice layout deleted successfully');
      refetch();
    }
  });

  return (
    <div>
      <div className={'flex justify-around gap-1'}>
        <Input
          placeholder={'Search...'}
          size="small"
          allowClear
          onPressEnter={(event: React.KeyboardEvent) => {
            //do something later here
          }}
        />

        <Link to={'/invoices/layouts/new'}>
          {' '}
          <Button
            htmlType={'button'}
            size={'small'}
            type={'primary'}
            className={'me-2'}
            onClick={() => navigate('/invoices/layouts/new')}>
            <PlusCircleFilled />
            <span> Add</span>
          </Button>
        </Link>
      </div>

      <div className="grid grid-cols-3 gap-5 my-5">
        {invoiceLoading ? (
          <div>Loading...</div>
        ) : (
          invoiceLayouts &&
          invoiceLayouts.data.map((invoices: ICreateInvoiceResponse, index: number) => {
            const confirmFunction = () => {
              deleteInvoicesLayoutsMutation.mutate(`${invoices.id}`);
            };
            return (
              <Card key={index} title={invoices.name}>
                <div className="flex justify-center items-center">
                  <CustomUpdateIcon link={`/invoices/layouts/${invoices.id}`} />
                  {/* <div
                  onClick={() => deleteInvoicesLayoutsMutation.mutate(invoices.id)}
                  className="cursor-pointer text-amber-700 ml-1">
                  <Tooltip title="Delete" color="red">
                    <DeleteOutlined type="danger" />
                  </Tooltip>
                </div> */}
                  <DeleteConfirmModal FunctionAfterOk={confirmFunction} />
                </div>
              </Card>
            );
          })
        )}
      </div>
    </div>
  );
};

export default List;
