import CustomSearch, { SelectProps } from '..';
import CategoryDB from '@/store/localstorage/CategoryDB';
import { get_category_list_only } from '@/services/category/queries';
import { ICategory } from '@/services/category/types';

type MultipleProps =
  | {
      defaultValue?: number;
      isMultiple?: false;
      setSelected?: (selected?: ICategory) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      defaultValue?: number[];
      isMultiple: true;
      setSelected?: (selected?: ICategory[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps & MultipleProps;

function ProductCategorySearchV2(props: Props) {
  return (
    <CustomSearch
      formFor="product category"
      addCallback={CategoryDB.addCategory}
      serverCallback={get_category_list_only}
      dbSearchById={CategoryDB.getCategory}
      dbSearchCallback={CategoryDB.searchCategoryByName}
      {...props}
    />
  );
}

export default ProductCategorySearchV2;
