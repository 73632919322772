import moment from 'moment';
import { useEffect, useState } from 'react';
import { Form, message, Modal } from 'antd';

import WidgetFilterUI from './WidgetFilterUI';
import { ReportRequiredFields } from '../../constant.dashboard';
import {
  IDateRangeType,
  IFormField,
  IWidgetConfiguration,
  IWidgetGetConfig,
  ICompareSelectedDate,
  IDateRange,
  ICompareRangeType
} from '@/services/dashboard/v3/types';
import { DateSegmentPresets } from '@/pages/sqlsource/report/utils/datePresets';
import { verifyComparableFields } from '@/services/dashboard/v3/services.dashboard';
import { CompareType } from '@/services/dashboard/v3/enums';

interface Props {
  visible: boolean;
  widget: IWidgetConfiguration;
  reports: { id: number; name: string }[];
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  getConfig: (props: IWidgetGetConfig) => Promise<void>;
}

interface FormValues {
  dateRange: IDateRangeType;
  customRange?: [moment.Moment, moment.Moment];
  compareDateRange?: IDateRangeType;
  customCompareDateRange?: [moment.Moment, moment.Moment];
  firstDate?: moment.Moment;
  secondDate?: moment.Moment;
  singleCustomRange?: [moment.Moment, moment.Moment];
  compareType?: CompareType;
  formFieldName: string;
  customFirstDateRange?: [moment.Moment, moment.Moment];
  customSecondDateRange?: [moment.Moment, moment.Moment];
}

function WidgetFilterNoContext({ visible, reports, setVisible, getConfig, widget }: Props) {
  const [form] = Form.useForm<FormValues>();
  const [previousValues, setPreviousValues] = useState<FormValues | null>(null);

  function handleDateChange(value: IDateRangeType) {
    let date = DateSegmentPresets.Daily;
    if (value === 'daily') date = DateSegmentPresets.Daily;
    if (value === 'weekly') date = DateSegmentPresets.Weekly;
    if (value === 'monthly') date = DateSegmentPresets.Monthly;
    if (value === 'custom') {
      const customRange = form.getFieldValue('customRange') as [moment.Moment, moment.Moment];
      date = [customRange[0].toISOString(), customRange[1].toISOString()];
    }

    return date;
  }

  function getReportFields(allFormFields?: IFormField) {
    if (allFormFields && Object.keys(allFormFields).length > 0) {
      Object.keys(allFormFields).forEach((reportId) => {
        const value = widget.formFields?.[reportId] as { key: string; value: string }[] | undefined;
        value?.forEach(({ key, value }) => {
          form.setFieldsValue({ [`${reportId}---${key}`]: value });
        });
      });
      return allFormFields;
    }

    const reportField = {} as Record<number, { key: string; value: string | undefined }[]>;

    reports.forEach((report) => {
      const formField = ReportRequiredFields[report.id];
      if (!formField) return;

      reportField[report.id] = [];

      formField.forEach((field) => {
        const { options } = field.formOption;
        const value = options?.[0].value ?? undefined;

        reportField[report.id].push({ key: field.field, value });
        form.setFieldsValue({ [`${report.id}---${field.field}`]: value });
      });
    });

    return reportField;
  }

  useEffect(() => {
    const comparable = widget.comparable;
    const formFields = getReportFields(widget.formFields);

    // Comparable date
    if (comparable) {
      const selectedConfig = comparable.selectedDate;

      // If no date has been saved, set the default date to firstDate and secondDate
      if (!selectedConfig) {
        const secondDate = moment();
        const firstDate = moment().subtract(1, 'days');

        const [fDate, sDate] = [firstDate, secondDate].map((d) => d.format('YYYY-MM-DD'));

        const newSelectedDate = {
          key: 'single',
          firstDate: fDate,
          secondDate: sDate
        } as ICompareSelectedDate;

        form.setFieldsValue({ firstDate, secondDate, compareType: CompareType.SINGLE_COMPARE });
        getConfig({ type: 'compare', isInitial: true, formFields, selectedDate: newSelectedDate });
        return;
      }

      if (selectedConfig.key === 'single') {
        const [firstDate, secondDate] = [selectedConfig.firstDate, selectedConfig.secondDate].map(
          (d) => moment(d)
        );

        form.setFieldsValue({ firstDate, secondDate, compareType: CompareType.SINGLE_COMPARE });
        getConfig({ type: 'compare', isInitial: true, formFields, selectedDate: selectedConfig });
        return;
      }

      if (selectedConfig.key === 'custom-both-range') {
        const { first, second } = selectedConfig.value;
        const firstMomentRange = first.map((d) => moment(d)) as [moment.Moment, moment.Moment];
        const secondMomentRange = second.map((d) => moment(d)) as [moment.Moment, moment.Moment];

        form.setFieldsValue({
          customFirstDateRange: firstMomentRange,
          customSecondDateRange: secondMomentRange,
          compareType: CompareType.CUSTOM_BOTH_RANGE
        });
        getConfig({ type: 'compare', isInitial: true, formFields, selectedDate: selectedConfig });
        return;
      }

      if (selectedConfig.key === 'range') {
        let selectedDates = undefined;

        if (selectedConfig.type === 'custom') {
          selectedDates = selectedConfig.value.map((d) => moment(d)) as [
            moment.Moment,
            moment.Moment
          ];
        }

        form.setFieldsValue({
          compareType: CompareType.RANGE_COMPARE,
          compareDateRange: selectedConfig.type,
          customCompareDateRange: selectedDates
        });
      }

      if (selectedConfig.key === 'custom') {
        const selectedDates = selectedConfig.value.map((d) => moment(d)) as [
          moment.Moment,
          moment.Moment
        ];

        form.setFieldsValue({
          compareType: CompareType.CUSTOM_RANGE,
          singleCustomRange: selectedDates
        });
      }

      form.setFieldValue(
        `${reports[0].id}---${selectedConfig.formField}`,
        selectedConfig.formFieldValue
      );

      getConfig({ type: 'compare', isInitial: true, formFields, selectedDate: selectedConfig });

      return;
    }

    // Global date
    const dateRange = widget.selectedDate?.type ?? 'daily';
    form.setFieldsValue({ dateRange });

    if (widget.selectedDate?.type === 'custom') {
      const customRange = widget.selectedDate.value.map((v) =>
        moment(v)
      ) as FormValues['customRange'];

      form.setFieldsValue({ customRange });
    }

    const date = widget.selectedDate
      ? widget.selectedDate.type === 'custom'
        ? widget.selectedDate.value
        : handleDateChange(widget.selectedDate.type)
      : handleDateChange('daily');

    getConfig({
      date,
      dateType: dateRange,
      formFields,
      isInitial: true,
      type: 'global'
    });
  }, []);

  useEffect(() => {
    if (visible) {
      setPreviousValues(form.getFieldsValue());
    }
  }, [visible]);

  function handleCancel() {
    if (previousValues) form.setFieldsValue(previousValues);
    setVisible(false);
  }

  function restructureData(input: Record<string, string>) {
    const output = {} as IFormField;

    Object.entries(input).forEach(([key, value]) => {
      const [id, prop] = key.split('---');
      if (!output[id]) {
        output[id] = [];
      }
      output[id].push({ key: prop, value: value });
    });

    return output;
  }

  async function onFinish() {
    const { dateRange, formFieldName, ...formFields } = form.getFieldsValue();
    await form.validateFields();

    const reportFormFields = { ...formFields } as Record<string, string>;
    delete reportFormFields.customRange;
    delete reportFormFields.firstDate;
    delete reportFormFields.secondDate;
    delete reportFormFields.compareDateRange;
    delete reportFormFields.singleCustomRange;
    delete reportFormFields.compareType;
    delete reportFormFields.customCompareDateRange;
    delete reportFormFields.customFirstDateRange;
    delete reportFormFields.customSecondDateRange;

    // COMPARABLE SELECT DATE
    if (widget.comparable) {
      const formFieldsValue = restructureData(reportFormFields);
      const selectedFormField = widget.comparable.formField;

      const { hasError } = verifyComparableFields(formFields);
      if (hasError) return;

      const handleComparableConfig = (
        dateType: IDateRangeType,
        dates: IDateRange,
        key: ICompareRangeType
      ) => {
        const selectedValue = formFieldsValue[reports[0].id]?.find(
          (field) => field.key === selectedFormField
        )?.value;

        if (!selectedValue) {
          return message.error('Please select a value.');
        }

        getConfig({
          type: 'compare',
          formFields: formFieldsValue,
          selectedDate: {
            key,
            type: dateType,
            value: dates,
            formField: selectedFormField,
            formFieldValue: selectedValue,
            formFieldName
          },
          isInitial: false
        });
        setVisible(false);
      };

      // RANGE_COMPARE
      if (formFields.compareDateRange) {
        const dateType = formFields.compareDateRange;
        if (formFields.compareDateRange !== 'custom') {
          const selectedDates = handleDateChange(dateType);
          handleComparableConfig(dateType, selectedDates, 'range');
          return;
        }

        const selectedDates = formFields.customCompareDateRange?.map((d) =>
          d.toISOString()
        ) as IDateRange;

        handleComparableConfig(dateType, selectedDates, 'range');
        return;
      }

      // SINGLE_CUSTOM_RANGE
      if (formFields.singleCustomRange) {
        const selectedDates = formFields.singleCustomRange.map((d) =>
          d.toISOString()
        ) as IDateRange;
        handleComparableConfig('custom', selectedDates, 'custom');
        return;
      }

      // COMPARE_BOTH_RANGE
      if (formFields.customFirstDateRange && formFields.customSecondDateRange) {
        const { customFirstDateRange, customSecondDateRange } = formFields;

        const selectedValue = formFieldsValue[reports[0].id]?.find(
          (field) => field.key === selectedFormField
        )?.value;

        if (!selectedValue) {
          return message.error('Please select a value.');
        }

        getConfig({
          type: 'compare',
          formFields: formFieldsValue,
          selectedDate: {
            key: 'custom-both-range',
            value: {
              first: customFirstDateRange.map((d) => d.toISOString()) as IDateRange,
              second: customSecondDateRange.map((d) => d.toISOString()) as IDateRange
            },
            formField: selectedFormField,
            formFieldValue: selectedValue,
            formFieldName
          },
          isInitial: false
        });
        setVisible(false);
        return;
      }

      // COMPARE SINGLE DATE
      const firstDate = formFields.firstDate as moment.Moment;
      const secondDate = formFields.secondDate as moment.Moment;

      const firstDateUTC = firstDate.format('YYYY-MM-DD');
      const secondDateUTC = secondDate.format('YYYY-MM-DD');

      getConfig({
        type: 'compare',
        formFields: formFieldsValue,
        isInitial: false,
        selectedDate: { key: 'single', firstDate: firstDateUTC, secondDate: secondDateUTC }
      });

      setVisible(false);
      return;
    }

    // GLOBAL SELECT DATE
    const dateType = dateRange as IDateRangeType;
    const selectedDates = handleDateChange(dateType);

    const formFieldsValue = restructureData(reportFormFields);
    if (dateType !== 'custom') {
      getConfig({
        date: selectedDates,
        dateType,
        formFields: formFieldsValue,
        isInitial: false,
        type: 'global'
      });

      setVisible(false);
      return;
    }

    const normalizedSelectedDates = selectedDates.map((date) => moment(date));
    const normalizedDailyDates = DateSegmentPresets.Daily.map((date) => moment(date));

    const isStartSame = normalizedSelectedDates[0].isSame(normalizedDailyDates[0]);
    const isEndSame = normalizedSelectedDates[1].isSame(normalizedDailyDates[1]);

    getConfig({
      date: selectedDates,
      dateType: isStartSame && isEndSame ? 'daily' : 'custom',
      formFields: formFieldsValue,
      isInitial: false,
      type: 'global'
    });
    setVisible(false);
  }

  return (
    <Modal
      title="Filter"
      visible={visible}
      onCancel={handleCancel}
      onOk={onFinish}
      data-no-dnd="true">
      <WidgetFilterUI form={form} reports={reports} widget={widget} />
    </Modal>
  );
}

export default WidgetFilterNoContext;
