import { get_units_list } from '@/services/products/queries';
import LocalStore from '.';
import { IUnits } from '../../services/products/types';

export default class UnitsDB extends LocalStore {
  /*Units Functions*/
  static async getAllUnits() {
    if (!UnitsDB.db) await UnitsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = UnitsDB.db.transaction('Units', 'readonly');
      const objectStore = transaction.objectStore('Units');
      const request = objectStore.getAll();

      request.onerror = (event: any) => {
        reject(event.target.error);
      };

      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      request.onsuccess = async (event: any) => {
        const units: IUnits[] = (event.target as IDBRequest).result;
        if (units.length > 0) {
          resolve(units);
          return;
        }

        const unitLists = await get_units_list();
        await UnitsDB.addUnits(unitLists);
        resolve(unitLists);
      };
    });
  }
  static async removeAllUnits() {
    if (!UnitsDB.db) await UnitsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = UnitsDB.db.transaction(['Units'], 'readwrite');
      transaction.oncomplete = (event) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Units');
      const objectStoreRequest = objectStore.clear();
      objectStoreRequest.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      objectStoreRequest.onerror = (event: any) => {
        reject(event.target.result);
      };
    });
  }
  static async addUnits(unitsData: any[]) {
    if (!UnitsDB.db) await UnitsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = UnitsDB.db.transaction('Units', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject('failed to find unit table in LC');
      };
      const objectStore = transaction.objectStore('Units');
      unitsData.forEach(async (unit) => {
        const checkKey = objectStore.count(unit.id);
        checkKey.onsuccess = async (event: any) => {
          unit.lowercaseName = unit.name.toLowerCase();
          if (checkKey.result == 0) {
            const request = objectStore.add(unit);
            request.onerror = (event: any) => {
              reject(event.target.error);
            };
          } else {
            await UnitsDB.updateUnit(unit);
          }
        };
      });
    });
  }

  static async getUnitByName(name: string, limit = 10) {
    if (!UnitsDB.db) await UnitsDB.init();

    return new Promise((resolve: (value: IUnits[] | string) => void, reject) => {
      const fiteredUnits: IUnits[] = [];
      const transaction = UnitsDB.db.transaction('Units', 'readonly');
      const objectStore = transaction.objectStore('Units');
      const lowercaseString = name.toLowerCase();

      const cursorRequest = objectStore.index('UnitsSecondIndex').openCursor();

      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          const item = cursor.value;

          if (item.name && item.name?.toLowerCase().includes(lowercaseString))
            fiteredUnits.push(item);

          if (fiteredUnits.length < limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(fiteredUnits);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async getUnit(id: number | string) {
    if (!UnitsDB.db) await UnitsDB.init();

    return new Promise<IUnits | string>((resolve, reject) => {
      const transaction = UnitsDB.db.transaction('Units', 'readonly');

      const objectStore = transaction.objectStore('Units');
      const request = objectStore.get(id);

      request.onerror = (event: any) => {
        reject(event.target.error);
      };

      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };

      request.onsuccess = async (event: any) => {
        const unit: IUnits = (event.target as IDBRequest).result;
        if (unit) {
          resolve(unit);
        } else {
          const unitLists = await get_units_list();
          await UnitsDB.addUnits(unitLists);

          const currentUnit = unitLists.find((u) => u.id === id);
          if (!currentUnit) return reject('Error, could not find id.');
          resolve(currentUnit);
        }
      };
    });
  }
  static async updateUnit(unit: any) {
    if (!UnitsDB.db) await UnitsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = UnitsDB.db.transaction('Units', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Units');
      const request = objectStore.get(unit.id);
      request.onsuccess = (event: any) => {
        if (event.target.result) {
          unit.lowercaseName = unit.name.toLowerCase();
          const requestUpdate = objectStore.put(unit);
          requestUpdate.onerror = (event: any) => {
            reject(event.target.error);
          };
          requestUpdate.onsuccess = (event) => {
            resolve('updated data');
          };
        } else reject('Error, could not find id.');
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async deleteUnit(id: number) {
    if (!UnitsDB.db) await UnitsDB.init();

    if (await UnitsDB.getUnit(id)) {
      return new Promise((resolve, reject) => {
        const transaction = UnitsDB.db.transaction('Units', 'readwrite');
        transaction.oncomplete = (event: any) => {
          resolve('success');
        };
        transaction.onerror = (event: any) => {
          reject(event.target.error);
        };
        const objectStore = transaction.objectStore('Units');
        const request = objectStore.delete(id);
        request.onsuccess = (event: any) => {
          resolve('deleted successfuly.');
          // if (event.target.result) resolve(event.target.result);
          // reject('Error, could not find id.');
        };
        request.onerror = (event: any) => {
          reject(event.target.error);
        };
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve('id does not exist');
      });
    }
  }
}
