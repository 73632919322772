import { IClearLotDetails, IProductType, IStockClear, IUnits } from '@/services/products/types';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_list_ids } from '@/services/users/queries';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list_for_ids } from '@/services/locations/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { get_product_list_ids } from '@/services/products/queries';

export async function fillHIstoryDetails(history: IStockClear) {
  // Get created user
  if (history.createdBy) {
    let createdUser = await UsersDB.getUser(history.createdBy);
    if (!createdUser) {
      const users = await get_user_list_ids([history.createdBy]);
      await UsersDB.addUsers(users.data.results);
      createdUser = await UsersDB.getUser(history.createdBy);
    }
    history.createdName = createdUser?.name;
  }

  // Get Location name
  if (history.locationId) {
    let location = await LocationsDB.getLocation(history.locationId);
    if (!location) {
      const locations = await get_location_list_for_ids([history.locationId]);
      await LocationsDB.addLocations(locations);
      location = await LocationsDB.getLocation(history.locationId);
    }
    history.locationName = location?.name;
  }
}

async function getLotDetails(lot: IClearLotDetails, product: IProductType, units: IUnits[]) {
  const productUnits = product?.productUnits.find((p) => p.isDefault === true);
  if (!productUnits) return;

  let unitDetails = units?.find((u) => u.id === productUnits.unitId);
  if (!unitDetails) {
    unitDetails = (await UnitsDB.getUnit(productUnits.unitId)) as IUnits;
    if (unitDetails) {
      units?.push(unitDetails);
    }
  }

  lot.purchasePriceAvg = lot.purchasePriceAvg * unitDetails.baseUnitMultiplier;
  lot.qtyAvailableName = `${lot.qtyAvailable / unitDetails.baseUnitMultiplier} ${
    unitDetails.shortName
  }`;
  lot.qtyClosingName = `${lot.qtyClosing / unitDetails.baseUnitMultiplier} ${
    unitDetails.shortName
  }`;
  lot.qtyOpeningName = `${lot.qtyOpening / unitDetails.baseUnitMultiplier} ${
    unitDetails.shortName
  }`;
  lot.qtySoldName = `${lot.qtySold / unitDetails.baseUnitMultiplier} ${unitDetails.shortName}`;
  lot.qtyReceivedName = `${lot.qtyReceived / unitDetails.baseUnitMultiplier} ${
    unitDetails.shortName
  }`;
}

export async function fillLotsDetails(lots: IClearLotDetails[]) {
  const absentProducts = [] as number[];
  const units = (await UnitsDB.getAllUnits()) as IUnits[];

  for (const lot of lots) {
    if (lot.productId) {
      const product = (await ProductsDB.getProduct(lot.productId)) as IProductType;
      if (!product) absentProducts.push(lot.productId);
      lot.productName = product?.name;
      await getLotDetails(lot, product, units);
    }
  }

  if (absentProducts.length > 0) {
    const allProducts = await get_product_list_ids(absentProducts);
    await ProductsDB.addProducts(allProducts.data.results);
  }

  for (const lot of lots) {
    if (lot.productId && absentProducts.includes(lot.productId)) {
      const product = (await ProductsDB.getProduct(lot.productId)) as IProductType;
      lot.productName = product?.name;
      await getLotDetails(lot, product, units);
    }
  }
}
