import type { ColumnOrColumnGroup } from 'react-data-grid';
import { IRow } from '../grid.types';
import { Tag } from 'antd';

const snCol: ColumnOrColumnGroup<IRow> = {
  key: 'id',
  name: 'S.N.',
  frozen: true,
  width: 60,
  minWidth: 60,
  renderCell(props) {
    return <strong>{props.rowIdx + 1}</strong>;
  }
};

const outletColumns: ColumnOrColumnGroup<IRow> = {
  name: 'FreshKtm Outlet',
  headerCellClass: 'text-center text-white bg-red-500',
  children: [
    {
      key: 'sellPurchasePrice',
      name: 'Purchase Price',
      width: 100,
      minWidth: 100,
      headerCellClass: 'text-wrap-header'
    },
    {
      key: 'isPurchasePriceChanged',
      name: 'Is Purchase Price Changed?',
      width: 130,
      headerCellClass: 'text-wrap-header',
      cellClass: 'p-0',
      renderCell: ({ row }) => (
        <div className="flex justify-center">
          <Tag color={row.isPurchasePriceChanged ? 'red' : 'green'}>
            {row.isPurchasePriceChanged ? 'Changed' : 'Same'}
          </Tag>
        </div>
      )
    },
    {
      key: 'sellYesterdayPrice',
      name: 'Yesterday Selling Rate (Rs)',
      width: 140,
      minWidth: 140,
      headerCellClass: 'text-wrap-header'
    },
    {
      key: 'sellTodayPrice',
      name: 'Today Selling Rate (Rs)',
      width: 130,
      minWidth: 130,
      headerCellClass: 'text-wrap-header'
    },
    {
      key: 'isSellRateChanged',
      name: 'Is Sell Rate Changed?',
      width: 130,
      minWidth: 130,
      headerCellClass: 'text-wrap-header',
      cellClass: 'p-0',
      renderCell: ({ row }) => (
        <div className="flex justify-center">
          <Tag color={row.isSellRateChanged ? 'red' : 'green'}>
            {row.isSellRateChanged ? 'Changed' : 'Same'}
          </Tag>
        </div>
      )
    },
    {
      key: 'marginSellAmount',
      name: 'Margin (Rs)',
      width: 100,
      minWidth: 100,
      headerCellClass: 'text-wrap-header'
    },
    {
      key: 'marginSellPercentage',
      name: 'Margin (%)',
      width: 100,
      minWidth: 100,
      headerCellClass: 'text-wrap-header'
    }
  ]
};

export const columnsB2BOnly: ColumnOrColumnGroup<IRow>[] = [
  snCol,
  {
    name: 'Purchase',
    headerCellClass: 'text-center',
    children: [
      {
        key: 'productName',
        name: 'Product',
        width: 300,
        minWidth: 300
      },
      {
        key: 'unitName',
        name: 'Unit',
        width: 150,
        minWidth: 150
      },
      {
        key: 'purchasePrice',
        name: 'Purchase Price (Rs)',
        width: 100,
        minWidth: 100,
        headerCellClass: 'text-wrap-header'
      },
      {
        key: 'kalimatiRate',
        name: 'Kalimati Rate (Rs)',
        width: 100,
        minWidth: 100,
        headerCellClass: 'text-wrap-header'
      },
      {
        key: 'morningPurchase',
        name: 'Morning Purchase (Rs)',
        width: 120,
        minWidth: 120,
        headerCellClass: 'text-wrap-header'
      },
      {
        key: 'kalimatiSurveyRate',
        name: 'Kalimati Survey Rate (Rs)',
        width: 130,
        minWidth: 130,
        headerCellClass: 'text-wrap-header'
      },
      {
        key: 'yesterdaySellingRate',
        name: 'Yesterday Selling Rate (Rs)',
        width: 133,
        minWidth: 133,
        headerCellClass: 'text-wrap-header'
      },
      {
        key: 'yesterdaySoldQty',
        name: 'Yesterday Sold Qty',
        width: 120,
        minWidth: 120,
        headerCellClass: 'text-wrap-header'
      },
      {
        key: 'totalStock',
        name: 'Total Stock',
        width: 110,
        minWidth: 110,
        headerCellClass: 'text-wrap-header'
      },
      {
        key: 'todayPrice',
        name: 'Today Price (Rs)',
        width: 100,
        minWidth: 100,
        headerCellClass: 'text-wrap-header'
      },
      {
        key: 'isPriceChanged',
        name: 'Is Price Changed?',
        width: 100,
        minWidth: 100,
        headerCellClass: 'text-wrap-header',
        cellClass: 'p-0',
        renderCell: ({ row }) => (
          <div className="flex justify-center">
            <Tag color={row.isPriceChanged ? 'red' : 'green'}>
              {row.isPriceChanged ? 'Changed' : 'Same'}
            </Tag>
          </div>
        )
      },
      {
        key: 'marginAmount',
        name: 'Margin (Rs)',
        width: 100,
        minWidth: 100,
        headerCellClass: 'text-wrap-header'
      },
      {
        key: 'marginPercentage',
        name: 'Margin (%)',
        width: 100,
        minWidth: 100,
        headerCellClass: 'text-wrap-header'
      },
      {
        key: 'openingStock',
        name: 'Opening Stock',
        width: 100,
        minWidth: 100,
        headerCellClass: 'text-wrap-header'
      },
      {
        key: 'totalOrder',
        name: 'Total Order',
        width: 100,
        minWidth: 100,
        headerCellClass: 'text-wrap-header'
      },
      {
        key: 'estimatedData',
        name: 'Estimated Data',
        width: 100,
        minWidth: 100,
        headerCellClass: 'text-wrap-header'
      }
    ]
  }
];

export const columnsB2BAndOutlet: ColumnOrColumnGroup<IRow>[] = [...columnsB2BOnly, outletColumns];

export const columnsOutletOnly: ColumnOrColumnGroup<IRow>[] = [
  snCol,
  { name: 'Product', key: 'productName', minWidth: 300, frozen: true },
  { name: 'Unit', key: 'unitName', minWidth: 150, frozen: true },
  outletColumns
];
