import { DatePicker, Form, Input, InputNumber, Select, TableProps } from 'antd';
import { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { ProfitCalculationDatePresets } from '../utils/profitCalculationDatePreset';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import { CustomModal } from '@/components/Common/CustomModal';
import ReusableSellView from '../../../sell/view/ReusableView';
import CustomReport from './report';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

const { RangePicker } = DatePicker;
import { SorterResult } from 'antd/lib/table/interface';
import { find_date_preference, find_to_use_NPR } from '@/store/localstorage/preferences';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { getUserData } from '@/utils/auth.utils';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

const ProfitCalculationReport = () => {
  const [form] = Form.useForm();
  // const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [nepaliType, setNepaliType] = useState(find_date_preference());
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  useEffect(() => {
    const nepaliDateStart = convert_string_to_nepali_date_string(
      ProfitCalculationDatePresets.Today[0].format('YYYY-MM-DD HH:mm:ss')
    );
    const nepaliDateEnd = convert_string_to_nepali_date_string(
      ProfitCalculationDatePresets.Today[0].format('YYYY-MM-DD HH:mm:ss')
    );
    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      customerId: '',
      productId: '',
      locationId: preferenceLocationId ? preferenceLocationId : '',
      dateCustom: [...ProfitCalculationDatePresets.Today],
      startDate: ProfitCalculationDatePresets.Today[0].format('YYYY-MM-DD HH:mm:ss'),
      endDate: ProfitCalculationDatePresets.Today[1].format('YYYY-MM-DD HH:mm:ss'),
      startDateNepali: nepaliDateStart,
      endDateNepali: nepaliDateEnd
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Profit Calculation Report',
      link: '/reports/profit-calculation'
    }
  ];
  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };

  const columns: ColumnsType<any> = [
    // {
    //   title: 'Product ID',
    //   key: 'id',
    //   render: (record) => <Link to={`/products/${record.product_id}`}>{record.product_id}</Link>
    // },
    {
      title: 'S.N',
      key: 'SN',
      width: 25,
      // sorter: (a, b) => a.id - b.id,
      // sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Product',
      // dataIndex: 'name',
      key: 'product_name',
      sorter: (a, b) => a.product_name.localeCompare(b.product_name),
      sortOrder: sortedInfo.columnKey === 'product_name' ? sortedInfo.order : null,
      render: (record) => <div>{record.product_name}</div>,
      width: 100
    },
    {
      title: 'Location',
      // dataIndex: 'name',
      key: 'location_name',
      sorter: (a, b) => a.location_name.localeCompare(b.location_name),
      sortOrder: sortedInfo.columnKey === 'location_name' ? sortedInfo.order : null,
      render: (record) => <div>{record.location_name}</div>,
      width: 100
    },
    {
      title: 'Sell',
      key: 'sell',
      align: 'right',
      sorter: (a, b) => a.sell_sum - b.sell_sum,
      sortOrder: sortedInfo.columnKey === 'quantity' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record.sell_sum)}</div>;
      },
      width: 50
    },
    {
      title: 'Sell Return',
      key: 'sell_return_sum',
      align: 'right',
      sorter: (a, b) => a.sell_return_sum - b.sell_return_sum,
      sortOrder: sortedInfo.columnKey === 'sell_return_sum' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">{nepaliNumberFormatter(record.sell_return_sum)}</div>
        );
      },
      width: 50
    },
    {
      title: 'Purchase',
      key: 'purchase',
      align: 'right',
      sorter: (a, b) => a.purchase_sum - b.purchase_sum,
      sortOrder: sortedInfo.columnKey === 'purchase' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record.purchase_sum)}</div>;
      },
      width: 50
    },
    {
      title: 'Purchase Return',
      key: 'purchase_return_sum',
      align: 'right',
      sorter: (a, b) => a.purchase_return_sum - b.purchase_return_sum,
      sortOrder: sortedInfo.columnKey === 'purchase_return_sum' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">{nepaliNumberFormatter(record.purchase_return_sum)}</div>
        );
      },
      width: 50
    },
    {
      title: 'Profit',
      key: 'profit',
      align: 'right',
      sorter: (a, b) => a.profit - b.profit,
      sortOrder: sortedInfo.columnKey === 'profit' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record.profit)}</div>;
      },
      width: 50
    },
    {
      title: 'Wastage',
      key: 'wastage',
      align: 'right',
      sorter: (a, b) => a.wastage - b.wastage,
      sortOrder: sortedInfo.columnKey === 'wastage' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record.wastage_sum)}</div>;
      },
      width: 50
    },
    {
      title: 'Stock Sum',
      key: 'stock_sum',
      align: 'right',
      sorter: (a, b) => a.stock_sum - b.stock_sum,
      sortOrder: sortedInfo.columnKey === 'stock_sum' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record.stock_sum)}</div>;
      },
      width: 50
    }
  ];

  const columns2: ColumnsType<any> = [
    {
      title: 'Account Name',
      key: 'accountName',
      sorter: (a, b) => a['accountName'].localeCompare(b['accountName']),
      sortOrder: sortedInfo.columnKey === 'accountName' ? sortedInfo.order : null,
      render: (record) => <div>{record['accountName']}</div>
    },
    {
      title: 'Debit Sum',
      key: 'debit_sum',
      sorter: (a, b) => a['debit_sum'] - b['debit_sum'],
      sortOrder: sortedInfo.columnKey === 'debit_sum' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record['debit_sum'])}</div>;
      }
    },
    {
      title: 'Credit Sum',
      key: 'credit_sum',
      sorter: (a, b) => a['credit_sum'] - b['credit_sum'],
      sortOrder: sortedInfo.columnKey === 'credit_sum' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record['credit_sum'])}</div>;
      }
    }
  ];

  const onSelectt = (val: any) => {
    // console.log('this is from onSelect', val);
    form.setFieldValue(['constraints', 'locationId'], val);
  };
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart)
      form.setFieldValue(['constraints', 'startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', 'endDate'], convert_nepali_to_english_date(val));
  };

  return (
    <CustomReport
      breadcrumbItems={breadcrumbItems}
      state={{ id: [41, 56], name: 'Profit Calculation Report' }}
      form={form}
      toSort={handleChange}
      footerData={{ sell: true, purchase: true }}
      columnsData={[columns, columns2]}>
      <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} title={'Sell View'}>
        <ReusableSellView id={id} />
      </CustomModal>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'identifier']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <ProductSearchV2 showAll hasParentFormItem={false} name={['constraints', 'productId']} />
      <LocationSearchV2 showAll hasParentFormItem={false} name={['constraints', 'locationId']} />

      {/* <Form.Item name={['constraints', 'locationId']} label="Location"> */}
      {/* <Select placeholder="Select a location!">
          <Option value="">All</Option>

          {locationsList?.data.results.map((value: any) => {
            return (
              <Option value={value.id} key={value.id}>
                {value.name}
              </Option>
            );
          })}
        </Select> */}
      {/* <LocationSearch withoutForm={true} onSelect={(val) => onSelectt(val)} /> */}
      {/* </Form.Item> */}
      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[
          {
            required: true,
            message: `Please select date!`
          }
        ]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          ranges={ProfitCalculationDatePresets}
          disabledDate={disabledDate}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm:ss'}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
};

export default ProfitCalculationReport;
