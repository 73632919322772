import { useMutation } from '@tanstack/react-query';
import { Form, Input, Button, Select, message, PageHeader, Checkbox, Card } from 'antd';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import AppContent from '../../../components/Common/Content/Content';
import { CloseOutlined } from '@ant-design/icons';
import GoBackButton from '../../../components/Common/GoBackButton/GoBackButton';
import { create_notification_group } from '../../../services/notifications/mutation';
import { LocationSearch } from '../../../components/Common/LocationSearch/LocationSearch';
import { getUserData } from '../../../utils/auth.utils';
import useDebounce from '../../../hooks/useDebounce';
import { get_user_list } from '../../../services/users/queries';
import { NotificationEvents } from '../../../services/notifications/enums';

const { Option } = Select;

const CreateNotification = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isloading, setIsloading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [userSearch, setUserSearch] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  // const [IsActive, setIsActive] = useState<any>(false);
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const breadcrumbItems = [
    {
      label: 'Notifications',
      link: '/notification'
    },
    {
      label: 'Create',
      link: '/notification/new'
    }
  ];

  const createnotificationMutation = useMutation(create_notification_group);

  const onFinish = async (values: any) => {
    console.log(values);
    try {
      setIsloading(true);
      if (disabled) {
        values = { ...values, permissions: ['ADMIN'] };
      }
      createnotificationMutation.mutateAsync(values, {
        onSuccess: async (data: any) => {
          setIsloading(false);
          message.success('Notification group created successfully');
          navigate('/notification');
        },
        onError: (e: any) => {
          setIsloading(false);
          message.error(`${e.response.data.message}`, 5);
        }
      });
    } catch (errors: any) {
      setIsloading(false);
    }
  };
  // const displayName = (name: any) => {
  //   const value = form.getFieldValue(['permissions', name]);
  //   return <div className="text-center">{value}</div>;
  // };

  useEffect(() => {
    form.setFieldsValue({ events: [], users: [], isActive: false });
  }, []);

  const options = userSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.name}
    </Option>
  ));

  const DisplayUserName = (name: any) => {
    const selectedUser = form.getFieldValue(['users', name]);
    // const imgData = await get_image(`${API_URL}storage/media/${selectedUser}`);
    const user = userList.find((val) => val.id == selectedUser);
    // console.log('UserList-->', userList);
    // console.log('selected User-->', selectedUser);
    // console.log('User--->', user);
    return (
      <>
        <span>{user?.name}</span>
      </>
    );
  };

  useEffect(() => {
    searchUser(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const searchUser = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_user_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any user with that name!');
        setUserSearch([]);
      } else {
        setUserSearch(response.data.results);
        checkUsersAndAdd(response.data.results);
      }
    }
  };

  const checkUsersAndAdd = (users: any[]) => {
    if (userList.length > 0) {
      users = users.filter((value) => {
        const searchProduct = userSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (users.length > 0)
        setUserList((prevValue) => {
          return [...prevValue, ...users];
        });
    } else {
      setUserList(users);
    }
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        backgroundWhite={true}
        button={<GoBackButton onClick={() => navigate('/notification')} />}>
        <Card style={{ borderRadius: '10px' }}>
          <PageHeader
            title="Create Notification"
            style={{
              padding: '8px 0px'
            }}
          />
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            disabled={isloading}
            validateTrigger={'onChange'}
            onValuesChange={(_, allFields) => {
              //   setProductDetails(allFields);
            }}
            autoComplete="off">
            <div className="grid grid-cols-4 gap-3">
              <div className="col-span-3">
                <LocationSearch notAll={true} />
              </div>
              <Form.Item name="isActive" label="IsActive?">
                <Checkbox
                  onChange={(val) => {
                    form.setFieldValue(['isActive'], val.target.checked);
                  }}></Checkbox>
              </Form.Item>
            </div>
            <Form.List name={['events']}>
              {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
                <>
                  <div className={'grid mb-5'}>
                    <PageHeader
                      subTitle="Select Permissions"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                    <div
                      className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
                      style={{
                        borderRadius: '39px',
                        padding: '10px',
                        backgroundColor: 'rgb(59 130 246 / 0.5)'
                      }}>
                      <>
                        {Object.values(NotificationEvents).map((key, index) => (
                          <div style={{ display: 'flex', gap: '5px' }} key={key}>
                            <Form.Item>
                              <Checkbox
                                onChange={(value: any) => {
                                  const checkedValue = value.target.checked;
                                  if (checkedValue) {
                                    add2(`${key}`);
                                  } else {
                                    remove2(form.getFieldValue(['events']).indexOf(key));
                                  }
                                }}>
                                {key}
                              </Checkbox>
                            </Form.Item>
                          </div>
                        ))}
                      </>
                    </div>
                  </div>
                </>
              )}
            </Form.List>
            <Form.List name={['users']}>
              {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
                <>
                  <div className={'grid mb-5'}>
                    <PageHeader
                      subTitle="Add Users"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                    <Select
                      showSearch
                      placeholder={'search user'}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={(val) => {
                        setSearchValue(val);
                      }}
                      onSelect={(val: number) => {
                        // if (loggedInUser.id == val) {
                        //   message.error('Cannot add self, already added.');
                        //   return;
                        // }
                        const selectedUsers = form.getFieldValue(['users']);
                        // console.log('form values-->', form.getFieldsValue());
                        // console.log('selected Users-->', selectedUsers);
                        const exists = selectedUsers.find((value: any) => value == val);
                        if (exists) {
                          message.error('Already Added');
                          return;
                        }
                        add2(val);
                      }}
                      notFoundContent={null}>
                      {options}
                    </Select>
                    {fields2.length > 0 && (
                      <PageHeader
                        title="Selected Users"
                        style={{
                          padding: '8px 0px 8px 10px'
                        }}
                      />
                    )}
                  </div>
                  <div className="flex flex-wrap gap-3 mb-5">
                    {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                      <div
                        key={key2}
                        className="card"
                        style={{
                          borderRadius: '15px',
                          backgroundColor: '#e5f5fb',
                          padding: '0px 2px 0px 0px'
                        }}>
                        <div className="flex items-center justify-center gap-2" key={key2}>
                          <Form.Item {...restField2} name={[name2]} hidden>
                            <Input type={'text'} disabled />
                          </Form.Item>
                          {DisplayUserName(name2)}
                          {
                            <div className="flex justify-center items-center">
                              {fields2.length > 0 ? (
                                <CloseOutlined
                                  className="dynamic-delete-button flex justify-center items-center"
                                  style={{ color: '#0AA245' }}
                                  onClick={() => remove2(name2)}
                                  // disabled={name2 === loggedInUser.id}
                                  disabled={true}
                                />
                              ) : null}
                            </div>
                          }
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </Form.List>
            <div className="flex justify-end mt-5">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isloading}
                  style={{
                    borderRadius: '5px',
                    color: 'white',
                    backgroundColor: '#1890ff'
                  }}>
                  Add
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Card>
      </AppContent>
    </div>
  );
};

export default CreateNotification;
