function getSnakeCase(word: string) {
  const formattedWord = word
    .trim()
    .replace(/\s+/g, '')
    .replace(/([A-Z ])/g, (match) => `_${match.trim().toLowerCase()}`)
    .replace(/\s+/g, '_');

  const isFirstUnderScore = formattedWord[0] === '_';
  if (isFirstUnderScore) {
    return formattedWord.slice(1);
  }

  return formattedWord;
}

export default getSnakeCase;
