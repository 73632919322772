import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Form, TableProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { useNavigate, useParams } from 'react-router-dom';
import AppContent from '../../../components/Common/Content';
import { get_location_list } from '../../../services/locations/queries';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import moment from 'moment';

import { SorterResult } from 'antd/lib/table/interface';
import { convertLocalToUTCString } from '../../../utils/convertToUTC';
import {
  get_price_by_group,
  get_product_list_ids,
  get_units_list
} from '../../../services/products/queries';
import { IPriceGroupsView, IProductType } from '../../../services/products/types';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import UnitsDB from '../../../store/localstorage/UnitsDB';

const PriceGroupView: React.FC = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [allPriceGroups, setAllPriceGroups] = useState<IPriceGroupsView[]>([]);
  const pricegroupinfo = JSON.parse(localStorage.getItem('pricegroupinfo')!);

  useQuery(['price-group-view'], async () => {
    setIsloading(true);
    if (id) {
      const response = await get_price_by_group(id);
      console.log(response);
      const searchProducts: any = {};
      for (let index = 0; index < response.data.length; index++) {
        const product: any = await ProductsDB.getProduct(response.data[index].productId);
        if (!product) {
          if (response.data[index].productId in searchProducts) {
            searchProducts[response.data[index].productId] = [
              ...searchProducts[response.data[index].productId],
              index
            ];
          } else {
            searchProducts[response.data[index].productId] = [index];
          }
        } else {
          response.data[index].productName = product.name;
        }
        let findUnit: any = await UnitsDB.getUnit(response.data[index].unitId);
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(response.data[index].unitId);
        }
        response.data[index].shortName = findUnit.shortName;
      }
      const searchProductslength = Object.entries(searchProducts).length;
      if (searchProductslength > 0) {
        const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
        for (const key in searchProducts) {
          const findproduct = productsresponse?.data?.results.find(
            (currProduct: IProductType) => currProduct.id == key
          );
          for (let i = 0; i < searchProducts[key].length; i++) {
            response.data[searchProducts[key][i]].productName = findproduct?.name;
          }
          await ProductsDB.addProducts([findproduct]);
        }
      }
      setAllPriceGroups(response.data);
    }
    setIsloading(false);
  });
  // const [allSellList, setAllProducts] = useState({ total: 0, results: [] });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const columns: ColumnsType<IPriceGroupsView> = [
    {
      title: 'ID',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      width: 15,
      render: (record) => {
        return <div color="black">{record.id}</div>;
      }
    },
    // {
    //   title: 'Price Group',
    //   key: 'priceGroupId',
    //   sorter: (a, b) => a.priceGroupId - b.priceGroupId,
    //   sortOrder: sortedInfo.columnKey === 'priceGroupId' ? sortedInfo.order : null,
    //   width: 30,
    //   render: (record) => {
    //     return <div color="black">{record.priceGroupId}</div>;
    //   }
    // },
    {
      title: 'Product',
      key: 'productId',
      sorter: (a, b) => a.productId - b.productId,
      sortOrder: sortedInfo.columnKey === 'productId' ? sortedInfo.order : null,
      width: 50,
      render: (record) => {
        return <div color="black">{record.productName}</div>;
      }
    },
    {
      title: 'unit',
      key: 'unitId',
      sorter: (a, b) => a.unitId - b.unitId,
      sortOrder: sortedInfo.columnKey === 'unitId' ? sortedInfo.order : null,
      width: 20,
      render: (record) => {
        return <div color="black">{record.shortName}</div>;
      }
    },
    {
      title: 'Sell Price',
      key: 'sellingPrice',
      sorter: (a, b) => a.sellingPrice - b.sellingPrice,
      sortOrder: sortedInfo.columnKey === 'sellingPrice' ? sortedInfo.order : null,
      width: 25,
      render: (record) => {
        return <div color="black">{record.sellingPrice}</div>;
      }
    },
    {
      title: 'Cost Price',
      key: 'costPrice',
      sorter: (a, b) => a.costPrice - b.costPrice,
      sortOrder: sortedInfo.columnKey === 'costPrice' ? sortedInfo.order : null,
      width: 25,
      render: (record) => {
        return <div color="black">{record.costPrice}</div>;
      }
    },
    {
      title: 'Currency',
      width: 15,
      key: 'currency',
      sorter: (a, b) => a.currency.localeCompare(b.currency),
      sortOrder: sortedInfo.columnKey === 'currency' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <div color="black">{record.currency ? record.currency : 'NPR'}</div>
          // </Link>
        );
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: `Products Info of ${pricegroupinfo.name} group.`,
      link: '/price-groups'
    }
  ];

  return (
    <AppContent breadcrumbItems={breadcrumbItems} withfilter={true}>
      <CustomizeTable
        columns={columns}
        data={allPriceGroups}
        usersLoading={isLoading}
        notshowPagination={true}
        customScroll={{ x: 1000, y: '70vh' }}
        toSort={handleChange}
      />
    </AppContent>
  );
};

export default PriceGroupView;
