import { Form, message, Select, Spin, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import AppContent from '../../components/Common/Content/Content';
import { LocationSearch } from '../../components/Common/LocationSearch/LocationSearch';
import TableFilter from '../../components/FliterTable';
import useDebounce from '../../hooks/useDebounce';
import { get_location_list } from '../../services/locations/queries';
import { get_product_list_ids } from '../../services/products/queries';
import {
  get_purchase_order_form_filter_v2,
  get_sell_order_form_filter_v2
} from '../../services/purchases/queries';
import ProductsDB from '../../store/localstorage/ProductsDB';
import { SorterResult } from 'antd/lib/table/interface';
import { getUserData } from '../../utils/auth.utils';
import FullScreenModal from '../../components/Common/FullScreenModal';
import moment from 'moment';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

const EstimateVSDemandView = () => {
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState<string>('');
  const [data, setData] = useState<any>();
  const [selectedLocation, setselectedLocation] = useState<number>();
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchLocation(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const [locationSearch, setLocationSearch] = useState<any>([]);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [isloading, setIsloading] = useState<boolean>(true);
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const searchLocation = async (value: any) => {
    let response;
    try {
      response = await get_location_list(0, 5, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any location with that name!');
      setLocationSearch([]);
    } else {
      setLocationSearch(response.data.results);
    }
  };

  const options = locationSearch.map((value: any) => (
    <Select.Option key={value.id} value={value.id}>
      {value.name}
    </Select.Option>
  ));

  const onSearch = async (val: string) => {
    setIsloading(true);
    // let sellVal = val;
    // let purchaseVal = val;
    const response: any = await get_sell_order_form_filter_v2({
      locationId: form.getFieldValue('locationId'),
      startDate: moment(form.getFieldValue(['date']))
        .utc()
        .format(),
      endDate: moment(form.getFieldValue(['date']))
        .utc()
        .add(1, 'days')
        .format()
    });
    // sellVal += '&page=1&size=1000&identifier=SALE_ORDER&identifierTable=sell_order';
    const response2: any = await get_purchase_order_form_filter_v2({
      locationId: form.getFieldValue('locationId'),
      startDate: moment(form.getFieldValue(['date']))
        .utc()
        .format(),
      endDate: moment(form.getFieldValue(['date']))
        .utc()
        .add(1, 'days')
        .format()
    });
    // purchaseVal += '&page=1&size=1000&identifier=PURCHASE&identifierTable=purchases';
    const searchProducts: any = {};
    for (let index = 0; index < response.data.data.length; index++) {
      const product: any = await ProductsDB.getProduct(response.data.data[index].productId);
      if (!product) {
        if (response.data.data[index].productId in searchProducts) {
          searchProducts[response.data.data[index].productId] = [
            ...searchProducts[response.data.data[index].productId],
            index
          ];
        } else {
          searchProducts[response.data.data[index].productId] = [index];
        }
      } else {
        response.data.data[index].productName = product.name;
      }
      // console.log('response2.data.data', response2.data.data);
      if (response2.data.data.length > 0) {
        const find = response2.data.data.find(
          (val: any) => val.productId === response.data.data[index].productId
        );
        if (find) {
          response.data.data[index].purchaseDemand = find.qty;
          response2.data.data = response2.data.data.filter(
            (val: any) => val.productId != response.data.data[index].productId
          );
        } else {
          response.data.data[index].purchaseDemand = 0;
        }
      } else {
        response.data.data[index].purchaseDemand = 0;
      }
    }
    if (response2.data.data.length > 0) {
      for (let index = 0; index < response2.data.data.length; index++) {
        const product: any = await ProductsDB.getProduct(response2.data.data[index].productId);
        if (!product) {
          if (response2.data.data[index].productId in searchProducts) {
            searchProducts[response2.data.data[index].productId] = [
              ...searchProducts[response2.data.data[index].productId],
              response.length
            ];
          } else {
            searchProducts[response2.data.data[index].productId] = [response.length];
          }
        } else {
          response2.data.data[index].productName = product.name;
        }
        response.data.data.push({
          ...response2.data.data[index],
          purchaseDemand: response2.data.data[index].qty,
          qty: 0
        });
      }
    }
    const searchProductslength = Object.entries(searchProducts).length;
    if (searchProductslength > 0) {
      const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
      for (const key in searchProducts) {
        const findproduct = productsresponse?.data?.results.find(
          (currProduct: any) => currProduct.id == key
        );
        for (let i = 0; i < searchProducts[key].length; i++) {
          response.data.data[searchProducts[key][i]].productName = findproduct?.name;
        }
        await ProductsDB.addProducts([findproduct]);
      }
    }
    setIsloading(false);
    setData(response.data.data);
  };
  const breadcrumbItems = [
    {
      label: 'Purchase Demand Vs Estimated Stock'
    }
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      width: 50,
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (record: any) => {
        return <div color="black">{record.id}</div>;
      }
    },
    {
      title: 'Product Name(SKU)',
      key: 'productName',
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      sortOrder: sortedInfo.columnKey === 'productName' ? sortedInfo.order : null,
      width: 200,
      render: (record: any) => {
        return <div color="black">{record.productName}</div>;
      }
    },
    {
      title: 'Purchase Demand Vs Estimated Stock',
      width: 100,
      key: 'OrderedVsSold',
      render: (record: any) => {
        let percentSold = '';
        let percentOrdered = '';
        let overflow = false;
        if (record.purchaseDemand >= record.qty) {
          if (record.purchaseDemand === 0) percentOrdered = '0%';
          else percentOrdered = '100%';
          percentSold = `${(record.qty / record.purchaseDemand) * 100}%`;
          if (isNaN(record.qty / record.purchaseDemand)) percentSold = '0%';
        } else {
          overflow = true;
          if (record.qty === 0) percentSold = '0%';
          else percentSold = '100%';
          percentOrdered = `${(record.purchaseDemand / record.qty) * 100}%`;
          if (isNaN(record.qty / record.purchaseDemand)) percentOrdered = '0%';
        }
        // console.log('percentSold', percentSold);
        // console.log('percentOrdered', percentOrdered);
        return (
          <div color="black" style={{ width: '100%' }}>
            <div>Demand({record.purchaseDemand})</div>
            <div
              style={{
                height: '5px',
                backgroundColor: 'blue',
                width: percentOrdered,
                borderRadius: '4px'
              }}></div>
            <div>
              Stock({record.qty}){overflow ? ' overFlow' : ''}
            </div>
            <div
              style={{
                height: '5px',
                backgroundColor: overflow ? 'red' : 'green',
                width: percentSold,
                borderRadius: '4px'
              }}></div>
          </div>
        );
      }
    },
    {
      title: 'Total',
      width: 100,
      key: 'totalAmount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (record: any) => {
        return <div color="black">{record.totalAmount}</div>;
      }
    }
  ];

  return (
    <Spin spinning={isloading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              {locationSearch.length > 0 && (
                <TableFilter
                  defaultValues={{
                    // dateCustom: [moment(), moment().add(1, 'days')],
                    // locationId: locationSearch[0].id
                    locationId: preferenceLocationId ? preferenceLocationId : locationSearch[0].id,
                    date: moment().format('YYYY-MM-DD')
                  }}
                  dateCustom={false}
                  singleDate={true}
                  initial={true}
                  onSubmit={onSearch}
                  timeVal={true}
                  form={form}
                  style={
                    'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                  }
                  outsidestyle={'flex flex-wrap justify-end items-end gap-2'}>
                  {/* <Form.Item
                    name={['locationId']}
                    label="Location"
                    rules={[
                      {
                        required: true,
                        message: 'Please choose location!'
                      }
                    ]}>
                    <Select
                      showSearch
                      placeholder={'search location'}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSelect={(val: number) => setselectedLocation(val)}
                      dropdownMatchSelectWidth={false}
                      onSearch={(val) => {
                        setSearchValue(val);
                      }}
                      notFoundContent={null}>
                      {options}
                    </Select>
                  </Form.Item> */}
                  <LocationSearchV2 hasParentFormItem={false} name="locationId" />
                </TableFilter>
              )}
            </div>
            {/* </div> */}
          </>
        }>
        <div className="flex justify-end">
          <FullScreenModal>
            {/* <CustomizeTable
            columns={columns}
            data={data?.todaysOrders}
            usersLoading={isloading}
            notshowPagination={true}
            toSort={handleChange}
          /> */}
            <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 xl:grid-cols-8 gap-2 mt-2">
              {data &&
                data.map((record: any, ind: number) => {
                  let percentSold = '';
                  let percentOrdered = '';
                  let overflow = false;
                  if (record.purchaseDemand >= record.qty) {
                    if (record.purchaseDemand === 0) percentOrdered = '0%';
                    else percentOrdered = '100%';
                    percentSold = `${(record.qty / record.purchaseDemand) * 100}%`;
                    if (isNaN(record.qty / record.purchaseDemand)) percentSold = '0%';
                  } else {
                    overflow = true;
                    if (record.qty === 0) percentSold = '0%';
                    else percentSold = '100%';
                    percentOrdered = `${(record.purchaseDemand / record.qty) * 100}%`;
                    if (isNaN(record.qty / record.purchaseDemand)) percentOrdered = '0%';
                  }
                  return (
                    <div className="cardforestimatestock" key={ind}>
                      {/* <div>{record.id}</div> */}
                      <div className="font-bold">{record.productName}</div>
                      <div color="black" style={{ width: '100%' }}>
                        <div>Demand ({record.purchaseDemand})</div>
                        <div
                          style={{
                            height: '3px',
                            backgroundColor: 'blue',
                            width: percentOrdered,
                            borderRadius: '4px'
                          }}></div>
                        <div>
                          Stock ({record.qty}){overflow ? ' Overflow' : ''}
                        </div>
                        <div
                          style={{
                            height: '3px',
                            backgroundColor: overflow ? 'red' : 'green',
                            width: percentSold,
                            borderRadius: '4px'
                          }}></div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </FullScreenModal>
        </div>
        {/* <CustomizeTable
        columns={columns}
        data={data?.todaysOrders}
        usersLoading={isloading}
        notshowPagination={true}
        toshowcustomPagintion={true}
        onPagination={onPagination}
        paginationDatas={{
          page: page,
          total: allpurchaseList.total,
          size: size,
          onPagination
        }}
        toSort={handleChange} */}
        {/* /> */}
        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 xl:grid-cols-8 gap-2 mt-2">
          {data &&
            data.map((record: any, ind: number) => {
              let percentSold = '';
              let percentOrdered = '';
              let overflow = false;
              if (record.purchaseDemand >= record.qty) {
                if (record.purchaseDemand === 0) percentOrdered = '0%';
                else percentOrdered = '100%';
                percentSold = `${(record.qty / record.purchaseDemand) * 100}%`;
                if (isNaN(record.qty / record.purchaseDemand)) percentSold = '0%';
              } else {
                overflow = true;
                if (record.qty === 0) percentSold = '0%';
                else percentSold = '100%';
                percentOrdered = `${(record.purchaseDemand / record.qty) * 100}%`;
                if (isNaN(record.qty / record.purchaseDemand)) percentOrdered = '0%';
              }
              return (
                <div className="cardforestimatestock" key={ind}>
                  {/* <div>{record.id}</div> */}
                  <div className="font-bold">{record.productName}</div>
                  <div color="black" style={{ width: '100%' }}>
                    <div>Demand ({record.purchaseDemand})</div>
                    <div
                      style={{
                        height: '3px',
                        backgroundColor: 'blue',
                        width: percentOrdered,
                        borderRadius: '4px'
                      }}></div>
                    <div>
                      Stock ({record.qty}){overflow ? ' Overflow' : ''}
                    </div>
                    <div
                      style={{
                        height: '3px',
                        backgroundColor: overflow ? 'red' : 'green',
                        width: percentSold,
                        borderRadius: '4px'
                      }}></div>
                  </div>
                </div>
              );
            })}
        </div>
      </AppContent>
    </Spin>
  );
};

export default EstimateVSDemandView;
