import { convertLocalToUTCString } from './convertToUTC';

interface Parameters {
  formValues: any;
  page: number;
  size: number;
  isSize: boolean;
}
/**
  Handle Pagination. Return Values, Page and Size. If isSize is true, set size value to returned size value else no need to change size value.
 */
function handlePagination({ formValues, page, size, isSize }: Parameters) {
  // Make a deep copy of formValues
  const values = JSON.parse(JSON.stringify(formValues));

  if (values.endDate) values.endDate = convertLocalToUTCString(values.endDate);
  if (values.startDate) values.startDate = convertLocalToUTCString(values.startDate);

  delete values.dateCustom;
  delete values.startDateNepali;
  delete values.endDateNepali;

  values.skip = isSize ? 0 : (page - 1) * size;
  values.count = size;

  const newPage = isSize ? 1 : page;
  return { values, page: newPage, size };
}

export default handlePagination;
