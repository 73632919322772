export const storesToCreate = [
  { name: 'Locations', keyPath: 'id', index: 'LocationsSecondIndex', indexKey: 'lowercaseName' },
  { name: 'Products', keyPath: 'id', index: 'ProductsSecondIndex', indexKey: 'lowercaseName' },
  { name: 'Units', keyPath: 'id', index: 'UnitsSecondIndex', indexKey: 'lowercaseName' },
  { name: 'Category', keyPath: 'id', index: 'CategorySecondIndex', indexKey: 'lowercaseName' },
  {
    name: 'FinancialYears',
    keyPath: 'id',
    index: 'FinancialYearSecondIndex',
    indexKey: 'isCurrent'
  },
  { name: 'Vehicles', keyPath: 'id', index: 'VehiclesSecondIndex', indexKey: 'lowercaseName' },
  { name: 'Users', keyPath: 'id', index: 'UsersSecondIndex', indexKey: 'lowercaseName' },
  { name: 'Customers', keyPath: 'id', index: 'CustomersSecondIndex', indexKey: 'lowercaseName' },
  { name: 'Agents', keyPath: 'id', index: 'AgentsSecondIndex', indexKey: 'userId' },
  { name: 'Vendors', keyPath: 'id', index: 'VendorsSecondIndex', indexKey: 'lowercaseName' },
  { name: 'Routes', keyPath: 'id', index: 'RoutesSecondIndex', indexKey: 'lowercaseName' },
  { name: 'TotalLotsAvailable', keyPath: 'locationId' },
  {
    name: 'ExpenseCategory',
    keyPath: 'id',
    index: 'ExpenseCategorySecondIndex',
    indexKey: 'lowercaseName'
  },
  { name: 'Expenses', keyPath: 'id' },
  { name: 'AgentsBillRange', keyPath: 'id' },
  { name: 'Menu', keyPath: 'id' },
  { name: 'Reports', keyPath: 'id' },
  { name: 'Dashboard', keyPath: 'widgetId' },
  { name: 'Accounts', keyPath: 'id', index: 'AccountsSecondIndex', indexKey: 'lowercaseName' }
];
