import { useReactToPrint } from 'react-to-print';
import { IProductInvoicePrint } from '../../../../services/invoice/types';
import { useRef } from 'react';
import { getUserData } from '../../../../utils/auth.utils';
import { nepaliNumberFormatter } from '../../../../utils/numberFormatter';
import { Button } from 'antd';
import CustomTable from '../../CustomResuableInvoice/CustomTable';

const ProductPrint: React.FC<IProductInvoicePrint> = ({
  products,
  categoryName,
  handleModalClose
}) => {
  const printRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    async onAfterPrint() {
      if (handleModalClose) {
        handleModalClose();
      }
    }
  });

  const columns = [
    {
      label: 'CustomerName',
      dataIndex: 'name',
      render: (text: string) => <div className="ml-1">{text}</div>
    },
    {
      label: 'Quantity',
      dataIndex: 'quantity',
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: 'Unit',
      dataIndex: 'unit',
      render: (text: string) => <div className="text-center">{text}</div>
    },
    {
      label: 'Route',
      dataIndex: 'routeName',
      render: (text: string) => <div className="text-center">{text}</div>
    },
    {
      label: 'Amount',
      dataIndex: 'totalAmount',
      render: (text: number) => <div className="ml-1">{nepaliNumberFormatter(text)}</div>
    }
  ];

  return (
    <>
      <div className="flex justify-end mb-3">
        <Button type="primary" onClick={handlePrint}>
          Print
        </Button>
      </div>

      <div ref={printRef}>
        <div className="mx-5 my-3">
          {categoryName !== '' ? (
            <p className="text-center">Product Category : {categoryName}</p>
          ) : (
            ''
          )}
          {products.map((curProduct: any) => {
            return (
              <>
                <div key={curProduct.name} className="p-5">
                  <div className="font-bold text-sm">{curProduct.productName}</div>
                  <CustomTable
                    columns={columns}
                    datas={[
                      ...curProduct.customers,
                      {
                        name: 'Total',
                        quantity: `${curProduct.totalUnit} ${curProduct.shortName}`,
                        unit: '',
                        totalAmount: curProduct.totalAmount
                      }
                    ]}
                  />
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ProductPrint;
