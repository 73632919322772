import { useState } from 'react';
import { EditFilled } from '@ant-design/icons';
import { Input, Table, Tooltip, Menu, Form, TableProps, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { get_users_list_v2 } from '@/services/users/queries';
import { SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { ConvertObjectToURL } from '@/utils/converturl';
import TableFilter from '@/components/FliterTable';
import AppContent from '@/components/Common/Content/Content';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { IUserData } from '@/services/users/types';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const HRList = () => {
  const [allList, setAlllist] = useState<IUserData>({ total: 0, results: [] });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [isloading, setIsloading] = useState(true);
  const [form] = Form.useForm();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const columns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    // Table.EXPAND_COLUMN,
    {
      title: 'S.N',
      key: 'SN',
      width: 2,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return (
          <TableCell className="text-center text-xs">{(page - 1) * size + (index + 1)}</TableCell>
        );
      }
    },
    {
      title: 'Name',
      width: 10,
      key: 'name',
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            <TableCell>{record.name}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Email',
      key: 'email',
      width: 10,
      sorter: (a, b) => {
        return a.email.localeCompare(b.email);
      },
      sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            <TableCell>{record.email}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Phone',
      key: 'phone',
      width: 10,
      sorter: (a, b) => {
        return a.phone.localeCompare(b.phone);
      },
      sortOrder: sortedInfo.columnKey === 'phone' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            <TableCell>{record.phone}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Created At',
      key: 'createdAt',
      width: 10,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`${breadcrumbItems[0].link}/${getId(record)}`} color="black">
          <TableCell>
            {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 3,
      fixed: 'right',
      render: (record) => {
        const menuItems = [
          {
            key: '10',
            label: (
              <Tooltip title="Edit HR" color="blue">
                <Link to={`/hr/${record.id}`}>
                  <div className="flex justify-between gap-3">
                    <EditFilled
                      style={{
                        transform: 'scale(1.1)',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    />
                    <div>HR</div>
                  </div>
                </Link>
              </Tooltip>
            )
          },
          {
            key: '7',
            label: (
              <DiscussionChatModal slug="hr" rreference="other" id={record.id} fromUser={true} />
            )
          }
        ];
        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const onSubmitFilterr = async (val: string) => {
    setIsloading(true);
    const response = await get_users_list_v2(val);
    setAlllist(response?.data);
    setIsloading(false);
    //pagination Data
    setPage(1);
    setSize(100);
  };

  // console.log('Delete Mutations----->', deleteMutation);
  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    // console.log('Url', url);
    const response = await get_users_list_v2(url);
    setAlllist(response?.data);
    setIsloading(false);
  };

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'S.N',
          dataIndex: 'SN',
          width: 100
        },
        {
          title: 'Name',
          width: 250,
          dataIndex: 'name'
        },
        {
          title: 'Email',
          width: 250,
          dataIndex: 'email'
        },
        {
          title: 'Phone',
          width: 200,
          dataIndex: 'phone'
        },
        {
          title: 'Created At',
          width: 200,
          dataIndex: 'date'
        }
      ];
      if (allList.results.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = allList.results.map((item: any, index: number) => {
        return {
          ...item,
          SN: index + 1,
          date: convertUTCStringtoLocalString(item.createdAt, DEFAULT_DATE_FORMAT)
        };
      });
      exportExcel(columns, dataUpdated, 'HR List');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        backgroundWhite={false}
        withfilter={true}
        button={
          <>
            <div>
              <TableFilter
                defaultValues={{
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  skip: 0,
                  count: 100,
                  value: ''
                }}
                form={form}
                initial={true}
                onSubmit={onSubmitFilterr}
                // outsidestyle={'md:flex flex-wrap items-end gap-2'}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2 items-center justify-center'
                }
                styleforbuttons={'flex justify-end items-center'}
                buttons={
                  <>
                    <CustomButton text="Export" backgroundColor="#1890ff" onClick={handleExport} />
                  </>
                }>
                <Form.Item label="Search" name="value">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          data={allList.results}
          usersLoading={isloading}
          columns={columns}
          notshowPagination={true}
          customScroll={{ x: 600, y: '75vh' }}
          // toshowcustomPagintion={true}
          // onPagination={onPagination}
          paginationDatas={{
            page: page,
            total: allList.total,
            size: size,
            onPagination
          }}
          tableName={'hr-list'}
          toSort={handleChange}
        />
      </AppContent>
    </div>
  );
};
const breadcrumbItems = [
  {
    label: 'HR',
    link: '/hr'
  }
];

export default HRList;
