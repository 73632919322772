import { DEFAULT_DATE_FORMAT } from '@/constants';
import { IVehicleSumDistanceReport } from '@/services/report/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

export const tableExportColumns = [
  {
    label: 'ID',
    dataIndex: 'vehicle_id',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Name',
    dataIndex: 'name',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'IMEI',
    dataIndex: 'imei',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Number',
    dataIndex: 'number',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Total Distance',
    dataIndex: 'sum_distance',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((field) => ({
  title: field.label,
  dataIndex: field.dataIndex
}));

export function getUpdatedData(data: IVehicleSumDistanceReport[]) {
  return data.map((item) => {
    return {
      ...item,
      sum_distance: nepaliNumberFormatter(parseFloat(item.sum_distance))
    };
  });
}
