import { httpBase } from './httpbase.utils';
import DOMPurify from 'dompurify';

function get(endpoint: string, params?: unknown) {
  if (sanitizeData(params)) {
    return httpBase().get(`${endpoint}`, { params });
  } else {
    throw { response: { data: { message: 'Sanitize Failed' } } };
  }
}

function store(endpoint: string, data?: unknown) {
  if (sanitizeData(data)) {
    return httpBase().post(`${endpoint}`, data);
  } else {
    throw { response: { data: { message: 'Sanitize Failed' } } };
  }
}

function update(endpoint: string, data?: unknown, bypassSanitize?: boolean) {
  if (sanitizeData(data) || bypassSanitize) {
    return httpBase().patch(`${endpoint}`, data);
  } else {
    throw { response: { data: { message: 'Sanitize Failed' } } };
  }
}

function updateWithFile(endpoint: string, data?: unknown) {
  if (sanitizeData(data)) {
    return httpBase(true).patch(`${endpoint}`, data);
  } else {
    throw { response: { data: { message: 'Sanitize Failed' } } };
  }
}

function saveWithFile(endpoint: string, data?: unknown) {
  if (sanitizeData(data)) {
    return httpBase(true).post(`${endpoint}`, data);
  } else {
    throw { response: { data: { message: 'Sanitize Failed' } } };
  }
}

function remove(endpoint: string) {
  return httpBase(true).delete(`${endpoint}`);
}

const httpUtils = {
  get,
  store,
  update,
  updateWithFile,
  saveWithFile,
  remove
};

const sanitizeData = (data?: any) => {
  if (data) {
    const jsonString = JSON.stringify(data);
    const sanitizedData = DOMPurify.sanitize(jsonString);
    if (sanitizedData === jsonString) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export default httpUtils;
