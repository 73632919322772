import React, { useContext, useState } from 'react';
import { CustomModal } from '../../../components/Common/CustomModal';
import ProductRoutePrint from '../../../components/Common/InvoicePrint/ProductRoutePrint/ProductRoutePrint';
import { ISellInvoice, ISellRecordData } from '../../../services/invoice/types';
import { SellListContext } from './context';
import { ICreateInvoiceResponse } from '../../../services/settings/types';
import { getSellPrintData } from '../../../components/Common/InvoicePrint/SellInvoice/services';
import SellInvoice from '../../../components/Common/InvoicePrint/SellInvoice/SellInvoice';
import InvoicePrintButton from '../../../components/Common/InvoicePrintButton/InvoicePrintButton';
import { ISellFromServer } from '../../../services/sell/types';
import { get_sell_return_lines_details } from '@/services/sell/queries';
import getGroupedData from '@/utils/getGroupedData';

export const SellInvoicePrintModal: React.FC<{
  record: ISellFromServer;
  getInfo: (url: string) => void;
}> = ({ record, getInfo }) => {
  const { setIsLoading, form, urlData } = useContext(SellListContext);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<ISellInvoice>(Object);

  const generateInvoiceForPrint = async (record: ISellRecordData, curr: ICreateInvoiceResponse) => {
    setIsLoading(true);
    const invoiceModalData = await getSellPrintData(
      record.id,
      record.locationId,
      record.sellOrderId
    );

    const sellDetails = invoiceModalData.sellDetails as any;
    const sellReturnData = sellDetails?.returns || [];
    const returnListResponse = await Promise.all(
      sellReturnData.map(async (value: any) => await get_sell_return_lines_details(value.id))
    );

    const returnList = returnListResponse.map((response) => response.data).flat();
    const returnGroupByProduct = getGroupedData(returnList, 'productId');

    // Calculate total quantity return for each product
    const productWihReturnQuantity = Object.keys(returnGroupByProduct).map((key) => {
      const data = returnGroupByProduct[key];
      return {
        productId: parseInt(key),
        quantity: data.reduce((a, b) => a + b.quantity, 0)
      };
    });

    const sellLines = invoiceModalData?.lines || [];

    // Is All Sell Lines Returned
    const isAllSellLinesReturned = sellLines.every((line) => {
      const returnQuantity = productWihReturnQuantity.find(
        (product: any) => product.productId === line.productId
      );

      if (!returnQuantity) return false;
      return returnQuantity.quantity === line.quantity;
    });

    setIsLoading(false);
    setInvoiceData({ ...invoiceModalData, invoiceLayouts: curr, isAllSellLinesReturned });
    setOpenModalForInvoicePrint(true);
  };

  const handleInvoicePrintModalClose = () => {
    setOpenModalForInvoicePrint(false);
    getInfo(urlData);
  };
  return (
    <>
      <InvoicePrintButton
        getInvoice={(curr) => {
          generateInvoiceForPrint(record, curr);
        }}
      />
      <CustomModal
        footer={false}
        isModalOpen={openModalForInvoicePrint}
        setIsModalOpen={setOpenModalForInvoicePrint}
        title="Sell Invoice Print">
        <SellInvoice
          sellDetails={invoiceData.sellDetails}
          isAllSellLinesReturned={invoiceData.isAllSellLinesReturned}
          customerDetails={invoiceData.customerDetails}
          lines={invoiceData.lines}
          invoiceLayouts={invoiceData.invoiceLayouts}
          handleModalClose={handleInvoicePrintModalClose}
        />
      </CustomModal>
    </>
  );
};

export default ProductRoutePrint;
