import { Checkbox, Form, Input, PageHeader } from 'antd';
import React from 'react';
import RichTextEditor from '../../../../components/RichTextEditor';

const Footer = ({ form, printingType }: { form: any; printingType?: string }) => {
  const handleRichTextEditorChange = (field: string, value: string) => {
    form.setFieldValue(field, value);
  };

  return (
    <div>
      <PageHeader
        title="Footer Details"
        style={{
          padding: '8px 0px',
          fontSize: '14px !important'
        }}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3">
        {printingType == 'classic' && (
          <Form.Item valuePropName="checked" name="showBarcode">
            <Checkbox className="w-100">Show Barcode</Checkbox>
          </Form.Item>
        )}
        <Form.Item label="Footer Text" name="footerText">
          {/* <RichTextEditor
          value={form.getFieldValue('footerText') ?? ''}
          fieldName="footerText"
          fn={handleRichTextEditorChange}
        /> */}
          <Input />
        </Form.Item>

        <Form.Item label="Received By Label" name="receivedByLabel">
          <Input />
        </Form.Item>
        <Form.Item label="Invoice By Label" name="invoiceByLabel">
          <Input />
        </Form.Item>
        <Form.Item label="Note Label" name="noteLabel">
          <Input />
        </Form.Item>
      </div>
      {/* <Form.Item label="Set as default" name="setAsDefault">
        <Checkbox className="w-100">Set as default</Checkbox>
      </Form.Item> */}
    </div>
  );
};

export default Footer;
