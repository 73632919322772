import { useQuery } from '@tanstack/react-query';
import { fetchMenuItems } from '@/layouts/main.layout';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import FinancialYear from '@/components/Common/FinancialYear';
import { useLocation } from 'react-router-dom';
import { accountParentMenu } from '@/constants/account.menu';
import { Menu } from 'antd';

async function filteredAccountMenu() {
  const menus = await fetchMenuItems();
  const data = menus.find((menu) => menu.key === 'accounts')?.children ?? [];
  return data as ItemType[];
}

interface Props {
  setShowDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
}

function AccountMenu({ setShowDrawer }: Props) {
  const { data: accountMenu } = useQuery(['accountMenu'], filteredAccountMenu);
  const { pathname } = useLocation();

  return (
    <>
      {accountParentMenu.includes(pathname) && (
        <div className="mb-4 px-4">
          <FinancialYear />
        </div>
      )}
      <Menu mode="vertical" items={accountMenu} onClick={() => setShowDrawer?.(false)} />
    </>
  );
}

export default AccountMenu;
