import { Button, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

interface ButtonProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  text: string;
  marginTop?: string;
  backgroundColor?: string;
  Linkto?: string;
  marginLeft?: string;
  marginBottom?: string;
  textColor?: string;
  borderColor?: string;
}

const CustomButton = ({
  onClick,
  text,
  backgroundColor,
  marginTop,
  Linkto,
  marginLeft,
  textColor,
  marginBottom,
  borderColor
}: ButtonProps) => {
  return (
    <>
      {Linkto ? (
        <>
          <Link to={Linkto}>
            {/* <Tooltip title={text} color="green"> */}
            <Button
              type="default"
              // className="ml-5"
              // onClick={onClick}
              style={{
                borderRadius: '5px',
                color: textColor ? textColor : 'white',
                border: backgroundColor === 'white' ? `2px solid ${borderColor || '#0DA347'}` : '',
                backgroundColor: backgroundColor ? backgroundColor : '',
                marginTop: marginTop ? marginTop : '',
                marginBottom: marginBottom || '',
                marginLeft: marginLeft ? marginLeft : '10px'
              }}>
              {text}
            </Button>
            {/* </Tooltip> */}
          </Link>
        </>
      ) : (
        <>
          {/* <Tooltip title={text} color="green"> */}
          <Button
            type="default"
            // className="ml-5"
            onClick={onClick}
            style={{
              borderRadius: '5px',
              color: textColor ? textColor : 'white',
              // border: '2px solid #0DA347',
              border: backgroundColor === 'white' ? `2px solid ${borderColor || '#0DA347'}` : '',
              backgroundColor: backgroundColor ? backgroundColor : '',
              marginTop: marginTop ? marginTop : '',
              marginBottom: marginBottom || '',
              marginLeft: marginLeft ? marginLeft : '10px'
            }}>
            {text}
          </Button>
          {/* </Tooltip> */}
        </>
      )}
    </>
  );
};

export default CustomButton;
