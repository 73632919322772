import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IUserLive, IUserLiveCreate, IUserLiveData } from './types';
export const get_users_track_by_id = async (id: number) => {
  const response: AxiosResponse<IUserLiveData, any> = await http.get('track/' + id);
  return response;
};

export const create_users_track = async (values: IUserLiveCreate) => {
  const response: AxiosResponse<IUserLive, any> = await http.store('track', values);
  return response;
};

export const get_user_track = async (filter = '') => {
  const response: AxiosResponse<IUserLiveData, any> = await http.get('track/?' + filter);
  return response;
};
