import React, { useEffect, useState, PropsWithChildren } from 'react';

// Antd imports
import Table, { ColumnsType } from 'antd/lib/table';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';

import {
  Alert,
  Button,
  Collapse,
  Form,
  FormInstance,
  PageHeader,
  Pagination,
  Spin,
  message
} from 'antd';

import { check_date_cap, check_export_report_date_cap } from '@/utils/common';
import AppContent from '@/components/Common/Content/Content';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { ICustomerHistoryDataType } from '@/services/report/types';
import { downloadRouteReport, getRouteReport } from '@/services/report/queries';
import { showReportConditionalDownloadMessage } from '../../../../utils/reportConditionalDownloadMessage';
import { excelExportColumns, getUpdatedData, tableExportColumns } from './column.export';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportCurrent from '@/components/Common/ExportCurrent';

interface Props {
  state: { id: number; name: string };
  breadcrumbItems: { label: string; link: string }[];
  form: FormInstance<any>;
  columnsData?: ColumnsType<ICustomerHistoryDataType>;
  toSort: any;
}

function CustomReport({ columnsData, form, ...props }: PropsWithChildren<Props>) {
  const [size, setSize] = useState(100);
  const [page, setPage] = useState<number>(1);
  const [isloading, setIsloading] = useState(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const [data, setData] = useState<ICustomerHistoryDataType[]>([]);

  const [footer, setFooter] = useState({
    totalCustomer: 0,
    totalAmount: 0,
    totalQuantity: 0,
    quantityAvg: 0
  });

  useEffect(() => {
    if (columnsData) return;

    // Auto generate columns
    const newColumns: ColumnsType = [];
    for (const key in data[0]) {
      newColumns.push({
        title: key,
        dataIndex: key,
        key,
        render: (record) => <div className="forless">{record[key]}</div>
      });
    }

    setColumns(newColumns);
  }, [data]);

  async function onSearch(values: any, storePage = false) {
    setIsloading(true);

    try {
      const hasDateCap = check_date_cap(values.constraints.startDate, values.constraints.endDate);

      if (hasDateCap) {
        await downloadPdf();
        return;
      }

      if (!storePage) {
        values.constraints['page'] = 1;
        values.constraints['size'] = size;
        setPage(1);
      }

      const constraints = {
        page: values.constraints.page,
        size: values.constraints.size,
        routeId: values.constraints.routeId,
        startDate: convertLocalToUTCString(values.constraints.startDate),
        endDate: convertLocalToUTCString(values.constraints.endDate)
      };

      const routeReportData = await getRouteReport(constraints);
      setData(routeReportData);
      console.log('data', routeReportData);
      if (routeReportData.length === 0) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }

      // Calculate Total and Avg
      const total = { totalCustomer: 0, totalAmount: 0, totalQuantity: 0, quantityAvg: 0 };

      routeReportData.forEach((item) => {
        total.totalCustomer += item.totalCustomers;
        total.totalAmount += item.totalAmount;
        total.totalQuantity += item.totalQuantity;
        total.quantityAvg += item.quantityAvg;
      });

      setFooter(total);
    } catch (error) {
      console.log('Error getting report', error);
    } finally {
      setIsloading(false);
    }
  }

  async function addPageAndSize(value: number, sizeVal = 0) {
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();

      if (sizeVal !== 0) {
        allValues.constraints.page = 1;
        allValues.constraints.size = sizeVal;
        setPage(1);
        setSize(size);
      } else {
        allValues.constraints.page = value;
        allValues.constraints.size = size;
        setPage(value);
      }

      onSearch(allValues, true);
    } catch (error) {
      console.log('validate error', error);
    }
  }

  async function downloadPdf() {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsloading(true);

    try {
      const values = form.getFieldsValue();
      await form.validateFields();

      const constraints = {
        page: values.constraints.page,
        size: values.constraints.size,
        routeId: values.constraints.routeId,
        startDate: convertLocalToUTCString(values.constraints.startDate),
        endDate: convertLocalToUTCString(values.constraints.endDate)
      };

      const hasExportReportCap = check_export_report_date_cap(
        constraints.startDate,
        constraints.endDate
      );

      if (hasExportReportCap) {
        const hideMessageHandler = message.info(
          'Report is being generated. Download will start soon.'
        );

        const response = await downloadRouteReport({
          ...constraints,
          customReportQueueName: 'Route History Report'
        });

        window.open(response.url, '_blank');
        hideMessageHandler();
      }
    } catch (error) {
      message.error('Error generating report. Please try again.');
    } finally {
      setIsloading(false);
    }
  }

  const updatedData = getUpdatedData(data);

  return (
    <Spin spinning={isloading}>
      <AppContent breadcrumbItems={props.breadcrumbItems}>
        <Collapse>
          <CollapsePanel header="Filters" key="1">
            <Form
              layout="vertical"
              form={form}
              autoComplete="off"
              disabled={isloading}
              validateTrigger="onChange"
              onFinish={onSearch}>
              {React.Children.count(props.children) > 1 && (
                <PageHeader
                  subTitle="Constraints"
                  style={{
                    padding: '0px 0px',
                    margin: '0px'
                  }}
                />
              )}

              <div className="grid grid-cols-4 gap-5 ">
                {props.children}
                <div className="flex justify-start mt-7 gap-1">
                  {/* Search Button  */}
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
                      Search
                    </Button>
                  </Form.Item>

                  {/* Download Button  */}
                  <Form.Item>
                    <Button
                      className="secondary-button"
                      onClick={downloadPdf}
                      style={{ borderRadius: '6px' }}>
                      Download
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </CollapsePanel>
        </Collapse>

        <div className="mt-2">
          <Alert
            message="Information!"
            description="Please note, the data is calculated at 00:00 every day. Today's data might not be visible."
            type="info"
            closable
            showIcon
          />
        </div>

        <PageHeader subTitle="Table" style={{ padding: '8px 0px' }} />

        <CustomizeTable
          tableName="route-history-report-table"
          notshowPagination
          customScroll={{ x: 1000, y: 500 }}
          toSort={props.toSort}
          data={data}
          columns={columnsData || columns}
          footer={() => `Unique Active Customers: ${footer.totalCustomer}`}
          tableSummary={
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={2}>{footer.totalCustomer}</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  {nepaliNumberFormatter(footer.totalAmount)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  {nepaliNumberFormatter(footer.totalQuantity)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  {nepaliNumberFormatter(footer.quantityAvg)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          }
          buttons={
            <ExportCurrent
              data={{ total: data.length, results: updatedData }}
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              title="Route History Report"
            />
          }
        />
      </AppContent>
    </Spin>
  );
}

export default CustomReport;
