import { Modal } from 'antd';

interface NotifySellPriceProps {
  title: React.ReactNode;
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  data?: string[];
}

const NotifySellPriceModal: React.FC<NotifySellPriceProps> = ({
  title,
  visible,
  onOk,
  onCancel,
  data
}) => {
  return (
    <Modal
      title={<span className="font-semibold">{title}</span>}
      visible={visible}
      okText="Confirm"
      cancelText="Back"
      onOk={async () => {
        onOk();
      }}
      onCancel={onCancel}>
      {data?.map((message, index) => (
        <div key={index}>{message}</div>
      ))}
    </Modal>
  );
};

export default NotifySellPriceModal;
