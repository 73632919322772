import { ColumnsType } from 'antd/lib/table';
import React, { useEffect } from 'react';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
interface SellReturnViewProps {
  data?: any;
}
const SellReturnView: React.FC<SellReturnViewProps> = ({ data = [] }) => {
  const columns: ColumnsType<{ name: string }> = [
    {
      title: 'ID',
      key: 'id',
      render: (record) => {
        return <div color="black">{record.id}</div>;
      }
    },
    {
      title: 'Total Amount Returned',
      key: 'totalAmount',
      render: (record) => {
        return <div color="black">{record.totalAmount}</div>;
      }
    },
    {
      title: 'Tax',
      key: 'totalTax',
      render: (record) => {
        return <div color="black">{record.totalTax}</div>;
      }
    },
    {
      title: 'Date',
      key: 'date',
      render: (record) => {
        return <div color="black">{record.date}</div>;
      }
    }
  ];
  useEffect(() => {
    // console.log(data);
    // if (data.length > 0) {
    //   getLinesData();
    // }
  }, [data]);

  //   const getLinesData = async () => {
  //     for (let i = 0; i < data.length; i++) {
  //       const response = await get_sell_return_details(data[0].id);
  //       console.log(response);
  //     }
  //   };

  return (
    <>
      <CustomizeTable columns={columns} data={data} />
    </>
  );
};

export default SellReturnView;
