import React, { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import {
  get_product_details,
  get_product_list,
  get_product_list_ids,
  get_units_list
} from '../../../services/products/queries';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import CustomTable from '../CustomResuableInvoice/CustomTable';
import { getUserData } from '../../../utils/auth.utils';
import { IProductType, IUnits } from '../../../services/products/types';
import { ISellRoutePrintForInvoice } from '../../../services/sell/types';

export const InvoiceForProductAndRoute = () => {
  const state = JSON.parse(localStorage.getItem('sellandroute') as string);
  const loggedInUser = getUserData();
  //console.log('State-->', state);
  const { result, routeInformation, fromLocation } = state;
  console.log('Result-->', result);
  const [productstable, setProductstable] = useState<any>();
  const [totalInfo, setTotalInfo] = useState<any>([]);
  const [loading, setIsloading] = useState(false);
  useEffect(() => {
    // window.print();
    if (fromLocation) {
      addQuantity();
    } else {
      addQuantityRoute();
    }
  }, []);

  useEffect(() => {
    if (productstable) {
      // window.print();
      // window.onafterprint = (event) => {
      //   window.close();
      //   localStorage.removeItem('sellandroute');
      // };
    }
  }, [productstable]);

  const addQuantityRoute = async () => {
    // console.log('this is called');
    //const unitIdMap: any = {};
    // const unitQuanityMap: any = {};
    // for (let index = 0; index < units.length; index++) {
    //   unitIdMap[units[index].productId] = units[index].unitId;
    //   if (units[index].unitId in unitQuanityMap) {
    //     unitQuanityMap[units[index].unitId] += productQuantityMap[units[index].productId];
    //   } else {
    //     unitQuanityMap[units[index].unitId] = productQuantityMap[units[index].productId];
    //   }
    // }
    // console.log('unitId Map', unitIdMap);
    // console.log('unitQuantity map', unitQuanityMap);
    const totalInfo: any[] = [];
    const totalProduct: ISellRoutePrintForInvoice[] = [];
    for (let index = 0; index < result.length; index++) {
      let findProduct: string | IProductType = await ProductsDB.getProduct(result[index].productId);
      if (!findProduct) {
        const allProducts = await get_product_list();
        await ProductsDB.addProducts(allProducts.data.results);
        findProduct = await ProductsDB.getProduct(result[index].productId);
      }
      if (typeof findProduct === 'object') {
        result[index].productName = findProduct.name;
      }

      let findUnit: string | IUnits = await UnitsDB.getUnit(result[index].unitId);
      if (!findUnit) {
        const allUnits = await get_units_list();
        await UnitsDB.addUnits(allUnits);
        findUnit = await UnitsDB.getUnit(result[index].unitId);
      }
      if (typeof findUnit === 'object') {
        result[index].unitName = `${findUnit.shortName}`;
        const indexTotal = totalInfo.findIndex((total) => total.unitId === result[index].unitId);
        if (indexTotal > -1) {
          totalInfo[indexTotal].quantity += result[index].quantity;
        } else {
          totalInfo.push({
            unitId: result[index].unitId,
            unitName: `${findUnit.name} (${findUnit.shortName})`,
            quantity: result[index].quantity
          });
        }

        const indexProduct = totalProduct.findIndex(
          (productList) =>
            productList.productId === result[index].productId &&
            productList.unitId === result[index].unitId
        );
        if (indexProduct > -1) {
          totalProduct[indexProduct].quantity += result[index].quantity;
        } else {
          totalProduct.push(result[index]);
        }
      }
    }
    totalProduct.map(
      (product) => (product.unitName = `${product.quantity.toFixed(2)} ${product.unitName}`)
    );
    //console.log('totalProduct', totalProduct);
    setTotalInfo(totalInfo);
    setProductstable(totalProduct);
    // localStorage.removeItem('sellandroute');
  };

  const addQuantity = async () => {
    const productSearch: any = {};
    // console.log('Products-->', products);
    for (let i = 0; i < result.length; i++) {
      // products[i].quantity = productQuantityMap[products[i].id];
      const product: string | IProductType = await ProductsDB.getProduct(result[i].productId);
      if (!product) {
        // product = await get_product_details(products[i].id);
        // productSearch[products[i].id] = i;
        if (result[i].productId in productSearch) {
          productSearch[result[i].productId] = [...productSearch[result[i].productId], i];
        } else {
          productSearch[result[i].productId] = [i];
        }
        // await ProductsDB.addProducts([product]);
      } else {
        // console.log('Product', product);
        const defaultUnitId = result[i].unitId;
        // console.log('Default Unit-->', defaultUnit);
        let findUnit: string | IUnits = await UnitsDB.getUnit(defaultUnitId);
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(defaultUnitId.unitId);
        }
        // console.log('findUnit', findUnit);
        if (typeof findUnit === 'object' && typeof product === 'object') {
          result[i].unitName = `${result[i].quantity} ${findUnit.shortName}`;
          result[i].productName = product.name;
        }
      }
      // console.log('Product-->', product);
    }

    const productSearchlength = Object.entries(productSearch).length;
    if (productSearchlength > 0) {
      const response: any = await get_product_list_ids([...Object.keys(productSearch)]);
      // console.log('Response', response);
      for (const key in productSearch) {
        const findproduct = response?.data?.results.find(
          (currProduct: any) => currProduct.id == key
        );
        // console.log('findproduct-->', findproduct);
        const defaultUnit = findproduct.productUnits.find((curr: any) => curr.isDefault === true);
        // console.log('Default Unit-->', defaultUnit);
        let findUnit: any = await UnitsDB.getUnit(defaultUnit.unitId);
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(defaultUnit.unitId);
        }
        for (let index = 0; index < productSearch[key].length; index++) {
          result[productSearch[key][index]].productName = findproduct.name;
        }
        await ProductsDB.addProducts([findproduct]);
      }
    }
    // console.log('Products from addQuantity', products);
    setIsloading(false);
    setProductstable(result);
    // window.print();

    // window.print();
    // window.onafterprint = (event) => {
    //   window.close();
    //   localStorage.removeItem('sellandroute');
    // };

    // localStorage.removeItem('sellandroute');
  };

  // console.log('productsss', products);
  // console.log('products Table', productstable);

  console.log('Products', productstable);

  const columns: any[] = [
    {
      label: 'Product Name',
      dataIndex: 'productName',
      render: (text: string) => {
        return (
          <div color="black" className="ml-2">
            {text}
          </div>
        );
      }
    },
    {
      label: 'Total Quantity',
      dataIndex: 'unitName',
      render: (text: string) => {
        // console.log('record-->', record);
        return <div className="text-center">{text}</div>;
      }
    }
  ];

  const columnss: any[] = [
    {
      label: 'Unit',
      dataIndex: 'unitName',
      render: (text: string) => {
        return (
          <div color="black" className="ml-2">
            {text}
          </div>
        );
      }
    },
    {
      label: 'Total',
      dataIndex: 'quantity',
      render: (text: string) => {
        // console.log('record-->', record);
        return <div className="text-center">{parseFloat(text).toFixed(2)}</div>;
      }
    }
  ];

  return (
    <div>
      <p className="text-center">
        Product List of {fromLocation ? 'Location ' : 'Route'}{' '}
        {routeInformation?.name ? routeInformation?.name : '(All)'}
        {routeInformation?.locationName ? `(${routeInformation?.locationName})` : ''}
      </p>
      <h4 className="text-center">Products Sold</h4>
      <CustomTable columns={columns} datas={productstable ? productstable : []} />
      {!fromLocation && (
        <div className="mt-5">
          <h4 className="text-center">Total Per Unit</h4>
          <CustomTable columns={columnss} datas={totalInfo} />
        </div>
      )}
      {/* <Table
        size="small"
        columns={columns}
        loading={loading}
        dataSource={productstable}
        bordered
        pagination={false}
        key={'id'}
  />*/}
      <div className="flex items-end">
        <div>
          Printed By:{' '}
          <span className="capitalize">{loggedInUser?.name ? loggedInUser.name : ''}</span>
        </div>
      </div>
    </div>
  );
};

// export default InvoiceForProductAndRoute;
