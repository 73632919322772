import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IFonepayCredentialsResponse } from './types';

export const get_fonepay_credentials_list_filter = async (filter = '') => {
  const response: AxiosResponse<IFonepayCredentialsResponse[], any> = await http.get(
    'payment/fonepay-credentials?' + filter
  );
  return response.data;
};

export const get_fonepay_credential_details = async (id: number) => {
  const response: AxiosResponse<IFonepayCredentialsResponse, any> = await http.get(
    'payment/fonepay-credentials/' + id
  );
  return response.data;
};
