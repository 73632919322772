import React, { createContext, useState } from 'react';
import { USER_INFO } from '../constants/config';
import { IUser } from '../services/auth/types';
import { getLocalSession, getLocalStorage } from '../utils/storage.utils';

interface IAuthContext {
  profileData: IUser;
  // eslint-disable-next-line no-unused-vars
  setProfileData: (profileData: IUser) => void;
  signOut: () => void;
}

const AuthContext = createContext<Partial<IAuthContext>>({});

const ProfileProvider = ({ children }: { children: React.ReactNode }) => {
  const userDataFromStorage = getLocalStorage(USER_INFO) || getLocalSession(USER_INFO);
  const [profileData, setProfileData] = useState<IUser>(userDataFromStorage ?? undefined);

  // const auth = getAuth();
  // const user = auth.currentUser;

  const signOut = async () => {
    const channel = new BroadcastChannel('auth-channel');
    channel.postMessage({ action: 'signout' });
    channel.close();
  };

  // const deleteUserFirebase = async () => {
  //   if (user)
  //     await deleteUser(user)
  //       .then(() => {
  //         console.log('deleted user');
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  // };

  return (
    <AuthContext.Provider value={{ profileData, setProfileData, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, ProfileProvider };
