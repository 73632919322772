import AppContent from '@/components/Common/Content/Content';
import { Collapse, PageHeader, Spin, message } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { useState } from 'react';
import FilterForm from './Form';

import TableData from './Table';
import { check_date_cap, check_export_report_date_cap } from '@/utils/common';
import { download_report, get_report } from '@/services/report/queries';
import { ICustomerNetSalesType } from '@/services/report/types';

import withReportPagination, {
  PaginatedReportValues,
  WithReportPaginationProps
} from '@/components/Common/Report/withReportPagination';
import { showReportConditionalDownloadMessage } from '../../../../utils/reportConditionalDownloadMessage';
import { getUpdatedData } from './column.export';

export type ExtendedInitialValues = PaginatedReportValues & {
  constraints: {
    customerId: string | number;
    locationId: string | number;
    routeId: string | number;
  };
};

function CustomerNetSalesPage({
  form,
  initialValues,
  pagination,
  ...handlers
}: WithReportPaginationProps) {
  const breadcrumbItems = [
    { label: 'Reports', link: '/reports' },
    { label: 'Customer Net Sales', link: '/reports/customer-net-sales' }
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [fullExportURL, setFullExportURL] = useState<any>(null);

  const [data, setData] = useState<{ total: number; data: ICustomerNetSalesType[] }>({
    total: 0,
    data: []
  });

  const customerNetReportState = { id: 55, name: 'Customer Net Sales Report' };

  const extendedInitialValues = {
    ...initialValues,
    constraints: {
      ...initialValues.constraints,
      customerId: '',
      locationId: 1,
      routeId: ''
    }
  };

  async function onSearchAll() {
    try {
      setIsLoading(true);
      if (!fullExportURL) {
        message.error('No URL specified. Please search first.');
        return;
      }
      const { data } = await get_report(fullExportURL, customerNetReportState.id);
      return getUpdatedData(data.data) as any;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function onSearch(values: ExtendedInitialValues) {
    setIsLoading(true);
    const { constraints } = values;
    try {
      const hasDateCap = check_date_cap(constraints.startDate, constraints.endDate);
      if (hasDateCap) {
        await onDownload();
        return;
      }

      const updatedConstraints = await handlers.getConstraint();

      const { data } = await get_report(updatedConstraints, customerNetReportState.id);
      if (data.data.length === 0) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }

      // Get all constraints excluding page, size
      const payload = updatedConstraints as any;
      payload.size = data.count;

      setFullExportURL(payload);
      setData({ total: data.count, data: data.data });
    } finally {
      setIsLoading(false);
    }
  }

  async function onDownload() {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsLoading(true);
    try {
      await form.validateFields();

      const constraints = await handlers.getConstraint();
      if (!constraints) return;

      const hasDateCap = check_export_report_date_cap(constraints.startDate, constraints.endDate);

      if (!hasDateCap) return;
      const customReportQueueName = 'Customer Net Sales Report';

      message.info({
        key: 'downloading',
        content: `${customReportQueueName} Added to Queue. Download will start soon.`
      });
      await download_report({ ...constraints, customReportQueueName }, customerNetReportState.id);
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Collapse>
          <CollapsePanel header="Filters" key="1">
            <FilterForm
              form={form}
              isLoading={isLoading}
              initialValues={extendedInitialValues}
              onSearch={onSearch}
              onDownload={onDownload}
              onChange={handlers.onChange}
              onDisabledDate={handlers.onDisabledDate}
              onChangeNepali={handlers.onChangeNepali}
            />
          </CollapsePanel>
        </Collapse>

        <PageHeader subTitle="Table" style={{ padding: '8px 0px' }} />
        <TableData
          data={data}
          pagination={pagination}
          onSearch={onSearch}
          onSearchAll={onSearchAll}
          onChange={handlers.onChange}
          onPagination={handlers.onPagination}
        />
      </AppContent>
    </Spin>
  );
}

export default withReportPagination(CustomerNetSalesPage);
