import { CloseOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Checkbox, Form, Input, message, PageHeader, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AppContent from '../../../components/Common/Content/Content';
import CustomSubmitButton from '../../../components/Common/CustomButton/CustomSubmitButton';
import useDebounce from '../../../hooks/useDebounce';
import { create_channels_mutation } from '../../../services/channels/mutations';
import { ICreateChannel } from '../../../services/channels/types';
import { get_user_details, get_user_list } from '../../../services/users/queries';
import { getUserData } from '../../../utils/auth.utils';
import { get_channel_reasons_list } from '../../../services/channels/queries';
const { Option } = Select;
interface ReusuableCreateChannelProps {
  slug: string;
  OnSuccess: () => void;
  withReferenceandId?: {
    referenceId: number | string;
    reference: string;
  };
  fromSellorPurchaseId?: string;
}
const ReusuableCreateChannel: React.FC<ReusuableCreateChannelProps> = ({
  slug,
  OnSuccess,
  withReferenceandId,
  fromSellorPurchaseId
}) => {
  const [form] = Form.useForm();
  // console.log('With reference and id', withReferenceandId);
  //product search
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [channelReasonList, setChannelReasonsList] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [userSearch, setUserSearch] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [responsize, setResponsize] = useState(false);
  const loggedInUser = getUserData();
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const getSellorPurchaseUserDetails = async (id: string) => {
    const response = await get_user_details(parseInt(id));
    // console.log('Response-->', response);
    // console.log('User List from function--->', userList);
    setUserList((prev) => [...prev, response.user]);
  };

  useEffect(() => {
    setUserList((prev) => [...prev, loggedInUser]);
    // const userList = [loggedInUser];
    const members = [loggedInUser.id];
    if (fromSellorPurchaseId !== '') {
      getSellorPurchaseUserDetails(fromSellorPurchaseId as string);
      members.push(fromSellorPurchaseId);
    }
    // console.log('members-->', members);
    const formValues = {
      name: '',
      slug: slug,
      members: members,
      referenceId: withReferenceandId?.referenceId,
      reference: withReferenceandId?.reference
    };
    form.setFieldsValue(formValues);
  }, []);
  useEffect(() => {
    searchUser(debouncedSearchValue);
  }, [debouncedSearchValue]);
  useQuery(['channel_reasons'], async () => {
    const response = await get_channel_reasons_list();
    setChannelReasonsList(response.data);
    setIsLoading(false);
    return response;
  });
  const createChannelMutation = useMutation(create_channels_mutation);
  // This is needed to get the product details update as its written
  const [productDetails, setProductDetails] = useState<any>();
  const breadcrumbItems = [
    {
      label: 'Ticket',
      link: '/channel'
    },
    {
      label: 'Create',
      link: '/channel/new'
    }
  ];
  const onFinish = (values: ICreateChannel) => {
    if (values.members.length < 2) {
      message.error('Please choose atleast 2 members!');
    } else {
      values.members.push(loggedInUser.id);
      console.log(values);
      createChannelMutation.mutateAsync(values, {
        onSuccess: async ({ data }: { data: any }) => {
          message.success('Channel created successfully');
          OnSuccess();
        },
        onError: (e: any) => {
          message.error(`${e.response.data.message}`, 5);
        }
      });
    }
  };
  const searchUser = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_user_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any user with that name!');
        setUserSearch([]);
      } else {
        setUserSearch(response.data.results);
        checkUsersAndAdd(response.data.results);
      }
    }
  };
  const checkUsersAndAdd = (users: any[]) => {
    if (userList.length > 0) {
      users = users.filter((value) => {
        const searchProduct = userSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (users.length > 0)
        setUserList((prevValue) => {
          return [...prevValue, ...users];
        });
    } else {
      setUserList(users);
    }
  };
  const DisplayUserName = (name: any) => {
    const selectedUser = form.getFieldValue(['members', name]);
    const user = userList.find((val) => val.id == selectedUser);
    return (
      <>
        <span>{user?.name}</span>
        {/* <Input type={'text'} key={user.id} value={user.name} disabled /> */}
      </>
    );
  };
  const options = userSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.name}
    </Option>
  ));

  useEffect(() => {
    if (window.innerWidth <= 650) {
      // setCollapsed(true);
      setResponsize(true);
    } else {
      setResponsize(false);
    }
    function handleResize() {
      if (window.innerWidth <= 650) {
        // setCollapsed(true);
        setResponsize(true);
      } else {
        setResponsize(false);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const selectAllUsers = async () => {
    const allUsers = await get_user_list(0, 1000);
    // console.log('allUsers', allUsers);
    if (allUsers?.data?.results) {
      const tobeAddedUsers = allUsers.data.results.filter((curr: any) => {
        const isAlreadyPresent = userList.find((cur: any) => cur.id == curr.id);
        if (isAlreadyPresent) {
          return false;
        } else {
          return true;
        }
      });
      setUserList((prev: any) => [...prev, ...tobeAddedUsers]);
      const prevarray = form.getFieldValue(['members']);
      for (let ind = 0; ind < tobeAddedUsers.length; ind++) {
        prevarray.push(tobeAddedUsers[ind].id);
        // fetchUserImage(tobeAddedUsers[ind].id);
      }
      form.setFieldValue(['members'], prevarray);
      // checkUsersAndAdd(allUsers.data.results)
    }
  };
  // console.log('User Lists-->', userList);

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Create Ticket"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          validateTrigger={'onChange'}
          // onValuesChange={(_, allFields) => {
          //   setProductDetails(allFields);
          // }}
          autoComplete="off">
          <div className={responsize ? 'grid grid-cols-1' : 'grid grid-cols-2 gap-5 mb-5'}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Slug"
              name="slug"
              rules={[{ required: true, message: 'Please add slug!' }]}>
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Color Code"
              name="color"
              rules={[{ required: true, message: 'Please add color code!' }]}>
              <Select
                placeholder="Select Color Code"
                style={{ borderRadius: '10px' }}
                allowClear
                dropdownMatchSelectWidth={false}>
                <Option value={'red'} key={'red'}>
                  Red
                </Option>
                <Option value={'green'} key={'green'}>
                  Green
                </Option>
                <Option value={'yellow'} key={'yellows'}>
                  Yellow
                </Option>
                {/* {paymentOptions.map((value: any) => {
                          return (
                            <Select.Option value={value} key={value}>
                              {value}
                            </Select.Option>
                          );
                        })} */}
              </Select>
            </Form.Item>
            <Form.Item
              label="Priority"
              name="priority"
              rules={[{ required: true, message: 'Please add priority!' }]}>
              <Select
                placeholder="Select Priority"
                style={{ borderRadius: '10px' }}
                allowClear
                dropdownMatchSelectWidth={false}>
                <Option value={'high'} key={'high'}>
                  High
                </Option>
                <Option value={'medium'} key={'medium'}>
                  Medium
                </Option>
                <Option value={'low'} key={'low'}>
                  Low
                </Option>
                {/* {paymentOptions.map((value: any) => {
                          return (
                            <Select.Option value={value} key={value}>
                              {value}
                            </Select.Option>
                          );
                        })} */}
              </Select>
            </Form.Item>
            <Form.Item
              label="Is Closed?"
              name="closed"
              rules={[{ required: false, message: 'Please add color code!' }]}
              hidden>
              <Checkbox value={false}></Checkbox>
            </Form.Item>
            <Form.Item
              label="Reference"
              name="reference"
              rules={[{ required: true, message: 'Please add color code!' }]}>
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Reference Id"
              name="referenceId"
              rules={[{ required: true, message: 'Please add color code!' }]}>
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Reason"
              name="reasonId"
              rules={[{ required: true, message: 'Please Select reason!' }]}>
              <Select
                placeholder="Select Reason"
                style={{ borderRadius: '10px' }}
                allowClear
                dropdownMatchSelectWidth={false}>
                {channelReasonList
                  .filter((curr: any) => !curr.isArchived)
                  .map((value: any) => {
                    return (
                      <Option value={value.id} key={value.id}>
                        {value.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <Form.List name={['members']}>
            {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
              <>
                <div className={'grid mb-5'}>
                  <PageHeader
                    subTitle="Add Users"
                    style={{
                      padding: '8px 0px 8px 10px'
                    }}
                  />
                  <Select
                    showSearch
                    placeholder={'search user'}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(val) => {
                      setSearchValue(val);
                    }}
                    onSelect={(val: number | string) => {
                      if (val == 'all') {
                        // console.log('all selected');
                        selectAllUsers();
                        return;
                      }
                      if (loggedInUser.id == val) {
                        message.error('Cannot add self, already added.');
                        return;
                      }
                      const selectedUsers = form.getFieldValue(['members']);
                      const exists = selectedUsers.find((value: any) => value == val);
                      if (exists) {
                        message.error('Already Added');
                        return;
                      }
                      add2(val);
                    }}
                    notFoundContent={null}>
                    <Option value={'all'}>All</Option>
                    {options}
                  </Select>
                  {fields2.length > 0 && (
                    <PageHeader
                      title="Selected Users"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                  )}
                </div>
                <div className="flex flex-wrap gap-3 mb-5">
                  {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                    <div
                      key={key2}
                      className="card"
                      style={{
                        borderRadius: '15px',
                        backgroundColor: '#e5f5fb',
                        padding: '8px 12px'
                      }}>
                      <div className="flex gap-2" key={key2}>
                        <Form.Item {...restField2} name={[name2]} hidden>
                          <Input type={'text'} disabled />
                        </Form.Item>
                        {DisplayUserName(name2)}
                        {parseInt(form.getFieldValue(['members', name2])) !==
                          parseInt(loggedInUser.id) &&
                          parseInt(form.getFieldValue(['members', name2])) !==
                            parseInt(fromSellorPurchaseId as string) && (
                            <div className="flex justify-center items-center">
                              {fields2.length > 0 ? (
                                <CloseOutlined
                                  className="dynamic-delete-button flex justify-center items-center"
                                  style={{ color: '#0AA245' }}
                                  onClick={() => remove2(name2)}
                                  // disabled={name2 === loggedInUser.id}
                                  disabled={true}
                                />
                              ) : null}
                            </div>
                          )}
                        {/* <div className="flex justify-center items-center">
                          {fields2.length > 0 ? (
                            <CloseOutlined
                              className="dynamic-delete-button flex justify-center items-center"
                              style={{ color: '#0AA245' }}
                              onClick={() => remove2(name2)}
                            />
                          ) : null}
                        </div> */}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </Form.List>
          <div className="flex justify-end mt-5">
            <Form.Item>
              {/* <Button type="primary" htmlType="submit">
                Submit
              </Button> */}
              <CustomSubmitButton text={'Create & Chat'} />
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </div>
  );
};

export default ReusuableCreateChannel;
