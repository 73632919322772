import React, { useState } from 'react';
import { EyeFilled, PlusCircleFilled } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate } from 'react-router-dom';
import AppContent from '../../../../components/Common/Content';
import CustomizeTable from '../../../../components/Common/CustomizeTable/CustomizeTable';
import CustomButton from '../../../../components/Common/CustomButton/CustomButton';
import { checkAccess } from '../../../../routes/acl';

interface DataType {
  url: string;
  name: string;
}

const ReportList: React.FC = () => {
  const navigate = useNavigate();
  const sourceList: DataType[] = [
    { name: 'User Report', url: 'user' },
    { name: 'Customer Report', url: 'customer' },
    { name: 'Vendor Report', url: 'vendor' },
    { name: 'Address Report', url: 'address' },
    { name: 'Total Purchase Report', url: 'total-purchase' },
    { name: 'Total Sale Report', url: 'total-sale' },
    { name: 'Purchase Order Line Report', url: 'purchase-order-line' },
    { name: 'Purchase Return Line Report', url: 'purchase-return-line' },
    { name: 'Purchase Line Report', url: 'purchase-line' },
    { name: 'Sale Order Line Report', url: 'sale-order-line' },
    { name: 'Sale Line Report', url: 'sale-line' },
    { name: 'Sale Return Line Report', url: 'sale-return-line' },
    { name: 'Transfer In Line Report', url: 'transfer-in-line' },
    { name: 'Transfer Out Line Report', url: 'transfer-out-line' },

    { name: 'Adjustment Line Report', url: 'adjustment-line' },
    { name: 'Purchase Order Product Identifier Report', url: 'purchase-order-product-identifier' },
    {
      name: 'Purchase Return Product Identifier Report',
      url: 'purchase-return-product-identifier'
    },
    { name: 'Purchase Product Identifier Report', url: 'purchase-product-identifier' },
    { name: 'Sale Order Product Identifier Report', url: 'sale-order-product-identifier' },
    { name: 'Sale Product Identifier Report', url: 'sale-product-identifier' },
    { name: 'Sale Return Product Identifier Report', url: 'sale-return-product-identifier' },
    { name: 'Transfer In Product Identifier Report', url: 'transfer-in-product-identifier' },
    { name: 'Transfer Out Product Identifier Report', url: 'transfer-out-product-identifier' },
    { name: 'Adjustment Product Identifier Report', url: 'adjustment-product-identifier' },
    { name: 'Product History Report', url: 'product-history' },
    { name: 'Sell Report', url: 'sell' },
    { name: 'Sell Payment Report', url: 'payment-sell' },
    { name: 'Payment Purchase Report', url: 'payment-purchase' },
    { name: 'Stock Adjustment Report', url: 'stock-adjustment' },
    { name: 'Customer Net Sales Report', url: 'customer-net-sales' },
    { name: 'Stock Value Report', url: 'stock-value' },
    { name: 'Opening Stock Report', url: 'opening-stock' }
  ];
  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      key: 'name',
      render: (record) => {
        return (
          <Link to={`/reports/${record.url}`} state={record} color="black">
            <div className="forless">{record.name}</div>
          </Link>
        );
      }
    }
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   fixed: 'right',
    //   render: (record) => {
    //     // const confirmFunction = () => {
    //     //   deleteofferMutation.mutate(record.id);
    //     // };
    //     return (
    //       <div className="flex justify-center items-center">
    //         <Link to={`/source/${record.id}`} color="black">
    //           <Tooltip title="Update" color="blue">
    //             <EyeFilled />
    //           </Tooltip>
    //         </Link>
    //         {/* <DeleteConfirmModal FunctionAfterOk={confirmFunction} /> */}
    //       </div>
    //     );
    //   }
    // }
  ];

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    }
  ];

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <CustomButton
              onClick={() => navigate('/source/new')}
              text="Add"
              Linkto="/source/new"
              backgroundColor="#1890ff"
            />
          </>
        }>
        {/* <div className="flex justify-end mt-5">
          <Link to={'/source/new'}>
            <Button
              htmlType={'button'}
              size={'small'}
              type={'primary'}
              className={'me-2'}
              onClick={() => navigate('/source/new')}>
              <PlusCircleFilled />
              <span> Add</span>
            </Button>
          </Link>
        </div> */}

        {/* <Table columns={columns} dataSource={sourceList} rowKey={'id'} scroll={{ y: 500 }} /> */}
        <CustomizeTable columns={columns} data={sourceList} notshowPagination={true} />
      </AppContent>
    </div>
  );
};

export default ReportList;
