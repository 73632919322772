import { useQuery, useMutation } from '@tanstack/react-query';
import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  message,
  PageHeader,
  Divider,
  Checkbox,
  Card
} from 'antd';

import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import AppContent from '../../../components/Common/Content/Content';
import { get_location_list } from '../../../services/locations/queries';
import {
  get_product_list,
  get_units_list,
  get_unexpired_lots_details_bylocationId_productId,
  prices_by_groupId,
  get_price_groups_by_location,
  get_unexpired_lots_details_bylocationId_productIds
} from '../../../services/products/queries';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import { ILotDetails, IProductType, IUnits } from '../../../services/products/types';
import useDebounce from '../../../hooks/useDebounce';
import { ILines, IProductUnits, Line } from '../../../services/sell/types';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import { create_opening_stock_mutation } from '../../../services/stock/mutations';
import { ICreateOpeningStock } from '../../../services/stock/types';
import { LocationSearch } from '../../../components/Common/LocationSearch/LocationSearch';
import { find_locationId_preference } from '../../../store/localstorage/preferences';
import { WebSocketContext } from '../../../contexts/websocket.context';
import { SocketEvents, SystemNotificationType } from '../../../constants/websocketConfig';
import fetchProductData from '../../../utils/fetchProductData';

const { Option } = Select;

const CreateOpeningStock = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { socket } = useContext(WebSocketContext);
  const [isloading, setIsloading] = useState<boolean>(false);
  const [isNewReason, setIsNewReason] = useState<boolean>(false);
  const [allLocalUnits, setAllLocalUnits] = useState<any>([]);
  const [totalLots, settotalLots] = useState<any>([]);
  //product search
  const [searchValue, setSearchValue] = useState<string>('');
  const [productSearch, setProductSearch] = useState<any[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchProduct(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  const [defaultPriceBylocationId, setDefaultPriceBylocationId] = useState<any>({});
  const preferenceLocationId = find_locationId_preference();
  // This is needed to get the product details update as its written
  const [productDetails, setProductDetails] = useState<any>();

  const breadcrumbItems = [
    {
      label: 'Products',
      link: '/products'
    },
    {
      label: 'Opening Stock',
      link: '/open-stock'
    },
    {
      label: 'Create'
    }
  ];

  useEffect(() => {
    socket?.on('connect', async () => {
      // console.log('Socket Reconnected');
      const locationId = form.getFieldValue(['locationId']);
      const lines = form.getFieldValue(['lines']);
      const productIds = new Set<number>(
        lines.map((value: ILines) => {
          return value.productId;
        })
      );
      await fetchLotsOnLocationandProductChange([...productIds], locationId, 'lotsupdate');
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_ZERO) {
        const locationId = form.getFieldValue(['locationId']);
        const socketData = data.data as { locationId: number };
        if (socketData.locationId === locationId) {
          settotalLots((prev: any) => {
            return prev.map((a: any) => ({ ...a, qtyAvailable: 0 }));
          });
        }
      }

      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        const lines = form.getFieldValue(['lines']);
        const productIds = new Set<number>(lines.map((value: ILines) => value.productId));

        const locationId = form.getFieldValue(['locationId']);
        // Get Products and filter by Location
        let updatedProducts = data.data as { productId: number; locationId: number }[];
        if (locationId) {
          updatedProducts = updatedProducts.filter(
            (value) =>
              value.locationId === locationId && Array.from(productIds).includes(value.productId)
          );
        }

        if (updatedProducts.length > 0) {
          const updatedProductIds = updatedProducts.map((value) => value.productId);
          await fetchLotsOnLocationandProductChange(updatedProductIds, locationId, 'lotsupdate');
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected]);

  const createOpeningStockMutation = useMutation(create_opening_stock_mutation);

  const onFinish = async (values: ICreateOpeningStock) => {
    try {
      setIsloading(true);
      if (values.lines.length == 0)
        throw {
          name: 'LinesError',
          message: `Please select atleast one Product.`
        };

      createOpeningStockMutation.mutateAsync(values, {
        onSuccess: async ({ data }: { data: any }) => {
          setIsloading(false);
          message.success('Opening Stock created successfully');
          navigate('/products');
        },
        onError: (e: any) => {
          setIsloading(false);
          message.error(`${e.response.data.message}`, 5);
        }
      });
    } catch (errors: any) {
      if ('name' in errors) message.error(errors.message);
      else message.error('Empty fields found!');
      setIsloading(false);
    }
  };

  const fetchLotsOnLocationandProductChange = async (
    productsIdArray: number[],
    locationId: number,
    from: string
  ) => {
    try {
      if (!locationId) {
        throw {
          name: 'Location Error',
          message: 'Please select Location!'
        };
      }

      if (productsIdArray.length == 0) {
        return;
      }

      const currenttotalLots = [];
      if (from === 'productchange') {
        const filterLots = totalLots.find((value: Line) => value.productId == productsIdArray[0]);
        if (!filterLots) {
          const response = await get_unexpired_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[0]
          );
          settotalLots([...totalLots, ...response]);
        }
      } else if (from === 'lotsupdate') {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);

        settotalLots((prev: ILotDetails[]) => {
          const filterLots = prev.filter((value) => !productsIdArray.includes(value.productId));
          return [...filterLots, ...result];
        });
      } else {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);

        currenttotalLots.push(...result);
        settotalLots([...currenttotalLots]);
      }
    } catch (err: any) {
      message.error(err.message);
    }
  };

  const onLocationChange = async (value: number) => {
    form.setFieldValue('location', value);
    const mySet = new Set<number>();
    const data = form.getFieldValue(['lines']);
    if (data) {
      data.map((curr: any, ind: number) => {
        mySet.add(curr.productId);
        form.setFieldValue(['lines', ind, 'lotId'], null);
      });
    }

    fetchLotsOnLocationandProductChange(Array.from(mySet), value, 'location');

    let defaultPrice: any = [];
    if (defaultPriceBylocationId[value]) {
      defaultPrice = defaultPriceBylocationId[value];
    } else {
      const response = await get_price_groups_by_location(value);
      // console.log('resposne===>', response);
      if (response.data[0]?.id) {
        const response2 = await prices_by_groupId(response.data[0].id);
        // console.log('response 2-->', response2);
        defaultPrice = [...response2.data];
        setDefaultPriceBylocationId({ ...defaultPriceBylocationId, [value]: response2.data });
      } else {
        data.map((curr: any, ind: number) => {
          form.setFieldValue(['lines', ind, 'unitPrice'], undefined);
          form.setFieldValue(['lines', ind, 'sellingPrice'], undefined);
        });
      }
    }
    // console.log('defaultPrice', defaultPrice);
    if (defaultPrice.length > 0) {
      data.map((curr: any, ind: number) => {
        const findone = defaultPrice.find((res2elem: any) => res2elem.productId === curr.productId);
        // console.log('findOne', findone);
        if (findone) {
          const alreadySelectedUnitId = form.getFieldValue(['lines', ind, 'unitId']);
          // console.log('alreadySelectedUnitId', alreadySelectedUnitId);
          if (alreadySelectedUnitId !== undefined && alreadySelectedUnitId !== null) {
            const findUnit = allLocalUnits.find((curr: any) => curr.id === alreadySelectedUnitId);
            // console.log('findUnit', findUnit);
            form.setFieldValue(
              ['lines', ind, 'unitPrice'],
              findUnit.baseUnitMultiplier * findone.sellingPrice
            );
          } else {
            form.setFieldValue(['lines', ind, 'unitPrice'], findone.sellingPrice);
          }
          form.setFieldValue(['lines', ind, 'sellingPrice'], findone.sellingPrice);
        } else {
          form.setFieldValue(['lines', ind, 'unitPrice'], undefined);
          form.setFieldValue(['lines', ind, 'sellingPrice'], undefined);
        }
      });
    } else {
      data.map((curr: any, ind: number) => {
        form.setFieldValue(['lines', ind, 'unitPrice'], undefined);
        form.setFieldValue(['lines', ind, 'sellingPrice'], undefined);
      });
    }
  };
  const FilterUnits = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    if (checkCurrentProduct && productList) {
      const selectedProduct = productList.find(
        (val: IProductType) => val.id == checkCurrentProduct
      );
      const filteredUnits = selectedProduct.productUnits.map((value: IProductUnits) => {
        const data = allLocalUnits.find((val: IUnits) => value.unitId == val.id);
        return data;
      });
      return (
        <>
          {filteredUnits.map((value: any) => {
            if (!value) return null;
            return (
              <Option value={value.id} key={value.id}>
                {`${value.name}`}
              </Option>
            );
          })}
        </>
      );
    }
  };
  const FilterLot = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    const currentLocation = form.getFieldValue(['locationId']);
    if (checkCurrentProduct && currentLocation) {
      // const filteredLots = allLots.filter((value: Line) => value.productId == checkCurrentProduct);
      let filteredLots: any = [];
      // console.log('totalLots-->', totalLots);
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter(
          (currLot: any) => currLot.productId === checkCurrentProduct
        );
        // console.log('filtered Lots-->', filteredLots);
      }
      const unitId = form.getFieldValue(['lines', name, 'unitId']);
      const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
      return (
        <>
          {filteredLots?.map((value: any) => (
            <Option
              value={value.id}
              key={value.id}
              style={{ color: value.qtyAvailable > 0 ? 'green' : 'red' }}>
              {/* {`(${value.qtyAvailable}) ${value.lotNumber}`} */}
              {`(${value.qtyAvailable / (unitInfo?.baseUnitMultiplier || 1)} ${
                unitInfo?.shortName || ''
              }) ${value.lotNumber} Grade-${value.grade} Expiry-${
                value?.expirationDate ? new Date(value.expirationDate).toLocaleDateString() : 'N/A'
              }`}
            </Option>
          ))}
        </>
      );
    }
  };
  const onProductChange = async (value: number, name?: number) => {
    // form.setFieldValue([name, 'productId'], value);
    // await checkAndGetLot(value);
    const data = form.getFieldValue(['lines']);
    const currentLocation = form.getFieldValue(['locationId']);
    await fetchLotsOnLocationandProductChange([value], currentLocation, 'productchange');
    if (productList) {
      const selectedProduct = productSearch.find((val) => val.id == value);
      // const selectedProduct = productList.data.results.find((val: IProductType) => val.id == value);
      const unitIdList: IProductUnits[] = selectedProduct.productUnits;
      const defaultUnit = unitIdList.find((currUnit: any) => currUnit.isDefault === true);
      form.setFieldValue(['lines', data.length - 1, 'unitId'], defaultUnit?.unitId);
      let fetch = false;
      for (const value of unitIdList) {
        let unitData;
        try {
          unitData = await UnitsDB.getUnit(value.unitId);
        } catch (e) {
          console.log(e);
        }
        if (!unitData) {
          const response = await get_units_list();
          await UnitsDB.addUnits(response);
          fetch = true;
        }
      }
      if (fetch) setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
    }

    let defaultPriceArray: any = [];
    if (defaultPriceBylocationId[currentLocation]) {
      defaultPriceArray = defaultPriceBylocationId[currentLocation];
    } else {
      const response = await get_price_groups_by_location(currentLocation);
      // console.log('resposne===>', response);
      if (response.data[0]?.id) {
        const response2 = await prices_by_groupId(response.data[0].id);
        defaultPriceArray = [...response2.data];
        setDefaultPriceBylocationId({
          ...defaultPriceBylocationId,
          currentLocation: response2.data
        });
        // console.log('response 2-->', response2);
      }
    }
    if (defaultPriceArray.length > 0) {
      const findone = defaultPriceArray.find((res2elem: any) => res2elem.productId === value);
      if (findone) {
        form.setFieldValue(['lines', data.length - 1, 'unitPrice'], findone.sellingPrice);
        form.setFieldValue(['lines', data.length - 1, 'sellingPrice'], findone.sellingPrice);
      }
    } else {
      form.setFieldValue(['lines', data.length - 1, 'unitPrice'], undefined);
    }
    const selectedUnitId = form.getFieldValue(['lines', data.length - 1, 'unitId']);
    const selectedProductId = form.getFieldValue(['lines', data.length - 1, 'productId']);
    const purchasedPrice = await fetchProductData(
      data.length - 1,
      selectedProductId,
      selectedUnitId,
      currentLocation
    );
    if (purchasedPrice === undefined) {
      form.setFieldValue(['lines', data.length - 1, 'unitPrice'], 0);
    } else {
      form.setFieldValue(['lines', data.length - 1, 'unitPrice'], purchasedPrice);
    }
  };

  const searchProduct = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_product_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any product with that name!');
        setProductSearch([]);
      } else {
        setProductSearch(response.data.results);
        checkProductAndAdd(response.data.results);
      }
    }
    // setSearchLoading(false);
  };
  const options = productSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.name}
    </Option>
  ));
  const checkProductAndAdd = (products: any[]) => {
    if (productList.length > 0) {
      products = products.filter((value) => {
        const searchProduct = productSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (products.length > 0)
        setProductList((prevValue) => {
          return [...prevValue, ...products];
        });
    } else {
      setProductList(products);
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      locationId: preferenceLocationId ? preferenceLocationId : null,
      lines: []
    });
    initializeUnits();
  }, []);
  const initializeUnits = async () => {
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
  };

  const checkLotQuantity = (index: number) => {
    const row = form.getFieldValue(['lines', index]);
    if (row.lotId) {
      const filteredLot: any = totalLots.find((value: any) => value.id == row.lotId);
      return filteredLot.qtyAvailable;
    }
    return null;
  };

  const [responsize, setResponsize] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 650) {
      // setCollapsed(true);
      setResponsize(true);
    } else {
      setResponsize(false);
    }
    function handleResize() {
      if (window.innerWidth <= 650) {
        // setCollapsed(true);
        setResponsize(true);
      } else {
        setResponsize(false);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const onUnitChange = async (name: number) => {
    const selectedUnitId = form.getFieldValue(['lines', name, 'unitId']);
    const findOne = allLocalUnits.find((curr: any) => curr.id === selectedUnitId);
    const prevValue = form.getFieldValue(['lines', name, 'sellingPrice']);
    const currentLocation = form.getFieldValue(['locationId']);
    // form.setFieldValue(['lines', name, 'unitPrice'], prevValue * findOne.baseUnitMultiplier);

    const selectedProductId = form.getFieldValue(['lines', name, 'productId']);
    const purchasedPrice = await fetchProductData(
      name,
      selectedProductId,
      selectedUnitId,
      currentLocation
    );
    if (purchasedPrice === undefined) {
      form.setFieldValue(['lines', name, 'unitPrice'], 0);
      return;
    }
    form.setFieldValue(['lines', name, 'unitPrice'], purchasedPrice);
  };

  const onUnitandQuantityChange = async (name: number) => {
    // console.log('name', name);
    // console.log('number', value);
    const rate = form.getFieldValue(['lines', name, 'unitPrice']);
    const qty = form.getFieldValue(['lines', name, 'quantity']);
    const mis = form.getFieldValue(['lines', name, 'misc']);
    const dis = form.getFieldValue(['lines', name, 'discount']);

    const total = rate * qty - dis + mis;
    form.setFieldValue(['lines', name, 'total'], total);

    const lines = form.getFieldValue(['lines']);
    // console.log('lines', lines);
    let grandTotal = 0;
    for (let i = 0; i < lines.length; i++) {
      grandTotal += lines[i].total;
    }
    // console.log('grandtotal', grandTotal);

    // form.setFieldValue(['lines', 'grandtotal'], grandTotal);
    form.setFieldValue(['grandtotal'], grandTotal);

    // const grandTotall = form.getFieldValue(['lines','grandtotal']);
    // console.log('grandTotall field value', grandTotall);
  };

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Stock Information"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          disabled={isloading}
          validateTrigger={'onChange'}
          onValuesChange={(_, allFields) => {
            setProductDetails(allFields);
          }}
          autoComplete="off">
          <div className={responsize ? 'grid grid-cols-1 mb-3' : 'grid grid-cols-3 gap-5 mb-5'}>
            {/* <Form.Item
              name="locationId"
              label="Location"
              rules={[
                {
                  required: true,
                  message: 'Please choose location!'
                }
              ]}>
              <Select placeholder="Select a location!" onChange={onLocationChange} allowClear>
                {locationsList?.data.results.map((value: any) => {
                  return (
                    <Option value={value.id} key={value.id}>
                      {value.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item> */}
            <LocationSearch notAll={true} onSelect={onLocationChange} />
          </div>
          {/* <Form.List name="purchases">
            {(fields, { add, remove: remove }, { errors }) => (
              <>
                {fields.map(({ key: key, name: name, ...restField }) => (
                  <div key={key}> */}
          <Divider />

          <div className="grid grid-cols-4 gap-5 mb-5"></div>
          <Form.List name={['lines']}>
            {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
              <>
                <div className={'grid mb-5'}>
                  <PageHeader
                    subTitle="Add Product"
                    style={{
                      padding: '8px 0px 8px 10px'
                    }}
                  />
                  <Select
                    showSearch
                    placeholder={'search product'}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(val) => {
                      setSearchValue(val);
                    }}
                    onSelect={(val: number) => {
                      const selectedProduct = productSearch.find((value) => value.id == val);
                      add2({
                        productId: val,
                        productName: selectedProduct.name,
                        unitId: null,
                        lotId: null,
                        quantity: 0,
                        unitPrice: 0,
                        discount: 0,
                        misc: 0,
                        isTraceable: true
                      });
                      onProductChange(val);
                    }}
                    notFoundContent={null}>
                    {options}
                  </Select>
                  {fields2.length > 0 && (
                    <PageHeader
                      title="All Products"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                  )}
                </div>
                {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                  <div key={key2} className="card">
                    <PageHeader
                      subTitle="Products"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                    <div
                      className={
                        responsize ? 'grid grid-cols-2 gap-3 mb-3' : 'grid grid-cols-9 gap-2 mb-2'
                      }
                      key={key2}>
                      <Form.Item {...restField2} name={[name2, 'productId']} hidden></Form.Item>
                      <Form.Item {...restField2} name={[name2, 'sellingPrice']} hidden></Form.Item>
                      <Form.Item {...restField2} name={[name2, 'productName']} label="Name">
                        <Input type={'text'} disabled />
                        {/* <Select
                          placeholder="Select a Product!"
                          onChange={(val) => onProductChange(val, name2)}
                          allowClear>
                          {productList?.data.results.map((value: IProductType) => {
                            return (
                              <Option value={value.id} key={value.id}>
                                {`${value.name}`}
                              </Option>
                            );
                          })}
                        </Select> */}
                      </Form.Item>
                      <Form.Item
                        {...restField2}
                        name={[name2, 'unitId']}
                        label="Unit"
                        rules={[
                          {
                            required: true,
                            message: 'Please choose a Unit!'
                          }
                        ]}>
                        {
                          <Select
                            placeholder="Select a Unit!"
                            onChange={() => onUnitChange(name2)}
                            dropdownMatchSelectWidth={false}
                            allowClear>
                            {FilterUnits(name2)}
                          </Select>
                        }
                      </Form.Item>
                      <Form.Item name={[name2, 'isLot']} valuePropName="checked">
                        <Checkbox
                          onChange={(value) => {
                            if (!value.target.checked)
                              form.setFieldValue(['lines', name2, 'lotId'], undefined);
                          }}>
                          Existing Lot?
                        </Checkbox>
                      </Form.Item>
                      {form.getFieldValue(['lines', name2, 'isLot']) && (
                        <Form.Item
                          {...restField2}
                          name={[name2, 'lotId']}
                          label="Lot"
                          rules={[
                            {
                              required: true,
                              message: 'Please choose a Lot!'
                            }
                          ]}>
                          {
                            <Select
                              placeholder="Select a Lot!"
                              dropdownMatchSelectWidth={false}
                              // onChange={onLocationChange}
                              allowClear>
                              {FilterLot(name2)}
                            </Select>
                          }
                        </Form.Item>
                      )}
                      <Form.Item
                        {...restField2}
                        label="Quantity"
                        name={[name2, 'quantity']}
                        rules={[{ required: true, message: 'Please add Quantity!' }]}

                        // rules={[
                        //   { required: true, message: 'Please add Quantity!' },
                        //   () => ({
                        //     validator(_: any, value) {
                        //       const quantity = checkLotQuantity(name2);
                        //       console.log(quantity);
                        //       if (!value) {
                        //         return Promise.reject();
                        //       }
                        //       if (value < 0) return Promise.reject(`Please Input valid quantity!`);
                        //       if (quantity == null) {
                        //         return Promise.reject(`Please select Lot!`);
                        //       }
                        //       if (value > quantity)
                        //         return Promise.reject(
                        //           `Please Input valid quantity! Currently in stock: ${quantity}`
                        //         );

                        //       // if (lineData.quantity < value) {
                        //       //   return Promise.reject(
                        //       //     `Please Input valid ${title}! Range: 0 to ${lineData.quantity}`
                        //       //   );
                        //       // }
                        //       // if (1 > value) {
                        //       //   return Promise.reject(
                        //       //     `Please Input valid ${title}! Range: 0 to ${lineData.quantity}`
                        //       //   );
                        //       // }
                        //       return Promise.resolve();
                        //     }
                        //   })
                        // ]}
                      >
                        <InputNumber
                          controls={false}
                          min={0}
                          onChange={() => onUnitandQuantityChange(name2)}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField2}
                        label="Rate"
                        name={[name2, 'unitPrice']}
                        rules={[{ required: true, message: 'Please add Rate!' }]}>
                        <InputNumber
                          controls={false}
                          min={0}
                          onChange={() => onUnitandQuantityChange(name2)}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField2}
                        label="Discount"
                        name={[name2, 'discount']}
                        rules={[{ required: true, message: 'Please add Discount!' }]}>
                        <InputNumber
                          controls={false}
                          min={0}
                          onChange={() => onUnitandQuantityChange(name2)}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField2}
                        label="Misc"
                        name={[name2, 'misc']}
                        rules={[{ required: true, message: 'Please add Misc!' }]}>
                        <InputNumber
                          controls={false}
                          min={0}
                          onChange={() => onUnitandQuantityChange(name2)}
                        />
                      </Form.Item>
                      <Form.Item name={[name2, 'isTraceable']} valuePropName="checked">
                        <Checkbox>Traceable?</Checkbox>
                      </Form.Item>
                      <Form.Item {...restField2} name={[name2, 'total']} label="Total">
                        <InputNumber
                          controls={false}
                          min={0}
                          disabled
                          // className="font-bold"
                          style={{ color: 'black' }}
                        />
                      </Form.Item>
                      {/* <div className="flex justify-center items-center"> */}
                      {fields2.length > 0 ? (
                        // <MinusCircleOutlined
                        //   className="dynamic-delete-button flex justify-center items-center"
                        //   onClick={() => remove2(name2)}
                        // />
                        <div className="flex items-center justify-start">
                          <CustomButton
                            backgroundColor="white"
                            text="Remove"
                            textColor="green"
                            marginTop={responsize ? '10px' : '20px'}
                            onClick={() => remove2(name2)}
                          />
                        </div>
                      ) : null}
                      {/* </div> */}
                    </div>
                  </div>
                ))}
                {/* {fields2.length > 0 ? (
                  <Card
                    style={{ borderRadius: '10px', marginBottom: '10px' }}
                    className="grid grid-cols-2">
                    <Form.Item name={['grandtotal']} label="Grand Total">
                      <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
                    </Form.Item>
                  </Card>
                ) : null} */}
              </>
            )}
          </Form.List>
          {form.getFieldValue(['lines'])?.length > 0 ? (
            <Card
              style={{ borderRadius: '10px', marginBottom: '10px' }}
              className="grid grid-cols-2">
              <Form.Item name={['grandtotal']} label="Grand Total">
                <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
              </Form.Item>
            </Card>
          ) : null}
          <Divider />

          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </div>
  );
};

export default CreateOpeningStock;
