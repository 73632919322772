import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Select,
  Space,
  Spin,
  Radio,
  Alert
} from 'antd';

import getSnakeCase from '@/utils/getSnakeCase';
import AppContent from '@/components/Common/Content/Content';
import { ExpenseColumnDataType } from '@/services/expense/enum';
import ExpenseCategoryDB from '@/store/localstorage/ExpenseCategoryDB';

import {
  ExpenseCategoryColumnItem,
  IExpenseCategoryRevamp
} from '@/services/expense/expense-category/types';

import {
  create_expense_category_mutation,
  update_expense_category_mutation
} from '@/services/expense/expense-category/mutations';
import { filterId } from '@/constants/filter.category';
import { AccountsTypeSearch } from '@/components/Common/AccountsTypeSearch';

interface Props {
  isParentLoading?: boolean;
  data?: IExpenseCategoryRevamp;
  type: 'create' | 'update';
  breadcrumbItems: { label: string; link: string }[];
}

interface IExtendedColumn extends ExpenseCategoryColumnItem {
  isDisabled: boolean;
}

export interface ExpenseCategoryFormData {
  name: string;
  debitAccountId?: number;
  content: { tableName: string; column: IExtendedColumn[] };
}

function CategoryMutationUI({ breadcrumbItems, isParentLoading = false, type, data }: Props) {
  const navigate = useNavigate();
  const [form] = Form.useForm<ExpenseCategoryFormData>();
  const [isLoading, setIsloading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedReference, setSelectedReference] = useState('');

  const [showReferenceModal, setShowReferenceModal] = useState(false);

  const [linkAccount, setLinkAccount] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [columns, setColumns] = useState<IExtendedColumn[]>([]);

  useEffect(() => {
    if (type === 'create') {
      handleAddColumn('normal');
      setLinkAccount(false);
    }

    // Set Initial Data to Form
    if (type === 'update' && data) {
      const { name, content, tableName, debitAccountId } = data;

      const parsedContent = (
        content ? JSON.parse(content) : { tableName: '', column: [] }
      ) as ExpenseCategoryFormData['content'];

      const columns =
        parsedContent.column.length === 0
          ? []
          : parsedContent.column.map((column, index) => {
              const isValid = isValidColumnName(column.name, index + 1, false);
              return {
                ...column,
                isDisabled: !isValid
              };
            });

      parsedContent.column = columns;
      setColumns(columns);
      setLinkAccount(Boolean(debitAccountId));

      const option = {
        name,
        content: { tableName, column: columns },
        ...(debitAccountId && { debitAccountId })
      };

      form.setFieldsValue(option);
      setShowColumns(true);
    }
  }, [type, data]);

  function isValidColumnName(name: string, index: number, showError = true) {
    const regex = /.*_?(id|ids)$/i;

    if (regex.test(name)) {
      showError &&
        message.error(`Column "${index}" contain "${name}" as a name which is not allowed`);

      return false;
    }

    return true;
  }

  const createExpenseMutation = useMutation(create_expense_category_mutation, {
    onSuccess: async (data) => {
      message.success('Table Created Successfully.');
      await ExpenseCategoryDB.add([data.data as any]);
      setIsloading(false);
      navigate('/expense-category');
    },
    onError: () => {
      setIsloading(false);
      message.error('Failed to create table');
    }
  });

  const updateExpenseMutation = useMutation(update_expense_category_mutation, {
    onSuccess: async (data) => {
      message.success('Table Updated Successfully.');
      await ExpenseCategoryDB.update(data.data as any);
      setIsloading(false);
      navigate('/expense-category');
    },
    onError: () => {
      setIsloading(false);
      message.error('Failed to update table');
    }
  });

  const onSubmit = async () => {
    try {
      setIsloading(true);
      const payload = form.getFieldsValue();
      const tableName = payload.content.tableName;

      if (tableName) {
        payload.content.tableName = getSnakeCase(tableName);
      }

      payload.content.column = payload.content.column.map((column) => {
        return {
          ...column,
          name: column.name.includes(' ') ? getSnakeCase(column.name) : column.name
        };
      });

      const validPayload = {
        name: payload.name,
        content: payload.content,
        ...(payload.debitAccountId && { debitAccountId: payload.debitAccountId })
      };

      if (type === 'create') {
        await createExpenseMutation.mutate(payload);
      }

      if (type === 'update' && data) {
        const payloadWithId = { ...validPayload, id: data.id };
        await updateExpenseMutation.mutate(payloadWithId);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowModal(false);
    }
  };

  function verifyColumnData(columns: IExtendedColumn[]) {
    const columnNames = columns.map((column) => column.name);

    // Has duplicate column name
    if (new Set(columnNames).size !== columnNames.length) {
      message.error('There are duplicate column names. Please change them first.');
      return false;
    }

    return columns.every(
      (column, index) => column.isDisabled || isValidColumnName(column.name, index + 1)
    );
  }

  const onFinalizeClick = async () => {
    await form.validateFields();
    const payload = form.getFieldsValue();

    const isValid = verifyColumnData(payload.content.column);
    if (!isValid) return;

    setShowModal(true);
  };

  // Update columns state when form values change
  const handleFormChange = (changedValues: any) => {
    let changedIndex = -1;

    const changedColumn = changedValues?.content?.column?.filter((col: any, index: number) => {
      if (col) {
        changedIndex = index;
        return true;
      }

      return false;
    });

    if (changedColumn && changedColumn?.length > 0) {
      const newColumn = changedColumn[0];
      setColumns((prev) => {
        const existingColumns = [...prev];
        const updatedColumns = existingColumns.map((column, index) => {
          if (index !== changedIndex) return column;
          return {
            ...column,
            ...newColumn,
            isDisabled: existingColumns[changedIndex].isDisabled || false
          };
        });

        return updatedColumns;
      });
    }
  };

  function handleAddColumn(type: 'reference' | 'normal') {
    if (type === 'normal') {
      const isValid = verifyColumnData(columns);
      if (!isValid) return;
    }

    const isReference = type === 'reference';

    if (isReference && !selectedReference) {
      return message.error('No any reference id is selected');
    }

    const newColumn: IExtendedColumn = {
      name: isReference ? selectedReference : '',
      dataType: isReference
        ? filterId.find((item) => item.key === selectedReference)?.type ||
          ExpenseColumnDataType.INTEGER
        : ExpenseColumnDataType.STRING,
      isNullable: false,
      isDisabled: isReference
    };

    const updatedColumns = [...columns, newColumn];
    setColumns(updatedColumns);
    if (!showColumns) setShowColumns(true);

    const updatedContent = { ...form.getFieldValue('content') };
    updatedContent.column = updatedColumns;
    form.setFieldsValue({ content: updatedContent });

    if (isReference) {
      // reset selected references
      setSelectedReference('');
      setShowReferenceModal(false);
    }
  }

  const dataTypeOptions = Object.keys(ExpenseColumnDataType).map((key) => {
    return {
      label: key,
      value: ExpenseColumnDataType[key as keyof typeof ExpenseColumnDataType]
    };
  });

  function handleDeleteColumn(index: number) {
    if (columns.length < 2) {
      message.error('At least a column is needed to create a table');
      return;
    }

    setColumns((prev) => {
      const newColumns = [...prev];
      newColumns.splice(index, 1);
      form.setFieldsValue({ content: { column: newColumns } });
      return newColumns;
    });
  }

  function handleLinkChange(e: any) {
    const isChecked = e.target.checked;
    setLinkAccount(isChecked);

    if (!isChecked) {
      form.setFieldsValue({ debitAccountId: undefined });
    }

    if (type === 'update') {
      const hasDebitAccount = data?.debitAccountId;
      if (hasDebitAccount && isChecked) {
        return form.setFieldsValue({ debitAccountId: data?.debitAccountId });
      }
    }
  }

  return (
    <Spin spinning={isLoading || isParentLoading}>
      <Modal
        title="Confirmation"
        visible={showModal}
        onOk={onSubmit}
        onCancel={() => setShowModal(false)}>
        <div>Are you sure you want to {type} this table?</div>
      </Modal>

      <Modal
        title="Add Referential Columns"
        visible={showReferenceModal}
        onOk={() => handleAddColumn('reference')}
        onCancel={() => setShowReferenceModal(false)}>
        <Radio.Group
          onChange={(event) => setSelectedReference(event.target.value)}
          value={selectedReference}>
          <Space direction="vertical">
            {filterId.map((filter) => {
              const hasExisting = columns.find((col) => col.name === filter.key);
              return (
                <Radio
                  disabled={!!hasExisting}
                  value={filter.key}
                  key={`reference_id_${filter.key}`}>
                  {filter.label} ({filter.key}) {hasExisting && '(Already Exists)'}
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
      </Modal>

      <AppContent breadcrumbItems={breadcrumbItems}>
        <Form
          form={form}
          onValuesChange={handleFormChange}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <div className="flex gap-4 items-center flex-wrap justify-between">
            <PageHeader title="Category Information" style={{ padding: '8px 0px' }} />
            <div className="flex items-center gap-4">
              <Checkbox checked={linkAccount} onChange={handleLinkChange}>
                <span>Link Account</span>
              </Checkbox>
            </div>
          </div>

          <div className={'grid grid-cols-1 sm:grid-cols-3 gap-5 mb-5'}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: false, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Table Name"
              name={['content', 'tableName']}
              rules={[{ required: false, message: 'Please add table name' }]}>
              <Input />
            </Form.Item>

            <AccountsTypeSearch
              formData={{ formName: 'debitAccountId', label: 'Account' }}
              required={linkAccount}
              hidden={!linkAccount}
              isAll={false}
              onSelect={(value) => {
                form.setFieldsValue({ debitAccountId: value });
              }}
            />
          </div>

          {/* Drag and Drop Columns  */}
          <div className="mt-2">
            <Alert
              message="Information!"
              description="Please ensure column names follow either 'first_name' (with underscores) or 'firstName' (camelCase) format."
              type="info"
              closable
              showIcon
            />
          </div>

          {showColumns && (
            <>
              <PageHeader title="Table Columns" style={{ padding: '8px 0px' }} />
              <Space direction="vertical" style={{ width: '100%' }}>
                {columns.map((item: IExtendedColumn, index: number) => (
                  <div className="flex justify-between items-center gap-4" key={index}>
                    <div className="flex gap-4">
                      <div className="flex gap-4" key={index}>
                        <Form.Item
                          rules={[{ required: true, message: 'Please insert column name' }]}
                          label={`Column ${index + 1} name`}
                          name={['content', 'column', index, 'name']}>
                          <Input disabled={item.isDisabled} />
                        </Form.Item>

                        <Form.Item hidden name={['content', 'column', index, 'isDisabled']}>
                          <Checkbox checked={item.isDisabled} />
                        </Form.Item>

                        <Form.Item
                          label="Data Type"
                          rules={[{ required: true, message: 'Please choose dataType' }]}
                          name={['content', 'column', index, 'dataType']}>
                          <Select className="min-w-[10rem]" disabled={item.isDisabled}>
                            {dataTypeOptions.map((option) => (
                              <Select.Option key={option.value} value={option.value}>
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          initialValue={false}
                          className="flex justify-start"
                          label="Nullable"
                          name={['content', 'column', index, 'isNullable']}
                          valuePropName="checked">
                          <Input type="checkbox" />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="justify-end">
                      <Button
                        type="default"
                        icon={<DeleteOutlined />}
                        onClick={() => handleDeleteColumn(index)}
                      />
                    </div>
                  </div>
                ))}
              </Space>
            </>
          )}

          {/* Buttons */}
          <div className="flex justify-end mt-5 gap-4 flex-wrap">
            <Button
              type="default"
              onClick={() => {
                const isValid = verifyColumnData(columns);
                if (!isValid) return;

                setShowReferenceModal(true);
              }}
              className="!bg-black !text-white !border-transparent">
              Add Column with References
            </Button>

            <Button
              type="default"
              onClick={() => handleAddColumn('normal')}
              className="!bg-black !text-white !border-transparent">
              Add Column
            </Button>

            <Button type="primary" loading={isLoading} onClick={onFinalizeClick}>
              Finalize Table
            </Button>

            <Button type="default" htmlType="button" onClick={() => navigate('/expense-category')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default CategoryMutationUI;
