import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get_product_details, get_product_list } from '../../../services/products/queries';
import LocalStore from '../../localstorage';
import ProductsDB from '../../localstorage/ProductsDB';
import { RootState } from '../../store';

interface IProductsIntialState {
  products: any[];
  status: string;
}
const INITIAL_STATE: IProductsIntialState = {
  products: [],
  status: 'idle'
};
interface ProductsProps {
  skip: number;
  count: number;
  value: string;
}
export const getProducts = createAsyncThunk(
  'products/getProducts',
  async ({ skip, count, value }: ProductsProps = { skip: 0, count: 0, value: '' }) => {
    let fetchData: any = await ProductsDB.getAllProducts();
    if (fetchData.length < 1) {
      fetchData = await (await get_product_list(skip, count, value)).data.results;
      ProductsDB.addProducts(fetchData);
    }
    return await fetchData;
  }
);
export const getProduct = createAsyncThunk('product/getProduct', async (id: number) => {
  return await get_product_details(id);
});
export const updateProducts = createAsyncThunk('products/getProducts', get_product_details);
const productsSlice = createSlice({
  name: 'products',
  initialState: INITIAL_STATE,
  reducers: {
    removeProduct: (state, action: PayloadAction<number>) => {
      state.products = state.products.filter(function (obj) {
        return obj.id !== action.payload;
      });
      ProductsDB.deleteProduct(action.payload);
    },
    addProduct: (state, action: PayloadAction<any>) => {
      state.products = [...state.products, action.payload];
      ProductsDB.addProducts([action.payload]);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getProducts.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products = action.payload;
        state.status = 'success';
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.status = 'error';
      })
      .addCase(getProduct.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        // console.log(action);
        const objWithIdIndex = state.products.findIndex((obj) => obj.id === action.payload.id);
        state.products[objWithIdIndex] = action.payload;
        state.status = 'success';
        // ProductsDB.updateProduct(action.payload.product);
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.status = 'error';
      });
  }
});

export const selectAllProducts = (state: RootState) => state.products.products;
export const { removeProduct, addProduct } = productsSlice.actions;
export default productsSlice.reducer;
