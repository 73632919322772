import { Button, Divider, Form, message, Select } from 'antd';
import { useEffect, useState } from 'react';

import useDebounce from '@/hooks/useDebounce';
import UsersDB from '@/store/localstorage/UsersDB';
import VendorsDB from '@/store/localstorage/VendorDB';
import CustomersDB from '@/store/localstorage/CustomerDB';

import { get_customer_list, get_user_list, get_vendor_list } from '@/services/users/queries';
import { AgentsDB } from '@/store/localstorage/AgentsDB';

interface Props {
  type?: 'User' | 'Customer' | 'Vendor';
  formName?: string;
  label?: string;
  required?: boolean;
  inital?: boolean;

  onSelect: (value: string | number) => void;
}

type UserSearchType = 'initial' | 'more';

function UserWithNoAgent({ formName, label, required = true, onSelect, type = 'User' }: Props) {
  const [skip, setSkip] = useState(0);
  const [isMore, setIsMore] = useState(true);

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const [userSearch, setUserSearch] = useState<any>([]);
  const [isCacheResponse, setIsCacheResponse] = useState(false);

  useEffect(() => {
    searchUserIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);

  function getUserId(user: any) {
    return Number(type === 'User' ? user.id : user.user.id);
  }

  async function searchUserIndexDB(value: string) {
    try {
      const userSearchCallback =
        type === 'User'
          ? UsersDB.searchUser
          : type === 'Customer'
          ? CustomersDB.searchCustomer
          : VendorsDB.searchVendor;

      const users = (await userSearchCallback(value)) as (Record<string, any> & {
        isAgent: boolean;
      })[];
      if (!users || users.length == 0) {
        message.error('Cannot find any user with that value in cache, searching in server...');
        searchUser(value, 'initial');
        return;
      }

      const userIds = users.map(getUserId);
      const agents = await AgentsDB.getAgentsByUserIds(userIds);

      // Check if user is an agent
      if (agents.length > 0) {
        const userWithNoAgent = users.map((user) => {
          const isAgent = agents.find((agent) => agent.userId === getUserId(user));
          user.isAgent = Boolean(isAgent);
          return user;
        });

        setUserSearch(userWithNoAgent);
      }

      setUserSearch(users);
      setIsCacheResponse(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function searchUser(value: string, searchType: UserSearchType) {
    try {
      const currentSkip = searchType === 'initial' ? 0 : skip;

      const userSearchCallback =
        type === 'User' ? get_user_list : type === 'Customer' ? get_customer_list : get_vendor_list;

      const { data } = await userSearchCallback(currentSkip, 10, value);
      const isValidResponse = data && data.results.length > 0;
      if (!isValidResponse) {
        message.error('Cannot find any user with that name!');
        setIsMore(false);
        searchType === 'initial' && setUserSearch([]);
      }

      if (isValidResponse) {
        const userIds = data.results.map(getUserId);
        const agents = await AgentsDB.getAgentsByUserIds(userIds);

        data.results.forEach((user: any) => {
          const isAgent = agents.find((agent) => agent.userId === getUserId(user));
          user.isAgent = Boolean(isAgent);
        });

        searchType === 'initial'
          ? setUserSearch(data.results)
          : setUserSearch((prev: any) => [...prev, ...data.results]);

        UsersDB.addUsers(data.results);
        setIsMore(data.results.length >= 10);

        searchType === 'more' && setSkip(currentSkip + 10);
      }

      if (searchType === 'initial') {
        setIsCacheResponse(false);
        setSkip(10);
      }
    } catch (error) {
      message.error(error as string);
    }
  }

  const options = userSearch?.map((value: any) => {
    const user = type === 'User' ? value : value.user;
    return (
      <Select.Option key={user.id} value={user.id} disabled={value.isAgent}>
        {user.name}
      </Select.Option>
    );
  });

  return (
    <Form.Item
      label={label || 'Select User'}
      name={formName || 'userId'}
      rules={[{ required, message: 'Please select user!' }]}>
      <Select
        showSearch
        allowClear
        filterOption={false}
        placeholder="Search user"
        defaultActiveFirstOption={false}
        onSearch={(val) => setSearchValue(val)}
        notFoundContent={<div>No Content</div>}
        onChange={onSelect}
        dropdownMatchSelectWidth={false}
        dropdownRender={(menu) => {
          return (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              {isCacheResponse ? (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  <Button
                    type="text"
                    style={{ color: 'blue', width: '100%' }}
                    onClick={() => searchUser(searchValue, 'initial')}>
                    {'Pull More & Sync'}
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {isMore ? (
                    <Button
                      type="text"
                      style={{ color: 'blue', width: '100%' }}
                      onClick={() => searchUser(searchValue, 'more')}>
                      {'Get More...'}
                    </Button>
                  ) : (
                    <div style={{ width: '100%', textAlign: 'center' }}>No more data...</div>
                  )}
                </div>
              )}
            </>
          );
        }}>
        {options}
      </Select>
    </Form.Item>
  );
}

export default UserWithNoAgent;
