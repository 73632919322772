import AgentForm from '../AgentForm';

function CreateAgentPage() {
  const breadcrumbItem = [
    { label: 'Users', link: '/users' },
    { label: 'Agents', link: '/users/agents' },
    { label: 'Create Agents', link: '/users/agents/new' }
  ];

  return <AgentForm mode="create" breadcrumb={breadcrumbItem} />;
}

export default CreateAgentPage;
