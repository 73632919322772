import { useContext, useState } from 'react';
import { CustomModal } from '../../../../components/Common/CustomModal';
import ReturnInvoice from '../../../../components/Common/InvoicePrint/ReturnInvoice/ReturnInvoice';
import { PurchaseReturnListContext } from './context';
import { getPurchaseReturnPrintData } from '../../../../components/Common/InvoicePrint/ReturnInvoice/services';
import { ICreateInvoiceResponse } from '../../../../services/settings/types';
import { IReturnInvoice } from '../../../../services/invoice/types';
import InvoicePrintButton from '../../../../components/Common/InvoicePrintButton/InvoicePrintButton';

export const PurchaseReturnInvoicePrintModal: React.FC<{
  getInfo: (filter?: string) => Promise<any>;
  record: any;
}> = ({ getInfo, record }) => {
  const { setIsLoading } = useContext(PurchaseReturnListContext);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<IReturnInvoice>(Object);

  const generateInvoiceForPrint = async (record: any, curr: ICreateInvoiceResponse) => {
    setIsLoading(true);
    const invoiceModalData = await getPurchaseReturnPrintData(
      record.purchaseId,
      record.id,
      record.locationId,
      true,
      record.createdBy
    );
    setIsLoading(false);
    setInvoiceData({ ...invoiceModalData, invoiceLayouts: curr });
    setOpenModalForInvoicePrint(true);
  };
  const handleInvoicePrintModalClose = async () => {
    setOpenModalForInvoicePrint(false);
    await getInfo();
  };
  return (
    <>
      <InvoicePrintButton
        getInvoice={(curr) => {
          generateInvoiceForPrint(record, curr);
        }}
      />
      <CustomModal
        footer={false}
        isModalOpen={openModalForInvoicePrint}
        setIsModalOpen={setOpenModalForInvoicePrint}
        title="Purchase Return Invoice Print">
        <ReturnInvoice
          returnDetails={invoiceData.returnDetails}
          customerDetails={invoiceData.customerDetails}
          lines={invoiceData.lines}
          invoiceLayouts={invoiceData.invoiceLayouts}
          handleModalClose={handleInvoicePrintModalClose}
        />
      </CustomModal>
    </>
  );
};
