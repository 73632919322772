import { Button, Form, Input, PageHeader, Switch, message } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import AppContent from '../../../../../components/Common/Content/Content';
import { create_user_type_mutation } from '../../../../../services/user-types/mutations';
import { IUserType, IUserTypeCreateRequest } from '../../../../../services/user-types/types';
import { AxiosError, AxiosResponse } from 'axios';
import { IServerError } from '../../../../../services/response/types';

const CreateUserType = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsloading] = useState<boolean>(false);

  const createUserTypeMutation = useMutation<
    AxiosResponse<IUserType>,
    AxiosError<IServerError>,
    IUserTypeCreateRequest
  >(create_user_type_mutation);

  const onFinish = async (values: IUserTypeCreateRequest) => {
    try {
      setIsloading(true);
      await createUserTypeMutation.mutate(values, {
        onSuccess: () => {
          setIsloading(false);
          message.success('User Category added successfully');
          navigate('/users/user-type');
        },
        onError: (error) => {
          setIsloading(false);
          message.error(`${error.response?.data.message}`);
        }
      });
    } catch (errors) {
      setIsloading(false);
    }
  };

  const breadcrumbItems = [
    {
      label: 'User Category',
      link: '/users/user-type'
    },
    {
      label: 'Create',
      link: '/users/user-type/new'
    }
  ];

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Form
          form={form}
          initialValues={{ hidden: false }}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="User Category "
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 gap-3 mb-3 sm:grid-cols-3 gap-5 mb-5'}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/users/user-type')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </div>
  );
};

export default CreateUserType;
