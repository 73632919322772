import { useEffect, useState } from 'react';
import {
  IAccountDuplicateMultiple,
  IAccountDuplicateWithChildren,
  IAccountTypeResponseData,
  IAccountTypeTableData,
  ICreateAccounts
} from '../../../services/accounts/types';
import { Button, Divider, Form, Input, InputNumber, PageHeader, Spin, message } from 'antd';
import LocationSearchMultiple from '../LocationSearch/LocationSearchMultiple';
import { nepaliNumberFormatter } from '../../../utils/numberFormatter';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  create_duplicate_account_with_children_mutation,
  create_multiple_account_mutation
} from '../../../services/accounts/mutations';
import { AccountsTypeSearch } from '../AccountsTypeSearch';
import { DoubleRightOutlined } from '@ant-design/icons';
import CustomButton from '../CustomButton/CustomButton';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import { get_location_list, get_location_list_for_ids } from '../../../services/locations/queries';
import { LocationSearch } from '../LocationSearch/LocationSearch';

interface ModalProps {
  data: IAccountTypeTableData;
  handleModalClose: () => void;
}
const ReusableAccountDuplicationWithChildrenModal: React.FC<ModalProps> = ({
  data,
  handleModalClose
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [parentAccountList, setParentAccountList] = useState<IAccountTypeResponseData[]>([]);
  const [locationId, setLocationId] = useState<number>();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue(['locationIds'], []);
    form.resetFields(['lines']);
  }, [data]);

  const onFinish = async (values: IAccountDuplicateWithChildren) => {
    setIsLoading(true);
    // console.log('Values', values);
    try {
      if (data.locationId === values.locationId) {
        throw {
          name: 'Location Error',
          message: 'Please select a different location from the original!'
        };
      }
      const mutationObj = {
        locationId: values.locationId,
        parentId: values.parentId,
        accountId: data.id
      };
      await createAccountsDuplicationWithChildrenMutation.mutateAsync(mutationObj);
    } catch (errors: any) {
      setIsLoading(false);
      message.error(errors.message);
    }
  };

  const createAccountsDuplicationWithChildrenMutation = useMutation(
    create_duplicate_account_with_children_mutation,
    {
      onSuccess: () => {
        setIsLoading(false);
        message.success('Account duplicated successfully');
        handleModalClose();
        setIsLoading(false);
      },
      onError: (e: any) => {
        setIsLoading(false);
        message.error(`${e.response.data.message}`);
      }
    }
  );

  const handleLocationChange = () => {
    // console.log('locationId', form.getFieldValue(['locationId']));
    form.resetFields(['parentId']);
    setLocationId(form.getFieldValue(['locationId']));
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        initialValues={{ locationIds: [] }}
        onFinish={onFinish}
        disabled={isLoading}
        layout="vertical"
        validateTrigger={'onChange'}
        autoComplete="off">
        <PageHeader
          subTitle="Duplicate From"
          style={{
            padding: '2px 0px'
          }}
        />
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div>Location: {data.locationName}</div>
          <div>Type: {data.type}</div>
          <div>Name: {data.name}</div>
          <div>Parent Account: {data.parentName}</div>
          <div>Balance: {nepaliNumberFormatter(data.balance)}</div>
          <div>Created By: {data.createdByName}</div>
        </div>
        <Divider />
        <PageHeader
          subTitle="Duplicate To"
          style={{
            padding: '2px 0px'
          }}
        />

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <LocationSearch
            formData={{ formLabel: 'Location', formName: ['locationId'] }}
            notAll={true}
            required={true}
            onSelect={() => handleLocationChange()}
          />
          <AccountsTypeSearch
            formData={{
              formName: ['parentId'],
              label: 'Parent Account'
            }}
            isAll={false}
            accountList={parentAccountList}
            setAccountList={setParentAccountList}
            required={true}
            locationId={locationId}
          />
        </div>

        <div className="flex justify-end mt-5">
          <Button type="primary" onClick={form.submit}>
            Submit
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

export default ReusableAccountDuplicationWithChildrenModal;
