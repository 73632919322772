import moment from 'moment';
import AppContent from '@/components/Common/Content/Content';
import { Alert, Collapse, Form, PageHeader, Spin, message } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { useState } from 'react';
import FilterForm from './Form';

import TableData from './Table';
import { get_report } from '@/services/report/queries';
import { IProductRateCompare, IProductRateReport } from '@/services/report/types';

import { getDateRangeFromSingle } from '@/utils/getDateRangeFromSingle';
import roundNumber from '@/utils/round.utils';
import getGroupedData from '@/utils/getGroupedData';

export type FormValues = {
  identifier: string;
  unitId: string | number;
  productId: string | number;
  locationId: string | number;
  firstDate?: moment.Moment;
  secondDate?: moment.Moment;
  page: number;
  size: number;
};

export interface ICustomProductRate extends IProductRateReport {
  type: string;
}

function ProductRateComparisonReport() {
  const [form] = Form.useForm();
  const breadcrumbItems = [
    { label: 'Reports', link: '/reports' },
    { label: 'Product Rate Comparison Report', link: '/reports/product-rate-comparison' }
  ];

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([] as IProductRateCompare[]);
  const [selectedDate, setSelectedDate] = useState<[string, string]>(['', '']);

  const state = { id: 64, name: 'Produc Rate Report' };
  const defaultUnitState = { id: 68, name: 'Product Rate Report' };

  const extendedInitialValues: FormValues = {
    unitId: 'default',
    productId: '',
    locationId: '',
    identifier: '',
    page: 1,
    size: -1
  };

  async function onSearch(constraints: FormValues) {
    setIsLoading(true);

    try {
      // Get ReportId based on Unit Select
      const isDefaultUnitId = constraints.unitId === 'default';
      const reportId = isDefaultUnitId ? defaultUnitState.id : state.id;

      const [firstDate, secondDate] = [constraints.firstDate, constraints.secondDate].sort(
        (a, b) => moment(a).unix() - moment(b).unix()
      );

      if (!firstDate || !secondDate) return message.error('Please select both dates');
      const firstUTC = moment(firstDate).format('YYYY-MM-DD');
      const secondUTC = moment(secondDate).format('YYYY-MM-DD');

      if (firstUTC === secondUTC) {
        message.error('Please select different dates');
        return;
      }

      setSelectedDate([firstUTC, secondUTC]);

      const firstDateRange = getDateRangeFromSingle(firstDate);
      const secondDateRange = getDateRangeFromSingle(secondDate);

      const newPayload = { ...constraints, unitId: isDefaultUnitId ? '' : constraints.unitId };
      delete newPayload.firstDate;
      delete newPayload.secondDate;

      const firstPromise = get_report({ ...newPayload, ...firstDateRange }, reportId).then(
        (r) => r.data as ICustomProductRate[]
      );

      const secondPromise = get_report({ ...newPayload, ...secondDateRange }, reportId).then(
        (r) => r.data as ICustomProductRate[]
      );

      const response = await Promise.all([firstPromise, secondPromise]);
      const firstDateResponse = response[0].map((item) => ({ ...item, type: 'firstDate' }));
      const secondDateResponse = response[1].map((item) => ({ ...item, type: 'secondDate' }));

      const mergedData = [...firstDateResponse, ...secondDateResponse];
      const groupedByProduct = getGroupedData(mergedData, 'Product');

      const tableData = Object.keys(groupedByProduct).map((key) => {
        const data = groupedByProduct[key];
        const productDetails = data[0];
        const firstDateProducts = data.filter((item) => item.type === 'firstDate');
        const secondDateProducts = data.filter((item) => item.type === 'secondDate');

        const firstDateTotalPrice = firstDateProducts.reduce((acc, item) => {
          acc += parseFloat(item['AVG PRICE']);
          return acc;
        }, 0);

        const secondDateTotalPrice = secondDateProducts.reduce((acc, item) => {
          acc += parseFloat(item['AVG PRICE']);
          return acc;
        }, 0);

        const firsDateAvgPrice =
          firstDateProducts.length > 0
            ? roundNumber(firstDateTotalPrice / firstDateProducts.length)
            : firstDateTotalPrice;

        const secondDateAvgPrice =
          secondDateProducts.length > 0
            ? roundNumber(secondDateTotalPrice / secondDateProducts.length)
            : secondDateTotalPrice;

        const difference = secondDateAvgPrice - firsDateAvgPrice;
        const differencePercentage = (difference / Math.max(firsDateAvgPrice, 1)) * 100;

        return {
          id: `${productDetails.Product}-${productDetails.Unit}`,
          product: productDetails.Product,
          unit: productDetails.Unit,
          priceFirstDate: firsDateAvgPrice,
          priceSecondDate: secondDateAvgPrice,
          difference: Math.min(roundNumber(differencePercentage), 100)
        } as IProductRateCompare;
      });

      setData(tableData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Collapse>
          <CollapsePanel header="Filters" key="1">
            <FilterForm
              form={form}
              isLoading={isLoading}
              initialValues={extendedInitialValues}
              onSearch={onSearch}
            />
          </CollapsePanel>
        </Collapse>

        <Alert
          style={{ margin: '8px 0px' }}
          type="info"
          showIcon
          message='When the "All with Default Unit" option is selected, all product prices are based on the default unit of the selected products.'
        />

        <PageHeader subTitle="Table" style={{ padding: '8px 0px' }} />

        <TableData data={data} selectedDate={selectedDate} />
      </AppContent>
    </Spin>
  );
}

export default ProductRateComparisonReport;
