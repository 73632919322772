import { useState } from 'react';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { DndContext, closestCorners, useSensor, useSensors, DragOverEvent } from '@dnd-kit/core';

import { MouseSensor, TouchSensor } from './DndSensor';
import { useDashboardCustomize } from '@/contexts/dashboard-customize.context';
import WidgetCustomizeLayout from '../WidgetCustomizeLayout';
import { IWidgetConfiguration } from '@/services/dashboard/v3/types';
import WidgetCustomizeModalv2 from '../modal/v2/WidgetCustomizeModalv2';

interface Props {
  gridCols: number;
}

function DraggableGrid({ gridCols }: Props) {
  const { editingWidgets, setEditingWidgets } = useDashboardCustomize();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const [openEditModal, setOpenEditModal] = useState(false);

  const [selectedWidget, setSelectedWidget] = useState<{
    id: string;
    position: number;
    isCustom: boolean;
  } | null>(null);

  function handleDragOver(event: DragOverEvent) {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    setEditingWidgets((widgets) => {
      const originalPosition = widgets.findIndex((item) => item.id === active.id);
      const newPosition = widgets.findIndex((item) => item.id === over.id);
      return arrayMove(widgets, originalPosition, newPosition);
    });
  }

  function resetSelectedWidget() {
    setSelectedWidget(null);
  }

  function onEditWidget(widget: IWidgetConfiguration, position: number) {
    setSelectedWidget({ id: widget.id, position, isCustom: widget.isCustom });
    setOpenEditModal(true);
  }

  return (
    <div>
      <WidgetCustomizeModalv2
        open={openEditModal}
        key={selectedWidget?.id}
        setOpen={setOpenEditModal}
        selectedWidget={selectedWidget}
        resetSelectedWidget={resetSelectedWidget}
      />
      <DndContext sensors={sensors} collisionDetection={closestCorners} onDragOver={handleDragOver}>
        <SortableContext
          items={editingWidgets.map((widget) => widget.id)}
          strategy={rectSortingStrategy}>
          <div
            className="grid gap-4 draggable-grid"
            style={{ gridTemplateColumns: `repeat(${gridCols}, 1fr)` }}>
            {editingWidgets.map((widget, index) => (
              <WidgetCustomizeLayout
                key={widget.id}
                widget={widget}
                position={index}
                column={widget.colSize}
                isCustom={widget.isCustom}
                onEdit={() => onEditWidget(widget, index)}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
}

export default DraggableGrid;
