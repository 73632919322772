import { data } from 'autoprefixer';
import React, { useEffect } from 'react';
import { createDocumentRegistry } from 'typescript';

export interface IColumnDataForCustomTable {
  dataIndex: string;
  label: string;
  render: (x: any) => any;
  width?: number;
  hasRowSpan: boolean;
}

export interface IcustomTable {
  columns: IColumnDataForCustomTable[];
  datas: any[];
  reff?: any;
  onAuto?: () => void;
  title?: any;
  serialNumber?: boolean;
  total?: any;
}

const CustomTableRowSpan: React.FC<IcustomTable> = ({
  columns,
  datas,
  reff,
  title,
  onAuto,
  serialNumber = true,
  total
}) => {
  useEffect(() => {
    if (onAuto) {
      onAuto();
    }
  }, [datas]);

  return (
    <div className="flex flex-col justify-center items-center" ref={reff}>
      {title && <h3>{title}</h3>}
      <table width={'99%'}>
        <tr>
          {serialNumber && (
            <th style={tdthStyle}>
              <div className="text-center">S.N.</div>
            </th>
          )}
          {columns.map((currCol: any) => (
            <th key={currCol.label} style={tdthStyle}>
              <div className="text-center">{currCol.label}</div>
            </th>
          ))}
        </tr>
        {datas.map((currData: any, indr: number) => {
          return (
            <tr key={`${currData}${indr}`}>
              {serialNumber && currData.rows > 0 && (
                <td style={tdthStyle} rowSpan={currData.rows} colSpan={1}>
                  <div className="text-center">{currData.sn}</div>
                </td>
              )}
              {columns.map((currCol: any, indc: number) => {
                return currCol.hasRowSpan && currData.rows > 0 ? (
                  <td key={indr + indc} style={tdthStyle} rowSpan={currData.rows} colSpan={1}>
                    {currCol.render(currData[currCol.dataIndex])}
                  </td>
                ) : !currCol.hasRowSpan ? (
                  <td key={indr + indc} style={tdthStyle} rowSpan={1} colSpan={1}>
                    {currCol.render(currData[currCol.dataIndex])}
                  </td>
                ) : null;
              })}
            </tr>
          );
        })}
        {total && (
          <tr style={tdthStyle}>
            <td colSpan={1} style={tdthStyle} />
            <td colSpan={1} style={tdthStyle} />
            <td colSpan={1} style={tdthStyle}>
              <div className="text-center font-bold">Total</div>
            </td>
            <td colSpan={1} style={tdthStyle}>
              <div className="text-center font-bold">{total.totalDebit}</div>
            </td>
            <td colSpan={1} style={tdthStyle}>
              <div className="text-center font-bold">{total.totalCredit}</div>
            </td>
          </tr>
        )}
      </table>
    </div>
  );
};

const tdthStyle = {
  border: '1px solid black',
  padding: '2px 0px'
};

export default CustomTableRowSpan;
