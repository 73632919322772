import generator from 'generate-password';

export const generatePassword = (length: number) => {
  const password = generator.generate({
    length: length,
    numbers: true,
    symbols: true,
    excludeSimilarCharacters: true
  });
  console.log(password);
  return password;
};
