import { Button, Checkbox, Dropdown, Form, Menu, Pagination, Table, Tooltip } from 'antd';
import React, { useRef, useState } from 'react';
import './index.css';
import { find_column_filter } from '../../../store/localstorage/preferences';
import { getUserData } from '../../../utils/auth.utils';
import { ITableFilter, IUserPreferences } from '../../../services/settings/types';
import { setLocalStorage } from '../../../utils/storage.utils';
import { useMutation } from '@tanstack/react-query';
import { USER_INFO } from '../../../constants/config';
import { FilterFilled } from '@ant-design/icons';
import { update_preference_user_mutation } from '../../../services/users/mutations';
import { tableFilterPrefUpdate } from '../../../utils/tableFilterPrefUpdate.utils';
import CustomErrorModal from '../CustomErrorModal';

interface paginationData {
  page: number;
  total: number;
  size: number;
  onPagination: (pageNo: number, totalSize: number, isSize?: boolean) => void;
  scrollToTop?: boolean;
}

interface Tableprops {
  columns: any[];
  tableName?: string;
  data: any[];
  customScroll?: { x?: string | number | true | undefined; y?: string | number | undefined };
  usersLoading?: any;
  footer?: any;
  notshowPagination?: boolean;
  paginationDatas?: paginationData;
  toSort?: any;
  rowSelection?: any;
  rowSelectionWidth?: string;
  tableSummary?: React.ReactNode;
  tableSummaryForFilter?: { index: number; render: JSX.Element | string }[];
  buttons?: React.ReactNode;
  showPager?: boolean;
  // onPagination?: (pageNo: number, totalSize: number, isSize?: boolean) => void;
}

const CustomizeTable: React.FC<Tableprops> = ({
  columns,
  tableName,
  data,
  usersLoading,
  footer,
  notshowPagination = false,
  paginationDatas,
  customScroll = { x: 1000, y: '75vh' },
  toSort,
  rowSelection,
  rowSelectionWidth = '30px',
  tableSummary,
  tableSummaryForFilter,
  buttons,
  showPager = true
  // onPagination
}) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const userData = getUserData();
  const columnFilter = find_column_filter(tableName);
  const defaultColumn = columns.map((value) => value.key);
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnFilter.length > 0 ? columnFilter : defaultColumn
  );

  const filterMenu = (
    <Menu className="ant-dropdown-menu max-h-[20rem] overflow-scroll">
      {columns.map((column) => {
        return (
          <Menu.ItemGroup key={column.key}>
            <Checkbox
              checked={filteredColumns.includes(column.key)}
              onChange={async (e) => {
                let updatedFilterData: string[] = [];
                if (e.target.checked) {
                  setFilteredColumns((prev) => {
                    updatedFilterData = [...prev, column.key];
                    return updatedFilterData;
                  });
                } else {
                  setFilteredColumns((prev) => {
                    updatedFilterData = prev.filter((val) => val !== column.key);
                    return updatedFilterData;
                  });
                }

                await updateTableFilterPref(updatedFilterData);
              }}>
              {column.title}
            </Checkbox>
          </Menu.ItemGroup>
        );
      })}
    </Menu>
  );

  const updateTableFilterPref = async (updatedFilterData: string[]) => {
    if ('preferences' in userData && userData.preferences) {
      const defaultLocalStoragePref: string = userData.preferences.preferences;
      try {
        let newTableFilter: ITableFilter[] = [];
        const localStoragePref: IUserPreferences = JSON.parse(userData.preferences.preferences);
        if (
          localStoragePref &&
          'tableFilters' in localStoragePref &&
          localStoragePref.tableFilters
        ) {
          const selectedFilterList = localStoragePref.tableFilters.find(
            (value) => value.tableKey === tableName
          );
          if (selectedFilterList) {
            const updatedTableFilter: ITableFilter[] = localStoragePref.tableFilters.map(
              (value) => {
                if (value.tableKey === tableName) {
                  return { tableKey: tableName, filteredKey: updatedFilterData };
                } else {
                  return value;
                }
              }
            );

            newTableFilter = updatedTableFilter;
          } else {
            const updatedTableFilter = [
              ...localStoragePref.tableFilters,
              { tableKey: tableName, filteredKey: updatedFilterData }
            ];

            newTableFilter = updatedTableFilter;
          }
        } else {
          newTableFilter = [{ tableKey: tableName, filteredKey: updatedFilterData }];
        }
        const updatedPref = { ...localStoragePref, tableFilters: newTableFilter };
        setLocalStorage(USER_INFO, {
          ...userData,
          preferences: {
            ...userData.preferences,
            preferences: JSON.stringify(updatedPref)
          }
        });
        await tableFilterPrefUpdate(
          async () =>
            await updatePreferencesMutation.mutateAsync({
              id: userData.id,
              value: JSON.stringify(updatedPref)
            }),
          tableName
        );
      } catch (error) {
        setLocalStorage(USER_INFO, {
          ...userData,
          preferences: {
            ...userData.preferences,
            preferences: defaultLocalStoragePref
          }
        });
      }
    } else {
      CustomErrorModal({
        title: 'Error',
        message: (
          <div>
            Default Preference has not been set! Please set it in <b>Settings/Preferences</b> first.
          </div>
        )
      });
    }
  };

  const updatePreferencesMutation = useMutation(update_preference_user_mutation, {
    onSuccess: () => {
      console.log('mutated table filter');
    }
  });

  const scrollToTop = () => {
    if (tableRef.current) {
      const tableBody = tableRef.current.querySelector('.ant-table-body');
      if (tableBody) {
        tableBody.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  return (
    <>
      {(tableName || buttons) && (
        <div className="flex w-full justify-between mb-2">
          <div className="grid grid-cols-5 mt-2">
            {tableName && (
              <Form.Item name="ColumnFilter">
                <Dropdown overlay={filterMenu} trigger={['click']}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Tooltip title="Table Filter">
                      <Button type="primary" className="!rounded-md" icon={<FilterFilled />}>
                        Filter
                      </Button>
                    </Tooltip>
                  </a>
                </Dropdown>
              </Form.Item>
            )}
          </div>
          {buttons && <div className="flex gap-2 my-2">{buttons}</div>}
        </div>
      )}

      <div ref={tableRef}>
        <Table
          scroll={customScroll}
          // scroll={{ x: 1000, y: '75vh' }}
          columns={
            tableName && columnFilter.length > 0
              ? columns.filter((value) => {
                  if (value.key) return columnFilter.includes(value.key.toString());
                })
              : columns
          }
          pagination={
            notshowPagination
              ? false
              : {
                  showSizeChanger: true
                }
          }
          rowSelection={
            rowSelection && {
              type: 'checkbox',
              ...rowSelection,
              columnWidth: rowSelectionWidth
            }
          }
          rowKey={'id'}
          dataSource={data ? data : []}
          loading={usersLoading}
          footer={footer}
          size="small"
          bordered={true}
          onChange={toSort}
          summary={(pageData) => {
            if (tableSummary) return tableSummary;
            const columnToMap = columnFilter.length > 0 ? columnFilter : defaultColumn;
            if (tableName && columnToMap.length > 0 && tableSummaryForFilter) {
              return (
                <Table.Summary fixed={true}>
                  <Table.Summary.Row>
                    {columns.map((value, index) => {
                      if (value.key && columnToMap.includes(value.key.toString())) {
                        const renderData = tableSummaryForFilter.find(
                          (value) => value.index === index
                        );
                        if (renderData) {
                          return (
                            <Table.Summary.Cell
                              key={index}
                              index={renderData.index}
                              className="text-right mr-2 text-xs">
                              {renderData.render}
                            </Table.Summary.Cell>
                          );
                        } else {
                          return (
                            <Table.Summary.Cell key={index} index={index}></Table.Summary.Cell>
                          );
                        }
                      }
                    })}
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }
            return null;
          }}
        />
      </div>
      {paginationDatas && (
        <div className="flex justify-end mt-4">
          <Pagination
            className={showPager ? '' : 'hide-pager'}
            current={paginationDatas?.page}
            total={paginationDatas?.total}
            pageSize={paginationDatas?.size}
            pageSizeOptions={[10, 20, 50, 100, 500, 2000]}
            onChange={(pageNo, pageSize) => {
              if (pageSize == paginationDatas?.size) {
                //code for page change
                paginationDatas.onPagination(pageNo, pageSize);
              } else {
                //code for size change
                paginationDatas.onPagination(pageNo, pageSize, true);
              }
              if (paginationDatas.scrollToTop) {
                scrollToTop();
              }
            }}
          />
        </div>
      )}
    </>
  );
};

export default CustomizeTable;
