import moment from 'moment';

import { Select, message } from 'antd';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IFinancialYear } from '@/services/financial-year/types';
import { FinancialYearDB } from '@/store/localstorage/FinancialYearDB';

function FinancialYear() {
  const [financialYear, setFinancialYear] = useState<IFinancialYear[]>([]);
  const [year, setYear] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();

  useLayoutEffect(() => {
    (async () => {
      try {
        const years = await FinancialYearDB.getAll();
        const currentFinancialYear = await FinancialYearDB.getCurrent();

        // Sort the years in descending order
        years.sort((a, b) => b.name.localeCompare(a.name));

        // Format the date
        years.forEach((year) => {
          year.startDate = moment(year.startDate).format('YYYY-MM-DD');
          year.endDate = moment(year.endDate).format('YYYY-MM-DD');
        });
        setFinancialYear(years);
        setYear(currentFinancialYear?.name || '');
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  function isDateWithinFinancialYear(
    financial: { start: string; end: string },
    selected: { start: string | null; end: string | null }
  ) {
    const financialStartDate = moment(financial.start);
    const financialEndDate = moment(financial.end);
    const selectedStartDate = moment(selected.start);
    const selectedEndDate = moment(selected.end);

    if (!selectedStartDate.isValid() || !selectedEndDate.isValid()) return false;

    return (
      selectedStartDate.isSameOrAfter(financialStartDate) &&
      selectedEndDate.isSameOrBefore(financialEndDate)
    );
  }

  useEffect(() => {
    (async () => {
      const currentFinancialYear = await FinancialYearDB.getCurrent();

      const searchParams = new URLSearchParams(search);
      // Set current financial year if not set
      searchParams.set('csd', currentFinancialYear.startDate);
      searchParams.set('ced', currentFinancialYear.endDate);

      const hasStartDate = searchParams.get('fsd');
      const hasEndDate = searchParams.get('fed');
      const hasSelectedStartDate = searchParams.get('startDate');
      const hasSelectedEndDate = searchParams.get('endDate');

      const hasBoth = hasStartDate && hasEndDate;
      const hasBothSelectedDate = hasSelectedStartDate && hasSelectedEndDate;

      const selectedDate = { start: hasSelectedStartDate, end: hasSelectedEndDate };

      // If yes, preserve that state
      if (hasBoth) {
        const currentYear = await FinancialYearDB.getYearByDate(hasStartDate, hasEndDate);
        if (currentYear) {
          setYear(currentYear.name);
          if (!hasBothSelectedDate) return;

          const isValidSelected = isDateWithinFinancialYear(
            { start: currentYear.startDate, end: currentYear.endDate },
            { start: selectedDate.start, end: selectedDate.end }
          );

          if (isValidSelected) return;
          searchParams.delete('startDate');
          searchParams.delete('endDate');
          setSearchParams(searchParams);
        }
      }

      // If no financial year is selected, select the previus year
      if (year) {
        const currentYear = financialYear.find((y) => y.name === year);
        if (currentYear) {
          const isValidSelected = isDateWithinFinancialYear(
            { start: currentYear.startDate, end: currentYear.endDate },
            { start: selectedDate.start, end: selectedDate.end }
          );

          if (!isValidSelected) {
            searchParams.delete('startDate');
            searchParams.delete('endDate');
          }

          searchParams.set('fsd', currentYear.startDate);
          searchParams.set('fed', currentYear.endDate);
          setSearchParams(searchParams);
          return;
        }
      }

      if (currentFinancialYear) {
        setYear(currentFinancialYear.name);
        const isValidSelected = isDateWithinFinancialYear(
          { start: currentFinancialYear.startDate, end: currentFinancialYear.endDate },
          { start: selectedDate.start, end: selectedDate.end }
        );

        if (!isValidSelected) {
          searchParams.delete('startDate');
          searchParams.delete('endDate');
        }
        searchParams.set('fsd', currentFinancialYear.startDate);
        searchParams.set('fed', currentFinancialYear.endDate);
        setSearchParams(searchParams);
      }
    })();
  }, [search]);

  const handleYearChange = (selectedYear: string) => {
    const year = financialYear.find((year) => year.name === selectedYear);
    if (!year) {
      message.error('Please select a year');
      return;
    }

    setYear(selectedYear);

    searchParams.set('fsd', year.startDate);
    searchParams.set('fed', year.endDate);
    setSearchParams(searchParams);
  };

  return (
    <Select
      value={year}
      placeholder="Select Financial Year"
      onChange={handleYearChange}
      className="w-full">
      {financialYear.map((year) => (
        <Select.Option key={year.name} value={year.name}>
          {year.name}
        </Select.Option>
      ))}
    </Select>
  );
}

export default FinancialYear;
