export const CapitalizeEachWord = (text: string) => {
  const words = text.trim().split(' ');
  const capitalizedText: string[] = [];
  for (let i = 0; i < words.length; i++) {
    if (words[i].length > 0) {
      capitalizedText.push(words[i][0].toUpperCase() + words[i].substr(1));
    }
  }

  return capitalizedText.join(' ');
};
