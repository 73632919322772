import moment from 'moment';
import { ICustomSellReport } from '@/services/report/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';

export const tableExportColumns = [
  {
    label: 'DATE',
    dataIndex: 'DATE',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'TIME',
    dataIndex: 'TIME',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'INVOICE NO.',
    dataIndex: 'INVOICE NO.',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'CUSTOMER',
    dataIndex: 'CUSTOMER',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'ROUTE NO.',
    dataIndex: 'ROUTE NO.',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'GROSS',
    dataIndex: 'GROSS',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'RETURN',
    dataIndex: 'RETURN',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'DISCOUNT',
    dataIndex: 'DISCOUNT',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'NET SALE',
    dataIndex: 'NET SALE',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'VAT',
    dataIndex: 'VAT',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'TOTAL',
    dataIndex: 'TOTAL',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'TOTAL PAYMENT',
    dataIndex: 'TOTAL PAYMENT',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'PAYMENT MODE',
    dataIndex: 'PAYMENT MODE',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'USER',
    dataIndex: 'USER',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData(data: ICustomSellReport[]) {
  return data.map((item) => {
    return {
      ...item,
      DATE: convertUTCStringtoLocalString(item.DATE, 'YYYY-MM-DD'),
      TIME: moment(item.TIME, 'HH:mm').format('hh:mm a')
    };
  });
}
