import { Button, Collapse, Form, FormInstance, message, PageHeader, Spin } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import React, { useState } from 'react';
import AppContent from '@/components/Common/Content/Content';

import { download_report, get_report } from '@/services/report/queries';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { getInt } from '../pending-payment-sell-report/report';
import { check_date_cap, check_export_report_date_cap } from '@/utils/common';
import { showReportConditionalDownloadMessage } from '@/utils/reportConditionalDownloadMessage';
import { excelExportColumns, getUpdatedData, tableExportColumns } from './column.export';
import ExportCurrent from '@/components/Common/ExportCurrent';
import { IOpeningStockReport } from '@/services/report/types';
import GenericTable from '@/components/Common/CustomizeTable';

interface FooterProps {
  totalAmount: number;
}

interface State {
  id: number;
  name: string;
}
interface ReportProps {
  state: State;
  breadcrumbItems: any[];
  form: FormInstance<any>;

  columnsData?: any[];
  toDownload?: boolean;
  toSort: any;
}

const CustomReport: React.FC<ReportProps> = ({
  state,
  breadcrumbItems,
  form,
  columnsData,
  children,
  toDownload,
  toSort
}) => {
  const [columns] = useState<any[]>([]);
  const [openingStock, setOpeningStock] = useState<{ count: number; data: IOpeningStockReport[] }>({
    count: 0,
    data: []
  });
  const [footer, setFooterData] = useState<FooterProps>({
    totalAmount: 0
  });
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState(100);
  const [isloading, setIsloading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const onSearch = async (values: any, storePage = false) => {
    setIsloading(true);
    if (check_date_cap(values.constraints.startDate, values.constraints.endDate)) {
      await downloadPdf();
      setIsloading(false);
      return;
    }
    if (!storePage) {
      values.constraints['page'] = 1;
      values.constraints['size'] = size;
      setPage(1);
    }
    //remove custom date
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    const response = await get_report({ ...values.constraints }, state.id);
    response.data.data = await calculateTotal(response.data.data);

    if (response.data.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }

    setOpeningStock(response.data);
    setIsloading(false);
  };

  const calculateTotal = async (data: any) => {
    let totalAmount = 0;

    for (let index = 0; index < data.length; index++) {
      totalAmount += getInt(data[index].total_amount);
    }

    setFooterData({
      totalAmount: totalAmount
    });
    return data;
  };
  const addPage = async (value: number, sizeVal = 0) => {
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();
      if (sizeVal !== 0) {
        allValues.constraints.page = 1;
        allValues.constraints.size = sizeVal;
        setPage(1);
        setSize(sizeVal);
      } else {
        allValues.constraints.page = value;
        allValues.constraints.size = size;
        setPage(value);
      }
      onSearch(allValues, true);
    } catch (e: any) {
      console.log('validate error', e);
    }
  };

  const downloadPdf = async () => {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsloading(true);
    const values = form.getFieldsValue();
    await form.validateFields();
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    try {
      if (check_export_report_date_cap(values.constraints.startDate, values.constraints.endDate)) {
        // const response =
        values.constraints.customReportQueueName = 'Opening Stock Report';
        message.info({
          key: 'downloading',
          content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
        });
        await download_report({ ...values.constraints, size: -1 }, state.id);
        setIsloading(false);
        // window.open(response.data, '_blank');
      }
    } catch (err: any) {
      setIsloading(false);
      message.error(err.message);
    }
  };

  const updatedData = getUpdatedData(openingStock.data);

  return (
    <div>
      <Spin spinning={isloading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <Collapse>
            <CollapsePanel header="Filters" key="1">
              <Form
                form={form}
                onFinish={onSearch}
                layout="vertical"
                validateTrigger={'onChange'}
                autoComplete="off"
                disabled={isloading}>
                {React.Children.count(children) > 1 && (
                  <>
                    <PageHeader
                      subTitle="Constraints"
                      style={{
                        padding: '0px 0px'
                      }}
                    />
                  </>
                )}
                <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 ">
                  {children}
                  <div className="flex justify-start mt-7 gap-2">
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
                        Search
                      </Button>
                    </Form.Item>
                    {toDownload ? (
                      <div>
                        <Form.Item>
                          <Button
                            className="secondary-button"
                            style={{ borderRadius: '6px' }}
                            onClick={downloadPdf}>
                            Download
                          </Button>
                        </Form.Item>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Form>
            </CollapsePanel>
          </Collapse>
          <PageHeader
            subTitle="Table"
            style={{
              padding: '8px 0px'
            }}
          />
          <GenericTable
            scroll={{ x: 1600, y: 500 }}
            tableName="opening-stock-report-table"
            hideDefaultPagination
            toSort={toSort}
            columns={columnsData ? columnsData : columns}
            data={openingStock.data}
            generateSummary
            buttons={
              <>
                <ExportCurrent
                  data={{ total: openingStock.data.length, results: updatedData }}
                  columns={tableExportColumns}
                  excelColumns={excelExportColumns}
                  title="Opening Stock Report"
                />
              </>
            }
            pagination={{
              page,
              total: openingStock.count,
              size,
              onPagination: (pageNo, currentSize) => {
                currentSize == size ? addPage(pageNo) : addPage(pageNo, currentSize);
              }
            }}
          />
        </AppContent>
      </Spin>
    </div>
  );
};

export default CustomReport;
