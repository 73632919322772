import { AxiosResponse } from 'axios';
import { IPreferencesResponse } from '../services/settings/types';

let timeOutTableFilter: NodeJS.Timeout | null = null;
let currTableKey = '';

export const tableFilterPrefUpdate = async (
  args: () => Promise<AxiosResponse<IPreferencesResponse>>,
  tableKey?: string
) => {
  if (timeOutTableFilter && currTableKey === tableKey) clearTimeout(timeOutTableFilter);
  if (tableKey) currTableKey = tableKey;
  timeOutTableFilter = setTimeout(async () => {
    await args();
    timeOutTableFilter = null;
    currTableKey = '';
  }, 20000);
};
