import { WithReportInitializationProps } from '@/components/Common/Report/withReportInitialization';
import { ExtendedInitialValues } from '.';
import { find_date_preference } from '@/store/localstorage/preferences';
import { Button, DatePicker, Form, Input, InputNumber, PageHeader } from 'antd';
import { CustomDatePresets } from '../utils/datePresets';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import VendorSearchV2 from '@/components/Common/CustomSearch/Vendors';

interface Props extends WithReportInitializationProps {
  isLoading: boolean;
  initialValues: ExtendedInitialValues;
  onSearch: (values: ExtendedInitialValues) => void;
  onDownload: () => void;
  setIsEmpty: React.Dispatch<React.SetStateAction<boolean>>;
}

function FilterForm({ isLoading, form, initialValues, ...handler }: Props) {
  const nepaliType = find_date_preference();

  function resetEmpty() {
    handler.setIsEmpty(false);
  }

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      disabled={isLoading}
      onFieldsChange={resetEmpty}
      validateTrigger="onChange"
      initialValues={initialValues}
      onFinish={handler.onSearch}>
      <PageHeader subTitle="Constraints" style={{ padding: '0px 0px' }} />

      <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 ">
        <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
          <InputNumber controls={false} />
        </Form.Item>

        <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
          <InputNumber controls={false} />
        </Form.Item>

        <VendorSearchV2 name={['constraints', 'vendorId']} showAll hasParentFormItem={false} />

        <Form.Item
          label={'Date'}
          name={['constraints', 'dateCustom']}
          rules={[{ required: true, message: 'Please add date!' }]}
          hidden={nepaliType}>
          <DatePicker.RangePicker
            showTime
            className="w-full"
            ranges={CustomDatePresets}
            disabledDate={handler.onDisabledDate}
            onChange={handler.onChange}
            format={'YYYY-MM-DD HH:mm'}
            allowClear={false}
          />
        </Form.Item>
        <Form.Item label={'Date'} name={['constraints', 'startDate']} hidden>
          <Input />
        </Form.Item>
        <Form.Item label={'Date'} name={['constraints', 'endDate']} hidden>
          <Input />
        </Form.Item>
        <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
          <NepaliDatePicker
            className="w-full"
            onChange={(val) => handler.onChangeNepali(val, true)}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
          />
        </Form.Item>
        <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
          <NepaliDatePicker
            className="w-full"
            onChange={(val) => handler.onChangeNepali(val, false)}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
          />
        </Form.Item>

        {/* // Buttons for submitting and downloading the form */}
        <div className="flex justify-start mt-7 gap-1">
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
              Search
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              className="secondary-button"
              style={{ borderRadius: '6px' }}
              onClick={handler.onDownload}>
              Download
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}

export default FilterForm;
