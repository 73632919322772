import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IDailyRateData, IDailyRateList } from './types';

export const get_daily_rate_list_filter = async (filter = '') => {
  const response: AxiosResponse<IDailyRateList, any> = await http.get('daily-rate/?' + filter);
  return response.data;
};

export const get_daily_rate_details = async (id: number) => {
  const response: AxiosResponse<IDailyRateData, any> = await http.get('daily-rate/' + id);
  return response.data;
};
