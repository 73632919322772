import { ICustomerReportType } from '@/services/report/types';

export function getExportTableColumns(data: ICustomerReportType[], type: 'customer' | 'vendor') {
  const dateValues = data.reduce((acc, vendor) => {
    for (const date in vendor) {
      if (
        ![
          'vendorId',
          'vendorName',
          'vendorPhone',
          'customerId',
          'customerName',
          'customerPhone'
        ].includes(date)
      ) {
        acc[date] = true;
      }
    }
    return acc;
  }, {} as { [key: string]: boolean });

  const dateKeys = Object.keys(dateValues).sort();

  const columns = [
    {
      label: 'Name',
      dataIndex: type === 'customer' ? 'customerName' : 'vendorName',
      render: (value: string) => <div className="text-center">{value}</div>
    },
    {
      label: 'Phone',
      dataIndex: type === 'customer' ? 'customerPhone' : 'vendorPhone',
      render: (value: string) => <div className="text-center">{value}</div>
    },

    // Dynamically creating columns for each date
    ...dateKeys.map((dateKey) => ({
      label: dateKey,
      dataIndex: dateKey,
      render: (value: number | undefined) => <div className="text-center">{value || '-'}</div>
    }))
  ];

  return columns;
}

export function getExportTableData(data: ICustomerReportType[], type: 'customer' | 'vendor') {
  const tableColumns = getExportTableColumns(data, type);
  return tableColumns.map((column) => ({
    title: column.label,
    dataIndex: column.dataIndex
  }));
}
