import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  ICreateCustomerGroup,
  ICreateCustomerGroupResponse,
  ICreateDiscount,
  ICreateDiscountCategory,
  ICreateDiscountProduct,
  ICreateDiscountResponse,
  ICustomerDiscount,
  ICustomerDiscountFormData,
  IOffer,
  IOfferCreateRequest,
  IUpdateCustomerGroup,
  IUpdateDiscount,
  IUpdateDiscountCategory,
  IUpdateDiscountProduct
} from './types';
import { IDeleteCommonResponse } from '../category/types';

export const create_offer_mutation = async (values: IOfferCreateRequest) => {
  const response: AxiosResponse<IOffer, any> = await http.store('offer', values);
  return response;
};

export const update_offer_mutation = async (values: IOffer) => {
  const response: AxiosResponse<IOffer, any> = await http.update('offer/' + values.id, values);
  return response;
};

/* Delete offer
 ** @@ params: id
 */
export const delete_offer = async (id: number) => {
  const response: AxiosResponse<IDeleteCommonResponse, any> = await http.remove('offer/' + id);
  return response;
};

export const upload_offer_file_mutation = async (values: any) => {
  const response = await http.store('ports/import/offer', values.file);
  return response;
};

export const create_customer_group_mutation = async (values: ICreateCustomerGroup) => {
  const response: AxiosResponse<ICreateCustomerGroupResponse, any> = await http.store(
    'customer-group',
    values
  );
  return response.data;
};

export const update_customer_group_mutation = async (values: IUpdateCustomerGroup) => {
  const response: AxiosResponse<ICreateCustomerGroupResponse, any> = await http.update(
    'customer-group/' + values.id,
    values
  );
  return response;
};

export const create_discount_mutation = async (values: ICreateDiscount) => {
  const response: AxiosResponse<ICreateDiscountResponse, any> = await http.store(
    'offer/customer-group',
    values
  );
  return response.data;
};

export const update_discount_mutation = async (values: IUpdateDiscount) => {
  const response: AxiosResponse<ICreateDiscountResponse, any> = await http.update(
    'offer/customer-group/' + values.id,
    values
  );
  return response.data;
};

export const create_discount_category_mutation = async (values: ICreateDiscountCategory) => {
  const response: AxiosResponse<ICreateDiscountResponse, any> = await http.store(
    'offer/category',
    values
  );
  return response.data;
};

export const update_discount_category_mutation = async (values: IUpdateDiscountCategory) => {
  const response: AxiosResponse<ICreateDiscountResponse, any> = await http.update(
    'offer/category/' + values.id,
    values
  );
  return response.data;
};

export const create_discount_product_mutation = async (values: ICreateDiscountProduct) => {
  const response: AxiosResponse<ICreateDiscountResponse, any> = await http.store(
    'offer/product',
    values
  );
  return response.data;
};

export const update_discount_product_mutation = async (values: IUpdateDiscountProduct) => {
  const response: AxiosResponse<ICreateDiscountResponse, any> = await http.update(
    'offer/product/' + values.id,
    values
  );
  return response.data;
};

export const create_customer_discount_mutation = async (values: ICustomerDiscountFormData) => {
  const response: AxiosResponse<ICustomerDiscount> = await http.store(
    'offer/customer-range',
    values
  );
  return response;
};

export const update_customer_discount_mutation = async (
  values: Partial<ICustomerDiscountFormData> & { id: number }
) => {
  const { id, ...rest } = values;
  const response: AxiosResponse<ICustomerDiscount> = await http.update(
    'offer/customer-range/' + id,
    rest
  );
  return response;
};
