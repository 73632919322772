const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
export const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;

export const randomHex = () => {
  const hex = Math.floor(Math.random() * 0xffffff);
  return `#${hex.toString(16)}`;
};

const randomNumDarkVer = () => 50 + Math.floor(Math.random() * 150);
export const randomRGBDarkVer = () =>
  `rgb(${randomNumDarkVer()}, ${randomNumDarkVer()}, ${randomNumDarkVer()})`;
