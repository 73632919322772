import { Spin, PageHeader, Input, InputNumber, Button, Form, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import AppContent from '../../../../components/Common/Content/Content';
import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { get_session_money_details } from '../../../../services/pos-session/queries';
import { IUpdateSessionMoney } from '../../../../services/pos-session/types';
import { update_session_money_mutation } from '../../../../services/pos-session/mutations';

const DetailsMoney = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();

  const breadcrumbItems = [
    {
      label: 'Money',
      link: '/session/money'
    },
    {
      label: 'Details',
      link: '/session/money/:id'
    }
  ];

  useQuery(['sessionMoney', id], async () => await getData());

  const getData = async () => {
    if (id) {
      const data = await get_session_money_details(parseInt(id));
      form.setFieldsValue({
        name: data.name,
        value: data.value
      });
      setIsLoading(false);
    }
  };

  const onFinish = async (values: IUpdateSessionMoney) => {
    setIsLoading(true);
    //console.log('Values', values);
    if (id) values.id = parseInt(id);
    await updateSessionMoneyMutation.mutateAsync(values);
  };

  const updateSessionMoneyMutation = useMutation(update_session_money_mutation, {
    onSuccess: () => {
      setIsLoading(false);
      message.success('Money updated successfully');
      navigate('/session/money');
    },
    onError: (e: any) => {
      setIsLoading(false);
      message.error(`${e.response.data.message}`);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Money Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 sm:grid-cols-3 gap-5 mb-5'}>
            <Form.Item
              label="Amount in words"
              name="name"
              rules={[{ required: true, message: 'Please write the amount in words!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Amount in numberals"
              name="value"
              rules={[{ required: true, message: 'Please write the amount in numerals!' }]}>
              <InputNumber controls={false} min={0} precision={0} />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/session/money')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default DetailsMoney;
