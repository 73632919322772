import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Checkbox,
  Collapse,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Spin,
  Tooltip
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import AppContent from '../../../../../components/Common/Content/Content';
import { update_invoice_layout_mutation } from '../../../../../services/settings/mutations';
import { get_invoices_details } from '../../../../../services/settings/queries';
import BasicInfo from '../../@Forms/BasicInfo';
import Footer from '../../@Forms/Footer';
import Headings from '../../@Forms/Headings';
import ProductInfo from '../../@Forms/ProductInfo';
import SubTotal from '../../@Forms/SubTotal';
import {
  IUpdateInvoiceLayoutDataFromServer,
  IUpdateInvoiceLayoutMutation,
  Ivalues
} from '../../../../../services/settings/types';
import { useState } from 'react';
import BillPrinting from '../../@Forms/BillPrinting';

const DetailsInvoices = () => {
  const { id } = useParams<string>();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [printingType, setprintingType] = useState<any>('classic');
  const [isProductDisplayFull, setIsProductDisplayFull] = useState<boolean>(true);
  const breadcrumbItems = [
    {
      label: 'Invoice',
      link: '/invoices'
    },
    {
      label: 'Layout',
      link: '/invoices/layouts'
    }
  ];

  const updateInvoiceLayoutMutation = useMutation(update_invoice_layout_mutation);

  const onFinish = (values: Ivalues) => {
    // console.log('values', values);
    setIsLoading(true);

    const formData: IUpdateInvoiceLayoutMutation = {
      name: values.layoutName,
      id: id,
      content: JSON.stringify(values)
    };

    updateInvoiceLayoutMutation.mutateAsync(formData, {
      onSuccess: async ({ data }: { data: IUpdateInvoiceLayoutDataFromServer }) => {
        if (data) {
          message.success('Invoice updated successfully');
          navigate('/invoices');
          setIsLoading(false);
        }
      }
    });
  };

  useQuery(['invoiceLayouts-details'], async () => get_invoices_details(id), {
    onSuccess(data) {
      if (data) {
        const result = data.data;
        form.setFieldValue('layoutName', result.name);
        result.content = JSON.parse(result.content);
        if (result.content.productNameDisplayType) {
          if (result.content.productNameDisplayType === 'full') {
            setIsProductDisplayFull(true);
          } else {
            setIsProductDisplayFull(false);
          }
        }
        setprintingType(result.content.design);
        form.setFieldsValue(result.content);
        setIsLoading(false);
      }
    }
  });

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        disabled={isLoading}
        validateTrigger={'onChange'}
        autoComplete="off">
        <AppContent breadcrumbItems={breadcrumbItems}>
          {' '}
          <BasicInfo form={form} printingtype={printingType} setprintingType={setprintingType} />
        </AppContent>
        <Headings printingtype={printingType} form={form} />
        {printingType == 'classic' && (
          <>
            {/* <AppContent breadcrumbItems={[]}>
             
            </AppContent> */}
            <Collapse defaultActiveKey={['1']}>
              <Collapse.Panel header="Invoice for Sell" key="1">
                <Form.Item name="adjustment">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 gap-2 mb-2">
                    <Form.Item name="customerNameLabel" label="Customer Name Label">
                      <Input />
                    </Form.Item>
                    <Form.Item name="customerAddressLabel" label="Cutomer Address Label">
                      <Input />
                    </Form.Item>
                    <Form.Item name="customerPanLabel" label="Customer Pan Label">
                      <Input />
                    </Form.Item>
                  </div>
                </Form.Item>
              </Collapse.Panel>
            </Collapse>

            <AppContent breadcrumbItems={[]}>
              <ProductInfo form={form} />
            </AppContent>
            <Collapse defaultActiveKey={['1']}>
              <Collapse.Panel header="Invoice for Sell order" key="1">
                <Form.Item name="SellOrder">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 gap-2 mb-2">
                    <Form.Item name="companyName" label="Company Name">
                      <Input />
                    </Form.Item>
                    <Form.Item name="companyAddress" label="Address">
                      <Input />
                    </Form.Item>
                    <Form.Item name="panNumber" label="PAN Number">
                      <Input />
                    </Form.Item>
                  </div>
                </Form.Item>
              </Collapse.Panel>
            </Collapse>
            <Collapse defaultActiveKey={['1']}>
              <Collapse.Panel header="Invoice for Adjustments" key="1">
                <Form.Item name="adjustment">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 gap-2 mb-2">
                    <Form.Item
                      name="showBusinessName"
                      valuePropName="checked"
                      label="show Business Name">
                      <Checkbox />
                    </Form.Item>
                    <Form.Item name="adjustmentTypeLabel" label="AdjustmentType Label">
                      <Input />
                    </Form.Item>
                    <Form.Item name="reasonLabel" label="Reason Label">
                      <Input />
                    </Form.Item>
                  </div>
                </Form.Item>
              </Collapse.Panel>
            </Collapse>
            <Collapse defaultActiveKey={['1']}>
              <Collapse.Panel header="Invoice for Transfers" key="1">
                <Form.Item name="transfers">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 gap-2 mb-2">
                    <Form.Item
                      name="showLocations"
                      valuePropName="checked"
                      label="Show Locations(From/To)">
                      <Checkbox />
                    </Form.Item>
                    <Form.Item name="additionalNotesLabel" label="Additional Notes Label">
                      <Input />
                    </Form.Item>
                  </div>
                </Form.Item>
              </Collapse.Panel>
            </Collapse>
            <Collapse defaultActiveKey={['1']}>
              <Collapse.Panel header="Invoice for Purchase/Purchase Order" key="1">
                <Form.Item name="adjustment">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 gap-2 mb-2">
                    <Form.Item name="purchaseStatusLabel" label="Purchases Status Label">
                      <Input />
                    </Form.Item>
                    <Form.Item name="purchasesTaxLabel" label="Purchase Tax Label">
                      <Input />
                    </Form.Item>
                  </div>
                </Form.Item>
              </Collapse.Panel>
            </Collapse>
            <AppContent breadcrumbItems={[]}>
              <SubTotal form={form} />
            </AppContent>
          </>
        )}

        {printingType == 'bill' && (
          <>
            <AppContent breadcrumbItems={[]}>
              <BillPrinting form={form} isFull={isProductDisplayFull} />
            </AppContent>
          </>
        )}
        <AppContent breadcrumbItems={[]}>
          <Footer form={form} printingType={printingType} />
        </AppContent>

        <div className="flex justify-end mt-5 gap-2">
          <Dropdown
            overlay={
              <Menu
                items={(printingType == 'classic' ? invoiceoptions : invoiceoptiontwo)?.map(
                  (curr: any, ind: number) => {
                    return {
                      key: ind,
                      label: (
                        <Tooltip title="preview" color="blue">
                          <div className="text-center">{curr.name}</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        curr.onclick(form.getFieldsValue());
                      }
                    };
                  }
                )}
              />
            }
            trigger={['click']}>
            <Button type="primary">Preview</Button>
          </Dropdown>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={updateInvoiceLayoutMutation.isLoading}>
              Submit
            </Button>
          </Form.Item>
          <Button type="default" htmlType="button">
            Cancel
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

export default DetailsInvoices;

const createdByDetails = {
  avatarId: null,
  createdAt: '2022-12-26T04:18:05.662Z',
  disabled: false,
  email: 'dummycreator@erp.com',
  id: 7490,
  name: 'Dummy creator',
  password: '$2b$10$5rTZmNUxr0lffmu9dcaj/OmxiDuUPMJ7nDBW65R4dGgO5iVFX44O2',
  phone: '123123',
  updatedAt: '2022-12-26T04:18:05.662Z',
  verified: false
};

const routeDetails = {
  createdAt: '2022-12-24T15:36:32.733Z',
  deletedAt: null,
  id: 14,
  isActive: true,
  name: '(Dummy Fulfillment)  Route 10 (DummyLocation-12)',
  path: null,
  updatedAt: '2022-12-24T15:36:32.733Z'
};

const locationDetails = {
  address: 'Dummy',
  city: 'DummyKathmandu',
  code: 'DummySF01',
  country: 'DummyNepal',
  createdAt: '2022-12-24T15:30:08.566Z',
  description: '',
  email: 'Dummyinfo@freshktm.com',
  fax: null,
  id: 1,
  isActive: true,
  latitude: 27.65985458,
  longitude: 85.4393059,
  name: 'Dummy Fullfillment',
  phone: null,
  state: 'Dummy Bagmati',
  updatedAt: '2022-12-24T15:30:08.566Z',
  website: null,
  zip: '44600'
};

const sellDetails = {
  address: {
    addressLine1: 'Sanepa-Jhamsikhel, Sanepa-Jhamsikhel',
    addressLine2: 'Sanepa-Jhamsikhel',
    city: null,
    country: null,
    district: null,
    id: 4887,
    landmark: null,
    latitude: null,
    longitude: null,
    municipality: null,
    postalCode: null,
    routeId: 14,
    state: null,
    street: null,
    userId: 5016,
    ward: null,
    addressId: 4887
  },
  adjustment: 0,
  amountPaid: 2097,
  amountReturned: 0,
  cratesReceived: 0,
  cratesSent: 0,
  createdAt: '2023-02-18T15:07:40.266Z',
  createdBy: 7490,
  customerId: 2695,
  date: '2023-02-20T06:57:47.000Z',
  discount: 0,
  id: 6987,
  localDate: '2023-02-20T06:57:47.000Z',
  locationId: 1,
  misc: 0,
  note: '',
  paymentStatus: 'full',
  payments: [
    {
      addedBy: 7490,
      against: 'sell',
      againstId: 6987,
      amount: 2097,
      createdAt: '2023-02-27T04:02:08.207Z',
      date: '2023-02-27T00:00:00.000Z',
      id: 6987,
      localDate: '2023-02-27T00:00:00.000Z',
      madeBy: 5016,
      note: 'test',
      paymentMethod: 'cash',
      paymentMode: 'credit',
      reference: 'S-001-20230220-0000003',
      type: 'full',
      updatedAt: '2023-02-27T04:02:08.207Z'
    }
  ],
  referenceNumber: 'S-001-20230220-0000003',
  returns: [],
  sellOrderId: 200,
  shipping: 0,
  taxId: 4887,
  totalAmount: 2097,
  totalDiscount: 0,
  totalPrice: 0,
  totalQuantity: '44.5 kg',
  totalTax: 0,
  updatedAt: '2023-02-27T04:02:08.000Z'
};

const invoicelayout = {
  createdAt: '2023-01-12T08:17:16.839Z',
  deletedAt: '2023-02-27T07:59:49.340Z',
  id: 1,
  name: 'Dummy Invoice',
  updatedAt: '2023-02-27T07:59:49.000Z',
  content: ''
};

const lines = [
  {
    baseUnitQuantity: 2000,
    createdAt: '2023-02-20T06:57:51.217Z',
    date: '2023-02-20T06:57:47.000Z',
    discount: 0,
    grade: 'A',
    id: 871882,
    identifier: 'SALE',
    identifierId: 6987,
    isTraceable: false,
    locationId: 1,
    lotId: 0,
    misc: 0,
    productId: 190,
    productName: 'DUMMY PRODUCT 1',
    quantity: '2 kg',
    totalAmount: 0,
    unitId: 2,
    unitPrice: 0,
    updatedAt: '2023-02-20T06:57:51.217Z'
  },
  {
    baseUnitQuantity: 2000,
    createdAt: '2023-02-20T06:57:51.217Z',
    date: '2023-02-20T06:57:47.000Z',
    discount: 0,
    grade: 'A',
    id: 871882,
    identifier: 'SALE',
    identifierId: 6987,
    isTraceable: false,
    locationId: 1,
    lotId: 0,
    misc: 0,
    productId: 190,
    productName: 'DUMMY PRODUCT 2',
    quantity: '2 kg',
    totalAmount: 0,
    unitId: 2,
    unitPrice: 0,
    updatedAt: '2023-02-20T06:57:51.217Z'
  },
  {
    baseUnitQuantity: 2000,
    createdAt: '2023-02-20T06:57:51.217Z',
    date: '2023-02-20T06:57:47.000Z',
    discount: 0,
    grade: 'A',
    id: 871882,
    identifier: 'SALE',
    identifierId: 6987,
    isTraceable: false,
    locationId: 1,
    lotId: 0,
    misc: 0,
    productId: 190,
    productName: 'DUMMY PRODUCT 2',
    quantity: '2 kg',
    totalAmount: 0,
    unitId: 2,
    unitPrice: 0,
    updatedAt: '2023-02-20T06:57:51.217Z'
  },
  {
    baseUnitQuantity: 2000,
    createdAt: '2023-02-20T06:57:51.217Z',
    date: '2023-02-20T06:57:47.000Z',
    discount: 0,
    grade: 'A',
    id: 871882,
    identifier: 'SALE',
    identifierId: 6987,
    isTraceable: false,
    locationId: 1,
    lotId: 0,
    misc: 0,
    productId: 190,
    productName: 'DUMMY PRODUCT 3',
    quantity: '2 kg',
    totalAmount: 0,
    unitId: 2,
    unitPrice: 0,
    updatedAt: '2023-02-20T06:57:51.217Z'
  }
];

const transferDetails = {
  checklist: '{"bags":1,"crate":1,"sack":1,"others":"1"}',
  createdAt: '2023-02-17T08:58:33.872Z',
  createdById: null,
  from: 1,
  id: 45,
  localDate: '2023-02-17T00:00:00.000Z',
  referenceNo: 'TFR-1-3-20230217-7',
  to: 3,
  transferDate: '2023-02-17T00:00:00.000Z',
  updatedAt: '2023-02-17T08:58:33.872Z',
  fromName: 'DummyFromLocation',
  fromEmail: 'from@from.com',
  fromDetails: 'Dummy city,Nepal',
  toName: 'DummyToLocation',
  toEmail: 'to@to.com',
  toDetails: `Dummy to city,Nepal`,
  netTotalAmount: 1000
};

const adjustmentDetails = {
  addedBy: 7491,
  adjustmentDate: '2023-02-19T08:07:11.000Z',
  createdAt: '2023-02-19T08:07:12.357Z',
  id: 23,
  localDate: '2023-02-19T08:07:11.000Z',
  locationId: 1,
  reasonId: 2,
  referenceNumber: 'ST-2023219-0000001',
  totalAmount: 215,
  updatedAt: '2023-02-19T08:07:12.000Z',
  reasonName: 'Reason Name why??..'
};

const invoiceoptions = [
  {
    name: 'Sell',
    onclick: (invoicelayoutss: any) => {
      localStorage.setItem(
        'referrer',
        JSON.stringify({
          purchaseDetails: sellDetails,
          locationDetails,
          customerDetails: { ...createdByDetails, name: 'Dummy customers' },
          lines,
          invoiceLayouts: {
            ...invoicelayout,
            content: JSON.stringify(invoicelayoutss)
          },
          createdByDetails,
          routeDetails
        })
      );
      window.open('/ERP-UI#/invoice-sell', '_blank', 'noopener,noreferrer');
    }
  },
  {
    name: 'Sellorder',
    link: '/ERP-UI#/custom-invoice-sellandsellorder',
    onclick: (invoicelayoutss: any) => {
      localStorage.setItem(
        'referrer',
        JSON.stringify({
          purchaseDetails: sellDetails,
          locationDetails,
          customerDetails: { ...createdByDetails, name: 'Dummy customers' },
          lines,
          invoiceLayouts: {
            ...invoicelayout,
            content: JSON.stringify(invoicelayoutss)
          },
          createdByDetails,
          routeDetails
        })
      );
      window.open('/ERP-UI#/custom-invoice-sellandsellorder', '_blank', 'noopener,noreferrer');
    }
  },
  {
    name: 'Transfers',
    link: '/ERP-UI#/adjustmentinvoice',
    onclick: (invoicelayoutss: any) => {
      localStorage.setItem(
        'referrer',
        JSON.stringify({
          transferData: transferDetails,
          lines: lines,
          invoiceLayouts: {
            ...invoicelayout,
            content: JSON.stringify(invoicelayoutss)
          }
        })
      );
      window.open('/ERP-UI#/transferinvoice', '_blank', 'noopener,noreferrer');
    }
  },
  {
    name: 'Adjustments',
    onclick: (invoicelayoutss: any) => {
      localStorage.setItem(
        'referrer',
        JSON.stringify({
          locationDetails,
          lines,
          invoiceLayouts: {
            ...invoicelayout,
            content: JSON.stringify(invoicelayoutss)
          },
          adjustmentDetails
        })
      );

      window.open('/ERP-UI#/adjustmentinvoice', '_blank', 'noopener,noreferrer');
    }
  }
];

const invoiceoptiontwo = [
  {
    name: 'Sell',
    onclick: (invoicelayoutss: any) => {
      localStorage.setItem(
        'referrer',
        JSON.stringify({
          purchaseDetails: sellDetails,
          locationDetails,
          customerDetails: { ...createdByDetails, name: 'Dummy customers' },
          lines,
          invoiceLayouts: {
            ...invoicelayout,
            content: JSON.stringify(invoicelayoutss)
          },
          createdByDetails,
          routeDetails
        })
      );
      window.open('/ERP-UI#/billprint', '_blank', 'noopener,noreferrer');
    }
  },
  {
    name: 'Sellorder',
    link: '/ERP-UI#/custom-invoice-sellandsellorder',
    onclick: (invoicelayoutss: any) => {
      localStorage.setItem(
        'referrer',
        JSON.stringify({
          purchaseDetails: sellDetails,
          locationDetails,
          customerDetails: { ...createdByDetails, name: 'Dummy customers' },
          lines,
          invoiceLayouts: {
            ...invoicelayout,
            content: JSON.stringify(invoicelayoutss)
          },
          createdByDetails,
          routeDetails
        })
      );
      window.open('/ERP-UI#/billprint', '_blank', 'noopener,noreferrer');
    }
  }
];
