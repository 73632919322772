import CategoryMutationUI from '../CategoryMutationUI';

function ExpenseCategoryCreate() {
  const breadcrumbItems = [
    { label: 'Expense Category', link: '/expense-category' },
    { label: 'Create', link: '/expense-category/new' }
  ];

  return <CategoryMutationUI type="create" breadcrumbItems={breadcrumbItems} />;
}

export default ExpenseCategoryCreate;
