import { useMutation, useQuery } from '@tanstack/react-query';
import { Checkbox, Divider, Form, Input, message, PageHeader } from 'antd';
import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AppContent from '../../../../components/Common/Content/Content';
import CustomSubmitButton from '../../../../components/Common/CustomButton/CustomSubmitButton';
import { IUpdateReason } from '../../../../services/adjustments/types';
import ReuseChannel from '../../../channel/Reuse';
import { update_channel_reason_mutation } from '../../../../services/channels/mutations';
import { get_channel_reasons_details } from '../../../../services/channels/queries';

const EditChannelReasons: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const updatereasonsMutation = useMutation(update_channel_reason_mutation);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // This is needed to get the product details update as its written
  const [productDetails, setProductDetails] = useState<any>();
  // useEffect(() => {
  //   const search = searchParams.get('name');
  //   if (search) form.setFieldsValue({ name: search });
  //   setIsLoading(false);
  // }, []);

  useQuery([`reasons ${id}`], async () => {
    setIsLoading(true);
    const response = await get_channel_reasons_details(parseInt(id as string));
    // console.log('response', response);
    if (response?.data) {
      form.setFieldsValue({
        name: response.data.name,
        isArchived: response.data.isArchived
      });
    }
    setIsLoading(false);
  });

  const breadcrumbItems = [
    {
      label: 'Channel Reason',
      link: '/channel/reasons'
    },
    {
      label: 'Edit'
    }
  ];
  const onFinish = (values: IUpdateReason) => {
    // console.log('values', values);
    if (!values.isArchived) {
      // console.log('this runs');
      values.isArchived = false;
    }
    setIsLoading(true);
    if (id) values.id = parseInt(id);
    updatereasonsMutation.mutateAsync(values, {
      onSuccess: async ({ data }: { data: any }) => {
        if (data) {
          message.success('Reason edited successfully');
          navigate('/channel/reasons');
          setIsLoading(false);
        }
      },
      onError: (e: any) => {
        message.error(`${e.response.data.message}`, 5);
        setIsLoading(true);
      }
    });
  };
  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Edit Ticket Reason"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          disabled={isLoading}
          validateTrigger={'onChange'}
          onValuesChange={(_, allFields) => {
            setProductDetails(allFields);
          }}
          autoComplete="off">
          <div className="grid grid-cols-2 gap-5 mb-5">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Is Archived" name="isArchived" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              {/* <Button type="primary" htmlType="submit">
                Submit
              </Button> */}
              <CustomSubmitButton text="Submit" />
            </Form.Item>
          </div>
        </Form>
        <Divider />
        <ReuseChannel slug={`channel_reasons_${id}`} />
      </AppContent>
    </div>
  );
};

export default EditChannelReasons;
