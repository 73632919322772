import { get_account_history_ids, get_account_list_custom } from '@/services/accounts/queries';
import { createEmptyPromise } from './services/reference.service';

import { getAccountDetails, getBalance } from '@/services/accounts/services';
import { getOpeningBalancev2 } from './services/openingBalance.service';
import { addLocationToJournal } from './services/location.services';
import { getSortedTableData } from './services';
import { IAccountTypeResponseData } from '@/services/accounts/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import getGroupedData from '@/utils/getGroupedData';

interface Props {
  filter: string;
  pageNo: number;
  searchParams: URLSearchParams;
  isResetPageRunningBalance: boolean;
  closingBalance: { [key: number]: number };
  pageRunningBalance: { [key: number]: number };
}

export function getParamsInfo(filter: string) {
  const params = new URLSearchParams(filter);
  const endDate = params.get('endDate') as string;
  const accountId = params.get('accountId') as string;
  const startDate = params.get('startDate') as string;

  return {
    params,
    accountId,
    startDate,
    endDate
  };
}

export async function getLedgerInfo({
  filter,
  pageNo,
  isResetPageRunningBalance,
  closingBalance,
  pageRunningBalance,
  searchParams
}: Props) {
  const { startDate, endDate, accountId, params } = getParamsInfo(filter);
  params.append('isAsc', 'true');

  // Get accountIds from filter and split them, then join them with '&'
  const accountIds = accountId ? accountId.split(',').map(Number) : [];
  const accountIdFilter = accountIds.map((id) => `accountIds[]=${id}`).join('&');
  params.delete('accountId');

  // Add accountIds to filter if exists
  filter = `${params.toString()}${accountIds.length ? `&${accountIdFilter}` : ''}`;

  // Store total credit, debit, opening balance, and closing balance
  const mergeValue = new Set();
  const balance = { opening: 0, closing: 0 };

  const response = await get_account_list_custom(filter);
  const groupedJournalLists = getGroupedData(
    [...response.results, ...response.otherJournals],
    'journalId'
  );

  const journalLists = Object.keys(groupedJournalLists).flatMap((key) => {
    const journalGroups = groupedJournalLists[key];
    journalGroups.sort((a, b) => a.journalLineId - b.journalLineId);
    return journalGroups;
  });
  if (journalLists.length === 0) return { total: 0, data: [] } as any;

  const historyPromise =
    accountId && accountIds.length > 0
      ? get_account_history_ids(startDate, endDate, accountIds)
      : createEmptyPromise([]);

  const currentBalancePromise = accountId
    ? getBalance(accountIds)
    : createEmptyPromise({ openingBalance: 0, closingBalance: 0 });

  const selectedAccountPromise = accountId
    ? getAccountDetails(accountIds[0])
    : createEmptyPromise(undefined);

  const [history, currentBalance, selectedAccount] = await Promise.all([
    historyPromise,
    currentBalancePromise,
    selectedAccountPromise
  ]);

  const journalWithLocation = await addLocationToJournal(journalLists);

  balance.opening = await getOpeningBalancev2({
    data: journalWithLocation,
    accountId: parseInt(accountId),
    history,
    pageNo,
    closingBalance,
    searchParams,
    startDate
  });

  const pageRunBal = isResetPageRunningBalance ? {} : pageRunningBalance;
  const { updatedGroups: tableData, runningBalanceCurrAcc } = await getSortedTableData({
    data: journalWithLocation,
    pageRunBal,
    pageNo,
    currentAccount: selectedAccount as IAccountTypeResponseData,
    openingBalance: balance.opening,
    history,
    nextJournalLines: []
  });

  if (accountId) {
    balance.closing = runningBalanceCurrAcc;
  }

  const journalWithTransfer = tableData.map((value) => {
    if (value.locationName?.includes('>>>'))
      return { journalId: value.journalId, locationName: value.locationName };
  });

  // Update tableData with rows, sn and locationName
  let sn = 0;
  const updatedTableData = tableData.map((value) => {
    let updatedValue = { ...value };

    const hasJournalWithTransfer = journalWithTransfer.find(
      (val) => val?.journalId === value.journalId
    );

    if (hasJournalWithTransfer) {
      updatedValue = { ...value, locationName: hasJournalWithTransfer?.locationName };
    }

    if (mergeValue.has(value.journalId)) {
      updatedValue = { ...updatedValue, rows: 0, sn: 0 };
    } else {
      const rowCount = tableData.filter((data) => data.journalId === value.journalId).length;
      mergeValue.add(value.journalId);
      sn += 1;
      updatedValue = { ...updatedValue, rows: rowCount, sn };
    }

    return {
      ...updatedValue,
      createdAt: convertUTCStringtoLocalString(updatedValue.createdAt, DEFAULT_DATE_FORMAT),
      transactionDate: updatedValue.journalDate
        ? convertUTCStringtoLocalString(updatedValue.journalDate, DEFAULT_DATE_FORMAT)
        : ''
    };
  });

  // Return total for pagination
  return {
    total: response.total,
    balance,
    selectedAccount,
    data: updatedTableData,
    currentBalance,
    runningBalanceCurrAcc
  };
}
