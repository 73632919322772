import { find_to_use_NPR } from '@/store/localstorage/preferences';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

export const tableExportColumns = [
  {
    label: 'Product',
    dataIndex: 'product_name',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Location',
    dataIndex: 'location_name',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },

  {
    label: 'Purchase Qty',
    dataIndex: 'purchase_qty',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Purchase Return Qty',
    dataIndex: 'purchase_return_qty',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Sell Qty',
    dataIndex: 'sell_qty',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Sell Return Qty',
    dataIndex: 'sell_return_qty',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Qty Diff',
    dataIndex: 'qty_diff',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Sell',
    dataIndex: 'sell',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Sell Return',
    dataIndex: 'sell_return',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Purchase',
    dataIndex: 'purchase',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Purchase Return',
    dataIndex: 'purchase_return',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      purchase_qty: `${parseFloat(record.purchase_total_quantity)} ${record.unit}`,
      purchase_return_qty: `${parseFloat(record.purchase_return_total_quantity)} ${record.unit}`,
      sell_qty: `${parseFloat(record.sell_total_quantity)} ${record.unit}`,
      sell_return_qty: `${parseFloat(record.sell_return_total_quantity)} ${record.unit}`,
      qty_diff: `${parseFloat(record.quantity_difference)} ${record.unit}`,
      sell: nepaliNumberFormatter(record.sell_sum),
      sell_return: nepaliNumberFormatter(record.sell_return_sum),
      purchase: nepaliNumberFormatter(record.purchase_sum),
      purchase_return: nepaliNumberFormatter(record.purchase_return_sum)
    };
  }) as any;
}
