import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useState, PropsWithChildren, useEffect } from 'react';

import MenuDrawer from './MenuDrawer';
import { fetchMenuItems } from './main.layout';

import HeaderWrapper from './header.layout';
import MenuDB from '@/store/localstorage/MenuDB';
import { CloseOutlined } from '@ant-design/icons';
import MenuSecondaryDrawer from './MenuSecondaryDrawer';
import { useGlobalContext } from '@/contexts/global.context';
import InfoDrawer from './InfoDrawer';

function MainLayoutSecondary({
  children,
  menu: Menu
}: PropsWithChildren<{ menu: React.ComponentType<any> }>) {
  const location = useLocation();
  const [showDrawer, setshowDrawer] = useState(false);
  const [infoContent, setInfoContent] = useState('');
  const { showSecondaryDrawer, setShowSecondaryDrawer, showInfo, setShowInfo } = useGlobalContext();

  const [showHamburger, setshowHamburger] = useState(false);

  function closeShowInfo() {
    setShowInfo(false);
  }

  const { data: menuOrders, refetch } = useQuery(['filterSearchMenu'], () => fetchMenuItems(true));

  useEffect(() => {
    const getMenuInfoContent = async () => {
      const response = await MenuDB.getAllMenuItem();
      const currentMenu = response.find((value) => value.key === location.pathname);

      const missingContentText = '<p>No information available</p>';
      setInfoContent(currentMenu?.content || missingContentText);
    };

    getMenuInfoContent();
  }, [location.pathname]);

  useEffect(() => {
    function handleResize() {
      window.innerWidth <= 768 ? setshowHamburger(true) : setshowHamburger(false);
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {/* // Main Menu Drawer  */}
      <MenuDrawer
        showDrawer={showDrawer}
        locationMenu={location.pathname}
        menu={menuOrders}
        setShowDrawer={setshowDrawer}
      />

      {/* // Secondary Menu Drawer  */}
      <MenuSecondaryDrawer
        menu={Menu}
        showDrawer={showSecondaryDrawer}
        setShowDrawer={setShowSecondaryDrawer}
      />

      {/* // Info Drawer  */}
      <InfoDrawer showDrawer={showInfo} setShowDrawer={setShowInfo} content={infoContent} />

      <Layout>
        {/* // Main Sidebar  */}
        <Layout.Sider
          width={200}
          className="transition-class overflow-y-auto hidden md:block"
          style={{
            height: '100vh',
            position: 'fixed',
            bottom: 0,
            left: showHamburger || showInfo ? '-100%' : 0,
            top: 64,
            backgroundColor: 'white',
            paddingBlock: 20,
            paddingInline: 10
          }}>
          <Menu />
        </Layout.Sider>

        <Layout className="site-layout">
          <div className={`transition-class ${showInfo ? 'md:mr-[260px]' : 'mr-0'}`}>
            <HeaderWrapper
              showHamburger
              collapsed={false}
              setshowDrawer={setshowDrawer}
              refetchMenu={refetch}
              isSecondaryMenu
              showSubMenuHamburger={showHamburger}
            />
            <div
              className="site-content mt-16"
              style={{ marginLeft: showHamburger || showInfo ? 0 : 220 }}>
              {children}
            </div>
          </div>
        </Layout>

        {/* Info Sidebar  */}
        <Layout.Sider
          width={260}
          trigger={null}
          theme="light"
          className="transition-class hidden md:block"
          style={{
            position: 'fixed',
            top: 64,
            right: showInfo ? 0 : -260,
            bottom: 0
          }}>
          <div
            style={{ height: 'calc(100% - 64px)' }}
            className="text-editor thin-scrollbar ql-editor">
            <div className="flex justify-end">
              <CloseOutlined className="close-icon" onClick={closeShowInfo} />
            </div>
            <div dangerouslySetInnerHTML={{ __html: infoContent }} className="m-0" />
          </div>
        </Layout.Sider>
      </Layout>
    </>
  );
}

export default MainLayoutSecondary;
