/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState, useEffect } from 'react';
import {
  Button,
  Form,
  InputNumber,
  Menu,
  message,
  Modal,
  PageHeader,
  Select,
  Spin,
  Table
} from 'antd';
import AppContent from '@/components/Common/Content';
import useDebounce from '@/hooks/useDebounce';
import {
  get_product_list,
  get_product_list_ids,
  get_units_list
} from '@/services/products/queries';
import TableFilter from '@/components/FliterTable';
import { get_location_list } from '@/services/locations/queries';
import moment from 'moment';
import {
  get_latest_week_sales,
  get_lots_by_group_of_product_id,
  get_sell_order_form_filter_v2
} from '@/services/purchases/queries';
import { useMutation } from '@tanstack/react-query';
import { create_purchase_form_order } from '@/services/purchases/mutations';
import ProductsDB from '@/store/localstorage/ProductsDB';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { getUserData } from '@/utils/auth.utils';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { useReactToPrint } from 'react-to-print';
import { CustomModal } from '@/components/Common/CustomModal';
import CustomTable from '@/components/Common/CustomResuableInvoice/CustomTable';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { numberDecimalFormatter } from '@/utils/numberFormatter';
import ProductSearchForEstimateStock from '@/components/Common/ProductSearch2/ProductSearchforestimate';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { AxiosResponse } from 'axios';
import { ICurrentLotsWithProductId } from '@/services/purchases/types';
import { IProductType } from '@/services/products/types';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

const { Option } = Select;

const SellOrderFormCreate: React.FC = () => {
  const printPDFRef = useRef<any>();

  const [form] = Form.useForm();
  const [data, setData] = useState<any>([]);
  const [allData, setAllData] = useState<any>([]);
  const [form2] = Form.useForm();
  const [searchValueLoc] = useState<string>('');
  const [selectedLocation, setselectedLocation] = useState<number>();
  const debouncedSearchValueLocation = useDebounce(searchValueLoc, 500);
  useEffect(() => {
    searchLocation(debouncedSearchValue);
  }, [debouncedSearchValueLocation]);
  const [locationSearch, setLocationSearch] = useState<any>([]);

  const [searchValue] = useState<string>('');
  const [productSearch, setProductSearch] = useState<any[]>([]);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [loading, setIsloading] = useState<boolean>(false);
  useEffect(() => {
    searchProduct(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const [openmodalforExport, setOpenModalforexport] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<any>({
    productName: '',
    lotInfo: []
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const searchProduct = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_product_list(0, 50, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any product with that name!');
        setProductSearch([]);
      } else {
        setProductSearch(response.data.results);
      }
    }
    // setSearchLoading(false);
  };
  const searchLocation = async (value: any) => {
    let response;
    try {
      response = await get_location_list(0, 5, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any location with that name!');
      setLocationSearch([]);
    } else {
      setLocationSearch(response.data.results);
      if (!selectedLocation) setselectedLocation(response.data.results[0].id);
    }
  };
  const options = productSearch
    .filter((cur: any) => !cur.hidden)
    .map((d) => (
      <Option key={d.id} value={d.id}>
        {d.name}
      </Option>
    ));
  const options2 = locationSearch.map((value: any) => (
    <Select.Option key={value.id} value={value.id}>
      {value.name}
    </Select.Option>
  ));

  const onSearch = async (val: string) => {
    const uniqueproducts = new Set<number>();
    setIsloading(true);
    // console.log('val', val);
    // const response: any = (await get_purchase_order_form_filter(val)).data;
    // console.log('This is called--> ', val);
    console.log('ff', form2.getFieldsValue());
    val +=
      '&page=1&size=1000&identifier=SALE&startDate=' +
      moment().subtract(7, 'days').utc().format() +
      '&endDate=' +
      moment().utc().format();

    // const responseSold: any = (await get_report_string(val, 8)).data;
    // const todaysPurchaseOrder = await get_sell_order_form_filter(val);
    const todaysPurchaseOrder = await get_sell_order_form_filter_v2({
      locationId: form2.getFieldValue('locationId'),
      startDate: moment(form2.getFieldValue(['date']))
        .utc()
        .format(),
      endDate: moment(form2.getFieldValue(['date']))
        .utc()
        .add(1, 'days')
        .format()
    });

    const locationDCDatas: any = {};

    for (let ind = 0; ind < todaysPurchaseOrder.data.locationDC.length; ind++) {
      const { productId, qty } = todaysPurchaseOrder.data.locationDC[ind];
      if (!locationDCDatas[productId]) {
        locationDCDatas[productId] = qty;
      } else {
        locationDCDatas[productId] += qty;
      }
    }

    // console.log('todays purchase order', todaysPurchaseOrder);
    if (todaysPurchaseOrder.data.data.length > 0) {
      const nproductSet = new Set<number>();
      for (let ind = 0; ind < todaysPurchaseOrder.data.data.length; ind++) {
        nproductSet.add(todaysPurchaseOrder.data.data[ind].productId);
        uniqueproducts.add(todaysPurchaseOrder.data.data[ind].productId);
      }

      let lotsandproductId: AxiosResponse<ICurrentLotsWithProductId, any>;

      if (nproductSet.size > 0) {
        lotsandproductId = await get_lots_by_group_of_product_id(
          Array.from(nproductSet),
          form2.getFieldValue(['locationId'])
        );

        const productSearch: { [key: number]: any[] } = {};
        // console.log('locationDCDatas', locationDCDatas);
        for (let ind = 0; ind < todaysPurchaseOrder.data.data.length; ind++) {
          let totalLots = 0;
          const productInfo: any = await ProductsDB.getProduct(
            todaysPurchaseOrder.data.data[ind].productId
          );
          if (!productInfo) {
            if (productSearch[todaysPurchaseOrder.data.data[ind].productId]) {
              productSearch[todaysPurchaseOrder.data.data[ind].productId] = [
                ...productSearch[todaysPurchaseOrder.data.data[ind].productId],
                ind
              ];
            } else {
              productSearch[todaysPurchaseOrder.data.data[ind].productId] = [ind];
            }
            // productInfo = await get_product_details(responseSold.data[i].id);
            // ProductsDB.addProducts([productInfo]);
          } else {
            const findUnit = productInfo.productUnits.find((curr: any) => curr.isDefault == true);
            // console.log('Find Unit->', findUnit);
            let unitInfo: any = await UnitsDB.getUnit(findUnit.unitId);
            // console.log('unit id', findUnit.unitId);
            if (!unitInfo) {
              const allUnits = await get_units_list();
              await UnitsDB.addUnits(allUnits);
              unitInfo = await UnitsDB.getUnit(findUnit.unitId);
            }
            if (!lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].done) {
              for (
                let index = 0;
                index <
                lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].lots.length;
                index++
              ) {
                totalLots +=
                  lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].lots[index]
                    .qtyAvailable;
                // lotInfo[ind].qtySold=`${lotInfo[ind].qtySold/(unitInfo.baseUnitMultiplier)} ${unitInfo.shortName}`
                lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].lots[
                  index
                ].qtyAvailableWithUnit = `${
                  lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].lots[index]
                    .qtyAvailable / unitInfo.baseUnitMultiplier
                } ${unitInfo.shortName}`;
              }
              lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].totallotsInfo =
                totalLots / unitInfo.baseUnitMultiplier;
              lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].done = true;
            }
            todaysPurchaseOrder.data.data[ind].baseUnitMultiplier = unitInfo.baseUnitMultiplier;
            todaysPurchaseOrder.data.data[ind].hidden = productInfo.hidden;
            todaysPurchaseOrder.data.data[ind].name = productInfo.name;
            todaysPurchaseOrder.data.data[ind].lotInfo =
              lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].lots;
            todaysPurchaseOrder.data.data[ind].unitName = unitInfo.shortName;
            todaysPurchaseOrder.data.data[ind].totalLots =
              lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].totallotsInfo;
          }
          // responseSold.data[i].lotInfo = lotsandproductId.data[responseSold.data[i].id].lots;
          // todaysPurchaseOrder.data.data[ind].productId.productId =  todaysPurchaseOrder.data.data[ind].productId;
          todaysPurchaseOrder.data.data[ind].sold = 0;
          todaysPurchaseOrder.data.data[ind].id = todaysPurchaseOrder.data.data[ind].productId;
          todaysPurchaseOrder.data.data[ind].locationDCData = locationDCDatas[
            todaysPurchaseOrder.data.data[ind].productId
          ]
            ? locationDCDatas[todaysPurchaseOrder.data.data[ind].productId]
            : 0;
          // todaysPurchaseOrder.data.data[ind].productId.qty = 0;
        }

        const productSearchlength = Object.entries(productSearch).length;
        if (productSearchlength > 0) {
          const allproducts = await get_product_list_ids([...Object.keys(productSearch)]);
          for (const key in productSearch) {
            const findproduct = allproducts?.data?.results.find(
              (currProduct: IProductType) => currProduct.id == key
            );
            if (findproduct) {
              let findUnit = findproduct.productUnits.find((curr: any) => curr.isDefault === true);
              if (!findUnit) findUnit = findproduct.productUnits[0];
              // console.log('Find Unit->', findUnit);
              let unitInfo: any;
              if (findUnit) unitInfo = await UnitsDB.getUnit(findUnit.unitId);
              // console.log('unit id', findUnit.unitId);
              if (!unitInfo && findUnit) {
                const allUnits = await get_units_list();
                await UnitsDB.addUnits(allUnits);
                unitInfo = await UnitsDB.getUnit(findUnit.unitId);
              }
              let totalLots = 0;
              for (let ind = 0; ind < lotsandproductId.data[key].lots.length; ind++) {
                totalLots += lotsandproductId.data[key].lots[ind].qtyAvailable;
                lotsandproductId.data[key].lots[ind].qtyAvailableWithUnit = `${
                  lotsandproductId.data[key].lots[ind].qtyAvailable / unitInfo.baseUnitMultiplier
                } ${unitInfo.shortName}`;
              }
              // console.log('Lots-->', lotsandproductId.data[key].lots);
              for (let i = 0; i < productSearch[key].length; i++) {
                todaysPurchaseOrder.data.data[productSearch[key][i]].baseUnitMultiplier =
                  unitInfo.baseUnitMultiplier;
                todaysPurchaseOrder.data.data[productSearch[key][i]].hidden = findproduct.hidden;
                todaysPurchaseOrder.data.data[productSearch[key][i]].name = findproduct.name;
                todaysPurchaseOrder.data.data[productSearch[key][i]].unitName = unitInfo.shortName;
                todaysPurchaseOrder.data.data[productSearch[key][i]].lotInfo =
                  lotsandproductId.data[key].lots;
                todaysPurchaseOrder.data.data[productSearch[key][i]].totalLots =
                  totalLots / unitInfo.baseUnitMultiplier;
              }
              await ProductsDB.addProducts([findproduct]);
            }
          }
        }
        todaysPurchaseOrder.data.data = mergeSameIdArray(todaysPurchaseOrder.data.data);
        // console.log('lines', todaysPurchaseOrder.data.data);
        // console.log('uniqueproducts', uniqueproducts);
        await fetchLastWeekSoldData(Array.from(uniqueproducts), todaysPurchaseOrder.data.data);
        form.setFieldsValue({ lines: todaysPurchaseOrder.data.data });
        // setData(todaysPurchaseOrder.data.data);
      }

      setAllData(todaysPurchaseOrder.data.data);
      setIsloading(false);
      return;
    }
    // const responseSold: any = (await get_report_string(val, 8)).data;
    const responseSold: any = (await get_latest_week_sales([], form2.getFieldValue(['date']))).data;

    // console.log('Response sold', responseSold);
    const productSet = new Set<number>();
    for (let i = 0; i < responseSold.length; i++) {
      productSet.add(responseSold[i].productId);
      uniqueproducts.add(responseSold[i].productId);
    }

    let lotsandproductId: any;

    if (productSet.size > 0) {
      lotsandproductId = await get_lots_by_group_of_product_id(
        Array.from(productSet),
        form2.getFieldValue(['locationId'])
      );

      // console.log('products', productSet);
      // console.log('lotsandproductId from group of products-->', lotsandproductId);
      const productSearch: { [key: number]: any[] } = {};
      const productsmap: any = {};
      for (let i = 0; i < responseSold.length; i++) {
        if (!productsmap[responseSold[i].productId]) {
          let totalLots = 0;
          productsmap[responseSold[i].productId] = {
            productId: responseSold[i].productId,
            id: responseSold[i].productId,
            qty: 0,
            locationDCData: locationDCDatas[responseSold[i].productId]
              ? locationDCDatas[responseSold[i].productId]
              : 0
          };
          // const lotInfo: any = (await get_lot_by_location_product(val, responseSold.data[i].id)).data;
          const productInfo: any = await ProductsDB.getProduct(responseSold[i].productId);
          if (!productInfo) {
            if (productSearch[responseSold[i].productId]) {
              productSearch[responseSold[i].productId] = [
                ...productSearch[responseSold[i].productId],
                i
              ];
            } else {
              productSearch[responseSold[i].productId] = [i];
            }
            // productInfo = await get_product_details(responseSold.data[i].id);
            // ProductsDB.addProducts([productInfo]);
          } else {
            const findUnit = productInfo.productUnits.find((curr: any) => curr.isDefault == true);
            // console.log('Find Unit->', findUnit);
            let unitInfo: any = await UnitsDB.getUnit(findUnit.unitId);
            // console.log('unit id', findUnit.unitId);
            if (!unitInfo) {
              const allUnits = await get_units_list();
              await UnitsDB.addUnits(allUnits);
              unitInfo = await UnitsDB.getUnit(findUnit.unitId);
            }
            if (!lotsandproductId.data[responseSold[i].productId].done) {
              for (
                let ind = 0;
                ind < lotsandproductId.data[responseSold[i].productId].lots.length;
                ind++
              ) {
                totalLots +=
                  lotsandproductId.data[responseSold[i].productId].lots[ind].qtyAvailable;
                // lotInfo[ind].qtySold=`${lotInfo[ind].qtySold/(unitInfo.baseUnitMultiplier)} ${unitInfo.shortName}`
                lotsandproductId.data[responseSold[i].productId].lots[
                  ind
                ].qtyAvailableWithUnit = `${
                  lotsandproductId.data[responseSold[i].productId].lots[ind].qtyAvailable /
                  unitInfo.baseUnitMultiplier
                } ${unitInfo.shortName}`;
                // lotsandproductId.data[responseSold.data[i].id].lots[ind].done = true;
              }
              lotsandproductId.data[responseSold[i].productId].done = true;
              lotsandproductId.data[responseSold[i].productId].totallotsInfo =
                totalLots / unitInfo.baseUnitMultiplier;
            }

            productsmap[responseSold[i].productId] = {
              ...productsmap[responseSold[i].productId],
              name: productInfo.name,
              unitName: unitInfo.shortName,
              baseUnitMultiplier: unitInfo.baseUnitMultiplier,
              hidden: productInfo.hidden,
              lotInfo: lotsandproductId.data[responseSold[i].productId].lots,
              totalLots: lotsandproductId.data[responseSold[i].productId].totallotsInfo
            };
          }
          // responseSold.data[i].lotInfo = lotsandproductId.data[responseSold.data[i].id].lots;
          // responseSold.data[i].productId = responseSold.data[i].id;
          // responseSold.data[i].sold = responseSold.data[i].totalQuantity;
          // responseSold.data[i].qty = 0;
        }
      }

      const productSearchlength = Object.entries(productSearch).length;
      if (productSearchlength > 0) {
        const allproducts = await get_product_list_ids([...Object.keys(productSearch)]);
        for (const key in productSearch) {
          const findproduct = allproducts?.data?.results.find(
            (currProduct: IProductType) => currProduct.id == key
          );
          if (findproduct) {
            let findUnit = findproduct.productUnits.find((curr: any) => curr.isDefault === true);
            if (!findUnit) findUnit = findproduct.productUnits[0];
            // console.log('Find Unit->', findUnit);
            let unitInfo: any = await UnitsDB.getUnit(findUnit.unitId);
            // console.log('unit id', findUnit.unitId);
            if (!unitInfo) {
              const allUnits = await get_units_list();
              await UnitsDB.addUnits(allUnits);
              unitInfo = await UnitsDB.getUnit(findUnit.unitId);
            }
            let totalLots = 0;
            for (let ind = 0; ind < lotsandproductId.data[key].lots.length; ind++) {
              totalLots += lotsandproductId.data[key].lots[ind].qtyAvailable;
              lotsandproductId.data[key].lots[ind].qtyAvailableWithUnit = `${
                lotsandproductId.data[key].lots[ind].qtyAvailable / unitInfo.baseUnitMultiplier
              } ${unitInfo.shortName}`;
            }
            productsmap[key] = {
              name: findproduct?.name,
              ...productsmap[key],
              hidden: findproduct?.hidden,
              baseUnitMultiplier: unitInfo.baseUnitMultiplier,
              unitName: unitInfo.shortName,
              lotInfo: lotsandproductId.data[key].lots,
              totalLots: totalLots / unitInfo.baseUnitMultiplier
            };
            await ProductsDB.addProducts([findproduct]);
          }
        }
      }
      // responseSold.data = mergeSameIdArray(responseSold.data);
      // console.log('lines', responseSold.data);
      // console.log('obj-> ', productsmap);
      await fetchLastWeekSoldData([], [...Object.values(productsmap)], false, responseSold);
      // form.setFieldsValue({ lines: responseSold.data });
      // setData(responseSold.data);
    } else {
      setData([]);
    }

    setIsloading(false);
  };

  const orderMutation = useMutation(create_purchase_form_order);
  const onFinish = async (values: any) => {
    setIsloading(true);
    const orders: any[] = [];
    // console.log('data', data);
    data.map((value: any) => {
      // console.log('this is called');
      if (value.qty > 0 && form2.getFieldValue(['locationId']))
        orders.push({
          // date: value.date,
          locationId: form2.getFieldValue(['locationId']),
          qty: value.qty,
          productId: value.id,
          type: 'SELL'
        });
    });
    orderMutation.mutateAsync(
      { orders },
      {
        onSuccess: async (data: any) => {
          message.success('added');
          setIsloading(false);
        },
        onError: (e: any) => {
          message.error(`${e.response.data.message}`, 5);
          setIsloading(false);
        }
      }
    );
  };

  const mergeSameIdArray = (arr: any[]) => {
    const newArr: any[] = [];
    arr.map((value: any) => {
      const findIndex = newArr.findIndex((curr: any) => curr.id == value.id);
      if (findIndex == -1) {
        value.sold = parseFloat(value.sold);
        newArr.push(value);
      } else {
        newArr[findIndex].sold = parseFloat(value.sold) + parseFloat(newArr[findIndex].sold);
      }
    });
    return newArr;
  };

  const columns = [
    {
      title: 'S.N.',
      render: (a: any, b: any, c: any) => <div className="text-center">{c + 1}</div>,
      width: 3
    },
    {
      title: 'Product',
      dataIndex: 'name',
      width: 10
    },
    {
      title: 'Unit',
      dataIndex: 'unitName',
      width: 3,
      render: (text: any) => <div className="text-center">{text}</div>
    },
    {
      title: 'Total Sold',
      dataIndex: 'sold',
      width: 4,
      className: 'highlight',
      render: (text: any) => <div className="text-center">{numberDecimalFormatter(text)}</div>
    },
    {
      title: `${moment(form2.getFieldValue(['date']))
        .subtract(6, 'days')
        .format('MM-DD')}`,
      dataIndex: `${moment(form2.getFieldValue(['date']))
        .subtract(6, 'days')
        .format('YYYY-MM-DD')}`,
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      title: `${moment(form2.getFieldValue(['date']))
        .subtract(5, 'days')
        .format('MM-DD')}`,
      dataIndex: `${moment(form2.getFieldValue(['date']))
        .subtract(5, 'days')
        .format('YYYY-MM-DD')}`,
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      title: `${moment(form2.getFieldValue(['date']))
        .subtract(4, 'days')
        .format('MM-DD')}`,
      dataIndex: moment(form2.getFieldValue(['date']))
        .subtract(4, 'days')
        .utc()
        .format('YYYY-MM-DD'),
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      title: `${moment(form2.getFieldValue(['date']))
        .subtract(3, 'days')
        .format('MM-DD')}`,
      dataIndex: `${moment(form2.getFieldValue(['date']))
        .subtract(3, 'days')
        .format('YYYY-MM-DD')}`,
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      title: `${moment(form2.getFieldValue(['date']))
        .subtract(2, 'days')
        .format('MM-DD')}`,
      dataIndex: `${moment(form2.getFieldValue(['date']))
        .subtract(2, 'days')
        .format('YYYY-MM-DD')}`,
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      title: `${moment(form2.getFieldValue(['date']))
        .subtract(1, 'days')
        .format('MM-DD')}`,
      dataIndex: moment(form2.getFieldValue(['date']))
        .local()
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    // {
    //   title: `${moment(form2.getFieldValue(['date'])).format('MM-DD')}`,
    //   dataIndex: moment(form2.getFieldValue(['date'])).format('YYYY-MM-DD'),
    //   width: 4,
    //   render: (text: any) => <div className="text-center">{text ? text : 0}</div>
    // },
    {
      title: `Quantity`,
      dataIndex: 'qty',
      width: 8,
      render: (value: number, row: any, index: number) => {
        // console.log(value);
        // form.setFieldValue(['lines', index], row);
        return (
          // <Form.Item
          //   name={['lines', index, 'qty']}
          //   key={row.id}
          //   rules={[
          //     {
          //       required: false,
          //       message: 'Please add quantity!'
          //     }
          //   ]}>
          <div className="px-2 py-1">
            <InputNumber
              controls={false}
              disabled={
                form2.getFieldValue(['date']) == moment().format('YYYY-MM-DD') ? false : true
              }
              // max={value
              value={row.qty}
              onChange={(valueInput: any) => {
                // console.log('value', value);
                setData((prev: any) => {
                  return prev.map((curr: any) => {
                    if (curr.productId == row.productId) {
                      return {
                        ...curr,
                        qty: valueInput
                      };
                    } else {
                      return curr;
                    }
                  });
                });
              }}
              min={0}
              autoFocus
            />
          </div>
          // </Form.Item>
        );
      }
    },
    {
      title: 'Total in Lots',
      width: 7,
      dataIndex: 'totalLots',
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      title: 'Total From Others',
      width: 5,
      dataIndex: 'locationDCData',
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    // {
    //   title: 'Lots',
    //   width: 5,
    //   render: (value: number, row: any, index: number) => {
    //     return (
    //       <div className="flex justify-center items-center">
    //         <Button
    //           disabled={
    //             form2.getFieldValue(['date']) == moment().format('YYYY-MM-DD') ? false : true
    //           }
    //           style={{ backgroundColor: '#343c4c', color: 'white' }}
    //           onClick={() => {
    //             setModalData({
    //               productName: row.name,
    //               lotInfo: row.lotInfo
    //             });
    //             showModal();
    //           }}>
    //           Show
    //         </Button>
    //       </div>
    //     );
    //   }
    // },
    {
      title: 'Action',
      width: 3,
      dataIndex: 'action',
      render: (value: number, row: any, index: number) => {
        const menuItems: any[] = [];
        if (form2.getFieldValue(['date']) == moment().format('YYYY-MM-DD')) {
          menuItems.push({
            key: '2',
            label: <div className="flex justify-center items-center">Remove</div>,
            onClick: () => {
              setData(data.filter((value: any, ind: number) => ind != index));
            }
          });
        }
        const menu = (
          <Menu
            items={[
              ...menuItems,
              {
                key: '3',
                label: <div className="flex justify-center items-center">All Lots</div>,
                onClick: () => {
                  setModalData({
                    productName: row.name,
                    lotInfo: row.lotInfo
                  });
                  showModal();
                }
              }
            ]}
          />
        );
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const columnss = [
    {
      title: 'S.N.',
      render: (a: any, b: any, c: any) => <div className="text-center">{c + 1}</div>,
      width: 3
    },
    {
      title: 'Lot Number',
      dataIndex: 'lotNumber',
      width: 10
    },
    {
      title: 'Qty Available',
      dataIndex: 'qtyAvailableWithUnit',
      width: 10
    }
  ];

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  const columsforPrint: any = [
    {
      label: 'Product Name',
      dataIndex: 'name',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Unit',
      dataIndex: 'unitName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: `${moment(form2.getFieldValue(['date']))
        .subtract(6, 'days')
        .format('MM-DD')}`,
      dataIndex: `${moment(form2.getFieldValue(['date']))
        .subtract(6, 'days')
        .format('YYYY-MM-DD')}`,
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      label: `${moment(form2.getFieldValue(['date']))
        .subtract(5, 'days')
        .format('MM-DD')}`,
      dataIndex: `${moment(form2.getFieldValue(['date']))
        .subtract(5, 'days')
        .format('YYYY-MM-DD')}`,
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      label: `${moment(form2.getFieldValue(['date']))
        .subtract(4, 'days')
        .format('MM-DD')}`,
      dataIndex: moment(form2.getFieldValue(['date']))
        .subtract(4, 'days')
        .utc()
        .format('YYYY-MM-DD'),
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      label: `${moment(form2.getFieldValue(['date']))
        .subtract(3, 'days')
        .format('MM-DD')}`,
      dataIndex: `${moment(form2.getFieldValue(['date']))
        .subtract(3, 'days')
        .format('YYYY-MM-DD')}`,
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      label: `${moment(form2.getFieldValue(['date']))
        .subtract(2, 'days')
        .format('MM-DD')}`,
      dataIndex: `${moment(form2.getFieldValue(['date']))
        .subtract(2, 'days')
        .format('YYYY-MM-DD')}`,
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      label: `${moment(form2.getFieldValue(['date']))
        .subtract(1, 'days')
        .format('MM-DD')}`,
      dataIndex: moment(form2.getFieldValue(['date']))
        .local()
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      label: 'Quantity',
      dataIndex: 'qty',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Total Sold',
      dataIndex: 'sold',
      render: (text: any) => <div className="text-center">{numberDecimalFormatter(text)}</div>
    },
    {
      label: 'Total in Lots',
      dataIndex: 'totalLots',
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      label: 'Total From Others',
      dataIndex: 'locationDCData',
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    }
  ];

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'S.N',
          dataIndex: 'SN',
          width: 50
        },
        {
          title: 'Product',
          dataIndex: 'name',
          width: 250
        },
        {
          title: 'Unit',
          width: 60,
          dataIndex: 'unitName'
        },
        {
          title: `${moment(form2.getFieldValue(['date']))
            .subtract(6, 'days')
            .format('MM-DD')}`,
          dataIndex: `${moment(form2.getFieldValue(['date']))
            .subtract(6, 'days')
            .format('YYYY-MM-DD')}`,
          width: 100
        },
        {
          title: `${moment(form2.getFieldValue(['date']))
            .subtract(5, 'days')
            .format('MM-DD')}`,
          dataIndex: `${moment(form2.getFieldValue(['date']))
            .subtract(5, 'days')
            .format('YYYY-MM-DD')}`,
          width: 100
        },
        {
          title: `${moment(form2.getFieldValue(['date']))
            .subtract(4, 'days')
            .format('MM-DD')}`,
          dataIndex: moment(form2.getFieldValue(['date']))
            .subtract(4, 'days')
            .utc()
            .format('YYYY-MM-DD'),
          width: 100
        },
        {
          title: `${moment(form2.getFieldValue(['date']))
            .subtract(3, 'days')
            .format('MM-DD')}`,
          dataIndex: `${moment(form2.getFieldValue(['date']))
            .subtract(3, 'days')
            .format('YYYY-MM-DD')}`,
          width: 100
        },
        {
          title: `${moment(form2.getFieldValue(['date']))
            .subtract(2, 'days')
            .format('MM-DD')}`,
          dataIndex: `${moment(form2.getFieldValue(['date']))
            .subtract(2, 'days')
            .format('YYYY-MM-DD')}`,
          width: 100
        },
        {
          title: `${moment(form2.getFieldValue(['date']))
            .subtract(1, 'days')
            .format('MM-DD')}`,
          dataIndex: moment(form2.getFieldValue(['date']))
            .local()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
          width: 100
        },
        {
          title: 'Quantity',
          width: 80,
          dataIndex: 'qty'
        },

        {
          title: 'Total Sold',
          dataIndex: 'sold',
          width: 100
        },
        {
          title: 'Total in Lots',
          dataIndex: 'totalLots',
          width: 150
        },
        {
          title: 'Total From Others',
          dataIndex: 'locationDCData',
          width: 150
        }
      ];
      if (data?.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = data?.map((item: any, index: number) => {
        return {
          ...item,
          SN: index + 1
        };
      });
      exportExcel(columns, dataUpdated, 'Estimated Stock');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  const fetchLastWeekSoldData = async (
    arr: number[],
    datas: any,
    callapi = true,
    prevresponse?: any
  ) => {
    // console.log('arr', arr);
    let response: any;
    if (callapi) {
      response = await get_latest_week_sales(arr, form2.getFieldValue(['date']));
    } else {
      response = { data: prevresponse };
    }
    // console.log('response', response);
    const productanddate: any = {};
    // productanddate = {productId:{date1:quantity,date2:quantity2}}
    for (let ind = 0; ind < response?.data?.length; ind++) {
      // const date = convertLocalToUTCString(response.data[ind].createdAt, 'YYYY-MM-DD');
      const date = moment(response.data[ind].createdAt).local().format('YYYY-MM-DD');
      // console.log('date', date);
      const productId = response.data[ind].productId;
      if (productId in productanddate) {
        if (date in productanddate[productId]) {
          productanddate[productId] = {
            ...productanddate[productId],
            [date]: productanddate[productId][date] + response.data[ind].baseUnitQuantity
          };
        } else {
          productanddate[productId] = {
            ...productanddate[productId],
            [date]: response.data[ind].baseUnitQuantity
          };
        }
      } else {
        // console.log('this runs');
        productanddate[productId] = { [date]: response.data[ind].baseUnitQuantity };
      }
    }
    // console.log('productdate', productanddate);
    for (const key in productanddate) {
      for (let ind = 0; ind < datas.length; ind++) {
        if (datas[ind].productId == key) {
          let total = 0;
          for (const k in productanddate[key]) {
            productanddate[key][k] = productanddate[key][k] / datas[ind].baseUnitMultiplier;
            total += productanddate[key][k];
          }
          datas[ind] = {
            ...datas[ind],
            ...productanddate[key],
            sold: total
          };
          break;
        }
      }
    }
    // console.log('datas', datas);
    setData(datas.filter((curr: any) => !curr.hidden));
  };

  // console.log('product Search', productSearch);
  // console.log('columns', columns);
  return (
    <Spin spinning={loading}>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title="Sell Estimate Stock View For PDF print">
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={data ? data : []}
            reff={printPDFRef}
            title={'Sell Estimate Stock List'}
          />
          <div className="flex justify-end mt-3">
            <Button type="primary" onClick={handlePDFExport}>
              Print Pdf
            </Button>
          </div>
        </div>
      </CustomModal>
      <Modal
        title={`All Lots for product ${modalData.productName}`}
        visible={isModalOpen}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}>
        <Table
          bordered
          dataSource={modalData.lotInfo}
          columns={columnss}
          pagination={false}
          size="small"
        />
      </Modal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        button={
          <>
            {/* <div className="flex flex-wrap items-center justify-end"> */}
            <div>
              {locationSearch.length > 0 && (
                <TableFilter
                  defaultValues={{
                    // dateCustom: [moment(), moment().add(1, 'days')],
                    locationId: preferenceLocationId ? preferenceLocationId : locationSearch[0].id,
                    date: moment().format('YYYY-MM-DD')
                  }}
                  singleDate={true}
                  dateCustom={false}
                  initial={true}
                  onSubmit={onSearch}
                  // outsidestyle={'flex justify-end items-end gap-2'}
                  styleforbuttons={'flex items-center justify-end'}
                  style={
                    'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                  }
                  buttonWrapperClassName="flex-col gap-2 items-end justify-center sm:flex-row sm:items-center sm:justify-end sm:gap-0"
                  form={form2}>
                  <LocationSearchV2 hasParentFormItem={false} name="locationId" showAll />
                </TableFilter>
              )}
            </div>
            {/* </div> */}
          </>
        }>
        <Form form={form} component={false}>
          <CustomizeTable
            columns={columns}
            data={data}
            notshowPagination
            customScroll={{ y: '75vh', x: 1600 }}
            buttons={
              <div className="flex">
                <CustomButton
                  text="Excel Export"
                  backgroundColor="#1890ff"
                  onClick={handleExport}
                />
                <CustomButton
                  text="PDF Export"
                  backgroundColor="white"
                  textColor="#1890ff"
                  borderColor="#1890ff"
                  onClick={() => setOpenModalforexport(true)}
                />
              </div>
            }
          />
        </Form>
        <div className={'grid '}>
          <PageHeader
            subTitle="Add Product"
            style={{
              padding: '8px 0px 8px 10px'
            }}
          />
          <ProductSearchForEstimateStock
            setData={setData}
            data={data}
            selectedLocation={selectedLocation}
            type="SALE"
            disabled={form2.getFieldValue(['date']) == moment().format('YYYY-MM-DD') ? false : true}
            locationId={form.getFieldValue(['locationId'])}
          />
        </div>
        <div className="flex justify-end mt-5">
          <Form.Item>
            <Button
              type="primary"
              onClick={() => onFinish(form.getFieldsValue())}
              disabled={
                form2.getFieldValue(['date']) == moment().format('YYYY-MM-DD') ? false : true
              }>
              Submit
            </Button>
          </Form.Item>
        </div>
      </AppContent>
    </Spin>
  );
};

export default SellOrderFormCreate;
const breadcrumbItems = [
  {
    label: 'Sell',
    link: '/sell'
  },
  {
    label: 'Estimate Stock'
  }
];
