import { Button, Divider, Form, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import useDebounce from '@/hooks/useDebounce';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_active_location_list } from '@/services/locations/queries';
import { ILocations } from '@/services/locations/types';

interface LocationSearchProps {
  formData?: {
    formName: (string | number)[];
    formLabel: string;
  };
  onSelect?: (value: (number | string)[]) => void;
  withoutForm?: boolean;
  notAll?: boolean;
  required?: boolean;
  setLocationInformation?: any;
  disabled?: boolean;
  hasLabel?: boolean;
  excludedLocation?: number;
  isMultiple?: boolean;
}

const { Option } = Select;

const LocationSearchMultiple: React.FC<LocationSearchProps> = ({
  formData,
  onSelect,
  withoutForm,
  notAll,
  required,
  disabled = false,
  hasLabel = true,
  excludedLocation,
  isMultiple = true
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);

  useEffect(() => {
    // searchLocation(debouncedSearchValue);
    searchLocationIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue, excludedLocation]);
  const [locationSearch, setLocationSearch] = useState<ILocations[]>([]);

  const searchLocationIndexDB = async (value: string) => {
    try {
      const response: ILocations[] = await LocationsDB.searchActiveLocationByName(value, 100);
      if (!response || response.length == 0) {
        message.info('Cannot find any location with that name in cache, searching in server...');
        searchLocation(value);
      } else {
        if (excludedLocation) {
          setLocationSearch(response.filter((value) => value.id !== excludedLocation));
        } else {
          setLocationSearch(response);
        }
        setIsCacheResponse(true);
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  const searchLocation = async (value: string) => {
    let response;
    try {
      response = await get_active_location_list(0, 100, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any location with that name in server!');
      setLocationSearch([]);
    } else {
      if (excludedLocation) {
        setLocationSearch(response.data.results.filter((value) => value.id !== excludedLocation));
        LocationsDB.addLocations(
          response.data.results.filter((value) => value.id !== excludedLocation)
        );
      } else {
        setLocationSearch(response.data.results);
        LocationsDB.addLocations(response.data.results);
      }
    }
    setIsCacheResponse(false);
  };

  const options = locationSearch.map((value: any) => (
    <Option key={value.id} value={value.id}>
      {value.name}
    </Option>
  ));

  return (
    <>
      {withoutForm ? (
        <Select
          mode={isMultiple ? 'multiple' : undefined}
          maxTagCount={isMultiple ? 'responsive' : undefined}
          disabled={disabled}
          showSearch={true}
          showArrow={false}
          filterOption={false}
          placeholder="Search location"
          dropdownMatchSelectWidth={false}
          onSelect={() => {
            setSearchValue('');
          }}
          onSearch={(val) => {
            setSearchValue(val);
          }}
          onBlur={() => {
            setSearchValue('');
          }}
          style={{ width: '100%' }}
          dropdownRender={(menu) => (
            <>
              {menu}
              <>
                <Divider style={{ margin: '8px 0' }} />
                {isCacheResponse ? (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchLocation(searchValue);
                      }}>
                      {'Pull More & Sync'}
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </>
            </>
          )}>
          {notAll ? <></> : <Option value="All">All</Option>}
          {options}
        </Select>
      ) : (
        <Form.Item
          name={formData ? formData.formName : ['locationIds']}
          label={formData && hasLabel ? formData.formLabel : 'Locations'}
          rules={[
            {
              required: required,
              message: 'Please choose location!'
            }
          ]}>
          <Select
            mode={isMultiple ? 'multiple' : undefined}
            maxTagCount={isMultiple ? 'responsive' : undefined}
            disabled={disabled}
            placeholder={'Search location'}
            onChange={onSelect}
            filterOption={false}
            dropdownMatchSelectWidth={false}
            onSelect={() => {
              setSearchValue('');
            }}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            onBlur={() => {
              setSearchValue('');
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}

                <>
                  <Divider style={{ margin: '8px 0' }} />
                  {isCacheResponse ? (
                    <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                      <Button
                        type="text"
                        style={{
                          color: 'blue',
                          width: '100%'
                        }}
                        onClick={() => {
                          searchLocation(searchValue);
                        }}>
                        {'Pull More & Sync'}
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              </>
            )}>
            {notAll ? <></> : <Option value="All">All</Option>}
            {options}
          </Select>
        </Form.Item>
      )}
    </>
  );
};

export default LocationSearchMultiple;
