import { AccountRulesEvent, redirectData } from '@/services/accounts/enums';
import { get_journal_details } from '@/services/accounts/queries';
import { get_payment_details } from '@/services/payments/queries';
import { PaymentAgainst } from '@/services/payments/enums';
import { get_sell_return_details_by_id } from '@/services/sell/queries';
import { get_purchase_reutrn_details_by_id } from '@/services/purchases/queries';

export function createEmptyPromise<T>(obj: T): Promise<T> {
  return new Promise((resolve) => {
    resolve(obj);
  });
}

export const fetchReferenceAndRedirect = async (journalId: number) => {
  const response = await get_journal_details(journalId);
  const redirectObj = redirectData.find((val) => val.key === response.type);
  const hasRedirect = redirectObj && response;
  if (!hasRedirect) return null;

  switch (response.type) {
    case AccountRulesEvent.SELL_PAYMENT_CASH:
    case AccountRulesEvent.SELL_PAYMENT_BANK:
    case AccountRulesEvent.SELL_PAYMENT_OTHER: {
      const paymentDetails = await get_payment_details(response.referenceId);
      if (paymentDetails.against === PaymentAgainst.Sell)
        response.redirectId = paymentDetails.againstId;
      break;
    }
    case AccountRulesEvent.SELL_RETURN: {
      const returnDetails = await get_sell_return_details_by_id(response.referenceId);
      response.redirectId = returnDetails.sellId;
      break;
    }
    case AccountRulesEvent.PURCHASE_PAYMENT_CASH:
    case AccountRulesEvent.PURCHASE_PAYMENT_USER:
    case AccountRulesEvent.PURCHASE_PAYMENT_OTHER: {
      const paymentDetails = await get_payment_details(response.referenceId);
      if (paymentDetails.against === PaymentAgainst.Purchase)
        response.redirectId = paymentDetails.againstId;
      break;
    }
    case AccountRulesEvent.PURCHASE_RETURN: {
      const returnDetails = await get_purchase_reutrn_details_by_id(response.referenceId);
      response.redirectId = returnDetails.purchaseId;
      break;
    }
    case AccountRulesEvent.TRANSFER_TO: {
      response.redirectId = `${response.referenceId}?type=in` as any;
      break;
    }
    default: {
      response.redirectId = response.referenceId;
    }
  }

  return { link: redirectObj.link, redirectId: response.redirectId };
};
