import moment from 'moment';

function getAccountInitialDate(query: string): [moment.Moment, moment.Moment] {
  const searchParams = new URLSearchParams(query);

  const financialStartDate = searchParams.get('fsd');
  const financialEndDate = searchParams.get('fed');
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');

  const hasFinancialYear = financialStartDate && financialEndDate;
  const hasStartDate = startDate && endDate;

  // check if financial year is set. If not, use the current year
  if (!hasFinancialYear) {
    const currentStartDate = searchParams.get('csd');
    const currentEndDate = searchParams.get('ced');
    return [moment(currentStartDate), moment(currentEndDate)];
  }

  // check if customStartDate and customEndDate is set. If not, use the financial year
  if (!hasStartDate) {
    return [moment(financialStartDate), moment(financialEndDate)];
  }

  // check if customStartDate and customEndDate is within the financial year. If not, use the financial year range else use the customStartDate and customEndDate
  const isStartDateWithinFinancialYear = moment(startDate).isBetween(
    financialStartDate,
    financialEndDate
  );

  const isEndDateWithinFinancialYear = moment(endDate).isBetween(
    financialStartDate,
    financialEndDate
  );

  if (isStartDateWithinFinancialYear && isEndDateWithinFinancialYear) {
    return [moment(startDate), moment(endDate)];
  }

  return [moment(financialStartDate), moment(financialEndDate)];
}

export default getAccountInitialDate;
