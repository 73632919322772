import { IProductQtyPriceSumReport } from '@/services/report/types';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

const fields = ['Unit', 'Product', 'Location', 'TOTAL QTY', 'TOTAL PRICE'];

export const tableExportColumns = fields.map((field) => ({
  label: field,
  dataIndex: field,
  render: (text: string) => {
    return <div className="text-center">{text}</div>;
  }
}));

export const excelExportColumns = fields.map((field) => ({
  title: field,
  dataIndex: field
}));

export function getUpdatedData(data: IProductQtyPriceSumReport[]) {
  return data.map((item) => {
    return {
      ...item,
      'TOTAL QTY': nepaliNumberFormatter(parseFloat(item['TOTAL QTY'])),
      'TOTAL PRICE': nepaliNumberFormatter(parseFloat(item['TOTAL PRICE']))
    };
  });
}
