import { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

// ANTD Components
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { Button, Checkbox, Divider, Form, Radio, Space, Spin, message } from 'antd';

import TableColumn from './TableColumn';
import TableFilter from '@/components/FliterTable';
import { exportToExcel } from '../export.services';
import AppContent from '@/components/Common/Content/Content';
import { IBalanceSheetWithTransaction } from '@/services/accounts/types';
import { ReusableDrawer } from '@/components/Common/Drawer/ReusableDrawer';
import { calculateStartAndEndBalance } from '@/services/accounts/services';
import { find_locationId_preference } from '@/store/localstorage/preferences';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';

import {
  addTransactionToBalanceSheet,
  getBalanceSheetData,
  getFlattenData
} from '../balance-sheet.services';
import getAccountInitialDate from '@/utils/getAccountInitialDate';
import AccountFilterTable from '@/components/AccountFilterTable';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

function BalanceSheet() {
  const [isLoading, setIsLoading] = useState(false);
  const [valueExport, setValueExport] = useState(1);
  const [openExportDrawer, setOpenExportDrawer] = useState(false);

  const [locationId, setLocationId] = useState<number | string>();
  const [currParentIds, setCurrParentIds] = useState<number[]>([]);
  const [tableData, setTableData] = useState<IBalanceSheetWithTransaction[]>([]);

  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [shownColumns, setShownColumns] = useState<CheckboxValueType[]>(['C']);
  const [searchParams] = useSearchParams();

  const [total, setTotal] = useState({
    debitStart: 0,
    creditStart: 0,
    debitEnd: 0,
    creditEnd: 0,
    creditTransaction: 0,
    debitTransaction: 0
  });

  const [form] = Form.useForm();

  const preferenceLocationId = find_locationId_preference() || 1;
  const breadcrumbItems = [{ label: 'Balance Sheet', link: '/balance-sheet' }];

  const location = useLocation();

  async function onSubmitFilter(vals?: string) {
    let hasMoreData = false;
    setIsLoading(true);

    const { locationId, shownColumns } = form.getFieldsValue(['locationId', 'shownColumns']);

    setExpandedRows([]);
    setLocationId(locationId);
    setShownColumns(shownColumns);

    // Reset total balance
    const totalBalance = {
      debitStart: 0,
      creditStart: 0,
      debitEnd: 0,
      creditEnd: 0,
      creditTransaction: 0,
      debitTransaction: 0
    };

    // Get data from API
    let date = form.getFieldsValue(['startDate', 'endDate']);

    if (vals) {
      const params = new URLSearchParams(vals);
      const startDate = params.get('startDate');
      const endDate = params.get('endDate');

      if (startDate && endDate) {
        date = { startDate, endDate };
      }
    }

    try {
      // Get Data From API and Add Transaction to Balance Sheet based on current date selected
      const { results } = await addTransactionToBalanceSheet({
        locationId,
        date,
        currentBalanceSortBy: 'DESC',
        parentIds: undefined,
        skip: 0,
        count: 100
      });

      const parentIds = results.map((item) => item.id);
      setCurrParentIds(parentIds);

      // calculate start and end balance for each data
      const balanceResults = await calculateStartAndEndBalance<typeof results>(
        date.startDate,
        parentIds,
        results,
        searchParams
      );

      // Get Actual Balance Sheet Data
      const balanceSheetActualData = await getBalanceSheetData<typeof balanceResults>({
        locationId,
        data: balanceResults,
        parentIds,
        date,
        currentBalanceSortBy: 'DESC',
        skip: 0,
        count: 100,
        searchParams
      });

      // Check if there are more data
      if (locationId !== '') hasMoreData = balanceSheetActualData.length >= 100;

      // Calculate total balance for each data
      balanceSheetActualData.forEach((data) => {
        if (data.transaction) {
          totalBalance.debitTransaction += data.transaction.totalDebit || 0;
          totalBalance.creditTransaction += data.transaction.totalCredit || 0;
        }

        if (data.startBalance) {
          totalBalance.debitStart += data.startBalance > 0 ? data.startBalance : 0;
          totalBalance.creditStart += data.startBalance < 0 ? Math.abs(data.startBalance) : 0;
        }

        if (data.endBalance) {
          totalBalance.debitEnd += data.endBalance > 0 ? data.endBalance : 0;
          totalBalance.creditEnd += data.endBalance < 0 ? Math.abs(data.endBalance) : 0;
        }
      });

      // Set Data to Table to be displayed
      if (!hasMoreData) {
        setTableData(balanceSheetActualData);
      } else {
        const obj = {} as IBalanceSheetWithTransaction;
        setTableData([
          ...balanceSheetActualData,
          {
            ...obj,
            parentIds: parentIds,
            skip: balanceSheetActualData.length,
            hasMore: true
          }
        ]);
      }

      // Set Total Balance
      setTotal(totalBalance);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  }

  async function onExportTreeView() {
    setIsLoading(true);

    try {
      const date = form.getFieldsValue(['startDate', 'endDate']);

      // Get Data from API or from current state and flatten it to be exported
      const flattenedData = await getFlattenData({
        date,
        locationId: locationId as number | string,
        valueExport,
        parentIds: currParentIds,
        currentData: tableData
      });

      // Check if there are data to be exported
      if (!flattenedData || flattenedData.length === 0) {
        message.error('No Data to Export');
        return;
      }

      // Calculate Total Balance for each data to be displayed in excel footer
      const totalBalance = {
        debitStart: 0,
        creditStart: 0,
        debitEnd: 0,
        creditEnd: 0,
        creditTransaction: 0,
        debitTransaction: 0
      };

      flattenedData.forEach((data) => {
        if (data.node === 0) {
          totalBalance.debitStart += data.start.debit || 0;
          totalBalance.creditStart += data.start.credit || 0;
          totalBalance.debitEnd += data.end.debit || 0;
          totalBalance.creditEnd += data.end.credit || 0;
          totalBalance.debitTransaction += data.transaction.totalDebit || 0;
          totalBalance.creditTransaction += data.transaction.totalCredit || 0;
        }
      });

      // Type Casting to be used in excel export
      const data = flattenedData as (IBalanceSheetWithTransaction & {
        date: string;
        miti: string;
      })[];

      // Export to Excel
      await exportToExcel({ totalBalance, data, date, valueExport, shownColumns });
    } catch (error) {
      console.log('Error on export tree view', error);
    } finally {
      setIsLoading(false);
      setOpenExportDrawer(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter
        button={
          <div>
            <AccountFilterTable
              form={form}
              hasHours={false}
              defaultValues={{
                dateCustom: getAccountInitialDate(location.search),
                locationId: '',
                shownColumns: ['C']
              }}
              initial={false}
              onSearch={onSubmitFilter}
              buttonParentStyle={'flex justify-end items-center'}>
              <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />

              <Divider orientation="left">Show Columns</Divider>
              <Form.Item name={['shownColumns']}>
                <Checkbox.Group>
                  <Checkbox value="O">Opening Balance</Checkbox>
                  <br />
                  <Checkbox value="T">Transaction</Checkbox>
                  <br />
                  <Checkbox value="C">Closing Balance</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </AccountFilterTable>
          </div>
        }>
        <ReusableDrawer
          title="Export"
          setShowDrawer={setOpenExportDrawer}
          showDrawer={openExportDrawer}
          footer={
            <Space>
              <Button type="primary" onClick={onExportTreeView}>
                Download
              </Button>
            </Space>
          }>
          <Radio.Group onChange={(e) => setValueExport(e.target.value)} value={valueExport}>
            <Space direction="vertical">
              <Radio value={1}>Export All</Radio>
              <Radio value={2}>Export Current</Radio>
            </Space>
          </Radio.Group>
        </ReusableDrawer>
        <div className="my-2 flex justify-end">
          <Button type="primary" onClick={() => setOpenExportDrawer(true)}>
            Export
          </Button>
        </div>

        <TableColumn
          date={form.getFieldsValue(['startDate', 'endDate'])}
          locationId={locationId}
          shownColumns={shownColumns}
          tableData={tableData}
          expandedRows={expandedRows}
          total={total}
          // Handlers
          setTableData={setTableData}
          setExpandedRows={setExpandedRows}
          setTotal={setTotal}
        />
      </AppContent>
    </Spin>
  );
}

export default BalanceSheet;
