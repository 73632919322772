import { Button, Form, PageHeader } from 'antd';
import React, { useState, useEffect } from 'react';
import AppContent from '../../../components/Common/Content/Content';
import { download_report } from '../../../services/report/queries';
import { LocationSearch } from '../../../components/Common/LocationSearch/LocationSearch';
import { find_locationId_preference } from '../../../store/localstorage/preferences';
import CustomErrorModal from '../../../components/Common/CustomErrorModal';

const ProductDownload = () => {
  const [form] = Form.useForm();
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(null);
  const preferenceLocationId = find_locationId_preference();

  const productDownload = async () => {
    if (!selectedLocationId) {
      CustomErrorModal({
        title: 'Error',
        message: 'Please select a location!'
      });
      return;
    }

    const response = await download_report(
      {
        locationId: selectedLocationId
      },
      52
    );
    window.open(response.data, '_blank');
  };

  const unitDownload = async () => {
    const response = await download_report({}, 33);
    window.open(response.data, '_blank');
  };

  const breadcrumbItems = [
    {
      label: 'Product Downloads'
    }
  ];

  const onLocationChange = async (id: number) => {
    setSelectedLocationId(id);
  };

  useEffect(() => {
    if (preferenceLocationId !== false) {
      setSelectedLocationId(preferenceLocationId);
    }
  }, []);

  return (
    <>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 mb-5'}>
          <LocationSearch
            required={true}
            notAll={true}
            onSelect={onLocationChange}
            withoutForm={true}
            value={preferenceLocationId}
          />
        </div>

        <PageHeader
          title="Download All Products and Units"
          style={{
            padding: '8px 0px'
          }}
        />
        <Button
          onClick={() => productDownload()}
          style={{ backgroundColor: '#0AA245', color: 'white', borderRadius: '10px' }}>
          Products Download
        </Button>
        <Button
          onClick={() => unitDownload()}
          style={{
            backgroundColor: '#0AA245',
            color: 'white',
            borderRadius: '10px',
            marginLeft: '10px'
          }}>
          Units Download
        </Button>
      </AppContent>
    </>
  );
};

export default ProductDownload;
