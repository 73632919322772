import { Button } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const style = {
  backgroundColor: 'black',
  color: 'white',
  borderRadius: '8px'
};

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        padding: '10px'
        // alignItems: 'center',
        // marginTop: '5%'
      }}>
      <div style={{ marginTop: '5%' }}>
        <div className="font-bold text-2xl">Oops! ERROR</div>
        <div className="font-black text-4xl">404</div>
        <div className="font-bold text-2xl">Page not found!</div>
        <div className="font-normal text-lg">
          The page you are looking for might have been removed, had its name changed or is
          temporarily unavailable.
        </div>
        <div style={{ marginTop: '1rem' }}>
          <Button onClick={() => navigate('/')} style={style}>
            Go to HomePage
          </Button>
          <Button onClick={() => navigate(-1)} className="ml-3" style={style}>
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
