import React, { useEffect, useState } from 'react';
import {
  get_sell_details,
  get_sell_lines_details,
  get_sell_lists_v2,
  get_sell_order_details
} from '../../../services/sell/queries';
import { Collapse, Form, Input, Menu, Select, Spin, Tooltip, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { ISellFromServer, ISellResponseFromServer } from '../../../services/sell/types';
import UsersDB from '../../../store/localstorage/UsersDB';
import CustomersDB from '../../../store/localstorage/CustomerDB';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import { get_location_details, get_location_list } from '../../../services/locations/queries';
import {
  convertLocalToUTCString,
  convertUTCStringtoLocalString
} from '../../../utils/convertToUTC';
import { nepaliNumberFormatter, numberDecimalFormatter } from '../../../utils/numberFormatter';
import {
  get_customer_details,
  get_customer_list,
  get_customer_list_ids,
  get_customer_list_route,
  get_user_details,
  get_user_list_ids
} from '../../../services/users/queries';
import Table, { ColumnsType, TableProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import TableCell from '../../../components/Common/CustomizeTable/CustomCell';
import CopyButton from '../../../components/Common/CopyButton';
import {
  find_default_pos_invoice,
  find_locationId_preference,
  find_to_use_NPR
} from '../../../store/localstorage/preferences';
import moment from 'moment';
import { checkAccess } from '../../../routes/acl';
import { RollbackOutlined } from '@ant-design/icons';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import AppContent from '../../../components/Common/Content/Content';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import { CustomModal } from '../../../components/Common/CustomModal';
import SellReturnView from '../../sell/return-view';
import TableFilter from '../../../components/FliterTable';
import { getUserData } from '../../../utils/auth.utils';
import { LocationSearch } from '../../../components/Common/LocationSearch/LocationSearch';
import PayoutStatus from '../../../components/Common/PayoutStatus';
import { UsersSearch } from '../../../components/Common/UsersSearch';
import { CustomerSearch } from '../../../components/Common/CustomerSearch/CustomerSearch';
import { ConvertObjectToURL } from '../../../utils/converturl';
import { useMutation, useQuery } from '@tanstack/react-query';
import { get_invoices_details, get_invoices_list } from '../../../services/settings/queries';
import { update_sell_print_count_mutation } from '../../../services/sell/mutations';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import { get_product_list_ids, get_units_list } from '../../../services/products/queries';
import { IProductType } from '../../../services/products/types';
import RoutesDB from '../../../store/localstorage/RoutesDB';
import { get_routes_list } from '../../../services/routes/queries';
import { shortNameHiearchy } from '../../sell/sell-order/view';
import SellReturn from '../../sell/sell-return/create';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import ReusableSellView from '../../sell/view/ReusableView';
import { ISellInvoice, ISellRecordData } from '../../../services/invoice/types';
import { getSellPrintData } from '../../../components/Common/InvoicePrint/SellInvoice/services';
import BillPrint from '../../../components/Common/InvoicePrint/BillPrint/BillPrint';
import SellInvoice from '../../../components/Common/InvoicePrint/SellInvoice/SellInvoice';
import InvoicePrintButton from '../../../components/Common/InvoicePrintButton/InvoicePrintButton';
import { ICreateInvoiceResponse } from '../../../services/settings/types';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const colorCode: any = {
  full: 'green',
  pending: 'red',
  partial: 'purple'
};
const { Option } = Select;

export const SellListPos = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsloading] = useState(true);
  const [allSellList, setallSellList] = useState<ISellResponseFromServer>({
    total: 0,
    results: []
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState<any>({
    totalAmount: 0,
    totalReturn: 0
  });
  const [id, setId] = useState(0);
  const [invoiceLists, setInvoiceLists] = useState<ICreateInvoiceResponse[]>([]);
  const [isSellReturnModalOpen, setIsSellReturnModalOpen] = useState(false);
  const [isSellModalOpen, setIsSellModalOpen] = useState(false);
  const [sellId, setSellId] = useState('');
  const [urlData, setUrlData] = useState('');
  const [invoiceData, setInvoiceData] = useState<ISellInvoice>(Object);
  const [billData, setBillData] = useState<ISellInvoice>(Object);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  const [openModalForBillPrint, setOpenModalForBillPrint] = useState<boolean>(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;
  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    // console.log('response-->', response);
    setInvoiceLists(response.data);
    return response;
  });

  const defaultBillPrint = find_default_pos_invoice();

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };
  const getInfo = async (filter = '') => {
    setIsloading(true);
    setUrlData(filter);
    const response = await get_sell_lists_v2(filter);
    if (response?.data.results) {
      const searchUsers: any = {};
      let ctotalamount = 0,
        creturnamount = 0;
      const createdByUsers: any = {};
      for (let index = 0; index < response.data.results.length; index++) {
        if (response?.data?.results[index]?.createdBy) {
          const createdByuser: any = await UsersDB.getUser(response.data.results[index].createdBy);
          if (!createdByuser) {
            // user = await get_customer_details(response.data.resultss[index].customerId);
            // user = { ...user.customer, user: user.user.user };
            // await CustomersDB.addCustomers([user]);
            if (response.data.results[index].createdBy in createdByUsers) {
              createdByUsers[response.data.results[index].createdBy] = [
                ...createdByUsers[response.data.results[index].createdBy],
                index
              ];
            } else {
              createdByUsers[response.data.results[index].createdBy] = [index];
            }
          } else {
            response.data.results[index].createdByName = createdByuser.name;
            // response.data.resultsWithCustomer[index].userData = user;
          }
        }

        const user: any = await CustomersDB.getCustomer(response.data.results[index].customerId);
        if (!user) {
          // user = await get_customer_details(response.data.results[index].customerId);
          // user = { ...user.customer, user: user.user.user };
          // await CustomersDB.addCustomers([user]);
          if (response.data.results[index].customerId in searchUsers) {
            searchUsers[response.data.results[index].customerId] = [
              ...searchUsers[response.data.results[index].customerId],
              index
            ];
          } else {
            searchUsers[response.data.results[index].customerId] = [index];
          }
        } else {
          response.data.results[index].userName = user.user.name;
          response.data.results[index].userData = user;
        }

        let locD: any = await LocationsDB.getLocation(response.data.results[index].locationId);
        if (!locD) {
          const allLocations = await get_location_list();
          await LocationsDB.addLocations([...allLocations.data.results]);
          locD = await LocationsDB.getLocation(response.data.results[index].locationId);
        }
        response.data.results[index].locationName = locD.name;
        ctotalamount += response.data.results[index].totalAmount;
        creturnamount += response.data.results[index].amountReturned;
        response.data.results[index].date = convertUTCStringtoLocalString(
          response.data.results[index].createdAt,
          'YYYY-MM-DD'
        );
        response.data.results[index].remainingAmount = numberDecimalFormatter(
          response.data.results[index].totalAmount -
            response.data.results[index].amountReturned -
            response.data.results[index].amountPaid
        );
      }
      setTotal({ totalAmount: ctotalamount, totalReturn: creturnamount });
      const searchUserslength = Object.entries(searchUsers).length;
      if (searchUserslength > 0) {
        const customerresponse = await get_customer_list_ids([...Object.keys(searchUsers)]);
        for (const key in searchUsers) {
          const customerUser = customerresponse?.data?.results.find(
            (currCustomer: any) => currCustomer.id == key
          );
          for (let i = 0; i < searchUsers[key].length; i++) {
            response.data.results[searchUsers[key][i]].userName = customerUser?.user.name;
            response.data.results[searchUsers[key][i]].userData = customerUser;
          }
          await CustomersDB.addCustomers([customerUser]);
        }
      }

      const createdByUserslength = Object.entries(createdByUsers).length;
      // console.log('createdbyuserslength-->', createdByUsers);
      if (createdByUserslength > 0) {
        const customerresponse = await get_user_list_ids([...Object.keys(createdByUsers)]);
        for (const key in createdByUsers) {
          const createdByUser = customerresponse?.data?.results.find(
            (currCustomer: any) => currCustomer.id == key
          );
          if (!createdByUser) continue;
          for (let i = 0; i < createdByUsers[key].length; i++) {
            response.data.results[createdByUsers[key][i]].createdByName = createdByUser.name;
          }
          await UsersDB.addUsers([createdByUser]);
        }
      }
    }
    setIsloading(false);
    setallSellList(response.data);
    return response;
  };

  const updatePrintCountMutation = useMutation(update_sell_print_count_mutation, {
    onSuccess: () => {
      getInfo(urlData);
    }
  });

  const generateBillForPrint = async (record: ISellRecordData, curr: any) => {
    setIsloading(true);
    const billModalData = await getSellPrintData(record.id, record.locationId, record.sellOrderId);

    let invoiceLayouts;
    if (defaultBillPrint) {
      invoiceLayouts = (await get_invoices_details(defaultBillPrint.toString())).data;
    } else if (invoiceLists) {
      invoiceLayouts = invoiceLists.find((curr) => {
        const content = JSON.parse(curr.content);
        return content.design == 'bill';
      });
    } else {
      message.error('Could not generate bill. No bill layout found!');
    }

    let paymentType = '';
    if (
      'payments' in billModalData.sellDetails &&
      billModalData.sellDetails.payments &&
      billModalData.sellDetails.payments.length > 0
    ) {
      paymentType = billModalData.sellDetails.payments[0].paymentMethod;
    } else {
      paymentType = 'Pending Payment';
    }

    setIsloading(false);

    if (invoiceLayouts) {
      setBillData({ ...billModalData, invoiceLayouts: invoiceLayouts, paymentType: paymentType });
    }
    setOpenModalForBillPrint(true);
  };

  const handleBillPrintModalClose = async () => {
    setOpenModalForBillPrint(false);
    await getInfo(urlData);
  };

  const generateInvoiceForPrint = async (record: ISellRecordData, curr: ICreateInvoiceResponse) => {
    setIsloading(true);
    const invoiceModalData = await getSellPrintData(
      record.id,
      record.locationId,
      record.sellOrderId
    );
    setIsloading(false);

    setInvoiceData({ ...invoiceModalData, invoiceLayouts: curr });

    setOpenModalForInvoicePrint(true);
  };

  const handleInvoicePrintModalClose = () => {
    setOpenModalForInvoicePrint(false);
    getInfo(urlData);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 3,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Sell Return',
      width: 3,
      key: 'sellReturn',
      render: (record: ISellFromServer) => {
        return (
          <TableCell>
            <Tooltip title="Return" color="blue">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setSellId(record.id.toString());
                  setIsSellReturnModalOpen(true);
                }}>
                <RollbackOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
              </div>
            </Tooltip>
          </TableCell>
        );
      }
    },
    {
      title: 'Print',
      width: 3,
      key: 'print',
      render: (record: ISellFromServer) => {
        return (
          <TableCell>
            <InvoicePrintButton
              getInvoice={(curr) => {
                generateBillForPrint(record, curr);
                // generateInvoiceForPrint(record, curr);
              }}
            />
            {/* <div style={{ height: '1.5rem' }} className="flex justify-center items-center">
              <ActionDropdown
                trigger={'hover'}
                insideaction={true}
                menu={
                  <Menu
                    items={invoiceLists?.map((curr: any, ind: number) => {
                      const content: any = JSON.parse(curr.content);
                      const type: boolean = content.design == 'classic' ? false : true;
                      return {
                        key: ind,
                        label: (
                          <Tooltip title="Print receipt" color="blue">
                            <div className="text-center">{curr.name}</div>
                          </Tooltip>
                        ),
                        onClick: () => {
                          if (type) {
                            generateBillForPrint(record, curr);
                          } else {
                            generateInvoiceForPrint(record);
                          }
                        }
                      };
                    })}
                  />
                }
              />
            </div> */}
          </TableCell>
        );
      }
    },
    /* {
      title: 'ID',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      width: 20,
      render: (record: ISellFromServer) => {
        return <TableCell>{record.id} </TableCell>;
      }
    }, */
    {
      title: 'Financial Reference',
      width: 10,
      key: 'financialReference',
      sorter: (a, b) => a.financialReference.localeCompare(b.financialReference),
      sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
      render: (record: ISellFromServer) => {
        return (
          <TableCell>
            <div className="flex flex-row justify-between items-center">
              {record.financialReference}
              {record.financialReference ? <CopyButton text={record.financialReference} /> : null}
            </div>
          </TableCell>
        );
      }
    },
    // {
    //   title: 'Ref',
    //   width: 10,
    //   key: 'referenceNumber',
    //   sorter: (a, b) => a.referenceNumber.localeCompare(b.referenceNumber),
    //   sortOrder: sortedInfo.columnKey === 'referenceNumber' ? sortedInfo.order : null,
    //   render: (record: ISellFromServer) => {
    //     return (
    //       <TableCell>
    //         <div className="flex flex-row justify-between items-center">
    //           <CopyButton text={record.referenceNumber} />
    //           <a
    //             onClick={() => {
    //               setId(record.id);
    //               setIsSellModalOpen(true);
    //             }}>
    //             {record.referenceNumber}
    //           </a>
    //         </div>
    //       </TableCell>
    //     );
    //   }
    // },
    {
      title: 'Customer',
      width: 8,
      key: 'userName',
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record: ISellFromServer) => {
        return (
          // <Link to={`/users/${record.address.userId}`} color="black">
          <TableCell>{record.userName}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Amount',
      width: 4,
      key: 'amount',
      sorter: (a, b) => a.totalAmount - a.shipping - (b.totalAmount - b.shipping),
      sortOrder: sortedInfo.columnKey === 'amount' ? sortedInfo.order : null,
      render: (record: ISellFromServer) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(record.totalAmount - record.shipping)}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Total',
      width: 4,
      key: 'totalAmount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (record: ISellFromServer) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(record.totalAmount)}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Shipping',
      width: 4,
      key: 'shippingCharge',
      sorter: (a, b) => {
        return a.shipping - b.shipping;
      },
      sortOrder: sortedInfo.columnKey === 'amountPaid' ? sortedInfo.order : null,
      render: (record: ISellFromServer) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(record.shipping)}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Location',
      width: 10,
      key: 'locationName',
      sorter: (a, b) => a.locationName.localeCompare(b.locationName),
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (record: ISellFromServer) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <TableCell>{record.locationName}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Date',
      width: 7,
      key: 'date',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (record: ISellFromServer) => {
        return (
          <TableCell>
            <div color="black">
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </div>
          </TableCell>
        );
      }
    }
  ];

  const onPagination = async (pageNo = 1, totalSize = 10, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);

    await getInfo(url);
  };

  const onSellReturnSave = () => {
    // console.log('onSellReturnSave');
    setIsSellReturnModalOpen(false);
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <CustomModal
          footer={false}
          isModalOpen={openModalForInvoicePrint}
          setIsModalOpen={setOpenModalForInvoicePrint}
          title="Sell Invoice Print">
          <SellInvoice
            sellDetails={invoiceData.sellDetails}
            customerDetails={invoiceData.customerDetails}
            lines={invoiceData.lines}
            invoiceLayouts={invoiceData.invoiceLayouts}
            handleModalClose={handleInvoicePrintModalClose}
          />
        </CustomModal>
        <CustomModal
          footer={false}
          isModalOpen={openModalForBillPrint}
          setIsModalOpen={setOpenModalForBillPrint}
          title="Bill Print">
          <BillPrint
            sellDetails={billData.sellDetails}
            customerDetails={billData.customerDetails}
            lines={billData.lines}
            firstCreatedByUserDetails={billData.firstCreatedByUserDetails}
            invoiceLayouts={billData.invoiceLayouts}
            paymentType={billData.paymentType}
            handleModalClose={handleBillPrintModalClose}
          />
        </CustomModal>
        <CustomModal
          isModalOpen={isSellModalOpen}
          setIsModalOpen={setIsSellModalOpen}
          title={'Sell View'}
          footer={false}>
          {/* <SellReturnView data={sellReturnData} /> */}
          <ReusableSellView id={id} fromPos={true} />
        </CustomModal>
        <AppContent
          withfilter={true}
          button={
            <>
              <div>
                {
                  <Collapse>
                    <CollapsePanel header="Filters" key="1" forceRender={true}>
                      <TableFilter
                        form={form}
                        defaultValues={{
                          dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                          locationId: preferenceLocationId ? preferenceLocationId : 1,
                          skip: 0,
                          count: 100,
                          customerGroup: '',
                          user: '',
                          paymentStatus: '',
                          customerId: '',
                          createdBy: '',
                          routeId: ''
                        }}
                        initial={true}
                        onSubmit={onSubmitFilter}
                        style={
                          'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-2'
                        }
                        styleforbuttons={'flex justify-end items-center'}
                        buttons={<></>}>
                        <LocationSearch />
                        <PayoutStatus />
                        <Form.Item name="value" label="Search">
                          <Input />
                        </Form.Item>
                        <CustomerSearch formData={{ formName: 'customerId' }} notRequired />
                        <Form.Item name="type" label="Select">
                          <Select
                            placeholder="Select a type"
                            style={{ borderRadius: '10px' }}
                            allowClear
                            dropdownMatchSelectWidth={false}>
                            <Option value={''} key={'none'}>
                              All
                            </Option>

                            <Option value={'POS'} key={'POS'}>
                              POS
                            </Option>
                            <Option value={'B2B'} key={'B2B'}>
                              B2B
                            </Option>
                          </Select>
                        </Form.Item>
                        <UsersSearch formData={{ formName: 'createdBy' }} />
                      </TableFilter>
                    </CollapsePanel>
                  </Collapse>
                }
              </div>
            </>
          }>
          <div>
            {isSellReturnModalOpen && (
              <CustomModal
                footer={false}
                isModalOpen={isSellReturnModalOpen}
                setIsModalOpen={setIsSellReturnModalOpen}
                title={'Sell Return'}>
                <SellReturn sellId={sellId} onSaveHandler={onSellReturnSave} />
              </CustomModal>
            )}
            <CustomizeTable
              columns={columns}
              data={allSellList.results}
              // usersLoading={isLoading}
              notshowPagination={true}
              customScroll={{ x: 1200, y: '75vh' }}
              // toshowcustomPagintion={true}
              // onPagination={onPagination}
              paginationDatas={{
                page: page,
                total: allSellList.total,
                size: size,
                onPagination
              }}
              toSort={handleChange}
              tableSummary={
                <Table.Summary fixed={true}>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={4}
                      className="text-right mr-2 text-xs"></Table.Summary.Cell>
                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                    <Table.Summary.Cell index={6} className="text-right mr-2 text-xs">
                      Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} className="text-right mr-2 text-xs">
                      {nepaliNumberFormatter(total.totalAmount)}
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              }
            />
          </div>
        </AppContent>
      </Spin>
    </div>
  );
};
