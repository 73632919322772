import { useState } from 'react';

import { CustomModal } from '@/components/Common/CustomModal';
import { getPurchasePrintData } from '@/components/Common/InvoicePrint/PurchaseInvoice/services';
import InvoicePrintButton from '@/components/Common/InvoicePrintButton/InvoicePrintButton';
import { IPurchaseInvoice } from '@/services/invoice/types';
import { IPurchaseList } from '@/services/purchases/types';
import { ICreateInvoiceResponse } from '@/services/settings/types';

import PurchaseInvoice from '@/components/Common/InvoicePrint/PurchaseInvoice/PurchaseInvoice';

interface Props {
  filter: string;
  record: IPurchaseList;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getInfo: (filter?: string) => Promise<void>;
}

function AgentPurchaseInvoiceModal({ record, filter, getInfo, setIsLoading }: Props) {
  const [openModal, setOpenModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState<IPurchaseInvoice>(Object);

  const generateInvoiceForPrint = async (curr: ICreateInvoiceResponse) => {
    setIsLoading(true);
    const invoiceModalData = await getPurchasePrintData(record.id, record.locationId, curr);
    setIsLoading(false);
    setInvoiceData(invoiceModalData);
    setOpenModal(true);
  };

  const handleInvoicePrintModalClose = () => {
    setOpenModal(false);
    getInfo(filter);
  };

  return (
    <>
      <InvoicePrintButton getInvoice={(curr) => generateInvoiceForPrint(curr)} />
      <CustomModal
        footer={false}
        isModalOpen={openModal}
        setIsModalOpen={setOpenModal}
        title="Purchase Invoice Print">
        <PurchaseInvoice
          purchaseDetails={invoiceData.purchaseDetails}
          vendorDetails={invoiceData.vendorDetails}
          lines={invoiceData.lines}
          locationDetails={invoiceData.locationDetails}
          invoiceLayouts={invoiceData.invoiceLayouts}
          handleModalClose={handleInvoicePrintModalClose}
        />
      </CustomModal>
    </>
  );
}

export default AgentPurchaseInvoiceModal;
