import { getValue } from '@testing-library/user-event/dist/utils';
import { Button, Divider, Form, message, Select } from 'antd';
import React, { useState, useEffect, BaseSyntheticEvent, SyntheticEvent, useRef } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { get_product_list } from '../../../services/products/queries';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import CustomErrorModal from '../CustomErrorModal';
import { IProductDetails } from '../../../services/products/types';

interface Iprops {
  formData?: {
    formName: string | (string | number)[];
    label?: string;
  };
  onProductChange?: (val: number, name?: any) => any;
  // productList: IProductDetails[];
  // setProductList: React.Dispatch<React.SetStateAction<IProductDetails[]>>;
  productSearch: IProductDetails[];
  setProductSearch: React.Dispatch<React.SetStateAction<IProductDetails[]>>;
  disabled?: boolean;
  required?: boolean;
}

const ProductSearchForStatus = ({
  formData,
  onProductChange,
  productSearch,
  setProductSearch,
  disabled = false,
  required = false
}: Iprops) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);

  const { Option } = Select;
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);

  useEffect(() => {
    searchProductIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const searchProductIndexDB = async (value: string) => {
    try {
      const response = await ProductsDB.searchProduct(value, 10);
      // console.log('Response-->', response);
      if (!response || response.length == 0) {
        message.info('Cannot find any product with that name in cache, searching in server...');
        searchProduct(value);
      } else {
        setProductSearch(response);
        // checkProductAndAdd(response);
        setIsCacheResponse(true);
      }
    } catch (error) {
      message.error(error as string);
    }
  };
  const searchProduct = async (value: string) => {
    let response;
    try {
      response = await get_product_list(0, 10, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any product with that name!');
      setProductSearch([]);
      setIsMore(false);
    } else {
      setProductSearch(response.data.results);
      // checkProductAndAdd(response.data.results);
      ProductsDB.addProducts(response.data.results);
      if (response.data.results.length < 10) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
    }
    setIsCacheResponse(false);
    setSkip(10);

    // setSearchLoading(false);
  };

  const searchMoreProduct = async (value: string) => {
    let response;
    try {
      response = await get_product_list(skip, 10, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.info('Cannot find more products with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);
      setProductSearch([...productSearch, ...response.data.results]);
      // checkProductAndAdd(response.data.results);
      ProductsDB.addProducts(response.data.results);

      if (response.data.results.length < 10) {
        setIsMore(false);
      }
    }
  };

  // const checkProductAndAdd = (products: IProductDetails[]) => {
  //   if (productList.length > 0) {
  //     products = products.filter((value) => {
  //       const searchProduct = productSearch.find((val) => val.id == value.id);
  //       if (searchProduct) return false;
  //       return true;
  //     });
  //     console.log('products', products);
  //     if (products.length > 0)
  //       setProductList((prevValue) => {
  //         return [...prevValue, ...products];
  //       });
  //   } else {
  //     setProductList(products);
  //   }
  // };

  const options = productSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {`${d.name} (${d.sku})`}
    </Option>
  ));

  return (
    <>
      {formData ? (
        <Form.Item
          name={formData.formName}
          label={formData.label ? formData.label : 'Product'}
          rules={[
            {
              required: required,
              message: 'Please choose a product!'
            }
          ]}
          initialValue={null}>
          <Select
            dropdownMatchSelectWidth={false}
            showSearch
            placeholder={'Search product'}
            defaultActiveFirstOption={false}
            style={{ width: '100%' }}
            autoClearSearchValue={true}
            showArrow={false}
            filterOption={false}
            disabled={disabled}
            // onClick={(val) => {
            //   console.log(val);
            //   console.log('clicked');
            // }}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            onSelect={async (val: any) => {
              const product = productSearch.find((current: any) => current.id == val);
              const hasDefaultUnit = product?.productUnits?.find((unit: any) => unit.isDefault);
              if (!hasDefaultUnit && product) {
                message.warning('This product does not have a default unit', 5);
              }

              if (onProductChange) await onProductChange(val);
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                {isCacheResponse ? (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {/* <div
                  style={{
                    color: 'green',
                    width: '100%',
                    textAlign: 'center'
                  }}>{`Cache found ${productSearch.length} data`}</div> */}
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchProduct(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Pull More & Sync'}
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {isMore ? (
                      <Button
                        type="text"
                        style={{
                          color: 'blue',
                          width: '100%'
                        }}
                        onClick={() => {
                          searchMoreProduct(searchValue);
                        }}>
                        {/* {'Sync & Search on Server'} */}
                        {'Get More...'}
                      </Button>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center'
                        }}>
                        No more data...
                      </div>
                    )}
                  </div>
                )}
              </>
            )}>
            {options}
          </Select>
        </Form.Item>
      ) : (
        <Select
          dropdownMatchSelectWidth={false}
          showSearch
          placeholder={'Search product'}
          defaultActiveFirstOption={false}
          style={{ width: '100%' }}
          autoClearSearchValue={true}
          // value={''}
          showArrow={false}
          filterOption={false}
          disabled={disabled}
          // onClick={(val) => {
          //   console.log(val);
          //   console.log('clicked');
          // }}
          onSearch={(val) => {
            setSearchValue(val);
          }}
          onSelect={async (val: any) => {
            if (onProductChange) await onProductChange(val);
          }}
          notFoundContent={null}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              {isCacheResponse ? (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {/* <div
                  style={{
                    color: 'green',
                    width: '100%',
                    textAlign: 'center'
                  }}>{`Cache found ${productSearch.length} data`}</div> */}
                  <Button
                    type="text"
                    style={{
                      color: 'blue',
                      width: '100%'
                    }}
                    onClick={() => {
                      searchProduct(searchValue);
                    }}>
                    {/* {'Sync & Search on Server'} */}
                    {'Pull More & Sync'}
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {isMore ? (
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchMoreProduct(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Get More...'}
                    </Button>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center'
                      }}>
                      No more data...
                    </div>
                  )}
                </div>
              )}
            </>
          )}>
          {options}
        </Select>
      )}
    </>
  );
};

export default ProductSearchForStatus;
