import { message } from 'antd';
import moment from 'moment';
import { getUserData } from './auth.utils';
import { find_export_report_days_limit } from '../store/localstorage/preferences';

export const check_date_cap = (startDate: string, endDate: string) => {
  const { global } = getUserData();
  const dateLimit = global.content ? JSON.parse(global.content).reportDateLimit : undefined;
  const dateDiff = moment(endDate).diff(startDate, 'days');
  // console.log('dateDiff', dateDiff);
  if (dateLimit) {
    if (dateDiff > dateLimit) {
      message.info(
        `Total days selected: ${dateDiff}. If range is greater than ${dateLimit}, file is downloaded.`
      );
      return true;
    } else {
      return false;
    }
  } else {
    if (dateDiff > 35) {
      message.info(
        `Date Limit is not set in preferences. Total days selected: ${dateDiff}. If range is greater than 35, file is downloaded.`
      );
      return true;
    } else {
      return false;
    }
  }
};

export const check_date_cap_limit = (startDate: string, endDate: string, limit: number) => {
  const dateDiff = moment(endDate).diff(startDate, 'months');
  // console.log('dateDiff', dateDiff);
  if (dateDiff >= limit) {
    return true;
  } else {
    return false;
  }
};

export const check_export_report_date_cap = (startDate: string, endDate: string) => {
  const exportReportDaysLimit = find_export_report_days_limit();
  const dateDiff = moment(endDate).diff(startDate, 'days');

  if (dateDiff > exportReportDaysLimit) {
    message.error(
      `Selected date range is greater than ${exportReportDaysLimit} days. Please select a smaller date range`
    );
    return false;
  }
  return true;
};
