import { ILocations } from '@/services/locations/types';
import {
  find_dashboard_filter,
  find_locationId_preference
} from '@/store/localstorage/preferences';
import {
  IDefaultAPIConfig,
  IWidgetConfiguration,
  IDateRange,
  IDateRangeType
} from '@/services/dashboard/v3/types';
import { createContext, useContext, useEffect, useState } from 'react';
import { DateSegmentPresets } from '@/pages/sqlsource/report/utils/datePresets';

import { IReportWithFields } from '@/services/report/types';
import {
  fetchLocations,
  fetchReportLists,
  updateDashboardLayout
} from '@/services/dashboard/v3/services.dashboard';
import { defaultDashboardLayout } from '@/pages/dashboard/v3/constant.dashboard';
import CustomErrorModal from '@/components/Common/CustomErrorModal';

const defaultAPIConfig = {
  locationId: find_locationId_preference() || 1,
  page: 1,
  size: -1
};

interface IDashboardContext {
  locationId: number;
  locations: ILocations[];
  selectedDate: IDateRange;
  dateType: IDateRangeType;
  isLoading: boolean;
  reportLists: IReportWithFields[];
  isFormSubmitted: boolean;
  isInitial: boolean;
  defaultAPIConfig: IDefaultAPIConfig;
  dashboardLayout: IWidgetConfiguration[];

  setIsInitial: React.Dispatch<React.SetStateAction<boolean>>;
  setLocationId: React.Dispatch<React.SetStateAction<number>>;
  setIsFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  setDateType: React.Dispatch<React.SetStateAction<IDateRangeType>>;
  setSelectedDate: React.Dispatch<React.SetStateAction<IDateRange>>;
}

const DashboardMainContext = createContext<IDashboardContext | undefined>(undefined);

function DashboardMainProvider({ children }: { children: React.ReactNode }) {
  const userLayout = find_dashboard_filter();
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardLayout, setDashboardLayout] = useState<IWidgetConfiguration[]>(
    userLayout.length > 0 ? userLayout : defaultDashboardLayout
  );
  const [locationId, setLocationId] = useState(-1);
  const [locations, setLocations] = useState<ILocations[]>([]);

  const [dateType, setDateType] = useState('daily' as IDateRangeType);
  const [selectedDate, setSelectedDate] = useState(DateSegmentPresets.Daily);

  const [isInitial, setIsInitial] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [reportLists, setReportLists] = useState<IReportWithFields[]>([]);

  // Fetch Location Initially
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        if (userLayout.length === 0) {
          await updateDashboardLayout(dashboardLayout);
        }

        const response = await fetchLocations();
        setLocationId(response.id);
        setLocations(response.locations);
        setDashboardLayout(dashboardLayout);

        const allReportLists = await fetchReportLists();
        setReportLists(allReportLists);
        setIsLoading(false);
      } catch (error: any) {
        if (error.type === 'Error') {
          CustomErrorModal({
            title: 'Error',
            message: error.message || 'Error saving dashboard layout'
          });
        }
      }
    })();
  }, []);

  return (
    <DashboardMainContext.Provider
      value={{
        locationId,
        locations,
        dateType,
        isLoading,
        selectedDate,
        dashboardLayout,
        defaultAPIConfig,
        isFormSubmitted,
        setIsFormSubmitted,
        setLocationId,
        setSelectedDate,
        setDateType,
        reportLists,
        isInitial,
        setIsInitial
      }}>
      {children}
    </DashboardMainContext.Provider>
  );
}

function useDashboardMain() {
  const context = useContext(DashboardMainContext);
  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
}

export default DashboardMainProvider;
export { useDashboardMain };
