import { useQuery } from '@tanstack/react-query';
import { Button, Table, Menu, TableProps, Form, Select, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContent from '../../../../components/Common/Content/Content';
import DiscussionChatModal from '../../../../components/Common/DiscussionChatModal';
import { get_reasons_list } from '../../../../services/adjustments/queries';
import moment from 'moment';
import CustomButton from '../../../../components/Common/CustomButton/CustomButton';
import { SorterResult } from 'antd/lib/table/interface';
import { convertUTCStringtoLocalString } from '../../../../utils/convertToUTC';
import ActionDropdown from '../../../../components/Common/Dropdownactions';
import UsersDB from '../../../../store/localstorage/UsersDB';
import { get_user_list_ids } from '../../../../services/users/queries';
import TableCell from '../../../../components/Common/CustomizeTable/CustomCell';
import { ConvertObjectToURL } from '../../../../utils/converturl';
import CustomUpdateIcon from '../../../../components/Common/CustomIcons/CustomUpdateIcon';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';

const ReasonsList: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [dataList, setDataList] = useState<any[]>([]);
  const [filter, setFilter] = useState<string>('');

  const { data: reasonList, refetch } = useQuery(['reasons', filter], async () => {
    //console.log('Executing useQuery callback');
    setIsloading(true);
    const response = await get_reasons_list(filter);
    const createdByUsers: any = {};
    for (let index = 0; index < response.data.length; index++) {
      if (response.data[index].createdBy) {
        const createdByuser: any = await UsersDB.getUser(response.data[index].createdBy!);
        if (!createdByuser) {
          if (response.data[index].createdBy! in createdByUsers) {
            createdByUsers[response.data[index].createdBy!] = [
              ...createdByUsers[response.data[index].createdBy!],
              index
            ];
          } else {
            createdByUsers[response.data[index].createdBy!] = [index];
          }
        } else {
          response.data[index].createdByName = createdByuser.name;
        }
      }
    }
    const createdByUserslength = Object.entries(createdByUsers).length;

    if (createdByUserslength > 0) {
      const customerresponse = await get_user_list_ids([...Object.keys(createdByUsers)]);
      for (const key in createdByUsers) {
        const createdByUser = customerresponse?.data?.results.find(
          (currCustomer: any) => currCustomer.id == key
        );
        if (!createdByUser) continue;
        for (let i = 0; i < createdByUsers[key].length; i++) {
          response.data[createdByUsers[key][i]].createdByName = createdByUser.name;
        }
        await UsersDB.addUsers([createdByUser]);
      }
    }
    setDataList(response.data);
    // console.log('Response-->', response);
    setIsloading(false);
    return response;
  });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  //   const deleteReasonMutation = useMutation(delete_offer, {
  //     onSuccess: () => {
  //       message.success('offer deleted successfully');
  //       refetch();
  //       // dispatch(removeLocation(selectedID));
  //     }
  //   });

  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 15,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{index + 1}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 100,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/adjustments/reasons/${record.id}?name=${record.name}`} color="black">
          <TableCell>{record.name}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Created',
      key: 'createdAt',
      width: 100,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/adjustments/reasons/${record.id}?name=${record.name}`} color="black">
          <TableCell>
            {/* {record.createdAt.slice(0, 10)} */}
            {moment(record.createdAt).local().format(DEFAULT_DATE_FORMAT)}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Created By',
      key: 'addedBy',
      width: 70,
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === 'addedBy' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.createdByName}</TableCell>;
      }
    },
    {
      title: 'Updated',
      key: 'updatedAt',
      width: 100,
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      sortOrder: sortedInfo.columnKey === 'updatedAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/adjustments/reasons/${record.id}?name=${record.name}`} color="black">
          <TableCell>
            {/* {record.updatedAt.slice(0, 10)} */}
            {/* {moment(record.updatedAt).local().format(DEFAULT_DATE_FORMAT)} */}
            {convertUTCStringtoLocalString(record.updatedAt, DEFAULT_DATE_FORMAT)}
          </TableCell>
          // </Link>
        );
      }
    },
    //   {
    //     title: 'Actions',
    //     key: 'actions',
    //     fixed: 'right',
    //     render: (record) => {
    //       // const confirmFunction = () => {
    //       //   deleteofferMutation.mutate(record.id);
    //       // };
    //       return (
    //         <div className="flex justify-center items-center">
    //           <Link to={`/adjustments/reasons/${record.id}?name=${record.name}`} color="black">
    //             <Tooltip title="Update" color="blue">
    //               <EyeFilled />
    //             </Tooltip>
    //           </Link>
    //           {/* <DeleteConfirmModal FunctionAfterOk={confirmFunction} /> */}
    //         </div>
    //       );
    //     }
    //   }
    // ];
    {
      title: 'Status',
      key: 'status',
      width: 40,
      render: (record) => {
        return (
          <Tag color={record.isArchived ? 'red' : 'green'}>
            {record.isArchived ? 'Inactive' : 'Active'}
          </Tag>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 25,
      render: (record) => {
        const menu = (
          <Menu
            items={[
              {
                key: '1',
                label: <CustomUpdateIcon link={`/adjustments/reasons/${record.id}`} />
              },
              {
                key: '2',
                label: <DiscussionChatModal slug="reasons" id={record.id} />
              }
            ]}
          />
        );
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Reasons',
      link: '/adjustments/reasons'
    }
  ];
  const { Option } = Select;

  useEffect(() => {
    form.setFieldValue(['status'], '');
  }, []);

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div className="flex gap-2 justify-end">
              <Form form={form} layout={'vertical'} className="flex gap-2 items-end">
                <Form.Item label={'Status'} name={['status']}>
                  <Select
                    placeholder="Select"
                    style={{ borderRadius: '10px' }}
                    allowClear
                    dropdownMatchSelectWidth={false}>
                    <Option value={''} key={''}>
                      All
                    </Option>
                    <Option value={'ACTIVE'} key={'ACTIVE'}>
                      Active
                    </Option>
                    <Option value={'INACTIVE'} key={'INACTIVE'}>
                      Inactive
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    className="secondary-button"
                    // htmlType="submit"
                    style={{
                      borderRadius: '9px',
                      marginTop: '0.45rem'
                    }}
                    onClick={() => {
                      const values = form.getFieldsValue();
                      const string = ConvertObjectToURL(values);
                      //console.log('Before', values);
                      setFilter(string);
                      //console.log('After', filter);
                      refetch();
                    }}>
                    Filter
                  </Button>
                </Form.Item>
                <Form.Item>
                  {' '}
                  <CustomButton
                    onClick={() => navigate(`/adjustments/reasons/new`)}
                    text="Add"
                    backgroundColor="#1890ff"
                    // marginTop="0.45rem"
                    marginLeft="0px"
                    Linkto="/adjustments/reasons/new"
                  />
                </Form.Item>
              </Form>
            </div>
          </>
        }>
        {/* <div className="flex justify-end mt-5"> */}
        {/* <Pagination showSizeChanger onShowSizeChange={onShowSizeChange} size="small" />
          <div className={'flex justify-around gap-1'}>
            <Input
              placeholder={'Search...'}
              size="small"
              allowClear
              onPressEnter={(event: any) => {
                dispatch(setValue(event.target.value));
              }}
            />
            <Button type="primary" onClick={showDrawer} size={'small'}>
              Filters
            </Button>
          </div> */}
        {/* <Link to={'/adjustments/reasons/new'}>
            <Button
              htmlType={'button'}
              size={'small'}
              type={'primary'}
              className={'me-2'}
              onClick={() => navigate('/adjustments/reasons/new')}>
              <PlusCircleFilled />
              <span> Add</span>
            </Button>
          </Link> */}
        {/* </div> */}

        <CustomizeTable
          customScroll={{ x: 1000, y: '80vh' }}
          columns={columns}
          data={dataList ? dataList : []}
          usersLoading={isLoading}
          toSort={handleChange}
          notshowPagination
          tableName="adjustment-reasons-list"
        />
      </AppContent>
    </div>
  );
};

export default ReasonsList;
