import { AxiosInstance, AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { ICreateReason, IReason, IUpdateReason } from '../adjustments/types';
import {
  ICreateChannel,
  ICreateMessage,
  ISendMessageResponse,
  IUpdateChannel,
  IUpdateChannelResponse
} from './types';

export const create_channels_mutation = async (values: ICreateChannel) => {
  const response: AxiosResponse<ICreateChannel, any> = await http.store('channel', values);
  return response;
};
export const update_channels_mutation = async (values: IUpdateChannel) => {
  const response: AxiosResponse<IUpdateChannelResponse, any> = await http.update(
    'channel/' + values.id,
    values
  );
  return response;
};
export const send_message_mutation = async (values: ICreateMessage) => {
  const response: AxiosResponse<ISendMessageResponse, any> = await http.store(
    'channel/' + values.channelId + '/message',
    values
  );
  return response;
};

export const create_channel_reason_mutation = async (values: ICreateReason) => {
  const response: AxiosResponse<IReason, any> = await http.store('channel/reasons', values);
  return response;
};

export const update_channel_reason_mutation = async (values: IUpdateReason) => {
  const response: AxiosResponse<IReason, any> = await http.update(
    'channel/reasons/' + values.id,
    values
  );
  return response;
};
