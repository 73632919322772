import { useQuery } from '@tanstack/react-query';
import { PageHeader, Select, Typography } from 'antd';
import React, { useState } from 'react';
import { get_invoices_list } from '../services/settings/queries';
import { ICreateInvoiceResponse } from '../services/settings/types';
const { Title, Text } = Typography;
const { Option } = Select;
export default function LayoutToUI() {
  const [invoiceLayouts, setinvoiceLayouts] = useState<ICreateInvoiceResponse[]>([]);
  const { isLoading: invoiceLoading, refetch } = useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    setinvoiceLayouts(response.data);
    return response;
  });
  const data = {
    id: 3,
    name: 'Layout with iamge and header text',
    content: {
      layoutName: 'Layout with iamge and header text',
      design: 'elegant',
      invoiceLogo: 41,
      isActive: true,
      subHeadingLine1: '1',
      subHeadingLine2: '2',
      subHeadingLine3: '3',
      subHeadingLine4: '4',
      subHeadingLine5: '5',
      invoiceHeading: 'Invoice Heading',
      headingSuffixNotPaid: 'Heading suffix for not paid',
      headingSuffixPaid: 'Heading suffix for paid',
      quotationHeading: 'Quotation Heading',
      invoiceNumberLabel: 'Invoice no. label:',
      quotationNumberLabel: 'Quotation no. label:',
      dateLabel: 'Date Label:',
      dueDateLabel: 'Due date Label:',
      showDueDate: true,
      salesPersonLabel: 'Sales Person label:',
      showBusinessName: true,
      showLocationName: true,
      showSalesPerson: true,
      customerLabel: 'Customer Label:',
      showClientID: true,
      clientIDLabel: 'Client ID Label:',
      clientTaxNumberLabel: 'Client tax number label:',
      showRewardPoint: true,
      customField1: 'z',
      customField2: 'c',
      customField3: 'v',
      customField4: 'b',
      landmark: true,
      city: true,
      state: true,
      country: true,
      zipCode: true,
      website: true,
      mobileNumber: true,
      alternateNumber: true,
      email: true,
      tax1Details: true,
      tax2Details: true,
      productLabel: 'Product label',
      quantityLabel: 'Quantity label',
      unitPriceLabel: 'Unit Price Label',
      subTotalLabel: 'Subtotal Label',
      categoryCodeLabel: 'Category or HSN code Label',
      totalQuantityLabel: 'Total quantity label',
      showBrand: true,
      showSKU: true,
      showCategoryCodeOrHSNCode: true,
      showSaleDescription: true,
      showProductExpiry: true,
      showLotNumber: true,
      showProductImage: true,
      showWarrentyName: true,
      showWarrentyExpiryDate: true,
      showWarrentyDescription: true,
      discountLabel: 'Discount Label',
      taxLabel: 'Tax Label',
      totalLabel: 'Total Label',
      showPaymentInformation: true,
      showTotalBalanceDue: true,
      changeReturnLabel: 'Change return label:',
      locationAddressCustomField1: true,
      locationAddressCustomField2: true,
      locationAddressCustomField3: true,
      locationAddressCustomField4: true,
      productDetailsCustomField1: true,
      productDetailsCustomField2: true,
      productDetailsCustomField3: true,
      productDetailsCustomField4: true,
      paymentSubTotalLabel: 'Subtotal label 2',
      totalWordsLabel: 'Total in words label',
      roundOffLabel: 'Round off label',
      amountPaidLabel: 'Amount Paid Label:',
      totalDueLabelCurrentSale: 'Total Due Label (Current sale):',
      totalDueLabelAllSales: 'Total due label (All sales):'
    },
    createdAt: '2022-09-14T12:38:43.433Z',
    updatedAt: '2022-09-14T12:38:43.433Z',
    deletedAt: '2022-09-14T12:38:43.433Z'
  };
  //   console.log(invoiceLayouts?.data);
  return (
    <div style={{ backgroundColor: 'red' }} className="grid gap-1 p-5">
      <Title level={3} style={{ textAlign: 'center' }}>
        Header
      </Title>
      <Text>Layout:</Text>
      <Select>
        {invoiceLayouts &&
          invoiceLayouts.map((val: any) => (
            <Option key={val.id} value={val.id}>
              {val.name}
            </Option>
          ))}
      </Select>
      <div className="grid grid-cols-2 gap-5 mb-5"></div>
    </div>
  );
}
