import { IUser } from '@/services/auth/types';
import CustomSearch, { SelectProps } from '..';
import { get_user_list_only } from '@/services/users/queries';
import UsersDB from '@/store/localstorage/UsersDB';

type MultipleProps =
  | {
      defaultValue?: number;
      isMultiple?: false;
      setSelected?: (selected?: IUser) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      defaultValue?: number[];
      isMultiple: true;
      setSelected?: (selected?: IUser[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps & MultipleProps;

function UserSearchV2(props: Props) {
  return (
    <CustomSearch
      formFor="user"
      addCallback={UsersDB.addUsers}
      dbSearchById={UsersDB.getUser}
      serverCallback={get_user_list_only}
      dbSearchCallback={UsersDB.searchUser}
      {...props}
    />
  );
}

export default UserSearchV2;
