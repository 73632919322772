import { useMutation } from '@tanstack/react-query';
import { Input, message, Menu, Form, TableProps, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LocationFilterDrawer from '@/components/@Locations/FilterDrawer';
import AppContent from '@/components/Common/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import DeleteConfirmModal from '@/components/Common/Deleteconfirm/DeleteConfirmModal';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import TableFilter from '@/components/FliterTable';
import useFilterDrawers from '@/hooks/useFilterDrawers';
import { checkAccess } from '@/routes/acl';
import { delete_location } from '@/services/locations/mutations';
import { get_location_list_for_filter } from '@/services/locations/queries';
// import LocalStore from '@/store/localstorage';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { AppDispatch } from '@/store/store';
import { ConvertObjectToURL } from '@/utils/converturl';
import { SorterResult } from 'antd/lib/table/interface';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { ILocationsData } from '@/services/locations/types';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';

interface DataType {
  id: number;
  name: string;
  address: string;
  description: string;
  city: string;
  state: string;
  zip: string;
  code: string;
  country: string;
  phone: string;
  email: string;
  isActive: boolean;
}

const List: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  // const allLocations = useSelector((state: RootState) => state.locations);
  const [isloading, setIsloading] = useState<boolean>(true);
  const { visible, showDrawer, onClose } = useFilterDrawers();
  let selectedID = 0;
  const [allLoctionsList, setAllLocationsList] = useState<ILocationsData>({
    total: 0,
    results: []
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [form] = Form.useForm();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };
  const getInfo = async (filter = '') => {
    setIsloading(true);
    // const response = await get_location_list(skip, count, value, filter);
    const response = await get_location_list_for_filter(filter);
    setAllLocationsList(response.data);
    setIsloading(false);
    LocationsDB.addLocations(response.data.results);
  };
  const deletelocationsMutation = useMutation(delete_location, {
    onSuccess: (data: any) => {
      message.success('locations deleted successfully');
      setIsloading(false);
      LocationsDB.deleteLocation(selectedID);
      const values = form.getFieldsValue();
      values.endDate = convertLocalToUTCString(values.endDate);
      values.startDate = convertLocalToUTCString(values.startDate);
      delete values.dateCustom;
      delete values.startDateNepali;
      delete values.endDateNepali;
      onSubmitFilter(ConvertObjectToURL(values));
      // getInfo();
      // dispatch(removeLocation(selectedID));
    },
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.response.data.message}`, 5);
    }
  });

  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      width: 15,
      render: (record) => {
        return <TableCell>{record.id}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 50,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.id}`} color="black">
          <TableCell>{record.name}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Description',
      key: 'description',
      width: 50,
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortOrder: sortedInfo.columnKey === 'description' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.id}`} color="black">
          <TableCell>{record.description}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Address',
      key: 'address',
      width: 50,
      sorter: (a, b) => a.address.localeCompare(b.address),
      sortOrder: sortedInfo.columnKey === 'address' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.id}`} color="black">
          <TableCell>{record.address}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'City',
      key: 'city',
      width: 40,
      sorter: (a, b) => a.city.localeCompare(b.city),
      sortOrder: sortedInfo.columnKey === 'city' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.id}`} color="black">
          <TableCell>{record.city} </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'State',
      key: 'state',
      width: 30,
      sorter: (a, b) => a.state.localeCompare(b.state),
      sortOrder: sortedInfo.columnKey === 'state' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.id}`} color="black">
          <TableCell>{record.state}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'ZIP',
      key: 'zip',
      width: 30,
      sorter: (a, b) => a.zip.localeCompare(b.zip),
      sortOrder: sortedInfo.columnKey === 'zip' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.id}`} color="black">
          <TableCell>{record.zip}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Code',
      key: 'code',
      width: 30,
      sorter: (a, b) => a.code.localeCompare(b.code),
      sortOrder: sortedInfo.columnKey === 'code' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.code}</TableCell>;
      }
    },
    {
      title: 'Type',
      key: 'type',
      width: 30,
      sorter: (a, b) => a.zip.localeCompare(b.zip),
      sortOrder: sortedInfo.columnKey === 'type' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.id}`} color="black">
          <TableCell>{record.type}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Country',
      key: 'country',
      width: 30,
      sorter: (a, b) => a.country.localeCompare(b.country),
      sortOrder: sortedInfo.columnKey === 'country' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.id}`} color="black">
          <TableCell>{record.country}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Phone',
      key: 'phone',
      width: 30,
      sorter: (a, b) => a.phone.localeCompare(b.phone),
      sortOrder: sortedInfo.columnKey === 'phone' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.id}`} color="black">
          <TableCell>{record.phone}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Email',
      key: 'email',
      width: 40,
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.id}`} color="black">
          <TableCell>{record.email}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Status',
      key: 'status',
      width: 30,
      dataIndex: 'isActive',
      render: (isActive) => (
        <Tag color={isActive ? 'green' : 'red'}>{isActive ? 'Active' : 'Inactive'}</Tag>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 20,
      fixed: 'right',
      render: (record) => {
        const confirmFunction = () => {
          selectedID = record.id;
          deletelocationsMutation.mutate(record.id);
        };
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];
        if (checkAccess('DELETE_LOCATION')) {
          menuItems.push({
            key: '2',
            label: <DeleteConfirmModal FunctionAfterOk={confirmFunction} />
          });
        }
        if (checkAccess('UPDATE_LOCATION')) {
          menuItems.push({
            key: '1',
            label: <CustomUpdateIcon link={`/locations/${record.id}`} />
          });
        }
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '3',
            label: <DiscussionChatModal slug="location" rreference="other" id={record.id} />
          });
        }
        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      //Do something here
    }
  };

  const onShowSizeChange = (current: number, pageSize: number) => {
    console.log(current, pageSize);
  };

  const breadcrumbItems = [
    {
      label: 'Locations',
      link: '/locations'
    }
  ];

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }

    const url = ConvertObjectToURL(values);
    const productResponse = await get_location_list_for_filter(url);
    setAllLocationsList(productResponse.data);
    setIsloading(false);
  };

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'ID',
          dataIndex: 'id',
          width: 100
        },
        {
          title: 'Name',
          width: 250,
          dataIndex: 'name'
        },
        {
          title: 'Description',
          width: 200,
          dataIndex: 'description'
        },
        {
          title: 'Address',
          width: 200,
          dataIndex: 'address'
        },
        {
          title: 'City',
          width: 200,
          dataIndex: 'city'
        },
        {
          title: 'State',
          width: 150,
          dataIndex: 'state'
        },
        {
          title: 'ZIP',
          width: 150,
          dataIndex: 'zip'
        },
        {
          title: 'Code',
          width: 150,
          dataIndex: 'code'
        },
        {
          title: 'Country',
          width: 150,
          dataIndex: 'country'
        },

        {
          title: 'Payment Status',
          width: 150,
          dataIndex: 'paymentStatus'
        },
        {
          title: 'phone',
          width: 200,
          dataIndex: 'phone'
        },
        {
          title: 'Email',
          width: 150,
          dataIndex: 'email'
        }
      ];
      if (allLoctionsList.results.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = allLoctionsList.results.map((item: any, index: number) => {
        return {
          ...item,
          SN: index + 1
        };
      });
      exportExcel(columns, dataUpdated, 'Location List');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <TableFilter
                defaultValues={{
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  skip: 0,
                  count: 100,
                  value: ''
                }}
                form={form}
                initial={true}
                onSubmit={onSubmitFilter}
                // outsidestyle={'flex flex-wrap items-end gap-2'}
                styleforbuttons={'flex items-center justify-end'}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                buttons={
                  <>
                    {checkAccess('CREATE_LOCATION') && (
                      <CustomButton
                        onClick={() => navigate(`/locations/new`)}
                        text="Add"
                        backgroundColor="#1890ff"
                        // marginTop="1.7rem"
                        Linkto="/locations/new"
                      />
                    )}
                    <CustomButton text="Export" backgroundColor="#1890ff" onClick={handleExport} />
                  </>
                }>
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          columns={columns}
          data={allLoctionsList.results}
          usersLoading={isloading}
          notshowPagination={true}
          // toshowcustomPagintion={true}
          // onPagination={onPagination}
          customScroll={{ x: 1700, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: allLoctionsList.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
          tableName={'locations-list'}
        />
        <LocationFilterDrawer visible={visible} onClose={onClose} />
      </AppContent>
    </div>
  );
};

export default List;
