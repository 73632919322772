import moment from 'moment';

export const optionalStringSorter = (a?: string, b?: string, defaultValue = '') => {
  let valueA = defaultValue;
  if (a) {
    valueA = a;
  }
  let valueB = defaultValue;
  if (b) {
    valueB = b;
  }

  return valueA.localeCompare(valueB);
};

export const optionalDateSorter = (
  a?: moment.Moment | string,
  b?: moment.Moment | string,
  defaultValue = ''
) => {
  let valueA = moment(defaultValue);
  if (typeof a === 'string') {
    valueA = moment(a);
  } else if (a) {
    valueA = a;
  }
  let valueB = moment(defaultValue);
  if (typeof b === 'string') {
    valueB = moment(b);
  } else if (b) {
    valueB = b;
  }
  return valueA.diff(valueB);
};

export const optionalNumberSorter = (a?: number, b?: number, defaultValue = 0) => {
  let valueA = defaultValue;
  if (a) {
    valueA = a;
  }
  let valueB = defaultValue;
  if (b) {
    valueB = b;
  }

  return valueA - valueB;
};

export const optionalBooleanSorter = (a: boolean, b: boolean) => {
  const valueA = a ? 1 : 0;
  const valueB = b ? 1 : 0;

  return valueA - valueB;
};
