import moment from 'moment';

import { get_journal_lines_list } from '@/services/accounts/queries';
import {
  IAccountHistoryResponse,
  IAccountTypeResponseData,
  ICustomViewAccountTableData
} from '@/services/accounts/types';
import getGroupedData from '@/utils/getGroupedData';
import { optionalDateSorter } from '@/utils/sorter.utils';

interface SortedDataProps {
  data: ICustomViewAccountTableData[];
  nextJournalLines: ICustomViewAccountTableData[];
  pageRunBal: { [key: number]: number };
  currentAccount?: IAccountTypeResponseData;
  pageNo: number;
  openingBalance: number;
  history: IAccountHistoryResponse[];
}

export function getSortedTableData({
  data,
  pageRunBal,
  currentAccount,
  pageNo,
  openingBalance,
  history,
  nextJournalLines
}: SortedDataProps) {
  let runningBalanceCurrAcc = 0;
  const groupedData = getGroupedData(data, 'journalId');

  // Sort grouped data by journalDate
  const sortedKeys = Object.keys(groupedData).sort((a, b) => {
    const journalDateA = groupedData[parseInt(a)][0].journalDate;
    const journalDateB = groupedData[parseInt(b)][0].journalDate;
    return optionalDateSorter(journalDateA, journalDateB);
  });

  // Get sorted grouped data
  const sortedGroupedData = sortedKeys.map((key) => [
    parseInt(key),
    groupedData[parseInt(key)]
  ]) as [number, ICustomViewAccountTableData[]][];

  // Update journalTableData with sorted grouped data
  if (!currentAccount) {
    const updatedGroups = sortedGroupedData.flatMap((journal) => journal[1]);
    return { updatedGroups, runningBalanceCurrAcc };
  }

  const currentAccountId = currentAccount.id;

  const updatedGroups = sortedGroupedData.flatMap((journalGroups, groupIndex) => {
    // Get Grouped Data excluding the journalId [journalId, groupedData]
    const group = journalGroups[1];

    let selectedAccType = '';
    const selectedAccountsInGroup = group.filter((val) => val.accountId === currentAccountId);
    const currentAccountInGroup = group.find((g) => g.accountId === currentAccountId);
    const currAccountTrasId = currentAccountInGroup?.journalLineId;

    // If multiple accounts in group, modify debit/credit based on selected account
    if (selectedAccountsInGroup.length > 1) {
      const { totalAccDebit, totalAccCredit } = selectedAccountsInGroup.reduce(
        (totals, acc) => ({
          totalAccDebit: totals.totalAccDebit + acc.debit,
          totalAccCredit: totals.totalAccCredit + acc.credit
        }),
        { totalAccDebit: 0, totalAccCredit: 0 }
      );

      if (currentAccountInGroup) {
        if (totalAccDebit > totalAccCredit) {
          currentAccountInGroup.debit = totalAccDebit - totalAccCredit;
          currentAccountInGroup.credit = 0;
        } else {
          currentAccountInGroup.debit = 0;
          currentAccountInGroup.credit = totalAccCredit - totalAccDebit;
        }
      }
    }

    // Assign selected types based on current account
    if (currentAccountInGroup) {
      if (currentAccountInGroup.debit !== 0) {
        selectedAccType = 'debit';
      } else if (currentAccountInGroup.credit !== 0) {
        selectedAccType = 'credit';
      }
    }

    let updatedGroup = processGroup(group, selectedAccType === 'debit', currentAccountInGroup);

    const isInitialPage = pageNo === 1 && groupIndex === 0;

    if (isInitialPage) {
      updatedGroup.forEach((g) => {
        const totalDebitCredit = g.debit - g.credit;
        const runningBalance = !runningBalanceCurrAcc
          ? openingBalance + totalDebitCredit
          : runningBalanceCurrAcc + totalDebitCredit;

        g.runningBalance = runningBalance;
        runningBalanceCurrAcc = runningBalance;
      });

      const lastOfGroup = updatedGroup[updatedGroup.length - 1];
      const transDate = moment(lastOfGroup.journalDate).format('YYYY-MM-DD');
      const selectedAccountsHistory = history.find(
        (val) =>
          val.accountId === currentAccountId &&
          val.date === transDate &&
          val.historyType === 'daily'
      );

      const hasAccountJournal = nextJournalLines.find(
        (val) =>
          moment(val.journalDate).format('YYYY-MM-DD') === transDate &&
          val.accountId === currentAccountId
      );

      if (selectedAccountsHistory && !hasAccountJournal && currAccountTrasId) {
        const transactionId = getTransactionIdForEndDay(data, transDate, currentAccount.id);
        if (transactionId === currAccountTrasId) {
          runningBalanceCurrAcc = selectedAccountsHistory.balance;
          updatedGroup[updatedGroup.length - 1].runningBalance = runningBalanceCurrAcc;
        }
      }

      pageRunBal[pageNo] = runningBalanceCurrAcc;
    }

    if (!isInitialPage) {
      const hasPageRunningBalance = pageRunBal[pageNo] !== undefined && pageRunBal[pageNo] !== null;

      if (hasPageRunningBalance) {
        updatedGroup = updatedGroup.map((g) => {
          if (hasPageRunningBalance) {
            runningBalanceCurrAcc = pageRunBal[pageNo] + g.debit - g.credit;
          } else {
            let found = false;
            for (let ind = pageNo - 1; ind > 0; ind--) {
              if (pageRunBal[ind] !== undefined && pageRunBal[ind] !== null) {
                runningBalanceCurrAcc = pageRunBal[ind] + g.debit - g.credit;
                found = true;
                break;
              }
            }

            if (!found) {
              const accBalance = history.find((val) => val.accountId === currentAccountId)?.balance;
              runningBalanceCurrAcc = accBalance ? accBalance + g.debit - g.credit : 0;
            }
          }
          pageRunBal[pageNo] = runningBalanceCurrAcc;
          return { ...g, runningBalance: runningBalanceCurrAcc };
        });
      } else {
        updatedGroup = updatedGroup.map((g) => {
          let found = false;
          for (let ind = pageNo - 1; ind > 0; ind--) {
            if (pageRunBal[ind] !== undefined && pageRunBal[ind] !== null) {
              runningBalanceCurrAcc = pageRunBal[ind] + g.debit - g.credit;
              found = true;
              break;
            }
          }
          if (!found) {
            const accBalance = history.find((val) => val.accountId === g.accountId)?.balance;
            runningBalanceCurrAcc = accBalance ? accBalance + g.debit - g.credit : 0;
          }
          pageRunBal[pageNo] = runningBalanceCurrAcc;
          return { ...g, runningBalance: runningBalanceCurrAcc };
        });
      }

      if (groupIndex !== sortedGroupedData.length - 1) {
        const lastOfGroup = updatedGroup[updatedGroup.length - 1];
        if (!lastOfGroup) return updatedGroup;

        const transDate = moment(lastOfGroup.journalDate).format('YYYY-MM-DD');
        const selectedAccountsHistory = history.find(
          (val) => val.accountId === currentAccountId && val.date === transDate
        );

        const hasNextSameDate = nextJournalLines.find(
          (val) =>
            moment(val.journalDate).format('YYYY-MM-DD') === transDate &&
            val.accountId === currentAccountId
        );

        if (selectedAccountsHistory && !hasNextSameDate && currAccountTrasId) {
          const transactionId = getTransactionIdForEndDay(data, transDate, currentAccountId);

          if (transactionId === currAccountTrasId) {
            runningBalanceCurrAcc = selectedAccountsHistory.balance;
            updatedGroup[updatedGroup.length - 1].runningBalance = runningBalanceCurrAcc;
          }
        }
      }

      pageRunBal[pageNo] = runningBalanceCurrAcc;
    }

    return updatedGroup;
  });
  return { updatedGroups, runningBalanceCurrAcc };
}

function processGroup(
  group: ICustomViewAccountTableData[],
  isDebit: boolean,
  selectedAccount: ICustomViewAccountTableData | undefined
) {
  const reverseSelectedType = isDebit ? 'credit' : 'debit';
  const accountsInDebit = group.filter((val) => val.debit !== 0);
  const accountsInCredit = group.filter((val) => val.credit !== 0);

  const isMultipleCreditDebit = accountsInDebit.length > 1 && accountsInCredit.length > 1;
  const isMultiple = isDebit ? accountsInDebit.length > 1 : accountsInCredit.length > 1;

  if (!isMultipleCreditDebit && !isMultiple) {
    return group
      .filter((val) => val[reverseSelectedType] !== 0)
      .map((g) => ({ ...g, debit: g.credit, credit: g.debit }));
  }

  // Get first index of group based on selectedType
  let firstIndex = group.findIndex((val) => {
    const value = isDebit ? val.credit : val.debit;
    return value > 0;
  });

  if (firstIndex === -1) {
    firstIndex = group.findIndex((val) => {
      const value = isDebit ? val.credit : val.debit;
      return value < 0;
    });
  }

  // Update the credit if selectedAccount is debit and vice versa
  if (isDebit) {
    group[firstIndex].credit = selectedAccount?.debit || 0;
  } else {
    if (firstIndex > -1) group[firstIndex].debit = selectedAccount?.credit || 0;
  }

  if (isMultipleCreditDebit) {
    const hasValue = group.find((val) => val[reverseSelectedType] !== 0);
    if (hasValue) {
      return [hasValue].map((g) => {
        return { ...g, debit: g.credit, credit: g.debit, accountName: `${g.accountName}(*)` };
      });
    }
  }

  return group
    .filter((val) => val[reverseSelectedType] !== 0)
    .map((g) => ({ ...g, debit: g.credit, credit: g.debit }));
}

export async function getNextJournalAccount(filter: string) {
  const urlParamsNext = new URLSearchParams(filter);
  const count = urlParamsNext.get('count');
  if (count) urlParamsNext.set('skip', count);
  urlParamsNext.set('count', '20');

  const responseNext = await get_journal_lines_list(urlParamsNext.toString());
  return responseNext.results;
}

const getTransactionIdForEndDay = (
  tableData: ICustomViewAccountTableData[],
  transDate: string,
  accountId: number
) => {
  const targetDate = new Date(transDate);

  const validData = tableData.filter(
    (val) =>
      moment(val.journalDate).format('YYYY-MM-DD') === transDate && val.accountId === accountId
  );

  const maxDateTrans = validData.reduce((max, item) => {
    if (item.journalDate && max.journalDate) {
      const currentDate = new Date(item.journalDate);
      const maxDate = new Date(max.journalDate);

      if (currentDate >= targetDate && currentDate > maxDate) {
        return item;
      } else if (currentDate.getTime() === maxDate.getTime()) {
        const currentCreatedDate = new Date(item.createdAt);
        const maxCreatedDate = new Date(max.createdAt);
        return currentCreatedDate > maxCreatedDate ? item : max;
      } else {
        return max;
      }
    } else {
      return max;
    }
  }, validData[0]);

  return maxDateTrans.journalLineId;
};
