import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  ICreateVehicle,
  IEditVehicle,
  IVehicleData,
  IVehicleFuelCreate,
  IVehicleMaintenanceCreate,
  IVehicleUsageCreate,
  IVehicleUsageEdit
} from './types';

export const create_vehicles_mutation = async (values: ICreateVehicle) => {
  const response: AxiosResponse<IVehicleData, any> = await http.store('vehicles', values);
  return response;
};
export const update_vehicles_mutation = async (values: IEditVehicle) => {
  const response: AxiosResponse<IVehicleData, any> = await http.update(
    'vehicles/' + values.id,
    values
  );
  return response;
};

export const delete_vehicle = async (id: number | string) => {
  const response: AxiosResponse<IVehicleData, any> = await http.remove('vehicles/' + id);
  return response;
};

export const create_vehicles_usage_mutation = async (values: IVehicleUsageCreate) => {
  const response: AxiosResponse<IVehicleUsageCreate, any> = await http.store(
    'vehicles-usage',
    values
  );
  return response;
};

export const update_vehicles_usage_mutation = async (values: IVehicleUsageEdit) => {
  const response: AxiosResponse<IVehicleUsageEdit, any> = await http.update(
    'vehicles-usage/' + values.vehicleId,
    values
  );
  return response;
};

export const create_vehicles_maintenance_mutation = async (values: IVehicleMaintenanceCreate) => {
  const response: AxiosResponse<IVehicleMaintenanceCreate, any> = await http.store(
    'vehicles-maintenance',
    values
  );
  return response;
};

export const create_vehicles_fuel_mutation = async (values: IVehicleFuelCreate) => {
  const response: AxiosResponse<IVehicleFuelCreate, any> = await http.store(
    'vehicles-fuel',
    values
  );
  return response;
};
