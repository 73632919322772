import { get_location_list } from './queries';
import LocationsDB from '@/store/localstorage/LocationsDB';

export async function getLocationDetails(locationId: number) {
  let locationDetails = await LocationsDB.getLocation(locationId);
  if (!locationDetails) {
    const allLocations = await get_location_list();
    await LocationsDB.addLocations(allLocations.data.results);
    locationDetails = await LocationsDB.getLocation(locationId);
  }

  return locationDetails;
}
