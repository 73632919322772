import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IUserType, IUserTypeCreateRequest, IUserTypeUpdateRequest } from './types';

export const create_user_type_mutation = async (values: IUserTypeCreateRequest) => {
  const response: AxiosResponse<IUserType, any> = await http.store('users/user-types/', values);
  return response;
};

export const update_user_type_mutation = async (values: IUserTypeUpdateRequest) => {
  const response: AxiosResponse<IUserType, any> = await http.update(
    'users/user-types/' + values.id,
    values
  );
  return response;
};
