import getErrorMessage from '@/utils/getError';
import { Form, Input, message, Select } from 'antd';
import { useState } from 'react';
import { get_account_receive_payable_v2 } from '@/services/accounts/queries';
import { ColumnsType } from 'antd/lib/table';

import { Link } from 'react-router-dom';
import handlePagination from '@/utils/handlePagination';
import { ConvertObjectToURL } from '@/utils/converturl';
import AppContent from '@/components/Common/Content/Content';
import AccountFilterTable from '@/components/AccountFilterTable';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { IAccountReceivePayableV2 } from '@/services/accounts/types';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { AccountsTypeSearch } from '@/components/Common/AccountsTypeSearch';
import { AccountType } from '@/services/accounts/enums';
import AccountSearchV2 from '@/components/Common/CustomSearch/Accounts/AccountSearchV2';

function AccountReceivePayableV2() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState({ receivable: 0, payable: 0 });
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState({
    total: 0,
    results: [] as IAccountReceivePayableV2[]
  });

  async function handleFormSubmit(filter: string) {
    const updatedFilter = modifyFilter(filter);
    await getInfo(updatedFilter);
    setPagination({ page: 1, size: 100 });
  }

  function modifyFilter(filter: string) {
    const params = new URLSearchParams(filter);
    const accountIds = params.get('accountIds');
    params.delete('accountIds');

    if (accountIds) {
      accountIds.split(',').forEach((id) => params.append('ids[]', id));
    }

    return params.toString();
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const response = await get_account_receive_payable_v2(filter);
      const total = response.results.reduce(
        (acc, curr) => {
          const isPositive = curr.balance >= 0;
          acc[isPositive ? 'receivable' : 'payable'] += Math.abs(curr.balance);
          return acc;
        },
        { receivable: 0, payable: 0 }
      );

      setTotal(total);
      setData(response);
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  const columns: ColumnsType<IAccountReceivePayableV2> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 2,
      render: (_, __, index) => index + 1
    },
    {
      title: 'Account Name',
      dataIndex: 'name',
      width: 15,
      key: 'name',
      render: (name, record) => (
        <Link to={`/accounts/detailed-ledger/?accountId=${record.id}`}>{name}</Link>
      )
    },
    {
      title: 'Receivable',
      width: 6,
      dataIndex: 'balance',
      key: 'receivable',
      render: (balance) => {
        return nepaliNumberFormatter(balance >= 0 ? balance : 0);
      }
    },
    {
      title: 'Payable',
      dataIndex: 'balance',
      width: 6,
      key: 'payable',
      render: (payable) => {
        return nepaliNumberFormatter(payable < 0 ? Math.abs(payable) : 0);
      }
    }
  ];

  const onPagination = async (page = 1, size = 10, isSize = false) => {
    const currentFormValues = form.getFieldsValue();

    const formValues = {
      balanceType: currentFormValues.balanceType,
      dateSort: currentFormValues.dateSort,
      count: currentFormValues.count,
      skip: currentFormValues.skip
    };

    const { values, ...pagination } = handlePagination({ formValues, page, size, isSize });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    const filter = modifyFilter(url);
    await getInfo(filter);
  };

  return (
    <AppContent
      withfilter={false}
      breadcrumbItems={[
        { label: 'Accounts', link: '/accounts' },
        { label: 'Receivable/Payable', link: '/accounts/receive-payable' }
      ]}
      button={
        <div>
          <AccountFilterTable
            initial
            form={form}
            hideDate
            dateCustom={false}
            defaultValues={{
              skip: 0,
              count: 100,
              value: '',
              sort: 'asc',
              status: 'ACTIVE'
            }}
            showFilterInitial={false}
            onSearch={handleFormSubmit}>
            <AccountSearchV2
              isMultiple
              label="Account"
              name={'accountIds'}
              type={AccountType.USER}
              hasParentFormItem={false}
            />
            {/* <AccountsTypeSearch
              formMain={form}
              isAll={false}
              isMultiple
              type={AccountType.USER}
              formData={{ formName: 'accountIds', label: 'Account' }}
            /> */}
            <Form.Item label="Sort By" name={'sort'}>
              <Select placeholder="Select Status">
                <Select.Option value="asc">ASC</Select.Option>
                <Select.Option value="desc">DESC</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Status" name={'status'}>
              <Select placeholder="Select Status">
                <Select.Option value="ALL">ALL</Select.Option>
                <Select.Option value="ACTIVE">ACTIVE</Select.Option>
                <Select.Option value="ARCHIVED">ARCHIVED</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Value" name={'value'}>
              <Input />
            </Form.Item>
          </AccountFilterTable>
        </div>
      }>
      <CustomizeTable
        data={data.results}
        columns={columns}
        notshowPagination
        customScroll={{ x: 800, y: '75vh' }}
        usersLoading={isLoading}
        tableName="account-receive-payable"
        paginationDatas={{
          page: pagination.page,
          size: pagination.size,
          total: data.total,
          onPagination,
          scrollToTop: true
        }}
        tableSummaryForFilter={[
          { index: 1, render: 'Total' },
          {
            index: 2,
            render: <div className="text-left">{nepaliNumberFormatter(total.receivable)}</div>
          },
          {
            index: 3,
            render: <div className="text-left">{nepaliNumberFormatter(total.payable)}</div>
          }
        ]}
      />
    </AppContent>
  );
}

export default AccountReceivePayableV2;
