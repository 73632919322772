import { MenuOutlined } from '@ant-design/icons';
import { MenuItemsType, menuItemsMisc } from '../../constants/menuItems/menuItems';
import { MenuTypes } from './enums';
import { IMenuList } from './types';
import { checkAccess } from '../../routes/acl';
import { IClickValueTypes } from '../source/types';
import { Link } from 'react-router-dom';
import history from '../../utils/history.utils';

export const searchSelected = (
  itemToSearch: IMenuList[],
  searchIndex: string
): IMenuList | undefined => {
  let selected = itemToSearch.find((val) => val.key === searchIndex) ?? undefined;

  if (!selected) {
    for (const item of itemToSearch) {
      if (item.children) {
        selected = searchSelected(item.children, searchIndex);
        if (selected) break;
      }
    }
  }

  return selected;
};

export const reArrangeArray = (
  items: IMenuList[],
  sourceDroppableId: string,
  childItem: IMenuList[]
) => {
  const newItem = items.map((item) => {
    if (item.key === sourceDroppableId) {
      return { ...item, children: childItem };
    } else if (item.children) {
      const newChildItem = reArrangeArray(item.children, sourceDroppableId, childItem);
      item.children = newChildItem;
      return { ...item };
    } else {
      return { ...item, children: [] };
    }
  });
  return newItem;
};

export const reorderList = (list: IMenuList[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const sortForDragBox = (
  parentIds: Set<number>,
  data: IMenuList[],
  responseData: IMenuList[]
) => {
  for (let i = 0; i < data.length; i++) {
    if (parentIds.has(data[i].id)) {
      const filteredData = responseData.filter((value) => value.parentId === data[i].id);
      if (filteredData.length > 0) {
        data[i].children = filteredData.sort((a, b) => a.position - b.position);
      }
    }
    if (data[i].children !== undefined) {
      const childIndex = sortForDragBox(parentIds, data[i].children!, responseData);
      if (childIndex.length > 0) {
        data[i].children = childIndex.filter((value) => value.parentId === data[i].id);
      }
    } else {
      data[i].children = [];
    }
  }
  return data.filter((val) => !val.parentId).sort((a, b) => a.position - b.position);
};

export const filterDragBox = (filterItem: IMenuList[], filterKey: string) => {
  let filteredItem: IMenuList[] = [];
  if (filterItem.find((val) => val.key === filterKey)) {
    filteredItem = filterItem.filter((val) => val.key !== filterKey);
  } else {
    filteredItem = filterItem.map((val) => {
      if (val.children) {
        const filteredChild = filterDragBox(val.children, filterKey);
        return { ...val, children: filteredChild };
      } else {
        return { ...val };
      }
    });
  }
  return filteredItem;
};

export const getItemList = (items: IMenuList) => {
  let itemsArr = [{ ...items, children: [] }];
  if (items.children) {
    const childItems = items.children.flatMap((val) => {
      return getItemList(val);
    });
    itemsArr = [...itemsArr, ...childItems];
  }

  return itemsArr;
};

export const updateForServer = (items: IMenuList[]): IMenuList[] => {
  const updatedItem = items.map((val, index) => {
    if (val.children) {
      let childUpdatedItem = updateForServer(val.children);
      childUpdatedItem = childUpdatedItem.map((c) => {
        return {
          ...c,
          parentId: val.id
        };
      });
      return { ...val, position: index + 1, children: childUpdatedItem };
    }
    return { ...val, position: index };
  });

  return updatedItem;
};

export const convertMenuItemsToItemType = (
  menuItems: IMenuList[],
  topLevelMenuItems: IMenuList[],
  isSearchMenu = false
) => {
  const generatedMenuItem: MenuItemsType[] = [];
  topLevelMenuItems.forEach((topLevelMenuItem) => {
    let menuItemChildren = undefined;
    const children = menuItems
      .filter((val) => val.parentId === topLevelMenuItem.id)
      .sort((a, b) => a.position - b.position);
    if (children.length > 0) {
      menuItemChildren = convertMenuItemsToItemType(menuItems, children, isSearchMenu);
    }
    let menuIcon: JSX.Element | undefined = undefined;
    const currMenuItemMisc = menuItemsMisc.find((val) => val.key === topLevelMenuItem.key);
    if (isSearchMenu) {
      menuIcon = topLevelMenuItem.type === MenuTypes.PARENT ? <MenuOutlined /> : undefined;
    } else if (currMenuItemMisc) {
      menuIcon = currMenuItemMisc.icon ? currMenuItemMisc.icon : undefined;
    }

    const labelName = topLevelMenuItem.alias ? topLevelMenuItem.alias : topLevelMenuItem.name;
    const menuObj: MenuItemsType = {
      key: topLevelMenuItem.key,
      title: labelName,
      icon: menuIcon,
      label:
        topLevelMenuItem.type === MenuTypes.PARENT ||
        topLevelMenuItem.key === '/dashboard/custom' ? (
          <>{labelName}</>
        ) : (
          <Link to={topLevelMenuItem.key}>{labelName}</Link>
        ),
      onClick: (val: IClickValueTypes) => {
        if (topLevelMenuItem.key === '/dashboard/custom') {
          window.open('https://reports.freshktm.com/', '_blank');
        } else if (topLevelMenuItem.type === MenuTypes.ITEM) {
          if (!val.domEvent.ctrlKey) {
            history.push({
              pathname: topLevelMenuItem.key
            });
          }
        }
      },
      children: menuItemChildren,
      keyword: topLevelMenuItem.keyword
    };
    if (currMenuItemMisc && currMenuItemMisc.aclCheck) {
      if (checkAccess(currMenuItemMisc.aclCheck)) generatedMenuItem.push(menuObj);
    } else {
      generatedMenuItem.push(menuObj);
    }
  });

  return generatedMenuItem;
};
