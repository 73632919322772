import { Modal } from 'antd';
import React, { useState } from 'react';
import CustomButton from '../CustomButton/CustomButton';

type Props = {
  children?: React.ReactNode;
};

const FullScreenModal = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };
  return (
    <>
      <CustomButton text="Full Screen" backgroundColor="#1890ff" onClick={handleOpen} />
      <Modal
        title={null}
        visible={isOpen}
        footer={null}
        width={`100vw`}
        bodyStyle={{ overflow: 'auto', width: '100%' }}
        style={{ top: 5, height: '100%' }}
        onCancel={handleClose}>
        <div className="mt-5">
          {/* <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p>
          <p className="text-center text-2xl font-bold">Full Screen Modal</p> */}
          {children}
        </div>
      </Modal>
    </>
  );
};

export default FullScreenModal;
