import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  ICreateInvoiceResponse,
  IGlobalSettingsResponse,
  IMenuList,
  IMenuListServer
} from './types';

export const get_invoices_list = async () => {
  const response: AxiosResponse<ICreateInvoiceResponse[], any> = await http.get('invoice-layout');
  return response;
};

export const get_invoices_details = async (id: string | undefined) => {
  const response: AxiosResponse<ICreateInvoiceResponse, any> = await http.get(
    'invoice-layout/' + id
  );
  return response;
};

// export const get_invoice_by_reference_and_id = async (reference: string, id: string) => {
//   const response = await http.get('invoice/' + reference + '/' + id);
//   return response;
// };

export const get_global_settings = async () => {
  const response: AxiosResponse<IGlobalSettingsResponse, any> = await http.get('settings');
  return response;
};

export const get_menu_items = async (skip = 0, count = 1000, value = '') => {
  const response: AxiosResponse<IMenuListServer> = await http.get('dynamic-menu', {
    skip,
    count,
    value
  });
  return response;
};

export const get_menu_items_filter = async (filter = '') => {
  const response: AxiosResponse<IMenuListServer> = await http.get('dynamic-menu?' + filter);
  return response;
};

export const get_menu_item_details = async (id: number) => {
  const response: AxiosResponse<IMenuList> = await http.get('dynamic-menu/' + id);
  return response.data;
};
