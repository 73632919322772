import CustomQRIcon from '@/components/Common/CustomIcons/CustomQRIcon';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ActionDropdown from '@/components/Common/Dropdownactions';
import {
  IVendorProductListData,
  IVendorProductListResponse
} from '@/services/vendor-products/types';
import { Menu, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';

interface Props {
  isLoading: boolean;
  data: IVendorProductListResponse;
  page: number;
  size: number;
  onView: (id: number) => void;
  onViewQRCode: (recordId: number) => void;
  onPagination: (page?: number, size?: number, isSize?: boolean) => void;
}

function Table({ isLoading, data, page, size, ...handlers }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IVendorProductListData>>({});

  const columns: ColumnsType<IVendorProductListData> = [
    {
      title: 'ID',
      key: 'id',
      width: 5,
      dataIndex: 'id'
    },
    {
      title: 'Vendor',
      key: 'vendorName',
      width: 15,
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === 'vendorName' ? sortedInfo.order : undefined,
      render: (record) => {
        return <TableCell>{record.userName}</TableCell>;
      }
    },
    {
      title: 'Product',
      key: 'productName',
      width: 15,
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      sortOrder: sortedInfo.columnKey === 'productName' ? sortedInfo.order : undefined,
      render: (record) => {
        return <TableCell>{record.productName}</TableCell>;
      }
    },

    {
      title: 'Actions',
      key: 'actions',
      width: 3,
      fixed: 'right',
      render: (record) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [
          {
            key: '1',
            label: <CustomUpdateIcon link={`/vendor-products/${record.id}`} />
          },
          {
            key: '3',
            label: <CustomViewIcon />,
            onClick: () => handlers.onView(record.id)
          },
          {
            key: '4',
            label: (
              <CustomQRIcon title="View QR Code" onClick={() => handlers.onViewQRCode(record.id)} />
            )
          }
        ];

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const handleChange: TableProps<IVendorProductListData>['onChange'] = (_, __, sorter) => {
    setSortedInfo(sorter as SorterResult<IVendorProductListData>);
  };

  return (
    <CustomizeTable
      columns={columns}
      data={data.vendorProducts}
      usersLoading={isLoading}
      notshowPagination={true}
      paginationDatas={{
        page: page,
        total: data.total,
        size: size,
        onPagination: handlers.onPagination
      }}
      tableName={'vendor-products-list'}
      toSort={handleChange}
    />
  );
}

export default Table;
