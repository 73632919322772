import CustomReport from './report';
import { Form, Input, InputNumber } from 'antd';
import DatePicker, { RangePickerProps } from 'antd/lib/date-picker';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import { ProductsSearch } from '@/components/Common/ProductsSearch';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { find_date_preference } from '@/store/localstorage/preferences';
import moment from 'moment';
import { CustomDatePresets } from '../utils/datePresets';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { getUserData } from '@/utils/auth.utils';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
const AllProductsSellReturnReport = () => {
  const [form] = Form.useForm();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [nepaliType] = useState(find_date_preference());
  const { preferences } = getUserData();

  const { RangePicker } = DatePicker;
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;
  useEffect(() => {
    const nepaliDateStart = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    const nepaliDateEnd = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      locationId: preferenceLocationId ? preferenceLocationId : undefined,
      productId: '',
      dateCustom: [...CustomDatePresets.Today],
      startDate: CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm'),
      endDate: CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm'),
      startDateNepali: nepaliDateStart,
      endDateNepali: nepaliDateEnd
    });
  }, []);

  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart)
      form.setFieldValue(['constraints', 'startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', 'endDate'], convert_nepali_to_english_date(val));
  };
  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'All Product Sell Return Report',
      link: '/reports/all-product-sell-return'
    }
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 25,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Product',
      key: 'product',
      sorter: (a, b) => a.product.localeCompare(b.product),
      sortOrder: sortedInfo.columnKey === 'product' ? sortedInfo.order : null,
      render: (record) => <div>{record.product}</div>,
      width: 100
    },
    {
      title: 'Location',
      key: 'location',
      sorter: (a, b) => a.location.localeCompare(b.location),
      sortOrder: sortedInfo.columnKey === 'location' ? sortedInfo.order : null,
      render: (record) => <div>{record.location}</div>,
      width: 100
    },

    {
      title: 'Return Quantity',
      key: 'return_quantity',
      align: 'right',
      sorter: (a, b) => a.return_quantity - b.return_quantity,
      sortOrder: sortedInfo.columnKey === 'return_quantity' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {nepaliNumberFormatter(record.return_quantity)} {record.unit}
          </div>
        );
      },
      width: 50
    }
  ];

  return (
    <CustomReport
      breadcrumbItems={breadcrumbItems}
      state={{ id: [47], name: 'All Product Sell Return Report' }}
      form={form}
      toSort={handleChange}
      columnsData={[columns]}>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'identifier']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <ProductSearchV2 showAll hasParentFormItem={false} name={['constraints', 'productId']} />
      <LocationSearchV2 showAll hasParentFormItem={false} name={['constraints', 'locationId']} />
      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[
          {
            required: true,
            message: `Please select date!`
          }
        ]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          ranges={CustomDatePresets}
          disabledDate={disabledDate}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
};

export default AllProductsSellReturnReport;
