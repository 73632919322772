import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

interface Igobackbutton {
  onClick: () => void;
}

const GoBackButton = ({ onClick }: Igobackbutton) => {
  return (
    <span
      onClick={onClick}
      style={{
        border: 'none',
        color: '#1890ff',
        // backgroundColor: 'black',
        cursor: 'pointer',
        marginTop: '15px'
      }}>
      <LeftOutlined />
      Go Back
    </span>
  );
};

export default GoBackButton;
