import { shortNameHiearchy } from '../../../../pages/sell/sell-order/view';
import { ISellRecordData, ISellInvoice } from '../../../../services/invoice/types';
import { get_location_details } from '../../../../services/locations/queries';
import { get_units_list, get_product_list_ids } from '../../../../services/products/queries';
import { IProductType } from '../../../../services/products/types';
import { get_routes_details, get_routes_list } from '../../../../services/routes/queries';
import {
  get_sell_details,
  get_sell_lines_details,
  get_sell_order_details,
  get_sell_order_line_details
} from '../../../../services/sell/queries';
import { get_customer_details, get_user_details } from '../../../../services/users/queries';
import LocationsDB from '../../../../store/localstorage/LocationsDB';
import ProductsDB from '../../../../store/localstorage/ProductsDB';
import RoutesDB from '../../../../store/localstorage/RoutesDB';
import UnitsDB from '../../../../store/localstorage/UnitsDB';
import UsersDB from '../../../../store/localstorage/UsersDB';
import { numberDecimalFormatter } from '../../../../utils/numberFormatter';
import { getPrintLocation } from '../BillPrint/services';

export const getSellPrintData = async (
  sellId: number,
  locationId: number,
  sellOrderId?: number | null
) => {
  // Fetch Sell Details
  const response = await get_sell_details(sellId);

  // Fetch Customer Details
  const userData = await get_customer_details(response.customerId);
  const routeData = await getRouteData(response.address.routeId);

  const customerPANInformation = userData.user.tax_informations.find(
    (tax) => tax.taxType === 'PAN'
  );

  // Fetch Location Details
  const locationDetails = await getLocationDetails(locationId);

  const sendUserData = {
    ...userData.user.user,
    routeName: routeData.name,
    panNumber: customerPANInformation?.taxNumber || ''
  };

  // Fetch Lines
  const LinesAndTotal = await getLinesForSellInvoice(sellId);
  const Lines = LinesAndTotal.lines;
  const totalQuantity = LinesAndTotal.totalQuantity;

  // Calc Total Quantity
  const totalQuantityString = getTotalQuantityString(totalQuantity);

  // Created and Finalized By
  const firstCreatedByUserDetails = await getFirstCreatedByDetails(sellOrderId);

  const printLocation = await getPrintLocation();
  return {
    sellDetails: { ...response, totalQuantity: totalQuantityString, printLocation: printLocation },
    customerDetails: {
      ...sendUserData,
      address: locationDetails.address,
      city: locationDetails.city,
      country: locationDetails.country
    },
    lines: Lines,
    firstCreatedByUserDetails: firstCreatedByUserDetails
  } as ISellInvoice;
};

export const getSellOrderPrintData = async (sellOrderId: number, locationId: number) => {
  // Fetch Sell Order Details
  const response: any = (await get_sell_order_details(sellOrderId)).data;

  // Fetch Customer Details
  const userData = await get_customer_details(response.customerId);
  const routeData = await getRouteData(response.address.routeId);

  const customerPANInformation = userData.user.tax_informations.find(
    (tax) => tax.taxType === 'PAN'
  );

  // Fetch Location Details
  const locationDetails = await getLocationDetails(locationId);

  const sendUserData = {
    ...userData.user.user,
    routeName: routeData.name,
    panNumber: customerPANInformation?.taxNumber || ''
  };

  // Fetch Lines
  const LinesAndTotal = await getLinesForSellOrderInvoice(sellOrderId);
  const Lines = LinesAndTotal.lines;
  const totalQuantity = LinesAndTotal.totalQuantity;

  // Taxable, Non Taxable and Vat
  let totalVat = 0;
  let totalTaxable = 0;
  let totalNonTaxable = 0;
  for (let i = 0; i < Lines.length; i++) {
    totalVat += Lines[i].vat;
    if (Lines[i].vat > 0) {
      totalTaxable += Lines[i].totalAmount - Lines[i].vat;
    } else {
      totalNonTaxable += Lines[i].totalAmount;
    }
  }

  // Calc Shipping
  if (response.shipping > 0) {
    totalTaxable = totalTaxable + (response.shipping - response.shippingTax);
    totalVat += response.shippingTax;
  }

  // Calc Total Quantity
  const totalQuantityString = getTotalQuantityString(totalQuantity);

  const printLocation = await getPrintLocation();

  return {
    sellDetails: {
      ...response,
      financialReference: response.id,
      totalVat: totalVat,
      totalTaxable: totalTaxable,
      totalNonTaxable: totalNonTaxable,
      totalQuantity: totalQuantityString,
      printLocation: printLocation
    },
    customerDetails: {
      ...sendUserData,
      address: locationDetails.address,
      city: locationDetails.city,
      country: locationDetails.country
    },
    lines: Lines
  } as ISellInvoice;
};

const getRouteData = async (routeId?: number) => {
  let routeData: any = {
    name: ''
  };
  if (routeId) {
    routeData = await RoutesDB.getRoute(routeId);
    if (!routeData) {
      const response = await get_routes_details(routeId);
      await RoutesDB.addRoutes([response]);
      routeData = await RoutesDB.getRoute(routeId);
    }
  }

  return routeData;
};

const getLocationDetails = async (locationId: number) => {
  let locationDetails = await LocationsDB.getLocation(locationId);
  if (!locationDetails) {
    locationDetails = await get_location_details(locationId);
    await LocationsDB.addLocations([locationDetails]);
  }
  return locationDetails;
};

const getLinesForSellInvoice = async (sellId: number) => {
  const linesResponse = await get_sell_lines_details(sellId);
  const totalQuantity: any = {};
  const searchProducts: any = {};
  for (let index = 0; index < linesResponse.data.length; index++) {
    const product = await ProductsDB.getProduct(linesResponse.data[index].productId);
    if (!product) {
      if (linesResponse.data[index].productId in searchProducts) {
        searchProducts[linesResponse.data[index].productId] = [
          ...searchProducts[linesResponse.data[index].productId],
          index
        ];
      } else {
        searchProducts[linesResponse.data[index].productId] = [index];
      }
    } else {
      linesResponse.data[index].productName = typeof product === 'object' ? product.name : '';
    }
    let findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
    if (!findUnit) {
      const allUnits = await get_units_list();
      await UnitsDB.addUnits(allUnits);
      findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
    }
    if (typeof findUnit === 'object') {
      if (!totalQuantity[findUnit.shortName]) {
        totalQuantity[findUnit.shortName] = linesResponse.data[index].quantity;
      } else {
        totalQuantity[findUnit.shortName] += linesResponse.data[index].quantity;
      }
      linesResponse.data[
        index
      ].quantityWithUnit = `${linesResponse.data[index].quantity} ${findUnit.shortName}`;
      linesResponse.data[index].shortName = findUnit.shortName;
    }
  }
  const searchProductslength = Object.entries(searchProducts).length;
  if (searchProductslength > 0) {
    const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
    for (const key in searchProducts) {
      const findproduct = productsresponse?.data?.results.find(
        (currProduct: IProductType) => currProduct.id == key
      );
      if (findproduct) {
        for (let i = 0; i < searchProducts[key].length; i++) {
          linesResponse.data[searchProducts[key][i]].productName = findproduct.name;
        }
        await ProductsDB.addProducts([findproduct]);
      }
    }
  }

  return { lines: linesResponse.data, totalQuantity: totalQuantity };
};

const getLinesForSellOrderInvoice = async (sellOrderId: number) => {
  const linesResponse = await get_sell_order_line_details(sellOrderId);

  const totalQuantity: any = {};
  const searchProducts: any = {};
  for (let index = 0; index < linesResponse.data.length; index++) {
    const product = await ProductsDB.getProduct(linesResponse.data[index].productId);
    if (!product) {
      if (linesResponse.data[index].productId in searchProducts) {
        searchProducts[linesResponse.data[index].productId] = [
          ...searchProducts[linesResponse.data[index].productId],
          index
        ];
      } else {
        searchProducts[linesResponse.data[index].productId] = [index];
      }
    } else {
      linesResponse.data[index].productName = typeof product === 'object' ? product.name : '';
    }
    let findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
    if (!findUnit) {
      const allUnits = await get_units_list();
      await UnitsDB.addUnits(allUnits);
      findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
    }
    if (typeof findUnit === 'object') {
      if (!totalQuantity[findUnit.shortName]) {
        totalQuantity[findUnit.shortName] = linesResponse.data[index].quantity;
      } else {
        totalQuantity[findUnit.shortName] += linesResponse.data[index].quantity;
      }
      linesResponse.data[
        index
      ].quantityWithUnit = `${linesResponse.data[index].quantity} ${findUnit.shortName}`;
      linesResponse.data[index].shortName = findUnit.shortName;
    }
  }
  const searchProductslength = Object.entries(searchProducts).length;
  if (searchProductslength > 0) {
    const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
    for (const key in searchProducts) {
      const findproduct = productsresponse?.data?.results.find(
        (currProduct: IProductType) => currProduct.id == key
      );
      if (findproduct) {
        for (let i = 0; i < searchProducts[key].length; i++) {
          linesResponse.data[searchProducts[key][i]].productName = findproduct.name;
        }
        await ProductsDB.addProducts([findproduct]);
      }
    }
  }

  return { lines: linesResponse.data, totalQuantity: totalQuantity };
};

const getTotalQuantityString = (totalQuantity: any) => {
  let totalQuantityString = '';
  const totalQuantityArray: { tqty: number; shortName: string }[] = [];
  for (const key in totalQuantity) {
    totalQuantityArray.push({
      tqty: totalQuantity[key],
      shortName: key
    });
  }
  totalQuantityArray.sort((a: any, b: any) => {
    return shortNameHiearchy.indexOf(a.shortName) - shortNameHiearchy.indexOf(b.shortName);
  });
  for (let ind = 0; ind < totalQuantityArray.length; ind++) {
    totalQuantityString += ` ${numberDecimalFormatter(totalQuantityArray[ind].tqty)} ${
      totalQuantityArray[ind].shortName
    }`;
  }

  return totalQuantityString;
};

const getFirstCreatedByDetails = async (sellOrderId?: number | null) => {
  let firstCreatedByUser;
  if (sellOrderId) {
    const sellOrderResponse = await get_sell_order_details(sellOrderId);
    if (sellOrderResponse?.data?.createdBy) {
      firstCreatedByUser = sellOrderResponse.data.createdBy;
    }
  }

  let firstCreatedByUserDetails: any;
  if (firstCreatedByUser) {
    firstCreatedByUserDetails = await UsersDB.getUser(firstCreatedByUser);
    if (!firstCreatedByUserDetails) {
      firstCreatedByUserDetails = (await get_user_details(firstCreatedByUser)).user;
      if (firstCreatedByUserDetails) UsersDB.addUsers(firstCreatedByUserDetails);
    }
  }

  return firstCreatedByUserDetails;
};
