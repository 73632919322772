import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IDeleteResponse, IRoutes, IRoutesCreateRequest } from './types';

export const create_routes_mutation = async (values: IRoutesCreateRequest) => {
  const response: AxiosResponse<IRoutes, any> = await http.store('routes', values);
  return response;
};

export const update_routes_mutation = async (values: IRoutes) => {
  const response: AxiosResponse<any, any> = await http.update('routes/' + values.id, values);
  return response;
};
export const delete_routes = async (id: number) => {
  const response: AxiosResponse<IDeleteResponse, any> = await http.remove('routes/' + id);
  return response;
};
