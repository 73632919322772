import { PrinterOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';
import { get_invoices_details } from '../../../services/settings/queries';
import { ICreateInvoiceResponse } from '../../../services/settings/types';
import { find_default_invoice } from '../../../store/localstorage/preferences';

interface IInvoicePrintButton {
  getInvoice: (curr: ICreateInvoiceResponse) => void;
}

const InvoicePrintButton: React.FC<IInvoicePrintButton> = ({ getInvoice }) => {
  const defaultInvoice = find_default_invoice();

  return (
    <div
      className="w-full p-0 cursor-pointer"
      onClick={async () => {
        if (defaultInvoice) {
          const curr = (await get_invoices_details(defaultInvoice.toString())).data;
          getInvoice(curr);
        } else {
          message.error('Please set default invoice in preference first!');
        }
      }}>
      <Tooltip title="Print" color="blue">
        <PrinterOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
      </Tooltip>
    </div>
  );
};

export default InvoicePrintButton;
