import { Button, Divider, Form, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';

import useDebounce from '../../../hooks/useDebounce';
import { get_vendor_types_filter } from '../../../services/vendor-types/queries';
import { IVendorType } from '../../../services/vendor-types/types';

interface VendorTypeSearchProps {
  formData?: {
    formName: string;
    label: string;
  };
  isAll?: boolean;
  required?: boolean;
  onSelect?: (value: number) => any;
}

const { Option } = Select;

export const VendorTypeSearch: React.FC<VendorTypeSearchProps> = ({
  formData,
  isAll = true,
  required = false
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);

  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchVendorType(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const [vendorTypeSearch, setVendorTypeSearch] = useState<IVendorType[]>([]);

  const searchVendorType = async (value: any) => {
    let response;
    try {
      response = await get_vendor_types_filter(skip, 10, value);
      console.log('response', response);
    } catch (e) {
      console.log(e);
    }

    if (!response || response.data.results.length == 0) {
      message.info('Cannot find more supplier types with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);
      setVendorTypeSearch([...vendorTypeSearch, ...response.data.results]);
      if (response.data.results.length < 10) {
        setIsMore(false);
      }
    }
  };

  const vendorTypeArray = Object.values(vendorTypeSearch);

  const options = vendorTypeArray.map((value: any) => (
    <Option key={value.id} value={value.id}>
      {value.name}
    </Option>
  ));

  return (
    <>
      {formData ? (
        <Form.Item
          name={formData.formName}
          label={formData.label}
          rules={[
            {
              required: required,
              message: 'Please choose a supplier type!'
            }
          ]}>
          <Select
            showSearch
            allowClear
            placeholder={'Select a supplier type!'}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            dropdownMatchSelectWidth={false}
            onSearch={(val) => {
              setSearchValue(`value=${val}`);
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                {isMore ? (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchVendorType(searchValue);
                      }}>
                      {'Pull More & Sync'}
                    </Button>
                  </div>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center'
                    }}>
                    No more data...
                  </div>
                )}
              </>
            )}>
            {isAll ? <Option value="">All</Option> : null}
            {options}
          </Select>
        </Form.Item>
      ) : (
        <Select
          showSearch
          allowClear
          placeholder="Select a supplier type!"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          notFoundContent={null}
          dropdownMatchSelectWidth={false}
          onSearch={(val) => {
            setSearchValue(`value=${val}`);
          }}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              {isMore ? (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  <Button
                    type="text"
                    style={{
                      color: 'blue',
                      width: '100%'
                    }}
                    onClick={() => {
                      searchVendorType(searchValue);
                    }}>
                    {'Pull More & Sync'}
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center'
                  }}>
                  No more data...
                </div>
              )}
            </>
          )}>
          {isAll ? <Option value="">All</Option> : null}
          {options}
        </Select>
      )}
    </>
  );
};
