import { Divider, Image, PageHeader, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { get_image } from '../../../components/Common/FetchImage/FetchImage';
import { API_URL } from '../../../constants/config';
import { get_invoices_list } from '../../../services/settings/queries';
import freshktmfulllogo from '../../../layouts/logo/freshktmfulllogo.png';
import type { ColumnsType } from 'antd/es/table';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import CustomTable from './CustomTable';
import CustomBarcode from '../../../pages/barcode';
import { nepaliNumberFormatter } from '../../../utils/numberFormatter';
import {
  convertLocalToUTCString,
  convertUTCStringtoLocalString
} from '../../../utils/convertToUTC';
import { clearLocalStorage } from '../../../utils/storage.utils';
import {
  convert_string_to_nepali_date_object,
  convert_string_to_nepali_date_string
} from '../../../utils/nepaliDateConverter';
import moment from 'moment';
import './index.css';
import { getUserData } from '../../../utils/auth.utils';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import { get_product_list } from '../../../services/products/queries';
import { DEFAULT_DATE_FORMAT } from '@/constants';

export const InvoiceForSellandSellOrder = () => {
  // const { state }: any = useLocation();
  const loggedInUser = getUserData();
  const state = JSON.parse(localStorage.getItem('referrer') as string);
  const {
    customerDetails,
    vendorDetails,
    locationDetails,
    purchaseDetails,
    lines,
    invoiceLayouts,
    routeInformation,
    adjustmentDetails,
    transferData,
    createdByDetails,
    printLocation
  } = state;
  // console.log(state);
  // console.log('--->', convert_string_to_nepali_date_object(moment().format()));
  const [imgSrc, setimgSrc] = useState('');
  const [data, setData] = useState({
    linesData: [],
    taxable: 0,
    nonTaxable: 0,
    vat: 0,
    printMode: false
  });
  let content: any;
  if (invoiceLayouts) {
    if ('content' in invoiceLayouts) {
      if (typeof invoiceLayouts.content == 'string') content = JSON.parse(invoiceLayouts.content);
    } else {
      invoiceLayouts.content = '';
    }
  }
  // console.log('content', content);
  // invoiceLayouts['content'] = invoiceLayouts?.content ? JSON.parse(invoiceLayouts.content) : '';s
  // console.log('invoice layout-->', invoiceLayouts);
  // console.log('purchaseDetails-->', purchaseDetails);
  // console.log('LocationDetails', locationDetails);
  // console.log('State-->', state);

  // if (invoiceLayouts.content.footerText) {
  //   const tag_id: any = document.getElementById('footerId');
  //   tag_id.innerHTML = invoiceLayouts.content.footerText;
  // }
  useEffect(() => {
    getData();
    getInfo();
  }, [data]);
  const getInfo = async () => {
    if ('invoiceLogo' in content) {
      const imageData = await get_image(`${API_URL}storage/media/${content.invoiceLogo}`);
      setimgSrc(imageData);
    } else if (data.printMode) {
      window.print();
      window.onafterprint = (event) => {
        window.close();
        localStorage.removeItem('referrer');
      };
      window.close();
    }
  };

  const getData = async () => {
    if (lines.length > 0) {
      let taxableAmt = 0;
      let nonTaxableAmt = 0;
      let vatAmt = 0;
      for (let index = 0; index < lines.length; index++) {
        const productDetails = await ProductsDB.getProduct(lines[index].productId);
        if (!productDetails) {
          const allProducts = await get_product_list();
          await ProductsDB.addProducts(allProducts.data.results);
        }
        if (typeof productDetails === 'object' && productDetails.vat != undefined) {
          const vat = lines[index].unitPrice * lines[index].quantity * (productDetails.vat / 100);
          lines[index].vat = vat;
          if (vat > 0) {
            vatAmt += vat;
            lines[index].totalAmount = lines[index].totalAmount - vat;
            taxableAmt += lines[index].totalAmount;
          } else {
            nonTaxableAmt += lines[index].totalAmount;
          }
        }
      }
      setData({
        linesData: lines,
        taxable: taxableAmt,
        nonTaxable: nonTaxableAmt,
        vat: vatAmt,
        printMode: true
      });
    }
  };

  const returnColumns: any = [
    {
      label: 'Total Amount (NPR)',
      dataIndex: 'totalAmount',
      width: 100,
      render: (text: number) => <div className="text-center">{text}/-</div>
    },
    {
      label: 'Total Tax (NPR)',
      dataIndex: 'totalTax',
      width: 100,
      render: (text: number) => <div className="text-center">{text}/-</div>
    },
    {
      label: 'Date',
      dataIndex: 'date',
      width: 100,
      render: (text: any) => (
        <div className="text-center">{moment(text).local().format(DEFAULT_DATE_FORMAT)}</div>
      )
    }
  ];
  const columns: any = [
    {
      label: (
        <div
          style={{
            backgroundColor: 'white',
            color: 'black',
            padding: '0px'
          }}>
          {`${
            invoiceLayouts?.content?.productLabel
              ? invoiceLayouts.content.productLabel
              : 'ProductName'
          }`}
        </div>
      ),
      dataIndex: 'productName',
      width: 200,
      render: (text: string) => <div className="px-1">{text}</div>
    },
    // {
    //   label: `${
    //     invoiceLayouts?.content?.unitPriceLabel ? invoiceLayouts.content.unitPriceLabel : 'Unit'
    //   }`,
    //   dataIndex: 'shortName',
    //   width: 100,
    //   render: (text: string) => <div className="text-right px-1">{text}</div>
    // },
    {
      label: `${
        invoiceLayouts?.content?.quantityLabel ? invoiceLayouts.content.quantityLabel : 'Quantity'
      }`,
      dataIndex: 'quantityWithUnit',
      width: 100,
      render: (text: number) => <div className="text-right px-1">{text}</div>
    },
    {
      label:
        `${
          invoiceLayouts?.content?.unitPriceLabel
            ? invoiceLayouts.content.unitPriceLabel
            : 'UnitPrice'
        }` + ' (NPR)',
      dataIndex: 'unitPrice',
      width: 100,
      render: (text: number) => <div className="text-right px-1">{text}/-</div>
    },
    {
      label:
        `${
          invoiceLayouts?.content?.vatColumnLabel ? invoiceLayouts.content.vatColumnLabel : 'VAT'
        }` + ' (NPR)',
      dataIndex: 'vat',
      width: 100,
      render: (text: number) => <div className="text-right px-1">{text}/-</div>
    },
    {
      label:
        `${
          invoiceLayouts?.content?.totalLabel ? invoiceLayouts.content.totalLabel : 'Total Amount'
        }` + ' (NPR)',
      dataIndex: 'totalAmount',
      width: 100,
      render: (text: number) => <div className="text-right px-1">{text}/-</div>
    }
  ];

  const paymentColumns: any = [
    {
      label: 'Date',
      dataIndex: 'date',
      width: 100,
      render: (text: string) => <div className="text-center">{text.slice(0, 10)}</div>
    },
    {
      label: 'Reference No.',
      dataIndex: 'reference',
      width: 100,
      render: (text: string) => <div className="text-center">{text}</div>
    },
    {
      label: 'Amount (NPR)',
      dataIndex: 'amount',
      width: 100,
      render: (text: number) => <div className="text-center">{text}/-</div>
    },
    {
      label: 'Payment Mode',
      dataIndex: 'paymentMode',
      width: 100,
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: 'Payment note',
      dataIndex: 'note',
      width: 100,
      render: (text: number) => <div className="text-center">{text}</div>
    }
  ];

  return (
    <div className="p-5" style={{ color: 'black' }}>
      <div className="flex justify-between">
        <Image
          src={imgSrc}
          preview={false}
          onLoad={() => {
            if (data.printMode) {
              window.print();
              window.onafterprint = (event) => {
                window.close();
                localStorage.removeItem('referrer');
              };
            }
          }}
        />
        <div>
          <div className="font-bold text-lg mr-3">
            {content?.companyName ? content.companyName : 'Fresh K.T.M. Pvt. Ltd.'}
          </div>
          <div>{printLocation?.name ? printLocation.name : ''} </div>
          <div className="mt-5">
            <span className="font-bold text-sm">
              {content?.panNumberLabel ? content.panNumberLabel : 'PAN No.'}:
            </span>
            <span className="text-sm">606891700</span>{' '}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-3">
        <div className="text-2xl font-extrabold">
          {content?.companyName ? content.companyName : 'Freshktm Agro Pvt. Ltd.'} ,
          {locationDetails?.name ? locationDetails.name : ''}
        </div>
        <div className="text-xl font-bold">
          {content?.sellOrderInvoiceName ? content.sellOrderInvoiceName : 'Proforma Invoice'}{' '}
        </div>
      </div>
      <div className="flex justify-between">
        <div>
          <div>
            <span className="text-sm font-bold">No.</span> <span>{purchaseDetails.id}</span>
          </div>
          <div>
            {content?.customerPanNumberLabel ? content.customerPanNumberLabel : 'PAN number'}:{' '}
            {content?.panNumber ? content.panNumber : ''}
          </div>
          <div>
            <span>{customerDetails.name}</span>
          </div>
          <div>
            <span>{customerDetails.email}</span>
          </div>
          <div>
            {purchaseDetails.address.addressLine1} {purchaseDetails.address.addressLine2}
          </div>
          <div>{customerDetails.phone}</div>
        </div>
        <div>
          <div>
            <span className="text-sm font-bold">Route: </span>
            {customerDetails?.routeName}
          </div>
          <div>
            <span className="text-sm font-bold">Date:</span>
            {/* <div>{new Date(new Date().getTime()).toString()}</div> */}
            {/* <div>{convert_string_to_nepali_date_object(moment().format())}</div> */}
            <span>{convertUTCStringtoLocalString(purchaseDetails.createdAt, 'YYYY-MM-DD')}</span>
          </div>
        </div>
      </div>
      <div className="mt-4">Products Info</div>
      <div className="mt-3">
        <CustomTable columns={columns} datas={data.linesData ? data.linesData : []} />
      </div>
      {/* <CustomizeTable columns={columnss} data={lines} /> */}
      {/* <CustomTable columns={columnss} datas={lines} /> */}
      {/* <CustomizeTable columns={columns} notshowPagination={true} data={lines} /> */}
      {purchaseDetails?.payments?.length > 0 ? (
        <>
          <div className="mt-4">Payment Info</div>
          <div>
            <CustomTable
              columns={paymentColumns}
              datas={purchaseDetails?.payments ? purchaseDetails.payments : []}
            />
          </div>
          <Divider />
        </>
      ) : (
        <></>
      )}

      {purchaseDetails?.returns?.length > 0 ? (
        <>
          <div className="mt-4">Return Info</div>
          <div>
            <CustomTable
              columns={returnColumns}
              datas={purchaseDetails?.returns ? purchaseDetails.returns : []}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      <Divider />
      {purchaseDetails ? (
        <div className="flex justify-end">
          {/* <div>
            <div>
              {content?.totalDueLabel ? content.totalDueLabel : 'Total Due'}: :
              {nepaliNumberFormatter(10000)}
            </div>
          </div> */}
          <div>
            <div>
              {content?.totalQuantityLabel ? content.totalQuantityLabel : 'Total Quantity'}:{' '}
              {purchaseDetails.totalQuantity}
            </div>
            <div>
              {content?.totalLabel ? content.totalLabel : 'Total Amount'}:{' '}
              {nepaliNumberFormatter(
                purchaseDetails.totalAmount - (data.vat + purchaseDetails.shipping)
              )}{' '}
              /-
            </div>
            <div>
              {content?.totalDiscount ? content.totalDiscount : 'Total Discount'}:{' '}
              {nepaliNumberFormatter(purchaseDetails.totalDiscount)} /-
            </div>
            <div>
              {content?.taxLabel ? content.taxLabel : 'Taxable'}:{' '}
              {nepaliNumberFormatter(data.taxable)} /-
            </div>
            <div>
              {content?.nonTaxableLabel ? content.nonTaxableLabel : 'Non Taxable'}:{' '}
              {nepaliNumberFormatter(data.nonTaxable)} /-
            </div>
            <div>
              {content?.vatLabel ? content.vatLabel : 'VAT'}: {nepaliNumberFormatter(data.vat)} /-
            </div>
            <div>
              {content?.shippingChargeLabel ? content.shippingChargeLabel : 'Shipping Charge'}:{' '}
              {nepaliNumberFormatter(purchaseDetails.shipping)} /-
            </div>
            <div>
              {content?.netTotalLabel ? content.netTotalLabel : 'Net Total'}:{' '}
              {nepaliNumberFormatter(purchaseDetails.totalAmount)} /-
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {invoiceLayouts?.content?.footerText ? (
        <div dangerouslySetInnerHTML={{ __html: invoiceLayouts?.content?.footerText }}></div>
      ) : (
        <></>
      )}
      {/* {purchaseDetails && (
        <div className="flex items-center justify-center">
          <CustomBarcode
            value={
              purchaseDetails.referenceNumber
                ? purchaseDetails.referenceNumber
                : '0000000000000000ssssssss'
            }
            height={25}
          />
        </div>
      )} */}
      <div>Created By:{createdByDetails ? createdByDetails.name : ''}</div>

      <div>
        Printed By:{' '}
        <span className="capitalize">{loggedInUser?.name ? loggedInUser.name : ''}</span>
      </div>

      <div>
        {content?.noteLabel ? content.noteLabel : 'Note'} :{' '}
        {purchaseDetails.note ? purchaseDetails.note : ''}
      </div>
      <div className="mt-5 flex justify-center">
        <table style={{ width: '80%', border: '1px solid black' }}>
          <tr>
            <td style={tdStyleone}>
              <div style={tdivStyle}>.....................................</div>
            </td>
            <td style={tdStyleone}>
              <div style={tdivStyle}>info@freshktm.com</div>{' '}
            </td>
            <td style={tdStyleone}>
              <div style={tdivStyle}>......................................</div>
            </td>
          </tr>
          <tr>
            <td style={tdStyle}>
              <div style={tdivStyle}>
                {content?.invoiceByLabel ? content.invoiceByLabel : 'Invoice by'}:
              </div>
            </td>
            <td style={tdStyle}>
              <div style={tdivStyle}>www.freshktm.com</div>
            </td>
            <td style={tdStyle}>
              <div style={tdivStyle}>For:Freshktm</div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

const tdStyleone = {
  border: '1px solid black',
  padding: '15px 0px 0px 1px',
  alignText: 'center'
};

const tdStyle = {
  border: '1px solid black',
  padding: '3px',
  alignText: 'center'
};

const tdivStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};
