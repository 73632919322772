import useDebounce from '@/hooks/useDebounce';
import { useEffect, useState } from 'react';
import ExpenseDB from '@/store/localstorage/ExpenseDB';
import { Button, Divider, Form, message, Select } from 'antd';
import { get_expense_list_v2 } from '@/services/expense/queries';
import { IExpenseListRevamp } from '@/services/expense/types';

interface Default {
  showAll?: boolean;
  onSelect?: (value: string | number) => void;
}

type Props = Default &
  (
    | {
        hasParentFormItem: true;
      }
    | {
        hasParentFormItem: false;
        formName?: string;
        label?: string;
        required?: boolean;
      }
  );

type ExpenseSearchType = 'initial' | 'more';

function ExpenseSearch({ onSelect, showAll, ...props }: Props) {
  const [skip, setSkip] = useState(0);
  const [isMore, setIsMore] = useState(true);

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const [expenseList, setExpenseList] = useState<IExpenseListRevamp[]>([]);
  const [isCacheResponse, setIsCacheResponse] = useState(false);

  useEffect(() => {
    searchIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);

  async function searchIndexDB(value: string) {
    try {
      const agents = await ExpenseDB.search(value);
      if (!agents || agents.length == 0) {
        message.error('Cannot find any expense with that value in cache, searching in server...');
        searchExpense(value, 'initial');
        return;
      }

      setExpenseList(agents);
      setIsCacheResponse(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function searchExpense(value: string, searchType: ExpenseSearchType) {
    try {
      const currentSkip = searchType === 'initial' ? 0 : skip;
      const data = await get_expense_list_v2(currentSkip, 10, value);
      const isValidResponse = data && data.results.length > 0;
      if (!isValidResponse) {
        message.error('Cannot find any expenses with that bill or reference number!');
        setIsMore(false);
        searchType === 'initial' && setExpenseList([]);
      }

      if (isValidResponse) {
        searchType === 'initial'
          ? setExpenseList(data.results)
          : setExpenseList((prev: any) => [...prev, ...data.results]);

        await ExpenseDB.add(data.results);
        setIsMore(data.results.length >= 10);
        searchType === 'more' && setSkip(currentSkip + 10);
      }

      if (searchType === 'initial') {
        setIsCacheResponse(false);
        setSkip(10);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data.message || 'Failed to get expense list';
      message.error(errorMessage);
    }
  }

  const options = expenseList.map((expense) => {
    return (
      <Select.Option key={expense.id} value={expense.id}>
        {expense.referenceNumber} (Bill: {expense.billNumber})
      </Select.Option>
    );
  });

  const selectMenu = (
    <Select
      showSearch
      allowClear
      filterOption={false}
      placeholder="Search agent"
      defaultActiveFirstOption={false}
      onClear={() => searchIndexDB('')}
      onSearch={(val) => setSearchValue(val)}
      notFoundContent={<div>No Content</div>}
      onChange={onSelect}
      dropdownMatchSelectWidth={false}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            {isCacheResponse ? (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                <Button
                  type="text"
                  style={{ color: 'blue', width: '100%' }}
                  onClick={() => searchExpense(searchValue, 'initial')}>
                  {'Pull More & Sync'}
                </Button>
              </div>
            ) : (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                {isMore ? (
                  <Button
                    type="text"
                    style={{ color: 'blue', width: '100%' }}
                    onClick={() => searchExpense(searchValue, 'more')}>
                    {'Get More...'}
                  </Button>
                ) : (
                  <div style={{ width: '100%', textAlign: 'center' }}>No more data...</div>
                )}
              </div>
            )}
          </>
        );
      }}>
      {showAll && <Select.Option value="">All</Select.Option>}
      {options}
    </Select>
  );

  if (props.hasParentFormItem) return selectMenu;
  return (
    <Form.Item
      label={props.label || 'Select Expense'}
      name={props.formName || 'expenseId'}
      rules={[{ required: props.required || false, message: 'Please select expense!' }]}>
      {selectMenu}
    </Form.Item>
  );
}

export default ExpenseSearch;
