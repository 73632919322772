import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get_location_details, get_location_list } from '../../../services/locations/queries';
import LocalStore from '../../localstorage';
import LocationsDB from '../../localstorage/LocationsDB';
import { RootState } from '../../store';

interface ILocationsIntialState {
  locations: any[];
  status: string;
}
const INITIAL_STATE: ILocationsIntialState = {
  locations: [],
  status: 'idle'
};
interface LocationProps {
  skip: number;
  count: number;
  value: string;
}
export const getLocations = createAsyncThunk(
  'locations/getLocations',
  async ({ skip, count, value }: LocationProps = { skip: 0, count: 0, value: '' }) => {
    // let fetchData: any = await LocalStore.getAllLocations();
    // if (fetchData.length < 1) {
    const fetchData = await (await get_location_list(skip, count, value)).data.results;
    // }
    return await fetchData;
  }
);
export const getLocation = createAsyncThunk('location/getLocation', async (id: number) => {
  // let fetchData: any;
  // try {
  //   fetchData = await LocalStore.getLocation(id);
  // } catch (e: any) {
  //   console.log(e);
  // } finally {
  //   console.log(fetchData);
  //   if (!fetchData) {
  //     fetchData = await get_location_details(id);
  //   }
  // }
  // return fetchData;
  return await get_location_details(id);
});
export const updateLocations = createAsyncThunk('locations/getLocations', get_location_details);
const locationsSlice = createSlice({
  name: 'locations',
  initialState: INITIAL_STATE,
  reducers: {
    removeLocation: (state, action: PayloadAction<number>) => {
      state.locations = state.locations.filter(function (obj) {
        return obj.id !== action.payload;
      });
      LocationsDB.deleteLocation(action.payload);
    },
    addLocation: (state, action: PayloadAction<any>) => {
      state.locations = [...state.locations, action.payload];
      LocationsDB.addLocations([action.payload]);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getLocations.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.locations = action.payload;
        state.status = 'success';
        LocationsDB.addLocations(action.payload);
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.status = 'error';
      })
      .addCase(getLocation.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getLocation.fulfilled, (state, action) => {
        // const objWithIdIndex = state.locations.findIndex((obj) => obj.id === action.payload.id);
        // if (objWithIdIndex == -1) {
        state.locations = [...state.locations, action.payload];
        // }
        // state.locations[objWithIdIndex] = action.payload;
        state.status = 'success';
        // LocalStore.updateLocation(action.payload);
        LocationsDB.addLocations([action.payload]);
      })
      .addCase(getLocation.rejected, (state, action) => {
        state.status = 'error';
      });
  }
});

export const selectAllLocations = (state: RootState) => state.locations.locations;
export const { removeLocation, addLocation } = locationsSlice.actions;
export default locationsSlice.reducer;
