import { Menu } from 'antd';
import React, { createContext, useState } from 'react';
import { getmenuorders } from '../../../constants/menu';
import { IMenuList } from '../../../services/settings/types';

interface props {
  children: JSX.Element | JSX.Element[];
}

export interface contextInterface {
  menuOrders: IMenuList[];
  setMenuOrders: (ar: IMenuList[]) => void;
}

export const MenuContext = createContext<contextInterface>({} as contextInterface);

export const Context = ({ children }: props) => {
  const [menuOrders, setMenuOrders] = useState<IMenuList[]>([]);

  return (
    <MenuContext.Provider value={{ menuOrders, setMenuOrders }}>{children}</MenuContext.Provider>
  );
};

// export default Context
