import { Button, Divider, Form, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { get_customer_list, get_customer_list_ids } from '../../../services/users/queries';
import CustomersDB from '../../../store/localstorage/CustomerDB';
interface ITaxtype {
  taxNumber: string;
  taxType: string;
  id?: string;
  userId?: number;
}
interface UsersSearchProps {
  formData?: {
    formName: any;
    label?: string;
  };
  onSelect?: (value: number) => any;
  all?: boolean;
  notRequired?: boolean;
  initial?: any;
  applyFilter?: boolean;
  isMultipleSelect?: boolean;
  isForm?: boolean;
}
const { Option } = Select;
export const CustomerSearch: React.FC<UsersSearchProps> = ({
  formData = { formName: ['userId'] },
  onSelect,
  all = false,
  notRequired = false,
  initial,
  applyFilter = false,
  isMultipleSelect = false,
  isForm = true
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [selectValue, setselectValue] = useState<any>();
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);
  const [initalSearch, setinitialSearch] = useState<boolean>(true);
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);

  useEffect(() => {
    if (initalSearch && initial) {
      searchCustomerIndexDB(`${initial}`);
    } else {
      searchCustomerIndexDB(debouncedSearchValue);
    }
    // searchUser(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const [userSearch, setUserSearch] = useState<any>([]);

  const fetchInitalUserFromServer = async (id: any) => {
    const response = await get_customer_list_ids([id]);
    if (response.data) {
      CustomersDB.addCustomers(response.data.results);
      setinitialSearch(false);
      searchCustomerIndexDB(`${id}`);
    }
  };

  const searchCustomerIndexDB = async (value: string) => {
    try {
      // console.log('value', value);
      const response = await CustomersDB.searchCustomer(value);
      if (!response || response.length == 0) {
        message.info('Cannot find any customer with that value in cache, searching in server...');

        if (initial && initalSearch) {
          fetchInitalUserFromServer(initial);
        } else {
          searchUser(value);
        }
      } else {
        setUserSearch(response);
        setIsCacheResponse(true);
      }
    } catch (error) {
      message.error(error as string);
    }
  };
  const searchUser = async (value: any) => {
    let response;
    try {
      response = await get_customer_list(0, 10, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any customer with that value in server!');
      setUserSearch([]);
      setIsMore(false);
    } else {
      setUserSearch(response.data.results);
      CustomersDB.addCustomers(response.data.results);
      if (response.data.results.length < 10) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
    }
    setIsCacheResponse(false);
    setSkip(10);
  };

  const searchMoreUser = async (value: any) => {
    let response;
    try {
      response = await get_customer_list(skip, 10, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.info('Cannot find more customer with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);
      setUserSearch([...userSearch, ...response.data.results]);
      CustomersDB.addCustomers(response.data.results);
      if (response.data.results.length < 10) {
        setIsMore(false);
      }
    }
  };

  const options = applyFilter
    ? userSearch
        .filter((value: any) => !value?.user?.disabled)
        .map((value: any) => (
          <Option key={value.id} value={value.id}>
            <div className="flex justify-between">
              <div>
                {' '}
                {value.name ? value.name : value?.user?.name},{' '}
                {value.phone ? value.phone : value?.user?.phone}{' '}
              </div>
              <div style={{ marginRight: '15%', color: 'green' }}>
                {selectValue && selectValue == value.id ? (
                  <>
                    <span className="text-xl font-bold">{value.grade}</span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Option>
        ))
    : userSearch.map((value: any) => (
        <Option key={value.id} value={value.id}>
          <div className="flex justify-between">
            <div>
              {' '}
              {value.name ? value.name : value?.user?.name},{' '}
              {value.phone ? value.phone : value?.user?.phone}{' '}
            </div>
            <div style={{ marginRight: '15%', color: 'green' }}>
              {selectValue && selectValue == value.id ? (
                <>
                  <span className="text-xl font-bold">{value.grade}</span>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Option>
      ));

  return (
    <>
      {isForm ? (
        <Form.Item
          name={formData.formName}
          label={formData.label ? formData.label : 'Customer'}
          rules={[
            {
              required: all || notRequired ? false : true,
              message: 'Please choose Customer!'
            }
          ]}>
          <Select
            showSearch
            allowClear
            mode={isMultipleSelect ? 'multiple' : undefined}
            placeholder={'search user'}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onChange={(value) => {
              setselectValue(value);
              onSelect && onSelect(value);
            }}
            dropdownMatchSelectWidth={false}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                {isCacheResponse ? (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {/* <div
                    style={{
                      color: 'green',
                      width: '100%',
                      textAlign: 'center'
                    }}>{`Cache found ${userSearch.length} data`}</div> */}
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchUser(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Pull More & Sync'}
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {isMore ? (
                      <Button
                        type="text"
                        style={{
                          color: 'blue',
                          width: '100%'
                        }}
                        onClick={() => {
                          searchMoreUser(searchValue);
                        }}>
                        {/* {'Sync & Search on Server'} */}
                        {'Get More...'}
                      </Button>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center'
                        }}>
                        No more data...
                      </div>
                    )}
                  </div>
                )}
              </>
            )}>
            {all && <Option value="">All</Option>}
            {options}
          </Select>
        </Form.Item>
      ) : (
        <Select
          showSearch
          allowClear
          mode={isMultipleSelect ? 'multiple' : undefined}
          placeholder={'search user'}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onChange={(value) => {
            setselectValue(value);
            onSelect && onSelect(value);
          }}
          dropdownMatchSelectWidth={false}
          onSearch={(val) => {
            setSearchValue(val);
          }}
          notFoundContent={null}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              {isCacheResponse ? (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  <Button
                    type="text"
                    style={{
                      color: 'blue',
                      width: '100%'
                    }}
                    onClick={() => {
                      searchUser(searchValue);
                    }}>
                    {/* {'Sync & Search on Server'} */}
                    {'Pull More & Sync'}
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {isMore ? (
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchMoreUser(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Get More...'}
                    </Button>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center'
                      }}>
                      No more data...
                    </div>
                  )}
                </div>
              )}
            </>
          )}>
          {all && <Option value="">All</Option>}
          {options}
        </Select>
      )}
    </>
  );
};
