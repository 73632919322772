import moment from 'moment';
import { useState } from 'react';
import { TableProps } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table, { ColumnsType } from 'antd/lib/table';

import { ExtendedInitialValues } from '.';
import { ICustomSellReport } from '@/services/report/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { WithReportPaginationProps } from '@/components/Common/Report/withReportPagination';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportCurrent from '@/components/Common/ExportCurrent';
import ExportAllData from '@/components/Common/ExportAll';
import CopyButton from '@/components/Common/CopyButton';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { excelExportColumns, getUpdatedData, tableExportColumns } from './column.export';

interface Props
  extends Pick<WithReportPaginationProps, 'onPagination' | 'onChange' | 'pagination'> {
  data: { total: number; data: ICustomSellReport[] };
  onSearch: (values: ExtendedInitialValues) => void;
  onSearchAll: () => Promise<ICustomSellReport[]>;
}

function TableData({ data, pagination, ...handlers }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const total = data.data.reduce(
    (acc, curr) => {
      acc.gross += Number(curr['GROSS']);
      acc.return += Number(curr['RETURN']);
      acc.discount += Number(curr['DISCOUNT']);
      acc.net_sale += Number(curr['NET SALE']);
      acc.vat += Number(curr['VAT']);
      acc.total += Number(curr['TOTAL']);
      acc.pay_amount += Number(curr['TOTAL PAYMENT']);
      return acc;
    },
    { gross: 0, return: 0, discount: 0, net_sale: 0, vat: 0, total: 0, pay_amount: 0 }
  );

  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const columns: ColumnsType<ICustomSellReport> = [
    {
      title: 'SN',
      key: 'SN',
      width: 30,
      render: (text, record, index) => {
        return <TableCell>{(pagination.page - 1) * pagination.size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'DATE',
      dataIndex: 'DATE',
      key: 'DATE',
      width: 70,
      sorter: (a, b) => a.DATE.localeCompare(b.DATE),
      sortOrder: sortedInfo.columnKey === 'DATE' ? sortedInfo.order : null,
      render: (text) => {
        return <TableCell>{convertUTCStringtoLocalString(text, 'YYYY-MM-DD')}</TableCell>;
      }
    },
    {
      title: 'TIME',
      dataIndex: 'TIME',
      key: 'TIME',
      width: 60,
      render: (text) => moment(text, 'HH:mm').format('hh:mm a')
    },
    {
      title: 'INVOICE NO.',
      key: 'INVOICE_NO',
      width: 100,

      render: (record: ICustomSellReport) => {
        const value = record['INVOICE NO.'];
        return (
          <TableCell>
            <div className="flex flex-row justify-between items-center">
              {value}
              {value ? <CopyButton text={value} /> : null}
            </div>
          </TableCell>
        );
      }
    },
    {
      title: 'CUSTOMER',
      dataIndex: 'CUSTOMER',
      key: 'CUSTOMER',
      width: 120,
      sorter: (a, b) => a.CUSTOMER.localeCompare(b.CUSTOMER),
      sortOrder: sortedInfo.columnKey === 'CUSTOMER' ? sortedInfo.order : null
    },
    {
      title: 'ROUTE NO.',
      key: 'ROUTE_NO',
      width: 130,
      sorter: (a, b) => {
        const aValue = a['ROUTE NO.'] || '';
        const bValue = b['ROUTE NO.'] || '';
        return aValue.localeCompare(bValue);
      },
      sortOrder: sortedInfo.columnKey === 'ROUTE_NO' ? sortedInfo.order : null,
      render: (record: ICustomSellReport) => <TableCell>{record['ROUTE NO.']}</TableCell>
    },
    {
      title: 'GROSS',
      dataIndex: 'GROSS',
      key: 'GROSS',
      sorter: (a, b) => parseFloat(a.GROSS) - parseFloat(b.GROSS),
      sortOrder: sortedInfo.columnKey === 'GROSS' ? sortedInfo.order : null,
      width: 70,
      render: (data) => nepaliNumberFormatter(data)
    },
    {
      title: 'RETURN',
      dataIndex: 'RETURN',
      key: 'RETURN',
      sorter: (a, b) => parseFloat(a.RETURN) - parseFloat(b.RETURN),
      sortOrder: sortedInfo.columnKey === 'RETURN' ? sortedInfo.order : null,
      width: 70,
      render: (data) => nepaliNumberFormatter(data)
    },
    {
      title: 'DISCOUNT',
      dataIndex: 'DISCOUNT',
      key: 'DISCOUNT',
      width: 70,
      sorter: (a, b) => parseFloat(a.DISCOUNT) - parseFloat(b.DISCOUNT),
      sortOrder: sortedInfo.columnKey === 'DISCOUNT' ? sortedInfo.order : null,
      render: (data) => nepaliNumberFormatter(data)
    },
    {
      title: 'NET SALE',
      width: 70,
      key: 'NET_SALE',
      sorter: (a, b) => parseFloat(a['NET SALE']) - parseFloat(b['NET SALE']),
      sortOrder: sortedInfo.columnKey === 'NET_SALE' ? sortedInfo.order : null,
      render: (record: ICustomSellReport) => nepaliNumberFormatter(parseFloat(record['NET SALE']))
    },
    {
      title: 'VAT',
      dataIndex: 'VAT',
      key: 'VAT',
      width: 70,
      sorter: (a, b) => parseFloat(a.VAT) - parseFloat(b.VAT),
      sortOrder: sortedInfo.columnKey === 'VAT' ? sortedInfo.order : null,
      render: (data) => nepaliNumberFormatter(data)
    },
    {
      title: 'TOTAL',
      dataIndex: 'TOTAL',
      key: 'TOTAL',
      width: 70,
      sorter: (a, b) => parseFloat(a.TOTAL) - parseFloat(b.TOTAL),
      sortOrder: sortedInfo.columnKey === 'TOTAL' ? sortedInfo.order : null,
      render: (data) => nepaliNumberFormatter(data)
    },
    {
      title: 'TOTAL PAYMENT',
      dataIndex: 'TOTAL PAYMENT',
      key: 'TOTAL PAYMENT',
      width: 70,
      sorter: (a, b) => parseFloat(a['TOTAL PAYMENT']) - parseFloat(b['TOTAL PAYMENT']),
      sortOrder: sortedInfo.columnKey === 'TOTAL PAYMENT' ? sortedInfo.order : null,
      render: (data) => nepaliNumberFormatter(data)
    },
    {
      title: 'PAYMENT MODE',
      width: 110,
      key: 'PAYMENT_MODE',
      sorter: (a, b) => {
        const aValue = a['PAYMENT MODE'] || '';
        const bValue = b['PAYMENT MODE'] || '';
        return aValue.localeCompare(bValue);
      },
      sortOrder: sortedInfo.columnKey === 'PAYMENT_MODE' ? sortedInfo.order : null,
      render: (record: ICustomSellReport) => <TableCell>{record['PAYMENT MODE']}</TableCell>
    },
    {
      title: 'USER',
      dataIndex: 'USER',
      key: 'USER',
      sorter: (a, b) => a.USER.localeCompare(b.USER),
      sortOrder: sortedInfo.columnKey === 'USER' ? sortedInfo.order : null,
      width: 100
    }
  ];

  const updatedData = getUpdatedData(data.data);

  return (
    <div>
      <CustomizeTable
        columns={columns}
        data={data.data}
        notshowPagination={true}
        toSort={handleChange}
        customScroll={{ x: 1750, y: '75vh' }}
        tableSummary={
          <Table.Summary>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={6} className="text-center">
                Total
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                {nepaliNumberFormatter(total.gross)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                {nepaliNumberFormatter(total.return)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9}>
                {nepaliNumberFormatter(total.discount)}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={10}>
                {nepaliNumberFormatter(total.net_sale)}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={11}>{nepaliNumberFormatter(total.vat)}</Table.Summary.Cell>

              <Table.Summary.Cell index={12}>
                {nepaliNumberFormatter(total.total)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={13}>
                {nepaliNumberFormatter(total.pay_amount)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        }
        buttons={
          <>
            <ExportAllData
              title="Sell Report (All)"
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              getInfo={handlers.onSearchAll}
            />

            <ExportCurrent
              data={{ total: data.total, results: updatedData }}
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              title="Sell Report"
            />
          </>
        }
        paginationDatas={{
          page: pagination.page,
          total: data.total,
          size: pagination.size,
          onPagination: (pageNo, currentSize) => {
            currentSize == pagination.size
              ? handlers.onPagination(handlers.onSearch, pageNo)
              : handlers.onPagination(handlers.onSearch, pageNo, currentSize);
          }
        }}
      />
    </div>
  );
}

export default TableData;
