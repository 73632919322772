import { useState, useEffect, useCallback } from 'react';

function useDebounceFunction<T extends (...args: any[]) => any>(callback: T, debounceTime = 500) {
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  const debouncedFunction = useCallback(
    (...args: Parameters<T>): Promise<ReturnType<T>> => {
      return new Promise((resolve) => {
        if (timer) {
          clearTimeout(timer);
        }

        const newTimer = setTimeout(() => {
          const result = callback(...args);
          resolve(result);
          setTimer(undefined);
        }, debounceTime);

        setTimer(newTimer);
      });
    },
    [callback, debounceTime, timer]
  );

  // Cleanup timer on unmount
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  return debouncedFunction;
}

export default useDebounceFunction;
