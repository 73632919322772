import React, { useEffect } from 'react';

export interface IColumnDataForCustomTable {
  dataIndex: string;
  label: string;
  render: (x: any) => any;
  width?: number;
}

export interface IcustomTable {
  columns: IColumnDataForCustomTable[];
  datas: any[];
  reff?: any;
  onAuto?: () => void;
  title?: any;
  serialNumber?: boolean;
  total?: any;
  serialStyle?: string;
  isColumnCenter?: boolean;
}

const CustomTable: React.FC<IcustomTable> = ({
  columns,
  datas,
  reff,
  title,
  onAuto,
  serialStyle = '',
  serialNumber = true,
  total,
  isColumnCenter = true
}) => {
  // console.log('this is from custom table', datas);
  // console.log('this is from columns', columns);
  useEffect(() => {
    if (onAuto) {
      onAuto();
    }
  }, [datas]);

  return (
    <div ref={reff}>
      {title && <h3 className="text-center">{title}</h3>}
      <table width={'99%'}>
        <tr>
          {serialNumber && (
            <th style={tdthStyle} className={serialStyle}>
              <div className="text-center">S.N.</div>
            </th>
          )}
          {columns.map((currCol: any) => (
            <th key={currCol.label} style={tdthStyle}>
              <div className={isColumnCenter ? 'text-center' : 'text-left'}>{currCol.label}</div>
            </th>
          ))}
        </tr>
        {datas.map((currData: any, indr: number) => {
          return (
            <tr key={`${currData}${indr}`}>
              {serialNumber && (
                <td style={tdthStyle}>
                  <div className="text-center">{indr + 1}</div>
                </td>
              )}
              {columns.map((currCol: any, indc: number) => {
                return (
                  <td key={indr + indc} style={tdthStyle}>
                    {
                      // currData[currCol.dataIndex]
                      currCol.render(currData[currCol.dataIndex])
                    }
                  </td>
                );
              })}
            </tr>
          );
        })}
        {total && (
          <tr style={tdthStyle}>
            <td colSpan={1} style={tdthStyle} />
            <td colSpan={1} style={tdthStyle} />
            <td colSpan={1} style={tdthStyle}>
              <div className="text-center font-bold">Total</div>
            </td>
            <td colSpan={1} style={tdthStyle}>
              <div className="text-center font-bold">{total.totalDebit}</div>
            </td>
            <td colSpan={1} style={tdthStyle}>
              <div className="text-center font-bold">{total.totalCredit}</div>
            </td>
          </tr>
        )}
      </table>
    </div>
  );
};

const tdthStyle = {
  border: '1px solid black',
  padding: '2px 4px'
};

export default CustomTable;
