import AppContent from '../../../components/Common/Content/Content';
import { Content } from 'antd/lib/layout/layout';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useState } from 'react';
import Card from 'antd/lib/card/Card';
import {
  IMenuList,
  IMenuListServer,
  IUpdateMenuItemPosition
} from '../../../services/settings/types';
import { Button, Select, Spin, message } from 'antd';
import { DropBox } from '../../../components/Common/DropBox';
import {
  filterDragBox,
  getItemList,
  reArrangeArray,
  reorderList,
  searchSelected,
  sortForDragBox,
  updateForServer
} from '../../../services/settings/services';
import { get_menu_items } from '../../../services/settings/queries';
import { useMutation, useQuery } from '@tanstack/react-query';
import { update_menu_item_position } from '../../../services/settings/mutations';
import MenuDB from '../../../store/localstorage/MenuDB';
import { AxiosError, AxiosResponse } from 'axios';
import { IServerError } from '../../../services/response/types';
import { ColorPaletteNodes, MenuTypes } from '../../../services/settings/enums';
import CustomErrorModal from '../../../components/Common/CustomErrorModal';
import { DownOutlined } from '@ant-design/icons';

export const MenuSetup = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dragBoxContent, setDragBoxContent] = useState<IMenuList[]>([]);
  const [items, setItems] = useState<IMenuList[]>([]);
  const [type, setType] = useState('1');

  const onDragEnd = (result: DropResult) => {
    const currDragBox = dragBoxContent;
    if (!result.destination) {
      return;
    }

    if (result.type === 'column') {
      const columnOrder = reorderList(
        dragBoxContent,
        result.source.index,
        result.destination.index
      );

      setDragBoxContent((prev) => [...prev, ...columnOrder]);
      return;
    }

    // reordering in same list
    if (result.source.droppableId === result.destination.droppableId) {
      let items: IMenuList[] = [];
      if (result.source.droppableId === 'MainDroppable') {
        items = reorderList(dragBoxContent, result.source.index, result.destination.index);

        // updating column entry
        setDragBoxContent(items);
      } else {
        const selected = searchSelected(dragBoxContent, result.source.droppableId);
        items = reorderList(
          selected?.children ? selected.children : [],
          result.source.index,
          result.destination.index
        );

        // updating column entry
        setDragBoxContent((prev) => {
          return reArrangeArray(prev, result.source.droppableId, items);
        });

        return;
      }
    }
    // moving between list
    if (result.source && result.destination) {
      if (result.source.droppableId === 'ItemDroppable') {
        if (result.destination.droppableId === 'MainDroppable') {
          const item = items.find((val, index) => result.source.index === index);
          if (item) {
            if (!item.children) item.children = [];
            items.splice(result.source.index, 1);
            currDragBox.splice(result.destination.index, 0, {
              ...item,
              parentId: null
            });

            setDragBoxContent(currDragBox);
          }
        } else {
          const destinationColumn = searchSelected(currDragBox, result.destination?.droppableId);
          const item = items.find((val, index) => result.source.index === index);

          if (item && destinationColumn) {
            if (!item.children) item.children = [];
            items.splice(result.source.index, 1);
            destinationColumn?.children?.splice(result.destination.index, 0, item);
            const newDestinationColumn = { ...destinationColumn };
            setDragBoxContent((prev) => {
              if (newDestinationColumn.children) {
                newDestinationColumn.children = newDestinationColumn.children.map((val) => {
                  return { ...val, parentId: newDestinationColumn.id };
                });
                return reArrangeArray(prev, destinationColumn.key, newDestinationColumn.children);
              } else {
                return prev;
              }
            });
          }
        }
      } else {
        const sourceColumn = searchSelected(currDragBox, result.source.droppableId);
        const destinationColumn = searchSelected(currDragBox, result.destination.droppableId);
        const item = sourceColumn?.children?.find((val, index) => result.source.index === index);
        if (item && sourceColumn && destinationColumn) {
          const newSourceColumn = {
            ...sourceColumn,
            children: sourceColumn.children ? [...sourceColumn.children] : []
          };
          newSourceColumn?.children?.splice(result.source.index, 1);
          const newDestinationColumn = {
            ...destinationColumn,
            children: destinationColumn.children ? [...destinationColumn.children] : []
          };
          newDestinationColumn?.children?.splice(result.destination.index, 0, item);

          // updating column entry
          if (newSourceColumn && newDestinationColumn) {
            setDragBoxContent((prev) => {
              if (sourceColumn.children && destinationColumn.children) {
                sourceColumn.children = sourceColumn.children.map((val) => {
                  return { ...val, parentId: sourceColumn.id };
                });
                destinationColumn.children = destinationColumn.children.map((val) => {
                  return { ...val, parentId: destinationColumn.id };
                });
                const updatedSourceColumn = reArrangeArray(
                  prev,
                  sourceColumn.key,
                  newSourceColumn.children
                );
                const updatedDestinationColumn = reArrangeArray(
                  updatedSourceColumn,
                  destinationColumn.key,
                  newDestinationColumn.children
                );
                return updatedDestinationColumn;
              } else {
                return prev;
              }
            });
          }
        }
      }
    }
  };

  const { refetch } = useQuery(['menuItems'], async () => await fetchMenuItems());

  const fetchMenuItems = async () => {
    setIsLoading(true);
    const parentIds: Set<number> = new Set();
    const response = await get_menu_items();
    response.data.results.forEach((val) => {
      if (val.parentId) parentIds.add(val.parentId);
    });
    const cloneData = [...response.data.results];
    const newList = sortForDragBox(parentIds, cloneData, response.data.results);
    setDragBoxContent(newList);
    setItems([]);
    setIsLoading(false);
  };

  const ItemCardHeader = () => {
    return (
      <div className="flex">
        <span className="flex-1">Items</span>
        <Select
          size="small"
          dropdownMatchSelectWidth={false}
          value={type}
          onChange={(value) => setType(value)}>
          <Select.Option value={'1'}>
            <div className="flex items-center gap-2">
              <span className="flex-1">{'1st Node'}</span>{' '}
              <div
                style={{
                  height: '12px',
                  width: '12px',
                  backgroundColor: ColorPaletteNodes.One
                }}></div>
            </div>
          </Select.Option>
          <Select.Option value={'2'}>
            <div className="flex items-center gap-2">
              <span className="flex-1">{'2nd Node'}</span>{' '}
              <div
                style={{
                  height: '12px',
                  width: '12px',
                  backgroundColor: ColorPaletteNodes.Two
                }}></div>
            </div>
          </Select.Option>
          <Select.Option value={'3'}>
            <div className="flex items-center gap-2">
              <span className="flex-1">{'3rd Node'}</span>{' '}
              <div
                style={{
                  height: '12px',
                  width: '12px',
                  backgroundColor: ColorPaletteNodes.Three
                }}></div>
            </div>
          </Select.Option>
          <Select.Option value={'4'}>
            <div className="flex items-center gap-2">
              <span className="flex-1">{'4th Node'}</span>{' '}
              <div
                style={{
                  height: '12px',
                  width: '12px',
                  backgroundColor: ColorPaletteNodes.Four
                }}></div>
            </div>
          </Select.Option>
          <Select.Option value={'5'}>
            <div className="flex items-center gap-2">
              <span className="flex-1">{'5th Node'}</span>{' '}
              <div
                style={{
                  height: '12px',
                  width: '12px',
                  backgroundColor: ColorPaletteNodes.Five
                }}></div>
            </div>
          </Select.Option>
        </Select>
      </div>
    );
  };

  const handleReturnBack = (key: string) => {
    setItems((prev) => {
      const itemsToAdd = searchSelected(dragBoxContent, key);
      if (itemsToAdd) return [...prev, ...getItemList(itemsToAdd)];
      return prev;
    });
    setDragBoxContent((prev) => {
      return filterDragBox(prev, key);
    });
  };

  const onReset = async () => {
    setIsLoading(true);
    await refetch();
    setIsLoading(false);
  };

  const onDefault = async () => {
    setIsLoading(true);
    const parentIds: Set<number> = new Set();
    const response = await get_menu_items();
    const modifiedMenu = response.data.results.map((menu) => {
      if (menu.default) {
        return {
          ...menu,
          position: menu.default.position,
          parentId: menu.default.parentId
        };
      }
      return menu;
    });
    modifiedMenu.forEach((val) => {
      if (val.parentId) parentIds.add(val.parentId);
    });
    const cloneData = [...modifiedMenu];
    const newList = sortForDragBox(parentIds, cloneData, modifiedMenu);
    setDragBoxContent(newList);
    setItems([]);
    setIsLoading(false);
  };

  const onFinish = async () => {
    setIsLoading(true);
    if (items.length === 0) {
      const updatedDragBoxContent = updateForServer(dragBoxContent);
      const updatedItemsForServer = updatedDragBoxContent.flatMap((val) => {
        return getItemList(val);
      });
      await updateMenuListMutation.mutateAsync({ lines: updatedItemsForServer });
    } else {
      setIsLoading(false);
      CustomErrorModal({ title: 'Error', message: 'Items pending to be added to Menu Container!' });
    }
  };

  const updateMenuListMutation = useMutation<
    AxiosResponse<IMenuListServer>,
    AxiosError<IServerError>,
    IUpdateMenuItemPosition
  >(update_menu_item_position, {
    onSuccess: async () => {
      setIsLoading(false);
      message.success('Menu position updated successfully');
      await MenuDB.removeAllMenuItems();
      const response = await get_menu_items();
      await MenuDB.addMenuItem(response.data.results);
    },
    onError: (e) => {
      setIsLoading(false);
      message.error(`${e.response?.data.message}`);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={[{ label: 'Menu Setup' }]}>
        <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280
          }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="grid grid-cols-4 gap-3">
              <div className="col-span-2">
                <Card
                  bodyStyle={{
                    height: '65vh',
                    overflowY: 'auto',
                    padding: '10px',
                    backgroundColor: ColorPaletteNodes.One
                  }}
                  title={'Menu'}>
                  <DropBox
                    droppableId="MainDroppable"
                    type="1"
                    items={dragBoxContent}
                    handleReturnBack={handleReturnBack}
                  />
                </Card>
              </div>
              <div className="col-span-2">
                <Card
                  bodyStyle={{
                    height: '65vh',
                    overflowY: 'auto',
                    padding: '10px',
                    backgroundColor:
                      type === '1'
                        ? ColorPaletteNodes.One
                        : type === '2'
                        ? ColorPaletteNodes.Two
                        : type === '3'
                        ? ColorPaletteNodes.Three
                        : type === '4'
                        ? ColorPaletteNodes.Four
                        : ColorPaletteNodes.Five
                  }}
                  title={<ItemCardHeader />}>
                  <Droppable droppableId={'ItemDroppable'} type={type}>
                    {(provided) => (
                      <div ref={provided.innerRef}>
                        {items.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                            {({ draggableProps, dragHandleProps: eventHandlers, innerRef }) => (
                              <div ref={innerRef} {...draggableProps} {...eventHandlers}>
                                <Card
                                  style={{ marginBottom: '5px', borderRadius: '10px' }}
                                  bodyStyle={{
                                    padding: '5px',
                                    paddingLeft: '10px',
                                    paddingRight: '10px'
                                  }}>
                                  <div className="flex items-center">
                                    <span className="flex-1">
                                      {item.alias ? item.alias : item.name}
                                    </span>
                                    {item.type === MenuTypes.PARENT && <DownOutlined />}
                                  </div>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Card>
              </div>
            </div>
          </DragDropContext>
          <div className="flex justify-end mt-2 gap-2">
            <Button type="dashed" onClick={onDefault}>
              Default
            </Button>
            <Button type="ghost" onClick={onReset}>
              Reset
            </Button>
            <Button type="primary" onClick={onFinish}>
              Update
            </Button>
          </div>
        </Content>
      </AppContent>
    </Spin>
  );
};
