import moment from 'moment';
import { useState } from 'react';
import { Empty, Form, message, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import TableFilter from '@/components/FliterTable';
import { ConvertObjectToURL } from '@/utils/converturl';
import handlePagination from '@/utils/handlePagination';
import AppContent from '@/components/Common/Content/Content';
import {
  get_expense_category_id,
  get_expense_category_table_list
} from '@/services/expense/expense-category/queries';
import { useQuery } from '@tanstack/react-query';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { generateExpenseReportColumn } from './utils';
import { filterId } from '@/constants/filter.category';
import { ExpenseCategoryColumnItem } from '@/services/expense/expense-category/types';
import RenderFilterSelect from './RenderFilterSelect';
import ExportCurrent from '@/components/Common/ExportCurrent';
import ExportAllData from '@/components/Common/ExportAll';
import isStringUTCDate from '@/utils/isStringUTCDate';

function ExpenseReport() {
  const { id } = useParams();
  if (!id) {
    return <h1>Expense Category not found</h1>;
  }

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [fullExportURL, setFullExportURL] = useState('');

  const [list, setList] = useState<{ results: any[]; total: number }>({ results: [], total: 0 });
  const [columns, setColumns] = useState<any>([]);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });

  const [currentFilterIds, setCurrentFilterIds] = useState([] as string[]);

  const { data: category, isLoading: isCategoryLoading } = useQuery(
    ['expense-category', id],
    async () => {
      try {
        const response = await get_expense_category_id(parseInt(id));
        const fieldIds = filterId.map((val) => val.key) as string[];
        const parsedContent = (JSON.parse(response.content)?.column ||
          []) as ExpenseCategoryColumnItem[];

        if (parsedContent.length > 0) {
          const idsInExpenseCategory = parsedContent
            .map((val) => val.name)
            .filter((id) => fieldIds.includes(id));

          setCurrentFilterIds(idsInExpenseCategory);
          form.setFieldsValue(idsInExpenseCategory.map((id) => ({ name: id, value: '' })));
        }

        return response;
      } catch (error) {
        message.error('Failed to get expense category details');
      }
    }
  );

  const onSubmitFilter = async (filter: string) => {
    const info = await getInfo(filter);
    setPagination({ page: 1, size: 100 });
    if (!info) {
      setFullExportURL('');
      return;
    }

    const response = info.response;
    if (response && response.total > 0) {
      const params = new URLSearchParams(filter);
      params.set('count', response.total.toString());
      setFullExportURL(params.toString());
    } else {
      setFullExportURL('');
    }

    setColumns(info.columns);
  };

  const getInfo = async (filter = '') => {
    if (!id) return;
    try {
      setIsLoading(true);
      const response = await get_expense_category_table_list(+id, filter);

      setList(response);
      const columns =
        response.results.length === 0
          ? []
          : generateExpenseReportColumn(response.results[0]).map((val) => {
              return {
                ...val,
                label: val.title,
                width: val.title.toLowerCase() === 'id' ? 15 : 30,
                render: (value: any) => {
                  const updatedValue = value
                    ? isStringUTCDate(value)
                      ? moment(value).format('YYYY-MM-DD hh:mm a')
                      : value
                    : '';

                  return <TableCell>{updatedValue}</TableCell>;
                }
              };
            });

      return { response, columns };
    } catch (error: any) {
      const errorMessage =
        error.response?.data.message || 'Failed to get expense category table list';

      message.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  async function onSearchAll() {
    try {
      setIsLoading(true);
      if (!fullExportURL || !id) {
        message.error('No URL specified. Please search first.');
        return;
      }

      const response = await get_expense_category_table_list(+id, fullExportURL);
      return response.results as any;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const excelExportColumns = columns.map((val: any) => ({
    title: val.title,
    dataIndex: val.dataIndex
  }));

  return (
    <Spin spinning={isLoading || isCategoryLoading}>
      <AppContent
        breadcrumbItems={[
          { label: 'Expense Category', link: '/expense-category/' },
          { label: 'Report: ' + category?.name }
        ]}
        withfilter
        button={
          <TableFilter
            initial
            form={form}
            onSubmit={onSubmitFilter}
            defaultValues={{
              dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
              skip: 0,
              count: 100
            }}
            styleforbuttons={'flex justify-end items-center'}
            style={'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-6 gap-2'}>
            {currentFilterIds.map((id) => (
              <RenderFilterSelect
                key={id}
                id={id}
                onSelect={(value) => form.setFieldsValue({ [id]: value })}
              />
            ))}
          </TableFilter>
        }>
        <div>
          <CustomizeTable
            tableName={`Expense Category Report: ${category?.name}`}
            columns={columns}
            data={list.results}
            notshowPagination={true}
            customScroll={{ x: columns.length * 30 * 4, y: '75vh' }}
            paginationDatas={{
              page: pagination.page,
              total: list.total,
              size: pagination.size,
              onPagination
            }}
            buttons={
              <>
                <ExportAllData
                  title="Expense Category Report (All)"
                  columns={columns as any}
                  excelColumns={excelExportColumns}
                  getInfo={onSearchAll}
                />
                <ExportCurrent
                  data={list}
                  columns={columns as any}
                  excelColumns={excelExportColumns}
                  title="Expense Category Report"
                />
              </>
            }
          />
        </div>
      </AppContent>
    </Spin>
  );
}

export default ExpenseReport;
