import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
  Spin,
  message
} from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ILotDetails, IProductType, IProductUnits, IUnits } from '@/services/products/types';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getUserData } from '@/utils/auth.utils';
import { AllLatestProductLines, Line } from '@/services/transfer/types';
import AppContent from '@/components/Common/Content/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { TransactionType } from '@/services/products/enums';
import ReusableQuantity from '@/components/Common/ReusableQuantity';
import ProductSearchForLines from '@/components/Common/ProductSearch2';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import ProductsDB from '@/store/localstorage/ProductsDB';
import {
  get_unexpired_lots_details_bylocationId_productId,
  get_product_list_ids,
  get_units_list,
  get_unexpired_lots_details_bylocationId_productIds
} from '@/services/products/queries';
import { calculateVat } from '@/utils/vatCalculate.utils';
import CustomInfoModal from '@/components/Common/CustomInfoModal';
import { AccountRulesEvent } from '@/services/accounts/enums';
import { numberDecimalFormatter } from '@/utils/numberFormatter';
import { find_locationId_preference } from '@/store/localstorage/preferences';
import { get_unit_price_prefill } from '@/services/products/services';
import { ILot } from '@/services/purchases/types';
import { checkHasAccountRule } from '@/services/accounts/services';
import {
  create_transfer_mutation,
  create_transfer_order_mutation
} from '@/services/transfer/mutations';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import { ILines } from '@/services/sell/types';
import useDebounce from '@/hooks/useDebounce';
import { WebSocketContext } from '@/contexts/websocket.context';
import DebounceButton from '@/components/Common/DebounceButton';

const { Option } = Select;
export const TransferOrderCreate = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { socket } = useContext(WebSocketContext);
  const [allLocalUnits, setAllLocalUnits] = useState<IUnits[]>([]);
  const [totalLots, settotalLots] = useState<ILotDetails[]>([]);
  useQuery(['units'], async () => {
    // const response = await get_product_list();
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
    // await get_units_list();
    // return response;
  });
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [hasRule, setHasRule] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [productSearch, setProductSearch] = useState<any[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  // This is needed to get the product details update as its written
  const [productDetails, setProductDetails] = useState<any>();
  const autofocusRef: React.Ref<any> = useRef(null);
  const autofocusRefQuantity: React.Ref<any> = useRef(null);
  const [defaultPriceGroup, setdefaultPriceGroup] = useState<any>([]);
  const loggedInUser = getUserData();
  const [latestProductLines, setLatestProductLines] = useState<any>({});
  const [latestProductLinesAll, setLatestProductLinesAll] = useState<AllLatestProductLines[]>([]);
  const [autoFocus, setAutoFocus] = useState(1);
  const debouncedAutoFocusValue = useDebounce(autoFocus, 300);
  const breadcrumbItems = [
    {
      label: 'Transfer Order',
      link: '/transfer/order'
    },
    {
      label: 'Create',
      link: '/transfer/order/new'
    }
  ];

  useEffect(() => {
    if (autofocusRefQuantity.current) {
      autofocusRefQuantity.current.focus();
    }
  }, [debouncedAutoFocusValue]);

  useEffect(() => {
    form.setFieldsValue({ location: null, lines: [], reason: null });
    initializeUnits();
  }, []);
  const initializeUnits = async () => {
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
  };

  useEffect(() => {
    socket?.on('connect', async () => {
      // console.log('Socket Reconnected');
      const locationId = form.getFieldValue(['from']);
      const lines = form.getFieldValue(['lines']);
      const productIds = new Set<number>(
        lines.map((value: ILines) => {
          return value.productId;
        })
      );
      await fetchLotsOnLocationandProductChange([...productIds], locationId, 'lotsupdate');
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_ZERO) {
        const locationId = form.getFieldValue(['from']);
        const socketData = data.data as { locationId: number };
        if (socketData.locationId === locationId) {
          settotalLots((prev) => {
            return prev.map((a) => ({ ...a, qtyAvailable: 0 }));
          });
        }
      }

      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        const lines = form.getFieldValue(['lines']);
        const productIds = new Set<number>(
          lines.map((value: ILines) => {
            return value.productId;
          })
        );

        const locationId = form.getFieldValue(['from']);

        let updatedProducts = data.data as { productId: number; locationId: number }[];
        if (locationId) {
          updatedProducts = updatedProducts.filter(
            (value) =>
              value.locationId === locationId && Array.from(productIds).includes(value.productId)
          );
        }

        if (updatedProducts.length > 0) {
          const updatedProductIds = updatedProducts.map((value) => value.productId);
          await fetchLotsOnLocationandProductChange(updatedProductIds, locationId, 'lotsupdate');
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected]);

  const createTransferOrderMutation = useMutation(create_transfer_order_mutation);

  const onFinish = async (values: any) => {
    // console.log('Valuess-->', values);
    try {
      setIsloading(true);
      values.checklist = JSON.stringify({
        bags: values.bags,
        crate: values.crate,
        sack: values.sack,
        others: values.others
      });
      values.archived = false;
      delete values.bags;
      delete values.crate;
      delete values.sack;
      delete values.others;
      values.date = JSON.stringify(new Date()).slice(1, 11);
      values.createdBy = loggedInUser.id;
      await createTransferOrderMutation.mutateAsync(values, {
        onSuccess: async ({ data }: { data: any }) => {
          if (data) {
            message.success('Transfer order created successfully');
            setIsloading(false);
            navigate('/transfer/order');
          }
        },
        onError: (e: any) => {
          setIsloading(false);
          message.error(`${e.response.data.message}`, 5);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLotsOnLocationandProductChange = async (
    productsIdArray: number[],
    locationId: number,
    from: string
  ) => {
    try {
      if (!locationId) {
        throw {
          name: 'Location Error',
          message: 'Please select Location!'
        };
      }

      if (productsIdArray.length === 0) return;
      if (from === 'productchange') {
        const filterLots = totalLots.find((value: ILot) => value.productId == productsIdArray[0]);
        if (!filterLots) {
          const response = await get_unexpired_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[0]
          );
          settotalLots([...totalLots, ...response]);
          return [...totalLots, ...response];
        }
        return totalLots;
      } else if (from === 'lotsupdate') {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);

        settotalLots((prev: ILotDetails[]) => {
          const filterLots = prev.filter((value) => !productsIdArray.includes(value.productId));
          return [...filterLots, ...result];
        });
      } else {
        setIsloading(true);
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);

        settotalLots([...result]);

        // latest price change
        const lines = form.getFieldValue(['lines']);
        if (lines !== undefined) {
          if (lines.length > 0) {
            const latestTransferLinesData = await Promise.all(
              productsIdArray.map(async (val) => {
                const response = await get_unit_price_prefill(val, locationId, [
                  TransactionType.PURCHASE,
                  TransactionType.TRANSFER_IN,
                  TransactionType.OPENING_STOCK
                ]);
                if (response) return response;
              })
            );
            for (let ind = 0; ind < lines.length; ind++) {
              form.setFieldValue(['lines', ind, 'vat'], 0);
              form.setFieldValue(['lines', ind, 'unitPrice'], 0);
              form.setFieldValue(['lines', ind, 'transactionType'], null);
            }
            if (latestTransferLinesData) {
              await Promise.all(
                latestTransferLinesData.map(async (latestTransferLinesAll) => {
                  if (latestTransferLinesAll) {
                    await Promise.all(
                      latestTransferLinesAll.all.map(async (latestTransferLine) => {
                        for (let ind = 0; ind < lines.length; ind++) {
                          form.setFieldValue(['lines', ind, 'vat'], 0);
                          form.setFieldValue(['lines', ind, 'unitPrice'], 0);
                          form.setFieldValue(['lines', ind, 'transactionType'], null);
                          const selectedUnitId = lines[ind].unitId;
                          const selectedProductId = lines[ind].productId;
                          let unitInfo = await UnitsDB.getUnit(latestTransferLine.unitId);
                          if (!unitInfo) {
                            const allUnits = await get_units_list();
                            UnitsDB.addUnits(allUnits);
                            unitInfo = await UnitsDB.getUnit(latestTransferLine.unitId);
                          }
                          let productInfo = await ProductsDB.getProduct(
                            latestTransferLine.productId
                          );
                          if (!productInfo) {
                            const allProducts = await get_product_list_ids([
                              latestTransferLine.productId
                            ]);
                            await ProductsDB.addProducts(allProducts.data.results);
                            productInfo = await ProductsDB.getProduct(latestTransferLine.productId);
                          }

                          let baseUnitPrice;
                          if (typeof unitInfo === 'object') {
                            baseUnitPrice =
                              latestTransferLine.unitPrice / unitInfo.baseUnitMultiplier;
                          } else {
                            throw {
                              name: 'UnitError',
                              message: 'Something went wrong. Please try again.'
                            };
                          }

                          const decimalBaseUnitPrice = baseUnitPrice;
                          let unitSelected = await UnitsDB.getUnit(selectedUnitId);
                          if (!unitSelected) {
                            const allUnits = await get_units_list();
                            UnitsDB.addUnits(allUnits);
                            unitSelected = await UnitsDB.getUnit(selectedUnitId);
                          }
                          let unitPrice;
                          if (typeof unitSelected === 'object') {
                            unitPrice = baseUnitPrice * unitSelected.baseUnitMultiplier;
                          } else {
                            throw {
                              name: 'UnitError',
                              message: 'Something went wrong. Please try again.'
                            };
                          }
                          let vat = 0;
                          if (typeof productInfo === 'object') {
                            if (productInfo.vat) {
                              vat = calculateVat(unitPrice, productInfo.vat);
                              if (
                                latestTransferLine.productId === selectedProductId &&
                                latestTransferLine.locationId === locationId
                              )
                                form.setFieldValue(['lines', ind, 'vat'], vat);
                            }
                          } else {
                            throw {
                              name: 'UnitError',
                              message: 'Something went wrong. Please try again.'
                            };
                          }

                          setLatestProductLines((prev: any) => ({
                            ...prev,
                            [selectedProductId]: {
                              ...latestTransferLine,
                              decimalBaseUnitPrice,
                              vat
                            }
                          }));
                          setLatestProductLinesAll((prev) => [
                            ...prev,
                            {
                              productId: selectedProductId,
                              locationId: locationId,
                              transferLine: { ...latestTransferLine, decimalBaseUnitPrice, vat }
                            }
                          ]);
                          if (
                            latestTransferLine.productId === selectedProductId &&
                            latestTransferLine.locationId === locationId
                          ) {
                            form.setFieldValue(['lines', ind, 'unitPrice'], unitPrice);
                            form.setFieldValue(
                              ['lines', ind, 'transactionType'],
                              latestTransferLine.identifier
                            );
                          }
                          onUnitandQuantityChange(ind);
                        }
                      })
                    );
                  }
                })
              );
            }
            for (let ind = 0; ind < lines.length; ind++) {
              onUnitandQuantityChange(ind);
            }
          }
        }
        setIsloading(false);
      }
    } catch (err: any) {
      message.error(err.message);
    }
  };

  const checkAccountRule = async (locationId: number, location: string) => {
    if (
      await checkHasAccountRule(
        locationId,
        location === 'from' ? AccountRulesEvent.TRANSFER_FROM : AccountRulesEvent.TRANSFER_TO
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onLocationChange = async (value: number) => {
    const from = form.getFieldValue(['from']);
    const to = form.getFieldValue(['to']);
    if (
      (from ? await checkAccountRule(from, 'from') : true) &&
      (to ? await checkAccountRule(to, 'to') : true)
    ) {
      setHasRule(true);
    } else {
      setHasRule(false);
      CustomInfoModal({
        title: 'Info',
        message: `"${AccountRulesEvent.TRANSFER_FROM}" or "${AccountRulesEvent.TRANSFER_TO}" rule has not been created!`
      });
    }
    let lines = form.getFieldValue('lines');
    lines = lines.map((linesValue: any) => {
      return { ...linesValue, lotId: undefined };
    });
    form.setFieldValue('lines', lines);
    if (from === to) {
      message.warn('From and To cannot be same!');
      form.setFieldValue('to', undefined);
    }
    //to fetch new lots
    const mySet = new Set<number>();
    const data = form.getFieldValue(['lines']);
    if (data) {
      data.map((curr: any) => {
        mySet.add(curr.productId);
      });
    }
    fetchLotsOnLocationandProductChange(Array.from(mySet), from, 'location');
  };
  const FilterUnits = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    if (checkCurrentProduct && productList) {
      const selectedProduct = productList.find(
        (val: IProductType) => val.id == checkCurrentProduct
      );

      const filteredUnits = selectedProduct.productUnits.map((value: IProductUnits) => {
        const data = allLocalUnits.find((val: IUnits) => value.unitId == val.id);
        return data;
      });
      return (
        <>
          {filteredUnits.map((value: any) => {
            if (!value) return null;
            return (
              <Option value={value.id} key={value.id}>
                {`${value.name}`}
              </Option>
            );
          })}
        </>
      );
    }
  };

  function onLotChange(name: number, value: number) {
    const productId = form.getFieldValue(['lines', name, 'productId']);
    if (!productId) {
      return message.error('Failed to get product id');
    }

    let filteredLots: any = [];
    if (totalLots.length !== 0) {
      filteredLots = totalLots.filter((currLot: any) => currLot.productId === productId);
    }

    const currentSelectedLot = filteredLots.find((currLot: any) => currLot.id === value);
    if (!currentSelectedLot) {
      return message.error('Failed to assign hs code based on selected lot');
    }

    form.setFieldValue(['lines', name, 'hsCode'], currentSelectedLot?.hsCode);
  }

  const FilterLot = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);

    const currentLocation = form.getFieldValue(['from']);

    if (checkCurrentProduct && currentLocation) {
      // const filteredLots = allLots.filter((value: Line) => value.productId == checkCurrentProduct);
      let filteredLots: any = [];
      // console.log('totalLots-->', totalLots);
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter(
          (currLot: any) => currLot.productId === checkCurrentProduct
        );
        // console.log('filtered Lots-->', filteredLots);
      }
      const unitId = form.getFieldValue(['lines', name, 'unitId']);
      const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
      return (
        <>
          {filteredLots?.map((value: any) => (
            <Option
              value={value.id}
              key={value.id}
              style={{ color: value.qtyAvailable > 0 ? 'green' : 'red' }}>
              {`(${numberDecimalFormatter(
                value.qtyAvailable / (unitInfo?.baseUnitMultiplier || 1)
              )} ${unitInfo?.shortName || ''}) ${value.lotNumber} Grade-${value.grade} Expiry-${
                value?.expirationDate ? new Date(value.expirationDate).toLocaleDateString() : 'N/A'
              }`}
            </Option>
          ))}
        </>
      );
    }
  };
  const checkLotQuantity = (index: number): { quantity: number; shortName: string } | null => {
    const row = form.getFieldValue(['lines', index]);

    const unitInfo: any = allLocalUnits.find((val: any) => row.unitId == val.id);
    if (row.lotId) {
      const filteredLot: any = totalLots.find((value: any) => value.id == row.lotId);
      return {
        quantity: filteredLot.qtyAvailable / unitInfo.baseUnitMultiplier,
        shortName: unitInfo.shortName
      };
    }
    return null;
  };

  const onProductChange = async (value: number, name?: number) => {
    // form.setFieldValue([name, 'productId'], value);
    // await checkAndGetLot(value);

    setIsloading(true);
    try {
      const data = form.getFieldValue(['lines']);
      const currentLocation = form.getFieldValue(['from']);
      const lots = await fetchLotsOnLocationandProductChange(
        [value],
        currentLocation,
        'productchange'
      );
      const formData: any = {
        hsCode: null,
        lotId: null,
        unitId: null,
        vat: null,
        unitPrice: null,
        transactionType: null
      };
      console.log('111');
      // console.log('totalLots-->', lots);
      if (lots && lots.length !== 0) {
        const filteredLots = lots
          .filter((val: any) => val.productId == value)
          .sort((a: ILot, b: ILot) => {
            return a.grade.localeCompare(b.grade) || b.qtyAvailable - b.qtyAvailable;
          });
        if (filteredLots.length !== 0) {
          formData.lotId = filteredLots[0].id;
          formData.hsCode = filteredLots[0]?.hsCode;

          // form.setFieldValue(['lines', data.length - 1, 'lotId'], filteredLots[0].id);
        }
      }
      let selectedProduct: any;
      if (productList) {
        selectedProduct = productList.find((val: any) => val.id == value);
        // const selectedProduct = productList.data.results.find((val: IProductType) => val.id == value);
        const unitIdList: IProductUnits[] = selectedProduct.productUnits;
        const defaultUnit = unitIdList.find((currUnit: any) => currUnit.isDefault === true);
        formData.unitId = defaultUnit?.unitId;
        // form.setFieldValue(['lines', data.length - 1, 'unitId'], defaultUnit?.unitId);
        let fetch = false;
        for (const value of unitIdList) {
          let unitData;
          try {
            unitData = await UnitsDB.getUnit(value.unitId);
          } catch (e) {
            console.log(e);
          }
          if (!unitData) {
            const response = await get_units_list();
            await UnitsDB.addUnits(response);
            fetch = true;
          }
        }
        if (fetch) setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
      }
      const selectedUnitId = formData.unitId;
      const currentLocationId = form.getFieldValue(['from']);
      if (!currentLocationId) {
        message.error('Please select locationId.');
        return;
      }
      // console.log('latestProductLines', latestProductLines[value].locationId);
      if (
        !latestProductLines[value] ||
        latestProductLines[value].locationId !== currentLocationId
      ) {
        const latesttransferlineData = await get_unit_price_prefill(value, currentLocationId, [
          TransactionType.PURCHASE,
          TransactionType.TRANSFER_IN,
          TransactionType.OPENING_STOCK
        ]);
        const latesttransferline = latesttransferlineData.filtered;
        const latesttransferlineAll = latesttransferlineData.all;

        formData.vat = 0;
        // form.setFieldValue(['lines', data.length - 1, 'vat'], 0);
        if (latesttransferlineAll) {
          for (let ind = 0; ind < latesttransferlineAll.length; ind++) {
            let unitInfo = await UnitsDB.getUnit(latesttransferlineAll[ind].unitId);
            if (!unitInfo) {
              const allUnits = await get_units_list();
              await UnitsDB.addUnits(allUnits);
              unitInfo = await UnitsDB.getUnit(latesttransferlineAll[ind].unitId);
            }
            let productInfo = await ProductsDB.getProduct(latesttransferlineAll[ind].productId);
            if (!productInfo) {
              const allProducts = await get_product_list_ids([
                latesttransferlineAll[ind].productId
              ]);
              await ProductsDB.addProducts(allProducts.data.results);
              productInfo = await ProductsDB.getProduct(latesttransferlineAll[ind].productId);
            }
            let baseUnitPrice;
            if (typeof unitInfo === 'object') {
              baseUnitPrice = latesttransferlineAll[ind].unitPrice / unitInfo.baseUnitMultiplier;
            } else {
              throw {
                name: 'UnitError',
                message: 'Something went wrong. Please try again.'
              };
            }
            const decimalBaseUnitPrice = baseUnitPrice;
            let unitSelected = await UnitsDB.getUnit(selectedUnitId);
            if (!unitSelected) {
              const allUnits = await get_units_list();
              await UnitsDB.addUnits(allUnits);
              unitSelected = await UnitsDB.getUnit(selectedUnitId);
            }
            let unitPrice;
            if (typeof unitSelected === 'object') {
              unitPrice = baseUnitPrice * unitSelected.baseUnitMultiplier;
            } else {
              throw {
                name: 'UnitError',
                message: 'Something went wrong. Please try again.'
              };
            }
            let vat = 0;
            if (typeof productInfo === 'object') {
              if (productInfo.vat) {
                vat = calculateVat(unitPrice, productInfo.vat);
                if (latesttransferlineAll[ind].id === latesttransferline?.id) formData.vat = vat;
                // form.setFieldValue(['lines', data.length - 1, 'vat'], vat);
              }
            } else {
              throw {
                name: 'UnitError',
                message: 'Something went wrong. Please try again.'
              };
            }
            setLatestProductLines((prev: any) => ({
              ...prev,
              [value]: { ...latesttransferlineAll[ind], decimalBaseUnitPrice, vat }
            }));
            setLatestProductLinesAll((prev) => [
              ...prev,
              {
                productId: value,
                locationId: currentLocationId,
                transferLine: { ...latesttransferlineAll[ind], decimalBaseUnitPrice, vat }
              }
            ]);

            if (latesttransferlineAll[ind].id === latesttransferline?.id) {
              formData.unitPrice = unitPrice;
              formData.transactionType = latesttransferlineAll[ind].identifier;
              // form.setFieldValue(['lines', data.length - 1, 'unitPrice'], unitPrice);
              // form.setFieldValue(
              //   ['lines', data.length - 1, 'transactionType'],
              //   latesttransferlineAll[ind].identifier
              // );
            }
          }
        }
      } else {
        let unitSelected = await UnitsDB.getUnit(selectedUnitId);
        if (!unitSelected) {
          const allUnits = await get_units_list();
          UnitsDB.addUnits(allUnits);
          unitSelected = await UnitsDB.getUnit(selectedUnitId);
        }

        const unitPrice =
          typeof unitSelected === 'object'
            ? latestProductLines[value].decimalBaseUnitPrice * unitSelected.baseUnitMultiplier
            : latestProductLines[value].decimalBaseUnitPrice * 1;

        formData.unitPrice = unitPrice;

        // form.setFieldValue(['lines', data.length - 1, 'unitPrice'], unitPrice);
        let productInfo = await ProductsDB.getProduct(selectedProduct.id);
        if (!productInfo) {
          const allProducts = await get_product_list_ids([selectedProduct.id]);
          await ProductsDB.addProducts(allProducts.data.results);
          productInfo = await ProductsDB.getProduct(selectedProduct.id);
        }
        if (typeof productInfo === 'object') {
          if (productInfo.vat) {
            const vat = calculateVat(unitPrice, productInfo.vat);
            formData.vat = vat;

            // form.setFieldValue(['lines', data.length - 1, 'vat'], vat);
          } else {
            formData.vat = 0;

            // form.setFieldValue(['lines', data.length - 1, 'vat'], 0);
          }
        }
        formData.unitId = selectedUnitId;
        formData.transactionType = latestProductLines[value].identifier;

        // form.setFieldValue(['lines', data.length - 1, 'unitId'], selectedUnitId);
        // form.setFieldValue(
        //   ['lines', data.length - 1, 'transactionType'],
        //   latestProductLines[value].identifier
        // );
      }
      const formLine = form.getFieldValue(['lines', data.length - 1]);
      for (const [key, value] of Object.entries(formData)) {
        if (value !== null) {
          formLine[key] = value;
        }
      }

      form.setFieldValue(['lines', data.length - 1], formLine);

      setIsloading(false);
    } catch (err: any) {
      setIsloading(false);
      message.error(err.message);
    } finally {
      console.log('set autofocus');
      if (debouncedAutoFocusValue === 1) {
        setAutoFocus(2);
      } else {
        setAutoFocus(1);
      }
    }
  };

  const preferenceLocationId = find_locationId_preference();
  useEffect(() => {
    form.setFieldsValue({
      from: preferenceLocationId ? preferenceLocationId : null,
      to: null,
      lines: []
    });
    if (preferenceLocationId) {
      onLocationChange(preferenceLocationId);
    }
  }, []);

  const onUnitChange = async (name: number) => {
    const selectedUnitId = form.getFieldValue(['lines', name, 'unitId']);
    const selectedProductId = form.getFieldValue(['lines', name, 'productId']);

    if (latestProductLines[selectedProductId]) {
      let unitInfo: any = await UnitsDB.getUnit(selectedUnitId);
      if (!unitInfo) {
        const allUnits = await get_units_list();
        UnitsDB.addUnits(allUnits);
        unitInfo = UnitsDB.getUnit(selectedUnitId);
      }

      const unitPrice =
        latestProductLines[selectedProductId].decimalBaseUnitPrice * unitInfo.baseUnitMultiplier;

      form.setFieldValue(['lines', name, 'unitPrice'], unitPrice);
      let productInfo = await ProductsDB.getProduct(selectedProductId);
      if (!productInfo) {
        const allProducts = await get_product_list_ids([selectedProductId]);
        await ProductsDB.addProducts(allProducts.data.results);
        productInfo = await ProductsDB.getProduct(selectedProductId);
      }
      if (typeof productInfo === 'object') {
        if (productInfo.vat) {
          form.setFieldValue(
            ['lines', name, 'vat'],
            numberDecimalFormatter(calculateVat(unitPrice, productInfo.vat))
          );
        } else {
          form.setFieldValue(['lines', name, 'vat'], 0);
        }
      } else {
        message.error('Something went wrong!');
      }

      onUnitandQuantityChange(name);
    }
  };

  const onUnitandQuantityChange = async (name: number) => {
    // console.log('name', name);
    // console.log('number', value);
    const rate = form.getFieldValue(['lines', name, 'unitPrice']);
    const qty = form.getFieldValue(['lines', name, 'quantity']);
    const mis = form.getFieldValue(['lines', name, 'misc']);
    const dis = form.getFieldValue(['lines', name, 'discount']);
    const total = rate * qty - dis + mis;
    const selectedProductId = form.getFieldValue(['lines', name, 'productId']);
    let productInfo = await ProductsDB.getProduct(selectedProductId);
    if (!productInfo) {
      const allProducts = await get_product_list_ids([selectedProductId]);
      await ProductsDB.addProducts(allProducts.data.results);
      productInfo = await ProductsDB.getProduct(selectedProductId);
    }
    let vat = 0;
    if (typeof productInfo === 'object') {
      if (productInfo.vat) {
        vat = calculateVat(total, productInfo.vat);
        form.setFieldValue(['lines', name, 'vat'], numberDecimalFormatter(vat));
      } else {
        form.setFieldValue(['lines', name, 'vat'], 0);
      }
    }

    form.setFieldValue(['lines', name, 'total'], numberDecimalFormatter(total));

    const lines = form.getFieldValue(['lines']);
    // console.log('lines', lines);
    let grandTotal = 0;
    for (let i = 0; i < lines.length; i++) {
      grandTotal += lines[i].total + lines[i].vat;
    }

    form.setFieldValue(['grandtotal'], numberDecimalFormatter(grandTotal));
  };

  const onSelectt = (val: any) => {
    form.setFieldValue('from', val);
    onLocationChange(val);
  };

  const onSelect2 = async (val: any) => {
    form.setFieldValue('to', val);
    const from = form.getFieldValue(['from']);
    const to = form.getFieldValue(['to']);

    if (
      (from ? await checkAccountRule(from, 'from') : true) &&
      (to ? await checkAccountRule(to, 'to') : true)
    ) {
      setHasRule(true);
    } else {
      setHasRule(false);
      CustomInfoModal({
        title: 'Info',
        message: `"${AccountRulesEvent.TRANSFER_FROM}" or "${AccountRulesEvent.TRANSFER_TO}" rule has not been created!`
      });
    }
    let lines = form.getFieldValue('lines');
    lines = lines.map((linesValue: any) => {
      return { ...linesValue, lotId: undefined };
    });
    form.setFieldValue('lines', lines);
    if (from === to) {
      message.warn('From and To cannot be same!');
      form.setFieldValue('to', undefined);
    }
  };

  const onTracheableChange = (name: number, value: any) => {
    form.setFieldValue(['lines', name, 'isTraceable'], value);
  };

  const isProductAlreadyAdded = (index: number, checkproductId: number) => {
    const lines = form.getFieldValue(['lines']);
    const findOne = lines?.find((curr: any) => curr.productId == checkproductId);
    if (findOne) {
      return false;
    }
    return true;
  };

  const handleTransactionTypeSelected = async (val: TransactionType, name: number) => {
    const currentLocation = form.getFieldValue(['from']);
    const currentProduct = form.getFieldValue(['lines', name, 'productId']);
    const selectedLatestProductLine = latestProductLinesAll.find((line) => {
      return (
        line.transferLine.identifier === val &&
        line.locationId === currentLocation &&
        line.productId === currentProduct
      );
    });
    if (selectedLatestProductLine) {
      const selectedUnitId = form.getFieldValue(['lines', name, 'unitId']);
      let unitSelected = await UnitsDB.getUnit(selectedUnitId);
      if (!unitSelected) {
        const allUnits = await get_units_list();
        UnitsDB.addUnits(allUnits);
        unitSelected = await UnitsDB.getUnit(selectedUnitId);
      }
      const unitPrice =
        typeof unitSelected === 'object'
          ? selectedLatestProductLine.transferLine.decimalBaseUnitPrice *
            unitSelected.baseUnitMultiplier
          : selectedLatestProductLine.transferLine.decimalBaseUnitPrice * 1;

      form.setFieldValue(['lines', name, 'unitPrice'], unitPrice);

      let productInfo = await ProductsDB.getProduct(currentProduct);
      if (!productInfo) {
        const allProducts = await get_product_list_ids([currentProduct]);
        await ProductsDB.addProducts(allProducts.data.results);
        productInfo = await ProductsDB.getProduct(currentProduct);
      }
      if (typeof productInfo === 'object') {
        if (productInfo.vat) {
          const vat = calculateVat(unitPrice, productInfo.vat);
          form.setFieldValue(['lines', name, 'vat'], vat);
        } else {
          form.setFieldValue(['lines', name, 'vat'], 0);
        }
      }

      form.setFieldValue(['lines', name, 'unitId'], selectedUnitId);
    } else {
      form.setFieldValue(['lines', name, 'unitPrice'], 0);
      form.setFieldValue(['lines', name, 'vat'], 0);
      CustomErrorModal({
        title: 'Error',
        message: (
          <>
            <span>Selected Transaction has been stale for the last 48 hrs.</span>{' '}
            <b>Setting default price to 0.</b>
          </>
        )
      });
    }
    onUnitandQuantityChange(name);
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Transfer Information"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          disabled={isLoading}
          validateTrigger={'onChange'}
          onValuesChange={(_, allFields) => {
            setProductDetails(allFields);
          }}
          autoComplete="off">
          <PageHeader
            subTitle="Location"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className="grid grid-cols-2 gap-5 mb-5">
            <LocationSearch
              formData={{ formName: 'from', formLabel: 'From' }}
              onSelect={(val) => onSelectt(val)}
              notAll={true}
              required={true}
              transferUnit={false}
            />
            <LocationSearch
              formData={{ formName: 'to', formLabel: 'To' }}
              onSelect={async (val) => await onSelect2(val)}
              notAll={true}
              required={true}
              transferUnit={true}
            />
          </div>
          <Form.List name={['lines']}>
            {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
              <>
                <PageHeader
                  subTitle="Add Product"
                  style={{
                    padding: '8px 0px 8px 10px'
                  }}
                />
                <Form.Item>
                  <ProductSearchForLines
                    add2={add2}
                    onProductChange={onProductChange}
                    productList={productList}
                    setProductList={setProductList}
                    productSearch={productSearch}
                    setProductSearch={setProductSearch}
                    autofocusRef={autofocusRef}
                    isProductAlreadyAdded={isProductAlreadyAdded}
                    index={0}
                    locationId={form.getFieldValue(['from'])}
                  />
                </Form.Item>
                {fields2.length > 0 && (
                  <PageHeader
                    title="All Products"
                    style={{
                      padding: '8px 0px 8px 10px'
                    }}
                  />
                )}
                <Card
                  style={{
                    maxHeight: '50vh',
                    overflowY: 'scroll',
                    // backgroundColor: 'gray',
                    borderRadius: '9px'
                  }}>
                  {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                    <div className="flex gap-1 items-center" key={key2}>
                      <span className="font-bold text-sm mb-5">{name2 + 1}.</span>
                      <div className="card">
                        <PageHeader
                          subTitle="Products"
                          style={{
                            padding: '8px 0px 8px 10px'
                          }}
                        />
                        <div
                          className={
                            'grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-11'
                          }
                          key={key2}>
                          <Form.Item {...restField2} name={[name2, 'productId']} hidden></Form.Item>
                          <Form.Item {...restField2} name={[name2, 'costPrice']} hidden></Form.Item>
                          <Form.Item {...restField2} name={[name2, 'productName']} label="Name">
                            <Input
                              type={'text'}
                              disabled
                              style={{
                                backgroundColor: 'white',
                                color: 'black',
                                // border: '0px',
                                fontWeight: 'bold'
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'unitId']}
                            label="Unit"
                            rules={[
                              {
                                required: true,
                                message: 'Please choose a Unit!'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select a Unit!"
                                onChange={() => {
                                  onUnitChange(name2), onUnitandQuantityChange(name2);
                                }}
                                dropdownMatchSelectWidth={false}
                                allowClear>
                                {FilterUnits(name2)}
                              </Select>
                            }
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'lotId']}
                            label="Lot"
                            rules={[
                              {
                                required: true,
                                message: 'Please choose a Lot!'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select a Lot!"
                                dropdownMatchSelectWidth={false}
                                onChange={(value) => onLotChange(name2, value)}
                                allowClear>
                                {FilterLot(name2)}
                              </Select>
                            }
                          </Form.Item>

                          <Form.Item {...restField2} label="HS Code" name={[name2, 'hsCode']}>
                            <Input disabled />
                          </Form.Item>

                          <ReusableQuantity
                            autoFocusRef={autofocusRefQuantity}
                            name={name2}
                            restField={restField2}
                            onChangeData={() => onUnitandQuantityChange(name2)}
                            onPressEnterData={(e) => {
                              if (autofocusRef.current) {
                                autofocusRef.current.focus();
                              }
                            }}
                            rules={[
                              { required: true, message: 'Please add a Valid Quantity!' },
                              () => ({
                                validator(_: any, value: any) {
                                  const checked = checkLotQuantity(name2);
                                  // console.log(quantity);
                                  if (!value) {
                                    return Promise.reject();
                                  }
                                  if (value < 0)
                                    return Promise.reject(`Please Input valid quantity!`);
                                  if (checked == null) {
                                    return Promise.reject(`Please select Lot!`);
                                  }
                                  if (value > checked.quantity)
                                    return Promise.reject(
                                      `Please Input valid quantity! Currently in stock: ${checked.quantity} ${checked.shortName}`
                                    );
                                  return Promise.resolve();
                                }
                              })
                            ]}
                          />
                          <Form.Item
                            {...restField2}
                            label="Rate"
                            name={[name2, 'unitPrice']}
                            rules={[{ required: true, message: 'Please add Rate!' }]}>
                            <InputNumber
                              controls={false}
                              disabled
                              style={{ color: 'black' }}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Price From"
                            name={[name2, 'transactionType']}>
                            <Select
                              placeholder={'Select price'}
                              dropdownMatchSelectWidth={false}
                              onChange={(val) => handleTransactionTypeSelected(val, name2)}>
                              <Select.Option value={TransactionType.PURCHASE}>
                                {TransactionType.PURCHASE}
                              </Select.Option>
                              <Select.Option value={TransactionType.TRANSFER_IN}>
                                {TransactionType.TRANSFER_IN}
                              </Select.Option>
                              <Select.Option value={TransactionType.OPENING_STOCK}>
                                {TransactionType.OPENING_STOCK}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="VAT"
                            name={[name2, 'vat']}
                            rules={[{ required: true, message: 'Please add VAT!' }]}>
                            <InputNumber
                              controls={false}
                              disabled={true}
                              style={{ color: 'black' }}
                              min={0}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Discount"
                            name={[name2, 'discount']}
                            rules={[{ required: true, message: 'Please add Discount!' }]}>
                            <InputNumber
                              controls={false}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Misc"
                            name={[name2, 'misc']}
                            rules={[{ required: true, message: 'Please add Misc!' }]}>
                            <InputNumber
                              controls={false}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>

                          {/* <div className="flex justify-between gap-2"> */}
                          <Form.Item {...restField2} name={[name2, 'total']} label="Total">
                            <InputNumber
                              controls={false}
                              min={0}
                              disabled
                              style={{ color: 'black' }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'isTraceable']}
                            label="IsTraceeable?">
                            <Checkbox
                              onChange={(value) => onTracheableChange(name2, value.target.checked)}
                            />
                          </Form.Item>
                          {fields2.length > 0 ? (
                            <CustomButton
                              backgroundColor="white"
                              text="Remove"
                              textColor="red"
                              onClick={() => remove2(name2)}
                            />
                          ) : null}
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </Card>
              </>
            )}
          </Form.List>
          {form.getFieldValue(['lines'])?.length > 0 ? (
            <Card
              style={{ borderRadius: '10px', marginBottom: '10px', marginTop: '10px' }}
              className="grid grid-cols-2">
              <Form.Item name={['grandtotal']} label="Grand Total">
                <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
              </Form.Item>
            </Card>
          ) : null}
          <Divider />
          <PageHeader
            subTitle="Checklist"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className="grid grid-cols-2 gap-2 md:grid-cols-4 gap-3 mb-5">
            <Form.Item
              label={'Crate'}
              name={['crate']}
              rules={[
                {
                  required: true,
                  message: `Can't be empty`
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              label={'Sack'}
              name={['sack']}
              rules={[
                {
                  required: true,
                  message: `Can't be empty`
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              label={'Bags'}
              name={['bags']}
              rules={[
                {
                  required: true,
                  message: `Can't be empty`
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item label={'Others'} name={['others']}>
              <Input />
            </Form.Item>
          </div>

          <div className="flex justify-end mt-5">
            <Form.Item>
              <DebounceButton type="primary" disabled={!hasRule || isLoading} onClick={form.submit}>
                Submit
              </DebounceButton>
              {/* <CustomSubmitButton text="Submit" /> */}
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};
