import {
  DatePicker,
  Form,
  FormInstance,
  Input,
  PageHeader,
  Radio,
  RadioGroupProps,
  Select,
  Space
} from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { IDateRangeType, IWidgetConfiguration } from '@/services/dashboard/v3/types';
import { CustomDatePresets } from '@/pages/sqlsource/report/utils/datePresets';
import { ReportCustomSearchFields, ReportRequiredFields } from '../../constant.dashboard';
import RenderCompareForm from './RenderCompareForm';
import { CompareType } from '@/services/dashboard/v3/enums';
import WidgetComparableUI from './WidgetComparableUI';

interface Props {
  form: FormInstance;
  widget: IWidgetConfiguration;
  reports: { id: number; name: string }[];
}

function WidgetFilterUI({ form, reports, widget }: Props) {
  const comparableFormField = widget.comparable?.formField;
  const compareType = Form.useWatch('compareType', form) as CompareType;

  const showFormFields = compareType !== CompareType.SINGLE_COMPARE;

  const handleDatePickerChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length === 2) {
      form.setFieldValue('dateRange', 'custom');
    }
  };

  function handleCustomChecked() {
    form.setFieldValue('dateRange', 'custom');
  }

  const handleDateChecked: RadioGroupProps['onChange'] = (e) => {
    const value = e.target.value as IDateRangeType;
    const isCustom = value === 'custom';
    if (!isCustom) form.setFieldsValue({ customRange: undefined });
  };

  return (
    <Form form={form}>
      <div>
        <Form.Item name="dateRange" label="Date Range" hidden={Boolean(comparableFormField)}>
          <Radio.Group className="!mt-1.5" onChange={handleDateChecked}>
            <Space direction="vertical">
              <Radio value={'daily'}>Daily (Most Recent date)</Radio>
              <Radio value={'weekly'}>Last 7 days</Radio>
              <Radio value={'monthly'}>Last 30 days</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="customRange" label="Custom Range" hidden={Boolean(comparableFormField)}>
          <DatePicker.RangePicker
            data-no-dnd="true"
            showTime
            allowClear
            onChange={handleDatePickerChange}
            ranges={CustomDatePresets}
            onOk={handleCustomChecked}
            className="w-full"
            format={'YYYY-MM-DD HH:mm'}
          />
        </Form.Item>

        {comparableFormField && <WidgetComparableUI compareType={compareType} form={form} />}

        {reports.map((report) => {
          const formField = ReportRequiredFields[report.id];
          if (!formField) return null;

          return formField.map((field) => {
            const { title, options } = field.formOption;
            return (
              <div key={field.field}>
                <PageHeader subTitle={report.name} style={{ padding: '8px 0' }} />
                <Form.Item
                  rules={[{ required: field.required, message: `Please select ${title}` }]}
                  name={`${report.id}---${field.field}`}
                  label={title}>
                  <Select placeholder={title}>
                    {options?.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            );
          });
        })}

        {reports.map((report) => {
          const formField = ReportCustomSearchFields[report.id];
          if (!formField) return null;

          return (
            <div key={`${report.id}---${report.name}`}>
              <PageHeader subTitle={report.name} style={{ padding: '8px 0' }} />
              <div>
                {formField.map((field) => (
                  <RenderCompareForm
                    key={field.field}
                    form={form}
                    type={field.field}
                    label={field.label}
                    required={field.required}
                    name={`${report.id}---${field.name}`}
                  />
                ))}
              </div>
            </div>
          );
        })}

        {widget.comparable?.formField && reports.length === 1 && showFormFields && (
          <>
            <PageHeader
              subTitle={`Select ${widget.comparable.formField?.replace('Id', '')}`}
              style={{ padding: '8px 0' }}
            />
            <RenderCompareForm
              form={form}
              type={widget.comparable.formField}
              required={Boolean(showFormFields)}
              setSelected={(name) => form.setFieldValue('formFieldName', name)}
              name={`${reports[0].id}---${widget.comparable.formField}`}
            />

            <Form.Item name="formFieldName" hidden>
              <Input />
            </Form.Item>
          </>
        )}
      </div>
    </Form>
  );
}

export default WidgetFilterUI;
