import { Spin, PageHeader, Input, InputNumber, Button, Form, message } from 'antd';
import form from 'antd/lib/form';
import Password from 'antd/lib/input/Password';
import AppContent from '../../../../components/Common/Content/Content';
import { LocationSearch } from '../../../../components/Common/LocationSearch/LocationSearch';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IFonepayCredentialsUpdate } from '../../../../services/epay/types';
import { update_fonepay_credentials_mutation } from '../../../../services/epay/mutations';
import { useMutation, useQuery } from '@tanstack/react-query';
import { get_fonepay_credential_details } from '../../../../services/epay/queries';

const DetailsFonepay = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();

  const breadcrumbItems = [
    {
      label: 'Fonepay',
      link: '/fonepay'
    },
    {
      label: 'Details',
      link: '/fonepay/:id'
    }
  ];

  useQuery(['fonepayCredList', id], async () => await getData());

  const getData = async () => {
    if (id) {
      const data = await get_fonepay_credential_details(parseInt(id));
      //console.log('Data', data);
      form.setFieldsValue({
        username: data.username,
        password: null,
        locationId: data.locationId,
        merchantCode: data.merchantCode,
        secretKey: data.secretKey
      });
      setIsLoading(false);
    }
  };

  const onFinish = (values: IFonepayCredentialsUpdate) => {
    setIsLoading(true);
    if (id) {
      // console.log('Values', values);
      values.id = parseInt(id);
      updateFonepayCredentialsMutation.mutate(values);
    }
  };

  const updateFonepayCredentialsMutation = useMutation(update_fonepay_credentials_mutation, {
    onSuccess: () => {
      setIsLoading(false);
      message.success('Fonepay Credentials added successfully');
      navigate('/fonepay');
    },
    onError: (e: any) => {
      setIsLoading(false);
      message.error(`${e.response.data.message}`);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          //initialValues={}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off"
          initialValues={{ username: '', password: '' }}>
          <PageHeader
            title="Fonepay Credential Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 sm:grid-cols-3 gap-5 mb-5'}>
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please add Username!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please add Username!' }]}>
              <Password />
            </Form.Item>
            <LocationSearch
              formData={{ formName: 'locationId', formLabel: 'Location' }}
              notAll={true}
              required={true}
            />
            <Form.Item
              label="Merchant Code"
              name="merchantCode"
              rules={[{ required: true, message: 'Please add Merchant Code!' }]}>
              <InputNumber controls={false} />
            </Form.Item>
            <Form.Item
              label="Secret Key"
              name="secretKey"
              rules={[{ required: true, message: 'Please add Secret Key!' }]}>
              <Input />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/fonepay')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default DetailsFonepay;
