import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, message } from 'antd';

import Table from './Table';
import getError from '@/utils/getError';
import TableFilter from '@/components/FliterTable';
import { ConvertObjectToURL } from '@/utils/converturl';
import handlePagination from '@/utils/handlePagination';
import AppContent from '@/components/Common/Content/Content';
import { get_product_zero_lots_filter } from '@/services/products/queries';
import { IStockClear } from '@/services/products/types';
import { get_location_list_for_ids } from '@/services/locations/queries';
import UsersDB from '@/store/localstorage/UsersDB';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_user_list_ids } from '@/services/users/queries';
import { createEmptyPromise } from '@/utils/createEmptyPromise';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { getUserData } from '@/utils/auth.utils';

function ExistingClearStocksList() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState({ total: 0, results: [] as IStockClear[] });

  const user = getUserData();
  const isAdmin = user.perms.includes('ADMIN');

  async function onSubmitFilter(filter: string) {
    await getInfo(filter);
    setPagination({ page: 1, size: 100 });
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const response = await get_product_zero_lots_filter(filter);
      const absentLocations = [] as number[];
      const absentUsers = [] as number[];

      for (const item of response.results) {
        const { createdBy, locationId } = item;
        if (createdBy) {
          const createdUser = await UsersDB.getUser(createdBy);
          if (!createdUser) absentUsers.push(createdBy);
          item.createdName = createdUser?.name;
        }

        if (locationId) {
          const location = await LocationsDB.getLocation(locationId);
          if (!location) absentLocations.push(locationId);
          item.locationName = location?.name;
        }
      }

      const usersPromise =
        absentUsers.length > 0
          ? get_user_list_ids([...new Set(absentUsers)])
          : createEmptyPromise({ data: { total: 0, results: [] } });

      const locationsPromise =
        absentLocations.length > 0
          ? get_location_list_for_ids([...new Set(absentLocations)])
          : createEmptyPromise([]);

      const [users, locations] = await Promise.all([usersPromise, locationsPromise]);
      await UsersDB.addUsers(users.data.results);
      await LocationsDB.addLocations(locations);

      for (const item of response.results) {
        const { createdBy, locationId } = item;
        if (createdBy && absentUsers.includes(createdBy)) {
          const createdUser = await UsersDB.getUser(createdBy);
          item.createdName = createdUser?.name;
        }

        if (locationId && absentLocations.includes(locationId)) {
          const location = await LocationsDB.getLocation(locationId);
          item.locationName = location?.name;
        }
      }

      setData(response);
    } catch (error) {
      message.error(getError(error));
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  return (
    <AppContent
      breadcrumbItems={[{ label: 'Products', link: '/products' }, { label: 'Clear Stocks' }]}
      withfilter
      button={
        <TableFilter
          form={form}
          onSubmit={onSubmitFilter}
          initial
          defaultValues={{
            dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
            skip: 0,
            count: 100,
            locationId: ''
          }}
          styleforbuttons={'flex justify-end items-center'}
          buttons={
            isAdmin && (
              <Link to="/products/clear-stocks/create">
                <Button type="primary" className="!rounded-md ml-2">
                  Clear All Stocks
                </Button>
              </Link>
            )
          }>
          <LocationSearchV2 name="locationId" hasParentFormItem={false} showAll />
        </TableFilter>
      }>
      <Table
        data={data}
        isLoading={isLoading}
        pagination={pagination}
        onPagination={onPagination}
      />
    </AppContent>
  );
}

export default ExistingClearStocksList;
