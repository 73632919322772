import moment from 'moment';

import { AccountRulesEvent } from './enums';
import {
  IAccountHistoryCreditDebitSum,
  IAccountLatestHistory,
  IJournalViewResponseTableData
} from './types';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { randomRGBDarkVer } from '@/utils/randomColors.utils';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { CustomDatePresets } from '@/pages/sqlsource/report/utils/datePresets';
import { addTransactionForExportAll } from '@/pages/accounts/trial-balance/trial-transaction.service';
import AccountsDB from '@/store/localstorage/AccountsDB';

// List of queries that are used in this file
import { get_location_list_for_ids } from '../locations/queries';

import {
  get_account_detail_by_ids,
  get_account_history_credit_debit_sum,
  get_account_journal_lines_credit_debit_sum,
  get_account_latest_history,
  get_account_latest_history_by_id,
  get_account_list_by_location,
  get_journal_lines_details,
  get_rules_list
} from './queries';
import { getDateFromDateTime } from '@/utils/getDateFromDateTime';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { historyPrecedence } from './constant.accounts';

export const checkHasAccountRule = async (
  locationId: number | null,
  accountRule: AccountRulesEvent
) => {
  const ruleList = await get_rules_list(
    locationId
      ? `skip=0&count=1000&locationId=${locationId}&eventType=${accountRule}`
      : `skip=0&count=1000&eventType=${accountRule}`
  );
  //   console.log('ruleList', ruleList);

  if (ruleList.results.length > 0) return true;
  return false;
};

export async function getHistoryAndLinesSum(
  startDate: string,
  endDate: string,
  accountIds: number[]
) {
  const today = moment().startOf('d');
  const isEndDateToday = moment(endDate).isSameOrAfter(today, 'day');

  let historySum: IAccountHistoryCreditDebitSum[] = [];
  let linesSum: IAccountHistoryCreditDebitSum[] = [];

  if (accountIds.length > 0) {
    // If endDate is today, we need to get the history sum until today and the lines sum until the end of the day
    if (isEndDateToday) {
      historySum = (
        await get_account_history_credit_debit_sum({
          startDate: moment(startDate).utc().format(),
          endDate: today.utc().format(),
          ids: accountIds
        })
      ).results;

      linesSum = (
        await get_account_journal_lines_credit_debit_sum({
          startDate: today.utc().format(),
          endDate: today.endOf('d').utc().format(),
          ids: accountIds
        })
      ).results;
    } else {
      historySum = (
        await get_account_history_credit_debit_sum({
          startDate,
          endDate,
          ids: accountIds
        })
      ).results;
    }
  }

  return { historySum, linesSum };
}

export function calculateTotalCreditDebit(
  transaction: IAccountHistoryCreditDebitSum,
  sum: IAccountHistoryCreditDebitSum[]
) {
  const { accountId } = transaction;
  const data = sum.find((sum) => sum.accountId === accountId);
  if (!data) return;

  const { totalCredit, totalDebit } = data;
  transaction.totalCredit +=
    typeof totalCredit === 'string' ? parseFloat(totalCredit) : totalCredit || 0;

  transaction.totalDebit +=
    typeof totalDebit === 'string' ? parseFloat(totalDebit) : totalDebit || 0;
}

export async function getSumAndCalculateTotal<T extends { id: number }>(
  startDate: string,
  endDate: string,
  data: T[]
) {
  const accountIds = data.map((acc) => acc.id);

  // Get history and lines sum
  const { historySum, linesSum } = await getHistoryAndLinesSum(startDate, endDate, accountIds);

  const results = data.map((acc) => {
    const transaction = { accountId: acc.id, totalDebit: 0, totalCredit: 0 };
    calculateTotalCreditDebit(transaction, historySum);
    calculateTotalCreditDebit(transaction, linesSum);

    return { ...acc, transaction };
  });

  return results;
}

export async function addLocationIfAbsent<T>({
  data,
  allowChildren = false
}: {
  data: T;
  allowChildren?: boolean;
}) {
  const isArray = Array.isArray(data);
  if (!isArray) return data;

  const responseData = await Promise.all(
    data.map(async (item) => {
      let locationName = '';

      if (item.locationId) {
        let location = await LocationsDB.getLocation(item.locationId);
        if (!location) {
          const locationIds = new Set<number>();
          data.forEach((value) => {
            if (value.locationId) locationIds.add(value.locationId);
          });

          const allLocations = await get_location_list_for_ids([...locationIds]);
          await LocationsDB.addLocations(allLocations);
          location = await LocationsDB.getLocation(item.locationId);
        }

        locationName = `(${location.code})`;
      }

      const returnObject = { ...item, locationName };
      if (allowChildren) {
        return { ...returnObject, children: [] };
      }

      return returnObject;
    })
  );

  return responseData as unknown as T;
}

export function getSingleHistory(
  history: IAccountLatestHistory[],
  currentFinancialHistoryDate: string
) {
  if (history.length === 0) return;
  if (history.length === 1) return history[0];

  const filteredRecords = history.filter((record) => {
    return moment(record.date).isSameOrAfter(currentFinancialHistoryDate);
  });

  if (filteredRecords.length === 0) return;
  const historyCopy = JSON.parse(JSON.stringify(filteredRecords)) as IAccountLatestHistory[];

  historyCopy.sort((a, b) => {
    // Date is same only if one is daily and other is financial_years
    if (a.date === b.date) {
      return historyPrecedence[a.historyType] - historyPrecedence[b.historyType];
    }

    // Fallback comparision for worst case
    return b.date.localeCompare(a.date);
  });

  return historyCopy[0];
}

export async function calculateStartAndEndBalance<T>(
  startDate: string,
  parentIds: number[],
  results: any[],
  searchParams: URLSearchParams
) {
  const start = moment(startDate).format('YYYY-MM-DD');
  const latestStartBalance = await get_account_latest_history(start, parentIds);

  const currentFinancialStart = searchParams.get('fsd') as string;
  // Get 1 day before current financial start
  const currentFinancialHistoryDate = moment(currentFinancialStart)
    .subtract(1, 'days')
    .format('YYYY-MM-DD');

  const data = results.map((item) => {
    const allHistory = latestStartBalance.results.filter((val) => val.accountId === item.id);
    const currentStartBalance = getSingleHistory(allHistory, currentFinancialHistoryDate);

    const startBalance = currentStartBalance?.balance || 0;
    const endBalance = startBalance + item.transaction.totalDebit - item.transaction.totalCredit;

    return {
      ...item,
      startBalance: parseFloat(startBalance.toFixed(2)),
      endBalance: parseFloat(endBalance.toFixed(2))
    };
  });

  return data as unknown as T;
}

export function convertIdToIndex<
  T extends {
    id: number;
    parentId: number | null;
    iconColor?: string;
    hasMore?: boolean;
    children: T[];
  }
>(id: Map<number, number>, data: T[], responseData: T[]) {
  for (let i = 0; i < data.length; i++) {
    if (id.has(data[i].id)) {
      let filteredData = responseData.filter((value) => value.parentId === data[i].id);
      const randomColor = randomRGBDarkVer();
      filteredData = filteredData.map((value) => {
        value.iconColor = randomColor;
        if (value.hasMore) {
          return { ...value };
        } else {
          return { ...value, children: [] };
        }
      });
      if (filteredData.length > 0) {
        data[i].children = filteredData;
      }
    }
    if (data[i].children !== undefined) {
      if (data[i].children.length > 0) {
        const childIndex = convertIdToIndex(id, data[i].children, responseData);
        if (childIndex.length > 0) {
          data[i].children = childIndex.filter((value) => value.parentId === data[i].id);
        }
      }
    }
  }
  return data;
}

export function flattenData<T extends { name: string; children?: T[] }>(
  data: T[],
  prefix = '',
  node = 0
) {
  const result: T[] = [];
  const traverse = (item: T, parent: T | null, prefix: string, node: number) => {
    const newItem = { ...item, parent, node };
    newItem.name = prefix + newItem.name;
    delete newItem.children;
    result.push(newItem);
    if (item.children) {
      const childPrefix = prefix + '\u00A0'.repeat(3);
      const childNode = node + 1;
      item.children.forEach((child) => traverse(child, newItem, childPrefix, childNode));
    }
  };
  data.forEach((item) => traverse(item, null, prefix, node));
  return result;
}

export function flattenDataExportAll<T extends { name: string; children?: any[] }>(
  data: T[],
  prefix = '',
  node = 0
) {
  const result: T[] = [];
  const traverse = (item: T, parent: T | null, prefix: string, node: number) => {
    const newItem = { ...item, parent, node };
    newItem.name = prefix + newItem.name;
    delete newItem.children;
    result.push(newItem);
    if (item.children) {
      const childPrefix = prefix + '\u00A0'.repeat(3);
      const childNode = node + 1;
      item.children.forEach((child) => traverse(child, newItem, childPrefix, childNode));
    }
  };
  data.forEach((item) => traverse(item, null, prefix, node));
  return result;
}

export function getExportData<T extends { parentId: number; id: number; balance: number }>(
  allData: T[],
  main: T[]
) {
  main = main.map((item) => {
    let childData: T[] = [];
    if (allData.filter((val) => val.parentId === item.id).length > 0) {
      childData = getExportData(
        allData,
        allData.filter((val) => val.parentId === item.id)
      );
    }
    return {
      ...item,
      children: childData.sort((a, b) => b.balance - a.balance)
    };
  });

  return main;
}

export const getExportAllData = async (
  locationId: number | string,
  parentIds: number[],
  startDate: string,
  endDate: string
) => {
  if (locationId === '') {
    let allAccounts = await get_account_list_by_location(0, 1);
    const totalAccounts = allAccounts.total;
    allAccounts = await get_account_list_by_location(0, totalAccounts);
    const ids = allAccounts.results.map((val) => {
      return val.id;
    });

    allAccounts.results = await addTransactionForExportAll(
      allAccounts.results,
      startDate,
      endDate,
      ids
    );

    const start = moment(startDate).format('YYYY-MM-DD');
    // const end = moment(endDate).add(1, 'day').format('YYYY-MM-DD');
    const startBalanceData = await get_account_latest_history(start, ids);
    // const endBalanceData = await get_account_latest_history(end, ids);
    const updatedAllAccounts = allAccounts.results.map((val) => {
      const selectedStartData = startBalanceData.results.find(
        (valStart) => val.id === valStart.accountId
      );
      // const selectedEndData = endBalanceData.results.find(
      //   (valEnd) => val.id === valEnd.accountId
      // );
      const startBal = selectedStartData ? selectedStartData.balance : 0;
      let transactionTotalDebit = 0;
      let transactionTotalCredit = 0;
      if (val.transaction) {
        transactionTotalDebit = val.transaction.totalDebit;
        transactionTotalCredit = val.transaction.totalCredit;
      }
      const endBal = startBal + transactionTotalDebit - transactionTotalCredit;
      return {
        ...val,
        startBalance: startBal,
        endBalance: endBal
      };
    });
    const top = updatedAllAccounts
      .filter((val) => parentIds.includes(val.id))
      .sort((a, b) => b.balance - a.balance);
    const exportData = getExportData(updatedAllAccounts, top);
    return exportData;
  } else {
    locationId = typeof locationId === 'string' ? parseInt(locationId) : locationId;
    let allAccounts = await get_account_list_by_location(0, 1, locationId);
    const totalAccounts = allAccounts.total;
    allAccounts = await get_account_list_by_location(0, totalAccounts, locationId);
    const ids = allAccounts.results.map((val) => {
      return val.id;
    });

    allAccounts.results = await addTransactionForExportAll(
      allAccounts.results,
      startDate,
      endDate,
      ids
    );

    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).add(1, 'day').format('YYYY-MM-DD');
    const startBalanceData = await get_account_latest_history(start, ids);
    // const endBalanceData = await get_account_latest_history(end, ids);
    const updatedAllAccounts = allAccounts.results
      .filter((value) => value.locationId)
      .map((val) => {
        const selectedStartData = startBalanceData.results.find(
          (valStart) => val.id === valStart.accountId
        );
        const startBal = selectedStartData ? selectedStartData.balance : 0;
        let transactionTotalDebitX = 0;
        let transactionTotalCreditX = 0;
        if (val.transaction) {
          transactionTotalDebitX = val.transaction.totalDebit;
          transactionTotalCreditX = val.transaction.totalCredit;
        }
        const endBal = startBal + transactionTotalDebitX - transactionTotalCreditX;
        return {
          ...val,
          startBalance: startBal,
          endBalance: endBal
        };
      });
    const top = updatedAllAccounts
      .filter((val) => parentIds.includes(val.parentId))
      .sort((a, b) => b.balance - a.balance);
    const exportData = getExportData(updatedAllAccounts, top);
    return exportData;
  }
};

export async function getBalance(accountIds: number[]) {
  const date = CustomDatePresets.Today;

  try {
    const startDate = convertLocalToUTCString(date[0].format('YYYY-MM-DD'));
    const endDate = convertLocalToUTCString(date[1].format('YYYY-MM-DD'));

    const accountLatestPromises = get_account_latest_history_by_id(accountIds).then(
      (res) => res.results
    );

    const currentHistoryPromises = get_account_journal_lines_credit_debit_sum({
      startDate,
      endDate,
      ids: accountIds
    }).then((res) => res.results);

    const [currentAccountDetails, currentHistory] = await Promise.all([
      accountLatestPromises,
      currentHistoryPromises
    ]);

    if (currentAccountDetails.length === 0)
      return {
        openingBalance: 0,
        closingBalance: 0
      };

    const startBalance = currentAccountDetails[0].balance;

    // If there is no history, return the start balance
    if (currentHistory.length === 0)
      return {
        openingBalance: startBalance,
        closingBalance: startBalance
      };

    // Calculate the end balance
    const { totalCredit, totalDebit } = currentHistory[0];
    const credit = typeof totalCredit === 'string' ? parseFloat(totalCredit) : totalCredit || 0;
    const debit = typeof totalDebit === 'string' ? parseFloat(totalDebit) : totalDebit || 0;

    return {
      openingBalance: startBalance,
      closingBalance: startBalance + debit - credit
    };
  } catch (error) {
    return {
      openingBalance: 0,
      closingBalance: 0
    };
  }
}

export const getJournalLinesDetails = async (id: number) => {
  const response = await get_journal_lines_details(id);

  const tableData: IJournalViewResponseTableData[] = [];
  let opBal = '--';
  const accountsId: number[] = response.map((data) => {
    return data.accountId;
  });

  const endDate = new Date(response[0].journalDate);
  const startDate = getDateFromDateTime(response[0].journalDate);

  const journalDebit = await get_account_journal_lines_credit_debit_sum({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    ids: accountsId
  });

  const momentDate = getDateFromDateTime(response[0].journalDate);

  const newRunningBalance = await get_account_latest_history(momentDate.toISOString(), accountsId);
  for (let ind = 0; ind < response.length; ind++) {
    let filteredAcc = await AccountsDB.getAccount(response[ind].accountId);
    if (!filteredAcc) {
      const allAccounts = await get_account_detail_by_ids([
        ...new Set(
          response.map((value) => {
            return value.accountId;
          })
        )
      ]);
      await AccountsDB.addAccounts(allAccounts.results);
      filteredAcc = await AccountsDB.getAccount(response[ind].accountId);
    }
    const accName = typeof filteredAcc === 'object' ? filteredAcc?.name : '';

    let currentBalance = newRunningBalance.results.find(
      (val) => val.accountId === response[ind].accountId
    )?.balance;

    const currentJournalDebit = journalDebit.results.find(
      (val) => val.accountId === response[ind].accountId
    );

    if (currentJournalDebit) {
      if (!currentBalance) {
        currentBalance =
          Number(currentJournalDebit.totalDebit) - Number(currentJournalDebit.totalCredit);
      } else {
        currentBalance =
          currentBalance +
          Number(currentJournalDebit.totalDebit) -
          Number(currentJournalDebit.totalCredit);
      }
    }

    tableData.push({
      ...response[ind],
      accountName: accName,
      runningBalance: currentBalance ? currentBalance : 0
    });
  }

  if (response.length > 0) opBal = nepaliNumberFormatter(response[0].runningBalance || 0);
  return { data: tableData, openingBalance: opBal };
};

export async function getAccountDetails(accountId: number) {
  const account = await AccountsDB.getAccount(accountId);
  if (account) return account;

  const accountDetail = await get_account_detail_by_ids([accountId]);
  await AccountsDB.addAccounts(accountDetail.results);
  return await AccountsDB.getAccount(accountId);
}
