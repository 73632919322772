import { Checkbox, Form, Input, PageHeader } from 'antd';
import React from 'react';

const SubTotal = ({ form }: { form: any }) => {
  return (
    <div>
      <PageHeader
        title="Total Details"
        style={{
          padding: '8px 0px',
          fontSize: '14px !important'
        }}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 gap-5 mb-5">
        {/* <Form.Item label="Subtotal label" name="paymentSubTotalLabel">
          <Input />
        </Form.Item> */}

        <Form.Item label="Discount Label" name="totalDiscountLabel">
          <Input />
        </Form.Item>

        <Form.Item label="Tax Label" name="taxLabel">
          <Input />
        </Form.Item>

        <Form.Item label="Total Label" name="totalLabel">
          <Input />
        </Form.Item>

        <Form.Item label="Total in words label" name="totalWordsLabel">
          <Input />
        </Form.Item>

        {/* <Form.Item label="Round off label" name="roundOffLabel">
          <Input />
        </Form.Item> */}

        <Form.Item label="Total Due Label:" name="totalDueLabel">
          <Input />
        </Form.Item>

        <Form.Item label="Amount Paid Label:" name="amountPaidLabel">
          <Input />
        </Form.Item>
        <Form.Item label="Non Taxable Label:" name="nonTaxableLabel">
          <Input />
        </Form.Item>
        <Form.Item label="Taxable Label:" name="taxableLabel">
          <Input />
        </Form.Item>
        <Form.Item label="VAT Label:" name="vatLabel">
          <Input />
        </Form.Item>
        <Form.Item label="Net Amount Label:" name="netTotalLabel">
          <Input />
        </Form.Item>
        <Form.Item name="shippingChargeLabel" label="Shipping Charge Label">
          <Input />
        </Form.Item>
      </div>

      <div className="grid grid-cols-4 gap-5 mb-5">
        {/* <Form.Item name="showPaymentInformation" valuePropName="checked">
          <Checkbox className="w-100">Show Payment Information</Checkbox>
        </Form.Item> */}

        {/* <Form.Item label="Discount Label" name="showBarcode">
          <Checkbox className="w-100">Show Barcode</Checkbox>
        </Form.Item> */}

        {/* <Form.Item label="Total due label (All sales):" name="totalDueLabelAllSales">
          <Input />
        </Form.Item> */}

        {/* <Form.Item name="showTotalBalanceDue" valuePropName="checked">
          <Checkbox className="w-100">Show total balance due (All Sales)</Checkbox>
        </Form.Item> */}

        {/* <Form.Item label="Change return label:" name="changeReturnLabel">
          <Input />
        </Form.Item> */}

        {/* <Form.Item label="Show Total in words" name="showTotalInWords">
          <Checkbox className="w-100">Show Total in words</Checkbox>
        </Form.Item> */}
      </div>
    </div>
  );
};

export default SubTotal;
