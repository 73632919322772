import { useRef, useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { SorterResult } from 'antd/lib/table/interface';
import { TableProps } from 'antd/lib/table';
import { Button, Form, Input, Menu, Spin, Tooltip, message } from 'antd';

import { getTransferOrderTable } from './TableColumn';
import { getUserData } from '@/utils/auth.utils';
import TableFilter from '@/components/FliterTable';
import UsersDB from '@/store/localstorage/UsersDB';
import { ConvertObjectToURL } from '@/utils/converturl';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_user_details } from '@/services/users/queries';
import AppContent from '@/components/Common/Content/Content';
import { CustomModal } from '@/components/Common/CustomModal';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { CustomDatePresets } from '../../../sqlsource/report/utils/datePresets';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { TransferOrderList, get_transfer_order_list } from '@/services/transfer/queries';

import { edit_transfer_order_archive_mutation } from '@/services/transfer/mutations';
import TransferInvoice from '@/components/Common/InvoicePrint/TransferInvoice/TransferInvoice';
import { getTransferOrderPrintData } from '@/components/Common/InvoicePrint/TransferInvoice/tranferOrderPrintData';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import CustomTable from '@/components/Common/CustomResuableInvoice/CustomTable';
import { useReactToPrint } from 'react-to-print';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import GenericTable from '@/components/Common/CustomizeTable';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

export const TransferOrder: React.FC = () => {
  const printPDFRef = useRef<any>();
  const userData = getUserData();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [invoiceData, setInvoiceData] = useState<any>(Object);
  const [form] = Form.useForm();
  const [listData, setListData] = useState<TransferOrderList[]>([]);
  const [alltransferOrderTotal, setalltransferOrderTotal] = useState(0);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);

  const [openModalForPDFExport, setOpenModalForPDFExport] = useState(false);
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const { preferences } = userData;
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const breadcrumbItems = [{ label: 'Transfer Order', link: '/transfer/order' }];

  const generateInvoiceForPrint = async (record: any, curr: any) => {
    setIsLoading(true);
    const invoiceModalData = await getTransferOrderPrintData(
      record.id,
      record.from,
      record.to,
      curr
    );
    setIsLoading(false);
    setInvoiceData(invoiceModalData);
    setOpenModalForInvoicePrint(true);
  };

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    await getInfo(url);
    setIsLoading(false);
  };

  const handleInvoicePrintModalClose = async () => {
    setOpenModalForInvoicePrint(false);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const response = await get_transfer_order_list(filter);
    const transferOrderDetails = response.data.results;
    let ctotalamount = 0;
    if (transferOrderDetails) {
      for (let i = 0; i <= transferOrderDetails.length - 1; i++) {
        // console.log(transferOrderDetails[i]);
        const fromLocationDetails = await LocationsDB.getLocation(transferOrderDetails[i].from);

        // get user details
        const createdByDetails =
          (await UsersDB.getUser(transferOrderDetails[i].createdBy)) ||
          (await get_user_details(transferOrderDetails[i].createdBy))?.user;

        const toLocationDetails = await LocationsDB.getLocation(transferOrderDetails[i].to);
        // console.log(fromLocationDetails, toLocationDetails);
        transferOrderDetails[i] = {
          ...transferOrderDetails[i],
          fromName: fromLocationDetails.name,
          toName: toLocationDetails.name,
          createdByName: createdByDetails.name
        };
        ctotalamount += transferOrderDetails[i].totalAmount;
      }

      setalltransferOrderTotal(response.data.total);
    }
    setListData(transferOrderDetails);
    setIsLoading(false);
    return response;
  };

  const columns = getTransferOrderTable({
    sortedInfo,
    page,
    size,
    onGenerate: generateInvoiceForPrint,
    onDelete: deleteTransferOrder
  });

  const columnsForPrint: ExportColumnType[] = [
    { title: 'S.N', dataIndex: 'SN', width: 50 },
    { title: 'ID', dataIndex: 'id', width: 100 },
    { title: 'Financial Reference', width: 200, dataIndex: 'financialReference' },
    { title: 'From', width: 250, dataIndex: 'fromName' },
    { title: 'To', width: 250, dataIndex: 'toName' },
    { title: 'Total Amount', width: 100, dataIndex: 'totalAmount' },
    { title: 'Created By', width: 100, dataIndex: 'createdByName' },
    { title: 'Date', width: 200, dataIndex: 'createdAt' }
  ];

  const columnsForPDFPrint = columnsForPrint.slice(1).map((column) => ({
    dataIndex: column.dataIndex,
    label: column.title,
    width: column.width,
    render: (data: any) => (
      <div className="px-2">
        {column.dataIndex !== 'createdAt'
          ? data
          : convertUTCStringtoLocalString(data, DEFAULT_DATE_FORMAT)}
      </div>
    )
  }));

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current,
    onAfterPrint: () => setOpenModalForPDFExport(false)
  });

  function handleExcelExport() {
    setIsLoading(true);

    try {
      if (listData.length === 0) {
        return message.error('No Data to Export');
      }

      const data = listData.map((item, index) => {
        return {
          ...item,
          SN: index + 1,
          date: convertUTCStringtoLocalString(item.createdAt, DEFAULT_DATE_FORMAT)
        };
      });

      exportExcel(columnsForPrint, data, 'Transfer Out List');
    } finally {
      setIsLoading(false);
    }
  }

  function exportPDFHandler() {
    if (listData.length === 0) {
      return message.error('No Data to Export');
    }

    setOpenModalForPDFExport(true);
  }

  const transferOrderEdit = useMutation(edit_transfer_order_archive_mutation);

  async function deleteTransferOrder(id: number | string) {
    transferOrderEdit.mutateAsync(
      { archived: true, id: id },
      {
        onSuccess: async ({ data }: { data: any }) => {
          if (data) {
            message.success('Transfer Order Deleted Succesfully');

            // remove the deleted item from the list instead of hard refresh and get the data again
            const previousTableData = [...listData];
            const newTableData = previousTableData.filter((item: any) => item.id !== id);
            setListData(newTableData);
          }
        },
        onError: console.log
      }
    );
  }

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        footer={false}
        isModalOpen={openModalForInvoicePrint}
        setIsModalOpen={setOpenModalForInvoicePrint}
        title="Transfer Order Invoice Print">
        <TransferInvoice
          isOrder
          transferData={invoiceData.transferData}
          lines={invoiceData.lines}
          invoiceLayouts={invoiceData.invoiceLayouts}
          handleModalClose={handleInvoicePrintModalClose}
        />
      </CustomModal>
      <CustomModal
        footer={false}
        isModalOpen={openModalForPDFExport}
        setIsModalOpen={setOpenModalForPDFExport}
        title="Transfer Order View For PDF print">
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columnsForPDFPrint as any}
            datas={listData}
            reff={printPDFRef}
            title={'Transfer Order List'}
          />
          <div className="flex justify-end mt-3">
            <Button type="primary" onClick={handlePDFExport}>
              Print Pdf
            </Button>
          </div>
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <TableFilter
                defaultValues={{
                  dateCustom: [...CustomDatePresets.Today],
                  locationId: preferenceLocationId ? preferenceLocationId : '',
                  value: '',
                  skip: 0,
                  count: 100
                }}
                initial={true}
                form={form}
                onSubmit={onSubmitFilter}
                // outsidestyle={'flex flex-wrap items-end gap-2'}
                styleforbuttons={'flex items-center justify-end'}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                buttons={
                  <>
                    <div>
                      <CustomButton
                        onClick={() => navigate('/transfer/order/new')}
                        text="Add"
                        backgroundColor="#1890ff"
                        Linkto="/transfer/order/new"
                      />
                    </div>
                  </>
                }>
                <LocationSearchV2
                  hasParentFormItem={false}
                  name={'locationId'}
                  label="Location(To)"
                />
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <div className="flex justify-end mb-2">
          <div>
            <ActionDropdown
              button={true}
              menu={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <Tooltip title="Export Excel" color="blue">
                          <div className="text-center">Excel</div>
                        </Tooltip>
                      ),
                      onClick: handleExcelExport
                    },
                    {
                      key: '2',
                      label: (
                        <Tooltip title="Export PDF" color="blue">
                          <div className="text-center">PDF</div>
                        </Tooltip>
                      ),
                      onClick: exportPDFHandler
                    }
                  ]}
                />
              }
            />
          </div>
        </div>
        <GenericTable
          columns={columns}
          data={listData}
          // usersLoading={isLoading}
          hideDefaultPagination={true}
          scroll={{ x: 1000, y: '75vh' }}
          pagination={{
            page: page,
            total: alltransferOrderTotal,
            size: size,
            onPagination
          }}
          generateSummary
          summaryClassName="!pr-2"
          tableName={'transfer-order'}
          toSort={handleChange}
        />

        {/* <ProductFilterDrawers visible={visible} onClose={onClose} /> */}
      </AppContent>
    </Spin>
  );
};
