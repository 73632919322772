import ProductsDB from '@/store/localstorage/ProductsDB';
import { IProductDetails } from '@/services/products/types';
import { get_product_list_only } from '@/services/products/queries';
import { Button, Divider, message, Select } from 'antd';
import { useEffect, useState } from 'react';
import useDebounce from '@/hooks/useDebounce';
import getErrorMessage from '@/utils/getError';
import { getProductById } from '@/services';

type Props = {
  defaultValue?: number;
  showAll?: boolean;
  count?: number;
  disabled?: boolean;
  className?: string;
  allowClear?: boolean;
  placeHolder?: string;
  notFoundContent?: React.ReactNode;
  defaultActiveFirstOption?: boolean;
  dropdownMatchSelectWidth?: boolean;
  hideDBNotFoundMessage?: boolean;
  onSelect?: (value: number, details: IProductDetails) => void | Promise<void>;
};

function ProductSearchForNonForm({
  count = 10,
  showAll = false,
  allowClear = true,
  placeHolder,
  notFoundContent = <div>No Content</div>,
  dropdownMatchSelectWidth = false,
  defaultActiveFirstOption = false,
  defaultValue,
  hideDBNotFoundMessage = false,
  className,
  ...props
}: Props) {
  const [skip, setSkip] = useState(0);
  const [isMore, setIsMore] = useState(true);

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const [data, setData] = useState<IProductDetails[]>([]);
  const [isCacheResponse, setIsCacheResponse] = useState(false);

  const [selectedProductId, setSelectedProductId] = useState<number>();

  useEffect(() => {
    searchIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue, defaultValue]);

  useEffect(() => {
    setSelectedProductId(defaultValue);
  }, [defaultValue]);

  async function searchIndexDB(value: string) {
    try {
      const indexDBData = await ProductsDB.searchProduct(value, count);
      if (defaultValue) {
        const isPresent = data.find((p) => p.id === defaultValue);
        if (!isPresent) {
          const currentProduct = await getProductById(defaultValue);
          indexDBData.unshift(currentProduct);
        }
      }

      if (indexDBData.length == 0) {
        if (!hideDBNotFoundMessage) {
          message.destroy('db-not-found');
          message.error({
            key: 'db-not-found',
            content: 'Cannot find any product with that value in cache, searching in server...'
          });
        }

        await searchInServer(value, 'initial');
      }

      setData(indexDBData);
      setIsCacheResponse(true);
    } catch (error) {
      getErrorMessage(error, true);
    }
  }

  async function searchInServer(value: string, type: 'initial' | 'more') {
    try {
      const currentSkip = type === 'initial' ? 0 : skip;
      const results = await get_product_list_only(currentSkip, count, value);
      const isValidResponse = results && results.length > 0;

      if (!isValidResponse) {
        message.error('Cannot find any product with that name!');
        setIsMore(false);
        type === 'initial' && setData([]);
      }

      if (isValidResponse) {
        type === 'initial' ? setData(results) : setData((prev) => [...prev, ...results]);

        ProductsDB.addProducts(results);
        setIsMore(results.length >= count);
        type === 'more' && setSkip(currentSkip + count);
      }

      if (type === 'initial') {
        setIsCacheResponse(false);
        setSkip(count);
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      message.error(errorMessage);
    }
  }

  function handleProductChange(value: number) {
    const product = data.find((p) => p.id === value);

    if (product) {
      const hasDefaultUnit = product.productUnits?.find((unit) => unit.isDefault);
      if (!hasDefaultUnit) {
        message.warning('This product does not have a default unit', 5);
      }

      setSelectedProductId(value);
      props.onSelect?.(value, product)?.catch(() => setSelectedProductId(undefined));
    }
  }

  return (
    <Select
      showSearch
      value={selectedProductId}
      allowClear={allowClear}
      className={className}
      filterOption={false}
      optionLabelProp="children"
      disabled={props.disabled}
      placeholder={placeHolder || 'Search product...'}
      defaultActiveFirstOption={defaultActiveFirstOption}
      onSearch={(val) => setSearchValue(val)}
      onClear={() => setSearchValue('')}
      onChange={handleProductChange}
      notFoundContent={notFoundContent}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            {isCacheResponse ? (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                <Button
                  type="text"
                  style={{ color: 'blue', width: '100%' }}
                  onClick={() => searchInServer(searchValue, 'initial')}>
                  {'Pull More & Sync'}
                </Button>
              </div>
            ) : (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                {isMore ? (
                  <Button
                    type="text"
                    style={{ color: 'blue', width: '100%' }}
                    onClick={() => searchInServer(searchValue, 'more')}>
                    {'Get More...'}
                  </Button>
                ) : (
                  <div style={{ width: '100%', textAlign: 'center' }}>No more data...</div>
                )}
              </div>
            )}
          </>
        );
      }}>
      {showAll && <Select.Option value="">All</Select.Option>}
      {data?.map((value) => {
        return (
          <Select.Option key={value.id} value={value.id}>
            {value.name}
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default ProductSearchForNonForm;
