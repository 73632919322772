import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { ITaskLog, ITasks, ITasksCreateRequest } from './types';

export const create_tasks_mutation = async (values: ITasksCreateRequest) => {
  const response: AxiosResponse<ITasks, any> = await http.store('task', values);
  return response;
};

export const update_tasks_mutation = async (values: ITasks) => {
  const response: AxiosResponse<ITasks, any> = await http.update('task/' + values.id, {
    ...values,
    id: values.id
  });
  return response;
};

export const update_tasks_log = async (values: {
  taskId: number;
  userId: number;
  operation: string;
}) => {
  const response: AxiosResponse<ITaskLog, any> = await http.store('task/log', values);
  return response;
};

/* Delete category
 ** @@ params: id
 */
// export const delete_tasks = async (id: number) => {
//   const response = await http.remove('task/' + id);
//   return response;
// };

export const archive_tasks = async (id: number[] | string[]) => {
  const response: AxiosResponse<ITasks[], any> = await http.update('task/multi-archive', {
    ids: id
  });
  return response;
};
