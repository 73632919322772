import React, { useState, useContext } from 'react';
import { RollbackOutlined } from '@ant-design/icons';
import { Table, Tooltip, Menu, Form, TableProps, Spin, Input } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { Link, useNavigate } from 'react-router-dom';
import AppContent from '@/components/Common/Content';

import { get_purchase_list_filter } from '@/services/purchases/queries';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import { get_user_details, get_user_list_ids, get_vendor_list_ids } from '@/services/users/queries';
import UsersDB from '@/store/localstorage/UsersDB';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list, get_location_list_for_ids } from '@/services/locations/queries';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { checkAccess } from '@/routes/acl';
import { VendorSearch } from '@/components/Common/VendorSearch/VendorSearch';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import VendorsDB from '@/store/localstorage/VendorDB';
import { ConvertObjectToURL } from '@/utils/converturl';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { getUserData } from '@/utils/auth.utils';
import ActionDropdown from '@/components/Common/Dropdownactions';
import CopyButton from '@/components/Common/CopyButton';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { UsersSearch } from '@/components/Common/UsersSearch';
import { find_to_use_NPR } from '@/store/localstorage/preferences';
import PayoutStatus from '@/components/Common/PayoutStatus';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import { PurchaseBuyerType, PurchaseType } from '@/services/purchases/enums';
import ProductCategorySearch from '@/components/Common/ProductCategorySearch/ProductCategorySearch';
import { TableExportPrint } from './TableExportPrint';
import { PurchaseListContext } from './context';
import { PurchaseInvoicePrintModal } from './PurchaseInvoicePrint';
import { PurchaseStatus } from './PurchaseStatus';
import { PurchaseTypeComp } from './PurchaseType';
import { PurchasePaymentModal } from './PaymentModal';
import { PurchaseBuyerTypeComp } from './PurchaseBuyerTypeComp';
import AgentsSearch from '@/components/Common/AgentsSearch';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import GenericTable from '@/components/Common/CustomizeTable';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import VendorSearchV2 from '@/components/Common/CustomSearch/Vendors';
import AgentSearchV2 from '@/components/Common/CustomSearch/Agents';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import ProductCategorySearchV2 from '@/components/Common/CustomSearch/ProductCategory';

const PurchaseList: React.FC = () => {
  const navigate = useNavigate();
  const { form, isLoading, setIsLoading, urlData, setUrlData } = useContext(PurchaseListContext);
  const [allpurchaseList, setallPurchaseList] = useState<any>({ total: 0, results: [] });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [total, setTotal] = useState<any>({
    totalAmount: 0,
    totalReturn: 0
  });
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;
  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };
  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const urlParams = new URLSearchParams(filter);
    const vendorIds = urlParams.get('vendorId');
    urlParams.delete('vendorId');
    if (vendorIds) {
      const customerList = vendorIds.split(',').map(Number);
      const updatedParams = customerList.map((id) => `vendorIds[]=${id}`).join('&');

      const updatedURL = `${urlParams.toString()}&${updatedParams}`;
      filter = updatedURL;
    } else {
      filter = urlParams.toString();
    }

    setUrlData(filter);
    const response = await get_purchase_list_filter(filter);
    const searchUsers: any = {};
    const createdByUsers: any = {};
    const agentUsers: any = {};
    let ctotalamount = 0,
      creturnamount = 0;

    for (let index = 0; index < response.data.results.length; index++) {
      const currentData = response.data.results[index];
      currentData.initialAmount = currentData.totalTaxable + currentData.totalNonTaxable;
      if (response.data.results[index].createdBy) {
        const createdByuser: any = await UsersDB.getUser(response.data.results[index].createdBy);
        if (!createdByuser) {
          // user = await get_customer_details(response.data.resultss[index].customerId);
          // user = { ...user.customer, user: user.user.user };
          // await CustomersDB.addCustomers([user]);
          if (response.data.results[index].createdBy in createdByUsers) {
            createdByUsers[response.data.results[index].createdBy] = [
              ...createdByUsers[response.data.results[index].createdBy],
              index
            ];
          } else {
            createdByUsers[response.data.results[index].createdBy] = [index];
          }
        } else {
          response.data.results[index].createdByName = createdByuser.name;
          // response.data.resultsWithCustomer[index].userData = user;
        }
      }

      if (response.data.results[index].agentId) {
        const agent = await UsersDB.getUser(response.data.results[index].agentId);
        if (!agent) {
          const agent = await get_user_details(response.data.results[index].agentId);
          await UsersDB.addUsers([agent.user]);
          response.data.results[index].agentName = agent.user.name;
        } else {
          response.data.results[index].agentName = agent.name;
        }
      }

      const user: any = await VendorsDB.getVendors(response.data.results[index].vendorId);
      if (!user) {
        if (response.data.results[index].vendorId in searchUsers) {
          searchUsers[response.data.results[index].vendorId] = [
            ...searchUsers[response.data.results[index].vendorId],
            index
          ];
        } else {
          searchUsers[response.data.results[index].vendorId] = [index];
        }
      } else {
        response.data.results[index].userName = user.user.name;
        response.data.results[index].userData = user;
        response.data.results[index].userContact = user.user.phone;
      }
      let locD: any = await LocationsDB.getLocation(response.data.results[index].locationId);
      if (!locD) {
        const allLocations = await get_location_list();
        // console.log('allLocations-->', allLocations);
        await LocationsDB.addLocations([...allLocations.data.results]);
        // await LocationsDB.addLocations([locD]);
        locD = await LocationsDB.getLocation(response.data.results[index].locationId);
      }
      response.data.results[index].locationName = locD.name;
      if (response.data.results[index].locationLCCId) {
        let lccLocD = await LocationsDB.getLocation(
          response.data.results[index].locationLCCId as number
        );
        if (!lccLocD) {
          const allLocationIds = new Set<number>();
          response.data.results.forEach((val) => {
            if (val.locationLCCId) allLocationIds.add(val.locationLCCId);
          });
          const allLocations = await get_location_list_for_ids([...allLocationIds]);
          await LocationsDB.addLocations([...allLocations]);
          lccLocD = await LocationsDB.getLocation(
            response.data.results[index].locationLCCId as number
          );
        }
        response.data.results[index].lccLocationName = lccLocD.name;
      }

      response.data.results[index].date = convertUTCStringtoLocalString(
        response.data.results[index].createdAt,
        'YYYY-MM-DD'
      );
      ctotalamount += response.data.results[index].totalAmount;
      creturnamount += response.data.results[index].amountReturned;
    }
    setTotal({ totalAmount: ctotalamount, totalReturn: creturnamount });
    const searchUserslength = Object.entries(searchUsers).length;
    if (searchUserslength > 0) {
      const customerresponse = await get_vendor_list_ids([...Object.keys(searchUsers)]);
      for (const key in searchUsers) {
        const vendorUser = customerresponse?.data?.results.find(
          (currCustomer: any) => currCustomer.id == key
        );
        for (let i = 0; i < searchUsers[key].length; i++) {
          response.data.results[searchUsers[key][i]].userName = vendorUser?.user.name;
          response.data.results[searchUsers[key][i]].userData = vendorUser;
          response.data.results[searchUsers[key][i]].userContact = vendorUser?.user.phone;
        }
        await VendorsDB.addVendors([vendorUser]);
      }
    }
    const createdByUserslength = Object.entries(createdByUsers).length;
    // console.log('createdbyuserslength-->', createdByUsers);
    if (createdByUserslength > 0) {
      const customerresponse = await get_user_list_ids([...Object.keys(createdByUsers)]);
      // console.log('Customer response-->', customerresponse);
      for (const key in createdByUsers) {
        // console.log('keys->', key);
        const createdByUser = customerresponse?.data?.results.find(
          (currCustomer: any) => currCustomer.id == key
        );
        // console.log('createdByUser-->', createdByUser);
        if (!createdByUser) continue;
        for (let i = 0; i < createdByUsers[key].length; i++) {
          response.data.results[createdByUsers[key][i]].createdByName = createdByUser.name;
        }
        await UsersDB.addUsers([createdByUser]);
      }
    }
    setallPurchaseList(response.data);
    setIsLoading(false);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 25,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    { title: 'ID', key: 'id', width: 25, dataIndex: 'id', sorter: (a, b) => a.id - b.id },
    {
      title: 'Financial Reference',
      key: 'financialReference',
      width: 60,
      sorter: (a, b) => a.financialReference.localeCompare(b.financialReference),
      sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="flex flex-row justify-between items-center text-xs px-1">
            <Link to={`/purchase/${record.id}`}>{record.financialReference}</Link>
            {record.financialReference && <CopyButton text={record.financialReference} />}
          </TableCell>
        );
      }
    },
    {
      title: 'Invoice Number',
      key: 'invoiceNumber',
      className: 'invoice',
      width: 60,
      sorter: (a, b) => a.invoiceNumber.localeCompare(b.invoiceNumber),
      sortOrder: sortedInfo.columnKey === 'invoiceNumber' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="flex flex-row justify-between items-center text-xs">
            {/* <Link to={`/purchase/${record.id}`} color="black"> */}
            <div>{record.invoiceNumber}</div>
            {/* </Link> */}
            <CopyButton text={record.invoiceNumber} />
          </TableCell>
        );
      }
    },
    // {
    //   title: 'Ref No.',
    //   key: 'referenceNumber',
    //   width: 35,
    //   sorter: (a, b) => a.referenceNumber.localeCompare(b.referenceNumber),
    //   sortOrder: sortedInfo.columnKey === 'referenceNumber' ? sortedInfo.order : null,
    //   render: (record) => {
    //     return (
    //       <TableCell className="flex flex-row justify-between items-center text-xs">
    //         <Link to={`/purchase/${record.id}`} color="black">
    //           <div>{record.referenceNumber}</div>
    //         </Link>
    //         <CopyButton text={record.referenceNumber} />
    //       </TableCell>
    //     );
    //   }
    // },

    {
      title: 'Supplier',
      key: 'userName',
      width: 50,
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/purchase/${record.id}`} color="black">
          <TableCell>{record.userName}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Supplier Contact',
      key: 'supplierContact',
      width: 40,
      sorter: (a, b) => a.supplierContact.localeCompare(b.supplierContact),
      sortOrder: sortedInfo.columnKey === 'supplierContact' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.userContact}</TableCell>;
      }
    },
    {
      title: 'Agent Name',
      key: 'agentName',
      width: 40,
      sorter: (a, b) => a.agentName.localeCompare(b.agentName),
      sortOrder: sortedInfo.columnKey === 'agentName' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.agentName}</TableCell>;
      }
    },
    {
      title: 'Buyer Type',
      key: 'purchaseBuyerType',
      width: 30,
      sorter: (a, b) => a.purchaseBuyerType.localeCompare(b.purchaseBuyerType),
      sortOrder: sortedInfo.columnKey === 'purchaseBuyerType' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            {PurchaseBuyerType[record.purchaseBuyerType as keyof typeof PurchaseBuyerType]}
          </TableCell>
        );
      }
    },
    {
      title: 'Purchase Type',
      key: 'purchaseType',
      width: 30,
      sorter: (a, b) => a.purchaseType.localeCompare(b.purchaseType),
      sortOrder: sortedInfo.columnKey === 'purchaseType' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>{PurchaseType[record.purchaseType as keyof typeof PurchaseType]}</TableCell>
        );
      }
    },
    {
      title: 'Created By',
      key: 'createdBy',
      width: 30,
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === 'createdBy' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.createdByName}</TableCell>;
      }
    },

    {
      title: 'Location',
      key: 'locationName',
      width: 40,
      sorter: (a, b) => a.locationName.localeCompare(b.locationName),
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/purchase/${record.id}`} color="black">
          <TableCell>{record.locationName}</TableCell>

          // </Link>
        );
      }
    },
    {
      title: 'LCC Location',
      key: 'lccLocationName',
      width: 25,
      sorter: (a, b) => a.lccLocationName.localeCompare(b.lccLocationName),
      sortOrder: sortedInfo.columnKey === 'lccLocationName' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.lccLocationName}</TableCell>;
      }
    },
    {
      title: 'Date',
      key: 'date',
      width: 30,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },
    {
      title: 'Total',
      key: 'totalAmount',
      width: 30,
      dataIndex: 'initialAmount',
      sorter: (a, b) => a.initialAmount - b.initialAmount,
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (initialAmount) => {
        return (
          <TableCell className="text-xs text-right mr-2">
            {nepaliNumberFormatter(initialAmount)}
          </TableCell>
        );
      }
    },
    {
      title: 'Discount',
      key: 'manualDiscount',
      width: 25,
      dataIndex: 'manualDiscount',
      sorter: (a, b) => a.manualDiscount - b.manualDiscount,
      sortOrder: sortedInfo.columnKey === 'manualDiscount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="text-xs text-right mr-2">{nepaliNumberFormatter(record)}</TableCell>
        );
      }
    },
    {
      title: 'Shipping',
      key: 'shippingAmount',
      width: 25,
      dataIndex: 'shippingAmount',
      sorter: (a, b) => a.shippingAmount - b.shippingAmount,
      sortOrder: sortedInfo.columnKey === 'shippingAmount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="text-xs text-right mr-2">{nepaliNumberFormatter(record)}</TableCell>
        );
      }
    },
    {
      title: 'Shipping Tax',
      key: 'shippingTax',
      width: 25,
      dataIndex: 'shippingTax',
      sorter: (a, b) => a.shippingTax - b.shippingTax,
      sortOrder: sortedInfo.columnKey === 'shippingTax' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="text-xs text-right mr-2">{nepaliNumberFormatter(record)}</TableCell>
        );
      }
    },
    {
      title: 'TAX',
      key: 'totalVat',
      width: 25,
      dataIndex: 'totalVat',
      sorter: (a, b) => a.totalVat - b.totalVat,
      sortOrder: sortedInfo.columnKey === 'totalVat' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="text-xs text-right mr-2">{nepaliNumberFormatter(record)}</TableCell>
        );
      }
    },
    {
      title: 'Grand Total',
      key: 'grandtotalAmount',
      className: 'highlight',
      dataIndex: 'totalAmount',
      width: 40,
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortOrder: sortedInfo.columnKey === 'grandtotalAmount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="text-xs text-right mr-2">{nepaliNumberFormatter(record)}</TableCell>
        );
      }
    },
    {
      title: 'Paid',
      key: 'amountPaid',
      width: 25,
      dataIndex: 'amountPaid',
      sorter: (a, b) => a.amountPaid - b.amountPaid,
      sortOrder: sortedInfo.columnKey === 'amountPaid' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="text-xs text-right mr-2">{nepaliNumberFormatter(record)}</TableCell>
        );
      }
    },
    {
      title: 'Returned',
      key: 'amountReturned',
      dataIndex: 'amountReturned',
      width: 25,
      sorter: (a, b) => a.totalTax - b.totalTax,
      sortOrder: sortedInfo.columnKey === 'amountReturned' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="text-xs text-right mr-2">{nepaliNumberFormatter(record)}</TableCell>
        );
      }
    },

    {
      title: 'Print Count',
      width: 20,
      key: 'printcount',
      sorter: (a, b) => a.printcount.localeCompare(b.printcount),
      sortOrder: sortedInfo.columnKey === 'printcount' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell className="text-xs text-center">{record.printCount}</TableCell>;
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 15,
      fixed: 'right',
      render: (record) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [
          {
            key: '1',
            label: <CustomViewIcon link={`/purchase/${record.id}`} />
          },
          {
            key: '2',
            label: (
              <Tooltip title="Return" color="blue">
                <Link to={`/purchase/return/${record.id}`}>
                  <RollbackOutlined
                    type="danger"
                    style={{ transform: 'scale(1.4)', width: '100%' }}
                  />
                </Link>
              </Tooltip>
            )
          },
          {
            key: '3',
            label: <PurchasePaymentModal getInfo={getInfo} record={record} key={record.id} />
          },
          {
            key: '4',
            label: <PurchaseInvoicePrintModal record={record} getInfo={getInfo} key={record.id} />
          }
        ];
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '5',
            label: (
              <DiscussionChatModal
                slug="purchase"
                id={record.id}
                fromSellorPurahcaseid={record.userData.userId}
              />
            )
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [{ label: 'Purchases', link: '/purchase' }];

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              {
                <TableFilter
                  defaultValues={{
                    dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                    value: '',
                    paymentStatus: '',
                    purchaseStatus: '',
                    supplier: '',
                    locationId: preferenceLocationId ? preferenceLocationId : 1,
                    lccLocationId: null,
                    vendorId: [],
                    skip: 0,
                    count: 100
                  }}
                  initial={true}
                  onSubmit={onSubmitFilter}
                  styleforbuttons={'flex justify-end items-center'}
                  style={'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-6 gap-2'}
                  form={form}
                  buttons={
                    <>
                      {checkAccess('CREATE_PURCHASE') && (
                        <div>
                          <CustomButton
                            onClick={() => navigate('/procurements')}
                            text="Add"
                            backgroundColor="#1890ff"
                            Linkto="/procurements"
                          />
                        </div>
                      )}
                    </>
                  }>
                  <LocationSearchV2 hasParentFormItem={false} name="locationId" showAll />
                  <VendorSearchV2 hasParentFormItem={false} name="vendorId" isMultiple />
                  <AgentSearchV2
                    hasParentFormItem={false}
                    name="agentId"
                    onSelect={(val) => {
                      form.setFieldValue(['agentId'], val);
                    }}
                  />

                  <PurchaseStatus />
                  <PurchaseBuyerTypeComp />
                  <PurchaseTypeComp />
                  <PayoutStatus />
                  <UserSearchV2 hasParentFormItem={false} name="createdBy" />
                  <ProductCategorySearchV2
                    hasParentFormItem={false}
                    name="categoryId"
                    label="Product Category"
                  />

                  <LocationSearchV2
                    hasParentFormItem={false}
                    name="lccLocationId"
                    label="LCC Location"
                  />

                  <Form.Item name="value" label="Search">
                    <Input placeholder="Search" />
                  </Form.Item>
                </TableFilter>
              }
            </div>
          </>
        }>
        {/* <TableExportPrint allpurchaseList={allpurchaseList} /> */}
        <GenericTable
          columns={columns}
          tableName={'purchase-list'}
          data={allpurchaseList.results}
          buttons={<TableExportPrint allpurchaseList={allpurchaseList} />}
          hideDefaultPagination
          generateSummary
          summaryClassName="text-right !pr-3"
          excludeSummaryByKeys={['id']}
          scroll={{ x: 3000, y: '75vh' }}
          pagination={{
            page: page,
            total: allpurchaseList.total,
            size: size,
            onPagination,
            scrollToTop: true
          }}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default PurchaseList;
