import { Form, Select } from 'antd';
import { PurchaseBuyerType } from '../../../services/purchases/enums';

export const PurchaseBuyerTypeComp: React.FC = () => {
  return (
    <Form.Item label="Buyer Type" name="purchaseBuyerType">
      <Select placeholder="Select purchase buyer type" allowClear>
        {(Object.keys(PurchaseBuyerType) as Array<keyof typeof PurchaseBuyerType>).map((key) => (
          <Select.Option key={key} value={key}>
            {PurchaseBuyerType[key]}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
