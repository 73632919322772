import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Button, Descriptions, Form, message, PageHeader, Spin } from 'antd';

import AppContent from '@/components/Common/Content/Content';
import { get_routes_details } from '@/services/routes/queries';
import { CustomModal } from '@/components/Common/CustomModal';
import { ArrowRightOutlined } from '@ant-design/icons';
import { IRoutesList } from '@/services/routes/types';
import { move_all_addresses_mutation } from '@/services/users/mutations';
import getErrorMessage from '@/utils/getError';
import RouteSearchV2 from '@/components/Common/CustomSearch/Routes';
import { IcustomerData } from '@/services/users/types';
import { get_customer_list_route } from '@/services/users/queries';
import handlePagination from '@/utils/handlePagination';
import CustomerMigrateTable from './Table';

interface FormValues {
  routeId: number;
}

interface FormFilter {
  skip: number;
  count: number;
}

const DEFAULT_SIZE = 20;

function MoveAllAddressRoute() {
  const navigate = useNavigate();
  const { id: currentRouteId } = useParams();
  const [form] = Form.useForm<FormValues>();
  const [showConfirm, setShowConfirm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: DEFAULT_SIZE });
  const [customerList, setCustomerList] = useState<IcustomerData>({ total: 0, results: [] });

  const [isCustomerLoading, setIsCustomerLoading] = useState(false);

  const [selectedRoute, setSelectedRoute] = useState<IRoutesList>();
  const { isLoading, data } = useQuery(
    ['move_all_address_route'],
    async () => {
      const routeDetails = get_routes_details(Number(currentRouteId));
      const customerList = onCustomerFetch();

      const response = await Promise.all([routeDetails, customerList]);
      return response?.[0];
    },
    { enabled: !!currentRouteId }
  );

  async function onCustomerFetch() {
    await getInfo({ skip: 0, count: pagination.size });
    setPagination({ page: 1, size: DEFAULT_SIZE });
  }

  async function getInfo({ skip, count }: FormFilter) {
    try {
      setIsCustomerLoading(true);
      const routeId = Number(currentRouteId);
      const response = await get_customer_list_route(skip, count, '', routeId);
      setCustomerList(response.data);
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsCustomerLoading(false);
    }
  }

  const onFinish = async () => {
    try {
      setIsSubmitting(true);
      const values = await form.validateFields();
      const payload = { currentRouteId: Number(currentRouteId), newRouteId: values.routeId };

      await move_all_addresses_mutation(payload);
      navigate('/routes');
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  function onModalCancel() {
    setShowConfirm(false);
  }

  async function onConfirmClick() {
    await form.validateFields();
    setShowConfirm(true);
  }

  async function onModalOk() {
    setShowConfirm(false);
    onFinish();
  }

  function onRouteChange(routeId: number | string) {
    const newRouteId = parseInt(routeId as string);
    if (newRouteId === Number(currentRouteId)) {
      message.error('Please select different route');
      form.resetFields(['routeId']);
    }
  }

  const onPagination = async (page = 1, size = DEFAULT_SIZE, isSize = false) => {
    setIsCustomerLoading(true);
    const pagination = handlePagination({
      formValues: {},
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const skip = (pagination.page - 1) * pagination.size;
    await getInfo({ skip, count: pagination.size });
  };

  return (
    <Spin spinning={isLoading || isSubmitting}>
      <CustomModal
        title="Confirm Route Move"
        isModalOpen={showConfirm}
        setIsModalOpen={setShowConfirm}
        width="35%"
        footer={false}>
        <div className="flex-col gap-2">
          <div className="flex gap-2 w-full items-center">
            <div className="text-red-600 flex-1">{data?.name}</div>
            <ArrowRightOutlined />
            <div className="text-green-600 flex-1">{selectedRoute?.name}</div>
          </div>
          <div className="flex justify-end gap-2 mt-5">
            <Button type="default" onClick={onModalCancel}>
              Cancel
            </Button>
            <Button type="primary" onClick={onModalOk}>
              Confirm
            </Button>
          </div>
        </div>
      </CustomModal>

      <AppContent
        breadcrumbItems={[{ label: 'Routes', link: '/routes' }, { label: 'Move All Addresses' }]}>
        <Form form={form} layout="vertical">
          <p>Move all addresses from one route to another</p>

          <Descriptions bordered column={1}>
            <Descriptions.Item label="Current Route">{data?.name}</Descriptions.Item>
            <Descriptions.Item label="Total Customers">{customerList?.total}</Descriptions.Item>
          </Descriptions>

          <div className="mt-5">
            {/* <RouteSearch
              form={form}
              required
              onSelect={onRouteChange}
              setSelected={setSelectedRoute}
            /> */}

            <RouteSearchV2
              hasParentFormItem={false}
              name={'routeId'}
              label="Move to"
              required
              onSelect={onRouteChange}
              setSelected={(value) => setSelectedRoute(Array.isArray(value) ? value[0] : value)}
            />

            <div className="flex justify-end mt-5 gap-2">
              <Button>
                <Link to="/routes">Cancel</Link>
              </Button>
              <Button type="primary" onClick={onConfirmClick}>
                Submit
              </Button>
            </div>
          </div>

          <div className="mt-5">
            <PageHeader
              subTitle="Following customers will be moved to new route"
              style={{ padding: '8px 0' }}
            />
            <CustomerMigrateTable
              data={customerList}
              isLoading={isCustomerLoading}
              pagination={pagination}
              onPagination={onPagination}
            />
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default MoveAllAddressRoute;
