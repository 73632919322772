import { useQuery, useMutation } from '@tanstack/react-query';
import { Form, message } from 'antd';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  update_customer_mutation,
  update_users_mutation,
  update_vendor_mutation
} from '@/services/users/mutations';

import {
  delete_user,
  get_user_details,
  get_vendor_details,
  get_customer_details
} from '@/services/users/queries';
import { IUserUpdateNameAndPhoneRequest, IUserUpdateRequest } from '@/services/users/types';
import DetailsUI from '../../common/DetailsUI';
import { breadcrumbItem } from '../../common/types';
import { CapitalizeEachWord } from '@/utils/capitalizeEachWord';
const defaultValue: IUserUpdateRequest = {
  id: '',
  name: '',
  email: '',
  phone: '',
  password: '',
  avatarId: null
};

interface DetailsProps {
  breadcrumbItem?: breadcrumbItem;
}
const Details: React.FC<DetailsProps> = ({
  breadcrumbItem = {
    label: 'Users',
    link: '/users'
  }
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [allInfo, setAllInfo] = useState<any>();
  const [userDetails, setUserDetails] = useState<IUserUpdateRequest>(defaultValue);
  const [CustomerDetails, setCustomerDetails] = useState<any>();
  const [vendorDetails, setVendorDetails] = useState<any>();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [buttonloading, setButtonloading] = useState<boolean>(false);
  const breadcrumbItems = [
    breadcrumbItem,
    {
      label: `details/${userDetails.name}`,
      link: `/users/${id}`
    }
  ];

  const { refetch } = useQuery(
    ['users', id],
    async () => {
      if (breadcrumbItem.label == 'Customer') {
        return await get_customer_details((id && parseInt(id)) || 0);
      } else if (breadcrumbItem.label == 'Vendors') {
        return await get_vendor_details((id && parseInt(id)) || 0);
      } else {
        return await get_user_details((id && parseInt(id)) || 0);
      }
    },
    {
      retry: false,
      onSuccess: (data: any) => {
        setAllInfo(data);
        if (breadcrumbItem.label == 'Customer') {
          setCustomerDetails(data.customer);
          // console.log('data from Customers', data);
          data = data.user;
        } else if (breadcrumbItem.label == 'Vendors') {
          console.log('vendor details', data.vendor);
          setVendorDetails(data.vendor);
          data = data.user;
        }
        data.user.password = '';
        form.setFieldsValue(data.user);
        setUserDetails(data.user);
        setIsloading(false);
      }
    }
  );

  const updateProductsMutation = useMutation(update_users_mutation, {
    onSuccess: (data: any) => {
      message.success('User updated successfully');
      navigate(`${breadcrumbItem.link}`);
    },
    onError: (error: any) => {
      message.error(error?.response.data.message, 2);
      setIsloading(false);
      setButtonloading(false);
    }
  });

  const updateCustomersMutation = useMutation(update_customer_mutation, {
    onSuccess: (data: any) => {
      message.success('Customer updated successfully');
      navigate(`${breadcrumbItem.link}`);
    },
    onError: (error: any) => {
      console.log(error);
      message.error(error?.response.data.message, 2);
      setIsloading(false);
      setButtonloading(false);
    }
  });

  const updateVendorsMutation = useMutation(update_vendor_mutation, {
    onSuccess: (data: any) => {
      message.success('Vendor updated successfully');
      navigate(`${breadcrumbItem.link}`);
    },
    onError: (error: any) => {
      message.error(error?.response.data.message, 2);
      setIsloading(false);
      setButtonloading(false);
    }
  });

  const onFinish = () => {
    setIsloading(true);
    setButtonloading(true);
    userDetails.name = CapitalizeEachWord(userDetails.name);
    if (parseInt(userDetails.id) == 1) {
      message.error('This is admin cannot modify');
    } else {
      if (breadcrumbItem.label == 'Customer') {
        if (id) {
          userDetails.id = id;
          updateCustomersMutation.mutate(userDetails);
        }
      } else if (breadcrumbItem.label == 'Vendors') {
        if (id) {
          userDetails.id = id;
          userDetails.vendorTypeId = vendorDetails.vendorTypeId;
          updateVendorsMutation.mutate(userDetails);
        }
      } else {
        updateProductsMutation.mutate(userDetails);
      }
    }
  };

  const onNameAndPhoneUpdate = (props: IUserUpdateNameAndPhoneRequest) => {
    setIsloading(true);
    setButtonloading(true);
    if (props.name) {
      props.name = CapitalizeEachWord(props.name);
    }
    if (parseInt(props.id) == 1) {
      message.error('This is admin cannot modify');
    } else {
      if (breadcrumbItem.label == 'Customer') {
        if (id) {
          props.id = id;
          updateCustomersMutation.mutate(props);
        }
      } else if (breadcrumbItem.label == 'Vendors') {
        if (id) {
          props.id = id;
          props.vendorTypeId = vendorDetails.vendorTypeId;
          updateVendorsMutation.mutate(props);
        }
      } else {
        updateProductsMutation.mutate(props);
      }
    }
  };

  const deleteUserMutation = useMutation(delete_user, {
    onSuccess: () => {
      message.success('User deleted successfully');
      navigate('/users');
    },
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.response.data.message}`, 5);
    }
  });

  return (
    <div>
      <DetailsUI
        allInfo={allInfo}
        refetch={refetch}
        breadcrumbItems={breadcrumbItems}
        title="User Information"
        onNameAndPhoneUpdate={onNameAndPhoneUpdate}
        onFinish={onFinish}
        form={form}
        userDetails={userDetails}
        setUserDetails={setUserDetails}
        isLoading={isLoading}
        setIsloading={setIsloading}
        buttonloading={buttonloading}
        setButtonloading={setButtonloading}
        deleteUserMutation={deleteUserMutation}
        customerDetails={CustomerDetails}
        vendorDetails={vendorDetails}
      />
    </div>
  );
};

export default Details;
