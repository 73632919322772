import * as ExcelJS from 'exceljs';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import { IBalanceSheetWithTransaction, ITotalBalanceTypes } from '@/services/accounts/types';

interface Props {
  totalBalance: ITotalBalanceTypes;
  data: (IBalanceSheetWithTransaction & { date: string; miti: string })[];
  date: { startDate: string; endDate: string };
  valueExport: number;
  shownColumns: CheckboxValueType[];
}

const colorPalette = ['B0B0B0', 'D3D3D3', 'FFFFFF'];

export async function exportToExcel({
  totalBalance,
  data,
  date: { startDate, endDate },
  valueExport,
  shownColumns
}: Props) {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Sheet 1');

  const isColumnHidden = shownColumns.length > 0 && shownColumns.length < 3;

  let header = [
    'Particular',
    'Debit (Start)',
    'Credit (Start)',
    'Debit (Transaction)',
    'Credit (Transaction)',
    'Debit (End)',
    'Credit (End)',
    'Date',
    'Miti'
  ];

  let footer = [
    'Total',
    totalBalance.debitStart,
    totalBalance.creditStart,
    totalBalance.debitTransaction,
    totalBalance.creditTransaction,
    totalBalance.debitEnd,
    totalBalance.creditEnd,
    '',
    ''
  ];

  const rows: (string | number)[][] = data.map((item) => {
    const returnRow = [
      item.name,
      item.start?.debit || 0,
      item.start?.credit || 0,
      item.transaction?.totalDebit || 0,
      item.transaction?.totalCredit || 0,
      item.end?.debit || 0,
      item.end?.credit || 0,
      item.date,
      item.miti
    ];

    if (!isColumnHidden) return returnRow;

    return returnRow.filter((_, index) => {
      if (index >= 1 && index <= 6) {
        if (shownColumns.includes('O') && (index === 1 || index === 2)) return true;
        if (shownColumns.includes('T') && (index === 3 || index === 4)) return true;
        if (shownColumns.includes('C') && (index === 5 || index === 6)) return true;
      } else {
        return true;
      }
    });
  });

  if (shownColumns.length > 0 && shownColumns.length < 3) {
    header = header.filter((item) => {
      if (item.includes('Debit') || item.includes('Credit')) {
        if (shownColumns.includes('O') && item.includes('Start')) return true;
        if (shownColumns.includes('T') && item.includes('Trans')) return true;
        if (shownColumns.includes('C') && item.includes('End')) return true;
      } else {
        return true;
      }
    });

    footer = footer.filter((_, index) => {
      if (index >= 1 && index <= 6) {
        if (shownColumns.includes('O') && (index === 1 || index === 2)) return true;
        if (shownColumns.includes('T') && (index === 3 || index === 4)) return true;
        if (shownColumns.includes('C') && (index === 5 || index === 6)) return true;
      } else {
        return true;
      }
    });
  }

  const excelData = [header, ...rows];
  let dummySpace = [];

  if ([0, 3].includes(shownColumns.length)) {
    dummySpace = ['', '', '', '', '', ''];
  } else {
    for (let i = 0; i < Math.pow(2, shownColumns.length); i++) {
      dummySpace.push('');
    }
  }

  sheet.spliceRows(1, 0, ['Ledger'], [`From: ${startDate}`, ...dummySpace, '', `To: ${endDate}`]);

  if (shownColumns.length == 0 || shownColumns.length === 3) {
    sheet.mergeCells('A1:I1');
  } else if (shownColumns.length === 1) {
    sheet.mergeCells('A1:E1');
  } else if (shownColumns.length === 2) {
    sheet.mergeCells('A1:G1');
  }

  const mainHeadingCell = sheet.getCell('A1');
  mainHeadingCell.alignment = { horizontal: 'center' };
  mainHeadingCell.font = { bold: true };

  // Add data and format header row
  excelData.forEach((rowData, rowIndex) => {
    const row = sheet.addRow(rowData);
    // Make header row bold
    if (rowIndex === 0) {
      row.eachCell((cell) => {
        cell.font = { bold: true };
      });
    } else {
      const item = data[rowIndex - 1];

      row.eachCell((cell) => {
        const node = item.node ? item.node : 0;
        const colorIndex = node % colorPalette.length;
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: colorPalette[colorIndex] }
        };

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
    }
  });

  // Fill the first 3 rows with green color
  for (let i = 1; i <= 3; i++) {
    sheet.getRow(i).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '50C878' } // Green color
      };
    });
  }

  // Set cell widths
  const columnWidths = [30, 20, 20, 20, 20, 20, 20, 20, 20];
  columnWidths.forEach((width, index) => {
    sheet.getColumn(index + 1).width = width;
  });

  // Footer
  const lastRowIndex = sheet.rowCount;
  const footerRow = sheet.getRow(lastRowIndex + 1);

  footer.forEach((value, index) => {
    footerRow.getCell(index + 1).value = value;
  });

  footerRow.eachCell((cell) => {
    cell.font = { bold: true };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '50C878' }
    };
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
  });

  // Generate the Excel file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = valueExport === 1 ? 'Balance_Sheet_All.xlsx' : 'Balance_Sheet.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  });
}
