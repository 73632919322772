import http from '../../utils/http.utils';

export const get_source_list = async () => {
  const response = await http.get('data');
  return response;
};
export const get_source_id = async (id: number) => {
  const response = await http.get('data/' + id);
  return response;
};
