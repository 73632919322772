import moment from 'moment';
import { useState } from 'react';
import { Form, Menu } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

import UsersDB from '@/store/localstorage/UsersDB';
import AccountsDB from '@/store/localstorage/AccountsDB';
import { get_user_details } from '@/services/users/queries';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { IAccountOpeningTableData } from '@/services/accounts/types';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import { get_account_detail_by_ids, get_account_opening_list } from '@/services/accounts/queries';
import handlePagination from '@/utils/handlePagination';
import { ConvertObjectToURL } from '@/utils/converturl';
import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import { checkAccess } from '@/routes/acl';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { UsersSearch } from '@/components/Common/UsersSearch';
import { AccountsTypeSearch } from '@/components/Common/AccountsTypeSearch';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import AccountSearchV2 from '@/components/Common/CustomSearch/Accounts/AccountSearchV2';

function AccountFinancialYear() {
  const [form] = Form.useForm();
  const [isLoading, setIsloading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [financialHistory, setFinancialHistory] = useState({
    total: 0,
    results: [] as IAccountOpeningTableData[]
  });

  async function onSubmitFilter(filter: string) {
    await getInfo(filter);
    setPagination({ page: 1, size: 100 });
  }

  async function getInfo(filter = '') {
    try {
      setIsloading(true);
      const response = await get_account_opening_list(filter);
      const openingList = await Promise.all(
        response.results.map(async (result) => {
          const { createdBy, accountId } = result;

          let user = await UsersDB.getUser(createdBy);
          if (!user) {
            user = (await get_user_details(createdBy)).user;
            await UsersDB.addUsers([user]);
          }

          let account = await AccountsDB.getAccount(accountId);
          if (!account) {
            const accounts = await get_account_detail_by_ids([accountId]);
            await AccountsDB.addAccounts(accounts.results);
            account = await AccountsDB.getAccount(accountId);
          }

          return {
            ...result,
            accountName: account.name,
            createdUser: user?.name
          };
        })
      );

      setFinancialHistory({ total: response.total, results: openingList });
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  }

  const columns: ColumnsType<IAccountOpeningTableData> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 3,
      render: (_, __, index) => <span>{index + 1}</span>,
      fixed: 'left'
    },
    {
      title: 'Account',
      width: 8,
      dataIndex: 'accountName',
      key: 'accountName',
      render(value, record) {
        return <Link to={`/accounts/opening/${record.id}`}>{value}</Link>;
      }
    },
    {
      title: 'History Balance',
      width: 4,
      dataIndex: 'historyBalance',
      key: 'historyBalance',
      render: (text) => nepaliNumberFormatter(text)
    },
    {
      title: 'New Balance',
      width: 4,
      dataIndex: 'newBalance',
      key: 'newBalance',
      render: (text) => nepaliNumberFormatter(text)
    },
    {
      title: 'Created At',
      width: 5,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment(text).format(DEFAULT_DATE_FORMAT)
    },
    { title: 'Created By', width: 5, dataIndex: 'createdUser', key: 'createdBy' },
    {
      title: 'Actions',
      key: 'actions',
      width: 3,
      fixed: 'right',
      render: (record) => {
        const menuItems = [
          {
            key: 'menu-view',
            label: <CustomViewIcon link={`/accounts/opening/${record.id}`} title="View" />
          }
        ];

        return <ActionDropdown menu={<Menu items={menuItems} />} />;
      }
    }
  ];

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsloading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  return (
    <AppContent
      breadcrumbItems={[
        { label: 'Accounts', link: '/accounts' },
        { label: 'Accounts Opening', link: '/accounts/opening' }
      ]}
      withfilter
      button={
        <TableFilter
          initial
          form={form}
          onSubmit={onSubmitFilter}
          defaultValues={{
            dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
            skip: 0,
            count: 100,
            createdBy: '',
            accountId: ''
          }}
          styleforbuttons={'flex justify-end items-center'}
          style={'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-6 gap-2'}
          buttons={
            <>
              {checkAccess('CREATE_ACCOUNT') && (
                <CustomButton text="Add" backgroundColor="#1890ff" Linkto="/accounts/opening/new" />
              )}
            </>
          }>
          <UserSearchV2 showAll hasParentFormItem={false} name={'createdBy'} label="Created By" />
          <AccountSearchV2 showAll hasParentFormItem={false} name={'accountId'} label="Account" />
        </TableFilter>
      }>
      <CustomizeTable
        columns={columns}
        data={financialHistory.results}
        usersLoading={isLoading}
        notshowPagination={true}
        customScroll={{ x: 1000, y: '75vh' }}
        paginationDatas={{
          page: pagination.page,
          total: financialHistory.total,
          size: pagination.size,
          onPagination
        }}
        tableName={'expense-list'}
      />
    </AppContent>
  );
}

export default AccountFinancialYear;
