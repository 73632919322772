import { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { Button, Divider, Form, Select, message } from 'antd';
import { get_customer_group_list } from '../../../services/offfers/queries';
import { ICustomerGroupListData } from '../../../services/offfers/types';

interface CustomerGroupSearchProps {
  formData?: {
    formName: string | (string | number)[];
    label: string;
  };
  isAll?: boolean;
  required?: boolean;
  disabled?: boolean;
  onSelect?: (value: number) => void;
  customerGroupList: ICustomerGroupListData[];
  setCustomerGroupList: React.Dispatch<React.SetStateAction<ICustomerGroupListData[]>>;
}

const { Option } = Select;

export const CustomerGroupSearch: React.FC<CustomerGroupSearchProps> = ({
  formData,
  isAll = true,
  required = false,
  disabled = false,
  customerGroupList,
  setCustomerGroupList
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    //searchCustomerGroupIndexDB(debouncedSearchValue);
    searchCustomerGroup(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const [customerGroupSearch, setCustomerGroupSearch] = useState<ICustomerGroupListData[]>([]);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);

  //   const searchCustomerGroupIndexDB = async (value: string) => {
  //       try {

  //     } catch (error) {
  //       message.error(error as string);
  //     }
  //     };

  const searchCustomerGroup = async (value: string) => {
    try {
      const response = await get_customer_group_list(0, 10, value);

      if (!response || response.results.length == 0) {
        message.error('Cannot find customer group with that name!');
        setCustomerGroupSearch([]);
        setIsMore(false);
      } else {
        setCustomerGroupSearch(response.results);
      }

      checkCustomerGroupAndAdd(response.results);
      //console.log('response', response);
      if (response.results.length < 10) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
    } catch (error) {
      console.error(error);
    }
    setIsCacheResponse(false);
    setSkip(10);
  };

  const searchMoreCustomerGroup = async (value: string) => {
    try {
      const response = await get_customer_group_list(skip, 10, value);

      if (!response || response.results.length == 0) {
        message.info('Cannot find more customer group with that value in server!');
        setIsMore(false);
      } else {
        setSkip(skip + 10);
        setCustomerGroupSearch([...customerGroupSearch, ...response.results]);
        checkCustomerGroupAndAdd(response.results);
        if (response.results.length < 10) {
          setIsMore(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkCustomerGroupAndAdd = (customerGroup: ICustomerGroupListData[]) => {
    if (customerGroupList.length > 0) {
      customerGroup = customerGroup.filter((value) => {
        const searchCustomerGroup = customerGroupSearch.find((val) => val.id == value.id);
        if (searchCustomerGroup) return false;
        return true;
      });
      if (customerGroup.length > 0)
        setCustomerGroupList((prevValue) => {
          customerGroup = customerGroup.filter((value) => {
            const searchAccount = prevValue.find((val) => val.id == value.id);
            if (searchAccount) return false;
            return true;
          });
          return [...prevValue, ...customerGroup];
        });
    } else {
      setCustomerGroupList(customerGroup);
    }
  };

  const options = customerGroupSearch.map((value) => (
    <Option key={value.id} value={value.id}>
      {value.name}
    </Option>
  ));

  return (
    <>
      {formData ? (
        <Form.Item
          name={formData.formName}
          label={formData.label}
          rules={[
            {
              required: required,
              message: 'Please add an account!'
            }
          ]}>
          <Select
            showSearch
            allowClear
            disabled={disabled}
            placeholder="Select an account type"
            defaultActiveFirstOption={false}
            showArrow={false}
            autoClearSearchValue={true}
            filterOption={false}
            dropdownMatchSelectWidth={false}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                {isCacheResponse ? (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {/* <div
                  style={{
                    color: 'green',
                    width: '100%',
                    textAlign: 'center'
                  }}>{`Cache found ${productSearch.length} data`}</div> */}
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchCustomerGroup(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Pull More & Sync'}
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {isMore ? (
                      <Button
                        type="text"
                        style={{
                          color: 'blue',
                          width: '100%'
                        }}
                        onClick={() => {
                          searchMoreCustomerGroup(searchValue);
                        }}>
                        {/* {'Sync & Search on Server'} */}
                        {'Get More...'}
                      </Button>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center'
                        }}>
                        No more data...
                      </div>
                    )}
                  </div>
                )}
              </>
            )}>
            {isAll ? <Option value="">All</Option> : null}
            {options}
          </Select>
        </Form.Item>
      ) : (
        <Select
          showSearch
          allowClear
          disabled={disabled}
          placeholder="Select an account type"
          defaultActiveFirstOption={false}
          showArrow={false}
          autoClearSearchValue={true}
          filterOption={false}
          dropdownMatchSelectWidth={false}
          onSearch={(val) => {
            setSearchValue(val);
          }}
          notFoundContent={null}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              {isCacheResponse ? (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {/* <div
                  style={{
                    color: 'green',
                    width: '100%',
                    textAlign: 'center'
                  }}>{`Cache found ${productSearch.length} data`}</div> */}
                  <Button
                    type="text"
                    style={{
                      color: 'blue',
                      width: '100%'
                    }}
                    onClick={() => {
                      searchCustomerGroup(searchValue);
                    }}>
                    {/* {'Sync & Search on Server'} */}
                    {'Pull More & Sync'}
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {isMore ? (
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchMoreCustomerGroup(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Get More...'}
                    </Button>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center'
                      }}>
                      No more data...
                    </div>
                  )}
                </div>
              )}
            </>
          )}>
          {isAll ? <Option value="">All</Option> : null}
          {options}
        </Select>
      )}
    </>
  );
};
