import { Button, Divider, Form, message, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { get_product_list, get_product_list_categoryId } from '../../../services/products/queries';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import { IProductDetails } from '../../../services/products/types';
import { getUserData } from '../../../utils/auth.utils';

interface Iprops {
  formData?: {
    formName: string | (string | number)[];
    label?: string;
  };
  locationId?: number;
  onProductChange?: (val: number, name?: any) => any;
  // productList: IProductDetails[];
  // setProductList: React.Dispatch<React.SetStateAction<IProductDetails[]>>;
  productSearch: IProductDetails[];
  setProductSearch: React.Dispatch<React.SetStateAction<IProductDetails[]>>;
  disabled?: boolean;
  required?: boolean;
  noall?: boolean;
  productCategory?: number | string | null;
}

const ProductSearch2 = ({
  formData,
  onProductChange,
  productSearch,
  locationId,
  setProductSearch,
  disabled = false,
  required = false,
  noall = false,
  productCategory
}: Iprops) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);
  const user = getUserData();
  const { Option } = Select;
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);

  if (!locationId) {
    const { preferences } = getUserData();
    locationId = preferences?.preferences
      ? JSON.parse(preferences?.preferences)?.locationId
      : message.error('Please choose a location from preferences!');
  }

  useEffect(() => {
    searchProductIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);

  useEffect(() => {
    searchProductIndexDB(debouncedSearchValue);
  }, [productCategory]);

  const searchProductIndexDB = async (value: string) => {
    try {
      let response = await ProductsDB.searchProduct(value);
      // console.log('productCategory', productCategory);
      if (productCategory) {
        if (productCategory !== 'default') {
          response = response.filter((val) => val.categoryId === productCategory);
        }
      }
      // console.log('Response-->', response);
      // console.log(productSearch);
      // console.log(response);
      // console.log('this', locationId);
      if (!response || response.length == 0) {
        message.info('Cannot find any product with that name in cache, searching in server...');
        searchProduct(value);
      } else {
        setProductSearch(response);
        // checkProductAndAdd(response);
        setIsCacheResponse(true);
      }
    } catch (error) {
      message.error(error as string);
    }
  };
  const searchProduct = async (value: string) => {
    let response;
    try {
      if (productCategory && typeof productCategory === 'number') {
        response = await get_product_list_categoryId(0, 10, value, productCategory);
      } else {
        response = await get_product_list(0, 10, value);
      }
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any product with that name!');
      setProductSearch([]);
      setIsMore(false);
    } else {
      setProductSearch(response.data.results);
      // checkProductAndAdd(response.data.results);
      ProductsDB.addProducts(response.data.results);
      if (response.data.results.length < 10) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
    }
    setIsCacheResponse(false);
    setSkip(10);

    // setSearchLoading(false);
  };

  const searchMoreProduct = async (value: string) => {
    let response;
    try {
      if (productCategory && typeof productCategory === 'number') {
        response = await get_product_list_categoryId(skip, 10, value, productCategory);
      } else {
        response = await get_product_list(skip, 10, value);
      }
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.info('Cannot find more products with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);
      setProductSearch([...productSearch, ...response.data.results]);
      // checkProductAndAdd(response.data.results);
      ProductsDB.addProducts(response.data.results);

      if (response.data.results.length < 10) {
        setIsMore(false);
      }
    }
  };

  // const checkProductAndAdd = (products: IProductDetails[]) => {
  //   if (productList.length > 0) {
  //     products = products.filter((value) => {
  //       const searchProduct = productSearch.find((val) => val.id == value.id);
  //       if (searchProduct) return false;
  //       return true;
  //     });
  //     console.log('products', products);
  //     if (products.length > 0)
  //       setProductList((prevValue) => {
  //         return [...prevValue, ...products];
  //       });
  //   } else {
  //     setProductList(products);
  //   }
  // };

  const options = productSearch
    .filter(
      (val) =>
        !val.hidden &&
        val.status.some((el: any) =>
          locationId ? el.locationId === locationId && !el.hidden : !el.hidden
        )
    )
    .map((d) => (
      <Option key={d.id} value={d.id}>
        <div
          onClick={() => {
            message.destroy('product_missing_default_unit');
            const hasDefaultUnit = d.productUnits?.find((unit) => unit.isDefault);
            if (!hasDefaultUnit) {
              message.warning({
                key: 'product_missing_default_unit',
                content: 'This product does not have a default unit.',
                duration: 5
              });
            }
          }}>{`${d.name} (${d.sku})`}</div>
      </Option>
    ));

  return (
    <>
      {formData ? (
        <Form.Item
          name={formData.formName}
          label={formData.label ? formData.label : 'Product'}
          rules={[
            {
              required: required,
              message: 'Please choose a product!'
            }
          ]}
          initialValue={null}>
          <Select
            dropdownMatchSelectWidth={false}
            showSearch
            placeholder={'Search product'}
            defaultActiveFirstOption={false}
            style={{ width: '100%' }}
            autoClearSearchValue={true}
            showArrow={false}
            filterOption={false}
            disabled={disabled}
            // onClick={(val) => {
            //   console.log(val);
            //   console.log('clicked');
            // }}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            onSelect={async (val: any) => {
              if (onProductChange) await onProductChange(val);
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                {isCacheResponse ? (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {/* <div
                  style={{
                    color: 'green',
                    width: '100%',
                    textAlign: 'center'
                  }}>{`Cache found ${productSearch.length} data`}</div> */}
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchProduct(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Pull More & Sync'}
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {isMore ? (
                      <Button
                        type="text"
                        style={{
                          color: 'blue',
                          width: '100%'
                        }}
                        onClick={() => {
                          searchMoreProduct(searchValue);
                        }}>
                        {/* {'Sync & Search on Server'} */}
                        {'Get More...'}
                      </Button>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center'
                        }}>
                        No more data...
                      </div>
                    )}
                  </div>
                )}
              </>
            )}>
            {noall ? <></> : <Option value="">All</Option>}
            {options}
          </Select>
        </Form.Item>
      ) : (
        <Select
          dropdownMatchSelectWidth={false}
          showSearch
          placeholder={'Search product'}
          defaultActiveFirstOption={false}
          style={{ width: '100%' }}
          autoClearSearchValue={true}
          // value={''}
          showArrow={false}
          filterOption={false}
          disabled={disabled}
          // onClick={(val) => {
          //   console.log(val);
          //   console.log('clicked');
          // }}
          onSearch={(val) => {
            setSearchValue(val);
          }}
          onSelect={async (val: any) => {
            if (onProductChange) await onProductChange(val);
          }}
          notFoundContent={null}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              {isCacheResponse ? (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {/* <div
                  style={{
                    color: 'green',
                    width: '100%',
                    textAlign: 'center'
                  }}>{`Cache found ${productSearch.length} data`}</div> */}
                  <Button
                    type="text"
                    style={{
                      color: 'blue',
                      width: '100%'
                    }}
                    onClick={() => {
                      searchProduct(searchValue);
                    }}>
                    {/* {'Sync & Search on Server'} */}
                    {'Pull More & Sync'}
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {isMore ? (
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchMoreProduct(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Get More...'}
                    </Button>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center'
                      }}>
                      No more data...
                    </div>
                  )}
                </div>
              )}
            </>
          )}>
          {noall ? <></> : <Option value="">All</Option>}
          {options}
        </Select>
      )}
    </>
  );
};

export default ProductSearch2;
