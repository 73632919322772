export enum AccountRule {
  'CREDIT_ADD' = 'Add to Credit',
  'CREDIT_SUBTRACT' = 'Subtract from Credit',
  'DEBIT_ADD' = 'Add to Debit',
  'DEBIT_SUBTRACT' = 'Subtract from Debit'
}

export enum JournalLinesEnum {
  USER = 'USER',
  VENDOR = 'VENDOR',
  CUSTOMER = 'CUSTOMER',
  ACCOUNT = 'ACCOUNT'
}

export enum UserAccountType {
  USER_PARENT = 'USER_PARENT',
  CUSTOMER_PARENT = 'CUSTOMER_PARENT',
  VENDOR_PARENT = 'VENDOR_PARENT'
}

export enum AccountRuleCredit {
  NONE = 'NONE',
  CREDIT_ADD = 'Add to Credit',
  CREDIT_SUBTRACT = 'Subtract from Credit'
}
export enum AccountRuleDebit {
  NONE = 'NONE',
  DEBIT_ADD = 'Add to Debit',
  DEBIT_SUBTRACT = 'Subtract from Debit'
}

export enum AccountRulesEvent {
  SELL_CREATE = 'SELL_CREATE',
  // SELL_ADD_PAYMENT = 'SELL_ADD_PAYMENT',
  SELL_PAYMENT_CASH = 'SELL_PAYMENT_CASH',
  SELL_PAYMENT_BANK = 'SELL_PAYMENT_BANK',
  SELL_PAYMENT_OTHER = 'SELL_PAYMENT_OTHER',

  SELL_RETURN = 'SELL_RETURN',
  PURCHASE_CREATE = 'PURCHASE_CREATE',
  // PURCHASE_ADD_PAYMENT = 'PURCHASE_ADD_PAYMENT',
  PURCHASE_PAYMENT_CASH = 'PURCHASE_PAYMENT_CASH',
  PURCHASE_PAYMENT_USER = 'PURCHASE_PAYMENT_USER',
  PURCHASE_PAYMENT_OTHER = 'PURCHASE_PAYMENT_OTHER',
  PURCHASE_RETURN = 'PURCHASE_RETURN',
  EXPENSE_CREATE = 'EXPENSE_CREATE',
  EXPENSE_ADD_PAYMENT = 'EXPENSE_ADD_PAYMENT',
  ADJUSTMENT_CREATE = 'ADJUSTMENT_CREATE',
  TRANSFER_FROM = 'TRANSFER_FROM',
  TRANSFER_TO = 'TRANSFER_TO',
  VAT_CREATE = 'VAT_CREATE',
  VEHICLE_FUEL_CREATE = 'VEHICLE_FUEL_CREATE',
  VEHICLE_MAINTENANCE_CREATE = 'VEHICLE_MAINTENANCE_CREATE'
}

export enum AccountType {
  OTHER = 'OTHER',
  BANK = 'BANK',
  USER = 'USER',
  TRANSFER_INITIATE = 'TRANSFER_INITIATE',
  TRANSFER_RECEIVE = 'TRANSFER_RECEIVE',
  USER_PARENT = 'USER_PARENT',
  VENDOR_PARENT = 'VENDOR_PARENT',
  CUSTOMER_PARENT = 'CUSTOMER_PARENT'
}

export const redirectData = [
  {
    key: AccountRulesEvent.SELL_CREATE,
    link: '/sell/'
  },
  {
    key: AccountRulesEvent.SELL_PAYMENT_CASH,
    link: '/sell/'
  },
  {
    key: AccountRulesEvent.SELL_PAYMENT_BANK,
    link: '/sell/'
  },
  {
    key: AccountRulesEvent.SELL_PAYMENT_OTHER,
    link: '/sell/'
  },
  {
    key: AccountRulesEvent.SELL_RETURN,
    link: '/sell/'
  },
  {
    key: AccountRulesEvent.PURCHASE_CREATE,
    link: '/purchase/'
  },
  {
    key: AccountRulesEvent.PURCHASE_PAYMENT_CASH,
    link: '/purchase/'
  },
  {
    key: AccountRulesEvent.PURCHASE_PAYMENT_USER,
    link: '/purchase/'
  },
  {
    key: AccountRulesEvent.PURCHASE_PAYMENT_OTHER,
    link: '/purchase/'
  },
  {
    key: AccountRulesEvent.PURCHASE_RETURN,
    link: '/purchase/'
  },
  {
    key: AccountRulesEvent.TRANSFER_FROM,
    link: '/transfers/view/'
  },
  {
    key: AccountRulesEvent.TRANSFER_TO,
    link: '/transfers/view/'
  },
  {
    key: AccountRulesEvent.ADJUSTMENT_CREATE,
    link: '/adjustments/'
  },
  {
    key: AccountRulesEvent.EXPENSE_CREATE,
    link: '/expense/'
  }
];

export const ruleInformation = [
  {
    key: AccountRulesEvent.SELL_CREATE,
    info: 'Debit will be customer account. If not found, debit will be the account set in this rule, credit will be the Credit Account in this rule.'
  },
  {
    key: AccountRulesEvent.SELL_PAYMENT_BANK,
    info: 'Debit account will be bank account. Credit Account will be Customer Account or the account set in this rule.'
  },
  {
    key: AccountRulesEvent.SELL_PAYMENT_CASH,
    info: 'Credit will be Customer Account or the account set in this rule. Debit will be the set account in this rule.'
  },
  {
    key: AccountRulesEvent.SELL_PAYMENT_OTHER,
    info: 'Credit will be Customer Account or the account set in this rule. Debit will be the set account in this rule.'
  },
  {
    key: AccountRulesEvent.PURCHASE_CREATE,
    info: 'Credit will be vendor account. If vendor account is not found, credit will be the Credit Account set in this rule, debit will be the Debit Account in this rule.'
  },
  {
    key: AccountRulesEvent.PURCHASE_PAYMENT_USER,
    info: 'Credit Account will be Agent Account (USER). Debit Account will be Vendor Account or the account set in this rule.'
  },
  {
    key: AccountRulesEvent.PURCHASE_PAYMENT_CASH,
    info: 'Debit will be Vendor Account or the account set in this rule. Credit will be the set account in this rule.'
  },
  {
    key: AccountRulesEvent.PURCHASE_PAYMENT_OTHER,
    info: 'Debit will be Vendor Account or the account set in this rule. Credit will be the set account in this rule.'
  },
  {
    key: AccountRulesEvent.TRANSFER_FROM,
    info: 'Specific Location is auto selected on debit.'
  },
  {
    key: AccountRulesEvent.TRANSFER_TO,
    info: 'Specific Location is auto selected on credit.'
  }
];
