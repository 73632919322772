import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Checkbox,
  Form,
  FormInstance,
  Input,
  message,
  PageHeader,
  Select,
  SelectProps
} from 'antd';

import getPascalCase from '@/utils/getPascalCase';
import ChartFeaturesForm from '../ChartFeaturesForm';
import MiscellaneousFeaturesForm from '../MiscellaneousFeature';
import { IReportWithFields } from '@/services/report/types';

import {
  IChartType,
  ICompareFieldValue,
  IWidgetConfiguration
} from '@/services/dashboard/v3/types';
import { useDashboardCustomize } from '@/contexts/dashboard-customize.context';

import {
  DEFAULT_BORDER_RADIUS,
  DEFAULT_ENLARGE,
  DEFAULT_GRADIENT,
  DEFAULT_SHOW_FILTER,
  DEFAULT_SHOW_ZOOM,
  reportCompareRequiredFields
} from '../../../constant.dashboard';
import { ChildComponentRef } from './WidgetCustomizeModalv2';

interface Props {
  form: FormInstance;
  selectedWidget: { id: string; position: number; isCustom: boolean } | null;
}

function SingleCustomizeUI({ form, selectedWidget }: Props, ref: React.Ref<ChildComponentRef>) {
  const [possibleDatasets, setPossibleDatasets] = useState<string[]>([]);
  const [possiblePlotAgainsts, setPossiblePlotAgainsts] = useState<
    { key: string; dataType: string }[]
  >([]);
  const [selectedReport, setSelectedReport] = useState<IReportWithFields | undefined>(undefined);
  const [selectedChartType, setSelectedChartType] = useState<IChartType | undefined>(undefined);

  const { reportLists, editingWidgets } = useDashboardCustomize();
  const [allReportLists, setAllReportLists] = useState<IReportWithFields[]>([]);

  const [selectedReportCompareField, setSelectedReportCompareField] = useState<
    ICompareFieldValue[]
  >([]);

  const [isComparable, setIsComparable] = useState(false);

  useImperativeHandle(ref, () => ({ onSubmit }));

  useEffect(() => {
    setAllReportLists(reportLists);
  }, [reportLists]);

  useEffect(() => {
    if (!selectedWidget || selectedWidget.isCustom) {
      form.resetFields();
      form.setFieldsValue({
        selectedWidget: '',
        title: '',
        showFilter: DEFAULT_SHOW_FILTER,
        showZoom: DEFAULT_SHOW_ZOOM,
        enlargeChart: DEFAULT_ENLARGE,
        isMultiple: false
      });
      return;
    }

    const selectedWidgetConfig = editingWidgets[selectedWidget.position];
    const { widgets, ...rest } = selectedWidgetConfig;

    setSelectedChartType(rest.chartType);
    onSelectWidget(widgets[0].reportId, false);

    const isComparable = Boolean(selectedWidgetConfig.comparable);
    setIsComparable(isComparable);

    form.setFieldsValue({
      ...rest,
      selectedWidget: widgets[0].reportId,
      plotAgainst: widgets[0].plotAgainst,
      fields: widgets[0].field,
      isComparable
    });
  }, [selectedWidget]);

  const handleDatasetsChange: SelectProps['onChange'] = (values) => {
    if ((selectedChartType !== 'pie' && !isComparable) || values.length < 2) return;

    // restrict the user to select only one field for pie chart
    form.setFieldsValue({ fields: [values[values.length - 1]] });
  };

  function handleSearch(value: string) {
    const filteredReports = reportLists.filter((report) => {
      const isShown = report.name.toLowerCase().includes(value.toLowerCase());
      if (!report.isComparable) return isShown;

      return isShown || 'comparable'.includes(value.toLowerCase());
    });

    setAllReportLists(filteredReports);
  }

  const onSelectWidget = (value: number, changeFormField?: boolean) => {
    form.resetFields();
    form.setFieldValue('isMultiple', false);
    const selectedReport = reportLists.find((report) => report.id === value);
    setSelectedReport(selectedReport);
    if (!selectedReport) return;

    const comparedField = reportCompareRequiredFields?.[value] || [];
    setSelectedReportCompareField(comparedField);

    const allFieldsOfSelectedReport = selectedReport.fields;
    const fieldsWithOnlyNumber = Object.keys(allFieldsOfSelectedReport).filter(
      (field) => allFieldsOfSelectedReport[field] === 'number'
    );

    const fieldsWithoutNumber = Object.keys(allFieldsOfSelectedReport)
      .filter((field) => ['string', 'date'].includes(allFieldsOfSelectedReport[field]))
      .map((field) => ({ key: field, dataType: allFieldsOfSelectedReport[field] }));

    if (fieldsWithoutNumber.length === 0) {
      message.error('No fields to plot against found!');
    }

    setPossibleDatasets(fieldsWithOnlyNumber);
    setPossiblePlotAgainsts(fieldsWithoutNumber);
    changeFormField &&
      form.setFieldsValue({
        enlargeChart: DEFAULT_ENLARGE,
        title: selectedReport.name,
        selectedWidget: value
      });
  };

  const handleChartChange = (value: IChartType) => {
    setSelectedChartType(value);
    const currentSelectedDatasets = (form.getFieldValue('fields') as string[]) || [];

    if (value === 'pie') {
      message.info('Only one field is allowed for Pie Chart');
      form.setFieldsValue({
        fields: currentSelectedDatasets.slice(0, 1),
        pieChartLimit: 5,
        isSmooth: undefined,
        isGradient: undefined,
        borderRadius: undefined,
        showZoom: false
      });

      return;
    }

    form.setFieldsValue({
      plotAgainst: undefined,
      pieChartLimit: undefined,
      isGradient: DEFAULT_GRADIENT,
      showZoom: DEFAULT_SHOW_ZOOM,
      showFilter: DEFAULT_SHOW_FILTER,
      isSmooth: value === 'line' ? DEFAULT_SHOW_ZOOM : undefined,
      borderRadius: value === 'bar' ? DEFAULT_BORDER_RADIUS : undefined
    });
  };

  function handleIsComparableChange(isChecked: boolean) {
    setIsComparable(isChecked);

    if (!isChecked) {
      form.setFieldsValue({ comparable: undefined });
      return;
    }

    if (selectedChartType === 'pie') {
      message.info('Only line and bar charts are allowed for Comparable. Switching to line chart');
      handleChartChange('line');
      form.setFieldsValue({ chartType: 'line' });
    }

    const selectedDatasets = (form.getFieldValue('fields') as string[]) || [];
    if (selectedDatasets.length > 1) {
      message.info('Only one field is allowed for Comparable');
      form.setFieldsValue({ fields: selectedDatasets.slice(0, 1) });
    }
  }

  async function onSubmit() {
    const values = await form.validateFields();
    if (!selectedWidget || !selectedReport) {
      message.error('This should not happen!. Please consult the developer!');
      return;
    }

    // Check if the widget is already present in the dashboard. Exclude the current widget
    const isWidgetAlreadyPresent = editingWidgets.some(
      (widget, index) => widget.id === values.id && index !== selectedWidget.position
    );

    if (isWidgetAlreadyPresent) {
      return message.error('Widget is already present in the dashboard. Edit the existing one.');
    }

    const isPlotAgainstDate =
      possiblePlotAgainsts.find((plot) => plot.key === values.plotAgainst)?.dataType === 'date';

    const widgetConfig: Partial<IWidgetConfiguration> = {
      id: selectedWidget.id,
      chartType: values.chartType,
      title: values.title,
      showFilter: values.showFilter,
      showZoom: values.showZoom,
      type: selectedReport?.type,
      isGradient: values.isGradient,
      isSmooth: values.isSmooth,
      borderRadius: values.borderRadius,
      comparable: isComparable ? values.comparable : undefined,
      pieChartLimit: values.pieChartLimit,
      isPlotAgainstDate,
      isCustom: false,
      enlargeChart: values.enlargeChart,
      widgets: [
        {
          reportId: values.selectedWidget,
          name: selectedReport.name,
          field: values.fields,
          plotAgainst: values.plotAgainst || ''
        }
      ]
    };

    return widgetConfig;
  }

  return (
    <div>
      <PageHeader subTitle="Select Widget" style={{ padding: '8px 0' }} />
      <Form.Item
        name="selectedWidget"
        label="Widget"
        rules={[{ required: true, message: 'Widget is required' }]}>
        <Select
          showSearch
          allowClear
          className="w-full"
          filterOption={false}
          onSearch={handleSearch}
          onClear={() => handleSearch('')}
          onSelect={(val: number) => onSelectWidget(val, true)}
          placeholder="Select a widget"
          dropdownMatchSelectWidth={false}>
          {allReportLists.map((widget) => (
            <Select.Option key={widget.id} value={widget.id}>
              {widget.name} {widget.isComparable ? '(Comparable)' : ''}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {selectedReport && (
        <div className="mt-2">
          <PageHeader subTitle="Customize Widget" style={{ padding: '8px 0' }} />
          <div className="space-y-2">
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Title is required' }]}>
              <Input placeholder="Title" className="mt-1" />
            </Form.Item>

            <Form.Item
              name="fields"
              label="Datasets"
              rules={[{ required: true, message: 'Select at least one field' }]}>
              <Select
                mode="multiple"
                onChange={handleDatasetsChange}
                className="w-full"
                placeholder="Select fields"
                dropdownMatchSelectWidth={false}
                options={possibleDatasets.map((field) => ({
                  label: getPascalCase(field),
                  value: field
                }))}
              />
            </Form.Item>

            <Form.Item
              name="chartType"
              label="Chart Type"
              rules={[{ required: true, message: 'Chart is required!' }]}>
              <Select
                className="w-full"
                placeholder="Select chart type"
                onSelect={handleChartChange}
                dropdownMatchSelectWidth={false}
                options={['line', 'bar', 'pie'].map((chart) => ({
                  label: getPascalCase(chart),
                  value: chart,
                  disabled: chart === 'pie' && isComparable
                }))}
              />
            </Form.Item>

            <Form.Item
              name="plotAgainst"
              label="Plot Chart Against"
              rules={[{ required: true, message: 'Select field to plot against' }]}>
              <Select
                showSearch
                className="w-full"
                optionFilterProp="label"
                placeholder="Select field to plot against"
                dropdownMatchSelectWidth={false}
                options={possiblePlotAgainsts
                  .filter((plotAgainst) => {
                    if (!selectedChartType || selectedChartType === 'pie') return true;

                    // For linechart and barchart, only allow date fields
                    return plotAgainst.dataType === 'date';
                  })
                  .map((field) => ({
                    label: `${getPascalCase(field.key)} (${field.dataType})`,
                    value: field.key
                  }))}
              />
            </Form.Item>

            {selectedChartType && (
              <div>
                <PageHeader subTitle="Chart Features" style={{ padding: '4px 0' }} />
                <ChartFeaturesForm selectedChartType={selectedChartType} />
              </div>
            )}

            <MiscellaneousFeaturesForm selectedChartType={selectedChartType} />

            {selectedReportCompareField.length > 0 && (
              <div>
                <PageHeader subTitle="Compare Fields" style={{ padding: '4px 0' }} />
                <Form.Item name="isComparable" valuePropName="checked">
                  <Checkbox onChange={(e) => handleIsComparableChange(e.target.checked)}>
                    <span>Is Comparable</span>
                  </Checkbox>
                </Form.Item>

                <Form.Item
                  hidden={!isComparable}
                  name={['comparable', 'formField']}
                  label="Compare of"
                  rules={[
                    {
                      required: isComparable,
                      message: 'Select field to compare'
                    }
                  ]}>
                  <Select
                    showSearch
                    className="w-full"
                    optionFilterProp="label"
                    placeholder="Select field to compare"
                    dropdownMatchSelectWidth={false}
                    options={selectedReportCompareField.map((field) => ({
                      label: getPascalCase(field.value),
                      value: field.filter
                    }))}
                  />
                </Form.Item>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default forwardRef(SingleCustomizeUI);
