import { find_to_use_NPR } from '@/store/localstorage/preferences';
import { nepaliNumberFormatter, numberDecimalFormatter } from '@/utils/numberFormatter';
import moment from 'moment';

export const tableExportColumns = [
  {
    label: 'Product',
    dataIndex: 'product_name',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Location',
    dataIndex: 'location_name',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Date',
    dataIndex: 'adjustment_date',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Reason',
    dataIndex: 'reason',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Quantity',
    dataIndex: 'quantity',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Total Amount',
    dataIndex: 'total_amount',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      adjustment_date: moment(record.created_at).local().format('YYYY-MM-DD'),
      quantity: `${numberDecimalFormatter(record.quantity)} ${record.shortName}`,
      total_amount: nepaliNumberFormatter(record.total_amount)
    };
  });
}
