import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  ICategory,
  ICategoryCreateRequest,
  ICreateCommonResponse,
  IDeleteCommonResponse
} from './types';

export const create_category_mutation = async (values: ICategoryCreateRequest) => {
  const response: AxiosResponse<ICategory, any> = await http.store('category', values);
  return response;
};

export const update_category_mutation = async (values: ICategory) => {
  const response: AxiosResponse<ICreateCommonResponse, any> = await http.update(
    'category/' + values.id,
    values
  );
  return response;
};

/* Delete category
 ** @@ params: id
 */
export const delete_category = async (id: number) => {
  const response: AxiosResponse<IDeleteCommonResponse, any> = await http.remove('category/' + id);
  return response;
};
