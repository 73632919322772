import { Button, Divider, Form, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import useDebounce from '@/hooks/useDebounce';
import { get_active_location_list } from '@/services/locations/queries';

import LocationsDB from '@/store/localstorage/LocationsDB';
import { find_locationId_preference } from '@/store/localstorage/preferences';
import { checkAccess } from '@/routes/acl';

interface UsersSearchProps {
  formData?: {
    formName: any;
    formLabel: any;
  };
  onSelect?: (value: number) => any;
  withoutForm?: boolean;
  value?: any;
  notAll?: boolean;
  required?: boolean;
  setLocationInformation?: any;
  preference?: boolean;
  disabled?: boolean;
  transferUnit?: boolean;
  hasLabel?: boolean;
  allowClear?: boolean;
  // defaultValue?: any;
}
const { Option } = Select;
export const LocationSearch: React.FC<UsersSearchProps> = ({
  formData,
  onSelect,
  withoutForm,
  value,
  notAll,
  required,
  setLocationInformation,
  preference = true,
  disabled = false,
  transferUnit = false,
  allowClear = false,
  // transferUnit = false,
  hasLabel = true
  // defaultValue
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);

  useEffect(() => {
    // searchLocation(debouncedSearchValue);
    searchLocationIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const [locationSearch, setLocationSearch] = useState<any>([]);

  const searchLocationIndexDB = async (value: string) => {
    try {
      const response = await LocationsDB.searchActiveLocationByName(value, 100);
      if (!response || response.length == 0) {
        message.info('Cannot find any location with that name in cache, searching in server...');
        searchLocation(value);
      } else {
        setLocationSearch(response);
        setIsCacheResponse(true);
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  const searchLocation = async (value: any) => {
    let response;
    try {
      response = await get_active_location_list(0, 100, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any location with that name in server!');
      setLocationSearch([]);
    } else {
      setLocationSearch(response.data.results);
      LocationsDB.addLocations(response.data.results);
    }
    setIsCacheResponse(false);
  };

  const options = locationSearch.map((value: any) => (
    <Option key={value.id} value={value.id}>
      {value.name}
    </Option>
  ));

  const preferenceLocationId = find_locationId_preference();
  const preferenceLocationInfo = preferenceLocationId
    ? locationSearch.find((curr: any) => curr.id == find_locationId_preference())
    : null;

  const preferenceLocationOption = preferenceLocationInfo ? (
    <Option key={preferenceLocationInfo?.id} value={preferenceLocationInfo?.id}>
      {preferenceLocationInfo?.name}
    </Option>
  ) : (
    <Option key={locationSearch[0]?.id} value={locationSearch[0]?.id}>
      {locationSearch[0]?.name}
    </Option>
  );
  const setInformation = (selectedId: any) => {
    const locationInformation = locationSearch.find((curr: any) => curr.id == selectedId);
    setLocationInformation(locationInformation);
  };
  return (
    <>
      {withoutForm ? (
        <Select
          showSearch={checkAccess('ADMIN') || transferUnit ? true : false}
          //   size={'middle'}
          showArrow={false}
          filterOption={false}
          placeholder="Please select a location"
          onChange={onSelect}
          allowClear={allowClear}
          defaultValue={value ?? null}
          dropdownMatchSelectWidth={false}
          onSearch={(val) => {
            setSearchValue(val);
          }}
          // value={preferenceLocationId ? preferenceLocationId : null}
          style={{ width: '100%' }}
          dropdownRender={(menu) => (
            <>
              {menu}
              {checkAccess('ADMIN') || transferUnit ? (
                <>
                  <Divider style={{ margin: '8px 0' }} />
                  {isCacheResponse ? (
                    <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                      {/* <div
                    style={{
                      color: 'green',
                      width: '100%',
                      textAlign: 'center'
                    }}>{`Cache found ${locationSearch.length} data`}</div> */}
                      <Button
                        type="text"
                        style={{
                          color: 'blue',
                          width: '100%'
                        }}
                        onClick={() => {
                          searchLocation(searchValue);
                        }}>
                        {'Pull More & Sync'}
                        {/* {'Sync & Search on Server'} */}
                      </Button>
                    </div>
                  ) : (
                    <>
                      {/* <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    <div
                      style={{
                        color: 'green',
                        width: '100%',
                        textAlign: 'center'
                      }}>{`Server found ${locationSearch.length} data`}</div>
                  </div> */}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}>
          {checkAccess('ADMIN') || transferUnit ? (
            notAll ? (
              <></>
            ) : (
              <Option value="">All</Option>
            )
          ) : (
            <></>
          )}
          {checkAccess('ADMIN') || transferUnit ? options : preferenceLocationOption}
        </Select>
      ) : (
        <Form.Item
          name={formData ? formData.formName : ['locationId']}
          label={formData && hasLabel ? formData.formLabel : 'Location'}
          rules={[
            {
              required: required,
              message: 'Please choose location!'
            }
          ]}>
          <Select
            disabled={disabled}
            showSearch={checkAccess('ADMIN') || transferUnit ? true : false}
            placeholder={'Search location'}
            defaultActiveFirstOption={true}
            allowClear={allowClear}
            // defaultValue={preference ? (preferenceLocationId ? preferenceLocationId : null) : null}
            // value={preferenceLocationId ? preferenceLocationId : null}
            showArrow={false}
            filterOption={false}
            onChange={(val) => {
              onSelect && onSelect(val);
              if (setLocationInformation) setInformation(val);
            }}
            dropdownMatchSelectWidth={false}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                {checkAccess('ADMIN') || transferUnit ? (
                  <>
                    <Divider style={{ margin: '8px 0' }} />
                    {isCacheResponse ? (
                      <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                        {/* <div
                      style={{
                        color: 'green',
                        width: '100%',
                        textAlign: 'center'
                      }}>{`Cache found ${locationSearch.length} data`}</div> */}
                        <Button
                          type="text"
                          style={{
                            color: 'blue',
                            width: '100%'
                          }}
                          onClick={() => {
                            searchLocation(searchValue);
                          }}>
                          {/* {'Sync & Search on Server'} */}
                          {'Pull More & Sync'}
                        </Button>
                      </div>
                    ) : (
                      <>
                        {/* <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                      <div
                        style={{
                          color: 'green',
                          width: '100%',
                          textAlign: 'center'
                        }}>{`Server found ${locationSearch.length} data`}</div>
                    </div> */}
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}>
            {checkAccess('ADMIN') || transferUnit ? (
              notAll ? (
                <></>
              ) : (
                <Option value="">All</Option>
              )
            ) : (
              <></>
            )}
            {checkAccess('ADMIN') || transferUnit ? options : preferenceLocationOption}
          </Select>
        </Form.Item>
      )}
    </>
  );
};
