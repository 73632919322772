import { IProductRateCompare } from '@/services/report/types';
import { useState } from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import { TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import DifferenceIndicator from '@/components/Common/DifferenceIndicator';
import { generateTableColumns, getUpdatedValues } from './columns.export';
import ExportCurrent from '@/components/Common/ExportCurrent';

interface Props {
  data: IProductRateCompare[];
  selectedDate: [string, string];
}

function TableData({ data, selectedDate }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const columns: ColumnsType<IProductRateCompare> = [
    {
      title: 'SN',
      key: 'SN',
      width: 15,
      render: (text, record, index) => index + 1
    },

    {
      title: 'Product',
      dataIndex: 'product',
      key: 'Product',
      width: 100,
      sorter: (a, b) => a.product.localeCompare(b.product),
      sortOrder: sortedInfo.columnKey === 'Product' ? sortedInfo.order : null
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      width: 40,
      sorter: (a, b) => a.unit.localeCompare(b.unit),
      sortOrder: sortedInfo.columnKey === 'unit' ? sortedInfo.order : null
    },
    {
      title: `Price (${selectedDate[0] || 'First Date'})`,
      dataIndex: 'priceFirstDate',
      key: 'priceFirstDate',
      width: 40,
      render: (text) => nepaliNumberFormatter(text),
      sorter: (a, b) => a.priceFirstDate - b.priceFirstDate,
      sortOrder: sortedInfo.columnKey === 'priceFirstDate' ? sortedInfo.order : null
    },
    {
      title: `Price (${selectedDate[1] || 'Second Date'})`,
      dataIndex: 'priceSecondDate',
      key: 'priceSecondDate',
      width: 40,
      render: (text) => nepaliNumberFormatter(text),
      sorter: (a, b) => a.priceSecondDate - b.priceSecondDate,
      sortOrder: sortedInfo.columnKey === 'priceSecondDate' ? sortedInfo.order : null
    },
    {
      title: 'Difference %',
      dataIndex: 'difference',
      key: 'difference',
      width: 30,
      render: (text) => <DifferenceIndicator value={text} />,
      sorter: (a, b) => a.difference - b.difference,
      sortOrder: sortedInfo.columnKey === 'difference' ? sortedInfo.order : null
    }
  ];

  const tableColumns = generateTableColumns(selectedDate);
  const excelColumns = tableColumns.map((column) => ({
    title: column.label,
    dataIndex: column.dataIndex
  }));

  const updatedData = getUpdatedValues(data);

  return (
    <div>
      <CustomizeTable
        columns={columns}
        data={data}
        notshowPagination={true}
        toSort={handleChange}
        buttons={
          <>
            <ExportCurrent
              data={{ total: updatedData.length, results: updatedData }}
              columns={tableColumns}
              excelColumns={excelColumns}
              title="Product Rate Comparision Report"
            />
          </>
        }
        customScroll={{ x: 1300, y: '75vh' }}
      />
    </div>
  );
}

export default TableData;
