import { Button, Divider, Form, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';

import useDebounce from '../../../hooks/useDebounce';
import { IUserType } from '../../../services/user-types/types';
import { get_user_types_filter, get_user_types_ids } from '../../../services/user-types/queries';

interface UserTypeSearchProps {
  formData?: {
    formName: string;
    label: string;
  };
  isAll?: boolean;
  required?: boolean;
  initialValue?: {
    typeId: number;
  };
  onSelect?: (value: number) => void;
  style?: React.CSSProperties;
}

const { Option } = Select;

export const UserTypeSearch: React.FC<UserTypeSearchProps> = ({
  formData,
  required = true,
  initialValue,
  style
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [isMore, setIsMore] = useState<boolean>(true);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [skipUserType, setSkipUserType] = useState<number>(0);
  useEffect(() => {
    searchUserType(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const [userTypeSearch, setUserTypeSearch] = useState<IUserType[]>([]);

  const searchUserType = async (value: string) => {
    try {
      const response = await get_user_types_filter(0, 10, value);

      if (!response || response.data.results.length == 0) {
        message.info('Cannot find more user types with that value in server!');
        setIsMore(false);
        setUserTypeSearch([]);
      } else {
        setUserTypeSearch(response.data.results);
        if (response.data.results.length < 10) {
          setIsMore(false);
        } else {
          setIsMore(true);
          setSkipUserType(10);
        }
      }
    } catch (error) {
      message.error(error as string);
    }
    setSkipUserType(10);
  };

  const searchMoreUserType = async (value: string) => {
    try {
      const response = await get_user_types_filter(skipUserType, 10, value);
      if (!response || response.data.results.length == 0) {
        message.info('Cannot find more user types with that value in server!');
        setIsMore(false);
      } else {
        const filteredUserType = response.data.results.filter(
          (value) => !userTypeSearch.find((userType) => userType.id === value.id)
        );

        setSkipUserType(skipUserType + 10);
        setUserTypeSearch([...userTypeSearch, ...filteredUserType]);
        if (response.data.results.length < 10) {
          setIsMore(false);
        }
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  const userTypeInInitialValue = userTypeSearch.find((value) => value.id === initialValue?.typeId);

  const fetchUserType = async () => {
    try {
      if (initialValue?.typeId) {
        const response = await get_user_types_ids([initialValue?.typeId]);

        setUserTypeSearch([...userTypeSearch, ...response.results]);
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  useEffect(() => {
    if (initialValue?.typeId !== 1 && userTypeInInitialValue === undefined) {
      fetchUserType();
    }
  }, [initialValue?.typeId]);

  const options = userTypeSearch.map((value) => (
    <Option key={value.id} value={value.id}>
      {value.name}
    </Option>
  ));

  return (
    <>
      {formData ? (
        <Form.Item
          name={formData.formName}
          label={formData.label}
          rules={[
            {
              required: required,
              message: 'Please choose a user type!'
            }
          ]}
          initialValue={initialValue?.typeId}>
          <Select
            showSearch
            placeholder={'Select a user type!'}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            dropdownMatchSelectWidth={false}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            notFoundContent={null}
            style={style} // @ts-ignore
            autoComplete="dontshow"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {isMore ? (
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchMoreUserType(searchValue);
                      }}>
                      {'Get More...'}
                    </Button>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center'
                      }}>
                      No more data...
                    </div>
                  )}
                </div>
                {/* )} */}
              </>
            )}>
            {options}
          </Select>
        </Form.Item>
      ) : (
        <Select
          showSearch
          placeholder="Select a user type!"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          notFoundContent={null}
          style={style}
          dropdownMatchSelectWidth={false}
          onSearch={(val) => {
            setSearchValue(val);
          }}
          // @ts-ignore
          autoComplete="dontshow"
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              {isMore ? (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  <Button
                    type="text"
                    style={{
                      color: 'blue',
                      width: '100%'
                    }}
                    onClick={() => {
                      searchUserType(searchValue);
                    }}>
                    {'Pull More & Sync'}
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center'
                  }}>
                  No more data...
                </div>
              )}
            </>
          )}>
          {options}
        </Select>
      )}
    </>
  );
};
