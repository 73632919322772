import { useState } from 'react';
import { Menu, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';

import { IStockClear } from '@/services/products/types';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import ActionDropdown from '@/components/Common/Dropdownactions';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import { Link } from 'react-router-dom';

interface Props {
  isLoading: boolean;
  data: { total: number; results: IStockClear[] };
  pagination: { page: number; size: number };
  onPagination: (page?: number, size?: number, isSize?: boolean) => Promise<void>;
}

function Table({ data, pagination, isLoading, onPagination }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IStockClear>>({});

  const handleChange: TableProps<IStockClear>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IStockClear>);
  };

  const columns: ColumnsType<IStockClear> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 3,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Location',
      key: 'locationName',
      dataIndex: 'locationName',
      width: 12,
      sorter: (a, b) => (a?.locationName || '').localeCompare(b?.locationName || ''),
      sortOrder: sortedInfo.field === 'locationName' ? sortedInfo.order : undefined,
      render: (loc, record) => <Link to={`/products/clear-stocks/${record.id}`}>{loc}</Link>
    },
    {
      title: 'Cleared By',
      key: 'createdName',
      dataIndex: 'createdName',
      width: 12,
      sorter: (a, b) => (a?.createdName || '').localeCompare(b?.createdName || ''),
      sortOrder: sortedInfo.field === 'createdName' ? sortedInfo.order : undefined
    },
    {
      title: 'Created At',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 6,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortOrder: sortedInfo.field === 'createdAt' ? sortedInfo.order : undefined,
      render: (createdAt: string) => convertUTCStringtoLocalString(createdAt, DEFAULT_DATE_FORMAT)
    },
    {
      title: 'Updated At',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      width: 6,
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
      sortOrder: sortedInfo.field === 'updatedAt' ? sortedInfo.order : undefined,
      render: (updatedAt: string) => convertUTCStringtoLocalString(updatedAt, DEFAULT_DATE_FORMAT)
    },
    {
      title: 'Action',
      key: 'action',
      width: 2,
      fixed: 'right',
      render: (record: IStockClear) => {
        const menuItems = [
          {
            key: 'view-agent-bill',
            label: <CustomViewIcon link={`/products/clear-stocks/${record.id}`} />
          }
        ];

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <CustomizeTable
      columns={columns}
      data={data.results}
      usersLoading={isLoading}
      tableName="agent-bill-range-list"
      toSort={handleChange}
      notshowPagination={true}
      customScroll={{ x: 1200, y: '75vh' }}
      paginationDatas={{
        page: pagination.page,
        total: data.total,
        size: pagination.size,
        onPagination
      }}
    />
  );
}

export default Table;
