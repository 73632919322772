import { Button, Divider, Image, Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { getUserData } from '../../../../utils/auth.utils';
import { ISellInvoice } from '../../../../services/invoice/types';
import { get_image } from '../../FetchImage/FetchImage';
import { API_URL } from '../../../../constants/config';
import { get_invoices_list } from '../../../../services/settings/queries';
import { find_invoice_number } from '../../../../store/localstorage/preferences';
import { convert_string_to_nepali_date_string } from '../../../../utils/nepaliDateConverter';
import moment from 'moment';
import CustomTable from '../../CustomResuableInvoice/CustomTable';
import { nepaliNumberFormatter } from '../../../../utils/numberFormatter';
import { IPayment } from '../../../../services/payments/types';
import { ColumnsType } from 'antd/lib/table';

const MultipleSellOrderInvoice: React.FC<{
  invoiceData: ISellInvoice[];
  handleModalClose: () => void;
}> = ({ invoiceData, handleModalClose }) => {
  const printRef = useRef<any>();
  const loggedInUser = getUserData();
  const [imgSrc, setimgSrc] = useState('');
  const content = JSON.parse(invoiceData[0].invoiceLayouts?.content);

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  const columns = [
    {
      label: `${content.productLabel ? content.productLabel : 'ProductName'}`,
      dataIndex: 'productName',
      width: 100,
      render: (text: string) => <div className="ml-2">{text}</div>
    },
    {
      label: `${content.quantityLabel ? content.quantityLabel : 'Quantity'}`,
      dataIndex: 'quantity',
      width: 100,
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: `${content.unitNameLabel ? content.unitNameLabel : 'Unit'}`,
      dataIndex: 'unitName',
      width: 100,
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: `${content.unitPriceLabel ? content.unitPriceLabel : 'UnitPrice'}`,
      dataIndex: 'unitPrice',
      width: 100,
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: `${content.totalLabel ? content.totalLabel : 'Total Amount'}`,
      dataIndex: 'totalAmount',
      width: 100,
      render: (text: number) => <div className="text-right mr-1">{nepaliNumberFormatter(text)}</div>
    }
  ];

  const paymentColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      width: 100,
      render: (text: string) => <div>{text.slice(0, 10)}</div>
    },
    {
      title: 'Reference No.',
      dataIndex: 'reference',
      width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}/-</div>
    },
    {
      title: 'Payment Mode',
      dataIndex: 'paymentMode',
      width: 100,
      render: (text: number) => <div>{text}</div>
    },
    {
      title: 'Payment note',
      dataIndex: 'note',
      width: 100,
      render: (text: number) => <div>{text}</div>
    }
  ];

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    const response = await get_invoices_list();
    // console.log(invoiceLayouts);
    if ('invoiceLogo' in content) {
      const imageData = await get_image(
        `${API_URL}storage/media/${JSON.parse(response.data[0].content).invoiceLogo}`
      );
      setimgSrc(imageData);
    }
  };

  return (
    <>
      <div className="flex justify-end mb-3">
        <Button type="primary" onClick={handlePrint}>
          Print
        </Button>
      </div>
      <div ref={printRef}>
        {invoiceData.map((sellOrderData, index) => (
          <div key={index} style={{ pageBreakAfter: 'always' }}>
            <div className="p-5" style={{ color: 'black' }}>
              <div className="flex justify-between">
                <Image src={imgSrc} preview={false} />
                <div>
                  <div className="font-bold text-lg mr-3">Fresh K.T.M. Pvt. Ltd.</div>
                  <div>Suryabhinayak-08,Bhaktapur,Nepal</div>
                  <span className="font-bold text-sm">VAT No.: {find_invoice_number()}</span>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-3">
                <div className="text-2xl font-extrabold">
                  Freshktm Agro Pvt. Ltd.,
                  {sellOrderData.sellDetails.printLocation?.name
                    ? sellOrderData.sellDetails.printLocation?.name
                    : ''}
                </div>
                <div className="text-xl font-bold">Proforma Invoice</div>
              </div>
              <div className="flex justify-between">
                <div>
                  <div>
                    <span className="text-sm font-bold">No.</span>
                    <span>
                      {sellOrderData.sellDetails.financialReference
                        ? sellOrderData.sellDetails.financialReference
                        : sellOrderData.sellDetails.referenceNumber
                        ? sellOrderData.sellDetails.referenceNumber
                        : sellOrderData.sellDetails.id}
                    </span>
                  </div>
                  <div>
                    Pan Number:{' '}
                    {sellOrderData.customerDetails?.panNumber
                      ? sellOrderData.customerDetails.panNumber
                      : ''}
                  </div>
                  <div>
                    <span>{sellOrderData.customerDetails.name}</span>
                  </div>
                  <div>
                    <span>{sellOrderData.customerDetails.email}</span>
                  </div>
                  <div>{sellOrderData.customerDetails.address}</div>
                  <div>{sellOrderData.customerDetails.phone}</div>
                </div>
                <div>
                  <div>
                    <span className="text-sm font-bold">Route: </span>
                    {sellOrderData.customerDetails.routeName}
                  </div>
                  <div>
                    <span className="text-sm font-bold">Date:{}</span>
                    <span>{convert_string_to_nepali_date_string(moment().format())}</span>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <CustomTable
                  datas={sellOrderData.lines ? sellOrderData.lines : []}
                  columns={columns}
                />
              </div>
              {sellOrderData.sellDetails.payments?.length > 0 ? (
                <>
                  <div>Payment Info</div>
                  <div style={{ border: '1px solid gray' }}>
                    <Table
                      columns={paymentColumns}
                      dataSource={sellOrderData.sellDetails.payments}
                      bordered
                      pagination={false}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              <Divider />
              {sellOrderData.sellDetails ? (
                <div className="flex justify-between">
                  <div>
                    <div>Total Quantity: {sellOrderData.sellDetails.totalQuantity}</div>
                    <div>
                      Total Amount: {nepaliNumberFormatter(sellOrderData.sellDetails.totalAmount)}{' '}
                      /-
                    </div>
                    <div>
                      Total Discount:{' '}
                      {nepaliNumberFormatter(sellOrderData.sellDetails.totalDiscount)} /-
                    </div>
                    <div>
                      Total Tax: {nepaliNumberFormatter(sellOrderData.sellDetails.totalVat)} /-
                    </div>
                    <div>
                      Total Price: {nepaliNumberFormatter(sellOrderData.sellDetails.totalPrice)} /-
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {content.footerText ? (
                <div dangerouslySetInnerHTML={{ __html: content.footerText }}></div>
              ) : (
                <></>
              )}
              <div>
                Created By:{' '}
                {sellOrderData.createdByDetails ? sellOrderData.createdByDetails.name : ''}
              </div>
              <div>
                Printed By:{' '}
                <span className="capitalize">{loggedInUser?.name ? loggedInUser.name : ''}</span>
              </div>
              <div>
                {content?.noteLabel ? content.noteLabel : 'Note'} :{' '}
                {sellOrderData.sellDetails.note ? sellOrderData.sellDetails.note : ''}
              </div>
              <div className="mt-5 flex justify-center">
                <table style={{ width: '80%', border: '1px solid black' }}>
                  <tr>
                    <td style={tdStyleone}>
                      <div style={tdivStyle}>.....................................</div>
                    </td>
                    <td style={tdStyleone}>
                      <div style={tdivStyle}>info@freshktm.com</div>{' '}
                    </td>
                    <td style={tdStyleone}>
                      <div style={tdivStyle}>......................................</div>
                    </td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>
                      <div style={tdivStyle}>Received by</div>
                    </td>
                    <td style={tdStyle}>
                      <div style={tdivStyle}>www.freshktm.com</div>
                    </td>
                    <td style={tdStyle}>
                      <div style={tdivStyle}>For:Freshktm</div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MultipleSellOrderInvoice;

const tdStyleone = {
  border: '1px solid black',
  padding: '15px 0px 0px 1px',
  alignText: 'center'
};

const tdStyle = {
  border: '1px solid black',
  padding: '3px',
  alignText: 'center'
};

const tdivStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};
