import { CSSProperties, useEffect, useState } from 'react';

import { useDashboardMain } from '@/contexts/dashboard-main.context';
import RenderWidget from '../widget/RenderWidget';
import { IWidgetConfiguration } from '@/services/dashboard/v3/types';

interface Props {
  colSize: number;
  widget: IWidgetConfiguration;
}

function MainWidgetLayout({ widget, colSize }: Props) {
  const { defaultAPIConfig, reportLists } = useDashboardMain();
  const [reportNames, setReportNames] = useState<{ id: number; name: string }[]>([]);

  const style: CSSProperties = {
    height: '24rem',
    gridColumn: `span ${colSize}`
  };

  useEffect(() => {
    const reportIds = widget.widgets.length > 0 ? widget.widgets.map((w) => w.reportId) : [];
    const reportNameWithId = reportLists
      .filter((report) => reportIds.includes(report.id))
      .map((report) => ({
        id: report.id,
        name: report.name
      }));

    setReportNames(reportNameWithId);
  }, [reportLists]);

  return (
    <div className="widget bg-white rounded-md relative shadow-sm" style={style}>
      {reportNames.length > 0 && (
        <RenderWidget
          type="main"
          widget={widget}
          reports={reportNames}
          defaultAPIConfig={defaultAPIConfig}
        />
      )}
    </div>
  );
}

export default MainWidgetLayout;
