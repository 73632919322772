import { PropsWithChildren } from 'react';

function FilterWrapper({ children, type }: PropsWithChildren<{ type: string }>) {
  return (
    <div style={{ border: 'var(--border-light)' }} className="bg-white p-2">
      <p className="text-xs widgets capitalize text-gray-400 mb-2">{type}</p>
      <div className="space-y-1">{children}</div>
    </div>
  );
}

export default FilterWrapper;
