import React, { useContext, useState } from 'react';
import { CustomModal } from '../../../components/Common/CustomModal';
import ProductRoutePrint from '../../../components/Common/InvoicePrint/ProductRoutePrint/ProductRoutePrint';
import { IProductRouteInvoice } from '../../../services/invoice/types';
import { SellListContext } from './context';
import { message } from 'antd';
import { getSellProductRoutePrintData } from '../../../components/Common/InvoicePrint/ProductRoutePrint/services';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';

export const ProductRoutePrintModal: React.FC<{
  getInfo: (urlData: string) => void;
}> = ({ getInfo }) => {
  const { setIsLoading, form, urlData } = useContext(SellListContext);
  const [openModalForProductRoutePrint, setOpenModalForProductRoutePrint] =
    useState<boolean>(false);
  const [productRouteData, setProductRouteData] = useState<IProductRouteInvoice>(Object);

  const generateProductRouteForPrint = async () => {
    setIsLoading(true);
    await form.validateFields();
    try {
      console.log(form.getFieldsValue());
      const startDate = form.getFieldValue(['startDate']);
      const endDate = form.getFieldValue(['endDate']);
      const routeId = form.getFieldValue(['routeId']);
      const locationId = form.getFieldValue(['locationId']);
      const categoryId = form.getFieldValue(['categoryId']);
      console.log('startDate', startDate);
      console.log('endDate', endDate);
      console.log('routeId', routeId);
      console.log('locationId', locationId);
      console.log('categoryId', categoryId);

      const productRotueModalData = await getSellProductRoutePrintData(
        startDate,
        endDate,
        locationId,
        routeId,
        categoryId
      );
      productRotueModalData.totalProduct.sort((a, b) => {
        const productNameA = a.productName?.toLowerCase();
        const productNameB = b.productName?.toLowerCase();

        if (productNameA && productNameB) {
          if (productNameA < productNameB) {
            return -1;
          }
          if (productNameA > productNameB) {
            return 1;
          }
        }
        return 0;
      });
      // console.log('productRotueModalData', productRotueModalData);
      setIsLoading(false);
      setProductRouteData({ ...productRotueModalData });
      setOpenModalForProductRoutePrint(true);
    } catch (err: any) {
      setIsLoading(false);
      message.error(err.message);
    }
  };

  const handleProductRoutePrintModalClose = () => {
    setOpenModalForProductRoutePrint(false);
    getInfo(urlData);
  };
  return (
    <div>
      <CustomModal
        footer={false}
        isModalOpen={openModalForProductRoutePrint}
        setIsModalOpen={setOpenModalForProductRoutePrint}
        title="Product Route Print">
        <ProductRoutePrint
          routeInformation={productRouteData.routeInformation}
          totalInfo={productRouteData.totalInfo}
          totalProduct={productRouteData.totalProduct}
          categoryName={productRouteData.categoryName}
          handleModalClose={handleProductRoutePrintModalClose}
        />
      </CustomModal>
      <CustomButton
        onClick={() => {
          // onFinish(form.getFieldsValue());
          generateProductRouteForPrint();
        }}
        text="Print"
        backgroundColor="#1890ff"
        marginLeft="10px"
      />
    </div>
  );
};

export default ProductRoutePrint;
