import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Form, message, Select } from 'antd';
import { useState } from 'react';
import AppContent from '../../../components/Common/Content/Content';
import { USER_INFO } from '../../../constants/config';
import { get_location_list } from '../../../services/locations/queries';
import { ILocations } from '../../../services/locations/types';
import { update_preference_user_mutation } from '../../../services/users/mutations';
import {
  find_addressId_preference,
  find_date_preference,
  find_locationId_preference
} from '../../../store/localstorage/preferences';
import { getUserData } from '../../../utils/auth.utils';
import { setLocalStorage } from '../../../utils/storage.utils';
import {
  IPreferences,
  IPreferencesResponse,
  IUserPreferences
} from '../../../services/settings/types';
import { AxiosResponse } from 'axios';
import { get_user_details } from '../../../services/users/queries';
import { IUSerlocation } from '../../../services/routes/types';
import { LocationSearch } from '../../../components/Common/LocationSearch/LocationSearch';

// const { Option } = Select;

const Preferences = () => {
  const [form] = Form.useForm();
  const userData = getUserData();
  //const [address, setAddress] = useState<IUSerlocation[]>([]);
  //let prefUpdate: string;
  const { data: locationsList } = useQuery(['locations'], async () => {
    const response = await get_location_list();
    const locationdId = find_locationId_preference();
    if (typeof locationdId === 'number') {
      form.setFieldValue('locationId', locationdId);
    } else {
      form.setFieldValue('locationId', response.data.results[0].id);
    }
    form.setFieldValue('nepaliDate', find_date_preference());
    return response;
  });

  // const { data: addressList } = useQuery(['address'], async () => {
  //   const addressId = find_addressId_preference();
  //   const userDetails = await get_user_details(userData.preferences.userId);
  //   if (typeof addressId === 'number') {
  //     form.setFieldValue('addressId', addressId);
  //   } else {
  //     form.setFieldValue('addressId', null);
  //   }
  //   return userDetails.addresses;
  // });

  const updatePreferencesMutation = useMutation(update_preference_user_mutation, {
    onSuccess: ({ data }: { data: IPreferencesResponse }) => {
      //console.log('data--->', data);
      setLocalStorage(USER_INFO, {
        ...userData,
        preferences: {
          ...userData.preferences,
          preferences: data.preferences
        }
      });
      message.success('Preference updated successfully');
    }
  });

  const onFinish = (values: IPreferences) => {
    // console.log('userData', userData);
    //console.log(values);
    if ('preferences' in userData && userData.preferences && userData.preferences.preferences) {
      const prefUpdate: IUserPreferences = JSON.parse(userData.preferences.preferences);
      updatePreferencesMutation.mutate({
        id: userData.id,
        value: JSON.stringify({
          ...prefUpdate,
          locationId: values.locationId,
          nepaliDate: values.nepaliDate
        })
      });
    } else {
      updatePreferencesMutation.mutate({
        id: userData.id,
        value: JSON.stringify({
          locationId: values.locationId,
          nepaliDate: values.nepaliDate
        })
      });
    }
  };
  return (
    <AppContent breadcrumbItems={[{ label: 'Preferences' }]}>
      <Form form={form} layout={'vertical'} onFinish={onFinish}>
        <div className="grid grid-cols-3 gap-2">
          {/* <Form.Item name="locationId" label="Location">
            <Select
              placeholder="Select a location!"
              style={{ borderRadius: '10px' }}
              allowClear
              dropdownMatchSelectWidth={false}>
              {locationsList?.data.results.map((value: ILocations) => {
                return (
                  <Select.Option value={value.id} key={value.id}>
                    {value.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item> */}
          <LocationSearch
            formData={{ formLabel: 'Location', formName: 'locationId' }}
            allowClear
            notAll={true}
          />
          <Form.Item name="nepaliDate" label="Date Type">
            <Select
              placeholder="Select a location!"
              style={{ borderRadius: '10px' }}
              allowClear
              dropdownMatchSelectWidth={false}>
              <Select.Option value={false} key={'1'}>
                English
              </Select.Option>
              <Select.Option value={true} key={'2'}>
                Nepali
              </Select.Option>
            </Select>
          </Form.Item>
          {/* <Form.Item name="addressId" label="POS Address">
            <Select placeholder="Select an address!" dropdownMatchSelectWidth={false}>
              {addressList?.map((value) => {
                return (
                  <Option value={value.id} key={value.id}>
                    {`${value.addressLine1}, ${value.addressLine2}, ${value.country}`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item> */}
        </div>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              color: 'green',
              border: '1px solid green',
              borderRadius: '9px',
              backgroundColor: 'transparent',
              marginTop: '25px'
            }}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </AppContent>
  );
};

export default Preferences;
