import { Button, Divider, Form, FormInstance, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { get_user_details, get_user_list_accounts } from '../../../services/users/queries';
import { User } from '../../../services/users/types';

const { Option } = Select;
interface UsersSearchForAccountProps {
  formData?: {
    formName: string | (string | number)[];
    label?: string;
  };
  formMain?: FormInstance<any>;
  isAll?: boolean;
  onSelect?: (value: number) => any;
  required?: boolean;
  disabled?: boolean;
}

export const UsersSearchForAccount: React.FC<UsersSearchForAccountProps> = ({
  formData,
  formMain,
  isAll = true,
  required = false,
  onSelect,
  disabled = false
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [userSearch, setUserSearch] = useState<User[]>([]);
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);

  if (formData?.formName) {
    const selectId = Form.useWatch(formData.formName, formMain);

    useEffect(() => {
      getAccountDetailsById();
    }, [selectId]);

    const getAccountDetailsById = async () => {
      if (selectId) {
        const userDetails = await get_user_details(selectId);
        setUserSearch((prev) => [...prev, userDetails.user]);
        if (formMain) {
          formMain.setFieldValue(formData.formName, selectId);
        }
      }
    };
  }

  useEffect(() => {
    searchUser(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const searchUser = async (value: string) => {
    let response;
    try {
      response = await get_user_list_accounts(0, 10, value, 'yes');
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any user with that name!');
      setUserSearch([]);
      setIsMore(false);
    } else {
      setUserSearch(response.data.results);
      if (response.data.results.length < 10) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
    }
    setIsCacheResponse(false);
    setSkip(10);
  };

  const searchMoreUser = async (value: string) => {
    let response;
    try {
      response = await get_user_list_accounts(skip, 10, value, 'yes');
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.info('Cannot find more user with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);
      for (let i = 0; i < response.data.results.length; i++) {
        for (let j = 0; j < userSearch.length; j++) {
          if (response.data.results[i].id === userSearch[j].id) {
            response.data.results.splice(i, 1);
          }
        }
      }
      setUserSearch([...userSearch, ...response.data.results]);
      if (response.data.results.length < 10) {
        setIsMore(false);
      }
    }
  };

  const options = userSearch?.map((value: any) => (
    <Option key={value.id} value={value.id}>
      {value.name ? value.name : value.user.name}, {value?.phone ? value.phone : value?.user?.phone}
    </Option>
  ));

  return (
    <>
      {formData ? (
        <Form.Item
          name={formData.formName}
          label={formData.label ? formData.label : 'Users'}
          rules={[
            {
              required: required,
              message: 'Please choose a user!'
            }
          ]}>
          <Select
            showSearch
            allowClear
            disabled={disabled}
            placeholder={'search user'}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            dropdownMatchSelectWidth={false}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                {isCacheResponse ? (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchUser(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Pull More & Sync'}
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {isMore ? (
                      <Button
                        type="text"
                        style={{
                          color: 'blue',
                          width: '100%'
                        }}
                        onClick={() => {
                          searchMoreUser(searchValue);
                        }}>
                        {/* {'Sync & Search on Server'} */}
                        {'Get More...'}
                      </Button>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center'
                        }}>
                        No more data...
                      </div>
                    )}
                  </div>
                )}
              </>
            )}>
            {isAll ? <Option value="">All</Option> : null}
            {options}
          </Select>
        </Form.Item>
      ) : (
        <Select
          disabled={disabled}
          showSearch
          placeholder={'search user'}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          dropdownMatchSelectWidth={false}
          onSearch={(val) => {
            setSearchValue(val);
          }}
          notFoundContent={null}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              {isCacheResponse ? (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  <Button
                    type="text"
                    style={{
                      color: 'blue',
                      width: '100%'
                    }}
                    onClick={() => {
                      searchUser(searchValue);
                    }}>
                    {/* {'Sync & Search on Server'} */}
                    {'Pull More & Sync'}
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </>
          )}>
          {isAll ? <Option value="">All</Option> : null}

          {options}
        </Select>
      )}
    </>
  );
};
