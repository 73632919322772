import { FilterTwoTone } from '@ant-design/icons';

import { BiFullscreen } from 'react-icons/bi';
import { TbZoomIn, TbZoomOut, TbZoomReset } from 'react-icons/tb';

type Props = {
  allowFilter?: boolean;
  enlargeChart?: boolean;
  handleFilterOpen?: () => void;
  handleFullScreen?: () => void;
} & ({ allowZoom: false } | { allowZoom: true; chartRef: React.MutableRefObject<any> });

function WidgetButtons({
  allowFilter,
  enlargeChart,
  handleFilterOpen,
  handleFullScreen,
  ...props
}: Props) {
  const handleChartZoom = (type: 'in' | 'out' | 'reset') => {
    if (props.allowZoom && props.chartRef.current) {
      const chart = props.chartRef.current;

      switch (type) {
        case 'reset':
          chart.resetZoom();
          break;
        case 'in':
          chart.zoom(1.1);
          break;
        case 'out':
          chart.zoom(0.9);
          break;
      }
    }
  };

  return (
    <div data-no-dnd="true" className="absolute top-3 right-2 flex items-center gap-2 select-none">
      {enlargeChart && (
        <BiFullscreen
          size={16}
          onClick={handleFullScreen}
          title="Open in full screen"
          className="cursor-pointer text-[#1677ff]"
        />
      )}
      {props.allowZoom && (
        <>
          <TbZoomReset
            size={16}
            title="Reset Zoom"
            onClick={() => handleChartZoom('reset')}
            className="cursor-pointer text-[#1677ff]"
          />

          <TbZoomIn
            size={16}
            title="Zoom In"
            onClick={() => handleChartZoom('in')}
            className="cursor-pointer text-[#1677ff]"
          />

          <TbZoomOut
            size={16}
            title="Zoom Out"
            onClick={() => handleChartZoom('out')}
            className="cursor-pointer text-[#1677ff]"
          />
        </>
      )}

      {allowFilter && (
        <FilterTwoTone
          onClick={handleFilterOpen}
          title="Filter By Date"
          style={{ fontSize: 16 }}
          className="[&>svg]:mt-0 cursor-pointer text-[#1677ff]"
        />
      )}
    </div>
  );
}

export default WidgetButtons;
