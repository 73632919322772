import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Form, Input, Select, Upload, Checkbox, UploadFile, UploadProps, Card } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useState } from 'react';
import AppContent from '../../../../components/Common/Content/Content';
import RichTextEditor from '../../../../components/RichTextEditor';
import { API_URL, JWT_TOKEN } from '../../../../constants/config';
import { get_user_avatar } from '../../../../services/users/queries';
import { getLocalStorage } from '../../../../utils/storage.utils';

const BasicInfo = ({
  form,
  printingtype,
  setprintingType
}: {
  form: any;
  printingtype: string;
  setprintingType: React.Dispatch<any>;
}) => {
  // console.log('Form-->any', form);

  const handleRichTextEditorChange = (field: string, value: string) => {
    form.setFieldValue(field, value);
  };

  return (
    <div>
      <AppContent>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2">
          <Form.Item
            label="Layout Name"
            name="layoutName"
            rules={[{ required: true, message: 'Please add layout name!' }]}>
            <Input />
          </Form.Item>
          <div>
            <Form.Item
              label="Design"
              name="design"
              rules={[{ required: true, message: 'Please choose design!' }]}>
              <Select
                defaultValue={'classic'}
                allowClear
                onChange={(value: any) => {
                  setprintingType(value);
                }}>
                <Select.Option value="classic">Classic ( for normal printers )</Select.Option>
                <Select.Option value="bill">Bill ( for normal printers )</Select.Option>
                {/* <Select.Option value="detailed">Detailed ( for normal printers ) </Select.Option> */}
              </Select>
            </Form.Item>
            <small>Used for browser based printing</small>
          </div>
        </div>
      </AppContent>
    </div>
  );
};

export default BasicInfo;
