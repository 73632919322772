import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { ITaskLog, ITasks, ITasksData } from './types';

/* Get Location lists
 * @param {number} skip
 * @param {number} count
 * @params {value} string
 * @returns {Promise<any>}
 */
export const get_tasks_list = async (
  skip = 0,
  count = 0,
  value: string | null = '',
  ids: number[] = []
) => {
  const response: AxiosResponse<ITasksData, any> = await http.get('task', { skip, count });
  return response;
};
export const get_tasks_assigned_list = async () => {
  const response: AxiosResponse<ITasks[], any> = await http.get('task/assigned');
  return response;
};

/* Get single location details
 * @param {number} id
 */
export const get_tasks_details = async (id: number) => {
  const response: AxiosResponse<ITasks, any> = await http.get('task/' + id);
  return response.data;
};

/* Get single location details
 * @param {number} id
 */
export const get_tasks_logs = async (id: number) => {
  const response: AxiosResponse<ITaskLog[], any> = await http.get('task/log/' + id);
  return response.data;
};

export const get_archived_tasks_list = async (skip = 0, count = 1000) => {
  const response: AxiosResponse<ITasksData, any> = await http.get('task/archived', { skip, count });
  return response;
};

export const get_tasks_list_filter = async (skip = 0, count = 1000, filter = '') => {
  const response: AxiosResponse<ITasksData, any> = await http.get('task/?' + filter, {
    skip,
    count
  });
  return response;
};

export const get_archived_tasks_list_filter = async (skip = 0, count = 1000, filter = '') => {
  const response: AxiosResponse<ITasksData, any> = await http.get('task/archived?' + filter, {
    skip,
    count
  });
  return response;
};
