import { Button, Menu, Tooltip, message } from 'antd';
import { CustomModal } from '../../../../components/Common/CustomModal';
import CustomTable from '../../../../components/Common/CustomResuableInvoice/CustomTable';
import { useContext, useRef, useState } from 'react';
import { ISellOrderResponseData } from '../../../../services/sell/types';
import { useReactToPrint } from 'react-to-print';
import ActionDropdown from '../../../../components/Common/Dropdownactions';
import { ExportColumnType, exportExcel } from '../../../../utils/exportExcel';
import { SellOrderListContext } from './context';
import { convertUTCStringtoLocalString } from '../../../../utils/convertToUTC';
import { DEFAULT_DATE_FORMAT } from '@/constants';

export const TableExportPrintModal: React.FC<{
  allSellOrderList: ISellOrderResponseData;
}> = ({ allSellOrderList }) => {
  const { setIsLoading } = useContext(SellOrderListContext);
  const [openmodalforExport, setOpenModalforexport] = useState<boolean>(false);
  const printPDFRef = useRef<any>();
  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });
  const columsforPrint: any = [
    {
      label: 'ID',
      dataIndex: 'id',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Customer',
      dataIndex: 'userName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Financial Reference',
      dataIndex: 'financialReference',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Total Amount(NPR)',
      dataIndex: 'totalAmount',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Route',
      dataIndex: 'routeName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'CreatedBy',
      dataIndex: 'createdByName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Category',
      dataIndex: 'categoryName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },

    {
      label: 'Location',
      dataIndex: 'locationName',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Note',
      dataIndex: 'note',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Date',
      dataIndex: 'date',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    }
  ];
  const handleExport = () => {
    setIsLoading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'S.N',
          dataIndex: 'SN',
          width: 50
        },
        {
          title: 'ID',
          dataIndex: 'id',
          width: 50
        },
        {
          title: 'Customer',
          width: 250,
          dataIndex: 'userName'
        },
        {
          title: 'Category',
          dataIndex: 'categoryName',
          width: 250
        },
        {
          title: 'Route',
          dataIndex: 'routeName',
          width: 250
        },
        {
          title: 'Financial Reference',
          dataIndex: 'financialReference',
          width: 250
        },
        {
          title: 'Created By',
          width: 150,
          dataIndex: 'createdByName'
        },
        {
          title: 'Location',
          width: 200,
          dataIndex: 'locationName'
        },
        {
          title: 'Note',
          width: 200,
          dataIndex: 'note'
        },
        {
          title: 'Total Amount',
          width: 100,
          dataIndex: 'totalAmount'
        },
        {
          title: 'Date',
          width: 200,
          dataIndex: 'date'
        }
      ];
      if (allSellOrderList.resultsWithCustomer.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = allSellOrderList.resultsWithCustomer.map((item: any, index: number) => {
        return {
          ...item,
          SN: index + 1,
          note: item.note.slice(0, 10),
          date: convertUTCStringtoLocalString(item.date, DEFAULT_DATE_FORMAT)
        };
      });
      exportExcel(columns, dataUpdated, 'Sell Order List');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="flex justify-end ">
        {/* <CustomButton text="Export" backgroundColor="#1890ff" onClick={handleExport} />
          <CustomButton
            text="PDF Export"
            backgroundColor="#1890ff"
            onClick={() => setOpenModalforexport(true)}
          /> */}
        <div>
          <ActionDropdown
            button={true}
            menu={
              <Menu
                items={[
                  {
                    key: '1',
                    label: (
                      <Tooltip title="Export Excel" color="blue">
                        <div className="text-center">Excel</div>
                      </Tooltip>
                    ),
                    onClick: () => {
                      handleExport();
                    }
                  },
                  {
                    key: '2',
                    label: (
                      <Tooltip title="Export PDF" color="blue">
                        <div className="text-center">PDF</div>
                      </Tooltip>
                    ),
                    onClick: () => {
                      setOpenModalforexport(true);
                    }
                  }
                ]}
              />
            }
          />
        </div>
      </div>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title="Sell Order View For Print">
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={allSellOrderList.resultsWithCustomer}
            reff={printPDFRef}
            title={'Sell Order List'}
          />
          <div className="flex justify-end mt-3">
            <Button type="primary" onClick={handlePDFExport}>
              Print Pdf
            </Button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};
