import { AccountRulesEvent } from '@/services/accounts/enums';
import { ExpenseColumnDataType } from '@/services/expense/enum';

import { get_expense_by_id } from '@/services/expense/queries';
import { ICreateInvoiceResponse } from '@/services/settings/types';
import { get_journal_list_by_reference } from '@/services/accounts/queries';

import { get_expense_category_table } from '@/services/expense/expense-category/queries';
import { getExpenseCategory, getLocation, getUser } from '@/pages/expense/view/services.expense';
import {
  ExpenseCategoryColumnItem,
  IExpenseInvoiceData
} from '@/services/expense/expense-category/types';
import { getPopulatedData } from '@/services/expense/services';
import { getJournalLinesDetails } from '@/services/accounts/services';

export async function getExpensePrintData(
  id: number,
  current: ICreateInvoiceResponse
): Promise<IExpenseInvoiceData> {
  const journalDetails = await getJournalList(id);
  const expenseDetails = await getExpenseDetails(id);

  return {
    journalDetails: journalDetails.data,
    lines: journalDetails.lines,
    expenseDetails,
    invoiceLayouts: current
  };
}

async function getJournalList(id: number) {
  const data = await get_journal_list_by_reference(AccountRulesEvent.EXPENSE_CREATE, id);
  const currentLocation = data.locationId;
  data.locationName = 'N/A';

  if (currentLocation) {
    const location = await getLocation(currentLocation);
    data.locationName = location.name;
  }

  const journalLines = await getJournalLinesDetails(data.id);

  return {
    data,
    lines: journalLines.data
  };
}

async function getExpenseDetails(id: number) {
  const data = await get_expense_by_id(id);
  const { createdBy, categoryId } = data;

  const user = await getUser(createdBy);
  const category = await getExpenseCategory(categoryId);
  const location = await getLocation(data.locationId);

  const content = JSON.parse(category.content);
  const column = content.column as ExpenseCategoryColumnItem[];

  const categoryByExpense = await get_expense_category_table(data.categoryId, {
    expenseId: data.id
  });

  const booleanColumn = column.filter((col) => col.dataType === ExpenseColumnDataType.BOOLEAN);

  const expenseData = categoryByExpense.results.map((item: any) => {
    const booleanName = booleanColumn.map((col) => col.name);
    booleanName.forEach((name) => {
      item[name] = item[name] ? 'Yes' : 'No';
    });
    return item;
  });

  const updatedExpenseData = await getPopulatedData(expenseData);

  return {
    ...data,
    createdUser: user?.name,
    categoryName: category?.name,
    locationName: location.name,
    expenses: updatedExpenseData
  };
}
