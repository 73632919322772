import { Spin } from 'antd';
import { useState } from 'react';

import ErrorWidget from '../ErrorWidget';
import BarChart from '../../chart/BarChart';
import PieChart from '../../chart/PieChart';
import LineChart from '../../chart/LineChart';
import {
  IWidgetConfiguration,
  IGroupedData,
  IWidgetGetConfig
} from '@/services/dashboard/v3/types';
import getError from '@/utils/getError';
import WidgetMainFilter from '../filter/WidgetMainFilter';
import { saveDashboardDate } from '@/services/dashboard/v3/services.dashboard';

interface Props {
  data?: IGroupedData;
  fields: string[];
  reports: { id: number; name: string }[];
  widget: IWidgetConfiguration;
  getConfig: (props: IWidgetGetConfig) => Promise<void>;
  xAxisLabel?: string;
  handleFullScreen?: () => void;
}

function WidgetMainWrapper({
  widget,
  fields,
  data,
  getConfig,
  reports,
  xAxisLabel,
  handleFullScreen
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [memoConfig, setMemoConfig] = useState<string | undefined>();
  const [errorMsg, setErrorMsg] = useState('');

  function handleFilter() {
    setOpenFilter(true);
  }

  async function getConfigData(props: IWidgetGetConfig) {
    try {
      const reportIds = reports.map((r) => r.id);
      if (props.type === 'compare') {
        const currentReportFields = props.formFields[reportIds[0]];
        const selectedConfig = props.selectedDate;

        // Take date to see if there is any change
        const config = JSON.stringify({
          selectedConfig: props.selectedDate,
          formFields: props.formFields,
          reportIds,
          locationId: props.locationId
        });

        // If config is same, do not make API call
        if (memoConfig === config) {
          return;
        }

        setIsLoading(true);
        if (!props.isInitial) {
          await saveDashboardDate({
            formType: 'compare',
            widgets: [
              { id: widget.id, selectedDate: props.selectedDate, formFields: props.formFields }
            ]
          });
        }

        if (selectedConfig.key !== 'single') {
          // Ensure unique key are added
          const hasKey = currentReportFields?.find((f) => f.key === selectedConfig.formField);

          // Make sure, only one key is added
          if (!hasKey && currentReportFields) {
            currentReportFields.push({
              key: selectedConfig.formField,
              value: String(selectedConfig.formFieldValue)
            });
          }
        }

        await getConfig(props);
        setMemoConfig(config);
        return;
      }

      const config = JSON.stringify({
        date: props.date,
        formFields: props.formFields,
        reportIds,
        locationId: props.locationId
      });

      if (memoConfig === config) {
        return;
      }

      setIsLoading(true);
      if (!props.isInitial) {
        const field = {
          id: widget.id,
          date: props.date,
          type: props.dateType,
          formFields: props.formFields
        };

        await saveDashboardDate({
          formType: 'global',
          widgets: [field]
        });
      }
      await getConfig(props);
      setMemoConfig(config);
    } catch (error: unknown) {
      console.error(error);
      setErrorMsg(getError(error));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <WidgetMainFilter
        widget={widget}
        reports={reports}
        visible={openFilter}
        getConfig={getConfigData}
        setVisible={setOpenFilter}
      />
      <Spin spinning={isLoading}>
        <div className="relative chart-box w-full">
          {reports.length > 0 && !errorMsg && (
            <>
              {widget.chartType === 'line' && (
                <LineChart
                  data={data || {}}
                  fields={fields}
                  title={widget.title}
                  xAxisLabel={xAxisLabel}
                  allowZoom={widget.showZoom}
                  enlargeChart={widget.enlargeChart}
                  allowFilter={widget.showFilter}
                  smooth={widget.isSmooth}
                  isGradient={widget.isGradient ?? false}
                  handleFilterOpen={handleFilter}
                  handleFullScreen={handleFullScreen}
                  maxTicksLimit={widget.maxTicksLimit}
                />
              )}

              {widget.chartType === 'bar' && (
                <BarChart
                  data={data || {}}
                  fields={fields}
                  title={widget.title}
                  xAxisLabel={xAxisLabel}
                  allowZoom={widget.showZoom}
                  allowFilter={widget.showFilter}
                  isGradient={widget.isGradient ?? false}
                  enlargeChart={widget.enlargeChart}
                  borderRadius={widget.borderRadius}
                  handleFilterOpen={handleFilter}
                  handleFullScreen={handleFullScreen}
                  maxTicksLimit={widget.maxTicksLimit}
                />
              )}

              {widget.chartType === 'pie' && (
                <PieChart
                  data={data || {}}
                  fields={fields}
                  title={widget.title}
                  handleFilterOpen={handleFilter}
                  handleFullScreen={handleFullScreen}
                  allowFilter={widget.showFilter}
                  enlargeChart={widget.enlargeChart}
                  dataLimit={widget.pieChartLimit}
                />
              )}
            </>
          )}

          {errorMsg && !isLoading && <ErrorWidget message={errorMsg} />}
        </div>
      </Spin>
    </>
  );
}

export default WidgetMainWrapper;
