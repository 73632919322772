import moment from 'moment';
import { Empty, Image, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { useEffect, useState } from 'react';

import getPascalCase from '@/utils/getPascalCase';
import { getPopulatedData } from '@/services/expense/services';
import CustomTable from '@/components/Common/CustomResuableInvoice/CustomTable';
import isStringUTCDate from '@/utils/isStringUTCDate';
import { filterId } from '@/constants/filter.category';
import PreviewMultipleImage from '@/components/Common/PreviewMultipleImage';

interface Props {
  data: any[];
  type?: 'view' | 'print';
}

function DynamicTable({ data, type = 'view' }: Props) {
  const [categoryData, setCategoryData] = useState<any[]>([]);

  useEffect(() => {
    async function populateData() {
      try {
        const updatedData = await getPopulatedData(data);
        setCategoryData(updatedData);
      } catch (error) {
        console.log(error);
      }
    }

    if (type === 'view') populateData();
  }, []);

  if (type === 'view' && categoryData.length === 0) {
    return <Empty description="No data available" />;
  }

  // Check if "expenseId" is present in the columns
  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 7,
      fixed: 'left',
      render: (_, __, index: number) => index + 1
    },
    ...Object.keys(data[0])
      .filter((key) => key !== 'expenseId' && key !== 'id' && key !== 'medias')
      .map((key) => ({
        title: getPascalCase(key).replace(/Ids?$/, ''),
        key,
        dataIndex: key,
        label: getPascalCase(key).replace(/Ids?$/, ''),
        width: 15,
        render: (value: any, record: any) => {
          const isUTCDate = isStringUTCDate(value);
          if (!value && value !== 0) {
            return '-';
          }

          if (key === 'mediaIds' && record?.medias) {
            const medias = record?.medias as string[];
            return <PreviewMultipleImage medias={medias} />;
          }

          const isReferencial = filterId.find((id) => id.key === key);
          if (isReferencial) return value;
          return isUTCDate ? moment(value).format('YYYY-MM-DD') : value;
        }
      }))
  ];

  return (
    <>
      {type === 'view' && (
        <Table
          size="small"
          pagination={false}
          columns={columns}
          dataSource={categoryData}
          rowKey={'id'}
          scroll={{
            x: 800,
            y: 'calc(100vh - 300px)'
          }}
        />
      )}

      {type === 'print' && (
        <CustomTable
          columns={columns.slice(1).filter((col) => col.key !== 'mediaIds') as any}
          datas={data}
          serialStyle="w-8"
          isColumnCenter={false}
        />
      )}
    </>
  );
}

export default DynamicTable;
