import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import {
  Button,
  Collapse,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  PageHeader,
  Spin
} from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { RangePickerProps } from 'antd/lib/date-picker';
import Modal from 'antd/lib/modal/Modal';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import AppContent from '../../../../components/Common/Content/Content';
import CustomizeTable from '../../../../components/Common/CustomizeTable/CustomizeTable';
import { get_location_details } from '../../../../services/locations/queries';
import { get_product_details, get_units_list } from '../../../../services/products/queries';
import { download_report, get_report } from '../../../../services/report/queries';
import { get_user_details } from '../../../../services/users/queries';
import LocationsDB from '../../../../store/localstorage/LocationsDB';
import ProductsDB from '../../../../store/localstorage/ProductsDB';
import UnitsDB from '../../../../store/localstorage/UnitsDB';
import UsersDB from '../../../../store/localstorage/UsersDB';
import { convertLocalToUTCString } from '../../../../utils/convertToUTC';
import { nepaliNumberFormatter } from '../../../../utils/numberFormatter';
import { getInt } from '../pending-payment-sell-report/report';
import { CustomDatePresets } from '../utils/datePresets';
import { ILocations } from '../../../../services/locations/types';
import { check_date_cap, check_export_report_date_cap } from '../../../../utils/common';
import CustomInfoModal from '../../../../components/Common/CustomInfoModal';
import { showReportConditionalDownloadMessage } from '../../../../utils/reportConditionalDownloadMessage';
interface ReportData {
  count: any;
  data: any;
}
interface FooterData {
  totalSell: number;
  totalSellReturn: number;
  totalPurchase: number;
  totalPurchaseReturn: number;
  stockBox: number;
}
interface State {
  id: number[];
  name: string;
}
interface ReportProps {
  state: State;
  breadcrumbItems: any[];
  locationList: any[];
  form: FormInstance<any>;
  columnsData?: any[];
  footerData?: { sell?: boolean; purchase?: boolean };
  toSort: any;
  toSorttwo: any;
}

const CustomReport: React.FC<ReportProps> = ({
  state,
  breadcrumbItems,
  form,
  columnsData,
  locationList,

  footerData = {
    sell: false,
    purchase: false
  },
  toSort,
  toSorttwo,
  children
}) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [sale, setSale] = useState<ReportData>({ count: 0, data: [] });
  const [purchase, setPurchase] = useState<ReportData>({ count: 0, data: [] });
  const [stock, setStock] = useState<ReportData>({ count: 0, data: [] });
  const [footer, setFooterData] = useState<FooterData>({
    totalSell: 0,
    totalSellReturn: 0,
    totalPurchase: 0,
    totalPurchaseReturn: 0,
    stockBox: 0
  });
  const [page, setPage] = useState<number>(1);
  const [isloading, setIsloading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isEmpty1, setIsEmpty1] = useState(false);
  const [isEmpty2, setIsEmpty2] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (!columnsData) createColumns();
  }, [sale]);
  const createColumns = () => {
    //auto generate columns
    const newColumns: ColumnsType<any> = [];
    for (const key in sale.data[0]) {
      newColumns.push({
        title: key,
        key,
        render: (record: any) => {
          return <div className="forless">{record[key]}</div>;
        }
      });
    }
    setColumns(newColumns);
  };
  const onSearch = async (values: any, storePage = false) => {
    setIsloading(true);
    if (!storePage) {
      values.constraints['page'] = 1;
      setPage(1);
    }
    if (check_date_cap(values.constraints.startDate, values.constraints.endDate)) {
      // await downloadReport();
      showModal();
      setIsloading(false);
      return;
    }
    //remove custom date
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);

    await Promise.all([
      getSaleReport(values),
      getPurchaseReport(values),
      getStockBoxReport(values)
    ]);
    // await getFooterData(values);
    setIsloading(false);

    console.log('values', values);
  };

  const getSaleReport = async (values: any) => {
    const response = await get_report(values.constraints, state.id[0]);
    response.data = calculateTotalSell(response.data);
    if (response.data.data.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }

    setSale(response.data);
  };

  const getPurchaseReport = async (values: any) => {
    const response2 = await get_report(values.constraints, state.id[1]);
    response2.data = calculateTotalPurchase(response2.data);
    if (response2.data.data.length === 0) {
      setIsEmpty1(true);
    } else {
      setIsEmpty1(false);
    }

    setPurchase(response2.data);
  };

  const getStockBoxReport = async (values: any) => {
    const response3 = await get_report(values.constraints, state.id[2]);
    response3.data = calculateTotalStock(response3.data);
    if (response3.data.data.length === 0) {
      setIsEmpty2(true);
    } else {
      setIsEmpty2(false);
    }

    setStock(response3.data);
  };

  const calculateTotalSell = (data: any) => {
    let total = 0;
    let totalReturn = 0;
    for (let i = 0; i < data.data.length; i++) {
      data.data[i].locationName = data.data[i].location_name;
      total += getInt(data.data[i].total_amount);
      totalReturn += getInt(data.data[i].amount_returned);
    }
    setFooterData((prev) => {
      return { ...prev, totalSell: total, totalSellReturn: totalReturn };
    });
    return data;
  };
  const calculateTotalPurchase = (data: any) => {
    let total = 0;
    let totalReturn = 0;
    for (let i = 0; i < data.data.length; i++) {
      data.data[i].locationName = data.data[i].location_name;
      total += getInt(data.data[i]['total_amount']);
      totalReturn += getInt(data.data[i].amount_returned);
    }
    setFooterData((prev) => {
      return { ...prev, totalPurchase: total, totalPurchaseReturn: totalReturn };
    });
    return data;
  };

  const calculateTotalStock = (data: any) => {
    let total = 0;
    for (let i = 0; i < data.data.length; i++) {
      total += getInt(data.data[i].total_amount);
    }
    setFooterData((prev) => {
      return { ...prev, stockBox: total };
    });
    return data;
  };

  const addPage = async (value: boolean) => {
    //check total pages
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();
      if (value) {
        allValues.constraints.page = page + 1;
        onSearch(allValues, true);
        setPage((prev) => prev + 1);
      } else {
        allValues.constraints.page = page - 1;
        onSearch(allValues, true);
        setPage((prev) => prev - 1);
      }
    } catch (e: any) {
      console.log('validate error', e);
    }
  };

  const downloadReport = async (sell = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    await form.validateFields();
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    try {
      if (sell) {
        if (
          check_export_report_date_cap(values.constraints.startDate, values.constraints.endDate)
        ) {
          // const response1 =
          if (isEmpty) {
            setIsloading(false);
            showReportConditionalDownloadMessage();
            return;
          }
          values.constraints.customReportQueueName = 'Sell Report';
          message.info({
            key: 'downloading',
            content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
          });
          await download_report(values.constraints, state.id[0]);
          setIsloading(false);
          // window.open(response1.data, '_blank');
        }
      } else {
        if (
          check_export_report_date_cap(values.constraints.startDate, values.constraints.endDate)
        ) {
          if (isEmpty1) {
            setIsloading(false);
            showReportConditionalDownloadMessage();
            return;
          }
          // const response2 =
          values.constraints.customReportQueueName = 'Purchase Report';
          message.info({
            key: 'downloading',
            content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
          });
          await download_report(values.constraints, state.id[1]);
          setIsloading(false);
          // window.open(response2.data, '_blank');
        }
      }
    } catch (err: any) {
      setIsloading(false);
      message.error(err.message);
    }
  };

  return (
    <div>
      <Modal
        title="Download purchase and sell report!"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}>
        <div className="flex gap-2">
          <Button
            onClick={() => downloadReport(true)}
            style={{
              backgroundColor: '#0AA245',
              color: 'white',
              borderRadius: '10px'
            }}>
            Sell report
          </Button>
          <Button
            onClick={() => downloadReport(false)}
            style={{
              backgroundColor: '#0AA245',
              color: 'white',
              borderRadius: '10px'
              // marginLeft: '1rem'
            }}>
            Purchase report
          </Button>
        </div>
      </Modal>
      <Spin spinning={isloading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <Collapse>
            <CollapsePanel header="Filters" key="1">
              <Form
                form={form}
                onFinish={onSearch}
                layout="vertical"
                validateTrigger={'onChange'}
                autoComplete="off"
                disabled={isloading}>
                {/* <PageHeader
                  title={state?.name}
                  style={{
                    padding: '8px 0px'
                  }}
                /> */}
                {React.Children.count(children) > 1 && (
                  <>
                    <PageHeader
                      subTitle="Constraints"
                      style={{
                        padding: '0px 0px'
                      }}
                    />
                  </>
                )}
                <div className="grid grid-cols-4 gap-3 ">
                  {children}
                  <div className="flex justify-start mt-7 gap-1">
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
                        Search
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className="secondary-button"
                        onClick={() => showModal()}
                        style={{
                          borderRadius: '6px'
                        }}>
                        Download
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </CollapsePanel>
          </Collapse>
        </AppContent>
        <AppContent breadcrumbItems={[]}>
          <div className="grid grid-cols-2 gap-5">
            <div>
              <PageHeader
                subTitle="Sale"
                style={{
                  padding: '8px 0px'
                }}
              />
              <Table
                size="small"
                scroll={{ x: true }}
                pagination={false}
                // loading={isloading}
                onChange={toSort}
                columns={columnsData ? columnsData[0] : columns}
                dataSource={sale.data}
                footer={() =>
                  `Total Amount: ${nepaliNumberFormatter(
                    footer.totalSell
                  )}, Total Returned: ${nepaliNumberFormatter(footer.totalSellReturn)}`
                }
                summary={(pageData) => {
                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2} className="text-right mr-2">
                          {nepaliNumberFormatter(footer.totalSell)}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                        <Table.Summary.Cell index={4}></Table.Summary.Cell>
                        <Table.Summary.Cell index={5} className="text-right mr-2">
                          {nepaliNumberFormatter(footer.totalSellReturn)}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
                }}
              />
            </div>
            <div>
              <PageHeader
                subTitle="Purchase"
                style={{
                  padding: '8px 0px'
                }}
              />
              <Table
                scroll={{ x: true }}
                pagination={false}
                size="small"
                // loading={isloading}
                onChange={toSorttwo}
                columns={columnsData ? columnsData[1] : columns}
                dataSource={purchase.data}
                footer={() =>
                  `Total Amount: ${nepaliNumberFormatter(
                    footer.totalPurchase
                  )}, Total Returned: ${nepaliNumberFormatter(footer.totalPurchaseReturn)}`
                }
                summary={(pageData) => {
                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2} className="text-right mr-2">
                          {nepaliNumberFormatter(footer.totalPurchase)}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                        <Table.Summary.Cell index={4} className="text-right mr-2">
                          {nepaliNumberFormatter(footer.totalPurchaseReturn)}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
                }}
              />
            </div>
            <div>
              <PageHeader
                subTitle="Stock"
                style={{
                  padding: '8px 0px'
                }}
              />
              <Table
                size="small"
                scroll={{ x: true }}
                pagination={false}
                // loading={isloading}
                onChange={toSort}
                columns={columnsData ? columnsData[2] : columns}
                dataSource={stock.data}
                footer={() => `Total Amount: ${nepaliNumberFormatter(footer.stockBox)}`}
                summary={(pageData) => {
                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2} className="text-right mr-2">
                          {nepaliNumberFormatter(footer.stockBox)}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
                }}
              />
            </div>
          </div>
        </AppContent>
      </Spin>
    </div>
  );
};

export default CustomReport;
