import { Button, Divider, PageHeader, Spin } from 'antd';
import { useEffect, useState } from 'react';
import {
  ISellConfirmation,
  ISellConfirmationData,
  Line,
  LineForConfirm
} from '../../../services/sell/types';
import { get_customer_details, get_user_details } from '../../../services/users/queries';
import { useQuery } from '@tanstack/react-query';
import {
  ICustomerDetails,
  IUserAddressDataFromServer,
  IUserCreateTaxRequest
} from '../../../services/users/types';
import { get_location_details, get_location_list } from '../../../services/locations/queries';
import { ILocations } from '../../../services/locations/types';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import CustomizeTable from '../CustomizeTable/CustomizeTable';
import { ColumnsType } from 'antd/lib/table';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import { IProductType, IUnits } from '../../../services/products/types';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import { get_product_list_ids, get_units_list } from '../../../services/products/queries';
import TableCell from '../CustomizeTable/CustomCell';
import { nepaliNumberFormatter } from '../../../utils/numberFormatter';
import DebounceButton from '../DebounceButton';

const ReusableSellConfirm: React.FC<ISellConfirmation> = ({
  data,
  handleSubmitClick,
  isFinishLoading
}) => {
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [customerDetails, setCustomerDetails] = useState<ICustomerDetails>();
  const [locationDetails, setLocationDetails] = useState<ILocations>();
  const [addressDetails, setAddressDetails] = useState<IUserAddressDataFromServer>();
  const [tax, setTax] = useState<IUserCreateTaxRequest>();
  const [productList, setProductList] = useState<Line[]>([]);
  //console.log('data', data);

  useEffect(() => {
    if (data) {
      getAllData(data);
    }
  }, [data]);

  const getAllData = async (data: ISellConfirmationData) => {
    setIsloading(true);

    const userDetails = await get_customer_details(data.userId);
    if (userDetails) {
      setCustomerDetails(userDetails);
      if (data.addressId) {
        const add = userDetails.user.addresses.find((add) => add.id == data.addressId);
        setAddressDetails(add);
      }
      if (data.taxId) {
        const tax = userDetails.user.tax_informations.find((tax) => tax.id == data.taxId);
        setTax(tax);
      }
    }
    if (data.locationId) {
      let loc: ILocations = await LocationsDB.getLocation(data.locationId);
      if (!loc) {
        const allLocations = await get_location_list();
        await LocationsDB.addLocations([...allLocations.data.results]);
        loc = await LocationsDB.getLocation(data.locationId);
      }
      setLocationDetails(loc);
    }
    const totalQuantity: any = {};
    let totalVat = 0;
    let taxable = 0;
    let nonTaxable = 0;
    if (data.lines.length > 0) {
      const searchProducts: any = {};
      for (let index = 0; index < data.lines.length; index++) {
        const product: IProductType | string = await ProductsDB.getProduct(
          data.lines[index].productId
        );
        if (typeof product === 'object') {
          if (!product) {
            if (data.lines[index].productId in searchProducts) {
              searchProducts[data.lines[index].productId] = [
                ...searchProducts[data.lines[index].productId],
                index
              ];
            } else {
              searchProducts[data.lines[index].productId] = [index];
            }
          } else {
            data.lines[index].productName = product.name;
          }
          if (product.vat) {
            const currVat =
              (data.lines[index].quantity * data.lines[index].unitPrice -
                data.lines[index].discount) *
              (product.vat / 100);
            data.lines[index].vat = currVat;
            totalVat += currVat;
          }
        }

        let findUnit: IUnits | string = await UnitsDB.getUnit(data.lines[index].unitId);
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(data.lines[index].unitId);
        }
        if (typeof findUnit === 'object') {
          if (!totalQuantity[findUnit.shortName]) {
            totalQuantity[findUnit.shortName] = data.lines[index].quantity;
          } else {
            totalQuantity[findUnit.shortName] += data.lines[index].quantity;
          }
          data.lines[
            index
          ].quantityWithUnit = `${data.lines[index].quantity} ${findUnit.shortName}`;
          data.lines[index].shortName = findUnit.shortName;
        }
        const total =
          data.lines[index].unitPrice * data.lines[index].quantity +
          data.lines[index].misc -
          data.lines[index].discount;
        data.lines[index].total = total;

        if (typeof product === 'object') {
          if (product.vat != undefined) {
            product.vat > 0 ? (taxable += total) : (nonTaxable += total);
          }
        }
      }

      data.totalVat = totalVat;
      data.taxable = taxable;
      data.nonTaxable = nonTaxable;
      data.grandTotal = totalVat + taxable + nonTaxable;

      const searchProductslength = Object.entries(searchProducts).length;
      if (searchProductslength > 0) {
        const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
        for (const key in searchProducts) {
          const findproduct = productsresponse?.data.results.find(
            (currProduct: any) => currProduct.id == key
          );
          for (let i = 0; i < searchProducts[key].length; i++) {
            data.lines[searchProducts[key][i]].productName = findproduct?.name;
          }
          await ProductsDB.addProducts([findproduct]);
        }
      }

      setProductList(data.lines);
    }

    setIsloading(false);
  };

  const handleSubmit = async () => {
    if (data) {
      setIsloading(true);
      if (data.finalize) {
        await handleSubmitClick(data.finalize);
      } else {
        await handleSubmitClick(false);
      }
      setIsloading(false);
    }
  };

  const productsColumns: ColumnsType<LineForConfirm> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 10,
      render: (a, b, c) => <TableCell>{c + 1}</TableCell>
    },
    {
      title: 'ProductName',
      key: 'productName',
      width: 50,
      render: (a, record) => <TableCell>{record.productName}</TableCell>
    },
    {
      title: 'HS Code',
      key: 'hsCode',
      width: 30,
      render: (a, record) => <TableCell>{record?.hsCode || 'N/A'}</TableCell>
    },
    {
      title: 'Quantity',
      key: 'quantity',
      width: 30,
      render: (a, record) => <TableCell>{`${record.quantity} ${record.shortName}`}</TableCell>
    },
    {
      title: 'Rate',
      key: 'unitPrice',
      width: 30,
      render: (a, record) => (
        <TableCell>{parseFloat(record.unitPrice.toString()).toFixed(2)}</TableCell>
      )
    },
    {
      title: 'Discount',
      key: 'discount',
      width: 30,
      render: (a, record) => (
        <TableCell>{parseFloat(record.discount.toString()).toFixed(2)}</TableCell>
      )
    },
    {
      title: 'Misc.',
      key: 'misc',
      width: 30,
      render: (a, record) => <TableCell>{parseFloat(record.misc.toString()).toFixed(2)}</TableCell>
    },
    {
      title: 'VAT.',
      key: 'vat',
      width: 30,
      render: (a, record) => (
        <TableCell>{record.vat ? parseFloat(record.vat.toString()).toFixed(2) : '0.00'}</TableCell>
      )
    },
    {
      title: 'Total',
      key: 'total',
      width: 30,
      render: (a, record) => (
        <TableCell>{record.total && parseFloat(record.total.toString()).toFixed(2)}</TableCell>
      )
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <div className="grid grid-cols-2 gap-5 mb-5">
        <div>
          <PageHeader
            subTitle="Customer"
            style={{
              padding: '2px 0px'
            }}
          />
          {data?.userId && (
            <div>
              {customerDetails?.user.user.name}, {customerDetails?.user.user.email}
            </div>
          )}
          {data?.userId && <div>{customerDetails?.user.user.phone}</div>}
        </div>
        <div>
          <PageHeader
            subTitle="Location"
            style={{
              padding: '2px 0px'
            }}
          />
          {data?.locationId && (
            <div>
              {locationDetails?.name}, {locationDetails?.address}, {locationDetails?.state},{' '}
              {locationDetails?.city}, {locationDetails?.country}
            </div>
          )}
          {data?.locationId && <div>{locationDetails?.email}</div>}
        </div>
      </div>
      <Divider />
      <PageHeader
        subTitle="Products"
        style={{
          padding: '2px 0px'
        }}
      />
      <CustomizeTable columns={productsColumns} notshowPagination={true} data={productList} />
      <div className="text-md font-bold mt-4">
        Taxable : {data?.taxable != undefined ? nepaliNumberFormatter(data.taxable) : ''}
      </div>
      <div className="text-md font-bold">
        Non-Taxable : {data?.nonTaxable != undefined ? nepaliNumberFormatter(data.nonTaxable) : ''}
      </div>
      <div className="text-md font-bold">
        VAT : {data?.totalVat ? nepaliNumberFormatter(data.totalVat) : ''}
      </div>
      <div className="text-md font-bold">
        Grand Total (Without Shipping) :{' '}
        {data?.grandTotal ? nepaliNumberFormatter(data.grandTotal) : ''}
      </div>
      <Divider />
      <PageHeader
        subTitle="Additional Details"
        style={{
          padding: '2px 0px'
        }}
      />
      {data ? (
        <div className="font-semibold">
          Address :{' '}
          {addressDetails?.addressLine1
            ? addressDetails.addressLine1
            : addressDetails?.addressLine2}
        </div>
      ) : (
        ''
      )}
      {data?.taxId ? (
        <div className="font-semibold">
          Tax : {tax?.taxType} {tax?.taxNumber ? '(' + tax.taxNumber + ')' : ''}
        </div>
      ) : (
        ''
      )}
      {data ? (
        <div className="font-semibold">Discount : {nepaliNumberFormatter(data.discount)}</div>
      ) : (
        ''
      )}
      {data ? <div className="font-semibold">Misc. : {nepaliNumberFormatter(data.misc)}</div> : ''}
      {data ? (
        <div className="font-semibold">
          Shipping Charge : {nepaliNumberFormatter(data.shipping)}
        </div>
      ) : (
        ''
      )}
      {data ? (
        <div className="font-semibold">
          Shipping Tax : {nepaliNumberFormatter(data.shipping * (13 / 100))}
        </div>
      ) : (
        ''
      )}
      {data ? (
        <div className="font-semibold">Adjustment : {nepaliNumberFormatter(data.adjustment)}</div>
      ) : (
        ''
      )}
      <div className="flex justify-end mt-5">
        <DebounceButton
          type="primary"
          disabled={isLoading || isFinishLoading}
          onClick={handleSubmit}>
          Confirm
        </DebounceButton>
      </div>
    </Spin>
  );
};

export default ReusableSellConfirm;
