import { useState } from 'react';

// Antd Components
import { Alert, Collapse, PageHeader, Spin, message } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';

import FilterForm from './Form';
import VendorTable from './Vendortable';
import CustomerTable from './CustomerTable';
import AppContent from '@/components/Common/Content/Content';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { check_date_cap, check_export_report_date_cap } from '@/utils/common';
import { ICustomerReportType, IVendorReportType } from '@/services/report/types';

import withReportInitialization, {
  ReportDownloadInitialValues,
  WithReportInitializationProps
} from '@/components/Common/Report/withReportInitialization';

import {
  downloadCustomerVendorHistoryAPI,
  getCustomerVendorHistoryAPI
} from '@/services/report/queries';
import { showReportConditionalDownloadMessage } from '../../../../utils/reportConditionalDownloadMessage';

export type ExtendedInitialValues = ReportDownloadInitialValues & {
  constraints: {
    type: 'customer' | 'vendor';
  };
};

function CustomerVendorReport({ form, initialValues, ...handlers }: WithReportInitializationProps) {
  const breadcrumbItems = [
    { label: 'Reports', link: '/reports' },
    { label: 'Customer & Supplier Report', link: '/reports/customer-supplier-report' }
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [defaultType, setDefaultType] = useState<'customer' | 'vendor'>('customer');
  const [data, setData] = useState<ICustomerReportType[] | IVendorReportType[]>([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const extendedInitialValues = {
    ...initialValues,
    constraints: {
      ...initialValues.constraints,
      type: defaultType
    }
  };

  async function onSearch(values: ExtendedInitialValues) {
    setIsLoading(true);

    try {
      // Make shallow copy of values.constraints
      const constraints = { ...values.constraints };

      // Check if the date cap is valid or not
      const hasDateCap = check_date_cap(constraints.startDate, constraints.endDate);

      if (hasDateCap) {
        await onDownload();
        return;
      }

      if ('dateCustom' in constraints) {
        delete constraints.dateCustom;
      }

      constraints.startDate = convertLocalToUTCString(constraints.startDate);
      constraints.endDate = convertLocalToUTCString(constraints.endDate);

      // Fetch data from the server using the constraints
      const data = await getCustomerVendorHistoryAPI(constraints, constraints.type);
      setData(data);
      if (data.length === 0) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }

      // Set the default type either customer or vendor
      setDefaultType(constraints.type);
    } finally {
      setIsLoading(false);
    }
  }

  async function onDownload() {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsLoading(true);

    try {
      // Get the form values and validate them
      const values = form.getFieldsValue() as ExtendedInitialValues;
      await form.validateFields();

      // Make shallow copy of values.constraints
      const constraints = { ...values.constraints };
      if ('dateCustom' in constraints) {
        delete constraints.dateCustom;
      }

      // Convert the date to UTC string
      constraints.startDate = convertLocalToUTCString(constraints.startDate);
      constraints.endDate = convertLocalToUTCString(constraints.endDate);

      // Check if the date cap is valid or not
      const hasExportReportCap = check_export_report_date_cap(
        constraints.startDate,
        constraints.endDate
      );

      if (!hasExportReportCap) return;
      const hideMessageHandler = message.info(
        'Report is being generated. Download will start soon.'
      );

      // Download the report. Will response csv file url in the response
      const response = await downloadCustomerVendorHistoryAPI(constraints, constraints.type);
      window.open(response.url, '_blank');

      hideMessageHandler();
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Collapse>
          <CollapsePanel header="Filters" key="1">
            <FilterForm
              form={form}
              isLoading={isLoading}
              initialValues={extendedInitialValues}
              // This is the function that is called when the form is submitted
              onSearch={onSearch}
              onChange={handlers.onChange}
              onDownload={onDownload}
              onDisabledDate={handlers.onDisabledDate}
              onChangeNepali={handlers.onChangeNepali}
            />
          </CollapsePanel>
        </Collapse>

        <PageHeader subTitle="Table" style={{ padding: '8px 0px' }} />
        <Alert
          message="Information!"
          description="Please note, the numbers under the dates is the amount bought for customer and amount sold for supplier."
          type="info"
          closable
          showIcon
        />
        <div className="mt-2">
          {defaultType === 'customer' && (
            <CustomerTable data={data as ICustomerReportType[]} key={defaultType} />
          )}
          {defaultType === 'vendor' && (
            <VendorTable data={data as IVendorReportType[]} key={defaultType} />
          )}
        </div>
      </AppContent>
    </Spin>
  );
}

CustomerVendorReport.displayName = 'CustomerVendorReport';

export default withReportInitialization(CustomerVendorReport);
