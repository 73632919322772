import { Checkbox, Form, PageHeader } from 'antd';
import { IChartType } from '@/services/dashboard/v3/types';

interface Props {
  selectedChartType?: IChartType;
}

function MiscellaneousFeaturesForm({ selectedChartType }: Props) {
  return (
    <div>
      <PageHeader subTitle="Miscellaneous Features" style={{ padding: '4px 0' }} />
      <div className="flex gap-2 flex-wrap">
        <Form.Item name="showFilter" valuePropName="checked">
          <Checkbox>
            <span>Filter Options</span>
          </Checkbox>
        </Form.Item>

        <Form.Item name="showZoom" valuePropName="checked" hidden={selectedChartType === 'pie'}>
          <Checkbox>
            <span>Zoom functionality</span>
          </Checkbox>
        </Form.Item>

        <Form.Item name="enlargeChart" valuePropName="checked">
          <Checkbox>
            <span>Full Screen</span>
          </Checkbox>
        </Form.Item>
      </div>
    </div>
  );
}

export default MiscellaneousFeaturesForm;
