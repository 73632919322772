import LocalStore from '.';
import { IExpenseCategory } from '../../services/expense/expense-category/types';
export default class ExpenseCategoryDB extends LocalStore {
  static async search(value: string, limit = 10): Promise<IExpenseCategory[]> {
    if (!ExpenseCategoryDB.db) await ExpenseCategoryDB.init();
    return new Promise((resolve: (value: any[]) => void, reject) => {
      const transaction = ExpenseCategoryDB.db.transaction('ExpenseCategory', 'readonly');

      const filteredExpenseCategory: IExpenseCategory[] = [];
      const objectStore = transaction.objectStore('ExpenseCategory');
      const lowercaseString = value.toLowerCase();
      const cursorRequest = objectStore.index('ExpenseCategorySecondIndex').openCursor();

      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        // console.log('thishits', cursor.value);
        if (cursor) {
          if (
            cursor.value.lowercaseName?.includes(lowercaseString) ||
            cursor.value.code?.toLowerCase().includes(value?.toLowerCase())
          ) {
            filteredExpenseCategory.push(cursor.value);
          }
          if (filteredExpenseCategory.length < limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(filteredExpenseCategory);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async add(expenseCategoryData: IExpenseCategory[]) {
    if (!ExpenseCategoryDB.db) await ExpenseCategoryDB.init();
    return new Promise((resolve, reject) => {
      const transaction = ExpenseCategoryDB.db.transaction('ExpenseCategory', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('ExpenseCategory');
      expenseCategoryData.forEach(async (expenseCategory) => {
        const checkKey = objectStore.count(expenseCategory.id);
        checkKey.onsuccess = async (event: any) => {
          // console.log('expense', expenseCategory);
          if (checkKey.result == 0) {
            expenseCategory.lowercaseName = expenseCategory.name.toLowerCase();
            const request = objectStore.add(expenseCategory);
            request.onerror = (event: any) => {
              reject(event.target.error);
            };
          } else {
            await ExpenseCategoryDB.update(expenseCategory);
          }
        };
      });
    });
  }

  static async getAll(): Promise<IExpenseCategory[]> {
    if (!ExpenseCategoryDB.db) await ExpenseCategoryDB.init();
    return new Promise((resolve, reject) => {
      const transaction = ExpenseCategoryDB.db.transaction('ExpenseCategory', 'readonly');
      //   transaction.oncomplete = (event: any) => {
      //     resolve('success');
      //   };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('ExpenseCategory');
      const request = objectStore.getAll();
      request.onsuccess = (event: any) => {
        resolve(event.target.result as IExpenseCategory[]);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async removeAll() {
    if (!ExpenseCategoryDB.db) await ExpenseCategoryDB.init();

    return new Promise((resolve, reject) => {
      const transaction = ExpenseCategoryDB.db.transaction(['ExpenseCategory'], 'readwrite');
      transaction.oncomplete = (event) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('ExpenseCategory');
      const request = objectStore.clear();
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async update(expenseCategoryData: IExpenseCategory) {
    if (!ExpenseCategoryDB.db) await ExpenseCategoryDB.init();

    return new Promise((resolve, reject) => {
      const transaction = ExpenseCategoryDB.db.transaction(['ExpenseCategory'], 'readwrite');
      transaction.oncomplete = (event) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('ExpenseCategory');
      expenseCategoryData.lowercaseName = expenseCategoryData.name.toLowerCase();
      const request = objectStore.put(expenseCategoryData);
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async remove(expenseCategoryId: number) {
    if (!ExpenseCategoryDB.db) await ExpenseCategoryDB.init();

    return new Promise((resolve, reject) => {
      const transaction = ExpenseCategoryDB.db.transaction(['ExpenseCategory'], 'readwrite');
      transaction.oncomplete = (event) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('ExpenseCategory');
      const request = objectStore.delete(expenseCategoryId);
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async get(expenseCategoryId: number | string): Promise<IExpenseCategory> {
    if (!ExpenseCategoryDB.db) await ExpenseCategoryDB.init();

    return new Promise((resolve, reject) => {
      const transaction = ExpenseCategoryDB.db.transaction(['ExpenseCategory'], 'readonly');
      //   transaction.oncomplete = (event) => {
      //     resolve('success');
      //   };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('ExpenseCategory');
      const request = objectStore.get(expenseCategoryId);
      request.onsuccess = (event: any) => {
        resolve(event.target.result as IExpenseCategory);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
}
