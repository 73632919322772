import { Divider, Image, PageHeader, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { get_image } from '../../../components/Common/FetchImage/FetchImage';
import { API_URL } from '../../../constants/config';
import { get_invoices_list } from '../../../services/settings/queries';
import freshktmfulllogo from '../../../layouts/logo/freshktmfulllogo.png';
import type { ColumnsType } from 'antd/es/table';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import CustomTable from './CustomTable';
import CustomBarcode from '../../../pages/barcode';
import { nepaliNumberFormatter } from '../../../utils/numberFormatter';
import {
  convertLocalToUTCString,
  convertUTCStringtoLocalString
} from '../../../utils/convertToUTC';
import { clearLocalStorage } from '../../../utils/storage.utils';
import { getUserData } from '../../../utils/auth.utils';

export const TransferInvoice = () => {
  const loggedInUser = getUserData();
  // const { state }: any = useLocation();
  const state = JSON.parse(localStorage.getItem('referrer') as string);
  const {
    customerDetails,
    vendorDetails,
    locationDetails,
    purchaseDetails,
    lines,
    invoiceLayouts,
    adjustmentDetails,
    transferData
  } = state;
  console.log(state);

  if (invoiceLayouts) {
    if ('content' in invoiceLayouts) {
      if (typeof invoiceLayouts.content == 'string')
        invoiceLayouts.content = JSON.parse(invoiceLayouts.content);
    } else {
      invoiceLayouts.content = '';
    }
  }

  const columns: any = [
    {
      label: `${
        invoiceLayouts?.content?.productLabel ? invoiceLayouts.content.productLabel : 'ProductName'
      }`,
      dataIndex: 'productName',
      // width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.unitPriceLabel
          ? invoiceLayouts.content.unitPriceLabel
          : 'UnitPrice'
      }`,
      dataIndex: 'unitPrice',
      // width: 100,
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.quantityLabel ? invoiceLayouts.content.quantityLabel : 'Quantity'
      }`,
      dataIndex: 'quantity',
      // width: 100,
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: 'Misc',
      dataIndex: 'misc',
      // width: 100,
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.discountLabel ? invoiceLayouts.content.discountLabel : 'Discount'
      }`,
      dataIndex: 'discount',
      // width: 100,
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.subTotalLabel
          ? invoiceLayouts.content.subTotalLabel
          : 'Total Amount'
      }`,
      dataIndex: 'totalAmount',
      // width: 100,
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    }
  ];

  useEffect(() => {
    if (transferData) {
      window.print();
      window.onafterprint = (event) => {
        window.close();
        localStorage.removeItem('referrer');
      };
    }
  }, []);

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex justify-between font-bold text-xl mt-4">
          Stock Transfers Details (
          {invoiceLayouts?.content?.referenceNumberLabel
            ? invoiceLayouts?.content?.referenceNumberLabel
            : 'Reference No'}{' '}
          : {transferData.referenceNo})
        </div>
        <div className="flex justify-center items-end">
          {invoiceLayouts && invoiceLayouts?.content?.dateLabel
            ? invoiceLayouts.content.dateLabel
            : 'Date'}

          {convertLocalToUTCString(transferData?.transferDate, 'YYYY MM-DD')}
        </div>
      </div>
      <hr />
      <div className="grid grid-cols-3 gap-5 mb-5">
        {invoiceLayouts?.content?.showLocations ? (
          <>
            <div>
              <PageHeader
                subTitle="Location(From)"
                style={{
                  padding: '8px 0px',
                  fontWeight: 'bold'
                }}
              />
              <div className="text-sm font-bold">{transferData.fromName} </div>
              <div>{transferData.fromDetails} </div>
              <div>{transferData.fromEmail}</div>
            </div>
            <div>
              <PageHeader
                subTitle="Location(To)"
                style={{
                  padding: '8px 0px',
                  fontWeight: 'bold'
                }}
              />
              <div className="text-sm font-bold">{transferData.toName} </div>
              <div>{transferData.toDetails}</div>
              <div>{transferData.toEmail}</div>
            </div>
          </>
        ) : (
          <></>
        )}
        <div>
          <PageHeader
            subTitle="Details"
            style={{
              padding: '8px 0px',
              fontWeight: 'bold'
            }}
          />
          {transferData ? (
            <>
              <div>
                {invoiceLayouts?.content?.referenceNumberLabel
                  ? invoiceLayouts?.content?.referenceNumberLabel
                  : 'Reference No.'}{' '}
                : {transferData?.referenceNo}
              </div>
              <div>
                Created : {convertUTCStringtoLocalString(transferData?.transferDate, 'YYYY MM-DD')}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {invoiceLayouts && invoiceLayouts?.content?.invoiceNumberLabel ? (
        <div>{invoiceLayouts.content.invoiceNumberLabel}</div>
      ) : (
        <></>
      )}
      <CustomTable columns={columns} datas={lines ? lines : []} />
      {transferData && (
        <div className="grid grid-cols-3 gap-5 mb-5">
          <div>
            <PageHeader
              subTitle="Payment Info"
              style={{
                padding: '8px 0px'
              }}
            />
            {transferData ? (
              <>
                <div>
                  {invoiceLayouts?.content?.netTotalAmount
                    ? invoiceLayouts?.content?.netTotalAmount
                    : 'Net Total Amount'}{' '}
                  : Rs {transferData.netTotalAmount}
                </div>
                <div>
                  {invoiceLayouts?.content?.shippingChargeLabel
                    ? invoiceLayouts?.content?.shippingChargeLabel
                    : 'Additional Shipping Charge'}{' '}
                  :{' '}
                </div>
                <div>Purchase Total : Rs {transferData.netTotalAmount}</div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      {invoiceLayouts?.content?.footerText ? (
        <div dangerouslySetInnerHTML={{ __html: invoiceLayouts?.content?.footerText }}></div>
      ) : (
        <></>
      )}
      {invoiceLayouts?.content?.showBarcode && (
        <div className="flex items-center justify-center">
          <CustomBarcode value={transferData.referenceNo} height={50} />
        </div>
      )}
      <div className="flex justify-end">
        <div>
          Printed By:{' '}
          <span className="capitalize">{loggedInUser?.name ? loggedInUser.name : ''}</span>
        </div>
      </div>
    </div>
  );
};
