import React from 'react';
import { Router } from 'react-router-dom';

/*
  This is a round about way of making HashRouter and HashHistory working in v6
  of react router. Since v6 doesn't support location state in HashRouter, custom updates are made. 
*/
export const CustomRouter: React.FC<{ basename?: any; children: any; history: any }> = ({
  basename = '',
  children,
  history
}) => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={history}>
      {children}
    </Router>
  );
};
