import LocalStore from '.';

export default class RoutesDB extends LocalStore {
  /*Routes Functions*/
  /*search for route by name, id with limit*/

  static async searchRoute(name: string, limit = 10) {
    if (!RoutesDB.db) await RoutesDB.init();
    const filteredRoutes: any[] = [];
    const lowerCaseString = name.toLowerCase();
    return new Promise((resolve: (value: any[]) => void, reject) => {
      const transaction = RoutesDB.db.transaction('Routes', 'readonly');
      const objectStore = transaction.objectStore('Routes');
      const cursorRequest = objectStore.index('RoutesSecondIndex').openCursor();
      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          if (
            cursor.value?.lowercaseName?.includes(lowerCaseString) ||
            JSON.stringify(cursor.value?.id)?.toLowerCase()?.includes(lowerCaseString)
          ) {
            filteredRoutes.push(cursor.value);
          }
          if (filteredRoutes.length <= limit) cursor.continue();
        }
      };

      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };

      transaction.oncomplete = (event: any) => {
        resolve(filteredRoutes);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  /*new data is added, existing data is updated*/
  static async addRoutes(routesData: any[]) {
    if (!RoutesDB.db) await RoutesDB.init();
    return new Promise((resolve, reject) => {
      const transaction = RoutesDB.db.transaction('Routes', 'readwrite');
      transaction.oncomplete = (event: any) => {
        // console.log('Event1-->', event);
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        // console.log('event2-->', event);
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Routes');
      routesData.forEach(async (route) => {
        const checkKey = objectStore.count(route.id);
        checkKey.onsuccess = async (event: any) => {
          if (checkKey.result == 0) {
            route.lowercaseName = route.name?.toLowerCase();
            const request = objectStore.add(route);
            request.onerror = (event: any) => {
              // console.log('Event3-->', event);
              reject(event.target.error);
            };
          } else {
            await RoutesDB.updateRoutes(route);
          }
        };
      });
    });
  }
  static async getAllRoutes() {
    if (!RoutesDB.db) await RoutesDB.init();
    return new Promise((resolve, reject) => {
      const transaction = RoutesDB.db.transaction('Routes', 'readonly');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Routes');
      const request = objectStore.getAll();
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async removeAllRoutes() {
    if (!RoutesDB.db) await RoutesDB.init();

    return new Promise((resolve, reject) => {
      const transaction = RoutesDB.db.transaction(['Routes'], 'readwrite');
      transaction.oncomplete = (event) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Routes');
      const objectStoreRequest = objectStore.clear();
      objectStoreRequest.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      objectStoreRequest.onerror = (event: any) => {
        reject(event.target.result);
      };
    });
  }
  static async getRoute(id: number | string) {
    if (!RoutesDB.db) await RoutesDB.init();

    return new Promise((resolve, reject) => {
      const transaction = RoutesDB.db.transaction('Routes', 'readonly');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Routes');
      const request = objectStore.get(id);
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
        // if (event.target.result) resolve(event.target.result);
        // reject('Error, could not find id.');
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async updateRoutes(route: any) {
    if (!RoutesDB.db) await RoutesDB.init();

    return new Promise((resolve, reject) => {
      const transaction = RoutesDB.db.transaction('Routes', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Routes');
      const request = objectStore.get(route.id);
      request.onsuccess = (event: any) => {
        if (event.target.result) {
          route.lowercaseName = route.name.toLowerCase();
          const requestUpdate = objectStore.put(route);
          requestUpdate.onerror = (event: any) => {
            reject(event.target.error);
          };
          requestUpdate.onsuccess = (event) => {
            resolve('updated data');
          };
        } else reject('Error, could not find id.');
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async deleteRoutes(id: number) {
    if (!RoutesDB.db) await RoutesDB.init();

    if (await RoutesDB.getRoute(id)) {
      return new Promise((resolve, reject) => {
        const transaction = RoutesDB.db.transaction('Routes', 'readwrite');
        transaction.oncomplete = (event: any) => {
          resolve('success');
        };
        transaction.onerror = (event: any) => {
          reject(event.target.error);
        };
        const objectStore = transaction.objectStore('Routes');
        const request = objectStore.delete(id);
        request.onsuccess = (event: any) => {
          resolve('deleted successfuly.');
          // if (event.target.result) resolve(event.target.result);
          // reject('Error, could not find id.');
        };
        request.onerror = (event: any) => {
          reject(event.target.error);
        };
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve('id does not exist');
      });
    }
  }
}
