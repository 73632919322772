import { useEffect, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router';

import { useQuery, useMutation } from '@tanstack/react-query';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';

import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  message,
  PageHeader,
  Divider,
  Upload,
  Checkbox
} from 'antd';

import { ICategory } from '@/services/category/types';
import { API_URL, JWT_TOKEN } from '@/constants/config';
import AppContent from '@/components/Common/Content/Content';
import { get_category_list } from '@/services/category/queries';
import { update_products_mutation, upload_default_unitId } from '@/services/products/mutations';

import { checkAccess } from '@/routes/acl';
import ReuseChannel from '../../channel/Reuse';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { getFilteredUnits } from '@/utils/units.utils';
import { getLocalStorage } from '@/utils/storage.utils';

import CategoryDB from '@/store/localstorage/CategoryDB';
import ProductsDB from '@/store/localstorage/ProductsDB';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { get_image } from '@/components/Common/FetchImage/FetchImage';
import { get_product_details, get_units_list } from '@/services/products/queries';
import { IProductResponse, IUnits, Unit, IProductType } from '@/services/products/types';
import CustomInfoModal from '@/components/Common/CustomInfoModal';

const { Option } = Select;

const Details = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();

  const [isloading, setIsloading] = useState<boolean>(true);
  const [buttonloading, setButtonloading] = useState<boolean>(false);
  const [productDetails, setProductDetails] = useState<any>();
  const [unitsList, setUnitsList] = useState<IUnits[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileList2, setFileList2] = useState<UploadFile[]>([]);
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const [previousunitsize, setPreviousunitsize] = useState<number>(0);
  const [baseUnit, setBaseUnit] = useState('');
  const [previousDefaultUnitId, setpreviousDefaultUnitId] = useState<number>();
  const getDataFromLC = async () => {
    const allcategory: ICategory[] = (await CategoryDB.getAllCategory()) as ICategory[];
    if (allcategory.length > 0) {
      setCategoryList(allcategory);
    } else {
      const response = await get_category_list();
      setCategoryList(response.data.results);
      CategoryDB.addCategory(response.data.results);
    }
  };
  const loginUserData = JSON.parse(localStorage.getItem('@fresh-user')!);
  const hasadminAccess = loginUserData.perms.includes('ADMIN');

  useEffect(() => {
    // Fetch units and store in indexDB
    (async function () {
      UnitsDB.addUnits(await get_units_list());
    })();
  }, []);

  useQuery(['productDetails', id], async () => get_product_details((id && parseInt(id)) || 0), {
    retry: false,
    onSuccess: async (data: IProductType) => {
      // console.log('Product details--->', data);
      // unitExists: true,
      const defaultUnit: any = data.productUnits.find((curr: any) => curr.isDefault == true);
      setpreviousDefaultUnitId(defaultUnit?.unitId);
      const units = data.productUnits.map((currunit: any) => {
        return {
          ...currunit.__unit__,
          defaultUnit: currunit.isDefault,
          unitExists: true
        };
      });
      const product = {
        name: data.name,
        description: data.description,
        sku: data.sku,
        lotExpiryDuration: data.lotExpiryDuration,
        categoryId: data.categoryId,
        bannerId: data.bannerId,
        imgId: data.imgId,
        vat: data.vat,
        purchasePrice: data.purchasePrice,
        units: [...units],
        hidden: data.hidden
      };
      let imgData = '';
      let bannerData = '';
      if (data.imgId) {
        imgData = await get_image(`${API_URL}storage/media/${data.imgId}`);
      }
      if (data.bannerId) {
        bannerData = await get_image(`${API_URL}storage/media/${data.bannerId}`);
      }
      setFileList([{ uid: '-1', name: 'test.png', status: 'done', url: imgData }]);
      setFileList2([{ uid: '-1', name: 'test2.png', status: 'done', url: bannerData }]);

      const baseDefaultUnit = defaultUnit?.__unit__?.baseUnitName || product.units[0].baseUnitName;
      setBaseUnit(baseDefaultUnit);
      const { updatedUnits, removedUnits } = getFilteredUnits(product.units, baseDefaultUnit);

      {
        removedUnits &&
          CustomInfoModal({
            message: `Units "${removedUnits}" are not based on base unit. So they have been filtered out from the list.`,
            title: 'Filtered Units'
          });
      }

      product.units = updatedUnits;
      setPreviousunitsize(product.units.length);

      form.setFieldsValue(product);
      let allunits: IUnits[] = (await UnitsDB.getAllUnits()) as IUnits[];
      if (allunits.length == 0) {
        allunits = await get_units_list();
        UnitsDB.addUnits(allunits);
      }

      // Set default base unit from the first unit or the default unit
      const unitBasedOnBaseUnit = allunits.filter(
        (unit: IUnits) => unit.baseUnitName === baseDefaultUnit
      );
      setUnitsList(unitBasedOnBaseUnit);
      setIsloading(false);
    }
  });

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('imgId', updatedFileId);
      setProductDetails({ ...productDetails, imgId: updatedFileId });
    }
    setFileList(newFileList);
  };

  const uploadProps: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: fileList,
    accept: 'image/*',
    onPreview: onPreview,
    onChange: onChange
  };
  const onChange2: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('bannerId', updatedFileId);
      setProductDetails({ ...productDetails, bannerId: updatedFileId });
    }
    setFileList2(newFileList);
  };

  const uploadProps2: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: fileList2,
    accept: 'image/*',
    onPreview: onPreview,
    onChange: onChange2
  };

  const uploadButton = (
    <div className="rounded-full">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const breadcrumbItems = [
    {
      label: 'Products',
      link: '/products'
    },
    {
      label: 'Details',
      link: `/products/${id}`
    }
  ];
  const updateProductsMutation = useMutation(update_products_mutation);
  const updateDefaultUnitIdMutation = useMutation(upload_default_unitId);

  const onFinish = async (values: any) => {
    setIsloading(true);
    setButtonloading(true);

    const defaultUnit: any = values.units.find((curr: any) => curr.defaultUnit == true);
    try {
      /*    const isEveryUnitSame = values.units.every(
        (unit: any) => unit.baseUnitName === values.units[0].baseUnitName
      );

      if (!isEveryUnitSame) {
        message.error('All units should have same base unit');
        setIsloading(false);
        setButtonloading(false);
        return;
      } */

      if (id) {
        values.id = parseInt(id);
        // if (values.units && values.units.length <= 0) {
        if (!values.units) {
          values.units = [];
        } else {
          values.units = values.units.map((unit: Unit) => {
            const productUnitValues = {
              name: unit.name,
              shortName: unit.shortName,
              description: unit.description,
              baseUnitMultiplier: unit.baseUnitMultiplier
              // isDefault: unit.defaultUnit
            };
            if (unit.id) {
              return {
                id: unit.id,
                ...productUnitValues
              };
            } else {
              // isFetch = true;
              return productUnitValues;
            }
          });
        }
        if (defaultUnit?.id !== previousDefaultUnitId) {
          updateDefaultUnitIdMutation.mutateAsync(
            { productId: id, unitId: defaultUnit.id! },
            {
              onSuccess: async ({ data }: { data: IProductResponse }) => {
                message.success('Default Unit updated successfully');
              },
              onError: (e: any) => {
                message.error(`${e.response.data.message}`, 5);
              }
            }
          );
        }
        await updateProductsMutation.mutateAsync(values, {
          onSuccess: async ({ data }: { data: any }) => {
            setIsloading(false);
            setButtonloading(false);
            const productUnits = values?.units?.map((curr: any) => {
              if (curr.id == defaultUnit.id) {
                return {
                  isDefault: true,
                  unitId: curr.id,
                  productId: parseInt(id as string)
                };
              } else {
                return {
                  isDefault: false,
                  unitId: curr.id,
                  productId: parseInt(id as string)
                };
              }
            });
            delete values.units;
            ProductsDB.addProducts([
              {
                ...values,
                productUnits
              }
            ]);
            message.success('Product updated successfully');
            navigate('/products');
          },
          onError: (e: any) => {
            setIsloading(false);
            const errorMessage = e?.response?.data?.message;
            if (errorMessage) {
              throw new Error(errorMessage);
            }

            if (e.response.data.exception.response.products) {
              CustomErrorModal({
                message: (
                  <div>
                    {e.response.data.message}
                    <br></br>
                    <div className="grid grid-cols-1">
                      {e.response.data.exception.response.products.map((value: any) => (
                        <>
                          <div className="grid grid-cols-2">
                            <span>
                              Product : <b>{value.name}</b>
                            </span>
                            <span>
                              SKU : <b>{value.sku}</b>
                            </span>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                )
              });
            } else {
              message.error(`${e.response.data.message}`, 5);
            }
          }
        });

        // Refetch units since socket will remove baseUnitName from units in indexDB
        UnitsDB.addUnits(await get_units_list());
      }
    } catch (errors: any) {
      setIsloading(false);
      setButtonloading(false);
      const errorMessage =
        errors?.response?.data?.message ||
        errors.message ||
        'Something went wrong while updating product details.';

      message.error(errorMessage, 5);
    }
  };

  const handleUnitFieldsDisabled = (name: number) => {
    const existingUnits = form.getFieldValue('units');
    return existingUnits?.[name]?.['unitExists'];
  };

  const getExistingUnitsDetails = (id: number, name: number) => {
    const existingUnits = form.getFieldValue('units');
    const isalreadyexists = existingUnits.find((curr: any) => curr.id == id);
    if (isalreadyexists) {
      message.error('You have already selected this unit!');
      return;
    }

    const unit = unitsList?.find((unit: IUnits) => unit.id === id);
    if (!id) return;

    existingUnits[name].id = id;
    existingUnits[name].name = unit?.name;
    existingUnits[name].shortName = unit?.shortName;
    existingUnits[name].description = unit?.description;
    existingUnits[name].baseUnitMultiplier = unit?.baseUnitMultiplier;
    form.setFieldValue('units', existingUnits);
  };
  useEffect(() => {
    getDataFromLC();
  }, []);
  const handleRichTextEditorChange = (field: string, value: string) => {
    form.setFieldValue(field, value);
  };
  // console.log('Product details---->', productDetails);
  const onDefaultUnitChange = (value: boolean, name: number) => {
    // console.log('change bool', value);
    // console.log('name', name);
    if (!hasadminAccess) {
      message.error('You are not allowed to change default unit.');
      return;
    }
    form.setFieldValue(['units', name, 'defaultUnit'], value);
    const data = form.getFieldValue(['units']);
    if (value) {
      for (let ind = 0; ind < data.length; ind++) {
        if (ind !== name) {
          form.setFieldValue(['units', ind, 'defaultUnit'], false);
        }
      }
    }
    const updateddata = form.getFieldValue(['units']);
    // console.log('updated-data', updateddata);
    // console.log('data', data);
  };

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Product Information"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          disabled={isloading}
          validateTrigger={'onChange'}
          onValuesChange={(_, allFields) => {
            setProductDetails(allFields);
          }}
          autoComplete="off">
          <div className={'grid grid-cols-1 gap-2 sm:grid-cols-2 gap-5 mb-5'}>
            <div className="flex mb-5 justify-center align-center">
              <div>
                <p>Upload an image</p>
                <Upload className="rounded-full" {...uploadProps}>
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
              </div>
            </div>

            <Form.Item
              label="Image"
              name="imgId"
              hidden
              rules={[{ required: false, message: 'Please upload image!' }]}>
              <Input />
            </Form.Item>
            <div className="flex mb-5 justify-center align-center">
              <div>
                <p>Upload an banner</p>
                <Upload className="rounded-full" {...uploadProps2}>
                  {fileList2.length >= 1 ? null : uploadButton}
                </Upload>
              </div>
            </div>

            <Form.Item
              label="Image"
              name="bannerId"
              hidden
              rules={[{ required: false, message: 'Please upload banner!' }]}>
              <Input />
            </Form.Item>
          </div>
          <div className={'grid grid-cols-1 gap-3 mb-3 sm:grid-cols-2 gap-5 mb-5'}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Category" name={'categoryId'}>
              <Select defaultValue={productDetails?.categoryId}>
                <Option key={'none'} value={undefined}>
                  None
                </Option>
                {categoryList?.map((category: ICategory) => (
                  <Option key={category.id} value={category.id}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="SKU"
              name="sku"
              rules={[{ required: true, message: 'Please add SKU!' }]}>
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Lot Expiry Duration"
              name="lotExpiryDuration"
              rules={[{ required: true, message: 'Please add Lot expiry duration!' }]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>

            <Form.Item
              label="Vat Percentage"
              name="vat"
              // rules={[
              //   {
              //     required: false,
              //     pattern: new RegExp('^(0|[1-9][0-9]?|100)$'),
              //     message: 'Please Input number between 1-100'
              //   }
              // ]}
              rules={[
                {
                  required: true,
                  message: 'Please add a vat percentage'
                }
              ]}>
              {/* <InputNumber controls={false} min={0} max={100} defaultValue={0} /> */}
              <Select placeholder="Select vat percentage">
                <Select.Option value={0}>0%</Select.Option>
                <Select.Option value={13}>13%</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Default Purchase Price (Exclusive of VAT)"
              name="purchasePrice"
              rules={[
                {
                  required: true,
                  message: 'Please add default purchase price'
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
          </div>

          {/* <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please add description!' }]}>
            <Input.TextArea />
          </Form.Item> */}
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please add description!' }]}>
            {/* <RichTextEditor
              value={form.getFieldValue('description')}
              fieldName="description"
              fn={handleRichTextEditorChange}
            /> */}
            <TextArea
              value={form.getFieldValue('description')}
              rows={5}
              placeholder="Description..."
            />
          </Form.Item>

          <Divider />

          <div className="mt-5">
            <PageHeader
              title="Units"
              style={{
                padding: '8px 0px'
              }}
            />

            <div className="mb-2 text-gray-500 text-base	">Base Unit: {baseUnit}</div>

            <Form.List name="units">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div
                      className="grid grid-cols-1 gap-3 mb-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 mb-5"
                      key={key}>
                      <Form.Item label="Select existing unit" name={[name, 'id']}>
                        <Select
                          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                            getExistingUnitsDetails(Number(event), name)
                          }
                          value={form.getFieldValue('units')[name]['id']}
                          disabled={name < previousunitsize}>
                          {unitsList.map((unit: IUnits) => (
                            <Option key={unit.id} value={unit.id}>
                              {unit.name}
                            </Option>
                          ))}
                        </Select>
                        <Form.Item name={[name, 'name']} hidden>
                          <Input />
                        </Form.Item>
                      </Form.Item>
                      {/* <Form.Item
                        {...restField}
                        name={[name, 'unitExists']}
                        valuePropName="checked"
                        label="Does Unit exist?">
                        <Switch disabled={!(unitsList && unitsList.length > 0)} />
                      </Form.Item> */}

                      {/* {
            <Form.Item label="Select existing unit" name={[name, 'id']}>
              <Select
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  getExistingUnitsDetails(Number(event), name)
                }
                value={form.getFieldValue('units')[name]['id']}>
                {unitsList.map((unit: IUnits) => (
                  <Option key={unit.id} value={unit.id}>
                    {unit.name}
                  </Option>
                ))}
              </Select>
              <Form.Item name={[name, 'name']} hidden>
                <Input />
              </Form.Item>
            </Form.Item>
            ) : (
              <>
                <Form.Item hidden name={[name, 'id']}>
                  <Input hidden />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'name']}
                  label="Name"
                  rules={[{ required: true, message: 'Please input name!' }]}>
                  <Input />
                </Form.Item>
              </>
            )}
          } */}

                      <Form.Item
                        {...restField}
                        name={[name, 'shortName']}
                        label="Short Name"
                        rules={[{ required: true, message: 'Please input short name!' }]}>
                        <Input disabled={true} />
                      </Form.Item>

                      <Form.Item
                        label="Description"
                        {...restField}
                        name={[name, 'description']}
                        rules={[{ required: true, message: 'Please add description!' }]}>
                        <Input disabled={false} />
                      </Form.Item>

                      <Form.Item
                        label="Base unit multiplier"
                        {...restField}
                        name={[name, 'baseUnitMultiplier']}
                        rules={[{ required: true, message: 'Please add SKU!' }]}>
                        <InputNumber controls={false} min={0} className="w-100" disabled={true} />
                      </Form.Item>

                      <Form.Item label="Default Unit" {...restField} name={[name, 'defaultUnit']}>
                        <Checkbox
                          checked={form.getFieldValue(['units', name, 'defaultUnit'])}
                          onChange={(value) => {
                            onDefaultUnitChange(value.target.checked, name);
                            // setIsTask(value.target.checked);
                          }}></Checkbox>
                      </Form.Item>
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      className="bg-cyan-400"
                      onClick={() => {
                        const isDisabled = fields.length >= unitsList.length;
                        if (isDisabled) {
                          message.error('All units are already added. You cannot add more units!');
                          return;
                        }

                        add({
                          id: null,
                          name: '',
                          shortName: '',
                          description: '',
                          baseUnitMultiplier: 0
                        });
                      }}
                      icon={<PlusOutlined />}>
                      Add Unit
                    </Button>

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>

          <div className="flex justify-between items-center mt-5">
            <Form.Item label="Hidden" name={['hidden']} valuePropName="checked">
              <Checkbox>hide?</Checkbox>
            </Form.Item>
            <div className="flex justify-end">
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={buttonloading}>
                  Submit
                </Button>
              </Form.Item>
              <Button
                type="default"
                className="ml-5"
                htmlType="button"
                onClick={() => navigate('/products')}>
                Cancel
              </Button>
            </div>
          </div>
        </Form>
        <Divider />
        {checkAccess('READ_CHANNEL') && <ReuseChannel slug={`products_${id}`} />}
      </AppContent>
    </div>
  );
};

export default Details;
