import { EyeFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

interface CustomIconProps {
  link?: string;
  title?: string;
  onlyIcon?: boolean;
}

const CustomViewIcon: React.FC<CustomIconProps> = ({ link, title, onlyIcon = true }) => {
  return (
    <Tooltip title={title ? title : 'View'} color="blue">
      {link ? (
        <Link to={link} className="flex gap-2">
          <EyeFilled style={{ transform: 'scale(1.4)', width: '100%' }} />
          {!onlyIcon && title}
        </Link>
      ) : (
        <EyeFilled style={{ transform: 'scale(1.4)', width: '100%' }} />
      )}
    </Tooltip>
  );
};

export default CustomViewIcon;
