import {
  ICreateTransfer,
  ICreateTransferOrder,
  ITransfer,
  ITransferDetails,
  ITransferReject
} from './types';
import http from '../../utils/http.utils';
import { AxiosResponse } from 'axios';

export const create_transfer_mutation = async (values: ICreateTransfer) => {
  const response: AxiosResponse<ITransfer, any> = await http.store('transfers', values);
  return response;
};

export const create_transfer_order_mutation = async (values: ICreateTransferOrder) => {
  const response: AxiosResponse<ITransfer, any> = await http.store('transfer-order', values);
  return response;
};

export const create_transfer_in_mutation = async (values: ICreateTransfer) => {
  const response: AxiosResponse<ITransfer, any> = await http.store('transfers/in', values);
  return response;
};

export const edit_transfer_order_mutation = async (values: any) => {
  console.log(values);
  const response: AxiosResponse<any, any> = await http.update(
    'transfer-order/' + values.id,
    values
  );
  return response;
};

export const edit_transfer_order_archive_mutation = async (values: any) => {
  const response: AxiosResponse<any, any> = await http.update(
    'transfer-order/archive/' + values.id,
    values
  );
  return response;
};

// export const create_transfer_approve_mutation = async ({
//   data,
//   id
// }: {
//   data: string;
//   id: number;
// }) => {
//   const response: AxiosResponse<ITransfer, any> = await http.store('transfers/approve/' + id, data);
//   return response;
// };
// export const create_transfer_acknowledge_mutation = async (id: number) => {
//   const response = await http.store('transfers/acknowledge/' + id);
//   return response;
// };

export const create_transfer_reject_mutation = async (values: ITransferReject) => {
  const response: AxiosResponse<ITransfer, any> = await http.update(
    `transfers/reject/${values.transferId}`,
    values
  );
  return response;
};
