import CustomSearch, { SelectProps } from '..';
import { get_vendor_list_only } from '@/services/users/queries';
import { IVendor } from '@/services/users/types';
import VendorsDB from '@/store/localstorage/VendorDB';

type MultipleProps =
  | {
      defaultValue?: number;
      isMultiple?: false;
      setSelected?: (selected?: IVendor) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      defaultValue?: number[];
      isMultiple: true;
      setSelected?: (selected?: IVendor[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps & MultipleProps;

function VendorSearchV2(props: Props) {
  return (
    <CustomSearch
      path={{ name: 'user.name', value: 'id' }}
      formFor="supplier"
      addCallback={VendorsDB.addVendors}
      dbSearchById={VendorsDB.getVendors}
      serverCallback={get_vendor_list_only}
      dbSearchCallback={VendorsDB.searchVendor}
      {...props}
    />
  );
}

export default VendorSearchV2;
