import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IVendorProductData, IVendorProductId, IVendorProductListResponse } from './types';

export const get_vendor_products_list = async (filter = '') => {
  // const response = await http.get(`vendor-products/` + filter);
  const response: AxiosResponse<IVendorProductListResponse> = await http.get(
    'vendor-products/?' + filter
  );
  return response;
};
export const get_vendor_products_id = async (id: string | number) => {
  const response: AxiosResponse<IVendorProductData> = await http.get(`vendor-products/${id}`);
  return response.data;
};
export const get_vendor_products_by_id = async (id: number) => {
  const response: AxiosResponse<IVendorProductId> = await http.get(`vendor-products/${id}`);
  return response.data;
};
