import { useReactToPrint } from 'react-to-print';
import { getUserData } from '../../../../utils/auth.utils';
import { useRef } from 'react';
import { IProductRouteInvoice } from '../../../../services/invoice/types';
import { Button } from 'antd';
import CustomTable from '../../CustomResuableInvoice/CustomTable';

const ProductRoutePrint: React.FC<IProductRouteInvoice> = ({
  routeInformation,
  fromLocation,
  totalInfo,
  totalProduct,
  categoryName,
  handleModalClose
}) => {
  const printRef = useRef<any>();
  const loggedInUser = getUserData();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    async onAfterPrint() {
      if (handleModalClose) {
        handleModalClose();
      }
    }
  });

  const columnForProductsSold = [
    {
      label: 'Product Name',
      dataIndex: 'productName',
      render: (text: string) => {
        return (
          <div color="black" className="ml-2">
            {text}
          </div>
        );
      }
    },
    {
      label: 'Total Quantity',
      dataIndex: 'unitName',
      render: (text: string) => {
        // console.log('record-->', record);
        return <div className="text-center">{text}</div>;
      }
    }
  ];

  const columnForTotalPerUnit = [
    {
      label: 'Unit',
      dataIndex: 'unitName',
      render: (text: string) => {
        return (
          <div color="black" className="ml-2">
            {text}
          </div>
        );
      }
    },
    {
      label: 'Total',
      dataIndex: 'quantity',
      render: (text: string) => {
        return <div className="text-center">{parseFloat(text).toFixed(2)}</div>;
      }
    }
  ];

  return (
    <>
      <div className="flex justify-end mb-3">
        <Button type="primary" onClick={handlePrint}>
          Print
        </Button>
      </div>

      <div ref={printRef}>
        <div className="mx-5 my-3">
          <p className="text-center mb-0">
            Product List of {fromLocation ? 'Location ' : 'Route'}{' '}
            {routeInformation?.name ? routeInformation?.name : '(All)'}
            {routeInformation?.locationName ? `(${routeInformation?.locationName})` : ''}
          </p>
          {categoryName !== '' ? (
            <div className="text-center">Product Category : {categoryName}</div>
          ) : (
            ''
          )}
          <h4 className="text-center mt-3">Products Sold</h4>
          <CustomTable columns={columnForProductsSold} datas={totalProduct} />
          {!fromLocation && (
            <div className="mt-5">
              <h4 className="text-center">Total Per Unit</h4>
              <CustomTable columns={columnForTotalPerUnit} datas={totalInfo} />
            </div>
          )}
          <div className="flex items-end">
            <div>
              Printed By:{' '}
              <span className="capitalize">{loggedInUser?.name ? loggedInUser.name : ''}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductRoutePrint;
