import { Button, Collapse, Form, FormInstance, message, PageHeader, Pagination } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import AppContent from '../../../components/Common/Content/Content';

import { download_report, get_report } from '../../../services/report/queries';
import { showReportConditionalDownloadMessage } from '../../../utils/reportConditionalDownloadMessage';

interface ReportData {
  count: any;
  data: any;
}
interface FooterData {
  totalSell: number;
  totalPurchase: number;
}
interface State {
  id: number;
  name: string;
}
interface ReportProps {
  state: State;
  breadcrumbItems: any[];
  form: FormInstance<any>;
  columnsData?: any[];
  footerData?: { sell?: boolean; purchase?: boolean };
  dataModifier?: (values: any) => any;
  toDownload?: boolean;
}

const Report: React.FC<ReportProps> = ({
  state,
  breadcrumbItems,
  form,
  columnsData,
  footerData = {
    sell: false,
    purchase: false
  },
  dataModifier,
  children,
  toDownload
}) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [data, setData] = useState<ReportData>({ count: 0, data: [] });
  const [footer, setFooterData] = useState<FooterData>({ totalSell: 0, totalPurchase: 0 });
  const [page, setPage] = useState<number>(1);
  const [isEmpty, setIsEmpty] = useState(false);

  const [isloading, setIsloading] = useState(false);
  useEffect(() => {
    if (!columnsData) createColumns();
  }, [data]);
  const createColumns = () => {
    //auto generate columns
    const newColumns: ColumnsType<any> = [];
    for (const key in data.data[0]) {
      newColumns.push({
        title: key,
        key,
        render: (record: any) => {
          return <div className="forless">{record[key]}</div>;
        }
      });
    }
    setColumns(newColumns);
  };
  const onSearch = async (values: any, storePage = false) => {
    setIsloading(true);
    if (!storePage) {
      values.constraints['page'] = 1;
      setPage(1);
    }
    //remove custom date
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    const response = await get_report(values.constraints, state.id);
    if (response.data.data.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
    if (dataModifier) {
      const modifiedData = await dataModifier(response.data);
      setData(modifiedData);
    } else {
      setData(response.data);
    }
    setIsloading(false);
  };
  const addPage = async (value: number) => {
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();
      allValues.constraints.page = value;
      onSearch(allValues, true);
      setPage(value);
    } catch (e: any) {
      console.log('validate error', e);
    }
  };
  const getFooterData = async (values: any) => {
    if (footerData.purchase) {
      const responsePurchase = await get_report(values.constraints, 7);
      setFooterData((prev) => {
        return {
          ...prev,
          totalPurchase: responsePurchase.data.data[0][`sum(total_amount)`]
        };
      });
    }
    if (footerData.sell) {
      const responseSell = await get_report(values.constraints, 6);
      setFooterData((prev) => {
        return {
          ...prev,
          totalSell: responseSell.data.data[0].total_amount
        };
      });
    }
  };
  const loadFooter = () => {
    if (!footerData) {
      return '';
    }
    return (
      <>
        {footerData.sell || footerData.purchase ? (
          <div
            className="text-base lg:text-xl py-2 flex-wrap gap-4 flex justify-around items-center"
            style={{
              backgroundColor: 'grey',
              color: 'white',
              alignItems: 'center',
              borderRadius: '9px'
            }}>
            {footerData.sell && <div>Total Sell: {footer.totalSell}</div>}
            {footerData.purchase && <div>Total Purchase: {footer.totalPurchase}</div>}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const downloadPdf = async () => {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    const values = form.getFieldsValue();
    await form.validateFields();
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    try {
      message.info({
        key: 'downloading',
        content: 'Report Added to Queue. Download will start soon.'
      });
      const response = await download_report(values.constraints, state.id);
      window.open(response.data, '_blank');
    } catch (err: any) {
      message.error(err.message);
    }
  };

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Collapse>
          <CollapsePanel header="Filters" key="1">
            <Form
              form={form}
              onFinish={onSearch}
              layout="vertical"
              validateTrigger={'onChange'}
              autoComplete="off"
              disabled={isloading}>
              <PageHeader
                title={state?.name}
                style={{
                  padding: '5px 0px'
                }}
              />
              {React.Children.count(children) > 1 && (
                <>
                  <PageHeader
                    subTitle="Constraints"
                    style={{
                      padding: '5px 0px'
                    }}
                  />
                </>
              )}
              <div className="grid xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 ">
                {children}
              </div>

              <div className="flex justify-end mt-5 gap-3">
                <Form.Item>
                  <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
                    Search
                  </Button>
                </Form.Item>
                {toDownload ? (
                  <div>
                    <Form.Item>
                      <Button
                        className="secondary-button"
                        style={{ borderRadius: '6px' }}
                        onClick={downloadPdf}>
                        Download
                      </Button>
                    </Form.Item>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Form>
          </CollapsePanel>
        </Collapse>
        <PageHeader
          subTitle="Table"
          style={{
            padding: '8px 0px'
          }}
        />
        <Table
          scroll={{ x: true }}
          pagination={false}
          loading={isloading}
          columns={columnsData ? columnsData : columns}
          dataSource={data.data}
          footer={loadFooter}
        />
        <div className="flex justify-end mt-4">
          <div>Total: {data.count}</div>
          <Pagination
            current={page}
            total={data.count}
            showSizeChanger={false}
            pageSize={100}
            onChange={(pageNo, pageSize) => {
              addPage(pageNo);
              // if (pageSize == paginationDatas?.size) {
              //   //code for page change
              //   onPagination && onPagination(pageNo, pageSize);
              // } else {
              //   //code for size change
              //   onPagination && onPagination(pageNo, pageSize, true);
              // }
            }}
          />
        </div>
      </AppContent>
    </div>
  );
};

export default Report;
