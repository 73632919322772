import { AxiosResponse } from 'axios';
import http from '../../../utils/http.utils';
import { ILogEvents, ILogEventsResult } from './types';
import { getDataForIds } from '@/utils/queries.utils';

export const get_log_events_list = async (filter = '') => {
  const response: AxiosResponse<ILogEventsResult, any> = await http.get('/log-events/?' + filter);
  return response.data;
};
export const get_log_events_list_all = async (skip = 0, count = 10, value = '', filter = '') => {
  const response: AxiosResponse<ILogEventsResult, any> = await http.get('/log-events/?' + filter, {
    skip,
    count,
    value
  });
  return response.data;
};

export const get_log_events_details = async (id: number) => {
  const response: AxiosResponse<ILogEvents, any> = await http.get('/log-events/' + id);
  return response.data;
};

export const get_log_events_ids = async (ids: string[] | number[]) => {
  const parsedIds = ids.map(Number);
  return getDataForIds<ILogEvents>(parsedIds, 'log-events/?');
};

export const get_vendor_log_events_list = async (filter = '') => {
  const response: AxiosResponse<any, any> = await http.get('/ird/events?' + filter);
  return response.data;
};
