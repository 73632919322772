import { Button, Form, Input, Modal, Checkbox, Select, DatePicker } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

interface Iprops {
  events: any;
  setEvents: React.Dispatch<any>;
  isModalOpen: boolean;
  showModal: () => void;
  handleOk: () => void;
  handleCancel: () => void;
  initalDate: Moment;
}

const AddEvent = ({
  events,
  setEvents,
  showModal,
  handleOk,
  handleCancel,
  isModalOpen,
  initalDate
}: Iprops) => {
  //   console.log('Initial Date: ', initalDate);
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    // console.log('Success:', values);
    const currdate = moment(values.date).format('YYYY-MM-DD');
    // console.log('cdate', currdate);
    delete values.date;
    if (events[currdate]) {
      setEvents((prev: any) => ({
        ...prev,
        [currdate]: [...prev[currdate], { ...values }]
      }));
    } else {
      setEvents((prev: any) => ({
        ...prev,
        [currdate]: [
          {
            ...values
          }
        ]
      }));
    }
    form.setFieldsValue({
      event: '',
      description: '',
      colorcode: 'green'
    });
    handleCancel();
  };

  useEffect(() => {
    form.setFieldValue(['date'], initalDate);
  }, [initalDate]);

  return (
    <>
      <Button type="primary" style={{ borderRadius: '9px' }} onClick={showModal}>
        + Event
      </Button>
      <Modal
        title={'Add Event'}
        visible={isModalOpen}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}>
        <Form
          name="basic"
          form={form}
          initialValues={{
            remember: true,
            description: '',
            colorcode: 'green'
            // date: initalDate
          }}
          layout={'vertical'}
          onFinish={onFinish}
          autoComplete="off">
          <Form.Item
            label="Select Date:"
            name="date"
            rules={[{ required: true, message: 'Please select date!' }]}>
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Event Name:"
            name="event"
            rules={[{ required: true, message: 'Please input event name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Description:"
            name="description"
            rules={[{ required: false, message: 'Please input description!' }]}>
            <TextArea />
          </Form.Item>
          <Form.Item
            className="grid grid-cols-2"
            label="Color code:"
            name="colorcode"
            rules={[{ required: false, message: 'Color code!' }]}>
            <Select allowClear>
              {[
                colorCodeOption('green'),
                colorCodeOption('red'),
                colorCodeOption('blue'),
                colorCodeOption('yellow')
              ]}
            </Select>
          </Form.Item>
          <div className="flex justify-end">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddEvent;

const colorCodeOption = (colorname: string) => {
  return (
    <Select.Option value={colorname}>
      <div className="flex justify-between items-center">
        <div style={{ textTransform: 'capitalize' }}>{colorname}</div>
        <div
          style={{
            height: '20px',
            width: '20px',
            backgroundColor: colorname,
            borderRadius: '50%'
          }}></div>
      </div>
    </Select.Option>
  );
};
