import {
  get_purchase_order_details,
  get_purchase_order_lines_details
} from '@/services/purchases/queries';
import { useQuery } from '@tanstack/react-query';
import { get_product_list_ids, get_units_list } from '@/services/products/queries';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AppContent from '@/components/Common/Content/Content';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_details } from '@/services/locations/queries';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_details } from '@/services/users/queries';
import { get_invoices_list } from '@/services/settings/queries';
import type { ColumnsType } from 'antd/es/table';
import { PageHeader, Button, Spin, Divider } from 'antd';
import ReuseChannel from '../../../channel/Reuse';
import UnitsDB from '@/store/localstorage/UnitsDB';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { checkAccess } from '@/routes/acl';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { IProductType } from '@/services/products/types';
import { ICreateInvoiceResponse } from '@/services/settings/types';
import PurchaseInvoice from '@/components/Common/InvoicePrint/PurchaseInvoice/PurchaseInvoice';
import { CustomModal } from '@/components/Common/CustomModal';
import InvoicePrintButton from '@/components/Common/InvoicePrintButton/InvoicePrintButton';
import { getPurchaseOrderPrintData } from '@/components/Common/InvoicePrint/PurchaseInvoice/services';
import { IPurchaseInvoice } from '@/services/invoice/types';
import { getImagesFromServer } from '@/services/upload/queries';
import { onPreviewImageURL } from '@/services/upload/services';

const PurchaseOrderView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsloading] = useState(true);
  const [purchaseDetails, setpurchaseDetails] = useState<any>();
  const [vendorDetails, setVendorDetails] = useState<any>();
  const [locationDetails, setLocationDetails] = useState<any>();
  const [lines, setLines] = useState<any>();
  const [invoiceLayouts, setinvoiceLayouts] = useState<any>();
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<IPurchaseInvoice>(Object);
  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    setinvoiceLayouts(response.data[0]);
    // console.log('response-->', response);
    // setInvoiceLists(
    //   response.data.filter((curr: any) => {
    //     const content = JSON.parse(curr.content);
    //     return content.design == 'classic';
    //   })
    // );
    return response;
  });

  const [invoiceMediaURL, setInvoiceMediaURL] = useState<string | null>(null);

  const { refetch } = useQuery(['order-details'], async () => {
    const response = await get_purchase_order_details(parseInt(id as string));
    const linesResponse = await get_purchase_order_lines_details(response.data.id);
    const searchProducts: any = {};
    for (let index = 0; index < linesResponse.data.length; index++) {
      const product: any = await ProductsDB.getProduct(linesResponse.data[index].productId);
      if (!product) {
        // product = await get_product_details(linesResponse.lines[index].productId);
        // await ProductsDB.addProducts([product]);
        if (linesResponse.data[index].productId in searchProducts) {
          searchProducts[linesResponse.data[index].productId] = [
            ...searchProducts[linesResponse.data[index].productId],
            index
          ];
        } else {
          searchProducts[linesResponse.data[index].productId] = [index];
        }
      } else {
        linesResponse.data[index].productName = product.name;
      }

      let findUnit: any = await UnitsDB.getUnit(linesResponse.data[index].unitId);
      if (!findUnit) {
        const allUnits = await get_units_list();
        await UnitsDB.addUnits(allUnits);
        findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
      }
      // linesResponse.lines[index].unitName = findUnit.name;
      linesResponse.data[
        index
      ].quantityWithUnit = `${linesResponse.data[index].quantity} ${findUnit.shortName}`;
    }
    const searchProductslength = Object.entries(searchProducts).length;
    if (searchProductslength > 0) {
      const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
      for (const key in searchProducts) {
        const findproduct = productsresponse?.data?.results.find(
          (currProduct: IProductType) => currProduct.id == key
        );
        for (let i = 0; i < searchProducts[key].length; i++) {
          linesResponse.data[searchProducts[key][i]].productName = findproduct?.name;
        }
        await ProductsDB.addProducts([findproduct]);
      }
    }
    let vendorDetailss: any = await UsersDB.getUser(response.data?.vendor.userId);
    if (!vendorDetailss) {
      vendorDetailss = (await get_user_details(response.data?.vendor.userId)).user;
      await UsersDB.addUsers([vendorDetailss]);
    }

    if (response.data.agentId) {
      const agentInfo = await UsersDB.getUser(response.data.agentId);
      if (agentInfo) {
        response.data.agentName = agentInfo.name;
        response.data.agentPhone = agentInfo.phone;
      } else {
        const agentInfo = (await get_user_details(response.data.agentId)).user;
        await UsersDB.addUsers([agentInfo]);
        response.data.agentName = agentInfo.name;
        response.data.agentPhone = agentInfo.phone;
      }
    }

    if (response.data.mediaId) {
      const media = await getImagesFromServer([response.data.mediaId]);
      const currentMedia = media[0];
      setInvoiceMediaURL(currentMedia.url);
    }

    // console.log('VendorDetails-->', vendorDetailss);
    setVendorDetails(vendorDetailss);
    let locationDetailss: any = await LocationsDB.getLocation(response.data?.locationId);
    if (!locationDetailss) {
      locationDetailss = await get_location_details(response.data?.locationId);
      await LocationsDB.addLocations([locationDetailss]);
    }
    setLines(linesResponse.data);
    // console.log('Location details-->', locationDetailss);
    setLocationDetails(locationDetailss);
    // console.log('linesResponse-->', linesResponse.data);
    setpurchaseDetails(response.data);
    // console.log('Response--->', response.data);
    setIsloading(false);
    return response;
  });

  const columns: ColumnsType<{ name: string }> = [
    {
      title: `S.N`,
      key: 'sn',
      width: 30,
      render: (a, b, c) => <div>{c + 1}.</div>
    },
    {
      title: `${
        invoiceLayouts?.content.productLabel ? invoiceLayouts.content.productLabel : 'ProductName'
      }`,
      dataIndex: 'productName',
      width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: `${
        invoiceLayouts?.content.unitPriceLabel ? invoiceLayouts.content.unitPriceLabel : 'UnitPrice'
      }`,
      dataIndex: 'unitPrice',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: 'HS Code',
      dataIndex: 'hsCode',
      width: 100,
      render: (text: string) => <div>{text || 'N/A'}</div>
    },
    {
      title: `${
        invoiceLayouts?.content.quantityLabel ? invoiceLayouts.content.quantityLabel : 'Quantity'
      }`,
      dataIndex: 'quantityWithUnit',
      width: 100,
      render: (text: number) => <div>{text}</div>
    },

    {
      title: 'Misc',
      dataIndex: 'misc',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: `${
        invoiceLayouts?.content.discountLabel ? invoiceLayouts.content.discountLabel : 'Discount'
      }`,
      dataIndex: 'discount',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: `${
        invoiceLayouts?.content.totalLabel ? invoiceLayouts.content.totalLabel : 'Total Amount'
      }`,
      dataIndex: 'totalAmount',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    }
  ];
  const breadcrumbItems = [
    {
      label: 'Purchase Order',
      link: '/purchase/order'
    },
    {
      label: 'View'
    }
  ];

  const generateInvoiceForPrint = async (record: any, curr: any) => {
    setIsloading(true);
    const invoiceModalData = await getPurchaseOrderPrintData(record.id, record.locationId, curr);
    setIsloading(false);

    setInvoiceData(invoiceModalData);

    setOpenModalForInvoicePrint(true);
  };

  const handleInvoicePrintModalClose = () => {
    setOpenModalForInvoicePrint(false);
    refetch();
  };

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Spin spinning={isLoading}>
          <CustomModal
            footer={false}
            isModalOpen={openModalForInvoicePrint}
            setIsModalOpen={setOpenModalForInvoicePrint}
            title="Purchase Order Invoice Print">
            <PurchaseInvoice
              purchaseDetails={invoiceData.purchaseDetails}
              vendorDetails={invoiceData.vendorDetails}
              lines={invoiceData.lines}
              locationDetails={invoiceData.locationDetails}
              invoiceLayouts={invoiceData.invoiceLayouts}
              handleModalClose={handleInvoicePrintModalClose}
            />
          </CustomModal>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mb-5">
            <div>
              <PageHeader
                subTitle="Supplier"
                style={{
                  padding: '8px 0px'
                }}
              />
              {vendorDetails ? (
                <div style={{ color: 'name' }}>
                  {vendorDetails?.name ? vendorDetails.name : ''}
                  {/* </p>
                  <p> */}
                  {`${vendorDetails?.phone ? `,${vendorDetails.phone}` : ''} ${
                    vendorDetails.email ? `,${vendorDetails.email}` : ''
                  }`}
                </div>
              ) : (
                <></>
              )}
              {purchaseDetails && (
                <>
                  <div style={{ color: 'name' }}>
                    Buyer Type : <b>{purchaseDetails.purchaseBuyerType}</b>
                  </div>
                  <div style={{ color: 'name' }}>
                    Purchase Type : <b>{purchaseDetails.purchaseType}</b>
                  </div>
                </>
              )}
            </div>
            {purchaseDetails?.agentId && (
              <div>
                <PageHeader
                  subTitle="Agent"
                  style={{
                    padding: '8px 0px'
                  }}
                />
                <div style={{ color: 'black' }}>
                  {purchaseDetails.agentName}, {purchaseDetails.agentPhone}
                </div>
              </div>
            )}
            <div>
              <PageHeader
                subTitle="Location"
                style={{
                  padding: '8px 0px'
                }}
              />
              {locationDetails ? (
                <>
                  <div style={{ color: 'black' }}>
                    {locationDetails?.name ? locationDetails.name : ''}
                    {/* </p>
                  <p> */}
                    {`${locationDetails?.zip ? `${locationDetails.zip},` : ''} ${
                      locationDetails?.address ? `${locationDetails.address},` : ''
                    } ${locationDetails?.city ? `${locationDetails.city},` : ''} ${
                      locationDetails?.country ? `${locationDetails.country}` : ''
                    }`}
                    {/* </p>
                  <p> */}
                    {`${locationDetails?.phone ? `${locationDetails.phone},` : ''} ${
                      locationDetails?.email ? `${locationDetails.email}` : ''
                    }`}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div>
              <PageHeader
                subTitle="Details"
                style={{
                  padding: '8px 0px'
                }}
              />
              {purchaseDetails ? (
                <>
                  <div style={{ color: 'black' }}>
                    <span className="block">
                      Reference Number : {purchaseDetails.referenceNumber}
                    </span>
                    {/* </p>
                <p> */}
                    <span className="block">
                      {' '}
                      Created :{' '}
                      {convertUTCStringtoLocalString(purchaseDetails.createdAt, 'YYYY-MM-DD')}
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}
              {invoiceMediaURL && (
                <div
                  onClick={() => onPreviewImageURL(invoiceMediaURL)}
                  className="cursor-pointer underline hover:text-blue-500">
                  View Invoice
                </div>
              )}
            </div>
          </div>

          <CustomizeTable columns={columns} data={lines} notshowPagination={true} />
          <div>
            <PageHeader
              subTitle="Payment Info"
              style={{
                padding: '8px 0px'
              }}
            />
            {purchaseDetails ? (
              <div style={{ color: 'black' }}>
                <span className="block">
                  Total Amount : {nepaliNumberFormatter(purchaseDetails.totalAmount)}
                </span>
                <span className="block">
                  Total Tax : {nepaliNumberFormatter(purchaseDetails.totalTax)}
                </span>
                <span className="block">
                  Manual Discount : {nepaliNumberFormatter(purchaseDetails.manualDiscount)}
                </span>
                <span className="block">
                  Shipping Amount : {nepaliNumberFormatter(purchaseDetails.shippingAmount)}
                </span>
                <span className="block">
                  Labour Cost : {nepaliNumberFormatter(purchaseDetails.labourCost)}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="flex justify-end  gap-5 mt-5">
            <div
              style={{
                width: '3rem',
                // backgroundColor: 'red',
                // border: '1px solid gray',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <InvoicePrintButton
                getInvoice={(curr) => {
                  generateInvoiceForPrint(purchaseDetails, curr);
                }}
              />
            </div>
            <Button type="default" onClick={() => navigate('/purchase/order')}>
              Back
            </Button>
          </div>
        </Spin>
        <Divider />
        {checkAccess('READ_CHANNEL') && (
          <ReuseChannel
            slug={`purchase_order_${id}`}
            fromSellorPurchaseId={vendorDetails?.id as string}
            withReferenceandId={{ reference: 'sell_order', referenceId: id || 0 }}
          />
        )}
      </AppContent>
    </div>
  );
};

export default PurchaseOrderView;
