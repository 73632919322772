import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Calendar, Dropdown, Form, Input, Menu, message, Modal } from 'antd';
import moment, { Moment } from 'moment';
import React, { useState, useEffect } from 'react';
import AppContent from '../../../components/Common/Content/Content';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import AddEvent from './addevent';
import EditEvent from './editevent';

const eventss: any = {
  '2023-02-28': [
    {
      colorcode: 'green',
      description: 'Event one Description.',
      event: 'Event one'
    },
    {
      colorcode: 'red',
      description: 'Event two Desrciption',
      event: 'Event two'
    },
    {
      colorcode: 'blue',
      description: 'Event three description',
      event: 'Event three'
    }
  ],
  '2023-02-20': [
    {
      colorcode: 'yellow',
      description: 'Event four description',
      event: 'Event four'
    },
    {
      colorcode: 'green',
      description: 'Event five description',
      event: 'Event five'
    },
    {
      colorcode: 'red',
      description: 'Event six description',
      event: 'Event six'
    }
  ]
};

const CropCalendar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currdate, setCurrdate] = useState<string>('');
  const [currdatemoment, setCurrentDateMoment] = useState<Moment>(moment());
  const [newEvent, setNewEvent] = useState<string>('');
  const [events, setEvents] = useState<any>(eventss);
  const [monthEvents, setMonthEvents] = useState<any>({});
  const [panelmode, setPanelmode] = useState<any>('month');
  const [currmonth, setcurrMonth] = useState<string>(moment().format('YYYY-MM'));
  const [editEvent, setEditEvent] = useState<any>();
  const [isModalOpenForCreate, setIsModalOpenForCreate] = useState(false);
  const [isModalOpenForEdit, setIsModalOpenForEdit] = useState(false);

  const showModalForEdit = () => {
    setIsModalOpenForEdit(true);
  };

  const handleOkForEdit = () => {
    setIsModalOpenForEdit(false);
  };

  const handleCancelForEdit = () => {
    setIsModalOpenForEdit(false);
  };

  const showModalForCreate = () => {
    setIsModalOpenForCreate(true);
  };

  const handleOkForCreate = () => {
    setIsModalOpenForCreate(false);
  };

  const handleCancelForCreate = () => {
    setIsModalOpenForCreate(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setNewEvent('');
  };

  useEffect(() => {
    const monthEventss: any = {};
    for (const key in events) {
      if (monthEventss[key.slice(0, 7)]) {
        monthEventss[key.slice(0, 7)] = [...monthEventss[key.slice(0, 7)], ...events[key]];
      } else {
        monthEventss[key.slice(0, 7)] = [...events[key]];
      }
    }
    setMonthEvents(monthEventss);
  }, [events]);

  const handleDelete = (ind: number) => {
    if (!currdate) {
      message.error('Date is not selected!');
      return;
    }
    if (currdate) {
      const afterDeletion = events[currdate].filter((curr: any, index: number) => index !== ind);
      setEvents((prev: any) => ({
        ...prev,
        [currdate]: [...afterDeletion]
      }));
    }
  };

  const breadcrumbItems = [
    {
      label: 'Crop Calendar',
      link: '/cropcalendar'
    }
  ];

  return (
    <AppContent
      breadcrumbItems={breadcrumbItems}
      button={
        <div className="flex justify-end">
          <AddEvent
            events={events}
            setEvents={setEvents}
            handleOk={handleOkForCreate}
            handleCancel={handleCancelForCreate}
            isModalOpen={isModalOpenForCreate}
            showModal={showModalForCreate}
            initalDate={currdatemoment}
          />
        </div>
      }>
      {editEvent && (
        <EditEvent
          events={events}
          setEvents={setEvents}
          handleOk={handleOkForEdit}
          handleCancel={handleCancelForEdit}
          isModalOpen={isModalOpenForEdit}
          showModal={showModalForEdit}
          toEditData={editEvent}
        />
      )}
      <Modal
        title={`Events for selected ${
          panelmode == 'year' ? `Month :${currdate.slice(0, 7)} ` : `Date: ${currdate}`
        }`}
        visible={isModalOpen}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}>
        {panelmode == 'year' && (
          <div>
            <div style={{ maxHeight: '50vh', overflow: 'scroll' }}>
              {monthEvents[currdate.slice(0, 7)] && (
                <>
                  {monthEvents[currdate.slice(0, 7)].map((curr: any, ind: any) => {
                    return (
                      <div key={ind + 1} className="mb-2 flex gap-2 items-center">
                        <div
                          style={{
                            height: '10px',
                            width: '10px',
                            borderRadius: '50%',
                            backgroundColor: curr.colorcode
                          }}></div>
                        <span className="ml-3 font-bold">{curr.event}</span>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
            {!monthEvents[currdate.slice(0, 7)] && <div>No Events to show in this month!</div>}
          </div>
        )}
        {panelmode == 'month' && (
          <div>
            {events[currdate] && (
              <div style={{ maxHeight: '50vh', overflow: 'scroll' }}>
                {events[currdate].map((curr: any, ind: any) => {
                  return (
                    <div key={ind + 1} className="flex justify-between mb-2 overflow-hidden">
                      <div className="flex items-center gap-2">
                        <div
                          style={{
                            height: '10px',
                            width: '10px',
                            borderRadius: '50%',
                            backgroundColor: curr.colorcode
                          }}></div>
                        <span className="ml-3 font-bold">{curr.event}</span>
                      </div>
                      <div>
                        <Dropdown
                          overlay={
                            <Menu
                              items={[
                                {
                                  key: '1',
                                  label: <DeleteOutlined />,
                                  onClick: () => {
                                    handleDelete(ind);
                                  }
                                },
                                {
                                  key: '2',
                                  label: <EditOutlined />,
                                  onClick: () => {
                                    setEditEvent({
                                      ...curr,
                                      index: ind,
                                      date: currdate
                                    });
                                    showModalForEdit();
                                  }
                                }
                              ]}
                            />
                          }
                          trigger={['hover']}>
                          <MoreOutlined style={{ transform: 'scale(1.4)', cursor: 'pointer' }} />
                        </Dropdown>
                      </div>
                      {/* </span> */}
                    </div>
                  );
                })}
              </div>
            )}
            {!events[currdate] && <div>No events to show!</div>}
            <div className="flex justify-end items-center gap-2">
              <Button
                color="blue"
                type="primary"
                style={{ borderRadius: '9px' }}
                onClick={() => {
                  handleCancel();
                  showModalForCreate();
                }}>
                Add
              </Button>
              <Button color="white" style={{ borderRadius: '9px' }} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </div>
        )}
      </Modal>
      <Calendar
        onSelect={(date) => {
          //   console.log('clicked', date);
          const cdate = moment(date).format('YYYY-MM-DD');
          setcurrMonth(cdate.slice(0, 7));
          setCurrdate(cdate);
          setCurrentDateMoment(date);
          showModal();
        }}
        dateCellRender={(date) => {
          const cdate = moment(date).format('YYYY-MM-DD');
          const cmonth = moment(date).format('YYYY-MM');
          if (events[cdate]) {
            return (
              <>
                {events[cdate].map((curr: any, ind: any) => {
                  return (
                    <div
                      key={ind + 1}
                      style={{ color: cmonth == currmonth ? '' : '#c6c6c6' }}
                      className="flex gap-2 items-center">
                      <div
                        style={{
                          height: '10px',
                          width: '10px',
                          borderRadius: '50%',
                          backgroundColor: curr.colorcode
                        }}></div>
                      <span>{curr.event}</span>
                    </div>
                  );
                })}
              </>
            );
          }
        }}
        monthCellRender={(date) => {
          const cmonth = moment(date).format('YYYY-MM');
          //   console.log('month slice', cmonth.slice(0, 7));

          if (monthEvents[cmonth]) {
            return (
              <>
                {monthEvents[cmonth].map((curr: any, ind: any) => {
                  return (
                    <div key={ind + 1} className="flex gap-2 items-center">
                      <div
                        style={{
                          height: '10px',
                          width: '10px',
                          borderRadius: '50%',
                          backgroundColor: curr.colorcode
                        }}></div>
                      <span>{curr.event}</span>
                    </div>
                  );
                })}
              </>
            );
          }
        }}
        onPanelChange={(date, mode) => {
          //   console.log('onpanel change');
          //   console.log('panelmode', mode);
          setPanelmode(mode);
        }}
      />
    </AppContent>
  );
};

export default CropCalendar;
