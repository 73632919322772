import { CloseOutlined, MinusCircleOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Checkbox, Form, Input, message, PageHeader, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppContent from '../../../components/Common/Content/Content';
import CustomSubmitButton from '../../../components/Common/CustomButton/CustomSubmitButton';
import useDebounce from '../../../hooks/useDebounce';
import {
  create_channels_mutation,
  update_channels_mutation
} from '../../../services/channels/mutations';
import { get_channel_id, get_channel_slug } from '../../../services/channels/queries';
import { ICreateChannel, IUpdateChannel } from '../../../services/channels/types';
import { get_user_details, get_user_list } from '../../../services/users/queries';
import UsersDB from '../../../store/localstorage/UsersDB';
import { getUserData } from '../../../utils/auth.utils';
const { Option } = Select;

function EditChannel() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  // console.log('Idd--->', idd);
  const { id } = useParams();
  // const id = '7049';
  //product search
  // console.log('id', id);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isloading, setIsloading] = useState<boolean>(true);
  const [userSearch, setUserSearch] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const loggedInUser = getUserData();
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    form.setFieldsValue({
      name: '',
      slug: '',
      members: []
    });
  }, []);
  useEffect(() => {
    searchUser(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const updateChannelMutation = useMutation(update_channels_mutation);
  // This is needed to get the product details update as its written
  const [productDetails, setProductDetails] = useState<any>();
  const { data: channelData } = useQuery(['channel-details'], async () => {
    // const test = await get_channel_id(parseInt(id as string));
    const response = await get_channel_slug(id as string);
    // console.log('Response-->', response);
    const listUser = [];
    if (response?.data.members) {
      for (let index = 0; index < response.data.members.length; index++) {
        let user: any = await UsersDB.getUser(response.data.members[index].userId);
        if (!user) {
          user = (await get_user_details(response.data.members[index].userId)).user;
          await UsersDB.addUsers([user]);
        }
        listUser.push(user);
      }
      setUserList(listUser);
    }
    const members = listUser.map((value) => value.id);
    const newSet = new Set(members);
    form.setFieldsValue({
      name: response.data.channel.name,
      slug: response.data.channel.slug,
      members: Array.from(newSet),
      priority: response.data.channel.priority,
      color: response.data.channel.color,
      reference: response.data.channel.reference,
      referenceId: response.data.channel.referenceId
    });
    setIsloading(false);
    return response;
  });
  const breadcrumbItems = [
    {
      label: 'Channel',
      link: '/channel'
    },
    {
      label: 'Edit'
    }
  ];
  const onFinish = async (values: IUpdateChannel) => {
    // console.log('Values-->', values);
    values.closed = checkbox;
    // console.log('Values-->', values);
    setIsloading(true);
    if (values.members.length === 0) {
      message.error('Please choose atleast 1 member!');
    } else {
      if (channelData?.data.channel.id) {
        values.id = channelData.data.channel.id;
        await updateChannelMutation.mutateAsync(values, {
          onSuccess: async ({ data }: { data: any }) => {
            message.success('Channel updated successfully');
            navigate('/channel');
          },
          onError: (e: any) => {
            message.error(`${e.response.data.message}`, 5);
          }
        });
      }
    }
    setIsloading(false);
  };
  const searchUser = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_user_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any user with that name!');
        setUserSearch([]);
      } else {
        setUserSearch(response.data.results);
        checkUsersAndAdd(response.data.results);
      }
    }
  };
  const checkUsersAndAdd = (users: any[]) => {
    if (userList.length > 0) {
      users = users.filter((value) => {
        const searchProduct = userSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (users.length > 0)
        setUserList((prevValue) => {
          return [...prevValue, ...users];
        });
    } else {
      setUserList(users);
    }
  };
  // const DisplayUserName = (name: any) => {
  //   const selectedUser = form.getFieldValue(['members', name]);
  //   const user = userList.find((val) => val.id == selectedUser);
  //   return <Input type={'text'} key={user.id} value={user.name} disabled />;
  // };
  const DisplayUserName = (name: any) => {
    const selectedUser = form.getFieldValue(['members', name]);
    // const imgData = await get_image(`${API_URL}storage/media/${selectedUser}`);
    const user = userList.find((val) => val.id == selectedUser);
    // console.log('UserList-->', userList);
    // console.log('selected User-->', selectedUser);
    // console.log('User--->', user);
    return (
      <>
        {/* {user?.imgSrc ? (
          user.imgSrc === 'error' ? (
            <UserOutlined
            <UserOutlined />
          ) : (
            <img src={user.imgSrc} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
          )
        ) : (
          <Spin indicator={antIcon} />
        )} */}
        <span>{user?.name}</span>
      </>
    );
  };
  const options = userSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.name}
    </Option>
  ));
  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Edit Channel"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          disabled={isloading}
          form={form}
          onFinish={onFinish}
          layout="vertical"
          validateTrigger={'onChange'}
          // onValuesChange={(_, allFields) => {
          //   setProductDetails(allFields);
          // }}
          autoComplete="off">
          <div className="grid grid-cols-2 gap-5 mb-5">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Slug"
              name="slug"
              rules={[{ required: true, message: 'Please add slug!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Color Code"
              name="color"
              rules={[{ required: true, message: 'Please add color code!' }]}>
              <Select
                placeholder="Select Color Code"
                style={{ borderRadius: '10px' }}
                allowClear
                dropdownMatchSelectWidth={false}>
                <Option value={'red'} key={'red'}>
                  Red
                </Option>
                <Option value={'green'} key={'green'}>
                  Green
                </Option>
                <Option value={'yellow'} key={'yellows'}>
                  Yellow
                </Option>
                {/* {paymentOptions.map((value: any) => {
                          return (
                            <Select.Option value={value} key={value}>
                              {value}
                            </Select.Option>
                          );
                        })} */}
              </Select>
            </Form.Item>
            <Form.Item
              label="Priority"
              name="priority"
              rules={[{ required: true, message: 'Please add priority!' }]}>
              <Select
                placeholder="Select Priority"
                style={{ borderRadius: '10px' }}
                allowClear
                dropdownMatchSelectWidth={false}>
                <Option value={'high'} key={'high'}>
                  High
                </Option>
                <Option value={'medium'} key={'medium'}>
                  Medium
                </Option>
                <Option value={'low'} key={'low'}>
                  Low
                </Option>
                {/* {paymentOptions.map((value: any) => {
                          return (
                            <Select.Option value={value} key={value}>
                              {value}
                            </Select.Option>
                          );
                        })} */}
              </Select>
            </Form.Item>
            <Form.Item
              label="Reference"
              name="reference"
              rules={[{ required: true, message: 'Please add color code!' }]}>
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Reference Id"
              name="referenceId"
              rules={[{ required: true, message: 'Please add color code!' }]}>
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Is Closed?"
              // value={checkbox}
              name="closed"
              rules={[{ required: false, message: 'Please add color code!' }]}>
              <Checkbox onChange={() => setCheckbox(!checkbox)}></Checkbox>
            </Form.Item>
          </div>
          <Form.List name={['members']}>
            {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
              <>
                <div className={'grid mb-5'}>
                  <PageHeader
                    subTitle="Add Users"
                    style={{
                      padding: '8px 0px 8px 10px'
                    }}
                  />
                  <Select
                    showSearch
                    placeholder={'search user'}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(val) => {
                      setSearchValue(val);
                    }}
                    onSelect={(val: number) => {
                      if (loggedInUser.id == val) {
                        message.error('Cannot add self, already added.');
                        return;
                      }
                      const selectedUsers = form.getFieldValue(['members']);
                      const exists = selectedUsers.find((value: any) => value == val);
                      if (exists) {
                        message.error('Already Added');
                        return;
                      }
                      add2(val);
                    }}
                    notFoundContent={null}>
                    {options}
                  </Select>
                  {fields2.length > 0 && (
                    <PageHeader
                      title="All Users"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                  )}
                </div>
                <div className="flex flex-wrap gap-3 mb-5">
                  {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                    // <div key={key2} className="card">
                    //   <div className="grid grid-cols-1 gap-4 " key={key2}>
                    //     <Form.Item {...restField2} name={[name2]} hidden>
                    //       <Input type={'text'} disabled />
                    //     </Form.Item>
                    //     {DisplayUserName(name2)}
                    //     <div className="flex justify-center items-center">
                    //       {fields2.length > 0 ? (
                    //         <MinusCircleOutlined
                    //           className="dynamic-delete-button flex justify-center items-center"
                    //           onClick={() => remove2(name2)}
                    //         />
                    //       ) : null}
                    //     </div>
                    //   </div>
                    // </div>
                    <div
                      key={key2}
                      className="card"
                      style={{
                        borderRadius: '15px',
                        backgroundColor: '#e5f5fb',
                        padding: '0px 2px 0px 0px'
                      }}>
                      <div className="flex items-center justify-center gap-2 p-2" key={key2}>
                        <Form.Item {...restField2} name={[name2]} hidden>
                          <Input type={'text'} disabled />
                        </Form.Item>
                        {DisplayUserName(name2)}
                        {parseInt(form.getFieldValue(['members', name2])) !==
                          parseInt(loggedInUser.id) && (
                          <div className="flex justify-center items-center">
                            {fields2.length > 0 ? (
                              <CloseOutlined
                                className="dynamic-delete-button flex justify-center items-center"
                                style={{ color: '#0AA245' }}
                                onClick={() => remove2(name2)}
                                // disabled={name2 === loggedInUser.id}
                                disabled={true}
                              />
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </Form.List>
          <div className="flex justify-end mt-5">
            <Form.Item>
              {/* <Button type="primary" htmlType="submit">
                Submit
              </Button> */}
              <CustomSubmitButton text={'Update'} />
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </div>
  );
}

export default EditChannel;
