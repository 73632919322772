import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { WebSocketContext } from '../../../contexts/websocket.context';
import { get_location_list } from '../../../services/locations/queries';
import { IProductDetails, IUnits } from '../../../services/products/types';
import { ILot } from '../../../services/purchases/types';
import { getUserData } from '../../../utils/auth.utils';
import {
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
  Spin,
  message
} from 'antd';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import {
  get_unexpired_lots_details_bylocationId_productId,
  get_product_list_ids,
  get_units_list,
  get_unexpired_lots_details_bylocationId_productIds
} from '../../../services/products/queries';
import { ILines } from '../../../services/sell/types';
import { SocketEvents, SystemNotificationType } from '../../../constants/websocketConfig';
import { get_transfer_out_details } from '../../../services/transfer/queries';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import { numberDecimalFormatter } from '../../../utils/numberFormatter';
import AppContent from '../../../components/Common/Content/Content';
import { ICreateTransfer } from '../../../services/transfer/types';
import { calculateVat } from '../../../utils/vatCalculate.utils';
import CustomSubmitButton from '../../../components/Common/CustomButton/CustomSubmitButton';
import { create_transfer_reject_mutation } from '../../../services/transfer/mutations';

const { Option } = Select;

const TransferReject = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { socket } = useContext(WebSocketContext);
  const [form] = Form.useForm();
  const [allLocalUnits, setAllLocalUnits] = useState<IUnits[]>([]);
  const [totalLots, settotalLots] = useState<ILot[]>([]);
  const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  const [defaultSelect, setDefaultSelect] = useState<boolean[]>([]);
  const [productList, setProductList] = useState<IProductDetails[]>([]);
  const [productDetails, setProductDetails] = useState<ICreateTransfer>();

  const breadcrumbItems = [
    {
      label: 'Transfers',
      link: '/transfers'
    },
    {
      label: 'Reject'
    }
  ];

  useEffect(() => {
    socket?.on('connect', async () => {
      const locationId = form.getFieldValue(['from']);
      const lines = form.getFieldValue(['lines']);
      const productIds = new Set<number>(
        lines.map((value: ILines) => {
          return value.productId;
        })
      );
      await fetchLotsOnLocationandProductChange([...productIds], locationId, 'lotsupdate');
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_ZERO) {
        const locationId = form.getFieldValue(['from']);
        const socketData = data.data as { locationId: number };
        if (socketData.locationId === locationId) {
          settotalLots((prev) => {
            return prev.map((a) => ({ ...a, qtyAvailable: 0 }));
          });
        }
      }

      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        const lines = form.getFieldValue(['lines']);
        const productIds = new Set<number>(
          lines.map((value: ILines) => {
            return value.productId;
          })
        );

        const locationId = form.getFieldValue(['from']);

        let updatedProducts = data.data as { productId: number; locationId: number }[];
        if (locationId) {
          updatedProducts = updatedProducts.filter(
            (value) =>
              value.locationId === locationId && Array.from(productIds).includes(value.productId)
          );
        }

        if (updatedProducts.length > 0) {
          const updatedProductIds = updatedProducts.map((value) => value.productId);
          await fetchLotsOnLocationandProductChange(updatedProductIds, locationId, 'lotsupdate');
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected]);

  useEffect(() => {
    form.setFieldsValue({
      from: null,
      to: null,
      lines: []
    });
  }, []);

  useQuery(['units'], async () => {
    let allUnits = await UnitsDB.getAllUnits();
    if (allLocalUnits.length == 0) {
      allUnits = await get_units_list();
      setAllLocalUnits(allUnits as IUnits[]);
    } else {
      setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
    }
  });

  useQuery(['transfer', id], async () => {
    setIsLoading(true);
    const response = await get_transfer_out_details(parseInt(id as string));
    const productsIds = new Set<number>();
    if (response.lines.length > 0) {
      for (let i = 0; i < response.lines.length; i++) {
        productsIds.add(response.lines[i].productId);
      }
    }
    if (Array.from(productsIds).length > 0) {
      const productList = await get_product_list_ids(Array.from(productsIds));
      setProductList(productList?.data?.results);
      await ProductsDB.addProducts(productList.data.results);
    }
    if (response) {
      //locaton name extract
      const checkListVals = JSON.parse(response.checklist);
      let netTotalAmount = 0;
      if (response.lines.length > 0) {
        const allProducts = [...productList];
        const mySet = new Set<number>();
        setDefaultSelect(Array(response.lines.length).fill(false));
        for (let i = 0; i < response.lines.length; i++) {
          delete response.lines[i].lotId;
          mySet.add(response.lines[i].productId);
          let findProduct = await ProductsDB.getProduct(response.lines[i].productId);
          if (!findProduct) {
            const products = await get_product_list_ids([
              ...new Set(
                response.lines.map((value) => {
                  return value.productId;
                })
              )
            ]);
            await ProductsDB.addProducts(products.data.results);
            findProduct = await ProductsDB.getProduct(response.lines[i].productId);
            if (typeof findProduct === 'object') allProducts.push(findProduct as IProductDetails);
          }
          response.lines[i].productName = typeof findProduct === 'object' ? findProduct.name : '';
          if (response.lines[i].vat) {
            response.lines[i].total = response.lines[i].totalAmount - response.lines[i].vat;
          } else {
            response.lines[i].total = response.lines[i].totalAmount;
          }
          netTotalAmount += response.lines[i].totalAmount;
        }
        await fetchLotsOnLocationandProductChange(Array.from(mySet), response.from, 'location');
      }
      form.setFieldsValue({
        ...response,
        ...checkListVals,
        grandtotal: netTotalAmount
      });
    }
    setIsLoading(false);
    return response;
  });

  const fetchLotsOnLocationandProductChange = async (
    productsIdArray: number[],
    locationId: number,
    from: string
  ) => {
    try {
      if (!locationId) {
        throw {
          name: 'Location Error',
          message: 'Please select Location!'
        };
      }

      if (productsIdArray.length == 0) return;
      const currenttotalLots = [];
      if (from === 'productchange') {
        const filterLots = totalLots.find((value: ILot) => value.productId == productsIdArray[0]);
        if (!filterLots) {
          const response = await get_unexpired_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[0]
          );
          settotalLots([...totalLots, ...response]);
        }
      } else if (from === 'lotsupdate') {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);

        settotalLots((prev) => {
          const filterLots = prev.filter((value) => !productsIdArray.includes(value.productId));
          return [...filterLots, ...result];
        });
      } else {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);
        currenttotalLots.push(...result);
        settotalLots([...currenttotalLots]);
      }
    } catch (err: any) {
      message.error(err.message);
    }
  };

  const onLocationChange = async () => {
    const from = form.getFieldValue(['from']);
    const to = form.getFieldValue(['to']);
    let lines = form.getFieldValue('lines');
    lines = lines.map((linesValue: ILines) => {
      return { ...linesValue, lotId: undefined };
    });
    form.setFieldValue('lines', lines);
    if (from === to) {
      message.warn('From and To cannot be same!');
      form.setFieldValue('to', undefined);
    }
    const mySet = new Set<number>();
    const data = form.getFieldValue(['lines']);
    if (data) {
      data.map((curr: ILines) => {
        mySet.add(curr.productId);
      });
    }
    fetchLotsOnLocationandProductChange(Array.from(mySet), from, 'location');
  };

  const onUnitandQuantityChange = async (name: number) => {
    const rate = form.getFieldValue(['lines', name, 'unitPrice']);
    const qty = form.getFieldValue(['lines', name, 'quantity']);
    const mis = form.getFieldValue(['lines', name, 'misc']);
    const dis = form.getFieldValue(['lines', name, 'discount']);

    const total = rate * qty - dis + mis;
    form.setFieldValue(['lines', name, 'total'], total);

    const selectedProductId = form.getFieldValue(['lines', name, 'productId']);
    let productInfo = await ProductsDB.getProduct(selectedProductId);
    if (!productInfo) {
      const allProducts = await get_product_list_ids([selectedProductId]);
      await ProductsDB.addProducts(allProducts.data.results);
      productInfo = await ProductsDB.getProduct(selectedProductId);
    }
    let vat = 0;
    if (typeof productInfo === 'object') {
      if (productInfo.vat) {
        vat = calculateVat(total, productInfo.vat);
        form.setFieldValue(['lines', name, 'vat'], vat);
      }
    }

    const lines = form.getFieldValue(['lines']);
    let grandTotal = 0;
    for (let i = 0; i < lines.length; i++) {
      grandTotal += lines[i].total + lines[i].vat;
    }

    form.setFieldValue(['grandtotal'], numberDecimalFormatter(grandTotal));
  };

  const FilterUnits = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    if (checkCurrentProduct && productList) {
      const selectedProduct = productList.find((val) => val.id == checkCurrentProduct);
      const filteredUnits = selectedProduct?.productUnits.map((value) => {
        const data = allLocalUnits.find((val: IUnits) => value.unitId == val.id);
        return data;
      });
      return (
        <>
          {filteredUnits?.map((value: any) => {
            if (!value) return null;
            return (
              <Option value={value.id} key={value.id}>
                {`${value.name}`}
              </Option>
            );
          })}
        </>
      );
    }
  };

  const FilterLot = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    const fromLocation = form.getFieldValue(['from']);
    if (checkCurrentProduct && fromLocation) {
      let filteredLots: ILot[] = [];
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter((currLot) => currLot.productId === checkCurrentProduct);
      }
      filteredLots = filteredLots.sort((a, b) => b.qtyAvailable - a.qtyAvailable);

      if (filteredLots.length > 0 && !defaultSelect[name]) {
        form.setFieldValue(['lines', name, 'lotId'], filteredLots[0].id);
        setDefaultSelect((prev) =>
          prev.map((curr, ind) => {
            if (ind == name) {
              return !curr;
            } else {
              return curr;
            }
          })
        );
      }

      const unitId = form.getFieldValue(['lines', name, 'unitId']);
      const unitInfo = allLocalUnits.find((val) => unitId == val.id);
      return (
        <>
          {filteredLots?.map((value) => (
            <Option
              value={value.id}
              key={value.id}
              style={{ color: value.qtyAvailable > 0 ? 'green' : 'red' }}>
              {`(${numberDecimalFormatter(
                value.qtyAvailable / (unitInfo?.baseUnitMultiplier || 1)
              )} ${unitInfo?.shortName || ''}) ${value.lotNumber} Grade-${value.grade} Expiry-${
                value?.expirationDate ? new Date(value.expirationDate).toLocaleDateString() : 'N/A'
              }`}
            </Option>
          ))}
        </>
      );
    }
  };

  const onFinish = async (values: ICreateTransfer) => {
    setIsLoading(true);
    if (id) {
      values.lines = values.lines.map((val) => {
        return {
          discount: val.discount,
          isLot: true,
          isTraceable: val.isTraceable,
          lotId: val.lotId,
          misc: val.misc,
          productId: val.productId,
          quantity: val.quantity,
          total: val.total,
          unitId: val.unitId,
          unitPrice: val.unitPrice,
          vat: val.vat
        };
      });

      const rejectObj = {
        transferId: parseInt(id),
        lines: values.lines
      };

      await createTransferMutation.mutateAsync(rejectObj, {
        onSuccess: async ({ data }) => {
          if (data) {
            message.success('Transfer Rejected successfully');
            setIsLoading(false);
            navigate('/transfers');
          }
        },
        onError: (e: any) => {
          setIsLoading(false);
          message.error(`${e.response.data.message}`, 5);
        }
      });
    }
    setIsLoading(false);
  };

  const createTransferMutation = useMutation(create_transfer_reject_mutation);

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Transfer Information"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          disabled={isLoading}
          validateTrigger={'onChange'}
          onValuesChange={(_, allFields) => {
            setProductDetails(allFields);
          }}
          autoComplete="off">
          <PageHeader
            subTitle="Location"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className="grid grid-cols-2 gap-5 mb-5">
            <Form.Item
              name="from"
              label="From"
              rules={[
                {
                  required: true,
                  message: 'Please choose location!'
                }
              ]}>
              <Select
                disabled
                placeholder="Select a location!"
                onChange={onLocationChange}
                allowClear>
                {locationsList?.data.results.map((value) => {
                  return (
                    <Option value={value.id} key={value.id}>
                      {value.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="to"
              label="To"
              rules={[
                {
                  required: true,
                  message: 'Please choose location!'
                }
              ]}>
              <Select
                disabled
                placeholder="Select a location!"
                onChange={onLocationChange}
                allowClear>
                {locationsList?.data.results.map((value) => {
                  return (
                    <Option value={value.id} key={value.id}>
                      {value.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <Form.List name={['lines']}>
            {(fields2) => (
              <>
                {fields2.length > 0 && (
                  <PageHeader
                    title="All Products"
                    style={{
                      padding: '8px 0px 8px 10px'
                    }}
                  />
                )}
                <Card
                  style={{
                    maxHeight: '50vh',
                    overflowY: 'scroll',
                    borderRadius: '9px'
                  }}>
                  {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                    <div className="flex gap-1 items-center" key={key2}>
                      <span className="font-bold text-sm mb-5">{name2 + 1}.</span>
                      <div className="card">
                        <PageHeader
                          subTitle="Products"
                          style={{
                            padding: '8px 0px 8px 10px'
                          }}
                        />
                        <div
                          className={
                            'grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-8'
                          }
                          key={key2}>
                          <Form.Item {...restField2} name={[name2, 'productId']} hidden></Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'sellingPrice']}
                            hidden></Form.Item>
                          <Form.Item {...restField2} name={[name2, 'productName']} label="Name">
                            <Input
                              type={'text'}
                              disabled
                              style={{
                                backgroundColor: 'white',
                                color: 'black',
                                fontWeight: 'bold'
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'unitId']}
                            label="Unit"
                            rules={[
                              {
                                required: true,
                                message: 'Please choose a Unit!'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select a Unit!"
                                disabled
                                onChange={() => {
                                  onUnitandQuantityChange(name2);
                                }}
                                dropdownMatchSelectWidth={false}
                                allowClear>
                                {FilterUnits(name2)}
                              </Select>
                            }
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'lotId']}
                            label="Lot"
                            rules={[
                              {
                                required: true,
                                message: 'Please choose a Lot!'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select a Lot!"
                                dropdownMatchSelectWidth={false}
                                allowClear>
                                {FilterLot(name2)}
                              </Select>
                            }
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Quantity"
                            name={[name2, 'quantity']}
                            rules={[{ required: true, message: 'Please add Quantity!' }]}>
                            <InputNumber
                              controls={false}
                              disabled={true}
                              style={{ color: 'black' }}
                              min={1}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Rate"
                            name={[name2, 'unitPrice']}
                            rules={[{ required: true, message: 'Please add Rate!' }]}>
                            <InputNumber
                              controls={false}
                              disabled
                              style={{ color: 'black' }}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="VAT"
                            name={[name2, 'vat']}
                            rules={[{ required: true, message: 'Please add VAT!' }]}>
                            <InputNumber
                              controls={false}
                              disabled={true}
                              style={{ color: 'black' }}
                              min={0}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Discount"
                            name={[name2, 'discount']}
                            rules={[{ required: true, message: 'Please add Discount!' }]}>
                            <InputNumber
                              controls={false}
                              disabled
                              style={{ color: 'black' }}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Misc"
                            name={[name2, 'misc']}
                            rules={[{ required: true, message: 'Please add Misc!' }]}>
                            <InputNumber
                              controls={false}
                              disabled
                              style={{ color: 'black' }}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item {...restField2} name={[name2, 'total']} label="Total">
                            <InputNumber
                              controls={false}
                              disabled
                              min={0}
                              style={{ color: 'black' }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'isTraceable']}
                            label="Is Traceable?"
                            hidden>
                            <Checkbox />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  ))}
                </Card>
              </>
            )}
          </Form.List>
          {form.getFieldValue(['lines'])?.length > 0 ? (
            <Card
              style={{ borderRadius: '10px', marginBottom: '10px' }}
              className="grid grid-cols-2">
              <Form.Item name={['grandtotal']} label="Grand Total">
                <InputNumber controls={false} min={0} disabled style={{ color: 'black' }} />
              </Form.Item>
            </Card>
          ) : null}
          <Divider />
          <PageHeader
            subTitle="Checklist"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className="grid grid-cols-2 gap-2 md:grid-cols-4 mb-5">
            <Form.Item
              label={'Crate'}
              name={['crate']}
              rules={[
                {
                  required: true,
                  message: 'Cannot be empty'
                }
              ]}>
              <InputNumber controls={false} disabled={true} style={{ color: 'black' }} min={0} />
            </Form.Item>
            <Form.Item
              label={'Sack'}
              name={['sack']}
              rules={[
                {
                  required: true,
                  message: 'Cannot be empty'
                }
              ]}>
              <InputNumber controls={false} disabled={true} style={{ color: 'black' }} min={0} />
            </Form.Item>
            <Form.Item
              label={'Bags'}
              name={['bags']}
              rules={[
                {
                  required: true,
                  message: 'Cannot be empty'
                }
              ]}>
              <InputNumber controls={false} disabled={true} style={{ color: 'black' }} min={0} />
            </Form.Item>
            <Form.Item label={'Others'} name={['others']}>
              <Input disabled={true} style={{ color: 'black' }} />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <CustomSubmitButton text="Reject" />
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default TransferReject;
