import { useState } from 'react';
import { CustomModal } from '../../../components/Common/CustomModal';
import BillPrint from '../../../components/Common/InvoicePrint/BillPrint/BillPrint';
import { ISellInvoice } from '../../../services/invoice/types';
import EditSellCrates from '../crates';
import { Tooltip } from 'antd';
import { ISellFromServer } from '../../../services/sell/types';
import SellReturnView from '../return-view';
import { get_sell_return_details } from '../../../services/sell/queries';

export const SellReturnModal: React.FC<{
  record: ISellFromServer;
  getInfo: (val?: string) => void;
}> = ({ getInfo, record }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sellReturnData, setSellReturnData] = useState<any>();
  const getSellReturnData = async (record: any) => {
    // const data={};
    const response = await get_sell_return_details(`${record.id}`);
    // const lines = await get_sell_lines_details(`${val}`);
    setSellReturnData(response.data);
    setIsModalOpen(true);
  };
  return (
    <>
      <div
        className="w-full p-0 cursor-pointer"
        onClick={async () => {
          getSellReturnData(record);
        }}>
        <Tooltip title="View Return" color="blue">
          View Return
        </Tooltip>
      </div>
      <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} title={'All Returns'}>
        <SellReturnView data={sellReturnData} />
      </CustomModal>
    </>
  );
};
