interface Props {
  label: string;
  value?: string | number;
}

function VerticalForm({ label, value }: Props) {
  return (
    <div className="space-y-2">
      <div className="whitespace-normal text-left leading-[1.5715] min-h-[1px]">{label}</div>
      <input
        style={{ fontFeatureSettings: 'tnum' }}
        className="w-full min-h-[32px] text-sm text-black/80 leading-[1.5715] tabular-nums px-2"
        readOnly
        disabled
        value={value}
      />
    </div>
  );
}

export default VerticalForm;
