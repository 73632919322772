import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import AgentForm from '../AgentForm';
import { IUserAgents } from '@/services/agents/types';
import UsersDB from '@/store/localstorage/UsersDB';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_agent_by_id } from '@/services/agents/queries';
import { get_location_list_for_ids } from '@/services/locations/queries';
import { get_user_details } from '@/services/users/queries';
import { User } from '@/services/users/types';
import { Spin } from 'antd';

function EditAgentPage() {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [agent, setAgent] = useState<IUserAgents | null>(null);

  if (!id) {
    return <h1>Agent not found</h1>;
  }

  const breadcrumbItem = [
    { label: 'Users', link: '/users' },
    { label: 'Agents', link: '/users/agents' },
    { label: 'Update Agents', link: `/users/agents/${id}` }
  ];

  useQuery(['agent', id], async () => {
    try {
      setIsLoading(true);
      const { data } = await get_agent_by_id(parseInt(id));
      if (!data) setAgent(null);

      // Get location details
      let locationDetails = await LocationsDB.getLocation(data.locationId);
      if (!locationDetails) {
        const allLocations = await get_location_list_for_ids([data.locationId]);
        await LocationsDB.addLocations(allLocations);
        locationDetails = await LocationsDB.getLocation(data.locationId);
      }

      // GET USER DETAILS
      let userDetails = await UsersDB.getUser(data.userId);
      if (!userDetails) {
        const user = await get_user_details(data.userId);
        await UsersDB.addUsers([user.user]);
        userDetails = await UsersDB.getUser(data.userId);
      }

      data.user = userDetails as User & { id: number; disabled: boolean };
      setAgent(data);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Spin spinning={isLoading}>
      {agent && (
        <AgentForm
          mode="edit"
          breadcrumb={breadcrumbItem}
          data={{
            user: agent.user.name,
            userId: agent.userId,
            locationId: agent.locationId,
            id: agent.id,
            isDisabled: agent.isDisabled
          }}
        />
      )}

      {!agent && <h1>Agent not found</h1>}
    </Spin>
  );
}

export default EditAgentPage;
