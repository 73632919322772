import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IVendorType, IVendorTypeResult } from './types';
import { getDataForIds } from '@/utils/queries.utils';

export const get_vendor_type_list = async (filter = '') => {
  const response: AxiosResponse<IVendorTypeResult, any> = await http.get('vendor-types/?' + filter);
  return response.data;
};

export const get_vendor_type_details = async (id: number) => {
  const response: AxiosResponse<IVendorType, any> = await http.get('vendor-types/' + id);
  return response.data;
};

export const get_vendor_types_ids = async (ids: number[] | string[]) => {
  const parsedIds = ids.map(Number);
  return await getDataForIds<IVendorType>(parsedIds, 'vendor-types?');
};
export const get_vendor_types_filter = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IVendorTypeResult, any> = await http.get(
    'vendor-types/?' + filter,
    {
      skip,
      count,
      value
    }
  );
  return response;
};
