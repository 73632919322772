import React from 'react';
import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Button, Input, Pagination, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { Link } from 'react-router-dom';
// import AppContent from '../../../../components/Common/Content/Content';
import CreateInvoiceScheme from './Create';
import CustomUpdateIcon from '../../../../components/Common/CustomIcons/CustomUpdateIcon';

interface DataType {
  id: number;
  name: string;
  age: number;
  address: string;
  description: string;
}

const List: React.FC = () => {
  // const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // const deleteProductMutation = useMutation(delete_product, {
  //   onSuccess: () => {
  //     message.success('Product deleted successfully');
  //     dispatch(removeProduct(selectedID));
  //     // refetch();
  //   }
  // });

  const columns: ColumnsType<DataType> = [
    Table.SELECTION_COLUMN,
    // Table.EXPAND_COLUMN,
    {
      title: 'Name',
      key: 'name',
      render: (record) => {
        return (
          <Link to={`/products/${record.id}`} color="black">
            {record.name}
          </Link>
        );
      }
    },
    {
      title: 'Prefix',
      key: 'prefix',
      render: (record) => {
        return (
          <Link to={`/products/${record.id}`} color="black">
            {record.sku}
          </Link>
        );
      }
    },
    {
      title: 'Start From',
      key: 'startFrom',
      render: (record) => {
        return (
          <Link to={`/products/${record.id}`} color="black">
            {record.startFrom}
          </Link>
        );
      }
    },
    {
      title: 'Invoice count',
      key: 'invoiceCount',
      render: (record) => {
        return (
          <Link to={`/products/${record.id}`} color="black">
            {record.invoiceCount}
          </Link>
        );
      }
    },
    {
      title: 'Number of digits',
      key: 'numberOfDigits',
      render: (record) => {
        return (
          <Link to={`/products/${record.id}`} color="black">
            {record.invoiceCount}
          </Link>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (record) => (
        <div className="flex justify-center items-center">
          <CustomUpdateIcon link={`/products/${record.id}`} />
          <div
            onClick={() => {
              // selectedID = record.id;
              // deleteProductMutation.mutate(record.id);
            }}
            className="cursor-pointer text-amber-700 ml-1">
            <DeleteOutlined type="danger" />
          </div>
        </div>
      )
    }
  ];

  const onShowSizeChange = (current: number, pageSize: number) => {
    console.log(current, pageSize);
  };

  //asdsadsadsad
  return (
    <div>
      <div className={'mb-4 pagination-changer-wrapper'}>
        {/* <Pagination showSizeChanger onShowSizeChange={onShowSizeChange} size="small" /> */}

        <div className={'flex justify-around gap-1'}>
          <Input placeholder={'Search...'} size="small" allowClear />

          <Button
            htmlType={'button'}
            size={'small'}
            type={'primary'}
            className={'me-2'}
            onClick={() => showModal()}>
            <PlusCircleFilled />
            <span> Add</span>
          </Button>
        </div>
      </div>

      <Table columns={columns} dataSource={[]} rowKey={'id'} scroll={{ x: true }} />

      <CreateInvoiceScheme
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
      />
    </div>
  );
};

export default List;
