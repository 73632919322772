import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IBankResponse, IBankResponseData } from './types';
import { getDataForIds } from '../../utils/queries.utils';

export const get_bank_list = async (skip = 0, count = 0, value = '') => {
  const response: AxiosResponse<IBankResponse, any> = await http.get('bank', {
    skip,
    count,
    value
  });
  return response.data;
};

export const get_bank_list_filter = async (filter = '') => {
  const response: AxiosResponse<IBankResponse, any> = await http.get('bank/?' + filter);
  return response.data;
};

export const get_bank_details = async (id: number) => {
  const response: AxiosResponse<IBankResponseData, any> = await http.get('bank/' + id);
  return response.data;
};

export const get_bank_detail_by_ids = async (ids: number[]) => {
  return await getDataForIds<IBankResponseData>(ids, 'bank/?');
};
