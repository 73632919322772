import { useContext, useState } from 'react';
import CustomButton from '../../../../components/Common/CustomButton/CustomButton';
import { CustomModal } from '../../../../components/Common/CustomModal';
import { SellOrderListContext } from './context';
import { find_default_invoice } from '../../../../store/localstorage/preferences';
import { get_invoices_details } from '../../../../services/settings/queries';
import { message } from 'antd';
import { ICreateInvoiceResponse } from '../../../../services/settings/types';
import { getMultipleSellOrderPrintData } from '../../../../components/Common/InvoicePrint/MultipleSellOrderInvoice/services';
import { ISellInvoice } from '../../../../services/invoice/types';
import MultipleSellOrderInvoice from '../../../../components/Common/InvoicePrint/MultipleSellOrderInvoice/MultipleSellOrderInvoice';

export const SelectedSellOrderModal: React.FC<{
  selectedProducts: any;
  getInfo: (url: string) => void;
}> = ({ selectedProducts, getInfo }) => {
  const { setIsLoading, urlData } = useContext(SellOrderListContext);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<ISellInvoice[]>(Object);
  const defaultInvoice = find_default_invoice();

  const generateInvoiceForPrint = async (selectedProducts: any, curr: ICreateInvoiceResponse) => {
    // console.log('record', record);
    setIsLoading(true);
    const invoiceModalData = await getMultipleSellOrderPrintData(selectedProducts, curr);
    setIsLoading(false);

    setInvoiceData(invoiceModalData);

    setOpenModalForInvoicePrint(true);
  };

  const handleInvoicePrintModalClose = () => {
    setOpenModalForInvoicePrint(false);
    getInfo(urlData);
  };

  return (
    <div>
      <CustomModal
        footer={false}
        isModalOpen={openModalForInvoicePrint}
        setIsModalOpen={setOpenModalForInvoicePrint}
        title="Sell Order Invoice Print">
        <MultipleSellOrderInvoice
          invoiceData={invoiceData}
          handleModalClose={handleInvoicePrintModalClose}
        />
      </CustomModal>
      <CustomButton
        onClick={async () => {
          if (defaultInvoice) {
            const curr = (await get_invoices_details(defaultInvoice.toString())).data;
            generateInvoiceForPrint(selectedProducts, curr);
          } else {
            message.error('Please select a default Invoice Layout First!');
          }
        }}
        text="Print Selected"
        backgroundColor="#1890ff"
      />
    </div>
  );
};
