import { CopyOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';

interface CopyButtonProps {
  text: string;
  color?: string;
}

const CopyButton = ({ text, color = '#007500' }: CopyButtonProps) => {
  const [isCopied, setIsCopied] = React.useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  return (
    <Tooltip title={isCopied ? 'copied!' : 'copy'}>
      <Button
        onClick={handleCopy}
        type="text"
        shape="circle"
        size="small"
        className="ml-1"
        style={{ color }}
        icon={<CopyOutlined />}
      />
    </Tooltip>
  );
};

export default CopyButton;
