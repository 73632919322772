import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { ILogsResult, ISystemLogs } from './types';

export const get_logs_list = async (filter = '') => {
  const response: AxiosResponse<ILogsResult, any> = await http.get('/logs/?' + filter);
  return response.data;
};

export const get_log_details = async (id: number) => {
  const response: AxiosResponse<ISystemLogs, any> = await http.get('logs/' + id);
  return response.data;
};

export const get_vendor_logs_list = async (filter = '') => {
  const response: AxiosResponse<any, any> = await http.get('/ird/logs?' + filter);
  return response.data;
};
