import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { TableProps } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';

import { ColumnsType } from 'antd/lib/table';

import { ExtendedInitialValues } from '.';
import CopyButton from '@/components/Common/CopyButton';
import { ISaleOrderReport } from '@/services/report/types';
import { getInt } from '../pending-payment-sell-report/report';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { WithReportPaginationProps } from '@/components/Common/Report/withReportPagination';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { Tooltip } from 'antd';
import ExportCurrent from '@/components/Common/ExportCurrent';
import { excelExportColumns, tableExportColumns } from './columns.export';
import ExportAllData from '@/components/Common/ExportAll';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import GenericTable from '@/components/Common/CustomizeTable';

interface Props
  extends Pick<WithReportPaginationProps, 'onPagination' | 'onChange' | 'pagination'> {
  data: { total: number; data: ISaleOrderReport[] };
  onSearch: (values: ExtendedInitialValues) => void;
  onSearchAll: () => Promise<ISaleOrderReport[]>;
}

function TableData({ data, pagination, ...handlers }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const updatedData = data.data.map((item) => {
    return {
      ...item,
      amount: getInt(item.total_taxable) + getInt(item.total_non_taxable),
      amount_remainng: item.total_amount - item.amount_returned - item.amount_paid,
      netSell: item.total_amount - item.amount_returned
    };
  });

  const total = data.data.reduce(
    (acc, curr) => {
      const netSell = curr.total_amount - curr.amount_returned;
      acc.totalAmount += getInt(curr.total_amount);
      acc.netSell += netSell;

      return acc;
    },
    { amount: 0, totalAmount: 0, remaining: 0, netSell: 0 }
  );

  const columns: ColumnsType<ISaleOrderReport> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 30,
      fixed: 'left',
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(pagination.page - 1) * pagination.size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Financial Reference',
      width: 80,
      key: 'financial_reference',
      className: 'invoice',
      sorter: (a, b) => a.financial_reference.localeCompare(b.financial_reference),
      sortOrder: sortedInfo.columnKey === 'financial_reference' ? sortedInfo.order : null,
      render: (record: ISaleOrderReport) => {
        return (
          <TableCell>
            <div className="flex flex-row justify-between items-center invoice">
              <Link to={`/sell/${record.id}`}>{record.financial_reference}</Link>
              {record.financial_reference ? <CopyButton text={record.financial_reference} /> : null}
            </div>
          </TableCell>
        );
      }
    },
    {
      title: 'Customer',
      width: 50,
      key: 'customer',
      sorter: (a, b) => a.customer.localeCompare(b.customer),
      sortOrder: sortedInfo.columnKey === 'customer' ? sortedInfo.order : null,
      render: (record: ISaleOrderReport) => {
        return <TableCell>{record.customer}</TableCell>;
      }
    },
    {
      title: 'Amount',
      width: 40,
      key: 'amount',
      dataIndex: 'amount',
      sorter: (a, b) =>
        a.total_taxable + a.total_non_taxable - b.total_taxable + b.total_non_taxable,
      sortOrder: sortedInfo.columnKey === 'amount' ? sortedInfo.order : null,
      render: (record: number) => {
        return (
          <TableCell className="text-right mr-2 text-xs">{nepaliNumberFormatter(record)}</TableCell>
        );
      }
    },

    {
      title: 'VAT',
      width: 40,
      key: 'totalVat',
      dataIndex: 'total_vat',
      sorter: (a, b) => a.total_vat - b.total_vat,
      sortOrder: sortedInfo.columnKey === 'total_vat' ? sortedInfo.order : null,
      render: (total_vat: number) => {
        return (
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(total_vat)}
          </TableCell>
        );
      }
    },
    {
      title: 'Shipping',
      width: 45,
      key: 'shippingCharge',
      dataIndex: 'shipping',
      sorter: (a, b) => {
        return a.shipping - b.shipping;
      },
      sortOrder: sortedInfo.columnKey === 'amount_paid' ? sortedInfo.order : null,
      render: (shipping: number) => {
        return (
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(shipping)}
          </TableCell>
        );
      }
    },
    {
      title: 'Shipping Tax',
      width: 45,
      key: 'shipping_tax',
      dataIndex: 'shipping_tax',
      sorter: (a, b) => a.shipping_tax - b.shipping_tax,
      sortOrder: sortedInfo.columnKey === 'shipping_tax' ? sortedInfo.order : null,
      render: (shipping_tax: string) => {
        return (
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(Number(shipping_tax))}
          </TableCell>
        );
      }
    },
    {
      title: 'Total',
      width: 50,
      key: 'total_amount',
      dataIndex: 'total_amount',
      sorter: (a, b) => a.total_amount - b.total_amount,
      sortOrder: sortedInfo.columnKey === 'total_amount' ? sortedInfo.order : null,
      render: (total_amount: number) => {
        return (
          <TableCell className="text-right mr-2 text-xs highlight-number">
            {nepaliNumberFormatter(total_amount)}
          </TableCell>
        );
      }
    },
    {
      title: 'Paid',
      width: 40,
      key: 'amount_paid',
      dataIndex: 'amount_paid',
      sorter: (a, b) => a.amount_paid - b.amount_paid,
      sortOrder: sortedInfo.columnKey === 'amount_paid' ? sortedInfo.order : null,
      render: (amount_paid: number) => {
        return (
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(amount_paid)}
          </TableCell>
        );
      }
    },
    {
      title: 'Return',
      width: 40,
      key: 'amount_returned',
      dataIndex: 'amount_returned',
      sorter: (a, b) => a.amount_returned - b.amount_returned,
      sortOrder: sortedInfo.columnKey === 'amount_returned' ? sortedInfo.order : null,
      render: (amount_returned: number) => {
        return (
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(amount_returned)}
          </TableCell>
        );
      }
    },
    {
      title: 'Remaining',
      width: 45,
      key: 'amountRemaining',
      dataIndex: 'amount_remainng',
      sorter: (a, b) => {
        const c = a.total_amount - a.amount_returned - a.amount_paid;
        const d = b.total_amount - b.amount_returned - b.amount_paid;
        return c - d;
      },
      sortOrder: sortedInfo.columnKey === 'amountRemaining' ? sortedInfo.order : null,
      render: (a, record: ISaleOrderReport) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(
              record.total_amount - record.amount_returned - record.amount_paid
            )}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Net Sell',
      width: 40,
      key: 'netsell',
      className: 'highlight',
      dataIndex: 'netSell',
      sorter: (a, b) => {
        const c = a.total_amount - a.amount_returned;
        const d = b.total_amount - b.amount_returned;
        return c - d;
      },
      sortOrder: sortedInfo.columnKey === 'netsell' ? sortedInfo.order : null,
      render: (a, record: ISaleOrderReport) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell className="text-right mr-2 text-xs">
            {nepaliNumberFormatter(record.total_amount - record.amount_returned)}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Profit',
      width: 40,
      key: 'profit',
      dataIndex: 'profit',
      sorter: (a, b) => {
        return a.profit - b.profit;
      },
      sortOrder: sortedInfo.columnKey === 'profit' ? sortedInfo.order : null,
      render: (profit: number) => {
        return (
          <div
            style={{
              color: profit > 0 ? 'green' : 'red'
            }}>
            <TableCell className="text-right mr-2 text-xs">
              {nepaliNumberFormatter(profit)}
            </TableCell>
          </div>
        );
      }
    },

    {
      title: 'Date',
      width: 55,
      key: 'date',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (record: ISaleOrderReport) => {
        return (
          <TableCell>
            <div color="black">
              {/* {moment(record.date).local().format(DEFAULT_DATE_FORMAT)} */}
              {convertUTCStringtoLocalString(record.created_at, DEFAULT_DATE_FORMAT)}
              {/* {record.date.slice(0, 10)} */}
            </div>
          </TableCell>
        );
      }
    },
    {
      title: 'Crates Sent',
      width: 35,
      key: 'crates_sent',
      sorter: (a, b) => a.crates_sent - b.crates_sent,
      sortOrder: sortedInfo.columnKey === 'crates_sent' ? sortedInfo.order : null,
      render: (record: ISaleOrderReport) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell>{record.crates_sent}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Crates Received',
      width: 45,
      key: 'crates_received',
      sorter: (a, b) => a.crates_received - b.crates_received,
      sortOrder: sortedInfo.columnKey === 'crates_received' ? sortedInfo.order : null,
      render: (record: ISaleOrderReport) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell>{record.crates_received}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Created By',
      key: 'addedBy',
      width: 40,
      sorter: (a, b) => (a.addedBy ?? '').localeCompare(b.addedBy ?? ''),
      sortOrder: sortedInfo.columnKey === 'addedBy' ? sortedInfo.order : null,
      render: (record: ISaleOrderReport) => {
        return <TableCell>{record.addedBy}</TableCell>;
      }
    },
    {
      title: 'Location',
      width: 50,
      key: 'location_name',
      sorter: (a, b) => (a.location_name ?? '').localeCompare(b.location_name ?? ''),
      sortOrder: sortedInfo.columnKey === 'location_name' ? sortedInfo.order : null,
      render: (record: ISaleOrderReport) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <TableCell>{record.location_name}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Note',
      width: 30,
      key: 'note',
      sorter: (a, b) => a.note.localeCompare(b.note),
      sortOrder: sortedInfo.columnKey === 'note' ? sortedInfo.order : null,
      render: (record: ISaleOrderReport) => {
        return (
          <TableCell>
            <Tooltip title={record.note} color="blue">
              <div className="cursor-pointer">
                {record.note ? `${record.note}`.slice(0, 10) + '...' : ''}
              </div>
            </Tooltip>
          </TableCell>
        );
      }
    },
    {
      title: 'Print Count',
      width: 30,
      key: 'print_count',
      sorter: (a, b) => a.print_count - b.print_count,
      sortOrder: sortedInfo.columnKey === 'print_count' ? sortedInfo.order : null,
      render: (record: ISaleOrderReport) => {
        return <TableCell>{record.print_count}</TableCell>;
      }
    }
  ];

  return (
    <div>
      <GenericTable
        columns={columns}
        tableName="sell-list-report"
        data={data.data}
        hideDefaultPagination={true}
        toSort={handleChange}
        scroll={{ x: 2000, y: '75vh' }}
        buttons={
          <>
            <ExportAllData
              title="Sell Report (All)"
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              getInfo={handlers.onSearchAll}
            />
            <ExportCurrent
              data={{ total: data.total, results: updatedData }}
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              title="Sell Report"
            />
          </>
        }
        generateSummary
        pagination={{
          page: pagination.page,
          total: data.total,
          size: pagination.size,
          onPagination: (pageNo, currentSize) => {
            currentSize == pagination.size
              ? handlers.onPagination(handlers.onSearch, pageNo)
              : handlers.onPagination(handlers.onSearch, pageNo, currentSize);
          }
        }}
      />
    </div>
  );
}

export default TableData;
