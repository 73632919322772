import { useContext, useState } from 'react';
import { CustomModal } from '../../../../components/Common/CustomModal';
import ReturnInvoice from '../../../../components/Common/InvoicePrint/ReturnInvoice/ReturnInvoice';
import { IReturnInvoice } from '../../../../services/invoice/types';
import { SellReturnListContext } from './context';
import { ISellReturn } from '../../../../services/sell/types';
import { ICreateInvoiceResponse } from '../../../../services/settings/types';
import { getSellReturnPrintData } from '../../../../components/Common/InvoicePrint/ReturnInvoice/services';
import InvoicePrintButton from '../../../../components/Common/InvoicePrintButton/InvoicePrintButton';

export const InvoicePrintModal: React.FC<{
  getInfo: (url: string) => void;
  record: any;
  urlData: string;
}> = ({ getInfo, record, urlData }) => {
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<IReturnInvoice>(Object);
  const { setIsLoading } = useContext(SellReturnListContext);

  const generateInvoiceForPrint = async (record: ISellReturn, curr: ICreateInvoiceResponse) => {
    setIsLoading(true);
    const invoiceModalData = await getSellReturnPrintData(
      record.sellId,
      record.id,
      record.locationId,
      false,
      record.createdBy
    );
    setIsLoading(false);
    setInvoiceData({ ...invoiceModalData, invoiceLayouts: curr });
    setOpenModalForInvoicePrint(true);
  };
  const handleInvoicePrintModalClose = async () => {
    setOpenModalForInvoicePrint(false);
    getInfo(urlData);
  };

  return (
    <>
      <InvoicePrintButton
        getInvoice={(curr) => {
          generateInvoiceForPrint(record, curr);
        }}
      />
      <CustomModal
        footer={false}
        isModalOpen={openModalForInvoicePrint}
        setIsModalOpen={setOpenModalForInvoicePrint}
        title="Sell Return Invoice Print">
        <ReturnInvoice
          returnDetails={invoiceData.returnDetails}
          customerDetails={invoiceData.customerDetails}
          lines={invoiceData.lines}
          invoiceLayouts={invoiceData.invoiceLayouts}
          handleModalClose={handleInvoicePrintModalClose}
        />
      </CustomModal>
    </>
  );
};
