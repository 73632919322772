import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Button, Form, FormInstance, Input, message, Spin } from 'antd';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import DateForm from '../Common/DateForm';
import { ConvertObjectToURL } from '@/utils/converturl';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { CustomDatePresets } from '@/pages/sqlsource/report/utils/datePresets';
import { convert_string_to_nepali_date_string } from '@/utils/nepaliDateConverter';
import { CloseOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import {
  deleteUserFilter,
  get_current_page_filter,
  updateUserFilter
} from '@/services/users/services.users';
import CustomErrorModal from '../Common/CustomErrorModal';

type DefaultValueType = {
  dateSingle?: moment.Moment;
  dateCustom?: [moment.Moment, moment.Moment];
  value?: string;
  skip?: number;
  count?: number;
} & { [key: string]: any };

interface Props {
  showFilterInitial?: boolean;
  initial?: boolean;
  form: FormInstance;
  defaultValues?: DefaultValueType;
  dateCustom?: boolean;
  hasHours?: boolean;
  buttons?: React.ReactNode;
  singleDate?: boolean;
  isQuery?: boolean;
  buttonParentStyle?: string;
  hideDate?: boolean;

  onSearch: (values: string, isInitial?: boolean) => void;
}

function AccountFilterTable({
  showFilterInitial = true,
  form,
  initial,
  buttons,
  children,
  defaultValues,
  hasHours = true,
  dateCustom = true,
  buttonParentStyle = '',
  isQuery = true,
  singleDate = false,
  hideDate = false,
  ...handlers
}: PropsWithChildren<Props>) {
  const prevFinancialYearRef = useRef('');
  const { pathname, search } = useLocation();
  const [showFilter, setShowFilter] = useState(showFilterInitial);

  const formParentRef = useRef<HTMLDivElement | null>(null);
  const filterRef = useRef<HTMLElement | null>(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterSaved, setIsFilterSaved] = useState(false);

  const toggleDrawer = () => setShowFilter((prev) => !prev);
  const closeModal = () => {
    setShowFilter(false);
  };

  function handleKeyboardClick(key: string) {
    if (showFilter && key === 'Enter') {
      setTimeout(() => filterRef.current?.click(), 100);
    }

    if (key === 'Escape') {
      toggleDrawer();
    }
  }

  useEffect(() => {
    const parentElement = formParentRef.current;
    if (!parentElement) return;

    function handleClickOutside(event: KeyboardEvent) {
      event.stopPropagation();
      const target = event.target as HTMLElement;
      const isSelectOpen = target.closest('.ant-select-open');
      setOpenDatePicker(false);

      if (isSelectOpen) return;
      handleKeyboardClick(event.key);
    }

    parentElement.addEventListener('keydown', handleClickOutside, true);
    return () => parentElement.removeEventListener('keydown', handleClickOutside, true);
  }, [showFilter]);

  useEffect(() => {
    function handleKeyboard(event: KeyboardEvent) {
      handleKeyboardClick(event.key);
    }

    document.addEventListener('keydown', handleKeyboard);
    return () => document.removeEventListener('keydown', handleKeyboard);
  }, [showFilter]);

  useEffect(() => {
    const currentFilter = checkFilterPersist(search);
    const formState = currentFilter || getFormState(true);
    form.setFieldsValue(formState);

    if (!initial) return;
    handleFilterSubmit(formState, true);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const f_startDate = params.get('fsd');
    const f_endDate = params.get('fed');
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');

    const hasBothCalendar = startDate && endDate;
    const hasBothFinancial = f_startDate && f_endDate;

    const financialYear = hasBothFinancial ? `${f_startDate}-${f_endDate}` : '';
    if (prevFinancialYearRef.current !== financialYear) {
      // If the financial year is changed, show the filter
      if (prevFinancialYearRef.current !== '') {
        setShowFilter(true);
      }

      prevFinancialYearRef.current = financialYear;
    }

    const currentFilter = checkFilterPersist(search);
    if (currentFilter) {
      form.setFieldsValue(currentFilter);
      return;
    }

    if (startDate && endDate) {
      const formState = getFormState(false, { start: startDate, end: endDate, type: 'calendar' });
      form.setFieldsValue(formState);
    }

    if (!hasBothCalendar && f_startDate && f_endDate) {
      const formState = getFormState(false, {
        start: f_startDate,
        end: f_endDate,
        type: 'financial'
      });
      form.setFieldsValue(formState);
    }
  }, [search]);

  function handleFilterSubmit(values: any, isInitial = false) {
    setShowFilter(false);

    if (!hideDate) {
      if (dateCustom) {
        values.startDate = convertLocalToUTCString(values.startDate);
        values.endDate = convertLocalToUTCString(values.endDate);

        delete values.dateCustom;
        delete values.startDateNepali;
        delete values.endDateNepali;
      }

      if (!dateCustom) {
        delete values.startDate;
        delete values.endDate;
      }

      if (singleDate) {
        values.date = convertLocalToUTCString(values.date);
        delete values.dateSingle;
        delete values.dateSingleNepali;
      } else {
        delete values.date;
      }
    }

    if (hideDate) {
      delete values.startDate;
      delete values.endDate;
      delete values.date;
      delete values.dateSingle;
      delete values.dateCustom;
      delete values.startDateNepali;
      delete values.endDateNepali;
      delete values.dateSingleNepali;
    }

    // IF query is needed, convert the object to URL string and pass it else pass object as it is
    !isQuery
      ? handlers.onSearch(values, isInitial)
      : handlers.onSearch(ConvertObjectToURL(values), isInitial);
  }

  function getInitialDate(start?: string, end?: string, type?: 'calendar' | 'financial') {
    if (!start || !end) return [...CustomDatePresets['Today']];

    const today = moment();
    const startDate = moment(start);
    const endDate = moment(end);

    if (type === 'calendar') {
      return [startDate, endDate];
    }

    if (today.isBetween(startDate, endDate)) {
      return [...CustomDatePresets['Today']];
    }

    const lastDay = endDate.startOf('day');
    return [endDate.clone().subtract(1, 'day'), lastDay];
  }

  function getFormState(
    useToday: boolean,
    year?: { start: string; end: string; type: 'financial' | 'calendar' }
  ) {
    if (hideDate) return { ...defaultValues };

    const customDateValues =
      useToday && !year
        ? [...CustomDatePresets['Today']]
        : getInitialDate(year?.start, year?.end, year?.type);

    if (dateCustom) {
      const nepaliDateStart = convert_string_to_nepali_date_string(
        customDateValues[0].format('YYYY-MM-DD')
      );

      const nepaliDateEnd = convert_string_to_nepali_date_string(
        customDateValues[1].format('YYYY-MM-DD')
      );

      return {
        ...defaultValues,
        dateCustom: customDateValues,
        startDateNepali: nepaliDateStart,
        endDateNepali: nepaliDateEnd,
        startDate: customDateValues[0].format('YYYY-MM-DD'),
        endDate: customDateValues[1].format('YYYY-MM-DD')
      };
    } else if (singleDate) {
      const date = customDateValues[0];
      const nepaliDate = convert_string_to_nepali_date_string(date.format('YYYY-MM-DD'));

      return {
        ...defaultValues,
        dateSingle: date,
        dateSingleNepali: nepaliDate,
        date: date.format('YYYY-MM-DD')
      };
    } else return { ...defaultValues };
  }

  const checkDateWithinRange = (
    currentFilter: Record<string, any>,
    startDate: moment.Moment,
    endDate: moment.Moment
  ) => {
    if (currentFilter.dateCustom) {
      const [currentStartDate, currentEndDate] = currentFilter.dateCustom.map((date: string) =>
        moment(date)
      );

      return (
        currentStartDate.isBetween(startDate, endDate) &&
        currentEndDate.isBetween(startDate, endDate)
      );
    }

    if (currentFilter.dateSingle) {
      return moment(currentFilter.dateSingle).isBetween(startDate, endDate);
    }

    return true;
  };

  const checkFilterPersist = (search: string) => {
    const currentFilter = get_current_page_filter(pathname);
    setIsFilterSaved(!!currentFilter);

    if (!currentFilter) return;

    const params = new URLSearchParams(search);
    const f_startDate = params.get('fsd');
    const f_endDate = params.get('fed');

    if (f_startDate && f_endDate) {
      const momentStartDate = moment(f_startDate);
      const momentEndDate = moment(f_endDate);

      const customDateValues = getInitialDate(f_startDate, f_endDate, 'financial');
      const isWithin = checkDateWithinRange(currentFilter, momentStartDate, momentEndDate);
      if (!isWithin) {
        if (currentFilter.dateCustom) {
          const nepaliDateStart = convert_string_to_nepali_date_string(
            customDateValues[0].format('YYYY-MM-DD')
          );

          const nepaliDateEnd = convert_string_to_nepali_date_string(
            customDateValues[1].format('YYYY-MM-DD')
          );

          currentFilter['dateCustom'] = customDateValues;
          currentFilter['startDateNepali'] = nepaliDateStart;
          currentFilter['endDateNepali'] = nepaliDateEnd;
          currentFilter['startDate'] = customDateValues[0].format('YYYY-MM-DD');
          currentFilter['endDate'] = customDateValues[1].format('YYYY-MM-DD');
        } else if (currentFilter.dateSingle) {
          const date = customDateValues[0];
          const nepaliDate = convert_string_to_nepali_date_string(date.format('YYYY-MM-DD'));

          currentFilter['dateSingle'] = date;
          currentFilter['dateSingleNepali'] = nepaliDate;
          currentFilter['date'] = date.format('YYYY-MM-DD');
        }
      }
    }

    return currentFilter;
  };

  async function onSaveFilter() {
    try {
      setIsLoading(true);
      const formValues = form.getFieldsValue();
      if (formValues['dateCustom']) {
        const dates = formValues['dateCustom'] as [moment.Moment, moment.Moment];
        formValues['dateCustom'] = [dates[0].toISOString(), dates[1].toISOString()];
      }

      if (formValues['dateSingle']) {
        const date = formValues['dateSingle'] as moment.Moment;
        formValues['dateSingle'] = date.toISOString();
      }

      await updateUserFilter(pathname, formValues);
      setIsFilterSaved(true);
      message.success('Filter saved successfully for current page');
    } catch (error: any) {
      if (error.type === 'Error') {
        CustomErrorModal({
          title: 'Error',
          message: error.message || 'Error saving dashboard layout'
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function onResetFilter() {
    try {
      setIsLoading(true);
      const formState = getFormState(true);
      form.setFieldsValue(formState);

      await deleteUserFilter(pathname);
      message.success('Filter reset successfully for current page');
      setIsFilterSaved(false);
    } catch (error: any) {
      if (error.type === 'Error') {
        CustomErrorModal({
          title: 'Error',
          message: error.message || 'Error saving dashboard layout'
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div ref={formParentRef} className="z-10">
      <Form form={form} layout="vertical" autoComplete="off" onFinish={handleFilterSubmit}>
        <div className="flex flex-row relative items-center justify-end mt-2 flex-wrap gap-2 sm:gap-0">
          <Button
            className="flex items-center justify-end secondary-button"
            onClick={toggleDrawer}
            style={{ borderRadius: '9px' }}>
            Filters
          </Button>

          {buttons && <div className={buttonParentStyle}>{buttons}</div>}
        </div>

        <div
          className={`fixed inset-0 bg-gray-800 bg-opacity-25 z-[1001] transition-opacity duration-300 ${
            showFilter ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
          }`}>
          <div className="fixed inset-0 z-0" onClick={closeModal} />
          <div className="fixed top-0 right-0 bottom-0 max-h-[100vh] w-full md:w-1/3 lg:w-1/4 transition-transform duration-300 ">
            <div className="h-full p-6 bg-gray-100 overflow-y-auto">
              <div className="absolute top-3 right-4 cursor-pointer" onClick={closeModal}>
                <CloseOutlined />
              </div>

              <Spin
                spinning={isLoading}
                wrapperClassName="h-full filter-wrapper"
                className="h-full">
                <div className="flex h-full flex-col justify-between gap-2">
                  <div className="grid grid-cols-1 gap-2">
                    <Form.Item label={'Start Date'} name={['skip']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item label={'End Date'} name={['count']} hidden>
                      <Input />
                    </Form.Item>

                    {/* // This is the DateForm component  */}
                    {!hideDate && (
                      <DateForm
                        form={form}
                        hasHours={hasHours}
                        singleDate={singleDate}
                        customDate={dateCustom}
                        stateManageExternal
                        open={openDatePicker}
                        setOpen={setOpenDatePicker}
                        defaultDate={defaultValues?.dateCustom}
                      />
                    )}
                    {children}
                  </div>

                  <div className="flex gap-2 justify-end flex-wrap pb-2">
                    <Button
                      type="primary"
                      onClick={onSaveFilter}
                      className="!rounded-md text-base"
                      icon={<SaveOutlined size={20} className="[&>svg]:m-0" />}>
                      Save Filter
                    </Button>

                    {isFilterSaved && (
                      <Button
                        type="default"
                        onClick={onResetFilter}
                        className="!flex justify-center items-center !rounded-md"
                        icon={<DeleteOutlined size={20} className="[&>svg]:m-0" />}>
                        Reset Filter
                      </Button>
                    )}

                    <Form.Item>
                      <Button
                        htmlType="button"
                        onClick={() => {
                          const value = form.getFieldsValue();
                          handleFilterSubmit(value);
                        }}
                        ref={filterRef}
                        type="default"
                        className="!rounded-md !text-green-500 !border !border-green-500 text-base">
                        Filter
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default AccountFilterTable;
