import { RetweetOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, InputNumber, Spin, Tooltip, message } from 'antd';
import { useEffect, useState } from 'react';
import { generatePassword } from '../../../utils/password.utils';
import { useMutation } from '@tanstack/react-query';

import {
  ICreateCustomerResponse,
  IUserCreateAddressRequest,
  IUserCreateRequest
} from '../../../services/users/types';
import {
  create_customers_mutation,
  create_users_address_mutation
} from '../../../services/users/mutations';
import { find_locationId_preference } from '../../../store/localstorage/preferences';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import { get_location_details } from '../../../services/locations/queries';
import { ICreateAutoAccounts } from '../../../services/accounts/types';
import { create_auto_account_type_mutation } from '../../../services/accounts/mutations';
import { UserAccountType } from '../../../services/accounts/enums';

interface ModalProps {
  handleModalClose: (Id: number, searchValue: string) => void;
}

const ReusableCustomerCreate: React.FC<ModalProps> = ({ handleModalClose }) => {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<number>();
  const [searchValue, setSearchValue] = useState<string>();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue('');
  }, []);

  const handleAdd = async () => {
    await form.validateFields();
    const phone = form.getFieldValue('phone').toString();

    const values: IUserCreateRequest = {
      phone: phone,
      name: isChecked ? form.getFieldValue('name') : phone,
      password: phone
    };
    setSearchValue(phone);
    setIsloading(true);
    createUsersMutation.mutate(values);
  };

  const createUsersMutation = useMutation(create_customers_mutation, {
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.response.data.message}`, 5);
    },
    onSuccess: async (data) => {
      if (data) {
        //console.log('Data', data);
        const defaultLocation = await find_locationId_preference();
        let LocationDetails: any;
        if (typeof defaultLocation === 'number') {
          LocationDetails = await LocationsDB.getLocation(defaultLocation);
          if (!LocationDetails) {
            LocationDetails = await get_location_details(defaultLocation);
            await LocationsDB.addLocations([LocationDetails]);
          }
        }
        setCustomerId(data.data.id);
        const values: IUserCreateAddressRequest = {
          userId: data.data.userId,
          addressLine1: LocationDetails.name
        };
        await updateAddressMutation.mutate(values);

        if (data.data.userId) {
          const accountData: ICreateAutoAccounts = {
            userId: data.data.userId,
            type: UserAccountType.CUSTOMER_PARENT
          };
          await createAutoAccountsMutation.mutateAsync(accountData);
        } else {
          message.error('Error creating account! Please create it manually.');
        }
      }
      form.resetFields();
    }
  });

  const updateAddressMutation = useMutation(create_users_address_mutation, {
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.response.data.message}`, 5);
    },
    onSuccess: async () => {
      if (customerId && searchValue) {
        message.success('Customer added successfully');
        await handleModalClose(customerId, searchValue);
      }
      setIsloading(false);
    }
  });

  const createAutoAccountsMutation = useMutation(create_auto_account_type_mutation, {
    onSuccess: () => {
      setIsloading(false);
      message.success('Account added successfully');
    },
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.response.data.message}`);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        //onFinish={onFinish}
        layout="vertical"
        validateTrigger={'onChange'}
        disabled={isLoading}
        autoComplete="off"
        className="p-2">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
          <Form.Item
            name={['phone']}
            label="Customer Phone No."
            rules={[
              {
                required: true,
                message: 'Please enter Customer Phone No.!'
              }
            ]}>
            <InputNumber controls={false} maxLength={10} />
          </Form.Item>
          <div className="flex gap-5">
            <div className="flex flex-col items-center">
              <label>Add name?</label>
              <Checkbox checked={isChecked} onClick={() => setIsChecked(!isChecked)} />
            </div>

            <Form.Item
              className={isChecked ? 'flex-1' : 'flex-1 hidden'}
              name={['name']}
              label="Customer Name"
              rules={[
                {
                  required: false,
                  message: 'Please enter Customer Name!'
                }
              ]}>
              <Input />
            </Form.Item>
          </div>
          {/* <div className="flex">
            <Form.Item
              className="flex-1"
              name={['password']}
              label="Customer Password"
              rules={[{ required: true, message: 'Please add password!' }]}>
              <Input.Password />
            </Form.Item>
            <Tooltip title="generate password">
              <Button
                style={{ marginTop: '30px' }}
                icon={<RetweetOutlined />}
                onClick={() => {
                  const password: string = generatePassword(10);
                  form.setFieldValue('customerPassword', password);
                }}
              />
            </Tooltip>
          </div> */}
        </div>
        <Form.Item className="text-right mt-3">
          <Button
            onClick={handleAdd}
            style={{ borderRadius: '5px', color: 'white', backgroundColor: '#1890ff' }}>
            Add
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};
export default ReusableCustomerCreate;
