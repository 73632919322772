import { Form, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import AppContent from '@/components/Common/Content/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { UsersSearch } from '@/components/Common/UsersSearch';
import TableFilter from '@/components/FliterTable';
import { get_attendance_by_user, get_self_attendance } from '@/services/attendance/queries';
import { get_location_list } from '@/services/locations/queries';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';

const AttendanceList = () => {
  const [isloading, setIsloading] = useState(true);
  const [attendanceList, setAttendanceList] = useState<any[]>([]);
  const [form] = Form.useForm();

  const breadcrumbItems = [
    {
      label: 'Attendance',
      link: '/attendance'
    }
  ];

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
  };
  const getInfo = async (val: any) => {
    setIsloading(true);
    if (!val.userId) {
      message.error('Please select user!');
      setIsloading(false);
      return;
    }
    const response = await get_attendance_by_user(val.userId);
    for (let index = 0; index < response.data.length; index++) {
      if (response.data[index].locationId) {
        let locD: any = await LocationsDB.getLocation(response.data[index].locationId);
        if (!locD) {
          const allLocations = await get_location_list();
          await LocationsDB.addLocations([...allLocations.data.results]);
          // await LocationsDB.addLocations([locD]);
          locD = await LocationsDB.getLocation(response.data[index].locationId);
        }
        response.data[index].locationName = locD.name;
      } else {
        response.data[index].locationName = 'None';
      }
    }
    setAttendanceList(response.data);
    setIsloading(false);
  };
  const selfAttendance = async () => {
    setIsloading(true);
    const response = await get_self_attendance();
    for (let index = 0; index < response.data.length; index++) {
      if (response.data[index].locationId) {
        let locD: any = await LocationsDB.getLocation(response.data[index].locationId);
        if (!locD) {
          const allLocations = await get_location_list();
          await LocationsDB.addLocations([...allLocations.data.results]);
          // await LocationsDB.addLocations([locD]);
          locD = await LocationsDB.getLocation(response.data[index].locationId);
        }
        response.data[index].locationName = locD.name;
      } else {
        response.data[index].locationName = 'None';
      }
    }
    setAttendanceList(response.data);
    form.setFieldValue('userId', '');
    setIsloading(false);
  };
  useEffect(() => {
    selfAttendance();
  }, []);

  return (
    <AppContent
      breadcrumbItems={breadcrumbItems}
      withfilter={true}
      button={
        <>
          <div>
            <TableFilter
              form={form}
              defaultValues={{
                dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')]
              }}
              noUrl={true}
              onSubmit={onSubmitFilter}
              // outsidestyle={'flex flex-wrap items-end gap-2'}
              styleforbuttons={'flex items-center justify-end'}
              style={
                'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
              }
              buttons={
                <CustomButton
                  onClick={selfAttendance}
                  text="Self"
                  backgroundColor="#1890ff"
                  // marginTop="1.7rem"
                />
              }>
              <UserSearchV2 required hasParentFormItem={false} name={'userId'} />
            </TableFilter>

            {/* <Link to="/acl/new">Add</Link> */}
          </div>
          {/* </div> */}
        </>
      }>
      <CustomizeTable
        columns={columns}
        data={attendanceList}
        usersLoading={isloading}
        notshowPagination={true}
        tableName={'attendance-list'}
      />
    </AppContent>
  );
};

const columns: ColumnsType<any> = [
  {
    title: 'S.N',
    key: 'SN',
    width: 20,
    render: (text, record, index) => {
      return <TableCell>{index + 1}</TableCell>;
    }
  },
  {
    title: 'ID',
    key: 'id',
    width: 50,
    render: (record) => {
      return (
        // <Link to={`/acl/${record.id}`} color="black">
        <TableCell> {record.id}</TableCell>
        // </Link>
      );
    }
  },
  {
    title: 'Location Name',
    key: 'locationName',
    width: 100,
    render: (record) => {
      return (
        // <Link to={`/acl/${record.id}`} color="black">
        <TableCell>{record.locationName}</TableCell>
        // </Link>
      );
    }
  },
  {
    title: 'Location',
    key: 'checkinLocation',
    width: 100,
    render: (record) => {
      return (
        // <Link to={`/acl/${record.id}`} color="black">
        <TableCell>{record.checkinLocation}</TableCell>
        // </Link>
      );
    }
  },
  {
    title: 'Check-in Time',
    key: 'checkinTime',
    width: 100,
    render: (record) => {
      return <TableCell>{moment(record.checkinTime).format(DEFAULT_DATE_FORMAT)}</TableCell>;
    }
  },
  {
    title: 'Check-out Time',
    key: 'checkinTime',
    width: 100,
    render: (record) => {
      return (
        <TableCell>
          {record.checkoutTime
            ? moment(record.checkoutTime).format(DEFAULT_DATE_FORMAT)
            : 'not checked out'}
        </TableCell>
      );
    }
  }
];
export default AttendanceList;
