import { useReactToPrint } from 'react-to-print';
import { CustomModal } from '../../../../components/Common/CustomModal';
import CustomTable from '../../../../components/Common/CustomResuableInvoice/CustomTable';
import { useContext, useRef, useState } from 'react';
import { SellReturnListContext } from './context';
import { ISellReturnResponse } from '../../../../services/sell/types';
import ActionDropdown from '../../../../components/Common/Dropdownactions';
import { Button, Menu, Tooltip, message } from 'antd';
import { ExportColumnType, exportExcel } from '../../../../utils/exportExcel';
import { convertUTCStringtoLocalString } from '../../../../utils/convertToUTC';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const columsforPrint = [
  {
    label: 'Sell ID',
    dataIndex: 'sellId',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Customer',
    dataIndex: 'userName',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Financial Reference',
    dataIndex: 'financialReference',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Debit Reference',
    dataIndex: 'debitReference',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Location',
    dataIndex: 'locationName',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Return Amount(NPR)',
    dataIndex: 'totalAmount',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Date',
    dataIndex: 'date',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];
export const TableExportModal: React.FC<{
  allSellReturnList: ISellReturnResponse;
}> = ({ allSellReturnList }) => {
  const { setIsLoading } = useContext(SellReturnListContext);
  const printPDFRef = useRef<any>();

  const [openmodalforExport, setOpenModalforexport] = useState<boolean>(false);

  const handleExport = () => {
    setIsLoading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'S.N',
          dataIndex: 'SN',
          width: 50
        },
        {
          title: 'ID',
          dataIndex: 'id',
          width: 50
        },
        {
          title: 'Customer',
          width: 250,
          dataIndex: 'userName'
        },
        {
          title: 'Financial Reference',
          width: 200,
          dataIndex: 'financialReference'
        },
        {
          title: 'Debit Reference',
          width: 200,
          dataIndex: 'denitReference'
        },
        {
          title: 'Location',
          width: 200,
          dataIndex: 'locationName'
        },
        {
          title: 'Return Amount',
          width: 150,
          dataIndex: 'totalAmount'
        },
        {
          title: 'Date',
          width: 200,
          dataIndex: 'date'
        }
      ];
      if (allSellReturnList.results.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = allSellReturnList.results.map((item: any, index: number) => {
        return {
          ...item,
          SN: index + 1,
          date: convertUTCStringtoLocalString(item.date, DEFAULT_DATE_FORMAT)
        };
      });
      exportExcel(columns, dataUpdated, 'Sell Return List');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });
  return (
    <>
      <div className="flex justify-end mb-2">
        <div>
          <ActionDropdown
            button={true}
            menu={
              <Menu
                items={[
                  {
                    key: '1',
                    label: (
                      <Tooltip title="Export Excel" color="blue">
                        <div className="text-center">Excel</div>
                      </Tooltip>
                    ),
                    onClick: () => {
                      handleExport();
                    }
                  },
                  {
                    key: '2',
                    label: (
                      <Tooltip title="Export PDF" color="blue">
                        <div className="text-center">PDF</div>
                      </Tooltip>
                    ),
                    onClick: () => {
                      setOpenModalforexport(true);
                    }
                  }
                ]}
              />
            }
          />
        </div>
      </div>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title="Sell View For PDF print">
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={allSellReturnList.results}
            reff={printPDFRef}
            title={'Sell Return List'}
          />
          <div className="flex justify-end mt-3">
            <Button type="primary" onClick={handlePDFExport}>
              Print Pdf
            </Button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};
