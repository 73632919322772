import { useQuery } from '@tanstack/react-query';
import React, { useState, useEffect } from 'react';
import { LoadScript, GoogleMap, MarkerF, Polyline, Marker, InfoBox } from '@react-google-maps/api';
import { useParams } from 'react-router-dom';
import AppContent from '../../../components/Common/Content/Content';
import { get_vehicles_track } from '../../../services/vehicle/queries';
import { IVehicleLiveData } from '../../../services/vehicle/types';
import { CarFilled } from '@ant-design/icons';
import DrivingMode from './icons/DrivingMode.svg';
import IdleMode from './icons/IdleMode.svg';
import OverSpeed from './icons/OverSpeed.svg';
import ParkingMode from './icons/ParkingMode.svg';
// import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';
const VehicleLive = () => {
  const { id } = useParams();
  const google = window.google;
  //   const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayInfo, setDisplayInfo] = useState<boolean>(false);
  const [data, setData] = useState<IVehicleLiveData>();
  const { refetch } = useQuery(['vehicle-live'], async () => {
    const response = await get_vehicles_track(id as string);
    setData(response.data as IVehicleLiveData);
    // setIsLoading(false);
    return response;
  });
  useEffect(() => {
    const interval = setInterval(() => refetch(), 20000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <AppContent breadcrumbItems={breadcrumbItems}>
      <LoadScript googleMapsApiKey={'AIzaSyATJbgY-H04JRS-GO4GaKRHPPDHS0p2ENA'}>
        {data && window.google && (
          <GoogleMap
            center={{
              lat: parseFloat(data.Details[0].Latitude),
              lng: parseFloat(data.Details[0].longitude)
            }}
            zoom={13}
            options={{
              fullscreenControl: false,
              streetViewControl: false,
              mapTypeControl: false
            }}
            // onClick={onClick}
            mapContainerStyle={containerStyle}>
            <Marker
              position={{
                lat: parseFloat(data.Details[0].Latitude),
                lng: parseFloat(data.Details[0].longitude)
              }}
              icon={{
                // url: `https://freshktm.github.io/ERP-UI/${data.Details[0].DeviceStatus}.svg`,
                url: `https://freshktm.github.io/ERP-UI/${data.Details[0].DeviceStatus}.svg`,
                // anchor: new google.maps.Point(17, 46),
                labelOrigin: new google.maps.Point(17, -10),
                scaledSize: new google.maps.Size(37, 37)
              }}
              onClick={() => setDisplayInfo(!displayInfo)}>
              {displayInfo && (
                <InfoBox
                  onCloseClick={() => {
                    console.log('clicked');
                  }}
                  options={{
                    closeBoxURL: ``,
                    enableEventPropagation: true,
                    position: new google.maps.LatLng({
                      lat: parseFloat(data.Details[0].Latitude),
                      lng: parseFloat(data.Details[0].longitude)
                    }),
                    pixelOffset: new google.maps.Size(-100, -150)
                  }}>
                  <div
                    style={{
                      backgroundColor: `white`,
                      opacity: 0.9,
                      padding: `12px`,
                      width: 200,
                      height: 100,
                      overflowY: 'scroll'
                    }}>
                    <div style={{ fontSize: `16px`, color: `#08233B` }}>
                      Number: {`${data.Details[0].VehicleNo}`}
                    </div>
                    <div style={{ fontSize: `16px`, color: `#08233B` }}>
                      Status: {`${data.Details[0].DeviceStatus}`}
                    </div>
                    {/* <div style={{ fontSize: `16px`, color: `#08233B` }}>
                    Number: {`${data.Details[0].VehicleNo}`}
                  </div> */}
                  </div>
                </InfoBox>
              )}
            </Marker>
          </GoogleMap>
        )}
      </LoadScript>
    </AppContent>
  );
};
const containerStyle = {
  // width: '100%',
  height: '80vh'
  // marginBottom: '20px'
};
const breadcrumbItems = [
  {
    label: 'Vehicle',
    link: '/vehicle'
  },
  {
    label: 'Live',
    link: '/vehicle/live'
  }
];

export default VehicleLive;
