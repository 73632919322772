import AgentsSearch from '@/components/Common/AgentsSearch';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { UsersSearch } from '@/components/Common/UsersSearch';
import { VehiclesSearch } from '@/components/Common/VehiclesSearch';
import { VendorSearch } from '@/components/Common/VendorSearch/VendorSearch';
import { CustomerSearch } from '@/components/Common/CustomerSearch/CustomerSearch';
import ExpenseSearch from '@/components/Common/ExpenseSearch';

interface Props {
  id: string;
  onSelect?: (value: any) => void;
}

function RenderFilterSelect({ id, onSelect }: Props) {
  if (id === 'locationId') {
    return <LocationSearch onSelect={onSelect} />;
  }

  if (id === 'agentId') {
    return <AgentsSearch hasParentFormItem={false} onSelect={onSelect} returnValue="agentId" />;
  }

  if (id === 'userId') {
    return (
      <UsersSearch formData={{ formName: id, label: 'Select User' }} onSelect={onSelect} isAll />
    );
  }

  if (id === 'vehicleId') {
    return (
      <VehiclesSearch onSelect={onSelect} formData={{ formName: id, label: 'Select Vehicle' }} />
    );
  }

  if (id === 'expenseId') {
    return (
      <ExpenseSearch
        showAll
        onSelect={onSelect}
        formName="expenseId"
        label="Select Expense"
        hasParentFormItem={false}
      />
    );
  }

  if (id === 'customerId') {
    return (
      <CustomerSearch
        isForm
        notRequired
        onSelect={onSelect}
        all
        formData={{ formName: id, label: 'Select Customer' }}
      />
    );
  }

  if (id === 'vendorId') {
    return <VendorSearch all onSelect={onSelect} />;
  }

  return <div></div>;
}

export default RenderFilterSelect;
