import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { ISelfAttendanceResponse } from './types';

export const get_self_attendance = async () => {
  const response: AxiosResponse<ISelfAttendanceResponse[], any> = await http.get('attendance/me');
  return response;
};

export const get_attendance_by_user = async (userId: string | number) => {
  const response: AxiosResponse<ISelfAttendanceResponse[], any> = await http.get(
    'attendance/attendance/' + userId
  );
  return response;
};
