import jwtDecode from 'jwt-decode';
import { USER_INFO, JWT_TOKEN } from '../constants/config';
import LocalStore from '../store/localstorage';
import {
  getLocalStorage,
  setLocalStorage,
  clearLocalStorage,
  clearLocalSession
} from './storage.utils';

export const getUserDetailsFromToken = (token: string) => {
  try {
    const decoded = jwtDecode(token);
    if (decoded) {
      setLocalStorage(USER_INFO, decoded);
      return decoded;
    }
  } catch (e) {
    return false;
  }
};

export const getToken = () => {
  if (getLocalStorage(JWT_TOKEN)) {
    return getLocalStorage(JWT_TOKEN);
  }
};

export const isAuthenticated = () => {
  return !!getToken();
  // return true;
};

export const getUserData = () => {
  const userData = getLocalStorage(USER_INFO);
  if (!userData || !userData.perms) {
    if (!window.location.pathname.includes('#/login')) window.location.href = '#/login';
  } else {
    return userData;
  }
};

export const logout = async () => {
  await LocalStore.delete();
  clearLocalStorage(JWT_TOKEN);
  clearLocalStorage(USER_INFO);
  clearLocalSession(JWT_TOKEN);
  clearLocalSession(USER_INFO);
};

export const globalSignOut = () => {
  const channel = new BroadcastChannel('auth-channel');
  channel.postMessage({ action: 'signout' });
  channel.close();
};
