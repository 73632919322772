import { WithReportPaginationProps } from '@/components/Common/Report/withReportPagination';
import { IProductRateReport } from '@/services/report/types';
import { ExtendedInitialValues } from '.';
import { useState } from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import { TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { excelExportColumns, getUpdatedData, tableExportColumns } from './columns.export';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportAllData from '@/components/Common/ExportAll';
import ExportCurrent from '@/components/Common/ExportCurrent';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

interface Props
  extends Pick<WithReportPaginationProps, 'onPagination' | 'onChange' | 'pagination'> {
  data: { total: number; data: IProductRateReport[] };
  onSearch: (values: ExtendedInitialValues) => void;
  onSearchAll: () => Promise<IProductRateReport[]>;
}

function TableData({ data, pagination, ...handlers }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const columns: ColumnsType<IProductRateReport> = [
    {
      title: 'SN',
      key: 'SN',
      width: 30,
      render: (text, record, index) => {
        return <TableCell>{(pagination.page - 1) * pagination.size + (index + 1)}</TableCell>;
      }
    },

    {
      title: 'Product',
      dataIndex: 'Product',
      key: 'Product',
      width: 120,
      sorter: (a, b) => a.Product.localeCompare(b.Product),
      sortOrder: sortedInfo.columnKey === 'Product' ? sortedInfo.order : null
    },
    {
      title: 'Unit',
      dataIndex: 'Unit',
      key: 'Unit',
      width: 60,
      sorter: (a, b) => a.Unit.localeCompare(b.Unit),
      sortOrder: sortedInfo.columnKey === 'Unit' ? sortedInfo.order : null
    },

    {
      title: 'Location',
      dataIndex: 'Location',
      key: 'Location',
      width: 80,
      sorter: (a, b) => a.Location.localeCompare(b.Location),
      sortOrder: sortedInfo.columnKey === 'Location' ? sortedInfo.order : null
    },
    {
      title: 'MIN PRICE',
      key: 'MIN_PRICE',
      width: 80,
      sorter: (a, b) => {
        const aValue = a['MIN PRICE'] || '';
        const bValue = b['MIN PRICE'] || '';
        return aValue.localeCompare(bValue);
      },
      sortOrder: sortedInfo.columnKey === 'MIN_PRICE' ? sortedInfo.order : null,
      render: (record: IProductRateReport) => (
        <TableCell>{nepaliNumberFormatter(parseFloat(record['MIN PRICE']))}</TableCell>
      )
    },
    {
      title: 'AVG PRICE',
      key: 'AVG_PRICE',
      width: 80,
      sorter: (a, b) => parseFloat(a['AVG PRICE']) - parseFloat(b['AVG PRICE']),
      sortOrder: sortedInfo.columnKey === 'AVG_PRICE' ? sortedInfo.order : null,
      render: (record: IProductRateReport) => (
        <TableCell>{nepaliNumberFormatter(parseFloat(record['AVG PRICE']))}</TableCell>
      )
    },
    {
      title: 'MAX PRICE',
      key: 'MAX_PRICE',
      width: 80,
      sorter: (a, b) => {
        const aValue = a['MAX PRICE'] || '';
        const bValue = b['MAX PRICE'] || '';
        return aValue.localeCompare(bValue);
      },
      sortOrder: sortedInfo.columnKey === 'MAX_PRICE' ? sortedInfo.order : null,
      render: (record: IProductRateReport) => (
        <TableCell>{nepaliNumberFormatter(parseFloat(record['MAX PRICE']))}</TableCell>
      )
    }
  ];

  const updatedData = getUpdatedData(data.data);

  return (
    <div>
      <CustomizeTable
        columns={columns}
        data={data.data}
        notshowPagination={true}
        toSort={handleChange}
        customScroll={{ x: 1300, y: '75vh' }}
        buttons={
          <>
            <ExportAllData
              title="Product Rate Report (All)"
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              getInfo={handlers.onSearchAll}
            />

            <ExportCurrent
              data={{ total: data.total, results: updatedData }}
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              title="Product Rate Sell Report"
            />
          </>
        }
        paginationDatas={{
          page: pagination.page,
          total: data.total,
          size: pagination.size,
          onPagination: (pageNo, currentSize) => {
            currentSize == pagination.size
              ? handlers.onPagination(handlers.onSearch, pageNo)
              : handlers.onPagination(handlers.onSearch, pageNo, currentSize);
          }
        }}
      />
    </div>
  );
}

export default TableData;
