import { useEffect, useState } from 'react';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { get_units_list } from '@/services/products/queries';
import { Form, Select } from 'antd';
import { IUnits } from '@/services/products/types';

type Props = {
  showAll?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
  placeHolder?: string;
  showAllWithDefaultUnit?: boolean;
  notFoundContent?: React.ReactNode;
  defaultActiveFirstOption?: boolean;
  selectedProductUnitIds?: Array<IProductUnitId>;
  dropdownMatchSelectWidth?: boolean;
  setSelected?: (selected?: IUnit) => void;
  onSelect?: (value: string | number) => void;
} & (
  | {
      hasParentFormItem: true;
    }
  | {
      hasParentFormItem: false;
      name: any;
      label?: string;
      required?: boolean;
    }
);

interface IUnit {
  id: number | string;
  name: string;
  shortName?: string;
}

export interface IProductUnitId {
  id: number | 'default';
  isDefault?: boolean;
}

function UnitSearch({
  showAll = false,
  allowClear = true,
  placeHolder = 'Search for unit',
  notFoundContent = <div>No Content</div>,
  dropdownMatchSelectWidth = false,
  defaultActiveFirstOption = false,
  showAllWithDefaultUnit = false,
  selectedProductUnitIds = [],
  onSelect,
  ...props
}: Props) {
  const [units, setUnits] = useState<IUnit[]>([]);

  function handleChange(id: any) {
    onSelect?.(id);

    const selectedData = units.find((current) => {
      return current.id === id;
    });

    if (selectedData) props.setSelected?.(selectedData);
  }

  useEffect(() => {
    (async () => {
      let units = (await UnitsDB.getAllUnits()) as IUnits[];
      if (!units || units.length === 0) {
        const serverUnits = await get_units_list();
        await UnitsDB.addUnits(serverUnits);
        units = (await UnitsDB.getAllUnits()) as IUnits[];
      }

      const updatedUnits = units
        .map((unit) => {
          return { id: unit.id, name: unit.name, shortName: unit.shortName } as IUnit;
        })
        .filter((unit) => {
          if (selectedProductUnitIds.length === 0) return true;
          const allIds = selectedProductUnitIds.map((value) => value.id);
          return allIds.includes(Number(unit.id)) || unit.id === '';
        });

      if (showAllWithDefaultUnit) {
        const defaultUnit = selectedProductUnitIds.find((unit) => unit.isDefault);
        const defaultUnitName = updatedUnits.find((unit) => unit.id === defaultUnit?.id)?.name;

        const labelName = `All with default unit ${defaultUnitName ? `(${defaultUnitName})` : ''}`;
        updatedUnits.unshift({ id: 'default', name: labelName });
      }

      if (showAll) {
        updatedUnits.unshift({ id: '', name: 'All' });
      }

      setUnits(updatedUnits);
    })();
  }, [selectedProductUnitIds]);

  const selectMenu = (
    <Select
      showSearch
      disabled={props.disabled}
      allowClear={allowClear}
      optionFilterProp="label"
      notFoundContent={notFoundContent}
      onChange={handleChange}
      defaultActiveFirstOption={defaultActiveFirstOption}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      className="w-full drawer-select"
      placeholder={placeHolder}
      options={units.map((unit) => ({
        value: unit.id,
        label: `${unit.name} ${unit?.shortName ? `(${unit.shortName})` : ''}`
      }))}
    />
  );

  if (props.hasParentFormItem) return selectMenu;
  return (
    <Form.Item
      label={props.label || 'Select unit'}
      name={props.name}
      rules={[{ required: props.required || false, message: 'Please select unit' }]}>
      {selectMenu}
    </Form.Item>
  );
}

export default UnitSearch;
