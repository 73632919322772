import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { ICreateDailyRate, IDailyRateData, IUpdateDailyRate } from './types';

export const create_daily_rate_mutation = async (values: ICreateDailyRate) => {
  const response: AxiosResponse<IDailyRateData, any> = await http.store('daily-rate', values);
  return response;
};

export const update_daily_rate_mutation = async (values: IUpdateDailyRate) => {
  const response: AxiosResponse<IDailyRateData, any> = await http.update(
    `daily-rate/${values.id}`,
    values
  );
  return response;
};
