import { Button, Collapse, Form, FormInstance, PageHeader, Pagination, Spin, message } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import AppContent from '@/components/Common/Content/Content';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { download_report, get_report } from '@/services/report/queries';
import { IPurchaseSellSummation } from '.';
import { check_date_cap, check_export_report_date_cap } from '@/utils/common';
import { showReportConditionalDownloadMessage } from '@/utils/reportConditionalDownloadMessage';
import { excelExportColumns, getUpdatedData, tableExportColumns } from './column.export';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportAllData from '@/components/Common/ExportAll';
import ExportCurrent from '@/components/Common/ExportCurrent';

interface State {
  id: number;
  name: string;
}

interface ReportProps {
  state: State;
  breadcrumbItems: { label: string; link: string }[];
  form: FormInstance<any>;
  columnsData?: any[];
  toDownload?: boolean;
  toSort: any;
}

const CustomReport: React.FC<ReportProps> = ({
  state,
  breadcrumbItems,
  form,
  columnsData,
  children,
  toDownload,
  toSort
}) => {
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState(100);
  const [isloading, setIsloading] = useState(false);
  const [data, setData] = useState({ count: '0', data: [] as IPurchaseSellSummation[] });
  const [columns, setColumns] = useState<any[]>([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const [fullExportURL, setFullExportURL] = useState<any>(null);

  useEffect(() => {
    if (!columnsData) createColumns();
  }, [data]);

  const createColumns = () => {
    //auto generate columns
    const newColumns: ColumnsType<any> = [];
    for (const key in data) {
      newColumns.push({
        title: key,
        key,
        render: (record: any) => {
          return <div className="forless">{record[key]}</div>;
        }
      });
    }
    setColumns(newColumns);
  };

  const paymentType = state.id === 49 ? 'Sale' : 'Purchase';

  const addPage = async (value: number, sizeVal = 0) => {
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();
      if (sizeVal !== 0) {
        allValues.constraints.page = 1;
        allValues.constraints.size = sizeVal;
        setPage(1);
        setSize(sizeVal);
      } else {
        allValues.constraints.page = value;
        allValues.constraints.size = size;
        setPage(value);
      }
      onSearch(allValues, true);
    } catch (e: any) {
      console.log('validate error', e);
    }
  };

  const onSearch = async (values: any, storePage = false) => {
    setIsloading(true);
    if (check_date_cap(values.constraints.startDate, values.constraints.endDate)) {
      await downloadPdf();
      setIsloading(false);
      return;
    }
    if (!storePage) {
      values.constraints['page'] = 1;
      values.constraints['size'] = size;
      setPage(1);
    }
    //remove custom date
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
      delete values.startDateNepali;
      delete values.endDateNepali;
    }

    if ('createdDateRange' in values.constraints) {
      delete values.constraints.createdDateRange;
      delete values.sellStartDateNepali;
      delete values.sellEndDateNepali;
    }

    const { createdEndDate, createdStartDate } = values.constraints;

    if (state.id === 49) {
      values.constraints.sellStartDate = convertLocalToUTCString(createdStartDate);
      values.constraints.sellEndDate = convertLocalToUTCString(createdEndDate);
    }

    if (state.id === 50) {
      values.constraints.purchaseStartDate = convertLocalToUTCString(createdStartDate);
      values.constraints.purchaseEndDate = convertLocalToUTCString(createdEndDate);
    }

    delete values.constraints.createdStartDate;
    delete values.constraints.createdEndDate;

    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    const response = await get_report(values.constraints, state.id);
    if (response.data.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }

    const payload = values.constraints as any;
    payload.size = response.data.count;
    setFullExportURL(payload);
    setData(response.data);
    setIsloading(false);
  };

  const downloadPdf = async () => {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsloading(true);
    const values = form.getFieldsValue();

    try {
      await form.validateFields();
      values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
      values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);

      const { createdEndDate, createdStartDate } = values.constraints;

      if (state.id === 49) {
        values.constraints.sellStartDate = convertLocalToUTCString(createdStartDate);
        values.constraints.sellEndDate = convertLocalToUTCString(createdEndDate);
      }

      if (state.id === 50) {
        values.constraints.purchaseStartDate = convertLocalToUTCString(createdStartDate);
        values.constraints.purchaseEndDate = convertLocalToUTCString(createdEndDate);
      }

      delete values.constraints.createdStartDate;
      delete values.constraints.createdEndDate;

      if ('dateCustom' in values.constraints) {
        delete values.constraints.dateCustom;
        delete values.sellStartDateNepali;
        delete values.sellEndDateNepali;
      }

      if ('createdDateRange' in values.constraints) {
        delete values.constraints.createdDateRange;
      }
      if (check_export_report_date_cap(values.constraints.startDate, values.constraints.endDate)) {
        // const response =
        values.constraints.customReportQueueName = 'Sell Purchase Summation Report';
        message.info({
          key: 'downloading',
          content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
        });
        await download_report(values.constraints, state.id);
        setIsloading(false);
        // window.open(response.data, '_blank');
      }
    } catch (err: any) {
      setIsloading(false);
      message.error(err.message);
    }
  };

  async function onSearchAll() {
    try {
      setIsloading(true);
      if (!fullExportURL) {
        message.error('No URL specified. Please search first.');
        return;
      }
      const response = await get_report(fullExportURL, state.id);
      return getUpdatedData(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  }

  const updatedData = getUpdatedData(data.data);

  return (
    <Spin spinning={isloading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Collapse>
          <CollapsePanel header="Filters" key="1">
            <Form
              form={form}
              onFinish={onSearch}
              layout="vertical"
              validateTrigger={'onChange'}
              autoComplete="off"
              disabled={isloading}>
              {React.Children.count(children) > 1 && (
                <>
                  <PageHeader
                    subTitle="Constraints"
                    style={{
                      padding: '0px 0px'
                    }}
                  />
                </>
              )}
              <div className="grid md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-3">
                {children}
                <div className="flex justify-start mt-7 gap-2">
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
                      Search
                    </Button>
                  </Form.Item>
                  {toDownload ? (
                    <div>
                      <Form.Item>
                        <Button
                          className="secondary-button"
                          style={{ borderRadius: '6px' }}
                          onClick={downloadPdf}>
                          Download
                        </Button>
                      </Form.Item>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Form>
          </CollapsePanel>
        </Collapse>
        <PageHeader
          subTitle="Table"
          style={{
            padding: '8px 0px'
          }}
        />
        <CustomizeTable
          tableName="purchase-sell-summation-report"
          customScroll={{ x: 1000, y: 500 }}
          notshowPagination
          columns={columnsData ? columnsData : columns}
          data={data.data}
          toSort={toSort}
          buttons={
            <>
              <ExportAllData
                title={`${paymentType} Summation Report (All)`}
                columns={tableExportColumns}
                excelColumns={excelExportColumns}
                getInfo={onSearchAll}
              />
              <ExportCurrent
                data={{ total: Number(data.count), results: updatedData }}
                columns={tableExportColumns}
                excelColumns={excelExportColumns}
                title={`${paymentType} Summation Report`}
              />
            </>
          }
          paginationDatas={{
            page,
            total: Number(data.count),
            size,
            onPagination: (pageNo, currentSize) => {
              currentSize == size ? addPage(pageNo) : addPage(pageNo, currentSize);
            }
          }}
        />
      </AppContent>
    </Spin>
  );
};

export default CustomReport;
