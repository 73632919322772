import { useQuery, useMutation } from '@tanstack/react-query';
import { Form, Input, Button, Select, message, PageHeader, Card, Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AppContent from '@/components/Common/Content/Content';
import { ACLGroup, IEditACLGroup } from '@/services/acl/types';
import { update_acl_group_mutation } from '@/services/acl/mutations';
import { get_acl_group_details, get_all_acl_permissions } from '@/services/acl/queries';
import ReuseChannel from '../../channel/Reuse';
import GoBackButton from '@/components/Common/GoBackButton/GoBackButton';
import { AxiosResponse, AxiosError } from 'axios';
import { IServerError } from '@/services/response/types';

const { Option } = Select;

const EditACL = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<IEditACLGroup>();
  const { id } = useParams();
  const [isloading, setIsloading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [aclData, setAclData] = useState<IEditACLGroup>();

  useQuery(['acl-perm-list'], async () => {
    try {
      const response = await get_acl_group_details(parseInt(id as string));
      form.setFieldsValue(response);
      if (response.permissions[0] == 'ADMIN') setDisabled(true);
      const aclPermList = await get_all_acl_permissions();
      response.aclPermList = aclPermList.data;
      setAclData(response);
      setIsloading(false);
      return response;
    } catch (error) {
      message.error('Unable to load data');
    }
  });

  const breadcrumbItems = [{ label: 'ACL', link: '/acl' }, { label: 'Edit' }];

  const editACLMutation = useMutation<
    AxiosResponse<ACLGroup>,
    AxiosError<IServerError>,
    IEditACLGroup
  >(update_acl_group_mutation);

  const onFinish = async (values: IEditACLGroup) => {
    values.id = parseInt(id as string);
    try {
      setIsloading(true);
      if (disabled) {
        values = { ...values, permissions: ['ADMIN'] };
      }
      await editACLMutation.mutateAsync(values, {
        onSuccess: async (data) => {
          setIsloading(false);
          message.success('ACL group edited successfully');
          navigate('/acl');
        },
        onError: (error) => {
          setIsloading(false);
          message.error(`${error.response?.data.message}`);
        }
      });
    } catch (errors) {
      setIsloading(false);
    }
  };
  useEffect(() => {
    form.setFieldsValue({ name: '', permissions: [] });
  }, []);
  const findIndex = (name: string) => {
    const value: string[] = form.getFieldValue(['permissions']);
    const index = value.findIndex((value) => value == name);
    return index;
  };
  const initialChecked = (name: string): boolean => {
    const value: string[] = form.getFieldValue(['permissions']);
    if (value) {
      const check = value.find((value) => value == name);
      if (check) return true;
    }
    return false;
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        backgroundWhite={true}
        button={<GoBackButton onClick={() => navigate('/acl')} />}>
        <Card style={{ borderRadius: '10px' }}>
          <PageHeader
            title="ACL Informations"
            style={{
              padding: '8px 0px'
            }}
          />
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            disabled={isloading}
            validateTrigger={'onChange'}
            autoComplete="off">
            <Form.Item
              name="name"
              label="ACL Title"
              rules={[
                {
                  required: true,
                  message: 'Please add a name!'
                }
              ]}>
              <Input style={{ borderRadius: '9px' }} />
            </Form.Item>
            <Form.List name={['permissions']}>
              {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
                <>
                  <div className={'grid mb-5'}>
                    <PageHeader
                      subTitle="Select Permissions"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                    <div
                      className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
                      style={{
                        borderRadius: '39px',
                        padding: '10px',
                        backgroundColor: 'rgb(59 130 246 / 0.5)'
                      }}>
                      {aclData?.aclPermList && (
                        <>
                          {Object.keys(aclData.aclPermList).map((key, index) => (
                            <div style={{ display: 'flex', gap: '5px' }} key={key}>
                              <Form.Item>
                                <Checkbox
                                  disabled={
                                    `${aclData.aclPermList[key]}` == 'ADMIN' ? false : disabled
                                  }
                                  defaultChecked={initialChecked(aclData.aclPermList[key])}
                                  onChange={(value) => {
                                    const checkedValue = value.target.checked;
                                    if (checkedValue) {
                                      if (`${aclData.aclPermList[key]}` == 'ADMIN') {
                                        setDisabled(true);
                                      }
                                      add2(`${aclData.aclPermList[key]}`);
                                    } else {
                                      if (`${aclData.aclPermList[key]}` == 'ADMIN') {
                                        setDisabled(false);
                                      }
                                      remove2(findIndex(aclData.aclPermList[key]));
                                    }
                                  }}>
                                  {`${aclData.aclPermList[key]}` +
                                    (aclData.aclPermList[key] == 'ADMIN' ? '(ALL)' : '')}
                                </Checkbox>
                              </Form.Item>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Form.List>
            <div className="flex justify-end mt-5">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isloading}
                  style={{
                    borderRadius: '5px',
                    color: 'white',
                    backgroundColor: '#1890ff'
                  }}>
                  Update
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Card>
      </AppContent>
      <ReuseChannel slug={`acl__${id}`} />
    </div>
  );
};

export default EditACL;
