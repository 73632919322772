import moment from 'moment';

function getDateRange(startDate: string, endDate: string): moment.Moment[] {
  const start = moment(startDate);
  const end = moment(endDate);
  const dateArray: moment.Moment[] = [];

  while (start <= end) {
    dateArray.push(start.clone());
    start.add(1, 'days');
  }

  return dateArray;
}

export function getAlignDateRanges(
  currentRange: [string, string],
  previousRange: [string, string]
) {
  const currentDates = getDateRange(currentRange[0], currentRange[1]);
  const previousDates = getDateRange(previousRange[0], previousRange[1]);

  if (currentDates.length !== previousDates.length) {
    throw new Error('Date ranges must have the same length');
  }

  return currentDates.map((currentDate, index) => {
    return {
      current: currentDate.format('YYYY-MM-DD'),
      previous: previousDates[index].format('YYYY-MM-DD')
    };
  });
}
