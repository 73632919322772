import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IRoutesList, IRoutesListData } from './types';
export const get_routes_list = async (skip = 0, count = 0, value = '') => {
  const response: AxiosResponse<IRoutesListData> = await http.get('routes', {
    skip,
    count,
    value
  });
  return response;
};

export const get_route_list_only = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IRoutesListData> = await http.get('routes/?' + filter, {
    skip,
    count,
    value
  });
  return response.data.results;
};

export const get_routes_list_filter = async (filter = '') => {
  const response: AxiosResponse<IRoutesListData> = await http.get('routes/?' + filter);
  return response;
};
export const get_routes_details = async (id: number) => {
  const response: AxiosResponse<IRoutesList> = await http.get('routes/' + id);
  return response.data;
};

export const get_unique_active_customers = async (startDate: string, endDate: string) => {
  const response: AxiosResponse<{ count: number }> = await http.get(
    'sell/unique-active-customers',
    {
      startDate,
      endDate
    }
  );
  return response.data;
};
