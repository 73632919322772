import { Button, PageHeader, Spin } from 'antd';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppContent from '../../../components/Common/Content/Content';
import { useQuery } from '@tanstack/react-query';
import { get_bank_details } from '../../../services/bank/queries';
import { IBankTableData } from '../../../services/bank/types';
import { convertUTCStringtoLocalString } from '../../../utils/convertToUTC';
import UsersDB from '../../../store/localstorage/UsersDB';
import { get_user_details } from '../../../services/users/queries';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const BankView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [bankData, setBankData] = useState<IBankTableData>(Object);

  const breadcrumbItems = [
    {
      label: 'Bank',
      link: '/bank'
    },
    {
      label: 'View'
    }
  ];

  useQuery(['bankDetails', id], async () => {
    await getData();
  });

  const getData = async () => {
    setIsLoading(true);
    const response = await get_bank_details(parseInt(id as string));
    // console.log('Response', response);
    if (response) {
      let userDetails = await UsersDB.getUser(response.createdBy);
      if (!userDetails) {
        const allUser = await get_user_details(response.createdBy);
        await UsersDB.addUsers([allUser.user]);
        userDetails = await UsersDB.getUser(response.createdBy);
      }
      const tableData: IBankTableData = { ...response, createdByName: userDetails.name };
      setBankData(tableData);
    }
    setIsLoading(false);
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          subTitle="Bank Information"
          style={{
            padding: '8px 0px'
          }}
        />
        <div className="grid grid-cols-2 gap-x-5">
          <div>
            Bank Name : <span className="font-bold">{bankData.name}</span>
          </div>
          <div>
            Address : <span className="font-bold">{bankData.address}</span>
          </div>
          <div>
            Account Name : <span className="font-bold">{bankData.accountName}</span>
          </div>
          <div>
            Account Number : <span className="font-bold">{bankData.accountNumber}</span>
          </div>
          <div>
            Created By : <span className="font-bold">{bankData.createdByName}</span>
          </div>
          <div>
            Created Date :{' '}
            <span className="font-bold">
              {convertUTCStringtoLocalString(bankData.createdAt, DEFAULT_DATE_FORMAT)}
            </span>
          </div>
        </div>
        <div className="flex justify-end items-center gap-5 mt-5">
          <Button type="default" onClick={() => navigate('/bank')}>
            Back
          </Button>
        </div>
      </AppContent>
    </Spin>
  );
};

export default BankView;
