import moment from 'moment';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { IClearLotDetails } from '@/services/products/types';
import { ColumnsType } from 'antd/lib/table';
import { get_created_by_opening_stock_by_ids } from '@/services/stock/queries';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_list_ids } from '@/services/users/queries';
import { useState } from 'react';
import { message, Modal, Tooltip } from 'antd';
import LotHistoryModal from '../../lots/view/LotHistoryModal';
import getErrorMessage from '@/utils/getError';

interface Props {
  pagination: { page: number; size: number };
  onPagination: (page: number, size: number, isSize?: boolean) => void;
  data: { total: number; results: IClearLotDetails[] };
}

function Table({ data, pagination, onPagination }: Props) {
  const [showHistoryDetails, setshowHistoryDetails] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const columns: ColumnsType<IClearLotDetails> = [
    {
      title: 'SN',
      key: 'lotid',
      width: 20,
      render: (_, __, index) => {
        return (pagination.page - 1) * pagination.size + index + 1;
      }
    },
    {
      title: 'Product',
      key: 'product',
      width: 100,
      render: (record: IClearLotDetails) => {
        return <TableCell>{record?.productName}</TableCell>;
      }
    },
    {
      title: 'Lot Number',
      key: 'lotNumber',
      width: 50,

      render: (record: IClearLotDetails) => {
        return <TableCell>{record.lotNumber}</TableCell>;
      }
    },

    {
      title: 'Grade',
      key: 'grade',
      width: 20,
      render: (record: IClearLotDetails) => {
        return <TableCell>{record.grade}</TableCell>;
      }
    },
    {
      title: 'Opening (qty.)',
      key: 'qtyOpeningName',
      width: 40,
      render: (record: IClearLotDetails) => {
        return <TableCell>{record.qtyOpeningName || record.qtyOpening}</TableCell>;
      }
    },
    {
      title: 'Closing (qty.)',
      key: 'qtyClosingName',
      width: 40,
      render: (record: IClearLotDetails) => {
        return <TableCell>{record.qtyClosingName || record.qtyClosing}</TableCell>;
      }
    },
    {
      title: 'Available (qty.)',
      key: 'qtyAvailableName',
      width: 40,
      render: (record: IClearLotDetails) => {
        return <TableCell>{record.qtyAvailableName || record.qtyAvailable}</TableCell>;
      }
    },
    {
      title: 'Sold (qty.)',
      width: 40,
      key: 'qtySoldName',
      render: (record: IClearLotDetails) => {
        return <TableCell>{record.qtySoldName || record.qtySold}</TableCell>;
      }
    },
    {
      title: 'Receieved (qty.)',
      width: 50,
      key: 'qtyReceivedName',
      render: (record: IClearLotDetails) => {
        return <TableCell>{record.qtyReceivedName || record.qtyReceived}</TableCell>;
      }
    },
    {
      title: 'Price Avg.',
      width: 40,
      key: 'purchasePriceAvg',
      render: (record) => {
        return <TableCell>{parseFloat(record.purchasePriceAvg).toFixed(2)}</TableCell>;
      }
    },
    {
      title: 'Expiry Date',
      width: 40,
      key: 'expiryDate',
      render: (record: IClearLotDetails) => {
        const expiryDate = record.expirationDate;
        const isExpired = moment(expiryDate).isBefore(moment());
        return (
          <TableCell className={isExpired ? 'text-red-500' : ''}>
            {record.expirationDate ? moment(record.expirationDate).format('YYYY-MM-DD') : 'N/A'}
          </TableCell>
        );
      }
    },

    {
      title: 'IsTraceable',
      key: 'traceable',
      width: 40,
      render: (record) => {
        const showHistory = async (record: IClearLotDetails) => {
          const histories = record.histories ? JSON.parse(record.histories) : [];
          const identifierId = histories.map((curr: any) => curr.identifierId);
          try {
            const response = await get_created_by_opening_stock_by_ids([
              ...new Set<number>(identifierId)
            ]);
            for (let i = 0; i < histories.length; i++) {
              const createdById = response.results[i].createdBy;
              let createdByName = (await UsersDB.getUser(createdById))?.name;
              if (!createdByName) {
                const allUsers = await get_user_list_ids([
                  ...new Set<number>(response.results.map((curr: any) => curr.createdBy))
                ]);
                await UsersDB.addUsers(allUsers.data.results);
                createdByName = (await UsersDB.getUser(createdById)).name;
              }
              histories[i].createdByName = createdByName;
            }
          } catch (error) {
            message.error(getErrorMessage(error));
          }
          record.histories = JSON.stringify(histories);
          setshowHistoryDetails(record);
          setIsModalOpen(true);
        };
        return (
          <TableCell data-boolean={record?.histories ? true : false}>
            {record?.histories ? (
              <>
                <span>
                  <Tooltip title="View" color="blue">
                    <div
                      style={{ color: 'blue', cursor: 'pointer' }}
                      onClick={() => {
                        showHistory(record);
                      }}>
                      View
                    </div>
                  </Tooltip>
                </span>
              </>
            ) : (
              <>
                <span>false</span>
              </>
            )}
          </TableCell>
        );
      }
    }
  ];
  return (
    <div>
      <Modal
        width={'60%'}
        title={`Lot History of ${showHistoryDetails?.lotNumber}`}
        visible={isModalOpen}
        footer={false}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}>
        {showHistoryDetails && <LotHistoryModal record={showHistoryDetails} />}
      </Modal>
      <CustomizeTable
        columns={columns}
        data={data.results}
        notshowPagination={true}
        customScroll={{ x: 1600, y: '75vh' }}
        paginationDatas={{
          page: pagination.page,
          total: data.total,
          size: pagination.size,
          onPagination
        }}
      />
    </div>
  );
}

export default Table;
