import AppContent from '@/components/Common/Content/Content';
import { Alert, Collapse, PageHeader, Spin, message } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { useState } from 'react';
import FilterForm from './Form';

import TableData from './Table';
import { check_date_cap, check_export_report_date_cap } from '@/utils/common';
import { download_report, get_report } from '@/services/report/queries';
import { IProductDailyIdentifierWiseTotalReport } from '@/services/report/types';

import withReportPagination, {
  PaginatedReportValues,
  WithReportPaginationProps
} from '@/components/Common/Report/withReportPagination';

import { showReportConditionalDownloadMessage } from '@/utils/reportConditionalDownloadMessage';
import { getUpdatedData } from './columns.export';
import { LineType } from '@/services/report/enums';
import getErrorMessage from '@/utils/getError';

export type ExtendedInitialValues = PaginatedReportValues & {
  constraints: {
    identifier: string;
    unitId: string | number;
    productId: string | number;
    locationId: string | number;
    customerId?: number;
    vendorId?: number;
  };
};

function ProductTotalByIdentifierReportBase({
  form,
  initialValues,
  pagination,
  ...handlers
}: WithReportPaginationProps) {
  const breadcrumbItems = [
    { label: 'Reports', link: '/reports' },
    {
      label: 'Product Total Qty and Amount by Identifier',
      link: '/reports/products-daily-identifier-wise-total'
    }
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [fullExportURL, setFullExportURL] = useState<ExtendedInitialValues['constraints']>();

  const [data, setData] = useState({
    total: 0,
    data: [] as IProductDailyIdentifierWiseTotalReport[]
  });

  const [currentIdentifier, setCurrentIdentifier] = useState<'sale' | 'purchase' | 'other'>(
    'other'
  );

  const state = { id: 67, name: 'Product Total Qty and Amount by Identifier' };
  const defaultUnitState = { id: 71, name: 'Product Total Qty and Amount by Identifier' };
  const saleState = { id: 76, name: 'Product Wise Sale Report' };
  const purchaseState = { id: 77, name: 'Product Wise Purchase Report' };

  const extendedInitialValues: ExtendedInitialValues = {
    ...initialValues,
    constraints: {
      ...initialValues.constraints,
      unitId: 'default',
      productId: '',
      locationId: '',
      identifier: ''
    }
  };

  async function onSearchAll() {
    try {
      setIsLoading(true);
      if (!fullExportURL) {
        message.error('No URL specified. Please search first.');
        return;
      }

      const { reportId, isDefaultUnitId, constraints } = getReportId(fullExportURL, true);
      const updatedConstraints = JSON.parse(JSON.stringify(constraints));
      if (isDefaultUnitId) updatedConstraints.unitId = '';

      const { data } = await get_report(updatedConstraints, reportId);
      return getUpdatedData(data.data) as any;
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  function getReportId(options: ExtendedInitialValues['constraints'], isExport?: boolean) {
    const constraints = JSON.parse(JSON.stringify(options));
    const identifier = constraints.identifier as LineType;
    const isDefaultUnitId = constraints.unitId === 'default';

    const isSaleIdentifier = identifier === LineType.SALE;
    const isPurchaseIdentifier = identifier === LineType.PURCHASE;

    let reportId = state.id;

    if (isDefaultUnitId) {
      reportId = isSaleIdentifier
        ? saleState.id
        : isPurchaseIdentifier
        ? purchaseState.id
        : defaultUnitState.id;

      if (isSaleIdentifier) {
        if (!isExport) setCurrentIdentifier('sale');
        constraints.vendorId = undefined;
      } else if (isPurchaseIdentifier) {
        if (!isExport) setCurrentIdentifier('purchase');
        constraints.customerId = undefined;
      } else {
        if (!isExport) setCurrentIdentifier('other');
        constraints.vendorId = undefined;
        constraints.customerId = undefined;
      }
    } else {
      if (!isExport) setCurrentIdentifier('other');
      constraints.customerId = undefined;
      constraints.vendorId = undefined;
    }

    return { reportId, constraints, isDefaultUnitId };
  }

  async function onSearch(values: ExtendedInitialValues) {
    setIsLoading(true);
    const { constraints } = values;

    try {
      const hasDateCap = check_date_cap(constraints.startDate, constraints.endDate);
      if (hasDateCap) {
        await onDownload();
        return;
      }

      const currentConstraints = (await handlers.getConstraint()) as any;

      const {
        reportId,
        constraints: newPayload,
        isDefaultUnitId
      } = getReportId(currentConstraints);

      const updatedConstraints = JSON.parse(JSON.stringify(newPayload));
      if (isDefaultUnitId) updatedConstraints.unitId = '';

      const { data } = await get_report(updatedConstraints, reportId);
      if (data.data.length === 0) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }

      // Get all constraints excluding page, size
      const payload = { ...newPayload };
      payload.size = data.count;

      setFullExportURL(payload);
      setData({ total: data.count, data: data.data });
      return data.data;
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function onDownload() {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsLoading(true);
    try {
      await form.validateFields();

      const constraints =
        (await handlers.getConstraint()) as unknown as ExtendedInitialValues['constraints'];
      if (!constraints) return;

      const hasDateCap = check_export_report_date_cap(constraints.startDate, constraints.endDate);

      if (!hasDateCap) return;
      const customReportQueueName = state.name;

      const { reportId, constraints: newPayload, isDefaultUnitId } = getReportId(constraints);
      const updatedConstraints = JSON.parse(JSON.stringify(newPayload));
      if (isDefaultUnitId) updatedConstraints.unitId = '';

      message.loading({
        key: 'downloading',
        content: `Waiting for ${customReportQueueName} to be downloaded`,
        duration: 0
      });

      await download_report({ ...updatedConstraints, customReportQueueName }, reportId);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Collapse>
          <CollapsePanel header="Filters" key="1">
            <FilterForm
              form={form}
              isLoading={isLoading}
              initialValues={extendedInitialValues}
              onSearch={onSearch}
              onDownload={onDownload}
              onChange={handlers.onChange}
              onDisabledDate={handlers.onDisabledDate}
              onChangeNepali={handlers.onChangeNepali}
            />
          </CollapsePanel>
        </Collapse>

        <Alert
          style={{ margin: '8px 0px' }}
          type="info"
          showIcon
          message='When the "All with Default Unit" option is selected, all product prices are based on the default unit of the selected products.'
        />

        <PageHeader subTitle="Table" style={{ padding: '8px 0px' }} />
        <TableData
          data={data}
          pagination={pagination}
          onSearch={onSearch}
          isSaleIdentifier={currentIdentifier === 'sale'}
          isPurchaseIdentifier={currentIdentifier === 'purchase'}
          onSearchAll={onSearchAll}
          onChange={handlers.onChange}
          onPagination={handlers.onPagination}
        />
      </AppContent>
    </Spin>
  );
}

const ProductTotalByIdentifierReport = withReportPagination(ProductTotalByIdentifierReportBase);
ProductTotalByIdentifierReport.displayName = 'ProductTotalByIdentifierReport';

export default ProductTotalByIdentifierReport;
