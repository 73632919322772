import { Form, InputNumber } from 'antd';
import { useEffect } from 'react';
import Report from '../..';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { DEFAULT_DATE_FORMAT } from '@/constants';

interface DataType {
  avatar_id: null;
  created_at: string;
  customerId: number;
  email: string;
  id: number;
  name: string;
  password: string;
  phone: string;
  updated_at: string;
  verified: number;
}

const CustomerReport = () => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldValue('constraints', {
      page: 1,
      size: 10
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Customer',
      link: '/reports/customer'
    }
  ];
  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: string) => <Link to={`/users/customers/${text}`}>{text}</Link>
    },
    {
      title: 'Name',
      key: 'name',
      render: (record) => <Link to={`/users/customers/${record.id}`}>{record.name}</Link>
    },
    // {
    //   title: 'Customer ID',
    //   key: 'customerId',
    //   render: (record) => <Link to={`/users/customers/${record.id}`}>{record.customerId}</Link>
    // },
    {
      title: 'Email',
      key: 'email',
      render: (record) => <Link to={`/users/customers/${record.id}`}>{record.email}</Link>
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Created Date',
      dataIndex: 'created_at',
      render: (text: string) => <div>{moment(text).local().format(DEFAULT_DATE_FORMAT)}</div>
    },
    {
      title: 'Updated Date',
      dataIndex: 'updated_at',
      render: (text: string) => <div>{moment(text).local().format(DEFAULT_DATE_FORMAT)}</div>
    }
  ];
  return (
    <Report
      breadcrumbItems={breadcrumbItems}
      columnsData={columns}
      state={{ id: 2, name: 'Customer Report' }}
      form={form}>
      <div className="grid grid-cols-3 gap-5 ">
        <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
          <InputNumber controls={false} />
        </Form.Item>
        <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
          <InputNumber controls={false} />
        </Form.Item>
      </div>
    </Report>
  );
};

export default CustomerReport;
