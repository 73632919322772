import moment from 'moment';
import { message } from 'antd';

import { USER_INFO } from '@/constants/config';
import { getUserData } from '@/utils/auth.utils';
import { IUserPreferences } from '../settings/types';
import { setLocalStorage } from '@/utils/storage.utils';
import { update_preference_user_mutation } from './mutations';

type IUserPreferenceKey = keyof IUserPreferences;

export function getUserPreferenceData<K extends IUserPreferenceKey>(key: K) {
  const userData = getUserData();
  const hasUserPreferences = 'preferences' in userData && userData.preferences;

  if (!hasUserPreferences) {
    throw {
      type: 'Error',
      message: 'Default Preference has not been set! Please set it in Settings/Preferences first.'
    };
  }

  const defaultLocalStoragePref = userData.preferences.preferences;
  const userPreferences: IUserPreferences = JSON.parse(defaultLocalStoragePref);
  if (!userPreferences) {
    throw {
      type: 'Error',
      message: 'Default Preference has not been set! Please set it in Settings/Preferences first.'
    };
  }

  const keyValue = userPreferences[key] as IUserPreferences[K];

  return {
    fieldValue: keyValue,
    userPreferences,
    userData,
    defaultPreference: defaultLocalStoragePref
  };
}

export async function updateUserFilter(pathname: string, filter: Record<string, any>) {
  const { defaultPreference, fieldValue, userPreferences, userData } =
    getUserPreferenceData('userFilters');

  try {
    const currentUserFilters = fieldValue || [];
    const value = { path: pathname, filter };
    const existingIndex = currentUserFilters.findIndex((filter) => filter.path === pathname);
    existingIndex === -1
      ? currentUserFilters.push(value)
      : (currentUserFilters[existingIndex] = value);

    userPreferences.userFilters = [...currentUserFilters];

    const updatedPreferences = {
      ...userData,
      preferences: { ...userData.preferences, preferences: JSON.stringify(userPreferences) }
    };

    setLocalStorage(USER_INFO, updatedPreferences);
    await update_preference_user_mutation({
      id: userData.id,
      value: JSON.stringify(userPreferences)
    });
  } catch (error: any) {
    setLocalStorage(USER_INFO, {
      ...userData,
      preferences: { ...userData.preferences, preferences: defaultPreference }
    });
    message.error('Error saving filters for current page');
  }
}

export function get_current_page_filter(pathname: string) {
  try {
    const { fieldValue } = getUserPreferenceData('userFilters');
    const currentPathFilter = fieldValue?.find((filter) => filter.path === pathname);
    const currentFilter = currentPathFilter?.filter;
    if (!currentFilter) return;

    const { dateCustom, dateSingle } = currentFilter;
    if (dateCustom) {
      currentFilter.dateCustom = dateCustom.map((date: string) => moment(date));
    }

    if (dateSingle) {
      currentFilter.dateSingle = moment(dateSingle);
    }

    return currentFilter;
  } catch (error) {
    return;
  }
}

export async function deleteUserFilter(pathname: string) {
  const { defaultPreference, fieldValue, userPreferences, userData } =
    getUserPreferenceData('userFilters');

  try {
    const currentUserFilters = fieldValue || [];
    const existingIndex = currentUserFilters.findIndex((filter) => filter.path === pathname);
    if (existingIndex !== -1) {
      currentUserFilters.splice(existingIndex, 1);
    }

    userPreferences.userFilters = [...currentUserFilters];

    const updatedPreferences = {
      ...userData,
      preferences: { ...userData.preferences, preferences: JSON.stringify(userPreferences) }
    };

    setLocalStorage(USER_INFO, updatedPreferences);
    await update_preference_user_mutation({
      id: userData.id,
      value: JSON.stringify(userPreferences)
    });
  } catch (error: any) {
    setLocalStorage(USER_INFO, {
      ...userData,
      preferences: { ...userData.preferences, preferences: defaultPreference }
    });
    message.error('Error reseting filters for current page');
  }
}
