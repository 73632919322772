import React, { useRef } from 'react';
import { getUserData } from '../../../utils/auth.utils';

import { useReactToPrint } from 'react-to-print';
import { CustomModal } from '../CustomModal';
import { Button } from 'antd';
import {
  IJournalListResponseData,
  IJournalViewResponseTableData
} from '../../../services/accounts/types';
import JournalPrintFormat from './JournalPrintFormat';

interface JournalInvoiceProps {
  modalData: IJournalViewResponseTableData[];
  journalData?: IJournalListResponseData;
  openModal: boolean;
  setOpenModal: (val: boolean) => void;
}
const JournalInvoice: React.FC<JournalInvoiceProps> = ({
  modalData,
  journalData,
  openModal,
  setOpenModal
}) => {
  const loggedInUser = getUserData();
  const printPDFRef = useRef<any>();
  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  return (
    <>
      <CustomModal isModalOpen={openModal} setIsModalOpen={setOpenModal} title="Journal Invoice">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print PDF
          </Button>
        </div>
        <div ref={printPDFRef}>
          <div style={{ color: 'black' }} className="m-4">
            <div className="text-center mt-3">
              <div className="font-bold text-xl">FreshKtm Agro Pvt. Ltd.</div>
              <div>Suryabinayak,Bhaktapur</div>
              <div className="font-bold text-sm">Journal Invoice</div>
            </div>

            <div className="my-3">
              <JournalPrintFormat journalData={journalData} modalData={modalData} />
            </div>

            <div className="mt-12 flex justify-between  px-8">
              <div>
                <div>
                  Printed By:{' '}
                  <span className="capitalize">{loggedInUser?.name ? loggedInUser.name : ''}</span>
                </div>
              </div>
            </div>
            <div
              style={{
                width: '95%',
                margin: 'auto',
                border: '1px solid black',
                overflowWrap: 'break-word',
                padding: '2px'
              }}
              className="text-xs">
              Remarks : {journalData?.description}
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default JournalInvoice;
