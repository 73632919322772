import { Button, Form, Radio, RadioChangeEvent, Space, Spin, Table, Tooltip, message } from 'antd';
import { useState } from 'react';
import AppContent from '@/components/Common/Content/Content';
import {
  get_account_latest_history,
  get_account_list_by_location,
  get_ledger_list
} from '@/services/accounts/queries';
import {
  IAccountTypeResponseData,
  ILedgerResponse,
  ILedgerTableData,
  ILedgerTableDataForIndexing
} from '@/services/accounts/types';
import { ColumnsType } from 'antd/lib/table';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { EllipsisOutlined, EnterOutlined } from '@ant-design/icons';
import { randomRGBDarkVer } from '@/utils/randomColors.utils';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import moment from 'moment';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list_for_ids } from '@/services/locations/queries';
import { ReusableDrawer } from '@/components/Common/Drawer/ReusableDrawer';
import * as ExcelJS from 'exceljs';
import { convert_string_to_nepali_date_object } from '@/utils/nepaliDateConverter';
import { addTransactionForExportAll } from '../../trial-balance/trial-transaction.service';
import getAccountInitialDate from '@/utils/getAccountInitialDate';
import AccountFilterTable from '@/components/AccountFilterTable';
import { getSingleHistory } from '@/services/accounts/services';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

const LedgerList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openExportDrawer, setOpenExportDrawer] = useState(false);
  // const [page, setPage] = useState(1);
  // const [size, setSize] = useState(100);
  const [valueExport, setValueExport] = useState(1);
  const [currParentIds, setCurrParentIds] = useState<number[]>([]);
  const colorPalette = ['B0B0B0', 'D3D3D3', 'FFFFFF'];
  const [tableData, setTableData] = useState<ILedgerTableData[]>([]);
  const [locationId, setLocationId] = useState<number | string>();
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [totalBalance, setTotalBalance] = useState<{ start: number; end: number }>({
    start: 0,
    end: 0
  });
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const location = useLocation();

  const breadcrumbItems = [{ label: 'Ledger', link: '/ledger' }];

  const getLedgerList = async (
    locationId: number | string,
    startDates?: string,
    endDates?: string
  ) => {
    setIsLoading(true);
    let totalBalanceStart = 0;
    let totalBalanceEnd = 0;
    const startDate = startDates || form.getFieldValue(['startDate']);
    const endDate = endDates || form.getFieldValue(['endDate']);
    let hasMore = false;

    const response = await get_ledger_list(
      locationId,
      startDate,
      endDate,
      'DESC',
      undefined,
      0,
      100
    );
    const parentIds: number[] = response.results.map((data) => {
      return data.id;
    });

    setCurrParentIds(parentIds);

    // get today date
    const dateNow = moment().add(1, 'day').format('YYYY-MM-DD');

    const currentFinancialStart = searchParams.get('fsd') as string;
    // Get 1 day before current financial start
    const currentFinancialHistoryDate = moment(currentFinancialStart)
      .subtract(1, 'days')
      .format('YYYY-MM-DD');

    // balance
    if (endDate >= dateNow) {
      const start = moment(startDate).format('YYYY-MM-DD');
      const latestStartBalance = await get_account_latest_history(start, parentIds);
      response.results = response.results.map((value) => {
        const allHistory = latestStartBalance.results.filter((val) => val.accountId === value.id);
        const currAccStartBal = getSingleHistory(allHistory, currentFinancialHistoryDate);

        return {
          ...value,
          startBalance: currAccStartBal ? currAccStartBal.balance : 0,
          endBalance: value.balance
        };
      });
    } else {
      const start = moment(startDate).format('YYYY-MM-DD');
      const end = moment(endDate).add(1, 'day').format('YYYY-MM-DD');
      const latestStartBalance = await get_account_latest_history(start, parentIds);
      const latestEndBalance = await get_account_latest_history(end, parentIds);
      response.results = response.results.map((value) => {
        const allHistory = latestStartBalance.results.filter((val) => val.accountId === value.id);

        const currAccStartBal = getSingleHistory(allHistory, currentFinancialHistoryDate);
        const currAccEndBal = latestEndBalance.results.find((val) => val.accountId === value.id);
        return {
          ...value,
          startBalance: currAccStartBal ? currAccStartBal.balance : 0,
          endBalance: currAccEndBal ? currAccEndBal.balance : 0
        };
      });
    }

    let LedgerData;
    if (locationId == '') {
      // for all Location

      LedgerData = await Promise.all(
        response.results.map(async (data) => {
          let locationName = '';
          if (data.locationId) {
            let location = await LocationsDB.getLocation(data.locationId);
            if (!location) {
              const locationIds = new Set<number>();
              response.results.forEach((value) => {
                if (value.locationId) locationIds.add(value.locationId);
              });
              const allLocations = await get_location_list_for_ids([...locationIds]);
              await LocationsDB.addLocations(allLocations);
              location = await LocationsDB.getLocation(data.locationId);
            }
            locationName = `(${location.code})`;
          }
          return { ...data, locationName: locationName, children: [] };
        })
      );
    } else {
      // for single location
      const responseSubLedger = await get_ledger_list(
        locationId,
        startDate,
        endDate,
        'DESC',
        parentIds,
        0,
        100
      );
      // balance
      if (endDate >= dateNow) {
        const start = moment(startDate).format('YYYY-MM-DD');
        const latestStartBalance = await get_account_latest_history(start, parentIds);
        responseSubLedger.results = responseSubLedger.results.map((value) => {
          const currAccStartBal = latestStartBalance.results.find(
            (val) => val.accountId === value.id
          );
          return {
            ...value,
            startBalance: currAccStartBal ? currAccStartBal.balance : 0,
            endBalance: value.balance
          };
        });
      } else {
        const start = moment(startDate).format('YYYY-MM-DD');
        const end = moment(endDate).add(1, 'day').format('YYYY-MM-DD');
        const latestStartBalance = await get_account_latest_history(start, parentIds);
        const latestEndBalance = await get_account_latest_history(end, parentIds);
        responseSubLedger.results = responseSubLedger.results.map((value) => {
          const currAccStartBal = latestStartBalance.results.find(
            (val) => val.accountId === value.id
          );
          const currAccEndBal = latestEndBalance.results.find((val) => val.accountId === value.id);
          return {
            ...value,
            startBalance: currAccStartBal ? currAccStartBal.balance : 0,
            endBalance: currAccEndBal ? currAccEndBal.balance : 0
          };
        });
      }
      if (responseSubLedger.results.length >= 100) hasMore = true;

      LedgerData = responseSubLedger.results.map((data) => {
        return { ...data, children: [] };
      });
    }

    // total
    LedgerData.forEach((value) => {
      totalBalanceStart += value.startBalance ? value.startBalance : 0;
      totalBalanceEnd += value.endBalance ? value.endBalance : 0;
    });

    if (!hasMore) {
      setTableData(LedgerData);
    } else {
      const obj = {} as ILedgerTableData;
      setTableData([
        ...LedgerData,
        { ...obj, parentIds: parentIds, skip: LedgerData.length, hasMore: true }
      ]);
    }

    setTotalBalance({ start: totalBalanceStart, end: totalBalanceEnd });

    setIsLoading(false);
  };

  const getSubLedgerList = async (parentData: ILedgerTableData, locationId: number | string) => {
    const startDate = form.getFieldValue(['startDate']);
    const endDate = form.getFieldValue(['endDate']);
    let hasMore = false;
    if (parentData) {
      const parentMapIds: Map<number, number> = new Map();
      parentMapIds.set(parentData.id, parentData.id);
      const parentIds = [parentData.id];
      //console.log('Parent Ids', parentIds);
      const response = await get_ledger_list(
        locationId,
        startDate,
        endDate,
        'DESC',
        parentIds,
        0,
        100
      );
      if (response.results.length === 0) return;
      if (response.results.length >= 100) hasMore = true;
      // get today date
      const dateNow = moment().add(1, 'day').format('YYYY-MM-DD');
      if (endDate >= dateNow) {
        const start = moment(startDate).format('YYYY-MM-DD');
        const latestStartBalance = await get_account_latest_history(start, parentIds);
        response.results = response.results.map((value) => {
          const currAccStartBal = latestStartBalance.results.find(
            (val) => val.accountId === value.id
          );
          return {
            ...value,
            startBalance: currAccStartBal ? currAccStartBal.balance : 0,
            endBalance: value.balance
          };
        });
      } else {
        const start = moment(startDate).format('YYYY-MM-DD');
        const end = moment(endDate).add(1, 'day').format('YYYY-MM-DD');
        const latestStartBalance = await get_account_latest_history(start, parentIds);
        const latestEndBalance = await get_account_latest_history(end, parentIds);
        response.results = response.results.map((value) => {
          const currAccStartBal = latestStartBalance.results.find(
            (val) => val.accountId === value.id
          );
          const currAccEndBal = latestEndBalance.results.find((val) => val.accountId === value.id);
          return {
            ...value,
            startBalance: currAccStartBal ? currAccStartBal.balance : 0,
            endBalance: currAccEndBal ? currAccEndBal.balance : 0
          };
        });
      }

      if (locationId == '') {
        response.results = await Promise.all(
          response.results.map(async (data) => {
            let locationName = '';
            if (data.locationId) {
              let location = await LocationsDB.getLocation(data.locationId);
              if (!location) {
                const locationIds = new Set<number>();
                response.results.forEach((value) => {
                  if (value.locationId) locationIds.add(value.locationId);
                });
                const allLocations = await get_location_list_for_ids([...locationIds]);
                await LocationsDB.addLocations(allLocations);
                location = await LocationsDB.getLocation(data.locationId);
              }
              locationName = `(${location.code})`;
            }

            return { ...data, locationName: locationName };
          })
        );
      }

      const cloneData = [...tableData];
      let childData: ILedgerTableData[] = [...response.results];
      if (hasMore) {
        const obj = {} as ILedgerTableData;
        childData = [
          ...response.results,
          {
            ...obj,
            parentId: parentData.id,
            parentIds: parentIds,
            skip: response.results.length,
            hasMore: true
          }
        ];
      }
      if (parentIds) {
        const newTableData = convertIdToIndex(
          parentMapIds,
          cloneData as ILedgerTableDataForIndexing[],
          childData as ILedgerTableDataForIndexing[]
        );
        //console.log('newTableData', newTableData);
        setTableData(newTableData);
      }
    }
  };

  function convertIdToIndex(
    id: Map<number, number>,
    data: ILedgerTableDataForIndexing[],
    responseData: ILedgerTableDataForIndexing[]
  ) {
    for (let i = 0; i < data.length; i++) {
      if (id.has(data[i].id)) {
        let filteredData = responseData.filter((value) => value.parentId === data[i].id);
        const randomColor = randomRGBDarkVer();
        filteredData = filteredData.map((value) => {
          value.iconColor = randomColor;
          if (value.hasMore) {
            return { ...value };
          } else {
            return { ...value, children: [] };
          }
        });
        if (filteredData.length > 0) {
          data[i].children = filteredData;
        }
      }
      if (data[i].children !== undefined) {
        if (data[i].children.length > 0) {
          const childIndex = convertIdToIndex(id, data[i].children, responseData);
          if (childIndex.length > 0) {
            data[i].children = childIndex.filter((value) => value.parentId === data[i].id);
          }
        }
      }
    }
    return data;
  }

  const searchMore = async (record: ILedgerTableData) => {
    if (locationId !== undefined && record.parentIds) {
      const startDate = form.getFieldValue(['startDate']);
      const endDate = form.getFieldValue(['endDate']);
      const response = await get_ledger_list(
        locationId,
        startDate,
        endDate,
        'DESC',
        record.parentIds,
        record.skip,
        100
      );

      if (locationId == '') {
        response.results = await Promise.all(
          response.results.map(async (data) => {
            let locationName = '';
            if (data.locationId) {
              let location = await LocationsDB.getLocation(data.locationId);
              if (!location) {
                const locationIds = new Set<number>();
                response.results.forEach((value) => {
                  if (value.locationId) locationIds.add(value.locationId);
                });
                const allLocations = await get_location_list_for_ids([...locationIds]);
                await LocationsDB.addLocations(allLocations);
                location = await LocationsDB.getLocation(data.locationId);
              }
              locationName = `(${location.code})`;
            }

            return { ...data, locationName: locationName };
          })
        );
      }

      let totalBalanceStart = 0,
        totalBalanceEnd = 0;

      setTableData((prev) => {
        if (record.parentIds) {
          if (record.parentIds.length > 1) {
            const prevItem = [...prev];
            response.results.forEach((data) => {
              prevItem.splice(prev.length - 1, 0, { ...data, children: [] });
            });
            if (response.results.length < 100) {
              prevItem.pop();
            } else {
              prevItem[prevItem.length - 1].skip = prevItem.filter((val) => !val.hasMore).length;
            }

            // total
            prevItem.forEach((value) => {
              totalBalanceStart += value.startBalance ? value.startBalance : 0;
              totalBalanceEnd += value.endBalance ? value.endBalance : 0;
            });

            return prevItem;
          } else {
            const newTableData = searchTableAndAdd(record.parentIds[0], response, prev);

            // total
            newTableData.forEach((value) => {
              totalBalanceStart += value.startBalance ? value.startBalance : 0;
              totalBalanceEnd += value.endBalance ? value.endBalance : 0;
            });

            return newTableData;
          }
        } else {
          return prev;
        }
      });

      setTotalBalance({ start: totalBalanceStart, end: totalBalanceEnd });
    }
  };

  const searchTableAndAdd = (
    parentId: number,
    data: ILedgerResponse,
    tableData: ILedgerTableData[]
  ): ILedgerTableData[] => {
    return tableData.map((item) => {
      if (item.id === parentId) {
        if (item.children) {
          data.results.forEach((data) => {
            item.children?.splice(item.children.length - 1, 0, {
              ...data,
              iconColor: item.children[0].iconColor,
              children: []
            });
          });
          if (data.results.length < 100) {
            item.children?.pop();
          } else {
            item.children[item.children?.length - 1].skip = item.children?.filter(
              (val) => !val.hasMore
            ).length;
          }
        }
        return { ...item };
      } else if (item.children) {
        const childItem = searchTableAndAdd(parentId, data, item.children);
        return { ...item, children: childItem };
      } else {
        return { ...item };
      }
    });
  };

  const columns: ColumnsType<ILedgerTableData> = [
    {
      title: 'Name',
      key: 'name',
      width: 15,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.hasMore ? (
                <Tooltip title="Load More . . .">
                  <Button
                    type="primary"
                    style={{ borderRadius: '9999px', padding: '0px 8px', height: '20px' }}
                    onClick={async (event) => {
                      event.stopPropagation();
                      await searchMore(record);
                    }}>
                    <EllipsisOutlined style={{ fontSize: '20px' }} />
                  </Button>
                </Tooltip>
              ) : record.parentId ? (
                <div>
                  <EnterOutlined
                    className="origin-center -scale-x-100 text-lg"
                    style={{ color: record.iconColor }}
                  />{' '}
                  <Link
                    to={`/accounts/detailed-ledger?accountId=${
                      record.id
                    }&startDate=${form.getFieldValue(['startDate'])}&endDate=${form.getFieldValue([
                      'endDate'
                    ])}`}
                    onClick={(event) => event.stopPropagation()}
                    target="_blank">
                    {record.locationName
                      ? `${record.name} ${record.locationName}`
                      : `${record.name}`}
                  </Link>
                </div>
              ) : (
                <Link
                  to={`/accounts/detailed-ledger?accountId=${
                    record.id
                  }&startDate=${form.getFieldValue(['startDate'])}&endDate=${form.getFieldValue([
                    'endDate'
                  ])}`}
                  onClick={(event) => event.stopPropagation()}
                  target="_blank">
                  {record.locationName ? `${record.name} ${record.locationName}` : `${record.name}`}
                </Link>
              )}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Type',
      key: 'type',
      width: 15,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.type}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Start Balance',
      key: 'startBalance',
      width: 15,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.startBalance !== null ? nepaliNumberFormatter(record.startBalance) : 'N/A'}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'End Balance',
      key: 'endBalance',
      width: 15,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.endBalance !== null ? nepaliNumberFormatter(record.endBalance) : 'N/A'}
            </TableCell>
          </>
        );
      }
    }
  ];

  const onSubmitFilter = async () => {
    const locationId = form.getFieldValue(['locationId']);
    // console.log('val', val);
    setExpandedRows([]);
    setLocationId(locationId);
    await getLedgerList(locationId);
  };

  const onSubmitDropdown = async (val: string) => {
    const locationId = form.getFieldValue(['locationId']);
    setExpandedRows([]);
    setLocationId(locationId);

    // Extract start and end date from the string
    const params = new URLSearchParams(val);
    const startDate = params.get('startDate') || undefined;
    const endDate = params.get('endDate') || undefined;

    await getLedgerList(locationId, startDate, endDate);
  };

  const onChangeExport = (e: RadioChangeEvent) => {
    setValueExport(e.target.value);
  };

  const showExportDrawer = () => {
    setOpenExportDrawer(true);
  };

  const onExportTreeView = async () => {
    setIsLoading(true);
    try {
      const startDate = form.getFieldValue('startDate');
      const endDate = form.getFieldValue('endDate');

      let flattenedData: {
        name: string;
        start: number;
        end: number;
        date: string;
        miti: string;
        node?: number;
      }[] = [];

      if (valueExport === 1) {
        if (locationId !== undefined) {
          const exportAllData = await getExportAllData(locationId, currParentIds);
          flattenedData = flattenDataExportAll(exportAllData).map((item) => {
            return {
              ...item,
              date: moment(item.createdAt).format('MM/DD/YYYY'),
              miti: convert_string_to_nepali_date_object(item.createdAt).format('MM/DD/YYYY'),
              start: item.startBalance ? item.startBalance : 0,
              end: item.endBalance ? item.endBalance : 0
            };
          });
        }
      } else {
        flattenedData = flattenData(tableData)
          .filter((val) => !val.hasMore)
          .map((item) => {
            return {
              ...item,
              date: moment(item.createdAt).format('MM/DD/YYYY'),
              miti: convert_string_to_nepali_date_object(item.createdAt).format('MM/DD/YYYY'),
              start: item.startBalance ? item.startBalance : 0,
              end: item.endBalance ? item.endBalance : 0
            };
          });
      }

      if (flattenedData.length === 0) {
        message.error('No Data to Export');
        return;
      }

      let totalStart = 0,
        totalEnd = 0;

      flattenedData.forEach((item) => {
        if (item.node === 0) {
          totalStart += item.start;
          totalEnd += item.end;
        }
      });

      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet('Sheet 1');

      const header = ['Particular', 'Start Balance', 'End Balance', 'Date', 'Miti'];

      const footer = ['Total', totalStart.toFixed(2), totalEnd.toFixed(2), '', ''];

      const rows: (string | number)[][] = flattenedData.map((item) => {
        return [item.name, item.start, item.end, item.date, item.miti];
      });

      const excelData = [header, ...rows];

      sheet.spliceRows(1, 0, ['Ledger'], [`From: ${startDate}`, '', '', '', `To: ${endDate}`]);

      sheet.mergeCells('A1:E1');

      const mainHeadingCell = sheet.getCell('A1');
      mainHeadingCell.alignment = { horizontal: 'center' };
      mainHeadingCell.font = { bold: true };

      // Add data and format header row
      excelData.forEach((rowData, rowIndex) => {
        const row = sheet.addRow(rowData);
        // Make header row bold
        if (rowIndex === 0) {
          row.eachCell((cell) => {
            cell.font = { bold: true };
          });
        } else {
          const item = flattenedData[rowIndex - 1];

          row.eachCell((cell) => {
            const node = item.node ? item.node : 0;
            const colorIndex = node % colorPalette.length;
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: colorPalette[colorIndex] }
            };

            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });
        }
      });

      // Fill the first 3 rows with green color
      for (let i = 1; i <= 3; i++) {
        sheet.getRow(i).eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '50C878' } // Green color
          };
        });
      }

      // Set cell widths
      const columnWidths = [30, 20, 20, 20, 20];
      columnWidths.forEach((width, index) => {
        sheet.getColumn(index + 1).width = width;
      });

      // Footer
      const lastRowIndex = sheet.rowCount;
      const footerRow = sheet.getRow(lastRowIndex + 1);

      footer.forEach((value, index) => {
        footerRow.getCell(index + 1).value = value;
      });

      footerRow.eachCell((cell) => {
        cell.font = { bold: true };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '50C878' }
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });

      // Generate the Excel file
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = valueExport === 1 ? 'Ledger_All.xlsx' : 'Ledger.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
      });

      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getExportAllData = async (locationId: number | string, parentIds: number[]) => {
    const startDate = form.getFieldValue('startDate');
    const endDate = form.getFieldValue('endDate');
    const dateNow = moment().add(1, 'day').format('YYYY-MM-DD');
    if (locationId === '') {
      let allAccounts = await get_account_list_by_location(0, 1);
      const totalAccounts = allAccounts.total;
      allAccounts = await get_account_list_by_location(0, totalAccounts);
      const ids = allAccounts.results.map((val) => {
        return val.id;
      });

      allAccounts.results = await addTransactionForExportAll(
        allAccounts.results,
        startDate,
        endDate,
        ids
      );

      const start = moment(startDate).format('YYYY-MM-DD');
      const end = moment(endDate).add(1, 'day').format('YYYY-MM-DD');
      const startBalanceData = await get_account_latest_history(start, ids);
      const endBalanceData = await get_account_latest_history(end, ids);
      const updatedAllAccounts = allAccounts.results.map((val) => {
        const selectedStartData = startBalanceData.results.find(
          (valStart) => val.id === valStart.accountId
        );
        const selectedEndData = endBalanceData.results.find(
          (valEnd) => val.id === valEnd.accountId
        );
        return {
          ...val,
          startBalance: selectedStartData ? selectedStartData.balance : 0,
          endBalance:
            endDate >= dateNow ? val.balance : selectedEndData ? selectedEndData.balance : 0
        };
      });
      const top = updatedAllAccounts
        .filter((val) => parentIds.includes(val.id))
        .sort((a, b) => b.balance - a.balance);
      const exportData = getExportData(updatedAllAccounts, top);
      return exportData;
    } else {
      locationId = typeof locationId === 'string' ? parseInt(locationId) : locationId;
      let allAccounts = await get_account_list_by_location(0, 1, locationId);
      const totalAccounts = allAccounts.total;
      allAccounts = await get_account_list_by_location(0, totalAccounts, locationId);
      const ids = allAccounts.results.map((val) => {
        return val.id;
      });

      allAccounts.results = await addTransactionForExportAll(
        allAccounts.results,
        startDate,
        endDate,
        ids
      );

      const start = moment(startDate).format('YYYY-MM-DD');
      const end = moment(endDate).add(1, 'day').format('YYYY-MM-DD');
      const startBalanceData = await get_account_latest_history(start, ids);
      const endBalanceData = await get_account_latest_history(end, ids);
      const updatedAllAccounts = allAccounts.results
        .filter((value) => value.locationId)
        .map((val) => {
          const selectedStartData = startBalanceData.results.find(
            (valStart) => val.id === valStart.accountId
          );
          const selectedEndData = endBalanceData.results.find(
            (valEnd) => val.id === valEnd.accountId
          );
          return {
            ...val,
            startBalance: selectedStartData ? selectedStartData.balance : 0,
            endBalance:
              endDate >= dateNow ? val.balance : selectedEndData ? selectedEndData.balance : 0
          };
        });
      const top = updatedAllAccounts
        .filter((val) => parentIds.includes(val.parentId))
        .sort((a, b) => b.balance - a.balance);
      const exportData = getExportData(updatedAllAccounts, top);
      return exportData;
    }
  };

  const getExportData = (allData: IAccountTypeResponseData[], main: IAccountTypeResponseData[]) => {
    main = main.map((item) => {
      let childData: IAccountTypeResponseData[] = [];
      if (allData.filter((val) => val.parentId === item.id).length > 0) {
        childData = getExportData(
          allData,
          allData.filter((val) => val.parentId === item.id)
        );
      }
      return {
        ...item,
        children: childData.sort((a, b) => b.balance - a.balance)
      };
    });

    return main;
  };

  // Function to flatten hierarchical data
  const flattenData = (data: ILedgerTableData[], prefix = '', node = 0) => {
    const result: ILedgerTableData[] = [];
    const traverse = (
      item: ILedgerTableData,
      parent: ILedgerTableData | null,
      prefix: string,
      node: number
    ) => {
      const newItem = { ...item, parent, node };
      newItem.name = prefix + newItem.name;
      delete newItem.children;
      result.push(newItem);
      if (item.children) {
        const childPrefix = prefix + '\u00A0'.repeat(3);
        const childNode = node + 1;
        item.children.forEach((child) => traverse(child, newItem, childPrefix, childNode));
      }
    };
    data.forEach((item) => traverse(item, null, prefix, node));
    return result;
  };

  // Function to flatten hierarchical data (Export All)
  const flattenDataExportAll = (data: IAccountTypeResponseData[], prefix = '', node = 0) => {
    const result: IAccountTypeResponseData[] = [];
    const traverse = (
      item: IAccountTypeResponseData,
      parent: IAccountTypeResponseData | null,
      prefix: string,
      node: number
    ) => {
      const newItem = { ...item, parent, node };
      newItem.name = prefix + newItem.name;
      delete newItem.children;
      result.push(newItem);
      if (item.children) {
        const childPrefix = prefix + '\u00A0'.repeat(3);
        const childNode = node + 1;
        item.children.forEach((child) => traverse(child, newItem, childPrefix, childNode));
      }
    };
    data.forEach((item) => traverse(item, null, prefix, node));
    return result;
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <AccountFilterTable
                form={form}
                defaultValues={{
                  dateCustom: getAccountInitialDate(location.search),
                  locationId: ''
                }}
                initial={false}
                onSearch={onSubmitFilter}
                buttonParentStyle={'flex justify-end items-center'}
                buttons={
                  <>
                    <CustomButton
                      onClick={() => navigate('/accounts/balance/customer/ledger')}
                      text="Customer Balance"
                      backgroundColor="#1890ff"
                      Linkto="/accounts/balance/customer/ledger"
                    />
                    <CustomButton
                      onClick={() => navigate('/accounts/balance/vendor/ledger')}
                      text="Vendor Balance"
                      backgroundColor="#1890ff"
                      Linkto="/accounts/balance/vendor/ledger"
                    />
                  </>
                }>
                <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />
              </AccountFilterTable>
            </div>
          </>
        }>
        <ReusableDrawer
          title="Export"
          setShowDrawer={setOpenExportDrawer}
          showDrawer={openExportDrawer}
          footer={
            <Space>
              <Button type="primary" onClick={onExportTreeView}>
                Download
              </Button>
            </Space>
          }>
          <Radio.Group onChange={onChangeExport} value={valueExport}>
            <Space direction="vertical">
              <Radio value={1}>Export All</Radio>
              <Radio value={2}>Export Current</Radio>
            </Space>
          </Radio.Group>
        </ReusableDrawer>
        <div className="my-2 flex justify-end">
          <Button type="primary" onClick={showExportDrawer}>
            Export
          </Button>
        </div>
        <Table
          columns={columns}
          rowKey={'id'}
          rowClassName={'cursor-pointer'}
          dataSource={tableData}
          expandable={{
            onExpand: async (expanded, record) => {
              if (expanded) {
                if (locationId !== undefined) {
                  setExpandedRows((prev) => [...prev, record.id]);
                  await getSubLedgerList(record, locationId);
                }
              } else {
                setExpandedRows((prev) => prev.filter((key) => key !== record.id));
              }
            },
            expandedRowKeys: expandedRows
          }}
          onRow={(record) => {
            return {
              onClick: async () => {
                if (!record.hasMore) {
                  if (expandedRows.find((key) => key === record.id)) {
                    setExpandedRows((prev) => prev.filter((key) => key !== record.id));
                  } else {
                    if (locationId !== undefined) {
                      setExpandedRows((prev) => [...prev, record.id]);
                      await getSubLedgerList(record, locationId);
                    }
                  }
                }
              }
            };
          }}
          summary={() => (
            <Table.Summary fixed={true}>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} className="font-bold">
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2} className="font-bold">
                  {nepaliNumberFormatter(totalBalance.start)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} className="font-bold">
                  {nepaliNumberFormatter(totalBalance.end)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
          pagination={false}
        />
      </AppContent>
    </Spin>
  );
};

export default LedgerList;
