import { Form, Select } from 'antd';

export const PurchaseStatus: React.FC = () => {
  const purchaseOptions = ['Received', 'Pending', 'Ordered'];

  return (
    <Form.Item name="purchaseStatus" label="Purchases Status">
      <Select
        placeholder="Select a Purchases Status"
        style={{ borderRadius: '10px' }}
        allowClear
        dropdownMatchSelectWidth={false}>
        <Select.Option value={''} key={'none'}>
          All
        </Select.Option>
        {purchaseOptions.map((value: any) => {
          return (
            <Select.Option value={value} key={value}>
              {value}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};
