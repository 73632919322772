import { Form, InputNumber } from 'antd';
import { useEffect } from 'react';
import Report from '../..';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { DEFAULT_DATE_FORMAT } from '@/constants';

interface DataType {
  avatar_id: null;
  created_at: string;
  email: string;
  id: number;
  name: string;
  password: string;
  phone: string;
  updated_at: string;
  vendorId: number;
  verified: number;
}

const VendorReport = () => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldValue('constraints', {
      page: 1,
      size: 10
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Vendor',
      link: '/reports/vendor'
    }
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      key: 'id',
      render: (record) => <Link to={`/users/vendors/${record.id}`}>{record.id}</Link>
    },
    {
      title: 'Name',
      key: 'name',
      render: (record) => <Link to={`/users/vendors/${record.id}`}>{record.name}</Link>
    },
    // {
    //   title: 'Vendor ID',
    //   dataIndex: 'vendorId',
    //   render: (text: string) => <a>{text}</a>
    // },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (record) => <Link to={`/users/vendors/${record.id}`}>{record.email}</Link>
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Created Date',
      dataIndex: 'created_at',
      render: (text: string) => <div>{moment(text).local().format(DEFAULT_DATE_FORMAT)}</div>
    },
    {
      title: 'Updated Date',
      dataIndex: 'updated_at',
      render: (text: string) => <div>{moment(text).local().format(DEFAULT_DATE_FORMAT)}</div>
    }
  ];

  return (
    <Report
      breadcrumbItems={breadcrumbItems}
      state={{ id: 4, name: 'Vendor Report' }}
      form={form}
      columnsData={columns}>
      <div className="grid grid-cols-3 gap-5 ">
        <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
          <InputNumber controls={false} />
        </Form.Item>
        <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
          <InputNumber controls={false} />
        </Form.Item>
      </div>
    </Report>
  );
};

export default VendorReport;
