import { useReactToPrint } from 'react-to-print';
import { CustomModal } from '../../../components/Common/CustomModal';
import { useContext, useRef, useState } from 'react';
import { Button, Menu, Tooltip, message } from 'antd';
import CustomTable from '../../../components/Common/CustomResuableInvoice/CustomTable';
import { ISellResponseFromServer } from '../../../services/sell/types';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import { ExportColumnType, exportExcel } from '../../../utils/exportExcel';

import { SellListContext } from './context';
import { AxiosResponse } from 'axios';
import { ReusableExportAll } from '../../../components/Common/ReusableExportAll';
const columsForPrint = [
  {
    label: 'ID',
    dataIndex: 'id',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Financial Reference',
    dataIndex: 'financialReference',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Customer',
    dataIndex: 'userName',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: `Amount`,
    dataIndex: 'totalAmount',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },

  {
    label: `VAT`,
    dataIndex: 'totalVat',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: `Shipping`,
    dataIndex: 'shipping',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: `Shipping Tax`,
    dataIndex: 'shippingTax',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: `Total`,
    dataIndex: 'totalAmount',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: `Paid`,
    dataIndex: 'amountPaid',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: `Return`,
    dataIndex: 'amountReturned',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: `Amount Remaining`,
    dataIndex: 'remainingAmount',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Net Sell',
    dataIndex: 'netsell',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: `Profit`,
    dataIndex: 'profit',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Payment Status',
    dataIndex: 'paymentStatus',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },

  {
    label: 'Date',
    dataIndex: 'date',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Crates Sent',
    dataIndex: 'cratesSent',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Crates Received',
    dataIndex: 'cratesReceived',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Created By',
    dataIndex: 'createdByName',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Location',
    dataIndex: 'locationName',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Note',
    dataIndex: 'note',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Print Count',
    dataIndex: 'printCount',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

const columnsForExport: ExportColumnType[] = [
  {
    title: 'S.N',
    dataIndex: 'SN',
    width: 50
  },
  {
    title: 'ID',
    dataIndex: 'id',
    width: 100
  },
  {
    title: 'Financial Reference',
    dataIndex: 'financialReference',
    width: 200
  },
  {
    title: 'Customer',
    dataIndex: 'userName',
    width: 200
  },
  {
    title: 'Amount',
    dataIndex: 'totalAmount',
    width: 100
  },
  {
    title: 'VAT',
    dataIndex: 'totalVat',
    width: 100
  },
  {
    title: 'Shipping',
    dataIndex: 'shipping',
    width: 100
  },
  {
    title: 'Shipping Tax',
    dataIndex: 'shippingTax',
    width: 100
  },
  {
    title: 'Total',
    dataIndex: 'totalAmount',
    width: 100
  },
  {
    title: 'Paid',
    dataIndex: 'amountPaid',
    width: 100
  },
  {
    title: 'Return',
    dataIndex: 'amountReturned',
    width: 100
  },
  {
    title: 'Amount Remaining',
    dataIndex: 'remainingAmount',
    width: 100
  },
  {
    title: 'Net Sell',
    dataIndex: 'netsell',
    width: 100
  },
  {
    title: 'Profit',
    dataIndex: 'profit',
    width: 100
  },
  {
    title: 'Payment Status',
    dataIndex: 'paymentStatus',
    width: 100
  },
  {
    title: 'Date',
    dataIndex: 'date',
    width: 100
  },
  {
    title: 'Crates Sent',
    dataIndex: 'cratesSent',
    width: 100
  },
  {
    title: 'Crates Received',
    dataIndex: 'cratesReceived',
    width: 100
  },
  {
    title: 'Created By',
    dataIndex: 'createdByName',
    width: 100
  },
  {
    title: 'Location',
    dataIndex: 'locationName',
    width: 100
  },
  {
    title: 'Note',
    dataIndex: 'note',
    width: 100
  },
  {
    title: 'Print Count',
    dataIndex: 'printCount',
    width: 100
  }
];

export const TableExportAllModal: React.FC<{
  // allSellList: ISellResponseFromServer;
  getInfo: (filter: string, forExportAll: boolean) => Promise<ISellResponseFromServer>;
  allExportUrl: string;
}> = ({ getInfo, allExportUrl }) => {
  const { setIsLoading } = useContext(SellListContext);
  const [allSellList, setAllSellList] = useState<ISellResponseFromServer>(
    {} as ISellResponseFromServer
  );
  const [openmodalforExport, setOpenModalforexport] = useState<boolean>(false);
  const printPDFRef = useRef<any>();

  // const handlePDFExport = useReactToPrint({
  //   content: () => printPDFRef.current
  // });
  // const handleExport = () => {
  //   setIsLoading(true);
  //   try {
  //     if (allSellList.results.length === 0) {
  //       message.error('No Data to Export');
  //       return;
  //     }
  //     const dataUpdated = allSellList.results.map((item: any, index: number) => {
  //       return {
  //         ...item,
  //         SN: index + 1,
  //         netsell: item.totalAmount - item.amountReturned
  //       };
  //     });
  //     exportExcel(columns, dataUpdated, 'Sell List');
  //   } catch (err: any) {
  //     console.log(err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  return (
    <>
      {/* <div className="flex justify-end ">
        <div>
          <ActionDropdown
            button={true}
            text="Export All"
            menu={
              <Menu
                items={[
                  {
                    key: '1',
                    label: (
                      <Tooltip title="Export Excel" color="blue">
                        <div className="text-center">Excel</div>
                      </Tooltip>
                    ),
                    onClick: async () => {
                      const exportData = await getInfo(allExportUrl, true);
                      setAllSellList(exportData.data);
                      handleExport();
                    }
                  },
                  {
                    key: '2',
                    label: (
                      <Tooltip title="Export PDF" color="blue">
                        <div className="text-center">PDF</div>
                      </Tooltip>
                    ),
                    onClick: async () => {
                      const exportData = await getInfo(allExportUrl, true);
                      setAllSellList(exportData.data);
                      setOpenModalforexport(true);
                    }
                  }
                ]}
              />
            }
          />
        </div>
      </div>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title="Sell View For PDF print">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={allSellList.results}
            reff={printPDFRef}
            title={'Sell List'}
            onAuto={handlePDFExport}
          />
        </div>
      </CustomModal> */}
      <ReusableExportAll
        setIsLoading={setIsLoading}
        title="Sell List"
        allExportUrl={allExportUrl}
        getInfo={getInfo}
        columnsForExport={columnsForExport}
        columsforPrint={columsForPrint}
      />
    </>
  );
};
