import { UploadFile } from 'antd/es/upload';
import { RcFile } from 'antd/lib/upload';
import { getImagesFromServer } from './queries';

export const onPreviewImage = async (file: UploadFile) => {
  let src = file.url as string;
  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj as RcFile);
      reader.onload = () => resolve(reader.result as string);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow = window.open(src);
  imgWindow?.document.write(image.outerHTML);
};

export const onPreviewImageURL = async (url: string) => {
  const image = new Image();
  image.src = url;

  const imgWindow = window.open(url);
  imgWindow?.document.write(image.outerHTML);
};

export async function getFileListFromMediaIds(mediaIds: number[]) {
  const images = await getImagesFromServer(mediaIds);
  const data: UploadFile[] = images.map((image) => ({
    name: image.name,
    status: 'done',
    url: image.url,
    uid: image.id.toString()
  }));

  return data;
}
