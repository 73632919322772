function getGroupedData<T extends Record<string, any>>(data: T[], index: keyof T) {
  return data.reduce((acc, row) => {
    const key = row[index];

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(row);
    return acc;
  }, {} as { [key: string]: T[] });
}

export default getGroupedData;
