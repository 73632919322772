import moment from 'moment';
import { useEffect, useState } from 'react';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { Form, Input, InputNumber, Select, TableProps } from 'antd';
import DatePicker, { RangePickerProps } from 'antd/lib/date-picker';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';

import { getUserData } from '@/utils/auth.utils';
import CustomReport from './report';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { CustomDatePresets } from '../utils/datePresets';
import { find_date_preference } from '@/store/localstorage/preferences';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

const { RangePicker } = DatePicker;

export interface IPurchaseSellSummation {
  adjusted: string;
  amount: string;
  method: string;
  wallet: string;
}

const PurchaseSellSummationReport = () => {
  const [form] = Form.useForm();
  const [paymentType, setPaymentType] = useState<number>(49);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const nepaliType = find_date_preference();

  useEffect(() => {
    const startDate = CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm');
    const endDate = CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm');

    const nepaliDateStart = convert_string_to_nepali_date_string(startDate);
    const nepaliDateEnd = convert_string_to_nepali_date_string(endDate);

    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      locationId: preferenceLocationId ? preferenceLocationId : '',
      dateCustom: [...CustomDatePresets.Today],
      startDate,
      endDate,
      startDateNepali: nepaliDateStart,
      endDateNepali: nepaliDateEnd,
      createdStartDate: startDate,
      createdEndDate: endDate,
      createdDateRange: [...CustomDatePresets.Today]
    });

    form.setFieldValue('startDateNepali', nepaliDateStart);
    form.setFieldValue('endDateNepali', nepaliDateEnd);
    form.setFieldValue('createdStartDateNepali', nepaliDateStart);
    form.setFieldValue('createdEndDateNepali', nepaliDateEnd);
  }, []);

  const breadcrumbItems = [
    { label: 'Reports', link: '/reports' },
    { label: 'Purchase/Sell Payment Summation', link: '/reports/purchase-sell-summation' }
  ];

  const columns: ColumnsType<IPurchaseSellSummation> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 25,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Method',
      key: 'method',
      sorter: (a, b) => a.method.localeCompare(b.method),
      sortOrder: sortedInfo.columnKey === 'method' ? sortedInfo.order : null,
      render: (record) => {
        return <div color="black">{record.method}</div>;
      },
      width: 100
    },
    {
      title: 'Amount',
      key: 'amount',
      className: 'highlight',
      sorter: (a, b) => parseFloat(a.amount) - parseFloat(b.amount),
      sortOrder: sortedInfo.columnKey === 'amount' ? sortedInfo.order : null,
      render: (record) => {
        return <div color="black">{nepaliNumberFormatter(parseFloat(record.amount))}</div>;
      },
      width: 50
    },
    {
      title: 'Wallet',
      key: 'wallet',
      sorter: (a, b) => parseFloat(a.wallet) - parseFloat(b.wallet),
      sortOrder: sortedInfo.columnKey === 'wallet' ? sortedInfo.order : null,
      render: (record) => {
        return <div color="black">{nepaliNumberFormatter(parseFloat(record.wallet))}</div>;
      },
      width: 50
    },
    {
      title: 'Adjusted',
      key: 'adjusted',
      sorter: (a, b) => parseFloat(a.adjusted) - parseFloat(b.adjusted),
      sortOrder: sortedInfo.columnKey === 'adjusted' ? sortedInfo.order : null,
      render: (record) => {
        return <div color="black">{nepaliNumberFormatter(parseFloat(record.adjusted))}</div>;
      },
      width: 50
    }
  ];

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };

  const handleCreatedDateChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'createdStartDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'createdEndDate'], dateStrings[1]);
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean, type: 'date' | 'sell') => {
    const startFormName = type === 'date' ? 'startDate' : 'createdStartDate';
    const endFormName = type === 'date' ? 'endDate' : 'createdEndDate';

    if (isStart)
      form.setFieldValue(['constraints', startFormName], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', endFormName], convert_nepali_to_english_date(val));
  };

  return (
    <CustomReport
      toSort={handleChange}
      breadcrumbItems={breadcrumbItems}
      state={{ id: paymentType, name: 'Purchase Sell Payment Report' }}
      form={form}
      columnsData={columns}
      toDownload={true}>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <LocationSearchV2 showAll hasParentFormItem={false} name={['constraints', 'locationId']} />

      <Form.Item
        name={['paymentType']}
        label="Payment Type"
        rules={[
          {
            required: true,
            message: 'Please choose type!'
          }
        ]}>
        <Select placeholder="Select a type!" onChange={(val) => setPaymentType(val)}>
          <Select.Option value={49}>Sell</Select.Option>
          <Select.Option value={50}>Purchase</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[{ required: true, message: 'Please select date!' }]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          ranges={CustomDatePresets}
          disabledDate={disabledDate}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item
        label={'Sell Date Range'}
        name={['constraints', 'createdDateRange']}
        rules={[{ required: !nepaliType, message: 'Please select date!' }]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          disabledDate={disabledDate}
          ranges={CustomDatePresets}
          onChange={handleCreatedDateChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
          className="w-full"
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'createdStartDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'createdEndDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true, 'date')}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false, 'date')}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item
        label={'Sell Start Date'}
        name={['createdStartDateNepali']}
        hidden={!nepaliType}
        rules={[{ required: nepaliType, message: 'Provide Sell Start Date' }]}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true, 'sell')}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item
        label={'Sell End Date'}
        name={['createdEndDateNepali']}
        hidden={!nepaliType}
        rules={[{ required: nepaliType, message: 'Provide Sell End Date' }]}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false, 'sell')}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
};

export default PurchaseSellSummationReport;
