import { ILogEvents } from '@/services/logs/events/types';
import CustomSearch, { SelectProps } from '..';
import { get_log_events_list_all } from '@/services/logs/events/queries';

type MultipleProps =
  | {
      defaultValue?: number;
      isMultiple?: false;
      setSelected?: (selected?: ILogEvents) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      defaultValue?: number[];
      isMultiple: true;
      setSelected?: (selected?: ILogEvents[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps & MultipleProps;

function EventSearchV2({ ...props }: Props) {
  async function get_events(skip = 0, count = 100, value = '', filter = '') {
    const response = await get_log_events_list_all(skip, count, value, filter);
    return response.results;
  }

  async function searchEventDB() {
    return [];
  }

  const emptyFuncion = async () => {
    return;
  };

  return (
    <CustomSearch
      count={50}
      path={{ name: 'url', value: 'id' }}
      formFor="event"
      hideDBNotFoundMessage
      addCallback={emptyFuncion}
      dbSearchById={emptyFuncion}
      serverCallback={get_events}
      dbSearchCallback={searchEventDB}
      {...props}
    />
  );
}

export default EventSearchV2;
