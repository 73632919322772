import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import './index.css';

interface CustomModalProps {
  isModalOpen: boolean;
  title: string | React.ReactNode;
  setIsModalOpen: (val: boolean) => void;
  style?: React.CSSProperties;
  footer?: boolean;
  maskClosable?: boolean;
  width?: string;
  handleCancel?: () => void;
  isSticky?: boolean;
}
export const CustomModal: React.FC<CustomModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  title,
  style,
  footer = true,
  maskClosable = true,
  children,
  isSticky,
  width = '80%',
  handleCancel = () => {
    setIsModalOpen(false);
  }
}) => {
  const [dynamicWidth, setDynamicWidth] = useState(width);

  // When screen size is 650, make width to 95%
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 650) {
        setDynamicWidth('95%');
      } else {
        setDynamicWidth(width);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {footer ? (
        <Modal
          width={dynamicWidth}
          title={title}
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={maskClosable}
          className={isSticky ? 'sticky-header-modal' : ''}>
          {children}
        </Modal>
      ) : (
        <Modal
          style={style ? style : { overflowX: 'scroll' }}
          width={dynamicWidth}
          title={title}
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={maskClosable}
          footer={null}
          className={isSticky ? 'sticky-header-modal' : ''}>
          {children}
        </Modal>
      )}
    </>
  );
};
