import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  IPosMoneyResponse,
  IPosMoneyResponseData,
  IPosSession,
  IPosSessionIdResponse,
  IPosSessionResponse
} from './types';
import { getDataForIds } from '@/utils/queries.utils';

export const get_session_info = async () => {
  const response: AxiosResponse<IPosSessionResponse, any> = await http.get('session/active');
  return response.data;
};

export const get_session_info_id = async (id: number) => {
  const response: AxiosResponse<IPosSessionIdResponse, any> = await http.get(`session/${id}`);
  return response.data;
};

export const get_session_list_filter = async (filter = '') => {
  const response: AxiosResponse<IPosSession, any> = await http.get('session/?' + filter);
  return response.data;
};

export const get_money_info = async (skip = 0, count = 100, value = '') => {
  const response: AxiosResponse<IPosMoneyResponse, any> = await http.get('money', {
    skip,
    count,
    value
  });
  return response.data;
};

export const get_money_info_filter = async (filter = '') => {
  const response: AxiosResponse<IPosMoneyResponse, any> = await http.get('money/?' + filter);
  return response.data;
};

export const get_session_money_details = async (id: number) => {
  const response: AxiosResponse<IPosMoneyResponseData, any> = await http.get('money/' + id);
  return response.data;
};

export const get_session_money_details_ids = async (ids: number[]) => {
  return getDataForIds<IPosMoneyResponseData>(ids, 'money/?');
};
