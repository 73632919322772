import { message } from 'antd';
import UnitsDB from '../store/localstorage/UnitsDB';
import { get_latest_purchase_line_by_location_product } from '../services/transfer/queries';
import CustomErrorModal from '../components/Common/CustomErrorModal';
import { ITransferLine } from '@/services/transfer/types';
import { IUnits } from '@/services/products/types';

export default async function fetchProductData(
  name: string | number,
  selectedProductId: number,
  selectedUnitId: number,
  selectedLocationId: number
): Promise<number | undefined> {
  try {
    const response = await get_latest_purchase_line_by_location_product(
      selectedProductId,
      selectedLocationId
    );
    const unitId = response.unitId;
    if (response) {
      if (unitId) {
        const [unitDataPurchase, unitDataSelected] = await Promise.all([
          UnitsDB.getUnit(unitId),
          UnitsDB.getUnit(selectedUnitId)
        ]);

        if (typeof unitDataPurchase === 'object' && typeof unitDataSelected === 'object') {
          const baseUnitPrice = response.unitPrice / unitDataPurchase.baseUnitMultiplier;
          const updatedUnitPrice = baseUnitPrice * unitDataSelected.baseUnitMultiplier;

          return updatedUnitPrice;
        }
      } else {
        message.error('Unit not found.');
      }
    } else {
      CustomErrorModal({ title: 'Error', message: 'Latest Purchase not found.' });
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
    return undefined;
  }
}

export async function fetchProductsDataFromResponse(
  response: ITransferLine,
  units: IUnits[],
  selectedUnitId: number
) {
  try {
    const unitId = response.unitId;
    if (response) {
      if (unitId) {
        const unitDataPurchase = units.find((unit) => unit.id === unitId);
        const unitDataSelected = units.find((unit) => unit.id === selectedUnitId);

        if (typeof unitDataPurchase === 'object' && typeof unitDataSelected === 'object') {
          const baseUnitPrice = response.unitPrice / unitDataPurchase.baseUnitMultiplier;
          const updatedUnitPrice = baseUnitPrice * unitDataSelected.baseUnitMultiplier;

          return updatedUnitPrice;
        }
      } else {
        message.error('Unit not found.');
      }
    } else {
      CustomErrorModal({ title: 'Error', message: 'Latest Purchase not found.' });
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
    return undefined;
  }
}
