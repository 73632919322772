import {
  get_adjustment_details,
  get_adjustment_line_details
} from '../../../../services/adjustments/queries';
import { IAdjustmentInvoice } from '../../../../services/invoice/types';
import { get_location_details } from '../../../../services/locations/queries';
import { get_units_list, get_product_list_ids } from '../../../../services/products/queries';
import LocationsDB from '../../../../store/localstorage/LocationsDB';
import ProductsDB from '../../../../store/localstorage/ProductsDB';
import UnitsDB from '../../../../store/localstorage/UnitsDB';

export const getAdjustmentPrintData = async (
  adjustmentId: number,
  adjustmentStatus: string,
  adjustmentReason: string,
  locationId: number,
  curr: any
) => {
  const response = await get_adjustment_details(adjustmentId);

  // Fetch Lines
  const lines = await getLinesForAdjustmentInvoice(adjustmentId, adjustmentStatus);

  // Fetch Location Details
  const locationDetails = await getLocationDetails(locationId);

  return {
    adjustmentDetails: { ...response.data, reasonName: adjustmentReason },
    locationDetails: { ...locationDetails },
    lines: lines,
    invoiceLayouts: curr
  } as IAdjustmentInvoice;
};

const getLinesForAdjustmentInvoice = async (adjustmentId: number, adjustmentStatus: string) => {
  const linesResponse = await get_adjustment_line_details(adjustmentStatus, adjustmentId);

  if (linesResponse.data.length > 0) {
    const searchProducts: any = {};
    for (let index = 0; index < linesResponse.data.length; index++) {
      const product = await ProductsDB.getProduct(linesResponse.data[index].productId);
      if (!product) {
        if (linesResponse.data[index].productId in searchProducts) {
          searchProducts[linesResponse.data[index].productId] = [
            ...searchProducts[linesResponse.data[index].productId],
            index
          ];
        } else {
          searchProducts[linesResponse.data[index].productId] = [index];
        }
      } else {
        linesResponse.data[index].productName = typeof product === 'object' ? product.name : '';
      }
      let findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
      if (!findUnit) {
        const allUnits = await get_units_list();
        await UnitsDB.addUnits(allUnits);
        findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
      }

      linesResponse.data[index].quantity = `${linesResponse.data[index].quantity} ${
        typeof findUnit === 'object' ? findUnit.shortName : ''
      }`;
    }
    const searchProductslength = Object.entries(searchProducts).length;
    if (searchProductslength > 0) {
      const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
      for (const key in searchProducts) {
        const findproduct = productsresponse?.data?.results.find(
          (currProduct) => currProduct.id == key
        );
        for (let i = 0; i < searchProducts[key].length; i++) {
          linesResponse.data[searchProducts[key][i]].productName = findproduct?.name;
        }
        await ProductsDB.addProducts([findproduct]);
      }
    }
  }

  return linesResponse.data;
};

const getLocationDetails = async (locationId: number) => {
  let locationDetails = await LocationsDB.getLocation(locationId);
  if (!locationDetails) {
    locationDetails = await get_location_details(locationId);
    await LocationsDB.addLocations([locationDetails]);
  }
  return locationDetails;
};
