import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Input, message, PageHeader, Typography, Spin } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AppContent from '../../../components/Common/Content/Content';
import { send_message_mutation } from '../../../services/channels/mutations';
import { get_channel_id, get_channel_id_skipfrom_skipto } from '../../../services/channels/queries';
import { ICreateMessage } from '../../../services/channels/types';
import { get_user_details } from '../../../services/users/queries';
import UsersDB from '../../../store/localstorage/UsersDB';
import { getUserData } from '../../../utils/auth.utils';
import { LoadingOutlined } from '@ant-design/icons';
import GoBackButton from '../../../components/Common/GoBackButton/GoBackButton';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Text } = Typography;
interface ChatBox {
  id: number;
  name: string;
  message: string;
  date: string;
}
const Room = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [isloading, setIsloading] = useState(true);
  const [activeUser, setActiveUser] = useState('0');
  const [channelName, setChannelName] = useState('');
  const [inputValue, setInputValue] = useState('');
  const scrollElement = useRef<HTMLDivElement>(null);
  const [allmessageList, setAllmessageList] = useState<any>([]);
  const [skipFrom, setSkipFrom] = useState<number>(0);
  const [skipTo, setSkipTo] = useState<number>(0);
  const [finisheMessage, setFinishMessage] = useState<boolean>(false);
  const [loadmessagespinner, setLoadmessagespinner] = useState<boolean>(false);
  const navigate = useNavigate();

  const { data: messageList, refetch } = useQuery(['message-list'], async () => {
    const response = await get_channel_id((id && parseInt(id)) || 0);
    for (let index = 0; index < response.data.length; index++) {
      let user: any = await UsersDB.getUser(response.data[index].userId);
      if (!user) {
        user = (await get_user_details(response.data[index].userId)).user;
        await UsersDB.addUsers([user]);
      }
      response.data[index].userName = user.name;
    }
    if (response.data.length < 20) {
      setFinishMessage(true);
      setLoadmessagespinner(false);
    } else {
      setFinishMessage(false);
      setLoadmessagespinner(false);
      setSkipFrom(response.data[response.data?.length - 1].id);
      setSkipTo(response.data[0].id);
    }
    response.data.sort(
      (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
    setAllmessageList(response.data);
    setIsloading(false);
    return response;
  });
  const createMessageMutation = useMutation(send_message_mutation);
  useEffect(() => {
    const name = searchParams.get('name');
    if (name) setChannelName(name);
    setActiveUser(getUserData().id);
  }, []);
  useEffect(() => {
    scrollToBottom();
  }, [messageList]);
  const breadcrumbItems = [
    {
      label: 'Channel',
      link: '/channel'
    },
    {
      label: 'Room'
    }
  ];
  const selfMessage = (details: ChatBox) => (
    <div className="flex flex-col mb-4" key={details.id}>
      <div className="flex flex-row-reverse">
        <img
          src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
          style={{ height: '40px', width: '40px', borderRadius: '50%' }}
        />
        <div
          className="mr-2 py-3 px-4 bg-blue-400 rounded-bl-3xl rounded-tl-3xl rounded-br-xl text-black"
          style={{ backgroundColor: '#e5f5fb' }}>
          {details.message}
        </div>
      </div>
      <Text className="flex justify-end">{new Date(details.date).toLocaleString('en-GB')}</Text>
    </div>
  );
  const otherMessage = (details: ChatBox) => (
    <div className="flex flex-col mb-4" key={details.id}>
      <div className="flex">
        <img
          src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
          style={{ height: '40px', width: '40px', borderRadius: '50%' }}
        />
        <div
          className="ml-2 py-3 px-4 bg-blue-400 rounded-bl-3xl rounded-tr-3xl rounded-br-xl text-black"
          style={{ backgroundColor: '#e5f5fb' }}>
          {details.message}
        </div>
      </div>
      <Text className="flex">{new Date(details.date).toLocaleString('en-GB')}</Text>
    </div>
  );

  const SendMessage = async (value: any) => {
    setIsloading(true);
    if (id) {
      const messageValue: ICreateMessage = {
        channelId: parseInt(id),
        content: ''
      };
      if (typeof value == 'string' && value != '') {
        messageValue.content = value;
      } else if (value.target.value != '') {
        messageValue.content = value.target.value;
      }
      if (messageValue.content != '')
        await createMessageMutation.mutateAsync(messageValue, {
          onSuccess: async ({ data }: { data: any }) => {
            message.success('message sent successfully');
            refetch();
            setInputValue('');
          },
          onError: (e: any) => {
            message.error(`${e.response.data.message}`, 5);
          }
        });
    }
    setIsloading(false);
  };
  const scrollToBottom = () => {
    if (scrollElement.current) scrollElement.current.scrollTop = scrollElement.current.scrollHeight;
  };

  const loadPreviousMessage = async () => {
    setLoadmessagespinner(true);
    const x = scrollElement.current?.scrollHeight;
    const response = await get_channel_id_skipfrom_skipto(parseInt(id as string), skipFrom, skipTo);
    if (response.data.length < 20) {
      setFinishMessage(true);
    } else {
      setSkipFrom(response.data[response.data.length - 1].id);
    }
    // console.log('New id', response.data[response.data.length - 1].id);
    response.data.sort(
      (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
    setAllmessageList([...response.data, ...allmessageList]);
    const y = scrollElement.current?.scrollHeight;
    if (scrollElement.current && x && y) {
      scrollElement.current.scrollTop = x - y;
    }
    // console.log('Response from load previous-->', response);
    setLoadmessagespinner(false);
  };
  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        button={<GoBackButton onClick={() => navigate('/channel')} />}>
        {/* <PageHeader
          title={channelName}
          style={{
            padding: '8px 0px'
          }}
        /> */}
        <div className="flex justify-start gap-5 items-center p-5" style={{}}>
          <div
            className="flex justify-center items-center text-xl "
            style={{
              height: '50px',
              width: '50px',
              borderRadius: '50%',
              border: '2px solid blue'
            }}>
            LS
          </div>
          <div>
            <div className="font-bold">{channelName}</div>
            <div>Chat Room</div>
          </div>
        </div>
        <div
          className="mx-auto shadow-lg rounded-lg flex flex-col justify-between"
          style={{ height: '70vh' }}>
          {messageList && (
            <div ref={scrollElement} className="flex flex-col mt-5 p-5 overflow-y-auto">
              {/* <Button type="link">load previous messages...</Button> */}
              {finisheMessage ? (
                <></>
              ) : loadmessagespinner ? (
                <Spin indicator={antIcon} />
              ) : (
                <Button type="link" onClick={() => loadPreviousMessage()}>
                  load previous messages...
                </Button>
              )}
              {allmessageList?.map((value: any) => {
                if (activeUser == value.userId) {
                  return selfMessage({
                    id: value.id,
                    name: value.userName,
                    message: value.content,
                    date: value.createdAt
                  });
                } else {
                  return otherMessage({
                    id: value.id,
                    name: value.userName,
                    message: value.content,
                    date: value.createdAt
                  });
                }
              })}
            </div>
          )}
          <div className="px-3 mt-5 mb-5 flex flex-col justify-end">
            <Search
              placeholder="type your message here..."
              loading={isloading}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onPressEnter={SendMessage}
              onSearch={SendMessage}
              enterButton="send"
            />
            {/* <Input type="text" placeholder="type your message here..." /> */}
          </div>
        </div>
      </AppContent>
    </div>
  );
};

export default Room;
