import React, { useState } from 'react';
import { EyeFilled, PlusCircleFilled } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate } from 'react-router-dom';
import AppContent from '../../../components/Common/Content';
import { get_channel_list } from '../../../services/channels/queries';
import UsersDB from '../../../store/localstorage/UsersDB';
import { get_user_details } from '../../../services/users/queries';
import { get_source_list } from '../../../services/source/queries';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';

interface DataType {
  id: number;
  name: string;
}

const SourceList: React.FC = () => {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(true);
  const { data: sourceList, refetch } = useQuery(['channel-list'], async () => {
    const response = await get_source_list();
    console.log(response.data);
    setIsloading(false);
    return response;
  });

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      key: 'name',
      render: (record) => {
        return <div className="forless">{record.name}</div>;
      }
    },
    {
      title: 'ID',
      key: 'id',
      render: (record) => {
        return <div className="forless">{record.id}</div>;
      }
    }
    // {
    //   title: 'Created',
    //   key: 'createdAt',
    //   render: (record) => {
    //     return (
    //       <Link to={`/source/${record.id}`} color="black">
    //         <div className="forless">{record.createdAt.slice(0, 10)}</div>
    //       </Link>
    //     );
    //   }
    // },

    // {
    //   title: 'Updated',
    //   key: 'updatedAt',
    //   render: (record) => {
    //     return (
    //       <Link to={`/source/${record.id}`} color="black">
    //         <div className="forless">{record.updatedAt.slice(0, 10)}</div>
    //       </Link>
    //     );
    //   }
    // },
  ];

  const breadcrumbItems = [
    {
      label: 'Channel',
      link: '/channel'
    }
  ];

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <CustomButton
              onClick={() => navigate('/source/new')}
              text="Add"
              Linkto="/source/new"
              backgroundColor="#1890ff"
            />
          </>
        }>
        {/* <div className="flex justify-end mt-5">
          <Link to={'/source/new'}>
            {' '}
            <Button
              htmlType={'button'}
              size={'small'}
              type={'primary'}
              className={'me-2'}
              onClick={() => navigate('/source/new')}>
              <PlusCircleFilled />
              <span> Add</span>
            </Button>
          </Link>
        </div> */}

        <Table
          columns={columns}
          dataSource={sourceList?.data ? sourceList.data : []}
          rowKey={'id'}
          loading={isloading}
        />
      </AppContent>
    </div>
  );
};

export default SourceList;
