import { get_vendor_list_ids } from '@/services/users/queries';
import LocalStore from '.';
import { IVendor } from '../../services/users/types';

export default class VendorsDB extends LocalStore {
  /*Vendors Functions*/
  /*search for vendor by name, email, customer id, user id, phone with limit*/
  static async searchVendor(name: string, limit = 10) {
    if (!VendorsDB.db) await VendorsDB.init();
    name = name.toLowerCase();
    const filteredVendors: any[] = [];
    return new Promise((resolve: (value: any[]) => void, reject) => {
      const transaction = VendorsDB.db.transaction('Vendors', 'readonly');

      const objectStore = transaction.objectStore('Vendors');
      const cursorRequest = objectStore.index('VendorsSecondIndex').openCursor();
      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          if (
            cursor.value?.lowercaseName?.includes(name) ||
            cursor.value?.user?.email?.toLowerCase().includes(name) ||
            cursor.value?.user?.phone?.toLowerCase().includes(name) ||
            JSON.stringify(cursor.value?.userId)?.toLowerCase().includes(name) ||
            JSON.stringify(cursor.value?.id)?.toLowerCase().includes(name)
          ) {
            filteredVendors.push(cursor.value);
          }
          if (filteredVendors.length < limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(filteredVendors);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async getAllVendors() {
    if (!VendorsDB.db) await VendorsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = VendorsDB.db.transaction('Vendors', 'readonly');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Vendors');
      const request = objectStore.getAll();
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async removeAllVendors() {
    if (!VendorsDB.db) await VendorsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = VendorsDB.db.transaction(['Vendors'], 'readwrite');
      transaction.oncomplete = (event) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Vendors');
      const objectStoreRequest = objectStore.clear();
      objectStoreRequest.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      objectStoreRequest.onerror = (event: any) => {
        reject(event.target.result);
      };
    });
  }
  static async addVendors(usersData: any[]) {
    if (!VendorsDB.db) await VendorsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = VendorsDB.db.transaction('Vendors', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Vendors');
      usersData.forEach(async (user) => {
        const checkKey = objectStore.count(user.id);
        checkKey.onsuccess = async (event: any) => {
          user.lowercaseName = user.user?.name?.toLowerCase();
          if (checkKey.result == 0) {
            const request = objectStore.add(user);
            request.onerror = (event: any) => {
              reject(event.target.error);
            };
          } else {
            await VendorsDB.updateVendors(user);
          }
        };
      });
    });
  }
  static async getVendors(id: number | string) {
    if (!VendorsDB.db) await VendorsDB.init();

    return new Promise<IVendor | string>((resolve, reject) => {
      const transaction = VendorsDB.db.transaction('Vendors', 'readonly');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Vendors');
      const request = objectStore.get(id);
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async checkVendors(id: number) {
    if (!VendorsDB.db) await VendorsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = VendorsDB.db.transaction('Vendors', 'readonly');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Vendors');
      const request = objectStore.get(id);
      request.onsuccess = (event: any) => {
        if (event.target.result) resolve(true);
        resolve(false);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async updateVendors(user: any) {
    if (!VendorsDB.db) await VendorsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = VendorsDB.db.transaction('Vendors', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Vendors');
      const request = objectStore.get(user.id);
      request.onsuccess = (event: any) => {
        if (event.target.result) {
          user.lowercaseName = user?.name?.toLowerCase();
          const requestUpdate = objectStore.put(user);
          requestUpdate.onerror = (event: any) => {
            reject(event.target.error);
          };
          requestUpdate.onsuccess = (event) => {
            resolve('updated data');
          };
        } else reject('Error, could not find id.');
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async deleteVendors(id: number) {
    if (!VendorsDB.db) await VendorsDB.init();

    if (await VendorsDB.getVendors(id)) {
      return new Promise((resolve, reject) => {
        const transaction = VendorsDB.db.transaction('Vendors', 'readwrite');
        transaction.oncomplete = (event: any) => {
          resolve('success');
        };
        transaction.onerror = (event: any) => {
          reject(event.target.error);
        };
        const objectStore = transaction.objectStore('Vendors');
        const request = objectStore.delete(id);
        request.onsuccess = (event: any) => {
          resolve('deleted successfuly.');
          // if (event.target.result) resolve(event.target.result);
          // reject('Error, could not find id.');
        };
        request.onerror = (event: any) => {
          reject(event.target.error);
        };
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve('id does not exist');
      });
    }
  }

  static async addVendorsIfAbsent(vendorIds: number[]): Promise<IVendor[]> {
    if (!VendorsDB.db) await VendorsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = VendorsDB.db.transaction('Vendors', 'readwrite');
      transaction.onerror = (event) => getTransactionError(event, reject, 'search');

      const objectStore = transaction.objectStore('Vendors');
      const request = objectStore.getAll();

      request.onsuccess = async (event) => {
        const storedVendors = (event.target as IDBRequest).result as IVendor[];
        const storedVendorIds = storedVendors.map((v) => v.id);
        const missingVendorIds = vendorIds.filter(
          (id) => Number.isNaN(id) || !storedVendorIds.includes(id)
        );

        if (missingVendorIds.length) {
          const response = await get_vendor_list_ids(missingVendorIds);
          const fetchedVendors = response.data.results;
          await VendorsDB.addVendors(fetchedVendors);

          const newList = [...storedVendors, ...fetchedVendors];
          const passedVendors = newList.filter((v) => vendorIds.includes(v.id));
          resolve(passedVendors);
        } else {
          const passedVendors = storedVendors.filter((v) => vendorIds.includes(v.id));
          resolve(passedVendors);
        }
      };
    });
  }
}

function getTransactionError(event: Event, reject: (reason?: any) => void, type: string) {
  const instanceOfIndexDB = event.target instanceof IDBRequest;
  if (instanceOfIndexDB) {
    reject(event.target.error);
    return;
  }

  reject(new Error(`Failed to ${type} agents on indexDB`));
}
