import { Form, Input, Menu, Select, Spin, TableProps, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { checkAccess } from '@/routes/acl';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { Link, useNavigate } from 'react-router-dom';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { get_account_detail_by_ids, get_rules_list } from '@/services/accounts/queries';
import { IAccountTypeResponseData, IAccountsRuleListTableData } from '@/services/accounts/types';
import { AccountRulesEvent } from '@/services/accounts/enums';
import { AccountsTypeSearch } from '@/components/Common/AccountsTypeSearch';
import { EditOutlined } from '@ant-design/icons';
import ActionDropdown from '@/components/Common/Dropdownactions';
import AccountsDB from '@/store/localstorage/AccountsDB';
import { ConvertObjectToURL } from '@/utils/converturl';
import { getUserData } from '@/utils/auth.utils';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list_for_ids } from '@/services/locations/queries';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import AccountSearchV2 from '@/components/Common/CustomSearch/Accounts/AccountSearchV2';

const AccountsRuleList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [ruleList, setRuleList] = useState<IAccountsRuleListTableData[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [locationId, setLocationId] = useState<number>();
  // const [debitAccountList, setDebitAccountList] = useState<IAccountTypeResponseData[]>([]);
  // const [creditAccountList, setCreditAccountList] = useState<IAccountTypeResponseData[]>([]);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const breadcrumbItems = [
    {
      label: 'Rule',
      link: '/accounts-rule'
    }
  ];

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    handleLocationChange();
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };

  const columns: ColumnsType<IAccountsRuleListTableData> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 15,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (a, record) => <TableCell>{record.name}</TableCell>
    },
    {
      title: 'Description',
      key: 'description',
      width: 20,
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortOrder: sortedInfo.columnKey === 'description' ? sortedInfo.order : null,
      render: (a, record) => <TableCell>{record.description}</TableCell>
    },
    {
      title: 'Event Type',
      key: 'eventType',
      width: 15,
      sorter: (a, b) => a.eventType.localeCompare(b.eventType),
      sortOrder: sortedInfo.columnKey === 'eventType' ? sortedInfo.order : null,
      render: (a, record) => <TableCell>{record.eventType}</TableCell>
    },
    {
      title: 'Debit Account',
      key: 'debitAccountName',
      width: 15,
      sorter: (a, b) =>
        a.debitAccountName && b.debitAccountName
          ? a.debitAccountName.localeCompare(b.debitAccountName)
          : 0,
      sortOrder: sortedInfo.columnKey === 'debitAccountName' ? sortedInfo.order : null,
      render: (a, record) => <TableCell>{record.debitAccountName}</TableCell>
    },
    // {
    //   title: 'Debit Type',
    //   key: 'debitType',
    //   width: 15,
    //   sorter: (a, b) => a.debitType.localeCompare(b.debitType),
    //   sortOrder: sortedInfo.columnKey === 'debitType' ? sortedInfo.order : null,
    //   render: (a, record) => (
    //     <TableCell>{AccountRuleDebit[record.debitType as keyof typeof AccountRuleDebit]}</TableCell>
    //   )
    // },
    {
      title: 'Credit Account',
      key: 'creditAccountName',
      width: 15,
      sorter: (a, b) =>
        a.creditAccountName && b.creditAccountName
          ? a.creditAccountName.localeCompare(b.creditAccountName)
          : 0,
      sortOrder: sortedInfo.columnKey === 'creditAccountName' ? sortedInfo.order : null,
      render: (a, record) => <TableCell>{record.creditAccountName}</TableCell>
    },
    {
      title: 'Location',
      key: 'locationName',
      width: 15,
      sorter: (a, b) =>
        a.locationName && b.locationName ? a.locationName.localeCompare(b.locationName) : 0,
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.locationName}</TableCell>
          </>
        );
      }
    },
    // {
    //   title: 'Credit Type',
    //   key: 'creditType',
    //   width: 15,
    //   sorter: (a, b) => a.creditType.localeCompare(b.creditType),
    //   sortOrder: sortedInfo.columnKey === 'creditType' ? sortedInfo.order : null,
    //   render: (a, record) => (
    //     <TableCell>
    //       {AccountRuleCredit[record.creditType as keyof typeof AccountRuleCredit]}
    //     </TableCell>
    //   )
    // },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 15,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Action',
      key: 'actions',
      width: 10,
      fixed: 'right',
      render: (record) => {
        const menuItems: {
          key: string;
          label: JSX.Element;
          onClick?: undefined;
        }[] = [];

        if (checkAccess('UPDATE_ACCOUNT')) {
          menuItems.push({
            key: '1',
            label: (
              <Link to={`/accounts-rule/${record.id}`}>
                <Tooltip title="Update" color="blue">
                  <EditOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Tooltip>
              </Link>
            )
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const response = await get_rules_list(filter);
    // console.log('Response', response);

    const tableData: IAccountsRuleListTableData[] = [];

    for (let ind = 0; ind < response.results.length; ind++) {
      let debitAcc = await AccountsDB.getAccount(response.results[ind].debitAccountId);
      let creditAcc = await AccountsDB.getAccount(response.results[ind].creditAccountId);
      if (!debitAcc || !creditAcc) {
        const allAccounts = await get_account_detail_by_ids([
          ...new Set(
            response.results.flatMap((value) => {
              return [value.debitAccountId, value.creditAccountId];
            })
          )
        ]);
        await AccountsDB.addAccounts(allAccounts.results);
        debitAcc = await AccountsDB.getAccount(response.results[ind].debitAccountId);
        creditAcc = await AccountsDB.getAccount(response.results[ind].creditAccountId);
      }
      let locationDetails;
      if (response.results[ind].locationId !== null) {
        locationDetails = await LocationsDB.getLocation(response.results[ind].locationId);
        if (!locationDetails) {
          const allLocations = await get_location_list_for_ids([
            ...new Set(
              response.results
                .filter((value) => value.locationId !== null)
                .map((value) => {
                  return value.locationId;
                })
            )
          ]);
          await LocationsDB.addLocations(allLocations);
          locationDetails = await LocationsDB.getLocation(response.results[ind].locationId);
        }
      }
      tableData.push({
        ...response.results[ind],
        debitAccountName: typeof debitAcc === 'object' ? debitAcc?.name : '',
        creditAccountName: typeof creditAcc === 'object' ? creditAcc?.name : '',
        locationName: typeof locationDetails === 'object' ? locationDetails.name : ''
      });
    }

    setRuleList(tableData);
    setTotalData(response.total);
    setIsLoading(false);
  };

  const handleLocationChange = () => {
    form.resetFields(['debitAccountId', 'creditAccountId']);
    setLocationId(form.getFieldValue(['locationId']));
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            {/* <div className="flex flex-wrap items-center justify-end"> */}
            <div>
              <TableFilter
                dateCustom={false}
                defaultValues={{
                  value: '',
                  skip: 0,
                  count: 100,
                  locationId: preferenceLocationId ? preferenceLocationId : 1
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                style={'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-7 gap-2'}
                styleforbuttons={'flex justify-end items-center'}
                buttons={
                  <>
                    {checkAccess('CREATE_ACCOUNT') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate('/accounts-rule/new')}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/accounts-rule/new"
                        />
                      </div>
                    )}
                  </>
                }>
                <LocationSearchV2
                  hasParentFormItem={false}
                  name={'locationId'}
                  onSelect={handleLocationChange}
                />

                <AccountSearchV2
                  hasParentFormItem={false}
                  name={'debitAccountId'}
                  label="Debit Account"
                  showAll={false}
                  locationId={locationId}
                />

                {/* <AccountsTypeSearch
                  formData={{ formName: 'debitAccountId', label: 'Debit Account' }}
                  required={false}
                  isAll={false}
                  accountList={debitAccountList}
                  setAccountList={setDebitAccountList}
                  locationId={locationId}
                /> */}

                <AccountSearchV2
                  hasParentFormItem={false}
                  name={'creditAccountId'}
                  label="Credit Account"
                  showAll={false}
                  locationId={locationId}
                />

                {/* <AccountsTypeSearch
                  formData={{ formName: 'creditAccountId', label: 'Credit Account' }}
                  required={false}
                  isAll={false}
                  accountList={creditAccountList}
                  setAccountList={setCreditAccountList}
                  locationId={locationId}
                /> */}
                {/* <Form.Item label="Debit Type" name="debitType">
                    <Select placeholder="Select debit type" allowClear={true}>
                      {(Object.keys(AccountRuleDebit) as Array<keyof typeof AccountRuleDebit>).map(
                        (key) => (
                          <Select.Option key={key} value={key}>
                            {AccountRuleDebit[key]}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </Form.Item> */}
                {/* <Form.Item label="Credit Type" name="creditType">
                    <Select placeholder="Select credit type" allowClear={true}>
                      {(
                        Object.keys(AccountRuleCredit) as Array<keyof typeof AccountRuleCredit>
                      ).map((key) => (
                        <Select.Option key={key} value={key}>
                          {AccountRuleCredit[key]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item> */}
                <Form.Item label="Event Type" name="eventType">
                  <Select
                    placeholder="Select event type"
                    allowClear={true}
                    dropdownMatchSelectWidth={false}>
                    {(Object.keys(AccountRulesEvent) as Array<keyof typeof AccountRulesEvent>).map(
                      (key) => (
                        <Select.Option key={key} value={key}>
                          {AccountRulesEvent[key]}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          columns={columns}
          data={ruleList}
          notshowPagination={true}
          customScroll={{ x: 1300, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: totalData,
            size: size,
            onPagination
          }}
          tableName={'rule-list'}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default AccountsRuleList;
