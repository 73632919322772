import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  ICreatePurchaseAndSellOrderFormRequest,
  ICreatePurchaseOrder,
  ICreatePurchaseReturnRequest,
  ICreatePurchaseReturnResponse,
  IPurchaseAgentBillCancelPayload,
  IPurchaseAgentBillRange,
  IPurchaseList,
  IPurchaseOrderCreateResponse,
  IPurchasePaymentRequest,
  IPurchaseReturn,
  IUpdatePurchaseOrder
} from './types';
import { ICreateCommonResponse, IDeleteCommonResponse } from '../category/types';

export const create_purchase_order_mutation = async (values: ICreatePurchaseOrder) => {
  const response: AxiosResponse<IPurchaseOrderCreateResponse, any> = await http.store(
    'purchase-order',
    values
  );
  return response;
};
export const edit_purchase_order_mutation = async (values: IUpdatePurchaseOrder) => {
  const response: AxiosResponse<IPurchaseOrderCreateResponse, any> = await http.update(
    'purchase-order/' + values.id,
    values
  );
  return response;
};
export const create_purchase_return_mutation = async (values: ICreatePurchaseReturnRequest) => {
  const response: AxiosResponse<ICreatePurchaseReturnResponse, any> = await http.store(
    'purchase-return',
    values
  );
  return response;
};
export const delete_purchase_order = async (id: number) => {
  const response: AxiosResponse<IDeleteCommonResponse, any> = await http.remove(
    'purchase-order/' + id
  );
  return response;
};

export const create_purchase_payment = async (values: IPurchasePaymentRequest) => {
  const response: AxiosResponse<any, any> = await http.store(
    `purchase/payment/${values.id}`,
    values
  );
  return response;
};

export const create_purchase_form_order = async (
  values: ICreatePurchaseAndSellOrderFormRequest
) => {
  const response = await http.store('order/', values);
  return response;
};

export const update_purchase_print_count_mutation = async (purchaseId: string | number) => {
  const response: AxiosResponse<IPurchaseList, any> = await http.update(
    `/purchase/print-count/${purchaseId}`
  );
  return response;
};

export const create_purchase_agent_bill_range = async (values: {
  invoiceNumber: string;
  min: number;
  max: number;
  agentId: number;
}) => {
  const response = await http.store('purchase-agent-bill/', values);
  return response;
};

export const update_purchase_agent_bill_status = async (id: number) => {
  const response: AxiosResponse<IPurchaseAgentBillRange> = await http.update(
    'purchase-agent-bill/change-status/' + id
  );

  return response.data;
};

export const cancel_purchase_agent_bill = async (
  id: number,
  payload: IPurchaseAgentBillCancelPayload
) => {
  const response = await http.update('purchase-agent-bill/cancel/' + id, payload);
  return response;
};
