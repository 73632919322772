import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { IJournalListResponseData, IJournalViewResponseTableData } from '@/services/accounts/types';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

interface Props {
  journalData: IJournalListResponseData;
  lines: IJournalViewResponseTableData[];
}

function JournalDetails({ journalData, lines }: Props) {
  const columns: ColumnsType<IJournalViewResponseTableData> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 5,
      render: (a, b, c) => <TableCell>{c + 1}.</TableCell>
    },
    {
      title: 'Account',
      key: 'accountId',
      width: 15,
      render: (a, record) => (
        <TableCell>
          <Link to={'/accounts/journal-lines?accountId=' + record.accountId} target="_blank">
            {record.accountName}
          </Link>
        </TableCell>
      )
    },
    {
      title: 'Description',
      key: 'description',
      width: 15,
      render: (a, record) => <TableCell>{record.description}</TableCell>
    },
    {
      title: 'Running Balance',
      key: 'runningBalance',
      width: 15,
      render: (a, record) => (
        <TableCell>
          {record.runningBalance ? nepaliNumberFormatter(record.runningBalance) : ''}
        </TableCell>
      )
    },
    {
      title: 'Debit',
      key: 'debit',
      width: 15,
      render: (a, record) => <TableCell>{nepaliNumberFormatter(record.debit)}</TableCell>
    },
    {
      title: 'Credit',
      key: 'credit',
      width: 15,
      render: (a, record) => <TableCell>{nepaliNumberFormatter(record.credit)}</TableCell>
    }
  ];

  return (
    <div className="space-y-4">
      <div>
        <div>
          <span>{'Voucher No.'}</span> :{' '}
          <span className="italic font-bold">{journalData?.referenceId}</span>
        </div>
        <div>
          <span>{'Cheque Ref.'}</span> :{' '}
          <span className="italic font-bold">{journalData?.chequeRef}</span>
        </div>
        <div>
          <span>{'Reference Number'}</span> :{' '}
          <span className="italic font-bold">{journalData?.referenceNumber}</span>
        </div>

        <div>
          <span>{'Remarks'}</span> :{' '}
          <span className="italic font-bold">{journalData?.description}</span>
        </div>
      </div>

      <CustomizeTable columns={columns} notshowPagination={true} data={lines} />
    </div>
  );
}

export default JournalDetails;
