import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import { Divider, Image, PageHeader, Spin } from 'antd';
import { checkAccess } from '@/routes/acl';
import ReuseChannel from '../../channel/Reuse';
import { get_vehicle_details } from '@/services/vehicle/queries';
import { IVehicleData } from '@/services/vehicle/types';
import { get_image } from '@/components/Common/FetchImage/FetchImage';
import { API_URL } from '@/constants/config';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const VehicleView: React.FC = () => {
  const { id } = useParams();
  const [vehicleDetails, setVehicleDetails] = useState<IVehicleData>();
  const [bluebookImg, setBluebookImg] = useState<string>();
  const [insuranceImg, setInsuranceImg] = useState<string>();
  const [pollutionImg, setPollutionImg] = useState<string>();

  const { isLoading } = useQuery(['vehicleView', id], async () => {
    if (!id) {
      return;
    }
    const response = await get_vehicle_details(parseFloat(id));
    if (response.data.bluebookId) {
      const bluebookData = await get_image(`${API_URL}storage/media/${response.data.bluebookId}`);
      setBluebookImg(bluebookData);
    }
    if (response.data.insuranceId) {
      const insuranceData = await get_image(`${API_URL}storage/media/${response.data.insuranceId}`);
      setInsuranceImg(insuranceData);
    }
    if (response.data.pollutionId) {
      const pollutionData = await get_image(`${API_URL}storage/media/${response.data.pollutionId}`);
      setPollutionImg(pollutionData);
    }
    setVehicleDetails(response.data);
  });

  const breadcrumbItems = [
    {
      label: 'Vehicle',
      link: '/vehicle'
    },
    {
      label: 'View'
    }
  ];

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Spin spinning={isLoading}>
          <PageHeader title="Vehicle Details" style={{ padding: '0px' }} className="small-title" />
          <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mb-5">
            <div>
              <PageHeader subTitle=" Name" style={{ padding: '8px 0px' }} />
              {vehicleDetails && <div style={{ color: 'black' }}>{vehicleDetails.name}</div>}
            </div>
            <div>
              <PageHeader subTitle="IMEI" style={{ padding: '8px 0px' }} />
              {vehicleDetails && <div style={{ color: 'black' }}>{vehicleDetails.imei}</div>}
            </div>
            <div>
              <PageHeader subTitle="Vehicle Number" style={{ padding: '8px 0px' }} />
              {vehicleDetails && <div style={{ color: 'black' }}>{vehicleDetails.number}</div>}
            </div>
          </div>

          <PageHeader title="Medias" style={{ padding: '0px' }} className="small-title" />
          <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mb-5">
            <div>
              <PageHeader subTitle="Bluebook" style={{ padding: '8px 0px' }} />
              <div style={{ color: 'black' }}>
                {bluebookImg ? (
                  <Image width={150} height={180} className="object-contain" src={bluebookImg} />
                ) : (
                  'N/A'
                )}
              </div>
            </div>

            <div>
              <PageHeader subTitle="Insurance" style={{ padding: '8px 0px' }} />
              <div style={{ color: 'black' }}>
                {insuranceImg ? (
                  <Image width={150} height={180} className="object-contain" src={insuranceImg} />
                ) : (
                  'N/A'
                )}
              </div>
            </div>

            <div>
              <PageHeader subTitle="Pollution" style={{ padding: '8px 0px' }} />
              <div style={{ color: 'black' }}>
                {pollutionImg ? (
                  <Image width={150} height={180} className="object-contain" src={pollutionImg} />
                ) : (
                  'N/A'
                )}
              </div>
            </div>
          </div>

          <PageHeader title="Reminders" style={{ padding: '0px' }} className="small-title" />
          <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mb-5">
            <div>
              <PageHeader subTitle="Bluebook Reminder" style={{ padding: '8px 0px' }} />
              <div style={{ color: 'black' }}>
                {vehicleDetails?.blueBookReminderDate
                  ? convertUTCStringtoLocalString(
                      vehicleDetails.blueBookReminderDate,
                      DEFAULT_DATE_FORMAT
                    )
                  : 'N/A'}
              </div>
            </div>

            <div>
              <PageHeader subTitle="Insurance Reminder" style={{ padding: '8px 0px' }} />
              <div style={{ color: 'black' }}>
                {vehicleDetails?.insuranceReminderDate
                  ? convertUTCStringtoLocalString(
                      vehicleDetails.insuranceReminderDate,
                      DEFAULT_DATE_FORMAT
                    )
                  : 'N/A'}
              </div>
            </div>

            <div>
              <PageHeader subTitle="Pollution Reminder" style={{ padding: '8px 0px' }} />
              <div style={{ color: 'black' }}>
                {vehicleDetails?.pollutionReminderDate
                  ? convertUTCStringtoLocalString(
                      vehicleDetails.pollutionReminderDate,
                      DEFAULT_DATE_FORMAT
                    )
                  : 'N/A'}
              </div>
            </div>

            <div>
              <PageHeader subTitle="Servicing Reminder" style={{ padding: '8px 0px' }} />
              <div style={{ color: 'black' }}>
                {vehicleDetails?.servicingReminderDate
                  ? convertUTCStringtoLocalString(
                      vehicleDetails.servicingReminderDate,
                      DEFAULT_DATE_FORMAT
                    )
                  : 'N/A'}
              </div>
            </div>

            <div>
              <PageHeader subTitle="Servicing Reminder Distance" style={{ padding: '8px 0px' }} />
              <div style={{ color: 'black' }}>
                {vehicleDetails?.servicingReminderDistance
                  ? vehicleDetails.servicingReminderDistance
                  : 'N/A'}
              </div>
            </div>
          </div>
        </Spin>

        {checkAccess('READ_CHANNEL') && (
          <ReuseChannel
            slug={`vehicles_${id}`}
            withReferenceandId={{ reference: 'vehicles', referenceId: id || 0 }}
          />
        )}
      </AppContent>
    </div>
  );
};

export default VehicleView;
