import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { CreatePartialPaymentReverse, IFonePay } from './types';

export const create_fonepay_mutation = async (values: IFonePay) => {
  const response: AxiosResponse<any> = await http.store('payment/fonepay/' + values.prn, values);
  return response;
};

export const reverse_full_payment_mutation = async (paymentId: number) => {
  const response: AxiosResponse<any> = await http.update('sell/full-reverse-payment/' + paymentId);
  return response;
};

export const reverse_partial_payment_mutation = async (values: CreatePartialPaymentReverse) => {
  const response: AxiosResponse<any> = await http.update(
    'sell/partial-reverse-payment/' + values.paymentId,
    values
  );
  return response;
};
