import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import { Button, DatePicker, Select, Slider, Typography, message } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import React, { useState, useEffect } from 'react';
import AppContent from '../../../components/Common/Content/Content';
import { CustomDatePresets } from '../../sqlsource/report/utils/datePresets';
import { get_user_track } from '../../../services/user-tracking/queries';
import { IUserLive, IUserLiveData } from '../../../services/user-tracking/types';
import moment from 'moment';
import { convertLocalToUTCString } from '../../../utils/convertToUTC';
import { UsersSearch } from '../../../components/Common/UsersSearch';

interface LatLng {
  lat: number;
  lng: number;
}
const { RangePicker } = DatePicker;

let intervalVal: number | undefined;
let markerVal: google.maps.Marker | undefined;
let polyLineVal: google.maps.Polyline | undefined;
let count = 0;
let rotation = 0;
type MapType = google.maps.Map | null;

const UserHistory = () => {
  const [pause, setPause] = useState(true);
  const [reset, setReset] = useState(true);
  const [speed, setSpeed] = useState(5);
  const [mapData, setMapData] = useState<MapType>();
  const [path, setPathPosition] = useState<LatLng[]>([]);
  const [dateData, setDateData] = useState({
    startDate: CustomDatePresets['Today'][0].utc().format(),
    endDate: CustomDatePresets['Today'][1].utc().format()
  });
  const [allData, setAllData] = useState<IUserLiveData>();
  const [center, setCenter] = useState({
    lat: 27.674804466363256,
    lng: 85.35849945181269
  });
  const [selectedUserId, setSelectedUserId] = useState<number>();

  const onSearch = async () => {
    if (selectedUserId) {
      const formattedStartDate = convertLocalToUTCString(dateData.startDate);
      const formattedEndDate = convertLocalToUTCString(dateData.endDate);

      const filter = `userId=${selectedUserId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      const response = await get_user_track(filter);

      const convertToPath: LatLng[] = response.data.results.map((val: IUserLive) => {
        return {
          lat: val.latitude,
          lng: val.longitude
        };
      });

      if (convertToPath.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        convertToPath.forEach((point) => {
          bounds.extend(new window.google.maps.LatLng(point.lat, point.lng));
        });

        if (mapData) {
          mapData.fitBounds(bounds);
        }
      }

      setAllData(response.data);
      setPathPosition(convertToPath);
      setReset(true);
    } else {
      message.error('Please select a user');
    }
  };

  const onChange: RangePickerProps['onChange'] = (dates) => {
    if (dates) {
      setDateData({
        ...dateData,

        startDate: (dates[0] as moment.Moment).utc().format(),
        endDate: (dates[1] as moment.Moment).utc().format()
      });
    }
  };

  useEffect(() => {
    if (reset) {
      count = 0;
      setReset(false);
      rotation = 0;
    }
  }, [reset]);

  useEffect(() => {
    if (markerVal && !pause) animateMarker(markerVal);
    return () => {
      clearInterval(intervalVal);
      intervalVal = undefined;
    };
  }, [pause]);

  function animateMarker(marker: google.maps.Marker) {
    let currentCount = count;

    intervalVal = window.setInterval(() => {
      if (currentCount === path.length) {
        clearInterval(intervalVal);
        count = 0;
        rotation = 0;
        currentCount = 0;
        if (path.length > 0) {
          animateMarker(marker);
        }
        return;
      }

      rotateMarker(marker);
      if (path.length > currentCount) {
        marker.setPosition({
          lat: path[currentCount].lat,
          lng: path[currentCount].lng
        });
      }

      currentCount += 1;
      count = currentCount;
    }, 1000 / speed);
  }

  const rotateMarker = (marker: google.maps.Marker) => {
    if (
      allData &&
      allData.results &&
      count + 1 < path.length &&
      allData.results[count] &&
      allData.results[count + 1]
    ) {
      const heading = google.maps.geometry.spherical.computeHeading(path[count], path[count + 1]);

      marker.setIcon({
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 5,
        strokeColor: 'lime',
        rotation: heading
      });

      rotation = heading;
    }
  };

  return (
    <AppContent breadcrumbItems={breadcrumbItems}>
      <div className="grid grid-cols-3 gap-5 mb-5">
        <UsersSearch
          isAll={false}
          onSelect={(value) => {
            setSelectedUserId(value);
          }}
        />
        <RangePicker
          defaultValue={CustomDatePresets['Today']}
          ranges={CustomDatePresets}
          onChange={onChange}
          format={'YYYY-MM-DD'}
          allowClear={false}
        />

        <Button onClick={() => onSearch()} disabled={!pause}>
          Search
        </Button>
      </div>
      <Typography.Text>Speed:</Typography.Text>
      <div className="grid grid-cols-3 gap-5 mb-5">
        <Slider
          value={speed}
          disabled={!pause}
          onChange={(val) => setSpeed(val)}
          min={1}
          max={100}
        />
        <Button onClick={() => setPause((prevPause) => !prevPause)}>
          {pause ? 'Play' : 'Pause'}
        </Button>

        <Button onClick={() => setReset(true)} disabled={!pause}>
          Reset
        </Button>
      </div>

      <LoadScript googleMapsApiKey={'AIzaSyATJbgY-H04JRS-GO4GaKRHPPDHS0p2ENA'}>
        <GoogleMap
          center={center}
          zoom={6}
          options={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false
          }}
          onLoad={(map) => {
            setMapData(map);

            setTimeout(() => {
              map.setCenter(center);
            }, 500);

            const bounds = new window.google.maps.LatLngBounds();
            allData?.results.forEach((point: IUserLive) => {
              bounds.extend(new window.google.maps.LatLng(point.latitude, point.longitude));
            });

            map.fitBounds(bounds);
          }}
          onClick={(val) => console.log(val)}
          mapContainerStyle={containerStyle}>
          {window.google && path.length > 0 && (
            <>
              <Marker
                position={{
                  lat: path[0].lat,
                  lng: path[0].lng
                }}
                label={'Start'}
                icon={{
                  url: `https://freshktm.github.io/ERP-UI/Flag.svg`,
                  labelOrigin: new google.maps.Point(17, -10),
                  scaledSize: new google.maps.Size(37, 37)
                }}
              />

              <Marker
                ref={(marker) => {
                  if (marker) {
                    markerVal = marker.marker;
                  }
                }}
                position={
                  path[count]
                    ? {
                        lat: path[count].lat,
                        lng: path[count].lng
                      }
                    : {
                        lat: path[path.length - 1].lat,
                        lng: path[path.length - 1].lng
                      }
                }
                icon={{
                  path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  rotation: rotation,
                  scale: 5,
                  strokeColor: 'lime'
                }}
              />

              <Marker
                position={{
                  lat: path[path.length - 1].lat,
                  lng: path[path.length - 1].lng
                }}
                label={'End'}
                icon={{
                  url: `https://freshktm.github.io/ERP-UI/Flag.svg`,
                  labelOrigin: new google.maps.Point(17, -10),
                  scaledSize: new google.maps.Size(37, 37)
                }}
              />
              {path.length > 1 && (
                <>
                  <Polyline
                    ref={(polyline) => {
                      if (polyline && polyline.state.polyline) {
                        polyLineVal = polyline.state.polyline;
                      }
                    }}
                    visible={true}
                    editable={false}
                    path={path}
                    options={{
                      strokeColor: '#145da0',
                      strokeOpacity: 0.5,
                      strokeWeight: 4,
                      clickable: false,
                      icons: [
                        {
                          icon: {
                            path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                            strokeOpacity: 1,
                            strokeColor: '#000000',

                            scale: 3
                          }
                        }
                      ]
                    }}
                  />
                  <Polyline
                    visible={true}
                    editable={false}
                    path={path.slice(path.length / 4, path.length / 2 + 1)}
                    options={{
                      strokeColor: ' #FF0000',
                      strokeOpacity: 0.5,
                      strokeWeight: 4,
                      clickable: false,
                      icons: [
                        {
                          icon: {
                            path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                            strokeOpacity: 1,
                            strokeColor: '#000000',

                            scale: 3
                          },
                          offset: '0',
                          repeat: '200px'
                        }
                      ]
                    }}
                  />
                  <Polyline
                    visible={true}
                    editable={false}
                    path={path.slice(path.length / 2, (path.length / 4) * 3 + 1)}
                    options={{
                      strokeColor: ' #00FF00',
                      strokeOpacity: 0.5,
                      strokeWeight: 4,
                      clickable: false,
                      icons: [
                        {
                          icon: {
                            path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                            strokeOpacity: 1,
                            strokeColor: '#000000',

                            scale: 3
                          },
                          offset: '0',
                          repeat: '200px'
                        }
                      ]
                    }}
                  />
                  <Polyline
                    visible={true}
                    editable={false}
                    path={path.slice((path.length / 4) * 3, path.length)}
                    options={{
                      strokeColor: ' #FFFF00',
                      strokeOpacity: 0.5,
                      strokeWeight: 4,
                      clickable: false,
                      icons: [
                        {
                          icon: {
                            path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                            strokeOpacity: 1,
                            strokeColor: '#000000',

                            scale: 3
                          },
                          offset: '0',
                          repeat: '200px'
                        }
                      ]
                    }}
                  />
                </>
              )}
            </>
          )}
        </GoogleMap>
      </LoadScript>
    </AppContent>
  );
};
const containerStyle = {
  height: '80vh'
};
const breadcrumbItems = [
  {
    label: 'user',
    link: '/users'
  },
  {
    label: 'History',
    link: '/users/history'
  }
];

export default UserHistory;
