import { Button, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

interface ButtonProps {
  onClick?: () => void;
  text: string;
  marginTop?: string;
  backgroundColor?: string;
  Linkto?: string;
  marginLeft?: string;
  textColor?: string;
}

const CustomGreenButton = ({
  onClick,
  text,
  backgroundColor,
  marginTop,
  Linkto,
  marginLeft,
  textColor
}: ButtonProps) => {
  return (
    <>
      {Linkto ? (
        <>
          <Link to={Linkto}>
            {/* <Tooltip title={text} color="green"> */}
            <Button
              type="default"
              // className="ml-5"
              // onClick={onClick}
              style={{
                borderRadius: '5px',
                backgroundColor: '#1890ff',
                color: 'white',
                marginTop: marginTop ? marginTop : '',
                marginLeft: marginLeft ? marginLeft : '10px'
              }}>
              {text}
            </Button>
            {/* </Tooltip> */}
          </Link>
        </>
      ) : (
        <>
          {/* <Tooltip title={text} color="green"> */}
          <Button
            type="default"
            // className="ml-5"
            onClick={onClick}
            style={{
              borderRadius: '5px',
              backgroundColor: '#1890ff',
              color: 'white',
              marginTop: marginTop ? marginTop : '',
              marginLeft: marginLeft ? marginLeft : '10px'
            }}>
            {text}
          </Button>
          {/* </Tooltip> */}
        </>
      )}
    </>
  );
};

export default CustomGreenButton;
