import { EditFilled, PlusCircleFilled, DownOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, message, Table, Tooltip, Dropdown, Menu, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import AppContent from '../../../../../components/Common/Content/Content';
import CustomButton from '../../../../../components/Common/CustomButton/CustomButton';
import DeleteConfirmModal from '../../../../../components/Common/Deleteconfirm/DeleteConfirmModal';
import DiscussionChatModal from '../../../../../components/Common/DiscussionChatModal';
import ActionDropdown from '../../../../../components/Common/Dropdownactions';
import { delete_users_address_mutation } from '../../../../../services/users/mutations';
import { get_user_details } from '../../../../../services/users/queries';
import { IDataFromServer, IUserAddressDataFromServer } from '../../../../../services/users/types';
import CustomUpdateIcon from '../../../../../components/Common/CustomIcons/CustomUpdateIcon';

const AddressList: React.FC = () => {
  const navigate = useNavigate();
  const [addressData, setAddressData] = useState<IUserAddressDataFromServer[]>();
  const [isloading, setIsloading] = useState(true);
  const { id } = useParams();
  const { refetch } = useQuery(
    ['users', id],
    async () => get_user_details(parseInt(id as string)),
    {
      retry: false,
      onSuccess: (data: IDataFromServer) => {
        setAddressData(data.addresses);
        setIsloading(false);
        // setUserDetails(data.user);
      }
    }
  );
  const deleteAddressMutation = useMutation(delete_users_address_mutation, {
    onError: (e: any) => {
      message.error(`${e.response.data.message}`, 5);
      setIsloading(false);
    },
    onSuccess: (data: any) => {
      if (data) {
        message.success('Address deleted successfully');
        setIsloading(true);
        refetch();
      }
    }
  });

  const columns: ColumnsType<IUserAddressDataFromServer> = [
    {
      title: 'Address Line 1',
      render: (record) => (
        <Link to={`/users/${id}/address/${record.id}`} color="black">
          {record.addressLine1}
        </Link>
      )
    },
    {
      title: 'Address Line 2',
      render: (record) => (
        <Link to={`/users/${id}/address/${record.id}`} color="black">
          {record.addressLine2}
        </Link>
      )
    },
    {
      title: 'Country',
      render: (record) => (
        <Link to={`/users/${id}/address/${record.id}`} color="black">
          {record.country}
        </Link>
      )
    },
    //   {
    //     title: 'Actions',
    //     key: 'actions',
    //     fixed: 'right',
    //     render: (record: IUserAddressDataFromServer) => {
    //       const confirmFunction = () => {
    //         setIsloading(true);
    //         deleteAddressMutation.mutate(record.id);
    //       };
    //       return (
    //         <div className="flex justify-center items-center">
    //           <Tooltip title="Edit" color="blue">
    //             <Button
    //               type="text"
    //               icon={<EditFilled />}
    //               onClick={() => {
    //                 navigate(`/users/${id}/address/${record.id}`);
    //               }}></Button>
    //           </Tooltip>
    //           <DeleteConfirmModal FunctionAfterOk={confirmFunction} />
    //         </div>
    //       );
    //     }
    //   }
    // ];
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (record) => {
        const confirmFunction = () => {
          setIsloading(true);
          deleteAddressMutation.mutate(record.id);
        };
        const menu = (
          <Menu
            items={[
              {
                key: '1',
                label: <DeleteConfirmModal FunctionAfterOk={confirmFunction} />
              },
              {
                key: '2',
                label: <CustomUpdateIcon link={`/users/${id}/address/${record.id}`} />
              },
              {
                key: '3',
                label: <DiscussionChatModal slug={`address_${id}`} id={record.id} />
              }
            ]}
          />
        );
        return <ActionDropdown menu={menu} />;
      }
    }
  ];
  const breadcrumbItems = [
    {
      label: 'Users',
      link: '/users'
    },
    {
      label: 'Address',
      link: `/users/${id}/address`
    }
  ];

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <CustomButton
            onClick={() => navigate(`/users/${id}/address/new`)}
            text="Add"
            Linkto={`/users/${id}/address/new`}
            backgroundColor="#1890ff"
          />
        }>
        {/* <div className={'flex justify-end'}>
          <Link to={`/users/${id}/address/new`}>
            {' '}
            <Button
              htmlType={'button'}
              size={'small'}
              type={'primary'}
              className={'me-2'}
              onClick={() => navigate(`/users/${id}/address/new`)}>
              <PlusCircleFilled />
              <span> Add</span>
            </Button>
          </Link>
        </div> */}
        <Table
          columns={columns}
          dataSource={addressData}
          scroll={{ x: true }}
          rowKey={'id'}
          loading={isloading}
          size="small"
        />
      </AppContent>
    </div>
  );
};

export default AddressList;
