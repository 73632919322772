import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import http from '../../utils/http.utils';
import { API_URL } from '../../constants/config';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface Iprops {
  id: number;
  isIcon?: boolean;
}

export const get_signed_url = async (id: number) => {
  const response = await http.get(`${API_URL}storage/media/${id}`);
  return response.data;
};

const FetchDocument: React.FC<Iprops> = ({ id, isIcon = false }) => {
  const downloadDocument = async () => {
    const url = await get_signed_url(id);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'download');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  return (
    <>
      {isIcon ? (
        <Button type="link" onClick={() => downloadDocument()}>
          <DownloadOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
        </Button>
      ) : (
        <Button type="link" onClick={() => downloadDocument()}>
          {id}
        </Button>
      )}
    </>
  );
};

export default FetchDocument;
