import { IPurchaseAgentBillRange } from '@/services/purchases/types';
import LocalStore from '.';
import { get_purchase_agent_bill_details } from '@/services/purchases/queries';

function getTransactionError(event: Event, reject: (reason?: any) => void, type: string) {
  const instanceOfIndexDB = event.target instanceof IDBRequest;
  if (instanceOfIndexDB) {
    reject(event.target.error);
    return;
  }

  reject(new Error(`Failed to ${type} financial year on indexDB`));
}

export class BillRangeDB extends LocalStore {
  static async adds(billRangeData: IPurchaseAgentBillRange[]) {
    if (!BillRangeDB.db) await BillRangeDB.init();

    return new Promise((resolve, reject) => {
      const transaction = BillRangeDB.db.transaction('AgentsBillRange', 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'add');

      const objectStore = transaction.objectStore('AgentsBillRange');

      billRangeData.forEach(async (billRange) => {
        const checkKey = objectStore.count(billRange.id);
        checkKey.onerror = (event) => getTransactionError(event, reject, 'add');

        checkKey.onsuccess = async () => {
          if (checkKey.result == 0) {
            const request = objectStore.add(billRange);
            request.onerror = (event) => getTransactionError(event, reject, 'add');
          } else {
            await BillRangeDB.update(billRange);
          }
        };
      });
    });
  }

  static async update(agent: IPurchaseAgentBillRange) {
    if (!BillRangeDB.db) await BillRangeDB.init();

    return new Promise((resolve, reject) => {
      const transaction = BillRangeDB.db.transaction('AgentsBillRange', 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'update');

      const objectStore = transaction.objectStore('AgentsBillRange');
      const request = objectStore.get(agent.id);

      request.onsuccess = (event) => {
        if ((event.target as IDBRequest).result) {
          const requestUpdate = objectStore.put(agent);

          requestUpdate.onerror = (event) => getTransactionError(event, reject, 'update');
          requestUpdate.onsuccess = () => resolve('updated data');
        } else reject('Error, could not find id.');
      };

      request.onerror = (event) => getTransactionError(event, reject, 'update');
    });
  }

  static async getById(id: number): Promise<IPurchaseAgentBillRange> {
    if (!BillRangeDB.db) await BillRangeDB.init();

    return new Promise((resolve, reject) => {
      const transaction = BillRangeDB.db.transaction('AgentsBillRange', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'get');

      const objectStore = transaction.objectStore('AgentsBillRange');

      const request = objectStore.get(id);
      request.onsuccess = async (event) => {
        const billRange = (event.target as IDBRequest).result;
        if (billRange) {
          resolve(billRange);
          return;
        }

        const billRangeAPI = await get_purchase_agent_bill_details(id);
        await BillRangeDB.adds([billRangeAPI]);
        resolve(billRange);
      };
      request.onerror = (event) => getTransactionError(event, reject, 'get');
    });
  }

  static async search(
    value: any,
    limit = 10,
    agentId?: number
  ): Promise<IPurchaseAgentBillRange[]> {
    if (!BillRangeDB.db) await BillRangeDB.init();

    return new Promise((resolve, reject) => {
      const transaction = BillRangeDB.db.transaction('AgentsBillRange', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'get');

      const objectStore = transaction.objectStore('AgentsBillRange');
      const request = objectStore.openCursor();

      const billRanges: IPurchaseAgentBillRange[] = [];
      let count = 0;

      request.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor && count < limit) {
          const billRange = cursor.value as IPurchaseAgentBillRange;

          if (billRange) {
            const rangeDetails = billRange;

            const baseCondition = value
              ? typeof value === 'number' &&
                rangeDetails &&
                rangeDetails.min <= value &&
                rangeDetails.max >= value
              : true;

            const condition = baseCondition && (agentId ? rangeDetails.agentId === agentId : true);

            if (condition) {
              billRanges.push(billRange);
              count++;
            }

            cursor.continue();
          }
        } else {
          resolve(billRanges);
        }
      };
    });
  }
}
