export enum DiscountStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum OfferType {
  RECURRING = 'RECURRING',
  TIMER = 'TIMER'
}

export enum OfferRecurringRef {
  CATEGORY = 'CATEGORY'
}

export enum OfferTimerRef {
  PRODUCT = 'PRODUCT',
  CATEGORY = 'CATEGORY'
}
