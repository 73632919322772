import { useRef } from 'react';
import { ICashSettlementPrint } from '../../../../services/invoice/types';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'antd';
import { convertUTCStringtoLocalString } from '../../../../utils/convertToUTC';
import moment from 'moment';

const CashSettlementPrint: React.FC<ICashSettlementPrint> = ({
  sessionInfo,
  paymentInfo,
  moneyInfo,
  locationDetails,
  totalPayment,
  sellTotal,
  sellReturnTotal,
  handleModalClose
}) => {
  const printRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    async onAfterPrint() {
      if (handleModalClose) handleModalClose();
    }
  });

  return (
    <>
      <div className="flex justify-end mb-3">
        <Button type="primary" onClick={handlePrint}>
          Print
        </Button>
      </div>
      <div ref={printRef}>
        <div className="my-2 mx-5 font-pt-sans-narrow" style={{ color: 'black' }}>
          <div className="text-center font-extrabold leading-tight">
            {'Freshktm Agro Pvt. Ltd.'}
          </div>
          <div className="text-center font-bold leading-tight">{locationDetails?.name}</div>
          <div className="text-center font-bold leading-tight">{locationDetails?.address}</div>
          <div className="text-center font-bold leading-tight">{'Cash Settlement'}</div>
          <div className="grid grid-cols-1 my-2 font-semibold text-sm">
            <span className="leading-tight">{`S.Id : ${sessionInfo.id}`}</span>
            <span className="leading-tight">{`Start Date : ${convertUTCStringtoLocalString(
              sessionInfo.startTime,
              'YYYY-MM-DD hh:mm A'
            )}`}</span>
            <span className="leading-tight">{`End Date : ${convertUTCStringtoLocalString(
              sessionInfo.endTime,
              'YYYY-MM-DD hh:mm A'
            )}`}</span>
            <span className="leading-tight">{`Cashier : ${sessionInfo.userName}`}</span>
          </div>
          <div className="grid grid-cols-3 my-2 font-semibold text-sm">
            <span className="col-span-3 leading-tight">{`Total Collection Amount : ${totalPayment.toFixed(
              2
            )}`}</span>
            {paymentInfo
              ? paymentInfo.map((paymentLine) => (
                  <span className="leading-tight" key={paymentLine.method}>{`${
                    paymentLine.method
                  } : ${parseFloat(paymentLine.amount).toFixed(2)}`}</span>
                ))
              : ''}
          </div>
          <div className="grid grid-cols-1 my-2 font-semibold text-sm">
            <span className="leading-tight">{`Total Bills : ${sellTotal}`}</span>
            <span className="leading-tight">{`Total Return Bills : ${sellReturnTotal}`}</span>
          </div>
          <div className="grid grid-cols-1 my-2 font-semibold text-sm">
            <span className="leading-tight">{`Settled Amount : ${sessionInfo.totalAmount.toFixed(
              2
            )}`}</span>
            <span className="leading-tight">{`Excess/Short (Cash) : ${(
              sessionInfo.totalAmount - totalPayment
            ).toFixed(2)}`}</span>
          </div>
          <div className="border-dashed border-x-0 py-1">
            <div className="leading-tight font-semibold text-sm">Denomination :</div>
            {moneyInfo
              ? moneyInfo.map((moneyLines) => (
                  <div
                    className="grid grid-cols-3 font-semibold text-sm"
                    style={{ width: '75%' }}
                    key={moneyLines.moneyId}>
                    <span className="leading-tight text-right">{`${
                      moneyLines.totalAmount / moneyLines.quantity
                    } X`}</span>
                    <span className="leading-tight text-right">{`${moneyLines.quantity} =`}</span>
                    <span className="leading-tight text-right">{`${moneyLines.totalAmount.toFixed(
                      2
                    )}`}</span>
                  </div>
                ))
              : ''}
          </div>
          <div className="border-dashed border-x-0 border-t-0 py-1">
            <div className="grid grid-cols-3 font-semibold text-sm" style={{ width: '75%' }}>
              <span className="col-span-2 leading-tight text-right">{'Total ='}</span>
              <span className="leading-tight text-right">{`${sessionInfo.totalAmount.toFixed(
                2
              )}`}</span>
            </div>
          </div>
          <div className="py-1">
            <span className="leading-tight font-semibold text-sm">{`Print Time : ${moment()
              .local()
              .format('hh:mm A')}`}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CashSettlementPrint;
