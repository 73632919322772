import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ILogEvents } from '../../../../../services/logs/events/types';
import { useQuery } from '@tanstack/react-query';
import { get_log_events_details } from '../../../../../services/logs/events/queries';
import { Divider, PageHeader, Spin } from 'antd';
import { checkAccess } from '../../../../../routes/acl';
import ReuseChannel from '../../../../channel/Reuse';
import AppContent from '../../../../../components/Common/Content/Content';
import { convertUTCStringtoLocalString } from '../../../../../utils/convertToUTC';

const LogEventView: React.FC = () => {
  const { id } = useParams();

  const [logEventsDetails, setLogEventsDetails] = useState<ILogEvents>();

  const { isLoading } = useQuery(['logEventView', id], async () => {
    if (!id) {
      return;
    }
    const response = await get_log_events_details(parseFloat(id));
    console.log('logresponse', response);
    setLogEventsDetails(response);
  });
  const breadcrumbItems = [
    {
      label: 'Log Events',
      link: '/log-events'
    },
    {
      label: 'View'
    }
  ];
  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Spin spinning={isLoading}>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mb-5">
            <div>
              <PageHeader
                subTitle="URL"
                style={{
                  padding: '8px 0px'
                }}
              />
              {logEventsDetails && <div style={{ color: 'black' }}>{logEventsDetails.url}</div>}
            </div>

            <div>
              <PageHeader
                subTitle="Created At"
                style={{
                  padding: '8px 0px'
                }}
              />
              {logEventsDetails && (
                <div style={{ color: 'black' }}>
                  {convertUTCStringtoLocalString(logEventsDetails.createdAt)}
                </div>
              )}
            </div>
            <div>
              <PageHeader
                subTitle="Updated At"
                style={{
                  padding: '8px 0px'
                }}
              />
              {logEventsDetails && (
                <div style={{ color: 'black' }}>
                  {convertUTCStringtoLocalString(logEventsDetails.updatedAt)}
                </div>
              )}
            </div>
          </div>

          <Divider />
        </Spin>
        {checkAccess('READ_CHANNEL') && (
          <ReuseChannel
            slug={`logs_${id}`}
            withReferenceandId={{ reference: 'logs', referenceId: id || 0 }}
          />
        )}
      </AppContent>
    </div>
  );
};

export default LogEventView;
