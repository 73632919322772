import { Button, Divider, PageHeader, Spin } from 'antd';
import { useEffect, useState } from 'react';
import {
  IProcurementModal,
  IPurchaseConfirmation,
  IPurchaseConfirmationData,
  LinesForConfirmation
} from '@/services/procurement/types';
import { ILocations } from '@/services/locations/types';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list } from '@/services/locations/queries';
import VendorsDB from '@/store/localstorage/VendorDB';
import { IVendor } from '@/services/users/types';
import CustomizeTable from '../CustomizeTable/CustomizeTable';
import { ColumnsType } from 'antd/lib/table';
import TableCell from '../CustomizeTable/CustomCell';
import { IUnits } from '@/services/products/types';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { get_units_list } from '@/services/products/queries';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { find_financial_year } from '@/store/localstorage/preferences';
import UsersDB from '@/store/localstorage/UsersDB';
import DebounceButton from '../DebounceButton';

const ReusablePurchaseConfirm: React.FC<IPurchaseConfirmation> = ({
  data,
  handleSubmitClick,
  disabled
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [locationDetails, setLocationDetails] = useState<ILocations>();
  const [list, setList] = useState<IProcurementModal[]>();
  const preferenceFinancialYear = find_financial_year();
  // console.log('Data', data);

  useEffect(() => {
    if (data) {
      getAllData(data);
    }
  }, [data]);

  const getAllData = async (data: IPurchaseConfirmationData) => {
    setIsLoading(true);

    if (data.locationId) {
      let loc: ILocations = await LocationsDB.getLocation(data.locationId);
      if (!loc) {
        const allLocations = await get_location_list();
        await LocationsDB.addLocations([...allLocations.data.results]);
        loc = await LocationsDB.getLocation(data.locationId);
      }
      setLocationDetails(loc);
    }

    const mapArray: IProcurementModal[] = [];
    if (data.purchases.length > 0) {
      for (let index = 0; index < data.purchases.length; index++) {
        data.purchases[index].lines.map(async (line) => {
          let findUnit: IUnits | string = await UnitsDB.getUnit(line.unitId);
          if (!findUnit) {
            const allUnits = await get_units_list();
            await UnitsDB.addUnits(allUnits);
            findUnit = await UnitsDB.getUnit(line.unitId);
          }
          if (typeof findUnit === 'object') {
            line.unitName = findUnit.shortName;
          }
        });

        const findVendor: IVendor | string = await VendorsDB.getVendors(
          data.purchases[index].vendorId
        );
        if (typeof findVendor === 'object') {
          mapArray.push({
            ...findVendor,
            invoiceNo: data.purchases[index].invoiceNumber,
            lines: data.purchases[index].lines,
            manualDiscount: data.purchases[index].discount
          });
        }
        if (data.purchases[index].agentId) {
          const agentInfo = await UsersDB.getUser(data.purchases[index].agentId);
          if (agentInfo) {
            data.purchases[index].agentName = agentInfo.name;
            data.purchases[index].agentPhone = agentInfo.phone;
          }
        }
      }
      setList(mapArray);
    }

    setIsLoading(false);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await handleSubmitClick();
    } finally {
      setIsLoading(false);
    }
  };

  const productsColumns: ColumnsType<LinesForConfirmation> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 10,
      render: (a, b, c) => <TableCell>{c + 1}</TableCell>
    },
    {
      title: 'ProductName',
      key: 'productName',
      width: 50,
      render: (a, record) => <TableCell>{record.productName}</TableCell>
    },
    {
      title: 'HS Code',
      key: 'hsCode',
      width: 30,
      render: (a, record) => <TableCell>{record?.hsCode || 'N/A'}</TableCell>
    },
    {
      title: 'Quantity',
      key: 'quantity',
      width: 30,
      render: (a, record) => <TableCell>{`${record.quantity} ${record.unitName}`}</TableCell>
    },
    {
      title: 'Rate',
      key: 'unitPrice',
      width: 30,
      render: (a, record) => (
        <TableCell>{parseFloat(record.unitPrice.toString()).toFixed(2)}</TableCell>
      )
    },
    {
      title: 'Discount',
      key: 'discount',
      width: 30,
      render: (a, record) => (
        <TableCell>{parseFloat(record.discount.toString()).toFixed(2)}</TableCell>
      )
    },
    {
      title: 'Misc.',
      key: 'misc',
      width: 30,
      render: (a, record) => <TableCell>{parseFloat(record.misc.toString()).toFixed(2)}</TableCell>
    },
    {
      title: 'Total',
      key: 'total',
      width: 30,
      render: (a, record) => (
        <TableCell>{record.total && parseFloat(record.total.toString()).toFixed(2)}</TableCell>
      )
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <div className="grid grid-cols-3 gap-5 mb-5">
        <div>
          <PageHeader
            subTitle="Location"
            style={{
              padding: '2px 0px'
            }}
          />
          {data?.locationId && (
            <div>
              {locationDetails?.name}, {locationDetails?.address}, {locationDetails?.state},{' '}
              {locationDetails?.city}, {locationDetails?.country}
            </div>
          )}
          {data?.locationId && <div>{locationDetails?.email}</div>}
        </div>
        {data?.purchases.map((agent) =>
          agent.agentId ? (
            <div key={agent.agentId}>
              <PageHeader
                subTitle="Agent"
                style={{
                  padding: '2px 0px'
                }}
              />
              <div>
                {agent.agentName}, {agent.agentPhone}
              </div>
            </div>
          ) : null
        )}
        <div>
          {data?.date && (
            <div className="text-right p-2">
              <span className="font-semibold">Date :</span> {new Date(data.date).toDateString()}
            </div>
          )}
        </div>
      </div>
      {list?.map((vendor) => (
        <div key={vendor.invoiceNo ? vendor.id + vendor.invoiceNo : vendor.id}>
          <Divider />
          <PageHeader
            subTitle={
              vendor.user.name +
              '(Invoice No. : ' +
              vendor.id +
              '-' +
              preferenceFinancialYear +
              '-' +
              vendor.invoiceNo +
              ')'
            }
            style={{
              padding: '2px 0px'
            }}
          />
          <div>
            {vendor.user.phone}, {vendor.user.email}
          </div>
          <div>
            <span className="font-semibold">Manual Discount :</span> {vendor.manualDiscount}
          </div>
          <CustomizeTable columns={productsColumns} notshowPagination={true} data={vendor.lines} />
        </div>
      ))}

      <Divider />
      {data?.grandTotal ? (
        <div className="font-semibold">Grand Total : {nepaliNumberFormatter(data.grandTotal)}</div>
      ) : (
        ''
      )}
      <div className="flex justify-end mt-5">
        <DebounceButton disabled={disabled || isLoading} type="primary" onClick={handleSubmit}>
          Confirm
        </DebounceButton>
      </div>
    </Spin>
  );
};

export default ReusablePurchaseConfirm;
