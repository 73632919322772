import { shortNameHiearchy } from '../../../../pages/sell/sell-order/view';
import { ISellInvoice, RouteInformation } from '../../../../services/invoice/types';
import { get_location_details } from '../../../../services/locations/queries';
import { get_product_details, get_units_list } from '../../../../services/products/queries';
import { IProductType, IUnits } from '../../../../services/products/types';
import { get_routes_details } from '../../../../services/routes/queries';
import { get_sell_order_line_details } from '../../../../services/sell/queries';
import { ICreateInvoiceResponse } from '../../../../services/settings/types';
import { get_user_details } from '../../../../services/users/queries';
import LocationsDB from '../../../../store/localstorage/LocationsDB';
import ProductsDB from '../../../../store/localstorage/ProductsDB';
import RoutesDB from '../../../../store/localstorage/RoutesDB';
import UnitsDB from '../../../../store/localstorage/UnitsDB';
import UsersDB from '../../../../store/localstorage/UsersDB';
import { getPrintLocation } from '../BillPrint/services';

export const getMultipleSellOrderPrintData = async (
  selectedProducts: any,
  curr: ICreateInvoiceResponse
) => {
  const allInformation: ISellInvoice[] = [];

  for (let index = 0; index < selectedProducts.length; index++) {
    // Fetch Created By
    const createdByDetails = {
      name: selectedProducts[index]?.createdByName ? selectedProducts[index]?.createdByName : ''
    };

    // Fetch Vendor
    const vendorDetails = await getVendorDetails(selectedProducts[index].address.userId);

    // Fetch Route
    const routeDetails = await getRouteDetails(selectedProducts[index].address.routeId);

    // Fetch Location
    const locationDetails = await getLocationDetails(selectedProducts[index].locationId);

    const linesResponse = (await get_sell_order_line_details(selectedProducts[index].id)).data;

    const totalQuantity: any = {};

    // Taxable, Non Taxable and Vat
    let totalVat = 0;
    let totalTaxable = 0;
    let totalNonTaxable = 0;
    for (let index = 0; index < linesResponse.length; index++) {
      const product = await getProductDetails(linesResponse[index].productId);

      const unit = await getUnitDetails(linesResponse[index].unitId);

      if (!totalQuantity[unit.shortName]) {
        totalQuantity[unit.shortName] = linesResponse[index].quantity;
      } else {
        totalQuantity[unit.shortName] += linesResponse[index].quantity;
      }
      linesResponse[index].unitName = unit.shortName;
      linesResponse[index].productName = product.name;

      totalVat += linesResponse[index].vat;
      if (linesResponse[index].vat > 0) {
        totalTaxable += linesResponse[index].totalAmount;
      } else {
        totalNonTaxable += linesResponse[index].totalAmount;
      }
    }

    const totalQuantityString = await getTotalQuantityString(totalQuantity);

    const printLocation = await getPrintLocation();

    allInformation.push({
      sellDetails: {
        ...selectedProducts[index],
        totalVat: totalVat,
        totalTaxable: totalTaxable,
        totalNonTaxable: totalNonTaxable,
        totalQuantity: totalQuantityString,
        printLocation: printLocation
      },
      customerDetails: {
        ...vendorDetails,
        email: vendorDetails.email,
        routeName: routeDetails ? routeDetails.name : '',
        address: locationDetails.address,
        city: locationDetails.city,
        country: locationDetails.country
      },
      lines: linesResponse,
      invoiceLayouts: curr,
      createdByDetails: createdByDetails
    });
  }

  return allInformation as ISellInvoice[];
};

const getVendorDetails = async (vendorUserId: number) => {
  let vendorDetails = await UsersDB.getUser(vendorUserId);
  if (!vendorDetails) {
    vendorDetails = (await get_user_details(vendorUserId)).user;
    await UsersDB.addUsers([vendorDetails]);
  }

  return vendorDetails;
};

const getRouteDetails = async (routeId?: number) => {
  let routeInformation;
  if (routeId) {
    routeInformation = await RoutesDB.getRoute(routeId);
    if (!routeInformation) {
      const routesList = await get_routes_details(routeId);
      RoutesDB.addRoutes([routesList]);
      routeInformation = await RoutesDB.getRoute(routeId);
    }
  } else {
    routeInformation = {};
  }

  return routeInformation as RouteInformation;
};

const getLocationDetails = async (locationId: number) => {
  let locationDetails = await LocationsDB.getLocation(locationId);
  if (!locationDetails) {
    locationDetails = await get_location_details(locationId);
    await LocationsDB.addLocations([locationDetails]);
  }

  return locationDetails;
};

const getProductDetails = async (productId: number) => {
  let product = await ProductsDB.getProduct(productId);
  if (!product) {
    product = await get_product_details(productId);
    await ProductsDB.addProducts([product]);
  }

  return product as IProductType;
};

const getUnitDetails = async (unitId: number) => {
  let findUnit = await UnitsDB.getUnit(unitId);
  if (!findUnit) {
    const allUnits = await get_units_list();
    await UnitsDB.addUnits(allUnits);
    findUnit = await UnitsDB.getUnit(unitId);
  }

  return findUnit as IUnits;
};

const getTotalQuantityString = (totalQuantity: any) => {
  let totalQuantityString = '';
  const totalQuantityArray: any[] = [];
  for (const key in totalQuantity) {
    totalQuantityArray.push({
      tqty: totalQuantity[key],
      shortName: key
    });
  }
  totalQuantityArray.sort((a: any, b: any) => {
    return shortNameHiearchy.indexOf(a.shortName) - shortNameHiearchy.indexOf(b.shortName);
  });
  for (let ind = 0; ind < totalQuantityArray.length; ind++) {
    totalQuantityString += ` ${totalQuantityArray[ind].tqty} ${totalQuantityArray[ind].shortName}`;
  }

  return totalQuantityString;
};
