import { Collapse, PageHeader, Spin, Table } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { CustomModal } from '../../components/Common/CustomModal';
import DiscussionChatModal from '../../components/Common/DiscussionChatModal';
import { checkAccess } from '../../routes/acl';
import { get_channel_list_filter } from '../../services/channels/queries';
import { get_sell_recent_list } from '../../services/sell/queries';
import { nepaliNumberFormatter, numberDecimalFormatter } from '../../utils/numberFormatter';
import ReusableSellView from '../sell/view/ReusableView';
import { DEFAULT_DATE_FORMAT } from '@/constants';

interface DataType {
  numberOfSales: number;
  numberOfReturns: number;
  numberOfPayments: number;
  totalSales: number;
  totalPayments: number;
  totalReturns: number;
  health: string;
  sellFrequency: number;
  averageSellAmount: number;
  averagePaymentAmount: number;
  averageReturnAmount: number;
  averageSellReturnTimeDifference: number;
  averageSellPaymentTimeDifference: number;
}
interface ScoreBoardProps {
  data: {
    userId: number;
    allData: DataType;
  };
  fromCustomerDetails?: boolean;
}
interface RecentChannelType {
  id: number;
  name: string;
  slug: string;
  reference: string;
  referenceId: number;
  priority: string;
  color: string;
  closed: boolean;
  createdAt: Date;
  updatedAt: Date;
}
export interface RecentDataType {
  id: number;
  date: Date;
  referenceNumber: string;
  sellOrderId: number;
  locationId: number;
  addressId: number;
  customerId: number;
  taxId: number;
  misc: string;
  shipping: number;
  discount: number;
  adjustment: number;
  totalPrice: number;
  totalDiscount: number;
  totalTax: number;
  totalAmount: number;
  amountPaid: number;
  amountReturned: number;
  createdBy: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface newRecentDatatype {
  sells: RecentDataType[];
}

const dtypes = {
  averagePaymentAmount: 'Average Payment Amount',
  averageReturnAmount: 'Average Return Amount',
  averageSellAmount: 'Average Sell Amount',
  averageSellPaymentTimeDifference: 'Average Sell Payment Time Difference',
  averageSellReturnTimeDifference: 'Average Sell Return Time Difference',
  health: 'Health',
  numberOfPayments: 'No. of Payments',
  numberOfReturns: 'No. of Returns',
  numberOfSales: 'No. of Sales',
  sellFrequency: 'Sell Frequency',
  totalPayments: 'Total Payments',
  totalReturns: 'Total Returns',
  totalSales: 'Total Sales'
};

type dtypess = keyof typeof dtypes;

const columnsChannel: ColumnsType<RecentChannelType> = [
  {
    title: 'ID',
    key: 'id',
    render: (record) => {
      return <div color="black">{record.id}</div>;
    }
  },
  {
    title: 'Name',
    key: 'name',
    render: (record) => {
      return <div color="black">{record.name}</div>;
    }
  },
  {
    title: 'Reference',
    key: 'reference',
    render: (record) => {
      return <div color="black">{record.reference}</div>;
    }
  },
  {
    title: 'Priority',
    key: 'priority',
    render: (record) => {
      return <div color="black">{record.priority}</div>;
    }
  },
  {
    title: 'Date',
    key: 'createdAt',
    render: (record) => {
      return (
        <div color="black">{moment(record.createdAt).local().format(DEFAULT_DATE_FORMAT)}</div>
      );
    }
  },
  {
    title: 'Color',
    key: 'color',
    render: (record) => {
      return <div color="black">{record.color}</div>;
    }
  },
  {
    title: 'Closed Status',
    key: 'closed',
    render: (record) => {
      return <div color="black">{record.closed}</div>;
    }
  },
  {
    title: 'View',
    key: 'id',
    render: (record) => {
      return <DiscussionChatModal slug={record.reference} id={record.referenceId} />;
    }
  }
];
const ScoreBoard: React.FC<ScoreBoardProps> = ({ data, fromCustomerDetails = false }) => {
  const [dataList, setDataList] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [recentData, setRecentData] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);

  const getInfo = async () => {
    if (data) {
      setLoading(true);
      setDataList(data.allData);
      if (!fromCustomerDetails) {
        const response = await get_sell_recent_list({ customerId: data.userId });
        // console.log(data, response);

        // const responseChannel = await get_channel_list_filter(`customerId=${data.userId}&count=5`);
        // console.log(responseChannel);
        // console.log('response -->', response);
        setRecentData(response.data);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    getInfo();
  }, [data]);
  // console.log('Data list', dataList);
  // console.log('Recent Data-->', recentData);
  // console.log('Data', data);
  const columns: ColumnsType<RecentDataType> = [
    {
      title: 'ID',
      key: 'id',
      render: (record) => {
        return <div color="black">{record.id}</div>;
      }
    },
    {
      title: 'Reference',
      key: 'referenceNumber',
      render: (record) => {
        return (
          <a
            color="black"
            onClick={() => {
              setIsModalOpen(true);
              setId(record.id);
            }}>
            {record.referenceNumber}
          </a>
        );
      }
    },
    {
      title: 'Total Amount',
      key: 'totalAmount',
      render: (record) => {
        return <div color="black">{nepaliNumberFormatter(record.totalAmount)}</div>;
      }
    },
    {
      title: 'Amount Paid',
      key: 'amountPaid',
      render: (record) => {
        return <div color="black">{nepaliNumberFormatter(record.amountPaid)}</div>;
      }
    },
    {
      title: 'Amount Returned',
      key: 'amountReturned',
      render: (record) => {
        return <div color="black">{nepaliNumberFormatter(record.amountReturned)}</div>;
      }
    }
  ];

  // console.log('ScoreboardData', data);
  return (
    <Spin spinning={isLoading}>
      <Collapse>
        <CollapsePanel header="User Score Board" key="1">
          {/* <h3>Score Board</h3> */}
          <div style={{ fontWeight: 'bold', fontSize: '15px', color: '#0AA245' }}>Score Board:</div>
          <div className={'grid grid-cols-3'}>
            {/* {dataList.map((value: any, index) => (
              <div key={index} className="w-1/3 p-2">
                {dtypes[value[0]]} : {value[1]}
              </div>
            ))} */}
            <div className="grid grid-cols-2">
              <span>No. of Sales</span>
              <div>
                <span>:{'  '}</span>
                <span>{numberDecimalFormatter(dataList?.numberOfSales)}</span>
              </div>
            </div>
            <div className="grid grid-cols-2">
              <span>No. of Returns</span>
              <div>
                <span>:{'  '}</span>{' '}
                <span>{numberDecimalFormatter(dataList?.numberOfReturns)}</span>
              </div>
            </div>
            <div className={'grid grid-cols-2'}>
              <span>No. of Payments</span>
              <div>
                <span>:{'  '}</span>{' '}
                <span>{numberDecimalFormatter(dataList?.numberOfPayments)}</span>
              </div>
            </div>
            <div className={'grid grid-cols-2'}>
              <span>Total Sales</span>
              <div>
                <span>:{'  '}</span>
                <span>{nepaliNumberFormatter(dataList?.totalSales)}</span>
              </div>
            </div>
            <div className={'grid grid-cols-2'}>
              <span>Total Payments</span>
              <div>
                <span>:{'  '}</span>
                <span>{numberDecimalFormatter(dataList?.totalPayments)}</span>
              </div>
            </div>
            <div className={'grid grid-cols-2'}>
              <span>Total Returns </span>
              <div>
                <span>:{'  '}</span>
                <span>{numberDecimalFormatter(dataList?.totalReturns)}</span>
              </div>
            </div>
            <div className={'grid grid-cols-2'}>
              <span>Health</span>
              <div>
                <span>:{'  '}</span>
                <span>{dataList?.health}</span>
              </div>
            </div>
            <div className={'grid grid-cols-2'}>
              <span>Sell Frequency</span>
              <div>
                <span>:{'  '}</span>
                <span>{numberDecimalFormatter(dataList?.sellFrequency)}</span>
              </div>
            </div>
            <div className={'grid grid-cols-2'}>
              <span>Average Sell Amount</span>
              <div>
                <span>:{'  '}</span>
                <span>{nepaliNumberFormatter(dataList?.averageSellAmount)}</span>
              </div>
            </div>
            <div className={'grid grid-cols-2'}>
              <span>Average Payment Amount</span>
              <div>
                <span>:{'  '}</span>
                <span>{nepaliNumberFormatter(dataList?.averagePaymentAmount)}</span>
              </div>
            </div>
            <div className={'grid grid-cols-2'}>
              <span>Average Return Amount</span>
              <div>
                <span>:{'  '}</span>
                <span>{nepaliNumberFormatter(dataList?.averageReturnAmount)}</span>
              </div>
            </div>
            <div className={'grid grid-cols-2'}>
              <span>Average Sell Return Time Difference</span>
              <div>
                <span>:</span>
                <span>{dataList?.averageSellReturnTimeDifference}</span>
              </div>
            </div>
            <div className={'grid grid-cols-2'}>
              <span>Average Sell Payment Time Difference</span>
              <div>
                <span>:</span>
                <span>{dataList?.averageSellPaymentTimeDifference}</span>
              </div>
            </div>
          </div>
          {!fromCustomerDetails && (
            <>
              <PageHeader subTitle="Recent Tickets" />
              <Table columns={columnsChannel} dataSource={recentData?.channels} size="small" />
              <PageHeader subTitle="Recent Sell" />
              <Table columns={columns} dataSource={recentData?.sells} size="small" />
              <CustomModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                title={'Sell View'}>
                <ReusableSellView id={id} />
              </CustomModal>
            </>
          )}
        </CollapsePanel>
      </Collapse>
    </Spin>
  );
};

export default ScoreBoard;
