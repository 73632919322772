import moment from 'moment';
import NepaliDate from 'nepali-date-converter';

export const convert_string_to_nepali_date_object = (val: string) => {
  return NepaliDate.fromAD(new Date(val));
};
export const convert_string_to_nepali_date_string = (val: string) => {
  return NepaliDate.fromAD(new Date(val)).format('YYYY-MM-DD');
};

export const convert_nepali_to_english_date = (val: string) => {
  const date = new NepaliDate(val).toJsDate();
  return moment(date).format('YYYY-MM-DD');
};
