import React, { useEffect, useState, useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  Button,
  Input,
  message,
  Table,
  Tooltip,
  Menu,
  Form,
  Select,
  TableProps,
  Spin,
  Tag
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate } from 'react-router-dom';
import AppContent from '../../../components/Common/Content';

import {
  delete_product,
  get_product_list_v2,
  get_units_list
} from '../../../services/products/queries';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import { IProductListResponse, IUnits, IProductType } from '../../../services/products/types';
import DeleteConfirmModal from '../../../components/Common/Deleteconfirm/DeleteConfirmModal';
import DiscussionChatModal from '../../../components/Common/DiscussionChatModal';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import TableFilter from '../../../components/FliterTable';
import moment from 'moment';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import { ICategory } from '../../../services/category/types';
import CategoryDB from '../../../store/localstorage/CategoryDB';
import { get_category_list } from '../../../services/category/queries';
import { checkAccess } from '../../../routes/acl';
import { ConvertObjectToURL } from '../../../utils/converturl';
import { LocationSearch } from '../../../components/Common/LocationSearch/LocationSearch';
import { SorterResult } from 'antd/lib/table/interface';
import { getUserData } from '../../../utils/auth.utils';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import { ExportColumnType, exportExcel } from '../../../utils/exportExcel';
import TableCell from '../../../components/Common/CustomizeTable/CustomCell';
import { useReactToPrint } from 'react-to-print';
import { CustomModal } from '../../../components/Common/CustomModal';
import CustomTable, {
  IColumnDataForCustomTable
} from '../../../components/Common/CustomResuableInvoice/CustomTable';
import { convertLocalToUTCString } from '../../../utils/convertToUTC';
import { AxiosResponse } from 'axios';
import CustomUpdateIcon from '../../../components/Common/CustomIcons/CustomUpdateIcon';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

const Archive: React.FC = () => {
  const printPDFRef = useRef<any>();
  const navigate = useNavigate();
  const [allProducts, setAllProducts] = useState<IProductListResponse>({ total: 0, results: [] });
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [isloading, setIsloading] = useState(true);
  const [unitsList, setUnitsList] = useState<IUnits[]>([]);
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const [openmodalforExport, setOpenModalforexport] = useState<boolean>(false);
  let selectedID = 0;

  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const deleteProductMutation = useMutation(delete_product, {
    onSuccess: () => {
      message.success('Product deleted successfully');
      setIsloading(false);
      ProductsDB.deleteProduct(selectedID);
      onPagination();
      // refetch();
    },
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.response.data.message}`, 5);
    }
  });

  const columns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record: IProductType, index: number) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 50,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record: IProductType) => {
        return <TableCell>{record.name}</TableCell>;
      }
    },

    {
      title: 'SKU',
      key: 'sku',
      width: 20,
      sorter: (a, b) => a.sku.localeCompare(b.sku),
      sortOrder: sortedInfo.columnKey === 'sku' ? sortedInfo.order : null,
      render: (record: IProductType) => {
        return <TableCell>{record.sku}</TableCell>;
      }
    },
    {
      title: 'Lot Expiry Duration',
      key: 'lotExpiryDuration',
      width: 25,
      sorter: (a, b) => a.lotExpiryDuration - b.lotExpiryDuration,
      sortOrder: sortedInfo.columnKey === 'lotExpiryDuration' ? sortedInfo.order : null,
      render: (record: IProductType) => {
        return <TableCell>{record.lotExpiryDuration}</TableCell>;
      }
    },
    {
      title: 'VAT %',
      key: 'vat',
      width: 25,
      sorter: (a, b) => a.vat - b.vat,
      sortOrder: sortedInfo.columnKey === 'vat' ? sortedInfo.order : null,
      render: (record: IProductType) => {
        return <TableCell>{record.vat?.toFixed(2)}</TableCell>;
      }
    },
    {
      title: 'Active',
      key: 'hidden',
      width: 15,
      sorter: (a, b) => a.hidden - b.hidden,
      sortOrder: sortedInfo.columnKey === 'hidden' ? sortedInfo.order : null,
      render: (record: IProductType) => {
        return (
          <Tag color={record.hidden ? 'red' : 'green'}>{record.hidden ? 'Inactive' : 'Active'}</Tag>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 10,
      fixed: 'right',
      render: (record: IProductType) => {
        const confirmFunction = () => {
          selectedID = record.id as number;
          deleteProductMutation.mutate(record.id as number);
        };
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];
        if (checkAccess('UPDATE_PRODUCT')) {
          menuItems.push({
            key: '1',
            label: <CustomUpdateIcon link={`/products/${record.id}`} />
          });
        }
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '3',
            label: (
              <DiscussionChatModal slug="products" rreference="other" id={record.id as number} />
            )
          });
        }
        menuItems.push({
          key: '2',
          label: <DeleteConfirmModal FunctionAfterOk={confirmFunction} />
        });

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Products',
      link: '/products'
    }
  ];
  const onSubmitFilter = async (val: string) => {
    setIsloading(true);
    const productResponse: AxiosResponse<IProductListResponse, any> = await get_product_list_v2(
      val
    );
    for (let ind = 0; ind < productResponse?.data?.results?.length; ind++) {
      productResponse.data.results[ind].hiddenStatus = productResponse.data.results[ind].hidden
        ? 'Inactive'
        : 'Active';
    }
    setAllProducts(productResponse.data);
    setIsloading(false);
    ProductsDB.addProducts(productResponse.data.results);
    //pagination Data
    setPage(1);
    setSize(100);
  };
  const { Option } = Select;

  const getDataFromLC = async () => {
    const allunits: IUnits[] = (await UnitsDB.getAllUnits()) as IUnits[];
    if (allunits.length > 0) {
      setUnitsList(allunits);
    } else {
      const response = await get_units_list();
      setUnitsList(response);
      UnitsDB.addUnits(response);
    }
    const allcategory: ICategory[] = (await CategoryDB.getAllCategory()) as ICategory[];
    if (allcategory.length > 0) {
      setCategoryList(allcategory);
    } else {
      const response = await get_category_list();
      setCategoryList(response.data.results);
      CategoryDB.addCategory(response.data.results);
    }
  };
  useEffect(() => {
    getDataFromLC();
  }, []);

  //pagination Data
  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    const productResponse = await get_product_list_v2(url);
    setAllProducts(productResponse.data);
    setIsloading(false);
    ProductsDB.addProducts(productResponse.data.results);
  };

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'S.N',
          dataIndex: 'SN',
          width: 50
        },
        {
          title: 'Name',
          dataIndex: 'name',
          width: 300
        },
        {
          title: 'Active',
          width: 200,
          dataIndex: 'hiddenStatus'
        },

        {
          title: 'SKU',
          width: 150,
          dataIndex: 'sku'
        },
        {
          title: 'Lot Expiry Duration',
          width: 150,
          dataIndex: 'lotExpiryDuration'
        },
        {
          title: 'Vat %',
          width: 150,
          dataIndex: 'vat'
        }
      ];
      if (allProducts.results.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = allProducts.results.map((item: IProductType, index: number) => {
        return {
          ...item,
          SN: index + 1,
          hiddenStatus: item.hidden ? 'Inactive' : 'Active'
        };
      });
      exportExcel(columns, dataUpdated, 'Products List');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  const columsforPrint: IColumnDataForCustomTable[] = [
    {
      label: 'Name',
      dataIndex: 'name',
      render: (text: string) => {
        return <div className="ml-2">{text}</div>;
      }
    },
    {
      label: 'Active',
      dataIndex: 'hiddenStatus',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'SKU',
      dataIndex: 'sku',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Lot Expiry Duration',
      dataIndex: 'lotExpiryDuration',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Vat %',
      dataIndex: 'vat',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    }
  ];

  return (
    <Spin spinning={isloading}>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title="Product List View For PDF print">
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={allProducts.results}
            reff={printPDFRef}
            title={'Product List'}
          />
          <div className="flex justify-end mt-3">
            <Button type="primary" onClick={handlePDFExport}>
              Print Pdf
            </Button>
          </div>
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <TableFilter
                form={form}
                defaultValues={{
                  skip: 0,
                  count: 100,
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  value: '',
                  locationId: preferenceLocationId ? preferenceLocationId : '',
                  unitId: '',
                  categoryId: '',
                  taxId: '',
                  hidden: true,
                  brandId: ''
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                styleforbuttons={'flex justify-end items-center'}
                buttons={
                  <>
                    {checkAccess('CREATE_PRODUCT') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate(`/products/new`)}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/products/new"
                        />
                      </div>
                    )}
                  </>
                }>
                <Form.Item label={'Search'} name={['value']}>
                  <Input />
                </Form.Item>
                <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />
                <Form.Item name="categoryId" label="Category">
                  <Select
                    placeholder="Select Category"
                    style={{ borderRadius: '10px' }}
                    allowClear
                    dropdownMatchSelectWidth={false}>
                    <Option value={''} key={'none'}>
                      All
                    </Option>
                    {categoryList?.map((value: ICategory) => {
                      return (
                        <Select.Option value={value.id} key={value.id}>
                          {value.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item name="hidden" label="Status" hidden>
                  <Select
                    placeholder="Select"
                    style={{ borderRadius: '10px' }}
                    allowClear
                    dropdownMatchSelectWidth={false}>
                    <Option value={true} key={'true'}>
                      Inactive
                    </Option>
                  </Select>
                </Form.Item>
              </TableFilter>
            </div>
          </>
        }>
        <CustomizeTable
          columns={columns}
          buttons={
            <div>
              <ActionDropdown
                button={true}
                menu={
                  <Menu
                    items={[
                      {
                        key: '1',
                        label: (
                          <Tooltip title="Export Excel" color="blue">
                            <div className="text-center">Excel</div>
                          </Tooltip>
                        ),
                        onClick: () => {
                          handleExport();
                        }
                      },
                      {
                        key: '2',
                        label: (
                          <Tooltip title="Export PDF" color="blue">
                            <div className="text-center">PDF</div>
                          </Tooltip>
                        ),
                        onClick: () => {
                          setOpenModalforexport(true);
                        }
                      }
                    ]}
                  />
                }
              />
            </div>
          }
          data={allProducts.results}
          notshowPagination={true}
          paginationDatas={{
            page: page,
            total: allProducts.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
          customScroll={{ y: '75vh', x: 700 }}
        />
      </AppContent>
    </Spin>
  );
};

export default Archive;
