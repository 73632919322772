import { createHashHistory } from 'history';

const history = createHashHistory({ window });
// const history = createBrowserHistory({ window });

/**
 * Get params of the current url
 * @returns {any} history object
 */
const getParams = () => {
  const paramItems = history.location.search.slice(1).split('&');
  const outputItems = {};

  for (const item of paramItems) {
    const [key, value] = item.split('=');
    outputItems[key] = value || null;
  }

  return outputItems;
};
export { history, getParams };
export default history;
