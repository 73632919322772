import { getFileListFromMediaIds } from '@/services/upload/services';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload, UploadFile, UploadProps, Form } from 'antd';
import useFormInstance from 'antd/lib/form/hooks/useFormInstance';
import { NamePath } from 'antd/lib/form/interface';
import { RcFile } from 'antd/lib/upload';
import { useState, useImperativeHandle, forwardRef } from 'react';

interface Props {
  name: NamePath;
  maxFileLimit?: number;
  getCurrentFileList?: (fileList: UploadFile[]) => void;
}

export interface PictureListByFormRef {
  clearAll: () => void;
  getCurrentMedias: () => UploadFile[];
  addMedia: (file: UploadFile, type?: 'append' | 'replace') => void;
  addByMediaIds: (mediaIds: number[], type?: 'append' | 'replace') => Promise<UploadFile[]>;
  removeMedia: (uid: string) => void;
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const PictureListByForm = forwardRef<PictureListByFormRef, Props>(
  ({ name, maxFileLimit = 8, getCurrentFileList }, ref) => {
    const form = useFormInstance();
    const fileList: UploadFile[] = Form.useWatch(name, form) || [];

    const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const [previewImage, setPreviewImage] = useState({ image: '', title: '' });

    function onSetFileList(fileList: UploadFile[]) {
      form.setFieldValue(name, fileList);
      getCurrentFileList?.(fileList);
    }

    async function addByMediaIds(mediaIds: number[], type: 'append' | 'replace' = 'append') {
      const medias = await getFileListFromMediaIds(mediaIds);
      const updatedFileList = type === 'replace' ? medias : [...fileList, ...medias];
      onSetFileList(updatedFileList);
      return medias;
    }

    const handleUploadChange: UploadProps['onChange'] = ({ fileList }) => {
      onSetFileList(fileList);
    };

    const handlePreview = async (file: UploadFile) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }

      const image = file.url || file.preview || '';
      const urlTitle = file.url
        ? file.url.substring(file.url.lastIndexOf('/') + 1)
        : 'Current Preview Image';

      const finalTitle = file.name || urlTitle;
      setPreviewImage({ image, title: finalTitle });
      setOpenPreviewModal(true);
    };

    function addMedia(file: UploadFile, type: 'append' | 'replace' = 'append') {
      const updatedFileList = type === 'append' ? [...fileList, file] : [file];
      onSetFileList(updatedFileList);
    }

    function removeMedia(uid: string) {
      const updatedFiles = fileList.filter((file) => file.uid !== uid);
      onSetFileList(updatedFiles);
    }

    useImperativeHandle(ref, () => ({
      clearAll: () => onSetFileList([]),
      getCurrentMedias: () => fileList,
      addMedia,
      removeMedia,
      addByMediaIds
    }));

    return (
      <div>
        <Modal
          visible={openPreviewModal}
          title={previewImage.title}
          footer={null}
          onCancel={() => {
            setPreviewImage({ image: '', title: '' });
            setOpenPreviewModal(false);
          }}>
          <img alt="example" style={{ width: '100%' }} src={previewImage.image} />
        </Modal>
        <Form.Item name={name} noStyle>
          <Upload
            accept="image/*"
            multiple={maxFileLimit > 1}
            fileList={fileList}
            beforeUpload={() => false}
            listType="picture-card"
            onChange={handleUploadChange}
            onPreview={handlePreview}>
            {fileList.length < maxFileLimit && (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
      </div>
    );
  }
);

PictureListByForm.displayName = 'PictureListByForm';
export default PictureListByForm;
