import { useState } from 'react';
import { CustomModal } from '../../../components/Common/CustomModal';
import BillPrint from '../../../components/Common/InvoicePrint/BillPrint/BillPrint';
import { ISellInvoice } from '../../../services/invoice/types';
import EditSellCrates from '../crates';
import { Tooltip } from 'antd';
import { ISellFromServer } from '../../../services/sell/types';

export const UpdateCratesModal: React.FC<{
  urlData: string;
  record: ISellFromServer;
  getInfo: (val?: string) => void;
}> = ({ urlData, getInfo, record }) => {
  const [crateData, setCrateData] = useState({ sellId: 0, amount: 0 });
  const [isModalOpen3, setIsModalOpen3] = useState(false);

  return (
    <>
      <div
        className="w-full p-0 cursor-pointer"
        onClick={async () => {
          setCrateData({
            sellId: record.id,
            amount: record.cratesSent
          });
          setIsModalOpen3(true);
        }}>
        <Tooltip title="Update Crates" color="blue">
          Send Crates
        </Tooltip>
      </div>
      <CustomModal
        footer={false}
        isModalOpen={isModalOpen3}
        setIsModalOpen={setIsModalOpen3}
        title={'Crates'}>
        <EditSellCrates
          refetchData={{ filter: urlData, refetch: getInfo }}
          isReceive={false}
          amount={crateData.amount}
          sellId={crateData.sellId}
        />
      </CustomModal>
    </>
  );
};
