import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { ACLGroup, ICreateACLGroup, IEditACLGroup, IEditACLUserGroup } from './types';

export const create_acl_group_mutation = async (values: ICreateACLGroup) => {
  const response: AxiosResponse<ACLGroup> = await http.store('acl/create-group/', values);
  return response;
};

export const update_acl_group_mutation = async (values: IEditACLGroup) => {
  const response: AxiosResponse<ACLGroup> = await http.update(
    'acl/update-group/' + values.id,
    values
  );
  return response;
};
export const update_acl_user_group_mutation = async (values: IEditACLUserGroup) => {
  const response: AxiosResponse<ACLGroup> = await http.update(
    'acl/update-user-group/' + values.userId,
    values
  );
  return response;
};
