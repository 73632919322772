import {
  get_purchase_order_details,
  get_purchase_order_lines_details
} from '../../../../services/purchases/queries';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  get_lots_details,
  get_product_details,
  get_product_list_ids,
  get_units_list
} from '../../../../services/products/queries';
import { useEffect, useState } from 'react';

import LocationsDB from '../../../../store/localstorage/LocationsDB';
import { get_location_details } from '../../../../services/locations/queries';
import UsersDB from '../../../../store/localstorage/UsersDB';
import { get_user_avatar, get_user_details } from '../../../../services/users/queries';
import { get_invoices_list } from '../../../../services/settings/queries';
import type { ColumnsType } from 'antd/es/table';
import { PageHeader, Table, Button, Spin, Divider, Menu, Tooltip } from 'antd';
import { createPDF } from '../list/pdfmaker';
import ReuseChannel from '../../../channel/Reuse';
import {
  upload_invoice_pdf,
  upload_invoice_pdf_with_mediaId
} from '../../../../services/settings/mutations';
import UnitsDB from '../../../../store/localstorage/UnitsDB';
import { Link } from 'react-router-dom';
import ProductsDB from '../../../../store/localstorage/ProductsDB';
import ActionDropdown from '../../../../components/Common/Dropdownactions';
import { convertUTCStringtoLocalString } from '../../../../utils/convertToUTC';
import { nepaliNumberFormatter } from '../../../../utils/numberFormatter';
import { IProductType } from '../../../../services/products/types';
import { ICreateInvoiceResponse } from '../../../../services/settings/types';
interface PurchaseOrderViewProps {
  id: number;
}
const PurchaseOrderReusableView: React.FC<PurchaseOrderViewProps> = ({ id }) => {
  const [productList, setProductList] = useState<any[]>([]);
  const [isLoading, setIsloading] = useState(true);
  const [purchaseDetails, setpurchaseDetails] = useState<any>();
  const [vendorDetails, setVendorDetails] = useState<any>();
  const [locationDetails, setLocationDetails] = useState<any>();
  const [lines, setLines] = useState<any>();
  const [invoiceLayouts, setinvoiceLayouts] = useState<any>();
  const [iframesrc, setiframesrc] = useState<string>();
  const [invoiceLists, setInvoiceLists] = useState<ICreateInvoiceResponse[]>([]);
  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    setinvoiceLayouts(response.data[0]);
    // console.log('response-->', response);
    setInvoiceLists(
      response.data.filter((curr: any) => {
        const content = JSON.parse(curr.content);
        return content.design == 'classic';
      })
    );
    return response;
  });
  useEffect(() => {
    getInfo();
  }, [id]);

  const getInfo = async () => {
    setIsloading(true);
    const response = await get_purchase_order_details(id);
    const linesResponse = await get_purchase_order_lines_details(response.data.id);
    // if (linesResponse.data.length > 0) {
    //   const allProducts = [...productList];
    //   const mySet = new Set<number>();
    //   for (let i = 0; i < linesResponse.data.length; i++) {
    //     mySet.add(linesResponse.data[i].productId);
    //     let findProduct = allProducts.find((value) => value.id == linesResponse.data[i].productId);
    //     if (!findProduct) {
    //       findProduct = await get_product_details(linesResponse.data[i].productId);
    //       allProducts.push(findProduct);
    //     }
    //     linesResponse.data[i].productName = findProduct.name;
    //     let findUnit: any = await UnitsDB.getUnit(linesResponse.data[i].unitId);
    //     if (!findUnit) {
    //       const allUnits = await get_units_list();
    //       await UnitsDB.addUnits(allUnits);
    //       findUnit = await UnitsDB.getUnit(linesResponse.data[i].unitId);
    //     }
    //     linesResponse.data[i].unitName = findUnit.name;
    //   }
    //   setLines(linesResponse.data);
    //   // console.log(allProducts);
    //   setProductList(allProducts);
    // }
    const searchProducts: any = {};
    for (let index = 0; index < linesResponse.data.length; index++) {
      const product: any = await ProductsDB.getProduct(linesResponse.data[index].productId);
      if (!product) {
        // product = await get_product_details(linesResponse.lines[index].productId);
        // await ProductsDB.addProducts([product]);
        if (linesResponse.data[index].productId in searchProducts) {
          searchProducts[linesResponse.data[index].productId] = [
            ...searchProducts[linesResponse.data[index].productId],
            index
          ];
        } else {
          searchProducts[linesResponse.data[index].productId] = [index];
        }
      } else {
        linesResponse.data[index].productName = product.name;
      }

      let findUnit: any = await UnitsDB.getUnit(linesResponse.data[index].unitId);
      if (!findUnit) {
        const allUnits = await get_units_list();
        await UnitsDB.addUnits(allUnits);
        findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
      }
      // linesResponse.lines[index].unitName = findUnit.name;
      linesResponse.data[
        index
      ].quantityWithUnit = `${linesResponse.data[index].quantity} ${findUnit.shortName}`;
    }
    const searchProductslength = Object.entries(searchProducts).length;
    if (searchProductslength > 0) {
      const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
      for (const key in searchProducts) {
        const findproduct = productsresponse?.data?.results.find(
          (currProduct: IProductType) => currProduct.id == key
        );
        for (let i = 0; i < searchProducts[key].length; i++) {
          linesResponse.data[searchProducts[key][i]].productName = findproduct?.name;
        }
        await ProductsDB.addProducts([findproduct]);
      }
    }
    setLines(linesResponse.data);
    let vendorDetailss: any = await UsersDB.getUser(response.data?.vendor.userId);
    if (!vendorDetailss) {
      vendorDetailss = (await get_user_details(response.data?.vendor.userId)).user;
      await UsersDB.addUsers([vendorDetailss]);
    }
    // console.log('VendorDetails-->', vendorDetailss);
    setVendorDetails(vendorDetailss);
    let locationDetailss: any = await LocationsDB.getLocation(response.data?.locationId);
    if (!locationDetailss) {
      locationDetailss = await get_location_details(response.data?.locationId);
      await LocationsDB.addLocations([locationDetailss]);
    }
    // console.log('Location details-->', locationDetailss);
    setLocationDetails(locationDetailss);
    // console.log('linesResponse-->', linesResponse.data);
    setpurchaseDetails(response.data);
    // console.log('Response--->', response.data);
    setIsloading(false);
  };

  const columns: ColumnsType<{ name: string }> = [
    {
      title: `S.N`,
      key: 'sn',
      width: 30,
      render: (a, b, c) => <div>{c + 1}.</div>
    },
    {
      title: `${invoiceLayouts ? invoiceLayouts.content.productLabel : 'ProductName'}`,
      dataIndex: 'productName',
      width: 100,
      render: (text: string) => <a>{text}</a>
    },
    {
      title: `${invoiceLayouts ? invoiceLayouts.content.unitPriceLabel : 'UnitPrice'}`,
      dataIndex: 'unitPrice',
      width: 100,
      render: (text: number) => <a>{text}</a>
    },
    {
      title: `${'unitName'}`,
      dataIndex: 'unitName',
      width: 100,
      render: (text: number) => <a>{text}</a>
    },
    {
      title: `${invoiceLayouts ? invoiceLayouts.content.quantityLabel : 'Quantity'}`,
      dataIndex: 'quantityWithUnit',
      width: 100,
      render: (text: number) => <a>{text}</a>
    },

    {
      title: 'Misc',
      dataIndex: 'misc',
      width: 100,
      render: (text: number) => <a>{text}</a>
    },
    {
      title: `${invoiceLayouts ? invoiceLayouts.content.discountLabel : 'Discount'}`,
      dataIndex: 'discount',
      width: 100,
      render: (text: number) => <a>{text}</a>
    },
    {
      title: `${invoiceLayouts ? invoiceLayouts.content.totalLabel : 'Total Amount'}`,
      dataIndex: 'totalAmount',
      width: 100,
      render: (text: number) => <a>{text}</a>
    }
  ];

  // const generatePDF = async () => {
  //   const response = await get_invoice_by_reference_and_id('purchase_order', `${id}`);
  //   console.log('first response', response);
  //   if (response?.data.mediaId) {
  //     console.log('data', response.data);
  //     const mediaresponse = await get_user_avatar(response.data.mediaId);
  //     console.log('mediaresponse->', mediaresponse);
  //     // const blogresponse = await fetch_url(mediaresponse);
  //     // const blogresponse = await fetch(mediaresponse);
  //     // console.log('blog response', blogresponse);
  //     console.log('typeofmedia response-->', typeof mediaresponse);
  //     setiframesrc(mediaresponse);
  //     // const blobb = new Blob([blogresponse.data], { type: 'application/pdf' });
  //     // const fileURL = URL.createObjectURL(blobb);
  //     // console.log('pdf url', fileURL);
  //     // window.open(fileURL);
  //   } else {
  //     try {
  //       const documenttt = createPDF({
  //         purchaseDetails,
  //         lines,
  //         invoiceLayouts,
  //         vendorDetails,
  //         locationDetails,
  //         columns: [
  //           `${invoiceLayouts ? invoiceLayouts.content.productLabel : 'Product'}`,
  //           `${invoiceLayouts ? invoiceLayouts.content.unitPriceLabel : 'unit price'}`,
  //           'unitName',
  //           `${invoiceLayouts ? invoiceLayouts.content.quantityLabel : 'quantity'}`,
  //           'Misc',
  //           `${invoiceLayouts ? invoiceLayouts.content.discountLabel : 'discount'}`,
  //           `${invoiceLayouts ? invoiceLayouts.content.totalLabel : 'total'}`
  //         ],
  //         columnspay: ['Date', 'Reference No', 'Amount', 'Payment Mode', 'Note']
  //       });
  //       // console.log('aa', aaa);
  //       const formData = new FormData();
  //       formData.append('media', documenttt);
  //       const values = {
  //         media: formData
  //       };
  //       const uploadresponse = await upload_invoice_pdf(values);
  //       // console.log('upload response-->', uploadresponse);
  //       const seconduploadresponse = await upload_invoice_pdf_with_mediaId({
  //         name: 'invoice',
  //         mediaId: uploadresponse?.data.id,
  //         reference: 'PURCHASE_ORDER',
  //         referenceId: id
  //       });
  //       console.log('secondupload', seconduploadresponse);
  //     } catch (err) {
  //       console.log('Error', err);
  //     }
  //   }
  // };

  return (
    <div>
      <Spin spinning={isLoading}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-5">
          <div>
            <PageHeader
              subTitle="Supplier"
              style={{
                padding: '8px 0px'
              }}
            />
            {vendorDetails ? (
              <div style={{ color: 'black' }}>
                {vendorDetails?.name ? vendorDetails.name : ''}
                {/* </p>
              <p> */}
                {`${vendorDetails?.phone ? `,${vendorDetails.phone}` : ''} ${
                  vendorDetails.email ? `,${vendorDetails.email}` : ''
                }`}
              </div>
            ) : (
              <></>
            )}
            {purchaseDetails && (
              <>
                <div style={{ color: 'name' }}>
                  Buyer Type : <b>{purchaseDetails.purchaseBuyerType}</b>
                </div>
                <div style={{ color: 'name' }}>
                  Purchase Type : <b>{purchaseDetails.purchaseType}</b>
                </div>
              </>
            )}
          </div>
          <div>
            <PageHeader
              subTitle="Location"
              style={{
                padding: '8px 0px'
              }}
            />
            {locationDetails ? (
              <>
                <div style={{ color: 'black' }}>
                  {locationDetails?.name ? locationDetails.name : ''}
                  {/* </p>
               <p> */}
                  {`${locationDetails?.zip ? `${locationDetails.zip},` : ''} ${
                    locationDetails?.address ? `${locationDetails.address},` : ''
                  } ${locationDetails?.city ? `${locationDetails.city},` : ''} ${
                    locationDetails?.country ? `${locationDetails.country}` : ''
                  }`}
                  {/* </p>
               <p> */}
                  {`${locationDetails?.phone ? `${locationDetails.phone},` : ''} ${
                    locationDetails?.email ? `${locationDetails.email}` : ''
                  }`}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            <PageHeader
              subTitle="Details"
              style={{
                padding: '8px 0px'
              }}
            />
            {purchaseDetails ? (
              <>
                <div style={{ color: 'black' }}>
                  <span className="block">
                    Reference Number : {purchaseDetails.referenceNumber}
                  </span>
                  {/* </p>
              <p> */}
                  <span className="block">
                    {' '}
                    Created :{' '}
                    {convertUTCStringtoLocalString(purchaseDetails.createdAt, 'YYYY-MM-DD')}
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={lines ? lines : []}
          rowKey={'id'}
          pagination={false}
          scroll={{ y: 400, x: true }}
        />

        <div>
          <PageHeader
            subTitle="Payment Info"
            style={{
              padding: '8px 0px'
            }}
          />
          {purchaseDetails ? (
            <div style={{ color: 'black' }}>
              <span className="block">
                Total Amount : {nepaliNumberFormatter(purchaseDetails.totalAmount)}
              </span>
              <span className="block">
                Total Tax : {nepaliNumberFormatter(purchaseDetails.totalTax)}
              </span>
              <span className="block">
                Manual Discount : {nepaliNumberFormatter(purchaseDetails.manualDiscount)}
              </span>
              <span className="block">
                Shipping Amount : {nepaliNumberFormatter(purchaseDetails.shippingAmount)}
              </span>
              <span className="block">
                Labour Cost : {nepaliNumberFormatter(purchaseDetails.labourCost)}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="flex justify-end  gap-5 mt-5">
          {/* <Button
            type="primary"
            onClick={() => {
              generatePDF();
            }}>
            Print
          </Button> */}
          {/* <Link
            to="/custom-invoice"
            target={'_blank'}
            className="link"
            onClick={() =>
              localStorage.setItem(
                'referrer',
                JSON.stringify({
                  purchaseDetails,
                  locationDetails,
                  vendorDetails,
                  lines: lines,
                  invoiceLayouts
                })
              )
            }>
            <Button type="primary">Print</Button>
          </Link> */}
          <div
            style={{
              width: '3rem',
              // backgroundColor: 'red',
              // border: '1px solid gray',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <ActionDropdown
              trigger={'click'}
              insideaction={true}
              menu={
                <Menu
                  items={invoiceLists?.map((curr: any, ind: number) => {
                    return {
                      key: ind,
                      label: (
                        <Tooltip title="Print receipt" color="blue">
                          <div className="text-center">{curr.name}</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        localStorage.setItem(
                          'referrer',
                          JSON.stringify({
                            purchaseDetails,
                            locationDetails,
                            vendorDetails,
                            lines: lines,
                            invoiceLayouts
                          })
                        );
                        window.open('/ERP-UI#/custom-invoice', '_blank', 'noopener,noreferrer');
                      }
                    };
                  })}
                />
              }
            />
          </div>
        </div>
      </Spin>
      <Divider />
      {/* <ReuseChannel slug={`purchase_order_${id}`} /> */}
      {/* <iframe src={iframesrc}></iframe> */}
      <ReuseChannel
        slug={`purchase_order_${id}`}
        fromSellorPurchaseId={vendorDetails?.id as string}
        withReferenceandId={{ reference: 'sell_order', referenceId: id || 0 }}
      />
    </div>
  );
};

export default PurchaseOrderReusableView;
