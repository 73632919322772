import http from '../../../utils/http.utils';
import { AxiosResponse } from 'axios';
import { ISalesRegisterListData, ISalesReturnRegisterListData } from './types';

export const get_sales_register_list = async (filter = '') => {
  const response: AxiosResponse<ISalesRegisterListData, any> = await http.get('sell/?' + filter);
  return response.data;
};

export const get_sell_return_register_list = async (filter = '') => {
  const response: AxiosResponse<ISalesReturnRegisterListData, any> = await http.get(
    'sell-return/?' + filter
  );
  return response.data;
};
