import AgentBillMutationUI from '../AgentBillMutationUI';

function CreatePurchaseBillDateRange() {
  const breadcrumbs = [
    { label: 'Purchases', link: '/purchase' },
    { label: 'Agent Bill Range', link: '/purchases/agents-bill-range' },
    { label: 'Create', link: '/purchases/agents-bill-range/new' }
  ];

  return <AgentBillMutationUI type="create" breadcrumbItems={breadcrumbs} />;
}

export default CreatePurchaseBillDateRange;
