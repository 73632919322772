import { IUnits } from '@/services/products/types';

function getFilteredUnits(unitLists: IUnits[], baseUnitName: string) {
  const filtered = unitLists.filter((unit) => {
    return unit.baseUnitName === baseUnitName;
  });

  const removedUnits = unitLists
    .filter((unit) => {
      return unit.baseUnitName !== baseUnitName;
    })
    .map((unit: any) => unit.name)
    .join(', ')
    .replace(/,([^,]*)$/, ' and$1');

  return { updatedUnits: filtered, removedUnits };
}

export { getFilteredUnits };
