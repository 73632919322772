import { Excel } from 'antd-table-saveas-excel';
// exports the data to excel
export interface ExportColumnType {
  title: string;
  dataIndex?: string;
  width: number;
  children?: ExportColumnType[];
}
export const exportExcel = (column: ExportColumnType[], data: any[], name: string) => {
  const excel = new Excel();
  excel.setTHeadStyle({
    background: 'ffffffff'
  });

  // console.log('column', column);
  // console.log('data', data);
  excel.addSheet(name);
  excel.addColumns(column as any[]);
  excel.addDataSource(data);
  excel.saveAs(name + '.xlsx');
};
