import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { ITransferInvoice } from '../../../../services/invoice/types';
import { getUserData } from '../../../../utils/auth.utils';
import { Button, PageHeader } from 'antd';
import { nepaliNumberFormatter } from '../../../../utils/numberFormatter';
import {
  convertLocalToUTCString,
  convertUTCStringtoLocalString
} from '../../../../utils/convertToUTC';
import CustomTable from '../../CustomResuableInvoice/CustomTable';
import CustomBarcode from '../../../../pages/barcode';

const TransferInvoice: React.FC<ITransferInvoice> = ({
  transferData,
  lines,
  invoiceLayouts,
  handleModalClose,
  isOrder
}) => {
  const printRef = useRef<any>();
  const loggedInUser = getUserData();

  const content = JSON.parse(invoiceLayouts?.content);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    async onAfterPrint() {
      if (handleModalClose) {
        handleModalClose();
      }
    }
  });

  const columns = [
    {
      label: `${content.productLabel ? content.productLabel : 'ProductName'}`,
      dataIndex: 'productName',
      render: (text: string) => <div className="px-2">{text}</div>
    },
    {
      label: `${content?.hsCode ? content?.hsCode : 'HS Code'}`,
      dataIndex: 'hsCode',
      render: (text?: string) => <div className="px-2">{text || 'NA'}</div>
    },
    {
      label: `${content.unitPriceLabel ? content.unitPriceLabel : 'UnitPrice'}`,
      dataIndex: 'unitPrice',
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: `${content.quantityLabel ? content.quantityLabel : 'Quantity'}`,
      dataIndex: 'quantity',
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: 'Misc',
      dataIndex: 'misc',
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    },
    {
      label: `${content.discountLabel ? content.discountLabel : 'Discount'}`,
      dataIndex: 'discount',
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    },
    {
      label: `${content.subTotalLabel ? content.subTotalLabel : 'Total Amount'}`,
      dataIndex: 'totalAmount',
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    }
  ];

  return (
    <>
      <div className="flex justify-end mb-3">
        <Button type="primary" onClick={handlePrint}>
          Print
        </Button>
      </div>

      <div ref={printRef}>
        <div style={{ color: 'black' }} className="mx-5 my-3">
          <div className="flex justify-between font-bold text-xl mt-4">
            {'FreshKtm Agro Pvt. Ltd.'}
          </div>
          <div className="flex justify-between">
            <div className="flex justify-between font-bold text-xl">
              Stock Transfers {isOrder ? 'Order' : ''} Details ({'Financial Reference'} :{' '}
              {transferData?.financialReference})
            </div>
            <div className="flex justify-center items-end">
              {invoiceLayouts && content.dateLabel ? content.dateLabel : 'Date'}
              {': '}
              {convertLocalToUTCString(transferData ? transferData.transferDate : '', 'YYYY MM-DD')}
            </div>
          </div>
          <hr />
          <div className="grid grid-cols-3 gap-5 mb-5">
            {content.showLocations ? (
              <>
                <div>
                  <PageHeader
                    subTitle="Location(From)"
                    style={{
                      padding: '8px 0px',
                      fontWeight: 'bold'
                    }}
                  />
                  <div className="text-sm font-bold">{transferData?.fromName} </div>
                  <div>{transferData?.fromDetails} </div>
                  <div>{transferData?.fromEmail}</div>
                </div>
                <div>
                  <PageHeader
                    subTitle="Location(To)"
                    style={{
                      padding: '8px 0px',
                      fontWeight: 'bold'
                    }}
                  />
                  <div className="text-sm font-bold">{transferData?.toName} </div>
                  <div>{transferData?.toDetails}</div>
                  <div>{transferData?.toEmail}</div>
                </div>
              </>
            ) : (
              <></>
            )}
            <div>
              <PageHeader
                subTitle="Details"
                style={{
                  padding: '8px 0px',
                  fontWeight: 'bold'
                }}
              />
              {transferData ? (
                <>
                  <div>
                    {'Financial Reference'} : {transferData?.financialReference}
                  </div>
                  <div>
                    Created :{' '}
                    {convertUTCStringtoLocalString(transferData?.transferDate, 'YYYY MM-DD')}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          {invoiceLayouts && content.invoiceNumberLabel ? (
            <div>{content.invoiceNumberLabel}</div>
          ) : (
            <></>
          )}
          <CustomTable columns={columns} datas={lines ? lines : []} />
          {transferData && (
            <div className="grid grid-cols-3 gap-5 mb-5">
              <div>
                <PageHeader
                  subTitle="Payment Info"
                  style={{
                    padding: '8px 0px'
                  }}
                />
                {transferData ? (
                  <>
                    <div>
                      {content.netTotalAmount ? content.netTotalAmount : 'Net Total Amount'} : Rs{' '}
                      {transferData.netTotalAmount}
                    </div>
                    <div>
                      {content.shippingChargeLabel
                        ? content.shippingChargeLabel
                        : 'Additional Shipping Charge'}{' '}
                      :{' '}
                    </div>
                    <div>Purchase Total : Rs {transferData.netTotalAmount}</div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
          {content.footerText ? (
            <div dangerouslySetInnerHTML={{ __html: content.footerText }}></div>
          ) : (
            <></>
          )}
          {content.showBarcode && (
            <div className="flex items-center justify-center">
              <CustomBarcode
                value={transferData ? transferData.financialReference : ''}
                height={35}
                width={1.4}
                fontSize={16}
              />
            </div>
          )}
          <div className="flex justify-end">
            <div>
              Printed By:{' '}
              <span className="capitalize">{loggedInUser?.name ? loggedInUser.name : ''}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransferInvoice;
