import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

export const tableExportColumns = [
  {
    label: 'Category',
    dataIndex: 'category_name',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Name',
    dataIndex: 'product_name',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Location',
    dataIndex: 'location_name',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Date',
    dataIndex: 'date',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Opening',
    dataIndex: 'qty_opening',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Closing',
    dataIndex: 'qty_closing',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Sold',
    dataIndex: 'qty_sold',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Sold Avg Price',
    dataIndex: 'qty_sold_price',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Returned Sell',
    dataIndex: 'qty_returned_sell',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Returned Sell Avg Price',
    dataIndex: 'qty_returned_sell_price',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Purchased',
    dataIndex: 'qty_purchased',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Purchased Avg Price',
    dataIndex: 'qty_purchased_price',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Returned Purchased',
    dataIndex: 'qty_returned_purchase',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Returned Purchased Avg Price',
    dataIndex: 'qty_returned_purchase_price',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Transferred',
    dataIndex: 'qty_transferred',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Transferred Avg Price',
    dataIndex: 'qty_transferred_price',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Transferred Out',
    dataIndex: 'qty_transferred_out',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Transferred Out Avg Price',
    dataIndex: 'qty_transferred_out_price',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Qty Adjusted',
    dataIndex: 'qty_adjusted',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Qty Adjusted Avg Price',
    dataIndex: 'qty_adjusted_price',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Qty Opening Received',
    dataIndex: 'qty_opening_received',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Qty Opening Received Avg Price',
    dataIndex: 'qty_opening_received_price',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Profit Per Unit',
    dataIndex: 'profit_per_unit',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Total Profit',
    dataIndex: 'total_profit',
    render: (text: string) => <div className="text-center">{text}</div>
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      date: convertUTCStringtoLocalString(record.date),
      qty_opening: `${nepaliNumberFormatter(record.qty_opening)} ${record.unit}`,
      qty_closing: `${nepaliNumberFormatter(record.qty_closing)} ${record.unit}`,
      qty_sold: `${nepaliNumberFormatter(record.qty_sold)} ${record.unit}`,
      qty_sold_price: nepaliNumberFormatter(record.qty_sold_price),
      qty_returned_sell: `${nepaliNumberFormatter(record.qty_returned_sell)} ${record.unit}`,
      qty_returned_sell_price: nepaliNumberFormatter(record.qty_returned_sell_price),
      qty_purchased: `${nepaliNumberFormatter(record.qty_purchased)} ${record.unit}`,
      qty_purchased_price: nepaliNumberFormatter(record.qty_purchased_price),
      qty_returned_purchase: `${nepaliNumberFormatter(record.qty_returned_purchase)} ${
        record.unit
      }`,
      qty_returned_purchase_price: nepaliNumberFormatter(record.qty_returned_purchase_price),
      qty_transferred: `${nepaliNumberFormatter(record.qty_transferred)} ${record.unit}`,
      qty_transferred_price: nepaliNumberFormatter(record.qty_transferred_price),
      qty_transferred_out: `${nepaliNumberFormatter(record.qty_transferred_out)} ${record.unit}`,
      qty_transferred_out_price: nepaliNumberFormatter(record.qty_transferred_out_price),
      qty_adjusted: `${nepaliNumberFormatter(record.qty_adjusted)} ${record.unit}`,
      qty_adjusted_price: nepaliNumberFormatter(record.qty_adjusted_price),
      qty_opening_received: `${nepaliNumberFormatter(record.qty_opening_received)} ${record.unit}`,
      qty_opening_received_price: nepaliNumberFormatter(record.qty_opening_received_price),
      profit_per_unit: nepaliNumberFormatter(record.profit_per_unit),
      total_profit: nepaliNumberFormatter(record.total_profit)
    };
  }) as any;
}
