import GenericTable from '@/components/Common/CustomizeTable';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import ExportCurrent from '@/components/Common/ExportCurrent';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { TableProps } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';

type Data = { name: string; total: number };
type PaginatedData = { data: Data[]; total: number };

const DEFAULT_SIZE = 100;

function TableData({ data }: { data: Data[] }) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Data>>({});

  const [pagination, setPagination] = useState({ page: 1, size: DEFAULT_SIZE });
  const [paginatedData, setPaginatedData] = useState<PaginatedData>({ data: [], total: 0 });

  useEffect(() => {
    const paginatedData = { data: data.slice(0, DEFAULT_SIZE), total: data.length };
    setPaginatedData(paginatedData);
    setPagination({ page: 1, size: DEFAULT_SIZE });
  }, [data]);

  const handleChange: TableProps<Data>['onChange'] = (_, __, sorter) => {
    setSortedInfo(sorter as SorterResult<Data>);
  };

  const columns: TableProps<Data>['columns'] = [
    {
      title: 'S.N',
      key: 'SN',
      width: 30,
      fixed: 'left',
      render: (_, __, index) => {
        return <TableCell>{index + 1}</TableCell>;
      }
    },
    {
      title: 'Supplier',
      dataIndex: 'name',
      width: 150,
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null
    },
    {
      title: 'Total Purchase',
      dataIndex: 'total',
      width: 50,
      key: 'value',
      align: 'right',
      sorter: (a, b) => a.total - b.total,
      sortOrder: sortedInfo.columnKey === 'value' ? sortedInfo.order : null,
      render: (text: number) => <TableCell>{nepaliNumberFormatter(text)}</TableCell>
    }
  ];

  const tableExportColumns = [
    {
      label: 'Supplier',
      dataIndex: 'name',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Total Purchase',
      dataIndex: 'total',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    }
  ];

  const excelExportColumns = tableExportColumns.map((column) => ({
    title: column.label,
    dataIndex: column.dataIndex
  }));

  const updatedData = data.map((item) => {
    return { ...item, total: nepaliNumberFormatter(item.total) };
  });

  function onPagination(page: number, size?: number) {
    const pages = size ? 1 : page;
    const sizes = size || pagination.size;

    setPagination((prev) => {
      prev.page = pages;
      if (size) prev.size = size;
      return prev;
    });

    setPaginatedData((prev) => {
      const start = (pages - 1) * sizes;
      const end = pages * sizes;
      return { ...prev, data: data.slice(start, end) };
    });
  }

  return (
    <div>
      <GenericTable
        columns={columns}
        data={paginatedData.data}
        toSort={handleChange}
        scroll={{ x: 500 }}
        hideDefaultPagination
        generateSummary
        buttons={
          <ExportCurrent
            data={{ total: updatedData.length, results: updatedData }}
            columns={tableExportColumns}
            excelColumns={excelExportColumns}
            title="Purchase Over Lakh Report"
          />
        }
        pagination={{
          page: pagination.page,
          total: paginatedData.total,
          size: pagination.size,
          onPagination: (pageNo, currentSize) => {
            currentSize == pagination.size
              ? onPagination(pageNo)
              : onPagination(pageNo, currentSize);
          }
        }}
      />
    </div>
  );
}

export default TableData;
