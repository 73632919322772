import { Form, Input, Menu, Select, Spin, TableProps, Tag, Tooltip } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ConvertObjectToURL } from '../../../../utils/converturl';
import TableCell from '../../../../components/Common/CustomizeTable/CustomCell';
import TableFilter from '../../../../components/FliterTable';
import AppContent from '../../../../components/Common/Content/Content';
import { checkAccess } from '../../../../routes/acl';
import CustomButton from '../../../../components/Common/CustomButton/CustomButton';
import CustomizeTable from '../../../../components/Common/CustomizeTable/CustomizeTable';
import { get_discount_product_list } from '../../../../services/offfers/queries';
import {
  IDiscountProductListData,
  IDiscountProductListResponse
} from '../../../../services/offfers/types';
import { convertUTCStringtoLocalString } from '../../../../utils/convertToUTC';
import moment from 'moment';
import { DiscountStatus } from '../../../../services/offfers/enums';
import ActionDropdown from '../../../../components/Common/Dropdownactions';
import { EditOutlined } from '@ant-design/icons';
import { LocationSearch } from '../../../../components/Common/LocationSearch/LocationSearch';
import { getUserData } from '../../../../utils/auth.utils';
import LocationsDB from '../../../../store/localstorage/LocationsDB';
import { get_location_list_for_ids } from '../../../../services/locations/queries';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const DiscountProductList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [discountList, setDiscountList] = useState<IDiscountProductListResponse>({
    results: [],
    count: 0
  });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const breadcrumbItems = [
    {
      label: 'Product Discount',
      link: '/offer/discount-product'
    }
  ];

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const response = await get_discount_product_list(filter);
    // console.log('response', response);
    if (response) {
      for (let i = 0; i < response.results.length; i++) {
        let locationDetails = await LocationsDB.getLocation(response.results[i].locationId);
        if (!locationDetails) {
          const allLocations = await get_location_list_for_ids([
            ...new Set(
              response.results.map((value) => {
                return value.locationId;
              })
            )
          ]);

          await LocationsDB.addLocations(allLocations);
          locationDetails = await LocationsDB.getLocation(response.results[i].locationId);
        }
        response.results[i].locationName = locationDetails.name;
      }
      setDiscountList(response);
    }
    setIsLoading(false);
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };

  const columns: ColumnsType<IDiscountProductListData> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 15,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (a, record) => {
        return <TableCell>{record.name}</TableCell>;
      }
    },

    {
      title: 'Location',
      key: 'locationName',
      width: 15,
      sorter: (a, b) =>
        a.locationName && b.locationName ? a.locationName.localeCompare(b.locationName) : 0,
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.locationName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 15,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Status',
      key: 'isActive',
      width: 8,
      sorter: (a, b) => a.isActive.localeCompare(b.isActive),
      sortOrder: sortedInfo.columnKey === 'isActive' ? sortedInfo.order : null,
      render: (a, record) => {
        return <Tag color={record.isActive === 'ACTIVE' ? 'green' : 'red'}>{record.isActive}</Tag>;
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 4,
      fixed: 'right',
      render: (a, record) => {
        const menuItems: {
          key: string;
          label: JSX.Element;
          onClick?: () => void;
        }[] = [];

        if (checkAccess('UPDATE_DISCOUNT')) {
          menuItems.push({
            key: '1',
            label: (
              <Tooltip title="Update" color="blue">
                <Link to={`/offer/discount-product/${record.id}`}>
                  <EditOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Link>
              </Tooltip>
            )
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            <div>
              <TableFilter
                dateCustom={false}
                defaultValues={{
                  value: '',
                  skip: 0,
                  count: 100,
                  locationId: preferenceLocationId ? preferenceLocationId : 1
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                styleforbuttons={'flex justify-end items-center'}
                buttons={
                  <>
                    {checkAccess('CREATE_DISCOUNT') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate('/offer/discount-product/new')}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/offer/discount-product/new"
                        />
                      </div>
                    )}
                  </>
                }>
                <LocationSearch
                  formData={{ formLabel: 'Location', formName: 'locationId' }}
                  allowClear={true}
                />
                <Form.Item name={'isActive'} label="Status">
                  <Select placeholder="Select status" allowClear>
                    {(Object.keys(DiscountStatus) as Array<keyof typeof DiscountStatus>).map(
                      (key) => (
                        <Select.Option key={key} value={key}>
                          {DiscountStatus[key]}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          columns={columns}
          data={discountList.results}
          notshowPagination={true}
          customScroll={{ x: 800, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: discountList.count,
            size: size,
            onPagination
          }}
          tableName={'offers-discount-product-list'}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default DiscountProductList;
