import ReactQuill from 'react-quill';
import { Alert, PageHeader } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';

import { cleanHTML } from './textEditor.services';
import { formats, modules, QuillToolbar } from './Toolbar';

interface Props {
  defaultValue?: string;
  getHtml: (html: string) => void;
}

function TextEditor({ defaultValue = '', ...props }: Props) {
  const [value, setValue] = useState('');
  const quillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  function handleChange(html: string) {
    setValue(html);
    props.getHtml(html);
  }

  function undo() {
    if (!quillRef.current) return;
    const editor = quillRef.current.getEditor() as any;
    editor?.history.undo();
  }

  function redo() {
    if (!quillRef.current) return;
    const editor = quillRef.current.getEditor() as any;
    editor?.history.redo();
  }

  const module = useMemo(() => modules(undo, redo), []);

  return (
    <div className="text-editor">
      <div className="mb-2">
        <Alert
          message="Information!"
          description="Link should follow relative path. e.g. http://localhost:3000/ERP-UI#/settings/menu-list is not allowed. Use /settings/menu-list instead."
          type="info"
          closable
          showIcon
        />
      </div>
      <QuillToolbar />
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={value}
        formats={formats}
        onChange={handleChange}
        placeholder="Write something informational..."
        modules={module}
      />

      {value && (
        <>
          <PageHeader subTitle="Preview" style={{ padding: '8px 0px' }} />
          <div className="ql-editor px-0 !min-h-0">
            <Alert description={<div dangerouslySetInnerHTML={{ __html: cleanHTML(value) }} />} />
          </div>
        </>
      )}
    </div>
  );
}

export default TextEditor;
