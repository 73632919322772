import { WidgetType } from '@/services/dashboard/v3/enums';
import { IWidgetConfiguration } from '@/services/dashboard/v3/types';
import SingleReportWidget from './SingleReportWidget';
import MultipleReportWidget from './MultipleReportWidget';

interface Props {
  type: 'main' | 'customize';
  widget: IWidgetConfiguration;
  defaultAPIConfig: { locationId: number; page: number; size: number };
  reports: { id: number; name: string }[];
}

function RenderWidget(props: Props) {
  const type = props.widget.type;

  switch (type) {
    case WidgetType.REPORT:
      if (props.widget.isMultiple) {
        return <MultipleReportWidget {...props} />;
      }

      return <SingleReportWidget {...props} />;

    case WidgetType.OTHER:
      return <div>Other</div>;

    default:
      return <div>NO WIDGET</div>;
  }
}

export default RenderWidget;
