import { getValue } from '@testing-library/user-event/dist/utils';
import { Button, Divider, Form, message, Select, Spin } from 'antd';
import React, { useState, useEffect, BaseSyntheticEvent, SyntheticEvent, useRef } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { get_product_list, get_product_list_categoryId } from '../../../services/products/queries';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import CustomErrorModal from '../CustomErrorModal';
import { getUserData } from '../../../utils/auth.utils';

interface Iprops {
  add2?: any;
  onProductChange: (val: any, name?: any) => Promise<void>;
  productList: any[];
  setProductList: React.Dispatch<React.SetStateAction<any[]>>;
  productSearch: any[];
  setProductSearch: React.Dispatch<React.SetStateAction<any[]>>;
  name?: any;
  autofocusRef?: any;
  isProductAlreadyAdded?: (index: number, checkproductId: number) => boolean;
  index?: number;
  disabled?: boolean;
  locationId?: number;
  validation?: boolean;
  productCategory?: number | string | null;
  isMultiple?: boolean;
}

const ProductSearchForLines = ({
  add2,
  onProductChange,
  productList,
  setProductList,
  productSearch,
  setProductSearch,
  name,
  autofocusRef,
  isProductAlreadyAdded,
  index,
  disabled = false,
  locationId,
  validation,
  productCategory,
  isMultiple = false
}: Iprops) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);
  // console.log('-->', isProductAlreadyAdded, index);
  // console.log('auto Focus', autoFocus);
  //   const [productSearch, setProductSearch] = useState<any[]>([]);
  //   const [productList, setProductList] = useState<any[]>([]);
  const { Option } = Select;
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);

  if (!locationId) {
    const { preferences } = getUserData();
    locationId = preferences?.preferences
      ? JSON.parse(preferences?.preferences)?.locationId
      : message.error('Please choose a location from preferences!');
  }

  const searchProductIndexDB = async (value: string) => {
    try {
      let response = await ProductsDB.searchProduct(value);
      if (productCategory) {
        if (productCategory !== 'default') {
          response = response.filter((val) => val.categoryId === productCategory);
        }
      }
      response = response.filter(
        (val) =>
          !val.hidden && val.status.some((el: any) => el.locationId === locationId && !el.hidden)
      );
      if (!response || response.length == 0) {
        message.info('Cannot find any product with that name in cache, searching in server...');
        searchProduct(value);
      } else {
        setProductSearch(response);
        checkProductAndAdd(response);
        setIsCacheResponse(true);
      }
    } catch (error) {
      message.error(error as string);
    }
  };
  const searchProduct = async (value: any) => {
    let response;
    try {
      if (productCategory && typeof productCategory === 'number') {
        response = await get_product_list_categoryId(0, 10, value, productCategory);
      } else {
        response = await get_product_list(0, 10, value);
      }
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any product with that name!');
      setProductSearch([]);
      setIsMore(false);
    } else {
      setProductSearch(response.data.results);
      checkProductAndAdd(response.data.results);
      await ProductsDB.addProducts(response.data.results);
      if (response.data.results.length < 10) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
    }
    setIsCacheResponse(false);
    setSkip(10);
    // setSearchLoading(false);
  };

  const searchMoreProduct = async (value: any) => {
    let response;
    try {
      if (productCategory && typeof productCategory === 'number') {
        response = await get_product_list_categoryId(skip, 10, value, productCategory);
      } else {
        response = await get_product_list(skip, 10, value);
      }
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.info('Cannot find more products with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);
      setProductSearch([...productSearch, ...response.data.results]);
      checkProductAndAdd(response.data.results);
      await ProductsDB.addProducts(response.data.results);

      if (response.data.results.length < 10) {
        setIsMore(false);
      }
    }
  };

  useEffect(() => {
    searchProductIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue, productCategory]);

  const checkProductAndAdd = (products: any[]) => {
    if (productList.length > 0) {
      products = products.filter((value) => {
        const searchProduct = productSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (products.length > 0)
        setProductList((prevValue) => {
          products = products.filter((value) => {
            const searchProduct = prevValue.find((val: any) => val.id == value.id);
            if (searchProduct) return false;
            return true;
          });
          return [...prevValue, ...products];
        });
    } else {
      setProductList(products);
    }
  };
  const options = productSearch
    .filter(
      (val) =>
        !val.hidden && val.status.some((el: any) => el.locationId === locationId && !el.hidden)
    )
    .map((d) => <Option key={d.id} value={d.id}>{`${d.name} (${d.sku})`}</Option>);

  return (
    <>
      <Select
        ref={autofocusRef}
        id="productSearch-custom"
        // onInputKeyDown={(val) => handleInputKeyDown(val)}
        showSearch
        placeholder={'Search product'}
        defaultActiveFirstOption={false}
        style={{ borderRadius: '10px' }}
        autoClearSearchValue={true}
        showArrow={false}
        filterOption={false}
        disabled={disabled}
        onSearch={(val) => {
          setSearchValue(val);
        }}
        onClick={async (val) => {
          if (isMultiple) {
            await searchProductIndexDB('');
          }
        }}
        onSelect={async (val: number) => {
          const product = productList.find((value) => value.id == val);
          const hasDefaultUnit = product?.productUnits?.find((unit: any) => unit.isDefault);
          if (!hasDefaultUnit) {
            message.warning('This product does not have a default unit', 5);
          }

          if (
            product &&
            typeof productCategory === 'number' &&
            product.categoryId !== productCategory
          ) {
            return message.error('Please wait for product to load');
          }
          if (validation !== undefined && !validation) {
            message.error('Please select category');
          } else {
            const selectedProduct = productSearch.find((value) => value.id == val);
            if (isProductAlreadyAdded && Number.isInteger(index)) {
              if (isProductAlreadyAdded(index!, val)) {
                add2({
                  productId: val,
                  productName: selectedProduct.name,
                  unitId: null,
                  isLot: true,
                  lotId: null,
                  quantity: null,
                  unitPrice: 0,
                  discount: 0,
                  misc: 0,
                  total: 0,
                  isTraceable: false
                  // autoFocus: true
                });
              } else {
                CustomErrorModal({
                  title: 'Error!',
                  message: selectedProduct.name + ' is already Added!'
                });
                return;
              }
            } else {
              add2({
                productId: val,
                productName: selectedProduct.name,
                unitId: null,
                lotId: null,
                quantity: null,
                unitPrice: 0,
                discount: 0,
                misc: 0,
                total: 0,
                isTraceable: false
                // autoFocus: true
              });
            }
            // setselected('');
            if (name !== undefined) {
              // console.log('this runs');
              await onProductChange(val, name); //this is for procruement create
            } else {
              await onProductChange(val);
            }
          }
        }}
        notFoundContent={null}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            {isCacheResponse ? (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                {/* <div
                  style={{
                    color: 'green',
                    width: '100%',
                    textAlign: 'center'
                  }}>{`Cache found ${productSearch.length} data`}</div> */}
                <Button
                  type="text"
                  style={{
                    color: 'blue',
                    width: '100%'
                  }}
                  onClick={() => {
                    searchProduct(searchValue);
                  }}>
                  {/* {'Sync & Search on Server'} */}
                  {'Pull More & Sync'}
                </Button>
              </div>
            ) : (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                {isMore ? (
                  <Button
                    type="text"
                    style={{
                      color: 'blue',
                      width: '100%'
                    }}
                    onClick={() => {
                      searchMoreProduct(searchValue);
                    }}>
                    {/* {'Sync & Search on Server'} */}
                    {'Get More...'}
                  </Button>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center'
                    }}>
                    No more data...
                  </div>
                )}
              </div>
            )}
          </>
        )}>
        {options}
      </Select>
    </>
  );
};

export default ProductSearchForLines;
