import * as ExcelJS from 'exceljs';
import getGroupedData from './getGroupedData';
import { IDetailedLedgerTableData, IExportProps } from '@/services/accounts/types';
import getCharFromNumber from './getCharFromNumber';
import { convertUTCStringtoLocalString } from './convertToUTC';

type Options = IExportProps<any> & {
  selectedColumns: string[];
};

const centerAlignment = { horizontal: 'center', vertical: 'middle' } as ExcelJS.Alignment;

function getColumnValues(column: number) {
  if (column % 3 === 0) {
    return [column / 3, column / 3, column / 3];
  }

  if (column % 3 === 1) {
    return [Math.floor(column / 3), Math.ceil(column / 3), Math.floor(column / 3)];
  }

  return [Math.floor(column / 3), Math.ceil(column / 3), Math.ceil(column / 3)];
}

function addBalanceRow(
  worksheet: ExcelJS.Worksheet,
  balance: number,
  hasAccount: boolean,
  accountName: string
) {
  const openingBalanceRow = worksheet.addRow([]);
  const runningBalanceColumn = openingBalanceRow.getCell('runningBalance');
  runningBalanceColumn.value = balance;

  if (hasAccount) {
    openingBalanceRow.getCell('accountName').value = accountName;
  } else {
    const previousColumn = worksheet.getColumn(parseInt(runningBalanceColumn.col) - 1).key;

    if (previousColumn) {
      openingBalanceRow.getCell(previousColumn).value = accountName;
    }
  }
}

function exportLedgerDynamic({ data, account, selectedColumns, ...props }: Options) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Detailed Ledger');

  const isAccountSelected = account !== '--';
  const openingBalance = data.find((d) => d.accountName === 'Opening Balance');
  const closingBalance = data.find((d) => d.accountName === 'Closing Balance');

  // Exclude Opening And Closing Balance Information
  const dataWithoutBalance = data.filter(
    (d) =>
      !['Opening Balance', 'Closing Balance', 'Current Closing Balance'].includes(
        d.accountName ?? ''
      )
  );

  const hasAccountColumn = selectedColumns.includes('Account');
  const hasRunningBalance = selectedColumns.includes('Running Balance');

  const headers = [
    { key: 'sn', label: 'SN', width: 5 },
    { key: 'journalName', label: 'Journal', width: 25 },
    { key: 'accountName', label: 'Account', width: 20 },
    { key: 'debit', label: 'Debit', width: 15 },
    { key: 'credit', label: 'Credit', width: 15 },
    { key: 'runningBalance', label: 'Running Balance', width: 15 },
    { key: 'ledgerType', label: 'Reference', width: 25 },
    { key: 'refNumber', label: 'Ref Number', width: 25 },
    { key: 'locationName', label: 'Location', width: 15 },
    { key: 'journalDate', label: 'Trn. Date', width: 15 },
    { key: 'createdAt', label: 'Created Date', width: 15 }
  ].filter((header) => selectedColumns.includes(header.label));

  const allKeys = headers.map((header) => header.key);
  const mergeAbleColumns = [
    'sn',
    'journalName',
    'ledgerType',
    'refNumber',
    'locationName',
    'journalDate',
    'createdAt'
  ].filter((key) => allKeys.includes(key));

  const balanceKey = ['credit', 'debit', 'runningBalance'];
  const isBalanceFirst = balanceKey.includes(headers[0].key);

  // Make sure first column is not balance column
  if (isBalanceFirst) {
    const columnWithoutBalance = headers.findIndex((header) => !balanceKey.includes(header.key));

    if (columnWithoutBalance > 0) {
      const removedColumn = headers.splice(columnWithoutBalance, 1);
      headers.unshift(removedColumn[0]);
    }
  }

  const endColumn = getCharFromNumber(headers.length - 1);
  worksheet.columns = headers.map((header) => ({
    header: header.label,
    key: header.key,
    width: headers.length > 4 ? header.width : 25
  }));

  // group the data by journalReferenceId. return array of array of rows
  const groupedData = getGroupedData<IDetailedLedgerTableData>(dataWithoutBalance, 'journalId');
  const groupedRows = Object.values(groupedData);
  groupedRows.sort((a, b) => a[0].journalDate.localeCompare(b[0].journalDate));

  // Merge cells for the title "Party Ledger"
  worksheet.mergeCells(`A1:${endColumn}1`);
  const titleCell = worksheet.getCell('A1');
  titleCell.value = 'Party Ledger';
  titleCell.alignment = centerAlignment;
  titleCell.font = { size: 14, bold: true };

  const [first, second] = getColumnValues(headers.length);

  // Divide the columns into three equal parts
  const firstColumnEnd = getCharFromNumber(first - 1);
  const secondColumnStart = getCharFromNumber(first);
  const secondColumnEnd = getCharFromNumber(first + second - 1);
  const thirdColumnStart = getCharFromNumber(first + second);

  // Merge and add Account Information
  const accountInfo = [
    {
      key: 'account',
      label: `Account: ${account}`,
      merge: `A2:${firstColumnEnd}2`,
      alignment: 'left'
    },
    {
      key: 'address',
      alignment: 'center',
      label: 'Address: Kathmandu, Nepal',
      merge: `${secondColumnStart}2:${secondColumnEnd}2`
    },
    {
      key: 'contact',
      alignment: 'right',
      label: 'Contact: 9876543210',
      merge: `${thirdColumnStart}2:${endColumn}2`
    }
  ];

  accountInfo.forEach((info) => {
    worksheet.mergeCells(info.merge);
    const cell = worksheet.getCell(info.merge.split(':')[0]);
    cell.value = info.label;

    // Set styles for the account information
    cell.font = { bold: true };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'f2f2f2' }
    };

    const horizontalAlignment = info.alignment as 'left' | 'right' | 'center';
    cell.alignment = { horizontal: horizontalAlignment, vertical: 'middle' };
  });

  const dateRange = [
    {
      key: 'fromDate',
      alignment: 'left',
      label: `From Date: ${props.from}`,
      merge: `A3:${firstColumnEnd}3`
    },
    {
      key: 'toDate',
      alignment: 'center',
      label: `To Date: ${props.to}`,
      merge: `${secondColumnStart}3:${secondColumnEnd}3`
    },
    {
      key: 'balance',
      alignment: 'right',
      label: `Current Balance: ${props.balance}`,
      merge: `${thirdColumnStart}3:${endColumn}3`
    }
  ];

  dateRange.forEach((info) => {
    worksheet.mergeCells(info.merge);
    const cell = worksheet.getCell(info.merge.split(':')[0]);
    cell.value = info.label;

    // Set styles for the date range
    const horizontalAlignment = info.alignment as 'left' | 'right' | 'center';
    cell.alignment = { horizontal: horizontalAlignment, vertical: 'middle' };
  });

  // Add empty row
  worksheet.addRow([]);

  // Add Header Row
  const headerRow = worksheet.addRow(headers.map((header) => header.label));

  // Set width of columns
  headerRow.eachCell((cell) => {
    cell.style = { alignment: centerAlignment };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'f2f2f2' }
    };
  });

  if (openingBalance && hasRunningBalance) {
    addBalanceRow(
      worksheet,
      openingBalance.runningBalance ?? 0,
      hasAccountColumn,
      openingBalance.accountName ?? 'Opening Balance'
    );

    worksheet.addRow([]);
  }

  // Add Data and Conditional Formatting
  groupedRows.forEach((rows, index) => {
    let firstRowStart = 0;

    const isEven = index % 2 === 0;

    rows.forEach((row, rowIndex) => {
      const rowStart = worksheet.rowCount + 1;

      if (rowIndex === 0) {
        firstRowStart = rowStart;
      }

      const rowData = headers.map((header) => {
        if (header.key === 'sn') {
          return index + 1;
        }

        const value = (row as any)[header.key];

        if (['debit', 'credit', 'runningBalance'].includes(header.key)) return value || 0;

        const isDate = ['createdAt', 'journalDate'].includes(header.key);
        if (isDate) return convertUTCStringtoLocalString(value, 'YYYY-MM-DD h:mm A');

        return value || '';
      });

      const color = !isEven ? 'f2f2f2' : 'ffffff';

      worksheet.addRow(rowData).eachCell((cell, col) => {
        const isNumber = typeof cell.value === 'number';
        const isAccount = col === 3;

        cell.style = {
          alignment: {
            horizontal: isNumber && col !== 1 ? 'right' : isAccount ? 'left' : 'center',
            vertical: 'middle',
            wrapText: true
          }
        };

        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        };
      });
    });

    // Now merge the rows
    const mergeStart = firstRowStart;
    const mergeEnd = mergeStart + rows.length - 1;

    mergeAbleColumns.forEach((key) => {
      const colLetter = getCharFromNumber(headers.findIndex((header) => header.key === key));

      const isLeft = ['journalName'].includes(key);

      worksheet.mergeCells(`${colLetter}${mergeStart}:${colLetter}${mergeEnd}`);

      // Set styles for the merged cells and make the cells vertically aligned
      worksheet.getCell(`${colLetter}${mergeStart}`).alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: isLeft ? 'left' : 'center'
      };
    });
  });

  if (closingBalance && hasRunningBalance) {
    addBalanceRow(
      worksheet,
      closingBalance.runningBalance ?? 0,
      hasAccountColumn,
      closingBalance.accountName ?? 'Closing Balance'
    );
  }

  // Add empty row
  worksheet.addRow([]);

  // Create Footer Row, add value Transaction Total with bold and center. also add total value of debit and credit
  const defaultFooterValue = Array.from({ length: headers.length }).map(() => '');
  const footerRow = worksheet.addRow(defaultFooterValue);
  const rowCount = worksheet.rowCount;

  // Get debit and credit column. if present add the total value
  const hasDebit = selectedColumns.includes('Debit');
  const hasCredit = selectedColumns.includes('Credit');

  if (hasDebit) {
    footerRow.getCell('debit').value = props.total.debit;
  }

  if (hasCredit) {
    footerRow.getCell('credit').value = props.total.credit;
  }

  if (isAccountSelected && hasRunningBalance) {
    const value = closingBalance ? closingBalance.runningBalance : props.balance;
    footerRow.getCell('runningBalance').value = value;
  }

  const indexOfTransaction = headers.findIndex(
    (header) =>
      header.label === 'Debit' || header.label === 'Credit' || header.label === 'Running Balance'
  );

  if (indexOfTransaction > 0) {
    const prevColumn = getCharFromNumber(indexOfTransaction - 1);
    const footerMerge = `A${rowCount}:${prevColumn}${rowCount}`;
    worksheet.mergeCells(footerMerge);
    const footerCell = worksheet.getCell(footerMerge.split(':')[0]);
    footerCell.value = 'Transaction Total';
  }

  footerRow.eachCell((cell, col) => {
    if (col === 1) {
      cell.style = { alignment: centerAlignment, font: { bold: true } };
    }

    cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'f2f2f2' } };
  });

  // Generate & Save Excel File

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'ledger.xlsx';
    a.click();
  });
}

export default exportLedgerDynamic;
