import { ITransferInvoice } from '../../../../services/invoice/types';
import { get_location_details } from '../../../../services/locations/queries';
import { get_product_list_ids, get_units_list } from '../../../../services/products/queries';
import {
  get_transfer_in_details,
  get_transfer_out_details,
  get_transfer_out_lines_details
} from '../../../../services/transfer/queries';
import LocationsDB from '../../../../store/localstorage/LocationsDB';
import ProductsDB from '../../../../store/localstorage/ProductsDB';
import UnitsDB from '../../../../store/localstorage/UnitsDB';

export const getTransferPrintData = async (
  transferId: number,
  locationFrom: number,
  locationTo: number,
  curr: any
) => {
  const response = await get_transfer_out_details(transferId);

  // Fetch Lines and Net Total
  const linesAndNetTotal = await getLinesForAdjustmentInvoice(transferId);
  const lines = linesAndNetTotal.lines;
  const netTotalAmount = linesAndNetTotal.netTotalAmount;

  // Fetch Location From
  const locFrom = await getLocationDetails(locationFrom);

  // Fetch Location From
  const locTo = await getLocationDetails(locationTo);

  return {
    transferData: {
      ...response,
      fromName: locFrom.name,
      fromDetails: `${locFrom.city}, ${locFrom.country}`,
      fromEmail: locFrom.email,
      toName: locTo.name,
      toDetails: `${locTo.city}, ${locTo.country}`,
      toEmail: locTo.email,
      netTotalAmount: netTotalAmount
    },
    lines: lines,
    invoiceLayouts: curr
  } as ITransferInvoice;
};

const getLinesForAdjustmentInvoice = async (transferId: number) => {
  const linesresponse = await get_transfer_out_lines_details(transferId);
  let netTotalAmount = 0;
  if (linesresponse.data.length > 0) {
    const searchProducts: any = {};
    for (let index = 0; index < linesresponse.data.length; index++) {
      const product: any = await ProductsDB.getProduct(linesresponse.data[index].productId);
      if (!product) {
        if (linesresponse.data[index].productId in searchProducts) {
          searchProducts[linesresponse.data[index].productId] = [
            ...searchProducts[linesresponse.data[index].productId],
            index
          ];
        } else {
          searchProducts[linesresponse.data[index].productId] = [index];
        }
      } else {
        linesresponse.data[index].productName = product.name;
      }

      let findUnit = await UnitsDB.getUnit(linesresponse.data[index].unitId);
      if (!findUnit) {
        const allUnits = await get_units_list();
        await UnitsDB.addUnits(allUnits);
        findUnit = await UnitsDB.getUnit(linesresponse.data[index].unitId);
      }
      if (typeof findUnit === 'object') {
        linesresponse.data[index].unitName = findUnit.name;
        linesresponse.data[
          index
        ].quantity = `${linesresponse.data[index].quantity} ${findUnit.shortName}`;
        linesresponse.data[index].rate = linesresponse.data[index].unitPrice;
        netTotalAmount += linesresponse.data[index].totalAmount;
      }
    }
    const searchProductslength = Object.entries(searchProducts).length;
    if (searchProductslength > 0) {
      const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
      for (const key in searchProducts) {
        const findproduct = productsresponse?.data?.results.find(
          (currProduct) => currProduct.id == key
        );
        for (let i = 0; i < searchProducts[key].length; i++) {
          linesresponse.data[searchProducts[key][i]].productName = findproduct?.name;
        }
        await ProductsDB.addProducts([findproduct]);
      }
    }
  }

  return { lines: linesresponse.data, netTotalAmount: netTotalAmount };
};

const getLocationDetails = async (locationId: number) => {
  let locationDetails = await LocationsDB.getLocation(locationId);
  if (!locationDetails) {
    locationDetails = await get_location_details(locationId);
    await LocationsDB.addLocations([locationDetails]);
  }
  return locationDetails;
};
