import { AxiosResponse } from 'axios';

import http from '@/utils/http.utils';
import { IUserAgents, IUserAgentsResults } from './types';
import { getDataForIds } from '@/utils/queries.utils';

export const get_agents_list_v2 = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IUserAgentsResults, any> = await http.get('user-agent/?' + filter, {
    skip,
    count,
    value
  });
  return response;
};

export const get_agent_list_ids = async (ids: number[]) => {
  const parsedIds = ids.map(Number);
  const response = await getDataForIds<IUserAgents>(parsedIds, 'user-agent/?');
  return response;
};

export const get_agents_list_only = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IUserAgentsResults, any> = await http.get('user-agent/?' + filter, {
    skip,
    count,
    value
  });
  return response.data.results;
};

export const get_agents_list = async (url: string) => {
  const response: AxiosResponse<IUserAgentsResults, any> = await http.get('user-agent/?' + url);
  return response;
};

export const get_agent_by_id = async (id: number) => {
  const response: AxiosResponse<IUserAgents, any> = await http.get(`user-agent/${id}`);
  return response;
};

export const get_agent_by_user_id = async (userIds: number[], isAll = false) => {
  let query = userIds.map((id) => `userIds=${id}`).join('&');

  if (userIds.length === 1) {
    query += '&userIds=0';
  }

  if (isAll) query += '&archiveStatus=ALL';

  const { data } = await get_agents_list(query);
  return data.results;
};
