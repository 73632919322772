import getGroupedData from '@/utils/getGroupedData';
import { forwardRef } from 'react';
import RenderTableV2 from './TableList';
import { PageHeader } from 'antd';

interface IExportData {
  productId: number;
  productName: string;
  price: number;
  unit: string;
  category: string;
}

interface Props {
  data: IExportData[];
  date: string;
  isOutlet?: boolean;
}

interface TableProps {
  isEven?: boolean;
  data: { [key: string]: IExportData[] };
}

function divideCategoryInHalf(data: IExportData[]) {
  const productsByCategory = getGroupedData(data, 'category');
  const allCategories = Object.keys(productsByCategory);

  const evenProductsByCategory: TableProps['data'] = {};
  const oddProductsByCategory: TableProps['data'] = {};

  for (let i = 0; i < allCategories.length; i++) {
    const category = allCategories[i];
    if (i % 2 === 0) {
      evenProductsByCategory[category] = productsByCategory[category];
    } else {
      oddProductsByCategory[category] = productsByCategory[category];
    }
  }

  return { evenProductsByCategory, oddProductsByCategory };
}

const PriceList = forwardRef<HTMLDivElement, Props>(({ data, date, isOutlet }, ref) => {
  const { evenProductsByCategory, oddProductsByCategory } = divideCategoryInHalf(data);

  const evenProductsLength = Object.values(evenProductsByCategory).flat().length;
  const oddProductsLength = Object.values(oddProductsByCategory).flat().length;
  const maxPossibleRows = Math.max(evenProductsLength, oddProductsLength);

  return (
    <div>
      <PageHeader title={'Generated Price List'} style={{ padding: '8px 0' }} />
      <div ref={ref} style={{ width: '1400px', padding: '20px' }}>
        <div className="flex-center">
          <img
            src="/images/flogo_long_colorful.png"
            alt="freshktm-logo"
            width={220}
            className="object-contain"
          />
        </div>

        <div
          className="font-bold text-base flex-center h-10 mt-4"
          style={{ border: '1px solid black', background: '#e7e6e6', borderBottom: 'none' }}>
          {isOutlet ? 'Outlet' : 'B2B'} Price List for {date}
        </div>
        <div className="grid grid-cols-2">
          <div>
            <RenderTableV2
              isEven
              maxPossibleRows={maxPossibleRows}
              groupByCategories={evenProductsByCategory}
            />
          </div>
          <div>
            <RenderTableV2
              maxPossibleRows={maxPossibleRows}
              groupByCategories={oddProductsByCategory}
            />
          </div>
        </div>

        <div
          className="font-bold text-base flex-center h-10 italic"
          style={{ border: '1px solid black', background: '#e7e6e6', borderTop: 'none' }}>
          The prices mentioned above are per kilogram excluding VAT. All items have been graded.
        </div>
      </div>
    </div>
  );
});

PriceList.displayName = 'PriceList';
export default PriceList;
