import React, { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import CustomizeTable from '../../../../components/Common/CustomizeTable/CustomizeTable';
import LocationsDB from '../../../../store/localstorage/LocationsDB';
import { get_location_list } from '../../../../services/locations/queries';

const LotHistoryModal = ({ record }: { record: any }) => {
  //   console.log('record', record.histories);
  const [loading, setIsloading] = useState(true);
  const histories = JSON.parse(record.histories);
  const [tableData, setTableDate] = useState<any>([]);
  //   console.log('histories', histories);

  useEffect(() => {
    addLocationName();
  }, []);

  const addLocationName = async () => {
    for (let index = 0; index < histories.length; index++) {
      let locD: any = await LocationsDB.getLocation(histories[index].locationId);
      if (!locD) {
        // locD = await get_location_details(response.data.resultsWithCustomers[index].locationId);
        const allLocations = await get_location_list();
        // console.log('allLocations-->', allLocations);
        await LocationsDB.addLocations([...allLocations.data.results]);
        // await LocationsDB.addLocations([locD]);
        locD = await LocationsDB.getLocation(histories[index].locationId);
      }
      histories[index].locationName = locD.name;
    }
    setIsloading(false);
    setTableDate(histories);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'LocationID',
      key: 'locationName',
      render: (record) => {
        return <div>{record.locationName}</div>;
      }
    },
    {
      title: 'Identifier',
      key: 'identifier',
      render: (record) => {
        return <div>{record.identifier}</div>;
      }
    },
    {
      title: 'IdentifierId',
      key: 'IdentifierId',
      render: (record) => {
        return <div>{record.identifierId}</div>;
      }
    },
    {
      title: 'Created By',
      key: 'createdByName',
      render: (record) => {
        return <div>{record.createdByName}</div>;
      }
    }
  ];
  return (
    <div>
      <CustomizeTable
        usersLoading={loading}
        columns={columns}
        data={tableData}
        notshowPagination={true}
        customScroll={{}}
      />
    </div>
  );
};

export default LotHistoryModal;
