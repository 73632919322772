import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { ISalesRegisterListItemWise } from '@/services/ird/sales/types';
import { IExportNestedColumn } from '@/utils/exportNestedExcel';

export const excelColumns: IExportNestedColumn<ISalesRegisterListItemWise>[] = [
  {
    title: 'INVOICE',
    dataIndex: '',
    children: [
      { title: 'DATE', width: 12, dataIndex: 'date' },
      { title: 'MITI', width: 12, dataIndex: 'miti' },
      { title: 'TIME', width: 12, dataIndex: 'time' },
      { title: 'BILL NUMBER', dataIndex: 'financialReference', width: 30 },
      { title: 'BUYER NAME', dataIndex: 'buyersName', width: 35 },
      { title: 'BUYER PAN', dataIndex: 'customerPan', width: 12 },
      { title: 'ITEM NAME', dataIndex: 'productName', width: 40 },
      {
        title: 'QUANTITY',
        dataIndex: 'quantity',
        width: 12,
        showDashIfAbsent: true,
        formatByComma: true,
        alignment: { horizontal: 'right' }
      },
      { title: 'UNIT', width: 12, dataIndex: 'unit' }
    ]
  },
  {
    title: 'GROSS SALES AMOUNT',
    width: 12,
    dataIndex: 'grossSalesAmount',
    showDashIfAbsent: true,
    formatByComma: true,
    alignment: { horizontal: 'right' }
  },
  {
    title: 'TOTAL SALES',
    width: 12,
    dataIndex: 'totalSale',
    showDashIfAbsent: true,
    formatByComma: true,
    alignment: { horizontal: 'right' }
  },
  {
    title: 'NON TAXABLE SALES',
    width: 12,
    dataIndex: 'nonTaxableAmount',
    showDashIfAbsent: true,
    formatByComma: true,
    alignment: { horizontal: 'right' }
  },
  {
    title: 'TAXABLE SALES',
    width: 20,
    dataIndex: '',
    children: [
      {
        title: 'AMOUNT',
        dataIndex: 'taxableAmount',
        width: 12,
        showDashIfAbsent: true,
        formatByComma: true,
        alignment: { horizontal: 'right' }
      },
      {
        title: 'VAT',
        dataIndex: 'vat',
        width: 12,
        showDashIfAbsent: true,
        formatByComma: true,
        alignment: { horizontal: 'right' }
      }
    ]
  },
  {
    title: 'EXPORT',
    width: 20,
    dataIndex: '',
    children: [
      { title: 'AMOUNT', width: 10, dataIndex: 'exportAmount' },
      { title: 'COUNTRY', width: 10, dataIndex: 'exportCountry' },
      { title: 'PGP NUMBER', width: 10, dataIndex: 'pgpNumber' },
      { title: 'PGP DATE', width: 10, dataIndex: 'pgpDate' }
    ]
  }
];

export const columnsforPrint: any = [
  {
    title: 'INVOICE',
    width: 100,
    children: [
      {
        title: 'DATE',
        width: 20,
        dataIndex: 'date',
        render: (text: string) => {
          return <TableCell>{text}</TableCell>;
        }
      },
      {
        title: 'MITI',
        width: 20,
        dataIndex: 'miti',
        render: (text: string) => {
          return <TableCell>{text}</TableCell>;
        }
      },
      {
        title: 'TIME',
        width: 20,
        dataIndex: 'time',
        render: (text: string) => {
          return <TableCell>{text}</TableCell>;
        }
      },
      {
        title: 'BILL NUMBER',
        dataIndex: 'financialReference',
        render: (text: string) => {
          return <TableCell>{text}</TableCell>;
        },
        width: 40
      },
      {
        title: 'BUYER NAME',
        dataIndex: 'buyersName',
        width: 35,
        render: (text: string) => {
          return <TableCell>{text}</TableCell>;
        }
      },
      {
        title: 'BUYER PAN',
        dataIndex: 'customerPan',
        width: 20,
        render: (text: string) => {
          return <TableCell>{text}</TableCell>;
        }
      },
      {
        title: 'ITEM NAME',
        dataIndex: 'productName',
        width: 40,
        render: (text: string) => {
          return <TableCell>{text}</TableCell>;
        }
      },
      {
        title: 'QUANTITY',
        dataIndex: 'quantity',
        width: 20,
        render: (text: string) => {
          return <TableCell>{text}</TableCell>;
        }
      },
      {
        title: 'UNIT',
        width: 20,
        dataIndex: 'unit',
        render: (text: string) => {
          return <TableCell>{text}</TableCell>;
        }
      }
    ]
  },
  {
    title: 'GROSS SALES AMOUNT',
    width: 26,
    isNumber: true,
    render: (text: string) => {
      return <TableCell>{text}</TableCell>;
    },
    dataIndex: 'grossSalesAmount'
  },
  {
    title: 'TOTAL SALES',
    width: 20,
    isNumber: true,
    render: (text: string) => {
      return <TableCell>{text}</TableCell>;
    },
    dataIndex: 'totalSale'
  },
  {
    title: 'NON TAXABLE SALES',
    width: 26,
    isNumber: true,
    render: (text: string) => {
      return <TableCell>{text}</TableCell>;
    },
    dataIndex: 'nonTaxableAmount'
  },
  {
    title: 'TAXABLE SALES',
    width: 20,
    children: [
      {
        title: 'AMOUNT',
        isNumber: true,
        render: (text: string) => {
          return <TableCell>{text}</TableCell>;
        },
        dataIndex: 'taxableAmount',
        width: 25
      },
      {
        title: 'VAT',
        isNumber: true,
        render: (text: string) => {
          return <TableCell>{text}</TableCell>;
        },
        dataIndex: 'vat',
        width: 25
      }
    ]
  },
  {
    title: 'EXPORT',
    width: 20,
    children: [
      {
        title: 'AMOUNT',
        width: 20,
        dataIndex: 'exportAmount',
        render: (text: string) => {
          return <TableCell>{text}</TableCell>;
        }
      },
      {
        title: 'COUNTRY',
        width: 20,
        dataIndex: 'exportCountry',
        render: () => {
          return <TableCell>{''}</TableCell>;
        }
      },
      {
        title: 'PGP NUMBER',
        width: 30,
        dataIndex: 'lcNumber',
        render: () => {
          return <TableCell>{''}</TableCell>;
        }
      },
      {
        title: 'PGP DATE',
        width: 20,
        dataIndex: 'lcDateNumber',
        render: () => {
          return <TableCell>{''}</TableCell>;
        }
      }
    ]
  }
];
