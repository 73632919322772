import { IAccountTypeResponseData } from '@/services/accounts/types';
import CustomSearch, { SelectProps } from '..';
import { AccountType } from '@/services/accounts/enums';
import AccountsDB from '@/store/localstorage/AccountsDB';
import { get_account_list_only } from '@/services/accounts/queries';

type MultipleProps =
  | {
      isMultiple?: false;
      defaultValue?: number;
      setSelected?: (selected?: IAccountTypeResponseData) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      isMultiple: true;
      defaultValue?: number[];
      setSelected?: (selected?: IAccountTypeResponseData[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps &
  MultipleProps & {
    type?: AccountType;
    locationId?: number;
  };

function AccountSearchV2({ type, locationId, ...props }: Props) {
  function buildFilter(filter = '') {
    const query = new URLSearchParams(filter);
    if (type) query.set('type', type);
    if (locationId) query.set('location', locationId.toString());
    return query.toString();
  }

  async function getIndexDBAccountByID(id: number | string) {
    const account = await AccountsDB.getAccount(id);
    if (!account) return;
    if (type && account.type !== type) return;
    if (locationId && account.locationId && account.locationId !== locationId) return;
    return account;
  }

  async function getIndeDBAccounts(name: string, limit?: number) {
    const response = await AccountsDB.searchAccountByNameTypeLocation(
      name,
      type,
      locationId,
      limit
    );

    if (!response) return [];
    return response;
  }

  async function searchAccount(skip = 0, count = 100, value = '', filter = '') {
    const query = buildFilter(filter);
    const response = await get_account_list_only(skip, count, value, query);
    return response;
  }

  return (
    <CustomSearch
      formFor="account"
      serverCallback={searchAccount}
      addCallback={AccountsDB.addAccounts}
      dbSearchById={AccountsDB.getAccount}
      dbSearchCallback={getIndeDBAccounts}
      {...props}
    />
  );
}

export default AccountSearchV2;
