import { Button, Divider, PageHeader, Spin } from 'antd';
import { useEffect, useState } from 'react';
import {
  IProcurementModal,
  IPurchaseConfirmation,
  IPurchaseConfirmationData,
  IPurchaseOrderConfirmation,
  Lines,
  LinesForConfirmation
} from '../../../services/procurement/types';
import { ILocations } from '../../../services/locations/types';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import { get_location_list } from '../../../services/locations/queries';
import VendorsDB from '../../../store/localstorage/VendorDB';
import { get_vendor_list, get_vendor_list_v2 } from '../../../services/users/queries';
import { IVendor, IVendorData } from '../../../services/users/types';
import CustomizeTable from '../CustomizeTable/CustomizeTable';
import { ColumnsType } from 'antd/lib/table';
import TableCell from '../CustomizeTable/CustomCell';
import { IUnits } from '../../../services/products/types';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import { get_units_list } from '../../../services/products/queries';
import { nepaliNumberFormatter } from '../../../utils/numberFormatter';
import { ICreatePurchaseOrderConfirm } from '../../../services/purchases/types';
import UsersDB from '../../../store/localstorage/UsersDB';
import DebounceButton from '../DebounceButton';

const ReusablePurchaseOrderConfirm: React.FC<IPurchaseOrderConfirmation> = ({
  disabled,
  data,
  handleSubmitClick
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [locationDetails, setLocationDetails] = useState<ILocations>();
  const [list, setList] = useState<IProcurementModal[]>();

  useEffect(() => {
    if (data) {
      getAllData(data);
    }
  }, [data]);

  const getAllData = async (data: ICreatePurchaseOrderConfirm) => {
    setIsLoading(true);

    if (data.locationId) {
      let loc: ILocations = await LocationsDB.getLocation(data.locationId);
      if (!loc) {
        const allLocations = await get_location_list();
        await LocationsDB.addLocations([...allLocations.data.results]);
        loc = await LocationsDB.getLocation(data.locationId);
      }
      setLocationDetails(loc);
    }

    const mapArray: IProcurementModal[] = [];

    data.lines.map(async (line) => {
      let findUnit: IUnits | string = await UnitsDB.getUnit(line.unitId);
      if (!findUnit) {
        const allUnits = await get_units_list();
        await UnitsDB.addUnits(allUnits);
        findUnit = await UnitsDB.getUnit(line.unitId);
      }
      if (typeof findUnit === 'object') {
        line.unitName = findUnit.shortName;
      }
    });

    const findVendor: IVendor | string = await VendorsDB.getVendors(data.vendorId);
    if (typeof findVendor === 'object') {
      mapArray.push({
        ...findVendor,
        lines: data.lines
      });
    }

    if (data.agentId) {
      const agentInfo = await UsersDB.getUser(data.agentId);
      if (agentInfo) {
        data.agentName = agentInfo.name;
        data.agentPhone = agentInfo.phone;
      }
    }

    setList(mapArray);

    setIsLoading(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    handleSubmitClick();
  };

  const productsColumns: ColumnsType<LinesForConfirmation> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 10,
      render: (a, b, c) => <TableCell>{c + 1}</TableCell>
    },
    {
      title: 'ProductName',
      key: 'productName',
      width: 50,
      render: (a, record) => <TableCell>{record.productName}</TableCell>
    },
    {
      title: 'HS Code',
      key: 'hsCode',
      width: 30,
      render: (a, record) => <TableCell>{record?.hsCode || 'N/A'}</TableCell>
    },
    {
      title: 'Quantity',
      key: 'quantity',
      width: 30,
      render: (a, record) => <TableCell>{`${record.quantity} ${record.unitName}`}</TableCell>
    },
    {
      title: 'Rate',
      key: 'unitPrice',
      width: 30,
      render: (a, record) => (
        <TableCell>{parseFloat(record.unitPrice.toString()).toFixed(2)}</TableCell>
      )
    },
    {
      title: 'Discount',
      key: 'discount',
      width: 30,
      render: (a, record) => (
        <TableCell>{parseFloat(record.discount.toString()).toFixed(2)}</TableCell>
      )
    },
    {
      title: 'Misc.',
      key: 'misc',
      width: 30,
      render: (a, record) => <TableCell>{parseFloat(record.misc.toString()).toFixed(2)}</TableCell>
    },
    {
      title: 'Total',
      key: 'total',
      width: 30,
      render: (a, record) => (
        <TableCell>{record.total && parseFloat(record.total.toString()).toFixed(2)}</TableCell>
      )
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <div className="grid grid-cols-3 gap-5 mb-5">
        <div>
          <PageHeader
            subTitle="Location"
            style={{
              padding: '2px 0px'
            }}
          />
          {data?.locationId && (
            <div>
              {locationDetails?.name}, {locationDetails?.address}, {locationDetails?.state},{' '}
              {locationDetails?.city}, {locationDetails?.country}
            </div>
          )}
          {data?.locationId && <div>{locationDetails?.email}</div>}
        </div>
        {data?.agentId ? (
          <div key={data.agentId}>
            <PageHeader
              subTitle="Agent"
              style={{
                padding: '2px 0px'
              }}
            />
            <div>
              {data.agentName}, {data.agentPhone}
            </div>
          </div>
        ) : null}
        <div>
          {data?.date && (
            <div className="text-right p-2">
              <span className="font-semibold">Date :</span> {new Date(data.date).toDateString()}
            </div>
          )}
        </div>
      </div>
      {list?.map((vendor) => (
        <div key={vendor.invoiceNo ? vendor.id + vendor.invoiceNo : vendor.id}>
          <Divider />
          <PageHeader
            subTitle={vendor.user.name}
            style={{
              padding: '2px 0px'
            }}
          />
          <div>
            {vendor.user.phone}, {vendor.user.email}
          </div>
          <CustomizeTable columns={productsColumns} notshowPagination={true} data={vendor.lines} />
        </div>
      ))}
      <Divider />
      {data?.discount ? (
        <div className="font-semibold">Discount : {nepaliNumberFormatter(data.discount)}</div>
      ) : (
        ''
      )}
      {data?.shippingAmount ? (
        <div className="font-semibold">
          Shipping Amt. : {nepaliNumberFormatter(data.shippingAmount)}
        </div>
      ) : (
        ''
      )}
      {data?.labourCost ? (
        <div className="font-semibold">Labour Cost : {nepaliNumberFormatter(data.labourCost)}</div>
      ) : (
        ''
      )}
      {data?.grandtotal && data.grandtotal > 0 ? (
        <div className="font-semibold">Grand Total : {nepaliNumberFormatter(data.grandtotal)}</div>
      ) : (
        ''
      )}
      <div className="flex justify-end mt-5">
        <DebounceButton disabled={isLoading || disabled} type="primary" onClick={handleSubmit}>
          Confirm
        </DebounceButton>
      </div>
    </Spin>
  );
};

export default ReusablePurchaseOrderConfirm;
