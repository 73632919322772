import { SearchOutlined } from '@ant-design/icons';
import { Divider, Form, Input, Menu, MenuProps } from 'antd';
import { MenuItemsType } from '../../../constants/menuItems/menuItems';
import { useEffect, useState } from 'react';
import { rootSubmenuKeys } from '../../../constants/menu';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useQuery } from '@tanstack/react-query';
import MenuDB from '../../../store/localstorage/MenuDB';
import { IMenuList } from '../../../services/settings/types';
import { convertMenuItemsToItemType } from '../../../services/settings/services';
import { get_menu_items } from '../../../services/settings/queries';

export const SearchForMenuModal: React.FC<{
  searchMenuItems: IMenuList[];
  handleItemClick: () => void;
}> = ({ searchMenuItems, handleItemClick }) => {
  const [menuItem, setMenuItem] = useState<MenuItemsType[]>([]);
  const [defaultMenuItem, setDefaultMenuItem] = useState<MenuItemsType[]>([]);
  const [openItem, setOpenItem] = useState<string[]>([]);
  const [lastValue, setLastValue] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    refetch();
    setOpenItem([]);
  }, [searchMenuItems]);

  const filterBySearch = (searchValue: string) => {
    const currentValue = searchValue;
    if (searchValue !== '') {
      let menuItems = menuItem;
      if (currentValue.length < lastValue.length) {
        menuItems = defaultMenuItem;
      }
      const updatedItems: MenuItemsType[] = searchMenuItem(menuItems, searchValue);

      // menuItems.forEach((menuItem) => {
      //   if (searchItem(menuItem, searchValue)) {
      //     updatedItems.push(menuItem);
      //     // updatedKeys.push(menuItem.key);
      //   }
      // });
      // console.log('updatedKeys', updatedKeys);
      const updatedKeys: string[] = getKeysFromItem(updatedItems);
      setMenuItem(updatedItems);
      setOpenItem(updatedKeys);
    } else {
      refetch();
      setOpenItem([]);
    }
    setLastValue(currentValue);
  };

  const searchMenuItem = (menuItems: MenuItemsType[], searchValue: string) => {
    let filteredMenuItem: MenuItemsType[] = [];

    menuItems.forEach((menuItem) => {
      if (
        menuItem.title.toLowerCase().includes(searchValue.toLowerCase()) ||
        menuItem.keyword.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        filteredMenuItem.push(menuItem);
      }

      if (menuItem.children) {
        const childrens = searchMenuItem(menuItem.children, searchValue);
        if (childrens.length > 0) {
          if (filteredMenuItem.find((val) => val.key === menuItem.key)) {
            filteredMenuItem = filteredMenuItem.map((filteredItem) => {
              if (filteredItem.key === menuItem.key) {
                return { ...filteredItem, children: childrens };
              } else {
                return filteredItem;
              }
            });
          } else {
            filteredMenuItem.push({ ...menuItem, children: childrens });
          }
        }
      }
    });

    return filteredMenuItem;
  };

  const searchItem = (menuItem: MenuItemsType, searchValue: string) => {
    if (
      menuItem.title.toLowerCase().includes(searchValue.toLowerCase()) ||
      menuItem.keyword.toLowerCase().includes(searchValue.toLowerCase())
    )
      return true;

    if (menuItem.children) {
      for (const child of menuItem.children) {
        if (searchItem(child, searchValue)) {
          return true;
        }
      }
    }
    return false;
  };

  const getKeysFromItem = (menuItem: MenuItemsType[]) => {
    const keys: string[] = [];
    for (const item of menuItem) {
      if (item.children) {
        keys.push(item.key);
        const childKeys = getKeysFromItem(item.children);
        keys.push(...childKeys);
      }
    }
    return keys;
  };

  const { refetch } = useQuery(['filterSearchMenu'], async () => await fetchMenuItems());

  const fetchMenuItems = async () => {
    let allMenuItems = await MenuDB.getAllMenuItem();
    if (allMenuItems.length === 0) {
      const menuItems = await get_menu_items();
      await MenuDB.addMenuItem(menuItems.data.results);
      allMenuItems = await MenuDB.getAllMenuItem();
    }
    const topLevelMenuItems = allMenuItems
      .filter((val) => !val.parentId)
      .sort((a, b) => a.position - b.position);
    const convertedMenuItems = convertMenuItemsToItemType(allMenuItems, topLevelMenuItems, true);
    setMenuItem(convertedMenuItems);
    setDefaultMenuItem(convertedMenuItems);
  };

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openItem.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenItem(keys);
    } else {
      setOpenItem(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleClick = () => {
    refetch();
    setOpenItem([]);
    handleItemClick();
  };

  return (
    <div>
      <Form form={form} autoComplete="off">
        <Form.Item name="searchBar">
          <Input
            placeholder="Search for Menu..."
            autoFocus
            prefix={<SearchOutlined className="site-form-item-icon" />}
            bordered={false}
            onChange={(e) => {
              filterBySearch(e.target.value);
            }}
          />
        </Form.Item>
      </Form>
      <Divider />
      <Menu
        style={{ height: '65vh', overflowY: 'auto', overflowX: 'hidden' }}
        mode="inline"
        items={menuItem as ItemType[]}
        onOpenChange={onOpenChange}
        openKeys={openItem}
        onSelect={handleClick}
      />
    </div>
  );
};
