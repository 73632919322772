import moment from 'moment';

export default function isStringUTCDate(value: string) {
  const isNaN = Number.isNaN(Number(value));
  if (!isNaN) return false;

  // Parse the string using moment.js
  const date = moment.utc(value, moment.ISO_8601, true);

  // Check if moment successfully parsed the string and it is a valid UTC date
  return date.isValid() && date.isUTC();
}
