import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { Button, Empty, message, PageHeader, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import UsersDB from '@/store/localstorage/UsersDB';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_user_details } from '@/services/users/queries';
import AppContent from '@/components/Common/Content/Content';
import { IExpenseListRevamp } from '@/services/expense/types';
import { get_expense_by_id } from '@/services/expense/queries';
import ExpenseCategoryDB from '@/store/localstorage/ExpenseCategoryDB';

import {
  get_expense_category_id,
  get_expense_category_table
} from '@/services/expense/expense-category/queries';
import DynamicTable from './DynamicTable';
import { ExpenseCategoryColumnItem } from '@/services/expense/expense-category/types';
import { ExpenseColumnDataType } from '@/services/expense/enum';
import { get_journal_list_by_reference } from '@/services/accounts/queries';
import { AccountRulesEvent } from '@/services/accounts/enums';
import { IJournalListResponseData, IJournalViewResponseTableData } from '@/services/accounts/types';
import { get_location_details } from '@/services/locations/queries';
import InvoicePrintButton from '@/components/Common/InvoicePrintButton/InvoicePrintButton';
import { CustomModal } from '@/components/Common/CustomModal';
import ExpenseInvoice from '@/components/Common/InvoicePrint/ExpensePrint';
import { ICreateInvoiceResponse } from '@/services/settings/types';
import { getJournalLinesDetails } from '@/services/accounts/services';
import JournalDetails from '@/pages/accounts/journal/view/JournalDetails';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { getParsedJSON } from '@/utils';
import { getImagesFromServer } from '@/services/upload/queries';

interface IExpenseViewList extends IExpenseListRevamp {
  locationName: string;
  categoryName: string;
  createdUser: string;
  expenses: IExpenseViewList[];
}

function ExpenseView() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [invoiceLayout, setInvoiceLayout] = useState(Object);
  const [openPrintModal, setOpenPrintModal] = useState(false);

  const [expenseDetail, setExpenseDetail] = useState<IExpenseViewList>(Object);
  const [journalData, setJournalData] = useState<IJournalListResponseData>(Object);
  const [lines, setLines] = useState<IJournalViewResponseTableData[]>([]);

  if (!id) {
    return <h1>Expense Category not found</h1>;
  }

  const breadcrumbItems = [
    { label: 'Expense', link: '/expense/' },
    { label: 'Details', link: `/expense/${id}` }
  ];

  const { isLoading: isJournalLoading, isFetching: isJournalFetching } = useQuery(
    ['journal', id],
    async () => get_journal_list_by_reference(AccountRulesEvent.EXPENSE_CREATE, parseInt(id)),
    {
      onError: () => {
        message.error('Failed to get journal details');
      },
      onSuccess: async (data) => {
        const journalLines = await getJournalLinesDetails(data.id);
        const currentLocation = data.locationId;
        data.locationName = 'N/A';

        if (currentLocation) {
          let location = await LocationsDB.getLocation(currentLocation);
          if (!location) {
            const allLocations = await get_location_details(currentLocation);
            await LocationsDB.addLocations([allLocations]);
            location = await LocationsDB.getLocation(currentLocation);
          }

          data.locationName = location.name;
        }

        setJournalData(data);
        setLines(journalLines.data);
      }
    }
  );

  const { isLoading, isFetching } = useQuery(
    ['expense', id],
    async () => get_expense_by_id(parseInt(id)),
    {
      onError: () => {
        message.error('Failed to get expense details');
      },
      onSuccess: async (data) => {
        // parse content here
        let user = await UsersDB.getUser(data.createdBy);
        if (!user) {
          user = (await get_user_details(data.createdBy)).user;
          await UsersDB.addUsers([user]);
        }

        let category = (await ExpenseCategoryDB.get(data.categoryId)) as any;
        if (!category) {
          category = await get_expense_category_id(data.categoryId);
          await ExpenseCategoryDB.add([category]);
        }

        let location = await LocationsDB.getLocation(data.locationId);
        if (!location) {
          location = await get_location_details(data.locationId);
          await LocationsDB.addLocations([location]);
        }

        const content = JSON.parse(category.content);
        const column = content.column as ExpenseCategoryColumnItem[];

        const categoryByExpense = await get_expense_category_table(data.categoryId, {
          expenseId: data.id
        });

        const booleanColumn = column.filter(
          (col) => col.dataType === ExpenseColumnDataType.BOOLEAN
        );

        const expenseData = await Promise.all(
          categoryByExpense.results.map(async (item: any) => {
            const booleanName = booleanColumn.map((col) => col.name);
            booleanName.forEach((name) => {
              item[name] = item[name] ? 'Yes' : 'No';
            });

            if (item.mediaIds) {
              const parsedMediaIds = getParsedJSON(item.mediaIds);
              if (parsedMediaIds) {
                const images = await getImagesFromServer(parsedMediaIds);
                item.medias = images.map((image) => image.url);
              }
            }

            return item;
          })
        );

        const expenseDetail = {
          ...data,
          createdUser: user?.name,
          categoryName: category?.name,
          locationName: location?.name,
          expenses: expenseData
        };

        setExpenseDetail(expenseDetail);
      }
    }
  );

  function generateInvoiceForPrint(current: ICreateInvoiceResponse) {
    setInvoiceLayout(current);
    setOpenPrintModal(true);
  }

  const isWholeLoading = isLoading || isJournalLoading || isFetching || isJournalFetching;

  return (
    <Spin spinning={isWholeLoading}>
      <CustomModal
        footer={false}
        title="Print Expense Invoice"
        isModalOpen={openPrintModal}
        setIsModalOpen={setOpenPrintModal}>
        <ExpenseInvoice
          lines={lines}
          invoiceLayouts={invoiceLayout}
          expenseDetail={expenseDetail}
          journalDetails={journalData}
          handleModalClose={() => setOpenPrintModal(false)}
        />
      </CustomModal>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader title="Expense Information" style={{ padding: '8px 0px' }} />
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-5 mb-5">
          <div>
            <PageHeader
              subTitle="Billing Details"
              style={{ padding: '8px 0px', fontWeight: 'bold' }}
            />
            <div>No. : {expenseDetail.billNumber}</div>
            <div>Date : {convertLocalToUTCString(expenseDetail.billDate || '', 'YYYY-MM-DD')}</div>
          </div>

          <div>
            <PageHeader subTitle="Category" style={{ padding: '8px 0px', fontWeight: 'bold' }} />
            <div>{expenseDetail.categoryName}</div>
          </div>

          <div>
            <PageHeader subTitle="Details" style={{ padding: '8px 0px', fontWeight: 'bold' }} />

            <div>Reference No. : {expenseDetail.referenceNumber}</div>
            <div>
              <span>{'Location'}</span> :{' '}
              <span className="italic font-bold">{expenseDetail?.locationName}</span>
            </div>
            <div>
              <span>{'Description'}</span> :{' '}
              <span className="italic font-bold">{expenseDetail?.description}</span>
            </div>
          </div>
        </div>

        <h2 className="!text-lg mt-8">Expense Category Information</h2>
        <div className="mt-4">
          {expenseDetail.expenses && expenseDetail.expenses?.length > 0 ? (
            <DynamicTable data={expenseDetail.expenses} type="view" />
          ) : (
            <Empty description="No data available" />
          )}
        </div>

        {/* Journal Details  */}
        <h2 className="!text-lg mt-8">Journal Details</h2>
        <JournalDetails journalData={journalData} lines={lines} />

        {!isWholeLoading && (
          <div className="flex justify-end mt-4 ">
            <div className="flex gap-4 items-center">
              <InvoicePrintButton getInvoice={generateInvoiceForPrint} />
              <Button onClick={() => navigate(-1)}>Back</Button>
            </div>
          </div>
        )}
      </AppContent>
    </Spin>
  );
}

export default ExpenseView;
