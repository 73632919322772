import { Button, Drawer, Form, Select } from 'antd';
import FilterIcon from '../../Icons/FilterIcon';

interface IFilterDrawers {
  onClose: () => void;
  visible: boolean;
}

interface Ivalues {
  category: string;
  productType: string;
}

const LocationFilterDrawers = ({ visible, onClose }: IFilterDrawers) => {
  const [form] = Form.useForm();

  const onFinish = (values: Ivalues) => {
    console.log(values, 'values');
  };

  return (
    <Drawer
      title={
        <div className="flex justify-start items-center">
          <FilterIcon />
          <p className="">Filters</p>
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}>
      <Form name="filter-products" form={form} layout={'vertical'} onFinish={onFinish}>
        <Form.Item label="Product Type" name="productType">
          <Select defaultValue={'all'} allowClear>
            <Select.Option value="all">All</Select.Option>
            <Select.Option value="single">Single</Select.Option>
            <Select.Option value="other">Variable</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Category" name="category">
          <Select defaultValue={'all'} allowClear>
            <Select.Option value="all">All</Select.Option>
            <Select.Option value="special">Special</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default LocationFilterDrawers;
