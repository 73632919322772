import React, { useState, useEffect } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Input, MenuProps, Menu } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AppContent from '../../../components/Common/Content';
import { get_channel_list_filter } from '../../../services/channels/queries';
import TableFilter from '../../../components/FliterTable';
import moment from 'moment';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import ReusableRoom from '../ReusableRoom';
import { checkAccess } from '../../../routes/acl';
import { IChannel, IChannelList } from '../../../services/channels/types';
import CustomUpdateIcon from '../../../components/Common/CustomIcons/CustomUpdateIcon';
import { DEFAULT_DATE_FORMAT } from '@/constants';
// import type { MenuProps } from 'antd';

interface DataType {
  id: number;
  userId: number;
  channelId: number;
  createdAt: string;
  updatedAt: string;
}

const ChannelsList: React.FC = () => {
  // const params = useParams();
  const [searchParams] = useSearchParams();
  // console.log('searchParams', searchParams);
  // console.log('id', searchParams.get('id'));
  // console.log('name', searchParams.get('name'));
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(true);
  const [channelList, setChannelList] = useState<IChannel[]>([]);
  const [channelDetails, setChannelDetails] = useState<IChannel>();
  const [responsize, setResponsize] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currchanneltoEdit, setCurrChanneltoEdit] = useState<IChannel>();

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
  };
  const getInfo = async (filter = '') => {
    setIsloading(true);
    const response = await get_channel_list_filter(filter);
    const channelFiltered = response.data.map((value: IChannelList) => value.channel);
    // response.data = response.data.map((value: any) => value.channel);
    setChannelList(channelFiltered);
    setIsloading(false);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      key: 'name',
      render: (record) => {
        return (
          <Link to={`/channel/room/${record.id}?name=${record.name}`} color="black">
            <div className="forless">{record.name}</div>
          </Link>
        );
      }
    },
    {
      title: 'Created',
      key: 'createdAt',
      render: (record) => {
        return (
          <Link to={`/channel/room/${record.id}?name=${record.name}`} color="black">
            <div className="forless">
              {/* {record.createdAt.slice(0, 10)} */}
              {moment(record.createdAt).local().format(DEFAULT_DATE_FORMAT)}
            </div>
          </Link>
        );
      }
    },

    {
      title: 'Updated',
      key: 'updatedAt',
      render: (record) => {
        return (
          <Link to={`/channel/room/${record.id}?name=${record.name}`} color="black">
            <div className="forless">
              {/* {record.updatedAt.slice(0, 10)} */}
              {moment(record.updatedAt).local().format(DEFAULT_DATE_FORMAT)}
            </div>
          </Link>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (record) => {
        // const confirmFunction = () => {
        //   deleteofferMutation.mutate(record.id);
        // };
        return (
          <div className="flex justify-start items-center">
            <CustomUpdateIcon link={`/channel/${record.slug}`} />
            {/* <DeleteConfirmModal FunctionAfterOk={confirmFunction} /> */}
          </div>
        );
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Tickets',
      link: '/channel'
    }
  ];
  // console.log('Channellist-->', channelList);
  // console.log('channel details-->', channelDetails);

  // useEffect(() => {
  //   if (channelList.length > 0) {
  //     if (!searchParams.get('id')) {
  //       setChannelDetails(channelList[0]);
  //     } else {
  //       const channelId = searchParams.get('id');
  //       const channelInfo = channelList.find((curr: any) => curr.id == channelId);
  //       setChannelDetails(channelInfo);
  //     }
  //   }
  // }, [channelList]);

  useEffect(() => {
    if (window.innerWidth <= 650) {
      // setCollapsed(true);
      setResponsize(true);
    } else {
      setResponsize(false);
    }
    function handleResize() {
      if (window.innerWidth <= 650) {
        // setCollapsed(true);
        setResponsize(true);
      } else {
        setResponsize(false);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <div>Edit Ticket</div>,
      onClick: (record: any) => {
        console.log('Clicked', record);
        navigate(`/channel/${currchanneltoEdit?.slug}`);
      }
    }
  ];
  const menu = <Menu items={items} />;

  const handleClick = (record: any) => {
    console.log('handleClick', record);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  console.log('Channel list ', channelList);
  console.log('CHannel details ', channelDetails);

  return (
    <div>
      {/* <Modal title="Edit Chat Room" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <EditChannel id={currchanneltoEdit.id} />
      </Modal> */}
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        backgroundWhite={true}
        button={
          <div>
            <TableFilter
              defaultValues={{
                dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                test: ''
              }}
              initial={true}
              onSubmit={onSubmitFilter}
              // outsidestyle={'flex flex-wrap items-end gap-2'}
              styleforbuttons={'flex items-center justify-end'}
              style={
                'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
              }
              buttons={
                <>
                  {checkAccess('CREATE_CHANNEL') && (
                    <div>
                      <CustomButton
                        onClick={() => navigate(`/channel/new`)}
                        text="Add New Ticket"
                        backgroundColor="#1890ff"
                        Linkto="/channel/new"
                      />
                    </div>
                  )}
                </>
              }></TableFilter>
          </div>
          // </div>
        }>
        {responsize ? (
          <>
            <div className="flex flex-col gap-3 bg-white rounded-lg p-5">
              <div className="text-lg font-bold">Recent Tickets</div>

              <div>
                <Input
                  style={{ padding: '8px', borderRadius: '9px' }}
                  placeholder="Search for ticket"
                />
              </div>
              <div className="flex flex-col gap-5" style={{ overflow: 'scroll', height: '65vh' }}>
                {channelList?.map((currChannel: any) => {
                  return (
                    <>
                      <div
                        key={currChannel.updatedAt}
                        style={{ cursor: 'pointer' }}
                        className="flex justify-start gap-5 items-center"
                        onClick={() => {
                          console.log('Clicked in mobile view');
                          navigate(`/channel/room/${currChannel.id}?name=${currChannel.name}`);
                        }}>
                        <div>
                          <div
                            className="flex justify-center items-center text-xl "
                            style={{
                              height: '50px',
                              width: '50px',
                              borderRadius: '50%',
                              border: '5px solid #75cbeb',
                              backgroundColor: '#009eda',
                              color: 'white'
                            }}>
                            LS
                          </div>
                        </div>
                        <div>
                          <div className="font-bold">{currChannel.name}</div>
                          <span>
                            {moment(currChannel.updatedAt).local().format(DEFAULT_DATE_FORMAT)}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex gap-1">
              <div
                style={{ flex: '1.5', padding: '25px' }}
                className="flex flex-col gap-3 bg-white rounded-lg">
                <div className="flex justify-between align-center">
                  <div className="text-lg font-bold">Recent Tickets</div>
                  <CustomButton
                    onClick={() => navigate(`/channel/new`)}
                    text="Add Ticket"
                    backgroundColor="#1890ff"
                    Linkto="/channel/new"
                  />
                </div>
                <div className="mt-5">
                  <Input
                    style={{ padding: '8px', borderRadius: '9px' }}
                    placeholder="Search for channel"
                  />
                </div>
                <div
                  className="flex flex-col gap-5"
                  style={{ overflow: 'scroll', height: '65vh', position: 'relative' }}>
                  {channelList?.map((currChannel: any) => {
                    return (
                      <>
                        <div
                          key={currChannel.updatedAt}
                          style={{
                            cursor: 'pointer',
                            backgroundColor: currChannel?.id == channelDetails?.id ? '#EEF2F0' : '',
                            borderRadius: '8px',
                            borderLeft: `4px solid ${currChannel?.color}`,
                            paddingLeft: '3px'
                          }}
                          className="flex justify-start gap-5 items-center"
                          onClick={() => setChannelDetails(currChannel)}>
                          <div>
                            <div
                              className="flex justify-center items-center text-xl font-bold"
                              style={{
                                height: '50px',
                                width: '50px',
                                borderRadius: '50%',
                                border: '5px solid #75cbeb',
                                backgroundColor: '#009eda',
                                color: 'white'
                              }}>
                              LS
                            </div>
                          </div>
                          <div>
                            <div className="font-bold">{currChannel.name}</div>
                            <span>
                              {moment(currChannel.updatedAt).local().format(DEFAULT_DATE_FORMAT)}
                            </span>
                          </div>
                          <div
                            className="flex align-center justify-center"
                            style={{
                              // backgroundColor: 'aqua',
                              position: 'absolute',
                              right: '15%'
                            }}>
                            {currChannel?.priority == 'high' ? (
                              <>
                                <div
                                  style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                                  <div
                                    style={{
                                      height: '3px',
                                      width: '20px',
                                      backgroundColor: '#ffbb2f',
                                      borderRadius: '3px'
                                    }}></div>
                                  <div
                                    style={{
                                      height: '3px',
                                      width: '20px',
                                      backgroundColor: '#ffbb2f',
                                      borderRadius: '3px'
                                    }}></div>
                                  <div
                                    style={{
                                      height: '3px',
                                      width: '20px',
                                      backgroundColor: '#ffbb2f',
                                      borderRadius: '3px'
                                    }}></div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {currChannel?.priority == 'medium' ? (
                              <>
                                <div
                                  style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                                  <div
                                    style={{
                                      height: '3px',
                                      width: '20px',
                                      backgroundColor: '#ffbb2f',
                                      borderRadius: '3px'
                                    }}></div>
                                  <div
                                    style={{
                                      height: '3px',
                                      width: '20px',
                                      backgroundColor: '#ffbb2f',
                                      borderRadius: '3px'
                                    }}></div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {currChannel?.priority == 'low' ? (
                              <>
                                <div
                                  style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                                  <div
                                    style={{
                                      height: '3px',
                                      width: '20px',
                                      backgroundColor: '#ffbb2f',
                                      borderRadius: '3px'
                                    }}></div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <Dropdown overlay={menu} placement="topRight">
                            <MoreOutlined
                              style={{
                                transform: 'scale(1.6)',
                                position: 'absolute',
                                right: '10px'
                              }}
                              onMouseOver={() => setCurrChanneltoEdit(currChannel)}
                            />
                          </Dropdown>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div
                style={{ flex: '3', maxWidth: `${(3 / 4.5) * 100}%` }}
                className="bg-white rounded-lg">
                <div
                  className="flex justify-start gap-5 items-center p-5"
                  style={{ borderBottom: '2px solid #EEF2F0' }}>
                  <div
                    className="flex justify-center items-center text-xl "
                    style={{
                      height: '50px',
                      width: '50px',
                      borderRadius: '50%',
                      border: '5px solid #75cbeb',
                      backgroundColor: '#009eda',
                      color: 'white'
                    }}>
                    LS
                  </div>
                  <div>
                    <div className="font-bold">{channelDetails?.name}</div>
                    <div>Chat Room</div>
                  </div>
                </div>
                {/* <ReusableRoom
                  id={channelDetails.id}
                  channelName={channelDetails.name}
                  fromChatList={true}
                /> */}
                {channelDetails?.id ? (
                  <ReusableRoom
                    id={channelDetails.id}
                    channelName={channelDetails.name}
                    fromChatList={true}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        )}
      </AppContent>
    </div>
  );
};

export default ChannelsList;
