import React, { useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { DeleteOutlined, MinusCircleFilled } from '@ant-design/icons';
import CustomDeleteIcon from '../CustomIcons/CustomDeleteIcon';

interface IProps {
  FunctionAfterOk: () => void;
  fromUser?: boolean;
  fromUserdetails?: boolean;
}

const DeleteConfirmModal = ({ FunctionAfterOk, fromUser, fromUserdetails }: IProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    FunctionAfterOk();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div style={{ marginRight: '6px' }}>
      <Tooltip title="Delete" color="red">
        {fromUser ? (
          <div className="flex justify-between gap-3" onClick={showModal} style={{ width: '109%' }}>
            <MinusCircleFilled
              style={{ transform: 'scale(1.1)', display: 'flex', alignItems: 'center' }}
            />
            <div>User</div>
          </div>
        ) : fromUserdetails ? (
          <Button
            type="default"
            className="ml-0"
            htmlType="button"
            onClick={showModal}
            style={{ borderRadius: '5px', color: 'red', borderColor: 'red' }}>
            Delete
          </Button>
        ) : (
          <CustomDeleteIcon onClick={showModal} />
        )}
        {/* {fromUserdetails ? (
          <Button type="default" className="ml-5" htmlType="button" onClick={showModal}>
            ACL
          </Button>
        ) : (
          <></>
        )} */}
      </Tooltip>
      <Modal
        title="Are you sure about to delete?"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}></Modal>
    </div>
  );
};

export default DeleteConfirmModal;
