function getPascalCase(fieldName: string) {
  if (fieldName === fieldName.toUpperCase()) {
    return fieldName;
  }

  const words = fieldName.split(/(?=[A-Z])|[_ ]+/);

  return words
    .map((word) => {
      if (word.startsWith('(')) {
        return '(' + word.charAt(1).toUpperCase() + word.slice(2).toLowerCase();
      }

      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
}

export default getPascalCase;
