import { useRef, useState, useEffect } from 'react';

interface Props {
  initialColSize: number;
  onResizeEnd?: (colSize: number) => void;
  setIsAnyResizing: React.Dispatch<React.SetStateAction<boolean>>;
}

function useResize({ initialColSize, onResizeEnd, setIsAnyResizing }: Props) {
  const colSizeRef = useRef(initialColSize);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [colSize, setColSize] = useState(initialColSize);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
    setIsResizing(true);
    setIsAnyResizing(true);
    const startX = e.clientX;
    const startWidth = wrapperRef.current?.offsetWidth || 0;

    const handleMouseMove = (e: MouseEvent) => {
      if (!wrapperRef.current) return;
      const newWidth = startWidth + (e.clientX - startX);
      const parentElement = wrapperRef.current.parentElement as HTMLElement;
      const estimatedNewColSize = Math.round((newWidth / parentElement.offsetWidth) * 12);

      const newColSize = Math.min(12, Math.max(1, estimatedNewColSize));
      colSizeRef.current = newColSize;
      setColSize(newColSize);
    };

    const handleMouseUp = () => {
      setIsResizing(false);
      setIsAnyResizing(false);
      onResizeEnd && onResizeEnd(colSizeRef.current);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMouseDown);
    };
  }, []);

  return {
    wrapperRef,
    isResizing,
    colSize,
    handleMouseDown
  };
}

export default useResize;
