import React, { useState } from 'react';
import { Menu, Tooltip, Form, message, TableProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AppContent from '../../../../components/Common/Content';
import DiscussionChatModal from '../../../../components/Common/DiscussionChatModal';

import TableFilter from '../../../../components/FliterTable';
import CustomizeTable from '../../../../components/Common/CustomizeTable/CustomizeTable';
import CustomButton from '../../../../components/Common/CustomButton/CustomButton';
import {
  get_vehicles_list_ids,
  get_vehicles_usage_list_filter
} from '../../../../services/vehicle/queries';
import { SorterResult } from 'antd/lib/table/interface';
import { ConvertObjectToURL } from '../../../../utils/converturl';

import ActionDropdown from '../../../../components/Common/Dropdownactions';
import { ExportColumnType, exportExcel } from '../../../../utils/exportExcel';
import TableCell from '../../../../components/Common/CustomizeTable/CustomCell';
import moment from 'moment';
import { convertUTCStringtoLocalString } from '../../../../utils/convertToUTC';
import { UsersSearch } from '../../../../components/Common/UsersSearch';
import { User } from '../../../../services/users/types';
import UsersDB from '../../../../store/localstorage/UsersDB';
import { get_user_list_ids } from '../../../../services/users/queries';
import { IVehicleData, IVehicleUsage, IVehicleUsageList } from '../../../../services/vehicle/types';
import { VehiclesSearch } from '../../../../components/Common/VehiclesSearch';
import {
  optionalDateSorter,
  optionalNumberSorter,
  optionalStringSorter
} from '../../../../utils/sorter.utils';
import CustomUpdateIcon from '../../../../components/Common/CustomIcons/CustomUpdateIcon';
import { CustomDatePresets } from '../../../sqlsource/report/utils/datePresets';
import { DEFAULT_DATE_FORMAT } from '@/constants';

interface DataType {
  id: number;
  name: string;
}

const VehicleUsageList: React.FC = () => {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(true);
  const [vehicleUseList, setVehicleUseList] = useState<IVehicleUsage[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();

  const [sortedInfo, setSortedInfo] = useState<SorterResult<IVehicleUsage>>({});
  const handleChange: TableProps<IVehicleUsage>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<IVehicleUsage>);
  };

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);

  const breadcrumbItems = [
    {
      label: 'Vehicle Usage',
      link: '/vehicle-usage'
    }
  ];

  const getInfo = async (filter = '') => {
    setIsloading(true);
    const vehicleUsageList = await get_vehicles_usage_list_filter(filter);

    const usersInfo = new Map<number, number[]>();
    const vehicleInfo = new Map<number, number[]>();

    for (let index = 0; index < vehicleUsageList.data.total; index++) {
      const vehicleUsage = vehicleUsageList.data.results[index];
      if (vehicleUsage.userId) {
        const userInfo: User = await UsersDB.getUser(vehicleUsage.userId);
        if (!userInfo) {
          if (usersInfo.has(vehicleUsage.userId)) {
            usersInfo.get(vehicleUsage.userId)?.push(index);
          } else {
            usersInfo.set(vehicleUsage.userId, [index]);
          }
        } else {
          vehicleUsageList.data.results[index].userName = userInfo.name;
        }
      }

      if (vehicleUsage.vehicleId) {
        if (vehicleInfo.has(vehicleUsage.vehicleId)) {
          vehicleInfo.get(vehicleUsage.vehicleId)?.push(index);
        } else {
          vehicleInfo.set(vehicleUsage.vehicleId, [index]);
        }
      }
    }

    const usersInfoLength = usersInfo.size;
    if (usersInfoLength > 0) {
      const usersInfoResult = await get_user_list_ids(Array.from(usersInfo.keys()));
      for (const entry of Array.from(usersInfo.entries())) {
        const key = entry[0];
        const value = entry[1];

        const userInfo = usersInfoResult.data.results.find((user: any) => user.id === key);
        if (userInfo) {
          for (let index = 0; index < value.length; index++) {
            vehicleUsageList.data.results[index].userName = userInfo.name;
          }
          await UsersDB.addUsers([userInfo]);
        }
      }
    }

    const vehicleInfoLength = vehicleInfo.size;
    if (vehicleInfoLength > 0) {
      const vehicleInfoResult = await get_vehicles_list_ids(Array.from(vehicleInfo.keys()));
      for (const entry of Array.from(vehicleInfo.entries())) {
        const key = entry[0];
        const value = entry[1];
        const vehicleInfo = vehicleInfoResult.data.vehicles.find((vehicle) => vehicle.id === key);

        if (vehicleInfo) {
          for (const index of value) {
            vehicleUsageList.data.results[index].vehicleName = vehicleInfo.name;
          }
        }
      }
    }
    setVehicleUseList(vehicleUsageList.data.results);
    setIsloading(false);
  };
  const onSubmitFilter = async (val: string) => {
    const values = form.getFieldsValue();
    values.skip = 0;
    values.count = 50;
    const url = ConvertObjectToURL(values);
    await getInfo(url);
    setPage(1);
    setSize(50);
  };
  const onPagination = async (pageNo = 1, totalSize = 50, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    delete values.dateCustom;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    await getInfo(url);
    setIsloading(false);
  };
  const columns: ColumnsType<IVehicleUsage> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 2,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'User',
      key: 'userName',
      width: 10,
      sorter: (a, b) => optionalStringSorter(a.userName, b.userName),
      sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.userName}</TableCell>;
      }
    },
    {
      title: 'Vehicle',
      key: 'vehicleName',
      width: 10,
      sorter: (a, b) => optionalStringSorter(a.vehicleName, b.vehicleName),
      sortOrder: sortedInfo.columnKey === 'vehicleName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <Link to={`/vehicle/view/${record.vehicleId}`} color="black">
            <TableCell>{record.vehicleName}</TableCell>
          </Link>
        );
      }
    },
    {
      title: 'Start Date',
      key: 'startDate',
      width: 10,
      sorter: (a, b) => optionalDateSorter(a.startDate, b.startDate),
      sortOrder: sortedInfo.columnKey === 'startDate' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            {convertUTCStringtoLocalString(record.startDate, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },
    {
      title: 'Created At',
      key: 'createdAt',
      width: 8,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },
    {
      title: 'Updated At',
      key: 'updatedAt',
      width: 8,
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
      sortOrder: sortedInfo.columnKey === 'updatedAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            {convertUTCStringtoLocalString(record.updatedAt, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },

    {
      title: 'Actions',
      key: 'actions',
      width: 3,
      fixed: 'right',
      render: (record) => {
        const menu = (
          <Menu
            items={[
              {
                key: '1',
                label: <CustomUpdateIcon link={`/vehicle-usage/${record.id}`} />
              },

              {
                key: '4',
                label: <DiscussionChatModal slug="vehicle" rreference="other" id={record.id} />
              }
            ]}
          />
        );
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'ID',
          dataIndex: 'id',
          width: 100
        },
        {
          title: 'User',
          width: 250,
          dataIndex: 'userName'
        },
        {
          title: 'Vehicle',
          width: 200,
          dataIndex: 'vehicleName'
        },
        {
          title: 'Start Date',
          width: 200,
          dataIndex: 'startDate'
        },
        {
          title: 'Created At',
          width: 200,
          dataIndex: 'createdAt'
        },
        {
          title: 'Updated At',
          width: 200,
          dataIndex: 'updatedAt'
        }
      ];
      if (vehicleUseList.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = vehicleUseList.map((item: IVehicleUsage, index: number) => {
        return {
          ...item,
          SN: index + 1
        };
      });
      exportExcel(columns, dataUpdated, 'Vehicle-Usage List');
    } catch (err) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <TableFilter
              form={form}
              defaultValues={{
                dateCustom: [...CustomDatePresets.Today],
                value: '',
                skip: 0,
                count: 100,
                vehicleId: searchParams.get('vehicleId')
                  ? parseInt(searchParams.get('vehicleId')!)
                  : ''
              }}
              initial={true}
              onSubmit={onSubmitFilter}
              styleforbuttons={'flex justify-end items-center'}
              style={
                'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
              }
              buttons={
                <>
                  <CustomButton
                    onClick={() => navigate(`/vehicle-usage/new`)}
                    text="Add"
                    backgroundColor="#1890ff"
                    Linkto="/vehicle-usage/new"
                  />
                  <CustomButton text="Export" backgroundColor="#1890ff" onClick={handleExport} />
                </>
              }>
              <UsersSearch formData={{ formName: 'userId', label: 'User' }} />
              <VehiclesSearch formData={{ formName: 'vehicleId', label: 'Vehicle' }} isAll={true} />
            </TableFilter>
          </>
        }>
        <CustomizeTable
          columns={columns}
          data={vehicleUseList}
          usersLoading={isloading}
          notshowPagination={true}
          // toshowcustomPagintion={true}
          // onPagination={onPagination}
          customScroll={{ y: '75vh', x: 700 }}
          paginationDatas={{
            page: page,
            total: vehicleUseList.length,
            size: size,
            onPagination
          }}
          toSort={handleChange}
          tableName={'vehicle-usage-list'}
        />
      </AppContent>
    </div>
  );
};

export default VehicleUsageList;
