import { useEffect, useRef, useState } from 'react';
import { Button, Divider, Form, message, Select } from 'antd';

import useDebounce from '@/hooks/useDebounce';
import getError from '@/utils/getError';
import { CapitalizeEachWord } from '@/utils/capitalizeEachWord';
import { NamePath } from 'antd/lib/form/interface';

type NestedObject = Record<string, any>;

export type SelectProps = {
  showAll?: boolean;
  count?: number;
  disabled?: boolean;
  className?: string;
  allowClear?: boolean;
  placeHolder?: string;
  notFoundContent?: React.ReactNode;
  defaultActiveFirstOption?: boolean;
  dropdownMatchSelectWidth?: boolean;
  path?: { value: string; name: string };
  isMultiple?: boolean;
  name: NamePath;
} & (
  | { hasParentFormItem: true }
  | { hasParentFormItem: false; label?: string; required?: boolean }
);

interface Callbacks {
  addCallback: (args: any[]) => void;
  dbSearchCallback: (name: any, limit: number) => Promise<any[]>;
  dbSearchById: (id: number | string) => Promise<any>;
  serverCallback: (
    skip?: number,
    count?: number,
    value?: string,
    filter?: string
  ) => Promise<any[]>;
}

type Props = SelectProps &
  Callbacks & {
    formFor:
      | 'agent'
      | 'event'
      | 'user'
      | 'customer'
      | 'product'
      | 'supplier'
      | 'category'
      | 'location'
      | 'route'
      | 'expense category'
      | 'product category'
      | 'unit'
      | 'account';
  } & {
    hideDBNotFoundMessage?: boolean;
    defaultValue?: number | number[];
    onSelect?: (value?: any | any[]) => void;
    setSelected?: (selected?: any | any[]) => void;
  };
/**
 * @param formFor string
 * @param isMultiple boolean
 * @param placeHolder Placeholder text
 * @param showAll Control the show all button
 * @param allowClear Control the clear button
 * @param searchCallback IndexDB search function
 * @param notFoundContent Custom not found message
 * @param dropdownMatchSelectWidth Control the width of the dropdown
 * @param defaultActiveFirstOption Control the default active first option
 * @param count Number of results to return
 * @param path Object with value and name to lookup in object
 * @param defaultValue Either a single value or an array of ids
 * @param onSelect Either a single value or an array of selected ids
 * @param setSelected Either a single value or an array of selected values
 */
function CustomSearch({
  formFor,
  path = { value: 'id', name: 'name' },
  count = 10,
  showAll = false,
  allowClear = true,
  placeHolder,
  notFoundContent = <div>No Content</div>,
  dropdownMatchSelectWidth = false,
  defaultActiveFirstOption = false,
  defaultValue,
  hideDBNotFoundMessage = false,
  className,
  ...props
}: Props) {
  const [skip, setSkip] = useState(0);
  const [isMore, setIsMore] = useState(true);

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const [data, setData] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [isCacheResponse, setIsCacheResponse] = useState(false);

  const formInstance = Form.useFormInstance();
  const formLiveValue = Form.useWatch(props.name, formInstance);

  const isServerSearchInProgress = useRef(false);

  function getInitialIds() {
    const formValue = formLiveValue || [];
    const formValueInArray = Array.isArray(formValue) ? formValue : [formValue];
    return [...new Set(formValueInArray)].filter((d) => d);
  }

  useEffect(() => {
    if (!defaultValue) return;
    formInstance.setFieldValue(props.name, defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    searchInIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue, formLiveValue]);

  function mergeData(originalData: any[], newData: any[]) {
    const mergedData = new Map([...originalData, ...newData].map((item) => [item.id, item]));
    return Array.from(mergedData.values());
  }

  function getSelectedValue(ids: number[], searchedData: any[]) {
    setData((prevData) => {
      const updatedData = mergeData(prevData, searchedData);
      const selected = updatedData.filter((d) => ids.includes(getValueByPath(d, path.value)));

      if (selected.length > 0) {
        setSelectedData(selected);
        props.setSelected?.(props.isMultiple ? selected : selected[0]);
      }

      return updatedData;
    });
  }

  async function getDataFromIds(ids: number[]) {
    try {
      if (ids.length === 0) {
        props?.setSelected?.(undefined);
        props?.onSelect?.(undefined);
        return;
      }

      props?.onSelect?.(props.isMultiple ? ids : ids[0]);

      const absentIdsInData = ids.filter(
        (id) => !data.find((d) => getValueByPath(d, path.value) == id)
      );

      // ALL SELECTED IDS ARE PRESENT IN DATA
      if (absentIdsInData.length === 0) {
        const selected = data.filter((d) => ids.includes(getValueByPath(d, path.value)));
        if (selected.length === 0) return;
        setSelectedData(selected);
        props.setSelected?.(props.isMultiple ? selected : selected?.[0]);
        return;
      }

      const searchedData = [];
      const absentIdsInIndexDB = [] as number[];

      for (const id of absentIdsInData) {
        const dbData = await props.dbSearchById(id);
        dbData ? searchedData.push(dbData) : absentIdsInIndexDB.push(id);
      }

      if (absentIdsInIndexDB.length === 0) {
        getSelectedValue(ids, searchedData);
        return;
      }

      // If default value is selected and is not present in data and indexDB
      const filter = absentIdsInIndexDB.map((id) => `ids[]=${id}`).join('&');
      const results = await props.serverCallback(0, absentIdsInIndexDB.length, '', filter);
      searchedData.push(...results);
      getSelectedValue(ids, searchedData);
      props.addCallback?.(results);
    } catch (error) {
      console.log(error);
    }
  }

  async function searchInIndexDB(value: string) {
    try {
      const indexDBData = await props.dbSearchCallback(value, count);
      if (indexDBData.length == 0 && !isServerSearchInProgress.current) {
        if (!hideDBNotFoundMessage) {
          message.destroy('db-not-found');
          message.error({
            key: 'db-not-found',
            content: `Cannot find any ${formFor} with that value in cache, searching in server...`
          });
        }

        isServerSearchInProgress.current = true;
        await searchInServer(value, 'initial');
        isServerSearchInProgress.current = false;
      }

      if (indexDBData.length > 0) {
        setData(indexDBData);
        setIsCacheResponse(true);
      }

      await getDataFromIds(getInitialIds());
    } catch (error) {
      console.log(error);
    }
  }

  async function searchInServer(value: string, type: 'initial' | 'more') {
    try {
      const currentSkip = type === 'initial' ? 0 : skip;
      const results = await props.serverCallback?.(currentSkip, count, value);
      const isValidResponse = results && results.length > 0;

      if (!isValidResponse) {
        message.error(`Cannot find any ${formFor} with that name!`);
        setIsMore(false);
        type === 'initial' && setData([]);
      }

      if (isValidResponse) {
        const allData = mergeData(results, selectedData);
        type === 'initial' ? setData(allData) : setData((prev: any) => [...prev, ...results]);

        props.addCallback(results);
        setIsMore(results.length >= count);
        type === 'more' && setSkip(currentSkip + count);
      }

      if (type === 'initial') {
        setIsCacheResponse(false);
        setSkip(count);
      }
    } catch (error) {
      const errorMessage = getError(error);
      message.error(errorMessage);
    }
  }

  function getValueByPath<T extends NestedObject, K extends keyof T>(
    obj: T,
    path: string
  ): T[K] | undefined {
    const keys = path.split('.');
    let value = JSON.parse(JSON.stringify(obj));

    for (const key of keys) {
      value = value[key];
      if (value === undefined) {
        throw new Error(`Data not found at path: ${path}`);
      }
    }

    return value;
  }

  const options = data?.map((value: any) => {
    return (
      <Select.Option key={value.id} value={getValueByPath(value, path.value)}>
        <span>{getValueByPath(value, path.name)?.trim()}</span>
        {['customer', 'user', 'supplier'].includes(formFor) && (
          <>
            {', '}
            <span>{value.phone || value.user?.phone}</span>
          </>
        )}

        {['account'].includes(formFor) && (
          <>
            <span> ({value?.type})</span>
          </>
        )}
      </Select.Option>
    );
  });

  const selectMenu = (
    <Select
      showSearch
      allowClear={allowClear}
      className={className}
      filterOption={false}
      optionLabelProp="children"
      disabled={props.disabled}
      mode={props.isMultiple ? 'multiple' : undefined}
      placeholder={placeHolder || `Search ${formFor}...`}
      defaultActiveFirstOption={defaultActiveFirstOption}
      onSearch={(val) => setSearchValue(val)}
      onClear={() => setSearchValue('')}
      notFoundContent={notFoundContent}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            {isCacheResponse ? (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                <Button
                  type="text"
                  style={{ color: 'blue', width: '100%' }}
                  onClick={() => searchInServer(searchValue, 'initial')}>
                  {'Pull More & Sync'}
                </Button>
              </div>
            ) : (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                {isMore ? (
                  <Button
                    type="text"
                    style={{ color: 'blue', width: '100%' }}
                    onClick={() => searchInServer(searchValue, 'more')}>
                    {'Get More...'}
                  </Button>
                ) : (
                  <div style={{ width: '100%', textAlign: 'center' }}>No more data...</div>
                )}
              </div>
            )}
          </>
        );
      }}>
      {showAll && <Select.Option value="">All</Select.Option>}
      {options}
    </Select>
  );

  if (props.hasParentFormItem) return selectMenu;
  return (
    <Form.Item
      label={props.label || CapitalizeEachWord(formFor)}
      name={props.name}
      rules={[{ required: props.required || false, message: `Please select ${formFor}!` }]}>
      {selectMenu}
    </Form.Item>
  );
}

export default CustomSearch;
