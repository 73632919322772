import { INotifications, IVehicleReminderEvent } from '@/services/notifications/types';
import { Badge, Typography, List, Modal } from 'antd';
import { Link } from 'react-router-dom';
const { Text } = Typography;
interface VehicleReminderModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  notification?: INotifications;
}

function VehicleReminderModal({ isOpen, setIsOpen, notification }: VehicleReminderModalProps) {
  if (!notification) return null;

  const event = notification?.event as IVehicleReminderEvent;

  return (
    <Modal
      width={800}
      footer={false}
      maskClosable={false}
      visible={isOpen}
      bodyStyle={{ paddingBlock: 4 }}
      onCancel={() => setIsOpen(false)}
      title="Vehicle Reminders">
      <List
        itemLayout="vertical"
        dataSource={event.notifications}
        renderItem={(item) => (
          <List.Item>
            <div>
              <div className="flex justify-between gap-2 flex-wrap">
                <Text strong style={{ fontSize: 16 }}>
                  {item.vehicle.name}
                </Text>

                <Link target="_blank" to={`/vehicle/view/${item.vehicle.id}`}>
                  View Details
                </Link>
              </div>

              <div className="mt-2">
                <Text type="secondary">
                  Number: {item.vehicle.number} | IMEI: {item.vehicle.imei}
                </Text>
              </div>
              <div style={{ marginTop: '10px' }}>
                {item.messages.map((msg, index) => (
                  <p className="!mb-0" key={index}>
                    <Badge status="warning" /> {msg}
                  </p>
                ))}
              </div>
            </div>
          </List.Item>
        )}
      />
    </Modal>
  );
}

export default VehicleReminderModal;
