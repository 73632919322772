import { message } from 'antd';
import { Quill } from 'react-quill';

// Add fonts to whitelist
const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ['12px', '15px', '18px', '20px', '32px', '54px'];
Quill.register(SizeStyle, true);

// Add Links  to whitelist
Quill.register(
  class PatchedLink extends Quill.import('formats/link') {
    static create(url: string) {
      const starter = '/ERP-UI#';
      if (url.startsWith('/') && !url.includes('http')) {
        return super.create(starter + url);
      }

      message.error('Only internal links are supported are allowed. Please use relative paths');
    }
  },
  true
);

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path className="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9" />
  </svg>
);

const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path className="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5" />
  </svg>
);

export const modules = (undo: () => void, redo: () => void) => ({
  toolbar: {
    container: '#toolbar',
    handlers: { undo, redo }
  },
  history: {
    delay: 500,
    maxStack: 500,
    userOnly: true
  }
});

export const formats = [
  'header',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'color',
  'header1',
  'header2',
  'header3',
  'header4',
  'header5',
  'header6',
  'normal',
  'script'
];

export function QuillToolbar() {
  return (
    <div id="toolbar">
      <span className="ql-formats">
        <select className="ql-size" defaultValue={'15px'} title="Select Font Size">
          <option value="12px">Small</option>
          <option value="15px">Normal</option>
          <option value="18px">Medium</option>
          <option value="20px">Large</option>
          <option value="32px">Huge</option>
          <option value="54px">Giant</option>
        </select>

        <select className="ql-header" defaultValue="7" title="Select Heading Type">
          <option value="1">H1</option>
          <option value="2">H2</option>
          <option value="3">H3</option>
          <option value="4">H4</option>
          <option value="5">H5</option>
          <option value="6">H6</option>
          <option value="7">Normal</option>
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" title="Bold" />
        <button className="ql-italic" title="Italic" />
        <button className="ql-underline" title="Underline" />
        <button className="ql-strike" title="Text Strike" />
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" title="Ordered List" />
        <button className="ql-list" value="bullet" title="Unordered List" />
        <button className="ql-indent" value="-1" title="Left Indent" />
        <button className="ql-indent" value="+1" title="Right Indent" />
      </span>
      <span className="ql-formats">
        <button className="ql-blockquote" title="Block Quote" />
        <select className="ql-align" title="Text Align" />
        <select className="ql-color" title="Color" />
      </span>

      <span className="ql-formats">
        <button className="ql-link" title="Internal Link" />
        <button className="ql-script" value="super" />
        <button className="ql-script" value="sub" />
      </span>

      <span className="ql-formats">
        <button className="ql-undo" title="Undo">
          <CustomUndo />
        </button>
        <button className="ql-redo" title="Redo">
          <CustomRedo />
        </button>
      </span>
    </div>
  );
}
