import { Button, Form, FormInstance, PageHeader, message, Collapse, Spin, Alert } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import Table, { TableProps } from 'antd/lib/table';
import React, { useState } from 'react';
import AppContent from '@/components/Common/Content/Content';
import moment from 'moment';

import { download_report, get_report } from '@/services/report/queries';

import { nepaliNumberFormatter, numberDecimalFormatter } from '@/utils/numberFormatter';
import { convertLocalToUTCString } from '@/utils/convertToUTC';

import { shortNameHiearchy } from '../../../sell/sell-order/view';
import { check_date_cap, check_date_cap_limit, check_export_report_date_cap } from '@/utils/common';
import { excelExportColumns, getUpdatedData, tableExportColumns } from './column.export';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportCurrent from '@/components/Common/ExportCurrent';

interface ReportData {
  data: any;
}

interface State {
  id: number[];
  name: string;
}
interface IBreadcrumbsItems {
  label: string;
  link?: string;
}

interface ReportProps {
  state: State;
  breadcrumbItems: IBreadcrumbsItems[];
  form: FormInstance<any>;
  columnsData: any;
  toSort?: TableProps<any>['onChange'];
}

const CustomReport: React.FC<ReportProps> = ({
  state,
  breadcrumbItems,
  form,
  columnsData,
  toSort,
  children
}) => {
  const [data, setData] = useState<ReportData>({ data: [] });
  const [size] = useState(-1);
  const [isEmpty, setIsEmpty] = useState(false);

  const [footer, setFooterData] = useState({
    totalQty: '0',
    totalPrice: 0
  });
  const [page, setPage] = useState<number>(1);
  const [isloading, setIsloading] = useState(false);

  const onSearch = async ({
    values,
    storePage = false,
    type
  }: {
    values: any;
    storePage?: boolean;
    type?: any;
  }) => {
    setIsloading(true);
    if (!storePage) {
      values.constraints['page'] = 1;
      values.constraints['size'] = size;
      setPage(1);
    }
    const dateDiff = moment(values.constraints.endDate).diff(values.constraints.startDate, 'days');
    if (check_date_cap_limit(values.constraints.startDate, values.constraints.endDate, 2)) {
      message.error(`${dateDiff} days selected.Please select date range no longer than 2 months!`);
      setIsloading(false);
      return;
    }
    if (check_date_cap(values.constraints.startDate, values.constraints.endDate)) {
      await downloadReport();
      setIsloading(false);
      return;
    }
    //remove custom date
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    const response = await get_report(
      {
        ...values.constraints
        // dailyAverageDays: dateDiff
      },
      state.id[0]
    );
    if (response.data.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
    const calculatedData = await calculateTotalDetail(response);
    setData(calculatedData);

    setIsloading(false);
  };

  const calculateTotalDetail = async (data: any) => {
    let totalPrice = 0;
    const totalq: any = {};

    for (const topSell of data.data) {
      totalPrice += parseFloat(topSell.total_price);

      if (!totalq[topSell.unit]) {
        totalq[topSell.unit] = parseFloat(topSell.total_quantity);
      } else {
        totalq[topSell.unit] += parseFloat(topSell.total_quantity);
      }
    }

    setFooterData((prev) => {
      return {
        ...prev,
        totalQty: calculateTotalQuantityString(totalq),
        totalPrice
      };
    });
    return data;
  };

  const downloadReport = async () => {
    if (isEmpty) {
      message.error('Cannot Download Empty Data');
      return;
    }
    setIsloading(true);
    const values = form.getFieldsValue();
    await form.validateFields();
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    try {
      if (check_export_report_date_cap(values.constraints.startDate, values.constraints.endDate)) {
        // const response =
        values.constraints.customReportQueueName = 'Top Sell Products From Customer Report';
        message.info({
          key: 'downloading',
          content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
        });
        await download_report(
          {
            ...values.constraints
          },
          state.id[0]
        );
        setIsloading(false);
        // window.open(response.data, '_blank');
      }
    } catch (err: any) {
      setIsloading(false);
      message.error(err.message);
    }
  };

  const calculateTotalQuantityString = (totalQuantity: any) => {
    let totalQuantityString = '';
    const totalQuantityArray: any[] = [];
    for (const key in totalQuantity) {
      totalQuantityArray.push({
        tqty: totalQuantity[key],
        shortName: key
      });
    }
    if (totalQuantityArray.length == 0) return '0';
    totalQuantityArray.sort((a: any, b: any) => {
      return shortNameHiearchy.indexOf(a.shortName) - shortNameHiearchy.indexOf(b.shortName);
    });
    for (let ind = 0; ind < totalQuantityArray.length; ind++) {
      totalQuantityString +=
        totalQuantityArray[ind].tqty == 0
          ? ''
          : ` ${numberDecimalFormatter(totalQuantityArray[ind].tqty)} ${
              totalQuantityArray[ind].shortName
            }`;
    }
    if (totalQuantityString == '') return '0';
    return totalQuantityString;
  };

  const updatedData = getUpdatedData(data.data);

  return (
    <div>
      <Spin spinning={isloading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <Collapse>
            <CollapsePanel header="Filters" key="1">
              <Form
                form={form}
                onFinish={(values) => onSearch({ values })}
                layout="vertical"
                validateTrigger={'onChange'}
                autoComplete="off"
                disabled={isloading}>
                <PageHeader
                  title={state?.name}
                  style={{
                    padding: '8px 0px'
                  }}
                />
                {React.Children.count(children) > 1 && (
                  <>
                    <PageHeader
                      subTitle="Constraints"
                      style={{
                        padding: '8px 0px'
                      }}
                    />
                  </>
                )}
                <div className="grid grid-cols-4 gap-3 ">{children}</div>
                <div className="flex justify-end mt-5 gap-3">
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
                      Search
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="secondary-button"
                      style={{ borderRadius: '6px' }}
                      onClick={() => downloadReport()}>
                      Download
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </CollapsePanel>
          </Collapse>
          <div className="mt-2">
            <Alert
              message="Information!"
              description="Please note, data is calculated at 00:00 every day. Today's data might not be visible."
              type="info"
              closable
              showIcon
            />
          </div>

          <PageHeader
            subTitle="Table"
            style={{
              padding: '8px 0px'
            }}
          />
          <CustomizeTable
            customScroll={{ x: 1000, y: 500 }}
            notshowPagination
            tableName="top-sell-products-from-customer"
            columns={columnsData[0]}
            toSort={toSort}
            data={data.data}
            tableSummary={
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4} className="text-right mr-2">
                    {footer.totalQty}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} className="text-right mr-2">
                    {nepaliNumberFormatter(footer.totalPrice)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            }
            buttons={
              <ExportCurrent
                data={{ total: data.data.length, results: updatedData }}
                columns={tableExportColumns}
                excelColumns={excelExportColumns}
                title="Top Sell Products From Customer Report"
              />
            }
          />
        </AppContent>
      </Spin>
    </div>
  );
};

export default CustomReport;
