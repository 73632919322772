import { useQuery } from '@tanstack/react-query';
import React, { useState, useEffect } from 'react';
import { LoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import AppContent from '../../../components/Common/Content/Content';
import { get_user_track } from '../../../services/user-tracking/queries';
import { IUserLive } from '../../../services/user-tracking/types';
import { Typography } from 'antd';
import { UsersSearch } from '../../../components/Common/UsersSearch';

const AllUserLive = () => {
  const [latestUserTrack, setLatestUserTrack] = useState<IUserLive>();
  const [zoom, setZoom] = useState(6);
  const [center, setCenter] = useState({
    lat: 28.3949,
    lng: 84.124
  });
  const [selectedUserId, setSelectedUserId] = useState<number>();

  const { refetch } = useQuery(['user-live'], async () => {
    let userData = {} as IUserLive;
    if (selectedUserId) {
      const response = await get_user_track(`userId=${selectedUserId}&count=1`);
      if (response.data.results[0]) {
        userData = response.data.results[0];
      }
    }
    setLatestUserTrack(userData);
    setCenter({
      lat: userData.latitude || 28.3949,
      lng: userData.longitude || 84.124
    });
  });

  useEffect(() => {
    if (selectedUserId) {
      refetch();
    }
    const interval = setInterval(() => refetch(), 30000);
    return () => {
      clearInterval(interval);
    };
  }, [selectedUserId]);

  return (
    <AppContent breadcrumbItems={breadcrumbItems}>
      <div className="grid grid-cols-1 gap-5 mb-5">
        <Typography.Text>Select to view user location:</Typography.Text>
        <UsersSearch
          isAll={false}
          onSelect={(value) => {
            setSelectedUserId(value);
            setZoom(8);
          }}
        />
      </div>
      <LoadScript googleMapsApiKey={'AIzaSyATJbgY-H04JRS-GO4GaKRHPPDHS0p2ENA'}>
        <GoogleMap
          center={center}
          zoom={zoom}
          options={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false
          }}
          mapContainerStyle={containerStyle}>
          {window.google &&
            latestUserTrack &&
            typeof latestUserTrack.latitude == 'number' &&
            typeof latestUserTrack.longitude == 'number' && (
              <Marker
                position={{
                  lat: latestUserTrack.latitude,
                  lng: latestUserTrack.longitude
                }}
                icon={{
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: 10,
                  fillColor: 'green',
                  fillOpacity: 1,
                  strokeColor: 'white',
                  strokeWeight: 2
                }}></Marker>
            )}
        </GoogleMap>
      </LoadScript>
    </AppContent>
  );
};
const containerStyle = {
  height: '80vh'
};
const breadcrumbItems = [
  {
    label: 'All User Live',
    link: '/users/all-live'
  }
];

export default AllUserLive;
