import { Button, Form, Input, PageHeader, Select, Spin, TableProps, message } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import {
  get_product_details,
  get_product_status_list
} from '../../../../services/products/queries';
import { ConvertObjectToURL } from '../../../../utils/converturl';
import AppContent from '../../../../components/Common/Content/Content';
import { checkAccess } from '../../../../routes/acl';
import CustomButton from '../../../../components/Common/CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import TableFilter from '../../../../components/FliterTable';
import CustomizeTable from '../../../../components/Common/CustomizeTable/CustomizeTable';
import TableCell from '../../../../components/Common/CustomizeTable/CustomCell';
import { LocationSearch } from '../../../../components/Common/LocationSearch/LocationSearch';
import { ProductsSearch } from '../../../../components/Common/ProductsSearch';
import { useMutation } from '@tanstack/react-query';
import {
  IProductDetails,
  IProductStatusResponse,
  ISearchProductStatus,
  IUpdateProductStatus
} from '../../../../services/products/types';
import { update_product_status_mutation } from '../../../../services/products/mutations';
import ProductSearch2 from '../../../../components/Common/ProductSearch2/ProductSearch2';
import ProductsDB from '../../../../store/localstorage/ProductsDB';
import ProductSearchForStatus from '../../../../components/Common/ProductSearch2/ProductSearchForStatus';

const ProductsStatusList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [productSearch, setProductSearch] = useState<IProductDetails[]>([]);
  const [productInformation, setProductInformation] = useState<IProductStatusResponse>(Object);

  const breadcrumbItems = [
    {
      label: 'Product Status',
      link: '/products/status'
    }
  ];

  const onSubmitFilter = async (filter: ISearchProductStatus) => {
    setIsLoading(true);
    const val = ConvertObjectToURL(filter);
    const response = await get_product_status_list(val);
    // console.log('Response --->', response);
    if (response) {
      form.setFieldValue(['hidden'], response.hidden);
      setProductInformation(response);
    }
    setIsLoading(false);
  };

  const handleSubmitClick = async () => {
    form
      .validateFields()
      .then(async (values: ISearchProductStatus) => {
        // console.log('Values', values);

        const mutationObj: IUpdateProductStatus = {
          id: productInformation.id,
          hidden: values.hidden
        };
        await updateProductStatusMutation.mutateAsync(mutationObj);
      })
      .catch((e) => console.log(e));
  };

  const updateProductStatusMutation = useMutation(update_product_status_mutation, {
    onSuccess: async (data) => {
      setIsLoading(false);
      message.success('Product Status updated successfully');
      form.resetFields();
      setProductInformation(Object);

      const productDetails = await ProductsDB.getProduct(data.productId);
      if (productDetails) {
        typeof productDetails == 'object'
          ? (productDetails.status = [
              ...productDetails.status.filter((val) => val.id !== data.id),
              data
            ])
          : '';
        // const updatedProduct = await get_product_details(data.productId);
        await ProductsDB.addProducts([productDetails]);
      }
    },
    onError: (e: any) => {
      setIsLoading(false);
      message.error(`${e.response.data.message}`);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          initialValues={{ hidden: false }}
          onFinish={onSubmitFilter}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Product Status"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 mb-5'}>
            <LocationSearch
              formData={{ formLabel: 'Location', formName: 'locationId' }}
              required={true}
              notAll={true}
              onSelect={() => form.submit()}
            />
            <ProductSearchForStatus
              formData={{ formName: 'productId', label: 'Product' }}
              required={true}
              onProductChange={() => form.submit()}
              productSearch={productSearch}
              setProductSearch={setProductSearch}
            />
          </div>
          <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 mb-5'}>
            <Form.Item label={'Hidden'} name={'hidden'}>
              <Select>
                <Select.Option value={true}>Yes</Select.Option>
                <Select.Option value={false}>No</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" onClick={handleSubmitClick}>
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default ProductsStatusList;
