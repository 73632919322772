import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, InputNumber, message } from 'antd';

import { AccountsTypeSearch } from '@/components/Common/AccountsTypeSearch';
import CustomButton from '@/components/Common/CustomButton/CustomButton';

interface JournalLine {
  description: string;
  accountId: string;
  debit: number;
  credit: number;
  locationId: string | undefined;
}

interface FormListFunction {
  add: (defaultValue?: JournalLine, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
}

interface Props {
  form: FormInstance;
  locationId?: number;
  isCustom: boolean;
  setIsCustom: (value: boolean) => void;
  totalAmount: number;
  selectedAccount: number;
}

function JournalContent({ form, locationId, isCustom, totalAmount, selectedAccount }: Props) {
  const [total, setTotal] = useState({ credit: 0, debit: 0 });

  useEffect(() => {
    calculateTotal();
  }, [totalAmount, selectedAccount, isCustom]);

  function calculateTotal() {
    const journalLines = (form.getFieldValue('journalLines') || []) as JournalLine[];
    const total = journalLines.reduce(
      (acc, line) => ({
        debit: acc.debit + line.debit,
        credit: acc.credit + line.credit
      }),
      { debit: 0, credit: 0 }
    );

    setTotal(total);
  }

  async function handleListAdd(add: FormListFunction['add']) {
    const journalLines = form.getFieldValue('journalLines') as JournalLine[];

    const isFilled = journalLines.every((line) => {
      const isNumberInValid = line.credit === undefined || line.debit === undefined;
      return line.accountId && !isNumberInValid;
    });

    // Check if the current line is fully filled
    if (!isFilled) {
      message.error({
        key: 'journalLines',
        content: 'Please fill all the fields in the current line before adding a new line.'
      });
      return;
    }

    const accountIds = journalLines.map((line) => line.accountId);
    const isDuplicate = new Set(accountIds).size !== accountIds.length;

    // Check if the current line has duplicate account
    if (isDuplicate) {
      message.error({
        key: 'journalLines',
        content: 'You cannot add a line with the same account twice!'
      });
      return;
    }

    add({ description: '', accountId: '', debit: 0, credit: 0, locationId: undefined });
  }

  function handleListRemove(remove: FormListFunction['remove'], name: number) {
    const isSingle = form.getFieldValue('journalLines').length === 1;
    if (isSingle) {
      // Remove the message if it exists before showing a new one
      message.destroy('journalLines');
      message.error({
        key: 'journalLines',
        content: 'You cannot delete the only line in the journal!'
      });
      return;
    }

    remove(name);
    calculateTotal();
  }

  return (
    <Form.List name="journalLines">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name }, index) => (
            <div key={key} className="flex gap-2 items-center">
              <span className="font-bold text-sm mb-5">{index + 1}.</span>
              <div
                className="card w-full"
                style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                <div
                  className={'grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-7'}>
                  <AccountsTypeSearch
                    disabled={index === 0 ? !isCustom : false}
                    isAll={false}
                    required
                    locationId={locationId}
                    formData={{ label: 'Account', formName: [name, 'accountId'] }}
                    formMain={form}
                    selectedAccount={index === 0 ? selectedAccount : undefined}
                  />

                  <Form.Item label="Description" name={[name, 'description']}>
                    <Input placeholder="Description" disabled={!isCustom} />
                  </Form.Item>

                  <Form.Item
                    label="DR."
                    name={[name, 'debit']}
                    rules={[{ required: true, message: 'Provide Debit' }]}>
                    <InputNumber
                      controls={false}
                      disabled={!isCustom}
                      onChange={() => {
                        form.setFieldValue(['journalLines', name, 'credit'], 0);
                        calculateTotal();
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="CR."
                    name={[name, 'credit']}
                    rules={[{ required: true, message: 'Provide Credit' }]}>
                    <InputNumber
                      controls={false}
                      disabled={!isCustom}
                      onChange={() => {
                        form.setFieldValue(['journalLines', name, 'debit'], 0);
                        calculateTotal();
                      }}
                    />
                  </Form.Item>
                  {isCustom && (
                    <div className="flex items-center justify-start mt-[1.875rem] h-fit">
                      <CustomButton
                        backgroundColor="white"
                        text="Remove"
                        textColor="green"
                        onClick={() => {
                          handleListRemove(remove, name);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}

          <Form.Item hidden={!isCustom}>
            <Button
              type="dashed"
              onClick={() => handleListAdd(add)}
              block
              style={{ width: '100%' }}>
              <PlusOutlined /> Add Journal Line
            </Button>
          </Form.Item>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
            <Form.Item label="Total Debit">
              <InputNumber controls={false} readOnly={true} value={total.debit} />
            </Form.Item>
            <Form.Item label="Total Credit">
              <InputNumber controls={false} readOnly={true} value={total.credit} />
            </Form.Item>
          </div>
        </>
      )}
    </Form.List>
  );
}

export default JournalContent;
