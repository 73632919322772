import { Button, Divider, Form, FormInstance, Select, message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { IAccountTypeResponseData } from '../../../services/accounts/types';
import {
  get_account_details,
  get_account_list,
  get_account_list_by_location_type,
  get_account_list_by_type
} from '../../../services/accounts/queries';
import AccountsDB from '../../../store/localstorage/AccountsDB';
import { AccountType } from '../../../services/accounts/enums';

interface AccountsTypeSearchProps {
  formData?: {
    formName: string | (string | number)[];
    label: string;
  };
  formMain?: FormInstance<any>;
  isAll?: boolean;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  onSelect?: (value: number) => any;
  accountList?: IAccountTypeResponseData[];
  setAccountList?: React.Dispatch<React.SetStateAction<IAccountTypeResponseData[]>>;
  locationId?: number;
  excludedAccount?: number | null;
  type?: string;
  isMultiple?: boolean;
  isLocationNull?: string;
  selectedAccount?: number;
  setSelectedAccountDetails?: React.Dispatch<
    React.SetStateAction<IAccountTypeResponseData | undefined>
  >;
}

const { Option } = Select;

export const AccountsTypeSearch: React.FC<AccountsTypeSearchProps> = ({
  formData,
  formMain,
  isAll = true,
  required = false,
  disabled = false,
  locationId,
  excludedAccount,
  type,
  isLocationNull,
  hidden,
  selectedAccount,
  isMultiple,
  setSelectedAccountDetails,
  onSelect
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchAccountsTypeIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue, excludedAccount]);
  const [accountsTypeSearch, setAccountsTypeSearch] = useState<IAccountTypeResponseData[]>([]);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);
  const [accountList, setAccountList] = useState<IAccountTypeResponseData[]>([]);

  if (formData?.formName) {
    const selectId = Form.useWatch(formData.formName, formMain);
    const accountId = selectedAccount || selectId;

    useEffect(() => {
      (async () => {
        const account = await getAccountDetailsById();
        setSelectedAccountDetails?.(account);
      })();
    }, [accountId]);

    const getAccountDetailsById = async () => {
      if (accountId) {
        const account = await AccountsDB.getAccount(accountId);
        if (!account) {
          const allAccount = await get_account_details(accountId);
          await AccountsDB.addAccounts([allAccount]);
          setAccountsTypeSearch([...accountsTypeSearch, allAccount]);
          checkAccountsAndAdd([allAccount]);
          if (formMain) {
            formMain.setFieldValue(formData.formName, accountId);
          }

          return await AccountsDB.getAccount(accountId);
        }

        return account;
      }
    };
  }

  useEffect(() => {
    searchAccountsTypeIndexDB('');
  }, [locationId, isLocationNull]);

  const searchAccountsTypeIndexDB = async (value: string) => {
    try {
      let response;
      if (locationId) {
        response = await AccountsDB.searchAccountByNameAndLocation(
          value,
          locationId,
          100,
          isLocationNull
        );
        if (!response || response.length == 0) {
          message.info('Cannot find any account with that name in cache, searching in server...');
          searchAccountsType(value);
        } else {
          if (excludedAccount) {
            setAccountsTypeSearch(response.filter((value) => value.id !== excludedAccount));
          } else {
            setAccountsTypeSearch(response);
          }
          checkAccountsAndAdd(response);
          setIsCacheResponse(true);
        }
      } else if (type) {
        response = await AccountsDB.searchAccountByName(value, 100, isLocationNull);

        if (!response || response.length == 0) {
          message.info('Cannot find any account with that name in cache, searching in server...');
          searchAccountsType(value);
        } else {
          if (excludedAccount) {
            setAccountsTypeSearch(
              response.filter((value) => value.id !== excludedAccount && value.type === type)
            );
          } else {
            setAccountsTypeSearch(response.filter((value) => value.type === type));
          }
          checkAccountsAndAdd(response);

          setIsCacheResponse(true);
        }
      } else {
        response = await AccountsDB.searchAccountByName(value, 100, isLocationNull);
        if (!response || response.length == 0) {
          message.info('Cannot find any account with that name in cache, searching in server...');
          searchAccountsType(value);
        } else {
          if (excludedAccount) {
            setAccountsTypeSearch(response.filter((value) => value.id !== excludedAccount));
          } else {
            setAccountsTypeSearch(response);
          }
          checkAccountsAndAdd(response);

          setIsCacheResponse(true);
        }
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  const searchAccountsType = async (value: string) => {
    let response;
    try {
      if (locationId) {
        if (isLocationNull) {
          response = await get_account_list_by_location_type(0, 10, value, isLocationNull);
        } else {
          response = await get_account_list(0, 10, value, locationId);
        }
      } else if (type) {
        response = await get_account_list_by_type(0, 10, value, type, isLocationNull);
      } else {
        response = await get_account_list_by_location_type(0, 10, value, isLocationNull);
      }
      if (!response || response.results.length == 0) {
        message.error('Cannot find account with that name!');
        setAccountsTypeSearch([]);
        setIsMore(false);
      } else {
        if (excludedAccount) {
          setAccountsTypeSearch(response.results.filter((value) => value.id !== excludedAccount));
          AccountsDB.addAccounts(response.results.filter((value) => value.id !== excludedAccount));
        } else {
          setAccountsTypeSearch(response.results);
          AccountsDB.addAccounts(response.results);
        }

        checkAccountsAndAdd(response.results);
        if (response.results.length < 10) {
          setIsMore(false);
        } else {
          setIsMore(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setIsCacheResponse(false);
    setSkip(10);
  };

  const searchMoreAccountsType = async (value: string) => {
    let response;
    try {
      if (locationId) {
        if (isLocationNull) {
          response = await get_account_list_by_location_type(skip, 10, value, isLocationNull);
        } else {
          response = await get_account_list(skip, 10, value, locationId);
        }
      } else if (type) {
        response = await get_account_list_by_type(skip, 10, value, type, isLocationNull);
      } else {
        response = await get_account_list_by_location_type(skip, 10, value, isLocationNull);
      }
    } catch (e) {
      console.log(e);
    }
    if (!response || response.results.length == 0) {
      message.info('Cannot find more account with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);
      if (excludedAccount) {
        setAccountsTypeSearch([
          ...accountsTypeSearch,
          ...response.results.filter((value) => value.id !== excludedAccount)
        ]);
        AccountsDB.addAccounts(response.results.filter((value) => value.id !== excludedAccount));
      } else {
        setAccountsTypeSearch([...accountsTypeSearch, ...response.results]);
        AccountsDB.addAccounts(response.results);
      }
      checkAccountsAndAdd(response.results);

      if (response.results.length < 10) {
        setIsMore(false);
      }
    }
  };

  const checkAccountsAndAdd = (accounts: IAccountTypeResponseData[]) => {
    if (accountList.length > 0) {
      accounts = accounts.filter((value) => {
        const searchAccount = accountsTypeSearch.find((val) => val.id == value.id);
        if (searchAccount) return false;
        return true;
      });

      if (accounts.length > 0) {
        setAccountList((prevValue) => {
          accounts = accounts.filter((value) => {
            const searchAccount = prevValue.find((val) => val.id == value.id);
            if (searchAccount) return false;
            return true;
          });

          return [...prevValue, ...accounts];
        });
      }
    } else {
      setAccountList(accounts);
    }
  };

  const options = accountsTypeSearch.map((value) => {
    return (
      <Option key={value.id} value={value.id}>
        {value.type === AccountType.OTHER ||
        !Object.values(AccountType).includes(value.type as AccountType)
          ? `${value.name}`
          : `${value.name} (${value.type})`}
      </Option>
    );
  });

  return (
    <>
      {formData ? (
        <Form.Item
          name={formData.formName}
          label={formData.label}
          hidden={hidden}
          rules={[
            {
              required: required,
              message: 'Please add an account!'
            }
          ]}>
          <Select
            showSearch
            allowClear
            disabled={disabled}
            onChange={onSelect}
            placeholder="Select an account type"
            defaultActiveFirstOption={false}
            showArrow={false}
            autoClearSearchValue={true}
            mode={isMultiple ? 'multiple' : undefined}
            filterOption={false}
            dropdownMatchSelectWidth={false}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                {isCacheResponse ? (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchAccountsType(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Pull More & Sync'}
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {isMore ? (
                      <Button
                        type="text"
                        style={{
                          color: 'blue',
                          width: '100%'
                        }}
                        onClick={() => {
                          searchMoreAccountsType(searchValue);
                        }}>
                        {/* {'Sync & Search on Server'} */}
                        {'Get More...'}
                      </Button>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center'
                        }}>
                        No more data...
                      </div>
                    )}
                  </div>
                )}
              </>
            )}>
            {isAll ? <Option value="">All</Option> : null}
            {options}
          </Select>
        </Form.Item>
      ) : (
        <Select
          showSearch
          allowClear
          onChange={onSelect}
          disabled={disabled}
          mode={isMultiple ? 'multiple' : undefined}
          placeholder="Select an account type"
          defaultActiveFirstOption={false}
          showArrow={false}
          autoClearSearchValue={true}
          filterOption={false}
          dropdownMatchSelectWidth={false}
          onSearch={(val) => {
            setSearchValue(val);
          }}
          notFoundContent={null}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              {isCacheResponse ? (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {/* <div
                  style={{
                    color: 'green',
                    width: '100%',
                    textAlign: 'center'
                  }}>{`Cache found ${productSearch.length} data`}</div> */}
                  <Button
                    type="text"
                    style={{
                      color: 'blue',
                      width: '100%'
                    }}
                    onClick={() => {
                      searchAccountsType(searchValue);
                    }}>
                    {/* {'Sync & Search on Server'} */}
                    {'Pull More & Sync'}
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {isMore ? (
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchMoreAccountsType(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Get More...'}
                    </Button>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center'
                      }}>
                      No more data...
                    </div>
                  )}
                </div>
              )}
            </>
          )}>
          {isAll ? <Option value="">All</Option> : null}
          {options}
        </Select>
      )}
    </>
  );
};
