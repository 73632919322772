import { find_date_preference } from '@/store/localstorage/preferences';
import { convert_nepali_to_english_date } from '@/utils/nepaliDateConverter';
import { DatePicker, Form, FormInstance, Input } from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { RangePickerProps } from 'antd/lib/date-picker';
import { accountParentMenu } from '@/constants/account.menu';
import DateRangePicker from './DateRangePicker';
import { CustomDatePresets } from '@/pages/sqlsource/report/utils/datePresets';
import { useLocation } from 'react-router-dom';
import AccountDatePicker from './AccountDatePicker';

interface Props {
  form: FormInstance;
  singleDate?: boolean;
  customDate?: boolean;
  defaultDate?: [moment.Moment, moment.Moment];
  hasHours: boolean;
  stateManageExternal?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

function DateForm({
  form,
  defaultDate,
  singleDate = false,
  customDate = true,
  hasHours,
  stateManageExternal,
  open,
  setOpen
}: Props) {
  const { pathname } = useLocation();
  const nepaliType = find_date_preference();
  const isAccount = accountParentMenu.includes(pathname);

  function onChangeSingle(value: string, type: 'english' | 'nepali') {
    const formValue = type === 'english' ? value : convert_nepali_to_english_date(value);

    form.setFieldValue(['date'], formValue);
  }

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart) form.setFieldValue(['startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['endDate'], convert_nepali_to_english_date(val));
  };

  const onChange: RangePickerProps['onChange'] = (_, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['startDate'], dateStrings[0]);
      form.setFieldValue(['endDate'], dateStrings[1]);
    }
  };

  return (
    <>
      <Form.Item
        label={'Select Date'}
        name={['dateSingleNepali']}
        hidden={!singleDate || !nepaliType}>
        <NepaliDatePicker
          className="w-full"
          onChange={(val) => onChangeSingle(val, 'nepali')}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      {isAccount ? (
        <AccountDatePicker singleDate={singleDate} onChange={onChangeSingle} />
      ) : (
        <Form.Item label={'Select Date'} name={['dateSingle']} hidden={!singleDate || nepaliType}>
          <DatePicker
            onChange={(_, date) => onChangeSingle(date, 'english')}
            format={'YYYY-MM-DD'}
            className="w-full"
            allowClear={false}
          />
        </Form.Item>
      )}

      <Form.Item
        label={'Select Start Date'}
        name={['startDateNepali']}
        hidden={!customDate || !nepaliType}>
        <NepaliDatePicker
          className="w-full"
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item
        label={'Select End Date'}
        name={['endDateNepali']}
        hidden={!customDate || !nepaliType}>
        <NepaliDatePicker
          className="w-full"
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select Date'} name={['dateCustom']} hidden={!customDate || nepaliType}>
        {isAccount ? (
          <DateRangePicker
            value={defaultDate || [null, null]}
            hasHours={hasHours}
            onChange={onChange}
            stateManageExternal={stateManageExternal}
            open={open}
            setOpen={setOpen}
          />
        ) : (
          <DatePicker.RangePicker
            showTime={hasHours}
            className="w-full"
            ranges={CustomDatePresets}
            onChange={onChange}
            format={hasHours ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
            allowClear={false}
            {...(stateManageExternal && setOpen && { open, onOpenChange: (open) => setOpen(open) })}
          />
        )}
      </Form.Item>
      <Form.Item label={'Date'} name={['date']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['endDate']} hidden>
        <Input />
      </Form.Item>
    </>
  );
}

export default DateForm;
