import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { delete_user, get_vendor_list_for_filter } from '../../../../services/users/queries';
import ListUI from '../../common/ListUI';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import VendorsDB from '../../../../store/localstorage/VendorDB';

const VendorsList: React.FC = () => {
  const [isLoading, setIsloading] = useState<boolean>(true);
  const { skip, count, value } = useSelector((state: RootState) => state.lists);

  const deleteUserMutation = useMutation(delete_user, {
    onSuccess: () => {
      message.success('Vendor deleted successfully');
      setIsloading(false);
      getInfo();
    },
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.response.data.message}`, 5);
    }
  });
  const breadcrumbItems = [
    {
      label: 'Suppliers',
      link: '/users/vendors'
    }
  ];

  const getInfo = async (filter = '') => {
    setIsloading(true);
    // const response = await get_vendor_list(skip, count, value, filter);
    const response = await get_vendor_list_for_filter(filter);
    VendorsDB.addVendors(response.data.results);
    const filteredData: any[] = response.data.results.map((val: any) => {
      const returnVal = val.user;
      returnVal.vendorId = val.id;
      return returnVal;
    });
    setIsloading(false);
    return response;
  };
  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
  };
  return (
    <div>
      <ListUI breadcrumbItems={breadcrumbItems} deleteMutation={deleteUserMutation} type="vendor" />
    </div>
  );
};

export default VendorsList;
