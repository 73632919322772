import moment from 'moment';

function getTimeRange(date: string) {
  // Parse the startDate using moment
  const startMoment = moment(date);

  // Extract hours and minutes
  const hours = startMoment.hours();
  const minutes = startMoment.minutes();

  if (hours === 0 && minutes === 0) {
    return;
  }

  // Create the start and end times
  const startTime = moment(date).startOf('day').toISOString();
  const endTime = moment(date).subtract(1, 'second').toISOString();

  return { startTime, endTime };
}

export default getTimeRange;
