import { RootState } from './../../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IListsIntialState {
  skip: number;
  count: number;
  value: string;
}

const INITIAL_STATE: IListsIntialState = {
  skip: 0,
  count: 0,
  value: ''
};

export const listSlice = createSlice({
  name: 'lists',
  initialState: INITIAL_STATE,
  reducers: {
    setSkip: (state, action: PayloadAction<number>) => {
      state.skip = action.payload;
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setValue: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
    setPaginationData: (state, action: PayloadAction<IListsIntialState>) => {
      state.skip = action.payload.skip;
      state.count = action.payload.count;
      state.value = action.payload.value;
    },
    reset: (state) => {
      state.skip = 0;
      state.count = 0;
      state.value = '';
    }
  }
});
export const { setSkip, setCount, setValue, reset, setPaginationData } = listSlice.actions;

export const getCurrentPage = (state: RootState) => state.lists.skip;

export default listSlice.reducer;
