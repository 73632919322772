import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  TimeScale,
  TimeSeriesScale,
  Filler
} from 'chart.js';

import ZoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
  ZoomPlugin,
  Filler
);

export const chartColors = [
  {
    backgroundColor: 'rgba(255, 99, 132, 0.2)',
    borderColor: 'rgba(255, 99, 132, 1)'
  },
  { backgroundColor: 'rgba(54, 162, 235, 0.2)', borderColor: 'rgba(54, 162, 235, 1)' },
  { backgroundColor: 'rgba(255, 206, 86, 0.2)', borderColor: 'rgba(255, 206, 86, 1)' },
  { backgroundColor: 'rgba(75, 192, 192, 0.2)', borderColor: 'rgba(75, 192, 192, 1)' },
  { backgroundColor: 'rgba(153, 102, 255, 0.2)', borderColor: 'rgba(153, 102, 255, 1)' },
  { backgroundColor: 'rgba(20, 200, 120, 0.2)', borderColor: 'rgba(20, 200, 120, 1)' },
  { backgroundColor: 'rgba(255, 159, 64, 0.2)', borderColor: 'rgba(255, 159, 64, 1)' },
  { backgroundColor: 'rgba(170, 50, 220, 0.2)', borderColor: 'rgba(170, 50, 220, 1)' },
  { backgroundColor: 'rgba(40, 150, 10, 0.2)', borderColor: 'rgba(40, 150, 10, 1)' },
  { backgroundColor: 'rgba(10, 80, 190, 0.2)', borderColor: 'rgba(10, 80, 190, 1)' },
  { backgroundColor: 'rgba(200, 100, 50, 0.2)', borderColor: 'rgba(200, 100, 50, 1)' },
  { backgroundColor: 'rgba(90, 30, 170, 0.2)', borderColor: 'rgba(90, 30, 170, 1)' },
  { backgroundColor: 'rgba(30, 170, 90, 0.2)', borderColor: 'rgba(30, 170, 90, 1)' },
  { backgroundColor: 'rgba(160, 120, 20, 0.2)', borderColor: 'rgba(160, 120, 20, 1)' },
  { backgroundColor: 'rgba(130, 10, 240, 0.2)', borderColor: 'rgba(130, 10, 240, 1)' },
  { backgroundColor: 'rgba(240, 210, 60, 0.2)', borderColor: 'rgba(240, 210, 60, 1)' },
  { backgroundColor: 'rgba(50, 220, 170, 0.2)', borderColor: 'rgba(50, 220, 170, 1)' },
  { backgroundColor: 'rgba(220, 20, 130, 0.2)', borderColor: 'rgba(220, 20, 130, 1)' },
  { backgroundColor: 'rgba(200, 60, 190, 0.2)', borderColor: 'rgba(200, 60, 190, 1)' },
  { backgroundColor: 'rgba(20, 120, 220, 0.2)', borderColor: 'rgba(20, 120, 220, 1)' }
];
