import { nepaliNumberFormatter } from '@/utils/numberFormatter';

export const tableExportColumns = [
  {
    label: 'Product',
    dataIndex: 'product',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Location',
    dataIndex: 'location',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Customer',
    dataIndex: 'customer',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Total Quantity',
    dataIndex: 'total_quantity',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Total Price',
    dataIndex: 'total_price',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      total_quantity: `${parseFloat(record.total_quantity)} ${record.unit}`,
      total_price: nepaliNumberFormatter(record.total_price)
    };
  }) as any;
}
