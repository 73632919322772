import { EditFilled, PlusCircleFilled, DownOutlined, LeftOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  message,
  Table,
  Tooltip,
  Dropdown,
  Menu,
  Space,
  Card,
  Form,
  PageHeader,
  Input
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import AppContent from '../../../../../components/Common/Content/Content';
import { get_user_details } from '../../../../../services/users/queries';
import {
  create_users_tax_mutation,
  delete_users_tax_mutation
} from '../../../../../services/users/mutations';
import { IUserUpdateTaxRequest, IDataFromServer } from '../../../../../services/users/types';
import DeleteConfirmModal from '../../../../../components/Common/Deleteconfirm/DeleteConfirmModal';
import DiscussionChatModal from '../../../../../components/Common/DiscussionChatModal';
import { create_tasks_mutation } from '../../../../../services/tasks/mutations';
import GoBackButton from '../../../../../components/Common/GoBackButton/GoBackButton';
import CustomButton from '../../../../../components/Common/CustomButton/CustomButton';
import ActionDropdown from '../../../../../components/Common/Dropdownactions';
import CustomUpdateIcon from '../../../../../components/Common/CustomIcons/CustomUpdateIcon';

interface ITaxValuesType {
  taxNumber: string;
  taxType: string;
  userId: number;
}

const TaxList: React.FC = () => {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(true);
  const { id } = useParams();
  const [taxData, setTaxData] = useState<IUserUpdateTaxRequest[]>([]);
  const [form] = Form.useForm();
  const { refetch } = useQuery(
    ['users', id],
    async () => get_user_details(parseInt(id as string)),
    {
      retry: false,
      onSuccess: (data: IDataFromServer) => {
        setTaxData(data.tax_informations);
        setIsloading(false);
        // setUserDetails(data.user);
      }
    }
  );
  const deleteTaxMutation = useMutation(delete_users_tax_mutation, {
    onError: (e: any) => {
      message.error(`${e.response.data.message}`, 5);
      setIsloading(false);
    },
    onSuccess: (data: any) => {
      if (data) {
        message.success('Address deleted successfully');
        setIsloading(true);
        refetch();
      }
    }
  });

  const createTaxMutation = useMutation(create_users_tax_mutation, {
    onError: (e: any) => {
      message.error(`${e.response.data.message}`, 5);
      setIsloading(false);
    },
    onSuccess: (data: any) => {
      if (data) {
        message.success('Tax added successfully');
        setIsloading(false);
        refetch();
        form.setFieldValue(['taxNumber'], null);
        form.setFieldValue(['taxType'], null);
      }
    }
  });

  const onFinish = (values: ITaxValuesType) => {
    setIsloading(true);
    values.userId = parseInt(id as string);
    createTaxMutation.mutate(values);
  };

  const columns: ColumnsType<IUserUpdateTaxRequest> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 2,
      render: (text, record, index) => {
        return <div className="text-center">{index + 1}</div>;
      }
    },
    {
      title: 'Tax Number',
      render: (record) => (
        <Link to={`/users/${id}/tax/${record.id}`} color="black">
          {record.taxNumber}
        </Link>
      )
    },
    {
      title: 'Tax Type',
      render: (record) => (
        <Link to={`/users/${id}/tax/${record.id}`} color="black">
          {record.taxType}
        </Link>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 5,
      fixed: 'right',
      render: (record) => {
        const confirmFunction = () => {
          setIsloading(true);
          deleteTaxMutation.mutate(record.id);
        };
        const menu = (
          <Menu
            items={[
              {
                key: '1',
                label: <DeleteConfirmModal FunctionAfterOk={confirmFunction} />
              },
              {
                key: '2',
                label: <CustomUpdateIcon link={`/users/${id}/tax/${record.id}`} />
              },
              {
                key: '3',
                label: <DiscussionChatModal slug={`tax_${id}`} id={record.id} />
              }
            ]}
          />
        );
        return (
          // <Dropdown overlay={menu} trigger={['click']}>
          //   <Button>
          //     <Space>
          //       Actions
          //       <DownOutlined />
          //     </Space>
          //   </Button>
          // </Dropdown>
          <ActionDropdown menu={menu} />
        );
      }
    }
  ];
  const breadcrumbItems = [
    {
      label: 'Users',
      link: '/users'
    },
    {
      label: 'Tax',
      link: `/users/${id}/tax`
    }
  ];
  const [responsize, setResponsize] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 650) {
      // setCollapsed(true);
      setResponsize(true);
    } else {
      setResponsize(false);
    }
    function handleResize() {
      if (window.innerWidth <= 650) {
        // setCollapsed(true);
        setResponsize(true);
      } else {
        setResponsize(false);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <div className="flex justify-end">
            <CustomButton
              onClick={() => navigate(`/users/${id}/tax/new`)}
              text="Add"
              Linkto={`/users/${id}/tax/new`}
              backgroundColor="#1890ff"
            />
          </div>
        }>
        {/* <Link to={`/users/${id}/tax/new`}>
          <Button
            htmlType={'button'}
            size={'small'}
            type={'primary'}
            className={'me-2'}
            onClick={() => navigate(`/users/${id}/tax/new`)}>
            <PlusCircleFilled />
            <span> Add</span>
          </Button>
        </Link> */}
        <Table
          columns={columns}
          dataSource={taxData}
          rowKey={'id'}
          loading={isloading}
          size="small"
        />
      </AppContent>
    </div>
  );
};

export default TaxList;
