export const detailedLedgerColumns = [
  'SN',
  'Journal',
  'Account',
  'Debit',
  'Credit',
  'Running Balance',
  'Reference',
  'Ref Number',
  'Location',
  'Trn. Date',
  'Created Date'
];
