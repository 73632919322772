import { USER_INFO } from '@/constants/config';
import { ITableFilter, IUserPreferences } from '@/services/settings/types';
import { getUserData } from '@/utils/auth.utils';
import { setLocalStorage } from '@/utils/storage.utils';
import { tableFilterPrefUpdate } from '@/utils/tableFilterPrefUpdate.utils';
import CustomErrorModal from '../CustomErrorModal';
import { update_preference_user_mutation } from '@/services/users/mutations';
import Table, { ColumnsType } from 'antd/lib/table';
import { ColumnType } from 'antd/es/table';
import { isNumeric } from '@/utils/isNumeric';
import { find_column_filter } from '@/store/localstorage/preferences';
import { cn } from '@/utils';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

type SummaryType<T> = {
  [K in keyof T]?: number;
};

export const updateTableFilterPref = async (updatedFilterData: string[], tableName?: string) => {
  const userData = getUserData();

  if ('preferences' in userData && userData.preferences) {
    const defaultLocalStoragePref: string = userData.preferences.preferences;
    try {
      let newTableFilter: ITableFilter[] = [];
      const localStoragePref: IUserPreferences = JSON.parse(userData.preferences.preferences);
      if (localStoragePref && 'tableFilters' in localStoragePref && localStoragePref.tableFilters) {
        const selectedFilterList = localStoragePref.tableFilters.find(
          (value) => value.tableKey === tableName
        );
        if (selectedFilterList) {
          const updatedTableFilter: ITableFilter[] = localStoragePref.tableFilters.map((value) => {
            if (value.tableKey === tableName) {
              return { tableKey: tableName, filteredKey: updatedFilterData };
            } else {
              return value;
            }
          });

          newTableFilter = updatedTableFilter;
        } else {
          const updatedTableFilter = [
            ...localStoragePref.tableFilters,
            { tableKey: tableName, filteredKey: updatedFilterData }
          ];

          newTableFilter = updatedTableFilter;
        }
      } else {
        newTableFilter = [{ tableKey: tableName, filteredKey: updatedFilterData }];
      }
      const updatedPref = { ...localStoragePref, tableFilters: newTableFilter };
      setLocalStorage(USER_INFO, {
        ...userData,
        preferences: {
          ...userData.preferences,
          preferences: JSON.stringify(updatedPref)
        }
      });
      await tableFilterPrefUpdate(
        async () =>
          await update_preference_user_mutation({
            id: userData.id,
            value: JSON.stringify(updatedPref)
          }),
        tableName
      );
    } catch (error) {
      setLocalStorage(USER_INFO, {
        ...userData,
        preferences: {
          ...userData.preferences,
          preferences: defaultLocalStoragePref
        }
      });
    }
  } else {
    CustomErrorModal({
      title: 'Error',
      message: (
        <div>
          Default Preference has not been set! Please set it in <b>Settings/Preferences</b> first.
        </div>
      )
    });
  }
};

export const generateTableSummary = <T extends object>(
  data: T[],
  columns: ColumnsType<T>,
  excluded?: (keyof T)[]
) => {
  const summary: SummaryType<T> = {};

  const processColumn = (column: ColumnType<T>, item: T) => {
    const dataIndex = column.dataIndex as keyof T;
    const key = column.key?.toString();
    const value = item[dataIndex] as unknown as string;

    if (key && excluded?.includes(key as keyof T)) return;
    if (isNumeric(value)) {
      const prevValue = (summary[dataIndex] || 0) as number;
      summary[dataIndex] = prevValue + parseFloat(value || '0');
    }
  };

  const processColumns = (columns: any[], item: T) => {
    columns.forEach((column) => {
      if (column.children) {
        processColumns(column.children, item);
      } else {
        processColumn(column, item);
      }
    });
  };

  data.forEach((item) => {
    processColumns(columns, item);
  });

  return summary;
};

export function getFilteredColumns(columns: ColumnsType<any>, tableName?: string) {
  const columnFilter = find_column_filter(tableName);
  const defaultColumn = columns.map((value) => value.key?.toString()) as string[];

  return {
    filter: columnFilter,
    default: defaultColumn,
    initial: columnFilter.length > 0 ? columnFilter : defaultColumn
  };
}

export function getSummaryRender<T>(
  columns: any[],
  summary: SummaryType<T>,
  filter: string[],
  className?: string
) {
  return (
    <Table.Summary.Row>
      {columns.map((column, index) => {
        function processReturn(column: any, index: number, reactKey: number | string) {
          const col = column as ColumnType<T>;
          const key = col.dataIndex as keyof T;

          const colKey = col?.key?.toString();
          if (colKey && !filter.includes(colKey) && filter.length > 0) return null;

          const summaryValue = summary[key] as number | undefined;

          return (
            <Table.Summary.Cell
              key={reactKey}
              index={index}
              className={cn('!py-1 !px-2 !text-[13px] text-right !pr-3', className)}>
              {(summaryValue && isNumeric(summaryValue)) || summaryValue === 0
                ? nepaliNumberFormatter(summaryValue)
                : ''}
            </Table.Summary.Cell>
          );
        }

        if (!column.children) {
          return processReturn(column, index, index);
        } else {
          return column.children.map((childColumn: T, childIndex: number) => {
            return processReturn(childColumn, childIndex, `${index}-${childIndex}`);
          });
        }
      })}
    </Table.Summary.Row>
  );
}
