import { getLocationByList } from '@/services';
import { ICustomViewAccount, ICustomViewAccountTableData } from '@/services/accounts/types';

export async function addLocationToJournal(journalLists: ICustomViewAccount[]) {
  const tableData: ICustomViewAccountTableData[] = [];

  for (const journal of journalLists) {
    let locationName = '';
    if (journal.locationId) {
      const locationDetail = await getLocationByList(journal.locationId);
      locationName = locationDetail?.name;
    }

    if (journal.transferToLocationId && journal.transferFromLocationId) {
      const transferToLocation = await getLocationByList(journal.transferToLocationId);
      const transferFromLocation = await getLocationByList(journal.transferFromLocationId);

      locationName = `${transferFromLocation?.name} >>> ${transferToLocation?.name}`;
    }

    tableData.push({
      ...journal,
      runningBalance: 0,
      locationName,
      debit: Number(journal.debit),
      credit: Number(journal.credit)
    });
  }

  return tableData;
}
