import { Button, Divider, PageHeader, Spin } from 'antd';
import { useEffect, useState } from 'react';
import {
  IAdjustmentConfirmation,
  IAdjustmentConfirmationData,
  IAdjustmentLinesConfirm
} from '@/services/adjustments/types';
import { ILocations } from '@/services/locations/types';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list } from '@/services/locations/queries';
import TableCell from '../CustomizeTable/CustomCell';
import { ColumnsType } from 'antd/lib/table';
import CustomizeTable from '../CustomizeTable/CustomizeTable';
import { IUnits } from '@/services/products/types';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { get_units_list } from '@/services/products/queries';
import { get_reasons_details } from '@/services/adjustments/queries';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import DebounceButton from '../DebounceButton';

const ReusableAdjustmentConfirm: React.FC<IAdjustmentConfirmation> = ({
  data,
  disabled = false,
  handleSubmitClick
}) => {
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [locationDetails, setLocationDetails] = useState<ILocations>();
  const [productList, setProductList] = useState<IAdjustmentLinesConfirm[]>([]);
  //console.log('data', data);

  useEffect(() => {
    if (data) {
      getAllData(data);
    }
  }, [data]);

  const getAllData = async (data: IAdjustmentConfirmationData) => {
    setIsloading(true);
    if (data.location) {
      let loc: ILocations = await LocationsDB.getLocation(data.location);
      if (!loc) {
        const allLocations = await get_location_list();
        await LocationsDB.addLocations([...allLocations.data.results]);
        loc = await LocationsDB.getLocation(data.location);
      }
      setLocationDetails(loc);
    }

    for (let ind = 0; ind < data.lines.length; ind++) {
      let findUnit: IUnits | string = await UnitsDB.getUnit(data.lines[ind].unitId);
      if (!findUnit) {
        const allUnits = await get_units_list();
        await UnitsDB.addUnits(allUnits);
        findUnit = await UnitsDB.getUnit(data.lines[ind].unitId);
      }
      if (typeof findUnit === 'object') {
        data.lines[ind].shortName = findUnit.shortName;
      }

      if (data.lines[ind].name) {
        data.lines[ind].reasonName = data.lines[ind].name;
      } else {
        if (data.lines[ind].reasonId) {
          const reason = await get_reasons_details(data.lines[ind].reasonId);
          data.lines[ind].reasonName = reason.data.name;
        }
      }
    }

    setProductList(data.lines);

    setIsloading(false);
  };

  const handleSubmit = async () => {
    try {
      setIsloading(true);
      await handleSubmitClick();
    } finally {
      setIsloading(false);
    }
  };

  const productsColumns: ColumnsType<IAdjustmentLinesConfirm> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 15,
      render: (a, b, c) => <TableCell>{c + 1}</TableCell>
    },
    {
      title: 'ProductName',
      key: 'productName',
      width: 50,
      render: (a, record) => <TableCell>{record.productName}</TableCell>
    },
    {
      title: 'HS Code',
      key: 'hsCode',
      width: 50,
      render: (a, record) => <TableCell>{record?.hsCode || 'N/A'}</TableCell>
    },
    {
      title: 'Quantity',
      key: 'quantity',
      width: 30,
      render: (a, record) => <TableCell>{`${record.quantity} ${record.shortName}`}</TableCell>
    },
    {
      title: 'Rate',
      key: 'unitPrice',
      width: 30,
      render: (a, record) => (
        <TableCell>{parseFloat(record.unitPrice.toString()).toFixed(2)}</TableCell>
      )
    },
    {
      title: 'Discount',
      key: 'discount',
      width: 30,
      render: (a, record) => (
        <TableCell>{parseFloat(record.discount.toString()).toFixed(2)}</TableCell>
      )
    },
    {
      title: 'Misc.',
      key: 'misc',
      width: 30,
      render: (a, record) => <TableCell>{parseFloat(record.misc.toString()).toFixed(2)}</TableCell>
    },
    {
      title: 'Reason',
      key: 'reasonName',
      width: 30,
      render: (a, record) => <TableCell>{record.reasonName}</TableCell>
    },
    {
      title: 'Note',
      key: 'note',
      width: 30,
      render: (a, record) => <TableCell>{record.note}</TableCell>
    },
    {
      title: 'Total',
      key: 'total',
      width: 30,
      render: (a, record) => (
        <TableCell>{record.total && parseFloat(record.total.toString()).toFixed(2)}</TableCell>
      )
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <div className="grid grid-cols-2 gap-5 mb-5">
        <div>
          <PageHeader
            subTitle="Location"
            style={{
              padding: '2px 0px'
            }}
          />
          {data?.location && (
            <div>
              {locationDetails?.name}, {locationDetails?.address}, {locationDetails?.state},{' '}
              {locationDetails?.city}, {locationDetails?.country}
            </div>
          )}
          {data?.location && <div>{locationDetails?.email}</div>}
        </div>
        <div>
          {data?.date && (
            <div className="text-right p-2">
              <span className="font-semibold">Date :</span> {new Date(data.date).toDateString()}
            </div>
          )}
        </div>
      </div>
      <Divider />
      <PageHeader
        subTitle="Products"
        style={{
          padding: '2px 0px'
        }}
      />
      <CustomizeTable columns={productsColumns} notshowPagination={true} data={productList} />
      <Divider />
      {data?.grandtotal ? (
        <div className="font-semibold">Grand Total : {nepaliNumberFormatter(data.grandtotal)}</div>
      ) : (
        ''
      )}
      <div className="flex justify-end mt-5">
        <DebounceButton type="primary" disabled={isLoading || disabled} onClick={handleSubmit}>
          Confirm
        </DebounceButton>
      </div>
    </Spin>
  );
};

export default ReusableAdjustmentConfirm;
