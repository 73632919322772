import { Button, ButtonProps } from 'antd';
import { useState, useEffect } from 'react';

interface Props extends ButtonProps {
  debounceTime?: number;
  onClick: () => void;
}

function DebounceButton({ onClick, debounceTime = 400, children, disabled, ...props }: Props) {
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  // Clear timeout initially if present
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  // Start a timeout
  const handleClick = () => {
    if (timer) clearTimeout(timer);

    const newTimer = setTimeout(() => {
      onClick();
      setTimer(undefined);
    }, debounceTime);
    setTimer(newTimer);
  };

  return (
    <Button disabled={timer !== undefined || disabled} onClick={handleClick} {...props}>
      {children}
    </Button>
  );
}

export default DebounceButton;
