export const ConvertObjectToURL = (values: any) => {
  let url = '';
  for (const key in values) {
    if (values[key] === '' || values[key] === undefined) {
      if (typeof values[key] !== 'number') continue;
    }
    url += `${key}=${values[key]}&`;
  }
  url = url.substring(0, url.length - 1);
  return url;
};
