import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  IVehicleData,
  IVehicleFuel,
  IVehicleFuelList,
  IVehicleList,
  IVehicleLiveData,
  IVehicleMaintenance,
  IVehicleMaintenanceList,
  IVehicleUsage,
  IVehicleUsageList
} from './types';
import exp from 'constants';
import { getDataForIdsByDynamicField } from '@/utils/queries.utils';

export const get_vehicles_list = async (skip = 0, count = 1000) => {
  const response: AxiosResponse<IVehicleList, any> = await http.get('vehicles', { skip, count });
  return response;
};
export const get_vehicles_list_filter = async (filter = '') => {
  const response: AxiosResponse<IVehicleList, any> = await http.get('vehicles/?' + filter);
  return response;
};

export const get_vehicles_list_filter_v2 = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IVehicleList, any> = await http.get('vehicles/?' + filter, {
    skip,
    count,
    value
  });
  return response;
};
export const get_vehicles_list_ids = async (ids: string[] | number[]) => {
  const parsedIds = ids.map(Number);
  const data = await getDataForIdsByDynamicField<IVehicleData>(parsedIds, 'vehicles/?', {
    totalField: 'count',
    resultsField: 'vehicles'
  });

  return {
    data: {
      count: data.total,
      vehicles: data.results
    }
  };

  // const response: AxiosResponse<IVehicleList, any> = await http.get('vehicles/?', {
  //   ids,
  //   count: ids.length
  // });
  // return response;
};
export const get_vehicles_track = async (id = '') => {
  const response: AxiosResponse<IVehicleLiveData, any> = await http.get('vehicles/track/' + id);
  return response;
};
export const get_vehicles_history = async (id = '', filter = '') => {
  const response = await http.get('vehicles/history/' + id + '?' + filter);
  return response;
};
export const get_vehicle_details = async (id: string | number) => {
  const response: AxiosResponse<IVehicleData, any> = await http.get('vehicles/' + id);
  return response;
};

export const get_vehicles_usage_list = async (skip = 0, count = 1000) => {
  const response: AxiosResponse<IVehicleUsage, any> = await http.get('vehicles-usage', {
    skip,
    count
  });
  return response;
};

export const get_vehicles_usage_list_detail = async (id = '') => {
  const response: AxiosResponse<IVehicleUsage, any> = await http.get('vehicles-usage/' + id);
  return response;
};

export const get_vehicles_usage_list_filter = async (filter = '') => {
  const response: AxiosResponse<IVehicleUsageList, any> = await http.get(
    'vehicles-usage/?' + filter
  );
  return response;
};

export const get_vehicles_maintenance_list = async (skip = 0, count = 1000) => {
  const response: AxiosResponse<IVehicleMaintenance, any> = await http.get('vehicles-maintenance', {
    skip,
    count
  });
  return response;
};

export const get_vehicles_maintenance_list_detail = async (id = '') => {
  const response: AxiosResponse<IVehicleMaintenanceList, any> = await http.get(
    'vehicles-maintenance/' + id
  );
  return response;
};

export const get_vehicles_maintenance_list_filter = async (filter = '') => {
  const response: AxiosResponse<IVehicleMaintenanceList, any> = await http.get(
    'vehicles-maintenance/?' + filter
  );
  return response;
};

export const get_vehicles_fuel_list = async (skip = 0, count = 1000) => {
  const response: AxiosResponse<IVehicleFuelList, any> = await http.get('vehicles-fuel', {
    skip,
    count
  });
  return response;
};

export const get_vehicles_fuel_list_detail = async (id = '') => {
  const response: AxiosResponse<IVehicleFuel, any> = await http.get('vehicles-fuel/' + id);
  return response;
};

export const get_vehicles_fuel_list_filter = async (filter = '') => {
  const response: AxiosResponse<IVehicleFuelList, any> = await http.get('vehicles-fuel/?' + filter);
  return response;
};

export const get_vehicle_by_id = async (id: string | number) => {
  const response: AxiosResponse<IVehicleData, any> = await http.get('vehicles/' + id);
  return response.data;
};
