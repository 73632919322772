import React, { useState, useEffect, useContext } from 'react';

import { useMutation } from '@tanstack/react-query';
import { Input, message, Tooltip, Menu, Form, TableProps, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate } from 'react-router-dom';
import AppContent from '@/components/Common/Content';
import ProductsDB from '@/store/localstorage/ProductsDB';
import {
  delete_sell_order,
  get_sell_order_line_details,
  get_sell_order_details,
  get_sell_order_list_v2
} from '@/services/sell/queries';
import UsersDB from '@/store/localstorage/UsersDB';
import {
  get_customer_list_ids,
  get_user_details,
  get_user_list_ids
} from '@/services/users/queries';
import DeleteConfirmModal from '@/components/Common/Deleteconfirm/DeleteConfirmModal';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_details, get_location_list } from '@/services/locations/queries';
import {
  get_product_details,
  get_product_list_ids,
  get_units_list
} from '@/services/products/queries';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { checkAccess } from '@/routes/acl';
import { ConvertObjectToURL } from '@/utils/converturl';
import { get_routes_list } from '@/services/routes/queries';
import { RouteSearch } from '@/components/Common/RouteSearch/RouteSearch';
import CustomersDB from '@/store/localstorage/CustomerDB';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { getUserData } from '@/utils/auth.utils';
import UnitsDB from '@/store/localstorage/UnitsDB';
import ActionDropdown from '@/components/Common/Dropdownactions';
import RoutesDB from '@/store/localstorage/RoutesDB';
import { shortNameHiearchy } from '../view';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { UsersSearch } from '@/components/Common/UsersSearch';
import { ISellOrderResponseData } from '@/services/sell/types';
import {
  find_default_invoice,
  find_locationId_preference,
  find_to_use_NPR
} from '@/store/localstorage/preferences';
import { IProductType } from '@/services/products/types';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import ProductCategorySearch from '@/components/Common/ProductCategorySearch/ProductCategorySearch';
import { get_category_list_ids } from '@/services/category/queries';
import { ICategory } from '@/services/category/types';
import { CustomerSearch } from '../../list/CustomerSearch';
import { SellOrderListContext } from './context';
import { ProductRoutePrintModal } from './ProductRoutePrint';
import { ProductPrintModal } from './ProductPrint';
import { TableExportPrintModal } from './ViewPrintModal';
import { SellOrderInvoicePrintModal } from './SellOrderInvoicePrintModal';
import { get_invoices_details } from '@/services/settings/queries';
import { ICreateInvoiceResponse } from '@/services/settings/types';
import { SelectedSellOrderModal } from './SelectedSellOrderPrintModal';
import CopyButton from '@/components/Common/CopyButton';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import GenericTable from '@/components/Common/CustomizeTable';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import RouteSearchV2 from '@/components/Common/CustomSearch/Routes';
import CustomerSearchByRoute from '@/components/Common/CustomSearch/Customer/CustomerByRoute';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import ProductCategorySearchV2 from '@/components/Common/CustomSearch/ProductCategory';

interface DataType {
  id: number;
  name: string;
  age: number;
  address: string;
  description: string;
}

const SellOrderList: React.FC = () => {
  const { form, isLoading, setIsLoading } = useContext(SellOrderListContext);
  const navigate = useNavigate();
  const [allSellOrderList, setallSellOrderList] = useState<ISellOrderResponseData>({
    total: 0,
    resultsWithCustomer: []
  });
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const routeId = Form.useWatch('routeId', form);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  //to set RouteId according to preference locationId at first
  useEffect(() => {
    if (preferenceLocationId) {
      fetchPreferenceRouteId(preferenceLocationId);
    }
  }, [preferenceLocationId]);

  const fetchPreferenceRouteId = async (preferenceLocationId: number) => {
    let locationDetails: any = await LocationsDB.getLocation(preferenceLocationId);
    if (!locationDetails) {
      locationDetails = await get_location_details(preferenceLocationId);
      await LocationsDB.addLocations([locationDetails]);
    }
    if (locationDetails.routeId) form.setFieldValue(['routeId'], locationDetails.routeId);
    else form.setFieldValue(['routeId'], '');
  };

  const deleteSellMutation = useMutation(delete_sell_order, {
    onSuccess: () => {
      message.success('Sell deleted successfully');
      const values = form.getFieldsValue();
      values.endDate = convertLocalToUTCString(values.endDate);
      values.startDate = convertLocalToUTCString(values.startDate);
      delete values.dateCustom;
      delete values.startDateNepali;
      delete values.endDateNepali;
      onSubmitFilter(ConvertObjectToURL(values));
    }
  });
  const [selectedProducts, setselectedProducts] = useState<any>([]);

  const generatePDF = async (record: any, frombill: boolean, curr: any) => {
    setIsLoading(true);
    const response: any = await get_sell_order_details(parseInt(record.id as string));
    let createdByDetails: any;
    if (record.createdBy) {
      createdByDetails = await UsersDB.getUser(record.createdBy);
      if (!createdByDetails) {
        createdByDetails = (await get_user_details(record.createdBy)).user;
        UsersDB.addUsers([createdByDetails]);
      }
    }
    let vendorDetails: any = await UsersDB.getUser(record.address.userId);
    if (!vendorDetails) {
      vendorDetails = (await get_user_details(record.address.userId)).user;
      await UsersDB.addUsers([vendorDetails]);
    }

    let locationDetails: any = await LocationsDB.getLocation(record.locationId);
    if (!locationDetails) {
      locationDetails = await get_location_details(record.locationId);
      await LocationsDB.addLocations([locationDetails]);
    }
    let routeInformation: any;
    if (record?.address?.routeId) {
      routeInformation = await RoutesDB.getRoute(record.address.routeId);
      if (!routeInformation) {
        const routesList = await get_routes_list();
        RoutesDB.addRoutes([routesList.data.results]);
        routeInformation = await RoutesDB.getRoute(record.address.routeId);
      }
    } else {
      routeInformation = {
        name: ''
      };
    }

    const linesResponse = await get_sell_order_line_details(record.id);
    const totalQuantity: any = {};
    let shortName: any = '';
    const searchProducts: any = {};
    for (let index = 0; index < linesResponse.data.length; index++) {
      const product: any = await ProductsDB.getProduct(linesResponse.data[index].productId);
      if (!product) {
        // product = await get_product_details(linesResponse.lines[index].productId);
        // await ProductsDB.addProducts([product]);
        if (linesResponse.data[index].productId in searchProducts) {
          searchProducts[linesResponse.data[index].productId] = [
            ...searchProducts[linesResponse.data[index].productId],
            index
          ];
        } else {
          searchProducts[linesResponse.data[index].productId] = [index];
        }
      } else {
        linesResponse.data[index].productName = product.name;
      }
      let findUnit: any = await UnitsDB.getUnit(linesResponse.data[index].unitId);
      if (!findUnit) {
        const allUnits = await get_units_list();
        await UnitsDB.addUnits(allUnits);
        findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
      }
      // linesResponse.lines[index].unitName = findUnit.name;
      // totalQuantity += linesResponse.data[index].quantity;
      if (!totalQuantity[findUnit.shortName]) {
        totalQuantity[findUnit.shortName] = linesResponse.data[index].quantity;
      } else {
        totalQuantity[findUnit.shortName] += linesResponse.data[index].quantity;
      }
      linesResponse.data[
        index
      ].quantityWithUnit = `${linesResponse.data[index].quantity} ${findUnit.shortName}`;
      shortName = findUnit.shortName;
    }
    const searchProductslength = Object.entries(searchProducts).length;
    if (searchProductslength > 0) {
      const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
      for (const key in searchProducts) {
        const findproduct = productsresponse?.data?.results.find(
          (currProduct: IProductType) => currProduct.id == key
        );
        for (let i = 0; i < searchProducts[key].length; i++) {
          linesResponse.data[searchProducts[key][i]].productName = findproduct?.name;
        }
        await ProductsDB.addProducts([findproduct]);
      }
    }
    let totalQuantityString = '';
    const totalQuantityArray: any[] = [];
    for (const key in totalQuantity) {
      totalQuantityArray.push({
        tqty: totalQuantity[key],
        shortName: key
      });
    }
    totalQuantityArray.sort((a: any, b: any) => {
      return shortNameHiearchy.indexOf(a.shortName) - shortNameHiearchy.indexOf(b.shortName);
    });
    for (let ind = 0; ind < totalQuantityArray.length; ind++) {
      totalQuantityString += ` ${totalQuantityArray[ind].tqty} ${totalQuantityArray[ind].shortName}`;
    }
    const printLocation = await find_locationId_preference();
    let printLocationDetails: any;
    if (typeof printLocation === 'number') {
      printLocationDetails = await LocationsDB.getLocation(printLocation);
      if (!printLocationDetails) {
        printLocationDetails = await get_location_details(printLocation);
        await LocationsDB.addLocations([printLocationDetails]);
      }
    }

    localStorage.setItem(
      'referrer',
      JSON.stringify({
        purchaseDetails: { ...response.data, totalQuantity: totalQuantityString },
        locationDetails,
        customerDetails: vendorDetails,
        lines: linesResponse.data,
        invoiceLayouts: curr,
        createdByDetails,
        routeInformation,
        printLocation: printLocationDetails
      })
    );
    setIsLoading(false);

    if (frombill) {
      window.open('/ERP-UI#/billprint', '_blank', 'noopener,noreferrer');
    } else {
      window.open('/ERP-UI#/custom-invoice-sellandsellorder', '_blank', 'noopener,noreferrer');
    }
  };
  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 30,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'ID',
      key: 'id',
      width: 30,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.id}</TableCell>;
      }
    },
    {
      title: 'Customer',
      key: 'userName',
      width: 90,
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/users/${record.address.userId}`} color="black">
          <TableCell>{record.userName}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Financial Reference',
      key: 'financialReference',
      className: 'invoice',
      width: 90,
      sorter: (a, b) => a.financialReference.localeCompare(b.financialReference),
      sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="flex flex-row justify-between items-center text-xs px-1">
            <Link to={`/sell/order/view/${record.id}`} color="black">
              <TableCell>{record.financialReference}</TableCell>
            </Link>
            {record.financialReference ? <CopyButton text={record.financialReference} /> : null}
          </TableCell>
        );
      }
    },
    {
      title: 'Total',
      key: 'totalAmount',
      className: 'highlight',
      dataIndex: 'totalAmount',
      width: 35,
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <Link to={`/sell/order/view/${record.id}`} color="black">
            <div className="text-right mr-2 text-xs">
              {nepaliNumberFormatter(record.totalAmount)}
            </div>
          </Link>
        );
      }
    },
    {
      title: 'Date',
      key: 'date',
      width: 50,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="text-center text-xs">
            {/* {record.date.slice(0, 10)} */}
            {/* {moment(record.date).local().format(DEFAULT_DATE_FORMAT)} */}
            {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },
    {
      title: 'Category',
      key: 'categoryName',
      width: 50,
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
      sortOrder: sortedInfo.columnKey === 'categoryName' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.categoryName}</TableCell>;
      }
    },
    {
      title: 'Route',
      key: 'routeName',
      width: 90,
      sorter: (a, b) => a.routeName.localeCompare(b.routeName),
      sortOrder: sortedInfo.columnKey === 'routeName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <TableCell>{record.routeName}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Created By',
      key: 'createdBy',
      width: 40,
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === 'createdBy' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/users/${record.address.userId}`} color="black">
          <TableCell>{record.createdByName}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Location',
      key: 'locationName',
      width: 50,
      sorter: (a, b) => a.locationName.localeCompare(b.locationName),
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <TableCell>{record.locationName}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Note',
      key: 'note',
      width: 50,
      sorter: (a, b) => a.note.localeCompare(b.note),
      sortOrder: sortedInfo.columnKey === 'note' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <TableCell>
            <Tooltip title={record.note} color="blue">
              <div className="cursor-pointer">
                {record.note ? `${record.note}`.slice(0, 10) + '...' : ''}
              </div>
            </Tooltip>
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 25,
      render: (record) => {
        const confirmFunction = () => {
          deleteSellMutation.mutate(record.id);
        };
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];

        if (checkAccess('UPDATE_SELL_ORDER')) {
          menuItems.push({
            key: '2',
            label: <CustomUpdateIcon link={`/sell/order/${record.id}`} />
          });
        }
        if (checkAccess('DELETE_SELL_ORDER')) {
          menuItems.push({
            key: '1',
            label: <DeleteConfirmModal FunctionAfterOk={confirmFunction} />
          });
        }
        menuItems.push({
          key: 'billandinvoice',
          label: <SellOrderInvoicePrintModal key={record.id} record={record} getInfo={getInfo} />
        });
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '4',
            label: (
              <DiscussionChatModal
                slug="sell_order"
                id={record.id}
                fromSellorPurahcaseid={record.customer.userId}
              />
            )
          });
        }
        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Sell Order',
      link: '/sell/order'
    }
  ];
  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };
  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const urlParams = new URLSearchParams(filter);
    const customerIds = urlParams.get('customerId');
    urlParams.delete('customerId');

    if (customerIds) {
      const customerList = customerIds.split(',').map(Number);
      const updatedParams = customerList.map((id) => `customerIds[]=${id}`).join('&');

      const updatedURL = `${urlParams.toString()}&${updatedParams}`;
      filter = updatedURL;
    } else {
      filter = urlParams.toString();
    }
    const response = await get_sell_order_list_v2(filter);
    let allCategory: ICategory[] = [];
    if (response?.data.resultsWithCustomer.length > 0) {
      const categoryList = await get_category_list_ids([
        ...new Set(
          response.data.resultsWithCustomer
            .filter((value) => value.categoryId !== null)
            .map((value) => {
              return value.categoryId;
            })
        )
      ]);

      allCategory = categoryList.results;
    }
    if (response?.data.resultsWithCustomer) {
      const searchUsers: any = {};
      const createdByUsers: any = {};
      let ctotalamount = 0;
      for (let index = 0; index < response.data.resultsWithCustomer.length; index++) {
        const user: any = await CustomersDB.getCustomer(
          response.data.resultsWithCustomer[index].customerId
        );
        if (!user) {
          if (response.data.resultsWithCustomer[index].customerId in searchUsers) {
            searchUsers[response.data.resultsWithCustomer[index].customerId] = [
              ...searchUsers[response.data.resultsWithCustomer[index].customerId],
              index
            ];
          } else {
            searchUsers[response.data.resultsWithCustomer[index].customerId] = [index];
          }
        } else {
          response.data.resultsWithCustomer[index].userName = user.user.name;
          response.data.resultsWithCustomer[index].userData = user;
        }

        if (response.data.resultsWithCustomer[index].categoryId) {
          const selectedCategory = allCategory.find(
            (val) => val.id === response.data.resultsWithCustomer[index].categoryId
          );
          response.data.resultsWithCustomer[index].categoryName = selectedCategory?.name;
        }

        if (response?.data?.resultsWithCustomer[index]?.createdBy) {
          const createdByuser: any = await UsersDB.getUser(
            response.data.resultsWithCustomer[index].createdBy
          );
          if (!createdByuser) {
            if (response.data.resultsWithCustomer[index].createdBy in createdByUsers) {
              createdByUsers[response.data.resultsWithCustomer[index].createdBy] = [
                ...createdByUsers[response.data.resultsWithCustomer[index].createdBy],
                index
              ];
            } else {
              createdByUsers[response.data.resultsWithCustomer[index].createdBy] = [index];
            }
          } else {
            response.data.resultsWithCustomer[index].createdByName = createdByuser.name;
          }
        }
        let locD: any = await LocationsDB.getLocation(
          response.data.resultsWithCustomer[index].locationId
        );
        if (!locD) {
          const allLocations = await get_location_list();
          await LocationsDB.addLocations([...allLocations.data.results]);
          locD = await LocationsDB.getLocation(response.data.resultsWithCustomer[index].locationId);
        }
        if (response.data.resultsWithCustomer[index].address.routeId) {
          let routeD: any = await RoutesDB.getRoute(
            response.data.resultsWithCustomer[index].address.routeId
          );
          if (!routeD) {
            const routesList = await get_routes_list(0, 100);
            await RoutesDB.addRoutes(routesList.data.results);
            routeD = await RoutesDB.getRoute(
              response.data.resultsWithCustomer[index].address.routeId
            );
          }
          response.data.resultsWithCustomer[index].routeName = routeD?.name;
        } else {
          response.data.resultsWithCustomer[index].routeName = 'N/A';
        }
        response.data.resultsWithCustomer[index].locationName = locD.name;
        response.data.resultsWithCustomer[index].key = response.data.resultsWithCustomer[index].id;
        ctotalamount += response.data.resultsWithCustomer[index].totalAmount;
        response.data.resultsWithCustomer[index].date = convertUTCStringtoLocalString(
          response.data.resultsWithCustomer[index].createdAt,
          'YYYY-MM-DD'
        );
        // response.data.resultsWithCustomer[index].totalAmount = Number(
        //   numberDecimalFormatter(response.data.resultsWithCustomer[index].totalAmount)
        // );
        // console.log(response);
      }
      setTotalAmount(ctotalamount);
      const searchUserslength = Object.entries(searchUsers).length;
      if (searchUserslength > 0) {
        const customerresponse = await get_customer_list_ids([...Object.keys(searchUsers)]);
        // console.log('Customer response-->', customerresponse);
        for (const key in searchUsers) {
          const customerUser = customerresponse?.data?.results.find(
            (currCustomer: any) => currCustomer.id == key
          );
          for (let i = 0; i < searchUsers[key].length; i++) {
            response.data.resultsWithCustomer[searchUsers[key][i]].userName =
              customerUser?.user.name;
            response.data.resultsWithCustomer[searchUsers[key][i]].userData = customerUser;
          }
          await CustomersDB.addCustomers([customerUser]);
        }
      }

      const createdByUserslength = Object.entries(createdByUsers).length;
      // console.log('createdbyuserslength-->', createdByUsers);
      if (createdByUserslength > 0) {
        const customerresponse = await get_user_list_ids([...Object.keys(createdByUsers)]);
        // console.log('Customer response-->', customerresponse);
        for (const key in createdByUsers) {
          // console.log('keys->', key);
          const createdByUser = customerresponse?.data?.results.find(
            (currCustomer: any) => currCustomer.id == key
          );
          // console.log('createdByUser-->', createdByUser);
          if (!createdByUser) continue;
          for (let i = 0; i < createdByUsers[key].length; i++) {
            response.data.resultsWithCustomer[createdByUsers[key][i]].createdByName =
              createdByUser.name;
          }
          await UsersDB.addUsers([createdByUser]);
        }
      }
      // setSellList(response.data.resultsWithCustomers);
    }
    // console.log('resonse->', response.data);
    setIsLoading(false);
    setallSellOrderList(response.data);

    return response;
  };

  const onPagination = async (pageNo = 1, totalSize = 10, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setselectedProducts(selectedRows);
    }
  };

  const onFinishSelectedSellOrder = async () => {
    setIsLoading(true);
    const allInformation: any = [];
    for (let index = 0; index < selectedProducts.length; index++) {
      const createdByDetails = {
        name: selectedProducts[index]?.createdByName ? selectedProducts[index]?.createdByName : ''
      };
      let vendorDetails: any = await UsersDB.getUser(selectedProducts[index].address.userId);
      if (!vendorDetails) {
        vendorDetails = (await get_user_details(selectedProducts[index].address.userId)).user;
        await UsersDB.addUsers([vendorDetails]);
      }
      let routeInformation: any;
      if (selectedProducts[index]?.address?.routeId) {
        routeInformation = await RoutesDB.getRoute(selectedProducts[index].address.routeId);
        if (!routeInformation) {
          const routesList = await get_routes_list();
          RoutesDB.addRoutes([routesList.data.results]);
          routeInformation = await RoutesDB.getRoute(selectedProducts[index].address.routeId);
        }
      } else {
        routeInformation = {};
      }
      let locationDetails: any = await LocationsDB.getLocation(selectedProducts[index].locationId);
      if (!locationDetails) {
        locationDetails = await get_location_details(selectedProducts[index].locationId);
        await LocationsDB.addLocations([locationDetails]);
      }

      const linesResponse = await get_sell_order_line_details(selectedProducts[index].id);
      const { createdAt, totalAmount, totalTax } = selectedProducts[index];
      const lines = linesResponse.data;
      const totalQuantity: any = {};
      for (let index = 0; index < lines.length; index++) {
        let product: any = await ProductsDB.getProduct(lines[index].productId);
        if (!product) {
          product = await get_product_details(lines[index].productId);
          await ProductsDB.addProducts([product]);
        }
        let findUnit: any = await UnitsDB.getUnit(lines[index].unitId);
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(lines[index].unitId);
        }
        if (!totalQuantity[findUnit.shortName]) {
          totalQuantity[findUnit.shortName] = linesResponse.data[index].quantity;
        } else {
          totalQuantity[findUnit.shortName] += linesResponse.data[index].quantity;
        }
        lines[index].unitName = findUnit.shortName;
        lines[index].productName = product.name;
      }
      let totalQuantityString = '';
      const totalQuantityArray: any[] = [];
      for (const key in totalQuantity) {
        totalQuantityArray.push({
          tqty: totalQuantity[key],
          shortName: key
        });
      }
      totalQuantityArray.sort((a: any, b: any) => {
        return shortNameHiearchy.indexOf(a.shortName) - shortNameHiearchy.indexOf(b.shortName);
      });
      for (let ind = 0; ind < totalQuantityArray.length; ind++) {
        totalQuantityString += ` ${totalQuantityArray[ind].tqty} ${totalQuantityArray[ind].shortName}`;
      }
      const defaultInvoice = find_default_invoice();
      let curr = {} as ICreateInvoiceResponse;
      if (defaultInvoice) {
        curr = (await get_invoices_details(defaultInvoice.toString())).data;
      }

      allInformation.push({
        purchaseDetails: { ...selectedProducts[index], totalQuantity: totalQuantityString },
        locationDetails,
        customerDetails: vendorDetails,
        routeInformation,
        lines: lines,
        invoiceLayouts: curr,
        createdByDetails
      });
    }
    localStorage.setItem('multipleSellorders', JSON.stringify(allInformation));
    window.open('/ERP-UI#/multiple-invoice', '_blank', 'noopener,noreferrer');
    setIsLoading(false);
  };

  const buttons = (
    <>
      {checkAccess('CREATE_SELL_ORDER') && (
        <div>
          <CustomButton
            onClick={() => navigate('/sell/order/new')}
            text="Add"
            backgroundColor="#1890ff"
            Linkto="/sell/order/new"
          />
        </div>
      )}
      <ProductRoutePrintModal getInfo={getInfo} />
      <ProductPrintModal getInfo={getInfo} />

      {selectedProducts.length > 0 && (
        <SelectedSellOrderModal selectedProducts={selectedProducts} getInfo={getInfo} />
      )}
    </>
  );

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        internalElement={<div className="flex sm:hidden flex-wrap justify-end">{buttons}</div>}
        button={
          <>
            <TableFilter
              defaultValues={{
                dateCustom: [moment(0, 'HH').subtract(1, 'days'), moment(0, 'HH').add(1, 'days')],
                value: '',
                locationId: preferenceLocationId ? preferenceLocationId : 1,
                skip: 0,
                count: 100,
                customerId: [],
                createdBy: '',
                routeId: ''
              }}
              form={form}
              initial={true}
              onSubmit={onSubmitFilter}
              style="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-2"
              styleforbuttons={'flex justify-end items-center'}
              buttons={<div className="hidden sm:flex">{buttons}</div>}>
              <LocationSearchV2 hasParentFormItem={false} name="locationId" showAll />
              <RouteSearchV2
                hasParentFormItem={false}
                name="routeId"
                showAll
                onSelect={() => {
                  form.setFieldValue(['customerId'], []);
                }}
              />
              <Form.Item name="value" label="Search">
                <Input placeholder="Search" />
              </Form.Item>
              <CustomerSearchByRoute
                hasParentFormItem={false}
                name="customerId"
                showAll
                isMultiple
                routeId={routeId}
              />
              <UserSearchV2 hasParentFormItem={false} name="user" label="Created By" showAll />
              <ProductCategorySearchV2
                hasParentFormItem={false}
                name="categoryId"
                label="Product Category"
                showAll
              />
            </TableFilter>
          </>
        }>
        <GenericTable
          columns={columns}
          data={allSellOrderList.resultsWithCustomer}
          buttons={<TableExportPrintModal allSellOrderList={allSellOrderList} />}
          tableName={'sell-order-list'}
          hideDefaultPagination
          rowSelection={rowSelection}
          rowSelectionWidthInPx={10}
          generateSummary
          excludeSummaryByKeys={['id']}
          scroll={{ x: 1600, y: '80vh' }}
          pagination={{
            page: page,
            total: allSellOrderList.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default SellOrderList;
