import { Button, Collapse, Form, FormInstance, message, PageHeader, Pagination, Spin } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import Table from 'antd/lib/table';
import React, { useState } from 'react';
import AppContent from '@/components/Common/Content/Content';
import { download_report, get_report } from '@/services/report/queries';
import CustomersDB from '@/store/localstorage/CustomerDB';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { getInt } from '../pending-payment-sell-report/report';
import { check_date_cap, check_export_report_date_cap } from '@/utils/common';
import { showReportConditionalDownloadMessage } from '@/utils/reportConditionalDownloadMessage';
import { get_location_list } from '@/services/locations/queries';
import { get_customer_list_ids } from '@/services/users/queries';
import { excelExportColumns, getUpdatedData, tableExportColumns } from './column.export';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportAllData from '@/components/Common/ExportAll';
import ExportCurrent from '@/components/Common/ExportCurrent';

interface ReportData {
  total: any;
  data: any;
}
interface FooterData {
  totalSell: number;
  totalPurchase: number;
}
interface State {
  name: string;
}
interface ReportProps {
  state: State;
  breadcrumbItems: any[];
  form: FormInstance<any>;
  columnsData: any[];
  sortHandle: any;

  footerData?: { sell?: boolean; purchase?: boolean };
}

const CustomReport: React.FC<ReportProps> = ({
  state,
  breadcrumbItems,
  form,
  columnsData,
  footerData = {
    sell: false,
    purchase: false
  },
  sortHandle,

  children
}) => {
  const [data, setData] = useState<ReportData>({ total: 0, data: [] });
  const [footer, setFooterData] = useState({
    totalDue: 0,
    totalAmount: 0,
    totalPaid: 0,
    totalReturned: 0,
    totalRemaining: 0
  });
  const [page, setPage] = useState<number>(1);
  const [isloading, setIsloading] = useState(false);
  const [size, setSize] = useState(100);
  const [isEmpty, setIsEmpty] = useState(false);
  const [fullExportURL, setFullExportURL] = useState<any>(null);

  const onSearch = async (values: any, storePage = false) => {
    setIsloading(true);
    if (check_date_cap(values.constraints.startDate, values.constraints.endDate)) {
      await downloadReport();
      setIsloading(false);
      return;
    }
    if (!storePage) {
      values.constraints['page'] = 1;
      values.constraints['size'] = size;
      setPage(1);
    }
    //remove custom date
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    values.constraints.createdBy = values.constraints.by;
    delete values.constraints.by;
    // let response = await get_sell_list_v2(
    //   -values.constraints['size'] + values.constraints.page * values.constraints['size'],
    //   values.constraints['size'],
    //   '',
    //   `location_id=${values.constraints.location_id}&userId=${values.constraints.userId}&from=${values.constraints.startDate}&to=${values.constraints.endDate}`
    // );
    let response = await get_report(values.constraints, 29);
    // console.log('Response-->', response);
    // response.data = await calculateTotal(response);
    if (response?.data.data) {
      response = await calculateTotal(response);
    }
    // console.log('Response..data-->', response.data);
    setData(response.data);

    if (response.data.data.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }

    const payload = values.constraints as any;
    payload.size = response.data.count;
    setFullExportURL(payload);

    // await getFooterData(values);
    setIsloading(false);
  };
  const calculateTotal = async (response: any) => {
    let totalDue = 0;
    let totalAmount = 0;
    let totalPaid = 0;
    let totalReturned = 0;
    let totalRemaining = 0;
    const searchUsers: any = {};

    for (let index = 0; index < response.data.data.length; index++) {
      const user: any = await CustomersDB.getCustomer(response.data.data[index].customer_id);
      if (!user) {
        if (response.data.data[index].customer_id in searchUsers) {
          searchUsers[response.data.data[index].customer_id] = [
            ...searchUsers[response.data.data[index].customer_id],
            index
          ];
        } else {
          searchUsers[response.data.data[index].customer_id] = [index];
        }
      } else {
        response.data.data[index].userName = user.user.name;
        response.data.data[index].userData = user;
      }
      let locD: any = await LocationsDB.getLocation(response.data.data[index].location_id);
      if (!locD) {
        // locD = await get_location_details(response.data.data[index].location_id);
        const allLocations = await get_location_list();
        // console.log('allLocations-->', allLocations);
        await LocationsDB.addLocations([...allLocations.data.results]);
        // await LocationsDB.addLocations([locD]);
        locD = await LocationsDB.getLocation(response.data.data[index].location_id);
      }
      response.data.data[index].locationName = locD.name;
      if (
        response.data.data[index].paymentStatus == 'partial' ||
        response.data.data[index].paymentStatus == 'pending'
      )
        totalDue += 1;
      totalAmount += getInt(response.data.data[index].total_amount);
      totalPaid += getInt(response.data.data[index].amount_paid);
      totalReturned += getInt(response.data.data[index].amount_returned);
      totalRemaining +=
        getInt(response.data.data[index].total_amount) -
        getInt(response.data.data[index].amount_paid);
    }
    const searchUserlength = Object.entries(searchUsers).length;
    if (searchUserlength > 0) {
      const customerresponse = await get_customer_list_ids([...Object.keys(searchUsers)]);
      for (const key in searchUsers) {
        const customerUser = customerresponse?.data?.results.find(
          (currCustomer: any) => currCustomer.id == key
        );
        for (let i = 0; i < searchUsers[key].length; i++) {
          response.data.data[searchUsers[key][i]].userName = customerUser?.user.name;
          response.data.data[searchUsers[key][i]].userData = customerUser;
        }
        await CustomersDB.addCustomers([customerUser]);
      }
    }
    setFooterData({ totalDue, totalAmount, totalPaid, totalReturned, totalRemaining });
    return response;
  };
  const addPage = async (value: number, sizeVal = 0) => {
    //check total pages
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();
      if (sizeVal !== 0) {
        allValues.constraints.page = 1;
        allValues.constraints.size = sizeVal;
        setPage(1);
        setSize(sizeVal);
      } else {
        allValues.constraints.page = value;
        allValues.constraints.size = size;
        setPage(value);
      }
      onSearch(allValues, true);
    } catch (e: any) {
      console.log('validate error', e);
    }
  };

  const downloadReport = async () => {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsloading(true);
    const values = form.getFieldsValue();
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    await form.validateFields();
    try {
      if (check_export_report_date_cap(values.constraints.startDate, values.constraints.endDate)) {
        // const response =
        values.constraints.customReportQueueName = 'Sell Representative Report';
        message.info({
          key: 'downloading',
          content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
        });
        await download_report(values.constraints, 29);
        setIsloading(false);
        // window.open(response.data, '_blank');
      }
    } catch (err: any) {
      setIsloading(false);
      message.error(err.message);
    }
  };

  async function onSearchAll() {
    try {
      setIsloading(true);
      if (!fullExportURL) {
        message.error('No URL specified. Please search first.');
        return;
      }
      let response = await get_report(fullExportURL, 29);
      if (response?.data.data) {
        response = await calculateTotal(response);
      }
      return getUpdatedData(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  }

  const updatedData = getUpdatedData(data.data);

  return (
    <div>
      <Spin spinning={isloading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <Collapse>
            <CollapsePanel header="Filters" key="1">
              <Form
                form={form}
                onFinish={onSearch}
                layout="vertical"
                validateTrigger={'onChange'}
                autoComplete="off"
                disabled={isloading}>
                {/* <PageHeader
                  title={state?.name}
                  style={{
                    padding: '8px 0px'
                  }}
                /> */}
                {React.Children.count(children) > 1 && (
                  <>
                    <PageHeader
                      subTitle="Constraints"
                      style={{
                        padding: '0px 0px'
                      }}
                    />
                  </>
                )}
                <div className="grid grid-cols-4 gap-2">
                  {children}
                  <div className="flex justify-start mt-7 gap-1">
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
                        Search
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className="secondary-button"
                        style={{ borderRadius: '6px' }}
                        onClick={() => downloadReport()}>
                        Download
                      </Button>
                    </Form.Item>
                  </div>
                </div>

                {/* <div className="flex justify-end mt-5 gap-3">
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ borderRadius: '10px' }}>
                      Search
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      style={{ backgroundColor: '#0AA245', color: 'white', borderRadius: '10px' }}
                      onClick={() => downloadReport()}>
                      Download
                    </Button>
                    </Form.Item>
                </div> */}
              </Form>
            </CollapsePanel>
          </Collapse>
          <PageHeader
            subTitle="Table"
            style={{
              padding: '8px 0px'
            }}
          />

          <CustomizeTable
            tableName="sell-representative-report-table"
            customScroll={{ x: 1500, y: 500 }}
            notshowPagination
            columns={columnsData[0]}
            data={data.data}
            toSort={sortHandle}
            footer={() =>
              `Total Amount: ${nepaliNumberFormatter(
                footer.totalAmount
              )}, Total Paid: ${nepaliNumberFormatter(
                footer.totalPaid
              )}, Total Remaining: ${nepaliNumberFormatter(
                footer.totalRemaining
              )}, Total Returned: ${nepaliNumberFormatter(
                footer.totalReturned
              )}, Total Due: ${nepaliNumberFormatter(footer.totalDue)}`
            }
            tableSummary={
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    {nepaliNumberFormatter(footer.totalAmount)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    {nepaliNumberFormatter(footer.totalPaid)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    {nepaliNumberFormatter(footer.totalReturned)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9}>
                    {nepaliNumberFormatter(footer.totalRemaining)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            }
            buttons={
              <>
                <ExportAllData
                  title="Sell Representative Report (All)"
                  columns={tableExportColumns}
                  excelColumns={excelExportColumns}
                  getInfo={onSearchAll}
                />
                <ExportCurrent
                  data={{ total: data.total, results: updatedData }}
                  columns={tableExportColumns}
                  excelColumns={excelExportColumns}
                  title="Sell Representative Report"
                />
              </>
            }
            paginationDatas={{
              page,
              total: data.total,
              size,
              onPagination: (pageNo, currentSize) => {
                currentSize == size ? addPage(pageNo) : addPage(pageNo, currentSize);
              }
            }}
          />
        </AppContent>
      </Spin>
    </div>
  );
};

export default CustomReport;

// const getFooterData = async (values: any) => {
//   if (footerData.purchase) {
//     const responsePurchase = await get_report(values.constraints, 7);
//     setFooterData((prev) => {
//       return {
//         ...prev,
//         totalPurchase: responsePurchase.data.data[0][`sum(total_amount)`]
//       };
//     });
//   }
//   if (footerData.sell) {
//     const responseSell = await get_report(values.constraints, 6);
//     setFooterData((prev) => {
//       return {
//         ...prev,
//         totalSell: responseSell.data.data[0].total_amount
//       };
//     });
//   }
// };
// const loadFooter = () => {
//   if (!footerData) {
//     return '';
//   }
//   return (
//     <>
//       {footerData.sell || footerData.purchase ? (
//         <div
//           style={{
//             backgroundColor: 'grey',
//             color: 'white',
//             height: '50px',
//             display: 'flex',
//             justifyContent: 'space-around',
//             alignItems: 'center',
//             fontSize: '25px',
//             borderRadius: '9px'
//           }}>
//           {footerData.sell && <div>Total Sell: {footer.totalSell}</div>}
//           {footerData.purchase && <div>Total Purchase: {footer.totalPurchase}</div>}
//         </div>
//       ) : (
//         <></>
//       )}
//     </>
//   );
// };
