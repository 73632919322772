import { find_fixed_decimal_limit, find_to_use_NPR } from '../store/localstorage/preferences';

export const nepaliNumberFormatter = (value: number, withoutC = false) => {
  const formatted = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'npr',
    minimumFractionDigits: find_fixed_decimal_limit(),
    maximumFractionDigits: find_fixed_decimal_limit()
  }).format(value);
  if (withoutC) {
    if (value < 0) {
      return '-' + formatted.slice(5);
    }
    return formatted.slice(4);
  }
  if (!find_to_use_NPR()) {
    if (value < 0) {
      return '-' + formatted.slice(5);
    }
    return formatted.slice(4);
  }
  return formatted;
};

export const numberDecimalFormatter = (value: number | string, isNumber = true) => {
  // if (Number.isInteger(value)) {
  //   return value;
  // }
  if (isNumber) {
    return parseFloat(Number(value).toFixed(find_fixed_decimal_limit()));
  }
  return Number(value).toFixed(find_fixed_decimal_limit());
};
