const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 1,
  margin: 0,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#f9f9f9',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'transparent',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
  flexWrap: 'wrap',
  // marginBottom: '10px',
  gap: '10px'
});

const reorder = (list, startIndex, endIndex, variable) => {
  const result = Array.from(list[variable]);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const move = (source, destination, droppableSource, droppableDestination, variable) => {
  const sourceClone = Array.from(source[variable]);
  const destClone = Array.from(destination[variable]);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = {
    name: source.name,
    [variable]: sourceClone
  };
  result[droppableDestination.droppableId] = {
    name: destination.name,
    [variable]: destClone
  };

  return result;
};
const isNotEmpty = (obj) => {
  return Object.keys(obj).length != 0;
};

export { getItemStyle, getListStyle, move, reorder, isNotEmpty };
