import { QrcodeOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';

interface BaseProps {
  title?: string;
  onlyIcon?: boolean;
}

type CustomIconProps =
  | (BaseProps & { link: string; onClick?: never })
  | (BaseProps & { onClick: () => void; link?: never });

function CustomQRIcon({ link, onClick, title, onlyIcon = true }: CustomIconProps) {
  return (
    <Tooltip title={title} color="blue">
      {link ? (
        <Link to={link} className="flex gap-2" aria-label={title}>
          <QrcodeOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
          {!onlyIcon && title}
        </Link>
      ) : (
        <div
          onClick={onClick}
          className="flex gap-2"
          aria-label={title}
          style={{ cursor: 'pointer' }}>
          <QrcodeOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
          {!onlyIcon && title}
        </div>
      )}
    </Tooltip>
  );
}

export default CustomQRIcon;
