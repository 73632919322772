import moment from 'moment';
import { Menu, message, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { EditFilled } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';

import { get_financial_years } from '@/services/financial-year/queries';
import { IFinancialYear } from '@/services/financial-year/types';

import AppContent from '@/components/Common/Content/Content';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { getUserData } from '@/utils/auth.utils';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { FinancialYearDB } from '@/store/localstorage/FinancialYearDB';
import { DEFAULT_DATE_FORMAT } from '@/constants';

function FinancialYearList() {
  const userData = getUserData();
  const isSuperUser = userData.perms.includes('SUPER_ADMIN');

  const { isLoading, data } = useQuery(['financialYear'], get_financial_years, {
    onError: () => {
      message.error('Failed to fetch financial years');
    },
    onSuccess: async (data) => {
      try {
        await FinancialYearDB.add(data);
      } catch (error) {
        console.log(error);
      }
    }
  });

  const columns: ColumnsType<IFinancialYear> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 5,
      render: (_, __, index) => <span>{index + 1}</span>
    },
    { title: 'Name', dataIndex: 'name', key: 'name', width: 15 },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 20,
      render: (text) => moment(text).format(DEFAULT_DATE_FORMAT)
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 20,
      render: (text) => moment(text).format(DEFAULT_DATE_FORMAT)
    },

    {
      title: 'Created At',
      width: 20,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment(text).format(DEFAULT_DATE_FORMAT)
    },
    {
      title: 'Status',
      width: 7,
      dataIndex: 'isClosed',
      key: 'paymentStatus',
      render: (isClosed: boolean) => {
        return <Tag color={isClosed ? 'red' : 'green'}>{isClosed ? 'Closed' : 'Open'}</Tag>;
      }
    }
  ];

  if (isSuperUser) {
    columns.push({
      title: 'Actions',
      key: 'actions',
      width: 6,
      fixed: 'right',
      render: (_, record) => {
        const menuItems = [
          {
            key: 'menu-view',
            label: (
              <Link to={`/accounts/financial-year/${record.id}`} className="flex justify-center">
                <EditFilled
                  style={{
                    transform: 'scale(1.1)'
                  }}
                />
              </Link>
            )
          }
        ];

        return <ActionDropdown menu={<Menu items={menuItems} />} />;
      }
    });
  }

  return (
    <AppContent
      breadcrumbItems={[
        { label: 'Accounts', link: '/accounts' },
        { label: 'FinancialYear', link: '/accounts/financial-year' }
      ]}
      button={
        <>
          {isSuperUser && (
            <CustomButton
              text="Add"
              backgroundColor="#1890ff"
              Linkto="/accounts/financial-year/new"
            />
          )}
        </>
      }>
      <CustomizeTable
        columns={columns}
        data={data || []}
        usersLoading={isLoading}
        notshowPagination={true}
        customScroll={{ x: 1000, y: '75vh' }}
        tableName={'financial-list'}
      />
    </AppContent>
  );
}

export default FinancialYearList;
