import moment from 'moment';
import { find_to_use_NPR } from '@/store/localstorage/preferences';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

export const tableExportColumns = [
  {
    label: 'Date',
    dataIndex: 'adjustment_date',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Reference No.',
    dataIndex: 'reference_number',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Location',
    dataIndex: 'location_name',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Adjustment Type',
    dataIndex: 'adjustmentType',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Total Amount',
    dataIndex: 'total_amount',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Total Amount Recovered',
    dataIndex: 'total_recovered_amount',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Reason',
    dataIndex: 'reason',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Added By',
    dataIndex: 'user',
    render: (text: string) => <div className="text-center">{text}</div>
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      adjustment_date: moment(record.adjustment_date).local().format('YYYY-MM-DD'),
      total_amount: nepaliNumberFormatter(record.total_amount),
      total_recovered_amount: record.totalAmountRecovered
        ? nepaliNumberFormatter(record.totalAmountRecovered)
        : ''
    };
  }) as any;
}
