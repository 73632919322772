import { IProcurementCreateRequest, IProcurementCreateResponse } from './types';
import http from '../../utils/http.utils';
import { ITasks, ITasksCreateRequest } from '../tasks/types';
import { AxiosResponse } from 'axios';

export const create_procurement_mutation = async (values: IProcurementCreateRequest) => {
  const response: AxiosResponse<IProcurementCreateResponse[], any> = await http.store(
    'procurement',
    values
  );
  return response;
};

export const create_tasks_mutation = async (values: ITasksCreateRequest) => {
  const response: AxiosResponse<ITasks, any> = await http.store('task', values);
  return response;
};
// export const update_procurement_mutation = async (values: any) => {
//   const response = await http.update('products/' + values.id, values);
//   return response;
// };
