import { useEffect, useState } from 'react';
import { get_image } from '../../../components/Common/FetchImage/FetchImage';
import { API_URL } from '../../../constants/config';

import CustomTable from './CustomTable';

import { nepaliNumberFormatter } from '../../../utils/numberFormatter';
import { convertUTCStringtoLocalString } from '../../../utils/convertToUTC';

import moment from 'moment';
import './index.css';
import { getUserData } from '../../../utils/auth.utils';

export const InvoiceForPOSSell = () => {
  // const { state }: any = useLocation();
  const loggedInUser = getUserData();
  const state = JSON.parse(localStorage.getItem('referrer') as string);
  const {
    customerDetails,
    vendorDetails,
    locationDetails,
    purchaseDetails,
    lines,
    invoiceLayouts,
    adjustmentDetails,
    transferData,
    createdByDetails,
    printLocation
  } = state;
  // console.log(state);
  // console.log('--->', convert_string_to_nepali_date_object(moment().format()));
  const [imgSrc, setimgSrc] = useState('');
  let content: any;
  if (invoiceLayouts) {
    if ('content' in invoiceLayouts) {
      if (typeof invoiceLayouts.content == 'string') content = JSON.parse(invoiceLayouts.content);
    } else {
      invoiceLayouts.content = '';
    }
  }

  useEffect(() => {
    getInfo();
    window.print();
    window.onafterprint = (event) => {
      window.close();
      localStorage.removeItem('referrer');
    };
  }, []);
  const getInfo = async () => {
    if ('invoiceLogo' in content) {
      const imageData = await get_image(`${API_URL}storage/media/${content.invoiceLogo}`);
      setimgSrc(imageData);
    } else {
      window.print();
      window.onafterprint = (event) => {
        window.close();
        localStorage.removeItem('referrer');
      };
      // window.close();
    }
  };

  const columns: any = [
    {
      label: (
        <div
          style={{
            backgroundColor: 'white',
            color: 'black',
            padding: '0px'
          }}>
          {`${
            invoiceLayouts?.content?.productLabel
              ? invoiceLayouts.content.productLabel
              : 'ProductName'
          }`}
        </div>
      ),
      dataIndex: 'productName',
      width: 200,
      render: (text: string) => <div className="px-1">{text}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.unitPriceLabel
          ? invoiceLayouts.content.unitPriceLabel
          : 'UnitPrice'
      }`,
      dataIndex: 'unitPrice',
      width: 100,
      render: (text: number) => <div className="text-right px-1">{nepaliNumberFormatter(text)}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.quantityLabel ? invoiceLayouts.content.quantityLabel : 'Quantity'
      }`,
      dataIndex: 'quantity',
      width: 100,
      render: (text: number) => <div className="text-right px-1">{text}</div>
    },

    {
      label: `${
        invoiceLayouts?.content?.totalLabel ? invoiceLayouts.content.totalLabel : 'Total Amount'
      }`,
      dataIndex: purchaseDetails.fromprint ? 'totalAmount' : 'amount',
      width: 100,
      render: (text: number) => <div className="text-right px-1">{nepaliNumberFormatter(text)}</div>
    }
  ];

  return (
    <div className="p-5" style={{ color: 'black' }}>
      <div className="flex flex-col justify-center items-center">
        {/* <Image
          src={imgSrc}
          preview={false}
          onLoad={() => {
            window.print();
            window.onafterprint = (event) => {
              window.close();
              localStorage.removeItem('referrer');
            };
          }}
        /> */}
        <div className="font-bold text-lg">
          {content?.companyName ? content.companyName : 'Fresh K.T.M. Pvt. Ltd.'}
        </div>
        <div className="text-2xl font-extrabold">
          {printLocation?.name ? printLocation.name : ''}
        </div>
        <div>
          <span className="font-bold text-sm mt-2">
            {content?.panNumberLabel ? content.panNumberLabel : 'PAN No.'}:
          </span>
          <span className="text-sm">606891700</span>{' '}
        </div>
        <div className="text-xl font-bold">
          {content?.sellOrderInvoiceName ? content.sellOrderInvoiceName : 'Invoice'}{' '}
        </div>
      </div>
      <hr />
      <div className="flex justify-between" style={{ color: 'black' }}>
        <div>
          <div>Invoice No:</div>
          <div>Date:</div>
          <div>Customer:</div>
        </div>
        <div>
          <div>{purchaseDetails.referenceNumber}</div>
          <div>
            {' '}
            <span>{convertUTCStringtoLocalString(moment().format('YYYY-MM-DD'))}</span>
          </div>
          <div>{customerDetails.name}</div>
          <div>{customerDetails.email}</div>
          <div>{customerDetails.phone}</div>
        </div>
      </div>
      <div className="mt-4">Products Info</div>
      <div className="mt-3">
        <CustomTable columns={columns} datas={lines ? lines : []} />
      </div>
      {/* <CustomizeTable columns={columnss} data={lines} /> */}
      {/* <CustomTable columns={columnss} datas={lines} /> */}
      {/* <CustomizeTable columns={columns} notshowPagination={true} data={lines} /> */}
      <hr />
      {purchaseDetails ? (
        <div className="flex justify-end">
          <div>
            <div>
              {content?.totalQuantityLabel ? content.totalQuantityLabel : 'Total Quantity'}:{' '}
              {purchaseDetails.totalQuantity}
            </div>
            <div>
              {content?.totalLabel ? content.totalLabel : 'Total Amount'}:{' '}
              {nepaliNumberFormatter(purchaseDetails.totalAmount)} /-
            </div>
            <div>Discount: {nepaliNumberFormatter(purchaseDetails.discount)} /-</div>
            {/* <div>
              {content?.totalDiscount ? content.totalDiscount : 'Total Discount'}:{' '}
              {nepaliNumberFormatter(purchaseDetails.totalDiscount)} /-
            </div>
            <div>
              {content?.taxLabel ? content.taxLabel : 'Total Tax'}:
              {nepaliNumberFormatter(purchaseDetails.totalTax)} /-
            </div>
            <div>
              {content?.netTotalLabel ? content.netTotalLabel : 'Net Total'}:{' '}
              {nepaliNumberFormatter(purchaseDetails.totalPrice)} /-
            </div> */}
          </div>
        </div>
      ) : (
        <></>
      )}
      {invoiceLayouts?.content?.footerText ? (
        <div dangerouslySetInnerHTML={{ __html: invoiceLayouts?.content?.footerText }}></div>
      ) : (
        <></>
      )}
      <div>CreatedBy:{createdByDetails ? createdByDetails.name : ''}</div>
      <div>
        Printed By:{' '}
        <span className="capitalize">{loggedInUser?.name ? loggedInUser.name : ''}</span>
      </div>
      <div>
        {content?.noteLabel ? content.noteLabel : 'Note'} :{' '}
        {purchaseDetails.note ? purchaseDetails.note : ''}
      </div>
    </div>
  );
};

const tdStyleone = {
  border: '1px solid black',
  padding: '15px 0px 0px 1px',
  alignText: 'center'
};

const tdStyle = {
  border: '1px solid black',
  padding: '3px',
  alignText: 'center'
};

const tdivStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};
