import { Button, Collapse, Form, FormInstance, message, PageHeader, Spin } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import Table from 'antd/lib/table';
import React, { useState } from 'react';
import AppContent from '@/components/Common/Content/Content';
import { get_location_list } from '@/services/locations/queries';

import { download_report, get_report } from '@/services/report/queries';
import { get_customer_list_ids, get_vendor_list_ids } from '@/services/users/queries';
import CustomersDB from '@/store/localstorage/CustomerDB';
import LocationsDB from '@/store/localstorage/LocationsDB';
import VendorsDB from '@/store/localstorage/VendorDB';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { getInt } from '../pending-payment-sell-report/report';
import { check_date_cap } from '@/utils/common';
import { showReportConditionalDownloadMessage } from '@/utils/reportConditionalDownloadMessage';
import { excelExportColumns, getUpdatedData, tableExportColumns } from './column.export';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportAllData from '@/components/Common/ExportAll';
import ExportCurrent from '@/components/Common/ExportCurrent';

interface ReportData {
  count: any;
  data: any;
}

interface State {
  name: string;
}
interface ReportProps {
  state: State;
  breadcrumbItems: any[];
  form: FormInstance<any>;
  columnsData: any[];
  footerData?: { sell?: boolean; purchase?: boolean };
  sortHandle: any;
}

const CustomReport: React.FC<ReportProps> = ({
  state,
  breadcrumbItems,
  form,
  columnsData,
  footerData = {
    sell: false,
    purchase: false
  },
  sortHandle,
  children
}) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [data, setData] = useState<ReportData>({ count: 0, data: [] });
  const [footer, setFooterData] = useState({ return: 0, receive: 0 });
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState(100);
  const [isEmpty, setIsEmpty] = useState(false);

  const [isloading, setIsloading] = useState(false);
  const [fullExportURL, setFullExportURL] = useState<any>(null);

  const onSearch = async (values: any, storePage = false) => {
    setIsloading(true);
    if (check_date_cap(values.constraints.startDate, values.constraints.endDate)) {
      await downloadReport();
      setIsloading(false);
      return;
    }
    if (!storePage) {
      values.constraints['page'] = 1;
      values.constraints['size'] = size;
      setPage(1);
    }
    values.constraints.endDate = convertLocalToUTCString(values.constraints.to);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.from);

    //remove custom date
    delete values.constraints.to;
    delete values.constraints.from;
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }

    let response: any;
    if (values.constraints.type == 'sell') {
      setColumns(columnsData[0]);
      response = await get_report(values.constraints, 12);
      if (response?.data.data) {
        response = await calculateTotalSell(response);
      }
    } else {
      setColumns(columnsData[1]);
      response = await get_report(values.constraints, 28);
      response = await calculateTotalPurchase(response);
    }
    if (response.data.data.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }

    const payload = values.constraints as any;
    payload.size = response.data.count;
    setFullExportURL(payload);
    setData(response.data);
    // await getFooterData(values);
    setIsloading(false);
  };

  const calculateTotalPurchase = async (response: any) => {
    let total = 0;
    let totalq = 0;
    const searchUsers: any = {};
    for (let index = 0; index < response.data.data.length; index++) {
      const user: any = await VendorsDB.getVendors(response.data.data[index].vendor_id);
      if (!user) {
        if (response.data.data[index].vendor_id in searchUsers) {
          searchUsers[response.data.data[index].vendor_id] = [
            ...searchUsers[response.data.data[index].vendor_id],
            index
          ];
        } else {
          searchUsers[response.data.data[index].vendor_id] = [index];
        }
      } else {
        response.data.data[index].userName = user.user.name;
        response.data.data[index].userData = user;
      }
      let locD: any = await LocationsDB.getLocation(response.data.data[index].location_id);
      if (!locD) {
        const allLocations = await get_location_list();
        await LocationsDB.addLocations([...allLocations.data.results]);
        locD = await LocationsDB.getLocation(response.data.data[index].location_id);
      }
      response.data.data[index].locationName = locD.name;
      total += parseInt(response.data.data[index].amount_paid);
      totalq +=
        getInt(response.data.data[index].total_amount) -
        getInt(response.data.data[index].amount_returned);
    }
    const searchUserslength = Object.entries(searchUsers).length;
    if (searchUserslength > 0) {
      const customerresponse = await get_vendor_list_ids([...Object.keys(searchUsers)]);
      for (const key in searchUsers) {
        const vendorUser = customerresponse?.data?.results.find(
          (currCustomer: any) => currCustomer.id == key
        );
        for (let i = 0; i < searchUsers[key].length; i++) {
          response.data.data[searchUsers[key][i]].userName = vendorUser?.user.name;
          response.data.data[searchUsers[key][i]].userData = vendorUser;
        }
        await VendorsDB.addVendors([vendorUser]);
      }
    }
    setFooterData({ receive: total, return: totalq });
    return response;
  };
  const calculateTotalSell = async (response: any) => {
    let total = 0;
    let totalq = 0;
    const searchUsers: any = {};
    for (let index = 0; index < response.data.data.length; index++) {
      const user: any = await CustomersDB.getCustomer(response.data.data[index].customer_id);
      if (!user) {
        if (response.data.data[index].customer_id in searchUsers) {
          searchUsers[response.data.data[index].customer_id] = [
            ...searchUsers[response.data.data[index].customer_id],
            index
          ];
        } else {
          searchUsers[response.data.data[index].customer_id] = [index];
        }
      } else {
        response.data.data[index].userName = user.user.name;
        response.data.data[index].userData = user;
      }
      let locD: any = await LocationsDB.getLocation(response.data.data[index].location_id);
      if (!locD) {
        const allLocations = await get_location_list();
        await LocationsDB.addLocations([...allLocations.data.results]);
        locD = await LocationsDB.getLocation(response.data.data[index].location_id);
      }
      response.data.data[index].locationName = locD.name;
      total += getInt(response.data.data[index].amount_paid);
      totalq +=
        getInt(response.data.data[index].total_amount) -
        getInt(response.data.data[index].amount_returned);
    }
    // console.log('Search Customers-->', searchUsers);
    const searchUserslength = Object.entries(searchUsers).length;
    if (searchUserslength > 0) {
      const customerresponse = await get_customer_list_ids([...Object.keys(searchUsers)]);
      // console.log('customer response', customerresponse.data);
      for (const key in searchUsers) {
        const customerUser = customerresponse?.data?.results.find(
          (currCustomer: any) => currCustomer.id == key
        );
        for (let i = 0; i < searchUsers[key].length; i++) {
          response.data.data[searchUsers[key][i]].userName = customerUser?.user.name;
          response.data.data[searchUsers[key][i]].userData = customerUser;
        }
        await CustomersDB.addCustomers([customerUser]);
      }
    }

    setFooterData({ receive: total, return: totalq });

    return response;
  };
  const addPage = async (value: number, sizeVal = 0) => {
    //check total pages
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();
      if (sizeVal !== 0) {
        allValues.constraints.page = 1;
        allValues.constraints.size = sizeVal;
        setPage(1);
        setSize(sizeVal);
      } else {
        allValues.constraints.page = value;
        allValues.constraints.size = size;
        setPage(value);
      }
      onSearch(allValues, true);
    } catch (e: any) {
      console.log('validate error', e);
    }
  };

  const downloadReport = async () => {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsloading(true);
    // console.log('Clicked');
    const values = form.getFieldsValue();

    values.constraints['page'] = 1;
    values.constraints['size'] = size;
    setPage(1);
    values.constraints.endDate = convertLocalToUTCString(values.constraints.to);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.from);

    //remove custom date
    delete values.constraints.to;
    delete values.constraints.from;
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    try {
      // const a = 8;
      await form.validateFields();

      if (values.constraints.type == 'sell') {
        // const response =
        values.constraints.customReportQueueName = 'Full Day Sell Report';
        message.info({
          key: 'downloading',
          content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
        });
        await download_report(values.constraints, 12); //id to change
        setIsloading(false);
        // window.open(response.data, '_blank');
      } else {
        // const response2 =
        values.constraints.customReportQueueName = 'Full Day Purchase Report';
        message.info({
          key: 'downloading',
          content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
        });
        await download_report(values.constraints, 28);
        setIsloading(false);
        // window.open(response2.data, '_blank');
      }
    } catch (err: any) {
      setIsloading(false);
      message.error(err.message);
    }
  };

  const updatedData = getUpdatedData(data.data);
  async function onSearchAll() {
    try {
      setIsloading(true);
      if (!fullExportURL) {
        message.error('No URL specified. Please search first.');
        return;
      }

      const parsedURL = new URLSearchParams(fullExportURL);
      const type = parsedURL.get('type');
      let response: any;
      if (type == 'sell') {
        response = await get_report(fullExportURL, 12);
        if (response?.data.data) {
          response = await calculateTotalSell(response);
        }
      } else {
        response = await get_report(fullExportURL, 28);
        response = await calculateTotalPurchase(response);
      }

      return getUpdatedData(response.data.data) as any;
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  }

  return (
    <div>
      <Spin spinning={isloading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <Collapse>
            <CollapsePanel header="Filters" key="1">
              <Form
                form={form}
                onFinish={onSearch}
                layout="vertical"
                validateTrigger={'onChange'}
                autoComplete="off"
                disabled={isloading}>
                {/* <PageHeader
                  title={state?.name}
                  style={{
                    padding: '8px 0px'
                  }}
                /> */}
                {React.Children.count(children) > 1 && (
                  <>
                    <PageHeader
                      subTitle="Constraints"
                      style={{
                        padding: '8px 0px'
                      }}
                    />
                  </>
                )}
                <div className="grid grid-cols-5 gap-2">
                  {children}
                  <div className="flex justify-start mt-7 gap-3">
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
                        Search
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className="secondary-button"
                        style={{ borderRadius: '6px' }}
                        onClick={() => downloadReport()}>
                        Download
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </CollapsePanel>
          </Collapse>
          <PageHeader
            subTitle="Table"
            style={{
              padding: '8px 0px'
            }}
          />
          <CustomizeTable
            tableName="full-day-report"
            customScroll={{ x: 1200, y: 500 }}
            notshowPagination={true}
            toSort={sortHandle}
            columns={columnsData[1]}
            data={data.data}
            footer={() =>
              `Total Receive: ${nepaliNumberFormatter(
                footer.receive
              )}, Total After Return: ${nepaliNumberFormatter(footer.return)}`
            }
            tableSummary={
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6} className="text-right mr-2">
                    {nepaliNumberFormatter(footer.return)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} className="text-right mr-2">
                    {nepaliNumberFormatter(footer.receive)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            }
            buttons={
              <>
                <ExportAllData
                  title="Full Day Report (All)"
                  columns={tableExportColumns}
                  excelColumns={excelExportColumns}
                  getInfo={onSearchAll}
                />
                <ExportCurrent
                  data={{ total: data.count, results: updatedData }}
                  columns={tableExportColumns}
                  excelColumns={excelExportColumns}
                  title="Full Day Report"
                />
              </>
            }
            paginationDatas={{
              page,
              total: data.count,
              size,
              onPagination: (pageNo, currentSize) => {
                currentSize == size ? addPage(pageNo) : addPage(pageNo, currentSize);
              }
            }}
          />
          {/* <div className="flex justify-end mt-4">
            <Pagination
              current={page}
              total={data.count}
              pageSize={size}
              pageSizeOptions={[100, 500, data.count]}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              onChange={(pageNo, pageSize) => {
                // addPage(pageNo);
                if (pageSize == size) {
                  //code for page change
                  addPage(pageNo);
                } else {
                  //code for size change
                  addPage(pageNo, pageSize);
                }
              }}
            />
          </div> */}
        </AppContent>
      </Spin>
    </div>
  );
};

export default CustomReport;
//  const getFooterData = async (values: any) => {
//    if (footerData.purchase) {
//      const responsePurchase = await get_report(values.constraints, 7);
//      setFooterData((prev) => {
//        return {
//          ...prev,
//          totalPurchase: responsePurchase.data.data[0][`sum(total_amount)`]
//        };
//      });
//    }
//    if (footerData.sell) {
//      const responseSell = await get_report(values.constraints, 6);
//      setFooterData((prev) => {
//        return {
//          ...prev,
//          totalSell: responseSell.data.data[0].total_amount
//        };
//      });
//    }
//  };
//  const loadFooter = () => {
//    if (!footerData) {
//      return '';
//    }
//    return (
//      <>
//        {footerData.sell || footerData.purchase ? (
//          <div
//            style={{
//              backgroundColor: 'grey',
//              color: 'white',
//              height: '50px',
//              display: 'flex',
//              justifyContent: 'space-around',
//              alignItems: 'center',
//              fontSize: '25px',
//              borderRadius: '9px'
//            }}>
//            {footerData.sell && <div>Total Sell: {footer.totalSell}</div>}
//            {footerData.purchase && <div>Total Purchase: {footer.totalPurchase}</div>}
//          </div>
//        ) : (
//          <></>
//        )}
//      </>
//    );
//  };
