import React, { useContext, useState } from 'react';
import { Input, Menu, Form, TableProps, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import AppContent from '@/components/Common/Content';
import { get_purchase_return_list_filter } from '@/services/purchases/queries';
import { get_user_list_ids, get_vendor_list_ids } from '@/services/users/queries';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import VendorsDB from '@/store/localstorage/VendorDB';
import { ConvertObjectToURL } from '@/utils/converturl';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { getUserData } from '@/utils/auth.utils';
import ActionDropdown from '@/components/Common/Dropdownactions';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { VendorSearch } from '@/components/Common/VendorSearch/VendorSearch';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { find_to_use_NPR } from '@/store/localstorage/preferences';
import { checkAccess } from '@/routes/acl';
import ProductCategorySearch from '@/components/Common/ProductCategorySearch/ProductCategorySearch';
import { PurchaseReturnInvoicePrintModal } from './InvoicePrint';
import { PurchaseReturnListContext } from './context';
import UsersDB from '@/store/localstorage/UsersDB';
import { IPurchaseReturn2 } from '@/services/purchases/types';
import CopyButton from '@/components/Common/CopyButton';
import GenericTable from '@/components/Common/CustomizeTable';
import ProductCategorySearchV2 from '@/components/Common/CustomSearch/ProductCategory';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import VendorSearchV2 from '@/components/Common/CustomSearch/Vendors';

const PurchaseReturn: React.FC = () => {
  const { form, isLoading, setIsLoading } = useContext(PurchaseReturnListContext);
  const [allpurchaseReturnList, setallPurchaseReturnList] = useState<any>({
    count: 0,
    results: []
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [total, setTotal] = useState({ totalAmount: 0, totalTax: 0 });
  let ctotalamount = 0;
  let ctotaltax = 0;
  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const columns: ColumnsType<IPurchaseReturn2> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 20,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Vendor',
      key: 'userName',
      width: 100,
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (a, record) => {
        return <TableCell>{record.userName}</TableCell>;
      }
    },
    {
      title: 'Financial Reference',
      key: 'financialReference',
      className: 'invoice',
      width: 100,
      sorter: (a, b) => a.financialReference.localeCompare(b.financialReference),
      sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <TableCell>
            {record.financialReference}{' '}
            {record.financialReference ? <CopyButton text={record.financialReference} /> : null}
          </TableCell>
        );
      }
    },
    {
      title: 'Total Amount.',
      key: 'totalAmount',
      className: 'highlight',
      width: 60,
      dataIndex: 'totalAmount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <TableCell className="text-xs text-right mr-2">
            {nepaliNumberFormatter(record.totalAmount)}
          </TableCell>
        );
      }
    },
    {
      title: 'Total Tax',
      key: 'totalTax',
      width: 50,
      dataIndex: 'totalVat',
      sorter: (a, b) => a.totalVat - b.totalVat,
      sortOrder: sortedInfo.columnKey === 'totalVat' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <TableCell className="text-xs text-right mr-2">
            {nepaliNumberFormatter(record.totalVat)}
          </TableCell>
        );
      }
    },
    {
      title: 'Note',
      key: 'note',
      width: 100,
      sorter: (a, b) => a.note.localeCompare(b.note),
      sortOrder: sortedInfo.columnKey === 'note' ? sortedInfo.order : null,
      render: (a, record) => {
        return <TableCell className="text-xs mr-2">{record.note}</TableCell>;
      }
    },
    {
      title: 'Created By',
      key: 'createdByName',
      width: 50,
      sorter: (a, b) =>
        a.createdByName && b.createdByName ? a.createdByName.localeCompare(b.createdByName) : 0,
      sortOrder: sortedInfo.columnKey === 'createdByName' ? sortedInfo.order : null,
      render: (a, record) => {
        return <TableCell className="text-xs mr-2">{record.createdByName}</TableCell>;
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 25,
      fixed: 'right',
      render: (a, record) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];
        if (checkAccess('READ_PURCHASE')) {
          menuItems.push({
            key: '2',
            label: (
              <PurchaseReturnInvoicePrintModal getInfo={getInfo} record={record} key={record.id} />
            )
          });
        }

        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '4',
            label: (
              <>
                {checkAccess('READ_CHANNEL') && (
                  <DiscussionChatModal slug="purchase_return" id={record.id} />
                )}
              </>
            )
          });
        }
        const menu = <Menu items={menuItems} />;

        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Purchases Return',
      link: '/purchase/return'
    }
  ];

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const urlParams = new URLSearchParams(filter);
    const vendorIds = urlParams.get('vendorId');
    urlParams.delete('vendorId');
    if (vendorIds) {
      const customerList = vendorIds.split(',').map(Number);
      const updatedParams = customerList.map((id) => `vendorIds[]=${id}`).join('&');

      const updatedURL = `${urlParams.toString()}&${updatedParams}`;
      filter = updatedURL;
    } else {
      filter = urlParams.toString();
    }

    const response = await get_purchase_return_list_filter(filter);
    const searchUsers: any = {};
    if (response?.data?.results) {
      for (let index = 0; index < response.data.results.length; index++) {
        const user: any = await VendorsDB.getVendors(response.data.results[index].vendorId);
        ctotalamount += response.data.results[index].totalAmount;
        ctotaltax += response.data.results[index].totalVat;
        setTotal({ totalAmount: ctotalamount, totalTax: ctotaltax });
        if (!user) {
          if (response.data.results[index].vendorId in searchUsers) {
            searchUsers[response.data.results[index].vendorId] = [
              ...searchUsers[response.data.results[index].vendorId],
              index
            ];
          } else {
            searchUsers[response.data.results[index].vendorId] = [index];
          }
        } else {
          response.data.results[index].userName = user.user.name;
          response.data.results[index].userData = user;
        }
        if (response.data.results[index].createdBy) {
          let createdByDetails = await UsersDB.getUser(response.data.results[index].createdBy);
          if (!createdByDetails) {
            const allUsers = await get_user_list_ids([
              ...new Set(
                response.data.results
                  .filter((val) => val.createdBy !== null)
                  .map((value) => {
                    return value.createdBy;
                  })
              )
            ]);
            await UsersDB.addUsers(allUsers.data.results);
            createdByDetails = await UsersDB.getUser(response.data.results[index].createdBy);
          }
          response.data.results[index].createdByName = createdByDetails.name;
        }
      }
    }

    const searchUserslength = Object.entries(searchUsers).length;

    if (searchUserslength > 0) {
      const customerresponse = await get_vendor_list_ids([...Object.keys(searchUsers)]);
      for (const key in searchUsers) {
        const vendorUser = customerresponse?.data?.results.find(
          (currCustomer: any) => currCustomer.id == key
        );
        if (vendorUser) {
          for (let i = 0; i < searchUsers[key].length; i++) {
            response.data.results[searchUsers[key][i]].userName = vendorUser.user.name;
            response.data.results[searchUsers[key][i]].userData = vendorUser;
          }
          await VendorsDB.addVendors([vendorUser]);
        }
      }
    }
    setallPurchaseReturnList(response.data);
    setIsLoading(false);
    return response;
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }

    const url = ConvertObjectToURL(values);
    const response = await get_purchase_return_list_filter(url);
    setallPurchaseReturnList(response.data);
    setIsLoading(false);
  };

  return (
    <AppContent
      breadcrumbItems={breadcrumbItems}
      withfilter={true}
      button={
        <>
          {/* <div className="flex flex-wrap items-center justify-end"> */}
          {
            <TableFilter
              defaultValues={{
                dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                value: '',
                // locationId: ''
                locationId: preferenceLocationId ? preferenceLocationId : 1,
                skip: 0,
                count: 100
              }}
              initial={true}
              onSubmit={onSubmitFilter}
              form={form}
              // outsidestyle={'flex flex-wrap gap-2'}
              style="grid grid-cols-3 md:grid-cols-3 xl:grid-cols-4 gap-2"
              styleforbuttons={'flex flex-wrap justify-end items-center'}>
              <LocationSearchV2 hasParentFormItem={false} name="locationId" showAll />
              <VendorSearchV2
                hasParentFormItem={false}
                name="vendorId"
                isMultiple
                label="Supplier"
              />
              <ProductCategorySearchV2
                hasParentFormItem={false}
                name="categoryId"
                label="Product Category"
              />
              <Form.Item name="value" label="Search">
                <Input placeholder="Search" />
              </Form.Item>
            </TableFilter>
          }
          {/* </div> */}
        </>
      }>
      <GenericTable
        columns={columns}
        data={allpurchaseReturnList.results}
        isLoading={isLoading}
        hideDefaultPagination
        tableName={'purchase-return-list'}
        scroll={{ x: 1000, y: '75vh' }}
        summaryClassName="text-right !pr-3"
        generateSummary
        pagination={{
          page: page,
          total: allpurchaseReturnList.count,
          size: size,
          onPagination
        }}
        toSort={handleChange}
      />
    </AppContent>
  );
};

export default PurchaseReturn;
