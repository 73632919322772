import { get_all_acl_groups } from '@/services/acl/queries';
import { useQuery } from '@tanstack/react-query';
import { Form, Select } from 'antd';

function ACLForm() {
  const { data } = useQuery(['acl-user-list'], async () => {
    const response = await get_all_acl_groups();
    return response.data;
  });

  return (
    <Form.Item
      name={'aclGroupIds'}
      label="ACL Groups"
      rules={[{ required: true, message: 'Please add at least 1 permission!' }]}>
      <Select
        showSearch
        mode="multiple"
        placeholder={'select group'}
        defaultActiveFirstOption={false}
        filterOption={false}
        notFoundContent={null}>
        {data?.map((value) => {
          return (
            <Select.Option value={value.id} key={value.id}>
              {value.name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
}

export default ACLForm;
