import { WithReportPaginationProps } from '@/components/Common/Report/withReportPagination';
import { ExtendedInitialValues } from '.';
import { useState } from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import { TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { IVehicleSumDistanceReport } from '@/services/report/types';
import GenericTable from '@/components/Common/CustomizeTable';
import ExportAllData from '@/components/Common/ExportAll';
import ExportCurrent from '@/components/Common/ExportCurrent';

import { excelExportColumns, getUpdatedData, tableExportColumns } from './columns.export';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

interface Props
  extends Pick<WithReportPaginationProps, 'onPagination' | 'onChange' | 'pagination'> {
  data: { total: number; data: IVehicleSumDistanceReport[] };
  onSearch: (values: ExtendedInitialValues) => void;
  onSearchAll: () => Promise<IVehicleSumDistanceReport[]>;
}

function TableData({ data, pagination, ...handlers }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IVehicleSumDistanceReport>>({});

  const handleChange: TableProps<IVehicleSumDistanceReport>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<IVehicleSumDistanceReport>);
  };

  const columns: ColumnsType<IVehicleSumDistanceReport> = [
    {
      title: 'SN',
      key: 'SN',
      width: 10,
      render: (text, record, index) => {
        return <TableCell>{(pagination.page - 1) * pagination.size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Vehicle ID',
      dataIndex: 'vehicle_id',
      key: 'vehicle_id',
      width: 15,
      sorter: (a, b) => a.vehicle_id - b.vehicle_id,
      sortOrder: sortedInfo.columnKey === 'vehicle_id' ? sortedInfo.order : null
    },
    {
      title: 'Vehicle Name',
      dataIndex: 'name',
      key: 'name',
      width: 50,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null
    },
    {
      title: 'IMEI',
      dataIndex: 'imei',
      key: 'imei',
      width: 30,
      sorter: (a, b) => a.imei.localeCompare(b.imei),
      sortOrder: sortedInfo.columnKey === 'imei' ? sortedInfo.order : null
    },
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
      width: 30,
      sorter: (a, b) => a.number.localeCompare(b.number),
      sortOrder: sortedInfo.columnKey === 'number' ? sortedInfo.order : null
    },
    {
      title: 'Total Distance',
      dataIndex: 'sum_distance',
      key: 'sum_distance',
      width: 20,
      sorter: (a, b) => parseFloat(a.sum_distance) - parseFloat(b.sum_distance),
      sortOrder: sortedInfo.columnKey === 'sum_distance' ? sortedInfo.order : null,
      render: (distance) => {
        return <TableCell className="text-right pr-1">{nepaliNumberFormatter(distance)}</TableCell>;
      }
    }
  ];

  const updatedData = getUpdatedData(data.data);

  return (
    <GenericTable
      columns={columns}
      data={data.data}
      hideDefaultPagination
      toSort={handleChange}
      scroll={{ x: 1000, y: '75vh' }}
      generateSummary
      excludeSummaryByKeys={['vehicle_id', 'imei', 'number']}
      buttons={
        <>
          <ExportAllData
            title="Vehicle Daily Distance (All)"
            columns={tableExportColumns}
            excelColumns={excelExportColumns}
            getInfo={handlers.onSearchAll}
          />

          <ExportCurrent
            data={{ total: data.total, results: updatedData }}
            columns={tableExportColumns}
            excelColumns={excelExportColumns}
            title="Vehicle Daily Distance Report"
          />
        </>
      }
      pagination={{
        page: pagination.page,
        total: data.total,
        size: pagination.size,
        onPagination: (pageNo, currentSize) => {
          currentSize == pagination.size
            ? handlers.onPagination(handlers.onSearch, pageNo)
            : handlers.onPagination(handlers.onSearch, pageNo, currentSize);
        }
      }}
    />
  );
}

export default TableData;
