import { MoreOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Dropdown, Tooltip } from 'antd';
import React from 'react';
import CustomButton from '../CustomButton/CustomButton';

const ActionDropdown = ({
  menu,
  trigger = 'click',
  insideaction = false,
  button = false,
  text = 'Export',
  marginLeft = '',
  buttonJSX
}: {
  menu: any;
  trigger?: any;
  insideaction?: boolean;
  button?: boolean;
  text?: string;
  marginLeft?: string;
  buttonJSX?: React.ReactNode;
}) => {
  return (
    <Dropdown overlay={menu} trigger={[trigger]}>
      <div className="w-[100%] cursor-pointer flex justify-center items-center" style={{}}>
        {button ? (
          buttonJSX || (
            <CustomButton text={text} backgroundColor="#1890ff" marginLeft={marginLeft} />
          )
        ) : (
          <>
            {insideaction ? (
              <PrinterOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
            ) : (
              <MoreOutlined style={{ transform: 'scale(1.4)' }} />
            )}
          </>
        )}
      </div>
    </Dropdown>
  );
};

export default ActionDropdown;
