import { Pie } from 'react-chartjs-2';
import type { ChartData, ChartOptions } from 'chart.js';
import getPascalCase from '@/utils/getPascalCase';
import { chartColors } from '@/chart.config';
import WidgetButtons from '../widget/WidgetButtons';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { IChartBaseConfig } from '@/services/dashboard/v3/types';
import { truncateString } from '@/services/dashboard/v3/services.dashboard';

interface Props extends IChartBaseConfig {
  dataLimit?: number;
}

function PieChart({
  data,
  fields,
  title = '',
  dataLimit = 5,
  handleFilterOpen,
  handleFullScreen,
  allowFilter,
  enlargeChart
}: Props) {
  const field = fields[0];
  const labels = Object.keys(data);

  const datasets = labels.map((key) => {
    const values = data[key];
    let totalValue = 0;

    if (values.length) {
      totalValue = values.reduce((acc: number, item: Record<string, number | string>) => {
        const value = Number(item[field] ?? 0);
        return acc + value;
      }, 0);
    }

    return { label: key, value: totalValue };
  });

  const allValueZero = datasets.every((data) => data.value === 0);

  // Filter datasets where the minimum value is at least 5% less than the maximum value
  const maxValue = Math.max(...datasets.map((data) => Math.abs(data.value)));
  const limit = (dataLimit * maxValue) / 100;
  const minFilteredDatasets = datasets.filter((data) => Math.abs(data.value) >= limit);

  minFilteredDatasets.sort((a, b) => b.value - a.value);
  const topDatasets = minFilteredDatasets.slice(0, 10);
  const filteredLabels = topDatasets.map((item) => item.label);
  const isEmptyData = filteredLabels.length === 0 || allValueZero;

  const chartOptions: ChartOptions<'pie'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          usePointStyle: true,
          boxHeight: 10,
          font: {
            size: 10
          }
        }
      },
      title: { display: Boolean(title), text: title },
      tooltip: {
        callbacks: {
          label: (context) => {
            if (isEmptyData) {
              return 'No Data Available';
            }
            const label = context.label || '';
            const value = context.parsed || 0;
            return `${field}(${label}): ${nepaliNumberFormatter(value)}`;
          }
        }
      }
    }
  };

  const chartData: ChartData<'pie'> = {
    labels: isEmptyData
      ? ['No data']
      : filteredLabels.length > 5
      ? filteredLabels.map((label) => {
          const newLabel = truncateString(label);
          return Array.isArray(newLabel) ? newLabel.join('') : newLabel;
        })
      : filteredLabels,
    datasets: [
      {
        label: getPascalCase(field),
        data: isEmptyData ? [1] : topDatasets.map((item) => item.value),
        backgroundColor: chartColors.map((color) => color.backgroundColor),
        borderColor: chartColors.map((color) => color.borderColor),
        borderWidth: 1
      }
    ]
  };

  return (
    <>
      <Pie data={chartData} options={chartOptions} />
      <WidgetButtons
        allowZoom={false}
        enlargeChart={enlargeChart}
        allowFilter={allowFilter}
        handleFullScreen={handleFullScreen}
        handleFilterOpen={handleFilterOpen}
      />
    </>
  );
}

export default PieChart;
