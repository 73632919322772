import { Spin, Button, Form, Select, InputNumber, PageHeader, message, Input } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import AppContent from '../../../../components/Common/Content/Content';
import { MenuTypes } from '../../../../services/settings/enums';
import { MenuTypeSearch } from '../../../../components/Common/MenuTypeSearch';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { IServerError } from '../../../../services/response/types';
import { ICreateMenuItem, IMenuList } from '../../../../services/settings/types';
import { create_menu_item } from '../../../../services/settings/mutations';
import MenuDB from '../../../../store/localstorage/MenuDB';
import { get_menu_items } from '../../../../services/settings/queries';

export const CreateMenuList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const breadcrumbItems = [
    {
      label: 'Create Menu List ',
      link: '/settings/menu-list'
    }
  ];

  const generateRandomKey = async () => {
    let randomKey = Math.random().toString(36).slice(2);
    const allMenu = await get_menu_items();
    if (allMenu.data.results.find((menu) => menu.key === randomKey)) {
      randomKey = await generateRandomKey();
    }

    return randomKey;
  };

  const onFinish = async (values: ICreateMenuItem) => {
    setIsLoading(true);
    //console.log('Values', values);
    values.key = await generateRandomKey();
    await createMenuListItemMutation.mutateAsync(values);
  };

  const createMenuListItemMutation = useMutation<
    AxiosResponse<IMenuList>,
    AxiosError<IServerError>,
    ICreateMenuItem
  >(create_menu_item, {
    onSuccess: async ({ data }) => {
      setIsLoading(false);
      message.success('Menu item added successfully');
      navigate('/settings/menu-list');
      await MenuDB.addMenuItem([data]);
    },
    onError: (e) => {
      setIsLoading(false);
      message.error(`${e.response?.data.message}`);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{ keyword: '', type: MenuTypes.PARENT }}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Menu List Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 sm:grid-cols-4 gap-5 mb-5'}>
            <Form.Item
              label="Menu Name"
              name="name"
              rules={[{ required: true, message: 'Please add Menu Name!' }]}>
              <Input />
            </Form.Item>
            {/* <Form.Item
              label="Menu Key"
              name="key"
              rules={[{ required: true, message: 'Please add Menu Key!' }]}>
              <Input disabled />
            </Form.Item> */}
            <Form.Item label="Menu Type" name="type">
              <Select placeholder="Select menu type" disabled>
                {(Object.keys(MenuTypes) as Array<keyof typeof MenuTypes>).map((key) => (
                  <Select.Option key={key} value={key}>
                    {MenuTypes[key]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Item Position"
              name="position"
              rules={[{ required: true, message: 'Please add Item Position!' }]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <MenuTypeSearch
              formData={{ formName: 'parentId', label: 'Parent Item' }}
              isAll={false}
            />
            <Form.Item
              label="Search Keyword"
              name="keyword"
              rules={[{ required: true, message: 'Please add Item Keyword!' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Alias" name="alias">
              <Input />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/settings/menu-list')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};
