import { LoadScript, GoogleMap, MarkerF } from '@react-google-maps/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Form,
  Input,
  Button,
  PageHeader,
  Divider,
  message,
  Checkbox,
  Select,
  InputNumber
} from 'antd';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import AppContent from '../../../components/Common/Content/Content';
import { GOOGLE_MAPS_KEY } from '../../../constants/config';
import { checkAccess } from '../../../routes/acl';
import { update_location_mutation } from '../../../services/locations/mutations';
import { get_location_details } from '../../../services/locations/queries';
import { ILocations } from '../../../services/locations/types';
// import LocalStore from '../../../store/localstorage';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import ReuseChannel from '../../channel/Reuse';
import { RouteSearch } from '../../../components/Common/RouteSearch/RouteSearch';

const containerStyle = {
  width: '100%',
  height: '400px',
  marginBottom: '20px'
};

const Update = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();

  const center = {
    lat: 27.7172,
    lng: 85.324
  };

  const [marker, setMarkerPosition] = useState(center);
  const [isloading, setIsloading] = useState<boolean>(true);
  const [buttonloading, setButtonloading] = useState<boolean>(false);

  const breadcrumbItems = [
    {
      label: 'Location',
      link: '/locations'
    },
    {
      label: 'Update',
      link: '/locations/create'
    }
  ];

  const updateLocationsMutation = useMutation(update_location_mutation, {
    onSuccess: (data: any) => {
      setButtonloading(false);
      setIsloading(false);
      if (data) {
        message.success('Location updated successfully');
        navigate('/locations');
        LocationsDB.addLocations([data.data]);
      }
    }
  });

  const onFinish = (values: ILocations) => {
    setIsloading(true);
    setButtonloading(true);
    if (id) {
      values.id = parseInt(id);
      values.latitude = marker.lat;
      values.longitude = marker.lng;
      if (values.routeId == undefined) {
        values.routeId = null;
      }
      //console.log('Values', values);
      updateLocationsMutation.mutate(values);
    }
  };

  const onClick = (e: google.maps.MapMouseEvent) => {
    if (e?.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();

      setMarkerPosition({ lat, lng });
    }
  };

  // const getLocationfromLC = async () => {
  //   let fetchData: any;
  //   try {
  //     fetchData = await LocalStore.getLocation(parseInt(id as string));
  //   } catch (e: any) {
  //     console.log(e);
  //   } finally {
  //     if (!fetchData) {
  //       fetchData = await get_location_details(parseInt(id as string));
  //       if (fetchData == '') {
  //         navigate('/404');
  //       } else {
  //         dispatch(addLocation(fetchData));
  //       }
  //     }
  //   }
  //   if (fetchData != '') form.setFieldsValue(fetchData);
  // };
  // useEffect(() => {
  //   // getLocationfromLC();
  // }, []);

  useQuery(['productDetails', id], async () => get_location_details((id && parseInt(id)) || 0), {
    retry: false,
    onSuccess: (data: ILocations) => {
      if (data.latitude && data.longitude) {
        setMarkerPosition({ lat: data?.latitude, lng: data?.longitude });
      }
      const locations = {
        name: data.name,
        description: data.description,
        address: data.address,
        city: data.city,
        state: data.state,
        country: data.country,
        code: data.code,
        zip: data.zip,
        fax: data.fax,
        phone: data.phone,
        email: data.email,
        website: data.website,
        isActive: data.isActive,
        type: data.type,
        shipping: data.shipping,
        routeId: data.routeId
      };
      form.setFieldsValue(locations);
      setIsloading(false);
    }
  });

  const checkForRegEx = (e: any) => {
    let value = e.target.value;
    value = value.replace(/[^a-zA-Z0-9-@! ]/g, '');
    form.setFieldsValue({ description: value });
  };

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Form
          form={form}
          initialValues={{}}
          onFinish={onFinish}
          disabled={isloading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Location Information"
            style={{
              padding: '8px 0px'
            }}
          />

          <div className={'grid grid-cols-1 gap-2 mb-3 sm:grid-cols-2 lg:grid-cols-3 gap-5 mb-5'}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please add email!' }]}>
              <Input type="email" />
            </Form.Item>

            <Form.Item
              label="Phone"
              name="phone"
              rules={[{ required: false, message: 'Please add phone number!' }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Fax"
              name="fax"
              rules={[{ required: false, message: 'Please add fax!' }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Website"
              name="website"
              rules={[{ required: false, message: 'Please add website!' }]}>
              <Input type="text" />
            </Form.Item>

            <div className="flex items-center">
              <Form.Item name="isActive" valuePropName="checked">
                <Checkbox className="w-100 text-white">Is Active</Checkbox>
              </Form.Item>
            </div>
          </div>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: false, message: 'Please add description!' }]}>
            <Input.TextArea onChange={checkForRegEx} />
          </Form.Item>

          <Divider />

          <PageHeader
            title="Locations Information"
            style={{
              padding: '8px 0px'
            }}
          />

          <div className={'grid grid-cols-1 gap-2 mb-3 sm:grid-cols-2 lg:grid-cols-3 gap-5 mb-5'}>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: 'Please add address!' }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="City"
              name="city"
              rules={[{ required: true, message: 'Please add city!' }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="State/Province"
              name="state"
              rules={[{ required: true, message: 'Please add state/province!' }]}>
              <Input type="text" />
            </Form.Item>

            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: 'Please add country!' }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Zip"
              name="zip"
              rules={[{ required: true, message: 'Please add zip!' }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Code"
              name="code"
              rules={[{ required: true, message: 'Please add code with three characters!' }]}>
              <Input
                maxLength={3}
                minLength={3}
                onInput={(e) => {
                  e.currentTarget.value = e.currentTarget.value.toUpperCase();
                }}
              />
            </Form.Item>

            <Form.Item
              label="Location Type"
              name="type"
              rules={[{ required: true, message: 'Please add Location Type!' }]}>
              <Select allowClear>
                <Select.Option value={'DC'}>DC</Select.Option>
                <Select.Option value={'LCC'}>LCC</Select.Option>
                <Select.Option value={'Outlet'}>Outlet</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="shipping"
              label="Shipping cost"
              rules={[
                {
                  required: true,
                  message: 'Please Input Shippping cost!'
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <RouteSearch required={false} isClear={true} />
            {/* <Form.Item
              name="totalZeroShipping"
              label="Total for zero cost"
              rules={[
                {
                  required: true,
                  message: 'Please Input total!'
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item> */}
          </div>

          <div className="my-5">
            <LoadScript googleMapsApiKey={GOOGLE_MAPS_KEY}>
              <GoogleMap
                center={marker}
                zoom={15}
                options={{
                  fullscreenControl: false,
                  streetViewControl: false,
                  mapTypeControl: false
                }}
                onClick={onClick}
                mapContainerStyle={containerStyle}>
                <MarkerF position={marker} />
              </GoogleMap>
            </LoadScript>
          </div>

          <div className="flex justify-end mt-5">
            <div className="flex justify-end">
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={buttonloading}>
                  Submit
                </Button>
              </Form.Item>
              <Button
                type="default"
                className="ml-5"
                htmlType="button"
                onClick={() => navigate('/locations')}>
                Cancel
              </Button>
            </div>
          </div>
        </Form>
        <Divider />
        {checkAccess('READ_CHANNEL') && <ReuseChannel slug={`location_${id}`} />}
      </AppContent>
    </div>
  );
};

export default Update;
