import { AxiosResponse } from 'axios';
import { IAgentCreateProps, IAgentUpdateProps, IUserAgents } from './types';
import http from '@/utils/http.utils';

export const create_agents_mutation = async (data: IAgentCreateProps) => {
  const response: AxiosResponse<IUserAgents, any> = await http.store('user-agent/', data);
  return response.data;
};

export const update_agents_mutation = async (values: IAgentUpdateProps) => {
  const { id, ...data } = values;
  const response: AxiosResponse<IUserAgents, any> = await http.update('user-agent/' + id, data);
  return response.data;
};
