import moment from 'moment';
import { DatePicker, Form } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { find_date_preference } from '@/store/localstorage/preferences';

interface Props {
  singleDate: boolean;
  onChange: (date: string, type: 'english' | 'nepali') => void;
}

function AccountDatePicker({ singleDate, onChange }: Props) {
  const [searchParams] = useSearchParams();
  const financialStartDate = searchParams.get('fsd');
  const financialEndDate = searchParams.get('fed');

  const nepaliType = find_date_preference();

  // Disable dates outside the financial year range
  const onDisabledDate = (current: moment.Moment | null) => {
    const hasStartAndEndDates = financialStartDate && financialEndDate;

    if (!hasStartAndEndDates) return false;

    const startDate = moment(financialStartDate).startOf('day');
    const isEndLater = moment(financialEndDate).isAfter(moment());
    const endDate = isEndLater ? moment().endOf('day') : moment(financialEndDate).endOf('day');

    // Disable dates outside the financial year range
    return current ? current.isBefore(startDate) || current.isAfter(endDate) : false;
  };

  return (
    <Form.Item label={'Select Date'} name={['dateSingle']} hidden={!singleDate || nepaliType}>
      <DatePicker
        onChange={(_, date) => onChange(date, 'english')}
        format={'YYYY-MM-DD'}
        className="w-full"
        disabledDate={onDisabledDate}
        allowClear={false}
      />
    </Form.Item>
  );
}

export default AccountDatePicker;
