import { message } from 'antd';
import { AxiosError } from 'axios';

function getErrorMessage(error: unknown, showError = false) {
  if (error instanceof AxiosError) {
    const messages = (error.response?.data?.message ?? error.message) as string | string[];
    const singleMessage = Array.isArray(messages) ? messages[0] : messages;
    if (showError) message.error(singleMessage);
    return singleMessage;
  }

  if (error instanceof Error) {
    if (showError) message.error(error.message);
    return error.message;
  }

  // Check if the error is an AxiosError

  if (typeof error === 'string') {
    if (showError) message.error(error);
    return error;
  }

  const messages = 'An error occurred. Please try again.';
  if (showError) message.error(messages);

  return messages;
}

export default getErrorMessage;
