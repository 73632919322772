import { CheckCircleFilled } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, Checkbox, Form, Input, InputNumber, message, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import { create_invoice_schemes_mutation } from '../../../../../services/settings/mutations';

interface ICreateInvoiceScheme {
  handleOk: () => void;
  isModalOpen: boolean;
  handleCancel: () => void;
  modalData?: any;
}

interface Ivalue {
  format: string;
  isDefault: boolean;
  name: string;
  numberOfDigits: string;
  prefix: string;
  startFrom: number;
}

const CreateInvoiceScheme = ({
  handleOk,
  isModalOpen,
  handleCancel,
  modalData
}: ICreateInvoiceScheme) => {
  const [activeFormat, setActiveFormat] = useState('');
  const [form] = Form.useForm();
  // console.log('Modaldata--->', modalData);
  const createInvoiceSchemeMutation = useMutation(create_invoice_schemes_mutation, {
    onSuccess: () => {
      message.success('Invoice Scheme created successfully');
      handleCancel();
    }
  });

  const onFinish = (value: Ivalue) => {
    createInvoiceSchemeMutation.mutate(value);
  };

  const changeActiveFormatTo2022XXXX = () => {
    setActiveFormat('2022-XXXX');
    form.setFieldsValue({
      prefix: '2022-',
      format: '2022-XXXX'
    });
  };

  const changeActiveFormatToXXXX = () => {
    setActiveFormat('XXXX');
    form.setFieldsValue({
      prefix: '',
      format: 'XXXX'
    });
  };

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue(modalData);
    } else {
      form.resetFields();
    }
  }, [modalData]);

  return (
    <>
      <Modal
        title="Add new invoice scheme"
        visible={isModalOpen}
        footer={null}
        onOk={handleOk}
        destroyOnClose
        onCancel={handleCancel}>
        <div>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-5">
            <div
              className="p-4 bg-slate-400 text-black-500 border border-slate-400 cursor-pointer"
              onClick={() => changeActiveFormatToXXXX()}>
              FORMAT:
              <div className="flex justify-between">
                <span>XXXX</span>

                <span>{activeFormat === 'XXXX' && <CheckCircleFilled />}</span>
              </div>
            </div>

            <div
              className="p-4 bg-slate-400 text-black-500 border border-slate-400 cursor-pointer"
              onClick={() => changeActiveFormatTo2022XXXX()}>
              FORMAT:
              <div className="flex justify-between">
                <span>2022-XXXX</span>

                <span>{activeFormat === '2022-XXXX' && <CheckCircleFilled />}</span>
              </div>
            </div>

            <div>
              <p className="font-bold">Preview:</p>
              {activeFormat === 'XXXX' && '#0000'}
              {activeFormat === '2022-XXXX' && '#2022-XXXX'}
            </div>
          </div>

          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            validateTrigger={'onChange'}
            autoComplete="off">
            <div className="mt-5">
              <Form.Item
                className=" mt-5 w-full"
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input name!' }]}>
                <Input />
              </Form.Item>
            </div>

            <Form.Item label="format" name="format" hidden>
              <Input />
            </Form.Item>

            <div className="grid grid-cols-2 gap-5">
              <Form.Item
                className="mb-0"
                label="Prefix:"
                name="prefix"
                rules={[{ required: false, message: 'Please add prefix!' }]}>
                <Input disabled={activeFormat === '2022-XXXX'} placeholder="XXXX" />
              </Form.Item>

              <Form.Item
                className="mb-0"
                label="Start From"
                name="startFrom"
                rules={[{ required: true, message: 'Please add start!' }]}>
                <InputNumber controls={false} />
              </Form.Item>

              <Form.Item
                className="mb-0"
                label="Number of digits:"
                name="numberOfDigits"
                rules={[{ required: false }]}>
                <Select defaultValue={'4'} allowClear>
                  <Select.Option value="4">4</Select.Option>
                  <Select.Option value="5">5</Select.Option>
                  <Select.Option value="6">6</Select.Option>
                  <Select.Option value="7">7</Select.Option>
                  <Select.Option value="8">8</Select.Option>
                  <Select.Option value="9">9</Select.Option>
                  <Select.Option value="10">10</Select.Option>
                  <Select.Option value="11">11</Select.Option>
                </Select>
              </Form.Item>

              <div className="flex items-center">
                <Form.Item className="mb-0" name="isDefault" valuePropName="checked">
                  <Checkbox className="w-100 text-white">Set as Default</Checkbox>
                </Form.Item>
              </div>
            </div>

            <div className="flex justify-end mt-5">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
              <Button
                type="default"
                className="ml-5"
                htmlType="button"
                onClick={() => handleCancel()}>
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default CreateInvoiceScheme;
