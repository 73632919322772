import { Button, Divider, Form, FormInstance, message, PageHeader, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { IUSerlocation } from '../../../services/routes/types';
import {
  get_customer_list,
  get_user_details,
  get_user_list,
  get_vendor_list,
  get_vendor_list_ids
} from '../../../services/users/queries';
import VendorsDB from '../../../store/localstorage/VendorDB';
interface ITaxtype {
  taxNumber: string;
  taxType: string;
  id?: string;
  userId?: number;
}
interface UsersSearchProps {
  formData?: {
    formName: any;
  };
  onSelect?: (value: number) => any;
  withoutForm?: boolean;
  required?: boolean;
  all?: boolean;
  initial?: any;
  applyFilter?: boolean;
  isMultiple?: boolean;
}
const { Option } = Select;
export const VendorSearch: React.FC<UsersSearchProps> = ({
  formData = { formName: ['vendorId'] },
  onSelect,
  withoutForm,
  required = false,
  all = true,
  initial,
  applyFilter = false,
  isMultiple
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);
  const [initalSearch, setinitialSearch] = useState<boolean>(true);
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);
  useEffect(() => {
    if (initalSearch && initial) {
      searchVendorIndexDB(`${initial}`);
    } else {
      searchVendorIndexDB(debouncedSearchValue);
    }
  }, [debouncedSearchValue]);
  const [userSearch, setUserSearch] = useState<any>([]);

  const fetchInitalUserFromServer = async (id: any) => {
    const response = await get_vendor_list_ids([id]);
    if (response.data) {
      VendorsDB.addVendors(response.data.results);
      setinitialSearch(false);
      searchVendorIndexDB(`${id}`);
    }
  };

  const searchVendorIndexDB = async (value: string) => {
    try {
      const response = await VendorsDB.searchVendor(value);
      if (!response || response.length == 0) {
        message.info('Cannot find any vendor with that value in cache, searching from server...');
        if (initial && initalSearch) {
          fetchInitalUserFromServer(initial);
        } else {
          searchUser(value);
        }
      } else {
        setUserSearch(response);
        setIsCacheResponse(true);
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  const searchUser = async (value: any) => {
    let response;
    try {
      response = await get_vendor_list(0, 10, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any vendor with that name!');
      setUserSearch([]);
      setIsMore(false);
    } else {
      setUserSearch(response.data.results);
      VendorsDB.addVendors(response.data.results);
      if (response.data.results.length < 10) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
    }
    setIsCacheResponse(false);
    setSkip(10);
  };

  const searchMoreUser = async (value: any) => {
    let response;
    try {
      response = await get_vendor_list(skip, 10, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.info('Cannot find more vendor with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);
      setUserSearch([...userSearch, ...response.data.results]);
      VendorsDB.addVendors(response.data.results);
      if (response.data.results.length < 10) {
        setIsMore(false);
      }
    }
  };

  const options = applyFilter
    ? userSearch
        .filter((curr: any) => !curr?.user?.disabled)
        .map((value: any) => (
          <Option key={value.user.id} value={value.id}>
            {value.name ? value.name : value.user.name},{' '}
            {value.phone ? value.phone : value?.user?.phone}
          </Option>
        ))
    : userSearch.map((value: any) => (
        <Option key={value.user.id} value={value.id}>
          {value.name ? value.name : value.user.name},{' '}
          {value.phone ? value.phone : value?.user?.phone}
        </Option>
      ));
  return (
    <>
      {withoutForm ? (
        <>
          <Select
            showSearch
            placeholder={'Search Vendor'}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onChange={onSelect}
            mode={isMultiple ? 'multiple' : undefined}
            dropdownMatchSelectWidth={false}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                {isCacheResponse ? (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {/* <div
                      style={{
                        color: 'green',
                        width: '100%',
                        textAlign: 'center'
                      }}>{`Cache found ${userSearch.length} data`}</div> */}
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchUser(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Pull More & Sync'}
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {isMore ? (
                      <Button
                        type="text"
                        style={{
                          color: 'blue',
                          width: '100%'
                        }}
                        onClick={() => {
                          searchMoreUser(searchValue);
                        }}>
                        {/* {'Sync & Search on Server'} */}
                        {'Get More...'}
                      </Button>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center'
                        }}>
                        No more data...
                      </div>
                    )}
                  </div>
                )}
              </>
            )}>
            {/* <Option value="">All</Option> */}
            {options}
          </Select>
        </>
      ) : (
        <Form.Item
          name={formData.formName}
          label="Supplier"
          rules={[
            {
              required: required ? true : false,
              message: 'Please choose Supplier!'
            }
          ]}>
          <Select
            showSearch
            mode={isMultiple ? 'multiple' : undefined}
            placeholder={'Search Supplier'}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onChange={onSelect}
            dropdownMatchSelectWidth={false}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                {isCacheResponse ? (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {/* <div
                      style={{
                        color: 'green',
                        width: '100%',
                        textAlign: 'center'
                      }}>{`Cache found ${userSearch.length} data`}</div> */}
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchUser(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Pull More & Sync'}
                    </Button>
                  </div>
                ) : (
                  <>
                    {/* <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                      <div
                        style={{
                          color: 'green',
                          width: '100%',
                          textAlign: 'center'
                        }}>{`Server found ${userSearch.length} data`}</div>
                    </div> */}
                  </>
                )}
              </>
            )}>
            {all && <Option value="">All</Option>}
            {options}
          </Select>
        </Form.Item>
      )}
    </>
  );
};
