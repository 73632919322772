import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { IPurchaseAgentBillCancel } from '@/services/purchases/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { TableProps, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';

interface Props {
  isLoading: boolean;
  pagination: { page: number; size: number };
  onPagination: (page: number, size: number, isSize?: boolean) => void;
  data: { total: number; results: IPurchaseAgentBillCancel[] };
}

function Table({ data, isLoading, pagination, onPagination }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IPurchaseAgentBillCancel>>({});

  const handleChange: TableProps<IPurchaseAgentBillCancel>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IPurchaseAgentBillCancel>);
  };

  const columns: ColumnsType<IPurchaseAgentBillCancel> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 2,
      render: (_, __, index) => index + 1
    },
    {
      title: 'Bill Number',
      dataIndex: 'billNumber',
      key: 'billNumber',
      width: 3,
      sortOrder: sortedInfo.columnKey === 'billNumber' ? sortedInfo.order : null,
      sorter: (a, b) => a.billNumber - b.billNumber
    },

    {
      title: 'Media',
      dataIndex: 'mediaURL',
      key: 'mediaURL',
      width: 2,
      render: (url: string) => {
        if (!url) return '';
        return (
          <a href={url} target="new" rel="noreferrer">
            View
          </a>
        );
      }
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 5,
      render: (text: string) => convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt)
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      width: 15,
      render: (text: string) => {
        return (
          <Tooltip title={text} className="text-ellipsis">
            {text}
          </Tooltip>
        );
      }
    }
  ];

  return (
    <CustomizeTable
      columns={columns}
      data={data.results}
      usersLoading={isLoading}
      toSort={handleChange}
      notshowPagination={true}
      customScroll={{ x: 1350, y: '75vh' }}
      paginationDatas={{
        page: pagination.page,
        total: data.total,
        size: pagination.size,
        onPagination
      }}
    />
  );
}

export default Table;
