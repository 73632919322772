import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Input, message, PageHeader, Typography, Spin } from 'antd';
import Search from 'antd/lib/input/Search';
import { useState, useEffect, useRef } from 'react';
import AppContent from '../../../components/Common/Content/Content';
import { send_message_mutation } from '../../../services/channels/mutations';
import { get_channel_id, get_channel_id_skipfrom_skipto } from '../../../services/channels/queries';
import { ICreateMessage } from '../../../services/channels/types';
import { get_user_details } from '../../../services/users/queries';
import UsersDB from '../../../store/localstorage/UsersDB';
import { getUserData } from '../../../utils/auth.utils';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import GoBackButton from '../../../components/Common/GoBackButton/GoBackButton';
import { useNavigate } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import { get_all_notification } from '../../../services/notifications/queries';
import { INotifications } from '../../../services/notifications/types';
import { getMessaging, isSupported, onMessage } from 'firebase/messaging';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Text } = Typography;
interface ChatBox {
  id: number;
  name: string;
  message: string;
  date: string;
  showImage?: boolean;
}
interface ReusableRoomProps {
  id: number;
  channelName: string;
  fromChatList?: boolean;
}
const ReusableRoom: React.FC<ReusableRoomProps> = ({ id, channelName, fromChatList }) => {
  const [isloading, setIsloading] = useState(false);
  const [activeUser, setActiveUser] = useState('0');
  const [inputValue, setInputValue] = useState('');
  const scrollElement = useRef<HTMLDivElement>(null);
  const [allmessageList, setAllmessageList] = useState<any>([]);
  const [skipFrom, setSkipFrom] = useState<number>(0);
  const [skipTo, setSkipTo] = useState<number>(0);
  const [finisheMessage, setFinishMessage] = useState<boolean>(false);
  const [loadmessagespinner, setLoadmessagespinner] = useState<boolean>(false);
  const loggedInUser = getUserData();

  useQuery(['notification'], async () => {
    try {
      const messagingSupport = await isSupported();
      if (!messagingSupport) {
        throw new Error('Your browser does not support messaging from room');
      }
      const messagingNew = getMessaging();
      onMessage(messagingNew, (payload: any) => {
        // console.log('payload', payload);
        payload.data.event = JSON.parse(payload.data.event);
        // console.log('this is message', payload.data.event);
        // const info = linkandmessage(payload.data.event.type);
        // console.log('Id', id);
        // console.log('Payload id', payload.data.event.id);
        // console.log('loggedin user id', loggedInUser.id);
        if (
          payload.data.event.type === 'MESSAGE_RECEIVED' &&
          payload.data.event.channelId === id &&
          payload.data.event.userId !== loggedInUser.id
        ) {
          // console.log('this runs');
          setAllmessageList((prev: any) => [
            ...prev,
            {
              channelId: payload.data.event.channelId,
              content: payload.data.event.content,
              createdAt: payload.data.event.createdAt,
              id: payload.data.event.createdAt,
              updatedAt: payload.data.event.updatedAt,
              userId: payload.data.event.userId
            }
          ]);
        }
      });
    } catch (e) {
      console.log(e);
    }
  });

  const { data: messageList, refetch } = useQuery(['message-list'], async () => {
    const response = await get_channel_id(id);
    for (let index = 0; index < response.data.length; index++) {
      let user: any = await UsersDB.getUser(response.data[index].userId);
      if (!user) {
        user = (await get_user_details(response.data[index].userId)).user;
        await UsersDB.addUsers([user]);
      }
      response.data[index].userName = user.name;
    }
    if (response.data.length < 20) {
      setFinishMessage(true);
      setLoadmessagespinner(false);
    } else {
      setFinishMessage(false);
      setLoadmessagespinner(false);
      setSkipFrom(response.data[response.data?.length - 1].id);
      setSkipTo(response.data[0].id);
    }
    response.data.sort(
      (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
    // console.log('Response-->', response);
    // console.log('Response data', response.data);
    setAllmessageList(response.data);
    setIsloading(false);
    return response;
  });
  const createMessageMutation = useMutation(send_message_mutation);
  useEffect(() => {
    setActiveUser(getUserData().id);
  }, []);
  useEffect(() => {
    scrollToBottom();
  }, [messageList]);
  useEffect(() => {
    refetch();
  }, [id]);

  const scrollToBottom = () => {
    if (scrollElement.current) scrollElement.current.scrollTop = scrollElement.current.scrollHeight;
  };

  const breadcrumbItems = [
    {
      label: 'Ticket',
      link: '/channel'
    },
    {
      label: 'Room'
    }
  ];

  const selfMessage = (details: ChatBox) => (
    <div className="flex flex-col mb-4" key={details.id} style={{ maxWidth: '100%' }}>
      <div className="flex flex-row-reverse" style={{}}>
        {details?.showImage ? (
          <img
            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
            style={{ height: '40px', width: '40px', borderRadius: '50%' }}
          />
        ) : (
          <>
            <div style={{ height: '40px', width: '40px', borderRadius: '50%' }}></div>
          </>
        )}

        <div
          className="mr-2 py-3 px-4 bg-blue-400 rounded-bl-0 rounded-tl-0 rounded-br-0 text-black"
          style={{ backgroundColor: '#e5f5fb', overflowWrap: 'break-word', maxWidth: '60%' }}>
          {details.message}
          <div>
            <Text className="flex justify-end">
              {/* {new Date(details.date).toLocaleString('en-GB', { hour: 'numeric', hour12: true })} */}
              {moment(details.date).local().format(DEFAULT_DATE_FORMAT)}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
  const otherMessage = (details: ChatBox) => (
    <div className="flex flex-col mb-4" key={details.id} style={{ maxWidth: '100%' }}>
      <div className="flex">
        {details?.showImage ? (
          <img
            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
            style={{ height: '40px', width: '40px', borderRadius: '50%' }}
          />
        ) : (
          <>
            <div style={{ height: '40px', width: '40px', borderRadius: '50%' }}></div>
          </>
        )}

        <div
          className="ml-2 py-3 px-4 bg-blue-400 rounded-bl-0 rounded-tr-0 rounded-br-0 text-black"
          style={{ backgroundColor: '#e5f5fb', overflowWrap: 'break-word', maxWidth: '60%' }}>
          {details.message}
          <div>
            <Text className="flex">
              {/* {new Date(details.date).toLocaleString('en-GB', { hour: 'numeric', hour12: true })} */}
              {moment(details.date).local().format(DEFAULT_DATE_FORMAT)}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );

  const SendMessage = async (value: any) => {
    setIsloading(true);
    const messageValue: ICreateMessage = {
      channelId: id,
      content: ''
    };
    if (typeof value == 'string' && value != '') {
      messageValue.content = value;
    } else if (value.target.value != '') {
      messageValue.content = value.target.value;
    }
    if (messageValue.content != '')
      await createMessageMutation.mutateAsync(messageValue, {
        onSuccess: async ({ data }: { data: any }) => {
          message.success('message sent successfully');
          refetch();
          setInputValue('');
        },
        onError: (e: any) => {
          message.error(`${e.response.data.message}`, 5);
        }
      });
    setIsloading(false);
  };

  const loadPreviousMessage = async () => {
    setLoadmessagespinner(true);
    const x = scrollElement.current?.scrollHeight;
    const response = await get_channel_id_skipfrom_skipto(id, skipFrom, skipTo);
    if (response.data.length < 20) {
      setFinishMessage(true);
    } else {
      setSkipFrom(response.data[response.data.length - 1].id);
    }
    // console.log('New id', response.data[response.data.length - 1].id);
    response.data.sort(
      (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
    setAllmessageList([...response.data, ...allmessageList]);
    const y = scrollElement.current?.scrollHeight;
    if (scrollElement.current && x && y) {
      scrollElement.current.scrollTop = x - y;
    }
    // console.log('Response from load previous-->', response);
    setLoadmessagespinner(false);
  };

  return (
    <Spin spinning={isloading}>
      {fromChatList ? (
        <>
          <div
            className="mx-auto rounded-lg flex flex-col justify-between"
            style={{ height: '70vh', maxWidth: '100%' }}>
            {allmessageList && (
              <div
                ref={scrollElement}
                className="flex flex-col mt-5 p-5 overflow-y-auto"
                style={{ maxWidth: '100%' }}>
                {finisheMessage ? (
                  <></>
                ) : loadmessagespinner ? (
                  <Spin indicator={antIcon} />
                ) : (
                  <Button type="link" onClick={() => loadPreviousMessage()}>
                    load previous messages...
                  </Button>
                )}
                {allmessageList?.map((value: any, ind: number) => {
                  if (ind == 0) {
                    if (activeUser == value.userId) {
                      return selfMessage({
                        id: value.id,
                        name: value.userName,
                        message: value.content,
                        date: value.createdAt,
                        showImage: true
                      });
                    } else {
                      return otherMessage({
                        id: value.id,
                        name: value.userName,
                        message: value.content,
                        date: value.createdAt,
                        showImage: true
                      });
                    }
                  } else {
                    if (allmessageList[ind].userId == allmessageList[ind - 1].userId) {
                      if (activeUser == value.userId) {
                        return selfMessage({
                          id: value.id,
                          name: value.userName,
                          message: value.content,
                          date: value.createdAt
                        });
                      } else {
                        return otherMessage({
                          id: value.id,
                          name: value.userName,
                          message: value.content,
                          date: value.createdAt
                        });
                      }
                    } else {
                      if (activeUser == value.userId) {
                        return selfMessage({
                          id: value.id,
                          name: value.userName,
                          message: value.content,
                          date: value.createdAt,
                          showImage: true
                        });
                      } else {
                        return otherMessage({
                          id: value.id,
                          name: value.userName,
                          message: value.content,
                          date: value.createdAt,
                          showImage: true
                        });
                      }
                    }
                  }
                })}
              </div>
            )}
            <div
              className="px-3 flex flex-col justify-center items-center"
              style={{ borderTop: '2px solid #EEF2F0' }}>
              <Search
                placeholder="Type your message here..."
                loading={isloading}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onPressEnter={SendMessage}
                onSearch={SendMessage}
                bordered={false}
                style={{ marginTop: '15px', color: 'gray' }}
                // autoSize={{ minRows: 2, maxRows: 6 }}
                enterButton={
                  <button
                    style={{
                      backgroundColor: 'transparent',
                      color: 'black',
                      padding: '8px 12px',
                      borderRadius: '10px',
                      border: '2px solid gray',
                      cursor: 'pointer'
                    }}>
                    Send
                  </button>
                }
              />
              {/* <Input type="text" placeholder="type your message here..." /> */}
            </div>
          </div>
        </>
      ) : (
        <AppContent breadcrumbItems={breadcrumbItems}>
          <PageHeader
            title={channelName}
            style={{
              padding: '8px 0px'
            }}
          />
          <div
            className="mx-auto rounded-lg flex flex-col justify-between"
            style={{ height: '70vh' }}>
            {allmessageList && (
              <div ref={scrollElement} className="flex flex-col mt-5 p-5 overflow-y-auto">
                {finisheMessage ? (
                  <></>
                ) : loadmessagespinner ? (
                  <Spin indicator={antIcon} />
                ) : (
                  <Button type="link" onClick={() => loadPreviousMessage()}>
                    load previous messages...
                  </Button>
                )}
                {allmessageList?.map((value: any, ind: number) => {
                  if (ind == 0) {
                    if (activeUser == value.userId) {
                      return selfMessage({
                        id: value.id,
                        name: value.userName,
                        message: value.content,
                        date: value.createdAt,
                        showImage: true
                      });
                    } else {
                      return otherMessage({
                        id: value.id,
                        name: value.userName,
                        message: value.content,
                        date: value.createdAt,
                        showImage: true
                      });
                    }
                  } else {
                    if (allmessageList[ind].userId == allmessageList[ind - 1].userId) {
                      if (activeUser == value.userId) {
                        return selfMessage({
                          id: value.id,
                          name: value.userName,
                          message: value.content,
                          date: value.createdAt
                          // showImage: true
                        });
                      } else {
                        return otherMessage({
                          id: value.id,
                          name: value.userName,
                          message: value.content,
                          date: value.createdAt
                          // showImage: true
                        });
                      }
                    } else {
                      if (activeUser == value.userId) {
                        return selfMessage({
                          id: value.id,
                          name: value.userName,
                          message: value.content,
                          date: value.createdAt,
                          showImage: true
                        });
                      } else {
                        return otherMessage({
                          id: value.id,
                          name: value.userName,
                          message: value.content,
                          date: value.createdAt,
                          showImage: true
                        });
                      }
                    }
                  }
                })}
              </div>
            )}
            {/* <div className="px-3 mt-5 mb-5 flex flex-col justify-end"> */}
            <Search
              placeholder="Type your message here....."
              loading={isloading}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onPressEnter={SendMessage}
              onSearch={SendMessage}
              style={{ marginTop: '15px', color: 'gray' }}
              enterButton={
                <button
                  style={{
                    backgroundColor: 'transparent',
                    color: 'black',
                    padding: '5px 8px',
                    borderRadius: '10px',
                    border: '2px solid #EEF2F0',
                    marginLeft: '2px'
                  }}>
                  Send
                </button>
              }
            />
            {/* <input onChange={(e)=>setInputValue(e.target.value)} value={inputValue}/> */}
            {/* <Input type="text" placeholder="type your message here..." /> */}
            {/* </div> */}
          </div>
        </AppContent>
      )}
    </Spin>
  );
};

export default ReusableRoom;
