import { Divider, Image, PageHeader, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { get_image } from '../../../components/Common/FetchImage/FetchImage';
import { API_URL } from '../../../constants/config';
import { get_invoices_list } from '../../../services/settings/queries';
import freshktmfulllogo from '../../../layouts/logo/freshktmfulllogo.png';
import type { ColumnsType } from 'antd/es/table';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import CustomTable from './CustomTable';
import CustomBarcode from '../../../pages/barcode';
import { nepaliNumberFormatter } from '../../../utils/numberFormatter';
import {
  convertLocalToUTCString,
  convertUTCStringtoLocalString
} from '../../../utils/convertToUTC';
import { clearLocalStorage } from '../../../utils/storage.utils';
import { getUserData } from '../../../utils/auth.utils';

export const AdjustmentInvoice = () => {
  // const { state }: any = useLocation();
  const loggedInUser = getUserData();
  const state = JSON.parse(localStorage.getItem('referrer') as string);
  const {
    customerDetails,
    vendorDetails,
    locationDetails,
    purchaseDetails,
    lines,
    invoiceLayouts,
    adjustmentDetails,
    transferData
  } = state;
  console.log('state', state);
  if (invoiceLayouts) {
    if ('content' in invoiceLayouts) {
      if (typeof invoiceLayouts.content == 'string')
        invoiceLayouts.content = JSON.parse(invoiceLayouts.content);
    } else {
      invoiceLayouts.content = '';
    }
  }

  //   useEffect(() => {
  //     getInfo();
  //   }, []);
  //   const getInfo = async () => {
  //     const response = await get_invoices_list();
  //     if ('invoiceLogo' in invoiceLayouts.content) {
  //       const imageData = await get_image(
  //         `${API_URL}storage/media/${JSON.parse(response.data[0].content).invoiceLogo}`
  //       );
  //       setimgSrc(imageData);
  //     } else {
  //       window.print();
  //       window.close();
  //       localStorage.removeItem('referrer');
  //     }
  //   };

  const columns: any = [
    {
      label: `${
        invoiceLayouts?.content?.productLabel ? invoiceLayouts.content.productLabel : 'ProductName'
      }`,
      dataIndex: 'productName',
      // width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.unitPriceLabel
          ? invoiceLayouts.content.unitPriceLabel
          : 'UnitPrice'
      }`,
      dataIndex: 'unitPrice',
      // width: 100,
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.quantityLabel ? invoiceLayouts.content.quantityLabel : 'Quantity'
      }`,
      dataIndex: 'quantity',
      // width: 100,
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: 'Misc',
      dataIndex: 'misc',
      // width: 100,
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.discountLabel ? invoiceLayouts.content.discountLabel : 'Discount'
      }`,
      dataIndex: 'discount',
      // width: 100,
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.subTotalLabel
          ? invoiceLayouts.content.subTotalLabel
          : 'Total Amount'
      }`,
      dataIndex: 'totalAmount',
      // width: 100,
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    }
  ];

  useEffect(() => {
    if (transferData || adjustmentDetails) {
      window.print();
      window.onafterprint = (event) => {
        window.close();
        localStorage.removeItem('referrer');
      };
    }
  }, []);

  return (
    <div>
      <div className="flex justify-between font-bold text-xl mt-4">
        Stock Adjustment Details (
        {invoiceLayouts?.content?.referenceNumberLabel
          ? invoiceLayouts?.content?.referenceNumberLabel
          : 'Reference No'}{' '}
        : {adjustmentDetails.referenceNumber})
      </div>
      <hr />
      <div className="grid grid-cols-3 gap-5 mb-5">
        {adjustmentDetails && (
          <div>
            <PageHeader
              subTitle="Business"
              style={{
                padding: '8px 0px',
                fontWeight: 'bold'
              }}
            />
            {locationDetails ? (
              <>
                <div>{locationDetails.name}</div>
                <div>
                  {`${locationDetails.zip}, ${locationDetails.address}, ${locationDetails.city}, ${locationDetails.country}`}
                </div>
                <div>{`${locationDetails?.phone}, ${locationDetails.email}`}</div>
              </>
            ) : (
              <></>
            )}
          </div>
        )}
        <div>
          <PageHeader
            subTitle="Details"
            style={{
              padding: '8px 0px',
              fontWeight: 'bold'
            }}
          />
          {adjustmentDetails ? (
            <>
              <div>
                {invoiceLayouts?.content?.referenceNumberLabel
                  ? invoiceLayouts?.content?.referenceNumberLabel
                  : 'Reference No.'}{' '}
                : {adjustmentDetails.referenceNumber}
              </div>
              <div>
                Created At:{' '}
                {convertUTCStringtoLocalString(adjustmentDetails.createdAt, 'YYYY MM-DD')}
              </div>
              <div>
                {invoiceLayouts?.content?.reasonLabel
                  ? invoiceLayouts?.content.reasonLabel
                  : 'Reason'}{' '}
                : {adjustmentDetails.reasonName}
              </div>
              <div>
                {invoiceLayouts?.content?.adjustmentTypeLabel
                  ? invoiceLayouts?.content.adjustmentTypeLabel
                  : 'Adjustment Type'}{' '}
                : {adjustmentDetails.reasonName}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <CustomTable columns={columns} datas={lines ? lines : []} />
      <div className="flex justify-end mt-5">
        <div>
          <div className="flex justify-between">
            <span>
              {' '}
              {invoiceLayouts?.content?.totalLabel
                ? invoiceLayouts?.content.totalLabel
                : 'Total Amount'}{' '}
              :
            </span>
            <span>{nepaliNumberFormatter(adjustmentDetails.totalAmount)}</span>
          </div>
          <div className="flex justify-between mt-2">
            <span>
              {invoiceLayouts?.content?.totalAmountRecoveredLabel
                ? invoiceLayouts?.content.totalAmountRecoveredLabel
                : 'Total Amount Recovered'}{' '}
              :
            </span>
            <span> {nepaliNumberFormatter(0)}</span>
          </div>
        </div>
      </div>
      {invoiceLayouts?.content?.footerText ? (
        <div dangerouslySetInnerHTML={{ __html: invoiceLayouts?.content?.footerText }}></div>
      ) : (
        <></>
      )}
      {invoiceLayouts?.content?.showBarcode && (
        <div className="flex items-center justify-center">
          <CustomBarcode value={adjustmentDetails.referenceNumber} height={50} />
        </div>
      )}
      <div className="flex justify-end">
        <div>
          Printed By:{' '}
          <span className="capitalize">{loggedInUser?.name ? loggedInUser.name : ''}</span>
        </div>
      </div>
    </div>
  );
};
