import { Button, Form, Input, message, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';

import PaymentModal from '@/components/Common/PaymentModal';
import TableFilter from '@/components/FliterTable';
import { get_location_list } from '@/services/locations/queries';
import { get_sell_list } from '@/services/sell/queries';
import { get_customer_list_ids } from '@/services/users/queries';
import CustomersDB from '@/store/localstorage/CustomerDB';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import CopyButton from '@/components/Common/CopyButton';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import GenericTable from '@/components/Common/CustomizeTable';

const breadcrumbItems = [{ label: 'Add Payment', link: '/pay/add' }];
const colorCode: any = { full: 'green', pending: 'red', partial: 'purple' };

export const AddPayment: React.FC<any> = () => {
  const [isloading, setIsloading] = useState(false);
  const [paymentData, setPaymentData] = useState<any[]>([]);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedSale, setSelectedSale] = useState<any>();
  const [form] = Form.useForm();
  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
  };
  const getInfo = async (filter = '') => {
    setIsloading(true);
    try {
      // console.log(filter);
      const response = await get_sell_list(filter);
      setPaymentData(response.data.results);
      if (response?.data.results) {
        // for (let index = 0; index < response.data.results.length; index++) {
        //   let user: any = await UsersDB.getUser(response.data.results[index].address.userId);
        //   if (!user) {
        //     user = (await get_user_details(response.data.results[index].address.userId)).user;
        //     await UsersDB.addUsers([user]);
        //   }

        //   response.data.results[index].userName = user.name;
        //   let locD: any = await LocationsDB.getLocation(response.data.results[index].locationId);
        //   if (!locD) {
        //     locD = await get_location_details(response.data.results[index].locationId);
        //     await LocationsDB.addLocations([locD]);
        //   }
        //   response.data.results[index].locationName = locD.name;
        // }
        const searchUsers: any = {};
        // let ctotalamount = 0,
        //   creturnamount = 0;
        for (let index = 0; index < response.data.results.length; index++) {
          const user: any = await CustomersDB.getCustomer(response.data.results[index].customerId);
          const currentData = response.data.results[index];
          currentData.netSell = currentData.totalAmount - currentData.amountReturned;
          if (!user) {
            // user = await get_customer_details(response.data.results[index].customerId);
            // user = { ...user.customer, user: user.user.user };
            // await CustomersDB.addCustomers([user]);
            if (response.data.results[index].customerId in searchUsers) {
              searchUsers[response.data.results[index].customerId] = [
                ...searchUsers[response.data.results[index].customerId],
                index
              ];
            } else {
              searchUsers[response.data.results[index].customerId] = [index];
            }
          } else {
            response.data.results[index].userName = user.user.name;
            // response.data.results[index].userData = user;
          }
          // let user: any = await UsersDB.getUser(response.data.results[index].address.userId);
          // if (!user) {
          //   user = (await get_user_details(response.data.results[index].address.userId)).user;
          //   await UsersDB.addUsers([user]);
          // }
          // response.data.results[index].userName = user.name;
          let locD: any = await LocationsDB.getLocation(response.data.results[index].locationId);
          if (!locD) {
            // locD = await get_location_details(response.data.results[index].locationId);
            const allLocations = await get_location_list();
            // console.log('allLocations-->', allLocations);
            await LocationsDB.addLocations([...allLocations.data.results]);
            // await LocationsDB.addLocations([locD]);
            locD = await LocationsDB.getLocation(response.data.results[index].locationId);
          }
          response.data.results[index].locationName = locD.name;
          // ctotalamount += response.data.results[index].totalAmount;
          // creturnamount += response.data.results[index].amountReturned;
        }
        const searchUserslength = Object.entries(searchUsers).length;
        if (searchUserslength > 0) {
          const customerresponse = await get_customer_list_ids([...Object.keys(searchUsers)]);
          for (const key in searchUsers) {
            const customerUser = customerresponse?.data?.results.find(
              (currCustomer: any) => currCustomer.id == key
            );
            for (let i = 0; i < searchUsers[key].length; i++) {
              response.data.results[searchUsers[key][i]].userName = customerUser?.user.name;
              // response.data.results[searchUsers[key][i]].userData = customerUser;
            }
            await CustomersDB.addCustomers([customerUser]);
          }
        }
      }
    } catch (e) {
      message.error('No reference found');
    }
    setIsloading(false);
  };
  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      key: 'id',
      render: (record) => {
        return <TableCell>{record.id}</TableCell>;
      }
    },
    {
      title: 'Financial Ref',
      key: 'referenceNumber',
      className: 'invoice',
      width: 150,
      render: (record) => {
        return (
          <TableCell>
            <div className="flex flex-row justify-between items-center">
              <Link to={`/sell/${record.id}`} color="black">
                {record.financialReference}
              </Link>
              {record.financialReference ? <CopyButton text={record.financialReference} /> : null}
            </div>
          </TableCell>
        );
      }
    },
    {
      title: 'User',
      key: 'userName',
      render: (record) => {
        return (
          // <Link to={`/users/${record.address.userId}`} color="black">
          <TableCell>{record.userName}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Location',
      key: 'locationName',
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <TableCell>{record.locationName}</TableCell>
          // </Link>
        );
      }
    },
    // {
    //   title: 'Address ID',
    //   key: 'addressId',
    //   render: (record) => {
    //     return <TableCell>color="black">{record.addressId}</TabelCell>;
    //   }
    // },
    // {
    //   title: 'Tax ID',
    //   key: 'taxId',
    //   render: (record) => {
    //     return <TabelCell>color="black">{record.taxId}</TabelCell>;
    //   }
    // },
    {
      title: 'Total',
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      render: (record) => {
        return (
          <TableCell className="text-xs text-right mr-2">{nepaliNumberFormatter(record)}</TableCell>
        );
      }
    },
    {
      title: 'Paid',
      key: 'totalAmount',
      dataIndex: 'amountPaid',
      render: (amountPaid) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <TableCell className="text-xs text-right mr-2">
            {nepaliNumberFormatter(amountPaid)}
          </TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Return',
      key: 'amountReturned',
      dataIndex: 'amountReturned',
      render: (record) => {
        return (
          <TableCell className="text-xs text-right mr-2">{nepaliNumberFormatter(record)}</TableCell>
        );
      }
    },
    {
      title: 'Net Sell',
      key: 'amountReturned',
      className: 'highlight',
      dataIndex: 'netSell',
      render: (record) => {
        return (
          <TableCell className="text-xs text-right mr-2">{nepaliNumberFormatter(record)}</TableCell>
        );
      }
    },

    {
      title: 'Payment Status',
      key: 'paymentStatus',
      render: (record) => {
        return (
          <Tag className="uppercase" color={colorCode[record.paymentStatus]}>
            {record.paymentStatus}
          </Tag>
        );
      }
    },
    {
      title: 'Date',
      key: 'date',
      render: (record) => {
        return (
          <TableCell>
            {moment(record.createdAt).local().format(DEFAULT_DATE_FORMAT)}
            {/* {record.date.slice(0, 10)} */}
          </TableCell>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (record) => {
        return (
          <TableCell>
            <Button
              color="black"
              onClick={() => {
                // console.log('Record', record);
                setSelectedSale(record);
                setTimeout(() => {
                  setShowPaymentModal(true);
                }, 500);
              }}>
              Add
            </Button>
          </TableCell>
        );
      }
    }
  ];

  return (
    <div>
      <PaymentModal
        paymentType={'sell'}
        visible={showPaymentModal}
        setShow={setShowPaymentModal}
        selectedSale={selectedSale}
        setSelectedSale={setSelectedSale}
        fetchAgain={getInfo}
        form2={form}
      />
      <AppContent breadcrumbItems={breadcrumbItems}>
        <TableFilter
          dateCustom={false}
          defaultValues={{}}
          onSubmit={onSubmitFilter}
          style={
            'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
          }
          // outsidestyle={'flex gap-2 items-end'}
          styleforbuttons="flex items-center justify-end"
          form={form}>
          <Form.Item
            label="Reference Number"
            name={['value']}
            rules={[{ required: true, message: 'Please add reference number!' }]}>
            <Input />
          </Form.Item>
        </TableFilter>
        <GenericTable
          scroll={{ x: 1300, y: '75vh' }}
          columns={columns}
          data={paymentData}
          isLoading={isloading}
          generateSummary
          excludeSummaryByKeys={['id']}
        />
      </AppContent>
    </div>
  );
};
