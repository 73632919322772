import { AxiosResponse } from 'axios';
import http from '../../../utils/http.utils';
import { IExpenseCategoryRevamp, ExpenseCategoryFormData } from './types';

export const create_expense_category_mutation = async (values: ExpenseCategoryFormData) => {
  const response: AxiosResponse<IExpenseCategoryRevamp, any> = await http.store(
    'expense-category',
    values
  );
  return response;
};

export const update_expense_category_mutation = async (
  values: Partial<ExpenseCategoryFormData> & { id: number }
) => {
  const { id, ...rest } = values;
  const response: AxiosResponse<IExpenseCategoryRevamp, any> = await http.update(
    'expense-category/' + id,
    rest
  );
  return response;
};
