import { getFileListFromMediaIds } from '@/services/upload/services';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload, UploadFile, UploadProps } from 'antd';
import { RcFile } from 'antd/lib/upload';

import { useState, useImperativeHandle, forwardRef } from 'react';

interface Props {
  getCurrentFileList?: (fileList: UploadFile[]) => void;
  maxFileLimit?: number;
}

export interface PictureListRef {
  clearAll: () => void;
  getCurrentMedias: () => UploadFile[];
  addMedia: (file: UploadFile, type?: 'append' | 'replace') => void;
  addByMediaIds: (mediaIds: number[], type?: 'append' | 'replace') => Promise<UploadFile[]>;
  removeMedia: (uid: string) => void;
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const PictureList = forwardRef<PictureListRef, Props>(
  ({ maxFileLimit = 8, ...handler }: Props, ref) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const [previewImage, setPreviewImage] = useState({ image: '', title: '' });

    function onSetFileList(fileList: UploadFile[]) {
      setFileList(fileList);
      handler?.getCurrentFileList?.(fileList);
    }

    async function addByMediaIds(mediaIds: number[], type: 'append' | 'replace' = 'append') {
      const medias = await getFileListFromMediaIds(mediaIds);
      const updatedFileList = type === 'replace' ? medias : [...fileList, ...medias];

      onSetFileList(updatedFileList);
      return medias;
    }

    const handleUploadChange: UploadProps['onChange'] = ({ fileList }) => {
      onSetFileList(fileList);
    };

    const handlePreview = async (file: UploadFile) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }

      const image = file.url || file.preview || '';
      const urlTitle = file.url
        ? file.url.substring(file.url.lastIndexOf('/') + 1)
        : 'Currenly Preview Image';

      const finalTitle = file.name || urlTitle;
      setPreviewImage({ image, title: finalTitle });
      setOpenPreviewModal(true);
    };

    function addMedia(file: UploadFile, type: 'append' | 'replace' = 'append') {
      if (type === 'append') {
        onSetFileList([...fileList, file]);
        return;
      }

      onSetFileList([file]);
    }

    function removeMedia(uid: string) {
      const updatedFiles = fileList.filter((file) => file.uid !== uid);
      onSetFileList(updatedFiles);
    }

    useImperativeHandle(ref, () => ({
      clearAll: () => onSetFileList([]),
      getCurrentMedias: () => fileList,

      addMedia,
      removeMedia,
      addByMediaIds
    }));

    return (
      <div>
        <Modal
          visible={openPreviewModal}
          title={previewImage.title}
          footer={null}
          onCancel={() => {
            setPreviewImage({ image: '', title: '' });
            setOpenPreviewModal(false);
          }}>
          <img alt="example" style={{ width: '100%' }} src={previewImage.image} />
        </Modal>
        <Upload
          accept="image/*"
          multiple={maxFileLimit > 1}
          fileList={fileList}
          beforeUpload={() => false}
          listType="picture-card"
          onChange={handleUploadChange}
          onPreview={handlePreview}>
          {fileList.length <= maxFileLimit && (
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
      </div>
    );
  }
);

PictureList.displayName = 'PictureList';
export default PictureList;
