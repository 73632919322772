export enum ReportQueueEvents {
  REPORT_QUEUE_ADDED = 'REPORT_QUEUE_ADDED',
  REPORT_QUEUE_COMPLETED = 'REPORT_QUEUE_COMPLETED',
  REPORT_QUEUE_FAILED = 'REPORT_QUEUE_FAILED'
}

export enum LineType {
  SALE = 'SALE',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  PURCHASE = 'PURCHASE',
  PURCHASE_RETURN = 'PURCHASE_RETURN',
  SALE_ORDER = 'SALE_ORDER',
  SALE_RETURN = 'SALE_RETURN',
  TRANSFER_IN = 'TRANSFER_IN',
  TRANSFER_ORDER = 'TRANSFER_ORDER',
  TRANSFER_OUT = 'TRANSFER_OUT',
  TRANSFER_REJECT = 'TRANSFER_REJECT',
  ADJUSTMENT_PENDING = 'ADJUSTMENT_PENDING',
  ADJUSTMENT_REJECT = 'ADJUSTMENT_REJECT',
  ADJUSTMENT = 'ADJUSTMENT',
  OPENING_STOCK = 'OPENING_STOCK',
  OTHER = 'OTHER'
}
