import { Form, InputNumber } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect } from 'react';
import moment from 'moment';
import Report from '../..';
import { Link, useNavigate } from 'react-router-dom';
import { DEFAULT_DATE_FORMAT } from '@/constants';
interface DataTypeColumns {
  id: number;
  name: string;
  email: string;
  phone: string;
  verified: number;
  created_at: Date;
  updated_at: Date;
}
const UserReport = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldValue('constraints', {
      page: 1,
      size: 10
    });
  }, []);

  const columns: ColumnsType<DataTypeColumns> = [
    {
      title: 'ID',
      key: 'id',
      render: (record) => {
        return <Link to={`/users/${record.id}`}>{record.id}</Link>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      render: (record) => {
        return <Link to={`/users/${record.id}`}>{record.name}</Link>;
      }
    },
    {
      title: 'Email',
      key: 'email',
      render: (record) => {
        return <Link to={`/users/${record.id}`}>{record.email}</Link>;
      }
    },
    {
      title: 'Phone',
      key: 'phone',
      render: (record) => {
        return <div>{record.phone}</div>;
      }
    },
    {
      title: 'Created At',
      key: 'createdAt',
      render: (record) => {
        return <div>{moment(record.created_at).local().format(DEFAULT_DATE_FORMAT)}</div>;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'User',
      link: '/reports/user'
    }
  ];
  return (
    <Report
      breadcrumbItems={breadcrumbItems}
      state={{ id: 1, name: 'User Report' }}
      form={form}
      columnsData={columns}>
      <div className="grid grid-cols-3 gap-5 ">
        <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
          <InputNumber controls={false} />
        </Form.Item>
        <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
          <InputNumber controls={false} />
        </Form.Item>
      </div>
    </Report>
  );
};

export default UserReport;
