import { Button, Spin, message } from 'antd';
import { IReportEventType } from '../../../services/notifications/types';
import { useState } from 'react';
import { get_image } from '../FetchImage/FetchImage';
import { API_URL } from '../../../constants/config';

interface IDownloadReportModal {
  data: IReportEventType;
  ids: number[];
  handleModalClose: (ids: number[]) => void;
}

export const DownloadReportModal: React.FC<IDownloadReportModal> = ({
  data,
  ids,
  handleModalClose
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadReport = async () => {
    setIsLoading(true);
    try {
      const downloadData = await get_image(`${API_URL}storage/media/${data.mediaId}`);
      //   console.log('downloadData', downloadData);
      window.open(downloadData, '_blank');
      setIsLoading(false);
      handleModalClose(ids);
    } catch (err: any) {
      setIsLoading(false);
      message.error(err.message);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div>{data.source.query.customReportQueueName}</div>
      <div className="flex justify-end">
        <Button
          type="primary"
          style={{ backgroundColor: '#0AA245', color: 'white', borderRadius: '10px' }}
          onClick={() => downloadReport()}>
          Download
        </Button>
      </div>
    </Spin>
  );
};
