import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import {
  get_lots_details,
  get_product_details,
  get_product_list,
  get_units_list,
  get_product_list_ids
} from '../../../../services/products/queries';
import {
  get_sell_order_details,
  get_sell_order_line_details,
  get_sell_list,
  get_sell_lines_details,
  get_sell_details
} from '../../../../services/sell/queries';
import { useEffect, useState } from 'react';
import { get_invoices_list } from '../../../../services/settings/queries';
import type { ColumnsType } from 'antd/es/table';
import AppContent from '../../../../components/Common/Content/Content';
import { PageHeader, Table, Button, Spin, Divider, message, Menu, Tooltip } from 'antd';
import LocationsDB from '../../../../store/localstorage/LocationsDB';
import { get_location_details } from '../../../../services/locations/queries';
import { get_user_details } from '../../../../services/users/queries';
import { createPDF } from '../list/pdfMaker';
import UsersDB from '../../../../store/localstorage/UsersDB';
import ReuseChannel from '../../../channel/Reuse';
import { create_sell_mutation } from '../../../../services/sell/mutations';
import UnitsDB from '../../../../store/localstorage/UnitsDB';
import CustomizeTable from '../../../../components/Common/CustomizeTable/CustomizeTable';
import { Link } from 'react-router-dom';
import ProductsDB from '../../../../store/localstorage/ProductsDB';
import { convertLocalToUTCString } from '../../../../utils/convertToUTC';
import { nepaliNumberFormatter, numberDecimalFormatter } from '../../../../utils/numberFormatter';
import { convert_string_to_nepali_date_string } from '../../../../utils/nepaliDateConverter';
import ActionDropdown from '../../../../components/Common/Dropdownactions';
import RoutesDB from '../../../../store/localstorage/RoutesDB';
import { get_routes_details } from '../../../../services/routes/queries';
import { CustomModal } from '../../../../components/Common/CustomModal';
import SellInvoice from '../../../../components/Common/InvoicePrint/SellInvoice/SellInvoice';
import { ISellInvoice } from '../../../../services/invoice/types';
import InvoicePrintButton from '../../../../components/Common/InvoicePrintButton/InvoicePrintButton';
import { getSellOrderPrintData } from '../../../../components/Common/InvoicePrint/SellInvoice/services';
import { ICreateInvoiceResponse } from '../../../../services/settings/types';
import { IProductType } from '@/services/products/types';

export const shortNameHiearchy: string[] = [
  'crt',
  'box',
  'kg',
  '500g',
  '100g',
  'g',
  'dz',
  'pkt(s)',
  'bch',
  'pc(s)',
  'gal',
  'btl',
  'ltr',
  'bori',
  'बट्टा'
];

const SellOrderView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [productList, setProductList] = useState<any[]>([]);
  const [lines, setProductLines] = useState<any>([]);
  const [isloading, setIsloading] = useState<boolean>(true);
  const [vendorDetails, setvendorDetails] = useState<any>();
  const [locationDetails, setLocationDetails] = useState<any>();
  const [sellorderDetails, setSellorderDetails] = useState<any>();
  const [invoiceLayouts, setinvoiceLayouts] = useState<any>();
  const [createdUser, setcreatedUser] = useState<any>();
  const [invoiceLists, setInvoiceLists] = useState<any>([]);
  const [routeInformation, setRouteInformation] = useState<any>({});
  const [data, setData] = useState({
    taxable: 0,
    nonTaxable: 0,
    vat: 0
  });
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<ISellInvoice>(Object);

  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    setInvoiceLists(response.data);
    setinvoiceLayouts(response.data[0]);
    return response;
  });
  const [allSellData, setAllSellData] = useState<any>();

  async function getInfo() {
    const response: any = await get_sell_order_details(parseInt(id as string));
    // console.log('response-->', response);

    let routeInformation: any;
    if (response?.data?.address?.routeId) {
      routeInformation = await RoutesDB.getRoute(response.data.address.routeId);
      if (!routeInformation) {
        const routesList = await get_routes_details(response.data.address.routeId);
        RoutesDB.addRoutes([routesList]);
        routeInformation = await RoutesDB.getRoute(response.data.address.routeId);
      }
    } else {
      routeInformation = { name: '' };
    }
    setRouteInformation(routeInformation);
    let createdByDetails: any;
    if (response?.data?.createdBy) {
      createdByDetails = await UsersDB.getUser(response?.data?.createdBy);
      if (!createdByDetails) {
        createdByDetails = (await get_user_details(response?.data?.createdBy)).user;
        UsersDB.addUsers([createdByDetails]);
      }
    }
    setcreatedUser(createdByDetails);
    const linesResponse = await get_sell_order_line_details(response.data.id);
    const totalQuantity: any = {};
    if (linesResponse.data.length > 0) {
      const absentProductIds: number[] = [];
      let taxable = 0;
      let nonTaxable = 0;
      let vatTotal = 0;

      for (let i = 0; i < linesResponse.data.length; i++) {
        const product: any = await ProductsDB.getProduct(linesResponse.data[i].productId);
        if (!product) absentProductIds.push(linesResponse.data[i].productId);
        linesResponse.data[i].productName = product?.name;
      }

      if (absentProductIds.length > 0) {
        const allProducts = await get_product_list_ids([...new Set(absentProductIds)]);
        await ProductsDB.addProducts(allProducts.data.results);
      }

      for (let index = 0; index < linesResponse.data.length; index++) {
        const product: any = await ProductsDB.getProduct(linesResponse.data[index].productId);
        linesResponse.data[index].productName = product?.name;

        let findUnit: any = await UnitsDB.getUnit(linesResponse.data[index].unitId);
        linesResponse.data[index].quantity = Number(
          numberDecimalFormatter(linesResponse.data[index].quantity)
        );
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
        }

        if (!totalQuantity[findUnit.shortName]) {
          totalQuantity[findUnit.shortName] = linesResponse.data[index].quantity;
        } else {
          totalQuantity[findUnit.shortName] += linesResponse.data[index].quantity;
        }
        // linesResponse.lines[index].unitName = findUnit.name;
        // totalQuantity += linesResponse.data[index].quantity;
        linesResponse.data[
          index
        ].quantityWithUnit = `${linesResponse.data[index].quantity} ${findUnit.shortName}`;
        linesResponse.data[index].shortName = findUnit.shortName;

        const vat =
          (linesResponse.data[index].unitPrice * linesResponse.data[index].quantity -
            linesResponse.data[index].discount) *
          (product.vat / 100);
        linesResponse.data[index].vat = vat;
        if (vat > 0) {
          vatTotal += vat;
          linesResponse.data[index].totalAmount = linesResponse.data[index].totalAmount - vat;
          taxable += linesResponse.data[index].totalAmount;
        } else {
          nonTaxable += linesResponse.data[index].totalAmount;
        }
      }

      taxable += response.data.shipping - response.data.shippingTax;
      setData({ taxable: taxable, nonTaxable: nonTaxable, vat: vatTotal });
    }
    if (typeof response.data.misc == 'string') {
      response.data.misc = parseInt(response.data.misc);
    }
    if (response.data?.locationId) {
      let locData: any = await LocationsDB.getLocation(response.data.locationId);
      if (!locData) {
        locData = await get_location_details(response.data.locationId);
        LocationsDB.addLocations([locData]);
      }
      setLocationDetails(locData);
      //   console.log('Location from location Id', locData);
    }
    if (response.data?.address.userId) {
      let userData: any = await UsersDB.getUser(response.data.address.userId);
      if (!userData) {
        userData = (await get_user_details(response.data.address.userId)).user;
        await UsersDB.addUsers([userData]);
      }
      // console.log('userData ', userData);
      setvendorDetails(userData);
      //   console.log('UserData-->', userData);
    }

    console.log('Line response data--->', linesResponse.data);
    setProductLines(linesResponse.data);
    // console.log('Response data--->', response.data);
    let totalQuantityString = '';
    const totalQuantityArray: any[] = [];
    for (const key in totalQuantity) {
      totalQuantityArray.push({
        tqty: totalQuantity[key],
        shortName: key
      });
    }
    totalQuantityArray.sort((a: any, b: any) => {
      return shortNameHiearchy.indexOf(a.shortName) - shortNameHiearchy.indexOf(b.shortName);
    });
    for (let ind = 0; ind < totalQuantityArray.length; ind++) {
      totalQuantityString += ` ${totalQuantityArray[ind].tqty} ${totalQuantityArray[ind].shortName}`;
    }
    setSellorderDetails({ ...response.data, totalQuantity: totalQuantityString });
    setAllSellData({
      lines: linesResponse.data,
      ...response.data,
      userId: response.data.address.userId
    });
    setIsloading(false);
    return response;
  }

  const { refetch } = useQuery(['sell-order-view-details'], getInfo, {
    retry: false,
    onError: (error) => {
      throw error;
    }
  });

  const columns: ColumnsType<{ name: string }> = [
    {
      title: `S.N`,
      key: 'sn',
      width: 30,
      render: (a, b, c) => <div>{c + 1}.</div>
    },
    {
      title: `${
        invoiceLayouts?.content.productLabel ? invoiceLayouts.content.productLabel : 'ProductName'
      }`,
      dataIndex: 'productName',
      render: (text: string) => <div>{text}</div>,
      width: 100
    },
    {
      title: 'HS Code',
      dataIndex: 'hsCode',
      width: 70,
      render: (text?: string) => <a>{text || 'N/A'}</a>
    },
    {
      title: `${
        invoiceLayouts?.content.quantityLabel ? invoiceLayouts.content.quantityLabel : 'Quantity'
      }`,
      key: 'quantity',
      width: 70,
      render: (record: any) => <div>{`${record.quantityWithUnit}`}</div>
    },
    {
      title: `${
        invoiceLayouts?.content.unitPriceLabel ? invoiceLayouts.content.unitPriceLabel : 'UnitPrice'
      }`,
      dataIndex: 'unitPrice',
      width: 70,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    // {
    //   title: `${'UnitName'}`,
    //   dataIndex: 'unitName',
    //   render: (text: number) => <div>{text}</div>
    // },
    // {
    //   title: 'Misc',
    //   dataIndex: 'misc',
    //   render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    // },
    {
      title: `${
        invoiceLayouts?.content.discountLabel ? invoiceLayouts.content.discountLabel : 'Discount'
      }`,
      dataIndex: 'discount',
      width: 70,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: 'VAT',
      dataIndex: 'vat',
      width: 70,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: `${
        invoiceLayouts?.content.totalLabel ? invoiceLayouts.content.totalLabel : 'Total Amount'
      }`,
      dataIndex: 'totalAmount',
      width: 70,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    }
  ];
  const breadcrumbItems = [
    {
      label: 'Sell Order',
      link: '/sell/order'
    },
    {
      label: 'View'
    }
  ];

  const printInvoiceAfterSellOrderFinalize = async (data: any) => {
    console.log('data', data);
    const response: any = await get_sell_details(parseInt(data.id as string));
    const invoiceresponse = await get_invoices_list();
    // setReturnList(response.returns);
    // setPayList(response.payments);
    let createdByDetails: any;
    if (response.createdBy) {
      createdByDetails = await UsersDB.getUser(response.createdBy);
      if (!createdByDetails) {
        createdByDetails = (await get_user_details(response.createdBy)).user;
      }
    }
    // setcreatedUser(createdByDetails);
    const linesResponse = await get_sell_lines_details(parseInt(data.id as string));
    let userData: any = await UsersDB.getUser(response.address.userId);
    // console.log('UUU', response.address.userId);
    if (!userData) {
      userData = await get_user_details(response.address.userId);
      await UsersDB.addUsers([userData]);
    }
    // console.log('userData', userData);
    // setVendorDetails(userData.user);
    // if (!userData.user) setVendorDetails(userData);
    let locationDetails: any = await LocationsDB.getLocation(response.locationId);
    if (!locationDetails) {
      locationDetails = await get_location_details(response.locationId);
      await LocationsDB.addLocations([locationDetails]);
    }
    // setLocationDetails(locationDetailss);
    let totalQuantity: any = 0;
    let shortName: any = '';
    if (linesResponse.data.length > 0) {
      const searchProducts: any = {};
      for (let index = 0; index < linesResponse.data.length; index++) {
        const product: any = await ProductsDB.getProduct(linesResponse.data[index].productId);
        if (!product) {
          // product = await get_product_details(linesResponse.lines[index].productId);
          // await ProductsDB.addProducts([product]);
          if (linesResponse.data[index].productId in searchProducts) {
            searchProducts[linesResponse.data[index].productId] = [
              ...searchProducts[linesResponse.data[index].productId],
              index
            ];
          } else {
            searchProducts[linesResponse.data[index].productId] = [index];
          }
        } else {
          linesResponse.data[index].productName = product.name;
        }

        let findUnit: any = await UnitsDB.getUnit(linesResponse.data[index].unitId);
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
        }
        // linesResponse.lines[index].unitName = findUnit.name;
        // linesResponse.data[index].quantity = `${linesResponse.data[index].quantity}`;
        linesResponse.data[index].shortName = findUnit.shortName;
        shortName = findUnit.shortName;

        totalQuantity += numberDecimalFormatter(linesResponse.data[index].quantity);
      }
      const searchProductslength = Object.entries(searchProducts).length;
      if (searchProductslength > 0) {
        const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
        for (const key in searchProducts) {
          const findproduct = productsresponse?.data?.results.find(
            (currProduct: any) => currProduct.id == key
          );
          for (let i = 0; i < searchProducts[key].length; i++) {
            linesResponse.data[searchProducts[key][i]].productName = findproduct?.name;
          }
          await ProductsDB.addProducts([findproduct]);
        }
      }
      // setLines(linesResponse.data);
      // console.log(linesResponse.data);
      // setIsloading(false);
      // console.log(allProducts);
      // setProductList(allProducts);
    }
    // setpurchaseDetails({ ...response, totalQuantity: `${totalQuantity} ${shortName}` });
    localStorage.setItem(
      'referrer',
      JSON.stringify({
        purchaseDetails: { ...response, totalQuantity: `${totalQuantity} ${shortName}` },
        locationDetails,
        customerDetails: userData?.user ? userData.user : userData,
        lines: linesResponse.data ? linesResponse.data : [],
        invoiceLayouts: invoiceresponse?.data[0] ? invoiceresponse?.data[0] : undefined,
        createdByDetails
      })
    );
    window.open('/ERP-UI#/invoice-sell', '_blank', 'noopener,noreferrer');
    // console.log('linesResponse-->', linesResponse.data);s
    // console.log('Response--->', response.data);
    setIsloading(false);
  };

  const finalizeSell = async () => {
    try {
      //validation
      setIsloading(true);
      navigate(`/sell/order/${id}`);

      // const values = allSellData;
      // //send sell
      // delete values.id;
      // if (id) values.sellOrderId = parseInt(id);
      // values.date = JSON.stringify(new Date()).slice(1, -1);
      // await createSellMutation.mutateAsync(values, {
      //   onSuccess: async ({ data }: { data: any }) => {
      //     printInvoiceAfterSellOrderFinalize(data);
      //     message.success('Sell finalized successfully');
      //     navigate('/sell');
      //   },
      //   onError: async (e: any) => {
      //     message.error(`${e.response.data.message}`, 5);
      //     navigate(`/sell/order/${id}`);
      //   }
      // });
    } catch (errors: any) {
      message.error('Error', errors);
      setIsloading(false);
    }
  };

  // console.log('sell order', sellorderDetails);

  const generateInvoiceForPrint = async (record: any, curr: ICreateInvoiceResponse) => {
    // console.log('record', record);
    setIsloading(true);
    const invoiceModalData = await getSellOrderPrintData(record.id, record.locationId);
    setIsloading(false);

    setInvoiceData({ ...invoiceModalData, invoiceLayouts: curr });

    setOpenModalForInvoicePrint(true);
  };

  const handleInvoicePrintModalClose = () => {
    setOpenModalForInvoicePrint(false);
    refetch();
  };

  return (
    <div>
      <CustomModal
        footer={false}
        isModalOpen={openModalForInvoicePrint}
        setIsModalOpen={setOpenModalForInvoicePrint}
        title="Sell Order Invoice Print">
        <SellInvoice
          sellDetails={invoiceData.sellDetails}
          customerDetails={invoiceData.customerDetails}
          lines={invoiceData.lines}
          invoiceLayouts={invoiceData.invoiceLayouts}
          handleModalClose={handleInvoicePrintModalClose}
        />
      </CustomModal>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Spin spinning={isloading}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-5">
            <div>
              <PageHeader
                subTitle="Customer"
                style={{
                  padding: '8px 0px'
                }}
              />
              {vendorDetails ? (
                <>
                  <div style={{ color: 'black' }}>
                    {vendorDetails?.name ? vendorDetails.name : ''}
                    {/* </p>
                  <p> */}
                    {`${vendorDetails?.phone ? `,${vendorDetails.phone}` : ''} ${
                      vendorDetails.email ? `,${vendorDetails.email}` : ''
                    }`}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div>
              <PageHeader
                subTitle="Location"
                style={{
                  padding: '8px 0px'
                }}
              />
              {locationDetails ? (
                <>
                  <div style={{ color: 'black' }}>
                    {locationDetails?.name ? locationDetails.name : ''}
                    {/* </p>
                <p> */}
                    {`${locationDetails?.zip ? `${locationDetails.zip},` : ''} ${
                      locationDetails?.address ? `${locationDetails.address},` : ''
                    } ${locationDetails?.city ? `${locationDetails.city},` : ''} ${
                      locationDetails?.country ? `${locationDetails.country}` : ''
                    }`}
                    {/* </p>
                <p> */}
                    {`${locationDetails?.phone ? `${locationDetails.phone},` : ''} ${
                      locationDetails?.email ? `${locationDetails.email}` : ''
                    }`}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div>
              <PageHeader
                subTitle="Details"
                style={{
                  padding: '8px 0px'
                }}
              />
              {sellorderDetails ? (
                <>
                  {/* <p>Reference Number : {}</p> */}
                  <div style={{ color: 'black' }}>
                    Created : {convert_string_to_nepali_date_string(sellorderDetails.createdAt)}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <CustomizeTable columns={columns} data={lines} notshowPagination={true} />
          <div>
            <PageHeader
              subTitle="Payment Info"
              style={{
                padding: '8px 0px'
              }}
            />
            {sellorderDetails ? (
              <div style={{ color: 'black' }}>
                <span className="block">Total Quantity:{sellorderDetails.totalQuantity}</span>
                <span className="block">Taxable : {nepaliNumberFormatter(data.taxable)}</span>
                <span className="block">
                  Non Taxable : {nepaliNumberFormatter(data.nonTaxable)}
                </span>
                <span className="block">VAT : {nepaliNumberFormatter(data.vat)}</span>
                <span className="block">
                  Total Amount : {nepaliNumberFormatter(sellorderDetails.totalAmount)}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
          {/* </div> */}
          <div className="grid grid-cols-1 mb-5">
            {sellorderDetails?.note ? (
              <>
                <PageHeader
                  subTitle="Note"
                  style={{
                    padding: '8px 0px'
                  }}
                />

                <div style={{ color: 'black' }}>{sellorderDetails.note}</div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="flex justify-end  gap-5 mt-5">
            <div
              style={{
                width: '3rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <InvoicePrintButton
                getInvoice={(curr) => {
                  generateInvoiceForPrint(sellorderDetails, curr);
                }}
              />
            </div>
            <Button
              type="primary"
              onClick={async () => {
                await finalizeSell();
              }}>
              Finalize
            </Button>
            <Button type="default" onClick={() => navigate('/sell/order')}>
              Back
            </Button>
          </div>
        </Spin>
        <Divider />
        <ReuseChannel
          slug={`sell_order_${id}`}
          fromSellorPurchaseId={vendorDetails?.id}
          withReferenceandId={{ reference: 'sell_order', referenceId: id || 0 }}
        />
      </AppContent>
    </div>
  );
};

export default SellOrderView;
