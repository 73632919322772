import { Navigate } from 'react-router-dom';

import MainLayout from '../layouts/main.layout';
import AccountMenu from '@/pages/accounts/Menu';
import { getUserData, globalSignOut, isAuthenticated } from '../utils/auth.utils';
import { ACLTypes, checkAccess, checkAccessAndReturn } from './acl';
import MainLayoutSecondary from '@/layouts/menu-secondary.layout';

interface IPrivateRouteProps {
  children: React.ReactElement;
  type?: ACLTypes | ACLTypes[];
  withoutmainlayout?: boolean;
  role?: 'USER' | 'ADMIN' | 'SUPER_ADMIN';
}

const PrivateRoute = ({
  children,
  type,
  role = 'USER',
  withoutmainlayout = false
}: IPrivateRouteProps) => {
  // console.log('type', type);
  // const navigate = useNavigate();
  if (!isAuthenticated()) {
    globalSignOut();
    return <Navigate to="/login" replace />;
  }

  if (type !== 'CREATE_POS' || type.includes('CREATE_POS')) {
    // console.log('loggedInUser', loggedInUser);
    if (checkAccess('CREATE_POS') && !checkAccess('B2B')) {
      return <Navigate to="/sell/pos-create-v2" />;
    }
  }

  if (type) {
    const arrayType = Array.isArray(type) ? type : [type];
    const isAllowed = arrayType.some((item) => checkAccessAndReturn(item));
    if (!isAllowed) return <Navigate to="/404" />;
  }

  const userData = getUserData();

  // Redirect user to 404 if has no super permission
  if (role !== 'USER') {
    const permission = userData.perms;
    if (!permission.includes(role)) {
      return <Navigate to="/404" />;
    }
  }

  let SubMenuLayoutComponent = undefined;
  if (type) {
    const arrayType = Array.isArray(type) ? type : [type];
    const isAccount = arrayType.some((item) => item?.toLowerCase().includes('account'));

    if (isAccount) {
      SubMenuLayoutComponent = AccountMenu;
    }
  }

  if (withoutmainlayout) return <>{children}</>;
  if (!SubMenuLayoutComponent) return <MainLayout>{children}</MainLayout>;

  return <MainLayoutSecondary menu={SubMenuLayoutComponent}>{children}</MainLayoutSecondary>;
};

export default PrivateRoute;
