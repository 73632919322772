import CategoryDiscountUI from '../CustomerDiscountUI';

function CustomerRangeCreate() {
  const breadcrumbItems = [
    { label: 'Customer Discount', link: '/offer/customer-discount' },
    { label: 'Create', link: '/offer/customer-discount/create' }
  ];

  return <CategoryDiscountUI type="create" breadcrumbItems={breadcrumbItems} />;
}

export default CustomerRangeCreate;
