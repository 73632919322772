import { Button, Divider, Form, FormInstance, message, PageHeader, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { get_routes_list, get_routes_list_filter } from '../../../services/routes/queries';
import { IUSerlocation } from '../../../services/routes/types';
import {
  get_customer_list,
  get_user_details,
  get_user_list,
  get_vendor_list
} from '../../../services/users/queries';
import RoutesDB from '../../../store/localstorage/RoutesDB';
interface ITaxtype {
  taxNumber: string;
  taxType: string;
  id?: string;
  userId?: number;
  form?: any;
}
interface UsersSearchProps {
  isClear?: boolean;
  formData?: {
    formName: any;
  };
  onSelect?: (value: number) => any;
  fromReport?: boolean;
  form?: any;
  showAll?: boolean;
  isAddress?: boolean;
  required?: boolean;
}
const { Option } = Select;
export const RouteSearch: React.FC<UsersSearchProps> = ({
  formData,
  onSelect,
  fromReport,
  form,
  showAll = false,
  isClear = false,
  isAddress = false,
  required = false
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchRouteIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const [routeSearch, setRouteSearch] = useState<any>([]);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);

  const searchRouteIndexDB = async (value: string) => {
    try {
      const response = await RoutesDB.searchRoute(value, 100);
      if (!response || response.length == 0) {
        message.info('Cannot find any route with that value in cache, searching in server...');
        searchRouteDB(value);
      } else {
        setRouteSearch(response);
        setIsCacheResponse(true);
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  const searchRouteDB = async (value: any) => {
    let response;
    try {
      response = await get_routes_list(0, 100, value);
      // form.setFieldValue(['routeId'], response.data.results[0].id);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any route with that value!');
      setRouteSearch([]);
    } else {
      setRouteSearch(response.data.results);
      RoutesDB.addRoutes(response.data.results);
    }
    setIsCacheResponse(false);
  };

  const options = routeSearch.map((value: any) => (
    <Option key={value.id} value={value.id}>
      {value.name}
    </Option>
  ));
  // console.log('User Search-->', userSearch);
  return (
    <>
      {fromReport ? (
        <>
          <Form.Item name={['constraints', 'routeId']} label="Route">
            <Select
              showSearch
              placeholder={'search route'}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onChange={onSelect}
              dropdownMatchSelectWidth={false}
              onSearch={(val) => {
                setSearchValue(val);
              }}
              notFoundContent={null}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  {isCacheResponse ? (
                    <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                      {/* <div
                        style={{
                          color: 'green',
                          width: '100%',
                          textAlign: 'center'
                        }}>{`Cache found ${routeSearch.length} data`}</div> */}
                      <Button
                        type="text"
                        style={{
                          color: 'blue',
                          width: '100%'
                        }}
                        onClick={() => {
                          searchRouteDB(searchValue);
                        }}>
                        {'Pull More & Sync'}
                      </Button>
                    </div>
                  ) : (
                    <>
                      {/* <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                        <div
                          style={{
                            color: 'green',
                            width: '100%',
                            textAlign: 'center'
                          }}>{`Server found ${routeSearch.length} data`}</div>
                      </div> */}
                    </>
                  )}
                </>
              )}>
              <Option value={''} key={'none'}>
                All
              </Option>
              {options}
            </Select>
          </Form.Item>
        </>
      ) : (
        <Form.Item
          name={['routeId']}
          label="Route"
          rules={[
            {
              required: isAddress ? true : required ? true : false,
              message: 'Please choose Route!'
            }
          ]}>
          <Select
            showSearch
            placeholder={'search route'}
            defaultActiveFirstOption={false}
            showArrow={false}
            size={isAddress ? 'large' : 'middle'}
            filterOption={false}
            onChange={onSelect}
            dropdownMatchSelectWidth={false}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            allowClear={isClear}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                {isCacheResponse ? (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {/* <div
                      style={{
                        color: 'green',
                        width: '100%',
                        textAlign: 'center'
                      }}>{`Cache found ${routeSearch.length} data`}</div> */}
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchRouteDB(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Pull More & Sync'}
                    </Button>
                  </div>
                ) : (
                  <>
                    {/* <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                      <div
                        style={{
                          color: 'green',
                          width: '100%',
                          textAlign: 'center'
                        }}>{`Server found ${routeSearch.length} data`}</div>
                    </div> */}
                  </>
                )}
              </>
            )}>
            {/* <Option value="">All</Option> */}
            {showAll && (
              <Option value={''} key={'none'}>
                All
              </Option>
            )}
            {options}
          </Select>
        </Form.Item>
      )}
    </>
  );
};
