import LocalStore from '.';

interface DataType {
  locationId: number;
  date: any;
  results: {
    productId: number;
    locationId: number;
    hidden: number;
    quantity: string;
  }[];
}
export default class TotalLotsAvailableDB extends LocalStore {
  /*TotalLotsAvailable Functions*/

  static async getAllDatas() {
    if (!TotalLotsAvailableDB.db) await TotalLotsAvailableDB.init();

    return new Promise((resolve, reject) => {
      const transaction = TotalLotsAvailableDB.db.transaction('TotalLotsAvailable', 'readonly');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('TotalLotsAvailable');
      const request = objectStore.getAll();
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async removeAllProducts() {
    if (!TotalLotsAvailableDB.db) await TotalLotsAvailableDB.init();

    return new Promise((resolve, reject) => {
      const transaction = TotalLotsAvailableDB.db.transaction(['TotalLotsAvailable'], 'readwrite');
      transaction.oncomplete = (event) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('TotalLotsAvailable');
      const objectStoreRequest = objectStore.clear();
      objectStoreRequest.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      objectStoreRequest.onerror = (event: any) => {
        reject(event.target.result);
      };
    });
  }
  static async addDatas(datas: DataType[]) {
    if (!TotalLotsAvailableDB.db) await TotalLotsAvailableDB.init();

    return new Promise((resolve, reject) => {
      const transaction = TotalLotsAvailableDB.db.transaction('TotalLotsAvailable', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('TotalLotsAvailable');
      datas.forEach(async (data) => {
        const checkKey = objectStore.count(data.locationId);
        checkKey.onsuccess = async (event: any) => {
          if (checkKey.result == 0) {
            const request = objectStore.add(data);
            request.onerror = (event: any) => {
              reject(event.target.error);
            };
          } else {
            await TotalLotsAvailableDB.updateData(data);
          }
        };
      });
    });
  }
  static async getData(locationId: number) {
    if (!TotalLotsAvailableDB.db) await TotalLotsAvailableDB.init();

    return new Promise((resolve, reject) => {
      const transaction = TotalLotsAvailableDB.db.transaction('TotalLotsAvailable', 'readonly');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('TotalLotsAvailable');
      const request = objectStore.get(locationId);
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async updateData(data: DataType) {
    if (!TotalLotsAvailableDB.db) await TotalLotsAvailableDB.init();

    return new Promise((resolve, reject) => {
      const transaction = TotalLotsAvailableDB.db.transaction('TotalLotsAvailable', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('TotalLotsAvailable');
      const request = objectStore.get(data.locationId);
      request.onsuccess = (event: any) => {
        if (event.target.result) {
          const requestUpdate = objectStore.put(data);
          requestUpdate.onerror = (event: any) => {
            reject(event.target.error);
          };
          requestUpdate.onsuccess = (event) => {
            resolve('updated data');
          };
        } else reject('Error, could not find id.');
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async deleteData(locationId: number) {
    if (!TotalLotsAvailableDB.db) await TotalLotsAvailableDB.init();
    if (await TotalLotsAvailableDB.getData(locationId)) {
      return new Promise((resolve, reject) => {
        const transaction = TotalLotsAvailableDB.db.transaction('TotalLotsAvailable', 'readwrite');
        transaction.oncomplete = (event: any) => {
          resolve('success');
        };
        transaction.onerror = (event: any) => {
          reject(event.target.error);
        };
        const objectStore = transaction.objectStore('TotalLotsAvailable');
        const request = objectStore.delete(locationId);
        request.onsuccess = (event: any) => {
          resolve('deleted successfuly.');
          // if (event.target.result) resolve(event.target.result);
          // reject('Error, could not find id.');
        };
        request.onerror = (event: any) => {
          reject(event.target.error);
        };
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve('id does not exist');
      });
    }
  }
}
