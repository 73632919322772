import { Table } from 'antd';
import React, { useEffect } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { nepaliNumberFormatter } from '../../../utils/numberFormatter';
import CustomTable from '../CustomResuableInvoice/CustomTable';

const ProductPrintInvoice = () => {
  const state = JSON.parse(localStorage.getItem('productprint') as string);
  const { products } = state;

  const columns: any[] = [
    {
      label: 'CustomerName',
      dataIndex: 'name',
      render: (text: any) => <div className="ml-1">{text}</div>
    },
    {
      label: 'Quantity',
      dataIndex: 'quantity',
      render: (text: any) => <div className="text-center">{text}</div>
    },
    {
      label: 'Unit',
      dataIndex: 'unit',
      render: (text: any) => <div className="text-center">{text}</div>
    },
    {
      label: 'Route',
      dataIndex: 'routeName',
      render: (text: any) => <div className="text-center">{text}</div>
    },
    {
      label: 'Amount',
      dataIndex: 'totalAmount',
      render: (text: any) => <div className="ml-1">{nepaliNumberFormatter(text)}</div>
    }
  ];

  useEffect(() => {
    window.print();
    window.onafterprint = (event) => {
      window.close();
      localStorage.removeItem('productprint');
    };
  }, []);

  return (
    <div>
      {products.map((curProduct: any) => {
        return (
          <>
            <div key={curProduct.name} className="p-5">
              <div className="font-bold text-sm">{curProduct.productName}</div>
              <CustomTable
                columns={columns}
                datas={[
                  ...curProduct.customers,
                  {
                    name: 'Total',
                    quantity: `${curProduct.totalUnit} ${curProduct.shortName}`,
                    unit: '',
                    totalAmount: curProduct.totalAmount
                  }
                ]}
              />
              {/* <Table
                columns={columns}
                dataSource={[...curProduct.customers,{
                  name:"Total",
                  quantity:`${curProduct.totalUnit} ${curProduct.shortName}`,
                  unit:"",
                  totalAmount:curProduct.totalAmount
                }]}
                size="small"
                pagination={false}
                summary={(pageData) => {
                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                        <Table.Summary.Cell
                          index={
                            1
                          }>{`${curProduct.totalUnit} ${curProduct.shortName}`}</Table.Summary.Cell>
                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          {nepaliNumberFormatter(curProduct.totalAmount)}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
                }}
              /> */}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default ProductPrintInvoice;
