import { CapitalizeEachWord } from './capitalizeEachWord';

export function numberToWords(num: number) {
  if (num === 0) return 'Zero only';

  const belowTwenty: string[] = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen'
  ];

  const tens = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety'
  ];

  const placeValues: string[] = ['', 'thousand', 'lakh', 'crore', 'arab', 'kharab'];

  function convertHundreds(n: number) {
    let result = '';
    if (n > 99) {
      result += belowTwenty[Math.floor(n / 100)] + ' hundred ';
      n %= 100;
    }
    if (n > 19) {
      result += tens[Math.floor(n / 10)] + ' ';
      n %= 10;
    }
    if (n > 0) {
      result += belowTwenty[n] + ' ';
    }
    return result.trim();
  }

  function convertLargeNumbers(n: number): string {
    let result = '';
    let placeIndex = 0;

    while (n > 0) {
      const chunkSize = placeIndex === 0 ? 3 : 2;
      const chunk = n % Math.pow(10, chunkSize);
      n = Math.floor(n / Math.pow(10, chunkSize));

      if (chunk > 0) {
        const chunkInWords = convertHundreds(chunk);
        result =
          chunkInWords +
          (placeValues[placeIndex] ? ' ' + placeValues[placeIndex] : '') +
          ' ' +
          result;
      }

      placeIndex++;
    }

    return result.trim();
  }

  const [integerPart, decimalPart] = num.toString().split('.');
  let result = convertLargeNumbers(Number(integerPart));
  if (result) result += ' rupees ';
  if (decimalPart) {
    const decimalNumber = Number(decimalPart.padEnd(2, '0'));
    const paisaInWords = convertLargeNumbers(decimalNumber);
    if (result) result += ' and ';
    result += paisaInWords + ' Paisa';
  }

  result += ' only';
  return CapitalizeEachWord(result);
}
