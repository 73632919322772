import { ExpenseColumnDataType } from '@/services/expense/enum';

export const filterId = [
  { label: 'Location', key: 'locationId', type: ExpenseColumnDataType.INTEGER },
  { label: 'Agent', key: 'agentId', type: ExpenseColumnDataType.INTEGER },
  { label: 'User', key: 'userId', type: ExpenseColumnDataType.INTEGER },
  { label: 'Vehicle', key: 'vehicleId', type: ExpenseColumnDataType.INTEGER },
  { label: 'Expense', key: 'expenseId', type: ExpenseColumnDataType.INTEGER },
  { label: 'Customer', key: 'customerId', type: ExpenseColumnDataType.INTEGER },
  { label: 'Vendor', key: 'vendorId', type: ExpenseColumnDataType.INTEGER },
  { label: 'Medias', key: 'mediaIds', type: ExpenseColumnDataType.STRING }
] as const;
