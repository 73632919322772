import moment from 'moment';

import {
  IDefaultAPIConfig,
  IDateRangeType,
  IWidgetConfiguration,
  IFormField,
  ICompareSelectedDate
} from './types';
import {
  reportCompareRequiredFields,
  ReportRequiredDateFields
} from '@/pages/dashboard/v3/constant.dashboard';
import groupByDate, { groupByTime } from '@/utils/groupByDate.utils';
import { get_report_list } from '@/services/report/queries';
import { WidgetType } from './enums';
import { get_location_list } from '@/services/locations/queries';
import {
  find_dashboard_filter,
  find_locationId_preference
} from '@/store/localstorage/preferences';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { IDateRange } from '@/services/dashboard/v3/types';
import { getUserData } from '@/utils/auth.utils';
import { message } from 'antd';
import { IUserPreferences } from '@/services/settings/types';
import { setLocalStorage } from '@/utils/storage.utils';
import { USER_INFO } from '@/constants/config';
import { update_preference_user_mutation } from '@/services/users/mutations';
import { ReportsDB } from './../../../store/localstorage/ReportsDB';
import { IReport } from '@/services/report/types';
import { ILocations } from '@/services/locations/types';

export function getReportPayload(
  defaultConfig: IDefaultAPIConfig,
  reportId: number,
  date: [string, string]
) {
  const payloadConfig = ReportRequiredDateFields[reportId] || {
    startDate: ['startDate'],
    endDate: ['endDate']
  };

  const payloadCopy = { ...defaultConfig } as Record<string, string | number>;
  payloadConfig.startDate.forEach((key) => {
    payloadCopy[key] = moment(date[0]).millisecond(0).toISOString();
  });

  payloadConfig.endDate.forEach((key) => {
    // IF one day report, add one day to start Date
    payloadCopy[key] = payloadConfig.isOneDay
      ? moment(date[0]).add(1, 'day').millisecond(0).toISOString()
      : moment(date[1]).millisecond(0).toISOString();
  });

  if (payloadConfig.locationId) {
    payloadCopy[payloadConfig.locationId] = defaultConfig.locationId;
    delete payloadCopy.locationId;
  }

  return payloadCopy;
}

export function truncateString(initialString: string, maxLength = 16) {
  const givenString = initialString.trim();
  if (givenString.length <= maxLength) {
    return givenString;
  }

  const slashIndex = initialString.indexOf('/');

  const slicedString =
    slashIndex === -1
      ? givenString.split(' ').filter(Boolean).slice(0, 2)
      : givenString.slice(0, slashIndex).split(' ').filter(Boolean).slice(0, 2);

  const lastIndex = slicedString.length - 1;
  const lastWord = slicedString[lastIndex];

  slicedString[lastIndex] = `${lastWord}...`;
  return slicedString;
}

export function getReportGroupedData(data: any[], groupBy: string, dataType: IDateRangeType) {
  const groupData = groupByDate(data, groupBy);
  if (dataType !== 'daily') {
    return groupData;
  }

  return groupByTime(data, groupBy);
}

export function calculateColSize(initialColSize: number, colSizes: number[]) {
  let currentRow = 0;
  let currentCol = 0;
  let remainingColSize = initialColSize;
  const matrix = [[]] as number[][];

  if (initialColSize === 1) {
    return colSizes.map(() => 1);
  }

  for (let i = 0; i < colSizes.length; i++) {
    const colSize = Math.min(colSizes[i], initialColSize);

    if (colSize <= remainingColSize) {
      matrix[currentRow][currentCol] = colSize;
      remainingColSize -= colSize;
      currentCol++;
    } else {
      // Distribute remaining colsize to current row members
      const totalColInCurrentRow = matrix[currentRow].length;
      const eachSize = Math.ceil(remainingColSize / totalColInCurrentRow);

      // Distribute remaining colsize to current row members
      for (let i = totalColInCurrentRow - 1; i >= 0; i--) {
        if (remainingColSize > eachSize) {
          matrix[currentRow][i] += eachSize;
          remainingColSize -= eachSize;
        } else {
          matrix[currentRow][i] += remainingColSize;
          break;
        }
      }

      // Create new row
      matrix.push([colSize]);
      currentRow++;
      currentCol = 1;
      remainingColSize = initialColSize - colSize;
    }
  }

  return matrix.flat();
}

export async function fetchReportLists() {
  let reportLists = [] as IReport[];
  try {
    reportLists = await ReportsDB.getAllReports();
  } catch (error) {
    reportLists = [];
  }

  if (reportLists.length === 0) {
    reportLists = await get_report_list();
    ReportsDB.addReports(reportLists).catch(console.log);
  }

  const updatedList = reportLists
    .filter((report) => Boolean(report.responseFields))
    .map((report) => {
      const isComparable = Object.keys(reportCompareRequiredFields).includes(report.id.toString());

      return {
        ...report,
        isComparable,
        type: WidgetType.REPORT,
        fields: report.responseFields ? JSON.parse(report.responseFields) : []
      };
    });

  return updatedList;
}

export async function fetchLocations() {
  let locationIdPref = find_locationId_preference();
  try {
    let locations = [] as ILocations[];
    try {
      locations = await LocationsDB.getAllLocationsAlways();
    } catch (error) {
      locations = [];
    }

    if (locations.length === 0) {
      const response = await get_location_list();
      locations = response.data.results;
      LocationsDB.addLocations(locations).catch(console.log);
    }

    if (!locationIdPref) {
      locationIdPref = locations[0].id;
    }

    return { locations, id: locationIdPref };
  } catch (error) {
    console.log(error);
    return { locations: [], id: locationIdPref || 1 };
  }
}

export async function saveDashboardDate(
  filters:
    | {
        formType: 'global';
        widgets: { id: string; date: IDateRange; type: IDateRangeType; formFields?: IFormField }[];
      }
    | {
        formType: 'compare';
        widgets: { id: string; selectedDate: ICompareSelectedDate; formFields?: IFormField }[];
      },
  makeApiCall = true
) {
  const currentWidgets = find_dashboard_filter();
  const userData = getUserData();
  const hasUserPreferences = 'preferences' in userData && userData.preferences;

  if (!hasUserPreferences) {
    return message.error(
      'Default Preference has not been set! Please set it in Settings/Preferences first.'
    );
  }

  const defaultLocalStoragePref = userData.preferences.preferences;
  const userPreferences: IUserPreferences = JSON.parse(defaultLocalStoragePref);
  if (!userPreferences) {
    throw {
      type: 'Error',
      message: 'Default Preference has not been set! Please set it in Settings/Preferences first.'
    };
  }
  try {
    userPreferences.userDashboard = currentWidgets.map((widget) => {
      if (filters.formType === 'global') {
        const value = filters.widgets.find((w) => w.id === widget.id);
        if (value) {
          widget.selectedDate = { value: value.date, type: value.type };
          widget.formFields = value?.formFields;
        }
      }

      if (filters.formType === 'compare') {
        const value = filters.widgets.find((w) => w.id === widget.id);
        if (value) {
          if (widget.comparable) widget.comparable.selectedDate = value.selectedDate;
          widget.formFields = value?.formFields;
        }
      }

      return widget;
    });

    setLocalStorage(USER_INFO, {
      ...userData,
      preferences: {
        ...userData.preferences,
        preferences: JSON.stringify(userPreferences)
      }
    });

    if (!makeApiCall) {
      return;
    }

    await update_preference_user_mutation({
      id: userData.id,
      value: JSON.stringify(userPreferences)
    });
  } catch (error) {
    setLocalStorage(USER_INFO, {
      ...userData,
      preferences: {
        ...userData.preferences,
        preferences: defaultLocalStoragePref
      }
    });

    message.error('Error saving selected dates for dashboard');
  }
}

export async function updateDashboardLayout(widgetConfigurations: IWidgetConfiguration[]) {
  const userData = getUserData();
  const hasUserPreferences = 'preferences' in userData && userData.preferences;

  if (!hasUserPreferences) {
    throw {
      type: 'Error',
      message: 'Default Preference has not been set! Please set it in Settings/Preferences first.'
    };
  }

  const defaultLocalStoragePref = userData.preferences.preferences;
  const userPreferences: IUserPreferences = JSON.parse(defaultLocalStoragePref);
  if (!userPreferences) {
    throw {
      type: 'Error',
      message: 'Default Preference has not been set! Please set it in Settings/Preferences first.'
    };
  }
  try {
    userPreferences.userDashboard = widgetConfigurations;
    setLocalStorage(USER_INFO, {
      ...userData,
      preferences: {
        ...userData.preferences,
        preferences: JSON.stringify(userPreferences)
      }
    });

    await update_preference_user_mutation({
      id: userData.id,
      value: JSON.stringify(userPreferences)
    });
  } catch (error) {
    setLocalStorage(USER_INFO, {
      ...userData,
      preferences: {
        ...userData.preferences,
        preferences: defaultLocalStoragePref
      }
    });

    message.error('Error saving dashboard');
  }
}

interface FormValues {
  customRange?: [moment.Moment, moment.Moment];
  compareDateRange?: IDateRangeType;
  firstDate?: moment.Moment;
  secondDate?: moment.Moment;
  singleCustomRange?: [moment.Moment, moment.Moment];
  customCompareDateRange?: [moment.Moment, moment.Moment];
  customFirstDateRange?: [moment.Moment, moment.Moment];
  customSecondDateRange?: [moment.Moment, moment.Moment];
}

export function verifyComparableFields({
  compareDateRange,
  firstDate,
  secondDate,
  singleCustomRange,
  customCompareDateRange,
  customFirstDateRange,
  customSecondDateRange
}: FormValues) {
  let hasError = false;

  const isAllAbsent =
    !compareDateRange &&
    !firstDate &&
    !secondDate &&
    !singleCustomRange &&
    !customCompareDateRange &&
    !customFirstDateRange &&
    customSecondDateRange;

  if (isAllAbsent) {
    message.error('Please select date');
    hasError = true;
  }

  if (!compareDateRange && !singleCustomRange) {
    const isTwoDatesAbsent = (firstDate && !secondDate) || (!firstDate && secondDate);
    if (isTwoDatesAbsent) {
      message.error('Please select two dates to compare');
      hasError = true;
    }
  }

  if (compareDateRange === 'custom' && !customCompareDateRange) {
    message.error('Please select two dates to compare');
    hasError = true;
  }

  return { hasError };
}
