import { ColumnsType } from 'antd/es/table';
import { ITransferLine } from '../../../../services/transfer/types';
import TableCell from '../../../../components/Common/CustomizeTable/CustomCell';
import { nepaliNumberFormatter } from '../../../../utils/numberFormatter';
import CustomizeTable from '../../../../components/Common/CustomizeTable/CustomizeTable';

interface Props {
  data: ITransferLine[];
}

function TableColumn({ data }: Props) {
  const columns: ColumnsType<ITransferLine> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 30,
      render: (a, b, c) => <TableCell>{c + 1}.</TableCell>
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      render: (text: string) => <div>{text}</div>,
      width: 100
    },
    {
      title: 'HS Code',
      dataIndex: 'hsCode',
      render: (text?: string) => <div>{text || 'N/A'}</div>,
      width: 70
    },
    {
      title: 'Quantity',
      key: 'quantity',
      width: 70,
      render: (record) => <div>{`${record.quantity} ${record.unitName}`}</div>
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      width: 70,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },

    {
      title: 'Sub Total',
      width: 70,
      render: (record) => <div>{nepaliNumberFormatter(record.quantity * record.unitPrice)}</div>
    },

    {
      title: 'Discount',
      dataIndex: 'discount',
      width: 70,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: 'VAT',
      dataIndex: 'vat',
      width: 70,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      width: 70,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    }
  ];

  return (
    <CustomizeTable
      columns={columns}
      data={data}
      notshowPagination={true}
      customScroll={{ x: 1200 }}
    />
  );
}

export default TableColumn;
