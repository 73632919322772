import { useState } from 'react';
import { CustomModal } from '../../../components/Common/CustomModal';
import BillPrint from '../../../components/Common/InvoicePrint/BillPrint/BillPrint';
import { ISellInvoice } from '../../../services/invoice/types';

export const BillPrintModal: React.FC<{
  urlData: string;
  getInfo: (url: string) => void;
}> = ({ urlData, getInfo }) => {
  const [openModalForBillPrint, setOpenModalForBillPrint] = useState<boolean>(false);
  const [billData, setBillData] = useState<ISellInvoice>(Object);
  const handleBillPrintModalClose = async () => {
    setOpenModalForBillPrint(false);
    getInfo(urlData);
  };
  return (
    <CustomModal
      footer={false}
      isModalOpen={openModalForBillPrint}
      setIsModalOpen={setOpenModalForBillPrint}
      title="Bill Print">
      <BillPrint
        sellDetails={billData.sellDetails}
        customerDetails={billData.customerDetails}
        lines={billData.lines}
        firstCreatedByUserDetails={billData.firstCreatedByUserDetails}
        invoiceLayouts={billData.invoiceLayouts}
        handleModalClose={handleBillPrintModalClose}
      />
    </CustomModal>
  );
};
