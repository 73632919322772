import { Button, Form, FormInstance, PageHeader, message, Collapse, Spin, Alert } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import Table from 'antd/lib/table';
import React, { useState } from 'react';
import AppContent from '@/components/Common/Content/Content';
import moment from 'moment';

import { download_report, get_report } from '@/services/report/queries';
import { numberDecimalFormatter } from '@/utils/numberFormatter';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { get_product_list_ids, get_units_list } from '@/services/products/queries';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { shortNameHiearchy } from '../../../sell/sell-order/view';
import { AxiosResponse } from 'axios';
import { IProductListResponse, IProductType } from '@/services/products/types';
import { check_date_cap, check_date_cap_limit, check_export_report_date_cap } from '@/utils/common';
import { showReportConditionalDownloadMessage } from '@/utils/reportConditionalDownloadMessage';
import { excelExportColumns, getUpdatedData, tableExportColumns } from './column.export';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportAllData from '@/components/Common/ExportAll';
import ExportCurrent from '@/components/Common/ExportCurrent';

interface ReportData {
  count: any;
  data: any;
}

interface State {
  id: number[];
  name: string;
}
interface ReportProps {
  state: State;
  breadcrumbItems: any[];
  form: FormInstance<any>;
  columnsData: any[];
  footerData?: { sell?: boolean; purchase?: boolean };
  toSort?: any;
}

const CustomReport: React.FC<ReportProps> = ({
  state,
  breadcrumbItems,
  form,
  columnsData,
  footerData = {
    sell: false,
    purchase: false
  },
  toSort,
  children
}) => {
  const [data, setData] = useState<ReportData>({ count: 0, data: [] });
  const [size, setSize] = useState(100);

  const [footer, setFooterData] = useState({
    tpurchase_return: '0',
    tpurchased: '0',
    tsell_return: '0',
    tsold: '0',
    ttransfer_in: '0',
    ttransfer_out: '0',
    twastage: '0'
  });
  const [page, setPage] = useState<number>(1);
  const [isloading, setIsloading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const [fullExportURL, setFullExportURL] = useState<any>(null);

  const onSearch = async ({
    values,
    storePage = false,
    type
  }: {
    values: any;
    storePage?: boolean;
    type?: any;
  }) => {
    setIsloading(true);
    if (!storePage) {
      values.constraints['page'] = 1;
      values.constraints['size'] = size;
      setPage(1);
    }
    const dateDiff = moment(values.constraints.endDate).diff(values.constraints.startDate, 'days');
    if (check_date_cap_limit(values.constraints.startDate, values.constraints.endDate, 2)) {
      message.error(`${dateDiff} days selected.Please select date range no longer than 2 months!`);
      setIsloading(false);
      return;
    }
    if (check_date_cap(values.constraints.startDate, values.constraints.endDate)) {
      await downloadReport();
      setIsloading(false);
      return;
    }
    //remove custom date
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    const response = await get_report(
      {
        ...values.constraints
        // dailyAverageDays: dateDiff
      },
      state.id[0]
    );
    if (response.data.data.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
    response.data = await calculateTotalDetail(response.data);
    const payload = values.constraints as any;
    payload.size = response.data.count;
    setFullExportURL(payload);
    setData(response.data);
    setIsloading(false);
  };

  const calculateTotalDetail = async (data: any) => {
    const purchasedtotalq: any = {};
    const purchase_return_totalq: any = {};
    const sellreturntotalq: any = {};
    const soldtotalq: any = {};
    const transfer_intotalq: any = {};
    const transfer_outtotalq: any = {};
    const wastagetotalq: any = {};

    for (const productQuantity of data.data) {
      if (!purchasedtotalq[productQuantity.unit]) {
        purchasedtotalq[productQuantity.unit] = Number(productQuantity.purchased);
      } else {
        purchasedtotalq[productQuantity.unit] += +Number(productQuantity.purchased);
      }

      if (!purchase_return_totalq[productQuantity.unit]) {
        purchase_return_totalq[productQuantity.unit] = Number(productQuantity.purchase_return);
      } else {
        purchase_return_totalq[productQuantity.unit] += Number(productQuantity.purchase_return);
      }

      if (!sellreturntotalq[productQuantity.unit]) {
        sellreturntotalq[productQuantity.unit] = Number(productQuantity.sell_return);
      } else {
        sellreturntotalq[productQuantity.unit] += Number(productQuantity.sell_return);
      }

      if (!soldtotalq[productQuantity.unit]) {
        soldtotalq[productQuantity.unit] = Number(productQuantity.sold);
      } else {
        soldtotalq[productQuantity.unit] += Number(productQuantity.sold);
      }

      if (!transfer_intotalq[productQuantity.unit]) {
        transfer_intotalq[productQuantity.unit] = Number(productQuantity.transfer_in);
      } else {
        transfer_intotalq[productQuantity.unit] += Number(productQuantity.transfer_in);
      }

      if (!transfer_outtotalq[productQuantity.unit]) {
        transfer_outtotalq[productQuantity.unit] = Number(productQuantity.transfer_out);
      } else {
        transfer_outtotalq[productQuantity.unit] += Number(productQuantity.transfer_out);
      }

      if (!wastagetotalq[productQuantity.unit]) {
        wastagetotalq[productQuantity.unit] = Number(productQuantity.wastage);
      } else {
        wastagetotalq[productQuantity.unit] += Number(productQuantity.wastage);
      }
    }

    setFooterData((prev) => {
      return {
        ...prev,
        tpurchase_return: calculateTotalQuantityString(purchase_return_totalq),
        tpurchased: calculateTotalQuantityString(purchasedtotalq),
        tsell_return: calculateTotalQuantityString(sellreturntotalq),
        tsold: calculateTotalQuantityString(soldtotalq),
        ttransfer_in: calculateTotalQuantityString(transfer_intotalq),
        ttransfer_out: calculateTotalQuantityString(transfer_outtotalq),
        twastage: calculateTotalQuantityString(wastagetotalq)
      };
    });

    return data;
  };

  const addPage = async (value: number, sizeVal = 0) => {
    //check total pages
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();
      if (sizeVal !== 0) {
        allValues.constraints.page = 1;
        allValues.constraints.size = sizeVal;
        setPage(1);
        setSize(sizeVal);
      } else {
        allValues.constraints.page = value;
        allValues.constraints.size = size;
        setPage(value);
      }
      onSearch({ values: allValues, storePage: true, type: ['detail'] });
    } catch (e: any) {
      console.log('validate error', e);
    }
  };

  const downloadReport = async () => {
    // console.log('Clicked');
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsloading(true);
    const values = form.getFieldsValue();
    await form.validateFields();
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    try {
      if (check_export_report_date_cap(values.constraints.startDate, values.constraints.endDate)) {
        // const response =
        values.constraints.customReportQueueName = 'All Product Quantity Report';
        message.info({
          key: 'downloading',
          content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
        });

        await download_report(
          {
            ...values.constraints
          },
          state.id[0]
        );
        setIsloading(false);
        // window.open(response.data, '_blank');
      }
    } catch (err: any) {
      setIsloading(false);
      message.error(err.message);
    }
  };

  const calculateTotalQuantityString = (totalQuantity: any) => {
    let totalQuantityString = '';
    const totalQuantityArray: any[] = [];
    for (const key in totalQuantity) {
      totalQuantityArray.push({
        tqty: totalQuantity[key],
        unit: key
      });
    }
    if (totalQuantityArray.length == 0) return '0';
    totalQuantityArray.sort((a: any, b: any) => {
      return shortNameHiearchy.indexOf(a.unit) - shortNameHiearchy.indexOf(b.unit);
    });
    for (let ind = 0; ind < totalQuantityArray.length; ind++) {
      totalQuantityString +=
        totalQuantityArray[ind].tqty == 0
          ? ''
          : ` ${numberDecimalFormatter(totalQuantityArray[ind].tqty)} ${
              totalQuantityArray[ind].unit
            }`;
    }
    if (totalQuantityString == '') return '0';
    return totalQuantityString;
  };

  const updatedData = getUpdatedData(data.data);

  async function onSearchAll() {
    try {
      setIsloading(true);
      if (!fullExportURL) {
        message.error('No URL specified. Please search first.');
        return;
      }
      const response = await get_report(fullExportURL, state.id);
      response.data = await calculateTotalDetail(response.data);
      return getUpdatedData(response.data.data) as any;
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  }

  return (
    <div>
      <Spin spinning={isloading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <Collapse>
            <CollapsePanel header="Filters" key="1">
              <Form
                form={form}
                onFinish={(values) => onSearch({ values })}
                layout="vertical"
                validateTrigger={'onChange'}
                autoComplete="off"
                disabled={isloading}>
                <PageHeader
                  title={state?.name}
                  style={{
                    padding: '8px 0px'
                  }}
                />
                {React.Children.count(children) > 1 && (
                  <>
                    <PageHeader
                      subTitle="Constraints"
                      style={{
                        padding: '8px 0px'
                      }}
                    />
                  </>
                )}
                <div className="grid grid-cols-4 gap-3 ">{children}</div>
                <div className="flex justify-end mt-5 gap-3">
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
                      Search
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="secondary-button"
                      style={{ borderRadius: '6px' }}
                      onClick={() => downloadReport()}>
                      Download
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </CollapsePanel>
          </Collapse>
          <div className="mt-2">
            <Alert
              message="Information!"
              description="Please note, data is calculated at 00:00 every day. Today's data might not be visible."
              type="info"
              closable
              showIcon
            />
          </div>

          <PageHeader
            subTitle="Table"
            style={{
              padding: '8px 0px'
            }}
          />
          <CustomizeTable
            customScroll={{ x: 1000, y: 500 }}
            tableName="al=product-quantity-report"
            notshowPagination={true}
            columns={columnsData[0]}
            toSort={toSort}
            data={data.data}
            tableSummary={
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3} className="text-right mr-2">
                    {footer.tsold}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} className="text-right mr-2">
                    {footer.tpurchased}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} className="text-right mr-2">
                    {footer.tsell_return}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} className="text-right mr-2">
                    {footer.tpurchase_return}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} className="text-right mr-2">
                    {footer.twastage}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} className="text-right mr-2">
                    {footer.ttransfer_in}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} className="text-right mr-2">
                    {footer.ttransfer_out}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            }
            buttons={
              <>
                <ExportAllData
                  title="Product Quantity (All)"
                  columns={tableExportColumns}
                  excelColumns={excelExportColumns}
                  getInfo={onSearchAll}
                />
                <ExportCurrent
                  data={{ total: data.count, results: updatedData }}
                  columns={tableExportColumns}
                  excelColumns={excelExportColumns}
                  title="Product Quantity"
                />
              </>
            }
            paginationDatas={{
              page,
              total: data.count,
              size,
              onPagination: (pageNo, currentSize) => {
                currentSize == size ? addPage(pageNo) : addPage(pageNo, currentSize);
              }
            }}
          />
        </AppContent>
      </Spin>
    </div>
  );
};

export default CustomReport;
