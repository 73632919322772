import * as ExcelJS from 'exceljs';

import { IPnLTableWithTransactionData } from '@/services/accounts/types';

interface Props {
  totalBalance: { credit: number; debit: number; balance: number };
  data: (IPnLTableWithTransactionData & { date: string; miti: string })[];
  date: { startDate: string; endDate: string };
  valueExport: number;
}

function exportToExcel({ totalBalance, data, date, valueExport }: Props) {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Sheet 1');

  const colorPalette = ['B0B0B0', 'D3D3D3', 'FFFFFF'];
  const header = ['Particular', 'Debit', 'Credit', 'Total', 'Date', 'Miti'];
  const footer = ['Total', totalBalance.debit, totalBalance.credit, totalBalance.balance, '', ''];

  const rows: (string | number)[][] = data.map((item) => {
    const transaction = item.transaction || { totalDebit: 0, totalCredit: 0 };

    return [
      item.name,
      transaction?.totalDebit,
      transaction?.totalCredit,
      transaction?.totalDebit - transaction?.totalCredit,
      item.date,
      item.miti
    ];
  });

  const excelData = [header, ...rows];
  sheet.spliceRows(
    1,
    0,
    ['Ledger'],
    [`From: ${date.startDate}`, '', '', '', '', `To: ${date.endDate}`]
  );

  sheet.mergeCells('A1:F1');
  const mainHeadingCell = sheet.getCell('A1');
  mainHeadingCell.alignment = { horizontal: 'center' };
  mainHeadingCell.font = { bold: true };

  // Add data and format header row
  excelData.forEach((rowData, rowIndex) => {
    const row = sheet.addRow(rowData);
    // Make header row bold
    if (rowIndex === 0) {
      row.eachCell((cell) => {
        cell.font = { bold: true };
      });
    } else {
      const item = data[rowIndex - 1];

      row.eachCell((cell) => {
        const node = item.node ? item.node : 0;
        const colorIndex = node % colorPalette.length;
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: colorPalette[colorIndex] }
        };

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
    }
  });

  // Fill the first 3 rows with green color
  for (let i = 1; i <= 3; i++) {
    sheet.getRow(i).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '50C878' } // Green color
      };
    });
  }

  // Set cell widths
  const columnWidths = [30, 20, 20, 20, 20, 20];
  columnWidths.forEach((width, index) => {
    sheet.getColumn(index + 1).width = width;
  });

  // Footer
  const lastRowIndex = sheet.rowCount;
  const footerRow = sheet.getRow(lastRowIndex + 1);

  footer.forEach((value, index) => {
    footerRow.getCell(index + 1).value = value;
  });

  footerRow.eachCell((cell) => {
    cell.font = { bold: true };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '50C878' }
    };
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
  });

  // Generate the Excel file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = valueExport === 1 ? 'Profit_and_Loss_All.xlsx' : 'Profit_and_Loss.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  });
}

export default exportToExcel;
