import { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import type { ChartData, ChartOptions } from 'chart.js';
import { chartColors } from '@/chart.config';
import getPascalCase from '@/utils/getPascalCase';
import WidgetButtons from '../widget/WidgetButtons';
import { DEFAULT_BORDER_RADIUS } from '../constant.dashboard';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { IChartBaseConfig } from '@/services/dashboard/v3/types';
import { truncateString } from '@/services/dashboard/v3/services.dashboard';

interface Props extends IChartBaseConfig {
  allowZoom?: boolean;
  isGradient?: boolean;
  borderRadius?: number;
  maxTicksLimit?: number;
  xAxisLabel?: string;
}

function BarChart({
  allowZoom,
  title,
  isGradient,
  allowFilter,
  fields,
  data,
  maxTicksLimit = 10,
  xAxisLabel,
  enlargeChart,
  borderRadius = DEFAULT_BORDER_RADIUS,
  handleFilterOpen,
  handleFullScreen
}: Props) {
  const chartRef = useRef<any>();

  const labels = Object.keys(data);
  const datasets = labels.map((key) => {
    const values = data[key];

    // Calculate Fields Total
    if (values.length) {
      return values.reduce((acc: Record<string, number>, item: Record<string, number | string>) => {
        fields.forEach((field) => {
          const value = Number(item[field]);
          acc[field] = (acc[field] || 0) + value;
        });
        return acc;
      }, {});
    }
  });

  const chartData: ChartData<'bar'> = {
    labels,
    datasets: fields.map((label, index) => ({
      label: getPascalCase(label),
      data: datasets.map((data) => data?.[label] || 0),
      fill: isGradient,
      backgroundColor(context) {
        const chart = context.chart;
        const { ctx, chartArea } = chart;

        if (!chartArea) return;
        if (!isGradient) return chartColors[index].backgroundColor;

        const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
        gradient.addColorStop(0, chartColors[index].backgroundColor);
        gradient.addColorStop(1, 'rgba(255,255,255,0.5)');

        return gradient;
      },
      borderColor: chartColors[index].borderColor,
      borderWidth: 1,
      borderRadius,
      maxBarThickness: 32
    }))
  };

  const chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxis: {
        ticks: {
          maxTicksLimit,
          callback: function (value, index) {
            const label = this.getLabelForValue(index);
            return truncateString(label);
          }
        },
        title: { display: Boolean(xAxisLabel), text: xAxisLabel, font: { size: 10 } }
      }
    },
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          usePointStyle: true,
          boxHeight: 10,
          font: {
            size: 10
          }
        }
      },
      title: { display: Boolean(title), text: title },
      zoom: {
        pan: { enabled: allowZoom, mode: 'x', modifierKey: 'ctrl', threshold: 10 },
        zoom: { drag: { enabled: allowZoom }, mode: 'x' }
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            const label = context[0].label || '';
            return truncateString(label, 50);
          },
          label: (context) => {
            const label = context.dataset.label || context.label || '';
            const value = context.parsed.y || 0;
            return `${label}: ${nepaliNumberFormatter(value)}`;
          }
        }
      }
    }
  };

  return (
    <>
      <Bar ref={chartRef} data={chartData} options={chartOptions} />
      <WidgetButtons
        chartRef={chartRef}
        enlargeChart={enlargeChart}
        allowZoom={allowZoom ?? false}
        allowFilter={allowFilter}
        handleFilterOpen={handleFilterOpen}
        handleFullScreen={handleFullScreen}
      />
    </>
  );
}

export default BarChart;
