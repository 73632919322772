import { find_to_use_NPR } from '@/store/localstorage/preferences';

export const tableExportColumns = [
  {
    label: 'Reference No',
    dataIndex: 'referenceNo',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Paid On',
    dataIndex: 'paidOn',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Invoice Date',
    dataIndex: 'invoiceDate',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Added By',
    dataIndex: 'addedBy',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Amount',
    dataIndex: 'amount',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Customer',
    dataIndex: 'customer',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Payment Method',
    dataIndex: 'paymentMethod',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));
