import { ICustomItemWiseSellReport } from '@/services/report/types';

const fields = [
  'ITEM CODE',
  'ITEM NAME',
  'UNIT',
  'SALE QTY',
  'RETURN QTY',
  'NET QTY',
  'GROSS VALUE',
  'DISCOUNT',
  'NET VALUE',
  'TAXABLE',
  'NON TAXABLE',
  'VAT',
  'NET AMOUNT',
  'LOCATION',
  'CUSTOMER'
];

export const tableExportColumns = fields.map((field) => ({
  label: field,
  dataIndex: field,
  render: (text: string) => {
    return <div className="text-center">{text}</div>;
  }
}));

export const excelExportColumns = fields.map((field) => ({
  title: field,
  dataIndex: field
}));

export function getUpdatedData(data: ICustomItemWiseSellReport[]) {
  return data.map((item) => {
    return {
      ...item
      // DATE: convertUTCStringtoLocalString(item.DATE, 'YYYY-MM-DD'),
      // TIME: moment(item.TIME, 'HH:mm').format('hh:mm a')
    };
  });
}
