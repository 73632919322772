import { Form, Checkbox, InputNumber, Tooltip } from 'antd';

import { IChartType } from '@/services/dashboard/v3/types';
import { InfoCircleTwoTone } from '@ant-design/icons';

interface Props {
  selectedChartType: IChartType;
}

const ChartFeaturesForm = ({ selectedChartType }: Props) => {
  if (selectedChartType === 'line') {
    return (
      <div className="flex gap-2 flex-wrap">
        <Form.Item name="isSmooth" valuePropName="checked">
          <Checkbox>Smooth Line</Checkbox>
        </Form.Item>
        <Form.Item name="isGradient" valuePropName="checked">
          <Checkbox>Gradient Fill</Checkbox>
        </Form.Item>
      </div>
    );
  }

  if (selectedChartType === 'bar') {
    return (
      <div className="flex gap-2 flex-wrap">
        <Form.Item name="isGradient" valuePropName="checked">
          <Checkbox>Gradient Fill</Checkbox>
        </Form.Item>
        <Form.Item name="borderRadius" label="Border Radius" className="flex-1">
          <InputNumber
            controls={false}
            min={0}
            placeholder="Border Radius for Bar Chart"
            className="w-full"
            max={20}
          />
        </Form.Item>
      </div>
    );
  }

  if (selectedChartType === 'pie') {
    return (
      <div className="flex items-center gap-2">
        <Form.Item
          name="pieChartLimit"
          label="Data Limit"
          className="flex-1"
          rules={[{ required: true, message: 'Limit is required' }]}>
          <InputNumber
            controls={false}
            min={0}
            placeholder="Limit for Pie Chart"
            addonAfter="%"
            className="w-full"
            max={99}
          />
        </Form.Item>
        <Tooltip title="Values less than percentage of the max value won't be shown. Maximum data shown is 10">
          <InfoCircleTwoTone className="[&>svg]:-mt-2 cursor-pointer" />
        </Tooltip>
      </div>
    );
  }

  return <div></div>;
};

export default ChartFeaturesForm;
