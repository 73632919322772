import { DashboardDatePresets } from '@/pages/sqlsource/report/utils/datePresets';
import { CompareType } from '@/services/dashboard/v3/enums';
import { DatePicker, Form, FormInstance, PageHeader } from 'antd';

interface Props {
  form: FormInstance;
  compareType: CompareType;
}

function CustomSingleRangeUI({ form, compareType }: Props) {
  const handleCustomCompareChange = () => {
    form.setFieldsValue({
      firstDate: undefined,
      secondDate: undefined,
      compareDateRange: undefined
    });
  };

  return (
    <div>
      <PageHeader className="flex-1" subTitle="Select Range Date" style={{ padding: '4px 0' }} />
      <Form.Item
        name="singleCustomRange"
        label="Select Range"
        rules={[
          {
            required: compareType === CompareType.CUSTOM_RANGE,
            message: 'Please select date'
          }
        ]}>
        <DatePicker.RangePicker
          data-no-dnd="true"
          showTime
          allowClear
          className="w-full"
          onChange={handleCustomCompareChange}
          ranges={DashboardDatePresets}
          format={'YYYY-MM-DD hh:mm a'}
        />
      </Form.Item>
    </div>
  );
}

export default CustomSingleRangeUI;
