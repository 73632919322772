import { configureStore } from '@reduxjs/toolkit';
import listSlice from './reducers/List/list.reducer';
import locationsSlice from './reducers/cache/locations.reducer';
import productsSlice from './reducers/cache/products.reducer';
import unitsSlice from './reducers/cache/units.reducer';
import LocalStore from './localstorage';

export const store = configureStore({
  reducer: {
    lists: listSlice,
    locations: locationsSlice,
    units: unitsSlice,
    products: productsSlice
  }
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
