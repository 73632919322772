import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import React, { useEffect } from 'react';

export interface IColumnDataForCustomTable {
  dataIndex: string;
  title: string;
  isNumber?: boolean;
  width?: number;
  children?: IColumnDataForCustomTable[];
}

export interface IcustomTable {
  columns: IColumnDataForCustomTable[];
  data: any[];
  reff?: any;
  onAuto?: () => void;
  exportYear: { from: string; to: string };
  highlightHeader?: boolean;
  showEmptyRow?: boolean;
  title?: string;
  subTitle?: string;
}

const CustomTableForIRDItemWise: React.FC<IcustomTable> = ({
  columns,
  data,
  reff,
  exportYear,
  highlightHeader,
  title,
  subTitle,
  onAuto
}) => {
  // console.log('this is from custom table', datas);
  // console.log('this is from columns', columns);
  const firstRow = columns.map((value) => {
    return {
      title: value.title,
      width: value.width,
      colspan: value.children?.length !== undefined ? value.children?.length : 0,
      rowspan: value.children?.length !== undefined && value.children?.length > 0 ? 1 : 2
    };
  });

  const secondRow: any[] = [];
  columns.forEach((value) => {
    if (value.children?.length !== undefined) {
      value.children.forEach((valueChild) => {
        secondRow.push({
          isNumber: valueChild.isNumber,
          title: valueChild.title,
          width: valueChild.width,
          dataIndex: valueChild.dataIndex,
          colspan: 1,
          rowspan: 1
        });
      });
    } else {
      secondRow.push({
        isNumber: value.isNumber,
        title: value.title,
        width: value.width,
        dataIndex: value.dataIndex,
        colspan: 0,
        rowspan: 0
      });
    }
  });

  // console.log('first Row', firstRow);
  // console.log('second Row', secondRow);
  useEffect(() => {
    if (onAuto) {
      onAuto();
    }
  }, [data]);

  return (
    <div className="flex flex-col justify-center items-center w-full" ref={reff}>
      <table width={'100%'}>
        <thead>
          <tr>
            <th colSpan={secondRow.length} className="text-lg font-extrabold">
              FreshKtm Agro Pvt. Ltd.
            </th>
          </tr>
          <tr>
            <th colSpan={secondRow.length} className="font-normal">
              Suryabinaya-8, Bhaktapur
            </th>
          </tr>
          <tr>
            <th colSpan={secondRow.length} className="font-bold">
              VAT No : 609891700
            </th>
          </tr>
          <tr>
            <th colSpan={secondRow.length} style={{ height: 15 }}></th>
          </tr>
          <tr>
            <th colSpan={secondRow.length} className="font-extrabold text-lg">
              {title}
            </th>
          </tr>
          <tr>
            <th colSpan={secondRow.length} className="font-bold">
              {subTitle}
            </th>
          </tr>

          <tr>
            <th colSpan={secondRow.length} className="text-left">
              <span className="w-12 inline-block">From:</span>
              <span className="font-normal">{exportYear.from}</span>
            </th>
          </tr>

          <tr>
            <th colSpan={secondRow.length}>
              <div className="flex justify-between">
                <div className="text-left">
                  <span className="w-12 inline-block">To:</span>
                  <span className="font-normal">{exportYear.to}</span>
                </div>
                <div>
                  <span className="w-20 inline-block text-left">Division:</span>
                  <span className="font-normal inline-block">All</span>
                </div>
              </div>
            </th>
          </tr>

          <tr>
            <th colSpan={secondRow.length} style={{ height: 15 }}></th>
          </tr>
          <tr>
            {firstRow.map((currCol) => (
              <th
                key={currCol.title}
                rowSpan={currCol.rowspan}
                colSpan={currCol.colspan}
                style={{
                  ...tdthStyle,
                  background: highlightHeader ? '#e7e6e6' : 'transparent',
                  maxWidth: currCol.title === 'URL' ? '180px' : 'auto'
                }}>
                <div className="text-center">{currCol.title}</div>
              </th>
            ))}
          </tr>
          <tr>
            {secondRow.map((currCol) => {
              if (currCol.colSpan !== 0 && currCol.rowspan !== 0) {
                return (
                  <th
                    key={currCol.dataIndex}
                    style={{
                      ...tdthStyle,
                      maxWidth: currCol.title === 'URL' ? '180px' : 'auto',
                      background: highlightHeader ? '#e7e6e6' : 'transparent'
                    }}>
                    <div className="text-center">{currCol.title}</div>
                  </th>
                );
              }
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((currData: any, indr: number) => {
            return (
              <tr key={`${currData}${indr}`}>
                {secondRow.map((currCol, indc: number) => {
                  return (
                    <td
                      key={indr + indc}
                      style={{
                        ...tdthStyle,
                        padding: '0 3px',
                        maxWidth: ['URL', 'Event'].includes(currCol.title) ? '180px' : 'auto'
                      }}
                      className="text-center">
                      {currCol.isNumber
                        ? nepaliNumberFormatter(currData[currCol.dataIndex])
                        : currData[currCol.dataIndex]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const tdthStyle = {
  border: '1px solid black',
  padding: '2px 0px'
};

export default CustomTableForIRDItemWise;
