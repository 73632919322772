import React, { useEffect } from 'react';
import { nepaliNumberFormatter } from '../../../utils/numberFormatter';

interface CustomTable {
  linesData: Array<[]>;
  taxable: number;
  nonTaxable: number;
  vat: number;
}

const CustomTableForSellReturn: React.FC<CustomTable> = ({
  linesData,
  taxable,
  nonTaxable,
  vat
}) => {
  const state = JSON.parse(localStorage.getItem('referrer') as string);
  const { purchaseDetails, lines, invoiceLayouts, routeDetails } = state;
  // console.log('lines', lines);
  // console.log('invoiceLayouts', invoiceLayouts);
  let content;
  if (invoiceLayouts?.content) {
    content = JSON.parse(invoiceLayouts.content);
  }

  // useEffect(() => {
  //   window.print();
  //   window.onafterprint = (event) => {
  //     window.close();
  //     localStorage.removeItem('referrer');
  //   };
  // }, []);

  const headers: any = [
    'S.N',
    content?.productLabel ? content.productLabel : 'Description',
    content?.quantityLabel ? content.quantityLabel : 'Quantity',
    content?.unitPriceLabel ? content.unitPriceLabel + '(NPR)' : 'unitPrice(NPR)',
    content?.discountColumnLabel ? content.discountColumnLabel + '(NPR)' : 'Discount(NPR)',
    content?.vatColumnLabel ? content.vatColumnLabel + '(NPR)' : 'VAT(NPR)',
    content?.subtotalLabel ? content.subtotalLabel + '(NPR)' : 'Amount(NPR)'
  ];

  return (
    <div className="flex justify-center items-center">
      <table width={'95%'}>
        {headers.map((curr: any, ind: any) => {
          return (
            <th key={ind} style={thStyle}>
              {curr}
            </th>
          );
        })}
        {linesData.map((currdata: any, ind: any) => {
          return (
            <tr key={currdata}>
              <td style={thStyle}>
                <div className="text-center">{ind + 1}.</div>
              </td>
              <td style={thStyle}>
                <div className="ml-1">{currdata.productName}</div>
              </td>
              <td style={thStyle}>
                <div className="text-right mr-1">{`${currdata.quantity} ${currdata.shortName}`}</div>
              </td>
              {/* <td style={thStyle}>
                <div className="text-right mr-1">{currdata.unit}</div>
              </td> */}
              <td style={thStyle}>
                <div className="text-right mr-1">{currdata.unitPrice.toFixed(2)}</div>{' '}
              </td>
              <td style={thStyle}>
                <div className="text-right mr-1">{currdata.discount.toFixed(2)}</div>{' '}
              </td>
              <td style={thStyle}>
                <div className="text-right mr-1">
                  {currdata.vat != undefined ? currdata.vat.toFixed(2) : ''}
                </div>{' '}
              </td>
              <td style={thStyle}>
                <div className="text-right mr-1">
                  {(currdata.totalAmount - currdata.vat).toFixed(2)}
                </div>{' '}
              </td>
            </tr>
          );
        })}
        {linesData.length < 16 &&
          [...Array(16 - lines.length)].map((curr: any) => {
            return (
              <tr key={curr} style={{ height: '1.5rem' }}>
                <td style={tdStyle}>
                  <div className="text-center">{}</div>
                </td>
                <td style={tdStyle}>
                  <div className="ml-1">{}</div>
                </td>
                <td style={tdStyle}>
                  <div className="text-right mr-1">{}</div>
                </td>
                <td style={tdStyle}>
                  <div className="text-right mr-1">{}</div>
                </td>
                <td style={tdStyle}>
                  <div className="text-right mr-1">{}</div>{' '}
                </td>
                <td style={tdStyle}>
                  <div className="text-right mr-1">{}</div>{' '}
                </td>
                <td style={tdStyle}>
                  <div className="text-right mr-1">{}</div>{' '}
                </td>
              </tr>
            );
          })}
        <tr>
          <td colSpan={2} style={thStyle}>
            <span>Total Return Quantity</span>:
          </td>
          <td colSpan={1} style={thStyle}>
            <div className="text-center">{purchaseDetails.totalQuantity}</div>
          </td>
          <td colSpan={3} style={thStyle}>
            <div className="text-center">
              {content?.totalReturnLabel ? content.totalReturnLabel : 'Total Return Amount'}
            </div>
          </td>
          <td colSpan={1} style={thStyle} className="text-right">
            {nepaliNumberFormatter(purchaseDetails.totalAmount - Math.floor(vat))}
          </td>
        </tr>
        <tr>
          <td colSpan={3} rowSpan={6} style={thStyle}>
            <div className="text-start">
              {content?.totalWordsLabel ? content.totalWordsLabel : 'In Words'}
            </div>
          </td>
          {/* <td colSpan={2} style={thStyle}>
            <div className="text-center">
              {content?.discountLabel ? content.discountLabel : 'Discount'}
            </div>
          </td>
          <td style={thStyle} className="text-right">
            {nepaliNumberFormatter(purchaseDetails.totalDiscount)}
          </td> */}
        </tr>
        <tr>
          <td colSpan={3} style={thStyle}>
            <div className="text-center">Taxable</div>
          </td>
          <td style={thStyle} className="text-right">
            {nepaliNumberFormatter(taxable)}
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={thStyle}>
            <div className="text-center">Non Taxable</div>
          </td>
          <td style={thStyle} className="text-right">
            {nepaliNumberFormatter(nonTaxable)}
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={thStyle}>
            <div className="text-center">{content?.vatLabel ? content.vatLabel : '13% VAT'}</div>
          </td>
          <td style={thStyle} className="text-right">
            {nepaliNumberFormatter(vat)}
          </td>
        </tr>
        {/* <tr>
          <td colSpan={3} style={thStyle}>
            <div className="text-center">
              {content?.shippingChargeLabel ? content?.shippingChargeLabel : 'Shipping Charge'}
            </div>
          </td>
          <td style={thStyle} className="text-right">
            {nepaliNumberFormatter(purchaseDetails.shipping)}
          </td>
        </tr> */}
        <tr>
          <td colSpan={3} style={thStyle}>
            <div className="text-center">
              {content?.netReturnTotalLabel ? content?.netReturnTotalLabel : 'Net Return Amount'}
            </div>
          </td>
          <td style={thStyle} className="text-right">
            {nepaliNumberFormatter(purchaseDetails.totalAmount)}
          </td>
        </tr>
        <tr>
          <td colSpan={7} style={thStyle}>
            <div>Remarks:</div>
          </td>
        </tr>
      </table>
    </div>
  );
};

const thStyle = {
  border: '1px solid black'
};

const tdStyle = {
  borderLeft: '1px solid black',
  borderRight: '1px solid black'
};

export default CustomTableForSellReturn;
