import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, DatePicker, PageHeader, Spin, Checkbox, message, Modal } from 'antd';

import AppContent from '@/components/Common/Content/Content';
import { create_financial_year } from '@/services/financial-year/queries';
import { FinancialYearDB } from '@/store/localstorage/FinancialYearDB';
import { RangePickerProps } from 'antd/lib/date-picker';
import { DEFAULT_DATE_FORMAT } from '@/constants';

interface FormValues {
  name: string;
  dateRange: [moment.Moment, moment.Moment];
  isClosed: boolean;
}

function FinancialYearCreate() {
  const [form] = Form.useForm<FormValues>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedData, setSelectedData] = useState(['', '']);

  const onChange: RangePickerProps['onChange'] = (_, dateStrings) => {
    if (dateStrings.length == 2) {
      const [startDate, endDate] = dateStrings;
      const newStartDate = moment(startDate).startOf('day');
      const newEndDate = moment(endDate).endOf('day').set({ millisecond: 0 });

      setSelectedData([newStartDate.toISOString(), newEndDate.toISOString()]);
    }
  };

  async function handleFormEvaluate() {
    const values = await form.validateFields();
    if (values.dateRange.length !== 2) {
      message.error('Please select valid date range');
      return;
    }

    setOpenConfirmation(true);
  }

  async function handleFormSubmit() {
    try {
      setIsLoading(true);
      setOpenConfirmation(false);
      const values = await form.validateFields();

      if (values.dateRange.length !== 2) {
        message.error('Please select valid date range');
        return;
      }

      const data = {
        name: values.name,
        startDate: values.dateRange[0].startOf('day').toISOString(),
        endDate: values.dateRange[1].endOf('day').set({ millisecond: 0 }).toISOString(),
        isClosed: values.isClosed
      };

      const response = await create_financial_year(data);
      if (response) {
        await FinancialYearDB.add([response]);
      }

      message.success('Financial Year created successfully');
      navigate('/accounts/financial-year');
    } catch (error: any) {
      const hasError = error?.response?.data?.message || 'Failed to create financial year';
      message.error(hasError);
    } finally {
      setIsLoading(false);
      setOpenConfirmation(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={[
          { label: 'Accounts', link: '/accounts' },
          { label: 'FinancialYear', link: '/accounts/financial-year' },
          { label: 'Create', link: '/accounts/financial-year/new' }
        ]}>
        <Modal
          title="Confirmation"
          visible={openConfirmation}
          onOk={handleFormSubmit}
          onCancel={() => {
            setOpenConfirmation(false);
          }}>
          <div>
            <span className="block">Do you want to create this financial year?</span>
            {`${moment(selectedData[0]).format(DEFAULT_DATE_FORMAT)} - ${moment(
              selectedData[1]
            ).format(DEFAULT_DATE_FORMAT)}`}
          </div>
        </Modal>
        <Form
          form={form}
          initialValues={{ isClosed: false }}
          onFinish={handleFormEvaluate}
          layout="vertical"
          autoComplete="off"
          disabled={isLoading}
          validateTrigger={'onChange'}>
          <PageHeader title="Financial Year Information" style={{ padding: '8px 0px' }} />
          <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Name is required' }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label={'Select Year Range'}
              name={'dateRange'}
              rules={[{ required: true, message: 'Year Range is required' }]}>
              <DatePicker.RangePicker
                format={'YYYY-MM-DD'}
                allowClear
                onChange={onChange}
                className="w-full"
              />
            </Form.Item>

            <Form.Item label={'Status'} name={'isClosed'} valuePropName="checked">
              <Checkbox>Close Financial Year</Checkbox>
            </Form.Item>
          </div>

          <div className="flex sm:justify-end gap-4 mt-8 flex-wrap">
            <Button type="primary" htmlType="submit">
              Create
            </Button>

            <Button onClick={() => navigate(-1)}>Back</Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default FinancialYearCreate;
