import useDebounce from '@/hooks/useDebounce';
import { InputNumber, message } from 'antd';
import { useEffect, useState } from 'react';

interface Props {
  placeholder?: string;
  errorMessage?: string;
  restrictedNumbers?: number[];
  min?: number;
  max?: number;
  debouncedOnChange?: (value?: number) => void;
  defaultValue?: number;
}

function DebouncedInputNumber({
  restrictedNumbers = [],
  defaultValue,
  placeholder = 'Enter a number',
  errorMessage,
  min,
  max,
  debouncedOnChange
}: Props) {
  const [searchValue, setSearchValue] = useState<number>();

  const debouncedSearchValue = useDebounce(searchValue, 500);

  useEffect(() => {
    if (defaultValue) setSearchValue(defaultValue);
  }, [defaultValue]);

  // Function to validate the input after debounce
  useEffect(() => {
    if (debouncedSearchValue !== null) {
      if (restrictedNumbers.includes(debouncedSearchValue)) {
        const msg = errorMessage || `Number ${debouncedSearchValue} is restricted`;
        message.error(msg);
        debouncedOnChange?.(undefined);
        setSearchValue(undefined);
      } else {
        const value = debouncedSearchValue ? parseInt(debouncedSearchValue) : undefined;
        debouncedOnChange?.(value);
      }
    }
  }, [debouncedSearchValue, restrictedNumbers]);

  const handleChange = (val: number) => {
    setSearchValue(val);
  };

  return (
    <InputNumber
      controls={false}
      value={searchValue}
      onChange={handleChange}
      placeholder={placeholder}
      min={min}
      max={max}
    />
  );
}

export default DebouncedInputNumber;
