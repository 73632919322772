import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IUserType, IUserTypeResult } from './types';
import { getDataForIds } from '@/utils/queries.utils';

export const get_user_type_list = async (filter = '') => {
  const response: AxiosResponse<IUserTypeResult, any> = await http.get(
    'users/user-types/?' + filter
  );
  return response.data;
};

export const get_user_type_details = async (id: number) => {
  const response: AxiosResponse<IUserType, any> = await http.get('users/user-types/' + id);
  return response.data;
};

export const get_user_types_ids = async (ids: number[]) => {
  return getDataForIds<IUserType>(ids, 'users/user-types/?');
};

export const get_user_types_filter = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IUserTypeResult, any> = await http.get(
    'users/user-types/?' + filter,
    {
      skip,
      count,
      value
    }
  );
  return response;
};
