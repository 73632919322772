import React, { useState, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Menu, Form, TableProps, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import AppContent from '@/components/Common/Content';
import { get_sell_return_list } from '@/services/sell/queries';
import { get_customer_list_ids } from '@/services/users/queries';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list } from '@/services/locations/queries';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import moment from 'moment';
import TableFilter from '@/components/FliterTable';
import { checkAccess } from '@/routes/acl';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import CustomersDB from '@/store/localstorage/CustomerDB';
import { ConvertObjectToURL } from '@/utils/converturl';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { getUserData } from '@/utils/auth.utils';
import ActionDropdown from '@/components/Common/Dropdownactions';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { ISellReturnResponse } from '@/services/sell/types';
import { CustomerSearch } from '@/components/Common/CustomerSearch/CustomerSearch';
import CopyButton from '@/components/Common/CopyButton';
import ProductCategorySearch from '@/components/Common/ProductCategorySearch/ProductCategorySearch';
import { SellReturnListContext } from './context';
import { TableExportModal } from './TableExportModal';
import { SellViewModal } from './SellViewModal';
import { InvoicePrintModal } from './InvoicePrintModal';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import CustomerSearchV2 from '@/components/Common/CustomSearch/Customer';
import ProductCategorySearchV2 from '@/components/Common/CustomSearch/ProductCategory';

const SellReturnList: React.FC = () => {
  const { isLoading, setIsLoading } = useContext(SellReturnListContext);
  const [form] = Form.useForm();
  const [allSellReturnList, setallSellReturnList] = useState<ISellReturnResponse>({
    total: 0,
    results: []
  });
  const [urlData, setUrlData] = useState('');
  const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [totalReturn, setTotalReturn] = useState<number>(0);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 15,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'View',
      width: 30,
      key: 'sell_id',
      sorter: (a, b) => a.sellId - b.sellId,
      sortOrder: sortedInfo.columnKey === 'sellId' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            <SellViewModal record={record} key={record.id} />
          </TableCell>
        );
      }
    },
    {
      title: 'Financial Reference',
      key: 'financialReference',
      className: 'invoice',
      width: 80,
      sorter: (a, b) => a.financialReference.localeCompare(b.financialReference),
      sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="flex flex-row justify-between items-center text-xs px-1">
            {record.financialReference}
            {record.financialReference && <CopyButton text={record.financialReference} />}
          </TableCell>
        );
      }
    },
    {
      title: 'Customer',
      width: 100,
      key: 'userName',
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.userName}</TableCell>;
      }
    },
    {
      title: 'Debit Reference',
      width: 60,
      key: 'debitReference',
      sorter: (a, b) => a.debitReference.localeCompare(b.debitReference),
      sortOrder: sortedInfo.columnKey === 'debitReference' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.debitReference}</TableCell>;
      }
    },
    {
      title: 'Location',
      width: 100,
      key: 'locationName',
      sorter: (a, b) => a.locationName.localeCompare(b.locationName),
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.locationName}</TableCell>;
      }
    },
    {
      title: 'Return',
      width: 40,
      align: 'right',
      key: 'total_amount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="text-xs text-right mr-2">
            {nepaliNumberFormatter(record.totalAmount)}
          </TableCell>
        );
      }
    },
    {
      title: 'Date',
      width: 60,
      key: 'created_at',
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
      sortOrder: sortedInfo.columnKey === 'created_at' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell className="classfornormaltext text-xs">
            {convertUTCStringtoLocalString(record.created_at, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },
    {
      title: 'Action',
      width: 25,
      key: 'actions',
      fixed: 'right',
      render: (record) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];
        if (checkAccess('READ_SELL_RETURN')) {
          menuItems.push({
            key: '2',
            label: <InvoicePrintModal getInfo={getInfo} record={record} urlData={urlData} />
          });
        }
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '4',
            label: (
              <>
                {checkAccess('READ_CHANNEL') && <DiscussionChatModal slug="sell" id={record.id} />}
              </>
            )
          });
        }

        const menu = <Menu items={menuItems} />;

        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Sell Return',
      link: '/sell/return'
    }
  ];
  const onSubmitFilter = async (val: string) => {
    // console.log(val, form.getFieldsValue());
    await getInfo(val);
    setPage(1);
    setSize(100);
  };
  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const urlParams = new URLSearchParams(filter);
    const customerIds = urlParams.get('customerId');
    urlParams.delete('customerId');

    if (customerIds) {
      const customerList = customerIds.split(',').map(Number);
      const updatedParams = customerList.map((id) => `customerIds[]=${id}`).join('&');

      const updatedURL = `${urlParams.toString()}&${updatedParams}`;
      filter = updatedURL;
    } else {
      filter = urlParams.toString();
      form.setFieldValue('customerId', []);
    }

    setUrlData(filter);
    // const response = await get_report(val, 32);
    const response = await get_sell_return_list(filter);
    // console.log(response);
    if (response?.data.results) {
      const searchUsers: any = {};
      let ctotalreturn = 0;
      for (let index = 0; index < response.data.results.length; index++) {
        const user: any = await CustomersDB.getCustomer(response.data.results[index].customerId);
        if (!user) {
          if (response.data.results[index].customerId in searchUsers) {
            searchUsers[response.data.results[index].customerId] = [
              ...searchUsers[response.data.results[index].customerId],
              index
            ];
          } else {
            searchUsers[response.data.results[index].customerId] = [index];
          }
        } else {
          response.data.results[index].userName = user.user.name;
          response.data.results[index].userData = user;
        }
        let locD: any = await LocationsDB.getLocation(response.data.results[index].locationId);
        if (!locD) {
          // locD = await get_location_details(response.data.results[index].locationId);
          const allLocations = await get_location_list();
          // console.log('allLocations-->', allLocations);
          await LocationsDB.addLocations([...allLocations.data.results]);
          // await LocationsDB.addLocations([locD]);
          locD = await LocationsDB.getLocation(response.data.results[index].locationId);
        }
        response.data.results[index].locationName = locD.name;
        ctotalreturn += response.data.results[index].totalAmount;
        response.data.results[index].date = convertUTCStringtoLocalString(
          response.data.results[index].createdAt,
          'YYYY-MM-DD'
        );
      }
      setTotalReturn(ctotalreturn);
      const searchUserArray = Object.keys(searchUsers);
      if (searchUserArray.length > 0) {
        const customerresponse = await get_customer_list_ids([...Object.keys(searchUsers)]);

        for (const key in searchUsers) {
          const customerUser = customerresponse?.data?.results.find(
            (currCustomer: any) => currCustomer.id == key
          );
          if (customerUser) {
            for (let i = 0; i < searchUsers[key].length; i++) {
              response.data.results[searchUsers[key][i]].userName = customerUser?.user.name;
              response.data.results[searchUsers[key][i]].userData = customerUser;
            }
            await CustomersDB.addCustomers([customerUser]);
          }
        }
      }
    }
    setIsLoading(false);
    setallSellReturnList(response.data);
    return response;
  };

  const onPagination = async (pageNo = 1, totalSize = 10, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            {/* <div className="flex flex-wrap items-center justify-end"> */}
            <div>
              {locationsList && (
                <TableFilter
                  form={form}
                  defaultValues={{
                    dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                    locationId: preferenceLocationId ? preferenceLocationId : 1,
                    skip: 0,
                    count: 100,
                    customerId: []
                  }}
                  initial={true}
                  onSubmit={onSubmitFilter}
                  // outsidestyle="sm:grid md:flex justify-center items-end gap-2"
                  style="grid grid-cols-2 gap-2 md:grid-cols-4"
                  styleforbuttons="flex items-center justify-end">
                  <LocationSearchV2 hasParentFormItem={false} name="locationId" showAll />
                  <CustomerSearchV2 hasParentFormItem={false} name="customerId" showAll />
                  <ProductCategorySearchV2
                    hasParentFormItem={false}
                    name="categoryId"
                    label="Product Category"
                    showAll
                  />
                </TableFilter>
              )}
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          columns={columns}
          data={allSellReturnList.results}
          buttons={<TableExportModal allSellReturnList={allSellReturnList} />}
          notshowPagination={true}
          tableName={'sell-return-list'}
          customScroll={{ x: 1750, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: allSellReturnList.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
          tableSummaryForFilter={[
            {
              index: 5,
              render: <span className="text-xs flex">Total</span>
            },
            {
              index: 6,
              render: (
                <span className="text-xs text-right mr-2">
                  {nepaliNumberFormatter(totalReturn)}
                </span>
              )
            }
          ]}
          // tableSummary={
          //   <Table.Summary fixed={true}>
          //     <Table.Summary.Row>
          //       <Table.Summary.Cell index={0}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={1}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={2}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={3}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={4}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={5}>Total</Table.Summary.Cell>
          //       <Table.Summary.Cell index={6} className="text-right mr-2 text-xs">
          //         {nepaliNumberFormatter(totalReturn)}
          //       </Table.Summary.Cell>
          //       <Table.Summary.Cell index={7}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={8}></Table.Summary.Cell>
          //     </Table.Summary.Row>
          //   </Table.Summary>
          // }
        />
      </AppContent>
    </Spin>
  );
};

export default SellReturnList;
