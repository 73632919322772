import { useMutation } from '@tanstack/react-query';
import { Form, message, PageHeader, Spin, DatePicker } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import AppContent from '../../../../components/Common/Content/Content';

import CustomSubmitButton from '../../../../components/Common/CustomButton/CustomSubmitButton';
import {
  create_vehicles_mutation,
  create_vehicles_usage_mutation
} from '../../../../services/vehicle/mutations';
import { ICreateVehicle, IVehicleUsageCreate } from '../../../../services/vehicle/types';
import { UsersSearch } from '../../../../components/Common/UsersSearch';
import { VehiclesSearch } from '../../../../components/Common/VehiclesSearch';
import { AxiosError } from 'axios';

const CreateVehicleUsage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setisLoading] = useState<boolean>(false);

  const breadcrumbItems = [
    {
      label: 'Vehicle Usage',
      link: '/vehicle-usage'
    },
    {
      label: 'Create',
      link: '/vehicle-usage/new'
    }
  ];
  const createVehicleUsageMutation = useMutation(create_vehicles_usage_mutation, {
    onSuccess: () => {
      message.success('Vehicle Usage added successfully');
      navigate('/vehicle-usage');
    },
    onError: (error: AxiosError) => {
      message.error(error.message);
    }
  });
  const onFinish = async (values: IVehicleUsageCreate) => {
    setisLoading(true);
    await createVehicleUsageMutation.mutateAsync(values);
    setisLoading(false);
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Vehicle Information"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          initialValues={{ units: [], isTraceable: false }}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2">
            <UsersSearch
              formData={{ formName: 'userId', label: 'User' }}
              isAll={false}
              required={true}
            />
            <VehiclesSearch
              formData={{ formName: 'vehicleId', label: 'Vehicle' }}
              isAll={false}
              required={true}
            />
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[
                {
                  required: true,
                  message: 'Please select date!'
                }
              ]}>
              <DatePicker />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              {/* <Button type="primary" htmlType="submit">
                Submit
              </Button> */}
              <CustomSubmitButton text="Submit" />
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default CreateVehicleUsage;
