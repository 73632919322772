import LocationsDB from '../../../store/localstorage/LocationsDB';
import { get_location_details } from '../../../services/locations/queries';
import { get_product_list_ids, get_units_list } from '../../../services/products/queries';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import {
  get_sell_details,
  get_sell_lines_details,
  get_sell_order_details,
  get_sell_return_lines_details
} from '../../../services/sell/queries';
import { useMutation, useQuery } from '@tanstack/react-query';
import AppContent from '../../../components/Common/Content';
import type { ColumnsType } from 'antd/es/table';
import { useNavigate, useParams } from 'react-router';
import { get_invoices_list } from '../../../services/settings/queries';
import { useState } from 'react';
import UsersDB from '../../../store/localstorage/UsersDB';
import { get_customer_list_ids, get_user_details } from '../../../services/users/queries';
import { PageHeader, Button, Spin, Divider, Menu, Tooltip, Modal, message } from 'antd';
import ReuseChannel from '../../channel/Reuse';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import moment from 'moment';
import { checkAccess } from '../../../routes/acl';
import { nepaliNumberFormatter, numberDecimalFormatter } from '../../../utils/numberFormatter';
import { convertLocalToUTCString } from '../../../utils/convertToUTC';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import RoutesDB from '../../../store/localstorage/RoutesDB';
import { get_routes_list } from '../../../services/routes/queries';
import CopyButton from '../../../components/Common/CopyButton';
import { shortNameHiearchy } from '../sell-order/view';
import { ICreateInvoiceResponse } from '../../../services/settings/types';
import { getSellPrintData } from '../../../components/Common/InvoicePrint/SellInvoice/services';
import { ISellInvoice } from '../../../services/invoice/types';
import { CustomModal } from '../../../components/Common/CustomModal';
import SellInvoice from '../../../components/Common/InvoicePrint/SellInvoice/SellInvoice';
import BillPrint from '../../../components/Common/InvoicePrint/BillPrint/BillPrint';
import InvoicePrintButton from '../../../components/Common/InvoicePrintButton/InvoicePrintButton';
import CustomersDB from '../../../store/localstorage/CustomerDB';
import TableCell from '../../../components/Common/CustomizeTable/CustomCell';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { reverse_full_payment_mutation } from '../../../services/payments/mutations';
import { PartialPaymentReverseModal } from '../../../components/Common/CustomModal/PartialPaymentReverseModal';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import getGroupedData from '@/utils/getGroupedData';

const { confirm } = Modal;

const SellView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsloading] = useState(true);
  const [purchaseDetails, setpurchaseDetails] = useState<any>();
  const [vendorDetails, setVendorDetails] = useState<any>();
  const [locationDetails, setLocationDetails] = useState<any>();
  const [lines, setLines] = useState<any>([]);
  const [returnList, setReturnList] = useState<any[]>([]);
  const [payList, setPayList] = useState<any[]>([]);
  const [invoiceLayouts, setinvoiceLayouts] = useState<any>();
  const [createdUser, setcreatedUser] = useState<any>();
  const [printCount, setPrintCount] = useState<number>(0);
  const [invoiceLists, setInvoiceLists] = useState<ICreateInvoiceResponse[]>([]);
  const [routeDetails, setRouteDetails] = useState({});
  const [firstCreatedByUserDetails, setFirstCreatedByUserDetails] = useState<any>();
  const [selectedPaymentId, setSelectedPaymentId] = useState<number>();
  const [data, setData] = useState({
    taxable: 0,
    nonTaxable: 0,
    vat: 0
  });
  const [invoiceData, setInvoiceData] = useState<ISellInvoice>(Object);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  const [openModalForPartialReverse, setOpenModalForPartialReverse] = useState<boolean>(false);
  const [billData, setBillData] = useState<ISellInvoice>(Object);
  const [openModalForBillPrint, setOpenModalForBillPrint] = useState<boolean>(false);

  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    setinvoiceLayouts(response.data[0]);
    setInvoiceLists(response.data);
    return response;
  });
  const { refetch } = useQuery(['sell', id], async () => {
    const response = await get_sell_details(parseInt(id as string));
    let firstCreatedByUser: any;
    if (response?.sellOrderId) {
      const sellOrderResponse = await get_sell_order_details(response.sellOrderId);
      if (sellOrderResponse?.data?.createdBy) {
        firstCreatedByUser = sellOrderResponse.data.createdBy;
      }
    }
    setPrintCount(response.printCount);
    let firstCreatedByUserDetail: any;
    if (firstCreatedByUser) {
      firstCreatedByUserDetail = await UsersDB.getUser(firstCreatedByUser);
      if (!firstCreatedByUserDetail) {
        firstCreatedByUserDetail = (await get_user_details(firstCreatedByUser)).user;
        if (firstCreatedByUserDetail) {
          UsersDB.addUsers(firstCreatedByUser);
        }
      }
      setFirstCreatedByUserDetails(firstCreatedByUserDetail);
    }
    setPayList(response.payments);
    let createdByDetails: any;
    let routeDetails: any;
    if (response?.address?.routeId) {
      const routeId = response.address.routeId;
      routeDetails = await RoutesDB.getRoute(routeId);
      if (!routeDetails) {
        const response = await get_routes_list();
        await RoutesDB.addRoutes(response?.data?.results);
        routeDetails = await RoutesDB.getRoute(routeId);
      }
    }
    setRouteDetails(routeDetails);
    if (response.createdBy) {
      createdByDetails = await UsersDB.getUser(response.createdBy);
      if (!createdByDetails) {
        createdByDetails = (await get_user_details(response.createdBy)).user;
      }
    }
    setcreatedUser(createdByDetails);
    const linesResponse = await get_sell_lines_details(parseInt(id as string));
    let userData = await CustomersDB.getCustomer(response.customerId);
    if (!userData) {
      const allCustomers = (await get_customer_list_ids([response.customerId])).data.results;
      await CustomersDB.addCustomers(allCustomers);
      userData = await CustomersDB.getCustomer(response.customerId);
    }
    setVendorDetails(userData.user);
    if (!userData.user) setVendorDetails(userData);
    let locationDetailss: any = await LocationsDB.getLocation(response.locationId);
    if (!locationDetailss) {
      locationDetailss = await get_location_details(response.locationId);
      await LocationsDB.addLocations([locationDetailss]);
    }
    setLocationDetails(locationDetailss);
    const totalQuantity: any = {};
    if (linesResponse.data.length > 0) {
      const searchProducts: any = {};
      let taxable = 0;
      let nonTaxable = 0;
      let vatTotal = 0;
      for (let index = 0; index < linesResponse.data.length; index++) {
        let product = await ProductsDB.getProduct(linesResponse.data[index].productId);
        if (!product) {
          // product = await get_product_details(linesResponse.lines[index].productId);
          // await ProductsDB.addProducts([product]);
          const allProducts = await get_product_list_ids([
            ...new Set(
              linesResponse.data.map((val) => {
                return val.productId;
              })
            )
          ]);
          await ProductsDB.addProducts(allProducts.data.results);
          product = await ProductsDB.getProduct(linesResponse.data[index].productId);
          linesResponse.data[index].productName = typeof product === 'object' ? product.name : '';
        } else {
          linesResponse.data[index].productName = typeof product === 'object' ? product.name : '';
        }

        let findUnit: any = await UnitsDB.getUnit(linesResponse.data[index].unitId);
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
        }

        if (!totalQuantity[findUnit.shortName]) {
          totalQuantity[findUnit.shortName] = linesResponse.data[index].quantity;
        } else {
          totalQuantity[findUnit.shortName] += linesResponse.data[index].quantity;
        }

        linesResponse.data[
          index
        ].quantityWithUnit = `${linesResponse.data[index].quantity} ${findUnit.shortName}`;
        linesResponse.data[index].shortName = findUnit.shortName;

        if (typeof product === 'object' && product.vat) {
          const vat =
            (linesResponse.data[index].unitPrice * linesResponse.data[index].quantity -
              linesResponse.data[index].discount) *
            (product.vat / 100);
          linesResponse.data[index].vat = vat;
          if (vat > 0) {
            vatTotal += vat;
            const amountWithoutVat = linesResponse.data[index].totalAmount - vat;
            // linesResponse.data[index].totalAmount = linesResponse.data[index].totalAmount - vat;
            taxable += amountWithoutVat;
          } else {
            nonTaxable += linesResponse.data[index].totalAmount;
          }
        }
      }
      taxable += response.shipping - response.shippingTax;
      setData({ taxable: taxable, nonTaxable: nonTaxable, vat: vatTotal });
      const searchProductslength = Object.entries(searchProducts).length;
      if (searchProductslength > 0) {
        const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
        for (const key in searchProducts) {
          const findproduct = productsresponse?.data?.results.find(
            (currProduct: any) => currProduct.id == key
          );
          for (let i = 0; i < searchProducts[key].length; i++) {
            linesResponse.data[searchProducts[key][i]].productName = findproduct?.name;
          }
          await ProductsDB.addProducts([findproduct]);
        }
      }
      setLines(linesResponse.data);
      const returnListId = response.returns.map((curr: any) => curr.id);

      const returnListResponse = await Promise.all(
        returnListId.map(async (id) => await get_sell_return_lines_details(id))
      );

      const flattenData = returnListResponse.map((response) => response.data).flat();

      const productDetailsPromises = await Promise.all(
        flattenData.map(async (item) => {
          let productDetails = await ProductsDB.getProduct(item.productId);
          if (!productDetails) {
            const allProducts = await get_product_list_ids([
              ...new Set(flattenData.map((curr) => curr.productId))
            ]);
            await ProductsDB.addProducts(allProducts.data.results);
            productDetails = await ProductsDB.getProduct(item.productId);
          }
          return typeof productDetails === 'object' ? productDetails.name : '';
        })
      );

      const mergedList = flattenData.map((item, index) => {
        return { ...item, productName: productDetailsPromises[index] };
      });
      setReturnList(mergedList);
      setIsloading(false);
    }
    let totalQuantityString = '';
    const totalQuantityArray: any[] = [];
    for (const key in totalQuantity) {
      totalQuantityArray.push({
        tqty: totalQuantity[key],
        shortName: key
      });
    }
    totalQuantityArray.sort((a: any, b: any) => {
      return shortNameHiearchy.indexOf(a.shortName) - shortNameHiearchy.indexOf(b.shortName);
    });
    for (let ind = 0; ind < totalQuantityArray.length; ind++) {
      totalQuantityString += ` ${numberDecimalFormatter(totalQuantityArray[ind].tqty)} ${
        totalQuantityArray[ind].shortName
      }`;
    }
    setpurchaseDetails({
      ...response,
      totalQuantity: totalQuantityString
    });

    setIsloading(false);
    return response;
  });

  const reverseFullPaymentMutation = useMutation(reverse_full_payment_mutation, {
    onSuccess: async () => {
      setIsloading(false);
      message.success('Payment reversed successfully');
      await refetch();
    },
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.response.data.message}`);
    }
  });

  const columns: ColumnsType<{ name: string }> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 30,
      render: (a, b, c) => <div>{c + 1}.</div>
    },
    {
      title: `${
        invoiceLayouts?.content?.productLabel ? invoiceLayouts.content.productLabel : 'ProductName'
      }`,
      dataIndex: 'productName',
      width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'HS Code',
      dataIndex: 'hsCode',
      width: 70,
      render: (text?: string) => <a>{text || 'N/A'}</a>
    },
    {
      title: `${
        invoiceLayouts?.content?.quantityLabel ? invoiceLayouts.content.quantityLabel : 'Quantity'
      }`,
      key: 'quantity',
      width: 70,
      render: (record: any) => <div>{`${record.quantityWithUnit}`}</div>
    },
    {
      title: `${
        invoiceLayouts?.content?.unitPriceLabel
          ? invoiceLayouts.content.unitPriceLabel
          : 'UnitPrice'
      }`,
      dataIndex: 'unitPrice',
      width: 70,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: 'Misc',
      dataIndex: 'misc',
      width: 70,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: `${
        invoiceLayouts?.content.discountLabel ? invoiceLayouts.content.discountLabel : 'Discount'
      }`,
      dataIndex: 'discount',
      width: 70,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: `${
        invoiceLayouts?.content.vatColumnLabel ? invoiceLayouts.content.vatColumnLabel : 'VAT'
      }`,
      dataIndex: 'vat',
      width: 70,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: `${
        invoiceLayouts?.content.totalLabel ? invoiceLayouts.content.totalLabel : 'Total Amount'
      }`,
      dataIndex: 'totalAmount',
      width: 70,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    }
  ];

  const returnColumns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 30,
      render: (a, b, c) => <div>{c + 1}</div>
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'HS Code',
      dataIndex: 'hsCode',
      width: 50,
      render: (text?: string) => <a>{text || 'N/A'}</a>
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      width: 50,
      render: (text: number) => <div>{text}</div>
    },

    {
      title: `Total Amount`,
      dataIndex: 'totalAmount',
      width: 50,
      render: (text: number) => <div>{nepaliNumberFormatter(Math.abs(text))}</div>
    },
    {
      title: 'Total Tax',
      dataIndex: 'vat',
      width: 50,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: 70,
      render: (text: any) => <div>{moment(text).local().format(DEFAULT_DATE_FORMAT)}</div>
    }
  ];

  const payColumns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 30,
      render: (a, b, c) => <div>{c + 1}</div>
    },
    {
      title: 'id',
      dataIndex: 'id',
      width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: 'Wallet',
      dataIndex: 'walletAmount',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: 100,
      render: (text: any) => <div>{moment(text).local().format(DEFAULT_DATE_FORMAT)}</div>
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      width: 100,
      render: (text: number) => <div>{text}</div>
    },
    {
      title: 'Note',
      dataIndex: 'note',
      width: 100,
      render: (text: number) => <div>{text}</div>
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 50,
      fixed: 'right',
      render: (a, record) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [
          {
            key: '1',
            label: (
              <>
                <div
                  className="w-full p-0 cursor-pointer text-center"
                  onClick={() => {
                    confirm({
                      title: 'Do you want to fully reverse this payment?',
                      icon: <ExclamationCircleOutlined />,
                      async onOk() {
                        await reverseFullPaymentMutation.mutateAsync(record.id);
                      }
                    });
                  }}>
                  <Tooltip title="Full Payment Reverse" color="blue">
                    FULL
                  </Tooltip>
                </div>
              </>
            )
          },
          {
            key: '2',
            label: (
              <>
                <div
                  className="w-full p-0 cursor-pointer text-center"
                  onClick={() => {
                    setSelectedPaymentId(record.id);
                    setOpenModalForPartialReverse(true);
                  }}>
                  <Tooltip title="Partial Payment Reverse" color="blue">
                    PARTIAL
                  </Tooltip>
                </div>
              </>
            )
          }
        ];

        const menu = <Menu items={menuItems} />;
        return (
          <TableCell>
            {record.note === 'REVERSE' ? <></> : <ActionDropdown menu={menu} />}
          </TableCell>
        );
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Sell',
      link: '/sell'
    },
    {
      label: 'View'
    }
  ];

  const generateInvoiceForPrint = async (curr: ICreateInvoiceResponse) => {
    setIsloading(true);
    const invoiceModalData = await getSellPrintData(
      purchaseDetails.id,
      purchaseDetails.locationId,
      purchaseDetails.sellOrderId
    );

    const returnGroupByProduct = getGroupedData(returnList, 'productId');
    const productWihReturnQuantity = Object.keys(returnGroupByProduct).map((key) => {
      const data = returnGroupByProduct[key];
      return {
        productId: parseInt(key),
        quantity: data.reduce((a, b) => a + b.quantity, 0)
      };
    });

    const sellLines = invoiceModalData?.lines || [];

    // Is All Sell Lines Returned
    const isAllSellLinesReturned = sellLines.every((line) => {
      const returnQuantity = productWihReturnQuantity.find(
        (product: any) => product.productId === line.productId
      );

      if (!returnQuantity) return false;
      return returnQuantity.quantity === line.quantity;
    });

    setIsloading(false);
    setInvoiceData({ ...invoiceModalData, invoiceLayouts: curr, isAllSellLinesReturned });
    setOpenModalForInvoicePrint(true);
  };

  const handleInvoicePrintModalClose = () => {
    setOpenModalForInvoicePrint(false);
    refetch();
  };

  const handlePartialPaymentReverseModalClose = async () => {
    setOpenModalForPartialReverse(false);
    await refetch();
  };

  const generateBillForPrint = async (curr: any) => {
    setIsloading(true);
    const billModalData = await getSellPrintData(
      purchaseDetails.id,
      purchaseDetails.locationId,
      purchaseDetails.sellOrderId
    );
    setIsloading(false);

    setBillData({ ...billModalData, invoiceLayouts: curr });
    setOpenModalForBillPrint(true);
  };

  const handleBillPrintModalClose = () => {
    setOpenModalForBillPrint(false);
    refetch();
  };

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Spin spinning={isLoading}>
          <CustomModal
            footer={false}
            width="500px"
            isModalOpen={openModalForPartialReverse}
            setIsModalOpen={setOpenModalForPartialReverse}
            title="Partial Payment Reverse">
            <PartialPaymentReverseModal
              paymentId={selectedPaymentId}
              handleModalClose={handlePartialPaymentReverseModalClose}
            />
          </CustomModal>
          <CustomModal
            footer={false}
            isModalOpen={openModalForInvoicePrint}
            setIsModalOpen={setOpenModalForInvoicePrint}
            title="Sell Invoice Print">
            <SellInvoice
              sellDetails={invoiceData.sellDetails}
              customerDetails={invoiceData.customerDetails}
              lines={invoiceData.lines}
              invoiceLayouts={invoiceData.invoiceLayouts}
              isAllSellLinesReturned={invoiceData.isAllSellLinesReturned}
              handleModalClose={handleInvoicePrintModalClose}
            />
          </CustomModal>
          <CustomModal
            footer={false}
            isModalOpen={openModalForBillPrint}
            setIsModalOpen={setOpenModalForBillPrint}
            title="Bill Print">
            <BillPrint
              sellDetails={billData.sellDetails}
              customerDetails={billData.customerDetails}
              lines={billData.lines}
              firstCreatedByUserDetails={billData.firstCreatedByUserDetails}
              invoiceLayouts={billData.invoiceLayouts}
              handleModalClose={handleBillPrintModalClose}
            />
          </CustomModal>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-5">
            <div>
              <PageHeader
                subTitle="Customer"
                style={{
                  padding: '8px 0px'
                }}
              />
              {vendorDetails && (
                <div style={{ color: 'black' }}>
                  {vendorDetails?.name ? vendorDetails.name : ''}
                  {`${vendorDetails?.phone ? `,${vendorDetails.phone}` : ''} ${
                    vendorDetails.email ? `,${vendorDetails.email}` : ''
                  }`}
                </div>
              )}
            </div>
            <div>
              <PageHeader
                subTitle="Location"
                style={{
                  padding: '8px 0px'
                }}
              />
              {locationDetails ? (
                <>
                  <div style={{ color: 'black' }}>
                    {locationDetails?.name ? locationDetails.name : ''}
                    {`${locationDetails?.zip ? `${locationDetails.zip},` : ''} ${
                      locationDetails?.address ? `${locationDetails.address},` : ''
                    } ${locationDetails?.city ? `${locationDetails.city},` : ''} ${
                      locationDetails?.country ? `${locationDetails.country}` : ''
                    }`}
                    {`${locationDetails?.phone ? `${locationDetails.phone},` : ''} ${
                      locationDetails?.email ? `${locationDetails.email}` : ''
                    }`}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div>
              <PageHeader
                subTitle="Details"
                style={{
                  padding: '8px 0px'
                }}
              />
              {purchaseDetails ? (
                <>
                  <div style={{ color: 'black' }}>
                    {purchaseDetails.financialReference ? (
                      <span className="block">
                        Financial Reference :<br /> {purchaseDetails.financialReference}{' '}
                        <CopyButton text={purchaseDetails.financialReference} />
                      </span>
                    ) : (
                      <></>
                    )}
                    <span className="block">
                      {' '}
                      Created : {convertLocalToUTCString(purchaseDetails.createdAt, 'YYYY-MM-DD')}
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <CustomizeTable columns={columns} notshowPagination={true} data={lines} />
          {returnList.length > 0 && (
            <>
              <Divider />
              <PageHeader
                subTitle="Returns"
                style={{
                  padding: '8px 0px'
                }}
              />
              <CustomizeTable columns={returnColumns} notshowPagination={true} data={returnList} />
            </>
          )}
          {payList.length > 0 && (
            <>
              <Divider />
              <PageHeader
                subTitle="Payments"
                style={{
                  padding: '8px 0px'
                }}
              />
              <CustomizeTable columns={payColumns} notshowPagination={true} data={payList} />
            </>
          )}
          <Divider />
          <div>
            <PageHeader
              subTitle="Total"
              style={{
                padding: '8px 0px'
              }}
            />
            {purchaseDetails ? (
              <div style={{ color: 'black' }}>
                <span className="block">Total Quantity:{purchaseDetails.totalQuantity}</span>
                <span className="block">
                  Taxable : {nepaliNumberFormatter(purchaseDetails.totalTaxable)}
                </span>
                <span className="block">
                  Non Taxable : {nepaliNumberFormatter(purchaseDetails.totalNonTaxable)}
                </span>
                <span className="block">
                  VAT : {nepaliNumberFormatter(purchaseDetails.totalVat)}
                </span>
                <span className="block">
                  Shipping Charge :{' '}
                  {nepaliNumberFormatter(purchaseDetails.shipping - purchaseDetails.shippingTax)}
                </span>
                <span className="block">
                  Total Amount : {nepaliNumberFormatter(purchaseDetails.totalAmount)}
                </span>
                <span
                  className="block"
                  style={{ color: purchaseDetails.profit > 0 ? 'green' : 'red' }}>
                  Profit : {nepaliNumberFormatter(purchaseDetails.profit)}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="grid grid-cols-1 mb-5">
            {purchaseDetails?.note ? (
              <>
                <PageHeader
                  subTitle="Note"
                  style={{
                    padding: '8px 0px'
                  }}
                />

                <div style={{ color: 'black' }}>{purchaseDetails.note}</div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="flex justify-end items-center gap-5 mt-5">
            <span className="font-bold">Print Count : {printCount}</span>
            <div
              style={{
                width: '3rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <InvoicePrintButton
                getInvoice={(curr) => {
                  generateInvoiceForPrint(curr);
                }}
              />
            </div>
            <Button type="default" onClick={() => navigate('/sell')}>
              Back
            </Button>
          </div>
        </Spin>
        <Divider />
        {checkAccess('READ_CHANNEL') && (
          <ReuseChannel
            slug={`sell_${id}`}
            fromSellorPurchaseId={vendorDetails?.user?.id}
            withReferenceandId={{ reference: 'sell', referenceId: id || 0 }}
          />
        )}
      </AppContent>
    </div>
  );
};

export default SellView;
