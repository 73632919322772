import { DatePicker, Form, Input, InputNumber, PageHeader } from 'antd';
import { useEffect } from 'react';
import Report from '../..';
import { RangePickerProps } from 'antd/lib/date-picker';
import { CustomDatePresets } from '../../utils/datePresets';
const { RangePicker } = DatePicker;
import moment from 'moment';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { DEFAULT_DATE_FORMAT } from '@/constants';

interface DataType {
  date: string;
  id: number;
  name: string;
  totalAmount: string;
  totalQuantity: string;
}

const TransferInProductIdentifierReport = () => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldValue('constraints', {
      page: 1,
      size: 10,
      identifier: 'TRANSFER_IN'
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Transfer In Product Identifier',
      link: '/reports/transfer-in-product-identifier'
    }
  ];
  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Product ID',
      key: 'id',
      render: (record) => <Link to={`/products/${record.id}`}>{record.id}</Link>
    },
    {
      title: 'Name',
      key: 'name',
      render: (record) => <Link to={`/products/${record.id}`}>{record.name}</Link>
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (text: string) => <div> {moment(text).local().format(DEFAULT_DATE_FORMAT)}</div>
    },
    {
      title: 'Total Quantity',
      dataIndex: 'totalQuantity',
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      render: (text: string) => <div>{text}</div>
    }
  ];

  return (
    <Report
      breadcrumbItems={breadcrumbItems}
      state={{ id: 8, name: 'Transfer In Product Identifier Report' }}
      form={form}
      columnsData={columns}>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'identifier']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>

      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[
          {
            required: true,
            message: `Please select date!`
          }
        ]}>
        <RangePicker
          ranges={CustomDatePresets}
          onChange={onChange}
          format={'YYYY-MM-DD'}
          className="w-full"
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
    </Report>
  );
};

export default TransferInProductIdentifierReport;
