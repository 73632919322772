import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { get_location_details, get_location_list } from '../../../services/locations/queries';
import {
  get_sell_lines_details,
  get_sell_lists_v2,
  get_sell_list_v2
} from '../../../services/sell/queries';
import {
  get_customer_details,
  get_customer_list_ids,
  get_user_list_ids
} from '../../../services/users/queries';
import CustomersDB from '../../../store/localstorage/CustomerDB';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import UsersDB from '../../../store/localstorage/UsersDB';
import { getUserData } from '../../../utils/auth.utils';
import { convertUTCStringtoLocalString } from '../../../utils/convertToUTC';
import { ConvertObjectToURL } from '../../../utils/converturl';
import { numberDecimalFormatter } from '../../../utils/numberFormatter';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import CopyButton from '../../../components/Common/CopyButton';
import { Menu } from 'antd';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import { get_product_list_ids, get_units_list } from '../../../services/products/queries';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import { shortNameHiearchy } from '../../sell/sell-order/view';
import { IProductType } from '../../../services/products/types';

const RecentPos = ({ isModalOpen }: { isModalOpen: boolean }) => {
  const [allSellList, setallSellList] = useState<any>({ total: 0, results: [] });
  const [isLoading, setIsloading] = useState(true);
  const loggedInUser = getUserData();
  const { preferences } = loggedInUser;
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  useQuery(['pos-recent', isModalOpen], async () => {
    const filter = ConvertObjectToURL({
      skip: 0,
      count: 10,
      locationId: preferenceLocationId,
      type: 'POS'
    });
    const response = await get_sell_lists_v2(filter);
    if (response?.data.results) {
      const searchUsers: any = {};
      let ctotalamount = 0,
        creturnamount = 0;
      const createdByUsers: any = {};
      for (let index = 0; index < response.data.results.length; index++) {
        if (response?.data?.results[index]?.createdBy) {
          const createdByuser: any = await UsersDB.getUser(response.data.results[index].createdBy);
          if (!createdByuser) {
            if (response.data.results[index].createdBy in createdByUsers) {
              createdByUsers[response.data.results[index].createdBy] = [
                ...createdByUsers[response.data.results[index].createdBy],
                index
              ];
            } else {
              createdByUsers[response.data.results[index].createdBy] = [index];
            }
          } else {
            response.data.results[index].createdByName = createdByuser.name;
            // response.data.resultsWithCustomer[index].userData = user;
          }
        }
        const user: any = await CustomersDB.getCustomer(response.data.results[index].customerId);
        if (!user) {
          if (response.data.results[index].customerId in searchUsers) {
            searchUsers[response.data.results[index].customerId] = [
              ...searchUsers[response.data.results[index].customerId],
              index
            ];
          } else {
            searchUsers[response.data.results[index].customerId] = [index];
          }
        } else {
          response.data.results[index].userName = user.user.name;
          response.data.results[index].userData = user;
        }
        let locD: any = await LocationsDB.getLocation(response.data.results[index].locationId);
        if (!locD) {
          const allLocations = await get_location_list();
          await LocationsDB.addLocations([...allLocations.data.results]);
          locD = await LocationsDB.getLocation(response.data.results[index].locationId);
        }
        response.data.results[index].locationName = locD.name;
        ctotalamount += response.data.results[index].totalAmount;
        creturnamount += response.data.results[index].amountReturned;
        response.data.results[index].date = convertUTCStringtoLocalString(
          response.data.results[index].createdAt,
          'YYYY-MM-DD'
        );
        response.data.results[index].remainingAmount = numberDecimalFormatter(
          response.data.results[index].totalAmount -
            response.data.results[index].amountReturned -
            response.data.results[index].amountPaid
        );
      }
      //   setTotal({ totalAmount: ctotalamount, totalReturn: creturnamount });
      const searchUserslength = Object.entries(searchUsers).length;
      if (searchUserslength > 0) {
        const customerresponse = await get_customer_list_ids([...Object.keys(searchUsers)]);
        for (const key in searchUsers) {
          const customerUser = customerresponse?.data?.results.find(
            (currCustomer: any) => currCustomer.id == key
          );
          for (let i = 0; i < searchUsers[key].length; i++) {
            response.data.results[searchUsers[key][i]].userName = customerUser?.user.name;
            response.data.results[searchUsers[key][i]].userData = customerUser;
          }
          await CustomersDB.addCustomers([customerUser]);
        }
      }

      const createdByUserslength = Object.entries(createdByUsers).length;
      // console.log('createdbyuserslength-->', createdByUsers);
      if (createdByUserslength > 0) {
        const customerresponse = await get_user_list_ids([...Object.keys(createdByUsers)]);
        // console.log('Customer response-->', customerresponse);
        for (const key in createdByUsers) {
          // console.log('keys->', key);
          const createdByUser = customerresponse?.data?.results.find(
            (currCustomer: any) => currCustomer.id == key
          );
          // console.log('createdByUser-->', createdByUser);
          if (!createdByUser) continue;
          for (let i = 0; i < createdByUsers[key].length; i++) {
            response.data.results[createdByUsers[key][i]].createdByName = createdByUser.name;
          }
          await UsersDB.addUsers([createdByUser]);
        }
      }
      // setSellList(response.data.results);
    }
    // console.log('Response data-->', response.data);
    setIsloading(false);
    setallSellList(response.data);
    return response;
  });

  const columns: ColumnsType<any> = [
    // Table.SELECTION_COLUMN,
    // Table.EXPAND_COLUMN,
    {
      title: 'S.N',
      key: 'SN',
      width: 10,
      render: (text, record, index) => {
        return <div>{index + 1}</div>;
      }
    },
    {
      title: 'ID',
      key: 'id',
      width: 20,
      render: (record) => {
        return <div>{record.id} </div>;
      }
    },
    {
      title: 'Ref',
      width: 80,
      key: 'referenceNumber',
      render: (record) => {
        return (
          <div>
            <div className="flex flex-row justify-between items-center">
              <Link to={`/sell/${record.id}`} color="black">
                {record.referenceNumber}
              </Link>
              <CopyButton text={record.referenceNumber} />
            </div>
          </div>
        );
      }
    },
    {
      title: 'Customer',
      width: 50,
      key: 'userName',
      render: (record) => {
        return <div>{record.userName}</div>;
      }
    },
    {
      title: 'Total Amount(NPR)',
      width: 50,
      key: 'totalAmount',
      render: (record) => {
        return <div className="text-center">{record.totalAmount}</div>;
      }
    },
    {
      title: 'Amount Paid(NPR)',
      width: 40,
      key: 'amountPaid',
      render: (record) => {
        return <div className="text-center">{record.amountPaid}</div>;
      }
    },
    {
      title: 'Remaining Amount(NPR)',
      width: 40,
      render: (record) => {
        return (
          <div className="text-center">
            {numberDecimalFormatter(record.totalAmount - record.amountReturned - record.amountPaid)}
          </div>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 20,
      fixed: 'right',
      render: (record) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [
          {
            key: 'billandinvoice',
            label: (
              <div
                style={{ height: '1.5rem' }}
                className="flex justify-center items-center"
                onClick={() => {
                  printPDF(record);
                }}>
                Print
              </div>
            )
          }
        ];
        const menu = <Menu items={menuItems} />;

        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const printPDF = async (values: any) => {
    console.log(values);

    console.log('this runs');

    const routeDetails = {
      name: ''
    };

    let locationDetails: any = await LocationsDB.getLocation(values.locationId);
    if (!locationDetails) {
      locationDetails = await get_location_details(values.locationId);
      await LocationsDB.addLocations([locationDetails]);
    }
    let totalAmount: any = 0;
    const totalQuantity: any = {};
    let shortName: any = '';
    const linesResponse = await get_sell_lines_details(values.id);
    const searchProducts: any = {};
    console.log('lines', linesResponse.data);
    for (let index = 0; index < linesResponse.data.length; index++) {
      totalAmount += linesResponse.data[index].totalAmount;
      const product: any = await ProductsDB.getProduct(linesResponse.data[index].productId);
      if (!product) {
        if (linesResponse.data[index].productId in searchProducts) {
          searchProducts[linesResponse.data[index].productId] = [
            ...searchProducts[linesResponse.data[index].productId],
            index
          ];
        } else {
          searchProducts[linesResponse.data[index].productId] = [index];
        }
      } else {
        linesResponse.data[index].productName = product.name;
      }
      let findUnit: any = await UnitsDB.getUnit(linesResponse.data[index].unitId);
      if (!findUnit) {
        const allUnits = await get_units_list();
        await UnitsDB.addUnits(allUnits);
        findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
      }
      if (!totalQuantity[findUnit.shortName]) {
        totalQuantity[findUnit.shortName] = linesResponse.data[index].quantity;
      } else {
        totalQuantity[findUnit.shortName] += linesResponse.data[index].quantity;
      }
      linesResponse.data[
        index
      ].quantityWithUnit = `${linesResponse.data[index].quantity} ${findUnit.shortName}`;
      linesResponse.data[index].shortName = findUnit.shortName;
      shortName = findUnit.shorName;
    }
    const searchProductslength = Object.entries(searchProducts).length;

    if (searchProductslength > 0) {
      const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
      for (const key in searchProducts) {
        const findproduct = productsresponse?.data?.results.find(
          (currProduct: IProductType) => currProduct.id == key
        );
        for (let i = 0; i < searchProducts[key].length; i++) {
          linesResponse.data[searchProducts[key][i]].productName = findproduct?.name;
        }
        await ProductsDB.addProducts([findproduct]);
      }
    }

    let totalQuantityString = '';
    const totalQuantityArray: any[] = [];
    for (const key in totalQuantity) {
      totalQuantityArray.push({
        tqty: totalQuantity[key],
        shortName: key
      });
    }
    totalQuantityArray.sort((a: any, b: any) => {
      return shortNameHiearchy.indexOf(a.shortName) - shortNameHiearchy.indexOf(b.shortName);
    });
    for (let ind = 0; ind < totalQuantityArray.length; ind++) {
      totalQuantityString += ` ${numberDecimalFormatter(totalQuantityArray[ind].tqty)} ${
        totalQuantityArray[ind].shortName
      }`;
    }
    console.log('totalAMount', totalAmount);
    localStorage.setItem(
      'referrer',
      JSON.stringify({
        purchaseDetails: {
          fromprint: true,
          ...values,
          totalAmount,
          totalQuantity: totalQuantityString
        },
        locationDetails,
        customerDetails: values?.userData?.user,
        lines: linesResponse.data,
        createdByDetails: loggedInUser,
        routeDetails
      })
    );
    window.open('/ERP-UI#/POSS-Sellinvoice', '_blank', 'noopener,noreferrer');
  };

  return (
    <div>
      <div>
        <CustomizeTable
          columns={columns}
          data={allSellList.results}
          // usersLoading={isLoading}
          notshowPagination={true}
          customScroll={{ x: 800, y: '75vh' }}
        />
      </div>
    </div>
  );
};

export default RecentPos;
