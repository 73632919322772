import { useQuery } from '@tanstack/react-query';
import { PageHeader, Table, Button, Spin, Divider, Menu, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AppContent from '../../../components/Common/Content/Content';
import type { ColumnsType } from 'antd/es/table';
import { get_purchase_details } from '../../../services/purchases/queries';
// import { get_location_details } from '../../../services/locations/queries';
import { get_vendor_details } from '../../../services/users/queries';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import {
  IpurchaseDetails,
  IVendordetails,
  Ilocationdetails,
  IPurchaseDetails
} from '../../../services/purchases/types';
import { get_invoices_list } from '../../../services/settings/queries';
import { createPDF } from './pdfMaker';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import { get_location_details } from '../../../services/locations/queries';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import {
  get_product_details,
  get_product_list_ids,
  get_units_list
} from '../../../services/products/queries';
import ReuseChannel from '../../channel/Reuse';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import { Link } from 'react-router-dom';
import { nepaliNumberFormatter } from '../../../utils/numberFormatter';
import {
  convertLocalToUTCString,
  convertUTCStringtoLocalString
} from '../../../utils/convertToUTC';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import CopyButton from '../../../components/Common/CopyButton';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import { IProductType } from '../../../services/products/types';
import { ICreateInvoiceResponse } from '../../../services/settings/types';
import { DEFAULT_DATE_FORMAT } from '@/constants';

// const { Option } = Select;
interface PurchaseViewProps {
  id: number;
}
const ReusablePurchaseView: React.FC<PurchaseViewProps> = ({ id }) => {
  const [purchaseDetails, setPurchaseDetails] = useState<IPurchaseDetails>();
  const [locationDetails, setLocationDetails] = useState<Ilocationdetails>();
  const [vendorDetails, setVendorDetails] = useState<any>();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [invoiceLayouts, setinvoiceLayouts] = useState<any>();
  const [invoiceLists, setInvoiceLists] = useState<ICreateInvoiceResponse[]>([]);
  const [payList, setPayList] = useState<any[]>([]);
  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    setinvoiceLayouts(response.data[0]);
    // console.log('response-->', response);
    setInvoiceLists(
      response.data.filter((curr: any) => {
        const content = JSON.parse(curr.content);
        return content.design == 'classic';
      })
    );
    return response;
  });
  const columns: ColumnsType<{ name: string }> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 10,
      render: (a, b, c) => <div>{c + 1}.</div>
    },
    {
      title: `${
        invoiceLayouts?.content.productLabel ? invoiceLayouts.content.productLabel : 'Product Name'
      }`,
      dataIndex: 'productName',
      width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: `${
        invoiceLayouts?.content.unitPriceLabel
          ? invoiceLayouts.content.unitPriceLabel
          : 'Unit Price'
      }`,
      dataIndex: 'unitPrice',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    // {
    //   title: 'unitName',
    //   dataIndex: 'unitName',
    //   render: (text: number) => <div>{text}</div>
    // },
    {
      title: `${
        invoiceLayouts?.content.quantityLabel ? invoiceLayouts.content.quantityLabel : 'Quantity'
      }`,
      dataIndex: 'quantity',
      width: 100,
      render: (text: number) => <div>{text}</div>
    },
    {
      title: 'Misc',
      dataIndex: 'misc',
      width: 100,
      render: (text: number) => <div>{text}</div>
    },
    {
      title: `${
        invoiceLayouts?.content.discountLabel ? invoiceLayouts.content.discountLabel : 'Discount'
      }`,
      dataIndex: 'discount',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: `${
        invoiceLayouts?.content.totalLabel ? invoiceLayouts.content.totalLabel : 'TotalAmount'
      }`,

      dataIndex: 'totalAmount',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    }
  ];

  const columnspay: ColumnsType<{ name: string }> = [
    {
      title: `S.N`,
      key: 'sn',
      width: 30,
      render: (a, b, c) => <div>{c + 1}.</div>
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: 100,
      render: (text: string) => (
        <div>{convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT)}</div>
      )
    },
    {
      title: 'Reference No',
      dataIndex: 'reference',
      width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}</div>
    },
    {
      title: 'Payment Mode',
      dataIndex: 'paymentMethod',
      width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Payment Note',
      dataIndex: 'note',
      width: 100,
      render: (text: string) => <div>{text}</div>
    }
  ];

  const fetchData = async () => {
    setIsloading(true);
    const response = await get_purchase_details(id);
    if (response?.purchase.locationId) {
      let locData: any = await LocationsDB.getLocation(response.purchase.locationId);
      if (!locData) {
        locData = await get_location_details(response.purchase.locationId);
        LocationsDB.addLocations([locData]);
      }
      // for (let index = 0; index < response.lines.length; index++) {
      //   let product: any = await ProductsDB.getProduct(response.lines[index].productId);
      //   if (!product) {
      //     product = await get_product_details(response.lines[index].productId);
      //     await ProductsDB.addProducts([product]);
      //   }
      //   response.lines[index].productName = product.name;

      //   let findUnit: any = await UnitsDB.getUnit(response.lines[index].unitId);
      //   if (!findUnit) {
      //     const allUnits = await get_units_list();
      //     await UnitsDB.addUnits(allUnits);
      //     findUnit = await UnitsDB.getUnit(response.lines[index].unitId);
      //   }
      //   response.lines[index].unitName = findUnit.name;
      // }
      const searchProducts: any = {};
      for (let index = 0; index < response.lines.length; index++) {
        const product: any = await ProductsDB.getProduct(response.lines[index].productId);
        if (!product) {
          // product = await get_product_details(response.lines[index].productId);
          // await ProductsDB.addProducts([product]);
          if (response.lines[index].productId in searchProducts) {
            searchProducts[response.lines[index].productId] = [
              ...searchProducts[response.lines[index].productId],
              index
            ];
          } else {
            searchProducts[response.lines[index].productId] = [index];
          }
        } else {
          response.lines[index].productName = product.name;
        }

        let findUnit: any = await UnitsDB.getUnit(response.lines[index].unitId);
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(response.lines[index].unitId);
        }
        // response.lines[index].unitName = findUnit.name;
        response.lines[
          index
        ].quantityWithUnit = `${response.lines[index].quantity} ${findUnit.shortName}`;
      }
      const searchProductslength = Object.entries(searchProducts).length;
      if (searchProductslength > 0) {
        const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
        for (const key in searchProducts) {
          const findproduct = productsresponse?.data?.results.find(
            (currProduct: IProductType) => currProduct.id == key
          );
          if (findproduct) {
            for (let i = 0; i < searchProducts[key].length; i++) {
              response.lines[searchProducts[key][i]].productName = findproduct.name;
            }
            await ProductsDB.addProducts([findproduct]);
          }
        }
      }
      setPurchaseDetails(response);
      setPayList(response.payments);
      setLocationDetails(locData);
      const venData = await get_vendor_details(response?.purchase.vendorId);
      setVendorDetails(venData);
    }
    setIsloading(false);
  };
  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <div>
      <Spin spinning={isLoading}>
        <PageHeader
          title="Purchase Information"
          style={{
            padding: '8px 0px'
          }}
        />

        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-5">
          <div>
            <PageHeader
              subTitle="Supplier"
              style={{
                padding: '8px 0px'
              }}
            />
            {vendorDetails ? (
              <>
                {/* <p>{vendorDetails.user.user.name}</p>
               <p>{`${vendorDetails.user.user.phone}, ${vendorDetails.user.user.email}`}</p> */}
                <div style={{ color: 'black' }}>
                  {vendorDetails?.user?.user?.name ? vendorDetails.user.user.name : ''}
                  {/* </p>
               <p> */}
                  {`${
                    vendorDetails?.user?.user?.phone ? `,${vendorDetails.user.user.phone}` : ''
                  } ${vendorDetails?.user?.user?.email ? `,${vendorDetails.user.user.email}` : ''}`}
                </div>
              </>
            ) : (
              <></>
            )}
            {purchaseDetails && (
              <>
                <div style={{ color: 'name' }}>
                  Buyer Type : <b>{purchaseDetails.purchase.purchaseBuyerType}</b>
                </div>
                <div style={{ color: 'name' }}>
                  Purchase Type : <b>{purchaseDetails.purchase.purchaseType}</b>
                </div>
              </>
            )}
          </div>
          <div>
            <PageHeader
              subTitle="Location"
              style={{
                padding: '8px 0px'
              }}
            />
            {locationDetails ? (
              <>
                <div style={{ color: 'black' }}>
                  {locationDetails?.name ? locationDetails.name : ''}
                  {/* </p>
               <p> */}
                  {`${locationDetails?.zip ? `${locationDetails.zip},` : ''} ${
                    locationDetails?.address ? `${locationDetails.address},` : ''
                  } ${locationDetails?.city ? `${locationDetails.city},` : ''} ${
                    locationDetails?.country ? `${locationDetails.country}` : ''
                  }`}
                  {/* </p>
               <p> */}
                  {`${locationDetails?.phone ? `${locationDetails.phone},` : ''} ${
                    locationDetails?.email ? `${locationDetails.email}` : ''
                  }`}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            <PageHeader
              subTitle="Details"
              style={{
                padding: '8px 0px'
              }}
            />
            {purchaseDetails ? (
              <>
                <div style={{ color: 'name' }}>
                  {purchaseDetails.purchase.financialReference ? (
                    <span className="block">
                      Invoice No. : {purchaseDetails.purchase.financialReference}{' '}
                      <CopyButton text={purchaseDetails.purchase.financialReference} />
                    </span>
                  ) : (
                    <></>
                  )}
                  <span className="block">
                    Reference Number : {purchaseDetails.purchase.referenceNumber}{' '}
                    <CopyButton text={purchaseDetails.purchase.referenceNumber} />
                  </span>
                  <span className="block">
                    {' '}
                    Created :{' '}
                    {convertUTCStringtoLocalString(
                      purchaseDetails.purchase.createdAt,
                      'YYYY-MM-DD'
                    )}
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <CustomizeTable
          columns={columns}
          data={purchaseDetails?.lines ? purchaseDetails.lines : []}
          notshowPagination={true}
        />
        {payList.length > 0 && (
          <>
            <Divider />
            <PageHeader
              subTitle="Payment Info"
              style={{
                padding: '8px 0px'
              }}
            />
            <CustomizeTable columns={columnspay} notshowPagination={true} data={payList} />
          </>
        )}
        <div className="grid grid-cols-3 gap-5 mb-5">
          <div>
            {purchaseDetails ? (
              <div style={{ color: 'black' }}>
                <span className="block">
                  Total Amount : {nepaliNumberFormatter(purchaseDetails.purchase.totalAmount)}
                </span>
                <span className="block">
                  Total Tax : {nepaliNumberFormatter(purchaseDetails.purchase.totalTax)}
                </span>
                <span className="block">
                  Manual Discount : {nepaliNumberFormatter(purchaseDetails.purchase.manualDiscount)}
                </span>
                <span className="block">
                  Amount Returned:{' '}
                  {nepaliNumberFormatter(purchaseDetails.purchase.amountReturned as number)}
                </span>
                <span className="block">
                  Shipping Amount: {nepaliNumberFormatter(purchaseDetails.purchase.shippingAmount!)}
                </span>
                <span className="block">
                  Labour Cost : {nepaliNumberFormatter(purchaseDetails.purchase.labourCost!)}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="flex justify-end  gap-5 mt-5">
          {/* <Link
            to="/custom-invoice"
            target={'_blank'}
            className="link"
            onClick={() =>
              localStorage.setItem(
                'referrer',
                JSON.stringify({
                  purchaseDetails: {
                    ...purchaseDetails?.purchase,
                    payments: purchaseDetails?.payments
                  },
                  locationDetails,
                  vendorDetails: { ...vendorDetails?.user.user },
                  lines: purchaseDetails?.lines,
                  invoiceLayouts
                })
              )
            }>
            <Button type="primary">Print</Button>
          </Link> */}
          <div
            style={{
              width: '3rem',
              // backgroundColor: 'red',
              // border: '1px solid gray',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <ActionDropdown
              trigger={'click'}
              insideaction={true}
              menu={
                <Menu
                  items={invoiceLists?.map((curr: any, ind: number) => {
                    return {
                      key: ind,
                      label: (
                        <Tooltip title="Print receipt" color="blue">
                          <div className="text-center">{curr.name}</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        localStorage.setItem(
                          'referrer',
                          JSON.stringify({
                            purchaseDetails: {
                              ...purchaseDetails?.purchase,
                              payments: purchaseDetails?.payments
                            },
                            locationDetails,
                            vendorDetails: { ...vendorDetails?.user.user },
                            lines: purchaseDetails?.lines,
                            invoiceLayouts
                          })
                        );
                        window.open('/ERP-UI#/custom-invoice', '_blank', 'noopener,noreferrer');
                      }
                    };
                  })}
                />
              }
            />
          </div>
          {/* <Button
            type="primary"
            onClick={() => {
              createPDF({
                invoiceLayouts,
                purchaseDetails,
                vendorDetails,
                locationDetails,
                columns: [
                  `${invoiceLayouts ? invoiceLayouts.content.productLabel : 'Product'}`,
                  `${invoiceLayouts ? invoiceLayouts.content.unitPriceLabel : 'unit price'}`,
                  'unitPrice',
                  `${invoiceLayouts ? invoiceLayouts.content.quantityLabel : 'quantity'}`,
                  'Misc',
                  `${invoiceLayouts ? invoiceLayouts.content.discountLabel : 'discount'}`,
                  `${invoiceLayouts ? invoiceLayouts.content.totalLabel : 'total'}`
                ],
                columnspay: ['Date', 'Reference No', 'Amount', 'Payment Mode', 'Note']
              });
            }}>
            Print
          </Button> */}
          {/* <Button type="default" onClick={() => navigate('/purchase')}>
              Back
            </Button> */}
        </div>
        <Divider />
        {/* <ReuseChannel slug={`purchase_${id}`} /> */}
        <ReuseChannel
          slug={`purchase_${id}`}
          fromSellorPurchaseId={vendorDetails?.vendor?.userId as string}
          withReferenceandId={{ reference: 'sell_order', referenceId: id }}
        />
      </Spin>
    </div>
  );
};

export default ReusablePurchaseView;
