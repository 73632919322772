import { useState } from 'react';

import { ITransferDetails } from '../../../../services/transfer/types';
import { ITransferInvoice } from '../../../../services/invoice/types';
import { CustomModal } from '../../../../components/Common/CustomModal';
import { ICreateInvoiceResponse } from '../../../../services/settings/types';

import InvoicePrintButton from '../../../../components/Common/InvoicePrintButton/InvoicePrintButton';
import TransferInvoice from '../../../../components/Common/InvoicePrint/TransferInvoice/TransferInvoice';
import { getTransferOrderPrintData } from '../../../../components/Common/InvoicePrint/TransferInvoice/tranferOrderPrintData';

interface Props {
  transferOrder: ITransferDetails;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

function PrintTransferOrder({ setIsLoading, transferOrder }: Props) {
  const [invoiceData, setInvoiceData] = useState<ITransferInvoice>(Object);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState(false);

  async function generateInvoiceForPrint(curr: ICreateInvoiceResponse) {
    setIsLoading(true);
    const invoiceModalData = await getTransferOrderPrintData(
      transferOrder.id,
      transferOrder.from,
      transferOrder.to,
      curr
    );

    setIsLoading(false);
    setInvoiceData(invoiceModalData);
    setOpenModalForInvoicePrint(true);
  }
  return (
    <>
      <CustomModal
        footer={false}
        isModalOpen={openModalForInvoicePrint}
        setIsModalOpen={setOpenModalForInvoicePrint}
        title="Transfer Order Invoice Print">
        <TransferInvoice
          transferData={invoiceData.transferData}
          lines={invoiceData.lines}
          invoiceLayouts={invoiceData.invoiceLayouts}
          isOrder
        />
      </CustomModal>
      <InvoicePrintButton getInvoice={generateInvoiceForPrint} />
    </>
  );
}

export default PrintTransferOrder;
