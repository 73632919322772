import moment from 'moment';
import { IDateRange } from '@/services/dashboard/v3/types';

export function getPreviousRange(start: string, end: string) {
  const startMoment = moment(start).startOf('day').millisecond(0);
  const endMoment = moment(end).endOf('day').millisecond(0);

  // Calculate the duration of the range
  const duration = endMoment.diff(startMoment, 'days');

  // Calculate the previous range
  const prevStartMoment = startMoment
    .clone()
    .subtract(duration + 1, 'days')
    .startOf('day')
    .millisecond(0)
    .toISOString();

  const prevEndMoment = startMoment
    .clone()
    .subtract(1, 'day')
    .endOf('day')
    .millisecond(0)
    .toISOString();
  return [prevStartMoment, prevEndMoment] as IDateRange;
}
