import { filterId } from '@/constants/filter.category';
import {
  getAgent,
  getCustomer,
  getLocation,
  getUser,
  getVehicle,
  getVendors
} from '@/pages/expense/view/services.expense';

export async function getPopulatedData(data: any[]) {
  const filterExpenseId = filterId.filter((filter) => filter.key !== 'expenseId');

  data.forEach(async (item) => {
    const currentItemKeys = Object.keys(item);

    for (const key of currentItemKeys) {
      const filter = filterExpenseId.find((filter) => filter.key === key);
      if (filter) {
        switch (key) {
          case 'locationId': {
            const location = await getLocation(item[key]);
            item[key] = location.name;
            break;
          }
          case 'vehicleId': {
            const vehicle = await getVehicle(item[key]);
            item[key] = vehicle.name;
            break;
          }
          case 'agentId': {
            const agent = await getAgent(item[key]);
            item[key] = agent?.user?.name || agent?.id;
            break;
          }
          case 'userId': {
            const user = await getUser(item[key]);
            item[key] = user.name;
            break;
          }
          case 'customerId': {
            const customer = await getCustomer(item[key]);
            item[key] = customer.user?.name || customer.id;
            break;
          }
          case 'vendorId': {
            const vendor = await getVendors(item[key]);
            if (typeof vendor === 'string') {
              item[key] = vendor;
              break;
            }

            item[key] = vendor.user?.name || vendor.id;
            break;
          }
          default:
            break;
        }
      }
    }

    return item;
  });

  return data;
}
