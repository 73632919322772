import { createContext, useContext, useEffect, useState } from 'react';

import { IDefaultAPIConfig, IWidgetConfiguration } from '@/services/dashboard/v3/types';
import { IReportWithFields } from '@/services/report/types';
import {
  find_dashboard_filter,
  find_locationId_preference
} from '@/store/localstorage/preferences';
import { arrayMove } from '@dnd-kit/sortable';
import { fetchReportLists } from '@/services/dashboard/v3/services.dashboard';

const defaultAPIConfig = {
  locationId: find_locationId_preference() || 1,
  page: 1,
  size: -1
};

interface ICustomizeDashboardContext {
  isLoading: boolean;
  isAnyWidgetResizing: boolean;
  defaultAPIConfig: IDefaultAPIConfig;
  currentWidgets: IWidgetConfiguration[];
  editingWidgets: IWidgetConfiguration[];
  reportLists: IReportWithFields[];
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAnyWidgetResizing: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentWidgets: React.Dispatch<React.SetStateAction<IWidgetConfiguration[]>>;
  setEditingWidgets: React.Dispatch<React.SetStateAction<IWidgetConfiguration[]>>;
  moveWidget: (widgetKey: string, directtion: 'left' | 'right') => void;
}

const DashboardContext = createContext<ICustomizeDashboardContext | undefined>(undefined);

function CustomizeDashboardProvider({ children }: { children: React.ReactNode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isAnyWidgetResizing, setIsAnyWidgetResizing] = useState(false);
  const [currentWidgets, setCurrentWidgets] = useState<IWidgetConfiguration[]>([]);
  const [editingWidgets, setEditingWidgets] = useState<IWidgetConfiguration[]>([]);

  const [reportLists, setReportLists] = useState<IReportWithFields[]>([]);

  function moveWidget(id: string, directtion: 'left' | 'right') {
    setEditingWidgets((widgets) => {
      const isLeft = directtion === 'left';
      const originalPosition = widgets.findIndex((item) => item.id === id);

      if (isLeft && originalPosition === 0) return widgets;
      if (!isLeft && originalPosition === widgets.length - 1) return widgets;

      const newPosition = isLeft ? originalPosition - 1 : originalPosition + 1;
      return arrayMove(widgets, originalPosition, newPosition);
    });
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const dashboardConfig = find_dashboard_filter();
      const reportListsWithFields = await fetchReportLists();

      setReportLists(reportListsWithFields);
      setCurrentWidgets(dashboardConfig);
      setEditingWidgets(dashboardConfig);
      setIsLoading(false);
    })();
  }, []);

  return (
    <DashboardContext.Provider
      value={{
        isLoading,
        reportLists,
        currentWidgets,
        editingWidgets,
        defaultAPIConfig,
        isAnyWidgetResizing,
        moveWidget,
        setIsLoading,
        setEditingWidgets,
        setCurrentWidgets,
        setIsAnyWidgetResizing
      }}>
      {children}
    </DashboardContext.Provider>
  );
}

function useDashboardCustomize() {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }

  return context;
}

export default CustomizeDashboardProvider;
export { useDashboardCustomize };
