import { Button, Divider, Form, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  get_log_events_list,
  get_log_events_list_all
} from '../../../services/logs/events/queries';
import { ILogEvents } from '../../../services/logs/events/types';
import useDebounce from '../../../hooks/useDebounce';

interface EventSearchProps {
  formData?: {
    formName: string;
    label: string;
  };
  isAll?: boolean;
  required?: boolean;
  style?: string;
}

const { Option } = Select;

export const EventSearch: React.FC<EventSearchProps> = ({
  formData,
  isAll = true,
  required = false,
  style = null
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 1000);
  const [eventSearch, setEventSearch] = useState<any>([]);
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);
  const [fetching, setFetching] = useState<boolean>(false);

  useEffect(() => {
    searchEvent(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const searchEvent = async (value: string) => {
    if (fetching) {
      return;
    }
    setFetching(true);
    let response;
    try {
      response = await get_log_events_list_all(skip, 50, value);
      console.log('response for event', response);
    } catch (error) {
      console.error(error);
    }

    if (!response || response.results.length == 0) {
      message.error('Cannot find any such event!');
      setEventSearch([]);
      setIsMore(false);
    } else {
      setEventSearch([...eventSearch, ...response.results]);
      setSkip(skip + 20);
      if (response.results.length < 10) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
    }
    setFetching(false);
  };

  const options = eventSearch.map((value: ILogEvents) => (
    <Option key={value.id} value={value.id}>
      {value.url}
    </Option>
  ));
  return (
    <div className={style ?? ''}>
      {formData ? (
        <Form.Item
          name={formData.formName}
          label={formData.label}
          rules={[
            {
              required: required,
              message: 'Please choose an event!'
            }
          ]}>
          <Select
            showSearch
            allowClear
            placeholder="Select an event"
            defaultActiveFirstOption={false}
            showArrow={false}
            style={{ width: '100%' }}
            filterOption={false}
            dropdownMatchSelectWidth={false}
            onSearch={(val) => {
              setSearchValue(val);
              setSkip(0);
              setEventSearch([]);
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {isMore ? (
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchEvent(searchValue);
                      }}>
                      {'Pull More & Sync'}
                    </Button>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center'
                      }}>
                      No more data...
                    </div>
                  )}
                </div>
              </>
            )}>
            {isAll ? <Option value="">All</Option> : null}
            {options}
          </Select>
        </Form.Item>
      ) : (
        <Select
          showSearch
          clearIcon
          placeholder="Select an event"
          defaultActiveFirstOption={false}
          showArrow={false}
          style={{ width: '100%' }}
          filterOption={false}
          notFoundContent={null}
          dropdownMatchSelectWidth={false}
          onSearch={(val) => {
            setSearchValue(val);
            setSkip(0);
            setEventSearch([]);
          }}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                <Button
                  type="text"
                  style={{
                    color: 'blue',
                    width: '100%'
                  }}
                  onClick={() => {
                    searchEvent(searchValue);
                  }}>
                  {'Pull More & Sync'}
                </Button>
              </div>
            </>
          )}>
          {isAll ? <Option value="">All</Option> : null}
          {console.log('options', options)}
          {options}
        </Select>
      )}
    </div>
  );
};
