import { Button, message, PageHeader, Upload } from 'antd';
import React, { useState } from 'react';
import AppContent from '../../../components/Common/Content/Content';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { upload_offer_file_mutation } from '../../../services/offfers/mutations';
import { download_offer_file } from '../../../services/offfers/queries';
import { download_report } from '../../../services/report/queries';

const Offerupload = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isloading, setIsloading] = useState<boolean>(false);
  const uploadMutation = useMutation(upload_offer_file_mutation);
  const breadcrumbItems = [
    {
      label: 'Offers',
      link: '/offer'
    },
    {
      label: 'Upload',
      link: '/offer/upload'
    }
  ];

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    // console.log(newFileList);
    setFileList(newFileList);
  };
  const beforeUpload = (values: any) => {
    return false;
  };

  const uploadProps: UploadProps = {
    name: 'Offers',
    multiple: false,
    maxCount: 1,
    fileList: fileList,
    accept: '.xlsx',
    onChange: onChange,
    beforeUpload: beforeUpload,
    showUploadList: {
      showPreviewIcon: false,
      showRemoveIcon: true
    }
  };
  const handleUpload = () => {
    // console.log('handle Upload clicekd...');
    try {
      const formData = new FormData();
      console.log(fileList[0]);
      formData.append('file', fileList[0].originFileObj as RcFile);
      const values = {
        file: formData
      };
      uploadMutation.mutateAsync(values, {
        onSuccess: async ({ data }: { data: any }) => {
          message.success('Uploaded successfully');
          console.log(data);
        },
        onError: (error: any) => {
          console.log(error);
          message.error(error.message);
        }
      });
      setIsloading(false);
    } catch (error: any) {
      message.error('Unable to upload');
    }
  };
  const downloadOfferFile = async () => {
    try {
      const response = await download_offer_file();
      // console.log('Response-->', response);
      window.open(response.data, '_blank');
    } catch (err: any) {
      message.error(err.message);
    }
  };
  const productDownload = async () => {
    const response = await download_report({}, 31);
    window.open(response.data, '_blank');
  };

  return (
    <AppContent breadcrumbItems={breadcrumbItems}>
      <PageHeader
        title="Upload Offer"
        style={{
          padding: '8px 0px'
        }}
      />
      <div className="flex gap-5">
        <div>
          <Upload {...uploadProps}>
            <Button style={{ width: 150 }} icon={<UploadOutlined />} disabled={fileList.length > 0}>
              Upload File
            </Button>
          </Upload>
        </div>
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={isloading}
          style={{ backgroundColor: '#0AA245', color: 'white', borderRadius: '10px' }}>
          {isloading ? 'loading' : 'Start Upload'}
        </Button>
        <Button
          onClick={() => downloadOfferFile()}
          style={{ backgroundColor: '#0AA245', color: 'white', borderRadius: '10px' }}>
          Download File
        </Button>
        <Button
          onClick={() => productDownload()}
          style={{ backgroundColor: '#0AA245', color: 'white', borderRadius: '10px' }}>
          Product Download
        </Button>
      </div>
    </AppContent>
  );
};

export default Offerupload;
