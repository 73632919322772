import { useEffect, useState } from 'react';
import { Button, Divider, Form, message, Select } from 'antd';

import useDebounce from '@/hooks/useDebounce';
import { AgentsDB } from '@/store/localstorage/AgentsDB';
import { get_agents_list_v2 } from '@/services/agents/queries';

interface Default {
  defaultValue?: number;
  returnValue?: 'agentId' | 'userId';
  onSelect?: (value: string | number) => void;
}

type Props = Default &
  (
    | {
        hasParentFormItem: true;
      }
    | {
        hasParentFormItem: false;
        formName?: string;
        label?: string;
        required?: boolean;
      }
  );

type UserSearchType = 'initial' | 'more';

function AgentsSearch({
  onSelect,
  defaultValue: defaultAgent,
  returnValue = 'userId',
  ...props
}: Props) {
  const [skip, setSkip] = useState(0);
  const [isMore, setIsMore] = useState(true);

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const [userSearch, setUserSearch] = useState<any>([]);
  const [isCacheResponse, setIsCacheResponse] = useState(false);

  useEffect(() => {
    searchUserIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue, defaultAgent]);

  async function searchUserIndexDB(value: string) {
    try {
      const agents = await AgentsDB.searchAgent(value);
      const currentAgent = defaultAgent
        ? (await AgentsDB.getAgentsByUserIds([defaultAgent]))[0]
        : null;

      // Merge currentAgent in agents
      const defaultInAgent = agents.find((agent) => agent.id === defaultAgent);
      if (defaultAgent && !defaultInAgent && currentAgent) {
        agents.unshift(currentAgent);
      }

      if (!agents || agents.length == 0) {
        message.error('Cannot find any agent with that value in cache, searching in server...');
        searchUser(value, 'initial');
        return;
      }

      setUserSearch(agents);
      setIsCacheResponse(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function searchUser(value: string, searchType: UserSearchType) {
    try {
      const currentSkip = searchType === 'initial' ? 0 : skip;

      const { data } = await get_agents_list_v2(currentSkip, 10, value);
      const isValidResponse = data && data.results.length > 0;
      if (!isValidResponse) {
        message.error('Cannot find any agents with that name!');
        setIsMore(false);
        searchType === 'initial' && setUserSearch([]);
      }

      if (isValidResponse) {
        searchType === 'initial'
          ? setUserSearch(data.results)
          : setUserSearch((prev: any) => [...prev, ...data.results]);

        AgentsDB.addAgents(data.results);
        setIsMore(data.results.length >= 10);

        searchType === 'more' && setSkip(currentSkip + 10);
      }

      if (searchType === 'initial') {
        setIsCacheResponse(false);
        setSkip(10);
      }
    } catch (error) {
      message.error(error as string);
    }
  }

  const options = userSearch?.map((value: any) => {
    return (
      <Select.Option key={value.id} value={returnValue === 'userId' ? value.userId : value.id}>
        {value.user.name}
      </Select.Option>
    );
  });

  const selectMenu = (
    <Select
      showSearch
      allowClear
      filterOption={false}
      placeholder="Search agent"
      defaultActiveFirstOption={false}
      onSearch={(val) => setSearchValue(val)}
      notFoundContent={<div>No Content</div>}
      onChange={onSelect}
      dropdownMatchSelectWidth={false}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            {isCacheResponse ? (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                <Button
                  type="text"
                  style={{ color: 'blue', width: '100%' }}
                  onClick={() => searchUser(searchValue, 'initial')}>
                  {'Pull More & Sync'}
                </Button>
              </div>
            ) : (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                {isMore ? (
                  <Button
                    type="text"
                    style={{ color: 'blue', width: '100%' }}
                    onClick={() => searchUser(searchValue, 'more')}>
                    {'Get More...'}
                  </Button>
                ) : (
                  <div style={{ width: '100%', textAlign: 'center' }}>No more data...</div>
                )}
              </div>
            )}
          </>
        );
      }}>
      {options}
    </Select>
  );

  if (props.hasParentFormItem) return selectMenu;
  return (
    <Form.Item
      label={props.label || 'Select Agents'}
      name={props.formName || 'agentId'}
      rules={[{ required: props.required || false, message: 'Please select agent!' }]}>
      {selectMenu}
    </Form.Item>
  );
}

export default AgentsSearch;
