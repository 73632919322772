export function createEmptyPromise<T>(obj: T): Promise<T> {
  return new Promise((resolve) => {
    resolve(obj);
  });
}

export function createEmptyVoidPromise(): Promise<void> {
  return new Promise((resolve) => {
    resolve();
  });
}
