import { Layout, Divider, message } from 'antd';
import PosMenu from './menu.layout';
import PosHeader from './header.layout';
import { useCallback, useContext, useEffect, useState } from 'react';
import { CustomModal } from '../../components/Common/CustomModal';
import { SellListPos } from '../../pages/posV2/create/sellListPos';
import SellReturnList from '../../pages/sell/sell-return/list';
import ReusableSellView from '../../pages/sell/view/ReusableView';
import { FormInstance } from 'antd/es/form/Form';
import { PosSessionContext } from './posSession.context';
import {
  SellReturnListContext,
  SellReturnListContextComp
} from '../../pages/sell/sell-return/list/context';

const { Content } = Layout;

const Pos = ({
  children,
  form,
  formValues
}: {
  children: React.ReactElement;
  form: FormInstance<any>;
  formValues: any;
}) => {
  const { isSessionStart, setIsSessionStart } = useContext(PosSessionContext);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [sellId, setSellId] = useState<number>(0);
  const [isSellModalOpen, setIsSellModalOpen] = useState<boolean>(false);
  const [isSellReturnModalOpen, setIsSellReturnModalOpen] = useState<boolean>(false);
  const [isRecentTransactionModalOpen, setIsRecentTransactionModalOpen] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const onMenuClick = (key: string, value?: string) => {
    // console.log(key);
    if (key == 'Sell') {
      setIsSellModalOpen(true);
    } else if (key == 'Return List') {
      setIsSellReturnModalOpen(true);
    } else if (key == 'Recent Transaction' && value) {
      setSellId(parseInt(value));
      setIsRecentTransactionModalOpen(true);
    }
  };

  const goToFullScreen = () => {
    document.documentElement.requestFullscreen().then(
      () => {
        console.log('FullScreen');
      },
      () => {
        message.error(
          'Cannot go to full screen on page reload. Please press F11 to go to fullscreen.'
        );
      }
    );
  };

  const exitFullScreen = () => {
    document.exitFullscreen();
  };

  const fullScreenFunction = () => {
    if (document.fullscreenElement && document.fullscreenElement !== null) {
      // full screen
      setIsFullScreen(true);
    } else {
      // exit full screen
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    goToFullScreen();
  }, []);

  useEffect(() => {
    document.addEventListener('fullscreenchange', fullScreenFunction);

    return () => {
      document.removeEventListener('fullscreenchange', fullScreenFunction);
    };
  }, [fullScreenFunction]);

  return (
    <>
      <Layout id="fullScreen">
        <PosMenu
          onMenuClick={onMenuClick}
          form={form}
          formValues={formValues}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          isSessionStart={isSessionStart}
        />
        <Layout>
          <Content style={{ margin: '0 16px', overflow: 'initial' }}>
            <PosHeader
              isSessionStart={isSessionStart}
              setIsSessionStart={setIsSessionStart}
              isFullScreen={isFullScreen}
              onFullScreenClick={goToFullScreen}
              onExitFullScreenClick={exitFullScreen}
            />
            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
            {children}
          </Content>
          {isSellModalOpen && (
            <CustomModal
              footer={false}
              width="90%"
              style={{ overflowX: 'scroll', top: '20px' }}
              isModalOpen={isSellModalOpen}
              setIsModalOpen={setIsSellModalOpen}
              title={'Sell'}>
              <SellListPos />
            </CustomModal>
          )}
          {isSellReturnModalOpen && (
            <CustomModal
              footer={false}
              style={{ overflowX: 'scroll', top: '20px' }}
              width="90%"
              isModalOpen={isSellReturnModalOpen}
              setIsModalOpen={setIsSellReturnModalOpen}
              title={'Return List'}>
              {/* <SellReturnListContext.Provider
      value={{
        isLoading,
        setIsLoading,
        form,
        urlData,
        setUrlData
      }}></SellReturnListContext.Provider>
              <SellReturnList />
              </SellReturnListContext.Provider> */}
              <SellReturnListContextComp />
            </CustomModal>
          )}
          {isRecentTransactionModalOpen && (
            <CustomModal
              footer={false}
              style={{ overflowX: 'scroll', top: '20px' }}
              width="90%"
              isModalOpen={isRecentTransactionModalOpen}
              setIsModalOpen={setIsRecentTransactionModalOpen}
              title={'Recent Transaction'}>
              {/* <iframe src="/#/sell" width="100%" height="100%"></iframe> */}
              <ReusableSellView id={sellId} fromPos={true} />
            </CustomModal>
          )}
        </Layout>
      </Layout>
    </>
  );
};

export default Pos;
