import { Fragment } from 'react';
import { PageHeader } from 'antd';

import { convertUTCStringtoLocalString } from '../../../../utils/convertToUTC';
import { User } from '../../../../services/users/types';
import { ITransferDetails } from '../../../../services/transfer/types';

interface Props {
  createdBy: User | null;
  data: ITransferDetails | undefined;
}

function MenuList({ createdBy, data }: Props) {
  const menu = [
    {
      key: 'customer-details',
      title: 'Customer',
      value: [createdBy?.name, createdBy?.phone, createdBy?.email]
        .filter(Boolean) // Remove falsy values (null, undefined, empty string)
        .map((value, index, array) => (
          <Fragment key={index}>
            {value}
            {index < array.length - 1 && ', '}
          </Fragment>
        ))
    },
    {
      key: 'location',
      title: 'Location',
      value: `${data?.fromName} to ${data?.toName}`
    },
    {
      key: 'checklist',
      title: 'Checklist',
      value: formatChecklist(data?.checklist)
    },
    {
      key: 'financial-references',
      title: 'Financial References',
      value: data?.financialReference || 'N/A'
    },
    {
      key: 'references-no',
      title: 'References No',
      value: data?.referenceNo || 'N/A'
    },
    {
      key: 'transfer-date',
      title: 'Transfer Date',
      value: data?.transferDate ? convertUTCStringtoLocalString(data.transferDate) : 'N/A'
    },
    {
      key: 'last-updated-date',
      title: 'Last Updated',
      value: data?.updatedAt ? convertUTCStringtoLocalString(data.updatedAt) : 'N/A'
    }
  ];

  function formatChecklist(checklist?: string) {
    if (!checklist) return 'N/A';

    try {
      // Parse the checklist string into an object
      const checklistObj = JSON.parse(checklist);
      const formattedChecklist = Object.entries(checklistObj)
        .map(([key, value]) => `${key} = ${value}`)
        .join(', '); // Format the object entries
      return formattedChecklist; // Return the formatted checklist string
    } catch (error) {
      return checklist;
    }
  }

  const menuList = menu.map((item) => (
    <div key={item.key}>
      <PageHeader subTitle={item.title} style={{ padding: '8px 0px' }} />
      <p className="!text-sm">{item.value}</p>
    </div>
  ));

  return <>{menuList}</>;
}

export default MenuList;
