import moment from 'moment';

type DateType = 'Daily' | 'Weekly' | 'Monthly';
export const DateSegmentPresets: Record<DateType, [string, string]> = {
  Daily: [
    moment().startOf('day').millisecond(0).toISOString(),
    moment().endOf('day').millisecond(0).toISOString()
  ],
  Weekly: [
    moment(0, 'HH').subtract(6, 'days').startOf('day').millisecond(0).toISOString(),
    moment(0, 'HH').endOf('day').millisecond(0).toISOString()
  ],
  Monthly: [
    moment(0, 'HH').subtract(29, 'days').startOf('day').millisecond(0).toISOString(),
    moment(0, 'HH').endOf('day').millisecond(0).toISOString()
  ]
};

const FinancialPresetsOnly: Record<string, [moment.Moment, moment.Moment]> = {
  'Current financial year':
    moment().month() <= 6 && moment().date() < 16
      ? [
          moment().subtract(1, 'year').month(6).date(16).startOf('day'),
          moment().month(6).date(15).endOf('day')
        ]
      : [
          moment().month(6).date(16).startOf('day'),
          moment().add(1, 'year').month(6).date(15).endOf('day')
        ],
  'Last financial year':
    moment().month() <= 6 && moment().date() < 16
      ? [
          moment().subtract(2, 'year').month(6).date(16).startOf('day'),
          moment().subtract(1, 'year').month(6).date(15).endOf('day')
        ]
      : [
          moment().subtract(1, 'year').month(6).date(16).startOf('day'),
          moment().month(6).date(15).endOf('day')
        ]
};

export const DashboardDatePresets: Record<string, [moment.Moment, moment.Moment]> = {
  Today: [moment().startOf('day').millisecond(0), moment().endOf('day').millisecond(0)],
  Yesterday: [
    moment().subtract(1, 'days').startOf('day').millisecond(0),
    moment().subtract(1, 'days').endOf('day').millisecond(0)
  ],
  'Last 7 Days': [
    moment().subtract(6, 'days').startOf('day').millisecond(0),
    moment().endOf('day').millisecond(0)
  ],
  'Last 14 Days': [
    moment().subtract(13, 'days').startOf('day').millisecond(0),
    moment().endOf('day').millisecond(0)
  ],
  'Last 30 Days': [
    moment().subtract(29, 'days').startOf('day').millisecond(0),
    moment().endOf('day').millisecond(0)
  ],
  'This Month': [moment().startOf('month').millisecond(0), moment().endOf('month').millisecond(0)],
  'Last Month': [
    moment().subtract(1, 'months').startOf('month').millisecond(0),
    moment().subtract(1, 'months').endOf('month').millisecond(0)
  ],
  'This Month Last Year': [
    moment().subtract(1, 'years').startOf('month').millisecond(0),
    moment().subtract(1, 'years').endOf('month').millisecond(0)
  ],
  'This Year': [moment().startOf('year').millisecond(0), moment().endOf('year').millisecond(0)],
  'Last Year': [
    moment().subtract(1, 'years').startOf('year').millisecond(0),
    moment().subtract(1, 'years').endOf('year').millisecond(0)
  ],
  ...FinancialPresetsOnly
};

export const FinancialDatePresets: Record<string, [moment.Moment, moment.Moment]> = {
  Today: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
  Yesterday: [moment(0, 'HH').subtract(1, 'days'), moment(0, 'HH').subtract(1, 'millisecond')],
  'Last 7 Days': [moment(0, 'HH').subtract(6, 'days'), moment(0, 'HH').add(1, 'days')],
  'Last 14 Days': [moment(0, 'HH').subtract(13, 'days'), moment(0, 'HH').add(1, 'days')],
  'Last 30 Days': [moment(0, 'HH').subtract(29, 'days'), moment(0, 'HH').add(1, 'days')],
  'This Month': [moment(0, 'HH').startOf('month'), moment(0, 'HH').add(1, 'days')],
  'Last Month': [
    moment(0, 'HH').subtract(1, 'months').startOf('month'),
    moment(0, 'HH').subtract(1, 'months').endOf('month')
  ],
  'This month last year': [
    moment(0, 'HH').subtract(1, 'years').startOf('month'),
    moment(0, 'HH').subtract(1, 'years').endOf('month')
  ],
  'This Year': [moment(0, 'HH').startOf('year'), moment(0, 'HH').add(1, 'days')],
  'Last Year': [
    moment(0, 'HH').subtract(1, 'years').startOf('year'),
    moment(0, 'HH').subtract(1, 'years').endOf('year')
  ]
};

export const CustomDatePresets: Record<string, [any, any]> = {
  ...FinancialDatePresets,
  ...FinancialPresetsOnly
};
