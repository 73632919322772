import { BiError } from 'react-icons/bi';

interface Props {
  message?: string;
}

function ErrorWidget({ message = 'Error loading widget' }: Props) {
  return (
    <div className={'flex justify-center flex-col items-center bg-white gap-2 h-full'}>
      <BiError size={32} color="#ff5b5b" />
      <span className="text-base font-semibold font-sans">{message}</span>
    </div>
  );
}

export default ErrorWidget;
