import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  ICreateSessionMoney,
  IPosMoneyResponseData,
  IPosSessionResponse,
  ISessionDataForServer,
  IUpdateSessionMoney
} from './types';

export const create_session_mutation = async () => {
  const response: AxiosResponse<IPosSessionResponse, any> = await http.store('session');
  return response.data;
};

export const update_session_mutation = async (values: ISessionDataForServer) => {
  const response: AxiosResponse<IPosSessionResponse, any> = await http.update(
    `session/${values.id}`,
    {
      lines: values.lines
    }
  );
  return response.data;
};

export const create_session_money_mutation = async (value: ICreateSessionMoney) => {
  const response: AxiosResponse<IPosMoneyResponseData, any> = await http.store('money', value);
  return response.data;
};

export const update_session_money_mutation = async (value: IUpdateSessionMoney) => {
  const response: AxiosResponse<IPosMoneyResponseData, any> = await http.update(
    `money/${value.id}`,
    value
  );
  return response.data;
};
