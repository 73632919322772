import { Divider, Image, PageHeader, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { get_image } from '../../../components/Common/FetchImage/FetchImage';
import { API_URL } from '../../../constants/config';
import { get_invoices_list } from '../../../services/settings/queries';
import freshktmfulllogo from '../../../layouts/logo/freshktmfulllogo.png';
import type { ColumnsType } from 'antd/es/table';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import CustomTable from './CustomTable';
import CustomBarcode from '../../../pages/barcode';
import { nepaliNumberFormatter } from '../../../utils/numberFormatter';
import {
  convertLocalToUTCString,
  convertUTCStringtoLocalString
} from '../../../utils/convertToUTC';
import { clearLocalStorage } from '../../../utils/storage.utils';
import { getUserData } from '../../../utils/auth.utils';
import { find_invoice_number } from '../../../store/localstorage/preferences';

export const CustomResuableInvoice = () => {
  // const { state }: any = useLocation();
  const loggedInUser = getUserData();
  const state = JSON.parse(localStorage.getItem('referrer') as string);
  const {
    customerDetails,
    vendorDetails,
    locationDetails,
    purchaseDetails,
    lines,
    invoiceLayouts,
    adjustmentDetails,
    transferData,
    printCount
  } = state;
  console.log(state);
  const [imgSrc, setimgSrc] = useState('');
  if (invoiceLayouts) {
    if ('content' in invoiceLayouts) {
      if (typeof invoiceLayouts.content == 'string')
        invoiceLayouts.content = JSON.parse(invoiceLayouts.content);
    } else {
      invoiceLayouts.content = '';
    }
  }

  useEffect(() => {
    getInfo();
  }, []);
  const getInfo = async () => {
    const response = await get_invoices_list();
    if ('invoiceLogo' in invoiceLayouts.content) {
      const imageData = await get_image(
        `${API_URL}storage/media/${JSON.parse(response.data[0].content).invoiceLogo}`
      );
      setimgSrc(imageData);
    } else {
      window.print();
      window.onafterprint = (event) => {
        window.close();
        localStorage.removeItem('referrer');
      };
    }
  };

  const columns: any = [
    {
      label: `${
        invoiceLayouts?.content?.productLabel ? invoiceLayouts.content.productLabel : 'ProductName'
      }`,
      dataIndex: 'productName',
      // width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.unitPriceLabel
          ? invoiceLayouts.content.unitPriceLabel
          : 'UnitPrice'
      }`,
      dataIndex: 'unitPrice',
      // width: 100,
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.quantityLabel ? invoiceLayouts.content.quantityLabel : 'Quantity'
      }`,
      dataIndex: 'quantityWithUnit',
      // width: 100,
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: 'Misc',
      dataIndex: 'misc',
      // width: 100,
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.discountLabel ? invoiceLayouts.content.discountLabel : 'Discount'
      }`,
      dataIndex: 'discount',
      // width: 100,
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.totalLabel ? invoiceLayouts.content.totalLabel : 'Total Amount'
      }`,
      dataIndex: 'totalAmount',
      // width: 100,
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    }
  ];

  interface ColumnData {
    dataIndex: string;
    label: string;
  }
  const columnss: ColumnData[] = [
    {
      dataIndex: 'productName',
      label: 'Product Name'
    },
    {
      dataIndex: 'unitPrice',
      label: 'Unit Price'
    },
    {
      dataIndex: 'quantity',
      label: 'Quantity'
    },
    {
      dataIndex: 'misc',
      label: 'Misc'
    },
    {
      dataIndex: 'discount',
      label: 'Discount'
    },
    {
      dataIndex: 'totalAmount',
      label: 'Total Amount'
    }
  ];

  const paymentColumns: any = [
    {
      label: 'Date',
      dataIndex: 'date',
      render: (text: string) => <div>{text.slice(0, 10)}</div>
    },
    {
      label: 'Reference No.',
      dataIndex: 'reference',
      render: (text: string) => <div>{text}</div>
    },
    {
      label: 'Amount',
      dataIndex: 'amount',
      render: (text: number) => <div>{nepaliNumberFormatter(text)}/-</div>
    },
    {
      label: 'Payment Mode',
      dataIndex: 'paymentMode',
      render: (text: number) => <div>{text}</div>
    },
    {
      label: 'Payment note',
      dataIndex: 'note',
      render: (text: number) => <div>{text}</div>
    }
  ];

  useEffect(() => {
    if (purchaseDetails) {
      window.print();
      window.onafterprint = (event) => {
        window.close();
        localStorage.removeItem('referrer');
      };
    }
  }, []);

  return (
    <div>
      <div className="flex flex-col justify-between">
        {printCount > 0 && (
          <div className="flex justify-start font-bold text-sm">Copy of Original {printCount}</div>
        )}
        <div className="flex justify-between font-bold text-xl mt-4">
          Purchase Details (
          {invoiceLayouts?.content?.referenceNumberLabel
            ? invoiceLayouts?.content?.referenceNumberLabel
            : 'Reference No'}{' '}
          : {purchaseDetails.referenceNumber})
        </div>
      </div>
      <hr />
      <div className="grid grid-cols-3 gap-5 mb-5">
        {invoiceLayouts && invoiceLayouts?.content?.showBusinessName ? (
          <>
            {vendorDetails && (
              <div>
                <PageHeader
                  subTitle="Supplier"
                  style={{
                    padding: '8px 0px',
                    fontWeight: 'bold'
                  }}
                />
                {vendorDetails ? (
                  <>
                    <div>{vendorDetails.name}</div>
                    <div>{`${vendorDetails.phone}, ${vendorDetails.email}`}</div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}
            {customerDetails && (
              <div>
                <PageHeader
                  subTitle="Customer"
                  style={{
                    padding: '8px 0px',
                    fontWeight: 'bold'
                  }}
                />
                {customerDetails ? (
                  <>
                    <div>{customerDetails.name}</div>
                    <div>{`${customerDetails.phone}, ${customerDetails.email}`}</div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}
          </>
        ) : (
          <></>
        )}
        {adjustmentDetails && (
          <div>
            <PageHeader
              subTitle="Business"
              style={{
                padding: '4px 0px'
              }}
            />
            {locationDetails ? (
              <>
                <div>{locationDetails.name}</div>
                <div>
                  {`${locationDetails.zip}, ${locationDetails.address}, ${locationDetails.city}, ${locationDetails.country}`}
                </div>
                <div>{`${locationDetails?.phone}, ${locationDetails.email}`}</div>
              </>
            ) : (
              <></>
            )}
          </div>
        )}
        {invoiceLayouts && invoiceLayouts?.content?.showLocations ? (
          <>
            {!(adjustmentDetails || transferData) && (
              <div>
                <PageHeader
                  subTitle="Business"
                  style={{
                    padding: '8px 0px',
                    fontWeight: 'bold'
                  }}
                />
                <div>VAT No.: {find_invoice_number()}</div>
                {locationDetails ? (
                  <>
                    <div>{`${
                      invoiceLayouts && invoiceLayouts?.content?.showLocations
                        ? locationDetails.name
                        : null
                    }`}</div>
                    <div>
                      {`${locationDetails.address}, ${
                        invoiceLayouts && invoiceLayouts?.content?.city ? locationDetails.city : ''
                      }, ${
                        invoiceLayouts && invoiceLayouts?.content?.country
                          ? locationDetails.country
                          : ''
                      } ${
                        invoiceLayouts && invoiceLayouts?.content?.zipCode
                          ? locationDetails.zip
                          : ''
                      }`}
                    </div>
                    <div>{`${
                      invoiceLayouts && invoiceLayouts?.content?.mobileNumber
                        ? locationDetails.phone
                        : ''
                    }, ${
                      invoiceLayouts && invoiceLayouts?.content?.email ? locationDetails.email : ''
                    }`}</div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}
          </>
        ) : (
          <></>
        )}
        <div>
          <PageHeader
            subTitle="Details"
            style={{
              padding: '8px 0px',
              fontWeight: 'bold'
            }}
          />
          {purchaseDetails ? (
            <>
              <div>
                {purchaseDetails.financialReference ? (
                  <div>Invoice No. : {purchaseDetails.financialReference}</div>
                ) : (
                  <></>
                )}
              </div>
              <div>
                {' '}
                {invoiceLayouts?.content?.referenceNumberLabel
                  ? invoiceLayouts?.content?.referenceNumberLabel
                  : 'Reference No'}{' '}
                : {purchaseDetails.referenceNumber}
              </div>
              <div>
                Created : {convertUTCStringtoLocalString(purchaseDetails.createdAt, 'YYYY MM-DD')}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <CustomTable columns={columns} datas={lines ? lines : []} />
      {/* <CustomizeTable columns={columnss} data={lines} /> */}
      {/* <CustomTable columns={columnss} datas={lines} /> */}
      {/* <CustomizeTable columns={columns} notshowPagination={true} data={lines} /> */}
      {purchaseDetails?.payments?.length > 0 ? (
        <>
          <div>Payment Info</div>
          {/* <div style={{ border: '1px solid gray' }}>
            <Table
              columns={paymentColumns}
              dataSource={purchaseDetails.payments}
              bordered
              pagination={false}
            />
          </div> */}
          <CustomTable
            columns={paymentColumns}
            datas={purchaseDetails?.payments ? purchaseDetails.payments : []}
          />
        </>
      ) : (
        <></>
      )}
      {/* <Divider /> */}
      {purchaseDetails ? (
        <div className="flex justify-start mt-5">
          <div>
            <div>
              {invoiceLayouts?.content?.netTotalLabel
                ? invoiceLayouts?.content?.netTotalLabel
                : 'Reference No.'}
              : Rs.{purchaseDetails.totalAmount} /-
            </div>
            <div>
              {invoiceLayouts?.content?.discountLabel
                ? invoiceLayouts?.content?.discountLabel
                : 'Discount'}
              : Rs.{purchaseDetails.totalDiscount} /-
            </div>
            <div>
              {invoiceLayouts?.content?.shippingLabel
                ? invoiceLayouts?.content?.shippingLabel
                : 'Shipping'}
              : Rs.{purchaseDetails.shippingAmount - purchaseDetails.shippingTax} /-
            </div>
            {/* <div>
              {invoiceLayouts?.content?.shippingTaxLabel
                ? invoiceLayouts?.content?.shippingTaxLabel
                : 'Shipping Tax'}
              : Rs.{purchaseDetails.shippingTax} /-
            </div> */}
            <div>
              {invoiceLayouts?.content?.purchaseTaxLabel
                ? invoiceLayouts?.content?.purchaseTaxLabel
                : 'Purchase Tax'}
              : Rs.{purchaseDetails.totalTax} /-
            </div>
            <div>
              {invoiceLayouts?.content?.netTotalLabel
                ? invoiceLayouts?.content?.netTotalLabel
                : 'Net Total'}
              : Rs.{purchaseDetails.totalPrice} /-
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {invoiceLayouts?.content?.footerText ? (
        <div dangerouslySetInnerHTML={{ __html: invoiceLayouts?.content?.footerText }}></div>
      ) : (
        <></>
      )}
      {invoiceLayouts?.content?.showBarcode && (
        <div className="flex items-center justify-center">
          <CustomBarcode value={purchaseDetails.referenceNumber} height={50} />
        </div>
      )}
      <div className="flex items-end">
        <div>
          Printed By:{' '}
          <span className="capitalize">{loggedInUser?.name ? loggedInUser.name : ''}</span>
        </div>
      </div>
    </div>
  );
};
