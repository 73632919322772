import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
  Spin,
  message
} from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import { MenuTypes } from '@/services/settings/enums';
import { ICreateMenuItem, IMenuList } from '@/services/settings/types';
import { useMutation } from '@tanstack/react-query';
import { create_menu_item } from '@/services/settings/mutations';
import { AxiosError, AxiosResponse } from 'axios';
import { IServerError } from '@/services/response/types';
import MenuDB from '@/store/localstorage/MenuDB';
import { MenuTypeSearch } from '@/components/Common/MenuTypeSearch';
import { cleanHTML } from '@/components/TextEditor/textEditor.services';
import TextEditor from '@/components/TextEditor';

export const AdminCreateMenu = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [infoContent, setInfoContent] = useState('');

  const breadcrumbItems = [
    {
      label: 'Dev Menu',
      link: '/admin/menu'
    },
    {
      label: 'New',
      link: '/admin/menu/new'
    }
  ];

  const onFinish = async (values: ICreateMenuItem) => {
    setIsLoading(true);
    values.content = cleanHTML(infoContent);
    if (form.getFieldValue('isDefault')) {
      values.default = {
        position: values.position,
        parentId: values.parentId
      };
    }
    await createMenuItemMutation.mutateAsync(values);
  };

  const createMenuItemMutation = useMutation<
    AxiosResponse<IMenuList>,
    AxiosError<IServerError>,
    ICreateMenuItem
  >(create_menu_item, {
    onSuccess: async ({ data }) => {
      setIsLoading(false);
      message.success('Menu item added successfully');
      navigate('/admin/menu');
      await MenuDB.addMenuItem([data]);
    },
    onError: (e) => {
      setIsLoading(false);
      message.error(`${e.response?.data.message}`);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{ keyword: '', isDefault: true }}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Menu Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 sm:grid-cols-4 gap-5 mb-5'}>
            <Form.Item
              label="Menu Name"
              name="name"
              rules={[{ required: true, message: 'Please add Menu Name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Menu Key"
              name="key"
              rules={[{ required: true, message: 'Please add Menu Key!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Menu Type"
              name="type"
              rules={[{ required: true, message: 'Please add Menu Type!' }]}>
              <Select placeholder="Select menu type">
                {(Object.keys(MenuTypes) as Array<keyof typeof MenuTypes>).map((key) => (
                  <Select.Option key={key} value={key}>
                    {MenuTypes[key]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Item Position"
              name="position"
              rules={[{ required: true, message: 'Please add Item Position!' }]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <MenuTypeSearch
              formData={{ formName: 'parentId', label: 'Parent Item' }}
              isAll={false}
            />
            <Form.Item
              label="Search Keyword"
              name="keyword"
              rules={[{ required: true, message: 'Please add Item Keyword!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="isDefault" label="Is Default?" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </div>

          <div>
            <PageHeader title="Menu Content" style={{ padding: '8px 0px', fontSize: 16 }} />
            <TextEditor getHtml={(html) => setInfoContent(html)} defaultValue={infoContent} />
          </div>

          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/admin/menu')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};
