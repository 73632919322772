import { IPurchaseSellSummation } from '../../../../pages/sqlsource/report/purchase-sell-summation-report';
import { ICashSettlementPrint } from '../../../../services/invoice/types';
import { get_location_details } from '../../../../services/locations/queries';
import { get_session_info_id } from '../../../../services/pos-session/queries';
import { get_sell_lists_v2, get_sell_return_list } from '../../../../services/sell/queries';
import { get_user_details } from '../../../../services/users/queries';
import LocationsDB from '../../../../store/localstorage/LocationsDB';
import UsersDB from '../../../../store/localstorage/UsersDB';
import { find_locationId_preference } from '../../../../store/localstorage/preferences';
import { convertLocalToUTCString } from '../../../../utils/convertToUTC';
import { ConvertObjectToURL } from '../../../../utils/converturl';

export const getCashSettlementPrintData = async (
  sessionId: number,
  paymentData: IPurchaseSellSummation[]
) => {
  const response = await get_session_info_id(sessionId);
  // console.log('response', response);

  // Cashier
  if (response.session) {
    let userDetails = await UsersDB.getUser(response.session.userId);
    if (!userDetails) {
      const user = await get_user_details(response.session.userId);
      await UsersDB.addUsers([user.user]);
      userDetails = await UsersDB.getUser(response.session.userId);
    }
    response.session.userName = userDetails.name;
  }

  // Total Payment
  let totalPayment = 0;
  if (paymentData) {
    for (let ind = 0; ind < paymentData.length; ind++) {
      totalPayment += parseFloat(paymentData[ind].amount);
    }
  }

  // Fetch Sell Total
  const sellTotal = await getSellTotal(
    response.session.userId,
    response.session.startTime,
    response.session.endTime
  );

  //For Sell Return Total
  const sellReturnTotal = await getSellReturnTotal(
    response.session.userId,
    response.session.startTime,
    response.session.endTime
  );

  const printLocation = await getPrintLocation();

  return {
    sessionInfo: response.session,
    paymentInfo: paymentData,
    moneyInfo: response.lines,
    locationDetails: printLocation,
    totalPayment: totalPayment,
    sellTotal: sellTotal,
    sellReturnTotal: sellReturnTotal
  } as ICashSettlementPrint;
};

const getPrintLocation = async () => {
  const printLocation = await find_locationId_preference();
  let printLocationDetails;
  if (typeof printLocation === 'number') {
    printLocationDetails = await LocationsDB.getLocation(printLocation);
    if (!printLocationDetails) {
      printLocationDetails = await get_location_details(printLocation);
      await LocationsDB.addLocations([printLocationDetails]);
    }
  }

  return printLocationDetails;
};

const getSellTotal = async (userId: number, startTime: string, endTime: string) => {
  const values = {
    startDate: convertLocalToUTCString(startTime),
    endDate: convertLocalToUTCString(endTime),
    createdBy: userId
  };
  const filter = ConvertObjectToURL(values);
  const response = await get_sell_lists_v2(filter);

  return response.data.total;
};

const getSellReturnTotal = async (userId: number, startTime: string, endTime: string) => {
  const values = {
    startDate: convertLocalToUTCString(startTime),
    endDate: convertLocalToUTCString(endTime),
    createdBy: userId
  };
  const filter = ConvertObjectToURL(values);
  const response = await get_sell_return_list(filter);

  return response.data.total;
};
