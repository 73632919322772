import { useQuery, useMutation } from '@tanstack/react-query';
import { Form, Select, message, PageHeader, Spin, DatePicker } from 'antd';
// import { useWatch } from 'antd/lib/form/Form';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AppContent from '../../../../components/Common/Content/Content';
import CustomSubmitButton from '../../../../components/Common/CustomButton/CustomSubmitButton';
import { update_vehicles_usage_mutation } from '../../../../services/vehicle/mutations';
import { get_vehicles_usage_list_detail } from '../../../../services/vehicle/queries';
import { IVehicleUsageEdit } from '../../../../services/vehicle/types';

import { UsersSearch } from '../../../../components/Common/UsersSearch';
import { VehiclesSearch } from '../../../../components/Common/VehiclesSearch';
import moment from 'moment';
import { AxiosError } from 'axios';
const { Option } = Select;

const EditVehicleUsage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();

  // This is needed to get the product details update as its written
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useQuery(['vehicle-usage-details'], async () => {
    const response = (await get_vehicles_usage_list_detail(id as string)).data;
    console.log('response', response);
    if (response.startDate) {
      response.startDate = moment(response.startDate);
    }
    if (response.endDate) {
      response.endDate = moment(response.endDate);
    }
    form.setFieldsValue(response);
    setIsLoading(false);
    return response;
  });
  const breadcrumbItems = [
    {
      label: 'Vehicle Usage',
      link: '/vehicle-usage'
    },
    {
      label: 'Edit',
      link: '/vehicle-usage/new'
    }
  ];
  const updateVehicleUsageMutation = useMutation(update_vehicles_usage_mutation, {
    onSuccess: (data) => {
      message.success('Vehicle Usage updated successfully');
      navigate('/vehicle-usage');
    },
    onError: (error: AxiosError) => {
      message.error(error.message);
    }
  });
  const onFinish = async (values: IVehicleUsageEdit) => {
    setIsLoading(true);
    await updateVehicleUsageMutation.mutateAsync(values);
    setIsLoading(false);
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Vehicle Information"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          initialValues={{ units: [], isTraceable: false }}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2">
            <UsersSearch
              formData={{ formName: 'userId', label: 'User' }}
              isAll={false}
              required={true}
            />
            <VehiclesSearch
              formData={{ formName: 'vehicleId', label: 'Vehicle' }}
              isAll={false}
              required={true}
            />
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[
                {
                  required: true,
                  message: 'Please select date!'
                }
              ]}>
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              label="End Date"
              name="endDate"
              rules={[
                {
                  required: false,
                  message: 'Please select date!'
                }
              ]}>
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <CustomSubmitButton text="Submit" />
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default EditVehicleUsage;
