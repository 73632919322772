import AppContent from '@/components/Common/Content/Content';
import { getUser } from '@/services';
import { get_price_analysis_id } from '@/services/products/queries';
import { useQuery } from '@tanstack/react-query';
import { Button, Descriptions, PageHeader, Spin } from 'antd';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { IRow } from '../grid.types';
import ViewPriceAnalysisGrid from './Grid';
import { formatPriceAnalysistoGridRow } from '../services';
import moment from 'moment';

function ViewPriceAnalysis() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [rows, setRows] = useState<IRow[]>([]);
  const [isAllowedToEdit, setIsAllowedToEdit] = useState(false);

  if (!id) {
    return <Navigate to="/price-analysis" replace />;
  }

  const breadcrumbs = [{ label: 'Price Analysis', link: '/price-analysis' }, { label: 'View' }];

  async function getInfo() {
    const data = await get_price_analysis_id(Number(id));
    const createdBy = data.analysis.createdBy;
    const isAllowedToEdit =
      moment(data.analysis.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');

    setIsAllowedToEdit(isAllowedToEdit);

    if (createdBy) {
      const userDetails = await getUser(createdBy);
      data.analysis.createdUser = userDetails?.name;
    }

    const updatedRows = await formatPriceAnalysistoGridRow(data);
    setRows(updatedRows);

    return data.analysis;
  }

  const { data, isLoading, isFetching } = useQuery(['priceAnalysis', id], getInfo);

  return (
    <Spin spinning={isLoading || isFetching}>
      <AppContent breadcrumbItems={breadcrumbs}>
        <Descriptions
          bordered
          column={{
            md: 2,
            sm: 1,
            xs: 1
          }}>
          <Descriptions.Item label="Date">{data?.date}</Descriptions.Item>
          <Descriptions.Item label="Created By">{data?.createdUser}</Descriptions.Item>
        </Descriptions>

        <div className="mt-2">
          <PageHeader subTitle="Product Price Analysis" style={{ padding: '8px 0' }} />
          <ViewPriceAnalysisGrid rows={rows} />
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <Button onClick={() => navigate('/price-analysis')}>Back</Button>
          {isAllowedToEdit && (
            <Button type="primary" onClick={() => navigate(`/price-analysis/details/${id}`)}>
              Goto Update
            </Button>
          )}
        </div>
      </AppContent>
    </Spin>
  );
}

export default ViewPriceAnalysis;
