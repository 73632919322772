import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { get_archived_tasks_list_filter } from '../../../services/tasks/queries';
import { update_tasks_log } from '../../../services/tasks/mutations';
import AppContent from '../../../components/Common/Content/Content';
import { AuthContext } from '../../../contexts/auth.context';
import { Form, Menu, Select, Spin } from 'antd';

import { checkAccess } from '../../../routes/acl';
import TableFilter from '../../../components/FliterTable';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import DiscussionChatModal from '../../../components/Common/DiscussionChatModal';
import { convertLocalToUTCString } from '../../../utils/convertToUTC';
import { ConvertObjectToURL } from '../../../utils/converturl';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import TableCell from '../../../components/Common/CustomizeTable/CustomCell';
import { ITasks } from '../../../services/tasks/types';
import CustomUpdateIcon from '../../../components/Common/CustomIcons/CustomUpdateIcon';
import { CustomDatePresets } from '../../sqlsource/report/utils/datePresets';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const color = {
  Rejected: 'red',
  Pending: '#B7950B',
  'In progress': 'green',
  Done: '#5D6D7E'
};

const backgroundColor = {
  Rejected: '#F9EBEA',
  Pending: '#FEF9E7',
  'In progress': '#EAFAF1',
  Done: '#EAECEE'
};

const viwers = ['UN', 'BT', 'AG', 'KK'];

const ArchivedTaskList = () => {
  const [state, setState] = React.useState<any>([]);
  const [form] = Form.useForm();
  const { profileData } = useContext(AuthContext);
  const [tableData, setTableData] = useState<ITasks[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<any>(false);
  const updateTaskLog = useMutation(update_tasks_log);

  const selectOptions = ['Packed', 'Dispatched', 'Delivered', 'Assigned'];

  const [responsize, setResponsize] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 650) {
      // setCollapsed(true);
      setResponsize(true);
    } else {
      setResponsize(false);
    }
    function handleResize() {
      if (window.innerWidth <= 650) {
        // setCollapsed(true);
        setResponsize(true);
      } else {
        setResponsize(false);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const breadcrumbItems = [
    {
      label: 'Archived Tasks',
      link: '/tasks/archived'
    }
  ];

  const columns = [
    {
      title: 'ID',
      key: 'id',
      // sorter: (a, b) => a.id - b.id,
      // sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      width: 50,
      render: (record: any) => {
        return <TableCell>{record.id}</TableCell>;
      }
    },
    {
      title: 'Reference',
      width: 100,
      key: 'Reference',
      className: 'invoice',
      // sorter: (a, b) => a.referenceNumber.localeCompare(b.referenceNumber),
      // sortOrder: sortedInfo.columnKey === 'referenceNumber' ? sortedInfo.order : null,
      render: (record: any) => {
        return <TableCell>{record.reference}</TableCell>;
      }
    },
    {
      title: 'Title',
      width: 100,
      key: 'Title',
      // sorter: (a, b) => a.userName.localeCompare(b.userName),
      // sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record: any) => {
        return <TableCell>{record.name}</TableCell>;
      }
    },
    {
      title: 'Description',
      width: 100,
      key: 'description',
      // sorter: (a, b) => a.userName.localeCompare(b.userName),
      // sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record: any) => {
        return <TableCell>{record.description}</TableCell>;
      }
    },
    {
      title: 'Due Date',
      width: 100,
      key: 'Due Date',
      // sorter: (a, b) => a.userName.localeCompare(b.userName),
      // sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record: any) => {
        return (
          <TableCell>
            {/* {(record.dueDate)} */}
            {convertLocalToUTCString(record.dueDate, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 50,
      fixed: 'right',
      render: (record: any) => {
        const confirmFunction = () => {
          // deleteSellMutation.mutate(record.id);
        };
        const menuItems = [];
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '4',
            label: (
              <DiscussionChatModal
                slug="task"
                id={record.id}
                rreference="other"
                // fromSellorPurahcaseid={record.id}
              />
            )
          });
        }
        if (checkAccess('UPDATE_LOCATION')) {
          menuItems.push({
            key: '1',
            label: <CustomUpdateIcon link={`/tasks/${record.id}`} />
          });
        }
        const menu = <Menu items={menuItems} />;

        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const onSubmitFilter = async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    console.log('Values', values);
    console.log('values', values.status);
    const filter = ConvertObjectToURL({
      startDate: convertLocalToUTCString(values.startDate),
      endDate: convertLocalToUTCString(values.endDate)
    });
    const response = await get_archived_tasks_list_filter(0, 1000, filter);
    // console.log('Response', response);
    // console.log('fiter', filter);
    // if (values.status) {
    //   const { results } = response.data;
    //   const filterArray = results.filter((curr: any) => curr.status == values.status);
    //   // const find = state.find((curr: any) => curr.name == values.status);
    //   setTableData(filterArray);
    // }
    setTableData(response.data.results);
    setLoading(false);
  };
  // console.log('tabledata', tableData);
  // console.log('State-->', state);
  return (
    <>
      <Spin spinning={loading}>
        <AppContent
          breadcrumbItems={breadcrumbItems}
          withfilter={true}
          button={
            <>
              <div>
                <TableFilter
                  form={form}
                  defaultValues={{
                    dateCustom: [...CustomDatePresets.Today],
                    value: '',
                    skip: 0,
                    count: 100
                  }}
                  initial={true}
                  onSubmit={onSubmitFilter}
                  // outsidestyle={'flex flex-wrap items-end gap-2'}
                  styleforbuttons={'flex items-center justify-end'}
                  style={
                    'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                  }
                  buttons={
                    <>
                      {checkAccess('CREATE_SELL') && (
                        <CustomButton
                          onClick={() => navigate(`/tasks/new`)}
                          text="Add"
                          backgroundColor="#1890ff"
                          // marginTop="0px"
                          // Linkto="/tasks/new"
                        />
                      )}
                    </>
                  }>
                  <Form.Item name="status" label="Select Status">
                    <Select
                      placeholder="Select Task Status"
                      style={{ borderRadius: '10px' }}
                      allowClear
                      dropdownMatchSelectWidth={false}>
                      {selectOptions.map((value) => {
                        return (
                          <Select.Option value={value} key={value}>
                            {value}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </TableFilter>
              </div>
              {/* </div> */}
            </>
          }>
          <CustomizeTable
            columns={columns}
            data={tableData}
            notshowPagination={true}
            tableName={'tasks-archived-list'}
            // toshowcustomPagintion={true}
            // onPagination={onPagination}
          />
        </AppContent>
      </Spin>
    </>
  );
};

// Put the thing into the DOM!
export default ArchivedTaskList;

function addOrUpdateState(newState: any, item: any, status: any) {
  const pendingSection = newState.find((item: any) => item.name === status);
  if (pendingSection) {
    pendingSection.tasks.push({
      id: JSON.stringify(item.id),
      title: item.name,
      description: item.description,
      dueDate: item.dueDate,
      status: item.status,
      assignee: item.assignee,
      reference: item.reference,
      referenceId: item.referenceId
    });
  } else {
    newState.push({
      name: status,
      tasks: [
        {
          id: JSON.stringify(item.id),
          title: item.name,
          description: item.description,
          dueDate: item.dueDate,
          status: item.status,
          assignee: item.assignee,
          reference: item.reference,
          referenceId: item.referenceId
        }
      ]
    });
  }
}
