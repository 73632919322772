import { useQuery, useMutation } from '@tanstack/react-query';
import { Form, Input, Select, message, PageHeader, Checkbox, Card, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import AppContent from '../../../components/Common/Content/Content';
import { ACLGroup, ICreateACLGroup } from '../../../services/acl/types';
import { create_acl_group_mutation } from '../../../services/acl/mutations';
import { get_all_acl_permissions } from '../../../services/acl/queries';
import GoBackButton from '../../../components/Common/GoBackButton/GoBackButton';
import CustomSubmitButton from '../../../components/Common/CustomButton/CustomSubmitButton';
import { AxiosError, AxiosResponse } from 'axios';
import { IServerError } from '../../../services/response/types';
const { Option } = Select;

const CreateACL = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<ICreateACLGroup>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { data: aclPermList } = useQuery(['acl-perm-list'], async () => {
    const response = await get_all_acl_permissions();
    setIsLoading(false);
    return response;
  });
  // This is needed to get the product details update as its written

  const breadcrumbItems = [
    {
      label: 'ACL',
      link: '/acl'
    },
    {
      label: 'Create',
      link: '/acl/new'
    }
  ];

  const createACLMutation = useMutation<
    AxiosResponse<ACLGroup>,
    AxiosError<IServerError>,
    ICreateACLGroup
  >(create_acl_group_mutation);

  const onFinish = async (values: ICreateACLGroup) => {
    try {
      setIsLoading(true);
      if (disabled) {
        values = { ...values, permissions: ['ADMIN'] };
      }
      await createACLMutation.mutateAsync(values, {
        onSuccess: () => {
          setIsLoading(false);
          message.success('ACL group created successfully');
          navigate('/acl');
        },
        onError: (error) => {
          setIsLoading(false);

          message.error(`${error.response?.data.message}`);
        }
      });
    } catch (errors) {
      setIsLoading(false);
    }
  };
  // const displayName = (name: any) => {
  //   const value = form.getFieldValue(['permissions', name]);
  //   return <div className="text-center">{value}</div>;
  // };
  const findIndex = (name: string) => {
    const value: string[] = form.getFieldValue(['permissions']);
    const index = value.findIndex((value) => value == name);
    return index;
  };
  useEffect(() => {
    form.setFieldsValue({ name: '', permissions: [] });
  }, []);

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        backgroundWhite={true}
        button={<GoBackButton onClick={() => navigate('/acl')} />}>
        <Card style={{ borderRadius: '10px' }}>
          <PageHeader
            title="ACL Informations"
            style={{
              padding: '8px 0px'
            }}
          />
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            disabled={isLoading}
            validateTrigger={'onChange'}
            autoComplete="off">
            <Form.Item
              name="name"
              label="ACL Title"
              rules={[
                {
                  required: true,
                  message: 'Please add a name!'
                }
              ]}>
              <Input style={{ borderRadius: '9px' }} />
            </Form.Item>
            <Form.List name={['permissions']}>
              {(fields2, { add: add2, remove: remove2 }) => (
                <>
                  <div className={'grid mb-5'}>
                    <PageHeader
                      subTitle="Select Permissions"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                    <div
                      className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
                      style={{
                        borderRadius: '39px',
                        padding: '10px',
                        backgroundColor: 'rgb(59 130 246 / 0.5)'
                      }}>
                      {aclPermList?.data && (
                        <>
                          {Object.keys(aclPermList.data).map((key) => (
                            <div style={{ display: 'flex', gap: '5px' }} key={key}>
                              <Form.Item>
                                <Checkbox
                                  disabled={
                                    `${aclPermList.data[key]}` == 'ADMIN' ? false : disabled
                                  }
                                  onChange={(value) => {
                                    const checkedValue = value.target.checked;
                                    if (checkedValue) {
                                      if (`${aclPermList.data[key]}` == 'ADMIN') {
                                        setDisabled(true);
                                      }
                                      add2(`${aclPermList.data[key]}`);
                                    } else {
                                      if (`${aclPermList.data[key]}` == 'ADMIN') {
                                        setDisabled(false);
                                      }
                                      remove2(findIndex(aclPermList.data[key]));
                                    }
                                  }}>
                                  {`${aclPermList.data[key]}` +
                                    (aclPermList.data[key] == 'ADMIN' ? '(ALL)' : '')}
                                </Checkbox>
                              </Form.Item>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Form.List>
            <div className="flex justify-end mt-5">
              <Form.Item>
                <CustomSubmitButton text="Add" />
                {/* <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  style={{
                    borderRadius: '5px',
                    color: 'white',
                    backgroundColor: '#1890ff'
                  }}>
                  Add
                </Button> */}
              </Form.Item>
            </div>
          </Form>
        </Card>
      </AppContent>
    </Spin>
  );
};

export default CreateACL;
