import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Divider, Form, Input, message, PageHeader } from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppContent from '../../../../../components/Common/Content/Content';
import { update_users_tax_mutation } from '../../../../../services/users/mutations';
import { IUserUpdateTaxRequest, IDataFromServer } from '../../../../../services/users/types';
import { get_user_details } from '../../../../../services/users/queries';
import ReuseChannel from '../../../../channel/Reuse';
import '../Create/index.css';
import GoBackButton from '../../../../../components/Common/GoBackButton/GoBackButton';

const TaxDetails: React.FC = () => {
  const navigate = useNavigate();
  const { id, tid } = useParams();
  const [form] = Form.useForm();
  const [isloading, setIsloading] = useState<boolean>(true);
  const [buttonloading, setButtonloading] = useState<boolean>(false);
  useQuery(['users', id], async () => get_user_details(parseInt(id as string)), {
    retry: false,
    onSuccess: (data: IDataFromServer) => {
      const index = data.tax_informations.findIndex((val: IUserUpdateTaxRequest) => val.id == tid);
      form.setFieldsValue(data.tax_informations[index]);
      setIsloading(false);
    }
  });

  const updateTaxMutation = useMutation(update_users_tax_mutation, {
    onError: (e: any) => {
      message.error(`${e.response.data.message}`, 5);
      setIsloading(false);
      setButtonloading(false);
    },
    onSuccess: (data: any) => {
      if (data) {
        message.success('Tax updated successfully');
        setIsloading(false);
        setButtonloading(false);
        navigate(`/users/${id}/tax`);
      }
    }
  });
  const onFinish = (values: IUserUpdateTaxRequest) => {
    setIsloading(true);
    setButtonloading(true);
    values.id = tid;
    values.userId = parseInt(id as string);
    updateTaxMutation.mutate(values);
  };
  const breadcrumbItems = [
    {
      label: 'Users',
      link: '/users'
    },
    {
      label: 'Tax',
      link: `/users/${id}/tax`
    },
    {
      label: 'Edit',
      link: `/users/${id}/tax/${tid}`
    }
  ];

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        backgroundWhite={true}
        button={<GoBackButton onClick={() => navigate(`/users/${id}/tax`)} />}>
        <Card style={{ margin: '15px 0px', borderRadius: '9px' }}>
          <Form
            form={form}
            initialValues={{}}
            onFinish={onFinish}
            disabled={isloading}
            layout="vertical"
            validateTrigger={'onChange'}
            autoComplete="off">
            <PageHeader
              title="Tax Information"
              style={{
                padding: '8px 0px'
              }}
            />
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
              <Form.Item
                name="taxNumber"
                label="Tax Number"
                rules={[{ required: true, message: 'Please add number!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name="taxType"
                label="Tax Type"
                rules={[{ required: true, message: 'Please add type!' }]}>
                <Input />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isloading}
                  style={{
                    borderRadius: '5px',
                    color: 'white',
                    backgroundColor: '#1890ff',
                    marginTop: '30px'
                  }}>
                  Add
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Card>
        <ReuseChannel slug={`tax_${id}_${tid}`} />
      </AppContent>
    </div>
  );
};

export default TaxDetails;
