import moment from 'moment';

function isBetween(startDate: string, endDate: string) {
  const start = moment(startDate);
  const end = moment(endDate);

  return moment().isBetween(start, end);
}

export default isBetween;
