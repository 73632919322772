import { Form, Input, Menu, Select, Spin, TableProps, Tooltip } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TableCell from '../../../../../components/Common/CustomizeTable/CustomCell';
import { optionalBooleanSorter, optionalStringSorter } from '../../../../../utils/sorter.utils';
import { checkAccess } from '../../../../../routes/acl';
import DiscussionChatModal from '../../../../../components/Common/DiscussionChatModal';
import ActionDropdown from '../../../../../components/Common/Dropdownactions';
import { convertUTCStringtoLocalString } from '../../../../../utils/convertToUTC';
import AppContent from '../../../../../components/Common/Content/Content';
import TableFilter from '../../../../../components/FliterTable';
import moment from 'moment';
import CustomButton from '../../../../../components/Common/CustomButton/CustomButton';
import CustomizeTable from '../../../../../components/Common/CustomizeTable/CustomizeTable';
import CustomUpdateIcon from '../../../../../components/Common/CustomIcons/CustomUpdateIcon';
import { IUserType, IUserTypeResult } from '../../../../../services/user-types/types';
import { get_user_type_list } from '../../../../../services/user-types/queries';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const { Option } = Select;

const UserTypeList: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [userCategories, setUserCategories] = useState<IUserTypeResult>({
    total: 0,
    results: []
  });

  const [sortedInfo, setSortedInfo] = useState<SorterResult<IUserType>>({});
  const handleChange: TableProps<IUserType>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<IUserType>);
  };
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const response = await get_user_type_list(filter);
    if (response) {
      setUserCategories(response);
    }
    setIsLoading(false);
  };

  const columns: ColumnsType<IUserType> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 2,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 15,
      sorter: (a, b) => optionalStringSorter(a.name, b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <>
            <TableCell>{record.name}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 15,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 3,
      fixed: 'right',
      render: (record) => {
        const menuItems: {
          key: string;
          label: JSX.Element;
          onClick?: undefined;
        }[] = [];

        if (checkAccess('UPDATE_USER')) {
          menuItems.push({
            key: '1',
            label: <CustomUpdateIcon link={`/users/user-type/${record.id}`} />
          });
        }
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '2',
            label: <DiscussionChatModal slug="/users/user-type" id={record.id} />
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'User Type',
      link: '/users/user-type'
    }
  ];
  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();

    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
  };
  return (
    <AppContent
      breadcrumbItems={breadcrumbItems}
      withfilter={false}
      button={
        <>
          <div>
            <TableFilter
              dateCustom={false}
              defaultValues={{
                value: '',
                skip: 0,
                count: 100
              }}
              initial={true}
              onSubmit={onSubmitFilter}
              form={form}
              style={
                'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
              }
              styleforbuttons={'flex justify-end items-center'}
              buttons={
                <>
                  {checkAccess('CREATE_USER') && (
                    <div>
                      <CustomButton
                        onClick={() => navigate(`/users/user-type/new`)}
                        text="Add"
                        backgroundColor="#1890ff"
                        Linkto="/users/user-type/new"
                      />
                    </div>
                  )}
                </>
              }>
              <Form.Item name="value" label="Search">
                <Input placeholder="Search" />
              </Form.Item>
            </TableFilter>
          </div>
          {/* </div> */}
        </>
      }>
      <CustomizeTable
        columns={columns}
        data={userCategories.results}
        usersLoading={isLoading}
        notshowPagination={true}
        customScroll={{ x: 600, y: '75vh' }}
        paginationDatas={{
          page: page,
          total: userCategories.total,
          size: size,
          onPagination
        }}
        toSort={handleChange}
      />
    </AppContent>
  );
};

export default UserTypeList;
