import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { IVendorReportType } from '@/services/report/types';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';

interface Props {
  data: IVendorReportType[];
}

function VendorTable({ data }: Props) {
  // Extracting unique date keys and sorting them in ascending order
  const total = {} as { [key: string]: number };

  const dateValues = data.reduce((acc, vendor) => {
    for (const date in vendor) {
      if (!['vendorId', 'vendorName', 'vendorPhone'].includes(date)) {
        acc[date] = true;
        total[date] = (total[date] || 0) + (vendor[date] as number);
      }
    }
    return acc;
  }, {} as { [key: string]: boolean });

  const dateKeys = Object.keys(dateValues).sort();

  // Defining columns for the table
  const columns: ColumnsType<IVendorReportType> = [
    {
      title: 'SN',
      dataIndex: 'sn',
      key: 'sn',
      width: 50,
      render: (_, __, index) => <TableCell className="p-4">{index + 1}</TableCell>
    },
    {
      title: 'Name',
      width: 150,
      key: 'vendorName',
      dataIndex: 'vendorName',
      render: (value: string) => <TableCell className="px-2">{value}</TableCell>
    },
    {
      title: 'Phone',
      width: 120,
      key: 'vendorPhone',
      dataIndex: 'vendorPhone',
      render: (value: string) => <TableCell className="px-2">{value}</TableCell>
    },

    // Dynamically creating columns for each date
    ...dateKeys.map((dateKey) => ({
      title: dateKey,
      dataIndex: dateKey,
      key: dateKey,
      width: 80,
      className: 'text-center',
      render: (value: number | undefined) => value || '-' // Displaying '-' if the value is falsy
    }))
  ];

  return (
    <Table
      size="middle"
      columns={columns}
      dataSource={data}
      rowKey={(record) => String(record.vendorId)}
      scroll={{ x: 'max-content' }}
      pagination={{ pageSize: 10000 }}
      summary={() => (
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={3}>
            Total
          </Table.Summary.Cell>
          {Object.keys(total)
            .sort()
            .map((dateKey, index) => (
              <Table.Summary.Cell
                key={`${dateKey}-footer`}
                index={index + 2}
                className="text-center">
                {total[dateKey]}
              </Table.Summary.Cell>
            ))}
        </Table.Summary.Row>
      )}
    />
  );
}

export default VendorTable;
