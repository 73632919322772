import { Form, Input, PageHeader, Select } from 'antd';
import React, { useState } from 'react';

const BillPrinting = ({ form, isFull = true }: { form: any; isFull?: boolean }) => {
  const [isDisplayTypeFull, setIsDisplayTypeFull] = useState<boolean>(isFull);

  return (
    <div>
      <PageHeader
        title="Product details to be shown in bill printing:"
        style={{
          padding: '8px 0px',
          fontSize: '14px !important'
        }}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mb-5">
        <Form.Item label="Product label" name="productLabel">
          <Input />
        </Form.Item>

        <Form.Item label="Quantity label" name="quantityLabel">
          <Input />
        </Form.Item>

        <Form.Item label="Unit Price Label" name="unitPriceLabel">
          <Input />
        </Form.Item>

        <Form.Item label="Subtotal Label" name="subTotalLabel">
          <Input />
        </Form.Item>
        <Form.Item label="Total quantity label" name="totalQuantityLabel">
          <Input />
        </Form.Item>
        <Form.Item label="Gross Amount label" name="grossAmount">
          <Input />
        </Form.Item>
        <Form.Item label="Discount Amount label" name="discountAmount">
          <Input />
        </Form.Item>

        <Form.Item label="Taxable Amount label" name="taxableAmountLabel">
          <Input />
        </Form.Item>
        <Form.Item label="NonTaxable Amount label" name="nonTaxableAmountLabel">
          <Input />
        </Form.Item>
        <Form.Item label="VAT label" name="vatLabel">
          <Input />
        </Form.Item>
        <Form.Item label="Net Amount Label" name="netAmountLabel">
          <Input />
        </Form.Item>
        {/* <Form.Item label="Total quantity label" name="totalQuantityLabel">
          <Input />
        </Form.Item> */}
        <Form.Item label="Tender label" name="tenderLabel">
          <Input />
        </Form.Item>
        <Form.Item label="Change label" name="changeLabel">
          <Input />
        </Form.Item>
        <Form.Item
          label="Product Name Show (Full/Partial)"
          name="productNameDisplayType"
          initialValue={'full'}>
          <Select
            allowClear
            placeholder="Select Product Name display type"
            onChange={(val) => {
              form.setFieldValue(['productNameDisplay'], undefined);
              if (val === 'partial') {
                setIsDisplayTypeFull(false);
              } else {
                setIsDisplayTypeFull(true);
              }
            }}>
            <Select.Option value="full">{'Full'}</Select.Option>
            <Select.Option value="partial">{'Partial'}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Product Name Display (Initial/Final)"
          name="productNameDisplay"
          rules={[
            {
              required: !isDisplayTypeFull,
              message: 'Please choose a display value!'
            }
          ]}>
          <Select placeholder="Select Product Name to be shown" disabled={isDisplayTypeFull}>
            <Select.Option value="initial">{'Prior to slash (/)'}</Select.Option>
            <Select.Option value="final">{'Subsequent of slash (/)'}</Select.Option>
          </Select>
        </Form.Item>
      </div>
    </div>
  );
};

export default BillPrinting;
