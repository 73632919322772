import CustomSearch, { SelectProps } from '..';
import { get_customer_list_only } from '@/services/users/queries';
import { ICustomer } from '@/services/users/types';
import CustomersDB from '@/store/localstorage/CustomerDB';

type MultipleProps =
  | {
      defaultValue?: number;
      isMultiple?: false;
      setSelected?: (selected?: ICustomer) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      defaultValue?: number[];
      isMultiple: true;
      setSelected?: (selected?: ICustomer[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps & MultipleProps;

function CustomerSearchByUserIdV2(props: Props) {
  return (
    <CustomSearch
      path={{ name: 'user.name', value: 'userId' }}
      formFor="customer"
      addCallback={CustomersDB.addCustomers}
      dbSearchById={CustomersDB.getCustomer}
      serverCallback={get_customer_list_only}
      dbSearchCallback={CustomersDB.searchCustomer}
      {...props}
    />
  );
}

export default CustomerSearchByUserIdV2;
