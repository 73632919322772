import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Form, Input, InputNumber, message, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import CustomButton from '../../../../../components/Common/CustomButton/CustomButton';
import { get_location_list } from '../../../../../services/locations/queries';
import {
  diverge_products_mutation,
  merge_products_mutation
} from '../../../../../services/products/mutations';
import LocationsDB from '../../../../../store/localstorage/LocationsDB';
import { ILocations } from '../../../../../services/locations/types';
import getErrorMessage from '@/utils/getError';

export const MergeLotsModal = ({ open, onOk, onCancel, lotsArray, refetch }: any) => {
  const [selectedLotIds, setSelectedLotIds] = useState<number[]>([]);
  const [locationsList, setLocationsList] = useState<ILocations[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<number>(0);
  const [form] = Form.useForm();

  useQuery(['locations'], async () => {
    const response = await get_location_list(0, 999, '');
    setLocationsList(response.data.results);
    LocationsDB.addLocations(response.data.results);
    return response;
  });

  const mergeLotsMutation = useMutation(merge_products_mutation, {
    onSuccess: () => {
      message.success('Merged Successfully!');
      refetch();
    },
    onError: (error) => {
      message.error(getErrorMessage(error));
    }
  });

  const handleChange = (value: number[]) => {
    setSelectedLotIds(value);
  };

  const handleSelectLocations = (value: number) => {
    setSelectedLocation(value);
  };

  const handleSubmit = async () => {
    if (selectedLotIds.length < 2) {
      message.error('Please select at least 2 lots!');
      return;
    }
    await mergeLotsMutation.mutateAsync({
      lotIds: selectedLotIds,
      grade: form.getFieldsValue().grade
    });
    setSelectedLotIds([]);
    setSelectedLocation(0);
    onCancel();
  };

  useEffect(() => {
    setSelectedLotIds([]);
    setSelectedLocation(0);
    form.setFieldsValue({ locationId: null, lotId: [], grade: '' });
  }, [open]);

  return (
    <Modal
      destroyOnClose={true}
      title={<div className="font-bold text-lg">Merge Lots</div>}
      visible={open}
      width={500}
      bodyStyle={{ padding: '10px 20px 10px 20px' }}
      onOk={() => {
        setSelectedLotIds([]);
        setSelectedLocation(0);
        onOk();
      }}
      onCancel={() => {
        setSelectedLotIds([]);
        setSelectedLocation(0);
        onCancel();
      }}
      footer={null}>
      <Form form={form} onFinish={handleSubmit}>
        <div className="grid grid-cols-1 gap-x-5 width-full mb-2">
          <Form.Item
            name={['locationId']}
            label={'Location'}
            rules={[{ required: true, message: 'Please select a location!' }]}>
            <Select
              // size={'medium'}
              placeholder="Please select a location."
              onChange={handleSelectLocations}
              allowClear
              style={{ width: '100%' }}>
              {locationsList.map((d: any) => (
                <Select.Option key={d.id} value={d.id}>
                  {d.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={['lotId']}
            label={'Lots'}
            rules={[{ required: true, message: 'Please select lots!' }]}>
            <Select
              mode="multiple"
              // size={'large'}
              placeholder="Please select"
              onChange={handleChange}
              style={{ width: '100%' }}>
              {lotsArray
                ?.filter(
                  (item: any) => item.locationId === selectedLocation && item.lotStatus === 'ACTIVE'
                )
                ?.map((d: any) => (
                  <Select.Option key={d.id} value={d.id}>
                    {`${d.lotNumber} (${d.qtyAvailableName})`}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name={['grade']}
            label={'Grade'}
            rules={[{ required: true, message: 'Please select a grade!' }]}>
            <Input maxLength={1} placeholder="Grade" />
          </Form.Item>
        </div>

        {/* <Button className="mt-5" type="primary" htmlType="submit">
          Diverge
        </Button> */}
        <div className="flex items-center justify-end mt-3">
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: '#1890ff', color: 'white', borderRadius: '9px' }}>
            Merge
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
