import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { ACLGroup, IACLGroupDetails, IACLPermisssionEnums, IACLUserGroupDetails } from './types';

export const get_all_acl_groups = async (filter = '') => {
  const response: AxiosResponse<ACLGroup[]> = await http.get('acl/get-all-groups/?' + filter);
  return response;
};
export const get_all_acl_permissions = async (filter = '') => {
  const response: AxiosResponse<IACLPermisssionEnums> = await http.get(
    'acl/permissions-enums/?' + filter
  );
  return response;
};
export const get_acl_group_details = async (id: number) => {
  const response: AxiosResponse<IACLGroupDetails> = await http.get('acl/get-group/' + id);
  return response.data;
};

export const get_acl_group_ids = async (ids: number[]) => {
  const promises = ids.map((id) => get_acl_group_details(id));
  const aclGroupDetailsArray = await Promise.all(promises);
  return aclGroupDetailsArray;
};

export const get_acl_user_group_details = async (id: number) => {
  const response: AxiosResponse<IACLUserGroupDetails[]> = await http.get(
    'acl/get-user-group/' + id
  );
  return response.data;
};
