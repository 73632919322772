import { AxiosResponse } from 'axios';
import http from '../../../utils/http.utils';
import { IRDCredentialsType } from './types';

export const get_materialized_lists = async (filter = '') => {
  const response: AxiosResponse<any, any> = await http.get('/ird/materialized?' + filter);
  return response.data;
};

export const get_ird_credential = async () => {
  const response: AxiosResponse<IRDCredentialsType> = await http.get('ird/credential');
  return response.data;
};
