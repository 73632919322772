import { Button, Empty } from 'antd';
import { Link } from 'react-router-dom';

interface Props {
  to?: string;
  linkTitle?: string;
  description: string;
  children?: React.ReactNode;
}

function EmptyLayout({ to, description, linkTitle = 'Go to', children }: Props) {
  return (
    <div className="flex items-center justify-center min-h-[calc(100vh-20rem)]">
      <Empty description={description}>
        {to === 'main' && (
          <Link to={to}>
            <Button type="primary">{linkTitle}</Button>
          </Link>
        )}

        {children}
      </Empty>
    </div>
  );
}

export default EmptyLayout;
