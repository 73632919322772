import { Button, message } from 'antd';
import { useState, useEffect } from 'react';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import { get_channel_slug } from '../../../services/channels/queries';
import CreateChannel from '../create';
import ReusuableCreateChannel from '../ReusableCreate';
import ReusableRoom from '../ReusableRoom';
interface ReuseChannelProps {
  slug: string;
  isButton?: boolean;
  fromSellorPurchaseId?: string;
  withReferenceandId?: {
    reference: string;
    referenceId: number | string;
  };
}
const ReuseChannel: React.FC<ReuseChannelProps> = ({
  slug,
  isButton = true,
  withReferenceandId,
  fromSellorPurchaseId = ''
}) => {
  const [displayChannel, setDisplayChannel] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [roomData, setRoomData] = useState({
    id: 0,
    channelName: ''
  });
  const CreateChannel = (
    <ReusuableCreateChannel
      slug={slug}
      OnSuccess={() => {
        checkChannelExists();
      }}
      withReferenceandId={withReferenceandId}
      fromSellorPurchaseId={fromSellorPurchaseId}
    />
  );
  const Message = <ReusableRoom id={roomData.id} channelName={roomData.channelName} />;
  const checkChannelExists = async () => {
    try {
      const response = await get_channel_slug(slug);
      setRoomData({
        id: response.data.channel.id,
        channelName: response.data.channel.name
      });
      setDisplayMessage(true);
    } catch (e: any) {
      message.error('No existing discussion found, please create new!');
      setDisplayMessage(false);
    }
    setDisplayChannel(true);
  };
  useEffect(() => {
    if (!isButton) {
      checkChannelExists();
    }
  }, []);

  return (
    <div>
      {displayChannel ? (
        <>{displayMessage ? Message : CreateChannel}</>
      ) : (
        // <Button onClick={() => checkChannelExists()}>Start Discussion?</Button>
        <CustomButton
          onClick={() => checkChannelExists()}
          text="Start Discussion?"
          textColor="black"
          marginLeft="0"
        />
      )}
    </div>
  );
};

export default ReuseChannel;
