import { Button, Input } from 'antd';
import moment from 'moment';
import TotalLotsAvailableDB from '../../../store/localstorage/TotalAvailableLotsDB';
import ResuableCalculator from '../ReusableCalculator';
import ExpenseCategoryDB from '../../../store/localstorage/ExpenseCategoryDB';
import Search from 'antd/lib/input/Search';
import { getTokenFromStorage } from '../../../utils/storage.utils';
import { API_URL } from '../../../constants/config';
import { Manager, io } from 'socket.io-client';
import { SocketNameSpaces } from '../../../constants/websocketConfig';

const Tests = () => {
  //const handleAdd = async () => {};

  // const socket = websocket(SocketNameSpaces.NOTIFICATION);
  // socket.on('USER_NOTIFICATION', (...data) => {
  //   console.log('Args', data);
  // });
  return <>{/* <Button onClick={handleAdd}>Add</Button> */}</>;
};

export default Tests;
