import { useEffect, useState } from 'react';
import { Button, Divider, Form, message, Select } from 'antd';
import { IPurchaseAgentBillRange } from '@/services/purchases/types';
import { BillRangeDB } from '@/store/localstorage/BillRangeDB';
import useDebounce from '@/hooks/useDebounce';
import { get_purchase_agent_bill_list_v2 } from '@/services/purchases/queries';
import getError from '@/utils/getError';

type Props = {
  disabled?: boolean;
  allowClear?: boolean;
  placeHolder?: string;
  notFoundContent?: React.ReactNode;
  defaultActiveFirstOption?: boolean;
  selectedAgentId: number;
  dropdownMatchSelectWidth?: boolean;
  setSelected?: (selected?: IPurchaseAgentBillRange) => void;
  onSelect?: (value: string | number) => void;
} & (
  | {
      hasParentFormItem: true;
    }
  | {
      hasParentFormItem: false;
      name: any;
      label?: string;
      required?: boolean;
    }
);

type SearchType = 'initial' | 'more';

export default function AgentBillRangeSearch({
  allowClear = true,
  placeHolder = 'Search for bill range',
  notFoundContent = <div>No Content</div>,
  dropdownMatchSelectWidth = false,
  defaultActiveFirstOption = false,
  selectedAgentId,
  onSelect,
  ...props
}: Props) {
  const [skip, setSkip] = useState(0);
  const [isMore, setIsMore] = useState(true);

  const [searchValue, setSearchValue] = useState<number>();
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const [billRange, setBillRange] = useState<IPurchaseAgentBillRange[]>([]);
  const [isCacheResponse, setIsCacheResponse] = useState(false);

  useEffect(() => {
    if (!selectedAgentId) {
      return message.error('Please select an agent first');
    }

    searchIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue, selectedAgentId]);

  async function searchIndexDB(value: number) {
    try {
      const billRanges = await BillRangeDB.search(value, 10, selectedAgentId);
      if (!billRanges || billRanges.length == 0) {
        message.error('Cannot find any bill with that value in cache, searching in server...');
        search(value, 'initial');
        return;
      }

      setBillRange(billRanges);
      setIsCacheResponse(true);
    } catch (error) {
      message.error(getError(error));
    }
  }

  async function search(value?: number, searchType?: SearchType) {
    try {
      const currentSkip = searchType === 'initial' ? 0 : skip;
      if (typeof value !== 'number' && value) {
        message.error('Only number is allowed');
        return;
      }

      const data = await get_purchase_agent_bill_list_v2(
        currentSkip,
        10,
        value,
        `agentId=${selectedAgentId}`
      );
      const isValidResponse = data && data.results.length > 0;
      if (!isValidResponse) {
        message.error('Cannot find any bill ranges with that value!');
        setIsMore(false);
        searchType === 'initial' && setBillRange([]);
      }

      if (isValidResponse) {
        searchType === 'initial'
          ? setBillRange(data.results)
          : setBillRange((prev) => [...prev, ...data.results]);

        BillRangeDB.adds(data.results);
        setIsMore(data.results.length >= 10);
        searchType === 'more' && setSkip(currentSkip + 10);
      }

      if (searchType === 'initial') {
        setIsCacheResponse(false);
        setSkip(10);
      }
    } catch (error) {
      message.error(getError(error));
    }
  }

  function handleChange(id: number) {
    onSelect?.(id);

    const selectedData = billRange.find((current) => {
      return current.id === id;
    });

    props.setSelected?.(selectedData);
  }

  const options = billRange?.map((value) => {
    return (
      <Select.Option key={value.id} value={value.id}>
        <div className={value.isArchived ? 'text-red-500' : ''}>
          Current: {value.current} ({value.min} - {value.max}){' '}
          {value.isArchived ? '(Archived)' : ''}
        </div>
      </Select.Option>
    );
  });

  const selectMenu = (
    <Select
      showSearch
      filterOption={false}
      disabled={props.disabled}
      allowClear={allowClear}
      onSearch={(val) => {
        const number = Number(val);

        if (Number.isNaN(number)) {
          return message.error('Provide number between start and end range');
        }

        setSearchValue(number);
      }}
      notFoundContent={notFoundContent}
      onChange={handleChange}
      defaultActiveFirstOption={defaultActiveFirstOption}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      className="w-full drawer-select"
      placeholder={placeHolder}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            {isCacheResponse ? (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                <Button
                  type="text"
                  style={{ color: 'blue', width: '100%' }}
                  onClick={() => search(searchValue, 'initial')}>
                  {'Pull More & Sync'}
                </Button>
              </div>
            ) : (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                {isMore ? (
                  <Button
                    type="text"
                    style={{ color: 'blue', width: '100%' }}
                    onClick={() => search(searchValue, 'more')}>
                    {'Get More...'}
                  </Button>
                ) : (
                  <div style={{ width: '100%', textAlign: 'center' }}>No more data...</div>
                )}
              </div>
            )}
          </>
        );
      }}>
      {options}
    </Select>
  );

  if (props.hasParentFormItem) return selectMenu;
  return (
    <Form.Item
      label={props.label || 'Select Bill Range'}
      name={props.name}
      rules={[{ required: props.required || false, message: 'Please select bill range' }]}>
      {selectMenu}
    </Form.Item>
  );
}
