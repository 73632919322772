export enum PaymentAgainst {
  Sell = 'sell',
  Purchase = 'purchase',
  Expense = 'expense'
}

export enum PaymentType {
  Full = 'full',
  Partial = 'partial',
  Pending = 'pending'
}

export enum PaymentMode {
  Debit = 'debit',
  Credit = 'credit'
}

export enum PaymentMethod {
  Cash = 'cash',
  Cheque = 'cheque',
  Card = 'card',
  BankTransfer = 'bank',
  Other = 'other'
}
