import { nepaliNumberFormatter } from '@/utils/numberFormatter';

export const tableExportColumns = [
  {
    label: 'Route',
    dataIndex: 'routeName',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Active Customers',
    dataIndex: 'totalCustomers',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Total Amount',
    dataIndex: 'totalAmount',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Total Quantity',
    dataIndex: 'totalQuantity',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Quantity Avg',
    dataIndex: 'quantityAvg',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      totalAmount: nepaliNumberFormatter(record.totalAmount),
      totalQuantity: nepaliNumberFormatter(record.totalQuantity),
      quantityAvg: nepaliNumberFormatter(record.quantityAvg)
    };
  }) as any;
}
