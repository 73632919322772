import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IVendorType, IVendorTypeCreateRequest, IVendorTypeUpdateRequest } from './types';

export const create_vendor_type_mutation = async (values: IVendorTypeCreateRequest) => {
  const response: AxiosResponse<IVendorType, any> = await http.store('vendor-types/', values);
  return response;
};

export const update_vendor_type_mutation = async (values: IVendorTypeUpdateRequest) => {
  const response: AxiosResponse<IVendorType, any> = await http.update(
    'vendor-types/' + values.id,
    values
  );
  return response;
};
