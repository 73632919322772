import { PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Form, FormListOperation, Input, message } from 'antd';

import { cn } from '@/utils';
import type { FormValues, IDetails } from './';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import PictureListByForm from '@/components/Common/PictureList/PictureListByForm';
import TextArea from 'antd/lib/input/TextArea';

function DetailsCollapsible() {
  const form = Form.useFormInstance<FormValues>();
  const details = Form.useWatch('details', form);

  function handleListAdd(add: FormListOperation['add'], parentName: number) {
    const customDetails = form.getFieldValue([
      'details',
      parentName,
      'customDetails'
    ]) as IDetails['customDetails'];

    const isFilled = customDetails.every((line) => line.label && line.value);
    if (!isFilled) {
      message.error({
        key: 'customDetails',
        content: 'Please fill all the fields before adding a new line.'
      });
      return;
    }

    add({ label: '', value: '', date: null });
  }

  return (
    <Form.List name="details">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, parentIndex) => (
            <div key={key} className="flex gap-2 items-center">
              <span className="font-bold text-sm mb-5">{parentIndex + 1}.</span>
              <div
                className="card w-full"
                style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                <div className={'grid grid-cols-2 gap-2 md:grid-cols-3 mb-4'}>
                  <Form.Item
                    {...restField}
                    name={[name, 'title']}
                    label="Title"
                    rules={[{ required: true, message: 'Please provide main title!' }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item {...restField} name={[name, 'files']} label="Files" hidden>
                    <Input />
                  </Form.Item>

                  {fields.length > 0 && (
                    <div className="flex items-center justify-start mt-[1.875rem] h-fit">
                      <CustomButton
                        backgroundColor="white"
                        text="Remove"
                        textColor="green"
                        onClick={() => remove(name)}
                      />
                    </div>
                  )}
                </div>

                <Collapse>
                  <Collapse.Panel header="Add Images" key="details-media" forceRender={false}>
                    <PictureListByForm name={['details', name, 'files']} />
                  </Collapse.Panel>

                  <Collapse.Panel
                    header="Add Custom Details"
                    key="custom-details"
                    forceRender={false}>
                    <Form.List name={[name, 'customDetails']}>
                      {(mFields, mHandler) => (
                        <>
                          {mFields.map(({ key: mKey, name: mName, ...mRestField }, mIndex) => (
                            <div key={mKey} className="flex gap-2 items-center">
                              <span className="font-bold text-sm mb-5">
                                {`${parentIndex + 1}.${mIndex + 1}`}
                              </span>
                              <div
                                className="card w-full"
                                style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                                <Form.Item
                                  {...mRestField}
                                  name={[mName, 'label']}
                                  label="Subtitle"
                                  rules={[{ required: true, message: 'Please add a label!' }]}>
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  {...mRestField}
                                  name={[mName, 'value']}
                                  label="Description"
                                  rules={[
                                    { required: true, message: 'Please add a desscription!' }
                                  ]}>
                                  <TextArea rows={4} />
                                </Form.Item>

                                {mFields.length > 0 && (
                                  <div className="flex items-center justify-start h-fit">
                                    <CustomButton
                                      backgroundColor="white"
                                      text="Remove"
                                      marginLeft="0"
                                      textColor="green"
                                      onClick={() => mHandler.remove(mName)}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}

                          <div
                            className={cn('flex', {
                              'justify-end': mFields.length > 0
                            })}>
                            <Button
                              type="primary"
                              ghost
                              onClick={() => handleListAdd(mHandler.add, name)}
                              icon={<PlusOutlined />}>
                              Add Detail
                            </Button>
                          </div>
                        </>
                      )}
                    </Form.List>
                  </Collapse.Panel>
                </Collapse>
              </div>
            </div>
          ))}

          <Button
            className={cn({
              'ml-6': details && details.length > 0
            })}
            type="primary"
            onClick={() => add({ date: null, customDetails: [] })}
            icon={<PlusOutlined />}>
            Add Detail
          </Button>
        </>
      )}
    </Form.List>
  );
}

export default DetailsCollapsible;
