import { EditOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

interface CustomIconProps {
  link: string;
}

const CustomUpdateIcon: React.FC<CustomIconProps> = ({ link }) => {
  return (
    <Tooltip title="Update" color="blue">
      <Link to={link}>
        <EditOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
      </Link>
    </Tooltip>
  );
};

export default CustomUpdateIcon;
