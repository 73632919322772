import AppContent from '@/components/Common/Content/Content';
import { create_users_mutation_v2 } from '@/services/users/mutations';
import { IUserCreateV2 } from '@/services/users/types';
import getErrorMessage from '@/utils/getError';
import { useMutation } from '@tanstack/react-query';
import { Form, Button, message, Collapse, Typography, Select, Spin } from 'antd';

const { Title } = Typography;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserCreateForm from './UserForm';
import ACLForm from './ACLForm';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { CapitalizeEachWord } from '@/utils/capitalizeEachWord';

function CreateUserV2() {
  const [form] = Form.useForm<IUserCreateV2>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [openKey] = useState<string | string[]>(['user-info', 'acl-info', 'other-info']); // Manage open collapse key

  const breadcrumbItems = [
    { label: 'Users', link: '/users' },
    { label: 'Create', link: '/users/create' }
  ];

  const createUsersMutation = useMutation(create_users_mutation_v2, {
    onError: (error) => {
      message.error(getErrorMessage(error));
      setIsLoading(false);
    },
    onSuccess: () => {
      message.success('User added successfully');
      setIsLoading(false);
      navigate('/users');
    }
  });

  async function onFinish(values: IUserCreateV2) {
    try {
      setIsLoading(true);
      values.name = CapitalizeEachWord(values.name);
      values.phone = values.phone.toString();
      values.secondaryPhone = values.secondaryPhone?.toString();

      await createUsersMutation.mutateAsync(values);
    } catch (error) {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Title level={4} style={{ color: '#001529' }}>
          Create User
        </Title>

        <Form
          form={form}
          initialValues={{ preferences: { nepaliDate: false } }}
          layout="vertical"
          onFinish={onFinish}>
          <Collapse activeKey={openKey}>
            <Collapse.Panel header="User Information" key="user-info">
              <UserCreateForm />
            </Collapse.Panel>

            <Collapse.Panel header="ACL (Permissions)" key="acl-info">
              <ACLForm />
            </Collapse.Panel>

            <Collapse.Panel header="Other Info" key="other-info">
              <div className="grid sm:grid-cols-3 gap-3">
                <LocationSearchV2
                  hasParentFormItem={false}
                  name={['preferences', 'locationId']}
                  required
                />

                <Form.Item name={['preferences', 'nepaliDate']} label="Date Type:">
                  <Select
                    placeholder="Select a Type!"
                    style={{ borderRadius: '10px' }}
                    allowClear
                    dropdownMatchSelectWidth={false}>
                    <Select.Option value={false} key={'1'}>
                      English
                    </Select.Option>
                    <Select.Option value={true} key={'2'}>
                      Nepali
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Collapse.Panel>
          </Collapse>

          <div className="flex justify-end gap-2.5 m-8">
            <Button onClick={() => navigate('/users')}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              Create User
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default CreateUserV2;
