import { Form, message, Modal, Radio } from 'antd';
import { useRef } from 'react';
import SingleCustomizeUI from './SingleCustomizeUI';
import { IChartType, IWidgetConfiguration } from '@/services/dashboard/v3/types';
import MultipleCustomizeUI from './MultipleCustomizeUI';
import { useDashboardCustomize } from '@/contexts/dashboard-customize.context';

interface Props {
  open: boolean;
  selectedWidget: { id: string; position: number; isCustom: boolean } | null;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  resetSelectedWidget: () => void;
}

type CommonFormValues = {
  title: string;
  chartType: IChartType;
  enlargeChart: boolean;
  widgetPlotConfig: { field: string; plotAgainst: string; reportId: number; name: string }[];
  isGradient?: boolean;
  showZoom?: boolean;
  showFilter?: boolean;
  isSmooth?: boolean;
  borderRadius?: number;
};

export type FormValues =
  | (CommonFormValues & { isMultiple: false; selectedWidget: number })
  | (CommonFormValues & { isMultiple: true; selectedWidgets: number[] });

export interface ChildComponentRef {
  onSubmit: () => Promise<Partial<IWidgetConfiguration> | undefined>;
}

function WidgetCustomizeModalv2({ open, setOpen, selectedWidget, resetSelectedWidget }: Props) {
  const [form] = Form.useForm<FormValues>();
  const isMultiple = Form.useWatch('isMultiple', form);

  const singleWidgetRef = useRef<ChildComponentRef>(null);
  const multipleWidgetRef = useRef<ChildComponentRef>(null);

  const { setEditingWidgets } = useDashboardCustomize();

  function onCancel() {
    setOpen(false);
    resetSelectedWidget();
  }

  async function onSubmit() {
    const values = await form.validateFields();
    let config: Partial<IWidgetConfiguration> | undefined;

    if (!selectedWidget) {
      message.error('This should not happen!. Please consult the developer!');
      return;
    }

    if (values.isMultiple && multipleWidgetRef.current) {
      config = await multipleWidgetRef.current.onSubmit();
      if (config) config.isMultiple = true;
    }

    if (!values.isMultiple && singleWidgetRef.current) {
      config = await singleWidgetRef.current?.onSubmit();
      if (config) config.isMultiple = false;
    }

    if (!config) {
      message.error('Failed to saved widget config');
      return;
    }

    setOpen(false);
    resetSelectedWidget();

    setEditingWidgets((prev) => {
      const newWidgets = [...prev];
      const currentEditedWidget = newWidgets[selectedWidget.position];

      newWidgets[selectedWidget.position] = { ...currentEditedWidget, ...config };
      return newWidgets;
    });
  }

  return (
    <Modal visible={open} onOk={onSubmit} onCancel={onCancel}>
      <Form
        initialValues={{ isMultiple: false }}
        form={form}
        layout="horizontal"
        autoComplete="off">
        <Form.Item
          name="isMultiple"
          label="Widget Selection Mode"
          rules={[
            { required: selectedWidget?.isCustom, message: 'Please choose a selection mode!' }
          ]}>
          <Radio.Group
            disabled={!selectedWidget?.isCustom}
            options={[
              { label: 'Single', value: false },
              { label: 'Multiple', value: true }
            ]}
          />
        </Form.Item>

        {!isMultiple && (
          <SingleCustomizeUI form={form} selectedWidget={selectedWidget} ref={singleWidgetRef} />
        )}
        {isMultiple && (
          <MultipleCustomizeUI
            form={form}
            selectedWidget={selectedWidget}
            ref={multipleWidgetRef}
          />
        )}
      </Form>
    </Modal>
  );
}

export default WidgetCustomizeModalv2;
