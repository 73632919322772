import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  IAdjustmentData,
  IAdjustmentLines,
  IAdjustmentList,
  IAdjustmentWithLinesResponse,
  IReason,
  IReasonDetails
} from './types';
import { getDataForIds } from '../../utils/queries.utils';

export const get_adjustments_list = async (skip = 0, count = 1000, value = '') => {
  const response: AxiosResponse<IAdjustmentData> = await http.get('adjustments', {
    skip,
    count,
    value
  });
  return response;
};

export const get_adjustments_list_filter = async (filter = '') => {
  const response: AxiosResponse<IAdjustmentData> = await http.get('adjustments/?' + filter);
  return response;
};

export const get_adjustments_lines_list_filter = async (filter = '') => {
  const response: AxiosResponse<IAdjustmentWithLinesResponse> = await http.get(
    'adjustments/with-lines/?' + filter
  );
  return response.data;
};

export const get_adjustments_details_ids = async (ids: number[]) => {
  return await getDataForIds<IAdjustmentList>(ids, 'adjustments/?');
};

export const get_reasons_list = async (filter = '') => {
  const response: AxiosResponse<IReason[]> = await http.get('adjustments/reasons/?' + filter);
  return response;
};
export const get_reasons_details = async (id: number) => {
  const response: AxiosResponse<IReasonDetails> = await http.get('adjustments/reasons/' + id);
  return response;
};

export const get_adjustment_details = async (id: number) => {
  const response: AxiosResponse<IAdjustmentList> = await http.get('adjustments/' + id);
  return response;
};

export const get_adjustment_line_details = async (status: string, id: number) => {
  const statusType = status === 'APPROVED' ? '' : status === 'PENDING' ? '_PENDING' : '_REJECT';

  const response: AxiosResponse<IAdjustmentLines[]> = await http.get(
    `products/lines/ADJUSTMENT${statusType}/${id}`
  );
  return response;
};
