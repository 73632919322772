import moment from 'moment';
import { Pagination, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { ExtendedInitialValues } from '.';
import { getInt } from '../pending-payment-sell-report/report';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { IUnitPriceType } from '@/services/report/types';
import { find_to_use_NPR } from '@/store/localstorage/preferences';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { WithReportPaginationProps } from '@/components/Common/Report/withReportPagination';
import { excelExportColumns, getUpdatedData, tableExportColumns } from './column.export';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportAllData from '@/components/Common/ExportAll';
import ExportCurrent from '@/components/Common/ExportCurrent';

interface Props
  extends Pick<WithReportPaginationProps, 'onPagination' | 'onChange' | 'pagination'> {
  data: { total: number; data: IUnitPriceType[] };
  onSearch: (values: ExtendedInitialValues) => void;
  onSearchAll: () => Promise<IUnitPriceType[]>;
}

function TableData({ data, pagination, ...handlers }: Props) {
  const columns: ColumnsType<IUnitPriceType> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 30,
      render: (_, __, index) => <TableCell>{index + 1}</TableCell>
    },
    {
      title: 'Product',
      key: 'product',
      dataIndex: 'product',
      sorter: (a, b) => a.product.localeCompare(b.product),
      width: 100
    },
    {
      title: 'Unit',
      key: 'unit',
      dataIndex: 'unit',
      sorter: (a, b) => a.unit?.localeCompare(b.unit),
      width: 50
    },
    {
      title: 'Average Unit Price',
      key: 'avg_unit_price',
      dataIndex: 'avg_unit_price',
      className: 'highlight',
      sorter: (a, b) => getInt(a.avg_unit_price) - getInt(b.avg_unit_price),
      width: 50,
      render: (price) => {
        return <div className="text-center">{nepaliNumberFormatter(price)}</div>;
      }
    },
    {
      title: 'Category',
      key: 'category',
      dataIndex: 'category',
      sorter: (a, b) => a.category.localeCompare(b.category),
      width: 50
    },
    {
      title: 'Location',
      key: 'location',
      dataIndex: 'location',
      sorter: (a, b) => a.location.localeCompare(b.location),
      width: 50
    },
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      width: 50,
      render: (date) => {
        return <div>{moment(date).format('YYYY-MM-DD')}</div>;
      }
    }
  ];

  const total = { avgUnit: 0 };

  data.data?.forEach((item) => {
    const { avg_unit_price } = item;
    total.avgUnit += getInt(avg_unit_price);
  });

  const updatedData = getUpdatedData(data.data);

  return (
    <>
      <CustomizeTable
        tableName="unit-price-report-table"
        columns={columns}
        data={data.data}
        notshowPagination={true}
        customScroll={{ x: 1000, y: 500 }}
        tableSummary={
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={2} className="text-right"></Table.Summary.Cell>
              <Table.Summary.Cell index={3} className="text-center highlight">
                {nepaliNumberFormatter(total.avgUnit)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        }
        buttons={
          <>
            <ExportAllData
              title="Unit Price Report (All)"
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              getInfo={handlers.onSearchAll}
            />
            <ExportCurrent
              data={{ total: data.total, results: updatedData }}
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              title="Unit Price Report"
            />
          </>
        }
        paginationDatas={{
          page: pagination.page,
          total: data.total,
          size: pagination.size,
          onPagination: (pageNo, currentSize) => {
            currentSize == pagination.size
              ? handlers.onPagination(handlers.onSearch, pageNo)
              : handlers.onPagination(handlers.onSearch, pageNo, currentSize);
          }
        }}
      />
    </>
  );
}

export default TableData;
