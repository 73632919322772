import moment from 'moment';
import { find_to_use_NPR } from '@/store/localstorage/preferences';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

export const tableExportColumns = [
  {
    label: 'Product',
    dataIndex: 'product',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Unit',
    dataIndex: 'unit',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Average Unit Price',
    dataIndex: 'avg_unit_price',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Category',
    dataIndex: 'category',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Location',
    dataIndex: 'location',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Date',
    dataIndex: 'date',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      date: moment(record.date).format('YYYY-MM-DD'),
      avg_unit_price: nepaliNumberFormatter(record.avg_unit_price)
    };
  }) as any;
}
