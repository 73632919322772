import { useMutation, useQuery } from '@tanstack/react-query';
import { get_bank_details } from '../../../services/bank/queries';
import { Button, Form, Input, PageHeader, Spin, message } from 'antd';
import { update_bank_mutation } from '../../../services/bank/mutations';
import AppContent from '../../../components/Common/Content/Content';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IUpdateBank } from '../../../services/bank/types';

const DetailsBank = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();

  const breadcrumbItems = [
    {
      label: 'Bank',
      link: '/bank'
    },
    {
      label: 'Details',
      link: '/bank/:id'
    }
  ];

  useQuery(['bankList', id], async () => await getData());

  const getData = async () => {
    if (id) {
      const data = await get_bank_details(parseInt(id));
      // console.log('Data', data);
      if (data) {
        form.setFieldsValue({
          name: data.name,
          accountNumber: data.accountNumber,
          accountName: data.accountName,
          address: data.address
        });
      }
      setIsLoading(false);
    }
  };

  const onFinish = (values: IUpdateBank) => {
    setIsLoading(true);
    //console.log('Values', values);
    if (id) values.id = parseInt(id);
    updateBankMutation.mutate(values);
  };

  const updateBankMutation = useMutation(update_bank_mutation, {
    onSuccess: () => {
      setIsLoading(false);
      message.success('Bank updated successfully');
      navigate('/bank');
    },
    onError: (e: any) => {
      setIsLoading(false);
      message.error(`${e.response.data.message}`);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Bank Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 sm:grid-cols-4 gap-5 mb-5'}>
            <Form.Item
              label="Bank Name"
              name="name"
              rules={[{ required: true, message: 'Please add Bank Name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Account Number"
              name="accountNumber"
              rules={[{ required: true, message: 'Please add Account Number!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Account Name"
              name="accountName"
              rules={[{ required: true, message: 'Please add Account Name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: 'Please add Address!' }]}>
              <Input />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/bank')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default DetailsBank;
