import http from '../../../utils/http.utils';
import { AxiosResponse } from 'axios';
import { IPurchaseRegisterListData, IPurchaseReturnRegisterListData } from './types';

export const get_purchase_register_list = async (filter = '') => {
  const response: AxiosResponse<IPurchaseRegisterListData, any> = await http.get(
    'purchase/?' + filter
  );
  return response.data;
};

export const get_purchase_return_register_list = async (filter = '') => {
  const response: AxiosResponse<IPurchaseReturnRegisterListData, any> = await http.get(
    'purchase-return/?' + filter
  );
  return response.data;
};
