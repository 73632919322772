import LocalStore from '.';
import { IExpenseListRevamp } from '@/services/expense/types';

function getTransactionError(event: Event, reject: (reason?: any) => void, type: string) {
  const instanceOfIndexDB = event.target instanceof IDBRequest;
  if (instanceOfIndexDB) {
    reject(event.target.error);
    return;
  }

  reject(new Error(`Failed to ${type} financial year on indexDB`));
}

export default class ExpenseDB extends LocalStore {
  static async search(value: string, limit = 10): Promise<IExpenseListRevamp[]> {
    if (!ExpenseDB.db) await ExpenseDB.init();
    return new Promise((resolve: (value: any[]) => void, reject) => {
      const transaction = ExpenseDB.db.transaction('Expenses', 'readonly');

      const filteredExpenses: IExpenseListRevamp[] = [];

      const objectStore = transaction.objectStore('Expenses');
      const lowercaseString = value.toLowerCase();
      const request = objectStore.openCursor();

      request.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor) {
          const { billNumber, referenceNumber } = cursor.value as IExpenseListRevamp;

          if (
            billNumber?.toLowerCase().includes(lowercaseString) ||
            referenceNumber?.toLowerCase().includes(lowercaseString)
          ) {
            filteredExpenses.push(cursor.value);
          }

          if (filteredExpenses.length < limit) {
            cursor.continue();
          }
        }
      };

      transaction.oncomplete = () => resolve(filteredExpenses);
      request.onerror = (event) => getTransactionError(event, reject, 'search');
      transaction.onerror = (event) => getTransactionError(event, reject, 'search');
    });
  }

  static async add(expenseData: IExpenseListRevamp[]) {
    if (!ExpenseDB.db) await ExpenseDB.init();
    return new Promise((resolve, reject) => {
      const transaction = ExpenseDB.db.transaction('Expenses', 'readwrite');

      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'add');

      const objectStore = transaction.objectStore('Expenses');
      expenseData.forEach(async (expense) => {
        const checkKey = objectStore.count(expense.id);
        checkKey.onsuccess = async () => {
          if (checkKey.result === 0) {
            const request = objectStore.add(expense);
            request.onerror = (event) => getTransactionError(event, reject, 'add');
          } else {
            await ExpenseDB.update(expense);
          }
        };
      });
    });
  }

  static async update(expense: IExpenseListRevamp) {
    if (!ExpenseDB.db) await ExpenseDB.init();
    return new Promise((resolve, reject) => {
      const transaction = ExpenseDB.db.transaction('Expenses', 'readwrite');

      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'update');

      const objectStore = transaction.objectStore('Expenses');
      const request = objectStore.get(expense.id);

      request.onsuccess = (event) => {
        if ((event.target as IDBRequest).result) {
          const requestUpdate = objectStore.put(expense);
          requestUpdate.onerror = (event) => getTransactionError(event, reject, 'update');
          requestUpdate.onsuccess = () => resolve('updated data');
        } else reject('Error, could not find id.');
      };

      request.onerror = (event) => getTransactionError(event, reject, 'update');
    });
  }
}
