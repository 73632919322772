import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IAdjustmentInvoice } from '../../../../services/invoice/types';
import { getUserData } from '../../../../utils/auth.utils';
import { nepaliNumberFormatter } from '../../../../utils/numberFormatter';
import { Button, PageHeader } from 'antd';
import { convertUTCStringtoLocalString } from '../../../../utils/convertToUTC';
import CustomTable from '../../CustomResuableInvoice/CustomTable';
import CustomBarcode from '../../../../pages/barcode';

const AdjustmentInvoice: React.FC<IAdjustmentInvoice> = ({
  adjustmentDetails,
  locationDetails,
  lines,
  invoiceLayouts,
  handleModalClose
}) => {
  const printRef = useRef<any>();
  const loggedInUser = getUserData();

  const content = JSON.parse(invoiceLayouts?.content);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    async onAfterPrint() {
      if (handleModalClose) {
        handleModalClose();
      }
    }
  });

  const columns = [
    {
      label: `${content.productLabel ? content.productLabel : 'ProductName'}`,
      dataIndex: 'productName',
      render: (text: string) => <div>{text}</div>
    },
    {
      label: `${content?.hsCodeLabel ? content?.hsCodeLabel : 'HS Code'}`,
      dataIndex: 'hsCode',
      render: (text?: string) => <div>{text || 'N/A'}</div>
    },
    {
      label: `${content.unitPriceLabel ? content.unitPriceLabel : 'UnitPrice'}`,
      dataIndex: 'unitPrice',
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: `${content.quantityLabel ? content.quantityLabel : 'Quantity'}`,
      dataIndex: 'quantity',
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: 'Misc',
      dataIndex: 'misc',
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    },
    {
      label: `${content.discountLabel ? content.discountLabel : 'Discount'}`,
      dataIndex: 'discount',
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    },
    {
      label: `${content.subTotalLabel ? content.subTotalLabel : 'Total Amount'}`,
      dataIndex: 'totalAmount',
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    }
  ];

  return (
    <>
      <div className="flex justify-end mb-3">
        <Button type="primary" onClick={handlePrint}>
          Print
        </Button>
      </div>

      <div ref={printRef}>
        <div style={{ color: 'black' }} className="mx-5 my-3">
          <div className="flex justify-between font-bold text-xl mt-4">
            {'FreshKtm Agro Pvt. Ltd.'}
          </div>
          <div className="flex justify-between font-bold text-xl">
            Stock Adjustment Details ({'Financial Reference'} :{' '}
            {adjustmentDetails?.financialReference})
          </div>
          <hr />
          <div className="grid grid-cols-3 gap-5 mb-5">
            {adjustmentDetails && (
              <div>
                <PageHeader
                  subTitle="Business"
                  style={{
                    padding: '8px 0px',
                    fontWeight: 'bold'
                  }}
                />
                {locationDetails ? (
                  <>
                    <div>{locationDetails.name}</div>
                    <div>
                      {`${locationDetails.zip}, ${locationDetails.address}, ${locationDetails.city}, ${locationDetails.country}`}
                    </div>
                    <div>{`${locationDetails?.phone}, ${locationDetails.email}`}</div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}
            <div>
              <PageHeader
                subTitle="Details"
                style={{
                  padding: '8px 0px',
                  fontWeight: 'bold'
                }}
              />
              {adjustmentDetails ? (
                <>
                  <div>
                    {'Financial Reference.'} : {adjustmentDetails.financialReference}
                  </div>
                  <div>
                    Created At:{' '}
                    {convertUTCStringtoLocalString(adjustmentDetails.createdAt, 'YYYY MM-DD')}
                  </div>
                  <div>
                    {content.reasonLabel ? content.reasonLabel : 'Reason'} :{' '}
                    {adjustmentDetails.reasonName}
                  </div>
                  <div>
                    {content.adjustmentTypeLabel ? content.adjustmentTypeLabel : 'Adjustment Type'}{' '}
                    : {adjustmentDetails.reasonName}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <CustomTable columns={columns} datas={lines ? lines : []} />
          <div className="flex justify-end mt-5">
            <div>
              <div className="flex justify-between">
                <span> {content.totalLabel ? content.totalLabel : 'Total Amount'} :</span>
                <span>
                  {nepaliNumberFormatter(adjustmentDetails ? adjustmentDetails.totalAmount : 0)}
                </span>
              </div>
              <div className="flex justify-between mt-2">
                <span>
                  {content.totalAmountRecoveredLabel
                    ? content.totalAmountRecoveredLabel
                    : 'Total Amount Recovered'}{' '}
                  :
                </span>
                <span> {nepaliNumberFormatter(0)}</span>
              </div>
            </div>
          </div>
          {content.footerText ? (
            <div dangerouslySetInnerHTML={{ __html: content.footerText }}></div>
          ) : (
            <></>
          )}
          {content.showBarcode && (
            <div className="flex items-center justify-center">
              <CustomBarcode
                value={adjustmentDetails ? adjustmentDetails.financialReference : ''}
                height={35}
                width={1.4}
                fontSize={16}
              />
            </div>
          )}
          <div className="flex justify-end">
            <div>
              Printed By:{' '}
              <span className="capitalize">{loggedInUser?.name ? loggedInUser.name : ''}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdjustmentInvoice;
