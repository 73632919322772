import http from '../../utils/http.utils';
import { ICreateSource, IUpdateSource } from './types';

export const create_source_mutation = async (values: ICreateSource) => {
  const response = await http.store('data', values);
  return response;
};
export const update_source_mutation = async (values: IUpdateSource) => {
  const response = await http.update('data/' + values.id, values);
  return response;
};
