import moment from 'moment';
import { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { RangePickerProps } from 'antd/lib/date-picker';
import { Button, DatePicker, Drawer, Form, Radio, RadioGroupProps, Select, Space } from 'antd';

import FilterWrapper from './FilterWrapper';
import { CustomDatePresets, DateSegmentPresets } from '@/pages/sqlsource/report/utils/datePresets';
import { useDashboardMain } from '@/contexts/dashboard-main.context';
import { IDateRangeType, IDateRange, IFormField } from '@/services/dashboard/v3/types';
import { saveDashboardDate } from '@/services/dashboard/v3/services.dashboard';

interface Props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormValues {
  dateRange: IDateRangeType;
  customRange?: [moment.Moment, moment.Moment];
  locationId: number;
}

function DashboardFilterDrawer({ show, setShow }: Props) {
  const {
    locations,
    locationId,
    dateType,
    selectedDate,
    dashboardLayout,
    setSelectedDate,
    setDateType,
    setLocationId,
    setIsFormSubmitted,
    setIsInitial
  } = useDashboardMain();
  const [form] = Form.useForm<FormValues>();
  const [width, setWidth] = useState('250px');

  useEffect(() => {
    const isCustom = dateType === 'custom';
    const momentSelectedDate = selectedDate.map((date) => moment(date)) as [
      moment.Moment,
      moment.Moment
    ];

    if (isCustom) {
      form.setFieldsValue({ customRange: momentSelectedDate, dateRange: 'custom', locationId });
      return;
    }

    form.setFieldsValue({ dateRange: dateType, locationId });
    if (locationId !== -1) {
      setIsInitial(true);
      setIsFormSubmitted(true);
    }
  }, [locationId]);

  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : 'overlay';
  }, [show]);

  useEffect(() => {
    function resize() {
      window.innerWidth < 400 ? setWidth('100%') : setWidth('250px');
    }

    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  const onCloseDrawer = () => setShow(false);

  const handleDateChecked: RadioGroupProps['onChange'] = (e) => {
    const value = e.target.value as IDateRangeType;
    const isCustom = value === 'custom';
    if (!isCustom) form.setFieldsValue({ customRange: undefined });
  };

  function handleDateChange(value: IDateRangeType) {
    let date = DateSegmentPresets.Daily;
    if (value === 'daily') date = DateSegmentPresets.Daily;
    if (value === 'weekly') date = DateSegmentPresets.Weekly;
    if (value === 'monthly') date = DateSegmentPresets.Monthly;
    if (value === 'custom') {
      const customRange = form.getFieldValue('customRange') as [moment.Moment, moment.Moment];
      date = [customRange[0].toISOString(), customRange[1].toISOString()];
    }

    return date;
  }

  function handleCustomChecked() {
    form.setFieldValue('dateRange', 'custom');
  }

  const handleDatePickerChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length === 2) {
      form.setFieldValue('dateRange', 'custom');
    }
  };

  async function handleApply() {
    const values = await form.validateFields();
    let dateType = values.dateRange as IDateRangeType;
    const selectedDates = handleDateChange(dateType);

    if (dateType === 'custom') {
      const normalizedSelectedDates = selectedDates.map((date) => moment(date));
      const normalizedDailyDates = DateSegmentPresets.Daily.map((date) => moment(date));

      const isStartSame = normalizedSelectedDates[0].isSame(normalizedDailyDates[0]);
      const isEndSame = normalizedSelectedDates[1].isSame(normalizedDailyDates[1]);
      dateType = isStartSame && isEndSame ? 'daily' : 'custom';
    }

    setSelectedDate(selectedDates);
    setDateType(dateType);
    onCloseDrawer();
    setLocationId(values.locationId);
    setIsFormSubmitted(true);
    setIsInitial(false);

    const widgetConfigs: {
      id: string;
      date: IDateRange;
      type: IDateRangeType;
      formFields?: IFormField;
    }[] = [];

    dashboardLayout.forEach((widget) => {
      if (widget.comparable) return;
      widgetConfigs.push({
        id: widget.id,
        date: selectedDates,
        type: dateType,
        formFields: widget?.formFields || {}
      });
    });

    await saveDashboardDate({ formType: 'global', widgets: widgetConfigs });
  }

  return (
    <Drawer
      headerStyle={{ background: '#f1f2fa', paddingInline: 12 }}
      footerStyle={{ background: '#f1f2fa', paddingInline: 12 }}
      style={{ position: 'fixed', zIndex: 1001 }}
      title={
        <div className="flex items-center justify-between">
          <div>Filters</div>
          <CloseOutlined onClick={onCloseDrawer} className="[&>svg]:m-0" />
        </div>
      }
      footer={
        <div className="flex gap-2">
          <Button type="primary" className="flex-1" onClick={handleApply}>
            Apply
          </Button>
          <Button className="flex-1" onClick={onCloseDrawer}>
            Cancel
          </Button>
        </div>
      }
      bodyStyle={{ paddingInline: 12, paddingBlock: 8, background: '#f1f2fa' }}
      placement="right"
      width={width}
      getContainer={false}
      visible={show}
      closable={false}
      onClose={onCloseDrawer}>
      <div>
        <div className="text-xs font-normal mb-2">
          You can filter the displayed information based on specific dates and locations.
        </div>

        <Form form={form} layout="vertical">
          <div className="space-y-2">
            <FilterWrapper type="Date">
              <Form.Item name="dateRange">
                <Radio.Group className="!mt-1.5" size="small" onChange={handleDateChecked}>
                  <Space direction="vertical">
                    <Radio value={'daily'}>Daily (Most Recent date)</Radio>
                    <Radio value={'weekly'}>Last 7 days</Radio>
                    <Radio value={'monthly'}>Last 30 days</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </FilterWrapper>

            <FilterWrapper type="Custom Range">
              <Form.Item name="customRange">
                <DatePicker.RangePicker
                  data-no-dnd="true"
                  showTime
                  allowClear
                  onOk={handleCustomChecked}
                  ranges={CustomDatePresets}
                  onChange={handleDatePickerChange}
                  className="w-full"
                  format={'YYYY-MM-DD HH:mm'}
                />
              </Form.Item>
            </FilterWrapper>

            <FilterWrapper type="Location">
              <Form.Item name="locationId">
                <Select
                  showSearch
                  optionFilterProp="label"
                  disabled={locations.length === 0}
                  className="w-full drawer-select"
                  placeholder="Select Location"
                  options={locations.map((location) => ({
                    value: location.id,
                    label: location.name
                  }))}
                />
              </Form.Item>
            </FilterWrapper>
          </div>
        </Form>
      </div>
    </Drawer>
  );
}

export default DashboardFilterDrawer;
