import { DashboardDatePresets } from '@/pages/sqlsource/report/utils/datePresets';
import { DatePicker, Divider, Form, FormInstance, PageHeader, Radio, Space } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';

interface Props {
  form: FormInstance;
}

function RangeCompareUI({ form }: Props) {
  const handleCustomRangeDatePicker: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length === 2) {
      form.setFieldValue('compareDateRange', 'custom');
    }
  };

  const handleCompareDateChanged = () => {
    form.setFieldsValue({
      firstDate: undefined,
      secondDate: undefined,
      singleCustomRange: undefined,
      customCompareDateRange: undefined
    });
  };

  return (
    <div>
      <PageHeader className="flex-1" subTitle="Select Compare Date" style={{ padding: '4px 0' }} />

      <Form.Item name="compareDateRange" label="Compare Date Range">
        <Radio.Group className="!mt-1.5" onChange={handleCompareDateChanged}>
          <Space direction="vertical">
            <Radio value={'daily'}>Daily (Most Recent date)</Radio>
            <Radio value={'weekly'}>Last 7 days</Radio>
            <Radio value={'monthly'}>Last 30 days</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>

      <Divider plain>OR</Divider>

      <Form.Item name={'customCompareDateRange'} label="Custom Compare Range">
        <DatePicker.RangePicker
          data-no-dnd="true"
          allowClear
          className="w-full"
          onChange={handleCustomRangeDatePicker}
          onOk={() => form.setFieldValue('compareDateRange', 'custom')}
          ranges={DashboardDatePresets}
        />
      </Form.Item>
    </div>
  );
}

export default RangeCompareUI;
