import { createContext, useContext, useState } from 'react';

interface IContext {
  showSecondaryDrawer: boolean;
  showInfo: boolean;

  setShowInfo: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSecondaryDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const GlobalContext = createContext<IContext | undefined>(undefined);

function GlobalContextProvider({ children }: { children: React.ReactNode }) {
  const [showInfo, setShowInfo] = useState(false);
  const [showSecondaryDrawer, setShowSecondaryDrawer] = useState(false);

  return (
    <GlobalContext.Provider
      value={{ showSecondaryDrawer, showInfo, setShowInfo, setShowSecondaryDrawer }}>
      {children}
    </GlobalContext.Provider>
  );
}

function useGlobalContext() {
  const context = useContext(GlobalContext);

  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalContextProvider');
  }

  return context;
}

export { GlobalContextProvider, useGlobalContext };
