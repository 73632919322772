import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import {
  get_product_details,
  get_product_list_ids,
  get_units_list
} from '../../../services/products/queries';

import { useEffect, useState } from 'react';
import { get_invoices_list } from '../../../services/settings/queries';
import type { ColumnsType } from 'antd/es/table';
import AppContent from '../../../components/Common/Content/Content';
import { PageHeader, Table, Button, Spin, Divider, message, Menu, Tooltip } from 'antd';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import { get_location_details } from '../../../services/locations/queries';
import ReuseChannel from '../../channel/Reuse';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import {
  get_transfer_in_details,
  get_transfer_lines_details,
  get_transfer_list,
  get_transfer_out_details
} from '../../../services/transfer/queries';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import CopyButton from '../../../components/Common/CopyButton';
import { numberDecimalFormatter } from '../../../utils/numberFormatter';
import { ICreateInvoiceResponse } from '../../../services/settings/types';
import { getTransferPrintData } from '../../../components/Common/InvoicePrint/TransferInvoice/services';
import { ITransferInvoice } from '../../../services/invoice/types';
import { CustomModal } from '../../../components/Common/CustomModal';
import TransferInvoice from '../../../components/Common/InvoicePrint/TransferInvoice/TransferInvoice';
import InvoicePrintButton from '../../../components/Common/InvoicePrintButton/InvoicePrintButton';

interface Props {
  id: number;
  type?: 'in' | 'out';
}
const ReusableTransfersView: React.FC<Props> = ({ id, type = 'in' }) => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsloading] = useState(true);
  const [productList, setProductList] = useState<any[]>([]);
  const [lines, setLines] = useState<any>();
  const [invoiceData, setInvoiceData] = useState<ITransferInvoice>(Object);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  const [netTotalAmount, setNettotalamount] = useState<any>();
  useEffect(() => {
    getInfo();
  }, [id]);

  const [invoiceLists, setInvoiceLists] = useState<ICreateInvoiceResponse[]>([]);
  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    // console.log('response-->', response);
    setInvoiceLists(
      response.data.filter((curr: any) => {
        const content = JSON.parse(curr.content);
        return content.design == 'classic';
      })
    );
    return response;
  });

  const getInfo = async () => {
    setIsloading(true);
    let response: any;
    if (type == 'in') {
      response = await get_transfer_in_details(id);
    } else {
      response = await get_transfer_out_details(id);
    }
    // console.log('Linesresponse-->', linesResponse);
    // console.log('resonse data results', response.data.results);
    if (response) {
      //locaton name extract
      // const filterdata = response.data.results.find((curr: any) => curr.id == id);
      let location: any = await LocationsDB.getLocation(response.from);
      if (!location) {
        location = await get_location_details(response.from);
        await LocationsDB.addLocations([location]);
      }
      response.fromName = location.name;
      response.fromEmail = location.email;
      response.fromDetails = `${location.city} ${location.country}`;
      // console.log('Location from details==>', location);
      //to
      location = await LocationsDB.getLocation(response.to);
      if (!location) {
        location = await get_location_details(response.to);
        await LocationsDB.addLocations([location]);
      }
      response.toName = location.name;
      response.toEmail = location.email;
      response.toDetails = `${location.city} ${location.country}`;
      // console.log('Location to details==>', location);
      setData(response);
    }
    if (response.lines.length > 0) {
      let netTotalAmount = 0;
      const searchProducts: any = {};
      for (let index = 0; index < response.lines.length; index++) {
        const product: any = await ProductsDB.getProduct(response.lines[index].productId);
        if (!product) {
          // product = await get_product_details(linesResponse.lines[index].productId);
          // await ProductsDB.addProducts([product]);
          if (response.lines[index].productId in searchProducts) {
            searchProducts[response.lines[index].productId] = [
              ...searchProducts[response.lines[index].productId],
              index
            ];
          } else {
            searchProducts[response.lines[index].productId] = [index];
          }
        } else {
          response.lines[index].productName = product.name;
        }

        let findUnit: any = await UnitsDB.getUnit(response.lines[index].unitId);
        if (!findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          findUnit = await UnitsDB.getUnit(response.lines[index].unitId);
        }
        // console.log('FindUnit-->', findUnit);
        response.lines[index].unitName = findUnit.name;
        response.lines[index].quantity = `${response.lines[index].quantity} ${findUnit.shortName}`;
        response.lines[index].rate = response.lines[index].unitPrice;
        netTotalAmount += response.lines[index].totalAmount;
      }
      // console.log('Search Products-->', searchProducts);
      const searchProductslength = Object.entries(searchProducts).length;
      if (searchProductslength > 0) {
        const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
        for (const key in searchProducts) {
          const findproduct = productsresponse?.data?.results.find(
            (currProduct: any) => currProduct.id == key
          );
          for (let i = 0; i < searchProducts[key].length; i++) {
            response.lines[searchProducts[key][i]].productName = findproduct?.name;
          }
          await ProductsDB.addProducts([findproduct]);
        }
      }

      setLines(response.lines);
      //   console.log(linesResponse.data);
      setNettotalamount(netTotalAmount);
      setIsloading(false);
    }
    setIsloading(false);
    return response;
  };

  const columns: ColumnsType<{ name: string }> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 10,
      render: (a, b, c) => <div>{c + 1}.</div>
    },
    {
      title: 'Id',
      dataIndex: 'id',
      render: (text: string) => <div>{text}</div>,
      width: 10
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      render: (text: number) => <div>{text}</div>,
      width: 40
    },
    // {
    //   title: `Unit`,
    //   dataIndex: 'unitName',
    //   render: (text: number) => <div>{text}</div>,
    //   width: 20
    // },
    {
      title: `Quantity`,
      dataIndex: 'quantity',
      render: (text: number) => <div>{text}</div>,
      width: 20
    },
    {
      title: 'Rate (NPR)',
      dataIndex: 'rate',
      render: (text: number) => (
        <div className="text-right mr-2">{numberDecimalFormatter(text)}</div>
      ),
      width: 20
    },
    {
      title: `Subtotal (NPR)`,
      dataIndex: 'totalAmount',
      render: (text: number) => (
        <div className="text-right mr-2">{numberDecimalFormatter(text)}</div>
      ),
      width: 20
    }
  ];

  const generateInvoiceForPrint = async (curr: any) => {
    setIsloading(true);
    const invoiceModalData = await getTransferPrintData(data.id, data.from, data.to, curr);
    setIsloading(false);

    setInvoiceData(invoiceModalData);

    setOpenModalForInvoicePrint(true);
  };

  const handleInvoicePrintModalClose = async () => {
    setOpenModalForInvoicePrint(false);
    await getInfo();
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <CustomModal
          footer={false}
          isModalOpen={openModalForInvoicePrint}
          setIsModalOpen={setOpenModalForInvoicePrint}
          title="Transfer Invoice Print">
          <TransferInvoice
            transferData={invoiceData.transferData}
            lines={invoiceData.lines}
            invoiceLayouts={invoiceData.invoiceLayouts}
            handleModalClose={handleInvoicePrintModalClose}
          />
        </CustomModal>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-5">
          <div>
            <PageHeader
              subTitle="Location(From)"
              style={{
                padding: '8px 0px'
              }}
            />
            {data ? (
              <div style={{ color: 'black' }}>
                <span className="block">{data.fromName}</span>
                <span className="block">{data.fromDetails}</span>
                <span className="block">{data.fromEmail}</span>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div>
            <PageHeader
              subTitle="Location(To)"
              style={{
                padding: '8px 0px'
              }}
            />
            {data ? (
              <>
                <div style={{ color: 'black' }}>
                  <span className="block">{data.toName} </span>
                  <span className="block">{data.toDetails}</span>
                  <span className="block">{data.toEmail}</span>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            <PageHeader
              subTitle="Details"
              style={{
                padding: '8px 0px'
              }}
            />
            {data ? (
              <>
                <div style={{ color: 'black' }}>
                  <span className="block">
                    Financial Reference : {data.financialReference}{' '}
                    <CopyButton text={data.financialReference} />{' '}
                  </span>
                  {/* </p>
              <p> */}
                  <span className="block">Created Date : {data.createdAt.substr(0, 10)}</span>
                  <span className="block">Status : {data.status}</span>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <Table
          size="small"
          columns={columns}
          dataSource={lines}
          rowKey={'id'}
          pagination={false}
          scroll={{ y: 400, x: 800 }}
        />
        <div className="grid grid-cols-3 gap-5 mb-5">
          <div>
            <PageHeader
              subTitle="Payment Info"
              style={{
                padding: '8px 0px'
              }}
            />
            {data ? (
              <div style={{ color: 'black' }}>
                <span className="block">Net Total Amount : Rs {netTotalAmount}</span>
                <span className="block">Additional Shipping Charges : </span>
                <span className="block">Purchase Total : Rs {netTotalAmount}</span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="flex justify-end  gap-5 mt-5">
          <div
            style={{
              width: '3rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <InvoicePrintButton
              getInvoice={(curr) => {
                generateInvoiceForPrint(curr);
              }}
            />
            {/* <ActionDropdown
              trigger={'click'}
              insideaction={true}
              menu={
                <Menu
                  items={invoiceLists?.map((curr: any, ind: number) => {
                    return {
                      key: ind,
                      label: (
                        <Tooltip title="Print receipt" color="blue">
                          <div className="text-center">{curr.name}</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        generateInvoiceForPrint(curr);
                      }
                    };
                  })}
                />
              }
            /> */}
          </div>
        </div>
      </Spin>
      <Divider />
      <ReuseChannel slug={`transfers_${id}`} />
    </div>
  );
};

export default ReusableTransfersView;
