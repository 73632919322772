import { useQuery } from '@tanstack/react-query';
import { Form, Spin, TableProps } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { get_customer_group_lines_list } from '../../../../services/offfers/queries';
import { ICustomerGroupLines } from '../../../../services/offfers/types';
import AppContent from '../../../../components/Common/Content/Content';
import CustomizeTable from '../../../../components/Common/CustomizeTable/CustomizeTable';
import { ConvertObjectToURL } from '../../../../utils/converturl';
import TableCell from '../../../../components/Common/CustomizeTable/CustomCell';
import moment from 'moment';
import { convertUTCStringtoLocalString } from '../../../../utils/convertToUTC';
import CustomersDB from '../../../../store/localstorage/CustomerDB';
import { get_customer_list_ids } from '../../../../services/users/queries';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const CustomerGroupView = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams();
  const [lines, setLines] = useState<ICustomerGroupLines[]>([]);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const breadcrumbItems = [
    {
      label: 'Customer Group',
      link: '/offer/customer-group'
    },
    {
      label: 'View',
      link: '/offer/customer-group/view/:id'
    }
  ];

  useQuery(['customergroupList', id], async () => await getData());

  const getData = async () => {
    if (id) {
      const lines = await get_customer_group_lines_list(parseInt(id));
      // console.log('data', data);
      // console.log('lines', lines);
      if (lines) {
        for (let i = 0; i < lines.length; i++) {
          let customerDetails = await CustomersDB.getCustomer(lines[i].customerId);
          if (!customerDetails) {
            const allCustomers = await get_customer_list_ids([
              ...new Set(
                lines.map((val) => {
                  return val.customerId;
                })
              )
            ]);
            await CustomersDB.addCustomers(allCustomers.data.results);
            customerDetails = await CustomersDB.getCustomer(lines[i].customerId);
          }
          lines[i].customerName = customerDetails.user.name;
        }
        setLines(lines);
      }
      setIsLoading(false);
    }
  };

  const columns: ColumnsType<ICustomerGroupLines> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      render: (txt, record, index) => {
        return <TableCell>{index + 1}</TableCell>;
      }
    },
    {
      title: 'Customer Name',
      key: 'customerName',
      width: 15,
      sorter: (a, b) =>
        a.customerName && b.customerName ? a.customerName.localeCompare(b.customerName) : 0,
      sortOrder: sortedInfo.columnKey === 'customerName' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.customerName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 15,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <CustomizeTable
          columns={columns}
          data={lines}
          notshowPagination={true}
          customScroll={{ x: 600, y: '75vh' }}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default CustomerGroupView;
