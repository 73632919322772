import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { INotificationsList, IResponseType } from './types';

export const create_notification_group = async (values: any) => {
  const response: AxiosResponse<INotificationsList[], any> = await http.store(
    'notification/group/',
    values
  );
  return response;
};

export const update_notification_group = async (information: {
  values: any;
  id: string | number;
}) => {
  const response: AxiosResponse<INotificationsList, any> = await http.store(
    `notification/group/${information.id}`,
    information.values
  );
  return response;
};

export const update_read_notification = async (id: string[] | number[]) => {
  const response: AxiosResponse<IResponseType, any> = await http.store('notification/read/', id);
  return response;
};

export const subscribe_notification = async (token: string) => {
  const response = await http.store('notification/subscribe/' + token);
  return response;
};
