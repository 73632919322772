import { CSSProperties } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

import { Dropdown, Menu } from 'antd';
import { EllipsisOutlined, ExpandAltOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { cn } from '@/utils';
import useResize from '@/hooks/useResize';
import { mergeRefs } from '@/utils/mergeRefs.utils';
import { WIDGET_CUSTOMIZE_HEIGHT } from '../constant.dashboard';
import { IWidgetConfiguration } from '@/services/dashboard/v3/types';
import { useDashboardCustomize } from '@/contexts/dashboard-customize.context';
import RenderWidget from '../widget/RenderWidget';

interface Props {
  position: number;
  column: number;
  widget: IWidgetConfiguration;
  isCustom: boolean;
  onEdit: () => void;
}

function WidgetCustomizeLayout({ position, column, widget, isCustom, onEdit }: Props) {
  const {
    isAnyWidgetResizing,
    editingWidgets,
    defaultAPIConfig,
    setEditingWidgets,
    setIsAnyWidgetResizing,
    moveWidget,
    reportLists
  } = useDashboardCustomize();

  const { wrapperRef, isResizing, colSize, handleMouseDown } = useResize({
    initialColSize: column,
    onResizeEnd,
    setIsAnyResizing: setIsAnyWidgetResizing
  });

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: widget.id,
    disabled: isAnyWidgetResizing
  });

  const style: CSSProperties = {
    transition,
    height: `${WIDGET_CUSTOMIZE_HEIGHT}px`,
    transform: CSS.Translate.toString(transform),
    gridColumn: `span ${colSize}`,
    zIndex: isDragging ? 1 : 0,
    opacity: isDragging ? 0.7 : 1,
    border: '1px solid transparent'
  };

  function onResizeEnd(colSize: number) {
    const widgetPosition = editingWidgets.findIndex((item) => item.id === widget.id);
    if (widgetPosition === -1) return;

    const newWidget = [...editingWidgets];
    newWidget[widgetPosition].colSize = colSize;
    setEditingWidgets(newWidget);
  }

  function onRemoveWidget() {
    const updatedWidgets = editingWidgets.filter((w) => w.id !== widget.id);
    setEditingWidgets(updatedWidgets);
  }

  const moveWidgetLeft = () => moveWidget(widget.id, 'left');
  const moveWidgetRight = () => moveWidget(widget.id, 'right');

  const reportIds = widget.widgets.length > 0 ? widget.widgets.map((w) => w.reportId) : [];
  const reportNameWithId = reportLists
    .filter((report) => reportIds.includes(report.id))
    .map((report) => ({
      id: report.id,
      name: report.name
    }));

  return (
    <div
      ref={mergeRefs(wrapperRef, setNodeRef)}
      className={cn(
        'widget bg-white rounded-md shadow-sm relative overflow-hidden origin-left cursor-move',
        isResizing && 'resizing select-none cursor-default'
      )}
      style={style}
      {...attributes}
      {...listeners}>
      <div className="flex justify-between items-center h-6 px-2  mt-2">
        <span className="select-none">{widget.title}</span>
        <div data-no-dnd="true" className="z-20">
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu data-no-dnd="true">
                <Menu.Item key="edit-widget" onClick={onEdit}>
                  Edit Widget
                </Menu.Item>
                <Menu.Item key="hide-widgets" onClick={onRemoveWidget}>
                  Remove Widget
                </Menu.Item>
                <Menu.Item
                  key="move-widget-left"
                  disabled={position === 0}
                  onClick={moveWidgetLeft}>
                  Move Left
                </Menu.Item>
                <Menu.Item
                  key="move-widget-right"
                  disabled={position === editingWidgets.length - 1}
                  onClick={moveWidgetRight}>
                  Move Right
                </Menu.Item>
              </Menu>
            }>
            <EllipsisOutlined className="[&>svg]:m-0 cursor-pointer" />
          </Dropdown>
        </div>
      </div>

      <div
        data-no-dnd="true"
        aria-label="Resize widget"
        className="absolute bottom-1 right-2 cursor-pointer z-20"
        onMouseDown={handleMouseDown}>
        <ExpandAltOutlined className="scale-x-[-1]" />
      </div>

      {isCustom && (
        <div className="flex justify-center items-center h-[calc(100%-3rem)]">
          <div data-no-dnd="true">
            <PlusCircleOutlined
              className="[&>svg]:m-0 cursor-pointer"
              style={{ fontSize: 40 }}
              onClick={onEdit}
            />
          </div>
        </div>
      )}

      {!isCustom && (
        <div className="h-[calc(100%-2rem)]">
          <RenderWidget
            type="customize"
            widget={widget}
            reports={reportNameWithId}
            defaultAPIConfig={defaultAPIConfig}
          />
        </div>
      )}
    </div>
  );
}

export default WidgetCustomizeLayout;
