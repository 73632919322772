import { storesToCreate } from './storeList.constant';

const newVersion = 6;

export default class LocalStore {
  static db: IDBDatabase;
  static async init() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('ERPDB', newVersion);

      request.onsuccess = (event: any) => {
        LocalStore.db = event.target.result;
        resolve('success');
      };
      request.onupgradeneeded = (event: any) => {
        // console.log('onupgradeneeded');
        LocalStore.db = event.target.result;

        storesToCreate.forEach(({ name, keyPath, index, indexKey }) => {
          if (!LocalStore.db.objectStoreNames.contains(name)) {
            const store = LocalStore.db.createObjectStore(name, { keyPath });
            if (index && indexKey) {
              store.createIndex(index, indexKey, { unique: false });
            }
          }
        });
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
      request.onblocked = (event: any) => {
        this.db.close();
        reject('Blocked!');
      };
    });
  }
  static async delete() {
    return new Promise((resolve, reject) => {
      const DBDeleteRequest = window.indexedDB.deleteDatabase('ERPDB');

      DBDeleteRequest.onblocked = async (event) => {
        console.log('Database blocked, retrying....');
        LocalStore.db.close();
        await LocalStore.delete();
        // reject('Database blocked');
      };
      DBDeleteRequest.onerror = (event) => {
        console.log('Database couldnt be deleted');
        reject('Database couldnt be deleted');
      };
      DBDeleteRequest.onsuccess = (event) => {
        console.log('Database deleted successfully');
        resolve('Database deleted successfully');
      };
    });
  }
}
