import { AxiosResponse } from 'axios';
import http from '../utils/http.utils';

export interface IAllResponse<T> {
  results: T[];
  total: number;
}

interface IGetDataDynamicFieldOptions {
  totalField?: string;
  resultsField?: string;
  filter?: Record<string, any>;
}

export const getDataOnlyForIds = async <T>(ids: number[], api: string, filter = {}) => {
  const uniqueIds = [...new Set(ids)];
  const newIds = splitIds(uniqueIds);

  const responses = await Promise.all(
    newIds.map(async (newIds) => {
      const response = await http.get(api, {
        ids: newIds,
        ...filter,
        count: newIds.length
      });
      return response.data as T[];
    })
  );

  const allResponses = responses.flat();
  return allResponses;
};

export const getDataForIdsByDynamicField = async <T>(
  ids: number[],
  api: string,
  options?: IGetDataDynamicFieldOptions
) => {
  const uniqueIds = [...new Set(ids)];
  const newIds = splitIds(uniqueIds);

  const filter = options?.filter || {};
  const totalField = options?.totalField || 'total';
  const resultsField = options?.resultsField || 'results';

  const responses = await Promise.all(
    newIds.map(async (newIds) => {
      const response: AxiosResponse<IAllResponse<T>> = await http.get(api, {
        ids: newIds,
        ...filter,
        count: newIds.length
      });
      return { ...response.data };
    })
  );

  const allResponse: IAllResponse<T> = { results: [], total: 0 };
  responses.forEach((res: any) => {
    allResponse.results.push(...res[resultsField]);
    allResponse.total += res[totalField];
  });

  return allResponse;
};

export const getDataForIds = async <T>(ids: number[], api: string, filter = {}) => {
  const uniqueIds = [...new Set(ids)];
  const newIds = splitIds(uniqueIds);

  const responses = await Promise.all(
    newIds.map(async (newIds) => {
      const response: AxiosResponse<IAllResponse<T>> = await http.get(api, {
        ids: newIds,
        ...filter,
        count: newIds.length
      });
      return { ...response.data };
    })
  );

  const allResponse: IAllResponse<T> = { results: [], total: 0 };
  responses.forEach((res) => {
    allResponse.results.push(...res.results);
    allResponse.total += res.total;
  });

  return allResponse;
};

export const getDataForIdsByDate = async <T>(ids: number[], startDate: string, api: string) => {
  const uniqueIds = [...new Set(ids)];
  const newIds = splitIds(uniqueIds);

  const responses = await Promise.all(
    newIds.map(async (newIds) => {
      const response: AxiosResponse<IAllResponse<T>> = await http.get(api, {
        ids: newIds,
        startDate: startDate,
        count: newIds.length
      });
      return { ...response.data };
    })
  );

  const allResponse: IAllResponse<T> = { results: [], total: 0 };
  responses.forEach((res) => {
    allResponse.results.push(...res.results);
    allResponse.total += res.total;
  });

  return allResponse;
};

export const getDataForIdsByDates = async <T>(
  ids: number[],
  startDate: string,
  endDate: string,
  api: string
) => {
  const uniqueIds = [...new Set(ids)];
  const newIds = splitIds(uniqueIds);

  const responses = await Promise.all(
    newIds.map(async (newIds) => {
      const response: AxiosResponse<IAllResponse<T>> = await http.get(api, {
        ids: newIds,
        startDate: startDate,
        endDate: endDate,
        count: newIds.length
      });
      return { ...response.data };
    })
  );

  const allResponse: IAllResponse<T> = { results: [], total: 0 };
  responses.forEach((res) => {
    allResponse.results.push(...res.results);
    allResponse.total += res.total;
  });

  return allResponse;
};

export const splitIds = (originalArray: number[]) => {
  const chunkSize = 50;
  return originalArray.reduce((result: number[][], item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!result[chunkIndex]) {
      result[chunkIndex] = [];
    }
    result[chunkIndex].push(item);
    return result;
  }, []);
};
