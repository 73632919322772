import Details from '../../Users/details/Details';

function CustomerDetails() {
  return (
    <>
      <Details
        breadcrumbItem={{
          label: 'Customer',
          link: '/users/customers'
        }}
      />
    </>
  );
}

export default CustomerDetails;
