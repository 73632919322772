import {
  ICreateCustomerResponse,
  ICreateVendorResponse,
  ICustomer,
  ICustomerCreateV2,
  IUpdateVendorTypeForVendorResponse,
  IUpgradeGradeForCustomerAndVendorResponse,
  IUserCreateAddressRequest,
  IUserCreateAddressResponse,
  IUserCreateRequest,
  IUserCreateTaxRequest,
  IUserCreateV2,
  IUserEditAddressRequest,
  IUserUpdateNameAndPhoneRequest,
  IUserUpdateRequest,
  IUserUpdateTaxRequest,
  User
} from './types';
import http from '../../utils/http.utils';
import { async } from 'q';
import { AxiosResponse } from 'axios';
import { IPreferences, IPreferencesResponse } from '../settings/types';
import { ICreateCommonResponse, IDeleteCommonResponse } from '../category/types';
import { type } from 'os';

export const create_users_mutation = async (values: IUserCreateRequest) => {
  const response: AxiosResponse<User, any> = await http.store('users', values);
  return response;
};

export const create_users_mutation_v2 = async (values: IUserCreateV2) => {
  const response: AxiosResponse<User, any> = await http.store('users-v2', values);
  return response.data;
};

export const update_disabled_mutation = async (values: { flag: boolean; id: string | number }) => {
  const response: AxiosResponse<User, any> = await http.store(
    `/users/disable/${values.id}/${values.flag}`
  );
  return response;
};
export const update_preference_user_mutation = async (values: {
  id: string | number;
  value: string;
}) => {
  const response: AxiosResponse<IPreferencesResponse, any> = await http.store(
    `/users/preferences/${values.id}`,
    values.value
  );
  return response;
};
export const update_users_mutation = async (
  values: IUserUpdateRequest | IUserUpdateNameAndPhoneRequest
) => {
  const response: AxiosResponse<ICreateCommonResponse, any> = await http.update(
    'users/' + values.id,
    values
  );
  return response;
};

export const update_customer_mutation = async (
  values: IUserUpdateRequest | IUserUpdateNameAndPhoneRequest
) => {
  const response: AxiosResponse<ICreateCommonResponse, any> = await http.update(
    'users/customers/' + values.id,
    values
  );
  return response;
};

export const update_customer_type_mutation = async (id: number, isCredit: boolean) => {
  const response: AxiosResponse<ICustomer> = await http.update('users/customers-type/' + id, {
    isCredit
  });

  return response.data;
};

export const update_vendor_mutation = async (
  values: IUserUpdateRequest | IUserUpdateNameAndPhoneRequest
) => {
  const response: AxiosResponse<ICreateCommonResponse, any> = await http.update(
    'users/vendors/' + values.id,
    values
  );
  return response;
};

export const create_vendors_mutation = async (values: IUserCreateRequest) => {
  const response: AxiosResponse<ICreateVendorResponse, any> = await http.store(
    'users/vendors',
    values
  );
  return response;
};
export const create_customers_mutation = async (values: IUserCreateRequest) => {
  const response: AxiosResponse<ICreateCustomerResponse, any> = await http.store(
    'users/customers',
    values
  );
  return response;
};

export const create_customers_mutation_v2 = async (values: ICustomerCreateV2) => {
  const response: AxiosResponse<ICreateCustomerResponse, any> = await http.store(
    'users-v2/customers',
    values
  );
  return response.data;
};

export const create_users_address_mutation = async (values: IUserCreateAddressRequest) => {
  const response: AxiosResponse<IUserCreateAddressResponse, any> = await http.store(
    'users/address',
    values
  );
  return response;
};
export const create_users_tax_mutation = async (values: IUserCreateTaxRequest) => {
  const response: AxiosResponse<IUserCreateTaxRequest, any> = await http.store(
    'users/tax_informations',
    values
  );
  return response;
};
export const update_users_address_mutation = async (values: IUserEditAddressRequest) => {
  const response: AxiosResponse<ICreateCommonResponse, any> = await http.update(
    'users/address/' + values.id,
    values
  );
  return response;
};

export const delete_users_address_mutation = async (id: number) => {
  const response: AxiosResponse<IDeleteCommonResponse, any> = await http.remove(
    'users/address/' + id
  );
  return response;
};

export const update_users_tax_mutation = async (values: IUserUpdateTaxRequest) => {
  const response: AxiosResponse<ICreateCommonResponse, any> = await http.update(
    'users/tax_informations/' + values.id,
    values
  );
  return response;
};

export const delete_users_tax_mutation = async (id: number) => {
  const response: AxiosResponse<IDeleteCommonResponse, any> = await http.remove(
    'users/tax_informations/' + id
  );
  return response;
};

export const update_credit_limit_for_customer = async (values: {
  id: number | string;
  creditLimit: number;
}) => {
  const response = await http.store(
    'users/customer/' + values.id + '/credit-limit/' + values.creditLimit
  );
  return response;
};

export const update_grade_for_vendor_or_customer = async (values: {
  type: string;
  id: number | string;
  grade: string;
}) => {
  const response: AxiosResponse<IUpgradeGradeForCustomerAndVendorResponse, any> = await http.store(
    'users/' + values.type + '/' + values.id + '/grade/' + values.grade
  );
  return response;
};

export const update_vendor_type_for_vendor = async (values: {
  id: number | string;
  vendorTypeId: number | string;
}) => {
  const response: AxiosResponse<IUpdateVendorTypeForVendorResponse, any> = await http.update(
    'users/' + 'vendors-type/' + values.id,
    values
  );
  return response;
};

export const convert_user_to_customer_mutation = async (userId: number) => {
  const response: AxiosResponse<ICustomer, any> = await http.store('users/staff-customer', {
    userId: userId
  });
  return response.data;
};

export const convert_user_to_vendor_mutation = async (values: {
  userId: number;
  vendorTypeId: number;
}) => {
  const response: AxiosResponse<IUpdateVendorTypeForVendorResponse, any> = await http.store(
    'users/staff-vendor',
    values
  );
  return response.data;
};

export const move_all_addresses_mutation = async (values: {
  currentRouteId: number;
  newRouteId: number;
}) => {
  const response = await http.update('users/move-all-addresses-route', values);
  return response.data;
};
