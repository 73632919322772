import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http.utils';
import { Image } from 'antd';

interface Iprops {
  idurl: string;
}

export const get_image = async (idurl: string) => {
  const response = await http.get(`${idurl}`);
  return response.data;
};

const FetchImage = ({ idurl }: Iprops) => {
  const [imageurl, setImageurl] = useState<string>('');
  useQuery([idurl], async () => get_image(idurl), {
    retry: false,
    onSuccess: (data: string) => {
      // console.log('Data from product image api', data);
      setImageurl(data);
    }
    // onError: (data: any) => {
    //   // console.log('Data from Error', data);
    // }
  });

  return <Image width={100} src={imageurl} />;
};

export default FetchImage;
