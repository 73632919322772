import CustomBarcode from '@/pages/barcode';
import DynamicTable from '@/pages/expense/view/DynamicTable';
import { IJournalListResponseData, IJournalViewResponseTableData } from '@/services/accounts/types';
import { getUserData } from '@/utils/auth.utils';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { Button, PageHeader } from 'antd';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import JournalPrintFormat from '../../CustomResuableInvoice/JournalPrintFormat';
import { convert_string_to_nepali_date_string } from '@/utils/nepaliDateConverter';
import { IExpenseListRevamp } from '@/services/expense/types';

interface IExpenseViewList extends IExpenseListRevamp {
  locationName: string;
  categoryName: string;
  createdUser: string;
  expenses: IExpenseViewList[];
}

interface Props {
  invoiceLayouts: any;
  expenseDetail: IExpenseViewList;
  journalDetails: IJournalListResponseData;
  lines: IJournalViewResponseTableData[];
  handleModalClose?: () => void;
}

function ExpenseInvoice({
  lines,
  invoiceLayouts,
  handleModalClose,
  expenseDetail,
  journalDetails
}: Props) {
  const printRef = useRef<any>();
  const currentUser = getUserData();
  const layout = JSON.parse(invoiceLayouts?.content);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    async onAfterPrint() {
      if (handleModalClose) {
        handleModalClose();
      }
    }
  });

  return (
    <>
      <div className="flex justify-end mb-3">
        <Button type="primary" onClick={handlePrint}>
          Print
        </Button>
      </div>

      <div ref={printRef}>
        <div style={{ color: 'black' }} className="mx-5 my-3">
          <div className="flex justify-between">
            <div className="font-bold text-xl">
              <div>{layout.headerText || 'FreshKtm Agro Pvt. Ltd.'}</div>
              <span>
                Expense Details ({'Expense Reference'} : {expenseDetail.referenceNumber})
              </span>
            </div>

            <div className="self-end">
              <div className="text-right">
                <span> Miti</span>:
                {expenseDetail?.createdAt
                  ? convert_string_to_nepali_date_string(expenseDetail?.createdAt)
                  : ''}
              </div>

              <div className="text-right">
                <span>Date</span>:
                {expenseDetail?.createdAt
                  ? convertUTCStringtoLocalString(expenseDetail.createdAt, 'YYYY-MM-DD')
                  : ''}
              </div>
            </div>
          </div>
          <hr />

          <div className="grid grid-cols-3 gap-5 mb-5">
            <div>
              <PageHeader
                subTitle="Billing Details"
                style={{ padding: '8px 0px', fontWeight: 'bold' }}
              />
              <div>No. : {expenseDetail.billNumber}</div>
              <div>
                Date : {convertLocalToUTCString(expenseDetail.billDate || '', 'YYYY-MM-DD')}
              </div>
            </div>

            <div>
              <PageHeader subTitle="Category" style={{ padding: '8px 0px', fontWeight: 'bold' }} />
              <div>{expenseDetail.categoryName}</div>
            </div>

            <div>
              <PageHeader subTitle="Details" style={{ padding: '8px 0px', fontWeight: 'bold' }} />

              <div>Reference No. : {expenseDetail.referenceNumber}</div>
              <div>
                <span>{'Location'}</span> :{' '}
                <span className="italic font-bold">{expenseDetail?.locationName}</span>
              </div>
              <div>
                <span>{'Description'}</span> :{' '}
                <span className="italic font-bold">{expenseDetail?.description}</span>
              </div>
            </div>
          </div>
          {expenseDetail.expenses && expenseDetail.expenses.length !== 0 && (
            <div>
              <PageHeader
                subTitle="Category Information"
                style={{ padding: '8px 0px', fontWeight: 'bold' }}
              />

              {<DynamicTable data={expenseDetail.expenses} type="print" />}
            </div>
          )}

          <div>
            <PageHeader
              subTitle="Journal Information"
              style={{ padding: '8px 0px', fontWeight: 'bold' }}
            />

            <JournalPrintFormat
              journalData={journalDetails}
              modalData={lines}
              hideDate
              showDescription
            />
          </div>

          <div className="flex items-center justify-center mt-4">
            <CustomBarcode
              value={expenseDetail.referenceNumber || ''}
              height={35}
              width={1.4}
              fontSize={16}
            />
          </div>

          <div className="flex justify-end">
            <div>
              Printed By: <span className="capitalize">{currentUser?.name || ''}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExpenseInvoice;
