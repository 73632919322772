import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IVendorProductData, IVendorProductsPayload } from './types';

export const create_vendor_products = async (values: IVendorProductsPayload) => {
  const response: AxiosResponse<IVendorProductData> = await http.store('vendor-products/', values);
  return response;
};

export const update_vendor_products = async (information: {
  values: IVendorProductsPayload;
  id: number;
}) => {
  const response: AxiosResponse<IVendorProductData> = await http.update(
    `vendor-products/${information.id}`,
    information.values
  );
  return response;
};

export const delete_vendor_products = async (id: string | number) => {
  const response = await http.remove('vendor-products/' + id);
  return response.data;
};
