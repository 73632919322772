import { cn } from '@/utils';
import React from 'react';

interface Props {
  className?: string;
  icon: React.ElementType;
}

function DashboardIconWrapper({ icon: Icon, className = '' }: Props) {
  return (
    <span
      role="img"
      aria-label="table"
      className={cn('anticon anticon-table menu-icons ant-menu-item-icon', className)}>
      <Icon />
    </span>
  );
}

export default DashboardIconWrapper;
