import {
  IOutletPricingByDate,
  IPriceAnalysisDetails,
  IProductsLinesAvgQty
} from '@/services/products/types';
import { IRow } from './grid.types';
import { getProductById, getUnit } from '@/services';
import roundNumber from '@/utils/round.utils';
import { getAllFilterPayload } from './create/services';
import {
  get_products_lines_avg_qty,
  get_products_lines_sum_qty,
  get_products_lots_sum
} from '@/services/products/queries';
import { LineType } from '@/services/report/enums';
import ProductsDB from '@/store/localstorage/ProductsDB';

export async function formatPriceAnalysistoGridRow(data: IPriceAnalysisDetails) {
  const { purchase: purchaseList, sale: saleList, analysis } = data;
  const purchaseProductIds = purchaseList.map((item) => item.productId);
  const saleProductIds = saleList.map((item) => item.productId);

  const uniqueProductIds = [...new Set([...purchaseProductIds, ...saleProductIds])];

  const rows: IRow[] = [];
  await ProductsDB.addProductsIfAbsent(uniqueProductIds);
  for (const productId of uniqueProductIds) {
    const purchase = purchaseList.find((item) => item.productId === productId);
    const sell = saleList.find((item) => item.productId === productId);

    const unitId = (purchase?.unitId || sell?.unitId) as number;

    const productDetails = await getProductById(productId);
    const unitDetails = await getUnit(unitId);

    const purchaseAmount = { margin: 0, marginPercentage: 0, isPriceChanged: false };
    const sellAmount = {
      margin: 0,
      marginPercentage: 0,
      isPriceChanged: false,
      isSellRateChanged: false
    };

    if (purchase) {
      purchaseAmount.margin = roundNumber(purchase.todayPrice - purchase.purchasePrice);
      purchaseAmount.marginPercentage = purchase?.purchasePrice
        ? roundNumber((purchaseAmount.margin / purchase.purchasePrice) * 100)
        : 0;
      purchaseAmount.isPriceChanged = purchase.yesterdaySellingRate !== purchase.todayPrice;
    }

    if (sell) {
      sellAmount.margin = roundNumber(sell.todayPrice - sell.purchasePrice);
      sellAmount.marginPercentage = sell?.purchasePrice
        ? roundNumber((sellAmount.margin / sell.purchasePrice) * 100)
        : 0;
      sellAmount.isPriceChanged = sell.purchasePrice !== purchase?.todayPrice;
      sellAmount.isSellRateChanged = sell.yesterdayPrice !== sell.todayPrice;
    }

    const allUnits = productDetails.productUnits.map((p) => p.unitId);

    rows.push({
      // Product Units
      analysisId: analysis.id,
      productId,
      productName: productDetails?.name,
      productUnits: allUnits || [],
      unitName: unitDetails?.name,
      unitId: unitId,

      // Purchase Data
      purchaseId: purchase?.id,
      purchasePrice: purchase?.purchasePrice || 0,
      kalimatiRate: purchase?.kalimatiRate || 0,
      morningPurchase: purchase?.morningPurchase || 0,
      kalimatiSurveyRate: purchase?.kalimatiSurveyRate || 'N/A',
      yesterdaySellingRate: purchase?.yesterdaySellingRate || 0,
      yesterdaySoldQty: purchase?.yesterdaySoldQty || 0,
      totalStock: purchase?.totalStock || 0,
      todayPrice: purchase?.todayPrice || 0,
      openingStock: purchase?.openingStock || 0,
      totalOrder: purchase?.totalOrder || 0,
      estimatedData: purchase?.estimatedData || 0,

      // Outlet Data
      sellId: sell?.id,
      sellPurchasePrice: sell?.purchasePrice || 0,
      sellYesterdayPrice: sell?.yesterdayPrice || 0,
      sellTodayPrice: sell?.todayPrice || 0,

      // Calculated Purchase Data
      isPriceChanged: purchaseAmount.isPriceChanged,
      marginAmount: purchaseAmount.margin,
      marginPercentage: purchaseAmount.marginPercentage,

      // Calculated Outlet Data
      isPurchasePriceChanged: sellAmount.isPriceChanged,
      isSellRateChanged: sellAmount.isSellRateChanged,
      marginSellAmount: sellAmount.margin,
      marginSellPercentage: sellAmount.marginPercentage
    });
  }

  return rows;
}

export function getIdentifierData(
  data: IProductsLinesAvgQty[],
  productId: number,
  identifier: LineType,
  baseUnitMultiplier = 1
) {
  const product = data.find((p) => p.productId === productId && p.identifier === identifier);
  const price = (product?.avgBaseUnitPrice || 0) * baseUnitMultiplier;
  const quantity = (product?.avgBaseUnitQuantity || 0) / baseUnitMultiplier;
  return { price: roundNumber(price), quantity: roundNumber(quantity) };
}

export async function fetchProductAllPriceDetails(
  products: { id: number; baseUnitMultiplier: number }[],
  date: string,
  priceAnalysis?: IOutletPricingByDate
) {
  const productIds = products.map((p) => p.id);
  const filter = getAllFilterPayload(date, productIds);

  const [today, morning, stockQty, yesterdayStockQty] = await Promise.all([
    get_products_lines_avg_qty(productIds, filter.todayDate, [LineType.PURCHASE]),
    get_products_lines_avg_qty(productIds, filter.morningDate, [LineType.PURCHASE]),
    get_products_lots_sum(productIds, filter.todayDate),
    get_products_lines_sum_qty(productIds, filter.yesterdayDate, [LineType.SALE])
  ]);

  const absentPurchasePriceProductIds: number[] = [];
  const absentSellPriceProductIds: number[] = [];
  const productDetails: Record<number, Partial<IRow>> = {};

  for (const product of products) {
    const { id: productId, baseUnitMultiplier: multiplier } = product;
    productDetails[productId] = productDetails[productId] || {};

    const purchasePriceAnalysis = priceAnalysis?.purchase || [];
    const sellPriceAnalysis = priceAnalysis?.outlet || [];

    // Today Purchase
    const todayPurchase = getIdentifierData(today, productId, LineType.PURCHASE, multiplier);
    productDetails[productId].purchasePrice = todayPurchase.price;

    //Morning Purchase
    const morningPurchase = getIdentifierData(morning, productId, LineType.PURCHASE, multiplier);
    productDetails[productId].morningPurchase = morningPurchase.price;

    // Stock Quantity
    const stock = stockQty.find((item) => item.productId === productId);
    if (stock) {
      productDetails[productId].openingStock = parseInt(stock.sumQtyOpening, 10) / multiplier;
    }

    // Yesterday Stock Quantity
    const yesterdayStock = yesterdayStockQty.find((item) => item.productId === productId);
    if (yesterdayStock) {
      productDetails[productId].yesterdaySoldQty =
        parseInt(yesterdayStock.sumBaseUnitQuantity, 10) / multiplier;
    }

    // Assign YesterdaySellingRate and SellYesterdayPrice
    const productOutletDetails = sellPriceAnalysis.find((p) => p.productId === productId);
    const productPurchaseDetails = purchasePriceAnalysis.find((p) => p.productId === productId);

    if (productPurchaseDetails) {
      productDetails[productId].yesterdaySellingRate = productPurchaseDetails.todayPrice || 0;
    } else {
      absentPurchasePriceProductIds.push(productId);
    }

    if (productOutletDetails) {
      productDetails[productId].sellYesterdayPrice = productOutletDetails.todayPrice || 0;
    } else {
      absentSellPriceProductIds.push(productId);
    }
  }

  const allAbsentProductIds = new Set([
    ...absentPurchasePriceProductIds,
    ...absentSellPriceProductIds
  ]);

  if (allAbsentProductIds.size === 0) {
    return productDetails;
  }

  const absentDetails = await get_products_lines_avg_qty(productIds, filter.yesterdayDate, [
    LineType.SALE,
    LineType.PURCHASE
  ]);

  for (const productId of allAbsentProductIds) {
    const multiplier = products.find((p) => p.id === productId)?.baseUnitMultiplier || 1;
    const isPresentInAbsentPurchasePrice = absentPurchasePriceProductIds.includes(productId);
    const isPresentInAbsentSellPrice = absentSellPriceProductIds.includes(productId);

    if (isPresentInAbsentPurchasePrice) {
      const data = getIdentifierData(absentDetails, productId, LineType.PURCHASE, multiplier);
      productDetails[productId].yesterdaySellingRate = data.price;
    }

    if (isPresentInAbsentSellPrice) {
      const data = getIdentifierData(absentDetails, productId, LineType.SALE, multiplier);
      productDetails[productId].sellYesterdayPrice = data.price;
    }
  }

  return productDetails;
}
