import { useQuery, useMutation } from '@tanstack/react-query';
import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  message,
  PageHeader,
  Divider,
  Checkbox,
  Card,
  Spin
} from 'antd';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AppContent from '../../../components/Common/Content/Content';
import { ICreateACLGroup } from '../../../services/acl/types';
import { create_acl_group_mutation } from '../../../services/acl/mutations';
import { get_all_acl_permissions } from '../../../services/acl/queries';
import { CloseOutlined, MinusCircleOutlined } from '@ant-design/icons';
import GoBackButton from '../../../components/Common/GoBackButton/GoBackButton';
import {
  create_notification_group,
  update_notification_group
} from '../../../services/notifications/mutation';
import { LocationSearch } from '../../../components/Common/LocationSearch/LocationSearch';
import { getUserData } from '../../../utils/auth.utils';
import useDebounce from '../../../hooks/useDebounce';
import {
  get_user_details,
  get_user_list,
  get_user_list_ids
} from '../../../services/users/queries';
import {
  get_all_notification,
  get_notificaton_list_by_locationId
} from '../../../services/notifications/queries';
import CustomSubmitButton from '../../../components/Common/CustomButton/CustomSubmitButton';
import UsersDB from '../../../store/localstorage/UsersDB';
import { INotificationsList } from '../../../services/notifications/types';
import { NotificationEvents } from '../../../services/notifications/enums';

const { Option } = Select;

const CreateNotification = () => {
  const navigate = useNavigate();
  const { id, locationId } = useParams();
  const [form] = Form.useForm();
  const [isloading, setIsloading] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [userSearch, setUserSearch] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [active, setisActive] = useState<boolean>(true);

  const breadcrumbItems = [
    {
      label: 'Notifications',
      link: '/notification'
    },
    {
      label: 'Edit'
      // link: '/notification/new'
    }
  ];

  const { data: notificationList, refetch } = useQuery(['notificationList'], async () => {
    const response = await get_notificaton_list_by_locationId(locationId || 0);
    const findNotification: INotificationsList = response.data.find((curr: any) => curr.id == id)!;
    // console.log('findNotification', findNotification);
    // const userss = await get_user_list_ids(JSON.parse(findNotification.users));

    // console.log('users', userss);
    const listUser = [];
    const userss: any = JSON.parse(findNotification.users);

    for (let index = 0; index < userss.length; index++) {
      let user: any = await UsersDB.getUser(userss[index]);
      if (!user) {
        user = (await get_user_details(userss[index])).user;
        await UsersDB.addUsers([user]);
      }
      listUser.push(user);
    }
    form.setFieldsValue({
      locationId: parseInt(locationId as string),
      isActive: findNotification.isActive,
      users: JSON.parse(findNotification.users),
      events: JSON.parse(findNotification.events)
    });
    console.log(form.getFieldsValue());
    setIsloading(false);
    setUserList(listUser);
    setisActive(findNotification.isActive);
  });

  const updatenotificationMutation = useMutation(update_notification_group);

  const onFinish = async (values: any) => {
    console.log(values);
    values.isActive = active;
    try {
      setIsloading(true);
      updatenotificationMutation.mutateAsync(
        { values, id: parseInt(id as string) },
        {
          onSuccess: async (data: any) => {
            setIsloading(false);
            message.success('Notification group updated successfully');
            navigate('/notification');
          },
          onError: (e: any) => {
            setIsloading(false);
            message.error(`${e.response.data.message}`, 5);
          }
        }
      );
    } catch (errors: any) {
      setIsloading(false);
    }
  };
  // const displayName = (name: any) => {
  //   const value = form.getFieldValue(['permissions', name]);
  //   return <div className="text-center">{value}</div>;
  // };

  useEffect(() => {
    form.setFieldsValue({ events: [], users: [] });
  }, []);

  const options = userSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.name}
    </Option>
  ));

  const DisplayUserName = (name: any) => {
    const selectedUser = form.getFieldValue(['users', name]);
    const user = userList.find((val) => val.id == selectedUser);
    return (
      <>
        <span>{user?.name}</span>
      </>
    );
  };

  useEffect(() => {
    searchUser(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const searchUser = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_user_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any user with that name!');
        setUserSearch([]);
      } else {
        setUserSearch(response.data.results);
        checkUsersAndAdd(response.data.results);
      }
    }
  };

  const checkUsersAndAdd = (users: any[]) => {
    if (userList.length > 0) {
      users = users.filter((value) => {
        const searchProduct = userSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (users.length > 0)
        setUserList((prevValue) => {
          return [...prevValue, ...users];
        });
    } else {
      setUserList(users);
    }
  };

  const checkKey = (key: any) => {
    // console.log('Checkkey', key);
    // console.log(form.getFieldValue(['events']));
    if (form.getFieldValue(['events']).includes(key)) return true;
    return false;
  };

  // console.log('Already Selected Event', alreadySelectedevent);

  return (
    // <div>
    <Spin spinning={isloading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        backgroundWhite={true}
        button={<GoBackButton onClick={() => navigate('/notification')} />}>
        <Card style={{ borderRadius: '10px' }}>
          <PageHeader
            title="Update Notification"
            style={{
              padding: '8px 0px'
            }}
          />
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            disabled={isloading}
            validateTrigger={'onChange'}
            onValuesChange={(_, allFields) => {
              //   setProductDetails(allFields);
            }}
            autoComplete="off">
            <div className="grid grid-cols-4 gap-3">
              <div className="col-span-3">
                <LocationSearch notAll={true} />
              </div>
              <Form.Item name="isActive" label="IsActive?" required={true}>
                <Checkbox
                  checked={active}
                  onChange={(val) => {
                    // console.log('this is called', val.target.checked);
                    setisActive((prev) => !prev);
                    form.setFieldValue(['isActive'], active);
                    // form.setFieldValue(['isActive'], !form.getFieldValue(['isActive']));s
                  }}></Checkbox>
              </Form.Item>
            </div>
            {form.getFieldValue(['events']) && (
              <Form.List name={['events']}>
                {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
                  <>
                    <div className={'grid mb-5'}>
                      <PageHeader
                        subTitle="Select Events"
                        style={{
                          padding: '8px 0px 8px 10px'
                        }}
                      />
                      <div
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
                        style={{
                          borderRadius: '39px',
                          padding: '10px',
                          backgroundColor: 'rgb(59 130 246 / 0.5)'
                        }}>
                        {Object.values(NotificationEvents).map((key, index) => (
                          <div style={{ display: 'flex', gap: '5px' }} key={key}>
                            <Form.Item>
                              <Checkbox
                                checked={checkKey(key) && form.getFieldValue(['events'])}
                                onChange={(value: any) => {
                                  // console.log('this is called');
                                  const checkedValue = value.target.checked;
                                  if (checkedValue) {
                                    add2(`${key}`);
                                  } else {
                                    // remove2(index);
                                    remove2(form.getFieldValue(['events']).indexOf(key));
                                  }
                                }}>
                                {key}
                              </Checkbox>
                            </Form.Item>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </Form.List>
            )}
            <Form.List name={['users']}>
              {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
                <>
                  <div className={'grid mb-5'}>
                    <PageHeader
                      subTitle="Add Users"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                    <Select
                      showSearch
                      placeholder={'search user'}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={(val) => {
                        setSearchValue(val);
                      }}
                      onSelect={(val: number) => {
                        // if (loggedInUser.id == val) {
                        //   message.error('Cannot add self, already added.');
                        //   return;
                        // }
                        const selectedUsers = form.getFieldValue(['users']);
                        // console.log('form values-->', form.getFieldsValue());
                        // console.log('selected Users-->', selectedUsers);
                        const exists = selectedUsers.find((value: any) => value == val);
                        if (exists) {
                          message.error('Already Added');
                          return;
                        }
                        add2(val);
                      }}
                      notFoundContent={null}>
                      {options}
                    </Select>
                    {fields2.length > 0 && (
                      <PageHeader
                        title="Selected Users"
                        style={{
                          padding: '8px 0px 8px 10px'
                        }}
                      />
                    )}
                  </div>
                  <div className="flex flex-wrap gap-3 mb-5">
                    {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                      <div
                        key={key2}
                        className="card"
                        style={{
                          borderRadius: '15px',
                          backgroundColor: '#e5f5fb',
                          padding: '0px 2px 0px 0px'
                        }}>
                        <div className="flex items-center justify-center gap-2" key={key2}>
                          <Form.Item {...restField2} name={[name2]} hidden>
                            <Input type={'text'} disabled />
                          </Form.Item>
                          {DisplayUserName(name2)}
                          {
                            <div className="flex justify-center items-center">
                              {fields2.length > 0 ? (
                                <CloseOutlined
                                  className="dynamic-delete-button flex justify-center items-center"
                                  style={{ color: '#0AA245' }}
                                  onClick={() => remove2(name2)}
                                  // disabled={name2 === loggedInUser.id}
                                  disabled={true}
                                />
                              ) : null}
                            </div>
                          }
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </Form.List>
            <div className="flex justify-end mt-5">
              <Form.Item>
                <CustomSubmitButton text="Update" />
              </Form.Item>
            </div>
          </Form>
        </Card>
      </AppContent>
      {/* </div> */}
    </Spin>
  );
};

export default CreateNotification;
