import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';
import { create_vendors_mutation } from '../../../../services/users/mutations';
import { IUserCreateRequest } from '../../../../services/users/types';
import CreateUI from '../../common/CreateUI';
import { useState } from 'react';
import {
  create_account_type_mutation,
  create_auto_account_type_mutation
} from '../../../../services/accounts/mutations';
import { ICreateAccounts, ICreateAutoAccounts } from '../../../../services/accounts/types';
import { UserAccountType } from '../../../../services/accounts/enums';
import { CapitalizeEachWord } from '@/utils/capitalizeEachWord';

const CreateVendor = () => {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState<boolean>(false);
  // This is needed to get the product details update as its written
  const breadcrumbItems = [
    {
      label: 'Vendors',
      link: '/users/vendors'
    },
    {
      label: 'Create',
      link: '/users/vendors/create'
    }
  ];
  const createUsersMutation = useMutation(create_vendors_mutation, {
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.response.data.message}`, 5);
    },
    onSuccess: (data: any) => {
      if (data) {
        setIsloading(false);
        message.success('User added successfully');
        // navigate('/users/vendors');
      }
    }
  });

  const createAutoAccountsMutation = useMutation(create_auto_account_type_mutation, {
    onSuccess: () => {
      setIsloading(false);
      message.success('Account added successfully');
      navigate('/users');
    },
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.response.data.message}`);
    }
  });

  const onFinish = async (values: IUserCreateRequest) => {
    setIsloading(true);
    values.name = CapitalizeEachWord(values.name);
    values.phone = values.phone.toString();
    await createUsersMutation.mutateAsync(values).then(async (value) => {
      if (value.data.userId) {
        const accountData: ICreateAutoAccounts = {
          userId: value.data.userId,
          type: UserAccountType.VENDOR_PARENT
        };
        await createAutoAccountsMutation.mutateAsync(accountData);
      } else {
        message.error('Error creating account! Please create it manually.');
      }
    });
  };

  return (
    <div>
      <CreateUI
        onFinish={onFinish}
        isloading={isloading}
        title="Vendors Information"
        breadcrumbItems={breadcrumbItems}
      />
    </div>
  );
};

export default CreateVendor;
