import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { Form, InputNumber, PageHeader, DatePicker, Input, Button, Select } from 'antd';

import { CustomDatePresets } from '../utils/datePresets';
import { find_date_preference } from '@/store/localstorage/preferences';
import { WithReportPaginationProps } from '@/components/Common/Report/withReportPagination';

import { ExtendedInitialValues } from '.';

import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import CustomerSearchV2 from '@/components/Common/CustomSearch/Customer';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import RouteSearchV2 from '@/components/Common/CustomSearch/Routes';
import ProductCategorySearchV2 from '@/components/Common/CustomSearch/ProductCategory';

interface Props
  extends Omit<WithReportPaginationProps, 'onPagination' | 'getConstraint' | 'pagination'> {
  isLoading: boolean;
  initialValues: ExtendedInitialValues;
  onSearch: (values: ExtendedInitialValues) => void;
  onDownload: () => void;
}

function FilterForm({ isLoading, form, initialValues, ...handler }: Props) {
  const nepaliType = find_date_preference();

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      disabled={isLoading}
      validateTrigger="onChange"
      initialValues={initialValues}
      onFinish={handler.onSearch}>
      <PageHeader subTitle="Constraints" style={{ padding: '0px 0px' }} />

      <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
        <Form.Item label={'page'} name={['constraints', 'page']} hidden>
          <InputNumber controls={false} />
        </Form.Item>

        <Form.Item label={'size'} name={['constraints', 'size']} hidden>
          <InputNumber controls={false} />
        </Form.Item>

        <CustomerSearchV2 showAll hasParentFormItem={false} name={['constraints', 'customerId']} />
        <RouteSearchV2 showAll hasParentFormItem={false} name={['constraints', 'routeId']} />
        <UserSearchV2 showAll hasParentFormItem={false} name={['constraints', 'createdBy']} />
        <LocationSearchV2 showAll hasParentFormItem={false} name={['constraints', 'locationId']} />

        <Form.Item name={['constraints', 'type']} label="Type">
          <Select placeholder="Select a type!" allowClear>
            <Select.Option value={''} key={'none'}>
              All
            </Select.Option>
            <Select.Option value={'POS'} key={'POS'}>
              POS
            </Select.Option>
            <Select.Option value={'B2B'} key={'B2B'}>
              B2B
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label={'categoryId'} name={['constraints', 'categoryId']} hidden>
          <InputNumber controls={false} />
        </Form.Item>

        <ProductCategorySearchV2
          showAll
          hasParentFormItem={false}
          name={['constraints', 'categoryId']}
        />

        <Form.Item
          label={'Pick a date'}
          name={['constraints', 'dateCustom']}
          rules={[{ required: true, message: 'Please select date!' }]}
          hidden={nepaliType}>
          <DatePicker.RangePicker
            showTime
            ranges={CustomDatePresets}
            disabledDate={handler.onDisabledDate}
            onChange={handler.onChange}
            format={'YYYY-MM-DD HH:mm'}
            allowClear={false}
          />
        </Form.Item>

        <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
          <Input />
        </Form.Item>

        <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
          <Input />
        </Form.Item>

        <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
          <NepaliDatePicker
            onChange={(val) => handler.onChangeNepali(val, true)}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
          />
        </Form.Item>

        <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
          <NepaliDatePicker
            onChange={(val) => handler.onChangeNepali(val, false)}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
          />
        </Form.Item>

        {/* // Buttons for submitting and downloading the form */}
        <div className="flex justify-start mt-7 gap-1">
          <Form.Item>
            <Button className="!rounded-md" type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              className="!rounded-md"
              style={{
                color: '#1890ff',
                border: '1px solid #1890ff'
              }}
              onClick={handler.onDownload}>
              Download
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}

export default FilterForm;
