import GenericTable from '@/components/Common/CustomizeTable';
import { ICustomer } from '@/services/users/types';
import { ColumnsType } from 'antd/lib/table';

interface Props {
  isLoading: boolean;
  data: { total: number; results: ICustomer[] };
  pagination: { page: number; size: number };
  onPagination: (page?: number, size?: number, isSize?: boolean) => Promise<void>;
}

function CustomerMigrateTable({ data, pagination, isLoading, onPagination }: Props) {
  const columns: ColumnsType<ICustomer> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 3,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Name',
      width: 10,
      render: (_, record) => record.user.name
    },
    {
      title: 'Email',
      width: 7,
      render: (_, record) => record.user?.email
    },
    {
      title: 'Phone',
      width: 7,
      render: (_, record) => record.user?.phone
    }
  ];

  return (
    <GenericTable
      columns={columns}
      data={data.results}
      isLoading={isLoading}
      hideDefaultPagination
      scroll={{ x: 1000, y: '75vh' }}
      pagination={{ page: pagination.page, total: data.total, size: pagination.size, onPagination }}
    />
  );
}

export default CustomerMigrateTable;
