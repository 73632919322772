import { Button, Collapse, Form, FormInstance, message, PageHeader, Spin } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import AppContent from '@/components/Common/Content/Content';

import { download_report, get_report } from '@/services/report/queries';
import moment from 'moment';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { getInt } from '../pending-payment-sell-report/report';
import { check_date_cap, check_export_report_date_cap } from '@/utils/common';
import { showReportConditionalDownloadMessage } from '@/utils/reportConditionalDownloadMessage';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportAllData from '@/components/Common/ExportAll';
import { excelExportColumns, getUpdatedData, tableExportColumns } from './column.export';
import ExportCurrent from '@/components/Common/ExportCurrent';

interface ReportData {
  count: any;
  data: any;
}

interface State {
  id: number;
  name: string;
}
interface ReportProps {
  state: State;
  breadcrumbItems: any[];
  form: FormInstance<any>;
  // locationList: any[];

  columnsData?: any[];
  footerData?: { sell?: boolean; purchase?: boolean };
  toDownload?: boolean;
  toSort: any;
}

const CustomReport: React.FC<ReportProps> = ({
  state,
  breadcrumbItems,
  form,
  // locationList,
  columnsData,
  footerData = {
    sell: false,
    purchase: false
  },
  children,
  toDownload,
  toSort
}) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [data, setData] = useState<ReportData>({ count: 0, data: [] });
  const [footer, setFooterData] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState(100);
  const [isEmpty, setIsEmpty] = useState(false);

  const [isloading, setIsloading] = useState(false);
  const [fullExportURL, setFullExportURL] = useState<any>(null);

  useEffect(() => {
    if (!columnsData) createColumns();
  }, [data]);
  const createColumns = () => {
    //auto generate columns
    const newColumns: ColumnsType<any> = [];
    for (const key in data.data[0]) {
      newColumns.push({
        title: key,
        key,
        render: (record: any) => {
          return <div className="forless">{record[key]}</div>;
        }
      });
    }
    setColumns(newColumns);
  };
  const onSearch = async (values: any, storePage = false) => {
    // console.log('values-->', values);
    setIsloading(true);
    if (check_date_cap(values.constraints.startDate, values.constraints.endDate)) {
      await downloadPdf();
      setIsloading(false);
      return;
    }
    if (!storePage) {
      values.constraints['page'] = 1;
      values.constraints['size'] = size;
      setPage(1);
    }
    //remove custom date
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    const response = await get_report(values.constraints, state.id);
    response.data = await calculateTotal(response.data);
    if (response.data.data.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }

    const payload = values.constraints as any;
    payload.size = response.data.count;
    setFullExportURL(payload);

    setData(response.data);
    setIsloading(false);
  };

  const calculateTotal = async (data: any) => {
    let total = 0;

    for (let index = 0; index < data.data.length; index++) {
      total += getInt(data.data[index].total_amount);
    }
    // console.log('data', data.data);

    setFooterData(total);
    return data;
  };
  const addPage = async (value: number, sizeVal = 0) => {
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();
      if (sizeVal !== 0) {
        allValues.constraints.page = 1;
        allValues.constraints.size = sizeVal;
        setPage(1);
        setSize(sizeVal);
      } else {
        allValues.constraints.page = value;
        allValues.constraints.size = size;
        setPage(value);
      }
      onSearch(allValues, true);
    } catch (e: any) {
      console.log('validate error', e);
    }
  };

  const downloadPdf = async () => {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsloading(true);
    const values = form.getFieldsValue();
    await form.validateFields();
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    try {
      if (check_export_report_date_cap(values.constraints.startDate, values.constraints.endDate)) {
        // const response =
        values.constraints.customReportQueueName = 'Stock Adjustment Report';
        message.info({
          key: 'downloading',
          content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
        });
        await download_report(values.constraints, state.id);
        setIsloading(false);
        // window.open(response.data, '_blank');
      }
    } catch (err: any) {
      setIsloading(false);
      message.error(err.message);
    }
  };

  async function onSearchAll() {
    try {
      setIsloading(true);
      if (!fullExportURL) {
        message.error('No URL specified. Please search first.');
        return;
      }
      const response = await get_report(fullExportURL, state.id);
      response.data = await calculateTotal(response.data);

      return getUpdatedData(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  }

  const updatedData = getUpdatedData(data.data);

  return (
    <div>
      <Spin spinning={isloading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <Collapse>
            <CollapsePanel header="Filters" key="1">
              <Form
                form={form}
                onFinish={onSearch}
                layout="vertical"
                validateTrigger={'onChange'}
                autoComplete="off"
                disabled={isloading}>
                {/* <PageHeader
                  title={state?.name}
                  style={{
                    padding: '5px 0px'
                  }}
                /> */}
                {React.Children.count(children) > 1 && (
                  <>
                    <PageHeader
                      subTitle="Constraints"
                      style={{
                        padding: '0px 0px'
                      }}
                    />
                  </>
                )}
                <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 ">
                  {children}
                  <div className="flex justify-start mt-7 gap-2">
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
                        Search
                      </Button>
                    </Form.Item>
                    {toDownload ? (
                      <div>
                        <Form.Item>
                          <Button
                            className="secondary-button"
                            style={{ borderRadius: '6px' }}
                            onClick={downloadPdf}>
                            Download
                          </Button>
                        </Form.Item>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Form>
            </CollapsePanel>
          </Collapse>
          <PageHeader
            subTitle="Table"
            style={{
              padding: '8px 0px'
            }}
          />
          <CustomizeTable
            tableName="stock-adjustment-report"
            customScroll={{ x: 1400, y: 500 }}
            notshowPagination
            toSort={toSort}
            columns={columnsData ? columnsData : columns}
            data={data.data}
            footer={() => `Total Amount: ${nepaliNumberFormatter(footer)}`}
            tableSummary={
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6} className="text-right mr-2">
                    {nepaliNumberFormatter(footer)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            }
            buttons={
              <>
                <ExportAllData
                  title="Stock Adjustment (All)"
                  columns={tableExportColumns}
                  excelColumns={excelExportColumns}
                  getInfo={onSearchAll}
                />
                <ExportCurrent
                  data={{ total: data.count, results: updatedData }}
                  columns={tableExportColumns}
                  excelColumns={excelExportColumns}
                  title="Stock Adjustment"
                />
              </>
            }
            paginationDatas={{
              page,
              total: data.count,
              size,
              onPagination: (pageNo, currentSize) => {
                currentSize == size ? addPage(pageNo) : addPage(pageNo, currentSize);
              }
            }}
          />
        </AppContent>
      </Spin>
    </div>
  );
};

export default CustomReport;

// const getFooterData = async (values: any) => {
//   if (footerData.purchase) {
//     const responsePurchase = await get_report(values.constraints, 7);
//     setFooterData((prev) => {
//       return {
//         ...prev,
//         totalPurchase: responsePurchase.data.data[0][`sum(total_amount)`]
//       };
//     });
//   }
//   if (footerData.sell) {
//     const responseSell = await get_report(values.constraints, 6);
//     setFooterData((prev) => {
//       return {
//         ...prev,
//         totalSell: responseSell.data.data[0].total_amount
//       };
//     });
//   }
// };
// const loadFooter = () => {
//   if (!footerData) {
//     return '';
//   }
//   return (
//     <>
//       {footerData.sell || footerData.purchase ? (
//         <div
//           style={{
//             backgroundColor: 'grey',
//             color: 'white',
//             height: '50px',
//             display: 'flex',
//             justifyContent: 'space-around',
//             alignItems: 'center',
//             fontSize: '25px',
//             borderRadius: '9px'
//           }}>
//           {footerData.sell && <div>Total Sell: {footer.totalSell}</div>}
//           {footerData.purchase && <div>Total Purchase: {footer.totalPurchase}</div>}
//         </div>
//       ) : (
//         <></>
//       )}
//     </>
//   );
// };
