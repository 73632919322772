import LocalStore from '.';
export default class CategoryDB extends LocalStore {
  /*Category Functions*/
  /*new data is added, existing data is updated*/
  static async searchCategoryByName(name: string, limit = 10) {
    if (!CategoryDB.db) await CategoryDB.init();
    return new Promise((resolve: (value: any[]) => void, reject) => {
      const filteredCategory: any[] = [];
      const transaction = CategoryDB.db.transaction('Category', 'readonly');
      const objectStore = transaction.objectStore('Category');
      const lowercaseString = name.toLowerCase();
      const cursorRequest = objectStore.index('CategorySecondIndex').openCursor();
      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          const item = cursor.value;

          if (item?.lowercaseName && item?.lowercaseName.includes(lowercaseString))
            filteredCategory.push(cursor.value);

          if (filteredCategory.length < limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(filteredCategory);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async searchCategoryByNameAndBanner(name: string, bannerId: number, limit = 10) {
    if (!CategoryDB.db) await CategoryDB.init();
    return new Promise((resolve: (value: any[]) => void, reject) => {
      const filteredCategory: any[] = [];
      const transaction = CategoryDB.db.transaction('Category', 'readonly');
      const objectStore = transaction.objectStore('Category');
      const lowercaseString = name.toLowerCase();
      const cursorRequest = objectStore.index('CategorySecondIndex').openCursor();

      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor && (cursor.value.bannerId === bannerId || cursor.value.bannerId === null)) {
          const item = cursor.value;

          if (item.name && item.name.includes(lowercaseString)) filteredCategory.push(cursor.value);

          if (filteredCategory.length < limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(filteredCategory);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async addCategory(categoryData: any[]) {
    if (!CategoryDB.db) await CategoryDB.init();
    return new Promise((resolve, reject) => {
      const transaction = CategoryDB.db.transaction('Category', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Category');
      categoryData.forEach(async (category) => {
        const checkKey = objectStore.count(category.id);
        checkKey.onsuccess = async (event: any) => {
          category.lowercaseName = category.name.toLowerCase();
          if (checkKey.result == 0) {
            const request = objectStore.add(category);
            request.onerror = (event: any) => {
              reject(event.target.error);
            };
          } else {
            await CategoryDB.updateCategory(category);
          }
        };
      });
    });
  }

  static async getAllCategory() {
    if (!CategoryDB.db) await CategoryDB.init();
    return new Promise((resolve, reject) => {
      const transaction = CategoryDB.db.transaction('Category', 'readonly');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Category');
      const request = objectStore.getAll();
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async removeAllCategory() {
    if (!CategoryDB.db) await CategoryDB.init();

    return new Promise((resolve, reject) => {
      const transaction = CategoryDB.db.transaction(['Category'], 'readwrite');
      transaction.oncomplete = (event) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Category');
      const objectStoreRequest = objectStore.clear();
      objectStoreRequest.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      objectStoreRequest.onerror = (event: any) => {
        reject(event.target.result);
      };
    });
  }
  static async getCategory(id: number | string) {
    if (!CategoryDB.db) await CategoryDB.init();

    return new Promise((resolve, reject) => {
      const transaction = CategoryDB.db.transaction('Category', 'readonly');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Category');
      const request = objectStore.get(id);
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
        // if (event.target.result) resolve(event.target.result);
        // reject('Error, could not find id.');
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async updateCategory(category: any) {
    if (!CategoryDB.db) await CategoryDB.init();

    return new Promise((resolve, reject) => {
      const transaction = CategoryDB.db.transaction('Category', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Category');
      const request = objectStore.get(category.id);
      request.onsuccess = (event: any) => {
        if (event.target.result) {
          category.lowercaseName = category.name.toLowerCase();
          const requestUpdate = objectStore.put(category);
          requestUpdate.onerror = (event: any) => {
            reject(event.target.error);
          };
          requestUpdate.onsuccess = (event) => {
            resolve('updated data');
          };
        } else reject('Error, could not find id.');
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }
  static async deleteCategory(id: number) {
    if (!CategoryDB.db) await CategoryDB.init();
    if (await CategoryDB.getCategory(id)) {
      return new Promise((resolve, reject) => {
        const transaction = CategoryDB.db.transaction('Category', 'readwrite');
        transaction.oncomplete = (event: any) => {
          resolve('success');
        };
        transaction.onerror = (event: any) => {
          reject(event.target.error);
        };
        const objectStore = transaction.objectStore('Category');
        const request = objectStore.delete(id);
        request.onsuccess = (event: any) => {
          resolve('deleted successfuly.');
          // if (event.target.result) resolve(event.target.result);
          // reject('Error, could not find id.');
        };
        request.onerror = (event: any) => {
          reject(event.target.error);
        };
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve('id does not exist');
      });
    }
  }
}
