import moment from 'moment';
import { useEffect, useState } from 'react';

import { DatePicker } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { RangePickerProps } from 'antd/lib/date-picker';
import { FinancialDatePresets } from '@/pages/sqlsource/report/utils/datePresets';

type momentType = [moment.Moment | null, moment.Moment | null];

interface Props {
  value: momentType;
  hasHours?: boolean;
  onChange: RangePickerProps['onChange'];
  stateManageExternal?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

function DateRangePicker({
  value,
  hasHours = false,
  onChange,
  stateManageExternal,
  open,
  setOpen
}: Props) {
  const [searchParams] = useSearchParams();
  const financialStartDate = searchParams.get('fsd');
  const financialEndDate = searchParams.get('fed');

  const financialStartMoment = moment(financialStartDate);
  const financialEndMoment = moment(financialEndDate);

  const [currentFinancialPreset, setCurrentFinancialPreset] =
    useState<Record<string, [moment.Moment, moment.Moment]>>();

  const today = moment();
  const isCurrentFinancialYear =
    financialStartDate && financialEndDate
      ? today.isBetween(financialStartMoment, financialEndMoment)
      : false;

  const [initialValue, setInitialValue] = useState<momentType>([null, null]);

  // Set initial value when value prop changes
  useEffect(() => {
    if (value) {
      setInitialValue(value);
    }
  }, [value]);

  useEffect(() => {
    const filteredPresets = Object.entries(FinancialDatePresets)
      .filter(([key, [presetStart, presetEnd]]) => {
        const presetStartDate = moment(presetStart);
        const presetEndDate = moment(presetEnd);

        const isValidPresetStartDate =
          presetStartDate.isSameOrAfter(financialStartMoment) &&
          presetEndDate.isSameOrBefore(financialEndMoment);

        return isValidPresetStartDate;
      })
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {} as Record<string, [moment.Moment, moment.Moment]>);

    if (isCurrentFinancialYear) {
      // Apppend financial year presets to the filtered presets
      filteredPresets['Current financial year'] = [financialStartMoment, financialEndMoment];
    }

    setCurrentFinancialPreset(filteredPresets);
  }, [financialStartDate, financialEndDate]);

  const onDateChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    setInitialValue(dates as momentType);
    onChange?.(dates, dateStrings);
  };

  const format = hasHours ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';

  // Disable dates outside the financial year range
  const onDisabledDate = (current: moment.Moment | null) => {
    const hasStartAndEndDates = financialStartDate && financialEndDate;

    if (!hasStartAndEndDates) return false;

    const startDate = financialStartMoment.startOf('day');
    const endDate = financialEndMoment.endOf('day');

    // Disable dates outside the financial year range
    return current ? current.isBefore(startDate) || current.isAfter(endDate) : false;
  };

  return (
    <DatePicker.RangePicker
      key={`${financialStartDate}-${financialEndDate}`}
      value={initialValue}
      showTime={hasHours}
      className="w-full"
      disabledDate={onDisabledDate}
      onChange={onDateChange}
      ranges={isCurrentFinancialYear ? currentFinancialPreset : undefined}
      format={format}
      allowClear={false}
      {...(stateManageExternal && setOpen && { open, onOpenChange: (open) => setOpen(open) })}
    />
  );
}

export default DateRangePicker;
