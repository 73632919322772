import { find_to_use_NPR } from '@/store/localstorage/preferences';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { DEFAULT_DATE_FORMAT } from '@/constants';

export const tableExportColumns = [
  {
    label: 'ID',
    dataIndex: 'id',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Financial Reference',
    dataIndex: 'financial_reference',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Customer',
    dataIndex: 'customer',
    render: (ref: string) => {
      return <div className="text-center">{ref}</div>;
    }
  },
  {
    label: 'Amount',
    dataIndex: 'amount',
    render: (text: number) => {
      return <div className="text-right">{nepaliNumberFormatter(text)}</div>;
    }
  },

  {
    label: 'VAT',
    dataIndex: 'total_vat',
    render: (text: number) => {
      return <div className="text-right">{nepaliNumberFormatter(text)}</div>;
    }
  },
  {
    label: 'Shipping',
    dataIndex: 'shipping',
    render: (text: number) => {
      return <div className="text-right">{nepaliNumberFormatter(text)}</div>;
    }
  },
  {
    label: 'Shipping Tax',
    dataIndex: 'shipping_tax',
    render: (text: number) => {
      return <div className="text-right">{nepaliNumberFormatter(text)}</div>;
    }
  },
  {
    label: 'Total',
    dataIndex: 'total_amount',
    render: (text: number) => {
      return <div className="text-right">{nepaliNumberFormatter(text)}</div>;
    }
  },
  {
    label: 'Paid',
    dataIndex: 'amount_paid',
    render: (text: number) => {
      return <div className="text-right">{nepaliNumberFormatter(text)}</div>;
    }
  },
  {
    label: 'Return',
    dataIndex: 'amount_returned',
    render: (text: number) => {
      return <div className="text-right">{nepaliNumberFormatter(text)}</div>;
    }
  },
  {
    label: 'Remaining',
    dataIndex: 'amount_remainng',
    render: (text: number) => {
      return <div className="text-right">{nepaliNumberFormatter(text)}</div>;
    }
  },
  {
    label: 'Net Sell',
    dataIndex: 'netsell',
    render: (text: number) => {
      return <div className="text-right">{nepaliNumberFormatter(text)}</div>;
    }
  },
  {
    label: 'Profit',
    dataIndex: 'profit',
    render: (profit: number) => {
      return (
        <div
          style={{
            color: profit > 0 ? 'green' : 'red'
          }}>
          <div className="text-right ">{nepaliNumberFormatter(profit)}</div>
        </div>
      );
    }
  },

  {
    label: 'Date',
    dataIndex: 'created_at',
    render: (text: string) => {
      return <div>{convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT)}</div>;
    }
  },
  {
    label: 'Crates Sent',
    dataIndex: 'crates_sent',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Crates Received',
    dataIndex: 'crates_received',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Created By',
    dataIndex: 'addedBy',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Location',
    dataIndex: 'location_name',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Note',
    dataIndex: 'note',
    render: (note: string) => {
      return <div>{note ? `${note}`.slice(0, 10) + '...' : ''}</div>;
    }
  },
  {
    label: 'Print Count',
    dataIndex: 'print_count',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));
