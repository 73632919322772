import { CompareType } from '@/services/dashboard/v3/enums';
import { DatePicker, Form, FormInstance, PageHeader, message } from 'antd';

interface Props {
  form: FormInstance;
  compareType: CompareType;
}

function SingleDateCompareUI({ form, compareType }: Props) {
  function handleSingleDateChange(date: moment.Moment | null, type: 'firstDate' | 'secondDate') {
    if (!date) return;
    form.setFieldsValue({ compareDateRange: undefined, singleCustomRange: undefined });

    const anotherDateType = type === 'firstDate' ? 'secondDate' : 'firstDate';
    const anotherDate = form.getFieldValue(anotherDateType) as moment.Moment | null;

    if (!anotherDate) return;

    const firstDateString = date.format('YYYY-MM-DD');
    const secondDateString = anotherDate.format('YYYY-MM-DD');

    // Check if date is same
    if (firstDateString === secondDateString) {
      message.error('Please select different dates to compare');
      setTimeout(() => form.setFieldsValue({ [type]: undefined }), 0);
    }
  }

  return (
    <div>
      <PageHeader className="flex-1" subTitle="Select Compare Date" style={{ padding: '4px 0' }} />

      <Form.Item
        name="firstDate"
        label="First Date"
        rules={[
          {
            required: compareType === CompareType.SINGLE_COMPARE,
            message: 'Please select first date'
          }
        ]}>
        <DatePicker
          data-no-dnd="true"
          className="w-full"
          format={'YYYY-MM-DD'}
          onChange={(date) => handleSingleDateChange(date, 'firstDate')}
        />
      </Form.Item>

      <Form.Item
        name="secondDate"
        label="Second Date"
        rules={[
          {
            required: compareType === CompareType.SINGLE_COMPARE,
            message: 'Please select second date'
          }
        ]}>
        <DatePicker
          data-no-dnd="true"
          className="w-full"
          format={'YYYY-MM-DD'}
          onChange={(date) => handleSingleDateChange(date, 'secondDate')}
        />
      </Form.Item>
    </div>
  );
}

export default SingleDateCompareUI;
