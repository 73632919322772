import moment from 'moment';
import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { Link, useNavigate } from 'react-router-dom';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { Form, Input, Menu, message, Modal, Select, TableProps, Tag } from 'antd';

import { checkAccess } from '@/routes/acl';
import { getUserData } from '@/utils/auth.utils';
import TableFilter from '@/components/FliterTable';
import { optionalBooleanSorter } from '@/utils/sorter.utils';
import AppContent from '@/components/Common/Content/Content';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import ActionDropdown from '@/components/Common/Dropdownactions';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';

import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';

import CustomDeleteIcon from '@/components/Common/CustomIcons/CustomDeleteIcon';
import { get_expense_category_list_revamp } from '@/services/expense/expense-category/queries';
import { update_expense_category_mutation } from '@/services/expense/expense-category/mutations';

import {
  IExpenseCategoryResultRevamp,
  IExpenseCategoryRevamp
} from '@/services/expense/expense-category/types';
import { ConvertObjectToURL } from '@/utils/converturl';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const { Option } = Select;

const ExpenseCategoryList: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  /*   const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<IExpenseCategoryRevamp | null>(null); */

  const [expenseCategories, setExpenseCategories] = useState<IExpenseCategoryResultRevamp>({
    total: 0,
    results: []
  });

  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);

  const userData = getUserData();
  const isSuperUser = userData.perms.includes('SUPER_ADMIN');

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsloading(true);
    const response = await get_expense_category_list_revamp(filter);
    setExpenseCategories(response);
    setIsloading(false);
  };

  const updateExpenseMutation = useMutation(update_expense_category_mutation, {
    onSuccess: (data, payload) => {
      const id = payload.id;
      message.success('Table Deleted Successfully.');

      // Remove the deleted item from the list
      setExpenseCategories((prev) => ({
        ...prev,
        results: prev.results.filter((item) => item.id !== id)
      }));
    }
  });

  /*   async function handleDelete() {
    setShowModal(false);

    if (!selectedRecord) {
      return message.error('No Record Selected');
    }

    const payload = { id: selectedRecord.id, name: selectedRecord.name };
    await updateExpenseMutation.mutate(payload);
  } */

  const columns: ColumnsType<IExpenseCategoryRevamp> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 2,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (txt, record, index) => <TableCell>{(page - 1) * size + (index + 1)}</TableCell>
    },
    {
      title: 'Name',
      key: 'name',
      width: 8,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record) => <Link to={`/expense-category/${record.id}`}>{record.name}</Link>
    },
    {
      title: 'Table Name',
      key: 'tableName',
      dataIndex: 'tableName',
      width: 15,
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Status',
      key: 'isArchived',
      width: 5,
      sorter: (a, b) => optionalBooleanSorter(a.isArchived, b.isArchived),
      sortOrder: sortedInfo.columnKey === 'isArchived' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <Tag color={record.isArchived ? 'red' : 'green'}>
            {record.isArchived ? 'Hidden' : 'Active'}
          </Tag>
        );
      }
    },
    {
      title: 'Created At',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 5,
      render: (date) => <TableCell>{moment(date).format(DEFAULT_DATE_FORMAT)}</TableCell>
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 3,
      fixed: 'right',
      render: (record) => {
        const menuItems: {
          key: string;
          label: JSX.Element;
          onClick?: undefined;
        }[] = [];

        if (isSuperUser && checkAccess('UPDATE_EXPENSE_CATEGORY')) {
          menuItems.push({
            key: '1',
            label: <CustomUpdateIcon link={`/expense-category/edit/${record.id}`} />
          });
        }
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '2',
            label: <DiscussionChatModal slug="expense-category" id={record.id} />
          });
        }
        menuItems.push({
          key: '4',
          label: (
            <CustomViewIcon title="View Report" link={`/expense-category-report/${record.id}`} />
          )
        });

        /*         if (isSuperUser && checkAccess('UPDATE_EXPENSE_CATEGORY')) {
          menuItems.push({
            key: '3',
            label: (
              <CustomDeleteIcon
                onClick={() => {
                  setSelectedRecord(record);
                  setShowModal(true);
                }}
              />
            )
          });
        } */

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Expense Category',
      link: '/expense-category'
    }
  ];
  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }

    const url = ConvertObjectToURL(values);
    getInfo(url);
  };
  return (
    <div>
      {/* <Modal
        title="Confirmation"
        visible={showModal}
        onOk={handleDelete}
        onCancel={() => setShowModal(false)}>
        <div>Are you sure you want to delete this table?</div>
      </Modal> */}
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            <div>
              <TableFilter
                defaultValues={{
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  value: '',
                  isHidden: '',
                  skip: 0,
                  count: 100,
                  archiveStatus: 'ACTIVE'
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                styleforbuttons={'flex justify-end items-center'}
                buttons={
                  <>
                    {isSuperUser && checkAccess('CREATE_EXPENSE_CATEGORY') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate('/expense-category/new')}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/expense-category/new"
                        />
                      </div>
                    )}
                  </>
                }>
                <Form.Item name="archiveStatus " label="Status">
                  <Select defaultValue={'ALL'} placeholder="Select Status" allowClear>
                    <Option value={'ALL'}>All</Option>
                    <Option value={'ACTIVE'}>Active</Option>
                    <Option value={'INACTIVE'}>Inactive</Option>
                  </Select>
                </Form.Item>

                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          columns={columns}
          data={expenseCategories.results}
          usersLoading={isLoading}
          notshowPagination={true}
          customScroll={{ x: 600, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: expenseCategories.total,
            size: size,
            onPagination
          }}
          tableName={'expense-category-list'}
          toSort={handleChange}
        />
      </AppContent>
    </div>
  );
};

export default ExpenseCategoryList;
