import { Spin } from 'antd';
import { useState } from 'react';

import ErrorWidget from '../ErrorWidget';
import BarChart from '../../chart/BarChart';
import PieChart from '../../chart/PieChart';
import LineChart from '../../chart/LineChart';
import WidgetFilterNoContext from '../filter/WidgetFilterNoContext';
import {
  IWidgetConfiguration,
  IGroupedData,
  IWidgetGetConfig
} from '@/services/dashboard/v3/types';
import getError from '@/utils/getError';
import { useDashboardCustomize } from '@/contexts/dashboard-customize.context';

interface Props {
  data?: IGroupedData;
  fields: string[];
  reports: { id: number; name: string }[];
  widget: IWidgetConfiguration;
  getConfig: (props: IWidgetGetConfig) => Promise<void>;
  xAxisLabel?: string;
  handleFullScreen?: () => void;
}

function WidgetCustomizeWrapper({
  widget,
  fields,
  data,
  getConfig,
  reports,
  xAxisLabel,
  handleFullScreen
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [memoConfig, setMemoConfig] = useState<string | undefined>();

  const [errorMsg, setErrorMsg] = useState('');

  const { setEditingWidgets, editingWidgets, defaultAPIConfig } = useDashboardCustomize();

  function handleFilter() {
    setOpenFilter(true);
  }

  async function getConfigData(props: IWidgetGetConfig) {
    try {
      const reportIds = reports.map((r) => r.id);

      if (props.type === 'compare') {
        const currentReportFields = props.formFields[reportIds[0]];
        const selectedConfig = props.selectedDate;

        // Take date to see if there is any change
        const config = JSON.stringify({
          selectedConfig: props.selectedDate,
          formFields: props.formFields,
          reportIds,
          locationId: defaultAPIConfig.locationId
        });

        // If config is same, do not make API call
        if (memoConfig === config) {
          return;
        }

        setIsLoading(true);
        const widgetIndex = editingWidgets.findIndex((w) => w.id === widget.id);
        if (widgetIndex !== -1) {
          const newWidgets = [...editingWidgets];
          const currentWidget = newWidgets[widgetIndex];

          currentWidget.formFields = props.formFields;
          if (currentWidget.comparable) {
            currentWidget.comparable.selectedDate = props.selectedDate;
          }

          setEditingWidgets(newWidgets);
        }

        // If config key for compare is range, append extra formField key to currentReportFields
        if (selectedConfig.key !== 'single') {
          // Ensure unique key are added
          const hasKey = currentReportFields?.find((f) => f.key === selectedConfig.formField);

          // Make sure, only one key is added
          if (!hasKey) {
            currentReportFields?.push({
              key: selectedConfig.formField,
              value: String(selectedConfig.formFieldValue)
            });
          }
        }

        // Fetch the data
        await getConfig({ ...props, locationId: defaultAPIConfig.locationId });
        setMemoConfig(config);
        return;
      }

      const config = JSON.stringify({ date: props.date, formFields: props.formFields, reportIds });
      if (memoConfig === config) {
        return;
      }

      setIsLoading(true);
      const widgetIndex = editingWidgets.findIndex((w) => w.id === widget.id);
      if (widgetIndex !== -1) {
        const newWidgets = [...editingWidgets];

        newWidgets[widgetIndex].formFields = props.formFields;
        newWidgets[widgetIndex].selectedDate = { value: props.date, type: props.dateType };
        setEditingWidgets(newWidgets);
      }

      await getConfig({ ...props, locationId: defaultAPIConfig.locationId });
      setMemoConfig(config);
    } catch (error: unknown) {
      console.error(error);
      setErrorMsg(getError(error));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <WidgetFilterNoContext
        widget={widget}
        visible={openFilter}
        getConfig={getConfigData}
        setVisible={setOpenFilter}
        reports={reports}
      />
      <Spin spinning={isLoading}>
        <div className="relative customize-chart-box w-full">
          {data && (
            <>
              {widget.chartType === 'line' && (
                <LineChart
                  data={data}
                  fields={fields}
                  title={widget.title}
                  xAxisLabel={xAxisLabel}
                  enlargeChart={widget.enlargeChart}
                  allowZoom={widget.showZoom}
                  allowFilter={widget.showFilter}
                  smooth={widget.isSmooth}
                  isGradient={widget.isGradient ?? false}
                  handleFilterOpen={handleFilter}
                  handleFullScreen={handleFullScreen}
                  maxTicksLimit={widget.maxTicksLimit}
                />
              )}

              {widget.chartType === 'bar' && (
                <BarChart
                  data={data}
                  fields={fields}
                  title={widget.title}
                  xAxisLabel={xAxisLabel}
                  allowZoom={widget.showZoom}
                  enlargeChart={widget.enlargeChart}
                  allowFilter={widget.showFilter}
                  isGradient={widget.isGradient ?? false}
                  borderRadius={widget.borderRadius}
                  handleFilterOpen={handleFilter}
                  handleFullScreen={handleFullScreen}
                  maxTicksLimit={widget.maxTicksLimit}
                />
              )}

              {widget.chartType === 'pie' && (
                <PieChart
                  data={data}
                  fields={fields}
                  title={widget.title}
                  handleFilterOpen={handleFilter}
                  enlargeChart={widget.enlargeChart}
                  allowFilter={widget.showFilter}
                  handleFullScreen={handleFullScreen}
                  dataLimit={widget.pieChartLimit}
                />
              )}
            </>
          )}

          {errorMsg && !isLoading && <ErrorWidget message={errorMsg} />}
        </div>
      </Spin>
    </>
  );
}

export default WidgetCustomizeWrapper;
