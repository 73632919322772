import { AxiosResponse } from 'axios';
import {
  IAccountHistoryCreditDebitSum,
  IAccountHistoryResponse,
  IAccountLatestHistory,
  IAccountLatestHistoryResponse,
  IAccountOpening,
  IAccountOpeningResponse,
  IAccountRuleListResponse,
  IAccountTypeResponse,
  IAccountTypeResponseData,
  IAccountRequest,
  ICreateAccountsRuleResponse,
  IJournalLinesListResponse,
  IJournalListResponse,
  IJournalListResponseData,
  IJournalViewResponse,
  ILedgerResponse,
  IPnLResponse,
  ISwitchAccountResponse,
  ITrialBalanceResponse,
  IAccountTransactionResponse,
  IAccountReceivePayable,
  IAccountReceivePayableV2,
  ICustomViewAccountResponse
} from './types';
import http from '../../utils/http.utils';
import {
  IAllResponse,
  getDataForIds,
  getDataOnlyForIds,
  getDataForIdsByDate,
  getDataForIdsByDates
} from '../../utils/queries.utils';
import { AccountType } from './enums';

export const get_account_list = async (skip = 0, count = 0, value = '', locationId?: number) => {
  const response: AxiosResponse<IAccountTypeResponse, any> = await http.get('account', {
    skip,
    count,
    value,
    locationId
  });
  return response.data;
};

export const get_account_list_ids = async (ids: number[], count = 10) => {
  if (count > 50) {
    return await getDataForIds<IAccountTypeResponse>(ids, 'account/?');
  }

  const response: AxiosResponse<IAccountTypeResponse> = await http.get('account', { ids, count });
  return response.data;
};

export const get_account_list_by_location = async (skip = 0, count = 0, locationId?: number) => {
  const response: AxiosResponse<IAccountTypeResponse> = await http.get('account', {
    skip,
    count,
    locationId
  });
  return response.data;
};

export const get_account_latest_history = async (startDate: string, ids: number[]) => {
  return await getDataForIdsByDate<IAccountLatestHistory>(ids, startDate, 'account/latest-history');
};

export const get_account_latest_history_by_id = async (ids: number[]) => {
  const response: AxiosResponse<IAccountLatestHistoryResponse, any> = await http.get(
    'account/latest-history',
    { ids, count: 1 }
  );
  return response.data;
};

export const get_account_list_by_location_type = async (
  skip = 0,
  count = 0,
  value = '',
  isLocationNull?: string
) => {
  const response: AxiosResponse<IAccountTypeResponse, any> = await http.get('account', {
    skip,
    count,
    value,
    isLocationNull
  });
  return response.data;
};

export const get_account_list_custom = async (filter: string) => {
  const response: AxiosResponse<ICustomViewAccountResponse> = await http.get(
    'account/custom-view?' + filter
  );
  return response.data;
};

export const get_account_details_by_userid_type = async (userId: number, type: AccountType) => {
  const response: AxiosResponse<IAccountTypeResponse> = await http.get('account', {
    referenceId: userId,
    type,
    status: 'ALL'
  });
  const results = response.data.results;
  return Array.isArray(results) ? results[0] : results;
};

export const get_account_list_by_type = async (
  skip = 0,
  count = 0,
  value = '',
  type: string,
  isLocationNull?: string
) => {
  const response: AxiosResponse<IAccountTypeResponse, any> = await http.get('account', {
    skip,
    count,
    value,
    type,
    isLocationNull
  });
  return response.data;
};

export const get_account_list_only = async (skip = 0, count = 100000, value = '', filter = '') => {
  const response: AxiosResponse<IAccountTypeResponse, any> = await http.get('account/?' + filter, {
    skip,
    count,
    value
  });
  return response.data.results;
};

export const get_account_list_filter = async (filter = '') => {
  const response: AxiosResponse<IAccountTypeResponse, any> = await http.get('account/?' + filter);
  return response.data;
};

export const get_account_latest_user_history = async (filter = '') => {
  const response: AxiosResponse<any> = await http.get('account/latest-user-history/?' + filter);
  return response.data;
};

export const get_account_latest_transaction = async (ids: number[]) => {
  const response: AxiosResponse<IAccountTransactionResponse> = await http.get(
    'account-journal-lines/account-latest-transaction',
    { ids }
  );
  return response.data;
};

export const get_account_history_filter = async (filter = '') => {
  const response: AxiosResponse<any> = await http.get('account/history/?' + filter);
  return response.data;
};

export const get_account_history_ids = async (
  startDate: string | null,
  endDate: string | null,
  ids: number[]
) => {
  return await getDataOnlyForIds<IAccountHistoryResponse>(ids, 'account/history/?', {
    startDate,
    endDate
  });
};

export const get_account_details = async (id: number) => {
  const response: AxiosResponse<IAccountTypeResponseData, any> = await http.get('account/' + id);
  return response.data;
};

export const get_account_detail_by_ids = async (ids: number[]) => {
  return await getDataForIds<IAccountTypeResponseData>(ids, 'account/?');
};

export const get_journal_list = async (filter = '') => {
  const response: AxiosResponse<IJournalListResponse, any> = await http.get(
    'account-journal/?' + filter
  );
  return response.data;
};

export const get_journal_list_by_reference = async (
  identifier: string,
  id: number,
  filter = ''
) => {
  const response: AxiosResponse<IJournalListResponseData, any> = await http.get(
    `account-journal/by-reference/${identifier}/${id}?` + filter
  );
  return response.data;
};

export const get_journal_details = async (id: number) => {
  const response: AxiosResponse<IJournalListResponseData, any> = await http.get(
    'account-journal/' + id
  );
  return response.data;
};

export const get_journal_details_ids = async (ids: number[]) => {
  return await getDataForIds<IJournalListResponseData>(ids, 'account-journal/?');
};

export const get_journal_lines_details = async (id: number) => {
  const response: AxiosResponse<IJournalViewResponse[], any> = await http.get(
    'account-journal-lines/journal/' + id
  );
  return response.data;
};

export const get_journal_lines_list = async (filter = '') => {
  const response: AxiosResponse<IJournalLinesListResponse, any> = await http.get(
    'account-journal-lines/?' + filter
  );
  return response.data;
};

export const get_rules_list = async (filter = '') => {
  const response: AxiosResponse<IAccountRuleListResponse, any> = await http.get(
    'account-rules/?' + filter
  );
  return response.data;
};

export const get_rules_details = async (id: number) => {
  const response: AxiosResponse<ICreateAccountsRuleResponse, any> = await http.get(
    'account-rules/' + id
  );
  return response.data;
};

export const get_ledger_list = async (
  locationId: number | string,
  startDate: string,
  endDate: string,
  currentBalanceSortBy: string,
  parentIds?: number[],
  skip?: number,
  count?: number
) => {
  if (parentIds != undefined) {
    const response: AxiosResponse<ILedgerResponse, any> = await http.get('account/sub-ledger/', {
      locationId,
      startDate,
      endDate,
      skip,
      count,
      currentBalanceSortBy,
      parentIds
    });
    return response.data;
  } else {
    const response: AxiosResponse<ILedgerResponse, any> = await http.get('account/sub-ledger/', {
      startDate,
      endDate,
      skip,
      count,
      currentBalanceSortBy
    });
    return response.data;
  }
};

export const get_account_history_credit_debit_sum = async ({
  startDate,
  endDate,
  ids = []
}: {
  startDate: string;
  endDate: string;
  ids?: number[];
}) => {
  return await getDataForIdsByDates<IAccountHistoryCreditDebitSum>(
    ids,
    startDate,
    endDate,
    'account/history-credit-debit-sum'
  );
};

export const get_account_journal_lines_credit_debit_sum = async ({
  startDate,
  endDate,
  ids = []
}: {
  startDate: string;
  endDate: string;
  ids?: number[];
}) => {
  return await getDataForIdsByDates<IAccountHistoryCreditDebitSum>(
    ids,
    startDate,
    endDate,
    'account-journal-lines/account-transactions-sum'
  );
};

export const get_trial_balance_list = async (
  locationId: number | string,
  startDate: string,
  endDate: string,
  currentBalanceSortBy: string,
  parentIds?: number[],
  skip?: number,
  count?: number
) => {
  if (parentIds != undefined) {
    const response: AxiosResponse<ITrialBalanceResponse, any> = await http.get('account/parents/', {
      locationId,
      startDate,
      endDate,
      skip,
      count,
      currentBalanceSortBy,
      parentIds
    });
    return response.data;
  } else {
    const response: AxiosResponse<ITrialBalanceResponse, any> = await http.get('account/parents/', {
      startDate,
      endDate,
      skip,
      count,
      currentBalanceSortBy
    });
    return response.data;
  }
};

export const get_child_from_parent_list = async (
  locationId: number | string,
  startDate: string,
  endDate: string,
  currentBalanceSortBy: string,
  parentIds?: number[],
  skip?: number,
  count?: number
) => {
  if (parentIds != undefined) {
    const response: AxiosResponse<ITrialBalanceResponse, any> = await http.get('account/parents/', {
      locationId,
      startDate,
      endDate,
      skip,
      count,
      currentBalanceSortBy,
      parentIds
    });
    return response.data;
  } else {
    const response: AxiosResponse<ITrialBalanceResponse, any> = await http.get('account/parents/', {
      startDate,
      skip,
      count,
      currentBalanceSortBy,
      endDate
    });
    return response.data;
  }
};

export const get_profit_loss_list = async (
  locationId: number | string,
  startDate: string,
  endDate: string,
  currentBalanceSortBy: string,
  parentIds?: number[],
  skip?: number,
  count?: number
) => {
  if (parentIds != undefined) {
    const response: AxiosResponse<IPnLResponse, any> = await http.get('account/profit-loss/', {
      locationId,
      startDate,
      endDate,
      skip,
      count,
      currentBalanceSortBy,
      parentIds
    });
    return response.data;
  } else {
    const response: AxiosResponse<IPnLResponse, any> = await http.get('account/profit-loss/', {
      startDate,
      endDate,
      skip,
      count,
      currentBalanceSortBy
    });
    return response.data;
  }
};

export const get_balance_sheet_list = async (
  locationId: number | string,
  startDate: string,
  endDate: string,
  currentBalanceSortBy: string,
  parentIds?: number[],
  skip?: number,
  count?: number
) => {
  if (parentIds != undefined) {
    const response: AxiosResponse<IPnLResponse, any> = await http.get('account/balance-sheet/', {
      locationId,
      startDate,
      endDate,
      skip,
      count,
      currentBalanceSortBy,
      parentIds
    });
    return response.data;
  } else {
    const response: AxiosResponse<IPnLResponse, any> = await http.get('account/balance-sheet/', {
      startDate,
      endDate,
      skip,
      count,
      currentBalanceSortBy
    });
    return response.data;
  }
};

export const get_account_opening_list = async (filter = '') => {
  const response: AxiosResponse<IAccountOpeningResponse> = await http.get(
    'account-opening/?' + filter
  );
  return response.data;
};

export const get_account_opening_id = async (id: number) => {
  const response: AxiosResponse<IAccountOpening> = await http.get('account-opening/' + id);
  return response.data;
};

export const get_account_all_parents = async (accountId: number) => {
  const response: AxiosResponse<ISwitchAccountResponse[]> = await http.get(
    'account/get-all-parents/' + accountId
  );
  return response.data;
};

export const get_account_children = async (accountId: number) => {
  const response: AxiosResponse<ISwitchAccountResponse[]> = await http.get(
    'account/parents/?parentIds=0&parentIds=' + accountId
  );
  return response.data;
};

export const get_account_receive_payable = async (filter = '') => {
  const response: AxiosResponse<IAccountReceivePayable[]> = await http.get(
    'account-journal-lines/account-receivable-payable?' + filter
  );
  return response.data;
};

export const get_account_receive_payable_v2 = async (filter = '') => {
  const response: AxiosResponse<{ total: number; results: IAccountReceivePayableV2[] }> =
    await http.get('account-journal-lines/account-receivable-payable-v2?' + filter);
  return response.data;
};
