import LocalStore from '.';
import UsersDB from './UsersDB';

import { IUserAgents } from '@/services/agents/types';
import { get_agent_by_user_id } from '@/services/agents/queries';
import { get_user_details } from '@/services/users/queries';

function getTransactionError(event: Event, reject: (reason?: any) => void, type: string) {
  const instanceOfIndexDB = event.target instanceof IDBRequest;
  if (instanceOfIndexDB) {
    reject(event.target.error);
    return;
  }

  reject(new Error(`Failed to ${type} agents on indexDB`));
}

export class AgentsDB extends LocalStore {
  static async addAgents(agentsData: IUserAgents[]) {
    if (!AgentsDB.db) await AgentsDB.init();

    const updatedData = await Promise.all(
      agentsData.map(async (agent) => {
        if (agent.user) return agent;

        let data = await UsersDB.getUser(agent.userId);
        if (!data) {
          const user = await get_user_details(agent.userId);
          await UsersDB.addUsers([user.user]);
          data = await UsersDB.getUser(agent.userId);
        }

        agent.user = data as any;
        return agent;
      })
    );

    return new Promise((resolve, reject) => {
      const transaction = AgentsDB.db.transaction('Agents', 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'add');

      const objectStore = transaction.objectStore('Agents');

      updatedData.forEach(async (agent) => {
        const checkKey = objectStore.count(agent.id);

        checkKey.onerror = (event) => getTransactionError(event, reject, 'add');

        checkKey.onsuccess = async () => {
          if (checkKey.result == 0) {
            const request = objectStore.add(agent);
            request.onerror = (event) => getTransactionError(event, reject, 'add');
          } else {
            await AgentsDB.updateAgent(agent);
          }
        };
      });
    });
  }

  static async searchAgent(name: string, limit = 10): Promise<IUserAgents[]> {
    if (!AgentsDB.db) await AgentsDB.init();
    const givenName = name.toLowerCase();

    return new Promise((resolve, reject) => {
      const transaction = AgentsDB.db.transaction('Agents', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'search');

      const objectStore = transaction.objectStore('Agents');
      const index = objectStore.index('AgentsSecondIndex');
      const request = index.openCursor();

      const agents: IUserAgents[] = [];
      let count = 0;

      request.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor && count < limit) {
          const agent = cursor.value;

          if (agent) {
            const userDetail = agent.user;

            if (
              userDetail &&
              (userDetail.name?.toLowerCase().includes(givenName) ||
                userDetail.email?.toLowerCase().includes(givenName) ||
                userDetail.phone?.toLowerCase().includes(givenName) ||
                JSON.stringify(userDetail.id)?.toLowerCase().includes(givenName))
            ) {
              agents.push(agent);
              count++;
            }

            cursor.continue();
          }
        } else {
          resolve(agents);
        }
      };
    });
  }

  static async getAgent(id: number | string): Promise<IUserAgents> {
    if (!AgentsDB.db) await AgentsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = AgentsDB.db.transaction('Agents', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'get');

      const objectStore = transaction.objectStore('Agents');

      const request = objectStore.get(id);
      request.onsuccess = (event) => resolve((event.target as IDBRequest).result);
      request.onerror = (event) => getTransactionError(event, reject, 'get');
    });
  }

  static async updateAgent(agent: IUserAgents) {
    if (!AgentsDB.db) await AgentsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = AgentsDB.db.transaction('Agents', 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'update');

      const objectStore = transaction.objectStore('Agents');
      const request = objectStore.get(agent.id);

      request.onsuccess = (event) => {
        if ((event.target as IDBRequest).result) {
          const requestUpdate = objectStore.put(agent);

          requestUpdate.onerror = (event) => getTransactionError(event, reject, 'update');
          requestUpdate.onsuccess = () => resolve('updated data');
        } else reject('Error, could not find id.');
      };

      request.onerror = (event) => getTransactionError(event, reject, 'update');
    });
  }

  static async getAgentsByUserIds(userIds: number[]): Promise<IUserAgents[]> {
    if (!AgentsDB.db) await AgentsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = AgentsDB.db.transaction('Agents', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'get');

      const objectStore = transaction.objectStore('Agents');
      const agents: IUserAgents[] = [];
      const notFoundUserIds: number[] = [];

      function checkCompletion() {
        if (notFoundUserIds.length > 0) {
          // Fetch agents from server for the user IDs not found in cache
          get_agent_by_user_id(notFoundUserIds, true).then((serverAgents) => {
            AgentsDB.addAgents(serverAgents).then(() => {
              resolve(agents.concat(serverAgents));
            });
          });
        } else {
          resolve(agents);
        }
      }

      userIds.forEach((userId) => {
        const index = objectStore.index('AgentsSecondIndex');
        if (!index) {
          reject(new Error('Index userId not found in Agents object store.'));
          return;
        }

        const request = index.get(userId);
        request.onsuccess = (event) => {
          const result = (event.target as IDBRequest).result;
          if (result) {
            agents.push(result);
          } else {
            notFoundUserIds.push(userId);
          }

          if (agents.length + notFoundUserIds.length == userIds.length) {
            checkCompletion();
          }
        };
        request.onerror = (event) => {
          reject(
            (event.target as IDBRequest).error ??
              new Error(`Error retrieving agent for userId ${userId}`)
          );
        };
      });

      transaction.oncomplete = () => resolve(agents);
    });
  }
}
