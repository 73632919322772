import {
  addNestedRows,
  exportExcelBuffer,
  flattenHeaders,
  IExportNestedColumn
} from '@/utils/exportNestedExcel';
import getCharFromNumber from '@/utils/getCharFromNumber';
import ExcelJS from 'exceljs';
import NepaliDate from 'nepali-date-converter';

interface IExportData<G> {
  data: G[];
  year: string;
  date: string;
  range?: { from: string; to: string };
}

interface Props<T> {
  fileName?: string;
  title: string;
  data: IExportData<T>;
  columns: IExportNestedColumn<T>[];
  isPurchase?: boolean;
}

const borderStyle: Partial<ExcelJS.Borders> = {
  top: { style: 'thin', color: { argb: 'FF000000' } },
  left: { style: 'thin', color: { argb: 'FF000000' } },
  bottom: { style: 'thin', color: { argb: 'FF000000' } },
  right: { style: 'thin', color: { argb: 'FF000000' } }
};

/**
 *
 * DataIndex must be unique
 */
export async function exportRegisterExcel<T>({
  data,
  columns,
  title,
  fileName = 'default',
  isPurchase
}: Props<T>) {
  const flattenColumns = flattenHeaders(columns);
  const lastColumnChar = getCharFromNumber(Math.max(flattenColumns.length - 1, 0));

  let monthName = '';
  if (data.date.includes('to')) {
    monthName = data.date;
  } else {
    const nepaliDate = new NepaliDate(data.date);
    monthName = nepaliDate.format('MMMM');
  }

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');
  const ruleBook = `(Related with Rule 23 SubRule(1) part(${isPurchase ? 'g' : 'h'}))`;

  const detailsArray = [
    'PAN: 609891700',
    "Tax Payer's Name: Freshktm Agro Pvt. Ltd.",
    `Year: ${data.year}`,
    `Tax Period: ${monthName}`
  ];

  if (data.range) {
    detailsArray.push(`From: ${data.range.from}`, `To: ${data.range.to}`);
  }

  const details = detailsArray.join('          ');
  const content = [title, ruleBook, '', details];

  content.forEach((text, idx) => {
    const row = idx + 1;
    const cells = `A${row}:${lastColumnChar}${row}`;

    worksheet.mergeCells(cells);
    worksheet.getCell(`A${row}`).value = text;
    worksheet.getCell(cells).alignment = { horizontal: 'center' };
    worksheet.getCell(cells).border = borderStyle;
  });

  // Add details
  worksheet.getCell('A1').font = { size: 20, bold: true };
  worksheet.getCell('A2').font = { size: 12 };
  worksheet.getCell('A4').font = { size: 11, bold: true };

  addNestedRows({
    data: data.data,
    columns,
    workSheet: worksheet,
    startRow: 5,
    header: { borderStyle }
  });

  // Save the workbook to file
  const buffer = await workbook.xlsx.writeBuffer();
  await exportExcelBuffer(buffer, fileName);
}

interface IPropsItemWise<T> {
  fileName?: string;
  data: T[];
  exportYear: { from: string; to: string };
  columns: IExportNestedColumn<T>[];
  isPurchase?: boolean;
  title?: string;
}

export async function exportRegisterItemWiseExcel<T>({
  title,
  data,
  columns,
  fileName = 'default',
  isPurchase,
  exportYear
}: IPropsItemWise<T>) {
  const flattenColumns = flattenHeaders(columns);
  const lastColumnChar = getCharFromNumber(Math.max(flattenColumns.length - 1, 0));

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');

  const parentStyle = [
    { value: 'FreshKtm Agro Pvt. Ltd.', size: 12, bold: true, align: 'center', font: 'Tahoma' },
    {
      value: 'Suryabinayak - 8, Bhaktapur',
      size: 10,
      bold: false,
      align: 'center',
      font: 'Tahoma'
    },
    { value: 'VAT No: 609891700', size: 10, bold: true, align: 'center', font: 'Tahoma' },
    { value: '', size: 11, bold: true, align: 'center', font: 'Tahoma' },
    { value: title, size: 12, bold: true, align: 'center', font: 'Tahoma' },
    {
      value: `Related With Rule 23 SubRule(1) Part(${isPurchase ? 'G' : 'H'})`,
      size: 11,
      bold: true,
      align: 'center'
    }
  ];

  parentStyle.forEach((style, idx) => {
    const row = idx + 1;
    const cells = `A${row}:${lastColumnChar}${row}`;

    worksheet.mergeCells(cells);
    const cell = worksheet.getCell(`A${row}`);
    cell.value = style.value;
    cell.alignment = { horizontal: 'center' };
    cell.font = { size: style.size, bold: style.bold, name: style.font || 'Calibri' };
  });

  const fromRow = parentStyle.length + 1;
  worksheet.mergeCells(`B${fromRow}:C${fromRow}`);

  const firstColOfFrom = worksheet.getCell(`A${fromRow}`);
  firstColOfFrom.value = 'From:';
  firstColOfFrom.font = { bold: true, size: 11 };

  const secondColOfFrom = worksheet.getCell(`B${fromRow}:C${fromRow}`);
  secondColOfFrom.value = exportYear.from;
  secondColOfFrom.font = { size: 11 };

  const toRow = fromRow + 1;
  const firstColOfTo = worksheet.getCell(`A${toRow}`);
  firstColOfTo.value = 'To:';
  firstColOfTo.font = { bold: true, size: 11 };

  const secondColOfTo = worksheet.getCell(`B${toRow}:C${toRow}`);
  secondColOfTo.value = exportYear.to;
  secondColOfTo.font = { size: 11 };

  // Get last 2 columns
  const secondLastChar = getCharFromNumber(flattenColumns.length - 2);
  const lastChar = getCharFromNumber(flattenColumns.length - 1);

  const secondLastColumn = `${secondLastChar}${toRow}`;
  const lastColumn = `${lastChar}${toRow}`;

  worksheet.getCell(secondLastColumn).value = 'Division:';
  worksheet.getCell(secondLastColumn).font = { bold: true, size: 11 };
  worksheet.getCell(secondLastColumn).alignment = { horizontal: 'right' };

  worksheet.getCell(lastColumn).value = 'All';
  worksheet.getCell(lastColumn).font = { size: 11 };
  worksheet.getCell(lastColumn).alignment = { horizontal: 'right' };

  addNestedRows({
    data: data,
    columns,
    workSheet: worksheet,
    startRow: toRow + 2,
    header: { borderStyle }
  });

  // Save the workbook to file
  const buffer = await workbook.xlsx.writeBuffer();
  await exportExcelBuffer(buffer, fileName);
}
