export enum WidgetType {
  REPORT = 'report',
  OTHER = 'other'
}

export enum CompareType {
  RANGE_COMPARE = 'range',
  SINGLE_COMPARE = 'single',
  CUSTOM_RANGE = 'custom',
  CUSTOM_BOTH_RANGE = 'custom-both-range'
}
