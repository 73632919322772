import { find_to_use_NPR } from '@/store/localstorage/preferences';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

export const tableExportColumns = [
  {
    label: 'Customer',
    dataIndex: 'customer_name',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Route',
    dataIndex: 'route_name',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Net Sales',
    dataIndex: 'net_sales',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Number of Sales',
    dataIndex: 'no_of_sales',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Average Sales',
    dataIndex: 'avg',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      net_sales: nepaliNumberFormatter(record.net_sales),
      no_of_sales: nepaliNumberFormatter(record.no_of_sales),
      avg: nepaliNumberFormatter(record.avg)
    };
  });
}
