import { Button, Divider, Form, FormInstance, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { IAccountTypeResponse, IAccountTypeResponseData } from '../../../services/accounts/types';
import { get_account_detail_by_ids, get_account_list } from '../../../services/accounts/queries';
import AccountsDB from '../../../store/localstorage/AccountsDB';
import { AccountType } from '../../../services/accounts/enums';

interface MultipleAccountsTypeSearchProps {
  formData?: {
    formName: string | (string | number)[];
    label: string;
  };
  formMain?: FormInstance<any>;
  isAll?: boolean;
  required?: boolean;
  disabled?: boolean;
  locationId?: number;
  mode?: 'multiple' | 'tags' | undefined;
}

const { Option } = Select;

export const MultipleAccountsTypeSearch: React.FC<MultipleAccountsTypeSearchProps> = ({
  formData,
  formMain,
  isAll = true,
  required = false,
  disabled = false,
  locationId,
  mode
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchAccountsTypeIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const [accountsTypeSearch, setAccountsTypeSearch] = useState<IAccountTypeResponseData[]>([]);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);
  const [accountList, setAccountList] = useState<IAccountTypeResponseData[]>([]);

  if (formData?.formName) {
    const selectId = Form.useWatch(formData.formName, formMain) || [];
    useEffect(() => {
      getAccountDetailsById();
    }, [selectId]);

    const getAccountDetailsById = async () => {
      if (selectId.length === 0) {
        return;
      }

      if (selectId.length > 1) {
        for (const id of selectId) {
          const account = await AccountsDB.getAccount(id);
          if (!account) {
            const allAccount = await get_account_detail_by_ids(selectId);
            AccountsDB.addAccounts(allAccount.results);
            const newAccounts = allAccount.results.filter((newAccount) => {
              return !accountsTypeSearch.some(
                (existingAccount) => existingAccount.id === newAccount.id
              );
            });
            setAccountsTypeSearch([...accountsTypeSearch, ...newAccounts]);
            checkAccountsAndAdd(allAccount.results);
            if (formMain) {
              formMain.setFieldValue(formData.formName, selectId);
            }
          }
        }
      } else {
        const account = await AccountsDB.getAccount(selectId[0]);
        if (!account) {
          const allAccount = await get_account_detail_by_ids(selectId);
          AccountsDB.addAccounts(allAccount.results);
          const newAccounts = allAccount.results.filter((newAccount) => {
            return !accountsTypeSearch.some(
              (existingAccount) => existingAccount.id === newAccount.id
            );
          });
          setAccountsTypeSearch([...accountsTypeSearch, ...newAccounts]);
          checkAccountsAndAdd(allAccount.results);
          if (formMain) {
            formMain.setFieldValue(formData.formName, selectId);
          }
        }
      }
    };
  }

  const searchAccountsTypeIndexDB = async (value: string) => {
    try {
      if (locationId) {
        const response = await AccountsDB.searchAccountByNameAndLocation(value, locationId, 100);
        if (!response || response.length == 0) {
          message.info('Cannot find any account with that name in cache, searching in server...');
          searchAccountsType(value);
        } else {
          const sortedResponse = response.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          setAccountsTypeSearch(sortedResponse);
          checkAccountsAndAdd(sortedResponse);
          setIsCacheResponse(true);
        }
      } else {
        const response = await AccountsDB.searchAccountByName(value, 100);
        if (!response || response.length == 0) {
          message.info('Cannot find any account with that name in cache, searching in server...');
          searchAccountsType(value);
        } else {
          const sortedResponse = response.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          setAccountsTypeSearch(sortedResponse);
          setIsCacheResponse(true);
          checkAccountsAndAdd(sortedResponse);
        }
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  const searchAccountsType = async (value: string) => {
    let response: IAccountTypeResponse;
    try {
      if (locationId) {
        response = await get_account_list(0, 10, value, locationId);
      } else {
        response = await get_account_list(0, 10, value);
      }
      if (!response || response.results.length == 0) {
        message.error('Cannot find account with that name!');
        setAccountsTypeSearch([]);
        setIsMore(false);
      } else {
        const sortedResults = response.results.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setAccountsTypeSearch([]);
        setAccountsTypeSearch((prevValue) => {
          const accounts = sortedResults.filter((value: any) => {
            const searchAccount = prevValue.find((val) => val.id === value.id);
            if (searchAccount) return false;
            return true;
          });

          return [...prevValue, ...accounts];
        });
        await AccountsDB.addAccounts(sortedResults);

        checkAccountsAndAdd(sortedResults);
        //console.log('response', response);
        if (sortedResults.length < 10) {
          setIsMore(false);
        } else {
          setIsMore(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setIsCacheResponse(false);
    setSkip(10);
  };

  const searchMoreAccountsType = async (value: string) => {
    let response: any;
    try {
      if (locationId) {
        response = await get_account_list(skip, 10, value, locationId);
      } else {
        response = await get_account_list(skip, 10, value);
      }
    } catch (e) {
      console.log(e);
    }
    if (!response || response.results.length == 0) {
      message.info('Cannot find more account with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);

      setAccountsTypeSearch((prevValue) => {
        const accounts = response.results.filter((value: any) => {
          const searchAccount = prevValue.find((val) => val.id === value.id);
          if (searchAccount) return false;
          return true;
        });

        return [...prevValue, ...accounts];
      });
      AccountsDB.addAccounts(response.results);
      checkAccountsAndAdd(response.results);
      if (response.results.length < 10) {
        setIsMore(false);
      }
    }
  };

  const checkAccountsAndAdd = (accounts: IAccountTypeResponseData[]) => {
    let accountsCopy = accounts.map((value) => ({ ...value }));
    if (accountList.length > 0) {
      accountsCopy = accountsCopy.filter((value) => {
        const searchAccount = accountsTypeSearch.find((val) => val.id == value.id);
        if (searchAccount) return false;
        return true;
      });
      if (accountsCopy.length > 0)
        setAccountList((prevValue) => {
          accountsCopy = accountsCopy.filter((value) => {
            const searchAccount = prevValue.find((val) => val.id == value.id);
            if (searchAccount) return false;
            return true;
          });
          return [...prevValue, ...accountsCopy];
        });
    } else {
      setAccountList(accountsCopy);
    }
  };

  const options = accountsTypeSearch.map((value) => (
    <Option key={value.id} value={value.id}>
      {value.type === AccountType.OTHER ||
      !Object.values(AccountType).includes(value.type as AccountType)
        ? `${value.name}`
        : `${value.name} (${value.type})`}
    </Option>
  ));

  return (
    <>
      {formData ? (
        <Form.Item
          name={formData.formName}
          label={formData.label}
          rules={[
            {
              required: required,
              message: 'Please add an account!'
            }
          ]}>
          <Select
            showSearch
            allowClear
            disabled={disabled}
            placeholder="Select an account type"
            defaultActiveFirstOption={false}
            showArrow={false}
            autoClearSearchValue={true}
            filterOption={false}
            dropdownMatchSelectWidth={false}
            mode={mode}
            onSearch={(val: string) => {
              setSearchValue(val.trim());
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                {isCacheResponse ? (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchAccountsType(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Pull More & Sync'}
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {isMore ? (
                      <Button
                        type="text"
                        style={{
                          color: 'blue',
                          width: '100%'
                        }}
                        onClick={() => {
                          searchMoreAccountsType(searchValue);
                        }}>
                        {/* {'Sync & Search on Server'} */}
                        {'Get More...'}
                      </Button>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center'
                        }}>
                        No more data...
                      </div>
                    )}
                  </div>
                )}
              </>
            )}>
            {isAll ? <Option value="">All</Option> : null}
            {options}
          </Select>
        </Form.Item>
      ) : (
        <Select
          showSearch
          allowClear
          disabled={disabled}
          placeholder="Select an account type"
          defaultActiveFirstOption={false}
          showArrow={false}
          autoClearSearchValue={true}
          filterOption={false}
          dropdownMatchSelectWidth={false}
          onSearch={(val: string) => {
            setSearchValue(val.trim());
          }}
          mode={mode}
          notFoundContent={null}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              {isCacheResponse ? (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {/* <div
                  style={{
                    color: 'green',
                    width: '100%',
                    textAlign: 'center'
                  }}>{`Cache found ${productSearch.length} data`}</div> */}
                  <Button
                    type="text"
                    style={{
                      color: 'blue',
                      width: '100%'
                    }}
                    onClick={() => {
                      searchAccountsType(searchValue);
                    }}>
                    {/* {'Sync & Search on Server'} */}
                    {'Pull More & Sync'}
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {isMore ? (
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchMoreAccountsType(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Get More...'}
                    </Button>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center'
                      }}>
                      No more data...
                    </div>
                  )}
                </div>
              )}
            </>
          )}>
          {isAll ? <Option value="">All</Option> : null}
          {options}
        </Select>
      )}
    </>
  );
};
