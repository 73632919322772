import { get_route_list_only } from '@/services/routes/queries';
import CustomSearch, { SelectProps } from '..';
import RoutesDB from '@/store/localstorage/RoutesDB';
import { IRoutes } from '@/services/routes/types';

type MultipleProps =
  | {
      defaultValue?: number;
      isMultiple?: false;
      setSelected?: (selected?: IRoutes) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      defaultValue?: number[];
      isMultiple: true;
      setSelected?: (selected?: IRoutes[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps & MultipleProps;

function RouteSearchV2(props: Props) {
  return (
    <CustomSearch
      formFor="route"
      addCallback={RoutesDB.addRoutes}
      serverCallback={get_route_list_only}
      dbSearchById={RoutesDB.getRoute}
      dbSearchCallback={RoutesDB.searchRoute}
      {...props}
    />
  );
}

export default RouteSearchV2;
