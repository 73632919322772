import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { Button, message, Modal, PageHeader, Tooltip } from 'antd';
import { BiReset } from 'react-icons/bi';
import { SaveFilled } from '@ant-design/icons';

import { useDashboardCustomize } from '@/contexts/dashboard-customize.context';
import { IWidgetConfiguration } from '@/services/dashboard/v3/types';
import { useNavigate } from 'react-router-dom';
import { updateDashboardLayout } from '@/services/dashboard/v3/services.dashboard';
import CustomErrorModal from '@/components/Common/CustomErrorModal';

function CustomizeHeader() {
  const [clearWidget, setClearWidget] = useState(false);
  const [saveDashboardLayout, setSaveDashboardLayout] = useState(false);
  const { editingWidgets, setEditingWidgets, setIsLoading } = useDashboardCustomize();

  function onClearWidgets() {
    setEditingWidgets([]);
    setClearWidget(false);
  }

  const navigate = useNavigate();

  async function updateDashboardCustomizeLayout(widgets: IWidgetConfiguration[]) {
    try {
      await updateDashboardLayout(widgets);
      message.success('Dashboard layout saved successfully');
      navigate('/');
    } catch (error: any) {
      if (error.type === 'Error') {
        CustomErrorModal({
          title: 'Error',
          message: error.message || 'Error saving dashboard layout'
        });
      }
    }
  }

  function onAddWidget(isMultiple = false) {
    const newWidget: IWidgetConfiguration = {
      id: uuidv4(),
      colSize: 3,
      isMultiple,
      isCustom: true,
      isPlotAgainstDate: false,
      title: isMultiple ? 'Multiple Custom Widgets' : 'Custom Widget',
      widgets: [],
      showFilter: false,
      showZoom: false
    };

    setEditingWidgets([...editingWidgets, newWidget]);
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 0);
  }

  async function onSaveDashboard() {
    setSaveDashboardLayout(false);
    setIsLoading(true);

    try {
      if (editingWidgets.length === 0) {
        message.error('Please add at least one widget to save the dashboard');
        return;
      }

      const hasCustomWidget = editingWidgets.some((widget) => widget.isCustom);
      if (hasCustomWidget) {
        message.error('Please fill in all custom widgets before saving the dashboard');
        return;
      }

      await updateDashboardCustomizeLayout(editingWidgets);
    } catch (error) {
      console.log('Error saving dashboard', error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="flex items-center flex-wrap gap-2 justify-between">
      <div className="space-y-0">
        <PageHeader style={{ margin: 0, padding: 0 }} title={'Dashboard'} />
        <p className="text-gray-600 flex items-center gap-2">Customize your dashboard layout</p>
      </div>

      <Modal visible={clearWidget} onCancel={() => setClearWidget(false)} onOk={onClearWidgets}>
        <p>Are you sure you want to clear all widgets?</p>
      </Modal>

      <Modal
        visible={saveDashboardLayout}
        onCancel={() => setSaveDashboardLayout(false)}
        onOk={onSaveDashboard}>
        <p>Are you sure you want to save the dashboard layout?</p>
      </Modal>

      <div className="flex gap-1 items-center flex-wrap">
        <Tooltip title="Clear all widgets">
          <Button
            onClick={() => {
              if (editingWidgets.length === 0) {
                return message.error('No widgets to clear');
              }

              setClearWidget(true);
            }}
            type="default"
            danger
            className="!w-10 !rounded-sm !flex items-center justify-center"
            icon={<BiReset size={24} className="cursor-pointer" />}
          />
        </Tooltip>

        <Tooltip title="Add Single or Multiple Widgets">
          <Button
            type="primary"
            ghost
            onClick={() => onAddWidget()}
            className="!flex justify-center items-center !rounded-sm !bg-white">
            Add Widget
          </Button>
        </Tooltip>

        <Tooltip title="Save Layout">
          <Button
            type="primary"
            onClick={() => setSaveDashboardLayout(true)}
            className="!flex justify-center items-center !rounded-sm"
            icon={<SaveFilled style={{ fontSize: 16 }} className="[&>svg]:m-0" />}>
            Save Widget
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}

export default CustomizeHeader;
