import { ExportColumnType } from '@/utils/exportExcel';

export const purchaseExportColumn: ExportColumnType[] = [
  { title: 'S.N', dataIndex: 'SN', width: 50 },
  { title: 'ID', dataIndex: 'id', width: 50 },
  { title: 'Financial Reference', dataIndex: 'financialReference', width: 250 },
  { title: 'Invoice Number', dataIndex: 'invoiceNumber', width: 250 },
  { title: 'Supplier', dataIndex: 'userName', width: 200 },
  { title: 'Supplier Contact', dataIndex: 'userContact', width: 200 },
  { title: 'Agent Name', dataIndex: 'agentName', width: 150 },
  { title: 'Buyer Type', dataIndex: 'purchaseBuyerType', width: 150 },
  { title: 'Purchase Type', dataIndex: 'purchaseType', width: 100 },
  { title: 'Created By', dataIndex: 'createdByName', width: 100 },
  { title: 'Location', dataIndex: 'locationName', width: 100 },
  { title: 'LCC Location', dataIndex: 'lccLocationName', width: 100 },
  { title: 'Date', dataIndex: 'date', width: 200 },
  { title: 'Manual Discount', dataIndex: 'manualDiscount', width: 100 },
  { title: 'Total', dataIndex: 'totalTaxable', width: 100 },
  { title: 'Shipping', dataIndex: 'shippingAmount', width: 100 },
  { title: 'Shipping Tax', dataIndex: 'shippingTax', width: 100 },
  { title: 'TAX', dataIndex: 'totalVat', width: 100 },
  { title: 'Grand Total', dataIndex: 'totalAmount', width: 100 },
  { title: 'Paid', dataIndex: 'amountPaid', width: 100 },
  { title: 'Returned', dataIndex: 'amountReturned', width: 100 },
  { title: 'Print Count', dataIndex: 'printCount', width: 100 }
];

export const purchaseExportPDFColumn = [
  {
    label: 'ID',
    dataIndex: 'id',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Financial Reference',
    dataIndex: 'financialReference',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Invoice Number',
    dataIndex: 'invoiceNumber',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Supplier',
    dataIndex: 'userName',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Supplier Contact',
    dataIndex: 'userContact',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Agent Name',
    dataIndex: 'agentName',
    render: (text: string) => {
      return <div className="text-center">{text ? text : 'NA'}</div>;
    }
  },
  {
    label: 'Buyer Type',
    dataIndex: 'purchaseBuyerType',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Purchase Type',
    dataIndex: 'purchaseType',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Created By',
    dataIndex: 'createdByName',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Location',
    dataIndex: 'locationName',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'LCC Location',
    dataIndex: 'lccLocationName',
    render: (text: string) => {
      return <div className="text-center">{text ? text : 'NA'}</div>;
    }
  },
  {
    label: 'Date',
    dataIndex: 'date',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Total',
    dataIndex: 'totalTaxable',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Shipping',
    dataIndex: 'shippingAmount',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Shipping Tax',
    dataIndex: 'shippingTax',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'TAX',
    dataIndex: 'totalVat',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Grand Total',
    dataIndex: 'totalAmount',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Manual Discount',
    dataIndex: 'manualDiscount',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Paid',
    dataIndex: 'amountPaid',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Returned',
    dataIndex: 'amountReturned',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Print Count',
    dataIndex: 'printCount',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];
