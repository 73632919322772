import { useContext, useState } from 'react';
import PurchasePayment from '../../../components/Common/PaymentModal/PurchasePayment';
import { PurchaseListContext } from './context';
import { message, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { MoneyCollectOutlined } from '@ant-design/icons';
import { get_account_details_by_userid_type } from '@/services/accounts/queries';
import { AccountType } from '@/services/accounts/enums';

export const PurchasePaymentModal: React.FC<{
  getInfo: (filter?: string) => Promise<void>;
  record: any;
}> = ({ getInfo, record }) => {
  const { form } = useContext(PurchaseListContext);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedSale, setSelectedSale] = useState<any>();

  return (
    <>
      <div
        className="w-full p-0 cursor-pointer"
        onClick={async () => {
          if (record.userData) {
            const userId = record.userData.userId;
            const userAccount = await get_account_details_by_userid_type(userId, AccountType.USER);
            record.isAccountArchived = userAccount?.isArchived;
          }
          setSelectedSale(record);
          setTimeout(() => {
            setShowPaymentModal(true);
          }, 500);
        }}>
        <Tooltip title="Add payment" color="blue">
          <Link to="#">
            <MoneyCollectOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
          </Link>
        </Tooltip>
      </div>
      <PurchasePayment
        visible={showPaymentModal}
        setShow={setShowPaymentModal}
        selectedSale={selectedSale}
        setSelectedSale={setSelectedSale}
        form2={form}
        fetchAgain={getInfo}
      />
    </>
  );
};
