import { find_to_use_NPR } from '@/store/localstorage/preferences';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

export const tableExportColumns = [
  {
    label: 'ID',
    dataIndex: 'id',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Customer Name',
    dataIndex: 'userName',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Invoice No.',
    dataIndex: 'reference_number',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Total',
    dataIndex: 'total_amount',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Return Total',
    dataIndex: 'amount_returned',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Total after Return',
    dataIndex: 'totalafterReturn',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Amount Paid',
    dataIndex: 'amount_paid',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      total_amount: nepaliNumberFormatter(record.total_amount),
      amount_returned: nepaliNumberFormatter(record.amount_returned),
      totalafterReturn: nepaliNumberFormatter(record.total_amount - record.amount_returned),
      amount_paid: nepaliNumberFormatter(record.amount_paid)
    };
  });
}
