import {
  DashOutlined,
  DeleteColumnOutlined,
  DeleteFilled,
  DeleteOutlined,
  RollbackOutlined,
  TranslationOutlined,
  UserAddOutlined,
  WalletOutlined
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
  type MenuProps,
  Layout,
  Menu,
  Typography,
  FormInstance,
  Button,
  Form,
  message,
  Tooltip
} from 'antd';
import { useEffect, useState } from 'react';
import { ConvertObjectToURL } from '../../utils/converturl';
import { getUserData } from '../../utils/auth.utils';
import { get_sell_lists_v2 } from '../../services/sell/queries';
import CustomersDB from '../../store/localstorage/CustomerDB';
import ButtonGroup from 'antd/lib/button/button-group';
import Paragraph from 'antd/lib/skeleton/Paragraph';

const { Content, Sider } = Layout;
const { Text } = Typography;

type MenuItem = Required<MenuProps>['items'][number];

interface IPosMenuProps {
  onMenuClick: (title: string, value?: string) => any;
  formValues: any;
  form: FormInstance<any>;
  isCollapsed: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  isSessionStart?: boolean;
}

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem;
}

interface IFormWrapper {
  formValues: any;
  label: string;
}

const PosMenu: React.FC<IPosMenuProps> = ({
  onMenuClick,
  form,
  formValues,
  isCollapsed,
  setIsCollapsed,
  isSessionStart
}) => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [recentTransactions, setRecentTransactions] = useState<any[]>([]);
  const [queueList, setQueueList] = useState<IFormWrapper[]>([{ formValues, label: 'Default' }]);
  const [currentActiveForm, setCurrentActiveForm] = useState<number>(0);
  const [updateMenuToggle, setUpdateMenuToggle] = useState<boolean>(false);
  const [updateRecentTransaction, setUpdateRecentTransaction] = useState<boolean>(false);

  const items = [
    { label: 'Sell', icon: <WalletOutlined /> },
    { label: 'Return List', icon: <RollbackOutlined /> },
    { label: 'Queue', icon: <DashOutlined /> },
    { label: 'Recent Transaction', icon: <TranslationOutlined /> }
  ];

  useEffect(() => {
    if (!isSessionStart) {
      setQueueList([{ formValues: {}, label: 'Queue 1' }]);
    }
  }, [isSessionStart]);

  const menuSelected = (item: any) => {
    const label = items[item.keyPath[item.keyPath.length - 1] - 1].label;
    let childId: string | undefined;
    if (item.keyPath.length == 2) {
      childId = item.keyPath[0].split('_')[1];
    }
    // console.log('label', label);
    // console.log('childId', childId);
    if (label == 'Queue' && childId) {
      form.resetFields();
      form.resetFields(['lines']);
      const formValues = queueList[parseInt(childId)].formValues;
      formValues.currentActiveKey = childId;
      // console.log('formValues', formValues);
      form.setFieldsValue(formValues);
      setCurrentActiveForm(parseInt(childId));
      //form.setFieldValue('currentActiveKey', childId);
    } else {
      onMenuClick(label, childId);
    }
  };
  const getUnusedLabelNumber = () => {
    let usedLabels = queueList
      .filter((item) => item.label.includes('Queue'))
      .map((item) => {
        return parseInt(item.label.split(' ')[1]);
      });
    usedLabels = usedLabels.sort((a, b) => a - b);
    // console.log('usedLabels', usedLabels);
    let unusedLabelNumber = 1;
    for (let i = 0; i < usedLabels.length; i++) {
      if (unusedLabelNumber != usedLabels[i]) {
        break;
      }
      unusedLabelNumber++;
    }
    return unusedLabelNumber;
  };

  const addForm = () => {
    //console.log('add form');
    form.resetFields();
    form.resetFields(['lines']);
    const newQueueList = queueList;
    newQueueList.push({ formValues: {}, label: 'Queue ' + getUnusedLabelNumber() });
    setQueueList([...newQueueList]);
    setCurrentActiveForm(newQueueList.length - 1);
    // console.log('newqueueList', newQueueList);
  };

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const removeFromQueue = (index: number, e: any) => {
    e.stopPropagation();
    e.preventDefault();
    /* if (currentActiveForm == index) {
      message.error('Cannot delete current active form');
      return;
    } */
    const newQueueList = queueList;
    newQueueList.splice(index, 1);
    setQueueList([...newQueueList]);
    // console.log('newqueueList', newQueueList);
    //setCurrentActiveForm(queueList.length - 1);
    if (index < currentActiveForm) {
      setCurrentActiveForm(currentActiveForm - 1);
    } else if (index == currentActiveForm) {
      // console.log('currentActiveForm', currentActiveForm);
      //console.log('formValues', formValues);
      form.resetFields();
      form.resetFields(['lines']);
      // console.log('currentActiveForm-1', currentActiveForm - 1);
      if (currentActiveForm == 0) {
        const formValues = queueList[currentActiveForm].formValues;
        formValues.currentActiveKey = currentActiveForm + 1;
        form.setFieldsValue(formValues);
        setCurrentActiveForm(currentActiveForm);
      } else {
        const formValues = queueList[currentActiveForm - 1].formValues;
        formValues.currentActiveKey = currentActiveForm - 1;
        // console.log('formValues', formValues);
        form.setFieldsValue(formValues);
        setCurrentActiveForm(currentActiveForm - 1);
      }
    } else {
      setUpdateMenuToggle(!updateMenuToggle);
    }
  };

  useEffect(() => {
    // console.log('menuUpdated!', updateMenuToggle);

    const menuItemList: MenuItem[] = items.map((item, index) => {
      let label: string | JSX.Element = item.label;
      let children: MenuItem[] = [];
      if (item.label == 'Recent Transaction') {
        children = recentTransactions.map((item: any) => {
          return getItem(
            <Text style={{ fontSize: '10px', fontWeight: 'bolder' }}>{item.referenceNumber}</Text>,
            `T_${item.id}`
          );
        });
      }
      if (item.label == 'Queue') {
        children = queueList.map((item, index) => {
          return getItem(
            <>
              {queueList.length > 1 && (
                <Button
                  size="small"
                  onClick={(e) => removeFromQueue(index, e)}
                  type="text"
                  style={{
                    borderRadius: '6px',
                    backgroundColor: '#3333cc',
                    color: 'white',
                    marginRight: '10px'
                  }}>
                  <Tooltip title="Delete Queue">
                    <DeleteFilled />
                  </Tooltip>
                </Button>
              )}

              <Tooltip title={item.label} color="blue">
                <Text style={{ fontSize: 'small' }}>
                  {index + 1} . {item.label}
                </Text>
              </Tooltip>
            </>,
            `Q_${index}`
          );
        });
        label = (
          <>
            Queue
            <Button
              disabled={!isSessionStart}
              type="text"
              size="small"
              style={{
                backgroundColor: '#3333cc',
                color: 'white',
                borderRadius: '6px',
                marginLeft: '10px'
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                addForm();
              }}>
              <Tooltip title="Add New Queue">
                <UserAddOutlined />
              </Tooltip>
            </Button>
          </>
        );
      }
      return getItem(label, index + 1, item.icon, children.length > 0 ? children : undefined);
    });
    setMenuItems(menuItemList);
  }, [recentTransactions, currentActiveForm, queueList, updateMenuToggle, isSessionStart]);

  const updateFormValues = async (formValues: any) => {
    //console.trace('updateFormValuesmenu', formValues);
    // console.log('formValuesmenu', formValues);
    const newQueueList = queueList;
    let label = 'Queue ' + getUnusedLabelNumber();
    if (newQueueList[currentActiveForm].label.includes('Queue')) {
      label = newQueueList[currentActiveForm].label;
    }
    if (formValues.userId) {
      const customer = await CustomersDB.getCustomer(parseInt(formValues.userId));
      label = customer.user.name;
    }
    // console.log('label', label);
    newQueueList[currentActiveForm] = { formValues, label };
    setQueueList(newQueueList);
    if (formValues.saved) {
      setUpdateRecentTransaction(!updateRecentTransaction);
    }
    setUpdateMenuToggle((prev) => !prev);
  };

  useEffect(() => {
    // console.log('formValues called', formValues);
    updateFormValues(formValues);
  }, [formValues]);

  const fetchRecentTransactions = async () => {
    const filter = ConvertObjectToURL({
      skip: 0,
      count: 5,
      locationId: preferenceLocationId,
      type: 'POS'
    });
    const response = await get_sell_lists_v2(filter);
    setRecentTransactions(response.data.results);
    // console.log('response', response.data.results);
    return response;
  };

  useEffect(() => {
    fetchRecentTransactions();
  }, [updateRecentTransaction]);

  /* useQuery(['pos-recent'], async () => {
    const filter = ConvertObjectToURL({
      skip: 0,
      count: 5,
      locationId: preferenceLocationId,
      type: 'POS'
    });
    const response = await get_sell_lists_v2(filter);
    setRecentTransactions(response.data.results);
    console.log('response', response.data.results);
    return response;
  }); */

  return (
    <Sider
      breakpoint="lg"
      width={220}
      collapsible
      collapsed={isCollapsed}
      onCollapse={() => setIsCollapsed((prev) => !prev)}
      ref={(el) => {
        if (el) {
          el.style.setProperty('height', '100vh', 'important');
        }
      }}
      style={{
        overflow: 'auto',
        position: 'sticky',
        top: 0,
        left: 0,
        backgroundColor: 'white'
      }}>
      <div className="text-lg font-bold pt-5 px-3 pb-3">MENU</div>
      <Menu
        mode="inline"
        items={menuItems}
        onSelect={menuSelected}
        defaultOpenKeys={['3', '4', '5']}
        selectedKeys={currentActiveForm ? [`Q_${currentActiveForm}`] : ['Q_0']}
        inlineIndent={12}
        disabled={!isSessionStart}
      />
    </Sider>
  );
};

export default PosMenu;
