import CustomSearch, { SelectProps } from '..';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { IProductDetails } from '@/services/products/types';
import { get_product_list_only } from '@/services/products/queries';
import { message } from 'antd';

type MultipleProps =
  | {
      defaultValue?: number;
      isMultiple?: false;
      setSelected?: (selected?: IProductDetails) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      defaultValue?: number[];
      isMultiple: true;
      setSelected?: (selected?: IProductDetails[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps & MultipleProps;

function ProductSearchV2({ setSelected, ...props }: Props) {
  return (
    <CustomSearch
      formFor="product"
      setSelected={(product) => {
        if (product && !Array.isArray(product)) {
          const currentProduct = product as IProductDetails;
          const hasDefaultUnit = currentProduct.productUnits?.find((unit) => unit.isDefault);
          if (!hasDefaultUnit) {
            message.warning('This product does not have a default unit', 5);
          }
        }

        setSelected?.(product);
      }}
      addCallback={ProductsDB.addProducts}
      serverCallback={get_product_list_only}
      dbSearchById={ProductsDB.getProductDetails}
      dbSearchCallback={ProductsDB.searchProduct}
      {...props}
    />
  );
}

export default ProductSearchV2;
