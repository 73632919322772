import { AccountType } from '@/services/accounts/enums';
import LocalStore from '.';
import { IAccountTypeResponseData } from '../../services/accounts/types';

export default class AccountsDB extends LocalStore {
  /* Accounts Function */

  static async searchAccountByName(name: string, limit = 10, isLoactionNull?: string) {
    if (!AccountsDB.db) await AccountsDB.init();
    return new Promise((resolve: (value: IAccountTypeResponseData[]) => void, reject) => {
      const filteredAccounts: IAccountTypeResponseData[] = [];
      const transaction = AccountsDB.db.transaction('Accounts', 'readonly');
      const objectStore = transaction.objectStore('Accounts');
      const lowercaseString = name.toLowerCase();

      const cursorRequest = objectStore.index('AccountsSecondIndex').openCursor();

      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          const item = cursor.value;
          if (isLoactionNull) {
            if (item.name && item.name.includes(lowercaseString) && item.locationId === null)
              filteredAccounts.push(cursor.value);
          } else {
            if (item.name && item.name.includes(lowercaseString))
              filteredAccounts.push(cursor.value);
          }

          if (filteredAccounts.length < limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(filteredAccounts);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async searchAccountByNameAndLocation(
    name: string,
    locationId: number,
    limit = 10,
    isLocationNull?: string
  ) {
    if (!AccountsDB.db) await AccountsDB.init();
    return new Promise((resolve: (value: IAccountTypeResponseData[]) => void, reject) => {
      const filteredAccounts: IAccountTypeResponseData[] = [];
      const transaction = AccountsDB.db.transaction('Accounts', 'readonly');
      const objectStore = transaction.objectStore('Accounts');
      const lowercaseString = name.toLowerCase();
      const cursorRequest = objectStore.index('AccountsSecondIndex').openCursor();

      cursorRequest.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (
          cursor &&
          (cursor.value.locationId === locationId || cursor.value.locationId === null)
        ) {
          const item = cursor.value;

          if (isLocationNull) {
            if (item.name && item.name.includes(lowercaseString) && item.locationId === null)
              filteredAccounts.push(cursor.value);
          } else {
            if (item.name && item.name.includes(lowercaseString))
              filteredAccounts.push(cursor.value);
          }

          if (filteredAccounts.length < limit) cursor.continue();
        }
      };
      cursorRequest.onerror = (event: any) => {
        reject(event.target.error);
      };
      transaction.oncomplete = (event: any) => {
        resolve(filteredAccounts);
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async getAllAccounts() {
    if (!AccountsDB.db) await AccountsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = AccountsDB.db.transaction('Accounts', 'readonly');
      transaction.oncomplete = (event) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Accounts');
      const request = objectStore.getAll();
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async addAccounts(accountsData: IAccountTypeResponseData[]) {
    if (!AccountsDB.db) await AccountsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = AccountsDB.db.transaction('Accounts', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject('failed to find accounts table in LC');
      };
      const objectStore = transaction.objectStore('Accounts');
      accountsData.forEach(async (account) => {
        const checkKey = objectStore.count(account.id);
        checkKey.onsuccess = async (event: any) => {
          account.lowercaseName = account.name.toLowerCase();
          if (checkKey.result == 0) {
            const request = objectStore.add(account);
            request.onerror = (event: any) => {
              reject(event.target.error);
            };
          } else {
            await AccountsDB.updateAccount(account);
          }
        };
      });
    });
  }

  static async updateAccount(account: IAccountTypeResponseData) {
    if (!AccountsDB.db) await AccountsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = AccountsDB.db.transaction('Accounts', 'readwrite');
      transaction.oncomplete = (event: any) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Accounts');
      const request = objectStore.get(account.id);
      request.onsuccess = (event: any) => {
        if (event.target.result) {
          account.lowercaseName = account.name.toLowerCase();
          const requestUpdate = objectStore.put(account);
          requestUpdate.onerror = (event: any) => {
            reject(event.target.error);
          };
          requestUpdate.onsuccess = (event) => {
            resolve('updated data');
          };
        } else reject('Error, could not find id.');
      };
      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async getAccount(id: number | string) {
    if (!AccountsDB.db) await AccountsDB.init();

    return new Promise<IAccountTypeResponseData>((resolve, reject) => {
      const transaction = AccountsDB.db.transaction('Accounts', 'readonly');
      transaction.oncomplete = (event: any) => {
        // resolve('success');
      };
      transaction.onerror = (event: any) => {
        // reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Accounts');

      if (typeof id === 'number') {
        const request = objectStore.get(id);
        request.onsuccess = (event: any) => {
          resolve(event.target.result);
        };
        request.onerror = (event: any) => {
          reject(event.target.error);
        };
      } else if (typeof id === 'string') {
        const lowercaseString = id.toLowerCase();
        const request = objectStore.index('AccountsSecondIndex').openCursor();

        request.onsuccess = (event: any) => {
          if (event.target.result) {
            const item = event.target.result.value;

            if (item.name && item.name.includes(lowercaseString))
              resolve(event.target.result.value);
          }
        };
        request.onerror = (event: any) => {
          reject(event.target.error);
        };
      }
    });
  }

  static async getAccountByReferenceId(referenceId: number) {
    if (!AccountsDB.db) await AccountsDB.init();

    return new Promise<IAccountTypeResponseData | null>((resolve, reject) => {
      const transaction = AccountsDB.db.transaction('Accounts', 'readonly');

      const objectStore = transaction.objectStore('Accounts');
      const index = objectStore.index('AccountsSecondIndex');
      const request = index.openCursor();

      request.onsuccess = (event: any) => {
        const cursor = event.target.result;

        if (cursor) {
          const account = cursor.value;
          if (account.referenceId === referenceId) {
            resolve(account);
          } else {
            cursor.continue();
          }
        } else {
          resolve(null);
        }
      };

      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  }

  static async removeAllAccounts() {
    if (!AccountsDB.db) await AccountsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = AccountsDB.db.transaction(['Accounts'], 'readwrite');
      transaction.oncomplete = (event) => {
        resolve('success');
      };
      transaction.onerror = (event: any) => {
        reject(event.target.error);
      };
      const objectStore = transaction.objectStore('Accounts');
      const objectStoreRequest = objectStore.clear();
      objectStoreRequest.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
      objectStoreRequest.onerror = (event: any) => {
        reject(event.target.result);
      };
    });
  }

  static async searchAccountByNameTypeLocation(
    name: string,
    type?: keyof typeof AccountType,
    locationId?: number,
    limit = 10
  ) {
    if (!AccountsDB.db) await AccountsDB.init();
    return new Promise<IAccountTypeResponseData[] | null>((resolve, reject) => {
      const transaction = AccountsDB.db.transaction('Accounts', 'readonly');
      const objectStore = transaction.objectStore('Accounts');
      const index = objectStore.index('AccountsSecondIndex');
      const request = index.openCursor();

      const results: IAccountTypeResponseData[] = [];
      let count = 0;

      request.onerror = (event: any) => {
        reject(event.target.error);
      };

      request.onsuccess = (event: any) => {
        const cursor = event.target.result;

        if (!cursor || count >= limit) {
          resolve(results.length ? results : null);
        } else {
          const account = cursor.value as IAccountTypeResponseData;
          const lowerName = account?.name?.toLowerCase();
          const isNameIncluded = lowerName?.includes(name.toLowerCase());

          if (isNameIncluded) {
            if (type && type !== account.type) {
              cursor.continue();
              return;
            }

            if (locationId && account.locationId && locationId !== account.locationId) {
              cursor.continue();
              return;
            }

            results.push(account);
            count++;
          }

          cursor.continue();
        }
      };
    });
  }

  static async deleteAccount(id: number) {
    if (!AccountsDB.db) await AccountsDB.init();

    if (await AccountsDB.getAccount(id)) {
      return new Promise((resolve, reject) => {
        const transaction = AccountsDB.db.transaction('Accounts', 'readwrite');
        transaction.oncomplete = (event: any) => {
          resolve('success');
        };
        transaction.onerror = (event: any) => {
          reject(event.target.error);
        };
        const objectStore = transaction.objectStore('Accounts');
        const request = objectStore.delete(id);
        request.onsuccess = (event: any) => {
          resolve('deleted successfuly.');
        };
        request.onerror = (event: any) => {
          reject(event.target.error);
        };
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve('id does not exist');
      });
    }
  }
}
