import { useEffect, useRef, useState } from 'react';

import { getUserData } from '@/utils/auth.utils';
import DashboardMainProvider, { useDashboardMain } from '@/contexts/dashboard-main.context';
import { calculateColSize } from '@/services/dashboard/v3/services.dashboard';
import DashboardHeader from './main/DashboardHeader';
import EmptyLayout from '@/components/Common/EmptyLayout';
import WidgetLayout from './main/WidgetLayout';
import { cn } from '@/utils';
import { Spin } from 'antd';

const breakpoints = [
  { width: 1200, cols: 12 },
  { width: 992, cols: 10 },
  { width: 768, cols: 8 },
  { width: 650, cols: 6 },
  { width: 0, cols: 1 }
];

function DashboardComponent() {
  const userData = getUserData();
  const { dashboardLayout, isLoading } = useDashboardMain();
  const [gridSize, setGridSize] = useState({ cols: 12 });

  const [widgetColSize, setWidgetColSize] = useState(
    dashboardLayout.map((widget) => widget.colSize)
  );

  const colSizeHashMap = useRef(new Map());

  // Set the initial grid size
  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      let cols = gridSize.cols;
      for (const breakpoint of breakpoints) {
        if (width >= breakpoint.width) {
          cols = breakpoint.cols;
          break;
        }
      }

      setGridSize({ cols });
      const hasColSize = colSizeHashMap.current.get(cols);
      if (hasColSize) {
        setWidgetColSize(hasColSize);
        return;
      }

      const calculatedColSize = calculateColSize(
        cols,
        dashboardLayout.map((widget) => widget.colSize)
      );
      colSizeHashMap.current.set(cols, calculatedColSize);
      setWidgetColSize(calculatedColSize);
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const style = gridSize.cols === 1 ? {} : { gridTemplateColumns: `repeat(${gridSize.cols}, 1fr)` };

  return (
    <Spin spinning={isLoading}>
      <div className="px-2 py-1 space-y-8">
        <DashboardHeader username={userData.name?.split(' ')[0]} />
        {dashboardLayout.length === 0 && (
          <EmptyLayout description="Your dashboard layout is currently empty. Start customizing to create your personalized dashboard." />
        )}
        {dashboardLayout.length > 0 && (
          <div
            className={cn('gap-4', {
              grid: gridSize.cols !== 1,
              'flex flex-col': gridSize.cols === 1
            })}
            style={style}>
            {dashboardLayout.map((widget, index) => (
              <WidgetLayout key={widget.id} widget={widget} colSize={widgetColSize[index]} />
            ))}
          </div>
        )}
      </div>
    </Spin>
  );
}

function DashboardV3() {
  return (
    <DashboardMainProvider>
      <DashboardComponent />
    </DashboardMainProvider>
  );
}
export default DashboardV3;
