import { useState } from 'react';
import { ISystemLogs } from '../../../services/logs/types';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { get_log_details } from '../../../services/logs/queries';
import AppContent from '../../../components/Common/Content/Content';
import { Divider, PageHeader, Spin } from 'antd';
import { checkAccess } from '../../../routes/acl';
import ReuseChannel from '../../channel/Reuse';
import { User } from '../../../services/users/types';
import { convertUTCStringtoLocalString } from '../../../utils/convertToUTC';
import UsersDB from '../../../store/localstorage/UsersDB';
import { get_user_details } from '../../../services/users/queries';
import { ILogEvents } from '../../../services/logs/events/types';
import { get_log_events_details } from '../../../services/logs/events/queries';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const LogView: React.FC = () => {
  const { id } = useParams();

  const [logDetails, setLogDetails] = useState<ISystemLogs>();
  const [userDetails, setUserDetails] = useState<User>();
  const [eventDetails, setEventDetails] = useState<ILogEvents>();
  const { isLoading } = useQuery(['logView', id], async () => {
    if (!id) {
      return;
    }
    const response = await get_log_details(parseFloat(id));
    fetchUsers(response);
    fetchEvents(response);
    setLogDetails(response);
  });
  async function fetchUsers(response: ISystemLogs) {
    if (response.userId) {
      let userData = await UsersDB.getUser(response.userId);

      if (!userData) {
        const userDetails = await get_user_details(response.userId);
        await UsersDB.addUsers([userDetails.user]);
        userData = userDetails.user;
      }
      setUserDetails(userData);
    }
  }
  async function fetchEvents(response: ISystemLogs) {
    if (response.eventsId) {
      const eventDetail = await get_log_events_details(response.eventsId);
      setEventDetails(eventDetail);
    }
  }

  const breadcrumbItems = [
    {
      label: 'Logs',
      link: '/logs'
    },
    {
      label: 'View'
    }
  ];
  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Spin spinning={isLoading}>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mb-5">
            <div>
              <PageHeader
                subTitle="Event Name"
                style={{
                  padding: '8px 0px'
                }}
              />
              {eventDetails && <div style={{ color: 'black' }}>{eventDetails.url}</div>}
            </div>
            <div>
              <PageHeader
                subTitle=" IP"
                style={{
                  padding: '8px 0px'
                }}
              />
              {logDetails && <div style={{ color: 'black' }}>{logDetails.ip}</div>}
            </div>
            <div>
              <PageHeader
                subTitle=" Method"
                style={{
                  padding: '8px 0px'
                }}
              />
              {logDetails && <div style={{ color: 'black' }}>{logDetails.method}</div>}
            </div>
            <div>
              <PageHeader
                subTitle="URL"
                style={{
                  padding: '8px 0px'
                }}
              />
              {logDetails && <div style={{ color: 'black' }}>{logDetails.url}</div>}
            </div>
            <div>
              <PageHeader
                subTitle="Status Code"
                style={{
                  padding: '8px 0px'
                }}
              />
              {logDetails && <div style={{ color: 'black' }}>{logDetails.statusCode}</div>}
            </div>
            <div>
              <PageHeader
                subTitle="Content Length"
                style={{
                  padding: '8px 0px'
                }}
              />
              {logDetails && <div style={{ color: 'black' }}>{logDetails.contentLength}</div>}
            </div>
            <div>
              <PageHeader
                subTitle="Request Body"
                style={{
                  padding: '8px 0px'
                }}
              />
              {logDetails && <div style={{ color: 'black' }}>{logDetails.requestBody}</div>}
            </div>
            <div>
              <PageHeader
                subTitle="Response Time"
                style={{
                  padding: '8px 0px'
                }}
              />
              {logDetails && <div style={{ color: 'black' }}>{logDetails.responseTime}</div>}
            </div>
            <div>
              <PageHeader
                subTitle="Response Body"
                style={{
                  padding: '8px 0px'
                }}
              />
              {logDetails && <div style={{ color: 'black' }}>{logDetails.responseBody}</div>}
            </div>
            <div>
              <PageHeader
                subTitle="Start Time"
                style={{
                  padding: '8px 0px'
                }}
              />
              {logDetails && (
                <div style={{ color: 'black' }}>
                  {convertUTCStringtoLocalString(logDetails.startTime, DEFAULT_DATE_FORMAT)}
                </div>
              )}
            </div>
            <div>
              <PageHeader
                subTitle="End Time"
                style={{
                  padding: '8px 0px'
                }}
              />
              {logDetails && (
                <div style={{ color: 'black' }}>
                  {convertUTCStringtoLocalString(logDetails.endTime, DEFAULT_DATE_FORMAT)}
                </div>
              )}
            </div>
            <div>
              <PageHeader
                subTitle="Created At"
                style={{
                  padding: '8px 0px'
                }}
              />
              {logDetails && (
                <div style={{ color: 'black' }}>
                  {convertUTCStringtoLocalString(logDetails.createdAt, DEFAULT_DATE_FORMAT)}
                </div>
              )}
            </div>
            <div>
              <PageHeader
                subTitle="Updated At"
                style={{
                  padding: '8px 0px'
                }}
              />
              {logDetails && (
                <div style={{ color: 'black' }}>
                  {convertUTCStringtoLocalString(logDetails.updatedAt, DEFAULT_DATE_FORMAT)}
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 mb-5">
            <PageHeader
              subTitle="User"
              style={{
                padding: '8px 0px'
              }}
            />
            {userDetails && userDetails.name && (
              <div style={{ color: 'black' }}> Name: {userDetails.name}</div>
            )}
            {userDetails && userDetails.email && (
              <div style={{ color: 'black' }}> Email: {userDetails.email}</div>
            )}
          </div>

          <Divider />
        </Spin>
        {checkAccess('READ_CHANNEL') && (
          <ReuseChannel
            slug={`logs_${id}`}
            withReferenceandId={{ reference: 'logs', referenceId: id || 0 }}
          />
        )}
      </AppContent>
    </div>
  );
};

export default LogView;
