import { Divider } from 'antd';
import { useEffect } from 'react';

interface IDrawer {
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  showDrawer: boolean;
  title?: string;
  footer: JSX.Element;
  children: React.ReactNode;
}

export const ReusableDrawer: React.FC<IDrawer> = ({
  setShowDrawer,
  showDrawer,
  title,
  children,
  footer = <></>
}) => {
  const closeDrawer = () => {
    setShowDrawer(false);
  };

  useEffect(() => {
    document.body.style.overflow = showDrawer ? 'hidden' : 'auto';
  }, [showDrawer]);

  return (
    <div
      className={`fixed inset-0 bg-gray-800 bg-opacity-25 z-[1001] transition-opacity duration-300 ${
        showDrawer ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
      }`}>
      <div className="fixed inset-0 z-0" onClick={closeDrawer}></div>
      <div className="fixed top-0 right-0 bottom-0 max-h-[100vh] w-full md:w-1/3 lg:w-1/4 transition-transform duration-300 ">
        <div className="flex h-full flex-col justify-between p-6 bg-gray-100 overflow-y-auto gap-2">
          <div className="grid grid-cols-1 gap-2">
            {title && (
              <>
                <Divider>{title}</Divider>
              </>
            )}
            {children}
          </div>
          <div className="flex flex-row mt-4 md:mt-0 justify-end gap-2">{footer}</div>
        </div>
      </div>
    </div>
  );
};
