export const tableExportColumns = [
  {
    label: 'Product',
    dataIndex: 'product_name',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Location',
    dataIndex: 'location_name',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Total Wastage',
    dataIndex: 'total_wastage',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      total_wastage: `${parseFloat(record.total_wastage)} ${record.unit}`
    };
  }) as any;
}
