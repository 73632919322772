import moment from 'moment';
import { Button, Form, message } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { PlusOutlined } from '@ant-design/icons';

import RenderInputField from './RenderInputField';
import getPascalCase from '@/utils/getPascalCase';
import { ExpenseColumnDataType } from '@/services/expense/enum';
import { ExpenseCategoryColumnItem } from '@/services/expense/expense-category/types';
import CustomButton from '@/components/Common/CustomButton/CustomButton';

interface FormListFunction {
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
}

interface Props {
  form: FormInstance;
  columns: ExpenseCategoryColumnItem[];
}

function CategoryContent({ columns, form }: Props) {
  const columnWithoutBoolean = columns.filter(
    (column) => column.dataType !== ExpenseColumnDataType.BOOLEAN
  );

  const columnWithBoolean = columns.filter(
    (column) => column.dataType === ExpenseColumnDataType.BOOLEAN
  );

  const selectedCategory = form.getFieldValue('categoryId');

  function handleAddData(add: FormListFunction['add']) {
    // get initial undefined values for each column, false for boolean datatype and if expenseId is present make it empty string
    const initialValues = columns.reduce((acc, column) => {
      const isBoolean = column.dataType === ExpenseColumnDataType.BOOLEAN;
      const isExpenseId = column.name === 'expenseId';
      const isDate = column.dataType === ExpenseColumnDataType.DATE;

      acc[column.name] = isBoolean ? false : isExpenseId ? 0 : isDate ? moment() : undefined;
      return acc;
    }, {} as Record<string, any>);

    add(initialValues);
  }

  return (
    <Form.List name="categoryContent">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name }, index) => (
            <div key={key} className="flex gap-2 items-center">
              <span className="font-bold text-sm mb-5">{index + 1}.</span>
              <div
                className="card w-full"
                style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
                  {columnWithoutBoolean.map((column) => {
                    const isNullable = column.isNullable;
                    const isExpenseId = column.name === 'expenseId';
                    const isRequired = !isNullable && !isExpenseId;
                    const isMediaIds = column.name === 'mediaIds';

                    const columnFormName = isMediaIds ? 'mediaFiles' : column.name;

                    return (
                      <Form.Item
                        className="form-space"
                        key={column.name}
                        label={getPascalCase(column.name).replace(/Ids?$/, '')}
                        hidden={isExpenseId}
                        name={[name, columnFormName]}
                        rules={[
                          {
                            required: isRequired,
                            message: `Please provide ${getPascalCase(column.name).replace(
                              /Ids?$/,
                              ''
                            )}`
                          }
                        ]}>
                        <RenderInputField
                          key={`render-input-${column.name}-${selectedCategory}`}
                          column={column}
                          onSelect={(value: any) => {
                            form.setFieldValue(['categoryContent', name, columnFormName], value);
                          }}
                        />
                      </Form.Item>
                    );
                  })}

                  {columnWithBoolean.map((column) => {
                    const isExpenseId = column.name === 'expenseId';

                    return (
                      <Form.Item
                        key={column.name}
                        label={getPascalCase(column.name)}
                        hidden={isExpenseId}
                        name={[name, column.name]}
                        valuePropName="checked">
                        <RenderInputField
                          key={`render-input-${column.name}-${selectedCategory}`}
                          column={column}
                          onSelect={(value: number) => {
                            form.setFieldValue(['categoryContent', name, column.name], value);
                          }}
                        />
                      </Form.Item>
                    );
                  })}

                  <div className="flex items-center justify-start mt-[1.875rem] h-fit">
                    <CustomButton
                      marginLeft="0"
                      backgroundColor="white"
                      text="Remove"
                      textColor="green"
                      onClick={() => {
                        const isSingle = fields.length === 1;
                        if (isSingle) {
                          return message.error('You cannot remove the last item');
                        }

                        remove(name);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}

          <Form.Item>
            <Button
              type="dashed"
              onClick={() => handleAddData(add)}
              block
              style={{ width: '100%' }}>
              <PlusOutlined /> Add Category Content
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
}

export default CategoryContent;
