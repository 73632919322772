import { CloseOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, Input, InputNumber, message, PageHeader, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AppContent from '../../../components/Common/Content/Content';
import CustomSubmitButton from '../../../components/Common/CustomButton/CustomSubmitButton';
import { get_image } from '../../../components/Common/FetchImage/FetchImage';
import GoBackButton from '../../../components/Common/GoBackButton/GoBackButton';
import { API_URL } from '../../../constants/config';
import useDebounce from '../../../hooks/useDebounce';
import { create_channels_mutation } from '../../../services/channels/mutations';
import { ICreateChannel } from '../../../services/channels/types';
import { get_user_list } from '../../../services/users/queries';
import { getUserData } from '../../../utils/auth.utils';
import { get_channel_reasons_list } from '../../../services/channels/queries';
import { IReason } from '../../../services/adjustments/types';
const { Option } = Select;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function CreateChannel() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [channelReasonList, setChannelReasonsList] = useState<IReason[]>([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [responsize, setResponsize] = useState(false);
  //product search
  const [searchValue, setSearchValue] = useState<string>('');
  const [userSearch, setUserSearch] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const loggedInUser = getUserData();
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    const slug = searchParams.get('slug');
    // setUserList([loggedInUser]);
    const formValues = {
      name: '',
      slug: '',
      members: [loggedInUser.id]
    };
    if (slug) formValues.slug = slug;
    form.setFieldsValue(formValues);
    // setUserList([...userList,userDetails?.data.user])
    fetchUserImage(loggedInUser.id);
  }, []);
  useEffect(() => {
    searchUser(debouncedSearchValue);
  }, [debouncedSearchValue]);

  // console.log('LoggedInUser-->', loggedInUser);

  const createChannelMutation = useMutation(create_channels_mutation);
  // This is needed to get the product details update as its written
  const breadcrumbItems = [
    {
      label: 'Tickets',
      link: '/channel'
    },
    {
      label: 'Create',
      link: '/channel/new'
    }
  ];

  useQuery(['channel_reasons'], async () => {
    const response = await get_channel_reasons_list();
    setChannelReasonsList(response.data);
    setIsLoading(false);
    return response;
  });

  const onFinish = (values: ICreateChannel) => {
    setIsLoading(true);
    // values.reference = 'sell';
    // values.referenceId = 0;
    if (values.reference === 'other' || !values.reference)
      (values.reference = 'other'), (values.referenceId = 0);

    // console.log('values', values);
    if (values.members.length < 2) {
      message.error('Please choose atleast 2 members!');
      setIsLoading(false);
    } else {
      // values.members.push(loggedInUser.id);
      // console.log(values);
      createChannelMutation.mutateAsync(values, {
        onSuccess: async ({ data }: { data: any }) => {
          if (data) {
            message.success('Channel created successfully');
            setIsLoading(false);
            navigate('/channel');
          }
        },
        onError: (e: any) => {
          message.error(`${e.response.data.message}`, 5);
          setIsLoading(false);
        }
      });
    }
  };
  const searchUser = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_user_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any user with that name!');
        setUserSearch([]);
      } else {
        setUserSearch(response.data.results);
        checkUsersAndAdd(response.data.results);
      }
    }
  };
  const checkUsersAndAdd = (users: any[]) => {
    if (userList.length > 0) {
      users = users.filter((value) => {
        const searchProduct = userSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (users.length > 0)
        setUserList((prevValue) => {
          return [...prevValue, ...users];
        });
    } else {
      setUserList(users);
    }
  };
  const DisplayUserName = (name: any) => {
    const selectedUser = form.getFieldValue(['members', name]);
    // const imgData = await get_image(`${API_URL}storage/media/${selectedUser}`);
    const user = userList.find((val) => val.id == selectedUser);
    // console.log('UserList-->', userList);
    // console.log('selected User-->', selectedUser);
    // console.log('User--->', user);
    return (
      <>
        {user?.imgSrc ? (
          user.imgSrc === 'error' ? (
            <UserOutlined />
          ) : (
            <img src={user.imgSrc} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
          )
        ) : (
          <Spin indicator={antIcon} />
        )}
        <span>{user?.name}</span>
      </>
    );
  };

  const options = userSearch.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.name}
    </Option>
  ));

  useEffect(() => {
    if (window.innerWidth <= 650) {
      // setCollapsed(true);
      setResponsize(true);
    } else {
      setResponsize(false);
    }
    function handleResize() {
      if (window.innerWidth <= 650) {
        // setCollapsed(true);
        setResponsize(true);
      } else {
        setResponsize(false);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const fetchUserImage = async (selectedId: number | string) => {
    // const selectedUser = form.getFieldValue(['members', name]);
    // const imgData = await get_image(`${API_URL}storage/media/${selectedUser}`);
    try {
      // console.log('selected user for imgage-->', selectedId);
      const imgData = await get_image(`${API_URL}storage/media/${selectedId}`);
      // console.log('img Data->', imgData);
      // console.log('Previous UserList', userList);
      // let newUserList: any[] = [];
      setUserList((prev: any) => {
        if (prev.length === 0) {
          return [{ ...loggedInUser, imgSrc: imgData }];
          // console.log('loggedinuser', loggedInUser);
        } else {
          return prev.map((val: any) => {
            if (val.id == selectedId) {
              return {
                ...val,
                imgSrc: imgData
              };
            } else {
              return val;
            }
          });
        }
      });
      // if (userList.length === 0) {
      //   newUserList = [{ ...loggedInUser, imgSrc: imgData }];
      //   console.log('loggedinuser', loggedInUser);
      // } else {
      //   newUserList = userList.map((val) => {
      //     if (val.id == selectedId) {
      //       return {
      //         ...val,
      //         imgSrc: imgData
      //       };
      //     } else {
      //       return val;
      //     }
      //   });
      // }
      // // console.log('New user List', newUserList);
      // setUserList(newUserList);
    } catch (err) {
      // let newUserLists: any[] = [];
      // if (userList.length === 0) {
      //   newUserLists = [{ ...loggedInUser, imgSrc: 'error' }];
      // } else {
      //   newUserLists = userList.map((val) => {
      //     if (val.id == selectedId) {
      //       return {
      //         ...val,
      //         imgSrc: 'error'
      //       };
      //     } else {
      //       return val;
      //     }
      //   });
      // }
      // console.log('New user List', newUserLists);
      // setUserList(newUserLists);
      setUserList((prev: any) => {
        if (prev.length === 0) {
          return [{ ...loggedInUser, imgSrc: 'error' }];
        } else {
          return prev.map((val: any) => {
            if (val.id == selectedId) {
              return {
                ...val,
                imgSrc: 'error'
              };
            } else {
              return val;
            }
          });
        }
      });
    }
  };

  const referenceoptions = [
    'purchase',
    'purchase_order',
    'purchase_return',
    'transfer',
    'adjustment',
    'sell',
    'sell_order',
    'sell_return',
    'other'
  ];

  const handleChange = () => {
    console.log(form.getFieldValue('reference'));
  };

  const selectAllUsers = async () => {
    const allUsers = await get_user_list(0, 1000);
    // console.log('allUsers', allUsers);
    if (allUsers?.data?.results) {
      const tobeAddedUsers = allUsers.data.results.filter((curr: any) => {
        const isAlreadyPresent = userList.find((cur: any) => cur.id == curr.id);
        if (isAlreadyPresent) {
          return false;
        } else {
          return true;
        }
      });
      setUserList((prev: any) => [...prev, ...tobeAddedUsers]);
      const prevarray = form.getFieldValue(['members']);
      for (let ind = 0; ind < tobeAddedUsers.length; ind++) {
        prevarray.push(tobeAddedUsers[ind].id);
        fetchUserImage(tobeAddedUsers[ind].id!);
      }
      form.setFieldValue(['members'], prevarray);
      // checkUsersAndAdd(allUsers.data.results)
    }
  };

  // console.log('userList ', userList);

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        button={<GoBackButton onClick={() => navigate('/channel')} />}>
        <PageHeader
          title="Create Ticket"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          validateTrigger={'onChange'}
          // onValuesChange={(_, allFields) => {
          //   setProductDetails(allFields);
          // }}
          autoComplete="off">
          <div className={responsize ? 'grid grid-cols-1' : 'grid grid-cols-2 gap-5 mb-5'}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Slug"
              name="slug"
              rules={[{ required: true, message: 'Please add slug!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Color Code"
              name="color"
              rules={[{ required: true, message: 'Please add color code!' }]}>
              <Select
                placeholder="Select Color Code"
                style={{ borderRadius: '10px' }}
                allowClear
                dropdownMatchSelectWidth={false}>
                <Option value={'red'} key={'red'}>
                  Red
                </Option>
                <Option value={'green'} key={'green'}>
                  Green
                </Option>
                <Option value={'yellow'} key={'yellows'}>
                  Yellow
                </Option>
                {/* {paymentOptions.map((value: any) => {
                          return (
                            <Select.Option value={value} key={value}>
                              {value}
                            </Select.Option>
                          );
                        })} */}
              </Select>
            </Form.Item>
            <Form.Item
              label="Priority"
              name="priority"
              rules={[{ required: true, message: 'Please add priority!' }]}>
              <Select
                placeholder="Select Priority"
                style={{ borderRadius: '10px' }}
                allowClear
                dropdownMatchSelectWidth={false}>
                <Option value={'high'} key={'high'}>
                  High
                </Option>
                <Option value={'medium'} key={'medium'}>
                  Medium
                </Option>
                <Option value={'low'} key={'low'}>
                  Low
                </Option>
                {/* {paymentOptions.map((value: any) => {
                          return (
                            <Select.Option value={value} key={value}>
                              {value}
                            </Select.Option>
                          );
                        })} */}
              </Select>
            </Form.Item>
            <Form.Item
              label="Reference"
              name="reference"
              rules={[{ required: false, message: 'Please add color code!' }]}>
              <Select
                placeholder="Select Reference"
                style={{ borderRadius: '10px' }}
                allowClear
                onChange={() => handleChange()}
                dropdownMatchSelectWidth={false}>
                {referenceoptions.map((curroptions: any) => {
                  return (
                    <Option value={curroptions} key={curroptions}>
                      {curroptions}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Reference Id"
              name="referenceId"
              rules={[{ required: false, message: 'Please add color code!' }]}>
              {/* <Select
                placeholder="Enter Id"
                style={{ borderRadius: '10px' }}
                allowClear
                dropdownMatchSelectWidth={false}> */}
              <InputNumber controls={false} />
              {/* </Select> */}
            </Form.Item>

            <Form.Item
              label="Reason"
              name="reasonId"
              rules={[{ required: true, message: 'Please Select reason!' }]}>
              <Select
                placeholder="Select Reason"
                style={{ borderRadius: '10px' }}
                allowClear
                // onChange={() => handleChange()}
                dropdownMatchSelectWidth={false}>
                {channelReasonList
                  .filter((curr: any) => !curr.isArchived)
                  .map((value: any) => {
                    return (
                      <Option value={value.id} key={value.id}>
                        {value.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            {/* <Form.Item
              label="Is Closed?"
              name="closed"
              rules={[{ required: false, message: 'Please add color code!' }]}
              hidden>
              <Checkbox value={false}></Checkbox>
            </Form.Item> */}
          </div>
          <Form.List name={['members']}>
            {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
              <>
                <div className={'grid mb-5'}>
                  <PageHeader
                    subTitle="Add Users"
                    style={{
                      padding: '8px 0px 8px 10px'
                    }}
                  />
                  <Select
                    showSearch
                    placeholder={'search user'}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(val) => {
                      setSearchValue(val);
                    }}
                    onSelect={(val: number | string) => {
                      if (val == 'all') {
                        // console.log('all selected');
                        selectAllUsers();
                        return;
                      }
                      if (loggedInUser.id == val) {
                        message.error('Cannot add self, already added.');
                        return;
                      }
                      const selectedUsers = form.getFieldValue(['members']);
                      const exists = selectedUsers.find((value: any) => value == val);
                      if (exists) {
                        message.error('Already Added');
                        return;
                      }
                      add2(val);
                      fetchUserImage(val);
                    }}
                    notFoundContent={null}>
                    <Option value={'all'}>All</Option>
                    {options}
                  </Select>
                  {fields2.length > 0 && (
                    <PageHeader
                      title="Selected Users"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                  )}
                </div>
                <div className="flex flex-wrap gap-3 mb-5">
                  {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                    <div
                      key={key2}
                      className="card"
                      style={{
                        borderRadius: '15px',
                        backgroundColor: '#e5f5fb',
                        padding: '0px 2px 0px 0px'
                      }}>
                      <div className="flex items-center justify-center gap-2" key={key2}>
                        <Form.Item {...restField2} name={[name2]} hidden>
                          <Input type={'text'} disabled />
                        </Form.Item>
                        {DisplayUserName(name2)}
                        {parseInt(form.getFieldValue(['members', name2])) !==
                          parseInt(loggedInUser.id) && (
                          <div className="flex justify-center items-center">
                            {fields2.length > 0 ? (
                              <CloseOutlined
                                className="dynamic-delete-button flex justify-center items-center"
                                style={{ color: '#0AA245' }}
                                onClick={() => remove2(name2)}
                                // disabled={name2 === loggedInUser.id}
                                disabled={true}
                              />
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </Form.List>
          <div className="flex justify-end mt-5">
            <Form.Item>
              {/* <Button type="primary" htmlType="submit">
                Submit
              </Button> */}
              <CustomSubmitButton text={'Create & Chat'} />
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default CreateChannel;
