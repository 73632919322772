import { CloseOutlined } from '@ant-design/icons';
import { Drawer, Menu, MenuProps } from 'antd';

import freshktmfulllogo from './logo/freshktmfulllogo.png';
import { MenuItemsType } from '@/constants/menuItems/menuItems';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useEffect, useState } from 'react';

interface Props {
  showDrawer: boolean;
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  locationMenu: string;
  menu?: MenuItemsType[];
  openKeys?: string[];

  onOpenChange?: MenuProps['onOpenChange'];
}

function MenuDrawer({
  showDrawer,
  menu,
  openKeys,
  setShowDrawer,
  locationMenu,
  onOpenChange
}: Props) {
  const dynamicProps = {} as any;
  const [drawerWidth, setDrawerWidth] = useState('250px');

  useEffect(() => {
    if (onOpenChange) dynamicProps['onOpenChange'] = onOpenChange;
    if (openKeys) dynamicProps['openKeys'] = openKeys;
  }, []);

  useEffect(() => {
    document.body.style.overflow = showDrawer ? 'hidden' : 'auto';
  }, [showDrawer]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 450) {
        setDrawerWidth('100%');
      } else {
        setDrawerWidth('250px');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Drawer
      style={{ position: 'fixed', zIndex: 10000000 }}
      title={
        <div
          style={{ width: drawerWidth }}
          className="bg-white justify-between flex items-center fixed h-[63px] z-[100000] top-0 left-0 text-white px-4">
          <div className="flex items-center justify-center">
            <img src={freshktmfulllogo} style={{ width: '130px' }} />
          </div>
          <CloseOutlined
            onClick={() => setShowDrawer((prev) => !prev)}
            style={{ color: 'black' }}
          />
        </div>
      }
      bodyStyle={{ padding: 0 }}
      placement="left"
      width={drawerWidth}
      getContainer={false}
      visible={showDrawer}
      closable={false}
      onClose={() => setShowDrawer((prev) => !prev)}>
      <div style={{ height: '25px' }}></div>
      <Menu
        mode="inline"
        className="font-medium"
        selectedKeys={[locationMenu]}
        items={menu as ItemType[]}
        onClick={() => {
          setShowDrawer(false);
        }}
        {...dynamicProps}
        style={{ height: '10px' }}
      />
    </Drawer>
  );
}

export default MenuDrawer;
