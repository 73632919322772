import { createContext, useContext, useRef, useState } from 'react';
import { Form, FormInstance } from 'antd';

import SellList from '.';

export const SellListContext = createContext<IContext>({} as IContext);

interface IContext {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<any>;
  urlData: string;
  setUrlData: React.Dispatch<React.SetStateAction<string>>;
}
export const SellListContextComp: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();
  const [urlData, setUrlData] = useState('');
  return (
    <SellListContext.Provider
      value={{
        isLoading,
        setIsLoading,
        form,
        urlData,
        setUrlData
      }}>
      <SellList />
    </SellListContext.Provider>
  );
};
