import { CloseOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';

import freshktmfulllogo from './logo/freshktmfulllogo.png';
import { useEffect, useState } from 'react';

interface Props {
  showDrawer: boolean;
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  content: string;
}

function InfoDrawer({ showDrawer, setShowDrawer, content }: Props) {
  const [drawerWidth, setDrawerWidth] = useState('250px');

  useEffect(() => {
    document.body.style.overflow = showDrawer ? 'hidden' : 'auto';
  }, [showDrawer]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 450) {
        setDrawerWidth('100%');
      } else {
        setDrawerWidth('250px');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Drawer
      className="md:hidden "
      style={{ position: 'fixed', zIndex: 10000 }}
      title={
        <div
          style={{ width: drawerWidth }}
          className="bg-white justify-between flex items-center absolute h-16 z-[100000] top-0 left-0 px-4">
          <div className="flex items-center justify-center">
            <img src={freshktmfulllogo} style={{ width: '130px' }} />
          </div>
          <CloseOutlined
            onClick={() => setShowDrawer((prev) => !prev)}
            style={{ color: 'black' }}
          />
        </div>
      }
      bodyStyle={{ padding: 0 }}
      placement="right"
      width={drawerWidth}
      getContainer={false}
      visible={showDrawer}
      closable={false}
      onClose={() => setShowDrawer((prev) => !prev)}>
      <div style={{ height: '25px' }}></div>
      <div style={{ height: 'calc(100% - 64px)' }} className="text-editor thin-scrollbar ql-editor">
        <div dangerouslySetInnerHTML={{ __html: content }} className="m-0" />
      </div>
    </Drawer>
  );
}

export default InfoDrawer;
