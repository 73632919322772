import { useState } from 'react';
import { Collapse, message, PageHeader, Spin } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';

import withReportPagination, {
  PaginatedReportValues,
  WithReportPaginationProps
} from '@/components/Common/Report/withReportPagination';
import { download_report, get_report } from '@/services/report/queries';
import { check_date_cap, check_export_report_date_cap } from '@/utils/common';
import { showReportConditionalDownloadMessage } from '@/utils/reportConditionalDownloadMessage';
import AppContent from '@/components/Common/Content/Content';
import FilterForm from './Form';
import TableData from './Table';

import { ICustomSellReport } from '@/services/report/types';
import { getUpdatedData } from './column.export';

export type ExtendedInitialValues = PaginatedReportValues & {
  constraints: {
    customerId: string | number;
    categoryId: string | number;
    locationId: string | number;
    routeId: string | number;
    createdBy: string | number;
  };
};

function SellCustomReportBase({
  form,
  initialValues,
  pagination,
  ...handlers
}: WithReportPaginationProps) {
  const breadcrumbItems = [
    { label: 'Reports', link: '/reports' },
    { label: 'Custom Sell Report', link: '/reports/sell-custom' }
  ];

  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fullExportURL, setFullExportURL] = useState<any>(null);

  const [data, setData] = useState({
    total: 0,
    data: [] as ICustomSellReport[]
  });

  const state = { id: 62, name: 'Custom Sell Report' };
  const extendedInitialValues = {
    ...initialValues,
    constraints: {
      ...initialValues.constraints,
      customerId: '',
      categoryId: '',
      locationId: '',
      routeId: '',
      createdBy: '',
      type: ''
    }
  };

  async function onSearch(values: ExtendedInitialValues) {
    setIsLoading(true);
    const { constraints } = values;

    try {
      const hasDateCap = check_date_cap(constraints.startDate, constraints.endDate);
      if (hasDateCap) {
        await onDownload();
        return;
      }

      const updatedConstraints = await handlers.getConstraint();

      const { data } = await get_report(updatedConstraints, state.id);
      if (data.data.length === 0) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }

      // Get all constraints excluding page, size
      const payload = updatedConstraints as any;
      payload.size = data.count;

      setFullExportURL(payload);
      setData({ total: data.count, data: data.data });
      return data.data;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function onSearchAll() {
    try {
      setIsLoading(true);
      if (!fullExportURL) {
        message.error('No URL specified. Please search first.');
        return;
      }
      const { data } = await get_report(fullExportURL, state.id);
      return getUpdatedData(data.data) as any;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function onDownload() {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsLoading(true);
    try {
      await form.validateFields();

      const constraints = await handlers.getConstraint();
      if (!constraints) return;

      const hasDateCap = check_export_report_date_cap(constraints.startDate, constraints.endDate);

      if (!hasDateCap) return;
      const customReportQueueName = 'Sells Report';

      message.loading({
        key: 'downloading',
        content: `${customReportQueueName} Downloading`,
        duration: 0
      });
      await download_report({ ...constraints, customReportQueueName }, state.id);
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Collapse>
          <CollapsePanel header="Filters" key="1">
            <FilterForm
              form={form}
              isLoading={isLoading}
              initialValues={extendedInitialValues}
              onSearch={onSearch}
              onDownload={onDownload}
              onChange={handlers.onChange}
              onDisabledDate={handlers.onDisabledDate}
              onChangeNepali={handlers.onChangeNepali}
            />
          </CollapsePanel>
        </Collapse>

        <PageHeader subTitle="Table" style={{ padding: '8px 0px' }} />
        <TableData
          data={data}
          pagination={pagination}
          onSearch={onSearch}
          onSearchAll={onSearchAll}
          onChange={handlers.onChange}
          onPagination={handlers.onPagination}
        />
      </AppContent>
    </Spin>
  );
}

const SellCustomReport = withReportPagination(SellCustomReportBase);
SellCustomReport.displayName = 'SellCustomReport';

export default SellCustomReport;
