import { Checkbox, Form, Input, PageHeader, Select } from 'antd';
import React from 'react';

const ProductInfo = ({ form }: { form: any }) => {
  // console.log('printing type', printingtype);
  return (
    <div>
      <PageHeader
        title="Table Product details"
        style={{
          padding: '8px 0px',
          fontSize: '14px !important'
        }}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 gap-5 mb-5">
        <Form.Item label="Product label" name="productLabel">
          <Input />
        </Form.Item>

        <Form.Item label="Quantity label" name="quantityLabel">
          <Input />
        </Form.Item>

        <Form.Item label="Unit Price Label" name="unitPriceLabel">
          <Input />
        </Form.Item>
        <Form.Item label="VAT in Column Label" name="vatinColumnLabel">
          <Input />
        </Form.Item>
        <Form.Item label="Subtotal Label" name="subTotalLabel">
          <Input />
        </Form.Item>

        <Form.Item label="Total quantity label" name="totalQuantityLabel">
          <Input />
        </Form.Item>

        {/* <Form.Item label="Category or HSN code Label" name="categoryCodeLabel">
          <Input />
        </Form.Item> */}
      </div>
      {/* 
      <div className="grid grid-cols-4 gap-5 mb-2">
        <Form.Item name="showBrand" valuePropName="checked" className="mb-0">
          <Checkbox className="w-100">Show brand</Checkbox>
        </Form.Item>

        <Form.Item name="showSKU" valuePropName="checked" className="mb-0">
          <Checkbox className="w-100">Show SKU</Checkbox>
        </Form.Item>

        <Form.Item name="showCategoryCodeOrHSNCode" valuePropName="checked" className="mb-0">
          <Checkbox className="w-100">Show category code or HSN code</Checkbox>
        </Form.Item>

        <Form.Item name="showSaleDescription" valuePropName="checked" className="mb-0">
          <Checkbox className="w-100">Show sale description</Checkbox>
        </Form.Item>

        <Form.Item name="productDetailsCustomField1" valuePropName="checked">
          <Checkbox className="w-100">Custom field 1</Checkbox>
        </Form.Item>

        <Form.Item name="productDetailsCustomField2" valuePropName="checked">
          <Checkbox className="w-100">Custom field 2</Checkbox>
        </Form.Item>

        <Form.Item name="productDetailsCustomField3" valuePropName="checked">
          <Checkbox className="w-100">Custom field 3</Checkbox>
        </Form.Item>

        <Form.Item name="productDetailsCustomField4" valuePropName="checked">
          <Checkbox className="w-100">Custom field 4</Checkbox>
        </Form.Item>

        <Form.Item name="showProductExpiry" valuePropName="checked">
          <Checkbox className="w-100">Show Product Expiry</Checkbox>
        </Form.Item>

        <Form.Item name="showLotNumber" valuePropName="checked">
          <Checkbox className="w-100">Show Lot Number</Checkbox>
        </Form.Item>

        <Form.Item name="showProductImage" valuePropName="checked">
          <Checkbox className="w-100">Show Product Image</Checkbox>
        </Form.Item>

        <Form.Item name="showWarrentyName" valuePropName="checked">
          <Checkbox className="w-100">Show Warrenty Name</Checkbox>
        </Form.Item>

        <Form.Item name="showWarrentyExpiryDate" valuePropName="checked">
          <Checkbox className="w-100">Show warrenty expiry date</Checkbox>
        </Form.Item>

        <Form.Item name="showWarrentyDescription" valuePropName="checked">
          <Checkbox className="w-100">Show Warrenty Description</Checkbox>
        </Form.Item>
      </div> */}
    </div>
  );
};

export default ProductInfo;
