import { getValue } from '@testing-library/user-event/dist/utils';
import { Button, Divider, message, Select } from 'antd';
import React, { useState, useEffect, BaseSyntheticEvent, SyntheticEvent, useRef } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { get_product_list } from '../../../services/products/queries';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import { getUserData } from '../../../utils/auth.utils';

interface Iprops {
  add2: any;
  name?: any;
  locationId?: number;
}

const ProductSearchForOffer = ({ add2, name, locationId }: Iprops) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);
  const [productList, setProductList] = useState<any>([]);
  const [productSearch, setProductSearch] = useState<any>([]);
  const { Option } = Select;
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);

  if (!locationId) {
    const { preferences } = getUserData();
    locationId = preferences?.preferences
      ? JSON.parse(preferences?.preferences)?.locationId
      : message.error('Please choose a location from preferences!');
  }

  const searchProductIndexDB = async (value: string) => {
    try {
      let response = await ProductsDB.searchProduct(value);
      response = response.filter(
        (val) =>
          !val.hidden && val.status.some((el: any) => el.locationId === locationId && !el.hidden)
      );
      if (!response || response.length == 0) {
        message.info('Cannot find any product with that name in cache, searching in server...');
        searchProduct(value);
      } else {
        setProductSearch(response);
        checkProductAndAdd(response);
        setIsCacheResponse(true);
      }
    } catch (error) {
      message.error(error as string);
    }
  };
  const searchProduct = async (value: any) => {
    let response;
    try {
      response = await get_product_list(0, 10, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any product with that name!');
      setProductSearch([]);
      setIsMore(false);
    } else {
      setProductSearch(response.data.results);
      checkProductAndAdd(response.data.results);
      ProductsDB.addProducts(response.data.results);
      if (response.data.results.length < 10) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
    }
    setIsCacheResponse(false);
    setSkip(10);

    // setSearchLoading(false);
  };

  const searchMoreProduct = async (value: any) => {
    let response;
    try {
      response = await get_product_list(skip, 10, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.info('Cannot find more products with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);
      setProductSearch([...productSearch, ...response.data.results]);
      checkProductAndAdd(response.data.results);
      ProductsDB.addProducts(response.data.results);

      if (response.data.results.length < 10) {
        setIsMore(false);
      }
    }
  };
  useEffect(() => {
    searchProductIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const checkProductAndAdd = (products: any[]) => {
    if (productList.length > 0) {
      products = products.filter((value) => {
        const searchProduct = productSearch.find((val: any) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (products.length > 0)
        setProductList((prevValue: any) => {
          return [...prevValue, ...products];
        });
    } else {
      setProductList(products);
    }
  };
  const options = productSearch
    .filter(
      (val: any) =>
        !val.hidden && val.status.some((el: any) => el.locationId === locationId && !el.hidden)
    )
    .map((d: any) => (
      <Option key={d.id} value={d.id}>
        {`${d.name} (${d.sku})`}
      </Option>
    ));

  // const handleInputKeyDown = (val: any) => {
  //   if (val.code === 'Tab' && autofocusRef) {
  //     val.preventDefault();
  //     const element = document.getElementById('productSearch-custom');
  //     const clickEvt: MouseEvent = new MouseEvent('click', {
  //       bubbles: true,
  //       cancelable: true
  //     });
  //     console.log(clickEvt, element);
  //     if (element) element.dispatchEvent(clickEvt);
  //   }
  //   // if (selectRef.current) {
  //   //   selectRef.current.blur();
  //   // }
  // };

  return (
    <>
      <Select
        // ref={autofocusRef}
        id="productSearch-custom"
        // onInputKeyDown={(val) => handleInputKeyDown(val)}
        showSearch
        placeholder={'Search product'}
        defaultActiveFirstOption={false}
        style={{ borderRadius: '10px' }}
        autoClearSearchValue={true}
        // value={''}
        showArrow={false}
        filterOption={false}
        // onClick={(val) => {
        //   console.log(val);
        //   console.log('clicked');
        // }}
        onSearch={(val) => {
          setSearchValue(val);
        }}
        onSelect={async (val: any) => {
          const selectedProduct = productSearch.find((value: any) => value.id == val);
          const hasDefaultUnit = selectedProduct?.productUnits?.find((unit: any) => unit.isDefault);
          if (!hasDefaultUnit) {
            message.warning('This product does not have a default unit', 5);
          }

          add2({
            productId: val,
            productName: selectedProduct.name,
            type: 'percentage',
            minimumQuantity: 0,
            minimumAmount: 0,
            percentage: 0,
            flat: 0
          });

          // setselected('');
          //   if (name !== undefined) {
          //     // console.log('this runs');
          //     await onProductChange(val, name); //this is for procruement create
          //   } else {
          //     await onProductChange(val);
          //   }
        }}
        notFoundContent={null}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            {isCacheResponse ? (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                {/* <div
                  style={{
                    color: 'green',
                    width: '100%',
                    textAlign: 'center'
                  }}>{`Cache found ${productSearch.length} data`}</div> */}
                <Button
                  type="text"
                  style={{
                    color: 'blue',
                    width: '100%'
                  }}
                  onClick={() => {
                    searchProduct(searchValue);
                  }}>
                  {/* {'Sync & Search on Server'} */}
                  {'Pull More & Sync'}
                </Button>
              </div>
            ) : (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                {isMore ? (
                  <Button
                    type="text"
                    style={{
                      color: 'blue',
                      width: '100%'
                    }}
                    onClick={() => {
                      searchMoreProduct(searchValue);
                    }}>
                    {/* {'Sync & Search on Server'} */}
                    {'Get More...'}
                  </Button>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center'
                    }}>
                    No more data...
                  </div>
                )}
              </div>
            )}
          </>
        )}>
        {options}
      </Select>
    </>
  );
};

export default ProductSearchForOffer;
