import { AxiosResponse } from 'axios';
import {
  IAccountOpening,
  IAccountTypeResponseData,
  ICreateAccounts,
  ICreateAccountsRule,
  ICreateAccountsRuleResponse,
  ICreateAutoAccounts,
  ICreateJournal,
  ICreateJournalResponse,
  IDuplicateAccountWithChildren,
  IMergeAccounts,
  ISwitchAccountMultiple,
  ISwitchAccountResponse,
  ITrialBalanceResponse,
  ITrialBalanceResponseData,
  IUpdateAccountRules,
  IUpdateAccounts
} from './types';
import http from '../../utils/http.utils';

export const create_account_type_mutation = async (values: ICreateAccounts) => {
  const response: AxiosResponse<IAccountTypeResponseData, any> = await http.store(
    'account',
    values
  );
  return response;
};

export const create_multiple_account_mutation = async (values: ICreateAccounts[]) => {
  const response: AxiosResponse<any> = await http.store('account/multiple', values);
  return response;
};

export const create_duplicate_account_with_children_mutation = async (
  values: IDuplicateAccountWithChildren
) => {
  const response: AxiosResponse<any> = await http.store('account/duplicate-children', values);
  return response;
};

export const merge_accounts = async (values: IMergeAccounts) => {
  const response: AxiosResponse<IAccountTypeResponseData, any> = await http.store(
    'account/merge-accounts',
    values
  );
  return response;
};

export const getAccountsByParentIds = async (skip: number, count: number, parentIds: number[]) => {
  const response: AxiosResponse<ITrialBalanceResponse, any> = await http.get('account/parents/', {
    parentIds,
    skip,
    count
  });
  return response.data;
};

export const update_account_type_mutation = async (values: IUpdateAccounts) => {
  const response: AxiosResponse<IAccountTypeResponseData, any> = await http.update(
    'account/' + values.id,
    values
  );
  return response;
};

export const create_journal_mutation = async (values: ICreateJournal) => {
  const response: AxiosResponse<ICreateJournalResponse, any> = await http.store(
    'account-journal',
    values
  );
  return response;
};

export const create_account_rule_mutation = async (values: ICreateAccountsRule) => {
  const response: AxiosResponse<ICreateAccountsRuleResponse, any> = await http.store(
    'account-rules',
    values
  );
  return response;
};

export const update_account_rule_mutation = async (values: IUpdateAccountRules) => {
  const response: AxiosResponse<ICreateAccountsRuleResponse, any> = await http.update(
    'account-rules/' + values.id,
    values
  );
  return response;
};

export const create_auto_account_type_mutation = async (values: ICreateAutoAccounts) => {
  const response: AxiosResponse<IAccountTypeResponseData, any> = await http.store(
    'account/auto-generate-user',
    values
  );
  return response;
};

export const switch_account_parent_mutation = async (values: ISwitchAccountMultiple) => {
  const response: AxiosResponse<ISwitchAccountResponse> = await http.store(
    'account/move-parents',
    values
  );
  return response;
};

export const create_account_opening = async (values: { historyId: number; newBalance: number }) => {
  const response: AxiosResponse<IAccountOpening> = await http.store('account-opening', values);
  return response.data;
};
