import EmptyLayout from '@/components/Common/EmptyLayout';
import CustomizeDashboardProvider, {
  useDashboardCustomize
} from '@/contexts/dashboard-customize.context';
import useBeforeUnloadListener from '@/hooks/useBeforeUnloadListener';
import { Alert, Button, Spin } from 'antd';
import { useState } from 'react';
import CustomizeHeader from './CustomizeHeader';
import DraggableGrid from './dnd/DraggableGrid';
import { defaultDashboardLayout } from '../constant.dashboard';

function CustomizeDashboardcomponent() {
  const { isLoading, editingWidgets, setEditingWidgets } = useDashboardCustomize();
  const [gridSize] = useState({ cols: 12 });

  //Warn user before leaving the page
  useBeforeUnloadListener();

  async function onAddWidget() {
    setEditingWidgets(defaultDashboardLayout);
  }

  return (
    <Spin spinning={isLoading}>
      <div className="space-y-4">
        <CustomizeHeader />
        {editingWidgets.length > 0 && (
          <>
            <Alert
              message="Drag and drop widgets to customize your dashboard layout. Selected dates will be saved when you save the layout."
              type="info"
              showIcon
            />
            <DraggableGrid gridCols={gridSize.cols} />
          </>
        )}
        {editingWidgets.length === 0 && (
          <EmptyLayout description="Your dashboard layout is currently empty. Start customizing to create your personalized dashboard.">
            <Button type="primary" onClick={onAddWidget}>
              Use Default Layout
            </Button>
          </EmptyLayout>
        )}
      </div>
    </Spin>
  );
}

function CustomizeDashboard() {
  return (
    <CustomizeDashboardProvider>
      <CustomizeDashboardcomponent />
    </CustomizeDashboardProvider>
  );
}

export default CustomizeDashboard;
