import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import CustomerDiscountUI from '../CustomerDiscountUI';
import { get_customer_discount_details } from '@/services/offfers/queries';

function UpdateCustomerDiscount() {
  const { id } = useParams();

  if (!id) {
    return <h1>Customer Expense Discount not found</h1>;
  }

  const breadcrumbItems = [
    { label: 'Customer Discount', link: '/offer/customer-discount' },
    { label: 'Details', link: `/offer/customer-discount/${id}` }
  ];

  const { data, isLoading } = useQuery(
    ['expense-category', id],
    async () => get_customer_discount_details(parseInt(id)),
    {
      onError: () => {
        message.error('Failed to get customer range details');
      }
    }
  );

  return (
    <CustomerDiscountUI
      data={data}
      type="update"
      isParentLoading={isLoading}
      breadcrumbItems={breadcrumbItems}
    />
  );
}

export default UpdateCustomerDiscount;
