import { IHRCreateRequest, IHRCreateResponse, IHRUpdateRequest } from './types';
import http from '../../utils/http.utils';
import { AxiosResponse } from 'axios';
import { ICreateCommonResponse } from '../category/types';

export const create_hr_mutation = async (values: IHRCreateRequest) => {
  const response: AxiosResponse<IHRCreateResponse, any> = await http.store(
    'users/information',
    values
  );
  return response;
};
export const update_hr_mutation = async (values: IHRUpdateRequest) => {
  const response: AxiosResponse<ICreateCommonResponse, any> = await http.update(
    'users/information/' + values.id,
    values
  );
  return response;
};
