import { Button, Form, Spin, message } from 'antd';
import {
  IAccountTypeResponseData,
  IAccountTypeTableData,
  ISwitchAccountMultiple,
  ISwitchAccountResponse,
  ITrialBalanceTableData
} from '../../../services/accounts/types';
import { useEffect, useState } from 'react';
import AppContent from '../Content/Content';
import { AccountsTypeSearch } from '../AccountsTypeSearch';
import { useMutation } from '@tanstack/react-query';
import { switch_account_parent_mutation } from '../../../services/accounts/mutations';
import { AxiosError, AxiosResponse } from 'axios';
import { IServerError } from '../../../services/response/types';
import { AccountType } from '../../../services/accounts/enums';

interface ISwitchParent {
  data: ITrialBalanceTableData[] | IAccountTypeTableData[];
  locationId?: number;
  handleModalClose?: () => void;
}
export const AccountSwitchParent: React.FC<ISwitchParent> = ({
  data,
  locationId,
  handleModalClose
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [parentAccountList, setParentAccountList] = useState<IAccountTypeResponseData[]>([]);
  const [isNullLocationRequired, setIsNullLocationRequired] = useState<string | undefined>();
  // console.log('AccountSwitch', isLocationNull);

  const checkIfLocationNull = (data: ITrialBalanceTableData[] | IAccountTypeTableData[]) => {
    for (const record of data) {
      if (
        record.type === AccountType.TRANSFER_INITIATE ||
        record.type === AccountType.USER_PARENT ||
        record.type === AccountType.TRANSFER_RECEIVE ||
        record.type === AccountType.VENDOR_PARENT ||
        record.type === AccountType.CUSTOMER_PARENT
      ) {
        return 'true';
      }
    }
  };

  useEffect(() => {
    setIsNullLocationRequired(checkIfLocationNull(data));
    form.resetFields();
  }, [data]);

  const onFinish = async (values: { parentId: number }) => {
    console.log(values);
    const accounts = data.map((value) => {
      return { accountId: value.id, parentId: values.parentId };
    });
    const serverObj: ISwitchAccountMultiple = { accounts: accounts };
    await switchAccountsParentMutation.mutateAsync(serverObj);
  };

  const switchAccountsParentMutation = useMutation<
    AxiosResponse<ISwitchAccountResponse>,
    AxiosError<IServerError>,
    ISwitchAccountMultiple
  >(switch_account_parent_mutation, {
    onSuccess: () => {
      setIsLoading(false);
      message.success('Switched Parent successfully');
      if (handleModalClose) handleModalClose();
    },
    onError: (e) => {
      setIsLoading(false);
      message.error(`${e.response?.data.message}`);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <AccountsTypeSearch
            formData={{ formName: 'parentId', label: 'Parent Account' }}
            isAll={false}
            accountList={parentAccountList}
            setAccountList={setParentAccountList}
            required={true}
            locationId={locationId}
            isLocationNull={isNullLocationRequired}
            excludedAccount={data[0].parentId}
          />
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};
