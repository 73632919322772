import { Form, Select } from 'antd';
import { PaymentType } from '.././../../services/payments/enums';
const { Option } = Select;

const PayoutStatus: React.FC = () => {
  const paymentType = Object.entries(PaymentType);

  return (
    <Form.Item name="paymentStatus" label="Payout Status">
      <Select
        placeholder="Select a Payment Status"
        style={{ borderRadius: '10px' }}
        allowClear
        dropdownMatchSelectWidth={false}>
        {paymentType.map((value: any) => (
          <Option value={value[1]} key={value[1]}>
            {value[0]}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default PayoutStatus;
