import moment from 'moment';

import AppContent from '@/components/Common/Content/Content';
import getError from '@/utils/getError';
import { useMutation, useQuery } from '@tanstack/react-query';
import { PageHeader, Button, message, Spin, Modal } from 'antd';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import {
  get_purchase_agent_bill_cancel_list,
  get_purchase_agent_bill_details
} from '@/services/purchases/queries';
import UsersDB from '@/store/localstorage/UsersDB';
import { AgentsDB } from '@/store/localstorage/AgentsDB';
import { get_user_details } from '@/services/users/queries';
import { get_agent_by_id } from '@/services/agents/queries';
import { update_purchase_agent_bill_status } from '@/services/purchases/mutations';
import { useState } from 'react';
import { IPurchaseAgentBillCancel } from '@/services/purchases/types';
import { createEmptyPromise } from '@/utils/createEmptyPromise';
import { getImagesFromServer } from '@/services/upload/queries';
import Table from './Table';
import handlePagination from '@/utils/handlePagination';
import { ConvertObjectToURL } from '@/utils/converturl';

function ViewPurchaseBillRange() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [isTableLoading, setIsTableLoading] = useState(false);

  const [cancelledBill, setCancelledBill] = useState({
    total: 0,
    results: [] as IPurchaseAgentBillCancel[]
  });

  if (!id) {
    return <Navigate to="/purchases/agents-bill-range" replace />;
  }

  const breadcrumbs = [
    { label: 'Purchases', link: '/purchase' },
    { label: 'Agent Bill Range', link: '/purchases/agents-bill-range' },
    { label: 'View', link: `/purchases/agents-bill-range/view/${id}` }
  ];

  async function getInfo() {
    const data = await get_purchase_agent_bill_details(Number(id));
    const { createdBy, agentId } = data;
    if (createdBy) {
      let createdUser = await UsersDB.getUser(createdBy);
      if (!createdUser) {
        const response = await get_user_details(createdBy);
        await UsersDB.addUsers([response.user]);
        createdUser = await UsersDB.getUser(createdBy);
      }
      data.createdUser = createdUser?.name;
    }

    if (agentId) {
      let agent = await AgentsDB.getAgent(agentId);
      if (!agent) {
        const response = await get_agent_by_id(agentId);
        await AgentsDB.addAgents([response.data]);
        agent = await AgentsDB.getAgent(agentId);
      }

      data.agent = agent?.user?.name;
    }

    return data;
  }

  async function getCancelledBill(filter = '') {
    if (!id) return;
    const params = new URLSearchParams(filter);
    params.set('billRangeIds[]', id);

    const updatedFilter = params.toString();
    const response = await get_purchase_agent_bill_cancel_list(updatedFilter);

    const allMediaId = response.results.map((item) => item.mediaId).filter(Boolean) as number[];
    const medias =
      allMediaId.length > 0
        ? await getImagesFromServer(allMediaId)
        : await createEmptyPromise(null);

    for (const item of response.results) {
      const media = medias?.find((m) => m?.id === item?.mediaId);
      if (media) item.mediaURL = media.url;
    }

    setCancelledBill(response);
  }

  async function fetchInfoAndCancelledBill() {
    try {
      const infoPromise = getInfo();
      const cancelledPromise = getCancelledBill('skip=0&count=100');

      const [info] = await Promise.all([infoPromise, cancelledPromise]);
      return info;
    } catch (error) {
      message.error(getError(error));
    }
  }

  const { data, isLoading } = useQuery(['purchase-agent-bill', id], fetchInfoAndCancelledBill, {
    onSuccess: () => {
      setPagination({ page: 1, size: 100 });
    },
    onError: (error) => {
      message.error(getError(error));
    }
  });

  const { mutateAsync, isLoading: isUpdating } = useMutation(
    async () => update_purchase_agent_bill_status(Number(id)),
    {
      onSuccess: async () => {
        message.success(
          `Purchase Agent Bill ${data?.isArchived ? 'unarchived' : 'archived'} Successfully`
        );
        navigate('/purchases/agents-bill-range');
      },
      onError: (error) => {
        message.error(getError(error) || 'Failed to update purchase agent bill');
      }
    }
  );

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsTableLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: {},
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getCancelledBill(url);
    setIsTableLoading(false);
  };

  return (
    <Spin spinning={isLoading || isUpdating}>
      <Modal
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => {
          setIsModalOpen(false);
          mutateAsync();
        }}>
        <div>
          Are you sure you want to {data?.isArchived ? 'unarchive' : 'archive'} this purchase agent
          bill range?
        </div>
      </Modal>
      <AppContent breadcrumbItems={breadcrumbs}>
        <PageHeader title="Purchase Agent Bill Range Detail" style={{ padding: '8px 0px' }} />
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div>
            <PageHeader subTitle="Details" style={{ padding: '8px 0px' }} />
            <div>
              Agent: {data?.agent} (ID: {data?.agentId})
            </div>
          </div>

          <div>
            <PageHeader subTitle="Range" style={{ padding: '8px 0px' }} />
            <div>Minimum: {data?.min}</div>
            <div>Maximum: {data?.max}</div>
            <div>Current: {data?.current}</div>
          </div>

          <div>
            <PageHeader subTitle="Created Information" style={{ padding: '8px 0px' }} />
            <div>
              By: {data?.createdUser} (ID: {data?.createdBy})
            </div>
            <div>At: {moment(data?.createdAt).format(DEFAULT_DATE_FORMAT)}</div>
          </div>
        </div>

        <PageHeader subTitle="Cancelled Bill" style={{ padding: '8px 0px' }} />
        <Table
          isLoading={isTableLoading}
          data={cancelledBill}
          pagination={pagination}
          onPagination={onPagination}
        />

        <div className="flex justify-end gap-2 mt-8">
          <Button
            key="1"
            type="primary"
            danger={!data?.isArchived}
            onClick={() => setIsModalOpen(true)}>
            {data?.isArchived ? 'Unarchive' : 'Archive'}
          </Button>
          <Button key="2" onClick={() => navigate('/purchases/agents-bill-range')}>
            Back
          </Button>
        </div>
      </AppContent>
    </Spin>
  );
}

export default ViewPurchaseBillRange;
