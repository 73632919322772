import { useEffect, useRef, useState } from 'react';
import { IRow } from '../grid.types';
import { GRID_MIN_HEIGHT, GRID_ROW_HEIGHT } from '../create/constant';
import DataGrid, { DataGridHandle } from 'react-data-grid';
import { get_read_price_analysis_perms } from '@/routes/acl';
import { Navigate } from 'react-router-dom';
import { columnsB2BAndOutlet, columnsB2BOnly, columnsOutletOnly } from './columns';

interface Props {
  rows: IRow[];
}

function ViewPriceAnalysisGrid({ rows }: Props) {
  const [gridHeight, setGridHeight] = useState(GRID_MIN_HEIGHT);
  const gridRef = useRef<DataGridHandle>(null);

  useEffect(() => {
    const rowsTotalHeight = rows.length * GRID_ROW_HEIGHT + 106.8; // column header height
    const height = Math.min(rowsTotalHeight, 600);
    if (height > GRID_MIN_HEIGHT) {
      setGridHeight(height);
    }
  }, [rows]);

  const readUserPerms = get_read_price_analysis_perms();
  if (!readUserPerms) {
    return <Navigate to="/404" replace />;
  }

  const columns =
    readUserPerms === 'b2b-outlet'
      ? columnsB2BAndOutlet
      : readUserPerms === 'outlet'
      ? columnsOutletOnly
      : columnsB2BOnly;

  return (
    <DataGrid
      ref={gridRef}
      className="rdg-light"
      style={{ height: gridHeight }}
      columns={columns}
      rows={rows}
      rowHeight={GRID_ROW_HEIGHT}
      headerRowHeight={50}
      renderers={{
        noRowsFallback: (
          <div className="relative col-span-full">
            <div className="sticky top-0 h-full left-0 w-screen flex items-center justify-center">
              <p>No products have been added yet.</p>
            </div>
          </div>
        )
      }}
      defaultColumnOptions={{ resizable: true }}
      enableVirtualization
    />
  );
}

export default ViewPriceAnalysisGrid;
