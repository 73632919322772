import { Form, Select } from 'antd';
import { PurchaseType } from '../../../services/purchases/enums';

export const PurchaseTypeComp: React.FC = () => {
  return (
    <Form.Item label="Purchase Type" name="purchaseType">
      <Select placeholder="Select purchase type" allowClear>
        {(Object.keys(PurchaseType) as Array<keyof typeof PurchaseType>).map((key) => (
          <Select.Option key={key} value={key}>
            {PurchaseType[key]}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
