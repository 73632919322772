import { Form, InputNumber } from 'antd';
import { useEffect } from 'react';
import Report from '../..';
import moment from 'moment';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

interface DataType {
  address_line_1: string;
  address_line_2: string;
  city: string;
  country: string;
  district: string;
  id: number;
  landmark: string;
  latitude: number;
  longitude: number;
  municipality: number;
  postalCode: string;
  route_id: null;
  state: string;
  street: string;
  user_id: number;
  ward: string;
}

const AddressReport = () => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldValue('constraints', {
      page: 1,
      size: 10
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Address',
      link: '/reports/address'
    }
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      key: 'id',
      render: (record) => (
        <Link to={`/users/${record.user_id}/address/${record.id}`}>{record.id}</Link>
      )
    },
    {
      title: 'Name',
      dataIndex: 'userName',
      render: (text: string) => <a>{text}</a>
    },

    {
      title: 'City',
      key: 'city',
      render: (record) => (
        <Link to={`/users/${record.user_id}/address/${record.id}`}>{record.city}</Link>
      )
    },
    {
      title: 'District',
      key: 'district',
      render: (record) => (
        <Link to={`/users/${record.user_id}/address/${record.id}`}>{record.district}</Link>
      )
    },
    {
      title: 'Ward',
      key: 'ward',
      render: (record) => (
        <Link to={`/users/${record.user_id}/address/${record.id}`}>{record.ward}</Link>
      )
    },
    {
      title: 'Address_line_1',
      key: 'address_line_1',
      render: (record) => (
        <Link to={`/users/${record.user_id}/address/${record.id}`}>{record.address_line_1}</Link>
      )
    },
    {
      title: 'Address_line_2',
      key: 'address_line_2',
      render: (record) => (
        <Link to={`/users/${record.user_id}/address/${record.id}`}>{record.address_line_2}</Link>
      )
    },
    {
      title: 'Postal Code',
      key: 'postalCode',
      render: (record) => (
        <Link to={`/users/${record.user_id}/address/${record.id}`}>{record.postalCode}</Link>
      )
    }
  ];

  return (
    <Report
      breadcrumbItems={breadcrumbItems}
      state={{ id: 3, name: 'Address Report' }}
      form={form}
      columnsData={columns}>
      <div className="grid grid-cols-3 gap-5 ">
        <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
          <InputNumber controls={false} />
        </Form.Item>
        <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
          <InputNumber controls={false} />
        </Form.Item>
      </div>
    </Report>
  );
};

export default AddressReport;
