import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  IAdjustmentApproveAndRejectReponse,
  IAdjustmentList,
  IAdjustmentpost,
  ICreateReason,
  IReason,
  IUpdateReason
} from './types';

export const create_adjustments_mutation = async (values: any) => {
  const response: AxiosResponse<IAdjustmentList, any> = await http.store('adjustments', values);
  return response;
};
export const create_reason_mutation = async (values: ICreateReason) => {
  const response: AxiosResponse<IReason, any> = await http.store('adjustments/reasons', values);
  return response;
};
export const update_reason_mutation = async (values: IUpdateReason) => {
  const response: AxiosResponse<IReason, any> = await http.update(
    'adjustments/reasons/' + values.id,
    values
  );
  return response;
};

export const approved_adjustments_mutation = async (values: any) => {
  const { id } = values;
  delete values.id;
  const response: AxiosResponse<IAdjustmentApproveAndRejectReponse, any> = await http.update(
    'adjustments/approve/' + id,
    values
  );
  return response;
};

export const reject_adjustments_mutation = async (id: number | string) => {
  const response: AxiosResponse<IAdjustmentApproveAndRejectReponse, any> = await http.update(
    'adjustments/reject/' + id
  );
  return response;
};
