import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IBankResponseData, ICreateBank, IUpdateBank } from './types';

export const create_bank_mutation = async (values: ICreateBank) => {
  const response: AxiosResponse<IBankResponseData, any> = await http.store('bank', values);
  return response.data;
};

export const update_bank_mutation = async (values: IUpdateBank) => {
  const response: AxiosResponse<IBankResponseData, any> = await http.update(
    'bank/' + values.id,
    values
  );
  return response.data;
};
