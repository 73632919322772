import { useState, Dispatch, SetStateAction } from 'react';
import {
  Button,
  Checkbox,
  Dropdown,
  Menu,
  message,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Tooltip
} from 'antd';

import { ReusableDrawer } from './ReusableDrawer';
import { FilterFilled } from '@ant-design/icons';

type Options = {
  key: string;
  label: string;
  value: string;
};

type SelectedOptions = {
  type: string;
  file: string;
};

interface Props {
  open: boolean;
  title?: string;
  downloadTitle?: string;
  currentSelected?: SelectedOptions;
  options: Options[];
  fileOptions?: Options[];
  selectedColumns?: string[];
  allColumns?: string[];

  onExport: (options: SelectedOptions) => void;
  onOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedColumns?: Dispatch<SetStateAction<string[]>>;
}

const defaultFileOptions = [
  { key: 'export-detailed-ledger-excel', label: 'Excel', value: 'excel' },
  { key: 'export-detailed-ledger-pdf', label: 'PDF', value: 'pdf' }
];

/**
 * Default Export Options includes PDF and Excel. Default Selected is Excel
 * @param open - Drawer Open State
 * @param title - Drawer Title. Default is Export
 * @param downloadTitle - Download Button Title. Default is Download
 * @param currentSelected - Current Selected Export Option.
 * @param options - Export Options
 * @param fileOptions - File Format Options
 * @param onExport - Export Button Click Handler
 * @param onOpen - Drawer Open Handler
 * @returns Drawer Component
 */
function ExportDrawer({
  open,
  currentSelected,
  options,
  title = 'Export',
  downloadTitle = 'Download',
  fileOptions = defaultFileOptions,
  selectedColumns = [],
  allColumns = [],
  ...handler
}: Props) {
  const [selectedExport, setSelectedExport] = useState({
    type: currentSelected?.type || options[0].value,
    file: currentSelected?.file || fileOptions[0].value
  });

  const exportOptionsChange = (event: RadioChangeEvent) => {
    const { value } = event.target;
    setSelectedExport((prev) => ({ ...prev, type: value }));
  };

  const fileFormatChange = (value: string) => {
    setSelectedExport((prev) => ({ ...prev, file: value }));
  };

  const onExport = () => {
    handler.onExport(selectedExport);
  };

  function handleColumnFilter(column: string, checked: boolean) {
    if (selectedColumns.length <= 3 && checked === false) {
      message.error('At least three column should be selected');
      return;
    }

    let updatedColumns: string[];

    if (checked) {
      const index = allColumns.indexOf(column);
      updatedColumns = [...selectedColumns];
      updatedColumns.splice(index, 0, column);
    } else {
      updatedColumns = selectedColumns.filter((col) => col !== column);
    }

    handler.setSelectedColumns?.(updatedColumns);
  }

  return (
    <>
      <ReusableDrawer
        title={title}
        showDrawer={open}
        setShowDrawer={handler.onOpen}
        footer={
          <div className="flex w-full items-center justify-between">
            {/* // File Format Options  */}
            <div>
              <span>Choose File Format: </span>
              <Select
                placement="topLeft"
                defaultValue={selectedExport.file}
                onChange={(value) => fileFormatChange(String(value))}>
                {fileOptions.map((option) => (
                  <Select.Option key={option.key} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </div>

            {/* // Export Button */}
            <Button type="primary" onClick={onExport}>
              {downloadTitle}
            </Button>
          </div>
        }>
        {allColumns.length > 0 && (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu className="ant-dropdown-menu max-h-[20rem] overflow-scroll">
                {allColumns.map((column) => {
                  return (
                    <Menu.ItemGroup key={column}>
                      <Checkbox
                        onChange={(e) => handleColumnFilter(column, e.target.checked)}
                        checked={selectedColumns.includes(column)}>
                        {column}
                      </Checkbox>
                    </Menu.ItemGroup>
                  );
                })}
              </Menu>
            }>
            <a className="flex justify-end" onClick={(e) => e.preventDefault()}>
              <Tooltip title="Column Filter">
                <Button type="primary">
                  <FilterFilled style={{ scale: 1.3 }} />
                </Button>
              </Tooltip>
            </a>
          </Dropdown>
        )}
        {/* // Export Options */}
        <Radio.Group onChange={exportOptionsChange} value={selectedExport.type}>
          <Space direction="vertical">
            {options.map((option) => (
              <Radio key={option.key} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </ReusableDrawer>

      {/* // Export Drawer Open Button */}
      <div className="my-2 flex justify-end">
        <Button type="primary" onClick={() => handler.onOpen(true)}>
          {title}
        </Button>
      </div>
    </>
  );
}

export default ExportDrawer;
