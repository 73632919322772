import { AccountRulesEvent } from '@/services/accounts/enums';
import { get_adjustments_details_ids } from '@/services/adjustments/queries';
import { get_expense_details_ids } from '@/services/expense/queries';
import { get_payment_details_ids } from '@/services/payments/queries';

import {
  get_purchase_details_ids,
  get_purchase_return_details_ids
} from '@/services/purchases/queries';

import { get_sell_details_ids, get_sell_return_details_ids } from '@/services/sell/queries';
import { get_transfer_details_ids, get_transfer_in_details_ids } from '@/services/transfer/queries';

type JournalType = keyof typeof AccountRulesEvent;

export const typeToReferenceMap = {
  [AccountRulesEvent.SELL_CREATE]: 'sellReferenceIds',
  [AccountRulesEvent.SELL_PAYMENT_CASH]: 'paymentReferenceIds',
  [AccountRulesEvent.SELL_PAYMENT_BANK]: 'paymentReferenceIds',
  [AccountRulesEvent.SELL_PAYMENT_OTHER]: 'paymentReferenceIds',
  [AccountRulesEvent.PURCHASE_PAYMENT_CASH]: 'paymentReferenceIds',
  [AccountRulesEvent.PURCHASE_PAYMENT_USER]: 'paymentReferenceIds',
  [AccountRulesEvent.PURCHASE_PAYMENT_OTHER]: 'paymentReferenceIds',
  [AccountRulesEvent.SELL_RETURN]: 'sellReturnReferenceIds',
  [AccountRulesEvent.PURCHASE_CREATE]: 'purchaseReferenceIds',
  [AccountRulesEvent.PURCHASE_RETURN]: 'purchaseReturnReferenceIds',
  [AccountRulesEvent.EXPENSE_CREATE]: 'expenseReferenceIds',
  [AccountRulesEvent.ADJUSTMENT_CREATE]: 'adjustmentReferenceIds',
  [AccountRulesEvent.VAT_CREATE]: 'vatReferenceIds',
  [AccountRulesEvent.TRANSFER_FROM]: 'transferFromReferenceIds',
  [AccountRulesEvent.TRANSFER_TO]: 'transferToReferenceIds'
} as Record<JournalType, string>;

// Reverse the map
export const referenceToTypeMap = {
  sellReferenceIds: [AccountRulesEvent.SELL_CREATE],
  paymentReferenceIds: [
    AccountRulesEvent.SELL_PAYMENT_CASH,
    AccountRulesEvent.SELL_PAYMENT_BANK,
    AccountRulesEvent.SELL_PAYMENT_OTHER,
    AccountRulesEvent.PURCHASE_PAYMENT_CASH,
    AccountRulesEvent.PURCHASE_PAYMENT_USER,
    AccountRulesEvent.PURCHASE_PAYMENT_OTHER
  ],
  sellReturnReferenceIds: [AccountRulesEvent.SELL_RETURN],
  purchaseReferenceIds: [AccountRulesEvent.PURCHASE_CREATE],
  purchaseReturnReferenceIds: [AccountRulesEvent.PURCHASE_RETURN],
  expenseReferenceIds: [AccountRulesEvent.EXPENSE_CREATE],
  adjustmentReferenceIds: [AccountRulesEvent.ADJUSTMENT_CREATE],
  vatReferenceIds: [AccountRulesEvent.VAT_CREATE],
  transferFromReferenceIds: [AccountRulesEvent.TRANSFER_FROM],
  transferToReferenceIds: [AccountRulesEvent.TRANSFER_TO]
};

export const typeAPIs = {
  vatReferenceIds: get_sell_details_ids,
  sellReferenceIds: get_sell_details_ids,
  expenseReferenceIds: get_expense_details_ids,
  paymentReferenceIds: get_payment_details_ids,
  purchaseReferenceIds: get_purchase_details_ids,
  sellReturnReferenceIds: get_sell_return_details_ids,
  adjustmentReferenceIds: get_adjustments_details_ids,
  purchaseReturnReferenceIds: get_purchase_return_details_ids,

  transferFromReferenceIds: get_transfer_details_ids,
  transferToReferenceIds: get_transfer_in_details_ids
};

export const detailedLedgerColumns = [
  'SN',
  'Journal',
  'Account',
  'Debit',
  'Credit',
  'Running Balance',
  'Reference',
  'Ref Number',
  'Location',
  'Trn. Date',
  'Created Date'
];
