import { find_to_use_NPR } from '@/store/localstorage/preferences';

export const tableExportColumns = [
  {
    label: 'Location',
    dataIndex: 'location_name',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Product Name',
    dataIndex: 'product_name',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Total Amount',
    dataIndex: 'total_amount',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));
