import { Pagination, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { ExtendedInitialValues } from '.';
import { getInt } from '../pending-payment-sell-report/report';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { ICustomerNetSalesType } from '@/services/report/types';
import { find_to_use_NPR } from '@/store/localstorage/preferences';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { WithReportPaginationProps } from '@/components/Common/Report/withReportPagination';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { TableProps } from 'antd/es/table';
import ExportAllData from '@/components/Common/ExportAll';
import { excelExportColumns, getUpdatedData, tableExportColumns } from './column.export';
import ExportCurrent from '@/components/Common/ExportCurrent';

interface Props
  extends Pick<WithReportPaginationProps, 'onPagination' | 'onChange' | 'pagination'> {
  data: { total: number; data: ICustomerNetSalesType[] };
  onSearch: (values: ExtendedInitialValues) => void;
  onSearchAll: () => Promise<ICustomerNetSalesType[]>;
}

function TableData({ data, pagination, ...handlers }: Props) {
  const [_, setSortedInfo] = useState<SorterResult<any>>({});

  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const columns: ColumnsType<ICustomerNetSalesType> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 30,
      render: (_, __, index) => <TableCell>{index + 1}</TableCell>
    },
    {
      title: 'Customer Name',
      key: 'customer_name',
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
      render: (record) => <div>{record.customer_name}</div>,
      width: 100
    },
    {
      title: 'Route',
      key: 'route_name',
      sorter: (a, b) => a.route_name?.localeCompare(b.route_name),
      render: (a, record) => {
        return <div>{record.route_name}</div>;
      },
      width: 100
    },
    {
      title: 'Location',
      key: 'location_name',
      sorter: (a, b) => a.location_name?.localeCompare(b.location_name),
      render: (a, record) => {
        return <div>{record.location_name}</div>;
      },
      width: 100
    },
    {
      title: 'Net Sales',
      key: 'net_sales',
      align: 'right',
      className: 'highlight',
      sorter: (a, b) => getInt(a.net_sales) - getInt(b.net_sales),
      render: (record) => {
        return <div className="text-center">{nepaliNumberFormatter(record.net_sales)}</div>;
      },
      width: 50
    },
    {
      title: 'Number of Sales',
      key: 'no_of_sales',
      align: 'right',
      sorter: (a, b) => getInt(a.no_of_sales) - getInt(b.no_of_sales),
      render: (record) => {
        return <div className="text-center">{nepaliNumberFormatter(record.no_of_sales)}</div>;
      },
      width: 50
    },
    {
      title: 'Average Sales',
      key: 'avg',
      align: 'right',
      sorter: (a, b) => getInt(a.avg) - getInt(b.avg),
      render: (record) => {
        return <div className="text-center">{nepaliNumberFormatter(record.avg)}</div>;
      },
      width: 50
    }
  ];

  const total = { netSales: 0, numberOfSales: 0, avgSales: 0 };

  data.data?.forEach((item) => {
    const { net_sales, no_of_sales, avg } = item;

    total.netSales += getInt(net_sales);
    total.numberOfSales += getInt(no_of_sales);
    total.avgSales += getInt(avg);
  });

  return (
    <>
      <CustomizeTable
        columns={columns}
        tableName="customer-net-sales-report-table"
        data={data.data}
        toSort={handleChange}
        notshowPagination={true}
        customScroll={{ x: 1000, y: 500 }}
        tableSummary={
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3} className="text-center">
                {nepaliNumberFormatter(total.netSales)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} className="text-center">
                {nepaliNumberFormatter(total.numberOfSales)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} className="text-center">
                {nepaliNumberFormatter(total.avgSales)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        }
        buttons={
          <>
            <ExportAllData
              title="Customer Net Sales (All)"
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              getInfo={handlers.onSearchAll}
            />
            <ExportCurrent
              data={{ total: data.total, results: getUpdatedData(data.data) }}
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              title="Customer Net Sales"
            />
          </>
        }
        paginationDatas={{
          page: pagination.page,
          total: data.total,
          size: pagination.size,
          onPagination: (pageNo, currentSize) => {
            currentSize == pagination.size
              ? handlers.onPagination(handlers.onSearch, pageNo)
              : handlers.onPagination(handlers.onSearch, pageNo, currentSize);
          }
        }}
      />

      <div className="flex justify-end mt-4">
        <Pagination
          current={pagination.page}
          total={data.total}
          pageSize={pagination.size}
          pageSizeOptions={[100, 500, data.total]}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          onChange={(pageNo, currentSize) =>
            currentSize == pagination.size
              ? handlers.onPagination(handlers.onSearch, pageNo)
              : handlers.onPagination(handlers.onSearch, pageNo, currentSize)
          }
        />
      </div>
    </>
  );
}

export default TableData;
