import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import {
  get_account_all_parents,
  get_account_detail_by_ids,
  get_account_opening_id
} from '@/services/accounts/queries';
import UsersDB from '@/store/localstorage/UsersDB';
import AccountsDB from '@/store/localstorage/AccountsDB';
import { get_user_details } from '@/services/users/queries';
import { Input, InputNumber, message, PageHeader, Spin } from 'antd';
import AppContent from '@/components/Common/Content/Content';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { ISwitchAccountResponse } from '@/services/accounts/types';

function ViewFinancialHistory() {
  const { id } = useParams();
  const [affectedAccounts, setAffectedAccounts] = useState<ISwitchAccountResponse[]>([]);

  if (!id) {
    return <h1>Financial History not found</h1>;
  }

  const { isLoading, data } = useQuery(
    ['financial_history', id],
    async () => {
      const accountOpening = await get_account_opening_id(parseInt(id));
      const { createdBy, accountId } = accountOpening;
      let user = await UsersDB.getUser(createdBy);
      if (!user) {
        user = (await get_user_details(createdBy)).user;
        await UsersDB.addUsers([user]);
      }

      let account = await AccountsDB.getAccount(accountId);
      if (!account) {
        const accounts = await get_account_detail_by_ids([accountId]);
        await AccountsDB.addAccounts(accounts.results);
        account = await AccountsDB.getAccount(accountId);
      }

      const allAccounts = await get_account_all_parents(accountId);
      setAffectedAccounts(allAccounts);

      return {
        ...accountOpening,
        accountName: account.name,
        createdUser: user?.name
      };
    },
    {
      onError: () => {
        message.error('Failed to get account opening details');
      }
    }
  );

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={[
          { label: 'Accounts', link: '/accounts' },
          { label: 'Accounts Opening', link: '/accounts/opening' },
          { label: 'View', link: `/accounts/opening/${id}` }
        ]}>
        <PageHeader title="Account Information" style={{ padding: '8px 0px' }} />
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Selected Account</label>
            <Input readOnly value={data?.accountName} className="!w-full" />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">History Balance</label>
            <InputNumber
              controls={false}
              readOnly
              value={data?.historyBalance}
              addonBefore="Rs"
              className="!w-full"
              formatter={(value) => nepaliNumberFormatter(Number(value))}
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">New Balance</label>
            <InputNumber
              controls={false}
              readOnly
              type="number"
              className="!w-full"
              addonBefore="Rs"
              value={data?.newBalance}
            />
          </div>
        </div>

        {affectedAccounts && affectedAccounts.length > 0 && (
          <div className="mt-4">
            <PageHeader title="Affected Accounts" style={{ padding: '2px 0px' }} />
            <p className="export-paragraph">
              The following {affectedAccounts.length === 1 ? 'account was' : 'accounts were'}{' '}
              affected by this transaction:
            </p>

            <div className="flex flex-wrap items-center gap-4">
              {affectedAccounts.map((item) => (
                <Link
                  key={item.id}
                  to={`/accounts/detailed-ledger?accountId=${item.id}`}
                  style={{ border: '1px solid #c3c4c7' }}
                  className="px-4 py-2 rounded-md hover:bg-gray-50 cursor-pointer">
                  <span className="text-blue-500">{item.name}</span>
                </Link>
              ))}
            </div>
          </div>
        )}
      </AppContent>
    </Spin>
  );
}

export default ViewFinancialHistory;
