import { useContext, useEffect } from 'react';
import { WebSocketContext } from './websocket.context';
import { SocketEvents } from '@/constants/websocketConfig';
import { ISystemNotification } from '@/services/users/types';
import { update_global_preferences, update_user_permissions } from '@/routes/acl';
import {
  IACLGroupChangeEvent,
  IACLPermissionChangeEvent,
  INotifications
} from '@/services/notifications/types';
import { NotificationEvents } from '@/services/notifications/enums';

interface Props {
  children: JSX.Element;
}

function SyncPermsProvider({ children }: Props) {
  const { socket } = useContext(WebSocketContext);

  useEffect(() => {
    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data: ISystemNotification) => {
      if (data.type === 'SETTINGS_UPDATE') {
        update_global_preferences(data.data);
      }
    });

    socket?.on(SocketEvents.USER_NOTIFICATION, async (data: INotifications) => {
      if (data.title === NotificationEvents.ACL) {
        const event = JSON.parse(data.message) as IACLPermissionChangeEvent | IACLGroupChangeEvent;

        const permissions = [...new Set(event.permissions)];
        update_user_permissions(permissions);
      }
    });

    return () => {
      socket?.off(SocketEvents.USER_NOTIFICATION);
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected]);
  return children;
}

export default SyncPermsProvider;
