import { useGlobalContext } from '@/contexts/global.context';
import { cn } from '@/utils';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout } from 'antd';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const { Content } = Layout;

interface IBreadcrumbsItems {
  label: string;
  link?: string;
}

interface IContentProps {
  children: React.ReactNode;
  collapse?: boolean;
  breadcrumbItems?: IBreadcrumbsItems[];
  backgroundWhite?: boolean;
  button?: React.ReactNode;
  withfilter?: boolean;
  internalElement?: React.ReactNode;
  setCollapse?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppContent = ({
  children,
  breadcrumbItems = [],
  backgroundWhite,
  button,
  internalElement
}: IContentProps) => {
  let newTitle = [];
  const { setShowInfo } = useGlobalContext();

  useEffect(() => {
    newTitle = breadcrumbItems.map(({ label }) => {
      return label;
    });
    document.title = newTitle.join(' / ');
  }, [breadcrumbItems]);

  function handleInfoClick() {
    setShowInfo((prev) => !prev);
  }

  return (
    <div>
      <div className="space-y-2">
        <div className="flex flex-row items-center justify-between gap-6">
          <Breadcrumb>
            {breadcrumbItems &&
              breadcrumbItems.map((item, index) => (
                <Breadcrumb.Item key={index}>
                  <Link to={item?.link ?? ''}>
                    <span style={{ fontSize: '18px' }} className={'ms-3 text-primary fw-bold'}>
                      {item.label}
                    </span>
                  </Link>
                </Breadcrumb.Item>
              ))}
          </Breadcrumb>

          <div className="flex flex-row gap-4 items-center">
            <InfoCircleOutlined
              className="cursor-pointer mt-1"
              style={{ color: 'var(--primary-color)', fontSize: 20 }}
              onClick={handleInfoClick}
            />

            {button}
          </div>
        </div>
        {internalElement}
      </div>

      <Content
        className={cn('mx-0 my-3 sm:p-5', {
          'site-layout-background p-1.5 rounded-2xl': !backgroundWhite
        })}>
        {children}
      </Content>
    </div>
  );
};

export default AppContent;
