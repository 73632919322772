import { useRef } from 'react';
import { Line } from 'react-chartjs-2';

import type { ChartData, ChartOptions } from 'chart.js';
import { chartColors } from '@/chart.config';
import getPascalCase from '@/utils/getPascalCase';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { IChartBaseConfig } from '@/services/dashboard/v3/types';
import { truncateString } from '@/services/dashboard/v3/services.dashboard';
import WidgetButtons from '../widget/WidgetButtons';

interface Props extends IChartBaseConfig {
  allowZoom?: boolean;
  smooth?: boolean;
  isGradient?: boolean;
  maxTicksLimit?: number;
  xAxisLabel?: string;
}

function LineChart({
  allowZoom,
  title,
  isGradient,
  allowFilter,
  smooth,
  fields,
  data,
  maxTicksLimit = 10,
  xAxisLabel,
  enlargeChart,
  handleFilterOpen,
  handleFullScreen
}: Props) {
  const chartRef = useRef<any>();

  const labels = Object.keys(data);
  const datasets = labels.map((key) => {
    const values = data[key];

    // Calculate Fields Total
    if (values.length) {
      return values.reduce((acc: Record<string, number>, item: Record<string, number | string>) => {
        fields.forEach((field) => {
          const value = Number(item[field] ?? 0);
          acc[field] = (acc[field] || 0) + value;
        });
        return acc;
      }, {});
    }
  });

  const chartData: ChartData<'line'> = {
    labels,
    datasets: fields.map((label, index) => ({
      label: getPascalCase(label),
      data: datasets.map((data) => data?.[label] || 0),
      fill: isGradient,
      backgroundColor(context) {
        const chart = context.chart;
        const { ctx, chartArea } = chart;

        if (!chartArea) return;
        if (!isGradient) return chartColors[index].backgroundColor;

        const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
        gradient.addColorStop(0, chartColors[index].backgroundColor);
        gradient.addColorStop(1, 'rgba(255,255,255,0.5)');

        return gradient;
      },
      borderColor: chartColors[index].borderColor,
      tension: smooth ? 0.4 : 0
    }))
  };

  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          maxTicksLimit,
          callback: function (value, index) {
            const label = this.getLabelForValue(index);
            return truncateString(label);
          }
        },
        title: { display: Boolean(xAxisLabel), text: xAxisLabel, font: { size: 10 } }
      }
    },
    plugins: {
      legend: {
        position: 'top' as const,
        labels: { usePointStyle: true, boxHeight: 10, font: { size: 10 } }
      },
      title: { display: Boolean(title), text: title },
      zoom: {
        pan: { enabled: allowZoom, mode: 'x', modifierKey: 'ctrl', threshold: 10 },
        zoom: { drag: { enabled: allowZoom }, mode: 'x' }
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            const label = context[0].label || '';
            return truncateString(label, 50);
          },
          label: (context) => {
            const label = context.dataset.label || context.label || '';
            const value = context.parsed.y || 0;
            return `${label}: ${nepaliNumberFormatter(value)}`;
          }
        }
      }
    }
  };

  return (
    <>
      <Line ref={chartRef} data={chartData} options={chartOptions} />
      <WidgetButtons
        chartRef={chartRef}
        enlargeChart={enlargeChart}
        allowZoom={allowZoom ?? false}
        allowFilter={allowFilter}
        handleFullScreen={handleFullScreen}
        handleFilterOpen={handleFilterOpen}
      />
    </>
  );
}

export default LineChart;
