import { DatePicker, Form, Input, InputNumber, Select, TableProps } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useEffect, useState } from 'react';

import { CustomDatePresets } from '../utils/datePresets';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import CustomReport from './report';
import { SorterResult } from 'antd/lib/table/interface';
import { numberDecimalFormatter } from '@/utils/numberFormatter';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { find_date_preference } from '@/store/localstorage/preferences';

import { getUserData } from '@/utils/auth.utils';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import VendorSearchV2 from '@/components/Common/CustomSearch/Vendors';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { DEFAULT_DATE_FORMAT } from '@/constants';
const { RangePicker } = DatePicker;

const PaymentPurchaseReport = () => {
  const [form] = Form.useForm();

  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [nepaliType] = useState(find_date_preference());
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;
  useEffect(() => {
    const nepaliDateStart = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    const nepaliDateEnd = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      paymentMethod: '',
      userId: '',
      vendorId: '',
      routeId: '',
      locationId: preferenceLocationId ? preferenceLocationId : '',
      dateCustom: [...CustomDatePresets.Today],
      startDate: CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm'),
      endDate: CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm'),
      startDateNepali: nepaliDateStart,
      endDateNepali: nepaliDateEnd
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Payment Purchase',
      link: '/reports/payment-purchase'
    }
  ];
  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };

  const columnsSupplier: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 25,
      // sorter: (a, b) => a.id - b.id,
      // sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <div>{index + 1}</div>;
      }
    },
    {
      title: 'Reference No.',
      key: 'referenceNo',
      className: 'invoice',
      sorter: (a, b) => a.referenceNo.localeCompare(b.referenceNo),
      sortOrder: sortedInfo.columnKey === 'referenceNo' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{record.referenceNo}</div>;
      },
      width: 100
    },
    {
      title: 'Paid On',
      key: 'paidOn',
      sorter: (a, b) => moment(a.paidOn).unix() - moment(b.paidOn).unix(),
      sortOrder: sortedInfo.columnKey === 'paidOn' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{moment(record.paidOn).local().format(DEFAULT_DATE_FORMAT)}</div>;
      },
      width: 100
    },
    {
      title: 'Added By',
      key: 'addedBy',
      sorter: (a, b) => a.addedBy.localeCompare(b.addedBy),
      sortOrder: sortedInfo.columnKey === 'addedBy' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{record.addedBy}</div>;
      },
      width: 100
    },
    {
      title: 'Amount',
      key: 'amount',
      className: 'highlight',
      sorter: (a, b) => a.amount - b.amount,
      sortOrder: sortedInfo.columnKey === 'amount' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{numberDecimalFormatter(record.amount)}</div>;
      },
      width: 100
    },
    {
      title: 'Supplier',
      key: 'vendor',
      sorter: (a, b) => a.vendor.localeCompare(b.vendor),
      sortOrder: sortedInfo.columnKey === 'vendor' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{record.vendor}</div>;
      },
      width: 100
    },
    {
      title: 'Payment Method',
      key: 'Payment',
      render: (record) => {
        return <div>{record.paymentMethod}</div>;
      },
      width: 100
    }
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (record) => {
    //     return <div color="black">View</div>;
    //   },
    //   width: 100
    // }
  ];
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart)
      form.setFieldValue(['constraints', 'startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', 'endDate'], convert_nepali_to_english_date(val));
  };

  return (
    <CustomReport
      toSort={handleChange}
      breadcrumbItems={breadcrumbItems}
      state={{ id: 14, name: 'Payment Purchase Report' }}
      form={form}
      toDownload={true}
      columnsData={columnsSupplier}>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <UserSearchV2 showAll hasParentFormItem={false} name={['constraints', 'userId']} />
      <VendorSearchV2
        showAll
        hasParentFormItem={false}
        name={['constraints', 'vendorId']}
        label="Select supplier"
      />

      <LocationSearchV2 hasParentFormItem={false} name={['constraints', 'locationId']} />
      {/* <Form.Item name={['constraints', 'locationId']} label="Location"> */}
      {/* <Select placeholder="Select a location!">
          <Option value="">All</Option>

          {locationsList?.data.results.map((value: any) => {
            return (
              <Option value={value.id} key={value.id}>
                {value.name}
              </Option>
            );
          })}
        </Select> */}
      {/* <LocationSearch withoutForm={true} onSelect={(val) => onSelectt(val)} /> */}
      {/* </Form.Item> */}
      {/* <Form.Item name={['constraints', 'routeId']} label="Route">
        <Select placeholder="Select a route!">
          <Option value={''} key={'none'}>
            All
          </Option>
          {routesList?.data.results.map((value: any) => {
            return (
              <Option value={value.id} key={value.id}>
                {value.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item> */}
      {/* <RouteSearch fromReport={true} /> */}
      <Form.Item name={['constraints', 'paymentMethod']} label="Payment Method">
        <Select placeholder="Select a method!">
          <Select.Option value="">none</Select.Option>
          <Select.Option value="cash">Cash</Select.Option>
          <Select.Option value="card">Card</Select.Option>
          <Select.Option value="cheque">Cheque</Select.Option>
          <Select.Option value="bank">Bank Tranfer</Select.Option>
          <Select.Option value="qr_offline">Offline QR</Select.Option>
          <Select.Option value="other">Other</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[{ required: true, message: 'Please select date!' }]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          disabledDate={disabledDate}
          ranges={CustomDatePresets}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
          className="w-full"
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
};

export default PaymentPurchaseReport;
