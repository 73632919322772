import { find_to_use_NPR } from '@/store/localstorage/preferences';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

export const tableExportColumns = [
  {
    label: 'Customer',
    dataIndex: 'user',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Amount Paid',
    dataIndex: 'amount_paid',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Total Sales',
    dataIndex: 'total_sales',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Net Sales',
    dataIndex: 'net_sales',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Sell Return',
    dataIndex: 'sell_return',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Daily Average',
    dataIndex: 'daily_average',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      amount_paid: nepaliNumberFormatter(record.amount_paid),
      total_sales: nepaliNumberFormatter(record.total_sales),
      net_sales: nepaliNumberFormatter(record.net_sales),
      sell_return: nepaliNumberFormatter(record.sell_return),
      daily_average: nepaliNumberFormatter(record.daily_average)
    };
  });
}
