import { MouseEvent, TouchEvent } from 'react';
import { MouseSensor as LibMouseSensor, TouchSensor as LibTouchSensor } from '@dnd-kit/core';

const handler = ({ nativeEvent: event, ctrlKey }: MouseEvent | TouchEvent) => {
  let cur = event.target as HTMLElement;
  if (ctrlKey) {
    return false;
  }

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false;
    }
    cur = cur.parentElement as HTMLElement;
  }

  return true;
};

export class MouseSensor extends LibMouseSensor {
  static activators = [
    { eventName: 'onMouseDown', handler }
  ] as typeof LibMouseSensor['activators'];
}

export class TouchSensor extends LibTouchSensor {
  static activators = [
    { eventName: 'onTouchStart', handler }
  ] as typeof LibTouchSensor['activators'];
}
