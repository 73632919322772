import { message } from 'antd';
import { useMutation } from '@tanstack/react-query';

import ListUI from '../../common/ListUI';
import { delete_user } from '@/services/users/queries';

const CustomersList: React.FC = () => {
  const deleteUserMutation = useMutation(delete_user, {
    onSuccess: () => {
      message.success('Customer deleted successfully');
    },
    onError: (e: any) => {
      message.error(`${e.response.data.message}`, 5);
    }
  });

  const breadcrumbItems = [{ label: 'Customers', link: '/users/customers' }];

  return (
    <div>
      <ListUI
        type="customer"
        breadcrumbItems={breadcrumbItems}
        deleteMutation={deleteUserMutation}
      />
    </div>
  );
};

export default CustomersList;
