import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get_units_list } from '../../../services/products/queries';
import LocalStore from '../../localstorage';
import UnitsDB from '../../localstorage/UnitsDB';
import { RootState } from '../../store';

interface IUnitsIntialState {
  units: any[];
  status: string;
}
const INITIAL_STATE: IUnitsIntialState = {
  units: [],
  status: 'idle'
};
export const getUnits = createAsyncThunk('units/getUnits', async (reset?: boolean) => {
  console.log(reset, 'getUnits');
  if (reset) {
    await UnitsDB.removeAllUnits();
    const data: any = await get_units_list();
    UnitsDB.addUnits(data);
    return data;
  }
  let fetchData: any = await UnitsDB.getAllUnits();
  if (fetchData.length < 1) {
    fetchData = await get_units_list();
    UnitsDB.addUnits(fetchData);
  }
  return await fetchData;
  // return await get_units_list();
});

// export const getUnit = createAsyncThunk('unit/getUnit', async (id: number) => {
//   return await get_unit_details(id);
// });
const unitsSlice = createSlice({
  name: 'units',
  initialState: INITIAL_STATE,
  reducers: {
    removeUnit: (state, action: PayloadAction<number>) => {
      state.units = state.units.filter(function (obj) {
        return obj.id !== action.payload;
      });
      UnitsDB.deleteUnit(action.payload);
    },
    addUnit: (state, action: PayloadAction<any>) => {
      state.units = [...state.units, action.payload];
      UnitsDB.addUnits([action.payload]);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getUnits.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getUnits.fulfilled, (state, action) => {
        state.units = action.payload;
        state.status = 'success';
      })
      .addCase(getUnits.rejected, (state, action) => {
        state.status = 'error';
      });
    //   .addCase(getUnit.pending, (state, action) => {
    //     state.status = 'loading';
    //   })
    //   .addCase(getUnit.fulfilled, (state, action) => {
    //     const objWithIdIndex = state.units.findIndex((obj) => obj.id === action.payload.id);
    //     state.units[objWithIdIndex] = action.payload;
    //     state.status = 'success';
    //   })
    //   .addCase(getUnit.rejected, (state, action) => {
    //     state.status = 'error';
    //   });
  }
});

export const selectAllUnits = (state: RootState) => state.units.units;
export const { removeUnit, addUnit } = unitsSlice.actions;
export default unitsSlice.reducer;
