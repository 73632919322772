import React, { useState } from 'react';
import { Menu, Form } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';

import AppContent from '@/components/Common/Content';
import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import TableFilter from '@/components/FliterTable';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { get_notificaton_list_by_locationId } from '@/services/notifications/queries';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { getUserData } from '@/utils/auth.utils';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { INotificationsList } from '@/services/notifications/types';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

const NotificationList: React.FC = () => {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(true);
  const [notificationList, setnotificationList] = useState<INotificationsList[]>([]);
  const [form] = Form.useForm();

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      key: 'id',
      width: 1,
      render: (record) => {
        return (
          // <Link to={`/notification/${record.id}/${record.locationId}`} color="black">
          <div>{record.id}</div>
          // </Link>
        );
      }
    },

    {
      title: 'Events',
      key: 'events',
      width: 7,
      render: (record) => {
        const events: string[] = JSON.parse(record.events) || [];
        return events.join(', ');
      }
    },
    {
      title: 'IsActive?',
      key: 'isactive',
      width: 2,
      render: (record) => {
        // const eventss = JSON.parse(record.events);
        // console.log('Event', eventss);
        return (
          // <Link to={`/notification/${record.id}/${record.locationId}`} color="black">
          <div data-boolean={record.isActive}>{record.isActive ? 'true' : 'false'}</div>
          // </Link>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 1,
      fixed: 'right',
      render: (record) => {
        const menu = (
          <Menu
            items={[
              {
                key: '2',
                label: <CustomUpdateIcon link={`/notification/${record.id}/${record.locationId}`} />
              },
              {
                key: '4',
                label: <DiscussionChatModal slug="notification" id={record.id} />
              }
            ]}
          />
        );
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Notifications',
      link: '/notification'
    }
  ];

  // useEffect(() => {
  //   const values = form.getFieldsValue();
  //   if (!values.locationId) {
  //     values.locationId = locationSearch[0]?.id;
  //     onFinish(values);
  //   }
  // }, []);
  // const onFinish = async (values: any) => {
  //   const response = await get_notificaton_list_by_locationId(values.locationId);
  //   setnotificationList(response.data);
  //   setIsloading(false);
  // };

  const onSearch = async (values: any) => {
    setIsloading(true);
    const response = await get_notificaton_list_by_locationId(values.locationId);
    setnotificationList(response.data);
    setIsloading(false);
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              {
                <TableFilter
                  defaultValues={{
                    // dateCustom: [moment(), moment().add(1, 'days')],
                    locationId: preferenceLocationId ? preferenceLocationId : undefined
                  }}
                  dateCustom={false}
                  initial={true}
                  onSubmit={onSearch}
                  form={form}
                  noUrl={true}
                  // outsidestyle={'flex flex-wrap items-end gap-2'}
                  styleforbuttons={'flex items-center justify-end'}
                  style={
                    'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                  }
                  buttons={
                    <>
                      <div>
                        <CustomButton
                          backgroundColor="#1890ff"
                          onClick={() => navigate(`/acl/new`)}
                          text="Add"
                          // marginTop="1.7rem"
                          Linkto="/notification/new"
                        />
                        {/* <Link to="/acl/new">Add</Link> */}
                      </div>
                    </>
                  }>
                  <LocationSearchV2
                    hasParentFormItem={false}
                    name={'locationId'}
                    label="Location"
                  />
                  {/* <Form.Item
                      name={['locationId']}
                      label="Location"
                      rules={[
                        {
                          required: true,
                          message: 'Please choose location!'
                        }
                      ]}>
                      <Select
                        showSearch
                        placeholder={'search location'}
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSelect={(val: number) => setselectedLocation(val)}
                        dropdownMatchSelectWidth={false}
                        onSearch={(val) => {
                          setSearchValue(val);
                        }}
                        notFoundContent={null}>
                        {options}
                      </Select>
                    </Form.Item> */}
                </TableFilter>
              }
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          columns={columns}
          data={notificationList}
          usersLoading={isloading}
          tableName={'notifications-list'}
        />
      </AppContent>
    </div>
  );
};

export default NotificationList;
