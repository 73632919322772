import AppContent from '../../../../components/Common/Content/Content';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
  Form,
  Input,
  Select,
  Upload,
  Checkbox,
  PageHeader,
  UploadFile,
  UploadProps,
  Card
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useState } from 'react';
import RichTextEditor from '../../../../components/RichTextEditor';
import { API_URL, JWT_TOKEN } from '../../../../constants/config';
import { get_user_avatar } from '../../../../services/users/queries';
import { getLocalStorage } from '../../../../utils/storage.utils';

const Headings = ({ form, printingtype }: { form: any; printingtype: any }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useQuery(
    ['storage/media', form.getFieldValue('invoiceLogo')],
    async () => get_user_avatar(form.getFieldValue('invoiceLogo')),
    {
      retry: false,
      onSuccess: (data: any) => {
        if (data && fileList.length === 0) {
          setFileList([
            {
              uid: '-1',
              name: 'test.png',
              status: 'done',
              url: data
            }
          ]);
        }

        // form.setFieldsValue(data.user);
        // setUserDetails(data.user);
      }
    }
  );

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('invoiceLogo', updatedFileId);
    }
    setFileList(newFileList);
  };

  const uploadProps: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: fileList,
    accept: 'image/*',
    onPreview: onPreview,
    onChange: onChange
  };

  const uploadButton = (
    <div className="rounded-full">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div>
      <AppContent>
        <PageHeader
          title="Invoice details:"
          style={{
            padding: '8px 0px',
            fontSize: '14px !important'
          }}
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mb-5">
          {printingtype == 'classic' && (
            <>
              {' '}
              <div>
                <p>Invoice Logo</p>
                <Upload className="rounded-full" {...uploadProps}>
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
                <Form.Item
                  label="Image"
                  name="invoiceLogo"
                  hidden
                  rules={[{ required: false, message: 'Please upload image!' }]}>
                  <Input />
                </Form.Item>
              </div>
              <Form.Item name="isActive" valuePropName="checked">
                <Checkbox className="w-100">Show Invoice Logo</Checkbox>
              </Form.Item>
            </>
          )}
          {/* <div className="grid grid-cols-4 gap-5 mb-5 mt-5">
        <Form.Item
          label="Sub Heading Line 1"
          name="subHeadingLine1"
          rules={[{ required: false, message: 'Please add Sub Heading!' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Sub Heading Line 2"
          name="subHeadingLine2"
          rules={[{ required: false, message: 'Please add Sub Heading!' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Sub Heading Line 3"
          name="subHeadingLine3"
          rules={[{ required: false, message: 'Please add Sub Heading!' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Sub Heading Line 4"
          name="subHeadingLine4"
          rules={[{ required: false, message: 'Please add Sub Heading!' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Sub Heading Line 5"
          name="subHeadingLine5"
          rules={[{ required: false, message: 'Please add Sub Heading!' }]}>
          <Input />
        </Form.Item>
      </div> */}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mb-2">
          <Form.Item
            label="Header Text"
            name="headerText"
            rules={[{ required: false, message: 'Add Description' }]}>
            {/* <RichTextEditor
          value={form.getFieldValue('headerText') ?? ''}
          fieldName="headerText"
          fn={handleRichTextEditorChange}
        /> */}
            <Input />
          </Form.Item>
          <Form.Item
            label="Sub Header Text"
            name="subHeaderText"
            rules={[{ required: false, message: 'Add Description' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Invoice Heading"
            name="invoiceHeading"
            rules={[{ required: false, message: 'Please add invoice heading' }]}>
            <Input />
          </Form.Item>

          {/* <Form.Item
          label="Heading suffix for not paid"
          name="headingSuffixNotPaid"
          rules={[{ required: false }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Heading suffix for paid"
          name="headingSuffixPaid"
          rules={[{ required: false }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Quotation Heading" name="quotationHeading" rules={[{ required: false }]}>
          <Input />
        </Form.Item> */}

          <Form.Item
            label="Invoice no. label:"
            name="invoiceNumberLabel"
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>

          {/* <Form.Item
          label="Quotation no. label:"
          name="quotationNumberLabel"
          rules={[{ required: false }]}>
          <Input />
        </Form.Item> */}
          <Form.Item label="PAN Number Label:" name="panNumberLabel" rules={[{ required: false }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Date Label:" name="dateLabel" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item name="referenceNumberLabel" label="Reference Number Label">
            <Input />
          </Form.Item>
          <Form.Item name="nepaliDateLabel" label="Nepali Date Label">
            <Input />
          </Form.Item>
          <Form.Item name="transactionDateLabel" label="Transaction Date Label">
            <Input />
          </Form.Item>
          {printingtype == 'bill' && (
            <>
              <Form.Item label="VAT No.:" name="vatNumber" rules={[{ required: false }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Nepali Date Label:"
                name="nepaliDateLabel"
                rules={[{ required: false }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Customer Name Label"
                name="customerNameLabel"
                rules={[{ required: false }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Address Label:" name="addressLabel" rules={[{ required: false }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="PAN Number Label:"
                name="panNumberLabel"
                rules={[{ required: false }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Payement Label:" name="paymentLabel" rules={[{ required: false }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Remarks Label:" name="remarksLabel" rules={[{ required: false }]}>
                <Input />
              </Form.Item>
            </>
          )}
          {/* 
        <Form.Item label="Due date Label:" name="dueDateLabel" rules={[{ required: false }]}>
          <Input />
        </Form.Item> */}

          {/* <Form.Item name="showDueDate" valuePropName="checked">
          <Checkbox className="w-100">Show due date</Checkbox>
        </Form.Item> */}

          {/* <Form.Item label="Date time format:" name="dateTimeFormat" rules={[{ required: false }]}>
          <Input />
        </Form.Item>
        <small>
          Enter date and time format in{' '}
          <a
            href="https://www.php.net/manual/en/function.date.php"
            target={'_blank'}
            rel="noreferrer">
            PHP datetime
          </a>{' '}
          format. If blank business date time format will be applied
        </small> */}

          {/* <Form.Item
          label="Sales Person label:"
          name="salesPersonLabel"
          rules={[{ required: false }]}>
          <Input />
        </Form.Item> */}

          {/* <Form.Item name="showBusinessName" valuePropName="checked">
          <Checkbox className="w-100">Show Business Name</Checkbox>
        </Form.Item>

        <Form.Item name="showLocationName" valuePropName="checked">
          <Checkbox className="w-100">Show Location Name</Checkbox>
        </Form.Item> */}
        </div>
      </AppContent>
      {printingtype == 'classic' && (
        <AppContent>
          <PageHeader
            title="Fields for customer/Vendor details:"
            style={{
              padding: '8px 0px',
              fontSize: '14px !important'
            }}
          />

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mb-2">
            {/* <Form.Item name="showSalesPerson" valuePropName="checked">
          <Checkbox className="w-100">Show Sales Person</Checkbox>
        </Form.Item> */}

            <Form.Item label="Customer Label:" name="customerLabel">
              <Input />
            </Form.Item>
            <Form.Item label="Vendor Label:" name="vendorLabel">
              <Input />
            </Form.Item>

            <Form.Item name="showDetails" valuePropName="checked">
              <Checkbox className="w-100">Show Customer/Vendor Details</Checkbox>
            </Form.Item>

            {/* <Form.Item label="Client ID Label:" name="clientIDLabel">
          <Input />
        </Form.Item> */}

            <Form.Item label="Client tax number label:" name="clientTaxNumberLabel">
              <Input />
            </Form.Item>

            {/* <Form.Item name="showRewardPoint" valuePropName="checked">
          <Checkbox className="w-100">Show reward point</Checkbox>
        </Form.Item> */}
          </div>

          {/* <div className="grid grid-cols-4 gap-5 mb-2">
        <Form.Item
          label="Custom Field 1"
          name="customField1"
          rules={[{ required: false, message: 'Please add Custom Field!' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Custom Field 2"
          name="customField2"
          rules={[{ required: false, message: 'Please add Custom Field!' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Custom Field 3"
          name="customField3"
          rules={[{ required: false, message: 'Please add Custom Field!' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Custom Field 4"
          name="customField4"
          rules={[{ required: false, message: 'Please add Custom Field!' }]}>
          <Input />
        </Form.Item>
      </div> */}

          {/* <PageHeader
        title="Fields to be shown in location address:"
        style={{
          padding: '8px 0px',
          fontSize: '14px !important'
        }}
      /> */}

          {/* <div className="grid grid-cols-4 gap-5 mb-2">
        <Form.Item name="landmark" valuePropName="checked">
          <Checkbox className="w-100">Landmark</Checkbox>
        </Form.Item>

        <Form.Item name="city" valuePropName="checked">
          <Checkbox className="w-100">City</Checkbox>
        </Form.Item>

        <Form.Item name="state" valuePropName="checked">
          <Checkbox className="w-100">State</Checkbox>
        </Form.Item>

        <Form.Item name="country" valuePropName="checked">
          <Checkbox className="w-100">Country</Checkbox>
        </Form.Item>

        <Form.Item name="zipCode" valuePropName="checked">
          <Checkbox className="w-100">Zip Code</Checkbox>
        </Form.Item>

        <Form.Item name="website" valuePropName="checked">
          <Checkbox className="w-100">Website</Checkbox>
        </Form.Item>

        <Form.Item name="locationAddressCustomField1" valuePropName="checked">
          <Checkbox className="w-100">Custom field 1</Checkbox>
        </Form.Item>

        <Form.Item name="locationAddressCustomField2" valuePropName="checked">
          <Checkbox className="w-100">Custom field 2</Checkbox>
        </Form.Item>

        <Form.Item name="locationAddressCustomField3" valuePropName="checked">
          <Checkbox className="w-100">Custom field 3</Checkbox>
        </Form.Item>

        <Form.Item name="locationAddressCustomField4" valuePropName="checked">
          <Checkbox className="w-100">Custom field 4</Checkbox>
        </Form.Item>
      </div> */}

          {/* <PageHeader
        title="Fields for Communication details:"
        style={{
          padding: '8px 0px',
          fontSize: '14px !important'
        }}
      />

      <div className="grid grid-cols-4 gap-5 mb-2">
        <Form.Item name="mobileNumber" valuePropName="checked">
          <Checkbox className="w-100">Mobile number</Checkbox>
        </Form.Item>

        <Form.Item name="alternateNumber" valuePropName="checked">
          <Checkbox className="w-100"> Alternate number</Checkbox>
        </Form.Item>

        <Form.Item name="email" valuePropName="checked">
          <Checkbox className="w-100"> Email</Checkbox>
        </Form.Item>
      </div> */}

          {/* <PageHeader
        title="Fields for Tax details:"
        style={{
          padding: '8px 0px',
          fontSize: '14px !important'
        }}
      />

      <div className="grid grid-cols-4 gap-5 mb-2">
        <Form.Item name="tax1Details" valuePropName="checked">
          <Checkbox className="w-100">Tax 1 details</Checkbox>
        </Form.Item>

        <Form.Item name="tax2Details" valuePropName="checked">
          <Checkbox className="w-100">Tax 2 details</Checkbox>
        </Form.Item>
      </div> */}
        </AppContent>
      )}
    </div>
  );
};

export default Headings;
