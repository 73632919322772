import { Button, Collapse, Form, FormInstance, message, PageHeader, Spin } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import AppContent from '@/components/Common/Content/Content';

import { download_report, get_report } from '@/services/report/queries';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { check_date_cap, check_export_report_date_cap } from '@/utils/common';
import { showReportConditionalDownloadMessage } from '@/utils/reportConditionalDownloadMessage';
import { excelExportColumns, getUpdatedData, tableExportColumns } from './column.export';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportAllData from '@/components/Common/ExportAll';
import ExportCurrent from '@/components/Common/ExportCurrent';

interface ReportData {
  count: any;
  data: any;
}

interface State {
  id: number;
  name: string;
}
interface ReportProps {
  state: State;
  breadcrumbItems: any[];
  form: FormInstance<any>;
  // locationList: any[];

  columnsData?: any[];

  toDownload?: boolean;
  toSort: any;
}

export const getInt = (num: string | number) => {
  if (typeof num === 'string') {
    return parseFloat(num);
  }
  return num;
};

const CustomReport: React.FC<ReportProps> = ({
  state,
  breadcrumbItems,
  form,
  columnsData,
  children,
  toDownload,
  toSort
}) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [data, setData] = useState<ReportData>({ count: 0, data: [] });
  //   const [footer, setFooterData] = useState(0);
  const [footer, setFooterData] = useState({
    totalDue: 0,
    totalAmount: 0,
    totalPaid: 0,
    totalReturned: 0,
    totalRemaining: 0
  });
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState(100);
  const [isloading, setIsloading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [fullExportURL, setFullExportURL] = useState<any>(null);

  useEffect(() => {
    if (!columnsData) createColumns();
  }, [data]);
  const createColumns = () => {
    //auto generate columns
    const newColumns: ColumnsType<any> = [];
    for (const key in data.data[0]) {
      newColumns.push({
        title: key,
        key,
        render: (record: any) => {
          return <div className="forless">{record[key]}</div>;
        }
      });
    }
    setColumns(newColumns);
  };
  const onSearch = async (values: any, storePage = false) => {
    // console.log('values-->', values);
    setIsloading(true);
    if (check_date_cap(values.constraints.startDate, values.constraints.endDate)) {
      await downloadPdf();
      setIsloading(false);
      return;
    }
    if (!storePage) {
      values.constraints['page'] = 1;
      values.constraints['size'] = size;
      setPage(1);
    }
    //remove custom date
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    const response = await get_report(values.constraints, state.id);
    const newresponse = await calculateTotal(response);
    setData(newresponse.data);

    if (response.data.data.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }

    const payload = values.constraints as any;
    payload.size = response.data.count;
    setFullExportURL(payload);

    setIsloading(false);
  };

  const calculateTotal = async (response: any) => {
    let totalDue = 0;
    let totalAmount = 0;
    let totalPaid = 0;
    let totalReturned = 0;
    let totalRemaining = 0;

    for (let index = 0; index < response.data.data.length; index++) {
      if (
        response.data.data[index].paymentStatus == 'partial' ||
        response.data.data[index].paymentStatus == 'pending'
      )
        totalDue += 1;
      totalAmount += getInt(response.data.data[index].total_amount);
      totalPaid += getInt(response.data.data[index].amount_paid);
      totalReturned += getInt(response.data.data[index].amount_returned);
      totalRemaining +=
        getInt(response.data.data[index].total_amount) -
        getInt(response.data.data[index].amount_paid);
    }
    setFooterData({ totalDue, totalAmount, totalPaid, totalReturned, totalRemaining });
    return response;
  };

  const addPage = async (value: number, sizeVal = 0) => {
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();
      if (sizeVal !== 0) {
        allValues.constraints.page = 1;
        allValues.constraints.size = sizeVal;
        setPage(1);
        setSize(sizeVal);
      } else {
        allValues.constraints.page = value;
        allValues.constraints.size = size;
        setPage(value);
      }
      onSearch(allValues, true);
    } catch (e: any) {
      console.log('validate error', e);
    }
  };

  const downloadPdf = async () => {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsloading(true);
    const values = form.getFieldsValue();
    await form.validateFields();
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    try {
      if (check_export_report_date_cap(values.constraints.startDate, values.constraints.endDate)) {
        // const response =
        values.constraints.customReportQueueName = 'Sell Pending Payment Report';
        message.info({
          key: 'downloading',
          content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
        });
        await download_report(values.constraints, state.id);
        setIsloading(false);
        // window.open(response.data, '_blank');
      }
    } catch (err: any) {
      setIsloading(false);
      message.error(err.message);
    }
  };

  async function onSearchAll() {
    try {
      setIsloading(true);
      if (!fullExportURL) {
        message.error('No URL specified. Please search first.');
        return;
      }
      let response = await get_report(fullExportURL, state.id);
      if (response?.data.data) {
        response = await calculateTotal(response);
      }
      return getUpdatedData(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  }

  const updatedData = getUpdatedData(data.data);

  return (
    <div>
      <Spin spinning={isloading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <Collapse>
            <CollapsePanel header="Filters" key="1">
              <Form
                form={form}
                onFinish={onSearch}
                layout="vertical"
                validateTrigger={'onChange'}
                autoComplete="off"
                disabled={isloading}>
                {/* <PageHeader
                  title={state?.name}
                  style={{
                    padding: '5px 0px'
                  }}
                /> */}
                {React.Children.count(children) > 1 && (
                  <>
                    <PageHeader
                      subTitle="Constraints"
                      style={{
                        padding: '0px 0px'
                      }}
                    />
                  </>
                )}
                <div className="grid grid-cols-5 gap-2 ">
                  {children}
                  <div className="flex justify-start mt-7 gap-2">
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
                        Search
                      </Button>
                    </Form.Item>
                    {toDownload ? (
                      <div>
                        <Form.Item>
                          <Button
                            className="secondary-button"
                            style={{ borderRadius: '6px' }}
                            onClick={downloadPdf}>
                            Download
                          </Button>
                        </Form.Item>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Form>
            </CollapsePanel>
          </Collapse>
          <PageHeader
            subTitle="Table"
            style={{
              padding: '8px 0px'
            }}
          />
          <CustomizeTable
            tableName="pending-payment-sell-report-table"
            customScroll={{ x: 1600, y: 500 }}
            notshowPagination
            columns={columnsData || columns}
            data={data.data}
            toSort={toSort}
            footer={() =>
              `Total Amount: ${nepaliNumberFormatter(
                footer.totalAmount
              )}, Total Paid: ${nepaliNumberFormatter(
                footer.totalPaid
              )}, Total Returned: ${nepaliNumberFormatter(
                footer.totalReturned
              )}, Total Remaining: ${nepaliNumberFormatter(
                footer.totalRemaining
              )}, Total Due: ${nepaliNumberFormatter(footer.totalDue)}`
            }
            tableSummary={
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7} className="text-right mr-2">
                    {nepaliNumberFormatter(footer.totalAmount)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} className="text-right mr-2">
                    {nepaliNumberFormatter(footer.totalPaid)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} className="text-right mr-2">
                    {nepaliNumberFormatter(footer.totalReturned)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10} className="text-right mr-2">
                    {nepaliNumberFormatter(footer.totalRemaining)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            }
            buttons={
              <>
                <ExportAllData
                  title="Pending Payment Sell Report (All)"
                  columns={tableExportColumns}
                  excelColumns={excelExportColumns}
                  getInfo={onSearchAll}
                />
                <ExportCurrent
                  data={{ total: data.count, results: updatedData }}
                  columns={tableExportColumns}
                  excelColumns={excelExportColumns}
                  title="Pending Payment Sell Report"
                />
              </>
            }
            paginationDatas={{
              page,
              total: data.count,
              size,
              onPagination: (pageNo, currentSize) => {
                currentSize == size ? addPage(pageNo) : addPage(pageNo, currentSize);
              }
            }}
          />
        </AppContent>
      </Spin>
    </div>
  );
};

export default CustomReport;
