import React, { useState } from 'react';
import { Menu } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import AppContent from '../../../components/Common/Content';
import DiscussionChatModal from '../../../components/Common/DiscussionChatModal';
import { get_all_acl_groups } from '../../../services/acl/queries';
import TableFilter from '../../../components/FliterTable';
import moment from 'moment';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import TableCell from '../../../components/Common/CustomizeTable/CustomCell';
import { ACLGroup } from '../../../services/acl/types';
import CustomGreenButton from '../../../components/Common/CustomButton/CustomGreenButton';
import CustomUpdateIcon from '../../../components/Common/CustomIcons/CustomUpdateIcon';

const ACLList: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [aclList, setaclList] = useState<ACLGroup[]>([]);
  const columns: ColumnsType<ACLGroup> = [
    {
      title: 'ID',
      key: 'id',
      width: 10,
      render: (a, record) => {
        return <TableCell> {record.id}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 100,
      render: (a, record) => {
        return <TableCell>{record.name}</TableCell>;
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 10,
      render: (a, record) => {
        const menu = (
          <Menu
            items={[
              {
                key: '2',
                label: <CustomUpdateIcon link={`/acl/${record.id}`} />
              },
              {
                key: '4',
                label: <DiscussionChatModal slug="acl" rreference="other" id={record.id} />
              }
            ]}
          />
        );
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'ACL',
      link: '/acl'
    }
  ];

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const response = await get_all_acl_groups(filter);
    setaclList(response.data);
    setIsLoading(false);
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <TableFilter
              defaultValues={{
                dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')]
              }}
              initial={true}
              onSubmit={onSubmitFilter}
              // outsidestyle={'flex justify-end items-end flex-wrap gap-2'}
              styleforbuttons={'flex gap-1 items-center justify-end'}
              style={
                'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
              }
              buttons={
                <>
                  <div>
                    {/* <CustomButton
                        onClick={() => navigate(`/acl/new`)}
                        text="Add"
                        backgroundColor="#1890ff"
                        // marginTop="1.7rem"
                        marginLeft="5px"
                        Linkto="/acl/new"
                      /> */}
                    <CustomGreenButton
                      text="Add"
                      onClick={() => navigate(`/acl/new`)}
                      marginLeft="5px"
                      Linkto="/acl/new"
                    />
                  </div>
                </>
              }></TableFilter>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          customScroll={{ x: 350, y: '75vh' }}
          columns={columns}
          data={aclList}
          usersLoading={isLoading}
          tableName={'acl-list'}
        />
      </AppContent>
    </div>
  );
};

export default ACLList;
