import { Button, Form, Input, Modal, Checkbox, Select, DatePicker } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

interface Iprops {
  events: any;
  setEvents: React.Dispatch<any>;
  isModalOpen: boolean;
  showModal: () => void;
  handleOk: () => void;
  handleCancel: () => void;
  toEditData: any;
}

const EditEvent = ({
  events,
  setEvents,
  showModal,
  handleOk,
  handleCancel,
  isModalOpen,
  toEditData
}: Iprops) => {
  //   console.log('Initial Data: ', toEditData);
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    console.log('values', values);
    if (values.index !== undefined) {
      const updatedData = events[values.date].map((curr: any, ind: number) => {
        if (ind == parseInt(values.index)) {
          return {
            event: values.event,
            description: values.descritption,
            colorcode: values.colorcode
          };
        } else {
          return curr;
        }
      });
      setEvents((prev: any) => ({
        ...prev,
        [values.date]: updatedData
      }));
    }
    handleCancel();
  };

  useEffect(() => {
    form.setFieldsValue({
      ...toEditData
    });
  }, [toEditData]);

  return (
    <>
      <Modal
        title={'Add Event'}
        visible={isModalOpen}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}>
        <Form name="basic" form={form} layout={'vertical'} onFinish={onFinish} autoComplete="off">
          <Form.Item
            label="Select Date:"
            name="date"
            rules={[{ required: true, message: 'Please select date!' }]}>
            <Input disabled />
            {/* <DatePicker /> */}
          </Form.Item>
          <Form.Item
            label="Event Name:"
            name="event"
            rules={[{ required: true, message: 'Please input event name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="index"
            rules={[{ required: true, message: 'Please input event name!' }]}
            hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label="Description:"
            name="description"
            rules={[{ required: false, message: 'Please input description!' }]}>
            <TextArea />
          </Form.Item>
          <Form.Item
            className="grid grid-cols-2"
            label="Color code:"
            name="colorcode"
            rules={[{ required: false, message: 'Color code!' }]}>
            <Select allowClear>
              {[
                colorCodeOption('green'),
                colorCodeOption('red'),
                colorCodeOption('blue'),
                colorCodeOption('yellow')
              ]}
            </Select>
          </Form.Item>
          <div className="flex justify-end">
            <Button type="primary" htmlType="submit">
              Edit
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default EditEvent;

const colorCodeOption = (colorname: string) => {
  return (
    <Select.Option value={colorname}>
      <div className="flex justify-between items-center">
        <div style={{ textTransform: 'capitalize' }}>{colorname}</div>
        <div
          style={{
            height: '20px',
            width: '20px',
            backgroundColor: colorname,
            borderRadius: '50%'
          }}></div>
      </div>
    </Select.Option>
  );
};
