import { Button, Form, FormInstance, PageHeader, message, Collapse, Spin, Alert } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import AppContent from '../../../../components/Common/Content/Content';
import moment from 'moment';

import { download_report, get_report } from '../../../../services/report/queries';

import { nepaliNumberFormatter, numberDecimalFormatter } from '../../../../utils/numberFormatter';
import { convertLocalToUTCString } from '../../../../utils/convertToUTC';

import { shortNameHiearchy } from '../../../sell/sell-order/view';

import {
  check_date_cap,
  check_date_cap_limit,
  check_export_report_date_cap
} from '../../../../utils/common';
import { find_expense_account } from '../../../../store/localstorage/preferences';
import AccountsDB from '../../../../store/localstorage/AccountsDB';
import {
  get_account_detail_by_ids,
  get_account_details
} from '../../../../services/accounts/queries';
import {
  IAccountExpenseData,
  IExpenseData,
  IGrandTotalProfit,
  IProfitReportData
} from '../../../../services/report/types';
import CustomErrorModal from '../../../../components/Common/CustomErrorModal';
import CustomInfoModal from '../../../../components/Common/CustomInfoModal';
import { showReportConditionalDownloadMessage } from '../../../../utils/reportConditionalDownloadMessage';

interface State {
  id: number[];
  name: string;
}
interface ReportProps {
  state: State;
  breadcrumbItems: any[];
  form: FormInstance<any>;
  columnsData: any[];
  footerData?: { sell?: boolean; purchase?: boolean };
  toSort?: any;
}

const CustomReport: React.FC<ReportProps> = ({
  state,
  breadcrumbItems,
  form,
  columnsData,
  toSort,
  children
}) => {
  const [profitReportData, setProfitReportData] = useState<IProfitReportData[]>([]);
  const [expenseData, setExpenseData] = useState<IExpenseData[]>([]);
  const [grandTotalProfitData, setGrandTotalProfitData] = useState<IGrandTotalProfit[]>([]);
  const [size, setSize] = useState(100);
  const preferenceAccountId = find_expense_account();
  const [isEmpty, setIsEmpty] = useState(false);

  const [profitReportDataFooter, setProfitReportDataFooter] = useState({
    totalSale: 0,
    totalSaleReturn: 0,
    totalPurchase: 0,
    totalPurchaseReturn: 0,
    totalProfit: 0,
    totalWastage: 0,
    totalStockSum: 0
  });
  const [expenseDataFooter, setExpenseDataFooter] = useState({
    totalDebit: 0,
    totalCredit: 0
  });
  const [grandTotalProfitFooter, setGrandTotalProfitFooter] = useState({
    grandTotal: 0
  });
  const [page, setPage] = useState<number>(1);
  const [isloading, setIsloading] = useState(false);

  const columns3: ColumnsType<any> = [
    {
      title: 'Before Expense',
      key: 'totalProfit',
      render: () => {
        return (
          <div className="text-right mr-2">
            {nepaliNumberFormatter(profitReportDataFooter.totalProfit)}
          </div>
        );
      }
    },
    {
      title: 'Debit Sum',
      key: 'totalDebit',
      render: () => {
        return (
          <div className="text-right mr-2">
            {nepaliNumberFormatter(expenseDataFooter.totalDebit)}
          </div>
        );
      }
    },
    {
      title: 'Credit Sum',
      key: 'totalCredit',
      render: () => {
        return (
          <div className="text-right mr-2">
            {nepaliNumberFormatter(expenseDataFooter.totalCredit)}
          </div>
        );
      }
    },
    {
      title: 'After Expense',
      key: 'grandTotal',
      render: () => {
        return (
          <div className="text-right mr-2">
            {nepaliNumberFormatter(grandTotalProfitFooter.grandTotal)}
          </div>
        );
      }
    }
  ];

  const onSearch = async ({
    values,
    storePage = false,
    type
  }: {
    values: any;
    storePage?: boolean;
    type?: any;
  }) => {
    setIsloading(true);
    if (!storePage) {
      values.constraints['page'] = 1;
      values.constraints['size'] = size;
      setPage(1);
    }
    const dateDiff = moment(values.constraints.endDate).diff(values.constraints.startDate, 'days');
    if (check_date_cap_limit(values.constraints.startDate, values.constraints.endDate, 2)) {
      message.error(`${dateDiff} days selected.Please select date range no longer than 2 months!`);
      setIsloading(false);
      return;
    }
    if (check_date_cap(values.constraints.startDate, values.constraints.endDate)) {
      message.info(
        `Total days selected: ${dateDiff}. If range is greater than 14, file is downloaded.`
      );
      await downloadReport();
      setIsloading(false);
      return;
    }
    //remove custom date
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }

    if (find_expense_account() == null) {
      message.error('Please set expense account in preferences');
      setIsloading(false);
      return;
    }

    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);

    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);

    const profitReport = await getProfitReport(values);
    // console.log('profit report', profitReport);
    setProfitReportData(profitReport);
    const accountReport = await getAccountReport(values);
    // console.log('accountrepit', accountReport);

    if (profitReport.length === 0) {
      setIsEmpty(true);
    }

    const grandTotalData = calculateGrandTotal(profitReport, accountReport);
    // console.log('grandTotalData', grandTotalData);
    setGrandTotalProfitData([grandTotalData]);
    setGrandTotalProfitFooter({
      grandTotal: grandTotalData.grandTotal
    });

    setIsloading(false);
  };

  const getProfitReport = async (values: any) => {
    const response = await get_report(
      {
        ...values.constraints,
        size: -1
      },
      state.id[0]
    );

    return await calculateTotalDetail(response.data);
  };

  const getAccountReport = async (values: any) => {
    try {
      if (!Array.isArray(preferenceAccountId)) {
        await CustomErrorModal({ message: 'Please sync or setup expense account in preferences' });
      }
      const response = await Promise.all(
        preferenceAccountId.map(async (id: any) => {
          const { data } = await get_report(
            {
              ...values.constraints,
              accountId: id,
              size: -1
            },
            state.id[1]
          );
          if (data.length === 0) {
            return {
              account_id: id,
              debit_sum: 0,
              credit_sum: 0
            };
          }

          return data[0];
        })
      );
      setExpenseData([]);
      const expenseDataArray = [];

      for (const id of preferenceAccountId) {
        const account = await AccountsDB.getAccount(id);

        if (!account) {
          const allAccount = await get_account_detail_by_ids(preferenceAccountId);
          await AccountsDB.addAccounts(allAccount.results);
        }
        const accountData = await AccountsDB.getAccount(id);

        const accountName = typeof accountData === 'object' ? accountData.name : '';

        const filteredExpenseData = response.find(
          (item: IAccountExpenseData) => item.account_id === id
        );

        if (filteredExpenseData) {
          const updatedExpenseData = {
            ...filteredExpenseData,
            accountName: accountName
          };
          expenseDataArray.push(updatedExpenseData);
        }
      }

      setExpenseData(expenseDataArray);
      return await calculateTotalDebitCredit(expenseDataArray);
    } catch (error) {
      setExpenseData([]);
      return await calculateTotalDebitCredit([]);
    }
  };

  const calculateTotalDetail = async (data: IProfitReportData[]) => {
    let totalSale = 0,
      totalSaleReturn = 0,
      totalPurchase = 0,
      totalPurchaseReturn = 0,
      totalProfit = 0,
      totalWastage = 0,
      totalStockSum = 0;

    for (let index = 0; index < data.length; index++) {
      totalSale += parseFloat(data[index].sell_sum);
      totalSaleReturn += parseFloat(data[index].sell_return_sum);
      totalPurchase += parseFloat(data[index].purchase_sum);
      totalPurchaseReturn += parseFloat(data[index].purchase_return_sum);
      totalProfit += parseFloat(data[index].profit);
      totalWastage += parseFloat(data[index].wastage_sum);
      totalStockSum += parseFloat(data[index].stock_sum);
    }

    setProfitReportDataFooter((prev) => {
      return {
        ...prev,
        totalProfit,
        totalPurchase,
        totalPurchaseReturn,
        totalSale,
        totalSaleReturn,
        totalWastage,
        totalStockSum
      };
    });

    return data;
  };
  const calculateTotalDebitCredit = async (data: IExpenseData[]) => {
    let totalDebit = 0,
      totalCredit = 0;

    for (const expense of data) {
      totalDebit += parseFloat(expense.debit_sum);
      totalCredit += parseFloat(expense.credit_sum);
    }
    setExpenseDataFooter({
      totalDebit,
      totalCredit
    });

    return data;
  };

  const calculateGrandTotal = (profitData: IProfitReportData[], expenseData: IExpenseData[]) => {
    const totalProfit = calculateTotalProfitFromData(profitData);
    const totalDebit = calculateTotalDebitFromExpense(expenseData);
    const totalCredit = calculateTotalCreditFromExpense(expenseData);

    const grandTotal = totalProfit - totalDebit + totalCredit;

    return {
      totalProfit,
      totalDebit,
      totalCredit,
      grandTotal
    };
  };

  const calculateTotalProfitFromData = (data: any) => {
    let totalProfit = 0;
    for (let index = 0; index < data.length; index++) {
      totalProfit += parseFloat(data[index].profit);
    }
    return totalProfit;
  };

  const calculateTotalDebitFromExpense = (data: IExpenseData[]) => {
    let totalDebit = 0;
    for (const expense of data) {
      totalDebit += parseFloat(expense.debit_sum);
    }
    return totalDebit;
  };

  const calculateTotalCreditFromExpense = (data: IExpenseData[]) => {
    let totalCredit = 0;
    for (const expense of data) {
      totalCredit += parseFloat(expense.credit_sum);
    }
    return totalCredit;
  };

  const addPage = async (value: number, sizeVal = 0) => {
    //check total pages
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();
      if (sizeVal !== 0) {
        allValues.constraints.page = 1;
        allValues.constraints.size = sizeVal;
        setPage(1);
        setSize(sizeVal);
      } else {
        allValues.constraints.page = value;
        allValues.constraints.size = size;
        setPage(value);
      }
      onSearch({ values: allValues, storePage: true, type: ['detail'] });
    } catch (e: any) {
      console.log('validate error', e);
    }
  };

  const addPage2 = async (value: number, sizeVal = 0) => {
    //check total pages
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();
      if (sizeVal !== 0) {
        allValues.constraints.page = 1;
        allValues.constraints.size = sizeVal;

        setSize(sizeVal);
      } else {
        allValues.constraints.page = value;
        allValues.constraints.size = size;
      }
      onSearch({ values: allValues, storePage: true, type: ['detail', value] });
    } catch (e: any) {
      console.log('validate error', e);
    }
  };

  const downloadReport = async () => {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsloading(true);
    // console.log('Clicked');
    const values = form.getFieldsValue();
    await form.validateFields();
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    try {
      if (check_export_report_date_cap(values.constraints.startDate, values.constraints.endDate)) {
        // const response =
        values.constraints.customReportQueueName = 'Profit Calculation Report';
        message.info({
          key: 'downloading',
          content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
        });
        await download_report(
          {
            ...values.constraints,
            size: -1
          },
          state.id[0]
        );
        setIsloading(false);
        // window.open(response.data, '_blank');
      }
    } catch (err: any) {
      setIsloading(false);
      message.error(err.message);
    }
  };

  const calculateTotalQuantityString = (totalQuantity: any) => {
    let totalQuantityString = '';
    const totalQuantityArray: any[] = [];
    for (const key in totalQuantity) {
      totalQuantityArray.push({
        tqty: totalQuantity[key],
        shortName: key
      });
    }
    if (totalQuantityArray.length == 0) return '0';
    totalQuantityArray.sort((a: any, b: any) => {
      return shortNameHiearchy.indexOf(a.shortName) - shortNameHiearchy.indexOf(b.shortName);
    });
    for (let ind = 0; ind < totalQuantityArray.length; ind++) {
      totalQuantityString +=
        totalQuantityArray[ind].tqty == 0
          ? ''
          : ` ${numberDecimalFormatter(totalQuantityArray[ind].tqty)} ${
              totalQuantityArray[ind].shortName
            }`;
    }
    if (totalQuantityString == '') return '0';
    return totalQuantityString;
  };

  return (
    <div>
      <Spin spinning={isloading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <Collapse>
            <CollapsePanel header="Filters" key="1">
              <Form
                form={form}
                onFinish={(values) => onSearch({ values })}
                layout="vertical"
                validateTrigger={'onChange'}
                autoComplete="off"
                disabled={isloading}>
                <PageHeader
                  title={state?.name}
                  style={{
                    padding: '8px 0px'
                  }}
                />
                {React.Children.count(children) > 1 && (
                  <>
                    <PageHeader
                      subTitle="Constraints"
                      style={{
                        padding: '8px 0px'
                      }}
                    />
                  </>
                )}
                <div className="grid grid-cols-4 gap-3 ">{children}</div>
                <div className="flex justify-end mt-5 gap-3">
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
                      Search
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="secondary-button"
                      style={{ borderRadius: '6px' }}
                      onClick={() => downloadReport()}>
                      Download
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </CollapsePanel>
          </Collapse>
          <div className="mt-2">
            <Alert
              message="Information!"
              description="Please note that transfer in and transfer out values are not calculated, you can get the transfer in and out values from the All Product Quantity Report."
              type="info"
              closable
              showIcon
            />
          </div>

          <PageHeader
            subTitle="Table"
            style={{
              padding: '8px 0px'
            }}
          />
          <Table
            scroll={{ x: 1000, y: 500 }}
            pagination={false}
            size="small"
            columns={columnsData[0]}
            onChange={toSort}
            dataSource={profitReportData}
            footer={() =>
              `Total Purchase: ${nepaliNumberFormatter(
                profitReportDataFooter.totalPurchase
              )}, Total Purchase Return: ${nepaliNumberFormatter(
                profitReportDataFooter.totalPurchaseReturn
              )}, Total Sale: ${nepaliNumberFormatter(
                profitReportDataFooter.totalSale
              )}, Total Sale Return: ${nepaliNumberFormatter(
                profitReportDataFooter.totalSaleReturn
              )}, Total Profit:${nepaliNumberFormatter(
                profitReportDataFooter.totalProfit
              )}, Total Wastage:${nepaliNumberFormatter(profitReportDataFooter.totalWastage)},
              Total Stock Sum:${nepaliNumberFormatter(profitReportDataFooter.totalStockSum)}`
            }
            summary={(pageData) => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3} className="text-right mr-2">
                      {nepaliNumberFormatter(profitReportDataFooter.totalSale)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} className="text-right mr-2">
                      {nepaliNumberFormatter(profitReportDataFooter.totalSaleReturn)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} className="text-right mr-2">
                      {nepaliNumberFormatter(profitReportDataFooter.totalPurchase)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} className="text-right mr-2">
                      {nepaliNumberFormatter(profitReportDataFooter.totalPurchaseReturn)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} className="text-right mr-2">
                      {nepaliNumberFormatter(profitReportDataFooter.totalProfit)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} className="text-right mr-2">
                      {nepaliNumberFormatter(profitReportDataFooter.totalWastage)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} className="text-right mr-2">
                      {nepaliNumberFormatter(profitReportDataFooter.totalStockSum)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
          <PageHeader
            subTitle="Expenses"
            style={{
              padding: '8px 0px'
            }}
          />
          <Table
            size="small"
            pagination={false}
            columns={columnsData[1]}
            onChange={toSort}
            dataSource={expenseData}
            summary={(pageData) => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} className="text-right mr-2">
                      {nepaliNumberFormatter(expenseDataFooter.totalDebit)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} className="text-right mr-2">
                      {nepaliNumberFormatter(expenseDataFooter.totalCredit)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
          <PageHeader
            subTitle="Grand Total Profit"
            style={{
              padding: '8px 0px'
            }}
          />
          <Table
            size="small"
            pagination={false}
            columns={columns3}
            onChange={toSort}
            dataSource={grandTotalProfitData}
            summary={(pageData) => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3} className="text-right mr-2">
                      {nepaliNumberFormatter(grandTotalProfitFooter.grandTotal)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </AppContent>
      </Spin>
    </div>
  );
};

export default CustomReport;
