import { useQuery } from '@tanstack/react-query';
import { DatePicker, Form, Input, InputNumber, PageHeader, Select } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useEffect } from 'react';
import Report from '../..';
import { get_location_list } from '../../../../../services/locations/queries';
import { CustomDatePresets } from '../../utils/datePresets';
const { Option } = Select;
const { RangePicker } = DatePicker;
import moment from 'moment';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { DEFAULT_DATE_FORMAT } from '@/constants';

interface DataType {
  date: string;
  id: number;
  location_id: number;
  product_id: number;
  qty_adjusted: number;
  qty_purchased: number;
  qty_returned_purchase: number;
  qty_returned_sell: number;
  qty_sold: number;
  qty_transferred: number;
  qty_transferred_out: number;
}

const ProductHistoryRangeReport = () => {
  const [form] = Form.useForm();
  const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  useEffect(() => {
    form.setFieldValue('constraints', {
      page: 1,
      size: 10
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Product History Range',
      link: '/reports/product-history-range'
    }
  ];
  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Product ID',
      key: 'id',
      render: (record) => <Link to={`/products/${record.product_id}`}>{record.product_id}</Link>
    },
    {
      title: 'Location ID',
      key: 'locationId',
      render: (record) => <Link to={`/locations/${record.location_id}`}>{record.location_id}</Link>
    },
    {
      title: 'Quantity adjusted',
      dataIndex: 'qty_adjusted',
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Quantity purchased',
      dataIndex: 'qty_purchased',
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Quantity Returned Purchase',
      dataIndex: 'qty_returned_purchase',
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Quantity Returned Sell',
      dataIndex: 'qty_returned_sell',
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Quantity Sold',
      dataIndex: 'qty_sold',
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Quantity transferred',
      dataIndex: 'qty_transferred',
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Quantity transferred Out',
      dataIndex: 'qty_transferred_out',
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (text: string) => <div> {moment(text).local().format(DEFAULT_DATE_FORMAT)}</div>
    }
  ];
  return (
    <Report
      breadcrumbItems={breadcrumbItems}
      state={{ id: 10, name: 'Product History Report' }}
      form={form}
      columnsData={columns}>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item
        name={['constraints', 'locationId']}
        label="Location"
        rules={[
          {
            required: true,
            message: 'Please choose location!'
          }
        ]}>
        <Select placeholder="Select a location!" allowClear>
          {locationsList?.data.results.map((value: any) => {
            return (
              <Option value={value.id} key={value.id}>
                {value.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[
          {
            required: true,
            message: `Please select date!`
          }
        ]}>
        <RangePicker
          ranges={CustomDatePresets}
          onChange={onChange}
          format={'YYYY-MM-DD'}
          className="w-full"
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
    </Report>
  );
};

export default ProductHistoryRangeReport;
