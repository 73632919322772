import { Modal } from 'antd';
import React from 'react';

type Props = {
  title?: string;
  message: string | React.ReactNode;
  onOkClick?: () => void;
  width?: number | string;
};

const CustomInfoModal = ({
  message,
  width,
  title = 'Info',
  onOkClick = () => {
    console.log('error default test');
  }
}: Props) => {
  Modal.info({
    title,
    content: message,
    width,
    onOk() {
      onOkClick();
    }
  });
};

export default CustomInfoModal;
