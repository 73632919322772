import { useRef } from 'react';
import { IPurchaseInvoice } from '../../../../services/invoice/types';
import { update_purchase_print_count_mutation } from '../../../../services/purchases/mutations';
import { useMutation } from '@tanstack/react-query';
import { useReactToPrint } from 'react-to-print';
import { getUserData } from '../../../../utils/auth.utils';
import { Button, PageHeader } from 'antd';
import { find_invoice_number } from '../../../../store/localstorage/preferences';
import { convertUTCStringtoLocalString } from '../../../../utils/convertToUTC';
import CustomTable from '../../CustomResuableInvoice/CustomTable';
import { nepaliNumberFormatter } from '../../../../utils/numberFormatter';
import CustomBarcode from '../../../../pages/barcode';

const PurchaseInvoice: React.FC<IPurchaseInvoice> = ({
  purchaseDetails,
  vendorDetails,
  locationDetails,
  customerDetails,
  adjustmentDetails,
  transferData,
  lines,
  invoiceLayouts,
  handleModalClose
}) => {
  const printRef = useRef<any>();
  const loggedInUser = getUserData();

  const content = JSON.parse(invoiceLayouts?.content);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    async onAfterPrint() {
      if (purchaseDetails) {
        if (purchaseDetails.purchase.printCount === 0)
          await updatePrintCountMutation.mutateAsync(purchaseDetails.purchase.id);
        await updatePrintCountMutation.mutateAsync(purchaseDetails.purchase.id);
      }
    }
  });

  const updatePrintCountMutation = useMutation(update_purchase_print_count_mutation, {
    onSuccess: () => {
      if (handleModalClose) {
        handleModalClose();
      }
    }
  });

  const columns = [
    {
      label: `${content.productLabel ? content.productLabel : 'ProductName'}`,
      dataIndex: 'productName',
      render: (text: string) => <div>{text}</div>
    },
    {
      label: `${content?.hsCodeLabel || 'HS Code'}`,
      dataIndex: 'hsCode',
      render: (text?: string) => <div>{text || 'N/A'}</div>
    },
    {
      label: `${content.unitPriceLabel ? content.unitPriceLabel : 'UnitPrice'}`,
      dataIndex: 'unitPrice',
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: `${content.quantityLabel ? content.quantityLabel : 'Quantity'}`,
      dataIndex: 'quantityWithUnit',
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: 'Misc',
      dataIndex: 'misc',
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    },
    {
      label: `${content.discountLabel ? content.discountLabel : 'Discount'}`,
      dataIndex: 'discount',
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    },
    {
      label: `${content.totalLabel ? content.totalLabel : 'Total Amount'}`,
      dataIndex: 'totalAmount',
      render: (text: number) => <div className="text-center">{nepaliNumberFormatter(text)}</div>
    }
  ];

  const paymentColumns = [
    {
      label: 'Date',
      dataIndex: 'date',
      render: (text: string) => <div>{text.slice(0, 10)}</div>
    },
    // {
    //   label: 'Reference No.',
    //   dataIndex: 'reference',
    //   render: (text: string) => <div>{text}</div>
    // },
    {
      label: 'Amount',
      dataIndex: 'amount',
      render: (text: number) => <div>{nepaliNumberFormatter(text)}/-</div>
    },
    {
      label: 'Payment Mode',
      dataIndex: 'paymentMode',
      render: (text: number) => <div>{text}</div>
    },
    {
      label: 'Payment note',
      dataIndex: 'note',
      render: (text: number) => <div>{text}</div>
    }
  ];

  return (
    <>
      <div className="flex justify-end mb-3">
        <Button type="primary" onClick={handlePrint}>
          Print
        </Button>
      </div>
      <div ref={printRef}>
        <div style={{ color: 'black' }} className="mx-5 my-3">
          <div className="flex flex-col justify-between">
            {purchaseDetails && purchaseDetails.purchase.printCount > 0 && (
              <div className="flex justify-start font-bold text-sm">
                Copy of Original {purchaseDetails?.purchase.printCount}
              </div>
            )}
            <div className="flex justify-between font-bold text-xl mt-4">
              {'FreshKtm Agro Pvt. Ltd.'}
            </div>
            <div className="flex justify-between font-bold text-xl">
              Purchase Details
              {/* ({'Reference No'} : {purchaseDetails?.purchase.referenceNumber}) */}
            </div>
            <hr />
            <div className="grid grid-cols-4 gap-5 mb-5">
              {invoiceLayouts && content.showBusinessName ? (
                <>
                  {vendorDetails && (
                    <div>
                      <PageHeader
                        subTitle="Supplier"
                        style={{
                          padding: '8px 0px',
                          fontWeight: 'bold'
                        }}
                      />
                      {vendorDetails ? (
                        <>
                          <div>{vendorDetails.name}</div>
                          <div>{`${vendorDetails.phone}, ${vendorDetails.email}`}</div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}

              {invoiceLayouts && content.showBusinessName ? (
                <>
                  {purchaseDetails?.purchase.agentName ? (
                    <div>
                      <PageHeader
                        subTitle="Agent"
                        style={{
                          padding: '8px 0px',
                          fontWeight: 'bold'
                        }}
                      />
                      <>
                        <div>
                          {`
                          ${purchaseDetails?.purchase.agentName},
                          ${purchaseDetails?.purchase.agentPhone}
                          `}
                        </div>
                      </>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {invoiceLayouts && content.showLocations ? (
                <>
                  <div>
                    <PageHeader
                      subTitle="Business"
                      style={{
                        padding: '8px 0px',
                        fontWeight: 'bold'
                      }}
                    />
                    <div>VAT No.: {find_invoice_number()}</div>
                    {locationDetails ? (
                      <>
                        <div>{`${
                          invoiceLayouts && content.showLocations ? locationDetails.name : null
                        }`}</div>
                        <div>
                          {`${locationDetails.address}, ${locationDetails.city}, ${locationDetails.country} ${locationDetails.zip}`}
                        </div>
                        <div>{`${locationDetails.phone}, ${locationDetails.email}`}</div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
              <div>
                <PageHeader
                  subTitle="Details"
                  style={{
                    padding: '8px 0px',
                    fontWeight: 'bold'
                  }}
                />
                {purchaseDetails ? (
                  <>
                    <div>
                      {purchaseDetails.purchase.financialReference ? (
                        <div>
                          Financial Reference: {purchaseDetails.purchase.financialReference}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* <div>
                      {' '}
                      {content.referenceNumberLabel
                        ? content.referenceNumberLabel
                        : 'Reference No'}{' '}
                      : {purchaseDetails.purchase.referenceNumber}
                    </div> */}
                    <div>
                      Created :{' '}
                      {convertUTCStringtoLocalString(
                        purchaseDetails.purchase.createdAt,
                        'YYYY MM-DD'
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <CustomTable columns={columns} datas={lines ? lines : []} />
            {purchaseDetails && purchaseDetails.payments?.length > 0 ? (
              <>
                <div>Payment Info</div>

                <CustomTable
                  columns={paymentColumns}
                  datas={purchaseDetails?.payments ? purchaseDetails.payments : []}
                />
              </>
            ) : (
              <></>
            )}
            {purchaseDetails ? (
              <div className="flex justify-start mt-5">
                <div>
                  <div>
                    {content.netTotalLabel ? content.netTotalLabel : 'Total Amount'}: Rs.
                    {purchaseDetails.purchase.totalTaxable +
                      purchaseDetails.purchase.totalNonTaxable}{' '}
                    /-
                  </div>
                  <div>
                    {content.shippingLabel ? content.shippingLabel : 'Shipping'}: Rs.
                    {purchaseDetails.purchase.shippingAmount && purchaseDetails.purchase.shippingTax
                      ? purchaseDetails.purchase.shippingAmount -
                        purchaseDetails.purchase.shippingTax
                      : '0.00'}{' '}
                    /-
                  </div>
                  <div>
                    {content.manualDiscount ? content.netTotalLabel : 'Manual Discount'}: Rs.
                    {purchaseDetails.purchase.manualDiscount} /-
                  </div>
                  <div>
                    {content.purchaseTaxLabel ? content.purchaseTaxLabel : 'Purchase Tax'}: Rs.
                    {purchaseDetails.purchase.totalVat} /-
                  </div>
                  <div>
                    {content.netTotalLabel ? content.netTotalLabel : 'Net Total'}: Rs.
                    {purchaseDetails.purchase.totalAmount} /-
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {content.footerText ? (
              <div dangerouslySetInnerHTML={{ __html: content.footerText }}></div>
            ) : (
              <></>
            )}
            {content.showBarcode && (
              <div className="flex items-center justify-center">
                <CustomBarcode
                  value={purchaseDetails ? purchaseDetails.purchase.financialReference : ''}
                  height={35}
                  width={1.4}
                  fontSize={16}
                />
              </div>
            )}
            <div className="flex items-end">
              <div>
                Printed By:{' '}
                <span className="capitalize">{loggedInUser?.name ? loggedInUser.name : ''}</span>
              </div>
            </div>
          </div>
        </div>
        {purchaseDetails?.purchase.printCount === 0 ? (
          <>
            <div className="pagebreak"></div>
            <div style={{ color: 'black' }} className="mx-5 my-3">
              <div className="flex flex-col justify-between">
                <div className="flex justify-start font-bold text-sm">Copy of Original 1</div>
                <div className="flex justify-between font-bold text-xl mt-4">
                  {'FreshKtm Agro Pvt. Ltd.'}
                </div>
                <div className="flex justify-between font-bold text-xl">
                  Purchase Details
                  {/* ({'Reference No'} : {purchaseDetails?.purchase.referenceNumber}) */}
                </div>
                <hr />
                <div className="grid grid-cols-4 gap-5 mb-5">
                  {invoiceLayouts && content.showBusinessName ? (
                    <>
                      {vendorDetails && (
                        <div>
                          <PageHeader
                            subTitle="Supplier"
                            style={{
                              padding: '8px 0px',
                              fontWeight: 'bold'
                            }}
                          />
                          {vendorDetails ? (
                            <>
                              <div>{vendorDetails.name}</div>
                              <div>{`${vendorDetails.phone}, ${vendorDetails.email}`}</div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                      {customerDetails && (
                        <div>
                          <PageHeader
                            subTitle="Customer"
                            style={{
                              padding: '8px 0px',
                              fontWeight: 'bold'
                            }}
                          />
                          {customerDetails ? (
                            <>
                              <div>{customerDetails.name}</div>
                              <div>{`${customerDetails.phone}, ${customerDetails.email}`}</div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {adjustmentDetails && (
                    <div>
                      <PageHeader
                        subTitle="Business"
                        style={{
                          padding: '4px 0px'
                        }}
                      />
                      {locationDetails ? (
                        <>
                          <div>{locationDetails.name}</div>
                          <div>
                            {`${locationDetails.zip}, ${locationDetails.address}, ${locationDetails.city}, ${locationDetails.country}`}
                          </div>
                          <div>{`${locationDetails?.phone}, ${locationDetails.email}`}</div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                  {invoiceLayouts && content.showBusinessName ? (
                    <>
                      {purchaseDetails?.purchase.agentName ? (
                        <div>
                          <PageHeader
                            subTitle="Agent"
                            style={{
                              padding: '8px 0px',
                              fontWeight: 'bold'
                            }}
                          />
                          <>
                            <div>
                              {`
                          ${purchaseDetails?.purchase.agentName},
                          ${purchaseDetails?.purchase.agentPhone}
                          `}
                            </div>
                          </>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {invoiceLayouts && content.showLocations ? (
                    <>
                      {!(adjustmentDetails || transferData) && (
                        <div>
                          <PageHeader
                            subTitle="Business"
                            style={{
                              padding: '8px 0px',
                              fontWeight: 'bold'
                            }}
                          />
                          <div>VAT No.: {find_invoice_number()}</div>
                          {locationDetails ? (
                            <>
                              <div>{`${
                                invoiceLayouts && content.showLocations
                                  ? locationDetails.name
                                  : null
                              }`}</div>
                              <div>
                                {`${locationDetails.address}, ${
                                  invoiceLayouts && content.city ? locationDetails.city : ''
                                }, ${
                                  invoiceLayouts && content.country ? locationDetails.country : ''
                                } ${invoiceLayouts && content.zipCode ? locationDetails.zip : ''}`}
                              </div>
                              <div>{`${
                                invoiceLayouts && content.mobileNumber ? locationDetails.phone : ''
                              }, ${
                                invoiceLayouts && content.email ? locationDetails.email : ''
                              }`}</div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <div>
                    <PageHeader
                      subTitle="Details"
                      style={{
                        padding: '8px 0px',
                        fontWeight: 'bold'
                      }}
                    />
                    {purchaseDetails ? (
                      <>
                        <div>
                          {purchaseDetails.purchase.financialReference ? (
                            <div>
                              Financial Reference : {purchaseDetails.purchase.financialReference}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        {/* <div>
                          {' '}
                          {content.referenceNumberLabel
                            ? content.referenceNumberLabel
                            : 'Reference No'}{' '}
                          : {purchaseDetails.purchase.referenceNumber}
                        </div> */}
                        <div>
                          Created :{' '}
                          {convertUTCStringtoLocalString(
                            purchaseDetails.purchase.createdAt,
                            'YYYY MM-DD'
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <CustomTable columns={columns} datas={lines ? lines : []} />
                {purchaseDetails?.payments?.length > 0 ? (
                  <>
                    <div>Payment Info</div>

                    <CustomTable
                      columns={paymentColumns}
                      datas={purchaseDetails?.payments ? purchaseDetails.payments : []}
                    />
                  </>
                ) : (
                  <></>
                )}
                {purchaseDetails ? (
                  <div className="flex justify-start mt-5">
                    <div>
                      <div>
                        {content.netTotalLabel ? content.netTotalLabel : 'Total Amount'}: Rs.
                        {purchaseDetails.purchase.totalTaxable +
                          purchaseDetails.purchase.totalNonTaxable}{' '}
                        /-
                      </div>
                      <div>
                        {content.shippingLabel ? content.shippingLabel : 'Shipping'}: Rs.
                        {purchaseDetails.purchase.shippingAmount -
                          purchaseDetails.purchase.shippingTax}{' '}
                        /-
                      </div>
                      <div>
                        {content.manualDiscount ? content.netTotalLabel : 'Manual Discount'}: Rs.
                        {purchaseDetails.purchase.manualDiscount} /-
                      </div>
                      <div>
                        {content.purchaseTaxLabel ? content.purchaseTaxLabel : 'Purchase Tax'}: Rs.
                        {purchaseDetails.purchase.totalVat} /-
                      </div>
                      <div>
                        {content.netTotalLabel ? content.netTotalLabel : 'Net Total'}: Rs.
                        {purchaseDetails.purchase.totalAmount} /-
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {content.footerText ? (
                  <div dangerouslySetInnerHTML={{ __html: content.footerText }}></div>
                ) : (
                  <></>
                )}
                {content.showBarcode && (
                  <div className="flex items-center justify-center">
                    <CustomBarcode
                      value={purchaseDetails.purchase.financialReference}
                      height={35}
                      width={1.4}
                      fontSize={16}
                    />
                  </div>
                )}
                <div className="flex items-end">
                  <div>
                    Printed By:{' '}
                    <span className="capitalize">
                      {loggedInUser?.name ? loggedInUser.name : ''}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default PurchaseInvoice;
