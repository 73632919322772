import LocalStore from './index';
import _ from 'lodash';
import { IWidgetGetConfig } from '@/services/dashboard/v3/types';

type IDashboardReportConfig = {
  widgetId: string;
  props: IWidgetGetConfig;
};

export type IDashboardData =
  | {
      type: 'compare';
      data: Record<string, any[]>;
      fields: string[];
      plotAgainst: string;
    }
  | {
      type: 'global';
      data: Record<string, any[]>;
    };

type IDashboardReportData = IDashboardData & IDashboardReportConfig;

function getTransactionError(event: Event, reject: (reason?: any) => void, type: string) {
  const instanceOfIndexDB = event.target instanceof IDBRequest;
  if (instanceOfIndexDB) {
    reject(event.target.error);
    return;
  }

  reject(new Error(`Failed to ${type} reports on indexDB`));
}

// Store as {data, props, reportId}
export class DashboardDB extends LocalStore {
  static async addReportData(props: IDashboardReportData) {
    if (!DashboardDB.db) await DashboardDB.init();

    return new Promise((resolve, reject) => {
      const transaction = DashboardDB.db.transaction('Dashboard', 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'add');

      const objectStore = transaction.objectStore('Dashboard');
      const checkKey = objectStore.count(props.widgetId);
      checkKey.onerror = (event) => getTransactionError(event, reject, 'add');
      checkKey.onsuccess = async () => {
        if (checkKey.result == 0) {
          const request = objectStore.add(props);
          request.onerror = (event) => getTransactionError(event, reject, 'add');
        } else {
          await DashboardDB.updateReportData(props);
        }
      };
    });
  }

  static async updateReportData(props: IDashboardReportData) {
    if (!DashboardDB.db) await DashboardDB.init();

    return new Promise((resolve, reject) => {
      const transaction = DashboardDB.db.transaction('Dashboard', 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'update');

      const objectStore = transaction.objectStore('Dashboard');
      const request = objectStore.get(props.widgetId);
      request.onerror = (event) => getTransactionError(event, reject, 'update');
      request.onsuccess = (event) => {
        if ((event.target as IDBRequest).result) {
          const requestUpdate = objectStore.put(props);
          requestUpdate.onerror = (event) => getTransactionError(event, reject, 'update');
          requestUpdate.onsuccess = () => resolve('updated data');
        } else reject('Error, could not find report id');
      };
    });
  }

  static async getReportData(
    widgetId: string,
    props: IWidgetGetConfig
  ): Promise<IDashboardData | undefined> {
    if (!DashboardDB.db) await DashboardDB.init();
    return new Promise((resolve, reject) => {
      const transaction = DashboardDB.db.transaction('Dashboard', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'get');

      const objectStore = transaction.objectStore('Dashboard');
      const request = objectStore.get(widgetId);
      request.onerror = (event) => getTransactionError(event, reject, 'get');
      request.onsuccess = (event) => {
        const report = (event.target as IDBRequest).result as IDashboardReportData;
        if (report) {
          const oldProps = { ...report.props, isInitial: false };
          const newProps = { ...props, isInitial: false };
          // Remove isInitial From Comparision

          const isPropSame = _.isEqual(oldProps, newProps);
          // console.log({ oldProps, newProps, isPropSame });

          if (isPropSame) {
            if (report.type === 'compare') {
              resolve({
                type: 'compare',
                data: report.data,
                fields: report.fields,
                plotAgainst: report.plotAgainst
              });
            } else {
              resolve({ type: 'global', data: report.data });
            }
          } else {
            resolve(undefined);
          }
        } else resolve(undefined);
      };
    });
  }

  static async deleteAll() {
    if (!DashboardDB.db) await DashboardDB.init();

    return new Promise((resolve, reject) => {
      const transaction = DashboardDB.db.transaction('Dashboard', 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'delete');

      const objectStore = transaction.objectStore('Dashboard');
      const request = objectStore.clear();
      request.onerror = (event) => getTransactionError(event, reject, 'delete');
      request.onsuccess = () => resolve('deleted data');
    });
  }
}
