import { IProductRateReport } from '@/services/report/types';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

const fields = ['Unit', 'Product', 'Location', 'MIN PRICE', 'AVG PRICE', 'MAX PRICE'];

export const tableExportColumns = fields.map((field) => ({
  label: field,
  dataIndex: field,
  render: (text: string) => {
    return <div className="text-center">{text}</div>;
  }
}));

export const excelExportColumns = fields.map((field) => ({
  title: field,
  dataIndex: field
}));

export function getUpdatedData(data: IProductRateReport[]) {
  return data.map((item) => {
    return {
      ...item,
      'MIN PRICE': nepaliNumberFormatter(parseFloat(item['MIN PRICE'])),
      'AVG PRICE': nepaliNumberFormatter(parseFloat(item['AVG PRICE'])),
      'MAX PRICE': nepaliNumberFormatter(parseFloat(item['MAX PRICE']))
    };
  });
}
