import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IReason, IReasonDetails } from '../adjustments/types';
import { IChannelList, IChannelMessageType, ICloseChannelListData } from './types';

export const get_channel_list = async () => {
  const response: AxiosResponse<IChannelList[], any> = await http.get('channel');
  return response;
};
export const get_channel_list_filter = async (filter = '') => {
  const response: AxiosResponse<IChannelList[], any> = await http.get('channel/?' + filter);
  return response;
};
export const get_channel_slug = async (value: string) => {
  const response = await http.get('channel/slug/' + value);
  return response;
};
export const get_channel_id = async (id: number) => {
  const response: AxiosResponse<IChannelMessageType[], any> = await http.get('channel/' + id);
  return response;
};

// /channel/1?skipFrom=1&skipTo=10
export const get_channel_id_skipfrom_skipto = async (
  id: number,
  skipFrom: number,
  skipTo: number
) => {
  // const response = await http.get('channel/' + id + '/', { skipFrom, skipTo });
  const response: AxiosResponse<IChannelMessageType[], any> = await http.get(
    'channel/' + id + '?skipFrom=' + skipFrom + '&skipTo=' + skipTo
  );
  return response;
};

export const get_closed_channel_list = async () => {
  const response: AxiosResponse<ICloseChannelListData, any> = await http.get('channel/closed', {
    skip: 0,
    count: 1000
  });
  return response;
};

export const get_closed_channel_list_filter = async (filter = '') => {
  const response: AxiosResponse<ICloseChannelListData, any> = await http.get(
    'channel/closed/?' + filter
  );
  return response;
};

export const get_channel_reasons_list = async (skip = 0, count = 0, value = '') => {
  const response: AxiosResponse<IReason[], any> = await http.get('channel/reasons', {
    skip,
    count,
    value
  });
  return response;
};

export const get_channel_reasons_details = async (id: number) => {
  const response: AxiosResponse<IReasonDetails, any> = await http.get('channel/reasons/' + id);
  return response;
};
