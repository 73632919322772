import { io } from 'socket.io-client';
import { API_URL } from '../constants/config';
import { getTokenFromStorage } from './storage.utils';

export const configSocket = (SocketNameSpaces: string) => {
  const token = getTokenFromStorage();

  const socket = io(API_URL + SocketNameSpaces, {
    auth: {
      token: token
    }
  });

  return socket;
};

export const configFonepaySocket = (url: string) => {
  const socket = new WebSocket(url);

  return socket;
};
