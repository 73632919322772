import { useRef, useState } from 'react';
import CustomTable, { IColumnDataForCustomTable } from '../CustomResuableInvoice/CustomTable';
import { useReactToPrint } from 'react-to-print';
import { Button, Menu, message, Tooltip } from 'antd';
import { exportExcelAutoWidth } from '@/utils/exportExcelAutoWidth';
import ActionDropdown from '../Dropdownactions';
import { CustomModal } from '../CustomModal';

interface Props {
  columns: IColumnDataForCustomTable[];
  excelColumns: { title: string; dataIndex: string }[];
  getInfo: () => Promise<any[]>;
  title: string;
}

function ExportAllData({ columns, excelColumns, getInfo, title }: Props) {
  const [exportData, setExportData] = useState<any[]>([]);

  const printPDFRef = useRef<any>();
  const [openPDFExportModal, setOpenPDFExportModal] = useState(false);

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  const handleExport = async (type: 'excel' | 'pdf') => {
    const data = await getInfo();

    const isDataEmpty = data.length === 0;
    if (isDataEmpty) {
      message.error('No data to export');
      return;
    }

    setExportData(data);
    if (type === 'pdf') {
      setOpenPDFExportModal(true);
      return;
    }

    exportExcelAutoWidth(excelColumns, data, title);
  };

  return (
    <>
      <div className="flex justify-end">
        <div>
          <ActionDropdown
            text="Export All"
            button={true}
            buttonJSX={<Button className="secondary-button !rounded-md">Export All</Button>}
            menu={
              <Menu
                items={[
                  {
                    key: '1',
                    label: (
                      <Tooltip title="Export Excel" color="blue">
                        <div className="text-center">Excel</div>
                      </Tooltip>
                    ),
                    onClick: () => handleExport('excel')
                  },
                  {
                    key: '2',
                    label: (
                      <Tooltip title="Export PDF" color="blue">
                        <div className="text-center">PDF</div>
                      </Tooltip>
                    ),
                    onClick: () => handleExport('pdf')
                  }
                ]}
              />
            }
          />
        </div>
      </div>

      <CustomModal
        footer={false}
        isModalOpen={openPDFExportModal}
        setIsModalOpen={setOpenPDFExportModal}
        title="PDF print">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable columns={columns} datas={exportData} reff={printPDFRef} title={title} />
        </div>
      </CustomModal>
    </>
  );
}

export default ExportAllData;
