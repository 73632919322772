import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  PageHeader,
  Select,
  Spin
} from 'antd';
import AppContent from '@/components/Common/Content/Content';
import { USER_INFO } from '@/constants/config';
import { create_global_settings } from '@/services/settings/mutations';
import {
  find_default_shipping_cost,
  find_fixed_decimal_limit,
  find_invoice_due_limit,
  find_profit_margin_preference,
  find_purchase_margin_preference,
  find_sell_default_grade,
  find_sell_margin_preference,
  find_time_limit,
  find_to_use_NPR,
  find_wastage_margin_preference,
  find_zero_total_shipping,
  find_financial_year,
  find_date,
  find_value,
  find_sell_confirmation,
  find_purchase_confirmation,
  find_expense_confirmation,
  find_adjustment_confirmation,
  find_invoice_number,
  find_default_product_category,
  find_default_invoice,
  find_default_pos_invoice,
  find_report_date_limit,
  find_expense_account,
  find_export_report_days_limit,
  find_customer_balance_limit,
  find_customer_payment_days,
  find_email_for_vehicle,
  find_vendor_product_url
} from '@/store/localstorage/preferences';
import { getUserData } from '@/utils/auth.utils';
import { setLocalStorage } from '@/utils/storage.utils';
import { ICreateGlobalSettings, IGlobalValues } from '@/services/settings/types';
import { AxiosResponse } from 'axios';
import CustomSubmitButton from '@/components/Common/CustomButton/CustomSubmitButton';
import { useState } from 'react';
import ProductCategorySearch from '@/components/Common/ProductCategorySearch/ProductCategorySearch';
import { get_invoices_list } from '@/services/settings/queries';
import { MultipleAccountsTypeSearch } from '@/components/Common/MultipleAccountsTypeSearch';
import { get_ird_credential } from '@/services/ird/materialized/queries';
import IRDModal from './IRDModal';
import { IRDCredentialsType } from '@/services/ird/materialized/types';
const { Option } = Select;
const GlobalSettings = () => {
  const user = getUserData();
  const isAdmin = user.perms.includes('ADMIN');

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [productCategory, setProductCategory] = useState<string | number | null>(
    find_default_product_category()
  );

  const [isIRDModalOpen, setIsIRDModalOpen] = useState(false);
  const [irdCredentials, setIrdCredentials] = useState<IRDCredentialsType>();

  useQuery(['ird-credentials'], get_ird_credential, {
    onSuccess: (data) => {
      setIrdCredentials(data);
    },
    enabled: isAdmin
  });

  const userData = getUserData();
  let prefUpdate: IGlobalValues;
  useQuery(['global-preferences'], async () => {
    form.setFieldValue('sellMargin', find_sell_margin_preference());
    form.setFieldValue('purchaseMargin', find_purchase_margin_preference());
    form.setFieldValue('profitMargin', find_profit_margin_preference());
    form.setFieldValue('wastageMargin', find_wastage_margin_preference());
    form.setFieldValue('defaultShippingCost', find_default_shipping_cost());
    form.setFieldValue('totalZeroShipping', find_zero_total_shipping());
    form.setFieldValue('sellGrade', find_sell_default_grade());
    form.setFieldValue('categoryId', find_default_product_category());
    form.setFieldValue('invoiceDueLimit', find_invoice_due_limit());
    form.setFieldValue('invoiceNumber', find_invoice_number());
    form.setFieldValue('defaultInvoice', find_default_invoice());
    form.setFieldValue('defaultPosPrint', find_default_pos_invoice());
    form.setFieldValue('financialYear', find_financial_year());
    form.setFieldValue('defaultTime', find_time_limit());
    form.setFieldValue('reportDateLimit', find_report_date_limit());
    form.setFieldValue('fixedDecimal', find_fixed_decimal_limit());
    form.setFieldValue('NPR', find_to_use_NPR());
    form.setFieldValue('date', find_date());
    form.setFieldValue('value', find_value());
    form.setFieldValue('confirmationSell', find_sell_confirmation());
    form.setFieldValue('confirmationPurchase', find_purchase_confirmation());
    form.setFieldValue('confirmationExpense', find_expense_confirmation());
    form.setFieldValue('confirmationAdjustment', find_adjustment_confirmation());
    form.setFieldValue('expenseAccountId', find_expense_account());
    form.setFieldValue('exportReportDaysLimit', find_export_report_days_limit());
    form.setFieldValue('customerAccountBalanceLimitForOffer', find_customer_balance_limit());
    form.setFieldValue('customerPaymentDaysForOffer', find_customer_payment_days());
    form.setFieldValue('emailForVehicle', find_email_for_vehicle());
    form.setFieldValue('vendorProductUrl', find_vendor_product_url());
    setIsLoading(false);
  });

  const { data: invoiceLayouts, isLoading: invoiceLoading } = useQuery(
    ['invoiceLayouts'],
    async () => get_invoices_list()
  );

  const updateGlobalSettingsMutation = useMutation(create_global_settings, {
    onSuccess: (data: AxiosResponse<ICreateGlobalSettings, any>) => {
      //console.log('Data', data);
      setLocalStorage(USER_INFO, {
        ...userData,
        global: data.data
      });
      message.success('Global settings updated successfully!');
      setIsLoading(false);
    },
    onError: (e: any) => {
      message.error(e.message);
      setIsLoading(false);
    }
  });

  const onFinish = (values: IGlobalValues) => {
    setIsLoading(true);
    const financialYearSchedule = {
      date: form.getFieldValue('date'),
      value: form.getFieldValue('value')
    };
    const { date, value, ...newValues } = values;
    prefUpdate = { ...newValues, financialYearSchedule: financialYearSchedule };
    // console.log('PrefUpdate', prefUpdate);

    updateGlobalSettingsMutation.mutate({ content: JSON.stringify(prefUpdate) });
  };

  const FilteredInvoice = () => {
    if (!invoiceLoading) {
      return (
        <>
          {invoiceLayouts?.data.map((value) => (
            <Option value={value.id} key={value.id}>
              {value.name}
            </Option>
          ))}
        </>
      );
    }
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={[{ label: 'Global' }]}>
        <IRDModal
          open={isIRDModalOpen}
          setOpen={setIsIRDModalOpen}
          setIsLoading={setIsLoading}
          irdCredentials={irdCredentials}
          setIrdCredentials={setIrdCredentials}
        />

        <Form form={form} layout={'vertical'} onFinish={onFinish}>
          <PageHeader
            subTitle="IRD Credentials"
            style={{ margin: 0, padding: 0, marginBottom: '20px' }}
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            <Form.Item label="Username">
              <Input disabled value={irdCredentials?.username} />
            </Form.Item>

            <div className="flex items-end mb-1.5">
              <Button className="w-fit" type="primary" onClick={() => setIsIRDModalOpen(true)}>
                Update
              </Button>
            </div>
          </div>

          <PageHeader
            subTitle="Dashboard Margins"
            style={{
              margin: 0,
              padding: 0,
              marginBottom: '20px'
            }}
            className="hidden"
          />

          <div className="hidden grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            <Form.Item
              name="sellMargin"
              label="Sell Margin"
              rules={[
                {
                  required: true,
                  message: 'Please Input Sell Margin!'
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              name="purchaseMargin"
              label="Purchase Margin"
              rules={[
                {
                  required: true,
                  message: 'Please Input Purchase Margin!'
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              name="wastageMargin"
              label="Wastage Margin"
              rules={[
                {
                  required: true,
                  message: 'Please Input Wastage Margin!'
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              name="profitMargin"
              label="Profit Margin"
              rules={[
                {
                  required: true,
                  message: 'Please Input Profit Margin!'
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
          </div>
          {/* <Divider /> */}
          <PageHeader
            subTitle="Shipping"
            style={{
              margin: 0,
              padding: 0,
              marginTop: '20px',
              marginBottom: '20px'
            }}
          />

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            {/* <Form.Item
              name="defaultShippingCost"
              label="Default cost"
              rules={[
                {
                  required: true,
                  message: 'Please Input default cost!'
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item> */}
            <Form.Item
              name="totalZeroShipping"
              label="Total for zero cost"
              rules={[
                {
                  required: true,
                  message: 'Please Input total!'
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
          </div>
          <Divider />
          <PageHeader
            subTitle="Grades"
            style={{
              margin: 0,
              padding: 0,
              marginTop: '20px',
              marginBottom: '20px'
            }}
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            <Form.Item
              name="sellGrade"
              label="Sell Default Grade"
              rules={[
                {
                  required: true,
                  pattern: new RegExp('^[A-Z]$'),
                  message: 'Please Input Grade(Capital Alphabet (A-Z))!'
                }
              ]}>
              <Input maxLength={1} />
            </Form.Item>
          </div>
          <Divider />
          <PageHeader
            subTitle="Product"
            style={{
              margin: 0,
              padding: 0,
              marginTop: '20px',
              marginBottom: '20px'
            }}
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            <Form.Item
              name="categoryId"
              label="Default Product Category"
              rules={[
                {
                  required: true,
                  message: 'Please add a category!'
                }
              ]}>
              <ProductCategorySearch
                isAll={false}
                showDefault={false}
                setValue={productCategory ? productCategory : null}
                onProductCategoryChange={(val: string | number | null) => {
                  form.setFieldValue(['categoryId'], val);
                  setProductCategory(val);
                }}
              />
            </Form.Item>
          </div>
          <Divider />
          <PageHeader
            subTitle="Account"
            style={{
              margin: 0,
              padding: 0,
              marginTop: '20px',
              marginBottom: '20px'
            }}
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            <MultipleAccountsTypeSearch
              formData={{
                formName: ['expenseAccountId'],
                label: 'Expense Account'
              }}
              isAll={false}
              required={true}
              mode="multiple"
            />
          </div>
          <Divider />
          <PageHeader
            subTitle="Report Export Days Limit"
            style={{
              margin: 0,
              padding: 0,
              marginTop: '20px',
              marginBottom: '20px'
            }}
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            <Form.Item
              name="exportReportDaysLimit"
              label="Export Report Days Limit"
              rules={[
                {
                  required: true,
                  message: 'Please input a number!'
                }
              ]}>
              <InputNumber controls={false} min={0} max={365} />
            </Form.Item>
          </div>
          <Divider />
          <PageHeader
            subTitle="Invoice"
            style={{
              margin: 0,
              padding: 0,
              marginTop: '20px',
              marginBottom: '20px'
            }}
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            <Form.Item
              name="invoiceDueLimit"
              label="Invoice Quantity Limit"
              rules={[
                {
                  required: true,
                  message: 'Please input a number!'
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item name="financialYear" label="Current Financial Year">
              <Input readOnly={true} />
            </Form.Item>
            <Form.Item name="value" label="Next Financial Year">
              <Input />
            </Form.Item>
            <Form.Item name="date" label="Start Date">
              <Input type="date" min={new Date().toISOString().split('T')[0]} />
            </Form.Item>
            <Form.Item
              name="invoiceNumber"
              label="Invoice Number"
              rules={[
                {
                  required: true,
                  message: 'Please input a number!'
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              name="defaultInvoice"
              label="Default Invoice"
              rules={[
                {
                  required: true,
                  message: 'Please add a default invoice!'
                }
              ]}>
              <Select placeholder="Select an invoice" allowClear={false}>
                {FilteredInvoice()}
              </Select>
            </Form.Item>
            <Form.Item
              name="defaultPosPrint"
              label="Default POS Print"
              rules={[
                {
                  required: true,
                  message: 'Please add a default bill print!'
                }
              ]}>
              <Select placeholder="Select an invoice" allowClear={false}>
                {FilteredInvoice()}
              </Select>
            </Form.Item>
          </div>
          <Divider />
          <PageHeader
            subTitle="Confirmation Modal"
            style={{
              margin: 0,
              padding: 0,
              marginTop: '20px',
              marginBottom: '20px'
            }}
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            <Form.Item name="confirmationSell" label="For Sell" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item name="confirmationPurchase" label="For Purchase" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item name="confirmationExpense" label="For Expense" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item name="confirmationAdjustment" label="For Adjustment" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </div>
          <Divider />
          <PageHeader
            subTitle="Default Fetch Data"
            style={{
              margin: 0,
              padding: 0,
              marginTop: '20px',
              marginBottom: '20px'
            }}
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            <Form.Item
              name="defaultTime"
              label="Default time limit(sec)"
              rules={[
                {
                  required: true,
                  message: 'Please input a number!'
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              name="reportDateLimit"
              label="Report Date Limit (days)"
              rules={[
                {
                  required: true,
                  message: 'Please input a number!'
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
          </div>
          <PageHeader
            subTitle="Fixed Decimal Places"
            style={{
              margin: 0,
              padding: 0,
              marginTop: '20px',
              marginBottom: '20px'
            }}
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            <Form.Item
              name="fixedDecimal"
              label="Fixed Decimal places"
              rules={[
                {
                  required: true,
                  message: 'Please input a number!'
                }
              ]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item name="NPR" label="Use NPR" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </div>
          <PageHeader
            subTitle="Customer Offer Settings"
            style={{
              margin: 0,
              padding: 0,
              marginTop: '20px',
              marginBottom: '20px'
            }}
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            <Form.Item
              name="customerAccountBalanceLimitForOffer"
              label="Account Balance Limit"
              rules={[{ required: true, message: 'Please input a number!' }]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
            <Form.Item
              name="customerPaymentDaysForOffer"
              label="Payment Days"
              rules={[{ required: true, message: 'Please input a number!' }]}>
              <InputNumber controls={false} min={0} />
            </Form.Item>
          </div>
          <Divider />
          <PageHeader
            subTitle="Vehicle Reminder"
            style={{ margin: 0, padding: 0, marginTop: '20px', marginBottom: '20px' }}
          />

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            <Form.Item
              name="emailForVehicle"
              label="Email for Reminder"
              rules={[{ required: true, type: 'email', message: 'Please provide valid email' }]}>
              <Input />
            </Form.Item>
          </div>

          <PageHeader
            subTitle="Vendor Products"
            style={{ margin: 0, padding: 0, marginTop: '20px', marginBottom: '20px' }}
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            <Form.Item
              name="vendorProductUrl"
              label="URL"
              rules={[{ required: true, message: 'Please provide url for vendor products' }]}>
              <Input />
            </Form.Item>
          </div>

          <div className="flex justify-end">
            <Form.Item>
              <CustomSubmitButton text="Save" />
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default GlobalSettings;
