import moment from 'moment';

export const convertLocalToUTCString = (localDate: moment.Moment | string, format?: string) => {
  if (typeof localDate === 'string' || localDate instanceof String) {
    if (format) {
      return moment(localDate).utc().format(format);
    }
    return moment(localDate).utc().format();
  } else {
    return localDate.utc().format();
  }
};

export const convertLocalToUTCMoment = (localDate: moment.Moment | string) => {
  if (typeof localDate === 'string' || localDate instanceof String) {
    return moment(localDate).utc();
  } else {
    return localDate.utc();
  }
};

export const convertUTCStringtoLocalString = (
  localDate: moment.Moment | string,
  format?: string
) => {
  if (format) {
    return moment(localDate).format(format);
  }
  return moment(localDate).format('YYYY-MM-DD');
};
