import { Button } from 'antd';
import React from 'react';

const CustomSubmitButton = ({ text, disabled = false }: { text: string; disabled?: boolean }) => {
  return (
    <Button
      htmlType="submit"
      disabled={disabled}
      className="!disabled:bg-white !disabled:text-black disabled:cursor-not-allowed disabled:opacity-50"
      style={{ backgroundColor: '#1890ff', color: 'white', borderRadius: '10px' }}>
      {text}
    </Button>
  );
};

export default CustomSubmitButton;
