import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { get_unexpired_lots_details_bylocationId_productId } from '../../../services/products/queries';
import { Spin, Table } from 'antd';
import { find_to_use_NPR } from '../../../store/localstorage/preferences';
// import { async } from '@firebase/util';

interface LotsInfo {
  productinfo: any;
  locationId: number;
}

const Lotsinfo = ({ productinfo, locationId }: LotsInfo) => {
  const [allData, setAllData] = useState<any>([]);
  const [loading, setloading] = useState<boolean>(true);

  //   useQuery([`lots ${locationId} ${productinfo.id}`], async () => {
  //     const lotsinfo = await get_unexpired_lots_details_bylocationId_productId(
  //       locationId,
  //       productinfo.productId
  //     );
  //     console.log('lotsInfo', lotsinfo);
  //   });
  const getInfo = async () => {
    const lotsinfo = await get_unexpired_lots_details_bylocationId_productId(
      locationId,
      productinfo.productId
    );
    for (let ind = 0; ind < lotsinfo.length; ind++) {
      (lotsinfo[ind].qtyAvailable = lotsinfo[ind].qtyAvailable / productinfo?.baseUnitMultiplier),
        (lotsinfo[ind].purchasePriceAvg = parseFloat(lotsinfo[ind].purchasePriceAvg).toFixed(2)),
        (lotsinfo[ind].unit = productinfo?.unitName ? productinfo.unitName : '');
    }
    setAllData(lotsinfo);
    setloading(false);
    console.log('lotsInfo', lotsinfo);
  };

  useEffect(() => {
    getInfo();
  }, [productinfo, locationId]);

  const columnss = [
    {
      title: 'S.N.',
      render: (a: any, b: any, c: any) => <div className="text-center">{c + 1}</div>,
      width: 3
    },
    {
      title: 'Lot Number',
      dataIndex: 'lotNumber',
      width: 10
    },
    {
      title: 'Qty Available',
      dataIndex: 'qtyAvailable',
      width: 10
    },
    {
      title: 'Price Avg.',
      dataIndex: 'purchasePriceAvg',
      width: 10
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      width: 10
    }
  ];
  // console.log('productInfo', productinfo);
  // console.log('locationID', locationId);
  return (
    <Spin spinning={loading}>
      <Table bordered dataSource={allData} columns={columnss} pagination={false} size="small" />
    </Spin>
  );
};

export default Lotsinfo;
