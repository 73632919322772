import ProductsDB from '../store/localstorage/ProductsDB';

interface PurchasedPriceInfo {
  productId: number;
  unitId: number;
  purchasedPrice: number;
}
export const notifyMessage = async (lines: any, purchasedPrices: PurchasedPriceInfo[]) => {
  const errorMessages = [];

  if (lines) {
    for (const line of lines) {
      if (line) {
        const purchasedPriceInfo = purchasedPrices.find(
          (info) => info.productId === line.productId && info.unitId === line.unitId
        );
        if (purchasedPriceInfo && line.unitPrice < purchasedPriceInfo.purchasedPrice) {
          let productName = line.productName;
          if (!productName) {
            const product = await ProductsDB.getProductDetails(line.productId);
            productName = product.name;
          }
          errorMessages.push(
            `The entered rate is less than the purchased price for product ${productName}. Purchased price: ${purchasedPriceInfo.purchasedPrice}.`
          );
        }
      }
    }
  }

  return errorMessages;
};
