import moment from 'moment';
import { DashboardDatePresets } from '@/pages/sqlsource/report/utils/datePresets';
import { CompareType } from '@/services/dashboard/v3/enums';
import { DatePicker, Form, FormInstance, message, PageHeader } from 'antd';
import { useEffect, useState } from 'react';
import { RangePickerProps } from 'antd/lib/date-picker';

interface Props {
  form: FormInstance;
  compareType: CompareType;
}

function CustomBothRange({ form, compareType }: Props) {
  const [singleDate, setSingleDate] = useState<moment.Moment | null>(null);
  const [isFirstRangeSelected, setIsFirstRangeSelected] = useState(false);

  useEffect(() => {
    if (compareType !== CompareType.CUSTOM_BOTH_RANGE) return;

    const firstRange = form.getFieldValue('customFirstDateRange') as [moment.Moment, moment.Moment];
    setIsFirstRangeSelected(!!firstRange);

    const secondRange = form.getFieldValue('customSecondDateRange') as
      | [moment.Moment, moment.Moment]
      | null;

    if (!secondRange) return;
    setSingleDate(secondRange[0]);
  }, [compareType]);

  const handleRangeChange: RangePickerProps['onChange'] = (date) => {
    form.setFieldsValue({ customSecondDateRange: null });
    setIsFirstRangeSelected(!!date);
    setSingleDate(null);
  };

  const disabledSingleDate = (current: moment.Moment) => {
    const firstRange = form.getFieldValue('customFirstDateRange') as [moment.Moment, moment.Moment];
    if (!firstRange) return false;

    const [start, end] = firstRange;
    return current.isBetween(start, end, 'days', '[]') || current.isAfter(moment(), 'day');
  };

  const handleSingleDateChange = (date: moment.Moment | null) => {
    const range = form.getFieldValue('customFirstDateRange') as [moment.Moment, moment.Moment];
    if (!range) {
      message.error(
        'Please select range date first, then select single date to calculate other range'
      );
      return;
    }

    if (!date) {
      message.error('Please select date');
      return;
    }

    const diff = range[1].diff(range[0], 'days');
    const calculatedEndDate = date.clone().add(diff, 'days').endOf('day');
    form.setFieldsValue({ customSecondDateRange: [date.startOf('day'), calculatedEndDate] });
    setSingleDate(date);
  };

  return (
    <div>
      <PageHeader className="flex-1" subTitle="Select Compare Date" style={{ padding: '4px 0' }} />

      <Form.Item
        rules={[
          { required: compareType === CompareType.CUSTOM_BOTH_RANGE, message: 'Please select date' }
        ]}
        name={'customFirstDateRange'}
        label="First Compare Range">
        <DatePicker.RangePicker
          data-no-dnd="true"
          allowClear
          className="w-full"
          onChange={handleRangeChange}
          ranges={DashboardDatePresets}
        />
      </Form.Item>

      {isFirstRangeSelected && (
        <>
          <Form.Item label="Single Date" required>
            <DatePicker
              className="w-full"
              value={singleDate}
              onChange={handleSingleDateChange}
              disabled={!isFirstRangeSelected}
              disabledDate={disabledSingleDate}
            />
          </Form.Item>

          <PageHeader
            className="flex-1"
            subTitle="Calculated Other Date"
            style={{ padding: '4px 0' }}
          />

          <Form.Item name={'customSecondDateRange'} label="Second Range">
            <DatePicker.RangePicker
              data-no-dnd="true"
              allowClear
              disabled
              className="w-full"
              format={'YYYY-MM-DD hh:mm a'}
            />
          </Form.Item>
        </>
      )}
    </div>
  );
}

export default CustomBothRange;
