export const NotificationEvents = {
  SELL_CREATE: 'SELL_CREATE',
  SELL_ORDER_CREATE: 'SELL_ORDER_CREATE',
  PURCHASE_CREATE: 'PURCHASE_CREATE',
  PURCHASE_ORDER_CREATE: 'PURCHASE_ORDER_CREATE',
  PURCHASE_ORDER_FORM: 'PURCHASE_ORDER_FORM',
  SELL_ORDER_FORM: 'SELL_ORDER_FORM',
  ADJUSTMENT_CREATE: 'ADJUSTMENT_CREATE',
  TRANSFER_INITIATED: 'TRANSFER_INITIATED',
  TRANSFER_APPROVED: 'TRANSFER_APPROVED',
  LOT_MERGED: 'LOT_MERGED',
  LOT_SPLIT: 'LOT_SPLIT',
  DISPATCH_CREATE: 'DISPATCH_CREATE',
  DISPATCH_FINALIZE: 'DISPATCH_FINALIZE',
  MESSAGE_RECEIVED: 'MESSAGE_RECEIVED',
  EXPENSE_CREATE: 'EXPENSE_CREATE',
  EXPENSE_RECURRING_CREATE: 'EXPENSE_RECURRING_CREATE',
  VEHICLE_REMINDERS: 'VEHICLE_REMINDERS',
  ACL: 'ACL'
};
