import moment from 'moment';

import LocalStore from '.';
import isBetween from '@/utils/isBetween';
import { IFinancialYear } from '@/services/financial-year/types';
import {
  get_current_financial_year,
  get_financial_year_by_id,
  get_financial_years
} from '@/services/financial-year/queries';

function getTransactionError(event: Event, reject: (reason?: any) => void, type: string) {
  const instanceOfIndexDB = event.target instanceof IDBRequest;
  if (instanceOfIndexDB) {
    reject(event.target.error);
    return;
  }

  reject(new Error(`Failed to ${type} financial year on indexDB`));
}

export class FinancialYearDB extends LocalStore {
  static async add(years: IFinancialYear[]) {
    if (!FinancialYearDB.db) await FinancialYearDB.init();

    return new Promise((resolve, reject) => {
      const transaction = FinancialYearDB.db.transaction('FinancialYears', 'readwrite');

      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'add');

      const store = transaction.objectStore('FinancialYears');
      years.forEach(async (year) => {
        const checkKey = store.count(year.id);
        checkKey.onsuccess = async () => {
          if (checkKey.result === 0) {
            year.isCurrent = isBetween(year.startDate, year.endDate);
            year.startDate = moment(year.startDate).format('YYYY-MM-DD');
            year.endDate = moment(year.endDate).format('YYYY-MM-DD');
            const request = store.add(year);

            request.onerror = (event) => getTransactionError(event, reject, 'add');
          } else {
            await FinancialYearDB.update(year);
          }
        };
      });
    });
  }

  static async update(year: IFinancialYear) {
    if (!FinancialYearDB.db) await FinancialYearDB.init();

    return new Promise((resolve, reject) => {
      const transaction = FinancialYearDB.db.transaction('FinancialYears', 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'update');

      const objectStore = transaction.objectStore('FinancialYears');
      const request = objectStore.get(year.id);

      request.onsuccess = (event) => {
        if ((event.target as IDBRequest).result) {
          year.isCurrent = isBetween(year.startDate, year.endDate);
          year.startDate = moment(year.startDate).format('YYYY-MM-DD');
          year.endDate = moment(year.endDate).format('YYYY-MM-DD');

          const requestUpdate = objectStore.put(year);

          requestUpdate.onerror = (event) => getTransactionError(event, reject, 'update');
          requestUpdate.onsuccess = () => resolve('updated data');
        } else reject('Error, could not find id.');
      };

      request.onerror = (event) => getTransactionError(event, reject, 'update');
    });
  }

  static async getById(id: number): Promise<IFinancialYear> {
    if (!FinancialYearDB.db) await FinancialYearDB.init();

    return new Promise((resolve, reject) => {
      const transaction = FinancialYearDB.db.transaction('FinancialYears', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'get');

      const objectStore = transaction.objectStore('FinancialYears');

      const request = objectStore.get(id);
      request.onsuccess = async (event) => {
        const year = (event.target as IDBRequest).result;
        if (year) {
          resolve(year);
          return;
        }

        const apiYear = await get_financial_year_by_id(id);
        await FinancialYearDB.add([apiYear]);
        resolve(apiYear);
      };
      request.onerror = (event) => getTransactionError(event, reject, 'get');
    });
  }

  static async getAll(): Promise<IFinancialYear[]> {
    const hasFinancialYear = FinancialYearDB?.db?.objectStoreNames?.contains('FinancialYears');

    if (!hasFinancialYear) await FinancialYearDB.init();

    return new Promise((resolve, reject) => {
      const transaction = FinancialYearDB.db.transaction('FinancialYears', 'readonly');
      const objectStore = transaction.objectStore('FinancialYears');

      const request = objectStore.getAll();
      request.onerror = (event) => getTransactionError(event, reject, 'get all');
      request.onsuccess = async (event) => {
        const financialYears: IFinancialYear[] = (event.target as IDBRequest).result;
        if (financialYears.length > 0) {
          resolve(financialYears);
          return;
        }

        // Return updated years
        const years = await get_financial_years();
        await FinancialYearDB.add(years);
        years.forEach((year) => {
          year.isCurrent = isBetween(year.startDate, year.endDate);
        });
        resolve(years);
      };
    });
  }

  static async getCurrent(): Promise<IFinancialYear> {
    if (!FinancialYearDB.db) await FinancialYearDB.init();

    return new Promise((resolve, reject) => {
      const transaction = FinancialYearDB.db.transaction('FinancialYears', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'get current');

      const objectStore = transaction.objectStore('FinancialYears');
      const request = objectStore.openCursor();

      request.onsuccess = async (event) => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor) {
          const year = cursor.value as IFinancialYear;
          if (isBetween(year.startDate, year.endDate)) {
            year.startDate = moment(year.startDate).format('YYYY-MM-DD');
            year.endDate = moment(year.endDate).format('YYYY-MM-DD');
            resolve(year);
          } else cursor.continue();
        } else {
          const currentYear = await get_current_financial_year();
          await FinancialYearDB.add([currentYear]);
          const startDate = moment(currentYear.startDate).format('YYYY-MM-DD');
          const endDate = moment(currentYear.endDate).format('YYYY-MM-DD');
          resolve({ ...currentYear, startDate, endDate });
        }
      };
    });
  }

  static async getYearByDate(startDate: string, endDate: string): Promise<IFinancialYear | null> {
    if (!FinancialYearDB.db) await FinancialYearDB.init();

    return new Promise((resolve, reject) => {
      const transaction = FinancialYearDB.db.transaction('FinancialYears', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'get year by date');

      const objectStore = transaction.objectStore('FinancialYears');
      const request = objectStore.openCursor();

      request.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor) {
          const year = cursor.value as IFinancialYear;

          const isStartSame = moment(year.startDate).isSame(startDate);
          const isEndSame = moment(year.endDate).isSame(endDate);

          if (isStartSame && isEndSame) {
            resolve(year);
            return;
          }

          cursor.continue();
        } else resolve(null);
      };
    });
  }
}
