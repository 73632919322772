import { Button, Divider, Form, FormInstance, Select, message } from 'antd';
import { IBankResponseData } from '../../../services/bank/types';
import { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { get_bank_list } from '../../../services/bank/queries';

interface BankSearchProps {
  formData?: {
    formName: string | (string | number)[];
    formLabel: string;
  };
  formMain?: FormInstance<any>;
  isAll?: boolean;
  required?: boolean;
  disabled?: boolean;
  onSelect?: (value: number) => any;
}

const { Option } = Select;

export const BankSearch: React.FC<BankSearchProps> = ({
  formData,
  formMain,
  isAll = true,
  required = false,
  disabled = false
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchBankIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const [bankSearch, setBankSearch] = useState<IBankResponseData[]>([]);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);

  // useEffect(() => {
  //   searchBankIndexDB('');
  // }, []);

  const searchBankIndexDB = async (value: string) => {
    try {
      // const response = await BankDB.searchBankByName(value, 100);
      // if (!response || response.length == 0) {
      // message.info('Cannot find any account with that name in cache, searching in server...');
      searchBank(value);
      // } else {
      //     setBankSearch(response);
      //     checkBankAndAdd(response);
      //     setIsCacheResponse(true);
      //   }
    } catch (error) {
      message.error(error as string);
    }
  };

  const searchBank = async (value: string) => {
    let response;
    try {
      response = await get_bank_list(0, 10, value);

      //console.log('response', response);
      if (!response || response.results.length == 0) {
        message.error('Cannot find bank with that name!');
        setBankSearch([]);
        setIsMore(false);
      } else {
        setBankSearch(response.results);
        // BankDB.addBank(response.result);

        // checkBankAndAdd(response.result);
        //console.log('response', response);
        if (response.results.length < 10) {
          setIsMore(false);
        } else {
          setIsMore(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setIsCacheResponse(false);
    setSkip(10);
  };

  const searchMoreBank = async (value: string) => {
    let response;
    try {
      response = await get_bank_list(skip, 10, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.results.length == 0) {
      message.info('Cannot find more bank with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);

      setBankSearch([...bankSearch, ...response.results]);
      // BankDB.addBank(response.result);

      // checkBankAndAdd(response.result);
      if (response.results.length < 10) {
        setIsMore(false);
      }
    }
  };

  // const checkBankAndAdd = (bank: IBankResponseData[]) => {
  //   if (bankList.length > 0) {
  //     bank = bank.filter((value) => {
  //       const searchBank = bankSearch.find((val) => val.id == value.id);
  //       if (searchBank) return false;
  //       return true;
  //     });
  //     if (bank.length > 0)
  //       setBankList((prevValue) => {
  //         bank = bank.filter((value) => {
  //           const searchBank = prevValue.find((val) => val.id == value.id);
  //           if (searchBank) return false;
  //           return true;
  //         });
  //         return [...prevValue, ...bank];
  //       });
  //   } else {
  //     setBankList(bank);
  //   }
  // };

  const options = bankSearch.map((value: IBankResponseData) => (
    <Option key={value.id} value={value.id}>
      {value.name}
    </Option>
  ));

  return (
    <>
      {formData ? (
        <Form.Item
          name={formData.formName}
          label={formData.formLabel}
          rules={[
            {
              required: required,
              message: 'Please add a bank!'
            }
          ]}>
          <Select
            showSearch
            allowClear
            disabled={disabled}
            placeholder="Select a bank"
            defaultActiveFirstOption={false}
            showArrow={false}
            autoClearSearchValue={true}
            filterOption={false}
            dropdownMatchSelectWidth={false}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                {isCacheResponse ? (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchBank(searchValue);
                      }}>
                      {'Pull More & Sync'}
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    {isMore ? (
                      <Button
                        type="text"
                        style={{
                          color: 'blue',
                          width: '100%'
                        }}
                        onClick={() => {
                          searchMoreBank(searchValue);
                        }}>
                        {'Get More...'}
                      </Button>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center'
                        }}>
                        No more data...
                      </div>
                    )}
                  </div>
                )}
              </>
            )}>
            {isAll ? <Option value="">All</Option> : null}
            {options}
          </Select>
        </Form.Item>
      ) : (
        <Select
          showSearch
          allowClear
          disabled={disabled}
          placeholder="Select an account type"
          defaultActiveFirstOption={false}
          showArrow={false}
          autoClearSearchValue={true}
          filterOption={false}
          dropdownMatchSelectWidth={false}
          onSearch={(val) => {
            setSearchValue(val);
          }}
          notFoundContent={null}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              {isCacheResponse ? (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  <Button
                    type="text"
                    style={{
                      color: 'blue',
                      width: '100%'
                    }}
                    onClick={() => {
                      searchBank(searchValue);
                    }}>
                    {'Pull More & Sync'}
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {isMore ? (
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchMoreBank(searchValue);
                      }}>
                      {'Get More...'}
                    </Button>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center'
                      }}>
                      No more data...
                    </div>
                  )}
                </div>
              )}
            </>
          )}>
          {isAll ? <Option value="">All</Option> : null}
          {options}
        </Select>
      )}
    </>
  );
};
