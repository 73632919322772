import { get_category_details } from '../../../../services/category/queries';
import { IProductInvoicePrint } from '../../../../services/invoice/types';
import { get_product_list_ids, get_units_list } from '../../../../services/products/queries';
import { IUnits } from '../../../../services/products/types';
import { get_routes_details } from '../../../../services/routes/queries';
import { IRoutesList } from '../../../../services/routes/types';
import { get_sell_order_product_print_byLocationId } from '../../../../services/sell/queries';
import { ISellOrderResponse } from '../../../../services/sell/types';
import { get_customer_list_ids } from '../../../../services/users/queries';
import CustomersDB from '../../../../store/localstorage/CustomerDB';
import ProductsDB from '../../../../store/localstorage/ProductsDB';
import RoutesDB from '../../../../store/localstorage/RoutesDB';
import UnitsDB from '../../../../store/localstorage/UnitsDB';
import { convertLocalToUTCString } from '../../../../utils/convertToUTC';
import { ConvertObjectToURL } from '../../../../utils/converturl';

export const getSellOrderProductPrintData = async (
  startDate: string,
  endDate: string,
  locationId?: number,
  routeId?: number,
  categoryId?: number
) => {
  const filter = ConvertObjectToURL({
    locationId: locationId,
    routeId: routeId,
    categoryId: categoryId,
    startDate: convertLocalToUTCString(startDate),
    endDate: convertLocalToUTCString(endDate)
  });
  const response = await get_sell_order_product_print_byLocationId(filter);

  const productss: any = {};
  const products: any = [];
  let categoryName = '';

  await fillCustomersDB(response.data.sellOrders);

  for (let ind = 0; ind < response.data.sellOrders.length; ind++) {
    const currentCustomerId = response.data.sellOrders[ind].customerId;
    const currentSellOrderId = response.data.sellOrders[ind].id;

    if (response.data.sellOrders[ind].customerId == null) {
      continue;
    } else {
      const customerDetails = await CustomersDB.getCustomer(currentCustomerId);
      const routeId = response.data.sellOrders[ind].address.routeId;
      let routeInfo;
      if (routeId) {
        routeInfo = await getRouteInfo(routeId);
      }

      if (!response.data.productData[currentSellOrderId]) {
        continue;
      }

      for (let index = 0; index < response.data.productData[currentSellOrderId].length; index++) {
        const currproductId = response.data.productData[currentSellOrderId][index].productId;
        let ProductDetails = await ProductsDB.getProduct(currproductId);
        if (!ProductDetails) {
          const allProducts = await get_product_list_ids([
            ...new Set(
              response.data.productData[currentSellOrderId].map((val) => {
                return val.productId;
              })
            )
          ]);
          await ProductsDB.addProducts(allProducts.data.results);
          ProductDetails = await ProductsDB.getProduct(currproductId);
        }

        let findUnit: IUnits | string = '';
        let findDefaultUnit;
        if (typeof ProductDetails === 'object') {
          let defaultUnit = ProductDetails.productUnits.find((curr) => curr.isDefault == true);
          let currUnit = response.data.productData[currentSellOrderId][index].unitId;
          if (!currUnit) {
            currUnit = ProductDetails.productUnits[0].unitId;
          }
          if (!defaultUnit) {
            defaultUnit = ProductDetails.productUnits[0];
          }
          findUnit = await UnitsDB.getUnit(currUnit);
          findDefaultUnit = await UnitsDB.getUnit(defaultUnit.unitId);
          if (!findUnit || !findDefaultUnit) {
            const allUnits = await get_units_list();
            await UnitsDB.addUnits(allUnits);
            findUnit = await UnitsDB.getUnit(currUnit);
            findDefaultUnit = await UnitsDB.getUnit(defaultUnit.unitId);
          }
        }

        if (!productss[currproductId]) {
          if (typeof findUnit === 'object' && typeof findDefaultUnit === 'object') {
            if (findUnit?.id === findDefaultUnit?.id) {
              productss[currproductId] = {
                productName: typeof ProductDetails === 'object' ? ProductDetails?.name : '',
                shortName: findDefaultUnit?.shortName,
                totalUnit: response.data.productData[currentSellOrderId][index].quantity,
                totalAmount: response.data.productData[currentSellOrderId][index].totalAmount,
                customers: [
                  {
                    id: customerDetails.id,
                    name: customerDetails.user.name,
                    quantity: response.data.productData[currentSellOrderId][index].quantity,
                    unit: findUnit?.shortName,
                    totalAmount: response.data.productData[currentSellOrderId][index].totalAmount,
                    routeName: routeInfo ? routeInfo.name : ''
                  }
                ]
              };
            } else {
              const BaseMultiplyer = findUnit.baseUnitMultiplier;
              const totalAfterConvert =
                response.data.productData[currentSellOrderId][index].quantity * BaseMultiplyer;
              productss[currproductId] = {
                productName: typeof ProductDetails === 'object' ? ProductDetails?.name : '',
                shortName: findDefaultUnit?.shortName,
                totalUnit: totalAfterConvert,
                totalAmount: response.data.productData[currentSellOrderId][index].totalAmount,
                customers: [
                  {
                    id: customerDetails.id,
                    name: customerDetails.user.name,
                    quantity: response.data.productData[currentSellOrderId][index].quantity,
                    unit: findUnit?.shortName,
                    totalAmount: response.data.productData[currentSellOrderId][index].totalAmount,
                    routeName: routeInfo ? routeInfo.name : ''
                  }
                ]
              };
            }
          }
        } else {
          // const newCustomers: any = productss[currproductId].customers.map((curr: any) => {
          //   if (curr.id == currentCustomerId) {
          //     return {
          //       ...curr,
          //       quantity:
          //         curr.quantity + response.data.productData[currentSellOrderId][index].quantity,
          //       totalAmount:
          //         curr.totalAmount +
          //         response.data.productData[currentSellOrderId][index].totalAmount
          //     };
          //   } else {
          //     return curr;
          //   }
          // });

          // if (typeof findUnit === 'object' && typeof findDefaultUnit === 'object') {
          //   if (findUnit?.id === findDefaultUnit?.id) {
          //     productss[currproductId] = {
          //       ...productss[currproductId],
          //       totalUnit:
          //         productss[currproductId].totalUnit +
          //         response.data.productData[currentSellOrderId][index].quantity,
          //       totalAmount:
          //         productss[currproductId].totalAmount +
          //         response.data.productData[currentSellOrderId][index].totalAmount,
          //       customers: newCustomers
          //     };
          //   } else {
          //     const BaseMultiplyer = findUnit.baseUnitMultiplier;
          //     const totalAfterConvert =
          //       response.data.productData[currentSellOrderId][index].quantity * BaseMultiplyer;
          //     productss[currproductId] = {
          //       ...productss[currproductId],
          //       totalUnit: productss[currproductId].totalUnit + totalAfterConvert,
          //       totalAmount:
          //         productss[currproductId].totalAmount +
          //         response.data.productData[currentSellOrderId][index].totalAmount,
          //       customers: newCustomers
          //     };
          //   }
          // }
          if (typeof findUnit === 'object' && typeof findDefaultUnit === 'object') {
            if (findUnit?.id === findDefaultUnit?.id) {
              productss[currproductId] = {
                ...productss[currproductId],
                totalUnit:
                  productss[currproductId].totalUnit +
                  response.data.productData[currentSellOrderId][index].quantity,
                totalAmount:
                  productss[currproductId].totalAmount +
                  response.data.productData[currentSellOrderId][index].totalAmount,
                customers: [
                  ...productss[currproductId].customers,
                  {
                    id: customerDetails.id,
                    name: customerDetails.user.name,
                    quantity: response.data.productData[currentSellOrderId][index].quantity,
                    unit: findUnit?.shortName,
                    totalAmount: response.data.productData[currentSellOrderId][index].totalAmount,
                    routeName: routeInfo ? routeInfo.name : ''
                  }
                ]
              };
            } else {
              const BaseMultiplyer = findUnit.baseUnitMultiplier;
              const totalAfterConvert =
                response.data.productData[currentSellOrderId][index].quantity * BaseMultiplyer;
              productss[currproductId] = {
                ...productss[currproductId],
                totalUnit: productss[currproductId].totalUnit + totalAfterConvert,
                totalAmount:
                  productss[currproductId].totalAmount +
                  response.data.productData[currentSellOrderId][index].totalAmount,
                customers: [
                  ...productss[currproductId].customers,
                  {
                    id: customerDetails.id,
                    name: customerDetails.user.name,
                    quantity: response.data.productData[currentSellOrderId][index].quantity,
                    unit: findUnit?.shortName,
                    totalAmount: response.data.productData[currentSellOrderId][index].totalAmount,
                    routeName: routeInfo ? routeInfo.name : ''
                  }
                ]
              };
            }
          }
        }
      }
    }
  }

  // Get Category Name
  if (categoryId) {
    const categoryDetails = await get_category_details(categoryId);
    categoryName = categoryDetails.name;
  }

  for (const key in productss) {
    // console.log(key);
    products.push({
      ...productss[key]
    });
  }

  for (let i = 0; i < products.length; i++) {
    products[i].customers.sort((a: any, b: any) => {
      const a1 = a.routeName.toLowerCase();
      const b1 = b.routeName.toLowerCase();
      if (a1 < b1) {
        return -1;
      }
      if (a1 > b1) {
        return 1;
      }
      return 0;
    });
  }

  return { products: products, categoryName: categoryName } as IProductInvoicePrint;
};

const fillCustomersDB = async (data: ISellOrderResponse[]) => {
  const customerSearch = new Set<number>();
  for (let ind = 0; ind < data.length; ind++) {
    const currentCustomerId = data[ind].customerId;
    if (!currentCustomerId) {
      continue;
    }
    const customerDetails = await CustomersDB.getCustomer(currentCustomerId);
    if (!customerDetails) {
      customerSearch.add(currentCustomerId);
    }
  }

  if (Array.from(customerSearch).length > 0) {
    const customerresponse = await get_customer_list_ids(Array.from(customerSearch));
    if (customerresponse?.data?.results) {
      CustomersDB.addCustomers(customerresponse.data.results);
    }
  }
};

const getRouteInfo = async (routeId: number) => {
  let routeInfo = await RoutesDB.getRoute(routeId);
  if (!routeInfo) {
    const routeresponse = await get_routes_details(routeId);
    if (routeresponse) {
      RoutesDB.addRoutes([routeresponse]);
      routeInfo = await RoutesDB.getRoute(routeId);
    }
  }

  return routeInfo as IRoutesList;
};
