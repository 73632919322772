import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';

interface CustomIconProps {
  onClick?: () => void;
}

const CustomDeleteIcon: React.FC<CustomIconProps> = ({ onClick }) => {
  return (
    <DeleteOutlined
      type="danger"
      onClick={onClick}
      style={{ transform: 'scale(1.4)', width: '105%' }}
    />
  );
};

export default CustomDeleteIcon;
