import { useEffect } from 'react';
import LocalStore from './../store/localstorage';
import { logout } from '../utils/auth.utils';
import { useNavigate } from 'react-router-dom';
import { checkAccess } from '@/routes/acl';

interface Props {
  children: JSX.Element;
}

function BroadcastDeleteProvider({ children }: Props) {
  const navigateTo = useNavigate();

  useEffect(() => {
    const handleSignOut = async (event: MessageEvent) => {
      if (event.data.action === 'signout') {
        if (!LocalStore.db) return;
        LocalStore.db.close();
        await logout();
        navigateTo('/login');
      }

      if (event.data.action === 'signin') {
        if (checkAccess('CREATE_POS') && !checkAccess('B2B')) {
          navigateTo('/sell/pos-create-v2');
          return;
        }

        navigateTo('/sell');
      }
    };

    // Listen for deletion messages from other tabs
    const channel = new BroadcastChannel('auth-channel');
    channel.addEventListener('message', handleSignOut);

    return () => {
      // Remove the event listener when the component unmounts
      channel.removeEventListener('message', handleSignOut);
      channel.close();
    };
  }, []);
  return children;
}

export default BroadcastDeleteProvider;
