import React, { useContext, useState } from 'react';
import { SellOrderListContext } from './context';
import { message } from 'antd';
import { IProductInvoicePrint } from '../../../../services/invoice/types';
import { CustomModal } from '../../../../components/Common/CustomModal';
import ProductRoutePrint from '../../list/ProductRoutePrint';
import { convertLocalToUTCString } from '../../../../utils/convertToUTC';
import { ConvertObjectToURL } from '../../../../utils/converturl';
import CustomButton from '../../../../components/Common/CustomButton/CustomButton';
import ProductPrint from '../../../../components/Common/InvoicePrint/ProductPrint/ProductPrint';
import { getSellOrderProductPrintData } from '../../../../components/Common/InvoicePrint/ProductPrint/services';

export const ProductPrintModal: React.FC<{
  getInfo: (urlData: string) => void;
}> = ({ getInfo }) => {
  const { setIsLoading, form, urlData } = useContext(SellOrderListContext);
  const [productData, setProductData] = useState<IProductInvoicePrint>(Object);
  const [openModalForProductPrint, setOpenModalForProductPrint] = useState<boolean>(false);

  const generateProductForPrint = async () => {
    setIsLoading(true);
    await form.validateFields();
    try {
      const startDate = form.getFieldValue(['startDate']);
      const endDate = form.getFieldValue(['endDate']);
      const routeId = form.getFieldValue(['routeId']);
      const locationId = form.getFieldValue(['locationId']);
      const categoryId = form.getFieldValue(['categoryId']);
      const productModalData = await getSellOrderProductPrintData(
        startDate,
        endDate,
        locationId,
        routeId,
        categoryId
      );
      setIsLoading(false);

      setProductData({ ...productModalData });
      setOpenModalForProductPrint(true);
    } catch (err: any) {
      setIsLoading(false);
      message.error(err.message);
    }
  };
  const handleProductPrintModalClose = () => {
    setOpenModalForProductPrint(false);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };
  return (
    <div>
      <CustomButton
        onClick={() => {
          generateProductForPrint();
        }}
        text="Product Print"
        backgroundColor="#1890ff"
      />
      <CustomModal
        footer={false}
        isModalOpen={openModalForProductPrint}
        setIsModalOpen={setOpenModalForProductPrint}
        title="Product Print">
        <ProductPrint
          products={productData.products}
          categoryName={productData.categoryName}
          handleModalClose={handleProductPrintModalClose}
        />
      </CustomModal>
    </div>
  );
};

export default ProductRoutePrint;
