import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  IExpense,
  IExpenseCreateFormData,
  IExpenseListRevamp,
  IExpensePaymentResult
} from './types';
import { IAddPayment } from '../sell/types';

export const create_expense_mutation = async (values: IExpenseCreateFormData) => {
  const response: AxiosResponse<IExpenseListRevamp, any> = await http.store('expense', values);
  return response;
};

export const update_expense_repetition_mutation = async (id: number) => {
  const response: AxiosResponse<IExpense, any> = await http.update('expense/close-recurring/' + id);
  return response;
};

export const create_expense_payment_mutation = async (values: IAddPayment) => {
  const response: AxiosResponse<IExpensePaymentResult, any> = await http.store(
    'expense/payment/' + values.id,
    values
  );
  return response;
};
