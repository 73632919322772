import { Calculator } from 'react-mac-calculator';
type Props = {
  handleInput?: (input: any) => void;
  onResultChange?: (newResult: any) => void;
};

const ResuableCalculator = ({
  handleInput = (input: any) => {
    console.log(`${input.expression} is shown in the calculator, User clicked the ${input.key}`);
  },
  onResultChange = (newResult: any) => {
    console.log(newResult);
    console.log(`${newResult.expression} is validated as ${newResult.result} `);
  }
}: Props) => {
  return <Calculator />;
};

export default ResuableCalculator;
