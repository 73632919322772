import { AxiosResponse } from 'axios';

import http from '@/utils/http.utils';
import { IFinancialYear, IFinancialYearCreate } from './types';

export const get_financial_years = async () => {
  const response: AxiosResponse<IFinancialYear[]> = await http.get('account-financial-year');
  return response.data;
};

export const get_financial_year_by_id = async (id: number) => {
  const response: AxiosResponse<IFinancialYear> = await http.get('account-financial-year/' + id);
  return response.data;
};

export const get_current_financial_year = async () => {
  const response: AxiosResponse<IFinancialYear> = await http.get('account-financial-year/current');
  return response.data;
};

export const create_financial_year = async (data: IFinancialYearCreate) => {
  const response: AxiosResponse<IFinancialYear> = await http.store('account-financial-year', data);
  return response.data;
};

export const update_financial_year = async (id: number, data: Partial<IFinancialYearCreate>) => {
  const response: AxiosResponse<IFinancialYear> = await http.update(
    'account-financial-year/' + id,
    data
  );
  return response.data;
};
