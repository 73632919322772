import {
  IAddPayment,
  ICreateSell,
  ICreateSellAndPayment,
  ICreateSellOrder,
  IEditSellOrder,
  ISellFromServer,
  ISellOrderResponse,
  ISellReturnCreate,
  ISellReturnCreateResponse
} from './types';
import http from '../../utils/http.utils';
import { AxiosResponse } from 'axios';
import { IPayment } from '../payments/types';

export const create_sell_mutation = async (values: ICreateSell) => {
  const response: AxiosResponse<ISellFromServer, any> = await http.store('sell', values);
  return response;
};

export const create_sell_payment_mutation = async (values: ICreateSellAndPayment) => {
  const response: AxiosResponse<{ sell: ISellFromServer; payment: IPayment }> = await http.store(
    'sell/payment-and-sale-combination',
    values
  );
  return response.data;
};

export const create_sell_order_mutation = async (values: ICreateSellOrder) => {
  const response: AxiosResponse<ISellOrderResponse, any> = await http.store('sell-order', values);
  return response;
};
export const edit_sell_order_mutation = async (values: IEditSellOrder) => {
  const response: AxiosResponse<ISellOrderResponse, any> = await http.update(
    'sell-order/' + values.id,
    values
  );
  return response;
};
// export const update_procurement_mutation = async (values: any) => {
//   const response = await http.update('products/' + values.id, values);
//   return response;
// };

export const create_sell_payment = async (values: IAddPayment) => {
  const id = values.id;
  delete values.id;
  const response: AxiosResponse<IPayment, any> = await http.store(`sell/payment/${id}`, values);
  return response;
};
export const create_sell_return_mutation = async (values: ISellReturnCreate) => {
  const response: AxiosResponse<ISellReturnCreateResponse, any> = await http.store(
    'sell-return',
    values
  );
  return response;
};
export const create_sell_crate_receive_mutation = async (values: {
  sellId: string | number;
  crates: number;
}) => {
  const response: AxiosResponse<ISellFromServer, any> = await http.store(
    `sell/receive/${values.sellId}/${values.crates}`
  );
  return response;
};

export const create_sell_crate_send_mutation = async (values: {
  sellId: string | number;
  crates: number;
}) => {
  const response: AxiosResponse<ISellFromServer, any> = await http.store(
    `sell/send/${values.sellId}/${values.crates}`
  );
  return response;
};

export const update_sell_print_count_mutation = async (sellId: string | number) => {
  const response: AxiosResponse<ISellFromServer, any> = await http.update(
    `/sell/print-count/${sellId}`
  );
  return response;
};

export const update_sell_return_print_count_mutation = async (sellId: string | number) => {
  const response: AxiosResponse<ISellReturnCreateResponse, any> = await http.update(
    `/sell-return/print-count/${sellId}`
  );
  return response;
};
