import moment from 'moment';

export const ProfitCalculationDatePresets: Record<string, [any, any]> = {
  Today: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days').subtract(1, 'seconds')],
  Yesterday: [moment(0, 'HH').subtract(1, 'days'), moment(0, 'HH').subtract(1, 'seconds')],
  'Last 7 Days': [
    moment(0, 'HH').subtract(6, 'days'),
    moment(0, 'HH').add(1, 'days').subtract(1, 'seconds')
  ],
  'Last 14 Days': [
    moment(0, 'HH').subtract(13, 'days'),
    moment(0, 'HH').add(1, 'days').subtract(1, 'seconds')
  ],
  'Last 30 Days': [
    moment(0, 'HH').subtract(29, 'days'),
    moment(0, 'HH').add(1, 'days').subtract(1, 'seconds')
  ],
  'This Month': [
    moment(0, 'HH').startOf('month'),
    moment(0, 'HH').add(1, 'days').subtract(1, 'seconds')
  ],
  'Last Month': [
    moment(0, 'HH').subtract(1, 'months').startOf('month'),
    moment(0, 'HH').subtract(1, 'months').endOf('month')
  ],
  'This month last year': [
    moment(0, 'HH').subtract(1, 'years').startOf('month'),
    moment(0, 'HH').subtract(1, 'years').endOf('month')
  ],
  'This Year': [
    moment(0, 'HH').startOf('year'),
    moment(0, 'HH').add(1, 'days').subtract(1, 'seconds')
  ],
  'Last Year': [
    moment(0, 'HH').subtract(1, 'years').startOf('year'),
    moment(0, 'HH').subtract(1, 'years').endOf('year')
  ],
  'Current financial year':
    moment().month() > 6
      ? [
          moment(0, 'HH').startOf('year').add(6, 'months'),
          moment(0, 'HH').add(1, 'year').startOf('year').add(5, 'months').endOf('month')
        ]
      : [
          moment(0, 'HH').subtract(1, 'years').startOf('year').add(6, 'months'),
          moment(0, 'HH').startOf('year').add(5, 'months').endOf('month')
        ],
  'Last financial year':
    moment().month() > 6
      ? [
          moment(0, 'HH').subtract(1, 'years').startOf('year').add(6, 'months'),
          moment(0, 'HH').startOf('year').add(5, 'months').endOf('month')
        ]
      : [
          moment(0, 'HH').subtract(2, 'years').startOf('year').add(6, 'months'),
          moment(0, 'HH').subtract(1, 'years').startOf('year').add(5, 'months').endOf('month')
        ]
};
