import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { get_wallet_payments_details } from '../../../services/users/queries';
import AppContent from '../../../components/Common/Content/Content';

const WalletPaymentDetails = () => {
  const { id } = useParams();
  const [paymentDetails, setPaymentDetails] = useState<any>();
  const [customerDetails, setCustomerDetails] = useState<any>();

  useQuery(['walletPaymentsDetails', id], async () => {
    const paymentDetails = await get_wallet_payments_details(id!);
    console.log('PaymentDetails ', paymentDetails);
    // if (paymentDetails.data.customer_id) {
    // }
    setPaymentDetails(paymentDetails.data);
  });

  const breadcrumbItems = [
    {
      label: 'Wallet Payments Details',
      link: `/wallet-payments/${id}`
    }
  ];

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        This is from wallet payment details.
      </AppContent>
    </div>
  );
};

export default WalletPaymentDetails;
