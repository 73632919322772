import moment from 'moment';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { message, PageHeader, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_details } from '@/services/users/queries';
import { ICustomerDiscount } from '@/services/offfers/types';
import AppContent from '@/components/Common/Content/Content';
import { get_customer_discount_details } from '@/services/offfers/queries';
import CustomButton from '@/components/Common/CustomButton/CustomButton';

function ViewCustomerDiscount() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [discountDetail, setDiscountDetail] = useState<ICustomerDiscount>(Object);

  if (!id) {
    return <h1>Customer Discount not found</h1>;
  }

  const breadcrumbItems = [
    { label: 'Customer Discount', link: '/offer/customer-discount/' },
    { label: 'Details', link: `/offer/customer-discount/view/${id}` }
  ];

  const { isLoading } = useQuery(
    ['customer-discount', id],
    () => get_customer_discount_details(parseInt(id)),
    {
      onError() {
        message.error('Failed to get discount details');
      },
      async onSuccess(data) {
        let user = await UsersDB.getUser(data.createdBy);
        if (!user) {
          user = (await get_user_details(data.createdBy)).user;
          await UsersDB.addUsers([user]);
        }

        setDiscountDetail({ ...data, createdUser: user?.name });
      }
    }
  );

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader title="Discount Information" style={{ padding: '8px 0px' }} />
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div>
            <PageHeader subTitle="Name" style={{ padding: '8px 0px' }} />
            <div>{discountDetail.name}</div>
          </div>

          <div>
            <PageHeader subTitle="Discount" style={{ padding: '8px 0px' }} />
            <div>Minimum: Rs {discountDetail.min}</div>
            <div>Maximum: Rs {discountDetail.max}</div>
            <div>Discount: {discountDetail.discount}%</div>
          </div>

          <div>
            <PageHeader subTitle="Created Information" style={{ padding: '8px 0px' }} />
            <div>By: {discountDetail.createdUser}</div>
            <div>At: {moment(discountDetail.createdAt).format('YYYY-MM-DD')}</div>
          </div>
        </div>

        <div className="flex justify-end mt-4 ">
          <div className="flex items-center">
            <CustomButton
              text="Update"
              Linkto={`/offer/customer-discount/${id}`}
              backgroundColor="blue"
            />
            <CustomButton text="Back" Linkto={'/offer/customer-discount'} textColor="black" />
          </div>
        </div>
      </AppContent>
    </Spin>
  );
}

export default ViewCustomerDiscount;
