import axios from 'axios';

import { API_URL, FRONT_VERSION } from '../constants/config';
import { globalSignOut } from './auth.utils';
import { getTokenFromStorage } from './storage.utils';

export const httpBase = (isFile = false) => {
  const token = getTokenFromStorage();
  const normalHeaders = {
    Accept: 'application/json',
    'front-version': FRONT_VERSION,
    'Content-Type': isFile ? 'multipart/form-data' : 'application/json'
  };

  const headers = token ? { ...normalHeaders, Authorization: `Bearer ${token}` } : normalHeaders;

  const api = axios.create({
    baseURL: `${API_URL}/`,
    headers: headers,
    responseType: 'json'
  });

  // handle interceptors for request. not needed right away.
  // api.interceptors.request.use(
  //   (config) =>
  //     // Do something before request is sent
  //     config,
  //   function (error) {
  //     // Do something with request error
  //     return Promise.reject(error);
  //   }
  // );

  api.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const code = error.code;
      const status = error.response.status;
      const data = error.response.data;

      if (code === 'ERR_NETWORK') {
        // have a toast here
      }

      if (status && status === 401) {
        globalSignOut();
        // have a toast here
      } else if (status && status === 404) {
        // have a toast here
      } else if (status && status === 500) {
        // have a toast here
      } else if (status && data?.message?.length > 0) {
        // have a toast here
      } else {
        // do nothing
      }
      return Promise.reject(error);
    }
  );

  return api;
};
