import moment from 'moment';
import AppContent from '@/components/Common/Content/Content';
import { getUser } from '@/services';
import { get_outlet_pricing_by_date, get_price_analysis_id } from '@/services/products/queries';
import { useQuery } from '@tanstack/react-query';
import { Descriptions, message, PageHeader, Spin } from 'antd';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { IRow } from '../grid.types';
import { formatPriceAnalysistoGridRow } from '../services';
import UpdatePriceAnalysisGrid from './Grid';
import { IOutletPricingByDate } from '@/services/products/types';

function UpdatePriceAnalysis() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [rows, setRows] = useState<IRow[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [yesterdayPriceAnalysis, setYesterdayPriceAnalysis] = useState<IOutletPricingByDate>();

  if (!id) {
    return <Navigate to="/price-analysis" replace />;
  }

  const breadcrumbs = [{ label: 'Price Analysis', link: '/price-analysis' }, { label: 'Update' }];

  async function getInfo() {
    const data = await get_price_analysis_id(Number(id));
    const { createdBy, date } = data.analysis;

    const isAllowedToEdit = moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
    if (!isAllowedToEdit) {
      message.error('You can only edit price analysis for today');
      navigate('/price-analysis');
      return;
    }

    if (createdBy) {
      const userDetails = await getUser(createdBy);
      data.analysis.createdUser = userDetails?.name;
    }

    const prevDate = moment(date).subtract(1, 'days').format('YYYY-MM-DD');
    const yesterdayPriceAnalysis = await get_outlet_pricing_by_date(prevDate);
    setYesterdayPriceAnalysis(yesterdayPriceAnalysis);

    const updatedRows = await formatPriceAnalysistoGridRow(data);
    setRows(updatedRows);
    return data.analysis;
  }

  const { data, isLoading, isFetching } = useQuery(['priceAnalysis', id], getInfo);

  return (
    <Spin spinning={isLoading || isFetching || isUpdating}>
      <AppContent breadcrumbItems={breadcrumbs}>
        <Descriptions
          bordered
          column={{
            md: 2,
            sm: 1,
            xs: 1
          }}>
          <Descriptions.Item label="Date">{data?.date}</Descriptions.Item>
          <Descriptions.Item label="Created By">{data?.createdUser}</Descriptions.Item>
        </Descriptions>

        <div className="mt-2">
          <PageHeader subTitle="Product Price Analysis" style={{ padding: '8px 0' }} />
          <UpdatePriceAnalysisGrid
            date={data?.date}
            rows={rows}
            id={Number(id)}
            setRows={setRows}
            setIsLoading={setIsUpdating}
            priceAnalysis={yesterdayPriceAnalysis}
          />
        </div>
      </AppContent>
    </Spin>
  );
}

export default UpdatePriceAnalysis;
