import moment from 'moment';
import { get_price_analysis_list } from '@/services/products/queries';
import { IPriceAnalysisResponse } from '@/services/products/types';
import { Form } from 'antd';
import { useState } from 'react';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { ConvertObjectToURL } from '@/utils/converturl';
import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import Table from './Table';
import { get_create_price_analysis_perms } from '@/routes/acl';
import { getUser } from '@/services';

function PriceAnalysisList() {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState<IPriceAnalysisResponse>({ total: 0, results: [] });

  const create_perms = get_create_price_analysis_perms();

  async function onSubmitFilter(filter: string) {
    await getInfo(filter);
    setPagination({ page: 1, size: 100 });
  }

  async function getInfo(filter = '') {
    try {
      setIsLoading(true);
      const response = await get_price_analysis_list(filter);

      // GET USER AND AGENT DETAILS
      for (const item of response.results) {
        const { createdBy } = item;
        if (createdBy) {
          const createdUser = await getUser(createdBy);
          item.createdUser = createdUser?.name;
        }
      }

      setData(response);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = 100, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  return (
    <AppContent
      breadcrumbItems={[{ label: 'Price Analysis' }]}
      withfilter
      button={
        <TableFilter
          initial
          form={form}
          onSubmit={onSubmitFilter}
          defaultValues={{
            dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
            createdBy: '',
            skip: 0,
            count: 100
          }}
          styleforbuttons={'flex justify-end items-center'}
          style={'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-6 gap-2'}
          buttons={
            <>
              {create_perms && create_perms !== 'outlet' && (
                <CustomButton
                  text="Add"
                  backgroundColor="#1890ff"
                  Linkto="/price-analysis/create"
                />
              )}
            </>
          }>
          <UserSearchV2 hasParentFormItem={false} name={['createdBy']} showAll />
        </TableFilter>
      }>
      <Table
        data={data}
        isLoading={isLoading}
        pagination={pagination}
        onPagination={onPagination}
      />
    </AppContent>
  );
}

export default PriceAnalysisList;
