import { IJournalListResponseData } from '@/services/accounts/types';
import { referenceToTypeMap, typeAPIs, typeToReferenceMap } from '../constant.ledger';
import { AccountRulesEvent, redirectData } from '@/services/accounts/enums';
import { get_journal_details } from '@/services/accounts/queries';
import { get_payment_details } from '@/services/payments/queries';
import { PaymentAgainst } from '@/services/payments/enums';
import { get_sell_return_details_by_id } from '@/services/sell/queries';
import { get_purchase_reutrn_details_by_id } from '@/services/purchases/queries';

export async function getReferenceTypeDetails(journals: IJournalListResponseData[]) {
  // Initialize an object to store reference IDs by type. default empty array
  const referenceIds = Object.fromEntries(
    Object.values(typeToReferenceMap).map((type) => [type, [] as number[]])
  );

  if (journals.length === 0) return journals;

  // Group reference IDs by type
  for (const journal of journals) {
    // Get type of journal eg: sell create, expense create, etc
    const journalType = journal.type as keyof typeof AccountRulesEvent;
    const referenceType = typeToReferenceMap[journalType];

    // Save the referenceId to referenceIds object for each journal type
    if (referenceType) {
      referenceIds[referenceType].push(journal.referenceId);
    }
  }

  // Get API function for each reference type and call it with the reference IDs
  // key: referenceType, value: referenceIds
  const apiPromises = Object.entries(referenceIds)
    .map(async ([type, referenceIds]) => {
      // GET API Function to call for each type
      const referenceType = type as keyof typeof typeAPIs;
      const getAPI = typeAPIs[referenceType];

      // If there are reference Ids, make an api call
      if (referenceIds.length > 0) {
        const data = await getAPI(referenceIds);

        return { type: referenceType, data };
      }

      // Else return null and filter it later
      return null;
    })
    .filter((apiPromise) => apiPromise !== null);

  // Run API calls in parallel
  const referenceResponses = await Promise.all(apiPromises);

  // Append Ref Number to journals based on financial reference or reference number
  for (const response of referenceResponses) {
    if (!response || !response.data) continue;
    const apiResponse = response.data;

    // If not data, continue for next responses
    const isDataPresent = 'results' in apiResponse && apiResponse.results.length > 0;
    if (!isDataPresent) continue;

    const allTypeForReference = referenceToTypeMap[response.type];
    const apiResponseResults = apiResponse.results as any[];

    const keyForReference = ['paymentReferenceIds'].includes(response.type)
      ? 'reference'
      : ['adjustmentReferenceIds', 'expenseReferenceIds'].includes(response.type)
      ? 'referenceNumber'
      : ['transferFromReferenceIds', 'transferToReferenceIds'].includes(response.type)
      ? 'referenceNo'
      : 'financialReference';

    for (const journal of journals) {
      const result = apiResponseResults.find(
        (res: any) =>
          res.id === journal.referenceId && allTypeForReference.includes(journal.type as any)
      );

      if (result && keyForReference in result) {
        journal.refNumber = result[keyForReference];
      }
    }
  }

  return journals;
}

export function createEmptyPromise<T>(obj: T): Promise<T> {
  return new Promise((resolve) => {
    resolve(obj);
  });
}

export const fetchReferenceAndRedirect = async (journalId: number) => {
  const response = await get_journal_details(journalId);
  const redirectObj = redirectData.find((val) => val.key === response.type);
  const hasRedirect = redirectObj && response;
  if (!hasRedirect) return null;

  switch (response.type) {
    case AccountRulesEvent.SELL_PAYMENT_CASH:
    case AccountRulesEvent.SELL_PAYMENT_BANK:
    case AccountRulesEvent.SELL_PAYMENT_OTHER: {
      const paymentDetails = await get_payment_details(response.referenceId);
      if (paymentDetails.against === PaymentAgainst.Sell)
        response.redirectId = paymentDetails.againstId;
      break;
    }
    case AccountRulesEvent.SELL_RETURN: {
      const returnDetails = await get_sell_return_details_by_id(response.referenceId);
      response.redirectId = returnDetails.sellId;
      break;
    }
    case AccountRulesEvent.PURCHASE_PAYMENT_CASH:
    case AccountRulesEvent.PURCHASE_PAYMENT_USER:
    case AccountRulesEvent.PURCHASE_PAYMENT_OTHER: {
      const paymentDetails = await get_payment_details(response.referenceId);
      if (paymentDetails.against === PaymentAgainst.Purchase)
        response.redirectId = paymentDetails.againstId;
      break;
    }
    case AccountRulesEvent.PURCHASE_RETURN: {
      const returnDetails = await get_purchase_reutrn_details_by_id(response.referenceId);
      response.redirectId = returnDetails.purchaseId;
      break;
    }
    case AccountRulesEvent.TRANSFER_TO: {
      response.redirectId = `${response.referenceId}?type=in` as any;
      break;
    }
    default: {
      response.redirectId = response.referenceId;
    }
  }

  return { link: redirectObj.link, redirectId: response.redirectId };
};
