import { IJournalListResponseData, IJournalViewResponseTableData } from '@/services/accounts/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { convert_string_to_nepali_date_string } from '@/utils/nepaliDateConverter';
import { useEffect, useState } from 'react';
import CustomTable from './CustomTable';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

interface Props {
  showDescription?: boolean;
  hideDate?: boolean;
  journalData?: IJournalListResponseData;
  modalData: IJournalViewResponseTableData[];
}

function JournalPrintFormat({
  journalData,
  modalData,
  hideDate = false,
  showDescription = false
}: Props) {
  const [total, setTotal] = useState({ totalCredit: 0, totalDebit: 0 });

  useEffect(() => {
    if (!journalData) return;

    setTotal({
      totalCredit: journalData?.credit,
      totalDebit: journalData?.debit
    });
  }, [journalData]);

  const columnsforPrint: any = [
    {
      dataIndex: 'accountName',
      label: 'Account',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      dataIndex: 'description',
      label: 'Description',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      dataIndex: 'debit',
      label: 'Debit',
      render: (text: number) => {
        return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
      }
    },
    {
      dataIndex: 'credit',
      label: 'Credit',
      render: (text: number) => {
        return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
      }
    },

    {
      dataIndex: 'runningBalance',
      label: 'Running Balance',
      render: (text: number) => {
        return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
      }
    }
  ];

  return (
    <>
      <div className="flex justify-between items-center mx-2">
        <div>
          <div>
            <span>{'Voucher No.'}</span> :{' '}
            <span className="italic font-bold">{journalData?.referenceId}</span>
          </div>
          <div>
            <span>{'Cheque Ref.'}</span> :{' '}
            <span className="italic font-bold">{journalData?.chequeRef}</span>
          </div>
          <div>
            <span>{'Reference Number'}</span> :{' '}
            <span className="italic font-bold">{journalData?.referenceNumber}</span>
          </div>
          {showDescription && (
            <div>
              <span>{'Remarks'}</span> :{' '}
              <span className="italic font-bold">{journalData?.description}</span>
            </div>
          )}
        </div>
        {!hideDate && (
          <div>
            <div className="text-right">
              <span> Miti</span>:
              {journalData?.createdAt
                ? convert_string_to_nepali_date_string(journalData?.createdAt)
                : ''}
            </div>

            <div className="text-right">
              <span>Date</span>:
              {journalData?.createdAt
                ? convertUTCStringtoLocalString(journalData.createdAt, 'YYYY-MM-DD')
                : ''}
            </div>
          </div>
        )}
      </div>

      <div className="mt-3">
        <CustomTable columns={columnsforPrint} datas={modalData} total={total} />
      </div>
    </>
  );
}

export default JournalPrintFormat;
