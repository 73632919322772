import React from 'react';

interface IExportData {
  productId: number;
  productName: string;
  price: number;
  unit: string;
  category: string;
}

interface TableProps {
  isEven?: boolean;
  groupByCategories: { [key: string]: IExportData[] };
  maxPossibleRows: number;
}

function RenderTableV2({ groupByCategories, isEven = false, maxPossibleRows }: TableProps) {
  const isEvenString = isEven.toString();
  const isOddString = !isEven.toString();
  const categories = Object.keys(groupByCategories);
  const currentMaxRows = Object.values(groupByCategories).flat().length;

  const rowSpanIfNeeded = maxPossibleRows - currentMaxRows;
  const newGroupByCategories: TableProps['groupByCategories'] = {};

  for (let i = 0; i < Object.keys(groupByCategories).length; i++) {
    const category = categories[i];
    const currentData = groupByCategories[category];
    if (i === categories.length - 1) {
      const newData = new Array(rowSpanIfNeeded).fill({});
      currentData.push(...newData);
    }

    newGroupByCategories[category] = currentData;
  }

  return (
    <div>
      <table className="price-analysis-table">
        <thead>
          <tr>
            <th data-even={isOddString} className="category-header w-24">
              Category
            </th>
            <th>Product Name</th>
            <th data-even={isEvenString} className="w-40">
              Price
            </th>
          </tr>
        </thead>

        <tbody>
          {categories.map((category) => {
            const categoryData = newGroupByCategories[category];
            return categoryData.map((product, productIndex) => {
              const isProductEmpty = Object.keys(product).length === 0;

              return (
                <React.Fragment key={`${product.productId}-${productIndex}`}>
                  <tr>
                    {productIndex === 0 && (
                      <td data-even={isEvenString} rowSpan={categoryData.length}>
                        {product.category}
                      </td>
                    )}
                    <td>{product.productName}</td>
                    <td data-even={isEvenString}>
                      {isProductEmpty ? '' : `Rs ${product.price} per ${product.unit}`}
                    </td>
                  </tr>
                </React.Fragment>
              );
            });
          })}
        </tbody>
      </table>
    </div>
  );
}

export default RenderTableV2;
