import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

function DifferenceIndicator({ value }: { value: number }) {
  const isPositive = value > 0;
  const color = value > 0 ? 'green' : value < 0 ? 'red' : 'black';
  const Icon = isPositive ? ArrowUpOutlined : ArrowDownOutlined;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {value !== 0 && <Icon style={{ color }} className="[&>svg]:m-0" />}
      <Typography.Text style={{ color, marginLeft: 5 }}>
        {nepaliNumberFormatter(Math.abs(value))}
      </Typography.Text>
    </div>
  );
}

export default DifferenceIndicator;
