import CustomSearch, { SelectProps } from '..';
import { get_expense_category_list_v2 } from '@/services/expense/expense-category/queries';
import { IExpenseCategory } from '@/services/expense/expense-category/types';
import ExpenseCategoryDB from '@/store/localstorage/ExpenseCategoryDB';

type MultipleProps =
  | {
      defaultValue?: number;
      isMultiple?: false;
      setSelected?: (selected?: IExpenseCategory) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      defaultValue?: number[];
      isMultiple: true;
      setSelected?: (selected?: IExpenseCategory[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps & MultipleProps;

function ExpenseCategorySearchV2(props: Props) {
  async function get_expense_category_only(
    skip?: number,
    count?: number,
    value?: string,
    filter?: string
  ) {
    const response = await get_expense_category_list_v2(skip, count, value, filter);
    return response.data.results;
  }

  return (
    <CustomSearch
      path={{ name: 'name', value: 'id' }}
      formFor="expense category"
      addCallback={ExpenseCategoryDB.add}
      dbSearchById={ExpenseCategoryDB.get}
      serverCallback={get_expense_category_only}
      dbSearchCallback={ExpenseCategoryDB.search}
      {...props}
    />
  );
}

export default ExpenseCategorySearchV2;
