import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  CustomerVendorByUser,
  ICustomer,
  IcustomerData,
  ICustomerDetails,
  ICustomerLedgerResponse,
  IUserData,
  IUserDetails,
  IUserPanData,
  IVendor,
  IVendorData,
  IVendorDetails,
  IVendorLedgerResponse,
  IWalletPaymentsListData,
  IWalletPaymentsListResponse,
  User
} from './types';
import { getDataForIds, getDataOnlyForIds } from '@/utils/queries.utils';

export const get_user_list = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IUserData, any> = await http.get('users/?' + filter, {
    skip,
    count,
    value
  });
  return response;
};

export const get_user_list_only = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IUserData, any> = await http.get('users/?' + filter, {
    skip,
    count,
    value
  });
  return response.data.results;
};

export const get_user_list_accounts = async (skip = 0, count = 0, value = '', isAll?: string) => {
  const response: AxiosResponse<IUserData, any> = await http.get('users', {
    skip,
    count,
    value,
    isAll
  });
  return response;
};

export const get_user_list_filter = async (filter = '') => {
  const response: AxiosResponse<IUserData, any> = await http.get('users/?' + filter, {
    skip: 0,
    count: 0
  });
  return response;
};

export const get_users_list_v2 = async (url: string) => {
  const response: AxiosResponse<IUserData, any> = await http.get('users/?' + url);
  return response;
};

export const get_users_list_with_acl = async (url: string) => {
  const response: AxiosResponse<IUserData, any> = await http.get('users/with-acl?' + url);
  return response;
};

export const get_user_details = async (id: number | string) => {
  const response: AxiosResponse<IUserDetails, any> = await http.get('users/' + id);
  return response.data;
};
export const get_user_avatar = async (id: number | null) => {
  if (id) {
    const response = await http.get('storage/media/' + id);
    return response.data;
  }
  return null;
};

export const delete_user = async (id: number) => {
  const response = await http.remove('users/' + id);
  return response;
};

export const get_vendor_list = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IVendorData, any> = await http.get('users/vendors/?' + filter, {
    skip,
    count,
    value
  });
  return response;
};

export const get_vendor_list_only = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IVendorData, any> = await http.get('users/vendors/?' + filter, {
    skip,
    count,
    value
  });
  return response.data.results;
};

export const get_vendor_list_for_filter = async (filter = '') => {
  const response: AxiosResponse<IVendorData, any> = await http.get('users/vendors/?' + filter, {
    skip: 0,
    count: 0
  });
  return response;
};
export const get_vendor_list_v2 = async (url: string) => {
  const response: AxiosResponse<IVendorData, any> = await http.get('users/vendors/?' + url);
  return response;
};
export const get_vendor_details = async (id: number | undefined) => {
  const response: AxiosResponse<IVendorDetails, any> = await http.get('users/vendors/' + id);
  return response.data;
};
export const get_customer_list = async (skip = 0, count = 100000, value = '', filter = '') => {
  const response: AxiosResponse<IcustomerData, any> = await http.get('users/customers/?' + filter, {
    skip,
    count,
    value
  });
  return response;
};

export const get_customer_list_only = async (skip = 0, count = 100000, value = '', filter = '') => {
  const response: AxiosResponse<IcustomerData, any> = await http.get('users/customers/?' + filter, {
    skip,
    count,
    value
  });
  return response.data.results;
};

export const get_customer_list_route = async (
  skip = 0,
  count = 100000,
  value = '',
  routeId: string | number,
  filter = ''
) => {
  const response: AxiosResponse<IcustomerData, any> = await http.get('users/customers/?' + filter, {
    skip,
    count,
    value,
    routeId
  });
  return response;
};

export const get_user_list_ids = async (ids: string[] | number[], isAll?: string) => {
  const parsedIds = ids.map(Number);
  const response = await getDataForIds<User>(parsedIds, 'users/?', { isAll });
  return { data: response };
};

export const get_customer_list_ids = async (ids: string[] | number[]) => {
  const parsedIds = ids.map(Number);
  const response = await getDataForIds<ICustomer>(parsedIds, 'users/customers/?');
  return { data: response };
};

export const get_vendor_list_ids = async (ids: string[] | number[]) => {
  const parsedIds = ids.map(Number);
  const response = await getDataForIds<IVendor>(parsedIds, 'users/vendors/?');
  return { data: response };
};

export const get_user_pan_ids = async (ids: string[] | number[]) => {
  const parsedIds = ids.map(Number);
  const data = await getDataOnlyForIds<IUserPanData>(parsedIds, 'users/tax?');
  return { data };
};

export const get_customer_list_for_filter = async (filter = '') => {
  const response: AxiosResponse<IcustomerData, any> = await http.get('users/customers/?' + filter, {
    skip: 0,
    count: 0
  });
  return response;
};
export const get_customers_list_v2 = async (url: string) => {
  const response: AxiosResponse<IcustomerData, any> = await http.get('users/customers/?' + url);
  return response;
};
export const get_customer_details = async (id: number | undefined) => {
  const response: AxiosResponse<ICustomerDetails, any> = await http.get('users/customers/' + id);
  return response.data;
};
export const get_user_routes_details = async (id: number) => {
  const response = await http.get('users/address/route/' + id);
  return response.data;
};

export const get_user_ledger = async (filter?: string) => {
  const response: AxiosResponse<ICustomerLedgerResponse, any> = await http.get(`data/20?${filter}`);
  return response.data;
};

export const get_vendor_ledger = async (filter?: string) => {
  const response: AxiosResponse<IVendorLedgerResponse, any> = await http.get(`data/40?${filter}`);
  return response.data;
};

export const get_wallet_payments = async (filter = '') => {
  const response: AxiosResponse<IWalletPaymentsListResponse, any> = await http.get(
    'wallet?' + filter
  );
  return response;
};

export const get_wallet_payments_details = async (id: string | number) => {
  const response: AxiosResponse<IWalletPaymentsListData, any> = await http.get('wallet/' + id);
  return response;
};

export const get_customer_vendor_by_userId = async (id: number) => {
  const response: AxiosResponse<CustomerVendorByUser, any> = await http.get(
    'users/vendor-customer/' + id
  );
  return response.data;
};

export const toggle_user_status = async (id: number, status: 'enable' | 'disable' = 'disable') => {
  const url = `users/${status}/${id}`;
  const response = await http.update(url);
  return response.data;
};
