/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  InputNumber,
  Menu,
  message,
  Modal,
  PageHeader,
  Select,
  Spin,
  Table
} from 'antd';
import AppContent from '../../../components/Common/Content';
import useDebounce from '../../../hooks/useDebounce';
import {
  get_product_list,
  get_product_list_ids,
  get_units_list
} from '../../../services/products/queries';
import TableFilter from '../../../components/FliterTable';
import { get_location_list } from '../../../services/locations/queries';
import moment from 'moment';
import {
  get_latest_week_sales,
  get_lots_by_group_of_product_id,
  get_purchase_order_form_filter_v2
} from '../../../services/purchases/queries';
import { useMutation } from '@tanstack/react-query';
import { create_purchase_form_order } from '../../../services/purchases/mutations';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import { ExportColumnType, exportExcel } from '../../../utils/exportExcel';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import { LocationSearch } from '../../../components/Common/LocationSearch/LocationSearch';
import { getUserData } from '../../../utils/auth.utils';
import ProductSearchForEstimateStock from '../../../components/Common/ProductSearch2/ProductSearchforestimate';
import { numberDecimalFormatter } from '../../../utils/numberFormatter';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import CustomErrorModal from '../../../components/Common/CustomErrorModal';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

const { Option } = Select;

const PurchaseOrderFormCreate: React.FC = () => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [data, setData] = useState<any>();
  const [loading, setIsloading] = useState<boolean>(true);
  const [searchValueLoc, setSearchValueLoc] = useState<string>('');
  const [selectedLocation, setselectedLocation] = useState<number>();
  const debouncedSearchValueLocation = useDebounce(searchValueLoc, 500);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<any>({
    productName: '',
    lotInfo: []
  });
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    searchLocation(debouncedSearchValue);
  }, [debouncedSearchValueLocation]);
  const [locationSearch, setLocationSearch] = useState<any>([]);

  const [searchValue, setSearchValue] = useState<string>('');
  const [productSearch, setProductSearch] = useState<any[]>([]);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchProduct(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const searchProduct = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_product_list(0, 50, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any product with that name!');
        setProductSearch([]);
      } else {
        setProductSearch(response.data.results);
      }
    }
    // setSearchLoading(false);
  };
  const searchLocation = async (value: any) => {
    let response;
    try {
      response = await get_location_list(0, 5, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any location with that name!');
      setLocationSearch([]);
    } else {
      setLocationSearch(response.data.results);
      if (!selectedLocation) setselectedLocation(response.data.results[0].id);
    }
  };
  const options = productSearch
    .filter((curr: any) => !curr.hidden)
    .map((d) => (
      <Option key={d.id} value={d.id}>
        {d.name}
      </Option>
    ));
  const options2 = locationSearch.map((value: any) => (
    <Select.Option key={value.id} value={value.id}>
      {value.name}
    </Select.Option>
  ));
  const onSearch = async (val: string) => {
    const uniqueproducts = new Set<number>();
    setIsloading(true);
    val +=
      // '&page=1&size=1000&identifier=SALE&startDate=' +
      '&page=1&size=1000&identifier=PURCHASE&startDate=' +
      moment().subtract(7, 'days').utc().format() +
      '&endDate=' +
      moment().utc().format();

    const todaysPurchaseOrder = await get_purchase_order_form_filter_v2({
      locationId: form2.getFieldValue('locationId'),
      startDate: moment(form2.getFieldValue(['date']))
        .utc()
        .format(),
      endDate: moment(form2.getFieldValue(['date']))
        .add(1, 'days')
        .utc()
        .format()
    });
    // console.log('todaysPurchaseOrder', todaysPurchaseOrder.data.data.todaysOrders);
    // let productsArray:any[] = new Set<number>0;
    // console.log('Response sold', responseSold.data);

    const locationDCDatas: any = {};

    for (let ind = 0; ind < todaysPurchaseOrder.data.locationDC.length; ind++) {
      const { productId, qty } = todaysPurchaseOrder.data.locationDC[ind];
      if (!locationDCDatas[productId]) {
        locationDCDatas[productId] = qty;
      } else {
        locationDCDatas[productId] += qty;
      }
    }
    if (todaysPurchaseOrder.data.data.length > 0) {
      const nproductSet = new Set<number>();
      for (let ind = 0; ind < todaysPurchaseOrder.data.data.length; ind++) {
        nproductSet.add(todaysPurchaseOrder.data.data[ind].productId);
        uniqueproducts.add(todaysPurchaseOrder.data.data[ind].productId);
      }
      let lotsandproductId: any;
      if (nproductSet.size > 0) {
        lotsandproductId = await get_lots_by_group_of_product_id(
          Array.from(nproductSet),
          form2.getFieldValue(['locationId'])
        );
      }

      const productSearch: { [key: number]: any[] } = {};

      for (let ind = 0; ind < todaysPurchaseOrder.data.data.length; ind++) {
        let totalLots = 0;
        const productInfo: any = await ProductsDB.getProduct(
          todaysPurchaseOrder.data.data[ind].productId
        );
        if (!productInfo) {
          if (productSearch[todaysPurchaseOrder.data.data[ind].productId]) {
            productSearch[todaysPurchaseOrder.data.data[ind].productId] = [
              ...productSearch[todaysPurchaseOrder.data.data[ind].productId],
              ind
            ];
          } else {
            productSearch[todaysPurchaseOrder.data.data[ind].productId] = [ind];
          }
          // productInfo = await get_product_details(responseSold.data[i].id);
          // ProductsDB.addProducts([productInfo]);
        } else {
          const findUnit = productInfo.productUnits.find((curr: any) => curr.isDefault == true);
          // console.log('Find Unit->', findUnit);
          let unitInfo: any = await UnitsDB.getUnit(findUnit.unitId);
          // console.log('unit id', findUnit.unitId);
          if (!unitInfo) {
            const allUnits = await get_units_list();
            await UnitsDB.addUnits(allUnits);
            unitInfo = await UnitsDB.getUnit(findUnit.unitId);
          }
          if (!lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].done) {
            for (
              let index = 0;
              index <
              lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].lots.length;
              index++
            ) {
              totalLots +=
                lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].lots[index]
                  .qtyAvailable;
              // lotInfo[ind].qtySold=`${lotInfo[ind].qtySold/(unitInfo.baseUnitMultiplier)} ${unitInfo.shortName}`
              lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].lots[
                index
              ].qtyAvailableWithUnit = `${
                lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].lots[index]
                  .qtyAvailable / unitInfo.baseUnitMultiplier
              } ${unitInfo.shortName}`;
              // lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].done =
              //   true;
            }
            lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].totallotsInfo = `${
              totalLots / unitInfo.baseUnitMultiplier
            }`;
            lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].done = true;
          }
          todaysPurchaseOrder.data.data[ind].baseUnitMultiplier = unitInfo.baseUnitMultiplier;
          todaysPurchaseOrder.data.data[ind].name = productInfo.name;
          todaysPurchaseOrder.data.data[ind].unitName = unitInfo.shortName;
          todaysPurchaseOrder.data.data[ind].lotInfo =
            lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].lots;
          todaysPurchaseOrder.data.data[ind].totalLots =
            lotsandproductId.data[todaysPurchaseOrder.data.data[ind].productId].totallotsInfo;
        }
        // responseSold.data[i].lotInfo = lotsandproductId.data[responseSold.data[i].id].lots;
        // todaysPurchaseOrder.data.data[ind].productId.productId =  todaysPurchaseOrder.data.data[ind].productId;
        todaysPurchaseOrder.data.data[ind].sold = 0;
        todaysPurchaseOrder.data.data[ind].id = todaysPurchaseOrder.data.data[ind].productId;
        todaysPurchaseOrder.data.data[ind].locationDCData = locationDCDatas[
          todaysPurchaseOrder.data.data[ind].productId
        ]
          ? locationDCDatas[todaysPurchaseOrder.data.data[ind].productId]
          : 0;
        // todaysPurchaseOrder.data.data[ind].productId.qty = 0;
      }

      const productSearchlength = Object.entries(productSearch).length;
      if (productSearchlength > 0) {
        const allproducts = await get_product_list_ids([...Object.keys(productSearch)]);
        for (const key in productSearch) {
          const findproduct = allproducts?.data?.results.find(
            (currProduct: any) => currProduct.id == key
          );
          if (findproduct) {
            let findUnit = findproduct.productUnits.find((curr: any) => curr.isDefault === true);
            if (!findUnit) findUnit = findproduct.productUnits[0];
            // console.log('Find Unit->', findUnit);
            let unitInfo: any = await UnitsDB.getUnit(findUnit.unitId);
            // console.log('unit id', findUnit.unitId);
            if (!unitInfo) {
              const allUnits = await get_units_list();
              await UnitsDB.addUnits(allUnits);
              unitInfo = await UnitsDB.getUnit(findUnit.unitId);
            }
            let totalLots = 0;
            for (let ind = 0; ind < lotsandproductId.data[key].lots.length; ind++) {
              totalLots += lotsandproductId.data[key].lots[ind].qtyAvailable;
              lotsandproductId.data[key].lots[ind].qtyAvailableWithUnit = `${
                lotsandproductId.data[key].lots[ind].qtyAvailable / unitInfo.baseUnitMultiplier
              } ${unitInfo.shortName}`;
            }
            // console.log('Lots-->', lotsandproductId.data[key].lots);
            for (let i = 0; i < productSearch[key].length; i++) {
              // for (let ind = 0; ind < lotsandproductId.data[key]; ind++) {
              //   // lotInfo[ind].qtySold=`${lotInfo[ind].qtySold/(unitInfo.baseUnitMultiplier)} ${unitInfo.shortName}`
              //   responseSold.data[productSearch[key][i]].lotInfo[ind].qtyAvailable = `${
              //     responseSold.data[productSearch[key][i]].lotInfo[ind].qtyAvailable /
              //     unitInfo.baseUnitMultiplier
              //   } ${unitInfo.shortName}`;
              // }
              todaysPurchaseOrder.data.data[productSearch[key][i]].baseUnitMultiplier =
                unitInfo.baseUnitMultiplier;
              todaysPurchaseOrder.data.data[productSearch[key][i]].name = findproduct.name;
              todaysPurchaseOrder.data.data[productSearch[key][i]].unitName = unitInfo.shortName;
              todaysPurchaseOrder.data.data[productSearch[key][i]].lotInfo =
                lotsandproductId.data[key].lots;
              todaysPurchaseOrder.data.data[productSearch[key][i]].totalLots = `${
                totalLots / unitInfo.baseUnitMultiplier
              }`;
            }
            await ProductsDB.addProducts([findproduct]);
          }
        }
      }
      // console.log('lines', todaysPurchaseOrder.data.data);
      todaysPurchaseOrder.data.data = mergeSameIdArray(todaysPurchaseOrder.data.data);
      await fetchLastWeekSoldData(Array.from(uniqueproducts), todaysPurchaseOrder.data.data);
      form.setFieldValue('lines', todaysPurchaseOrder.data.data);
      // form.setFieldValue(['lines'], response.lines);
      setIsloading(false);
      return;
    }
    const responseSold: any = (await get_latest_week_sales([], form2.getFieldValue(['date']))).data;

    // console.log('Response sold', responseSold);
    const productSet = new Set<number>();
    for (let i = 0; i < responseSold.length; i++) {
      productSet.add(responseSold[i].productId);
      uniqueproducts.add(responseSold[i].productId);
    }
    let lotsandproductId: any;
    if (Array.from(productSet).length > 0) {
      lotsandproductId = await get_lots_by_group_of_product_id(
        Array.from(productSet),
        form2.getFieldValue(['locationId'])
      );
    }
    // console.log('products', productSet);
    // console.log('lotsandproductId from group of products-->', lotsandproductId);
    const productSearch: any = {};
    const productsmap: any = {};
    for (let i = 0; i < responseSold.length; i++) {
      if (!productsmap[responseSold[i].productId]) {
        let totalLots = 0;
        productsmap[responseSold[i].productId] = {
          productId: responseSold[i].productId,
          id: responseSold[i].productId,
          qty: 0,
          locationDCData: locationDCDatas[responseSold[i].productId]
            ? locationDCDatas[responseSold[i].productId]
            : 0
        };
        // const lotInfo: any = (await get_lot_by_location_product(val, responseSold.data[i].id)).data;
        const productInfo: any = await ProductsDB.getProduct(responseSold[i].productId);
        if (!productInfo) {
          if (productSearch[responseSold[i].productId]) {
            productSearch[responseSold[i].productId] = [
              ...productSearch[responseSold[i].productId],
              i
            ];
          } else {
            productSearch[responseSold[i].productId] = [i];
          }
          // productInfo = await get_product_details(responseSold.data[i].id);
          // ProductsDB.addProducts([productInfo]);
        } else {
          const findUnit = productInfo.productUnits.find((curr: any) => curr.isDefault == true);
          // console.log('Find Unit->', findUnit);
          // console.log('Find productInfo->', productInfo);
          if (!findUnit) {
            CustomErrorModal({
              title: 'Error',
              message: `Default unit not selected for ${productInfo.name}`
            });
            setIsloading(false);
            return;
          }
          let unitInfo: any = await UnitsDB.getUnit(findUnit.unitId);
          // console.log('unit id', findUnit.unitId);
          if (!unitInfo) {
            const allUnits = await get_units_list();
            await UnitsDB.addUnits(allUnits);
            unitInfo = await UnitsDB.getUnit(findUnit.unitId);
          }
          if (!lotsandproductId.data[responseSold[i].productId].done) {
            for (
              let ind = 0;
              ind < lotsandproductId.data[responseSold[i].productId].lots.length;
              ind++
            ) {
              totalLots += lotsandproductId.data[responseSold[i].productId].lots[ind].qtyAvailable;
              // lotInfo[ind].qtySold=`${lotInfo[ind].qtySold/(unitInfo.baseUnitMultiplier)} ${unitInfo.shortName}`
              lotsandproductId.data[responseSold[i].productId].lots[ind].qtyAvailableWithUnit = `${
                lotsandproductId.data[responseSold[i].productId].lots[ind].qtyAvailable /
                unitInfo.baseUnitMultiplier
              } ${unitInfo.shortName}`;
              // lotsandproductId.data[responseSold.data[i].id].lots[ind].done = true;
            }
            lotsandproductId.data[responseSold[i].productId].done = true;
            lotsandproductId.data[responseSold[i].productId].totalLotsinfo =
              totalLots / unitInfo.baseUnitMultiplier;
          }

          productsmap[responseSold[i].productId] = {
            ...productsmap[responseSold[i].productId],
            name: productInfo.name,
            unitName: unitInfo.shortName,
            baseUnitMultiplier: unitInfo.baseUnitMultiplier,
            hidden: productInfo.hidden,
            lotInfo: lotsandproductId.data[responseSold[i].productId].lots,
            totalLots: lotsandproductId.data[responseSold[i].productId].totalLotsinfo
          };
        }
        // responseSold.data[i].lotInfo = lotsandproductId.data[responseSold.data[i].id].lots;
        // responseSold.data[i].productId = responseSold.data[i].id;
        // responseSold.data[i].sold = responseSold.data[i].totalQuantity;
        // responseSold.data[i].qty = 0;
      }
    }

    const productSearchlength = Object.entries(productSearch).length;
    if (productSearchlength > 0) {
      const allproducts = await get_product_list_ids([...Object.keys(productSearch)]);
      for (const key in productSearch) {
        const findproduct = allproducts?.data?.results.find(
          (currProduct: any) => currProduct.id == key
        );
        let findUnit = findproduct?.productUnits.find((curr: any) => curr.isDefault === true);
        if (!findUnit) findUnit = findproduct?.productUnits[0];
        // console.log('Find Unit->', findUnit);
        let unitInfo: any;
        if (findUnit) unitInfo = await UnitsDB.getUnit(findUnit.unitId);
        // console.log('unit id', findUnit.unitId);
        if (!unitInfo && findUnit) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          unitInfo = await UnitsDB.getUnit(findUnit.unitId);
        }
        let totalLots = 0;
        for (let ind = 0; ind < lotsandproductId.data[key].lots.length; ind++) {
          totalLots += lotsandproductId.data[key].lots[ind].qtyAvailable;
          lotsandproductId.data[key].lots[ind].qtyAvailable = `${
            lotsandproductId.data[key].lots[ind].qtyAvailable / unitInfo.baseUnitMultiplier
          } ${unitInfo.shortName}`;
        }
        productsmap[key] = {
          ...productsmap[key],
          name: findproduct?.name,
          hidden: findproduct?.hidden,
          baseUnitMultiplier: unitInfo.baseUnitMultiplier,
          unitName: unitInfo.shortName,
          lotInfo: lotsandproductId.data[key].lots,
          totalLots: totalLots / unitInfo.baseUnitMultiplier
        };
        await ProductsDB.addProducts([findproduct]);
      }
    }
    // responseSold.data = mergeSameIdArray(responseSold.data);
    // console.log('lines', responseSold.data);
    // console.log('obj-> ', productsmap);
    await fetchLastWeekSoldData([], [...Object.values(productsmap)], false, responseSold);
    // form.setFieldsValue({ lines: responseSold.data });
    // setData(responseSold.data);
    setIsloading(false);
  };
  const orderMutation = useMutation(create_purchase_form_order);
  const onFinish = async (values: any) => {
    setIsloading(true);
    // console.log('onfinish', values);
    const orders: any[] = [];
    data.map((value: any) => {
      if (value.qty > 0 && form2.getFieldValue(['locationId']))
        orders.push({
          // date: value.date,
          locationId: form2.getFieldValue(['locationId']),
          qty: value.qty,
          productId: value.id,
          type: 'PURCHASE'
        });
    });
    orderMutation.mutateAsync(
      { orders },
      {
        onSuccess: async (data: any) => {
          message.success('added');
          setIsloading(false);
        },
        onError: (e: any) => {
          message.error(`${e.response.data.message}`, 5);
          setIsloading(false);
        }
      }
    );
  };
  const mergeSameIdArray = (arr: any[]) => {
    const newArr: any[] = [];
    arr.map((value: any) => {
      const findIndex = newArr.findIndex((curr: any) => curr.id == value.id);
      if (findIndex == -1) {
        value.sold = parseFloat(value.sold);
        newArr.push(value);
      } else {
        newArr[findIndex].sold = parseFloat(value.sold) + parseFloat(newArr[findIndex].sold);
      }
    });
    return newArr;
  };

  const columns = [
    {
      title: 'S.N.',
      render: (a: any, b: any, c: any) => <div className="text-center">{c + 1}</div>,
      width: 3
    },
    {
      title: 'Product',
      dataIndex: 'name',
      width: 10
      // render: (text: any) => <div className="text-center">{text}</div>
    },
    {
      title: 'Unit',
      dataIndex: 'unitName',
      width: 3,
      render: (text: any) => <div className="text-center">{text}</div>
    },
    {
      title: 'Total Sold',
      dataIndex: 'sold',
      width: 4,
      className: 'highlight',
      render: (text: any) => <div className="text-center">{text}</div>
    },
    {
      title: `${moment(form2.getFieldValue(['date']))
        .subtract(6, 'days')
        .format('MM-DD')}`,
      dataIndex: moment(form2.getFieldValue(['date']))
        .subtract(6, 'days')
        .format('YYYY-MM-DD'),
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      title: `${moment(form2.getFieldValue(['date']))
        .subtract(5, 'days')
        .format('MM-DD')}`,
      dataIndex: moment(form2.getFieldValue(['date']))
        .subtract(5, 'days')
        .format('YYYY-MM-DD'),
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      title: `${moment(form2.getFieldValue(['date']))
        .subtract(4, 'days')
        .format('MM-DD')}`,
      dataIndex: moment(form2.getFieldValue(['date']))
        .subtract(4, 'days')
        .format('YYYY-MM-DD'),
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      title: `${moment(form2.getFieldValue(['date']))
        .subtract(3, 'days')
        .format('MM-DD')}`,
      dataIndex: moment(form2.getFieldValue(['date']))
        .subtract(3, 'days')
        .format('YYYY-MM-DD'),
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      title: `${moment(form2.getFieldValue(['date']))
        .subtract(2, 'days')
        .format('MM-DD')}`,
      dataIndex: moment(form2.getFieldValue(['date']))
        .subtract(2, 'days')
        .format('YYYY-MM-DD'),
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    {
      title: `${moment(form2.getFieldValue(['date']))
        .subtract(1, 'days')
        .format('MM-DD')}`,
      dataIndex: moment(form2.getFieldValue(['date']))
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      width: 4,
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    // {
    //   title: `${moment(form2.getFieldValue(['date'])).format('MM-DD')}`,
    //   dataIndex: moment(form2.getFieldValue(['date'])).format('YYYY-MM-DD'),
    //   width: 4,
    //   render: (text: any) => <div className="text-center">{text ? text : 0}</div>
    // },
    {
      title: `Quantity`,
      dataIndex: 'qty',
      width: 8,
      render: (value: number, row: any, index: number) => {
        // console.log(value);
        form.setFieldValue(['lines', index], row);
        return (
          // <Form.Item
          //   name={['lines', index, 'qty']}
          //   key={row.id}
          //   rules={[
          //     {
          //       required: false,
          //       message: 'Please add quantity!'
          //     }
          //   ]}>
          <div className="px-2 py-1">
            <InputNumber
              controls={false}
              disabled={
                form2.getFieldValue(['date']) == moment().format('YYYY-MM-DD') ? false : true
              }
              // max={value}
              value={row.qty}
              onChange={(valueInput: any) => {
                // console.log('value', value);
                setData((prev: any) => {
                  return prev.map((curr: any) => {
                    if (curr.productId == row.productId) {
                      return {
                        ...curr,
                        qty: valueInput
                      };
                    } else {
                      return curr;
                    }
                  });
                });
              }}
              min={0}
              autoFocus
            />
          </div>
          // </Form.Item>
        );
      }
    },
    {
      title: 'Total in Lots',
      width: 7,
      dataIndex: 'totalLots',
      render: (text: any) => <div className="text-center">{text}</div>
    },
    {
      title: 'Total From Others',
      width: 5,
      dataIndex: 'locationDCData',
      render: (text: any) => (
        <div className="text-center">{text ? numberDecimalFormatter(text) : 0}</div>
      )
    },
    // {
    //   title: 'Lots',
    //   width: 5,
    //   render: (value: number, row: any, index: number) => {
    //     return (
    //       <div className="flex justify-center items-center">
    //         <Button
    //           style={{ backgroundColor: '#343c4c', color: 'white' }}
    //           onClick={() => {
    //             // console.log('dd', row);
    //             setModalData({
    //               productName: row.name,
    //               lotInfo: row.lotInfo
    //             });
    //             showModal();
    //           }}>
    //           Show
    //         </Button>
    //       </div>
    //     );
    //   }
    // },
    // {
    //   title: 'Action',
    //   width: 3,
    //   dataIndex: 'action',
    //   render: (value: number, row: any, index: number) => {
    //     return (
    //       <div className="flex justify-center items-center">
    //         <Button
    //           style={{ color: 'white', backgroundColor: 'red' }}
    //           onClick={() => {
    //             // console.log('clicked');
    //             setData(data.filter((value: any, ind: number) => ind != index));
    //           }}
    //           disabled={
    //             form2.getFieldValue(['date']) == moment().format('YYYY-MM-DD') ? false : true
    //           }>
    //           Remove
    //         </Button>
    //       </div>
    //     );
    //   }
    // },
    {
      title: 'Action',
      width: 4,
      dataIndex: 'action',
      render: (value: number, row: any, index: number) => {
        const menuItems: any[] = [];
        if (form2.getFieldValue(['date']) == moment().format('YYYY-MM-DD')) {
          menuItems.push({
            key: '2',
            label: <div className="flex justify-center items-center">Remove</div>,
            onClick: () => {
              setData(data.filter((value: any, ind: number) => ind != index));
            }
          });
        }
        const menu = (
          <Menu
            items={[
              ...menuItems,
              {
                key: '3',
                label: <div className="flex justify-center items-center">All Lots</div>,
                onClick: () => {
                  setModalData({
                    productName: row.name,
                    lotInfo: row.lotInfo
                  });
                  showModal();
                }
              }
            ]}
          />
        );
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const columnss = [
    {
      title: 'S.N.',
      render: (a: any, b: any, c: any) => <div className="text-center">{c + 1}</div>,
      width: 3
    },
    {
      title: 'Lot Number',
      dataIndex: 'lotNumber',
      width: 10
    },
    {
      title: 'Qty Available',
      dataIndex: 'qtyAvailableWithUnit',
      width: 10
    }
  ];

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'S.N',
          dataIndex: 'SN',
          width: 50
        },
        {
          title: 'Product',
          dataIndex: 'name',
          width: 250
        },
        {
          title: 'Unit',
          width: 60,
          dataIndex: 'unitName'
        },
        {
          title: 'Total Sold',
          width: 150,
          dataIndex: 'sold'
        },
        {
          title: 'Total In Lots',
          width: 150,
          dataIndex: 'totalLots'
        },
        {
          title: 'Total From Others',
          width: 150,
          dataIndex: 'locationDCData'
        },
        {
          title: `${moment(form2.getFieldValue(['date']))
            .subtract(6, 'days')
            .format('MM-DD')}`,
          dataIndex: moment(form2.getFieldValue(['date']))
            .subtract(6, 'days')
            .format('YYYY-MM-DD'),
          width: 150
        },
        {
          title: `${moment(form2.getFieldValue(['date']))
            .subtract(5, 'days')
            .format('MM-DD')}`,
          dataIndex: moment(form2.getFieldValue(['date']))
            .subtract(5, 'days')
            .format('YYYY-MM-DD'),
          width: 150
        },
        {
          title: `${moment(form2.getFieldValue(['date']))
            .subtract(4, 'days')
            .format('MM-DD')}`,
          dataIndex: moment(form2.getFieldValue(['date']))
            .subtract(4, 'days')
            .format('YYYY-MM-DD'),
          width: 150
        },
        {
          title: `${moment(form2.getFieldValue(['date']))
            .subtract(3, 'days')
            .format('MM-DD')}`,
          dataIndex: moment(form2.getFieldValue(['date']))
            .subtract(3, 'days')
            .format('YYYY-MM-DD'),
          width: 150
        },
        {
          title: `${moment(form2.getFieldValue(['date']))
            .subtract(2, 'days')
            .format('MM-DD')}`,
          dataIndex: moment(form2.getFieldValue(['date']))
            .subtract(2, 'days')
            .format('YYYY-MM-DD'),
          width: 150
        },
        {
          title: `${moment(form2.getFieldValue(['date']))
            .subtract(1, 'days')
            .format('MM-DD')}`,
          dataIndex: moment(form2.getFieldValue(['date']))
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
          width: 150
        },
        {
          title: 'Quantity',
          width: 80,
          dataIndex: 'qty'
        }
      ];
      if (data?.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = data?.map((item: any, index: number) => {
        return {
          ...item,
          SN: index + 1
        };
      });
      exportExcel(columns, dataUpdated, 'Estimated Stock');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  const fetchLastWeekSoldData = async (
    arr: number[],
    datas: any,
    callapi = true,
    prevresponse?: any
  ) => {
    let response: any;
    if (callapi) {
      response = await get_latest_week_sales(arr, form2.getFieldValue(['date']));
    } else {
      response = { data: prevresponse };
    }
    const productanddate: any = {};
    // productanddate = {productId:{date1:quantity,date2:quantity2}}
    for (let ind = 0; ind < response?.data?.length; ind++) {
      // const date = convertLocalToUTCString(response.data[ind].createdAt, 'YYYY-MM-DD');
      const date = moment(response.data[ind].createdAt).local().format('YYYY-MM-DD');
      // console.log('date', date);
      const productId = response.data[ind].productId;
      if (productId in productanddate) {
        if (date in productanddate[productId]) {
          productanddate[productId] = {
            ...productanddate[productId],
            [date]: productanddate[productId][date] + response.data[ind].baseUnitQuantity
          };
        } else {
          productanddate[productId] = {
            ...productanddate[productId],
            [date]: response.data[ind].baseUnitQuantity
          };
        }
      } else {
        // console.log('this runs');
        productanddate[productId] = { [date]: response.data[ind].baseUnitQuantity };
      }
    }
    for (const key in productanddate) {
      for (let ind = 0; ind < datas.length; ind++) {
        if (datas[ind].productId == key) {
          let total = 0;
          for (const k in productanddate[key]) {
            productanddate[key][k] = productanddate[key][k] / datas[ind].baseUnitMultiplier;
            total += productanddate[key][k];
          }
          datas[ind] = {
            ...datas[ind],
            ...productanddate[key],
            sold: total
          };
          break;
        }
      }
    }
    setData(datas.filter((curr: any) => !curr.hidden));
  };

  return (
    <Spin spinning={loading}>
      <Modal
        title={`All Lots for product ${modalData.productName}`}
        visible={isModalOpen}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}>
        <Table
          bordered
          dataSource={modalData.lotInfo}
          columns={columnss}
          pagination={false}
          size="small"
        />
      </Modal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        button={
          <>
            <div>
              {locationSearch.length > 0 && (
                <TableFilter
                  defaultValues={{
                    // dateCustom: [moment(), moment().add(1, 'days')],
                    locationId: preferenceLocationId ? preferenceLocationId : locationSearch[0].id,
                    date: moment().format('YYYY-MM-DD')
                  }}
                  singleDate={true}
                  dateCustom={false}
                  initial={true}
                  // outsidestyle={'flex items-end gap-2'}
                  styleforbuttons={`flex items-center justify-end`}
                  style={
                    'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                  }
                  onSubmit={onSearch}
                  form={form2}>
                  <LocationSearchV2 hasParentFormItem={false} name="locationId" />
                </TableFilter>
              )}
            </div>
            {/* </div> */}
          </>
        }>
        <Form form={form} component={false}>
          <CustomizeTable
            columns={columns}
            data={data}
            notshowPagination
            customScroll={{ y: '75vh', x: 1400 }}
            buttons={
              <CustomButton text="Excel Export" backgroundColor="#1890ff" onClick={handleExport} />
            }
          />
        </Form>
        <PageHeader
          subTitle="Add Product"
          style={{
            padding: '8px 0px 8px 10px'
          }}
        />
        <div className="grid grid-cols-1">
          <ProductSearchForEstimateStock
            data={data}
            setData={setData}
            selectedLocation={selectedLocation}
            type="PURCHASE"
            disabled={form2.getFieldValue(['date']) == moment().format('YYYY-MM-DD') ? false : true}
            locationId={form.getFieldValue(['locationId'])}
          />
        </div>
        <div className="flex justify-end mt-5">
          <Form.Item>
            <Button
              type="primary"
              onClick={() => onFinish(form.getFieldsValue())}
              disabled={
                form2.getFieldValue(['date']) == moment().format('YYYY-MM-DD') ? false : true
              }>
              Submit
            </Button>
          </Form.Item>
        </div>
      </AppContent>
    </Spin>
  );
};

export default PurchaseOrderFormCreate;
const breadcrumbItems = [
  {
    label: 'Purchase',
    link: '/purchase'
  },
  {
    label: 'Purchase Demand'
  }
];
