import { IAttendaceCheckIn } from './types';
import http from '../../utils/http.utils';
import { AxiosResponse } from 'axios';

export const check_in_attendance = async (value: IAttendaceCheckIn) => {
  const response: AxiosResponse<IAttendaceCheckIn, any> = await http.store(
    '/attendance/checkin/',
    value
  );
  return response;
};
export const check_out_attendance = async () => {
  const response: AxiosResponse<IAttendaceCheckIn, any> = await http.store('/attendance/checkout/');
  return response;
};
