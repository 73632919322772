import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { get_expense_category_id } from '@/services/expense/expense-category/queries';
import { message } from 'antd';
import CategoryMutationUI from '../CategoryMutationUI';

function ExpenseCategoryUpdate() {
  const { id } = useParams();

  if (!id) {
    return <h1>Expense Category not found</h1>;
  }

  const breadcrumbItems = [
    { label: 'Expense Category', link: '/expense-category/' },
    { label: 'Details', link: `/expense-category/edit/${id}` }
  ];

  const { data, isLoading, isError } = useQuery(
    ['expense-category', id],
    async () => get_expense_category_id(parseInt(id)),
    {
      onError: (error) => {
        message.error('Failed to get expense category details');
      }
    }
  );

  if (isError) {
    return <h1>Failed to get expense category details</h1>;
  }

  return (
    <CategoryMutationUI
      data={data}
      type="update"
      isParentLoading={isLoading}
      breadcrumbItems={breadcrumbItems}
    />
  );
}

export default ExpenseCategoryUpdate;
