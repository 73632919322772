import AppContent from '@/components/Common/Content/Content';
import { get_purchase_agent_bill_details } from '@/services/purchases/queries';
import getError from '@/utils/getError';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Descriptions,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Spin,
  Upload,
  UploadFile
} from 'antd';
import { UploadProps } from 'antd/es/upload';
import { RcFile } from 'antd/lib/upload';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import UsersDB from '@/store/localstorage/UsersDB';
import { AgentsDB } from '@/store/localstorage/AgentsDB';
import { get_user_details } from '@/services/users/queries';
import { get_agent_by_id } from '@/services/agents/queries';
import { uploadImagesToServer } from '@/services/upload/queries';
import { IPurchaseAgentBillCancelPayload } from '@/services/purchases/types';
import { cancel_purchase_agent_bill } from '@/services/purchases/mutations';

interface FormValues {
  cancelNumber: number;
  reason: string;
  media: UploadFile[];
}

function PurchaseAgentBillCancel() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm<FormValues>();

  if (!id) {
    return <Navigate to="/purchases/agents-bill-range" replace />;
  }

  const breadcrumbs = [
    { label: 'Purchases', link: '/purchase' },
    { label: 'Agent Bill Range', link: '/purchases/agents-bill-range' },
    { label: 'Cancel', link: `/purchases/agents-bill-range/cancel/${id}` }
  ];

  async function getInfo() {
    setIsLoading(true);
    const data = await get_purchase_agent_bill_details(Number(id));
    const { createdBy, agentId } = data;
    if (createdBy) {
      let createdUser = await UsersDB.getUser(createdBy);
      if (!createdUser) {
        const response = await get_user_details(createdBy);
        await UsersDB.addUsers([response.user]);
        createdUser = await UsersDB.getUser(createdBy);
      }
      data.createdUser = createdUser?.name;
    }

    if (agentId) {
      let agent = await AgentsDB.getAgent(agentId);
      if (!agent) {
        const response = await get_agent_by_id(agentId);
        await AgentsDB.addAgents([response.data]);
        agent = await AgentsDB.getAgent(agentId);
      }

      data.agent = agent?.user?.name;
    }

    return data;
  }

  const { data } = useQuery(['purchase-agent-bill', id], getInfo, {
    onError: (error) => message.error(getError(error)),
    onSettled: () => setIsLoading(false)
  });

  const onPreviewImage = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleFileChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  async function handleSubmit() {
    try {
      setIsModalOpen(false);
      const values = await form.validateFields();

      setIsLoading(true);
      const payload: IPurchaseAgentBillCancelPayload = {
        cancelNumber: values.cancelNumber,
        reason: values.reason
      };

      const uploadImagesResponse = await uploadImagesToServer(values.media);

      if (uploadImagesResponse.length !== 0) {
        payload.mediaId = uploadImagesResponse[0]?.id;
      }

      await cancel_purchase_agent_bill(Number(id), payload);
      message.success('Purchase Agent Bill Cancelled Successfully');
      navigate('/purchases/agents-bill-range');
    } catch (error) {
      message.error(getError(error));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Modal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={handleSubmit}>
        Are you sure you want to cancel?
      </Modal>
      <AppContent breadcrumbItems={breadcrumbs}>
        <div className="mb-4">
          <PageHeader subTitle="Purchase Agent Bill Information" style={{ padding: '8px 0' }} />
          <Descriptions
            bordered
            column={{
              xxl: 4,
              xl: 3,
              lg: 3,
              md: 2,
              sm: 1,
              xs: 1
            }}>
            <Descriptions.Item label="Range">
              {data?.min} to {data?.max}
            </Descriptions.Item>
            <Descriptions.Item label="Current">{data?.current}</Descriptions.Item>
            <Descriptions.Item label="Agent">{data?.agent}</Descriptions.Item>
          </Descriptions>
        </div>

        <Form
          form={form}
          layout="vertical"
          onFinish={() => setIsModalOpen(true)}
          validateTrigger={'onChange'}
          autoComplete="off">
          <div>
            <Form.Item
              label="Cancel Number"
              name={'cancelNumber'}
              rules={[{ required: true, message: 'Please provide a cancel number!' }]}>
              <InputNumber
                controls={false}
                placeholder="Cancel Number"
                min={data?.min}
                max={data?.max}
              />
            </Form.Item>
          </div>

          <div className="sm:flex gap-4 mt-4">
            <Form.Item
              className="flex-1"
              label="Reason for Cancellation"
              name="reason"
              rules={[{ required: true, message: 'Please provide a reason for cancellation!' }]}>
              <Input.TextArea placeholder="Reason for cancellation" className="!h-[6.4rem]" />
            </Form.Item>

            <Form.Item
              name="media"
              label="Upload Media"
              valuePropName="fileList"
              getValueFromEvent={(e) => e.fileList}>
              <Upload
                accept="image/*"
                multiple={false}
                maxCount={1}
                listType="picture-card"
                onPreview={onPreviewImage}
                onChange={handleFileChange}
                beforeUpload={() => false}>
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>{fileList.length > 0 ? 'Change' : 'Upload'}</div>
                </div>
              </Upload>
            </Form.Item>
          </div>

          <div className="flex justify-end gap-2">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>

            <Button>Cancel</Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default PurchaseAgentBillCancel;
