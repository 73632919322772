export enum MenuTypes {
  'ITEM' = 'ITEM',
  'PARENT' = 'PARENT'
}

export enum ColorPaletteNodes {
  One = '#639edf',
  Two = '#b7bdc9',
  Three = '#ead5c6',
  Four = '#af9cb1',
  Five = '#866475'
}
