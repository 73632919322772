import { nepaliNumberFormatter } from '@/utils/numberFormatter';

export const tableExportColumns = [
  {
    label: 'Method',
    dataIndex: 'method',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Amount',
    dataIndex: 'amount',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Wallet',
    dataIndex: 'wallet',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Adjusted',
    dataIndex: 'adjusted',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      amount: nepaliNumberFormatter(record.amount),
      wallet: nepaliNumberFormatter(record.wallet),
      adjusted: nepaliNumberFormatter(record.adjusted)
    };
  }) as any;
}
