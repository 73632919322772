import React, { useEffect } from 'react';
import { convertUTCStringtoLocalString } from '../../../utils/convertToUTC';
import { convert_string_to_nepali_date_string } from '../../../utils/nepaliDateConverter';
import moment from 'moment';
import { nepaliNumberFormatter } from '../../../utils/numberFormatter';
import { find_invoice_number } from '../../../store/localstorage/preferences';

const BillPrintingInvoice = () => {
  const state = JSON.parse(localStorage.getItem('referrer') as string);
  const {
    customerDetails,
    vendorDetails,
    locationDetails,
    purchaseDetails,
    lines,
    invoiceLayouts,
    printCount,
    adjustmentDetails,
    transferData,
    createdByDetails,
    printLocation
  } = state;
  const headers = ['Sn', 'Particulars', 'Qty', 'Rate', 'Amount'];
  //console.log('lines', lines);
  const content = JSON.parse(invoiceLayouts.content);
  //   console.log('content', content);
  const getShortName = (name: string) => {
    const ind = name.indexOf('/');
    if (ind == -1) {
      return name;
    }
    return name.slice(0, ind);
  };
  console.log('content', content);

  // console.log('localdat', convertUTCStringtoLocalString(purchaseDetails.createdAt));
  useEffect(() => {
    if (purchaseDetails) {
      window.print();
      window.onafterprint = (event) => {
        window.close();
        localStorage.removeItem('referrer');
      };
    }
  });
  return (
    <div className="py-2" style={{ color: 'black' }}>
      <div className="text-center font-extrabold">{content?.headerText}</div>
      <div className="text-center font-bold">{printLocation?.name}</div>
      <div className="text-center font-bold">VAT NO : {find_invoice_number()}</div>
      <div className="text-center font-bold">
        {printCount > 0 ? 'Copy of Original ' + printCount : content?.invoiceHeading}
      </div>
      <div className="grid grid-cols-3 gap-x-2 mt-2 font-semibold text-sm">
        <span>
          {purchaseDetails?.financialReferenceLabel
            ? purchaseDetails.financialReferenceLabel
            : 'Invoice No.'}
        </span>
        <span className="col-span-2">: {purchaseDetails?.financialReference}</span>
        <span>Bill No.</span>
        <span className="col-span-2">: {purchaseDetails.referenceNumber}</span>
        <span>{content?.customerNameLabel ? content.customerNameLabel : 'Name'}</span>
        <span className="col-span-2">: {customerDetails.name}</span>
        <span>{content?.addressLabel ? content.addressLabel : 'Address'}</span>
        <span className="col-span-2">: </span>
        <span>{content?.dateLabel ? content.dateLabel : 'Date'}</span>
        <span className="col-span-2">
          : {convertUTCStringtoLocalString(purchaseDetails.createdAt)}
        </span>
        <span>{content?.nepaliDateLabel ? content.nepaliDateLabel : 'Miti'}</span>
        <span className="col-span-2">
          :{' '}
          {purchaseDetails?.createdAt
            ? convert_string_to_nepali_date_string(purchaseDetails.createdAt)
            : convert_string_to_nepali_date_string(moment().format())}
        </span>
        <span>{content?.panNumberLabel ? content.panNumberLabel : 'PAN No.'}</span>
        <span className="col-span-2">: {content?.panNumber ? content.panNumber : ''}</span>
        <span>{content?.paymentLabel}</span>
        <span className="col-span-2">: Cash</span>
      </div>

      <div className="p-2" style={{ color: 'black' }}>
        <div className="font-semibold text-sm">{content?.remarksLabel}: </div>
        <div className="flex justify-between border-dashed border-y-1 border-x-0 py-1 border-black mt-2">
          {[
            'Sn',
            content?.productLabel,
            content?.quantityLabel,
            content?.unitPriceLabel,
            content?.subTotalLabel
          ].map((curr: any, ind: number) => {
            return (
              <>
                <span className="font-semibold text-sm">{curr}</span>
                <span className="font-semibold text-sm">|</span>
              </>
            );
          })}
        </div>
        <tbody></tbody>
        {lines.map((curr: any, ind: number) => {
          return (
            <div key={ind} style={{ display: 'flex' }}>
              <span className="font-semibold text-sm" style={{ flex: 2 }}>
                {ind + 1}
              </span>
              <span className="font-semibold text-sm" style={{ flex: 6 }}>
                {getShortName(curr.productName)}
              </span>
              <span className="font-semibold text-sm" style={{ flex: 4, textAlign: 'center' }}>
                {curr.quantity}
              </span>
              <span className="font-semibold text-sm" style={{ flex: 4, textAlign: 'center' }}>
                {curr.unitPrice}
              </span>
              <span className="font-semibold text-sm" style={{ flex: 4, textAlign: 'center' }}>
                {curr.amount ? curr.amount : curr.totalAmount}
              </span>
              {console.log('curr', curr)}
            </div>
          );
        })}
        <div className="flex justify-end">
          <div
            style={{ width: '70%', textAlign: 'left' }}
            className="m-0 p-0 border-dashed border-t-1 border-x-0 border-b-0 border-black">
            <table className="table w-full">
              <tbody style={{ textAlign: 'right' }}>
                <tr>
                  <td className="font-semibold text-sm">
                    {content?.grossAmount ? content.grossAmount : 'Amount'}
                  </td>
                  <td className="font-semibold text-sm">:</td>
                  <td className="font-semibold text-sm text-right">
                    {nepaliNumberFormatter(purchaseDetails.totalAmount)}
                  </td>
                </tr>
                <tr>
                  <td className="font-semibold text-sm">
                    {content?.discountAmount ? content.discountAmount : 'Discount'}
                  </td>
                  <td className="font-semibold text-sm">:</td>
                  <td className="font-semibold text-sm text-right">
                    {purchaseDetails?.totalDiscount
                      ? nepaliNumberFormatter(purchaseDetails.totalDiscount)
                      : nepaliNumberFormatter(purchaseDetails.discount)}
                  </td>
                </tr>
                <tr>
                  <td className="font-semibold text-sm">Taxable</td>
                  <td className="font-semibold text-sm">:</td>
                  <td className="font-semibold text-sm text-right">
                    {purchaseDetails?.totalTaxable != undefined
                      ? nepaliNumberFormatter(purchaseDetails.totalTaxable)
                      : nepaliNumberFormatter(0)}
                  </td>
                </tr>
                <tr>
                  <td className="font-semibold text-sm">Non Taxable</td>
                  <td className="font-semibold text-sm">:</td>
                  <td className="font-semibold text-sm text-right">
                    {purchaseDetails?.totalNonTaxable != undefined
                      ? nepaliNumberFormatter(purchaseDetails.totalNonTaxable)
                      : nepaliNumberFormatter(0)}
                  </td>
                </tr>
                <tr>
                  <td className="font-semibold text-sm">VAT</td>
                  <td className="font-semibold text-sm">:</td>
                  <td className="font-semibold text-sm text-right">
                    {purchaseDetails?.totalVat != undefined
                      ? nepaliNumberFormatter(purchaseDetails.totalVat)
                      : nepaliNumberFormatter(0)}
                  </td>
                </tr>
                <tr className="border-dashed border-b-1 border-x-0 border-t-0 border-black">
                  <td className="font-semibold text-sm">
                    {content?.netAmountLabel ? content.netAmountLabel : 'Net Amount'}
                  </td>
                  <td className="font-semibold text-sm">:</td>
                  <td className="font-semibold text-sm text-right">
                    {purchaseDetails?.grandtotal != undefined
                      ? nepaliNumberFormatter(purchaseDetails.grandtotal)
                      : nepaliNumberFormatter(purchaseDetails.totalAmount)}
                  </td>
                </tr>
                {/*  <tr>
                  <td className="font-semibold text-sm">
                    {content?.tenderLabel ? content.tenderLabel : 'Tender'}
                  </td>
                  <td className="font-semibold text-sm">:</td>
                  <td className="font-semibold text-sm text-right">0.00</td>
                </tr> */}
                <tr>
                  <td className="font-semibold text-sm">
                    {content?.changeLabel ? content.changeLabel : 'Change'}
                  </td>
                  <td className="font-semibold text-sm">:</td>
                  <td className="font-semibold text-sm text-right">0.00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-end border-dashed border-y-1 border-x-0 py-1 border-black font-semibold text-sm">
          <div style={{ width: '50%' }}>
            {content?.totalQuantityLabel} :{purchaseDetails.totalQuantity}
          </div>
        </div>
        <div className="border-dashed border-b-1 border-t-0 border-x-0 border-black py-1 font-semibold text-sm">
          {content?.footerText}
        </div>
        {/* <div>Counter: Terminal B(12:25PM)</div> */}
        <div className="py-1 font-semibold text-sm">Cashier : {createdByDetails?.name}</div>
        {/* <button onClick={() => window.print()}>print</button> */}
      </div>
    </div>
  );
};

export default BillPrintingInvoice;
