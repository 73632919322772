import React, { useState, useEffect } from 'react';
import { SelectProps, Select, message, Form } from 'antd';
import useDebounce from '../../../hooks/useDebounce';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import { get_location_list } from '../../../services/locations/queries';
import { useQuery } from '@tanstack/react-query';
import { ILocations } from '../../../services/locations/types';
import { FormInstance } from 'antd/es/form/Form';

const { Option } = Select;
interface IProps {
  form: FormInstance<any>;
}

const MultiSelectLocations: React.FC = () => {
  const [locationList, setLocationList] = useState<ILocations[]>([]);
  const [fromLocations, setFromLocations] = useState<string[]>([]);
  const [toLocations, setToLocations] = useState<string[]>([]);

  const [options, setOptions] = useState<SelectProps['options']>([]);
  const [optionsto, setOptionsTo] = useState<SelectProps['options']>([]);
  const handleChange = (value: string[]) => {
    setFromLocations(value);
  };

  const handleChangeTo = (value: string[]) => {
    setToLocations(value);
  };

  useQuery(['locations'], async () => {
    const allLocations = await get_location_list();
    if (allLocations?.data?.results) {
      setLocationList(allLocations.data.results);
    }
  });

  useEffect(() => {
    const options: SelectProps['options'] = [];
    const filteredLocations: ILocations[] = locationList.filter((currLoc: ILocations) => {
      return !toLocations.includes(`${currLoc.id}`);
    });
    for (let i = 0; i < filteredLocations.length; i++) {
      options.push({
        label: filteredLocations[i].name,
        value: `${filteredLocations[i].id}`
      });
    }
    setOptions(options);
  }, [toLocations, locationList]);

  useEffect(() => {
    const optionsto: SelectProps['options'] = [];
    // console.log('From locations', fromLocations);
    console.log(
      'filter',
      locationList.filter((currLoc: ILocations) => {
        return !fromLocations.includes(`${currLoc.id}`);
      })
    );
    const filteredLocations: ILocations[] = locationList.filter((currLoc: ILocations) => {
      return !fromLocations.includes(`${currLoc.id}`);
    });
    for (let i = 0; i < filteredLocations.length; i++) {
      optionsto.push({
        label: filteredLocations[i].name,
        value: `${filteredLocations[i].id}`
      });
    }
    setOptionsTo(optionsto);
  }, [fromLocations, locationList]);

  //   console.log('namid', nameIdMap);

  return (
    <>
      <Form.Item name={['constraints', 'from']} label={'Locations(From)'}>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Select From Locations"
          onChange={handleChange}
          options={options}
          optionFilterProp="label"
          dropdownMatchSelectWidth={false}
        />
      </Form.Item>
      <Form.Item name={['constraints', 'to']} label={'Locations(to)'}>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Select To Locations"
          onChange={handleChangeTo}
          options={optionsto}
          optionFilterProp="label"
          dropdownMatchSelectWidth={false}
        />
      </Form.Item>
    </>
  );
};

export default MultiSelectLocations;
