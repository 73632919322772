import { Button, Divider, Form, PageHeader, Select, Spin, message } from 'antd';
import AppContent from '@/components/Common/Content/Content';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CustomModal } from '@/components/Common/CustomModal';
import useDebounce from '@/hooks/useDebounce';
import { IAccountTypeTableData, ITrialBalanceResponseData } from '@/services/accounts/types';
import { AccountType } from '@/services/accounts/enums';
import { getAccountsByParentIds, merge_accounts } from '@/services/accounts/mutations';
import AccountsDB from '@/store/localstorage/AccountsDB';
import { get_account_details } from '@/services/accounts/queries';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_details } from '@/services/users/queries';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_details } from '@/services/locations/queries';
import { useMutation } from '@tanstack/react-query';
import { ArrowRightOutlined } from '@ant-design/icons';
import getErrorMessage from '@/utils/getError';

const { Option } = Select;

export const MergeAccounts = () => {
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const navigate = useNavigate();
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [searchAccountList, setSearchAccountList] = useState<ITrialBalanceResponseData[]>([]);
  const [selectedAccountInfo, setSelectedAccountInfo] = useState<IAccountTypeTableData>();
  const [accountList, setAccountList] = useState<ITrialBalanceResponseData[]>([]);
  const { id } = useParams();
  const [mergingAccount, setMergingAccount] = useState<IAccountTypeTableData>(
    {} as IAccountTypeTableData
  );
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const breadcrumbItems = [
    {
      label: 'Accounts',
      link: '/accounts'
    },
    {
      label: 'Merge Accounts'
    }
  ];

  useEffect(() => {
    getInfo();
  }, [id]);

  useEffect(() => {
    searchAccountByName(searchValue.toLowerCase());
  }, [debouncedSearchValue]);

  const getInfo = async () => {
    setIsLoading(true);
    try {
      if (id) {
        const mergingAccount: IAccountTypeTableData = await get_account_details(parseInt(id));
        if (mergingAccount) {
          if (mergingAccount.createdBy) {
            let createdByDetails = await UsersDB.getUser(mergingAccount.createdBy);
            if (!createdByDetails) {
              const user = await get_user_details(mergingAccount.createdBy);
              await UsersDB.addUsers([user.user]);
              createdByDetails = await UsersDB.getUser(mergingAccount.createdBy);
            }
            mergingAccount.createdByName = createdByDetails.name;
          }

          if (mergingAccount.parentId) {
            const parentAccountDetails = await AccountsDB.getAccount(mergingAccount.parentId);

            if (typeof parentAccountDetails === 'object') {
              mergingAccount.parentName = parentAccountDetails.name;
            }
          }

          if (mergingAccount.locationId) {
            let locationDetails = await LocationsDB.getLocation(mergingAccount.locationId);
            if (!locationDetails) {
              const location = await get_location_details(mergingAccount.locationId);
              await LocationsDB.addLocations([location]);
              locationDetails = await LocationsDB.getLocation(mergingAccount.locationId);
            }
            mergingAccount.locationName = locationDetails.name;
          }
          setMergingAccount(mergingAccount);

          let response = await getAccountsByParentIds(0, 1, [mergingAccount.parentId]);
          if (response.results.length > 0) {
            if (response.total > 1) {
              response = await getAccountsByParentIds(0, response.total, [mergingAccount.parentId]);
            }
            response.results = response.results.filter((value) => value.id !== mergingAccount.id);
            setAccountList(response.results);
            setSearchAccountList(response.results);
            setIsLoading(false);
          }
        }
      }

      setIsLoading(false);
    } catch (e) {
      if (e instanceof Error) {
        console.log(e.message);
      }
      setIsLoading(false);
    }
  };

  const getSelectedInfo = async (id: number) => {
    setIsLoading(true);
    try {
      const mergedToAccount: IAccountTypeTableData = await get_account_details(id);
      if (mergedToAccount) {
        if (mergedToAccount.createdBy) {
          let createdByDetails = await UsersDB.getUser(mergedToAccount.createdBy);
          if (!createdByDetails) {
            const user = await get_user_details(mergedToAccount.createdBy);
            await UsersDB.addUsers([user.user]);
            createdByDetails = await UsersDB.getUser(mergedToAccount.createdBy);
          }
          mergedToAccount.createdByName = createdByDetails.name;
        }

        if (mergedToAccount.parentId) {
          const parentAccountDetails = await AccountsDB.getAccount(mergedToAccount.parentId);

          if (typeof parentAccountDetails === 'object') {
            mergedToAccount.parentName = parentAccountDetails.name;
          }
        }

        if (mergedToAccount.locationId) {
          let locationDetails = await LocationsDB.getLocation(mergedToAccount.locationId);
          if (!locationDetails) {
            const location = await get_location_details(mergedToAccount.locationId);
            await LocationsDB.addLocations([location]);
            locationDetails = await LocationsDB.getLocation(mergedToAccount.locationId);
          }
          mergedToAccount.locationName = locationDetails.name;
        }
        setSelectedAccountInfo(mergedToAccount);
      }

      setIsLoading(false);
    } catch (e) {
      if (e instanceof Error) {
        console.log(e.message);
      }
      setIsLoading(false);
    }
  };

  const onFinish = async () => {
    const mergeAccountId = form.getFieldValue('ParentId');
    const destinationDetails = accountList.find((value) => value.id === mergeAccountId);
    if (!destinationDetails) {
      throw new Error('Destination account not found');
    }

    if (mergingAccount.type === AccountType.USER && destinationDetails.type === AccountType.OTHER) {
      // eslint-disable-next-line quotes
      return message.error("Can't merge account of type USER to account of type OTHER");
    }

    setConfirmModal(true);
  };

  const handleMerge = async () => {
    setIsLoading(true);

    try {
      const mergeAccountId = form.getFieldValue('ParentId');
      const values = {
        moveAccountId: mergingAccount.id,
        destinationAccountId: mergeAccountId
      };

      await mergeAccounts.mutateAsync(values);
    } catch (e) {
      message.error(getErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const mergeAccounts = useMutation(merge_accounts, {
    onSuccess: () => {
      setIsLoading(false);
      message.success('Account merged successfully');
      navigate('/accounts');
    },
    onError: (e: any) => {
      setIsLoading(false);
      message.error(`${e.response.data.message}`);
    }
  });

  const searchAccountByName = async (name: string) => {
    try {
      if (searchAccountList.length > 0) {
        const searchData = searchAccountList.filter((data) =>
          data.name.toLowerCase().includes(name)
        );
        setAccountList(searchData);
      }
    } catch (e) {
      if (e instanceof Error) {
        console.log(e.message);
      }
    }
  };

  const options = accountList.map((value) => {
    return (
      <Option key={value.id} value={value.id}>
        {value.type === AccountType.OTHER ||
        !Object.values(AccountType).includes(value.type as AccountType)
          ? `${value.name}`
          : `${value.name} (${value.type})`}
      </Option>
    );
  });

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <div className="flex justify-end mt-5 h-full">
          <CustomModal
            title="Confirm Account Merge"
            isModalOpen={confirmModal}
            setIsModalOpen={setConfirmModal}
            width="35%"
            footer={false}>
            <div className="flex-col gap-2">
              <div className="flex gap-2 w-full items-center">
                <div className="text-red-600">{mergingAccount.name}</div>
                <ArrowRightOutlined />
                <div className="text-green-600">{selectedAccountInfo?.name}</div>
              </div>
              <div className="flex justify-end gap-2 mt-5">
                <Button
                  type="default"
                  onClick={() => {
                    setConfirmModal(false);
                  }}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setConfirmModal(false);
                    handleMerge();
                  }}>
                  Confirm
                </Button>
              </div>
            </div>
          </CustomModal>
        </div>
        <Form
          form={form}
          initialValues={{ ParentId: null }}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            subTitle="Merge Account:"
            style={{
              padding: '2px 0px'
            }}
          />
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div>Location: {mergingAccount.locationName}</div>
            <div>Type: {mergingAccount.type}</div>
            <div>Name: {mergingAccount.name}</div>
            <div>Parent Account: {mergingAccount.parentName}</div>
            <div>Balance: {nepaliNumberFormatter(mergingAccount.balance)}</div>
            <div>Created By: {mergingAccount.createdByName}</div>
          </div>
          <Divider />
          <PageHeader
            subTitle="To:"
            style={{
              padding: '2px 0px'
            }}
          />
          {selectedAccountInfo ? (
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div>Location: {selectedAccountInfo.locationName}</div>
              <div>Type: {selectedAccountInfo.type}</div>
              <div>Name: {selectedAccountInfo.name}</div>
              <div>Parent Account: {selectedAccountInfo.parentName}</div>
              <div>Balance: {nepaliNumberFormatter(selectedAccountInfo.balance)}</div>
              <div>Created By: {selectedAccountInfo.createdByName}</div>
            </div>
          ) : (
            <></>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-5">
            <div className="col-span-2">
              <Form.Item
                name={'ParentId'}
                rules={[
                  {
                    required: true,
                    message: 'Please add an account!'
                  }
                ]}>
                <Select
                  showSearch
                  allowClear
                  placeholder="Select an account"
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  onClear={() => {
                    setSelectedAccountInfo(undefined);
                  }}
                  onSelect={(val: number) => {
                    getSelectedInfo(val);
                  }}
                  autoClearSearchValue={true}
                  onSearch={(val) => {
                    setSearchValue(val);
                  }}
                  filterOption={false}
                  dropdownMatchSelectWidth={false}
                  notFoundContent={null}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                    </>
                  )}>
                  {options}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="flex justify-end mt-5">
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}>
              Submit
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};
