import { AxiosResponse } from 'axios';
import http from '../../../utils/http.utils';
import {
  IExpenseCategory,
  IExpenseCategoryResult,
  IExpenseCategoryResultRevamp,
  IExpenseCategoryRevamp
} from './types';
import { getDataForIds } from '../../../utils/queries.utils';

export const get_expense_category_list = async (filter = '') => {
  const response: AxiosResponse<IExpenseCategoryResult, any> = await http.get(
    'expense-category/?' + filter
  );
  return response.data.results;
};

export const get_expense_category_list_v2 = async (
  skip = 0,
  count = 0,
  value = '',
  filter = ''
) => {
  const response: AxiosResponse<IExpenseCategoryResultRevamp, any> = await http.get(
    'expense-category/?' + filter,
    {
      skip,
      count,
      value
    }
  );
  return response;
};

export const get_expense_category_list_revamp = async (filter = '') => {
  const response: AxiosResponse<IExpenseCategoryResultRevamp, any> = await http.get(
    'expense-category/?' + filter
  );
  return response.data;
};

export const get_expense_category_details = async (id: number) => {
  const response: AxiosResponse<IExpenseCategory, any> = await http.get('expense-category/' + id);
  return response.data;
};

export const get_expense_category_ids = async (ids: number[]) => {
  return await getDataForIds<IExpenseCategory>(ids, 'expense-category/?');
};

export const get_expense_category_id = async (id: number) => {
  const response: AxiosResponse<IExpenseCategoryRevamp, any> = await http.get(
    'expense-category/' + id
  );
  return response.data;
};

export const get_expense_category_table = async (id: number, filter = {}) => {
  const response: AxiosResponse<any, any> = await http.store(
    'expense-category/get-table/' + id,
    filter
  );
  return response.data;
};

export const get_expense_category_table_list = async (id: number, filter = '') => {
  const response: AxiosResponse<{ results: any[]; total: number }, any> = await http.get(
    `expense/report/${id}?${filter}`
  );
  return response.data;
};
