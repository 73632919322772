import { AxiosResponse } from 'axios';
import http from '@/utils/http.utils';
import { IExpense, IExpenseList, IExpenseListRevamp, IExpenseResult } from './types';
import { getDataForIds } from '@/utils/queries.utils';

export const get_expense_list = async (filter = '') => {
  const response: AxiosResponse<IExpenseResult> = await http.get('expense/?' + filter);
  return response.data;
};

export const get_expense_list_v2 = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IExpenseResult> = await http.get('expense/?' + filter, {
    skip,
    count,
    value
  });
  return response.data;
};

export const get_expense_details_ids = async (ids: number[]) => {
  return await getDataForIds<IExpenseList>(ids, 'expense/?');
};

export const get_expense_details = async (id: number) => {
  const response: AxiosResponse<IExpense> = await http.get('expense/' + id);
  return response.data;
};

export const get_expense_by_id = async (id: number) => {
  const response: AxiosResponse<IExpenseListRevamp> = await http.get('expense/' + id);
  return response.data;
};
