import { get_location_details } from '../../../../services/locations/queries';
import LocationsDB from '../../../../store/localstorage/LocationsDB';
import { find_locationId_preference } from '../../../../store/localstorage/preferences';

export const getShortName = (name: string) => {
  const ind = name.indexOf('/');
  if (ind == -1) {
    return name;
  }
  return name.slice(0, ind);
};

export const getShortLastName = (name: string) => {
  const ind = name.indexOf('/');
  if (ind == -1) {
    return name;
  }
  return name.slice(ind + 1, name.length);
};

export const getPrintLocation = async () => {
  const printLocation = await find_locationId_preference();
  let printLocationDetails;
  if (typeof printLocation === 'number') {
    printLocationDetails = await LocationsDB.getLocation(printLocation);
    if (!printLocationDetails) {
      printLocationDetails = await get_location_details(printLocation);
      await LocationsDB.addLocations([printLocationDetails]);
    }
  }

  return printLocationDetails;
};
