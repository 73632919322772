import { Button, message, PageHeader, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { get_expense_category_id } from '@/services/expense/expense-category/queries';
import AppContent from '@/components/Common/Content/Content';
import { ExpenseCategoryFormData } from '@/services/expense/expense-category/types';
import { useState } from 'react';
import AccountsDB from '@/store/localstorage/AccountsDB';
import { get_account_details } from '@/services/accounts/queries';
import Table, { ColumnsType } from 'antd/lib/table';

function ViewExpenseCategory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [linkedAccount, setLinkedAccount] = useState('');

  const [tableData, setTableData] = useState<any[]>([]);
  const [tableColumns, setTableColumns] = useState<ColumnsType<any>>([]);

  if (!id) {
    return <h1>Expense Category not found</h1>;
  }

  const breadcrumbItems = [
    { label: 'Expense Category', link: '/expense-category/' },
    { label: 'Details', link: `/expense-category/${id}` }
  ];

  const convertData = (data: any[]): Record<string, string>[] => {
    const result: Record<string, Record<string, string>> = {};

    data.forEach((item) => {
      for (const key in item) {
        if (key !== 'name') {
          if (!result[key]) {
            result[key] = {};
          }
          result[key][item.name] = `${key}: ${item[key]}`;
        }
      }
    });

    return Object.values(result);
  };

  const { isLoading, data } = useQuery(
    ['expense-category', id],
    async () => get_expense_category_id(parseInt(id)),
    {
      onError: () => {
        message.error('Failed to get expense category details');
      },
      onSuccess: async (data) => {
        // parse content here
        const { content, debitAccountId } = data;
        if (!content) return;

        const parsedContent = JSON.parse(content) as ExpenseCategoryFormData['content'];

        if (debitAccountId) {
          const account = await AccountsDB.getAccount(debitAccountId);
          if (!account) {
            const accountDetails = await get_account_details(debitAccountId);
            await AccountsDB.addAccounts([accountDetails]);
            setLinkedAccount(accountDetails.name);
          }

          if (account) setLinkedAccount(account.name);
        }

        const result = convertData(parsedContent.column);
        const colums = parsedContent.column.map((item) => ({
          title: item.name,
          dataIndex: item.name,
          key: item.name,
          render: (text: string) => <span className="inline-block p-2">{text}</span>
        }));

        setTableData(result);
        setTableColumns(colums);
      }
    }
  );

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader title="Category Information" style={{ padding: '8px 0px' }} />

        <div className={'grid grid-cols-1 sm:grid-cols-4 gap-5 mb-5'}>
          <div>
            <span>Name: </span>
            <span>{data?.name}</span>
          </div>

          <div>
            <span>Table Name: </span>
            <span>{data?.tableName}</span>
          </div>

          {linkedAccount && (
            <div>
              <span>Linked Account: </span>
              <span>{linkedAccount}</span>
            </div>
          )}
        </div>

        <h2 className="!text-base">Columns Information</h2>
        <Table
          columns={tableColumns}
          dataSource={tableData}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />

        <div className="flex justify-end mt-5 gap-4">
          <Button
            type="primary"
            htmlType="button"
            onClick={() => navigate(`/expense-category/edit/${id}`)}>
            Edit Category
          </Button>

          <Button type="default" htmlType="button" onClick={() => navigate(-1)}>
            Go back
          </Button>
        </div>
      </AppContent>
    </Spin>
  );
}

export default ViewExpenseCategory;
