import PictureList, { PictureListRef } from '@/components/Common/PictureList';
import { Checkbox, Form } from 'antd';

interface Props {
  avatarId?: number;
  vendorMediaRef: React.RefObject<PictureListRef>;
  onDefaultVendorImageChange: (checked: boolean) => void;
}

function VendorImageCollapsible({ avatarId, vendorMediaRef, onDefaultVendorImageChange }: Props) {
  return (
    <>
      <PictureList ref={vendorMediaRef} />

      {/* Add Or Remove Media  */}
      <Form.Item name={'isVendorDefault'} valuePropName="checked">
        <Checkbox
          onChange={(e) => onDefaultVendorImageChange(e.target.checked)}
          disabled={!avatarId}>
          Add default vendor picture
        </Checkbox>
      </Form.Item>
    </>
  );
}

export default VendorImageCollapsible;
