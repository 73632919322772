import { useEffect, useState } from 'react';
import {
  ICreateJournal,
  JournalPreview,
  LinesForJournalPreview
} from '../../../services/accounts/types';
import { Button, Divider, PageHeader, Spin } from 'antd';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import { get_location_details } from '../../../services/locations/queries';
import AccountsDB from '../../../store/localstorage/AccountsDB';
import { get_account_detail_by_ids } from '../../../services/accounts/queries';
import moment from 'moment';
import CustomizeTable from '../CustomizeTable/CustomizeTable';
import { ColumnsType } from 'antd/lib/table';
import TableCell from '../CustomizeTable/CustomCell';
import { nepaliNumberFormatter } from '../../../utils/numberFormatter';

interface ModalProps {
  data: ICreateJournal;
  handleModalClose: (values: ICreateJournal) => void;
}

export const ReusableJournalPreview: React.FC<ModalProps> = ({ data, handleModalClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalData, setModalData] = useState<JournalPreview>({} as JournalPreview);

  useEffect(() => {
    if (data) {
      getAllData(data);
    }
  }, [data]);

  const columns: ColumnsType<LinesForJournalPreview> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 10,
      render: (a, b, c) => <TableCell>{c + 1}</TableCell>
    },
    {
      title: 'Account Name',
      key: 'accountName',
      width: 50,
      render: (a, record) => <TableCell>{record.accountName}</TableCell>
    },
    {
      title: 'Description',
      key: 'description',
      width: 50,
      render: (a, record) => <TableCell>{record.description}</TableCell>
    },
    {
      title: 'Debit',
      key: 'debit',
      width: 25,
      render: (a, record) => <TableCell>{nepaliNumberFormatter(record.debit)}</TableCell>
    },
    {
      title: 'Credit',
      key: 'credit',
      width: 25,
      render: (a, record) => <TableCell>{nepaliNumberFormatter(record.credit)}</TableCell>
    }
  ];

  const getAllData = async (data: ICreateJournal) => {
    setIsLoading(true);
    if (data) {
      let locationDetails = await LocationsDB.getLocation(data.locationId);
      if (!locationDetails) {
        const location = await get_location_details(data.locationId);
        await LocationsDB.addLocations([location]);
        locationDetails = await LocationsDB.getLocation(data.locationId);
      }
      const lines: LinesForJournalPreview[] = [];
      for (let i = 0; i < data.lines.length; i++) {
        let accountDetails = await AccountsDB.getAccount(data.lines[i].accountId);
        if (!accountDetails) {
          const allAccounts = await get_account_detail_by_ids([
            ...new Set(
              data.lines.map((value) => {
                return value.accountId;
              })
            )
          ]);
          await AccountsDB.addAccounts(allAccounts.results);
          accountDetails = await AccountsDB.getAccount(data.lines[i].accountId);
        }

        lines.push({
          ...data.lines[i],
          accountName: typeof accountDetails === 'object' ? accountDetails.name : ''
        });
      }
      setModalData({ ...data, locationName: locationDetails.name, lines });
    }
    setIsLoading(false);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    handleModalClose(data);
  };

  return (
    <Spin spinning={isLoading}>
      <PageHeader
        subTitle="Journal Details"
        style={{
          padding: '2px 0px'
        }}
      />
      <div className="grid grid-cols-2 gap-2">
        <div>
          Location: <b>{modalData.locationName}</b>
        </div>
        <div>
          Date: <b>{moment(modalData.date).utc().format('YYYY-MM-DD')}</b>
        </div>
        <div>
          Description: <b>{modalData.description}</b>
        </div>
        <div>
          Cheque Ref.: <b>{modalData.chequeRef}</b>
        </div>
      </div>
      <Divider />
      <CustomizeTable columns={columns} notshowPagination={true} data={modalData.lines} />
      <div className="flex justify-end mt-5">
        <Button type="primary" onClick={() => handleSubmit()}>
          Confirm
        </Button>
      </div>
    </Spin>
  );
};
