import { useState } from 'react';
import { get_lots_details } from '@/services/products/queries';
import AppContent from '@/components/Common/Content/Content';
import { ProductsSearch } from '@/components/Common/ProductsSearch';
import { Button, Form, message, Spin } from 'antd';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_details } from '@/services/locations/queries';
import { CustomModal } from '@/components/Common/CustomModal';
import ReusablePurchaseView from '../../purchases/view/ReusableView';
import ReusableTransfersView from '../../transfers/view/ReusableView';
import { get_created_by_opening_stock_by_ids } from '@/services/stock/queries';
import UsersDB from '@/store/localstorage/UsersDB';
import { get_user_list_ids } from '@/services/users/queries';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';

const ProductHistory = () => {
  const [form] = Form.useForm();
  const [productInfromation, setproductInformation] = useState<any>();
  const [historyInformation, setHistroyInformation] = useState<any>();
  const [isloading, setIsloading] = useState<any>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [type, setType] = useState();

  const breadcrumbItems = [{ label: 'Products', link: '/products' }, { label: 'History' }];

  const onFinish = async (values: any) => {
    setIsloading(true);
    // console.log('Values', values);
    if (!values.productId) {
      message.error('Please select any product');
      setIsloading(false);
      return;
    }
    const response = await get_lots_details(values.productId);
    const lotswithHistory = response.filter((currLot: any) => currLot.histories !== null);

    // console.log('Response-->', response);
    for (let ind = 0; ind < lotswithHistory.length; ind++) {
      const history = JSON.parse(lotswithHistory[ind].histories);
      try {
        const identifierId = history.map((currHistory: any) => currHistory.identifierId);

        const response = await get_created_by_opening_stock_by_ids([
          ...new Set<number>(identifierId)
        ]);
        for (let i = 0; i < history.length; i++) {
          let locData: any = await LocationsDB.getLocation(history[i].locationId);
          if (!locData) {
            locData = await get_location_details(history[i].locationId);
            LocationsDB.addLocations([locData]);
          }
          history[i].locationName = locData.name;
          const createdById = response.results[i].createdBy;
          let createdByName = (await UsersDB.getUser(createdById))?.name;
          if (!createdByName) {
            const allUsers = await get_user_list_ids([
              ...new Set<number>(response.results.map((curr: any) => curr.createdBy))
            ]);
            await UsersDB.addUsers(allUsers.data.results);
            createdByName = (await UsersDB.getUser(createdById)).name;
          }
          history[i].createdByName = createdByName;
        }
      } catch (error) {
        console.log('Error', error);
      }
      lotswithHistory[ind].histories = history;
    }
    setHistroyInformation(lotswithHistory);
    setIsloading(false);
  };

  const element = (
    <Form form={form} layout={'vertical'} onFinish={onFinish}>
      <div className="flex items-center gap-2 flex-wrap">
        {/* <ProductsSearch
          noall={true}
          formData={{ formName: 'productId' }}
          setProductInformation={setproductInformation}
        /> */}

        <ProductSearchV2
          hasParentFormItem={false}
          name={'productId'}
          onSelect={(val) => form.setFieldValue(['productId'], val)}
          setSelected={(value) => setproductInformation(value)}
        />

        <Form.Item>
          <Button
            className="secondary-button"
            htmlType="submit"
            style={{
              borderRadius: '9px',
              marginTop: '25px'
            }}>
            Search
          </Button>
        </Form.Item>
      </div>
    </Form>
  );

  return (
    <div>
      <Spin spinning={isloading}>
        <AppContent
          breadcrumbItems={breadcrumbItems}
          withfilter={true}
          button={<div className="justify-end hidden xs:flex">{element}</div>}
          internalElement={<div className="block xs:hidden">{element}</div>}>
          {type == 'PURCHASE' ? (
            <CustomModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              title={'Purchase View'}>
              <ReusablePurchaseView id={id} />
            </CustomModal>
          ) : (
            <>
              {type == 'TRANSFER_OUT' ? (
                <>
                  <CustomModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    title={'Transfer Out View'}>
                    <ReusableTransfersView id={id} type={'out'} />
                  </CustomModal>
                </>
              ) : (
                <>
                  {type == 'TRANSFER_IN' ? (
                    <>
                      <CustomModal
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        title={'Transfer Out View'}>
                        <ReusableTransfersView id={id} type={'in'} />
                      </CustomModal>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}

          {productInfromation && <p>Product History of {productInfromation.name}</p>}
          <div>
            {historyInformation?.length > 0 ? (
              <>
                {historyInformation?.map((currLot: any) => {
                  return (
                    <div key={currLot.lotNumber}>
                      <div className="font-bold text-xl">Lot Number: {currLot.lotNumber}</div>
                      <div className="grid grid-cols-4 gap-3 mt-3">
                        {/* <div>
                    <span>Location Name</span>
                    <span>Identifier</span>
                    <span>IdentifierID</span>
                  </div> */}
                        {currLot.histories.map((currHistory: any, ind: number) => {
                          return (
                            <div
                              key={currLot.lotNumber + ind}
                              className="card"
                              style={{ borderRadius: '9px' }}>
                              <div>
                                <span className="font-bold">{'Location     : '}</span>{' '}
                                {currHistory.locationName}
                              </div>
                              <div>
                                <span className="font-bold">{'Identifier    : '}</span>{' '}
                                {currHistory.identifier}
                              </div>
                              <div>
                                <span className="font-bold">{'IdentifierID : '}</span>{' '}
                                <a
                                  className="font-bold"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    console.log('this is called');
                                    setIsModalOpen(true);
                                    setId(currHistory.identifierId);
                                    setType(currHistory.identifier);
                                  }}>
                                  {currHistory.identifierId}
                                </a>
                              </div>
                              <div>
                                <span className="font-bold">{'Created By : '}</span>{' '}
                                {currHistory.createdByName}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div>No History to Show!</div>
              </>
            )}
          </div>
        </AppContent>
      </Spin>
    </div>
  );
};

export default ProductHistory;
