export const tableExportColumns = [
  {
    label: 'Product',
    dataIndex: 'product',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Location',
    dataIndex: 'location',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Sold',
    dataIndex: 'sold',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Purchased',
    dataIndex: 'purchased',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Sell Return',
    dataIndex: 'sell_return',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Purchase Return',
    dataIndex: 'purchase_return',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Wastage',
    dataIndex: 'wastage',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Transfer In',
    dataIndex: 'transfer_in',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Transfer Out',
    dataIndex: 'transfer_out',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

function calculateUnitValue(record: any, field: string) {
  return parseFloat(record[field]);
}

export function getUpdatedData(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      sold: `${calculateUnitValue(record, 'sold')} ${record.unit}`,
      purchased: `${calculateUnitValue(record, 'purchased')} ${record.unit}`,
      sell_return: `${calculateUnitValue(record, 'sell_return')} ${record.unit}`,
      purchase_return: `${calculateUnitValue(record, 'purchase_return')} ${record.unit}`,
      wastage: `${calculateUnitValue(record, 'wastage')} ${record.unit}`,
      transfer_in: `${calculateUnitValue(record, 'transfer_in')} ${record.unit}`,
      transfer_out: `${calculateUnitValue(record, 'transfer_out')} ${record.unit}`
    };
  });
}
