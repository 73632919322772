import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { ICategory, ICategoryListResponse } from './types';
import { getDataForIds } from '../../utils/queries.utils';

/* Get Location lists
 * @param {number} skip
 * @param {number} count
 * @params {value} string
 * @returns {Promise<any>}
 */
export const get_category_list = async (
  skip = 0,
  count = 0,
  value: string | null = '',
  ids: number[] = []
) => {
  const response: AxiosResponse<ICategoryListResponse, any> = await http.get('category', {
    skip,
    count,
    value,
    ids
  });
  return response;
};

export const get_category_list_ids = async (ids: number[] = []) => {
  return await getDataForIds<ICategory>(ids, 'category/?');
};

export const get_category_list_only = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<ICategoryListResponse> = await http.get('category/?' + filter, {
    skip,
    count,
    value
  });
  return response.data.results;
};

export const get_category_list_filter = async (filter = '') => {
  const response: AxiosResponse<ICategoryListResponse, any> = await http.get('category/?' + filter);
  return response;
};

/* Get single location details
 * @param {number} id
 */
export const get_category_details = async (id: number) => {
  const response: AxiosResponse<ICategory, any> = await http.get('category/' + id);
  return response.data;
};
