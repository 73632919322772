import { NotificationEvents } from './enums';

type keys = keyof typeof NotificationMessages;

export const getNotificationLinkAndMessage = (evenType: keys) => {
  return NotificationMessages[evenType];
};
export const NotificationMessages = {
  [NotificationEvents.SELL_CREATE]: { link: '/sell', message: 'New Sell is created.' },
  [NotificationEvents.SELL_ORDER_CREATE]: {
    link: '/sell/order/view',
    message: 'New sell order is created.'
  },
  [NotificationEvents.PURCHASE_CREATE]: { link: '/purchase', message: 'New Purchase is created.' },
  [NotificationEvents.PURCHASE_ORDER_CREATE]: {
    link: '/purchase/order/view',
    message: 'New purchase order is created.'
  },
  [NotificationEvents.PURCHASE_ORDER_FORM]: {
    link: '/purchase/purchase-list/view',
    message: 'New purchase order form is created.'
  },
  [NotificationEvents.SELL_ORDER_FORM]: {
    link: '/sell/sell-list/view',
    message: 'New sell order is created.'
  },
  [NotificationEvents.ADJUSTMENT_CREATE]: {
    link: '/adjustments',
    message: 'New adjustment is created.'
  },
  [NotificationEvents.TRANSFER_INITIATED]: {
    link: '/transfers/view',
    message: 'New transfer is initiated.'
  },
  [NotificationEvents.TRANSFER_APPROVED]: {
    link: '/transfers/view',
    message: 'Tansfer is approved.'
  },
  [NotificationEvents.LOT_MERGED]: { link: '/products/lots', message: 'Lots merged.' },
  [NotificationEvents.LOT_SPLIT]: { link: '/products/lots', message: 'Lots splitted.' },
  [NotificationEvents.DISPATCH_CREATE]: { link: '/diapatch', message: 'New dispatch is created.' },
  [NotificationEvents.DISPATCH_FINALIZE]: { link: '/dispatch', message: 'Dispatch is finalized' },
  [NotificationEvents.MESSAGE_RECEIVED]: { link: '/channel', message: 'New message.' },
  [NotificationEvents.EXPENSE_CREATE]: { link: '/expense', message: 'New expense is created.' },
  [NotificationEvents.EXPENSE_RECURRING_CREATE]: {
    link: '/expense',
    message: 'New recurring expense is created.'
  }
};
