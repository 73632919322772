import React, { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { nepaliNumberFormatter } from '../../../utils/numberFormatter';
import { get_invoices_list } from '../../../services/settings/queries';
import { get_image } from '../FetchImage/FetchImage';
import { API_URL } from '../../../constants/config';
import CustomBarcode from '../../../pages/barcode';
import { Divider, Image, PageHeader, Table } from 'antd';
import { convertLocalToUTCString } from '../../../utils/convertToUTC';
import { convert_string_to_nepali_date_string } from '../../../utils/nepaliDateConverter';
import moment from 'moment';
import CustomTable from '../CustomResuableInvoice/CustomTable';
import { getUserData } from '../../../utils/auth.utils';

const PrintMultipleInvoice = () => {
  const state = JSON.parse(localStorage.getItem('multipleSellorders') as string);
  // console.log('State-->', state);
  const { invoiceLayouts } = state[0];
  const [imgSrc, setimgSrc] = useState('');
  const loggedInUser = getUserData();

  useEffect(() => {
    getInfo();
  }, []);
  const getInfo = async () => {
    const response = await get_invoices_list();
    // console.log(invoiceLayouts);
    if ('invoiceLogo' in invoiceLayouts.content) {
      const imageData = await get_image(
        `${API_URL}storage/media/${JSON.parse(response.data[0].content).invoiceLogo}`
      );
      setimgSrc(imageData);
    } else {
      window.print();
      window.onafterprint = (event) => {
        window.close();
        localStorage.removeItem('multipleSellorders');
      };
    }
  };

  const columns: any[] = [
    {
      label: `${
        invoiceLayouts?.content?.productLabel ? invoiceLayouts.content.productLabel : 'ProductName'
      }`,
      dataIndex: 'productName',
      width: 100,
      render: (text: string) => <div className="ml-2">{text}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.quantityLabel ? invoiceLayouts.content.quantityLabel : 'Quantity'
      }`,
      dataIndex: 'quantity',
      width: 100,
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.unitNameLabel ? invoiceLayouts.content.unitNameLabel : 'Unit'
      }`,
      dataIndex: 'unitName',
      width: 100,
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.unitPriceLabel
          ? invoiceLayouts.content.unitPriceLabel
          : 'UnitPrice'
      }`,
      dataIndex: 'unitPrice',
      width: 100,
      render: (text: number) => <div className="text-center">{text}</div>
    },
    {
      label: `${
        invoiceLayouts?.content?.totalLabel ? invoiceLayouts.content.totalLabel : 'Total Amount'
      }`,
      dataIndex: 'totalAmount',
      width: 100,
      render: (text: number) => <div className="text-right mr-1">{nepaliNumberFormatter(text)}</div>
    }
  ];

  interface ColumnData {
    dataIndex: string;
    label: string;
  }

  const paymentColumns: ColumnsType<{ name: string }> = [
    {
      title: 'Date',
      dataIndex: 'date',
      width: 100,
      render: (text: string) => <div>{text.slice(0, 10)}</div>
    },
    // {
    //   title: `Unit Name`,
    //   dataIndex: 'unitName',
    //   width: 100,
    //   render: (text: string) => <a>{text}</a>
    // },
    {
      title: 'Reference No.',
      dataIndex: 'reference',
      width: 100,
      render: (text: string) => <div>{text}</div>
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: 100,
      render: (text: number) => <div>{nepaliNumberFormatter(text)}/-</div>
    },
    {
      title: 'Payment Mode',
      dataIndex: 'paymentMode',
      width: 100,
      render: (text: number) => <div>{text}</div>
    },
    {
      title: 'Payment note',
      dataIndex: 'note',
      width: 100,
      render: (text: number) => <div>{text}</div>
    }
  ];

  return (
    <div>
      {state.map((currSellOrders: any, index: number) => {
        const {
          customerDetails,
          vendorDetails,
          locationDetails,
          purchaseDetails,
          lines,
          invoiceLayouts,
          adjustmentDetails,
          transferData,
          createdByDetails,
          routeInformation
        } = currSellOrders;
        invoiceLayouts.content = JSON.parse(invoiceLayouts.content);
        return (
          <div style={{ width: '100vw', pageBreakAfter: 'always' }} key={index}>
            <div className="p-5" style={{ color: 'black' }}>
              <div className="flex justify-between">
                <Image
                  src={imgSrc}
                  preview={false}
                  onLoad={() => {
                    window.print();
                    window.onafterprint = (event) => {
                      window.close();
                      localStorage.removeItem('multipleSellorders');
                    };
                  }}
                />
                <div>
                  <div className="font-bold text-lg mr-3">Fresh K.T.M. Pvt. Ltd.</div>
                  <div>Suryabhinayak-08,Bhaktapur,Nepal</div>
                  <div className="mt-5">
                    <span className="font-bold text-sm">PAN No::</span>
                    <span className="text-sm">606891700</span>{' '}
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-3">
                <div className="text-2xl font-extrabold">
                  Freshktm Agro Pvt. Ltd.,{locationDetails?.name ? locationDetails.name : ''}
                </div>
                <div className="text-xl font-bold">Proforma Invoice</div>
              </div>
              <div className="flex justify-between">
                <div>
                  <div>
                    <span className="text-sm font-bold">No.</span>
                    <span>
                      {purchaseDetails?.financialReference
                        ? purchaseDetails.financialReference
                        : purchaseDetails?.invoiceReference
                        ? purchaseDetails.invoiceReference
                        : purchaseDetails.id}
                    </span>
                  </div>
                  <div>Pan Number: 610415863</div>
                  <div>
                    <span>{customerDetails.name}</span>
                  </div>
                  <div>
                    <span>{customerDetails.email}</span>
                  </div>
                  <div>
                    {purchaseDetails.address.addressLine1}
                    {/* {purchaseDetails.address.addressLine2} */}
                  </div>
                  <div>{customerDetails.phone}</div>
                </div>
                <div>
                  <div>
                    <span className="text-sm font-bold">Route: </span>
                    {routeInformation.name}
                  </div>
                  <div>
                    <span className="text-sm font-bold">Date:{}</span>
                    {/* <div>{new Date(new Date().getTime()).toString()}</div> */}
                    {/* <div>{convert_string_to_nepali_date_object(moment().format())}</div> */}
                    <span>{convert_string_to_nepali_date_string(moment().format())}</span>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                {/* <Table
                  size="small"
                  columns={columns}
                  dataSource={lines ? lines : []}
                  bordered
                  pagination={false}
                  // scroll={{ y: '80vh', x: true }}
                /> */}
                <CustomTable datas={lines ? lines : []} columns={columns} />
              </div>
              {/* <CustomizeTable columns={columnss} data={lines} /> */}
              {/* <CustomTable columns={columnss} datas={lines} /> */}
              {/* <CustomizeTable columns={columns} notshowPagination={true} data={lines} /> */}
              {purchaseDetails?.payments?.length > 0 ? (
                <>
                  <div>Payment Info</div>
                  <div style={{ border: '1px solid gray' }}>
                    <Table
                      columns={paymentColumns}
                      dataSource={purchaseDetails.payments}
                      bordered
                      pagination={false}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              <Divider />
              {purchaseDetails ? (
                <div className="flex justify-between">
                  <div>{/* <div>Total Due:{nepaliNumberFormatter(10000)}</div> */}</div>
                  <div>
                    <div>Total Quantity: {purchaseDetails.totalQuantity}</div>
                    <div>Total Amount: {nepaliNumberFormatter(purchaseDetails.totalAmount)} /-</div>
                    <div>
                      Total Discount: {nepaliNumberFormatter(purchaseDetails.totalDiscount)} /-
                    </div>
                    <div>Total Tax: {nepaliNumberFormatter(purchaseDetails.totalTax)} /-</div>
                    <div>Total Price: {nepaliNumberFormatter(purchaseDetails.totalPrice)} /-</div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {/* <div className="flex align-center justify-center">
                {invoiceLayouts && invoiceLayouts.content.footerText
                  ? parser.parseFromString(invoiceLayouts.content.footerText, 'text/html')
                  : ''}
              </div> */}
              {invoiceLayouts?.content?.footerText ? (
                <div
                  dangerouslySetInnerHTML={{ __html: invoiceLayouts?.content?.footerText }}></div>
              ) : (
                <></>
              )}
              <div>Created By:{createdByDetails ? createdByDetails.name : ''}</div>
              <div>
                Printed By:{' '}
                <span className="capitalize">{loggedInUser?.name ? loggedInUser.name : ''}</span>
              </div>
              <div>
                {invoiceLayouts?.content?.noteLabel ? invoiceLayouts?.content.noteLabel : 'Note'} :{' '}
                {purchaseDetails.note ? purchaseDetails.note : ''}
              </div>
              <div className="mt-5 flex justify-center">
                <table style={{ width: '80%', border: '1px solid black' }}>
                  <tr>
                    <td style={tdStyleone}>
                      <div style={tdivStyle}>.....................................</div>
                    </td>
                    <td style={tdStyleone}>
                      <div style={tdivStyle}>info@freshktm.com</div>{' '}
                    </td>
                    <td style={tdStyleone}>
                      <div style={tdivStyle}>......................................</div>
                    </td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>
                      <div style={tdivStyle}>Received by</div>
                    </td>
                    <td style={tdStyle}>
                      <div style={tdivStyle}>www.freshktm.com</div>
                    </td>
                    <td style={tdStyle}>
                      <div style={tdivStyle}>For:Freshktm</div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PrintMultipleInvoice;

const tdStyleone = {
  border: '1px solid black',
  padding: '15px 0px 0px 1px',
  alignText: 'center'
};

const tdStyle = {
  border: '1px solid black',
  padding: '3px',
  alignText: 'center'
};

const tdivStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};
