import LocationsDB from '@/store/localstorage/LocationsDB';
import { AgentsDB } from '@/store/localstorage/AgentsDB';

import UsersDB from '@/store/localstorage/UsersDB';
import CustomersDB from '@/store/localstorage/CustomerDB';
import VendorsDB from '@/store/localstorage/VendorDB';
import { VehiclesDB } from '@/store/localstorage/VehicleDB';
import ExpenseCategoryDB from '@/store/localstorage/ExpenseCategoryDB';

import { get_agent_by_id } from '@/services/agents/queries';
import { get_location_details } from '@/services/locations/queries';
import {
  get_customer_details,
  get_user_details,
  get_vendor_details
} from '@/services/users/queries';
import { get_expense_category_id } from '@/services/expense/expense-category/queries';

export async function getLocation(id: number) {
  let data = await LocationsDB.getLocation(id);
  if (!data) {
    const newData = await get_location_details(id);
    await LocationsDB.addLocations([newData]);
    data = await LocationsDB.getLocation(id);
  }

  return data;
}

export async function getAgent(id: number) {
  let data = await AgentsDB.getAgent(id);
  if (!data) {
    const agent = await get_agent_by_id(id);
    await AgentsDB.addAgents([agent.data]);
    data = await AgentsDB.getAgent(id);
  }

  return data;
}

export async function getUser(id: number) {
  let data = await UsersDB.getUser(id);
  if (!data) {
    const user = await get_user_details(id);
    await UsersDB.addUsers([user.user]);
    data = await UsersDB.getUser(id);
  }

  return data;
}

export async function getVehicle(id: number) {
  return await VehiclesDB.getVehicle(id);
}

export async function getCustomer(id: number) {
  let data = await CustomersDB.getCustomer(id);
  if (!data) {
    const customer = await get_customer_details(id);
    data = customer.customer;
    await CustomersDB.addCustomers([data]);
  }

  return data;
}

export async function getVendors(id: number) {
  let data = await VendorsDB.getVendors(id);

  if (!data) {
    const vendor = await get_vendor_details(id);
    data = vendor.vendor;
    await VendorsDB.addVendors([data]);
  }

  return data;
}

export async function getExpenseCategory(id: number) {
  let data = (await ExpenseCategoryDB.get(id)) as any;
  if (!data) {
    data = await get_expense_category_id(id);
    await ExpenseCategoryDB.add([data]);
  }

  return data;
}
