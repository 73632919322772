import { useContext, useRef, useState } from 'react';

import { useReactToPrint } from 'react-to-print';
import { Button, Menu, Tooltip, message } from 'antd';

import { PurchaseListContext } from './context';
import { exportExcel } from '@/utils/exportExcel';
import { CustomModal } from '@/components/Common/CustomModal';
import ActionDropdown from '@/components/Common/Dropdownactions';

import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import CustomTable from '@/components/Common/CustomResuableInvoice/CustomTable';
import { purchaseExportColumn, purchaseExportPDFColumn } from './TableColumns';
import { DEFAULT_DATE_FORMAT } from '@/constants';

export const TableExportPrint: React.FC<{ allpurchaseList: any }> = ({ allpurchaseList }) => {
  const printPDFRef = useRef<any>();
  const { setIsLoading } = useContext(PurchaseListContext);
  const [openmodalforExport, setOpenModalForExport] = useState<boolean>(false);

  const purchaseData = allpurchaseList.results;

  // Print PDF using react-to-print
  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  const handleExport = () => {
    setIsLoading(true);

    // Check if there is no data to export
    if (purchaseData.length === 0) {
      message.error('No Data to Export');
      setIsLoading(false);
      return;
    }

    // Convert UTC date to local date
    const dataUpdated = purchaseData.map((item: any, index: number) => {
      return {
        ...item,
        SN: index + 1,
        date: convertUTCStringtoLocalString(item.createdAt, DEFAULT_DATE_FORMAT)
      };
    });

    try {
      // Export to excel
      exportExcel(purchaseExportColumn, dataUpdated, 'Purchase List');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const menu = {
    items: [
      {
        key: 'export-excel',
        label: (
          <Tooltip title="Export Excel" color="blue">
            <div className="text-center">Excel</div>
          </Tooltip>
        ),
        tooltip: 'Export Excel',
        onClick: handleExport
      },
      {
        key: 'export-pdf',
        label: (
          <Tooltip title="Export PDF" color="blue">
            <div className="text-center">PDF</div>
          </Tooltip>
        ),
        tooltip: 'Export PDF',
        onClick: () => setOpenModalForExport(true)
      }
    ]
  };

  return (
    <>
      <div className="flex justify-end mb-2">
        <ActionDropdown button={true} menu={<Menu items={menu.items} />} />
      </div>

      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalForExport}
        title="Purchase List View For PDF print">
        <div className="flex justify-end mt-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>

        <div style={{ maxHeight: '80vh', overflow: 'scroll', width: '100%' }}>
          <CustomTable
            columns={purchaseExportPDFColumn}
            datas={purchaseData}
            reff={printPDFRef}
            title={'Purchase List'}
          />
        </div>
      </CustomModal>
    </>
  );
};
