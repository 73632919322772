import { get_category_details } from '../../../../services/category/queries';
import { IProductRouteInvoice, RouteInformation } from '../../../../services/invoice/types';
import { get_location_details } from '../../../../services/locations/queries';
import {
  get_product_details,
  get_product_list_ids,
  get_units_list
} from '../../../../services/products/queries';
import { get_routes_details } from '../../../../services/routes/queries';
import { IRoutesList } from '../../../../services/routes/types';
import {
  get_sell_list_to_print_byRouteId,
  get_sell_order_route_print
} from '../../../../services/sell/queries';
import {
  ISellRoutePrintForInvoice,
  ISellRoutePrintTotalInfo
} from '../../../../services/sell/types';
import LocationsDB from '../../../../store/localstorage/LocationsDB';
import ProductsDB from '../../../../store/localstorage/ProductsDB';
import RoutesDB from '../../../../store/localstorage/RoutesDB';
import UnitsDB from '../../../../store/localstorage/UnitsDB';
import { convertLocalToUTCString } from '../../../../utils/convertToUTC';
import { ConvertObjectToURL } from '../../../../utils/converturl';

export const getSellProductRoutePrintData = async (
  startDate: string,
  endDate: string,
  locationId: number,
  routeId?: number,
  categoryId?: number
) => {
  const filter = ConvertObjectToURL({
    startDate: convertLocalToUTCString(startDate),
    endDate: convertLocalToUTCString(endDate)
  });

  let response;
  let routeInformation: RouteInformation | undefined;
  let quantity;
  let categoryName = '';
  if (!routeId) {
    response = await responseWithoutRoute(startDate, endDate, locationId, filter, categoryId);
    quantity = await getQuantity(response.result);
  } else {
    response = await responseWithRoute(routeId, locationId, filter, categoryId);
    routeInformation = await getRouteInformation(routeId);
    quantity = await getQuantity(response.result);
  }

  if (categoryId) {
    const categoryDetails = await get_category_details(categoryId);
    categoryName = categoryDetails.name;
  }

  let locationDetails;
  if (locationId) {
    locationDetails = await getLocationDetails(locationId);
  }

  return {
    result: response.result,
    routeInformation: {
      ...routeInformation,
      locationName: locationDetails ? locationDetails.name : undefined
    },
    totalProduct: quantity?.totalProduct,
    totalInfo: quantity?.totalInfo,
    categoryName: categoryName
  } as IProductRouteInvoice;
};

export const getSellOrderProductRoutePrintData = async (
  startDate: string,
  endDate: string,
  locationId: number,
  routeId?: number,
  categoryId?: number
) => {
  const apiVals: {
    locationId?: number;
    routeId?: number;
    categoryId?: number;
    startDate: string;
    endDate: string;
  } = {
    startDate: startDate,
    endDate: endDate
  };
  let categoryName = '';

  if (locationId) {
    apiVals['locationId'] = locationId;
  }

  if (categoryId) {
    apiVals['categoryId'] = categoryId;
    const categoryDetails = await get_category_details(categoryId);
    categoryName = categoryDetails.name;
  }

  let routeInformation: Partial<RouteInformation> = { name: 'All Routes' };
  if (routeId) {
    apiVals['routeId'] = routeId;
    routeInformation = await getRouteInformation(routeId);
  } else {
    if (!validateDateRange(startDate, endDate)) {
      throw {
        name: 'Range Error',
        message: 'Date range cannot exceed 30 days if all route is selected!'
      };
    }
  }

  const response = await get_sell_order_route_print(apiVals);
  const quantity = await getQuantity(response.data.result);

  return {
    result: response.data.result,
    routeInformation: { ...routeInformation },
    totalProduct: quantity?.totalProduct,
    totalInfo: quantity?.totalInfo,
    categoryName: categoryName
  } as IProductRouteInvoice;
};

const validateDateRange = (startDate: string, endDate: string) => {
  const interval = Math.floor(
    (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24)
  );
  if (interval > 30) {
    return false;
  } else {
    return true;
  }
};

const responseWithoutRoute = async (
  startDate: string,
  endDate: string,
  locationId: number,
  filter: string,
  categoryId?: number
) => {
  if (!validateDateRange(startDate, endDate)) {
    throw {
      name: 'Range Error',
      message: 'Date range cannot exceed 30 days if all route is selected!'
    };
  }
  const response = await get_sell_list_to_print_byRouteId(
    locationId,
    undefined,
    categoryId,
    filter
  );

  return response.data;
};

const responseWithRoute = async (
  routeId: number,
  locationId: number,
  filter: string,
  categoryId?: number
) => {
  const response = await get_sell_list_to_print_byRouteId(locationId, routeId, categoryId, filter);
  return response.data;
};

const getRouteInformation = async (routeId: number) => {
  let routeInformation = await RoutesDB.getRoute(routeId);
  if (!routeInformation) {
    const routesList = await get_routes_details(routeId);
    RoutesDB.addRoutes([routesList]);
    routeInformation = await RoutesDB.getRoute(routeId);
  }

  return routeInformation as IRoutesList;
};

const getLocationDetails = async (locationId: number) => {
  let locationDetails = await LocationsDB.getLocation(locationId);
  if (!locationDetails) {
    locationDetails = await get_location_details(locationId);
    await LocationsDB.addLocations([locationDetails]);
  }
  return locationDetails;
};

const getQuantity = async (result: ISellRoutePrintForInvoice[]) => {
  const totalInfo: ISellRoutePrintTotalInfo[] = [];
  const totalProduct: ISellRoutePrintForInvoice[] = [];
  for (let index = 0; index < result.length; index++) {
    let findProduct = await ProductsDB.getProduct(result[index].productId);
    if (!findProduct) {
      const allProducts = await get_product_list_ids([
        ...new Set(
          result.map((val) => {
            return val.productId;
          })
        )
      ]);
      await ProductsDB.addProducts(allProducts.data.results);
      findProduct = await ProductsDB.getProduct(result[index].productId);
    }
    if (typeof findProduct === 'object') {
      result[index].productName = findProduct.name;
    }

    let findUnit = await UnitsDB.getUnit(result[index].unitId);
    if (!findUnit) {
      const allUnits = await get_units_list();
      await UnitsDB.addUnits(allUnits);
      findUnit = await UnitsDB.getUnit(result[index].unitId);
    }

    if (typeof findUnit === 'object') {
      result[index].unitName = `${findUnit.shortName}`;
      const indexTotal = totalInfo.findIndex((total) => total.unitId === result[index].unitId);
      if (indexTotal > -1) {
        totalInfo[indexTotal].quantity += result[index].quantity;
      } else {
        totalInfo.push({
          unitId: result[index].unitId,
          unitName: `${findUnit.name} (${findUnit.shortName})`,
          quantity: result[index].quantity
        });
      }

      const indexProduct = totalProduct.findIndex(
        (productList) =>
          productList.productId === result[index].productId &&
          productList.unitId === result[index].unitId
      );
      if (indexProduct > -1) {
        totalProduct[indexProduct].quantity += result[index].quantity;
      } else {
        totalProduct.push(result[index]);
      }
    }
  }

  totalProduct.map(
    (product) => (product.unitName = `${product.quantity.toFixed(2)} ${product.unitName}`)
  );

  return { totalProduct: totalProduct, totalInfo: totalInfo };
};
