import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Input, Form, message, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import AppContent from '@/components/Common/Content';

import TableFilter from '@/components/FliterTable';

import { get_location_list } from '@/services/locations/queries';
import CustomButton from '@/components/Common/CustomButton/CustomButton';

import VendorsDB from '@/store/localstorage/VendorDB';
import { ConvertObjectToURL } from '@/utils/converturl';
import { get_vendor_products_list } from '@/services/vendor-products/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { IVendorProductListResponse } from '@/services/vendor-products/types';
import { CustomDatePresets } from '../../sqlsource/report/utils/datePresets';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import VendorSearchV2 from '@/components/Common/CustomSearch/Vendors';
import getErrorMessage from '@/utils/getError';
import {
  find_locationId_preference,
  find_vendor_product_url
} from '@/store/localstorage/preferences';
import { exportExcelAutoWidth } from '@/utils/exportExcelAutoWidth';
import { getProductById, getVendors } from '@/services';
import Table from './Table';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import QRCode from 'react-qr-code';

const VendorProductsList: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [allVendorList, setAllVendorList] = useState<IVendorProductListResponse>({
    total: 0,
    vendorProducts: []
  });

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const preferenceLocationId = find_locationId_preference() || '';

  const vendorProductUrl = find_vendor_product_url();
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const [QRModalContent, setQRModalContent] = useState<JSX.Element | null>(null);

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(10);
  };

  function handleView(recordId: number) {
    if (!vendorProductUrl) {
      CustomErrorModal({
        title: 'Error!',
        message: 'Please set vendor product url in preferences.'
      });
      return;
    }

    const url = `${vendorProductUrl}?id=${recordId}`;
    window.open(url, '_blank');
  }

  const handleViewQRCode = (recordId: number) => {
    if (vendorProductUrl) {
      setIsQRModalOpen(true);
      setQRModalContent(<QRCode value={`${vendorProductUrl}?id=${recordId}`} />);
    } else {
      CustomErrorModal({
        title: 'Error!',
        message: 'Please set vendor product url in preferences.'
      });
    }
  };

  const getInfo = async (filter = '') => {
    setIsloading(true);
    const { data } = await get_vendor_products_list(filter);
    const allVendorIds = Array.from(new Set(data.vendorProducts.map((item) => item.vendorId)));
    const allProductsIds = Array.from(new Set(data.vendorProducts.map((item) => item.productId)));

    await Promise.all([
      VendorsDB.addVendorsIfAbsent(allVendorIds),
      ProductsDB.addProductsIfAbsent(allProductsIds)
    ]);

    for (const vendorProduct of data.vendorProducts) {
      const product = await getProductById(vendorProduct.productId);
      const vendor = await getVendors(vendorProduct.vendorId);

      vendorProduct.productName = product.name;
      vendorProduct.userName = vendor.user.name;
    }

    setAllVendorList(data);
    setIsloading(false);
  };

  const { data: locationsList } = useQuery(['locations'], async () => get_location_list());

  const onPagination = async (pageNo = 1, totalSize = 10, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    delete values.dateCustom;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns = [
        { title: 'S.N', dataIndex: 'SN' },
        { title: 'Vendor', dataIndex: 'userName' },
        { title: 'Product', dataIndex: 'productName' }
      ];

      if (allVendorList.vendorProducts.length === 0) {
        message.error('No Data to Export');
        return;
      }

      const dataUpdated = allVendorList.vendorProducts.map((item, index) => {
        return { ...item, SN: index + 1 };
      });

      exportExcelAutoWidth(columns, dataUpdated, 'Vendor Product List');
    } catch (err) {
      getErrorMessage(err, true);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <div>
      <Modal
        footer={false}
        width="300px"
        visible={isQRModalOpen}
        onCancel={() => setIsQRModalOpen(false)}
        title="QR Code">
        <div className="flex justify-center">{<div>{QRModalContent}</div>}</div>
      </Modal>
      <AppContent
        breadcrumbItems={[{ label: 'Vendor Products', link: '/vendor-products' }]}
        withfilter={true}
        button={
          <>
            <div>
              {locationsList && (
                <TableFilter
                  defaultValues={{
                    dateCustom: [...CustomDatePresets.Today],
                    value: '',
                    paymentStatus: '',
                    purchaseStatus: '',
                    supplier: '',
                    locationId: preferenceLocationId,
                    vendorId: ''
                  }}
                  initial
                  onSubmit={onSubmitFilter}
                  form={form}
                  styleforbuttons="flex justify-end items-center"
                  buttons={
                    <>
                      <CustomButton
                        onClick={() => navigate('/vendor-products/new')}
                        text="Add"
                        backgroundColor="#1890ff"
                        Linkto="/vendor-products/new"
                      />

                      <CustomButton
                        text="Export"
                        backgroundColor="#1890ff"
                        onClick={handleExport}
                      />
                    </>
                  }>
                  <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />
                  <VendorSearchV2 hasParentFormItem={false} name={'vendorId'} showAll />
                  <Form.Item name="value" label="Search">
                    <Input placeholder="Search" />
                  </Form.Item>
                </TableFilter>
              )}
            </div>
          </>
        }>
        <Table
          page={page}
          size={size}
          isLoading={isLoading}
          data={allVendorList}
          onView={handleView}
          onPagination={onPagination}
          onViewQRCode={handleViewQRCode}
        />
      </AppContent>
    </div>
  );
};

export default VendorProductsList;
