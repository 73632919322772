import { CloseSquareFilled, CloseSquareTwoTone } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Card,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  PageHeader,
  Select,
  TimePicker
} from 'antd';
import { AxiosError } from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppContent from '../../../components/Common/Content/Content';
import CustomSubmitButton from '../../../components/Common/CustomButton/CustomSubmitButton';
import { UsersSearch } from '../../../components/Common/UsersSearch';
import useDebounce from '../../../hooks/useDebounce';
import { create_hr_mutation, update_hr_mutation } from '../../../services/hr/mutations';
import { IHRCreateRequest, IHRUpdateRequest, IHRUpdateRequestUI } from '../../../services/hr/types';
import { get_user_details } from '../../../services/users/queries';
// import type { Moment } from 'moment';

const { Option } = Select;
const HRDetails = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [userSearch, setUserSearch] = useState<any>([]);
  const [isCreate, setIsCreate] = useState(false);

  useQuery(['hr'], async () => {
    if (id) {
      const response = await get_user_details(id);
      form.setFieldValue('userId', response.user.id);
      setUserSearch([response.user]);
      if (response.information) {
        if (response.information.shifts) {
          response.information.shifts = JSON.parse(response.information.shifts);
          for (let ind = 0; ind < response.information.shifts.length; ind++) {
            response.information.shifts[ind].startTime = moment(
              response.information.shifts[ind].startTime,
              'HH:mm:ss'
            );
            response.information.shifts[ind].endTime = moment(
              response.information.shifts[ind].endTime,
              'HH:mm:ss'
            );
          }
          // delete response.information.shifts;
        }
        // console.log(response.information.shifts);
        response.information.joinDate = moment(response.information.joinDate);
        if (response.information.dateOfTermination) {
          response.information.dateOfTermination = moment(response.information.dateOfTermination);
          form.setFieldsValue(response.information);
        } else {
          form.setFieldsValue(response.information);
        }
        console.log('response information', response.information);
      } else {
        message.info('No HR information for this user yet! Create new!');
        setIsCreate(true);
      }
    }
    setIsLoading(false);
  });

  const updateHRMutation = useMutation(update_hr_mutation, {
    onSuccess: () => {
      message.success('HR updated successfully!');
      navigate('/hr');
    },
    onError: (error: AxiosError) => {
      message.error(error.message);
    }
  });
  const createHRMutation = useMutation(create_hr_mutation, {
    onSuccess: () => {
      message.success('HR created successfully!');
      navigate('/hr');
    },
    onError: (error: AxiosError) => {
      message.error(error.message);
    }
  });
  const onFinish = async (values: IHRUpdateRequestUI) => {
    setIsLoading(true);
    console.log('values', values);
    if (values.id) {
      const postValue: IHRUpdateRequest = {
        ...values,
        joinDate: values.joinDate.utc().format(),
        dateOfTermination: values.dateOfTermination?.utc().format(),
        id: values.id
      };
      await updateHRMutation.mutateAsync(postValue);
    } else {
      delete values.id;
      const postValue: IHRCreateRequest = {
        ...values,
        joinDate: values.joinDate.utc().format(),
        dateOfTermination: values.dateOfTermination?.utc().format()
      };
      await createHRMutation.mutateAsync(postValue);
    }
    setIsLoading(false);
  };

  const options = userSearch?.map((value: any) => (
    <Option key={value.id} value={value.id}>
      {value.name ? value.name : value.user.name}, {value?.phone ? value.phone : value?.user?.phone}
    </Option>
  ));

  const breadcrumbItems = [
    {
      label: 'HR',
      link: '/hr'
    },
    {
      label: isCreate ? 'Create' : 'Edit'
    }
  ];

  return (
    <AppContent breadcrumbItems={breadcrumbItems}>
      <PageHeader
        title={isCreate ? 'HR Create' : 'HR Edit'}
        style={{
          padding: '8px 0px'
        }}
      />
      <Form
        form={form}
        onFinish={onFinish}
        disabled={isLoading}
        layout="vertical"
        className="main-div"
        autoComplete="off">
        <Form.Item
          name={'userId'}
          label={'User'}
          rules={[
            {
              required: true,
              message: 'Please choose a user!'
            }
          ]}>
          <Select
            showSearch
            placeholder={'search user'}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            dropdownMatchSelectWidth={false}
            notFoundContent={null}
            disabled>
            {options}
          </Select>
        </Form.Item>
        <Collapse defaultActiveKey={['1']}>
          <Collapse.Panel header="Personal Information" key="1">
            <PageHeader
              subTitle={'Personal Information'}
              style={{
                padding: '8px 0px'
              }}
            />
            <Form.Item
              label="id"
              name="id"
              rules={[{ required: false, message: 'Please input permanent location!' }]}
              hidden>
              <Input />
            </Form.Item>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              <Form.Item
                label="Gender"
                name="gender"
                rules={[{ required: true, message: 'Please select gender!' }]}>
                <Select>
                  <Option value="female">Female</Option>
                  <Option value="male">Male</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Blood Group"
                name="bloodGroup"
                rules={[{ required: true, message: 'Please select blood group!' }]}>
                <Select>
                  <Option value="A+">A+</Option>
                  <Option value="A-">A-</Option>
                  <Option value="B+">B+</Option>
                  <Option value="B-">B-</Option>
                  <Option value="AB+">AB+</Option>
                  <Option value="AB-">AB-</Option>
                  <Option value="O+">O+</Option>
                  <Option value="O-">O-</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Martial Status"
                name="maritalStatus"
                rules={[{ required: true, message: 'Please select martial status!' }]}>
                <Select>
                  <Option value="single">Single</Option>
                  <Option value="married">Married</Option>
                  <Option value="divorced">Divorced</Option>
                  <Option value="widowed">Widowed</Option>
                </Select>
              </Form.Item>
              {/* </div>
            <div className="grid grid-cols-2 gap-2"> */}
              <Form.Item
                label="Permanent Location"
                name="permanentLocation"
                rules={[{ required: true, message: 'Please input permanent location!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Current Location"
                name="currentLocation"
                rules={[{ required: true, message: 'Please input current location!' }]}>
                <Input />
              </Form.Item>
            </div>
          </Collapse.Panel>
        </Collapse>

        <Collapse defaultActiveKey={['1']}>
          <Collapse.Panel header="Bank Information" key="1">
            <PageHeader
              subTitle={'Bank Information'}
              style={{
                padding: '8px 0px'
              }}
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              <Form.Item
                label="PAN"
                name="pan"
                rules={[{ required: true, message: 'Please input PAN!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Account Number"
                name="accountNo"
                rules={[{ required: true, message: 'Please input account number!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Bank Name"
                name="bankName"
                rules={[{ required: true, message: 'Please input bank name!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Branch"
                name="branchName"
                rules={[{ required: true, message: 'Please input branch!' }]}>
                <Input />
              </Form.Item>
            </div>
          </Collapse.Panel>
        </Collapse>

        <Collapse defaultActiveKey={['1']}>
          <Collapse.Panel header="Job Details" key="1">
            <PageHeader
              subTitle={'Job Details'}
              style={{
                padding: '8px 0px'
              }}
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              <Form.Item
                label="Job Type"
                name="jobType"
                rules={[{ required: true, message: 'Please input job type!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true, message: 'Please input description!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Department"
                name="departments"
                rules={[{ required: true, message: 'Please input Department!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Salary"
                name="salary"
                rules={[{ required: true, message: 'Please input salary!' }]}>
                <InputNumber controls={false} min={0} />
              </Form.Item>
              <Form.Item
                label="Join Date"
                name="joinDate"
                rules={[{ required: true, message: 'Please input join date!' }]}>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                label="Termination Date"
                name="dateOfTermination"
                rules={[{ required: false, message: 'Please input termination date!' }]}>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </div>
          </Collapse.Panel>
        </Collapse>
        <Collapse defaultActiveKey={['1']}>
          <Collapse.Panel header="Shifts" key="1">
            <PageHeader
              subTitle={'Shifts'}
              style={{
                padding: '8px 0px'
              }}
            />
            <Form.List name={['shifts']}>
              {(fields, { add, remove }, { errors: errors2 }) => (
                <>
                  {fields.map(({ key, name, ...restField2 }) => (
                    <div
                      className="grid mt-2 grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-2"
                      key={key}>
                      <Form.Item
                        label="Start Day"
                        name={[name, 'startDay']}
                        rules={[{ required: true, message: 'Please select start day!' }]}>
                        <Select>
                          <Option value={1}>Sunday</Option>
                          <Option value={2}>Monday</Option>
                          <Option value={3}>Tuseday</Option>
                          <Option value={4}>Wednesday</Option>
                          <Option value={5}>Thursday</Option>
                          <Option value={6}>Friday</Option>
                          <Option value={7}>Saturday</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Start Time"
                        name={[name, 'startTime']}
                        rules={[{ required: true, message: 'Please select start time!' }]}>
                        <TimePicker
                          onChange={(time: any, timeString: string) => {
                            // form.setFieldValue([name, 'startTime'], timeString);
                          }}
                        />
                      </Form.Item>
                      {/* <Form.Item label="End Time" name={[name, 'startTime']} hidden>
                        <Input />
                      </Form.Item> */}
                      <Form.Item
                        label="End Day"
                        name={[name, 'endDay']}
                        rules={[{ required: true, message: 'Please select end day!' }]}>
                        <Select>
                          <Option value={1}>Sunday</Option>
                          <Option value={2}>Monday</Option>
                          <Option value={3}>Tuseday</Option>
                          <Option value={4}>Wednesday</Option>
                          <Option value={5}>Thursday</Option>
                          <Option value={6}>Friday</Option>
                          <Option value={7}>Saturday</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="End Time"
                        name={[name, 'endTime']}
                        rules={[{ required: true, message: 'Please select end time!' }]}>
                        <TimePicker
                          onChange={(time: any, timeString: string) => {
                            console.log(timeString);
                            // form.setFieldValue([name, 'endTime'], timeString);
                          }}
                        />
                      </Form.Item>
                      {/* <Form.Item label="End Time" name={[name, 'endTime']} hidden>
                        <Input />
                      </Form.Item> */}
                      <div className="flex items-center justify-start mt-2 sm:mt-5">
                        <CloseSquareTwoTone
                          style={{ transform: 'scale(1.5)', color: 'red' }}
                          onClick={() => {
                            remove(name);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  <Button
                    type="primary"
                    onClick={() => {
                      add({
                        startDay: 1,
                        startTime: moment('10:00:00', 'HH:mm:ss'),
                        endDay: 1,
                        endTime: moment('18:00:00', 'HH:mm:ss')
                      });
                    }}
                    className="mt-2">
                    Add Shift
                  </Button>
                </>
              )}
            </Form.List>
          </Collapse.Panel>
        </Collapse>

        <div className="flex justify-end mt-5">
          <Form.Item>
            <CustomSubmitButton text={isCreate ? 'Create' : 'Update'} />
          </Form.Item>
        </div>
      </Form>
    </AppContent>
  );
};

export default HRDetails;
