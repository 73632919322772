import { useState, useEffect, useCallback, useRef } from 'react';

function useSingleClick<T extends (...args: any[]) => any>(callback: T, delayTime = 2500) {
  const [clickTime, setClickTime] = useState<number | null>(null);
  const timerRef = useRef<any | null>(null);

  const clearExistingTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const handleClick = useCallback(
    (...args: Parameters<T>): ReturnType<T> | undefined => {
      const now = Date.now();
      let result: ReturnType<T> | undefined;

      if (clickTime === null) {
        result = callback(...args);
      }

      setClickTime(now);
      clearExistingTimer();

      timerRef.current = setTimeout(() => {
        setClickTime(null);
      }, delayTime);

      return result;
    },
    [clickTime, callback, delayTime]
  );

  useEffect(() => {
    return () => clearExistingTimer();
  }, []);

  return handleClick;
}

export default useSingleClick;
