import { Form, Input, Select, Spin, Table, TableProps } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import TableCell from '../../../../components/Common/CustomizeTable/CustomCell';
import { nepaliNumberFormatter } from '../../../../utils/numberFormatter';
import { get_vendor_list_v2 } from '../../../../services/users/queries';
import { ConvertObjectToURL } from '../../../../utils/converturl';
import AppContent from '../../../../components/Common/Content/Content';
import TableFilter from '../../../../components/FliterTable';
import CustomizeTable from '../../../../components/Common/CustomizeTable/CustomizeTable';
import { IVendor, IVendorData } from '../../../../services/users/types';
import { useParams } from 'react-router-dom';

const VendorBalanceList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [totalBalance, setTotalBalance] = useState<number>(0);
  const [vendorList, setVendorList] = useState<IVendorData>(Object);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const breadcrumbItems = [
    {
      label: id === 'trialbalance' ? 'Trial Balance' : 'Ledger',
      link: id === 'trialbalance' ? '/trial-balance' : '/ledger'
    },
    {
      label: 'Vendor Balance',
      link: '/accounts/balance/vendor'
    }
  ];

  const columns: ColumnsType<IVendor> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      width: 10,
      key: 'name',
      sorter: (a, b) => {
        return a.user.name.localeCompare(b.user.name);
      },
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (a, record) => {
        return <TableCell>{record.user.name}</TableCell>;
      }
    },
    {
      title: 'Email',
      key: 'email',
      width: 10,
      sorter: (a, b) => {
        return a.user.email.localeCompare(b.user.email);
      },
      sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
      render: (a, record) => {
        return <TableCell> {record.user.email}</TableCell>;
      }
    },
    {
      title: 'Phone',
      key: 'phone',
      width: 7,
      sorter: (a, b) => {
        return a.user.phone.localeCompare(b.user.phone);
      },
      sortOrder: sortedInfo.columnKey === 'phone' ? sortedInfo.order : null,
      render: (a, record) => {
        return <TableCell>{record.user.phone}</TableCell>;
      }
    },
    {
      title: 'Balance',
      key: 'accountBalance',
      width: 7,
      sorter: (a, b) => {
        return a.accountBalance - b.accountBalance;
      },
      sortOrder: sortedInfo.columnKey === 'accountBalance' ? sortedInfo.order : null,
      render: (a, record) => {
        return <TableCell>{nepaliNumberFormatter(record.accountBalance)}</TableCell>;
      }
    }
  ];

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const response = await get_vendor_list_v2(filter);
    // console.log('Response', response);
    let total = 0;
    response.data.results.forEach((value) => (total += value.accountBalance));
    setTotalBalance(total);
    setVendorList(response.data);
    setIsLoading(false);
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            {/* <div className="flex flex-wrap items-center justify-end"> */}
            <div>
              <TableFilter
                dateCustom={false}
                defaultValues={{
                  value: '',
                  skip: 0,
                  count: 100,
                  disabled: false
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                styleforbuttons="flex justify-end items-center"
                style={'grid grid-cols-2 gap-2'}>
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
                <Form.Item label="Status" name="disabled">
                  <Select allowClear>
                    <Select.Option value={true}>Disabled</Select.Option>
                    <Select.Option value={false}>Enabled</Select.Option>
                  </Select>
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          columns={columns}
          data={vendorList.results}
          notshowPagination={true}
          customScroll={{ x: 600, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: vendorList.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
          tableSummary={
            <Table.Summary fixed={true}>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4} className="text-right mr-2 text-xs">
                  {nepaliNumberFormatter(totalBalance)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          }
        />
      </AppContent>
    </Spin>
  );
};

export default VendorBalanceList;
