import {
  Alert,
  Button,
  Collapse,
  Form,
  FormInstance,
  PageHeader,
  Pagination,
  Spin,
  message
} from 'antd';
import Table, { ColumnType, ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { check_date_cap, check_export_report_date_cap } from '@/utils/common';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { download_report, get_report } from '@/services/report/queries';
import AppContent from '@/components/Common/Content/Content';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { nepaliNumberFormatter, numberDecimalFormatter } from '@/utils/numberFormatter';
import { shortNameHiearchy } from '../../../sell/sell-order/view';
import { showReportConditionalDownloadMessage } from '@/utils/reportConditionalDownloadMessage';
import { excelExportColumns, getUpdatedData, tableExportColumns } from './column.export';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportAllData from '@/components/Common/ExportAll';
import ExportCurrent from '@/components/Common/ExportCurrent';

interface State {
  id: number;
  name: string;
}

interface ReportData {
  count: any;
  data: any;
}

interface ReportProps {
  state: State;
  breadcrumbItems: {
    label: string;
    link: string;
  }[];
  form: FormInstance<any>;
  columnsData: ColumnType<object>[];
  toSort?: any;
}

const CustomReport: React.FC<ReportProps> = ({
  state,
  breadcrumbItems,
  form,
  columnsData,
  toSort,
  children
}) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [data, setData] = useState<ReportData>({ count: 0, data: [] });
  const [size, setSize] = useState(100);
  const [isEmpty, setIsEmpty] = useState(false);

  const [fullExportURL, setFullExportURL] = useState<any>(null);

  const [footerTotal, setFooterTotal] = useState({
    detailAmount: 0,
    detailQuantity: '0',
    groupAmount: 0,
    groupQuantity: 0,
    totalQuantityOpening: '0',
    totalQuantityClosing: '0',
    totalQuantityPurchased: '0',
    totalAvgQuantityPurchased: '0',
    totalQuantityPurchasedReturned: '0',
    totalAvgQuantityPurchasedReturned: '0',
    totalQuantityReturnedSell: '0',
    totalAvgQuantityReturnedSell: '0',
    totalQuantityTransferred: '0',
    totalAvgQuantityTransferred: '0',
    totalQuantityTransferredOut: '0',
    totalAvgQuantityTransferredOut: '0',
    totalQuantitySold: '0',
    totalAvgQuantitySold: '0',
    totalQuantityAdjusted: '0',
    totalAvgQuantityAdjusted: '0',
    totalQuantityOpeningReceived: '0',
    totalAvgQuantityOpeningReceived: '0',
    totalProfit: '0'
  });
  const [page, setPage] = useState<number>(1);
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    if (!columnsData) createColumns();
  }, [data]);
  const createColumns = () => {
    //auto generate columns
    const newColumns: ColumnsType<any> = [];
    for (const key in data.data[0]) {
      newColumns.push({
        title: key,
        key,
        render: (record: any) => {
          return <div className="forless">{record[key]}</div>;
        }
      });
    }
    setColumns(newColumns);
  };

  const onSearch = async (values: any, storePage = false) => {
    // console.log('values-->', values);
    setIsloading(true);
    if (check_date_cap(values.constraints.startDate, values.constraints.endDate)) {
      await downloadPdf();
      setIsloading(false);
      return;
    }
    if (!storePage) {
      values.constraints['page'] = 1;
      values.constraints['size'] = size;
      setPage(1);
    }
    //remove custom date
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    const response = await get_report(values.constraints, state.id);
    // console.log('Response', response);

    if (response.data.data.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
    response.data = await calculateTotal(response.data);
    const payload = values.constraints as any;
    payload.size = response.data.count;
    setFullExportURL(payload);

    setData(response.data);
    setIsloading(false);
  };

  const calculateTotal = async (data: any) => {
    const t_qty_opening: any = {};
    const t_qty_closing: any = {};
    const t_qty_adjusted: any = {};
    const t_qty_purchased: any = {};
    const t_qty_returned_purchase: any = {};
    const t_qty_returned_sell: any = {};
    const t_qty_sold: any = {};
    const t_qty_transferred: any = {};
    const t_qty_transferred_out: any = {};
    const t_qty_opening_received: any = {};
    const t_avg_qty_adjusted: any = {};
    const t_avg_qty_purchased: any = {};
    const t_avg_qty_returned_purchase: any = {};
    const t_avg_qty_returned_sell: any = {};
    const t_avg_qty_sold: any = {};
    const t_avg_qty_transferred: any = {};
    const t_avg_qty_transferred_out: any = {};
    const t_avg_qty_opening_received: any = {};
    let t_total_profit = 0;

    for (const stockHistory of data.data) {
      t_total_profit += stockHistory.total_profit as number;
      if (!t_qty_opening[stockHistory.unit]) {
        t_qty_opening[stockHistory.unit] = parseFloat(stockHistory.qty_opening);
      } else {
        t_qty_opening[stockHistory.unit] += parseFloat(stockHistory.qty_opening);
      }

      if (!t_qty_closing[stockHistory.unit]) {
        t_qty_closing[stockHistory.unit] = parseFloat(stockHistory.qty_closing);
      } else {
        t_qty_closing[stockHistory.unit] += parseFloat(stockHistory.qty_closing);
      }

      if (!t_qty_adjusted[stockHistory.unit]) {
        t_qty_adjusted[stockHistory.unit] = parseFloat(stockHistory.qty_adjusted);
      } else {
        t_qty_adjusted[stockHistory.unit] += parseFloat(stockHistory.qty_adjusted);
      }

      if (!t_qty_purchased[stockHistory.unit]) {
        t_qty_purchased[stockHistory.unit] = parseFloat(stockHistory.qty_purchased);
      } else {
        t_qty_purchased[stockHistory.unit] += parseFloat(stockHistory.qty_purchased);
      }

      if (!t_qty_returned_purchase[stockHistory.unit]) {
        t_qty_returned_purchase[stockHistory.unit] = parseFloat(stockHistory.qty_returned_purchase);
      } else {
        t_qty_returned_purchase[stockHistory.unit] += parseFloat(
          stockHistory.qty_returned_purchase
        );
      }

      if (!t_qty_returned_sell[stockHistory.unit]) {
        t_qty_returned_sell[stockHistory.unit] = parseFloat(stockHistory.qty_returned_sell);
      } else {
        t_qty_returned_sell[stockHistory.unit] += parseFloat(stockHistory.qty_returned_sell);
      }

      if (!t_qty_transferred[stockHistory.unit]) {
        t_qty_transferred[stockHistory.unit] = parseFloat(stockHistory.qty_transferred);
      } else {
        t_qty_transferred[stockHistory.unit] += parseFloat(stockHistory.qty_transferred);
      }

      if (!t_qty_transferred_out[stockHistory.unit]) {
        t_qty_transferred_out[stockHistory.unit] = parseFloat(stockHistory.qty_transferred_out);
      } else {
        t_qty_transferred_out[stockHistory.unit] += parseFloat(stockHistory.qty_transferred_out);
      }

      if (!t_qty_sold[stockHistory.unit]) {
        t_qty_sold[stockHistory.unit] = parseFloat(stockHistory.qty_sold);
      } else {
        t_qty_sold[stockHistory.unit] += parseFloat(stockHistory.qty_sold);
      }

      if (!t_qty_opening_received[stockHistory.unit]) {
        t_qty_opening_received[stockHistory.unit] = parseFloat(stockHistory.qty_opening_received);
      } else {
        t_qty_opening_received[stockHistory.unit] += parseFloat(stockHistory.qty_opening_received);
      }

      if (!t_avg_qty_adjusted[stockHistory.unit]) {
        t_avg_qty_adjusted[stockHistory.unit] = parseFloat(stockHistory.qty_adjusted_price);
      } else {
        t_avg_qty_adjusted[stockHistory.unit] += parseFloat(stockHistory.qty_adjusted_price);
      }

      if (!t_avg_qty_purchased[stockHistory.unit]) {
        t_avg_qty_purchased[stockHistory.unit] = parseFloat(stockHistory.qty_purchased_price);
      } else {
        t_avg_qty_purchased[stockHistory.unit] += parseFloat(stockHistory.qty_purchased_price);
      }

      if (!t_avg_qty_returned_purchase[stockHistory.unit]) {
        t_avg_qty_returned_purchase[stockHistory.unit] = parseFloat(
          stockHistory.qty_returned_purchase_price
        );
      } else {
        t_avg_qty_returned_purchase[stockHistory.unit] += parseFloat(
          stockHistory.qty_returned_purchase_price
        );
      }

      if (!t_avg_qty_returned_sell[stockHistory.unit]) {
        t_avg_qty_returned_sell[stockHistory.unit] = parseFloat(
          stockHistory.qty_returned_sell_price
        );
      } else {
        t_avg_qty_returned_sell[stockHistory.unit] += parseFloat(
          stockHistory.qty_returned_sell_price
        );
      }

      if (!t_avg_qty_sold[stockHistory.unit]) {
        t_avg_qty_sold[stockHistory.unit] = parseFloat(stockHistory.qty_sold_price);
      } else {
        t_avg_qty_sold[stockHistory.unit] += parseFloat(stockHistory.qty_sold_price);
      }

      if (!t_avg_qty_transferred[stockHistory.unit]) {
        t_avg_qty_transferred[stockHistory.unit] = parseFloat(stockHistory.qty_transferred_price);
      } else {
        t_avg_qty_transferred[stockHistory.unit] += parseFloat(stockHistory.qty_transferred_price);
      }

      if (!t_avg_qty_transferred_out[stockHistory.unit]) {
        t_avg_qty_transferred_out[stockHistory.unit] = parseFloat(
          stockHistory.qty_transferred_out_price
        );
      } else {
        t_avg_qty_transferred_out[stockHistory.unit] += parseFloat(
          stockHistory.qty_transferred_out_price
        );
      }

      if (!t_avg_qty_opening_received[stockHistory.unit]) {
        t_avg_qty_opening_received[stockHistory.unit] = parseFloat(
          stockHistory.qty_opening_received_price
        );
      } else {
        t_avg_qty_opening_received[stockHistory.unit] += parseFloat(
          stockHistory.qty_opening_received_price
        );
      }
    }

    setFooterTotal((prev) => {
      return {
        ...prev,
        totalQuantityOpening: calculateTotalQuantityString(t_qty_opening),
        totalQuantityClosing: calculateTotalQuantityString(t_qty_closing),
        totalQuantityPurchased: calculateTotalQuantityString(t_qty_purchased),
        totalQuantityPurchasedReturned: calculateTotalQuantityString(t_qty_returned_purchase),
        totalQuantityReturnedSell: calculateTotalQuantityString(t_qty_returned_sell),
        totalQuantityTransferred: calculateTotalQuantityString(t_qty_transferred),
        totalQuantityTransferredOut: calculateTotalQuantityString(t_qty_transferred_out),
        totalQuantitySold: calculateTotalQuantityString(t_qty_sold),
        totalQuantityAdjusted: calculateTotalQuantityString(t_qty_adjusted),
        totalQuantityOpeningReceived: calculateTotalQuantityString(t_qty_opening_received),
        totalAvgQuantityAdjusted: calculateTotalQuantityStringWithoutUnit(t_avg_qty_adjusted),
        totalAvgQuantityPurchased: calculateTotalQuantityStringWithoutUnit(t_avg_qty_purchased),
        totalAvgQuantityPurchasedReturned: calculateTotalQuantityStringWithoutUnit(
          t_avg_qty_returned_purchase
        ),
        totalAvgQuantityReturnedSell:
          calculateTotalQuantityStringWithoutUnit(t_avg_qty_returned_sell),
        totalAvgQuantitySold: calculateTotalQuantityStringWithoutUnit(t_avg_qty_sold),
        totalAvgQuantityTransferred: calculateTotalQuantityStringWithoutUnit(t_avg_qty_transferred),
        totalAvgQuantityTransferredOut:
          calculateTotalQuantityStringWithoutUnit(t_avg_qty_transferred_out),
        totalAvgQuantityOpeningReceived: calculateTotalQuantityStringWithoutUnit(
          t_avg_qty_opening_received
        ),
        totalProfit: `${t_total_profit}`
      };
    });

    // console.log('Data', data);
    return data;
  };

  const calculateTotalQuantityString = (totalQuantity: any) => {
    let totalQuantityString = '';
    const totalQuantityArray: any[] = [];
    for (const key in totalQuantity) {
      totalQuantityArray.push({
        tqty: totalQuantity[key],
        shortName: key
      });
    }
    if (totalQuantityArray.length == 0) return '0';
    totalQuantityArray.sort((a: any, b: any) => {
      return shortNameHiearchy.indexOf(a.shortName) - shortNameHiearchy.indexOf(b.shortName);
    });
    for (let ind = 0; ind < totalQuantityArray.length; ind++) {
      totalQuantityString +=
        totalQuantityArray[ind].tqty == 0
          ? ''
          : ` ${numberDecimalFormatter(totalQuantityArray[ind].tqty)} ${
              totalQuantityArray[ind].shortName
            }`;
    }
    if (totalQuantityString == '') return '0';
    return totalQuantityString;
  };

  const calculateTotalQuantityStringWithoutUnit = (totalQuantity: any) => {
    let totalQuantityString = '';
    const totalQuantityArray: any[] = [];
    for (const key in totalQuantity) {
      totalQuantityArray.push({
        tqty: totalQuantity[key]
      });
    }
    if (totalQuantityArray.length == 0) return '0';
    totalQuantityArray.sort((a: any, b: any) => {
      return shortNameHiearchy.indexOf(a.shortName) - shortNameHiearchy.indexOf(b.shortName);
    });
    for (let ind = 0; ind < totalQuantityArray.length; ind++) {
      totalQuantityString +=
        totalQuantityArray[ind].tqty == 0
          ? ''
          : ` ${numberDecimalFormatter(totalQuantityArray[ind].tqty)} 
            `;
    }
    if (totalQuantityString == '') return '0';
    return totalQuantityString;
  };

  const downloadPdf = async () => {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsloading(true);
    const values = form.getFieldsValue();
    await form.validateFields();
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    try {
      // const response =
      values.constraints.customReportQueueName = 'Stock History Report';
      message.info({
        key: 'downloading',
        content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
      });
      await download_report(values.constraints, state.id);
      setIsloading(false);
      // window.open(response.data, '_blank');
    } catch (err: any) {
      setIsloading(false);
      message.error(err.message);
    }
  };

  const addPage = async (value: number, sizeVal = 0) => {
    //check total pages
    try {
      await form.validateFields();
      const allValues = form.getFieldsValue();
      if (sizeVal !== 0) {
        allValues.constraints.page = 1;
        allValues.constraints.size = sizeVal;
        setPage(1);
        setSize(sizeVal);
      } else {
        allValues.constraints.page = value;
        allValues.constraints.size = size;
        setPage(value);
      }
      onSearch(allValues, true);
    } catch (e: any) {
      console.log('validate error', e);
    }
  };

  const downloadReport = async () => {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsloading(true);
    // console.log('Clicked');
    const values = form.getFieldsValue();

    values.constraints['page'] = 1;
    values.constraints['size'] = size;
    setPage(1);
    values.constraints.endDate = convertLocalToUTCString(values.constraints.endDate);
    values.constraints.startDate = convertLocalToUTCString(values.constraints.startDate);
    //remove custom date
    delete values.constraints.to;
    delete values.constraints.from;
    if ('dateCustom' in values.constraints) {
      delete values.constraints.dateCustom;
    }
    await form.validateFields();
    try {
      if (check_export_report_date_cap(values.constraints.startDate, values.constraints.endDate)) {
        // const response =
        values.constraints.customReportQueueName = 'Stock History Report';
        message.info({
          key: 'downloading',
          content: `${values.constraints.customReportQueueName} Added to Queue. Download will start soon.`
        });
        await download_report(values.constraints, 10);
        setIsloading(false);
        // window.open(response.data, '_blank');
      }
    } catch (err: any) {
      setIsloading(false);
      message.error(err.message);
    }
  };

  async function onSearchAll() {
    try {
      setIsloading(true);
      if (!fullExportURL) {
        message.error('No URL specified. Please search first.');
        return;
      }
      const response = await get_report(fullExportURL, state.id);
      response.data = await calculateTotal(response.data);
      return getUpdatedData(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  }

  const updatedData = getUpdatedData(data.data);

  return (
    <div>
      <Spin spinning={isloading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <Collapse>
            <CollapsePanel header="Filters" key="1">
              <Form
                form={form}
                onFinish={onSearch}
                layout="vertical"
                validateTrigger={'onChange'}
                autoComplete="off"
                disabled={isloading}>
                <PageHeader
                  title={state?.name}
                  style={{
                    padding: '8px 0px'
                  }}
                />
                {React.Children.count(children) > 1 && (
                  <>
                    <PageHeader
                      subTitle="Constraints"
                      style={{
                        padding: '8px 0px'
                      }}
                    />
                  </>
                )}
                <div className="grid grid-cols-4 gap-3 ">{children}</div>
                <div className="flex justify-end mt-5 gap-3">
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
                      Search
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="secondary-button"
                      style={{ borderRadius: '6px' }}
                      onClick={() => downloadReport()}>
                      Download
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </CollapsePanel>
          </Collapse>
          <div className="mt-2">
            <Alert
              message="Information!"
              description="Please note, the data is calculated at 00:00 every day. Today's data might not be visible."
              type="info"
              closable
              showIcon
            />
          </div>
          <PageHeader
            subTitle="Table"
            style={{
              padding: '8px 0px'
            }}
          />
          <CustomizeTable
            customScroll={{ x: 2800, y: 500 }}
            notshowPagination
            tableName="stock-history-report-table"
            columns={columnsData}
            data={data.data}
            footer={() =>
              footerTotal.groupAmount ? (
                `Total Amount: ${nepaliNumberFormatter(footerTotal.groupAmount)}, Total Quantity: ${
                  footerTotal.groupQuantity
                }`
              ) : (
                <></>
              )
            }
            tableSummary={
              footerTotal ? (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                      {footerTotal.totalQuantityOpening}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6}>
                      {footerTotal.totalQuantityClosing}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} className="text-center">
                      {footerTotal.totalQuantitySold}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} className="text-center">
                      {footerTotal.totalAvgQuantitySold}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} className="text-center">
                      {footerTotal.totalQuantityReturnedSell}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} className="text-center">
                      {footerTotal.totalAvgQuantityReturnedSell}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} className="text-center">
                      {footerTotal.totalQuantityPurchased}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12} className="text-center">
                      {footerTotal.totalAvgQuantityPurchased}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13} className="text-center">
                      {footerTotal.totalQuantityPurchasedReturned}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14} className="text-center">
                      {footerTotal.totalAvgQuantityPurchasedReturned}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15} className="text-center">
                      {footerTotal.totalQuantityTransferred}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={16} className="text-center">
                      {footerTotal.totalAvgQuantityTransferred}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={17} className="text-center">
                      {footerTotal.totalQuantityTransferredOut}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={18} className="text-center">
                      {footerTotal.totalAvgQuantityTransferredOut}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={19} className="text-center">
                      {footerTotal.totalQuantityAdjusted}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={20} className="text-center">
                      {footerTotal.totalAvgQuantityAdjusted}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={21} className="text-center">
                      {footerTotal.totalQuantityOpeningReceived}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={22} className="text-center">
                      {footerTotal.totalAvgQuantityOpeningReceived}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={23}></Table.Summary.Cell>

                    <Table.Summary.Cell index={24} className="text-center">
                      {nepaliNumberFormatter(parseInt(footerTotal.totalProfit) ?? 0)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              ) : (
                <></>
              )
            }
            buttons={
              <>
                <ExportAllData
                  title="Stock History Report (All)"
                  columns={tableExportColumns}
                  excelColumns={excelExportColumns}
                  getInfo={onSearchAll}
                />
                <ExportCurrent
                  data={{ total: data.count, results: updatedData }}
                  columns={tableExportColumns}
                  excelColumns={excelExportColumns}
                  title="Stock History Report"
                />
              </>
            }
            paginationDatas={{
              page,
              total: data.count,
              size,
              onPagination: (pageNo, currentSize) => {
                currentSize == size ? addPage(pageNo) : addPage(pageNo, currentSize);
              }
            }}
          />
        </AppContent>
      </Spin>
    </div>
  );
};

export default CustomReport;
