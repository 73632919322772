import { useState } from 'react';

const useFilterDrawers = () => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return { visible, showDrawer, onClose };
};

export default useFilterDrawers;
