import { ICreateIRDCredentials, IRDCredentialsType } from '@/services/ird/materialized/types';
import { Form, Input, message, Modal } from 'antd';
import { useEffect } from 'react';
import getErrorMessage from '@/utils/getError';
import { create_ird_credentials } from '@/services/ird/materialized/mutation';

interface Props {
  open: boolean;
  setIrdCredentials: React.Dispatch<React.SetStateAction<IRDCredentialsType | undefined>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  irdCredentials?: IRDCredentialsType;
}

function IRDModal({ open, irdCredentials, setOpen, setIsLoading, setIrdCredentials }: Props) {
  const [form] = Form.useForm<ICreateIRDCredentials>();

  useEffect(() => {
    form.setFieldValue('username', irdCredentials?.username);
  }, [irdCredentials]);

  async function onOK() {
    try {
      const values = await form.validateFields();
      setOpen(false);
      setIsLoading(true);
      const irdData = await create_ird_credentials(values);
      setIrdCredentials(irdData);
      form.resetFields();
      message.success('Credentials added successfully');
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      const isFallBackError = errorMessage.includes('error occurred');
      if (!isFallBackError) message.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal visible={open} onCancel={() => setOpen(false)} onOk={onOK}>
      <Form form={form} layout={'vertical'}>
        <Form.Item
          label="Username"
          name={'username'}
          rules={[{ required: true, message: 'Please provide username' }]}>
          <Input placeholder="Username" />
        </Form.Item>

        <Form.Item
          label="Password"
          name={'password'}
          rules={[
            {
              required: true,
              message: 'Please provide password'
            }
          ]}>
          <Input.Password placeholder="Password" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default IRDModal;
