import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Spin,
  Upload,
  UploadFile,
  UploadProps,
  message
} from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import { API_URL, JWT_TOKEN } from '@/constants/config';
import { update_daily_rate_mutation } from '@/services/daily-rate/mutation';
import { IUpdateDailyRate } from '@/services/daily-rate/types';
import { getLocalStorage } from '@/utils/storage.utils';
import { get_daily_rate_details } from '@/services/daily-rate/queries';
import { get_image } from '@/components/Common/FetchImage/FetchImage';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { onPreviewImage } from '@/services/upload/services';
import { getImagesFromServer } from '@/services/upload/queries';
import getErrorMessage from '@/utils/getError';

const DetailsDailyRate = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [b2bFileList, setB2bFileList] = useState<UploadFile[]>([]);
  const [outletFileList, setOutletFileList] = useState<UploadFile[]>([]);

  const breadcrumbItems = [
    { label: 'Daily Rate', link: '/daily-rate' },
    { label: 'Details', link: '/daily-rate/:id' }
  ];

  const uploadButton = (
    <div className="rounded-full">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onB2bChange: UploadProps['onChange'] = ({ fileList }) => {
    const updatedFileId = fileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('b2bMediaId', updatedFileId);
    }
    setB2bFileList(fileList);
  };

  const onOutletChange: UploadProps['onChange'] = ({ fileList }) => {
    const updatedFileId = fileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('outletMediaId', updatedFileId);
    }
    setOutletFileList(fileList);
  };

  const baseProps: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    accept: 'image/*',
    onPreview: onPreviewImage
  };

  const uploadB2BProps: UploadProps = {
    ...baseProps,
    fileList: b2bFileList,
    onChange: onB2bChange
  };

  const uploadOutletProps: UploadProps = {
    ...baseProps,
    fileList: outletFileList,
    onChange: onOutletChange
  };

  useQuery(['dailyRateDetails', id], async () => await getDailyRateDetails());

  const getDailyRateDetails = async () => {
    setIsLoading(true);
    if (id) {
      const response = await get_daily_rate_details(parseInt(id));
      if (response) {
        const imgIds = [response.b2bMediaId, response.outletMediaId].filter(
          (val) => !!val
        ) as number[];

        const allImages = await getImagesFromServer(imgIds);
        const b2bImageData = allImages.find((val) => val.id === response.b2bMediaId);
        const outletImageData = allImages.find((val) => val.id === response.outletMediaId);

        if (b2bImageData) {
          setB2bFileList([
            { uid: '1', name: b2bImageData.name, status: 'done', url: b2bImageData.url }
          ]);
        }

        if (outletImageData) {
          setOutletFileList([
            { uid: '1', name: outletImageData.name, status: 'done', url: outletImageData.url }
          ]);
        }

        form.setFieldsValue({
          date: moment(response.date),
          b2bMediaId: response.b2bMediaId,
          outletMediaId: response.outletMediaId
        });
      }
    }
    setIsLoading(false);
  };

  const updateDailyRateMutation = useMutation(update_daily_rate_mutation);

  const onFinish = async (values: IUpdateDailyRate) => {
    setIsLoading(true);
    //console.log('Values', values);
    try {
      if (id) {
        values.id = parseInt(id);
        values.date = convertUTCStringtoLocalString(values.date, 'YYYY-MM-DD');
        await updateDailyRateMutation.mutateAsync(values, {
          onSuccess: async ({ data }) => {
            if (data) {
              setIsLoading(false);
              message.success('Daily Rate added successfully');
              navigate('/daily-rate');
            }
          },
          onError: (e: any) => {
            setIsLoading(false);
            message.error(`${e.response.data.message}`, 5);
          }
        });
      }
    } catch (err) {
      setIsLoading(false);
      getErrorMessage(err, true);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader title="Daily Rate Information" style={{ padding: '8px 0px' }} />
          <div className={'grid grid-cols-2 gap-5 mb-5'}>
            <div className="col-span-1">
              <Form.Item
                name={['date']}
                label="Date"
                rules={[{ required: true, message: 'Please choose date!' }]}>
                <DatePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} allowClear={false} />
              </Form.Item>
            </div>
            <div className="col-span-2 flex gap-8">
              <div>
                <p>Upload B2B Price</p>
                <Upload className="rounded-full" {...uploadB2BProps}>
                  {b2bFileList.length >= 1 ? null : uploadButton}
                </Upload>
              </div>
              <Form.Item
                label="B2B Media"
                name="b2bMediaId"
                hidden
                rules={[{ required: false, message: 'Please upload b2b media!' }]}>
                <Input />
              </Form.Item>

              <div>
                <p>Upload Outlet Price</p>
                <Upload className="rounded-full" {...uploadOutletProps}>
                  {outletFileList.length >= 1 ? null : uploadButton}
                </Upload>
              </div>
              <Form.Item
                label="Outlet Media"
                name="outletMediaId"
                hidden
                rules={[{ required: false, message: 'Please upload outlet media!' }]}>
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/daily-rate')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default DetailsDailyRate;
