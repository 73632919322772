import { useContext, useState } from 'react';
import { SellOrderListContext } from './context';
import { ISellFromServer } from '../../../../services/sell/types';
import InvoicePrintButton from '../../../../components/Common/InvoicePrintButton/InvoicePrintButton';
import { CustomModal } from '../../../../components/Common/CustomModal';
import SellInvoice from '../../../../components/Common/InvoicePrint/SellInvoice/SellInvoice';
import { getSellOrderPrintData } from '../../../../components/Common/InvoicePrint/SellInvoice/services';
import { ISellInvoice, ISellRecordData } from '../../../../services/invoice/types';
import { ICreateInvoiceResponse } from '../../../../services/settings/types';

export const SellOrderInvoicePrintModal: React.FC<{
  record: ISellFromServer;
  getInfo: (url: string) => void;
}> = ({ record, getInfo }) => {
  const { setIsLoading, urlData } = useContext(SellOrderListContext);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<ISellInvoice>(Object);

  const generateInvoiceForPrint = async (record: any, curr: ICreateInvoiceResponse) => {
    // console.log('record', record);
    setIsLoading(true);
    const invoiceModalData = await getSellOrderPrintData(record.id, record.locationId);
    setIsLoading(false);

    setInvoiceData({ ...invoiceModalData, invoiceLayouts: curr });

    setOpenModalForInvoicePrint(true);
  };

  const handleInvoicePrintModalClose = () => {
    setOpenModalForInvoicePrint(false);
    getInfo(urlData);
  };

  return (
    <>
      <InvoicePrintButton
        getInvoice={(curr) => {
          generateInvoiceForPrint(record, curr);
        }}
      />
      <CustomModal
        footer={false}
        isModalOpen={openModalForInvoicePrint}
        setIsModalOpen={setOpenModalForInvoicePrint}
        title="Sell Order Invoice Print">
        <SellInvoice
          sellDetails={invoiceData.sellDetails}
          customerDetails={invoiceData.customerDetails}
          lines={invoiceData.lines}
          invoiceLayouts={invoiceData.invoiceLayouts}
          handleModalClose={handleInvoicePrintModalClose}
        />
      </CustomModal>
    </>
  );
};
