import moment from 'moment';
import {
  IAccountHistoryResponse,
  IJournalLinesViewResponseTableData
} from '@/services/accounts/types';
import { optionalDateSorter } from '@/utils/sorter.utils';
import { getSingleHistory } from '@/services/accounts/services';
import getTimeRange from '@/utils/getTimeRange';
import { get_account_journal_lines_credit_debit_sum } from '@/services/accounts/queries';

interface Props {
  data: IJournalLinesViewResponseTableData[];
  accountId: number;
  history: IAccountHistoryResponse[];
  searchParams: URLSearchParams;
  pageNo: number;
  closingBalance: { [key: number]: number };
}

interface PropsV2 extends Props {
  startDate: string;
}

function getOpeningBalanceFromStartDate({
  data,
  accountId,
  history,
  startDate,
  pageNo,
  closingBalance,
  searchParams
}: PropsV2) {
  let openingBalance = 0;
  const tableData = JSON.parse(JSON.stringify(data)) as IJournalLinesViewResponseTableData[];
  tableData.sort((a, b) => optionalDateSorter(a.journalDate, b.journalDate));

  const currentFinancialStart = searchParams.get('fsd') as string;

  // Get 1 day before current financial start
  const currentFinancialHistoryDate = moment(currentFinancialStart)
    .subtract(1, 'days')
    .format('YYYY-MM-DD');

  // Get lowest transaction Date
  const opBalStart = startDate;

  let opBalDate = moment(opBalStart ? opBalStart : tableData[0].journalDate)
    .subtract(1, 'days')
    .format('YYYY-MM-DD');

  // use currentfinancial if opBalDate less than currentfinancial
  if (moment(opBalDate).isBefore(currentFinancialStart)) {
    opBalDate = currentFinancialHistoryDate;
  }

  // Use previous page closing balance as opening balance for the current page
  if (pageNo !== 1) {
    openingBalance = closingBalance[pageNo - 1];
  }

  // get opening balance from history
  if (pageNo === 1) {
    const hasAccount = history.filter(
      (val) => val.date === opBalDate && val.accountId === accountId
    );

    const accountHistory = getSingleHistory(hasAccount, opBalDate);

    // If this account is of type daily, check if type financial_year present at same date
    // if (hasAccount && hasAccount.historyType === 'daily') {
    //   const financialHistory = history.find(
    //     (val) =>
    //       val.date === opBalDate &&
    //       val.accountId === accountId &&
    //       val.historyType === 'financial_year'
    //   );

    //   if (financialHistory) {
    //     hasAccount = financialHistory;
    //   }
    // }

    if (accountHistory) {
      openingBalance = accountHistory.balance;
    } else {
      const selectedOpBal = history
        .filter((val) => val.accountId === accountId)
        .find((val) => val.date < opBalDate && val.date >= currentFinancialHistoryDate);

      if (selectedOpBal) openingBalance = selectedOpBal.balance;
    }

    // const hasHistoryLess = history.find(
    //   (val) => val.date <= opBalDate && val.accountId === accountId
    // );

    // if (!hasHistoryLess) {
    //   openingBalance = 0;
    // }
  }

  return openingBalance;
}

function getOpeningBalance({
  data,
  accountId,
  history,
  pageNo,
  closingBalance,
  searchParams
}: Props) {
  let openingBalance = 0;
  const tableData = JSON.parse(JSON.stringify(data)) as IJournalLinesViewResponseTableData[];
  tableData.sort((a, b) => optionalDateSorter(a.journalDate, b.journalDate));

  const currentFinancialStart = searchParams.get('fsd') as string;

  // Get 1 day before current financial start
  const currentFinancialHistoryDate = moment(currentFinancialStart)
    .subtract(1, 'days')
    .format('YYYY-MM-DD');

  // Get lowest transaction Date
  const opBalStart = tableData.find((val) => val.accountId === accountId)?.journalDate;

  let opBalDate = moment(opBalStart ? opBalStart : tableData[0].journalDate)
    .subtract(1, 'days')
    .format('YYYY-MM-DD');

  // use currentfinancial if opBalDate less than currentfinancial
  if (moment(opBalDate).isBefore(currentFinancialStart)) {
    opBalDate = currentFinancialHistoryDate;
  }

  // Use previous page closing balance as opening balance for the current page
  if (pageNo !== 1) {
    openingBalance = closingBalance[pageNo - 1];
  }

  // get opening balance from history
  if (pageNo === 1) {
    const hasAccount = history.filter(
      (val) => val.date === opBalDate && val.accountId === accountId
    );

    const accountHistory = getSingleHistory(hasAccount, opBalDate);

    // If this account is of type daily, check if type financial_year present at same date
    // if (hasAccount && hasAccount.historyType === 'daily') {
    //   const financialHistory = history.find(
    //     (val) =>
    //       val.date === opBalDate &&
    //       val.accountId === accountId &&
    //       val.historyType === 'financial_year'
    //   );

    //   if (financialHistory) {
    //     hasAccount = financialHistory;
    //   }
    // }

    if (accountHistory) {
      openingBalance = accountHistory.balance;
    } else {
      const selectedOpBal = history
        .filter((val) => val.accountId === accountId)
        .find((val) => val.date < opBalDate && val.date >= currentFinancialHistoryDate);

      if (selectedOpBal) openingBalance = selectedOpBal.balance;
    }

    // const hasHistoryLess = history.find(
    //   (val) => val.date <= opBalDate && val.accountId === accountId
    // );

    // if (!hasHistoryLess) {
    //   openingBalance = 0;
    // }
  }

  return openingBalance;
}

export async function getOpeningBalancev2({ startDate, ...props }: PropsV2) {
  const openingBalance = getOpeningBalanceFromStartDate({ ...props, startDate });

  const timeRange = getTimeRange(startDate);
  if (!timeRange) return openingBalance;

  // const betweenDebitSum = await
  const betweenHistory = await get_account_journal_lines_credit_debit_sum({
    startDate: timeRange.startTime,
    endDate: timeRange.endTime,
    ids: [props.accountId]
  });

  const currentAccountHistory = betweenHistory.results.find(
    (val) => val.accountId === props.accountId
  );

  const debit = currentAccountHistory?.totalDebit || 0;
  const credit = currentAccountHistory?.totalCredit || 0;

  return openingBalance + Number(debit) - Number(credit);
}

export default getOpeningBalance;
