import { Draggable, Droppable } from 'react-beautiful-dnd';
import { IDropBox } from '../../../services/settings/types';
import { Button, Card } from 'antd';
import { ColorPaletteNodes, MenuTypes } from '../../../services/settings/enums';
import { ArrowRightOutlined } from '@ant-design/icons';

export const DropBox: React.FC<IDropBox> = ({ droppableId, type, items, handleReturnBack }) => {
  const DropBoxCardHeader = ({ title, itemKey }: { title: string; itemKey: string }) => {
    return (
      <div className="flex">
        <span className="flex-1 font-bold">{title}</span>
        <Button
          size="small"
          type="primary"
          danger
          style={{ borderRadius: '5px' }}
          onClick={() => {
            if (handleReturnBack) handleReturnBack(itemKey);
          }}>
          <ArrowRightOutlined />
        </Button>
      </div>
    );
  };

  return (
    <Droppable droppableId={droppableId} type={type}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          style={{ minHeight: droppableId === 'MainDroppable' ? '60vh' : '20px' }}>
          {items &&
            items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                {({ draggableProps, dragHandleProps: eventHandlers, innerRef }) => (
                  <div ref={innerRef} {...draggableProps} {...eventHandlers}>
                    <Card
                      size="small"
                      style={{ marginBottom: '5px', borderRadius: '10px' }}
                      bodyStyle={{
                        padding: item.type === MenuTypes.PARENT ? '10px' : '0px',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        backgroundColor:
                          type === '1'
                            ? ColorPaletteNodes.Two
                            : type === '2'
                            ? ColorPaletteNodes.Three
                            : type === '3'
                            ? ColorPaletteNodes.Four
                            : ColorPaletteNodes.Five
                      }}
                      title={
                        <DropBoxCardHeader
                          title={item.alias ? item.alias : item.name}
                          itemKey={item.key}
                        />
                      }>
                      {item.type === MenuTypes.PARENT && (
                        <>
                          <DropBox
                            droppableId={item.key}
                            type={(parseInt(type) + 1).toString()}
                            items={item.children}
                            handleReturnBack={handleReturnBack}
                          />
                        </>
                      )}
                    </Card>
                  </div>
                )}
              </Draggable>
            ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
