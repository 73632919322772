import { useState } from 'react';
import { CustomModal } from '../../../../components/Common/CustomModal';
import ReusableSellView from '../../view/ReusableView';

export const SellViewModal: React.FC<{
  record: any;
}> = ({ record }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);

  return (
    <>
      <a
        onClick={() => {
          setId(record.sellId);
          setIsModalOpen(true);
        }}>
        View
      </a>
      <CustomModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={'Sell View'}
        footer={false}>
        <ReusableSellView id={id} />
      </CustomModal>
    </>
  );
};
