import moment from 'moment';

// Antd imports
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { RangePickerProps } from 'antd/lib/date-picker';

// Custom imports
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { CustomDatePresets } from '@/pages/sqlsource/report/utils/datePresets';

export interface ReportDownloadInitialValues {
  constraints: {
    page: number;
    size: number;
    dateCustom?: moment.Moment[];
    startDate: string;
    endDate: string;
    startDateNepali: string;
    endDateNepali: string;
  };
}
/**
 * This HOC initializes the form with the initial values and provides the onChange and disabledDate functions
 */
export interface WithReportInitializationProps {
  form: FormInstance;
  initialValues: ReportDownloadInitialValues;
  onChange: RangePickerProps['onChange'];
  onDisabledDate: RangePickerProps['disabledDate'];
  onChangeNepali: (val: string, isStart: boolean) => void;
}

function withReportInitialization<T extends WithReportInitializationProps>(
  WrappedComponent: React.ComponentType<T>
) {
  const ReportInitializationWrapper: React.FC<Omit<T, keyof WithReportInitializationProps>> = (
    props
  ) => {
    const [form] = Form.useForm();

    const formInitialValue = {
      constraints: {
        page: 1,
        size: 100000,
        dateCustom: [...CustomDatePresets.Today],
        startDate: CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm'),
        endDate: CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm'),
        startDateNepali: convert_string_to_nepali_date_string(
          CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
        ),
        endDateNepali: convert_string_to_nepali_date_string(
          CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
        )
      }
    };

    const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
      if (dateStrings && dateStrings.length === 2) {
        form.setFieldsValue({
          constraints: {
            ...form.getFieldValue('constraints'),
            startDate: dateStrings[0],
            endDate: dateStrings[1]
          }
        });
      }
    };

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
      return current && current > moment().endOf('day');
    };

    const onChangeNepali = (val: string, isStart: boolean) => {
      const fieldName = isStart ? 'startDate' : 'endDate';
      form.setFieldsValue({
        constraints: {
          ...form.getFieldValue('constraints'),
          [fieldName]: convert_nepali_to_english_date(val)
        }
      });
    };

    return (
      <WrappedComponent
        {...(props as T)}
        initialValues={formInitialValue}
        form={form}
        onChange={onChange}
        onDisabledDate={disabledDate}
        onChangeNepali={onChangeNepali}
      />
    );
  };

  return ReportInitializationWrapper;
}

export default withReportInitialization;
