import { Button, Divider, Form, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import { get_category_details, get_category_list } from '../../../services/category/queries';
import { ICategory } from '../../../services/category/types';
import useDebounce from '../../../hooks/useDebounce';

interface IProductCategoryProps {
  formData?: { formName?: string | string[]; formLabel?: string };
  onProductCategoryChange?: (val: number | string | null, name?: number) => void;
  onClearProduct?: (name?: number) => void;
  isAll?: boolean;
  showDefault?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
  required?: boolean;
  setValue?: string | number | null;
  name?: number;
}

const { Option } = Select;

const ProductCategorySearch: React.FC<IProductCategoryProps> = ({
  formData,
  isAll = true,
  disabled = false,
  allowClear = false,
  required = true,
  showDefault = true,
  onProductCategoryChange,
  onClearProduct,
  setValue,
  name
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);
  const [productCategoryList, setProductCategoryList] = useState<ICategory[]>([]);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchProductCategory(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const searchProductCategory = async (value: string) => {
    let response;
    try {
      response = (await get_category_list(0, 10, value)).data;
      if (setValue && typeof setValue === 'number') {
        const setResponse = await get_category_details(setValue);
        if (!response.results.some((el) => el.id === setResponse.id)) {
          response.results = [...response.results, setResponse];
        }
      }
      if (!response || response.results.length == 0) {
        message.error('Cannot find product category with that name!');
        setProductCategoryList([]);
        setIsMore(false);
      } else {
        setProductCategoryList(response.results);

        if (response.results.length < 10) {
          setIsMore(false);
        } else {
          setIsMore(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setSkip(10);
  };

  const searchMoreProductCategory = async (value: string) => {
    let response;
    try {
      response = (await get_category_list(skip, 10, value)).data;
      response.results.filter((val) => val.id === setValue);
      if (!response || response.results.length == 0) {
        message.info('Cannot find more product category with that value in server!');
        setIsMore(false);
      } else {
        setSkip(skip + 10);
        setProductCategoryList([...productCategoryList, ...response.results]);

        if (response.results.length < 10) {
          setIsMore(false);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const options = productCategoryList.map((value) => (
    <Option key={value.id} value={value.id}>
      {value.name}
    </Option>
  ));

  return (
    <>
      {formData ? (
        <Form.Item
          name={formData.formName}
          label={formData.formLabel}
          rules={[
            {
              required: required,
              message: 'Please add a Product Category!'
            }
          ]}>
          <Select
            showSearch
            disabled={disabled}
            allowClear={allowClear}
            placeholder="Select a category"
            defaultActiveFirstOption={false}
            showArrow={false}
            autoClearSearchValue={true}
            filterOption={false}
            dropdownMatchSelectWidth={false}
            value={setValue ? setValue : null}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  {isMore ? (
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchMoreProductCategory(searchValue);
                      }}>
                      {/* {'Sync & Search on Server'} */}
                      {'Get More...'}
                    </Button>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center'
                      }}>
                      No more data...
                    </div>
                  )}
                </div>
              </>
            )}>
            {isAll ? <Option value="">All</Option> : null}
            {showDefault ? <Option value="default">Fruits/Vegetables</Option> : null}
            {options}
          </Select>
        </Form.Item>
      ) : (
        <Select
          showSearch
          disabled={disabled}
          allowClear={allowClear}
          placeholder="Select a category"
          defaultActiveFirstOption={false}
          showArrow={false}
          autoClearSearchValue={true}
          filterOption={false}
          dropdownMatchSelectWidth={false}
          value={setValue ? setValue : null}
          onSearch={(val) => {
            setSearchValue(val);
          }}
          onSelect={(val: string | number) => {
            if (onProductCategoryChange) {
              if (name !== undefined) {
                onProductCategoryChange(val, name); //this is for procruement create
              } else {
                onProductCategoryChange(val);
              }
            }
          }}
          onClear={() => {
            if (onClearProduct) {
              if (name !== undefined) {
                onClearProduct(name); //this is for procruement create
              } else {
                onClearProduct();
              }
            }
          }}
          notFoundContent={null}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                {isMore ? (
                  <Button
                    type="text"
                    style={{
                      color: 'blue',
                      width: '100%'
                    }}
                    onClick={() => {
                      searchMoreProductCategory(searchValue);
                    }}>
                    {/* {'Sync & Search on Server'} */}
                    {'Get More...'}
                  </Button>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center'
                    }}>
                    No more data...
                  </div>
                )}
              </div>
            </>
          )}>
          {isAll ? <Option value="all">All</Option> : null}
          {showDefault ? <Option value="default">Fruits/Vegetables</Option> : null}
          {options}
        </Select>
      )}
    </>
  );
};

export default ProductCategorySearch;
