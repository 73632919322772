import moment from 'moment';

function groupByDate(datas: any[], key: string) {
  const datesWithData = datas.reduce((groups, data) => {
    const date = moment(data[key]).format('YYYY-MM-DD');
    if (!groups[date]) {
      groups[date] = [];
    }

    groups[date].push(data);
    return groups;
  }, {});

  // Sort Data by Date
  const sortedData = Object.keys(datesWithData).sort((a, b) => {
    return new Date(a).getTime() - new Date(b).getTime();
  });

  return sortedData.reduce((acc, date) => {
    acc[date] = datesWithData[date];
    return acc;
  }, {} as Record<string, any[]>);
}

export function groupByTime(datas: any[], key: string) {
  const groupedData = datas.reduce((groups, data) => {
    const date = new Date(data[key]);
    const hours = date.getHours().toString().padStart(2, '0'); // Ensure 2-digit format
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure 2-digit format
    const timeKey = `${hours}:${minutes}`;

    if (!groups[timeKey]) {
      groups[timeKey] = [];
    }

    groups[timeKey].push(data);
    return groups;
  }, {});

  const sortedKeys = Object.keys(groupedData).sort((a, b) => {
    const [hoursA, minutesA] = a.split(':').map(Number);
    const [hoursB, minutesB] = b.split(':').map(Number);

    if (hoursA === hoursB) {
      return minutesA - minutesB;
    }
    return hoursA - hoursB;
  });

  const sortedGroupedData: Record<string, any[]> = {};
  sortedKeys.forEach((key) => {
    sortedGroupedData[key] = groupedData[key];
  });

  return sortedGroupedData;
}

export default groupByDate;
