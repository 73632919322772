import { useState } from 'react';
import { Form, message, Modal, Spin } from 'antd';

import Table from './Table';
import Filter from './Filter';
import { ConvertObjectToURL } from '@/utils/converturl';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_agents_list } from '@/services/agents/queries';
import { IAgentsTableProps } from '@/services/agents/types';
import AppContent from '@/components/Common/Content/Content';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { get_location_list_for_ids } from '@/services/locations/queries';
import { getUserData } from '@/utils/auth.utils';
import { update_agents_mutation } from '@/services/agents/mutation';

function AgentList() {
  const [form] = Form.useForm();
  const [isDisbled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });
  const [data, setData] = useState<{ total: number; agents: IAgentsTableProps[] }>({
    total: 0,
    agents: []
  });

  const [selectedAgentId, setSelectedAgentId] = useState<number | null>(null);
  const [agentStatus, setAgentStatus] = useState<'enable' | 'disable'>('disable');

  const currentUser = getUserData();

  const breadcrumbItem = [
    { label: 'Users', link: '/users' },
    { label: 'Agents', link: '/users/agents' }
  ];

  async function getAgents(vals: string) {
    try {
      setIsLoading(true);
      const { data } = await get_agents_list(vals);
      const agentsPromise = data.results.map(async (agent, index) => {
        const locationId = agent.locationId;

        // Get location details
        let locationDetails = await LocationsDB.getLocation(locationId);
        if (!locationDetails) {
          const allLocations = await get_location_list_for_ids([locationId]);
          await LocationsDB.addLocations(allLocations);
          locationDetails = await LocationsDB.getLocation(locationId);
        }

        return {
          sn: index + 1,
          id: agent.id,
          name: agent.user.name,
          email: agent.user.email,
          location: locationDetails?.name,
          locationId,
          createdAt: convertUTCStringtoLocalString(agent.createdAt),
          isDisabled: agent.isDisabled
        };
      });

      const agents = await Promise.all(agentsPromise);
      setData({ total: data.total, agents });
    } finally {
      setIsLoading(false);
    }
  }

  async function onPagination(page = 1, size = 100, isSize = false) {
    const values = form.getFieldsValue();

    values.startDate = convertLocalToUTCString(values.startDate);
    values.endDate = convertLocalToUTCString(values.endDate);

    values.skip = isSize ? 0 : (page - 1) * size;
    values.count = size;

    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;

    setPagination((prev) => {
      prev.page = page;
      if (isSize) prev.size = size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getAgents(url);
  }

  async function toggleAgentStatus() {
    setIsLoading(true);
    const agentLists = [...data.agents];

    if (selectedAgentId === null) {
      setIsLoading(false);
      setIsDisabled(false);
      return message.error(`No agent selected. Please specify which agent to ${agentStatus}d`);
    }

    const agentIndex = agentLists.findIndex((agent) => agent.id === selectedAgentId);

    if (agentIndex < 0) return;
    const agent = agentLists[agentIndex];

    try {
      // do optimistic update
      agentLists.splice(agentIndex, 1);

      // Make an API Call and display success message
      await update_agents_mutation({
        id: selectedAgentId,
        isDisabled: agentStatus === 'disable',
        locationId: agent.locationId
      });
      message.success(`User ${agentStatus}d successfully`);
    } catch (error) {
      // show error message and revert the optimistic update
      message.error(`Failed to ${agentStatus} user`);
      agentLists.splice(agentIndex, 0, agent);
    } finally {
      setData({ ...data, agents: agentLists });
      setIsLoading(false);
      setIsDisabled(false);
    }
  }

  function onClickDisabled(value: IAgentsTableProps) {
    const isSameUser = currentUser?.id === value.id;
    if (isSameUser) {
      message.error('You cannot disable yourself');
      return;
    }

    setSelectedAgentId(value.id);
    setAgentStatus(value.isDisabled ? 'enable' : 'disable');
    setIsDisabled(true);
  }

  return (
    <Spin spinning={isLoading}>
      <Modal
        title="Confirmation"
        visible={isDisbled}
        onOk={toggleAgentStatus}
        onCancel={() => {
          setIsDisabled(false);
          setSelectedAgentId(null);
        }}>
        <div>Are you sure you want to {agentStatus} this agent?</div>
      </Modal>
      <AppContent
        breadcrumbItems={breadcrumbItem}
        button={<Filter form={form} onSubmit={getAgents} />}>
        <Table
          data={data.agents}
          onClickDisabled={onClickDisabled}
          pagination={{
            onPagination,
            page: pagination.page,
            size: pagination.size,
            total: data.total
          }}
        />
      </AppContent>
    </Spin>
  );
}

export default AgentList;
