import { CompareType } from '@/services/dashboard/v3/enums';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Form, FormInstance, Select, Tooltip } from 'antd';
import RangeCompareUI from './ui/RangeCompareUI';
import CustomSingleRangeUI from './ui/CustomSingleRangeUI';
import SingleDateCompareUI from './ui/SingleDateCompareUI';
import CustomBothRange from './ui/CustomBothRange';
import { generateAlertMessageForFilter } from '../queries';

interface Props {
  form: FormInstance;
  compareType: CompareType;
}

function WidgetComparableUI({ compareType, form }: Props) {
  const msg = generateAlertMessageForFilter(compareType);

  return (
    <>
      {msg && (
        <div className="mb-2">
          <Alert message={msg} />
        </div>
      )}

      <div className="flex gap-2 items-center">
        <Form.Item
          label="Compare Type"
          name={'compareType'}
          rules={[{ required: true }]}
          className="flex-1">
          <Select className="w-full">
            <Select.Option value={CompareType.CUSTOM_RANGE}>
              Custom Range for specific
            </Select.Option>
            <Select.Option value={CompareType.RANGE_COMPARE}>Range Compare</Select.Option>
            <Select.Option value={CompareType.SINGLE_COMPARE}>Single Date Compare</Select.Option>
            <Select.Option value={CompareType.CUSTOM_BOTH_RANGE}>Compare Both Range</Select.Option>
          </Select>
        </Form.Item>

        <Tooltip
          autoAdjustOverflow
          placement="right"
          overlayStyle={{ maxWidth: 450 }}
          title={
            <div>
              <p>
                <strong>Custom Range:</strong> View data within a specified range. For example,
                select a range and a product to see data within that range for that product.
              </p>
              <p>
                <strong>Range Compare:</strong> Compare ranges such as the last 30 days to the
                previous 30 days. Select a range, and the system will calculate the corresponding
                previous range for comparison.
              </p>
              <p>
                <strong>Single Date Compare:</strong> Compare data from one specific date to
                another.
              </p>
              <p>
                <strong>Compare Both Range:</strong> Compare data within two specified ranges of
                your choice.
              </p>
            </div>
          }>
          <InfoCircleOutlined className="cursor-pointer [&>svg]:-mt-1" />
        </Tooltip>
      </div>

      {compareType === CompareType.RANGE_COMPARE && <RangeCompareUI form={form} />}
      {compareType === CompareType.CUSTOM_RANGE && (
        <CustomSingleRangeUI form={form} compareType={compareType} />
      )}
      {compareType === CompareType.SINGLE_COMPARE && (
        <SingleDateCompareUI form={form} compareType={compareType} />
      )}

      {compareType === CompareType.CUSTOM_BOTH_RANGE && (
        <CustomBothRange form={form} compareType={compareType} />
      )}
    </>
  );
}

export default WidgetComparableUI;
