import { Button, Modal, Tooltip } from 'antd';
import React, { useState } from 'react';
import ReuseChannel from '../../../pages/channel/Reuse';
import { WechatOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const DiscussionChatModal = ({
  slug,
  id,
  fromUser,
  fromSellorPurahcaseid = '',
  rreference
}: {
  slug: string;
  id: number;
  fromUser?: boolean;
  fromSellorPurahcaseid?: string;
  rreference?: string;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {/* <Link to="#"> */}
      <Tooltip title="Start Discussion" color="blue">
        {fromUser ? (
          <div className="flex justify-between gap-3" onClick={showModal}>
            <WechatOutlined
              style={{ transform: 'scale(1.5)', display: 'flex', alignItems: 'center' }}
            />
            <div>Chat</div>
          </div>
        ) : (
          <WechatOutlined style={{ transform: 'scale(1.5)', width: '105%' }} onClick={showModal} />
        )}
      </Tooltip>
      {/* </Link> */}
      <Modal
        title="Chat Room"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}>
        <ReuseChannel
          slug={`${slug}_${id}`}
          isButton={false}
          withReferenceandId={{
            reference: rreference ? rreference : slug,
            referenceId: id
          }}
          fromSellorPurchaseId={fromSellorPurahcaseid}
        />
      </Modal>
    </>
  );
};

export default DiscussionChatModal;
//onOk={handleOk} onCancel={handleCancel}
