import { get_report_list } from '@/services/report/queries';
import LocalStore from '.';
import { IReport } from '@/services/report/types';
import getErrorMessage from '@/utils/getError';

function getTransactionError(event: Event, reject: (reason?: any) => void, type: string) {
  const instanceOfIndexDB = event.target instanceof IDBRequest;
  if (instanceOfIndexDB) {
    reject(event.target.error);
    return;
  }

  reject(new Error(`Failed to ${type} reports on indexDB`));
}

export class ReportsDB extends LocalStore {
  static async addReports(reportsData: IReport[]) {
    if (!ReportsDB.db) await ReportsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = ReportsDB.db.transaction('Reports', 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'add');

      const objectStore = transaction.objectStore('Reports');
      reportsData.forEach((report) => {
        const checkKey = objectStore.count(report.id);
        checkKey.onerror = (event) => getTransactionError(event, reject, 'add');

        checkKey.onsuccess = async () => {
          if (checkKey.result == 0) {
            const request = objectStore.add(report);
            request.onerror = (event) => getTransactionError(event, reject, 'add');
          } else {
            await ReportsDB.updateReport(report);
          }
        };
      });
    });
  }

  static async updateReport(report: IReport) {
    if (!ReportsDB.db) await ReportsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = ReportsDB.db.transaction('Reports', 'readwrite');
      transaction.oncomplete = () => resolve('success');
      transaction.onerror = (event) => getTransactionError(event, reject, 'update');

      const objectStore = transaction.objectStore('Reports');
      const request = objectStore.get(report.id);
      request.onerror = (event) => getTransactionError(event, reject, 'update');
      request.onsuccess = (event) => {
        if ((event.target as IDBRequest).result) {
          const requestUpdate = objectStore.put(report);
          requestUpdate.onerror = (event) => getTransactionError(event, reject, 'update');
          requestUpdate.onsuccess = () => resolve('updated data');
        } else reject('Error, could not find id');
      };
    });
  }

  static async getAllReports(): Promise<IReport[]> {
    if (!ReportsDB.db) await ReportsDB.init();

    return new Promise((resolve, reject) => {
      const transaction = ReportsDB.db.transaction('Reports', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'get');

      const objectStore = transaction.objectStore('Reports');
      const request = objectStore.getAll();
      request.onerror = (event) => getTransactionError(event, reject, 'get');
      request.onsuccess = async (event) => {
        const reports = (event.target as IDBRequest).result || [];
        if (reports && reports.length > 0) {
          resolve(reports);
        } else {
          const reportLists = await get_report_list();
          await ReportsDB.addReports(reportLists);
          resolve(reportLists);
        }
      };
    });
  }

  static async getReportById(id: number | string): Promise<IReport | undefined> {
    if (!ReportsDB.db) await ReportsDB.init();
    return new Promise((resolve, reject) => {
      const transaction = ReportsDB.db.transaction('Reports', 'readonly');
      transaction.onerror = (event) => getTransactionError(event, reject, 'get');

      const objectStore = transaction.objectStore('Reports');
      const request = objectStore.get(id);

      request.onerror = (event) => getTransactionError(event, reject, 'get');
      request.onsuccess = async (event) => {
        const report = (event.target as IDBRequest).result;
        if (report) {
          resolve(report);
        } else {
          // Make an API call to fetch the report by ID if not found in IndexedDB
          try {
            const reportFromApi = await get_report_list();
            await ReportsDB.addReports(reportFromApi);
            const report = reportFromApi.find((report) => report.id === id);
            resolve(report);
          } catch (error) {
            console.log(getErrorMessage(error));
            resolve(undefined);
          }
        }
      };
    });
  }
}
