import { Button, Divider, Form, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import ExpenseCategoryDB from '../../../store/localstorage/ExpenseCategoryDB';
import { get_expense_category_list_v2 } from '../../../services/expense/expense-category/queries';

import useDebounce from '../../../hooks/useDebounce';

interface ExpenseCategorySearchProps {
  formData?: {
    formName: string;
    label: string;
  };
  isAll?: boolean;
  required?: boolean;
  onSelect?: (value: number) => any;
  allowClear?: boolean;
}

type SearchType = 'initial' | 'more';

export const ExpenseCategorySearch: React.FC<ExpenseCategorySearchProps> = ({
  formData,
  isAll = true,
  required = false,
  onSelect,
  allowClear = true
}) => {
  const [skip, setSkip] = useState(0);
  const [isMore, setIsMore] = useState(true);

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const [categorySearch, setCategorySearch] = useState<any>([]);
  const [isCacheResponse, setIsCacheResponse] = useState(false);

  useEffect(() => {
    searchIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);

  async function searchIndexDB(value: string) {
    try {
      const category = await ExpenseCategoryDB.search(value);
      if (!category || category.length == 0) {
        message.error('Cannot find any category with that value in cache, searching in server...');
        searchCategory(value, 'initial');
        return;
      }

      setCategorySearch(category);
      setIsCacheResponse(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function searchCategory(value: string, searchType: SearchType) {
    try {
      const currentSkip = searchType === 'initial' ? 0 : skip;

      const { data } = await get_expense_category_list_v2(currentSkip, 10, value);
      const isValidResponse = data && data.results.length > 0;
      if (!isValidResponse) {
        message.error('Cannot find any category with that name!');
        setIsMore(false);
        searchType === 'initial' && setCategorySearch([]);
      }

      if (isValidResponse) {
        searchType === 'initial'
          ? setCategorySearch(data.results)
          : setCategorySearch((prev: any) => [...prev, ...data.results]);

        ExpenseCategoryDB.add(data.results as any[]);
        setIsMore(data.results.length >= 10);

        searchType === 'more' && setSkip(currentSkip + 10);
      }

      if (searchType === 'initial') {
        setIsCacheResponse(false);
        setSkip(10);
      }
    } catch (error) {
      message.error(error as string);
    }
  }

  const options = categorySearch?.map((value: any) => {
    return (
      <Select.Option key={value.id} value={value.id}>
        {value.name}
      </Select.Option>
    );
  });

  const selectMenu = (
    <Select
      showSearch
      allowClear={allowClear}
      filterOption={false}
      placeholder="Search expense category"
      defaultActiveFirstOption={false}
      onSearch={(val) => setSearchValue(val)}
      notFoundContent={<div>No Content</div>}
      onChange={onSelect}
      dropdownMatchSelectWidth={false}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            {isCacheResponse ? (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                <Button
                  type="text"
                  style={{ color: 'blue', width: '100%' }}
                  onClick={() => searchCategory(searchValue, 'initial')}>
                  {'Pull More & Sync'}
                </Button>
              </div>
            ) : (
              <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                {isMore ? (
                  <Button
                    type="text"
                    style={{ color: 'blue', width: '100%' }}
                    onClick={() => searchCategory(searchValue, 'more')}>
                    {'Get More...'}
                  </Button>
                ) : (
                  <div style={{ width: '100%', textAlign: 'center' }}>No more data...</div>
                )}
              </div>
            )}
          </>
        );
      }}>
      {isAll ? <Select.Option value="">All</Select.Option> : null}
      {options}
    </Select>
  );

  if (!formData) return selectMenu;
  return (
    <Form.Item
      label={formData.label || 'Expense Category'}
      name={formData.formName || 'categoryId'}
      rules={[{ required, message: 'Please select category!' }]}>
      {selectMenu}
    </Form.Item>
  );
};
