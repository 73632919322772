import { createContext } from 'react';

interface IPosSessionContext {
  isSessionStart: boolean;
  setIsSessionStart: React.Dispatch<React.SetStateAction<boolean>>;
}

const PosSessionContext = createContext<Partial<IPosSessionContext>>({ isSessionStart: false });

const PosSessionProvider = ({
  children,
  isSessionStart,
  setIsSessionStart
}: {
  children: React.ReactNode;
  isSessionStart: boolean;
  setIsSessionStart: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <PosSessionContext.Provider value={{ isSessionStart, setIsSessionStart }}>
      {children}
    </PosSessionContext.Provider>
  );
};

export { PosSessionContext, PosSessionProvider };
