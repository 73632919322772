import CustomSearch, { SelectProps } from '..';
import { AgentsDB } from '@/store/localstorage/AgentsDB';
import { get_agents_list_only } from '@/services/agents/queries';
import { IUserAgents } from '@/services/agents/types';

type MultipleProps =
  | {
      isMultiple?: false;
      defaultValue?: number;
      setSelected?: (selected?: IUserAgents) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      isMultiple: true;
      defaultValue?: number[];
      setSelected?: (selected?: IUserAgents[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps &
  MultipleProps & {
    returnType?: 'agentId' | 'userId';
  };

function AgentSearchV2({ returnType = 'agentId', ...props }: Props) {
  const path =
    returnType === 'agentId'
      ? { value: 'id', name: 'user.name' }
      : { value: 'userId', name: 'user.name' };

  return (
    <CustomSearch
      path={path}
      formFor="agent"
      dbSearchById={AgentsDB.getAgent}
      addCallback={AgentsDB.addAgents}
      serverCallback={get_agents_list_only}
      dbSearchCallback={AgentsDB.searchAgent}
      {...props}
    />
  );
}

export default AgentSearchV2;
