import { Tabs } from 'antd';
import React from 'react';
import AppContent from '../../../components/Common/Content/Content';

import InvoiceLayouts from './Layouts/List';
import InvoiceSchemes from './Schemes';

const Invoices: React.FC = () => {
  const breadcrumbItems = [
    {
      label: 'Invoices',
      link: '/invoices'
    }
  ];

  const onChange = (key: string) => {
    // console.log(key);
  };
  return (
    <AppContent breadcrumbItems={breadcrumbItems}>
      <Tabs defaultActiveKey="schemes" onChange={onChange}>
        <Tabs.TabPane tab="Invoice Schemes" key="schemes">
          <InvoiceSchemes />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Invoice Layouts" key="layouts">
          <InvoiceLayouts />
        </Tabs.TabPane>
      </Tabs>
    </AppContent>
  );
};

export default Invoices;
