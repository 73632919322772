import { IProductRateCompare } from '@/services/report/types';
import roundNumber from '@/utils/round.utils';

export const generateTableColumns = (selectedDate: [string, string]) => {
  return [
    {
      label: 'Product',
      dataIndex: 'product',
      render: (text: string) => <div>{text}</div>
    },
    {
      label: 'Unit',
      dataIndex: 'unit',
      render: (text: string) => <div>{text}</div>
    },
    {
      label: `Price (${selectedDate[0] || 'First Date'})`,
      dataIndex: 'priceFirstDate',
      render: (text: string) => <div className="text-center">{text}</div>
    },
    {
      label: `Price (${selectedDate[1] || 'Second Date'})`,
      dataIndex: 'priceSecondDate',
      render: (text: string) => <div className="text-center">{text}</div>
    },
    {
      label: 'Difference',
      dataIndex: 'difference',
      render: (text: number) => (
        <div
          className={`text-center ${text > 0 ? 'text-green-500' : text < 0 ? 'text-red-500' : ''}`}>
          {text}
        </div>
      )
    }
  ];
};

export const getUpdatedValues = (data: IProductRateCompare[]) => {
  return data.map((item) => {
    return {
      ...item,
      priceFirstDate: roundNumber(item.priceFirstDate),
      priceSecondDate: roundNumber(item.priceSecondDate)
    };
  });
};
