import { Button, Menu, Tooltip, message } from 'antd';
import ActionDropdown from '../Dropdownactions';
import { CustomModal } from '../CustomModal';
import CustomTable, { IColumnDataForCustomTable } from '../CustomResuableInvoice/CustomTable';
import { ExportColumnType, exportExcel } from '../../../utils/exportExcel';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { AxiosResponse } from 'axios';
import CustomErrorModal from '../CustomErrorModal';

interface IExportAll {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  columnsForExport: ExportColumnType[];
  columsforPrint: IColumnDataForCustomTable[];
  title: string;
  getInfo: (filter: string, forExportAll: boolean) => Promise<any>;
  allExportUrl: string;
}

export const ReusableExportAll: React.FC<IExportAll> = ({
  setIsLoading,
  columnsForExport,
  columsforPrint,
  title,
  getInfo,
  allExportUrl
}) => {
  const [allExportData, setAllExportData] = useState<any>({});
  const [openmodalforExport, setOpenModalforexport] = useState<boolean>(false);
  const printPDFRef = useRef<HTMLDivElement>(null);

  const handleExport = (exportData: any) => {
    setIsLoading(true);
    try {
      const columns: ExportColumnType[] = columnsForExport;

      if (exportData.length === 0) {
        message.error('No Data to Export');
        return;
      }
      exportExcel(columns, exportData, title);
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  const handleExcelClick = async () => {
    try {
      const exportData = await getInfo(allExportUrl, true);

      if (exportData) {
        if ('results' in exportData) {
          setAllExportData(exportData.results);
        } else if ('result' in exportData) {
          setAllExportData(exportData.result);
        } else {
          throw {
            name: 'FetchError',
            message: 'Error Fetching Data!'
          };
        }
      }
      handleExport(exportData.results);
    } catch (errors: any) {
      setIsLoading(false);
      if ('name' in errors) {
        CustomErrorModal({
          title: 'Error!',
          message: errors.message
        });
      } else {
        CustomErrorModal({
          title: 'Error!',
          message: errors.message
        });
      }
    }
  };

  const handlePdfClick = async () => {
    try {
      const exportData = await getInfo(allExportUrl, true);
      if (exportData) {
        if ('results' in exportData) {
          setAllExportData(exportData.results);
        } else if ('result' in exportData.data) {
          setAllExportData(exportData.result);
        } else {
          throw {
            name: 'FetchError',
            message: 'Error Fetching Data!'
          };
        }
      }
      setOpenModalforexport(true);
    } catch (errors: any) {
      setIsLoading(false);
      if ('name' in errors) {
        CustomErrorModal({
          title: 'Error!',
          message: errors.message
        });
      } else {
        CustomErrorModal({
          title: 'Error!',
          message: errors.message
        });
      }
    }
  };

  return (
    <>
      <div className="flex justify-end ">
        <div>
          <ActionDropdown
            button={true}
            buttonJSX={<Button className="secondary-button !rounded-md">Export All</Button>}
            text="Export All"
            menu={
              <Menu
                items={[
                  {
                    key: '1',
                    label: (
                      <Tooltip title="Export Excel" color="blue">
                        <div className="text-center">Excel</div>
                      </Tooltip>
                    ),
                    onClick: async () => {
                      await handleExcelClick();
                    }
                  },
                  {
                    key: '2',
                    label: (
                      <Tooltip title="Export PDF" color="blue">
                        <div className="text-center">PDF</div>
                      </Tooltip>
                    ),
                    onClick: async () => {
                      await handlePdfClick();
                    }
                  }
                ]}
              />
            }
          />
        </div>
      </div>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title={title}>
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={allExportData}
            reff={printPDFRef}
            title={title}
            onAuto={handlePDFExport}
          />
        </div>
      </CustomModal>
    </>
  );
};
