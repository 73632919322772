import sanitizeHtml from 'sanitize-html';

export function cleanHTML(html: string) {
  const headerTag = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  const styleTag = ['em', 'u', 'strong', 's', 'b'];
  const listTag = ['ul', 'ol', 'li'];
  const wrapperTag = ['div', 'span', 'p', 'blockquote'];

  const data = sanitizeHtml(html, {
    allowedTags: ['a', 'br', 'sub', 'sup', ...wrapperTag, ...headerTag, ...styleTag, ...listTag],
    allowedAttributes: {
      a: ['style', 'class', 'target', 'href'],
      '*': ['style', 'class']
    },
    parseStyleAttributes: false,
    enforceHtmlBoundary: false
  });

  if (data === '<p><br /></p>') return '';
  return data;
}
