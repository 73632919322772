import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getUserData } from '../../../utils/auth.utils';
import { convertUTCStringtoLocalString } from '../../../utils/convertToUTC';
import { convert_string_to_nepali_date_string } from '../../../utils/nepaliDateConverter';
import CustomTable from '../CustomResuableInvoice/CustomTable';
import CustomTableForSell from '../SellInvoice/CustomTableForSell';
import CustomTableForSellReturn from './CustomTableForSellReturn';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import { get_product_list } from '../../../services/products/queries';
import { find_invoice_number } from '../../../store/localstorage/preferences';

const columns: any = [
  {
    dataIndex: 'name',
    label: 'ProductName'
  },
  {
    dataIndex: 'quantity',
    label: 'Quantity'
  },
  {
    dataIndex: 'amount',
    label: 'Amount'
  }
];

const SellReturnInvoice = () => {
  const loggedInUser = getUserData();
  const state = JSON.parse(localStorage.getItem('referrer') as string);
  const [data, setData] = useState({
    linesData: [],
    taxable: 0,
    nonTaxable: 0,
    vat: 0,
    printMode: false
  });
  const {
    customerDetails,
    vendorDetails,
    locationDetails,
    purchaseDetails,
    invoiceLayouts,
    createdByDetails,
    firstCreatedByUserDetails,
    routeDetails,
    printCount,
    printLocation,
    lines
  } = state;
  let content;
  if (invoiceLayouts?.content) {
    content = JSON.parse(invoiceLayouts.content);
  }

  console.log('state', state);

  useEffect(() => {
    getData();
    if (data.printMode) {
      window.print();
      window.onafterprint = (event) => {
        window.close();
        localStorage.removeItem('referrer');
      };
    }
  }, [data]);

  const getData = async () => {
    if (lines.length > 0) {
      let taxableAmt = 0;
      let nonTaxableAmt = 0;
      let vatAmt = 0;
      for (let index = 0; index < lines.length; index++) {
        const productDetails = await ProductsDB.getProduct(lines[index].productId);
        if (!productDetails) {
          const allProducts = await get_product_list();
          await ProductsDB.addProducts(allProducts.data.results);
        }
        if (typeof productDetails === 'object' && productDetails.vat != undefined) {
          const vat = lines[index].unitPrice * lines[index].quantity * (productDetails.vat / 100);
          lines[index].vat = vat;
          if (vat > 0) {
            taxableAmt += lines[index].totalAmount - vat;
            vatAmt += vat;
          } else {
            nonTaxableAmt += lines[index].totalAmount;
          }
        }
      }
      setData({
        linesData: lines,
        taxable: taxableAmt,
        nonTaxable: nonTaxableAmt,
        vat: vatAmt,
        printMode: true
      });
    }
  };

  return (
    <div style={{ color: 'black' }}>
      <div className="text-center mt-3">
        <div className="font-bold text-xl">
          {content?.companyName ? content.companyName : 'FreshKtm Agro Pvt. Ltd.'}
        </div>
        <div>{printLocation?.name ? printLocation?.name : ''}</div>
        <div className="font-bold text-sm">
          {printCount === 0 && purchaseDetails?.fromPurchase
            ? 'Purchase Return Invoice'
            : printCount === 0
            ? 'Credit Note'
            : 'Copy of Original ' + printCount}
        </div>
        <div className="font-bold text-sm">VAT No.: {find_invoice_number()}</div>
      </div>
      <div className="flex justify-between items-center mt-3 px-8">
        <div>
          {purchaseDetails.financialReference ? (
            <>
              <span>{'Invoice No.'}</span> :{' '}
              <span className="italic font-bold">{purchaseDetails.financialReference} </span>
            </>
          ) : (
            <></>
          )}
          <div>
            <span>{'Ref No.'}</span> :{' '}
            <span className="italic font-bold">{purchaseDetails?.referenceNumber}</span>
          </div>
          <div>
            {purchaseDetails?.fromPurchase ? (
              <span>{content?.vendorNameLabel ? content.vendorNameLabel : 'Supplier Name'}</span>
            ) : (
              <span>
                {content?.customerNameLabel ? content.customerNameLabel : 'Customer Name'}
              </span>
            )}
            : <span className="font-bold uppercase">{customerDetails.name}</span>
          </div>
          <div>
            {purchaseDetails?.fromPurchase ? (
              <span>
                {content?.vendorAddressLabel ? content.vendorAddressLabel : 'Supplier Address'}
              </span>
            ) : (
              <span>
                {content?.customerAddressLabel ? content.customerAddressLabel : 'Customer Address'}
              </span>
            )}
            :{' '}
            <span className="font-bold uppercase">
              {locationDetails.address} {locationDetails.city},{locationDetails.country}
            </span>
          </div>
          <div>
            <span>{content?.routeNameLabel ? content.routeNameLabel : 'Route'}</span>:{' '}
            <span className="font-bold uppercase">{customerDetails?.routeName}</span>
          </div>
          <div>
            <span>{purchaseDetails?.fromPurchase ? 'Supplier' : 'Customer'} Number</span>:{' '}
            <span className="font-bold uppercase">{customerDetails?.phone}</span>
          </div>
        </div>
        <div>
          <div>
            <span>{content?.nepaliDateLabel ? content.nepaliDateLabel : 'Miti'}</span>:
            {convert_string_to_nepali_date_string(purchaseDetails.createdAt)}
          </div>
          <div>
            <span>{content?.dateLabel ? content.dateLabel : 'Date'}</span>:
            {purchaseDetails?.createdAt
              ? convertUTCStringtoLocalString(purchaseDetails.createdAt, 'YYYY-MM-DD')
              : ''}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center px-8">
        {purchaseDetails?.fromPurchase ? (
          <div>
            <span>{content?.customerPanLabel ? content.customerPanLabel : 'Supplier PAN'}</span>:{' '}
            <span className="font-bold uppercase">
              {content?.panNumber ? content.panNumber : ''}
            </span>
          </div>
        ) : (
          <div>
            <span>{content?.customerPanLabel ? content.customerPanLabel : 'Customer PAN'}</span>:{' '}
            <span className="font-bold uppercase">
              {content?.panNumber ? content.panNumber : ''}
            </span>
          </div>
        )}

        {/* <div>
          <span>{content?.paymentStatusLabel ? content.paymentStatus : 'Payment Status'}</span>:
          <span className="uppercase">{purchaseDetails.paymentStatus}</span>{' '}
        </div> */}
      </div>
      <CustomTableForSellReturn
        linesData={data.linesData}
        taxable={data.taxable}
        nonTaxable={data.nonTaxable}
        vat={data.vat}
      />
      {/* <CustomTable columns={columns} datas={datas} /> */}
      <div className="mt-12 flex justify-between  px-8">
        <div>
          <div>..........................................</div>
          <div>{content?.receivedByLabel ? content.receivedByLabel : 'Received By'}</div>
        </div>
        <div className="font-bold">www.freshktm.com</div>
        <div>
          <div>........................................................................</div>
          <div>
            For:
            <span>{content?.companyName ? content.companyName : 'FreshKtm Agro Pvt. Ltd.'}</span>
          </div>
          <div>
            Finalized By:
            <span className="capitalize">
              {createdByDetails?.name ? createdByDetails.name : ''}
            </span>
          </div>
          {firstCreatedByUserDetails && (
            <div>
              Created By:
              <span className="capitalize">
                {firstCreatedByUserDetails?.name ? firstCreatedByUserDetails.name : ''}
              </span>
            </div>
          )}
          <div>
            Printed By:{' '}
            <span className="capitalize">{loggedInUser?.name ? loggedInUser.name : ''}</span>
          </div>
          {/* <div>Print Date and Time: BK2(13:10)</div> */}
        </div>
      </div>
      <div
        style={{
          width: '95%',
          margin: 'auto',
          border: '1px solid black',
          overflowWrap: 'break-word',
          padding: '2px'
        }}
        className="text-xs">
        Note : {purchaseDetails?.note ? purchaseDetails.note : ''}
      </div>
      <div className="flex justify-between mt-3 px-8">
        <span>Thank You !</span>
        <span className="font-bold">E. &O.E</span>
      </div>
    </div>
  );
};

export default SellReturnInvoice;
