import { useEffect, useState } from 'react';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { get_units_list } from '@/services/products/queries';
import { Select } from 'antd';
import { IUnits } from '@/services/products/types';
import { cn } from '@/utils';

type Props = {
  defaultValue?: number;
  showAll?: boolean;
  className?: string;
  disabled?: boolean;
  allowClear?: boolean;
  placeHolder?: string;
  notFoundContent?: React.ReactNode;
  defaultActiveFirstOption?: boolean;
  selectedProductUnitIds?: Array<number>;
  dropdownMatchSelectWidth?: boolean;
  onSelect?: (value: number, unit: IUnit) => void;
};

interface IUnit {
  id: number | string;
  name: string;
  shortName?: string;
}

function UnitSearchForNonForm({
  showAll = false,
  allowClear = true,
  placeHolder = 'Search for unit',
  notFoundContent = <div>No Content</div>,
  dropdownMatchSelectWidth = false,
  defaultActiveFirstOption = false,
  selectedProductUnitIds = [],
  onSelect,
  defaultValue,
  className,
  disabled
}: Props) {
  const [units, setUnits] = useState<IUnit[]>([]);
  const [selectedUnit, setSelectedUnit] = useState(defaultValue);

  useEffect(() => {
    setSelectedUnit(defaultValue);
  }, [defaultValue]);

  function handleChange(id: number) {
    setSelectedUnit(id);
    const unit = units.find((current) => current.id === id);
    if (unit) onSelect?.(id, unit);
  }

  useEffect(() => {
    (async () => {
      let units = (await UnitsDB.getAllUnits()) as IUnits[];
      if (!units || units.length === 0) {
        const serverUnits = await get_units_list();
        await UnitsDB.addUnits(serverUnits);
        units = (await UnitsDB.getAllUnits()) as IUnits[];
      }

      const updatedUnits = units
        .map((unit) => {
          return { id: unit.id, name: unit.name, shortName: unit.shortName } as IUnit;
        })
        .filter((unit) => {
          if (selectedProductUnitIds.length === 0) return true;
          return selectedProductUnitIds.includes(Number(unit.id)) || unit.id === '';
        });

      if (showAll) {
        updatedUnits.unshift({ id: '', name: 'All' });
      }

      setUnits(updatedUnits);
    })();
  }, [selectedProductUnitIds]);

  return (
    <Select
      showSearch
      disabled={disabled}
      allowClear={allowClear}
      optionFilterProp="label"
      value={selectedUnit}
      notFoundContent={notFoundContent}
      onChange={handleChange}
      defaultActiveFirstOption={defaultActiveFirstOption}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      className={cn('w-full', className)}
      placeholder={placeHolder}
      options={units.map((unit) => ({
        value: unit.id,
        label: `${unit.name} ${unit?.shortName ? `(${unit.shortName})` : ''}`
      }))}
    />
  );
}

export default UnitSearchForNonForm;
