import React, { useContext, useState } from 'react';
import { SellOrderListContext } from './context';
import { message } from 'antd';
import { getSellOrderProductRoutePrintData } from '../../../../components/Common/InvoicePrint/ProductRoutePrint/services';
import { IProductRouteInvoice } from '../../../../services/invoice/types';
import { CustomModal } from '../../../../components/Common/CustomModal';
import ProductRoutePrint from '../../list/ProductRoutePrint';
import { convertLocalToUTCString } from '../../../../utils/convertToUTC';
import { ConvertObjectToURL } from '../../../../utils/converturl';
import CustomButton from '../../../../components/Common/CustomButton/CustomButton';

export const ProductRoutePrintModal: React.FC<{
  getInfo: (urlData: string) => void;
}> = ({ getInfo }) => {
  const { setIsLoading, form, urlData } = useContext(SellOrderListContext);
  const [openModalForProductRoutePrint, setOpenModalForProductRoutePrint] =
    useState<boolean>(false);
  const [productRouteData, setProductRouteData] = useState<IProductRouteInvoice>(Object);
  const generateProductRouteForPrint = async () => {
    setIsLoading(true);
    await form.validateFields();
    try {
      const startDate = form.getFieldValue(['startDate']);
      const endDate = form.getFieldValue(['endDate']);
      const routeId = form.getFieldValue(['routeId']);
      const locationId = form.getFieldValue(['locationId']);
      const categoryId = form.getFieldValue(['categoryId']);
      const productRotueModalData = await getSellOrderProductRoutePrintData(
        startDate,
        endDate,
        locationId,
        routeId,
        categoryId
      );
      productRotueModalData.totalProduct.sort((a, b) => {
        const productNameA = a.productName?.toLowerCase();
        const productNameB = b.productName?.toLowerCase();

        if (productNameA && productNameB) {
          if (productNameA < productNameB) {
            return -1;
          }
          if (productNameA > productNameB) {
            return 1;
          }
        }
        return 0;
      });
      // console.log('productRotueModalData', productRotueModalData);
      setIsLoading(false);

      setProductRouteData({ ...productRotueModalData });
      setOpenModalForProductRoutePrint(true);
    } catch (err: any) {
      setIsLoading(false);
      message.error(err.message);
    }
  };
  const handleProductRoutePrintModalClose = () => {
    setOpenModalForProductRoutePrint(false);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };

  return (
    <div>
      <CustomButton
        onClick={() => {
          // onFinish(form.getFieldsValue());
          generateProductRouteForPrint();
        }}
        text="Print"
        backgroundColor="#1890ff"
      />
      <CustomModal
        footer={false}
        isModalOpen={openModalForProductRoutePrint}
        setIsModalOpen={setOpenModalForProductRoutePrint}
        title="Product Route Print">
        <ProductRoutePrint
          routeInformation={productRouteData.routeInformation}
          totalInfo={productRouteData.totalInfo}
          totalProduct={productRouteData.totalProduct}
          categoryName={productRouteData.categoryName}
          handleModalClose={handleProductRoutePrintModalClose}
        />
      </CustomModal>
    </div>
  );
};

export default ProductRoutePrint;
