import { useContext, useState } from 'react';
import { CustomModal } from '../../../components/Common/CustomModal';
import PurchaseInvoice from '../../../components/Common/InvoicePrint/PurchaseInvoice/PurchaseInvoice';
import { PurchaseListContext } from './context';
import { IPurchaseInvoice } from '../../../services/invoice/types';
import { getPurchasePrintData } from '../../../components/Common/InvoicePrint/PurchaseInvoice/services';
import InvoicePrintButton from '../../../components/Common/InvoicePrintButton/InvoicePrintButton';

export const PurchaseInvoicePrintModal: React.FC<{
  getInfo: (urlData: string) => void;
  record: any;
}> = ({ getInfo, record }) => {
  const { setIsLoading, urlData } = useContext(PurchaseListContext);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<IPurchaseInvoice>(Object);

  const generateInvoiceForPrint = async (record: any, curr: any) => {
    setIsLoading(true);
    const invoiceModalData = await getPurchasePrintData(record.id, record.locationId, curr);
    setIsLoading(false);

    setInvoiceData(invoiceModalData);

    setOpenModalForInvoicePrint(true);
  };

  const handleInvoicePrintModalClose = () => {
    setOpenModalForInvoicePrint(false);
    getInfo(urlData);
  };
  return (
    <>
      <InvoicePrintButton
        getInvoice={(curr) => {
          generateInvoiceForPrint(record, curr);
        }}
      />
      <CustomModal
        footer={false}
        isModalOpen={openModalForInvoicePrint}
        setIsModalOpen={setOpenModalForInvoicePrint}
        title="Purchase Invoice Print">
        <PurchaseInvoice
          purchaseDetails={invoiceData.purchaseDetails}
          vendorDetails={invoiceData.vendorDetails}
          lines={invoiceData.lines}
          locationDetails={invoiceData.locationDetails}
          invoiceLayouts={invoiceData.invoiceLayouts}
          handleModalClose={handleInvoicePrintModalClose}
        />
      </CustomModal>
    </>
  );
};
