import { shortNameHiearchy } from '../../../../pages/sell/sell-order/view';
import { IReturnInvoice } from '../../../../services/invoice/types';
import { get_location_details } from '../../../../services/locations/queries';
import { get_product_list_ids, get_units_list } from '../../../../services/products/queries';
import {
  get_purchase_details,
  get_purchase_return_lines_details
} from '../../../../services/purchases/queries';
import { get_routes_details, get_routes_list } from '../../../../services/routes/queries';
import {
  get_sell_details,
  get_sell_order_details,
  get_sell_return_details,
  get_sell_return_lines_details
} from '../../../../services/sell/queries';
import {
  get_customer_details,
  get_user_details,
  get_vendor_details
} from '../../../../services/users/queries';
import { User } from '../../../../services/users/types';
import LocationsDB from '../../../../store/localstorage/LocationsDB';
import ProductsDB from '../../../../store/localstorage/ProductsDB';
import RoutesDB from '../../../../store/localstorage/RoutesDB';
import UnitsDB from '../../../../store/localstorage/UnitsDB';
import UsersDB from '../../../../store/localstorage/UsersDB';
import { numberDecimalFormatter } from '../../../../utils/numberFormatter';
import { getPrintLocation } from '../BillPrint/services';

export const getSellReturnPrintData = async (
  sellId: number,
  sellReturnId: number,
  locationId: number,
  fromPurchase: boolean,
  createdById?: number | null
) => {
  //Fetch Sell Return Details
  const response = await get_sell_return_details(sellId.toString());

  const sellResponse = await get_sell_details(sellId);

  const responseCurrSellReturn = response.data.find((val: any) => val.id === sellReturnId);

  //Fetch Sell Return Lines
  const LinesAndTotal = await getLinesForSellReturnInvoice(sellReturnId);
  const Lines = LinesAndTotal.lines;
  const totalQuantity = LinesAndTotal.totalQuantity;

  // Fetch Customer Details
  const userData = await get_customer_details(responseCurrSellReturn.customerId);
  const routeData = await getRouteData(sellResponse.address.routeId);

  const customerPANInformation = userData.user.tax_informations.find(
    (tax) => tax.taxType === 'PAN'
  );

  const sendUserData = {
    ...userData.user.user,
    routeName: routeData.name,
    panNumber: customerPANInformation?.taxNumber || ''
  };

  // Calc Total Quantity
  const totalQuantityString = getTotalQuantityString(totalQuantity);

  // Fetch Location Details
  const locationDetails = await getLocationDetails(locationId);

  // Fetch Created by Details
  const createdByDetails = await getCreatedByDetails(createdById);

  // Created and Finalized By
  const firstCreatedByUserDetails = await getFirstCreatedByDetails(sellResponse.sellOrderId);

  const printLocation = await getPrintLocation();

  return {
    returnDetails: {
      ...responseCurrSellReturn,
      referenceNumber: sellResponse.financialReference,
      fromPurchase: fromPurchase,
      totalQuantity: totalQuantityString,
      printLocation: printLocation
    },
    customerDetails: {
      ...sendUserData,
      address: locationDetails.address,
      city: locationDetails.city,
      country: locationDetails.country
    },
    createdByDetails: createdByDetails,
    firstCreatedByUserDetails: firstCreatedByUserDetails,
    lines: Lines
  } as IReturnInvoice;
};

export const getPurchaseReturnPrintData = async (
  purchaseId: number,
  purchaseReturnId: number,
  locationId: number,
  fromPurchase: boolean,
  createdById?: number | null
) => {
  //Fetch Purchase Return Details
  const response = await get_purchase_details(purchaseId);

  const responseReturn = response.returns.find((val) => val.id === purchaseReturnId);

  //Fetch Sell Return Lines
  const LinesAndTotal = await getLinesForPurchaseReturnInvoice(purchaseReturnId);
  const Lines = LinesAndTotal.lines;
  const totalQuantity = LinesAndTotal.totalQuantity;

  // Fetch Customer Details
  const userData = await get_vendor_details(response.purchase.vendorId);

  const sendUserData = {
    ...userData.user.user,
    routeName: ''
  };

  // Calc Total Quantity
  const totalQuantityString = getTotalQuantityString(totalQuantity);

  // Fetch Location Details
  const locationDetails = await getLocationDetails(locationId);

  // Fetch Created by Details
  const createdByDetails = await getCreatedByDetails(createdById);

  // Created and Finalized By
  const firstCreatedByUserDetails = await getFirstCreatedByDetails(
    response.purchase.purchaseOrderId
  );

  const printLocation = await getPrintLocation();

  return {
    returnDetails: {
      ...responseReturn,
      printCount: response.purchase.printCount,
      fromPurchase: fromPurchase,
      totalQuantity: totalQuantityString,
      printLocation: printLocation
    },
    customerDetails: {
      ...sendUserData,
      address: locationDetails.address,
      city: locationDetails.city,
      country: locationDetails.country
    },
    createdByDetails: createdByDetails,
    firstCreatedByUserDetails: firstCreatedByUserDetails,
    lines: Lines
  } as IReturnInvoice;
};

const getLinesForSellReturnInvoice = async (sellReturnId: number) => {
  const linesResponse = await get_sell_return_lines_details(sellReturnId);
  const totalQuantity: any = {};
  const searchProducts: any = {};
  for (let index = 0; index < linesResponse.data.length; index++) {
    const { productId, unitId } = linesResponse.data[index];
    const product: any = await ProductsDB.getProduct(productId);
    if (!product) {
      // product = await get_product_details(linesResponse.lines[index].productId);
      // await ProductsDB.addProducts([product]);
      if (linesResponse.data[index].productId in searchProducts) {
        searchProducts[linesResponse.data[index].productId] = [
          ...searchProducts[linesResponse.data[index].productId],
          index
        ];
      } else {
        searchProducts[linesResponse.data[index].productId] = [index];
      }
    } else {
      linesResponse.data[index].productName = product.name;
    }

    let findUnit = await UnitsDB.getUnit(unitId);
    if (!findUnit) {
      const allUnits = await get_units_list();
      await UnitsDB.addUnits(allUnits);
      findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
    }
    if (typeof findUnit === 'object') {
      if (!totalQuantity[findUnit.shortName]) {
        totalQuantity[findUnit.shortName] = linesResponse.data[index].quantity;
      } else {
        totalQuantity[findUnit.shortName] += linesResponse.data[index].quantity;
      }
      linesResponse.data[
        index
      ].quantityWithUnit = `${linesResponse.data[index].quantity} ${findUnit.shortName}`;
      linesResponse.data[index].shortName = findUnit.shortName;
    }
  }
  const searchProductslength = Object.entries(searchProducts).length;
  if (searchProductslength > 0) {
    const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
    for (const key in searchProducts) {
      const findproduct = productsresponse?.data?.results.find(
        (currProduct) => currProduct.id == key
      );
      if (findproduct) {
        for (let i = 0; i < searchProducts[key].length; i++) {
          linesResponse.data[searchProducts[key][i]].productName = findproduct.name;
        }
        await ProductsDB.addProducts([findproduct]);
      }
    }
  }

  return { lines: linesResponse.data, totalQuantity: totalQuantity };
};

const getLinesForPurchaseReturnInvoice = async (purchaseReturnId: number) => {
  const linesResponse = await get_purchase_return_lines_details(purchaseReturnId);
  const totalQuantity: any = {};
  const searchProducts: any = {};
  for (let index = 0; index < linesResponse.data.length; index++) {
    const { productId, unitId } = linesResponse.data[index];
    const product = await ProductsDB.getProduct(productId);
    if (!product) {
      if (linesResponse.data[index].productId in searchProducts) {
        searchProducts[linesResponse.data[index].productId] = [
          ...searchProducts[linesResponse.data[index].productId],
          index
        ];
      } else {
        searchProducts[linesResponse.data[index].productId] = [index];
      }
    } else {
      linesResponse.data[index].productName = typeof product === 'object' ? product.name : '';
    }

    let findUnit = await UnitsDB.getUnit(unitId);
    if (!findUnit) {
      const allUnits = await get_units_list();
      await UnitsDB.addUnits(allUnits);
      findUnit = await UnitsDB.getUnit(linesResponse.data[index].unitId);
    }
    if (typeof findUnit === 'object') {
      if (!totalQuantity[findUnit.shortName]) {
        totalQuantity[findUnit.shortName] = linesResponse.data[index].quantity;
      } else {
        totalQuantity[findUnit.shortName] += linesResponse.data[index].quantity;
      }
      linesResponse.data[
        index
      ].quantityWithUnit = `${linesResponse.data[index].quantity} ${findUnit.shortName}`;
      linesResponse.data[index].shortName = findUnit.shortName;
    }
  }
  const searchProductslength = Object.entries(searchProducts).length;
  if (searchProductslength > 0) {
    const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
    for (const key in searchProducts) {
      const findproduct = productsresponse?.data?.results.find(
        (currProduct) => currProduct.id == key
      );
      if (findproduct) {
        for (let i = 0; i < searchProducts[key].length; i++) {
          linesResponse.data[searchProducts[key][i]].productName = findproduct.name;
        }
        await ProductsDB.addProducts([findproduct]);
      }
    }
  }

  return { lines: linesResponse.data, totalQuantity: totalQuantity };
};

const getTotalQuantityString = (totalQuantity: any) => {
  let totalQuantityString = '';
  const totalQuantityArray: any[] = [];
  for (const key in totalQuantity) {
    totalQuantityArray.push({
      tqty: totalQuantity[key],
      shortName: key
    });
  }
  if (totalQuantityArray.length == 0) return '0';
  totalQuantityArray.sort((a: any, b: any) => {
    return shortNameHiearchy.indexOf(a.shortName) - shortNameHiearchy.indexOf(b.shortName);
  });
  for (let ind = 0; ind < totalQuantityArray.length; ind++) {
    totalQuantityString +=
      totalQuantityArray[ind].tqty == 0
        ? ''
        : ` ${numberDecimalFormatter(totalQuantityArray[ind].tqty)} ${
            totalQuantityArray[ind].shortName
          }`;
  }
  if (totalQuantityString == '') return '0';
  return totalQuantityString;
};

const getLocationDetails = async (locationId: number) => {
  let locationDetails = await LocationsDB.getLocation(locationId);
  if (!locationDetails) {
    locationDetails = await get_location_details(locationId);
    await LocationsDB.addLocations([locationDetails]);
  }
  return locationDetails;
};

const getCreatedByDetails = async (createdById?: number | null) => {
  let createdByDetails: User;
  if (createdById) {
    createdByDetails = await UsersDB.getUser(createdById);
    if (!createdByDetails) {
      createdByDetails = (await get_user_details(createdById)).user;
      if (createdByDetails) UsersDB.addUsers([createdByDetails]);
    }
    return createdByDetails;
  }
};

const getRouteData = async (routeId?: number) => {
  let routeData: any = {
    name: ''
  };
  if (routeId) {
    routeData = await RoutesDB.getRoute(routeId);
    if (!routeData) {
      const response = await get_routes_details(routeId);
      await RoutesDB.addRoutes([response]);
      routeData = await RoutesDB.getRoute(routeId);
    }
  }

  return routeData;
};

const getFirstCreatedByDetails = async (sellOrderId?: number | null) => {
  let firstCreatedByUser;
  if (sellOrderId) {
    const sellOrderResponse = await get_sell_order_details(sellOrderId);
    if (sellOrderResponse?.data?.createdBy) {
      firstCreatedByUser = sellOrderResponse.data.createdBy;
    }
  }

  let firstCreatedByUserDetails: any;
  if (firstCreatedByUser) {
    firstCreatedByUserDetails = await UsersDB.getUser(firstCreatedByUser);
    if (!firstCreatedByUserDetails) {
      firstCreatedByUserDetails = (await get_user_details(firstCreatedByUser)).user;
      if (firstCreatedByUserDetails) UsersDB.addUsers(firstCreatedByUserDetails);
    }
  }

  return firstCreatedByUserDetails;
};
