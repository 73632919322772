import CustomersDB from '../../store/localstorage/CustomerDB';
import ProductsDB from '../../store/localstorage/ProductsDB';
import UnitsDB from '../../store/localstorage/UnitsDB';
import UsersDB from '../../store/localstorage/UsersDB';
import VendorsDB from '../../store/localstorage/VendorDB';
import { ISystemNotification } from '../users/types';

export const ws_new_customer = async (data: ISystemNotification) => {
  await CustomersDB.addCustomers([data.data]);
};

export const ws_update_customer = async (data: ISystemNotification) => {
  if ('id' in data.data && typeof data.data.id === 'number') {
    const customerDetails = await CustomersDB.getCustomer(data.data.id);
    if (!customerDetails) {
      await CustomersDB.addCustomers([data.data]);
    } else {
      await CustomersDB.updateCustomer(data.data);
    }
  }
};

export const ws_new_vendor = async (data: ISystemNotification) => {
  await VendorsDB.addVendors([data.data]);
};

export const ws_update_vendor = async (data: ISystemNotification) => {
  if ('id' in data.data && typeof data.data.id === 'number') {
    const vendorDetails = await VendorsDB.getVendors(data.data.id);
    if (!vendorDetails) {
      await VendorsDB.addVendors([data.data]);
    } else {
      await VendorsDB.updateVendors(data.data);
    }
  }
};

export const ws_new_product = async (data: ISystemNotification) => {
  await ProductsDB.addProducts([data.data]);
};

export const ws_update_product = async (data: ISystemNotification) => {
  if ('id' in data.data && typeof data.data.id === 'number') {
    const productDetails = await ProductsDB.getProduct(data.data.id);
    if (!productDetails) {
      await ProductsDB.addProducts([data.data]);
    } else {
      await ProductsDB.updateProduct(data.data);
    }
  }
};

export const ws_new_user = async (data: ISystemNotification) => {
  await UsersDB.addUsers([data.data]);
};

export const ws_update_user = async (data: ISystemNotification) => {
  if ('id' in data.data && typeof data.data.id === 'number') {
    const userDetails = await UsersDB.getUser(data.data.id);
    if (!userDetails) {
      await UsersDB.addUsers([data.data]);
    } else {
      await UsersDB.updateUser(data.data);
    }
  }
};

export const ws_new_unit = async (data: ISystemNotification) => {
  await UnitsDB.addUnits([data.data]);
};

export const ws_update_unit = async (data: ISystemNotification) => {
  if ('id' in data.data && typeof data.data.id === 'number') {
    const unitDetails = await UnitsDB.getUnit(data.data.id);
    if (!unitDetails) {
      await UnitsDB.addUnits([data.data]);
    } else {
      await UnitsDB.updateUnit(data.data);
    }
  }
};
