import CustomerSearchV2 from '@/components/Common/CustomSearch/Customer';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';
import UnitSearch from '@/components/Common/CustomSearch/Units';
import VendorSearchV2 from '@/components/Common/CustomSearch/Vendors';
import { Form, FormInstance } from 'antd';

interface Props {
  form: FormInstance;
  type: string;
  name: string;
  required?: boolean;
  setSelected?: (name: string) => void;
  label?: string;
}

function RenderCompareForm({ name, type, required, form, setSelected, label }: Props) {
  const currentValue = Form.useWatch(name, form) as number | undefined;

  switch (type) {
    case 'productId':
      return (
        <ProductSearchV2
          defaultValue={currentValue}
          hasParentFormItem={false}
          name={name}
          label={label}
          allowClear={!required}
          required={required}
          setSelected={(products) => {
            if (!products) return;
            const product = Array.isArray(products) ? products[0] : products;
            setSelected?.(product.name);
          }}
        />
      );

    case 'locationId':
      return (
        <LocationSearchV2
          hasParentFormItem={false}
          name={name}
          defaultValue={currentValue}
          allowClear={!required}
          label={label}
          required={required}
          setSelected={(locations) => {
            if (!locations) return;
            const location = Array.isArray(locations) ? locations[0] : locations;
            setSelected?.(location.name);
          }}
        />
      );

    case 'unitId':
      return (
        <UnitSearch
          hasParentFormItem={false}
          name={name}
          allowClear={!required}
          label={label}
          required={required}
          setSelected={(unit) => {
            if (!unit) return;
            setSelected?.(unit.name);
          }}
        />
      );

    case 'customerId':
      return (
        <CustomerSearchV2
          hasParentFormItem={false}
          name={name}
          allowClear={!required}
          label={label}
          required={required}
          setSelected={(customer) => {
            if (!customer) return;
            setSelected?.(customer.user?.name);
          }}
        />
      );

    case 'vendorId':
      return (
        <VendorSearchV2
          hasParentFormItem={false}
          name={name}
          allowClear={!required}
          label={label}
          required={required}
          setSelected={(vendor) => {
            if (!vendor) return;
            setSelected?.(vendor.user?.name);
          }}
        />
      );

    default:
      return null;
  }
}

export default RenderCompareForm;
