import { Alert, Button, Form, InputNumber, Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import { CreatePartialPaymentReverse } from '../../../services/payments/types';
import { useMutation } from '@tanstack/react-query';
import { reverse_partial_payment_mutation } from '../../../services/payments/mutations';

interface ModalProps {
  paymentId?: number;
  handleModalClose: () => void;
}

export const PartialPaymentReverseModal: React.FC<ModalProps> = ({
  paymentId,
  handleModalClose
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [paymentId]);

  const onFinish = async (values: CreatePartialPaymentReverse = form.getFieldsValue()) => {
    setIsLoading(true);
    try {
      if (paymentId) {
        values.paymentId = paymentId;
        await reversePartialPaymentMutation.mutateAsync(values);
      }
    } catch (errors: any) {
      setIsLoading(false);
      message.error(errors.message);
    }
  };

  const reversePartialPaymentMutation = useMutation(reverse_partial_payment_mutation, {
    onSuccess: async () => {
      setIsLoading(false);
      message.success('Payment reversed successfully');
      await handleModalClose();
    },
    onError: (e: any) => {
      setIsLoading(false);
      message.error(`${e.response.data.message}`);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <div className="my-2">
        <Alert
          message="Information!"
          description="Please note, wallet balance will be added if Wallet Amount has positive value and subtracted if Wallet Amount has negative value."
          type="info"
          closable
          showIcon
        />
      </div>
      <Form
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        labelCol={{ span: 8 }}
        initialValues={{ amount: 0, walletAmount: 0 }}>
        <Form.Item label="Amount" name={'amount'}>
          <InputNumber controls={false} min={0} />
        </Form.Item>
        <Form.Item label="Wallet Amount" name={'walletAmount'}>
          <InputNumber controls={false} />
        </Form.Item>
        <div className="flex justify-end mt-5">
          <Form.Item>
            <Button type="primary" onClick={form.submit}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
};
