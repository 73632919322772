export const accountParentMenu = [
  '/accounts/journal',
  '/accounts/journal-lines',
  '/accounts/journal-lines-v2',
  '/accounts/new-journal-lines',
  '/accounts/detailed-ledger',
  '/accounts/detailed-ledger-v2',
  '/accounts/view',
  '/ledger',
  '/trial-balance',
  '/balance-sheet',
  '/profit-loss',
  '/accounts/receive-payable'
];
