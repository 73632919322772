import { useQuery } from '@tanstack/react-query';
import React, { useState, useEffect, useRef } from 'react';
import { LoadScript, GoogleMap, MarkerF, Polyline, Marker, InfoBox } from '@react-google-maps/api';
import { useParams } from 'react-router-dom';
import AppContent from '../../../components/Common/Content/Content';
import { get_vehicles_track } from '../../../services/vehicle/queries';
import { IVehicleLiveData } from '../../../services/vehicle/types';
import { CarFilled } from '@ant-design/icons';
import { PageHeader, Select, Typography } from 'antd';
// import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';
const AllVehicleLive = () => {
  const google = window.google;
  //   const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayInfo, setDisplayInfo] = useState<any>({ 0: true });
  const [vehicleIndex, setVehicleIndex] = useState(0);
  const [data, setData] = useState<IVehicleLiveData>();
  const [zoom, setZoom] = useState(6);
  const [center, setCenter] = useState({
    lat: 28.3949,
    lng: 84.124
  });
  const [mapData, setMapData] = useState<any>();
  const { refetch } = useQuery(['vehicle-live'], async () => {
    const response = await get_vehicles_track();
    setData(response.data as IVehicleLiveData);
    // setIsLoading(false);
    return response;
  });
  useEffect(() => {
    const interval = setInterval(() => refetch(), 20000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // const zoomHandle = () => {
  //   if (mapData) console.log(mapData.getBounds());
  //   // if (google?.maps && mapElement) {
  //   //   console.log(mapElement);
  //   // }
  // };

  return (
    <AppContent breadcrumbItems={breadcrumbItems}>
      <div className="grid grid-cols-1 gap-5 mb-5">
        <Typography.Text>Select to view vehicle location:</Typography.Text>
        {data && (
          <Select
            dropdownMatchSelectWidth={false}
            value={vehicleIndex}
            onChange={(val) => {
              setVehicleIndex(val);
              if (val in displayInfo) {
                setDisplayInfo({ [val]: !displayInfo[val] });
              } else {
                setDisplayInfo({ [val]: true });
              }
              setZoom(15);
              setCenter({
                lat: parseFloat(data.Details[val].Latitude),
                lng: parseFloat(data.Details[val].longitude)
              });
            }}>
            {data.Details.map((val, index) => {
              return (
                <Select.Option key={index} value={index}>
                  {val.VehicleNo} ({val.IMEI})
                </Select.Option>
              );
            })}
          </Select>
        )}
      </div>
      <LoadScript googleMapsApiKey={'AIzaSyATJbgY-H04JRS-GO4GaKRHPPDHS0p2ENA'}>
        <GoogleMap
          // ref={(ref) => {
          //   if (ref) {
          //     if (!mapData) setMapData(ref.getInstance());
          //   }
          // }}
          center={center}
          zoom={zoom}
          // onZoomChanged={() => {
          //   zoomHandle();
          // }}
          // on
          options={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false
          }}
          onClick={(val) => console.log(val)}
          mapContainerStyle={containerStyle}>
          {window.google &&
            data?.Details.map((val, index) => {
              return (
                <Marker
                  key={index}
                  position={{
                    lat: parseFloat(val.Latitude),
                    lng: parseFloat(val.longitude)
                  }}
                  label={`${displayInfo[index] ? val.VehicleNo : ''}`}
                  icon={{
                    // url: 'https://freshktm.github.io/ERP-UI/temp.png',
                    url: `https://freshktm.github.io/ERP-UI/${data.Details[index].DeviceStatus}.svg`,
                    // anchor: new google.maps.Point(17, 46),
                    labelOrigin: new google.maps.Point(17, -10),
                    scaledSize: new google.maps.Size(37, 37)
                  }}
                  onClick={(value) => {
                    if (index in displayInfo) {
                      // setDisplayInfo({ ...displayInfo, [index]: !displayInfo[index] });
                      setDisplayInfo({ [index]: !displayInfo[index] });
                    } else {
                      setDisplayInfo({ [index]: true });
                      // setDisplayInfo({ ...displayInfo, [index]: true });
                    }
                    // setDisplayInfo(!displayInfo);
                  }}>
                  {displayInfo[index] && (
                    <InfoBox
                      onCloseClick={() => {
                        console.log('clicked');
                      }}
                      options={{
                        closeBoxURL: ``,
                        enableEventPropagation: true,
                        position: new google.maps.LatLng({
                          lat: parseFloat(val.Latitude),
                          lng: parseFloat(val.longitude)
                        }),
                        pixelOffset: new google.maps.Size(-100, -155)
                      }}>
                      <div
                        style={{
                          backgroundColor: `white`,
                          opacity: 0.9,
                          padding: `12px`,
                          width: 200,
                          height: 100,
                          overflowY: 'scroll'
                        }}>
                        <div style={{ fontSize: `16px`, color: `#08233B` }}>
                          Number: {`${val.VehicleNo}`}
                        </div>
                        <div style={{ fontSize: `16px`, color: `#08233B` }}>
                          Status: {`${val.DeviceStatus}`}
                        </div>
                      </div>
                    </InfoBox>
                  )}
                </Marker>
              );
            })}
        </GoogleMap>
      </LoadScript>
    </AppContent>
  );
};
const containerStyle = {
  // width: '100%',
  height: '80vh'
  // marginBottom: '20px'
};
const breadcrumbItems = [
  {
    label: 'All Vehicle Live',
    link: '/vehicle/all-live'
  }
];

export default AllVehicleLive;
