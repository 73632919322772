import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Input,
  message,
  Pagination,
  Table,
  Tooltip,
  Menu,
  Dropdown,
  Space,
  TableProps
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppContent from '../../../../components/Common/Content/Content';
import DiscussionChatModal from '../../../../components/Common/DiscussionChatModal';
import { get_reasons_list } from '../../../../services/adjustments/queries';
import moment from 'moment';
import CustomButton from '../../../../components/Common/CustomButton/CustomButton';
import { SorterResult } from 'antd/lib/table/interface';
import {
  convertLocalToUTCString,
  convertUTCStringtoLocalString
} from '../../../../utils/convertToUTC';
import ActionDropdown from '../../../../components/Common/Dropdownactions';
import UsersDB from '../../../../store/localstorage/UsersDB';
import { get_user_list_ids } from '../../../../services/users/queries';
import TableCell from '../../../../components/Common/CustomizeTable/CustomCell';
import { get_channel_reasons_list } from '../../../../services/channels/queries';
import { checkAccess } from '../../../../routes/acl';
import CustomUpdateIcon from '../../../../components/Common/CustomIcons/CustomUpdateIcon';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const ChannelReasonsList: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [dataList, setDataList] = useState<any[]>([]);
  const { data: reasonList, refetch } = useQuery(['channel_reasons'], async () => {
    const response = await get_channel_reasons_list();
    const createdByUsers: any = {};
    for (let index = 0; index < response.data.length; index++) {
      if (response.data[index].createdBy) {
        const createdByuser: any = await UsersDB.getUser(response.data[index].createdBy!);
        if (!createdByuser) {
          if (response.data[index].createdBy! in createdByUsers) {
            createdByUsers[response.data[index].createdBy!] = [
              ...createdByUsers[response.data[index].createdBy!],
              index
            ];
          } else {
            createdByUsers[response.data[index].createdBy!] = [index];
          }
        } else {
          response.data[index].createdByName = createdByuser.name;
        }
      }
    }
    const createdByUserslength = Object.entries(createdByUsers).length;

    if (createdByUserslength > 0) {
      const customerresponse = await get_user_list_ids([...Object.keys(createdByUsers)]);
      for (const key in createdByUsers) {
        const createdByUser = customerresponse?.data?.results.find(
          (currCustomer: any) => currCustomer.id == key
        );
        if (!createdByUser) continue;
        for (let i = 0; i < createdByUsers[key].length; i++) {
          response.data[createdByUsers[key][i]].createdByName = createdByUser.name;
        }
        await UsersDB.addUsers([createdByUser]);
      }
    }
    setDataList(response.data);
    setIsloading(false);
    return response;
  });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 15,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <TableCell>{index + 1}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 100,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.name}</TableCell>;
      }
    },
    {
      title: 'Created',
      key: 'createdAt',
      width: 100,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>{moment(record.createdAt).local().format(DEFAULT_DATE_FORMAT)}</TableCell>
        );
      }
    },
    {
      title: 'Created By',
      key: 'addedBy',
      width: 70,
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === 'addedBy' ? sortedInfo.order : null,
      render: (record) => {
        return <TableCell>{record.createdByName}</TableCell>;
      }
    },
    {
      title: 'Updated',
      key: 'updatedAt',
      width: 100,
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      sortOrder: sortedInfo.columnKey === 'updatedAt' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <TableCell>
            {convertUTCStringtoLocalString(record.updatedAt, DEFAULT_DATE_FORMAT)}
          </TableCell>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 25,
      render: (record) => {
        const menu = (
          <Menu
            items={[
              {
                key: '1',
                label: (
                  <CustomUpdateIcon link={`/channel/reasons/${record.id}?name=${record.name}`} />
                )
              },
              {
                key: '2',
                label: <DiscussionChatModal slug="reasons" id={record.id} />
              }
            ]}
          />
        );
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Ticket Reasons',
      link: '/channel/reasons'
    }
  ];

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            {checkAccess('CREATE_REASON') && (
              <div className="flex justify-end">
                <CustomButton
                  onClick={() => navigate(`/channel/reasons/new`)}
                  text="Add"
                  backgroundColor="#1890ff"
                  marginTop="17px"
                  Linkto="/channel/reasons/new"
                />
              </div>
            )}
          </>
        }>
        <Table
          scroll={{ x: 1000, y: '80vh' }}
          columns={columns}
          dataSource={dataList ? dataList : []}
          rowKey={'id'}
          loading={isLoading}
          onChange={handleChange}
          size="small"
          pagination={false}
        />
      </AppContent>
    </div>
  );
};

export default ChannelReasonsList;
