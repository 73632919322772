import { find_to_use_NPR } from '@/store/localstorage/preferences';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import moment from 'moment';

export const tableExportColumns = [
  {
    label: 'Date',
    dataIndex: 'date',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Invoice No.',
    dataIndex: 'reference_number',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Customer',
    dataIndex: 'userName',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Location',
    dataIndex: 'locationName',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Payment Status',
    dataIndex: 'payment_status',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Total amount',
    dataIndex: 'total_amount',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Total paid',
    dataIndex: 'amount_paid',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Total Returned',
    dataIndex: 'amount_returned',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  },
  {
    label: 'Total remaining',
    dataIndex: 'total_remaining',
    render: (text: string) => {
      return <div className="text-center">{text}</div>;
    }
  }
];

export const excelExportColumns = tableExportColumns.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      date: moment(record.date).format('YYYY-MM-DD'),
      total_amount: nepaliNumberFormatter(record.total_amount),
      amount_paid: nepaliNumberFormatter(record.amount_paid),
      amount_returned: nepaliNumberFormatter(record.amount_returned),
      total_remaining: nepaliNumberFormatter(record.total_amount - record.amount_paid)
    };
  }) as any;
}
