import { createContext, useContext, useRef, useState } from 'react';
import { Form, FormInstance } from 'antd';
import PurchaseList from './List';

export const PurchaseListContext = createContext<IContext>({} as IContext);

interface IContext {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<any>;
  urlData: string;
  setUrlData: React.Dispatch<React.SetStateAction<string>>;
}
export const PurchaseListContextComp: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();
  const [urlData, setUrlData] = useState('');
  return (
    <PurchaseListContext.Provider
      value={{
        isLoading,
        setIsLoading,
        form,
        urlData,
        setUrlData
      }}>
      <PurchaseList />
    </PurchaseListContext.Provider>
  );
};
