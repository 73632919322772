import { message } from 'antd';
import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import ListUI from '../../common/ListUI';
import UsersDB from '@/store/localstorage/UsersDB';
import { delete_user, get_user_list_filter } from '@/services/users/queries';

const List: React.FC = () => {
  const [isLoading, setIsloading] = useState<boolean>(true);

  const deleteUserMutation = useMutation(delete_user, {
    onSuccess: () => {
      message.success('User deleted successfully');
      getInfo();
    },
    onError: (e: any) => {
      setIsloading(false);
      message.error(`${e.response.data.message}`, 5);
    }
  });
  const breadcrumbItems = [{ label: 'Users', link: '/users' }];

  const getInfo = async (filter = '') => {
    console.log('this is called!');
    setIsloading(true);
    // const response = await get_user_list(skip, count, value, filter);
    const response = await get_user_list_filter(filter);
    UsersDB.addUsers(response.data.results);
    setIsloading(false);
    return response;
  };

  return (
    <div>
      <ListUI breadcrumbItems={breadcrumbItems} deleteMutation={deleteUserMutation} type="user" />
    </div>
  );
};

export default List;
