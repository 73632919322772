import AppContent from '@/components/Common/Content/Content';
import { ICustomerCreateFormV2 } from '@/services/users/types';
import { Button, Collapse, Form, message, Spin, Typography } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomerCreateForm from './CustomerCreateForm';
import CustomerAddressForm from './CustomerAddressForm';
import { CapitalizeEachWord } from '@/utils/capitalizeEachWord';
import { useMutation } from '@tanstack/react-query';
import { create_customers_mutation_v2 } from '@/services/users/mutations';
import getErrorMessage from '@/utils/getError';

function CreateCustomerV2() {
  const navigate = useNavigate();
  const [form] = Form.useForm<ICustomerCreateFormV2>();
  const [isLoading, setIsLoading] = useState(false);
  const [openKey, setOpenKey] = useState<string | string[]>(['customer-info', 'other-info']);

  const breadcrumbItems = [
    { label: 'Customers', link: '/users/customers' },
    { label: 'Create', link: '/users/customers/create' }
  ];

  const customerCreateMutaion = useMutation(create_customers_mutation_v2, {
    onError: (error) => {
      message.error(getErrorMessage(error));
      setIsLoading(false);
    },
    onSuccess: () => {
      message.success('Customer added successfully');
      setIsLoading(false);
      navigate('/users/customers');
    }
  });

  async function onFinish(values: ICustomerCreateFormV2) {
    try {
      const { addresses, ...rest } = values;
      setIsLoading(true);
      rest.name = CapitalizeEachWord(rest.name);
      rest.phone = rest.phone.toString();
      rest.secondaryPhone = rest.secondaryPhone?.toString();

      const updatedValues = { ...rest, addresses: [addresses] };
      await customerCreateMutaion.mutateAsync(updatedValues);
    } catch (error) {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Typography.Title level={4} style={{ color: '#001529' }}>
          Create Customer
        </Typography.Title>

        <Form
          form={form}
          initialValues={{ preferences: { nepaliDate: false } }}
          layout="vertical"
          onFinish={onFinish}>
          <Collapse activeKey={openKey}>
            <Collapse.Panel header="Customer Information" key="customer-info">
              <CustomerCreateForm />
            </Collapse.Panel>

            <Collapse.Panel header="Customer Address" key="other-info">
              <CustomerAddressForm />
            </Collapse.Panel>
          </Collapse>

          <div className="flex justify-end gap-2.5 m-8">
            <Button onClick={() => navigate('/users/customers')}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              Create Customer
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default CreateCustomerV2;
