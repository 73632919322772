import CustomSearch, { SelectProps } from '..';
import { get_customer_list_only, get_customer_list_route } from '@/services/users/queries';
import { ICustomer } from '@/services/users/types';
import CustomersDB from '@/store/localstorage/CustomerDB';

type MultipleProps =
  | {
      defaultValue?: number;
      isMultiple?: false;
      setSelected?: (selected?: ICustomer) => void;
      onSelect?: (selected: number) => void;
    }
  | {
      defaultValue?: number[];
      isMultiple: true;
      setSelected?: (selected?: ICustomer[]) => void;
      onSelect?: (selected: number[]) => void;
    };

type Props = SelectProps &
  MultipleProps & {
    routeId?: number;
  };

function CustomerSearchByRoute({ routeId, ...props }: Props) {
  async function get_customer(skip = 0, count = 100, value = '', filter = '') {
    if (!routeId) {
      return get_customer_list_only(skip, count, value, filter);
    }

    const customer = await get_customer_list_route(skip, count, value, routeId, filter);
    return customer.data.results;
  }

  async function searchCustomerDB(value: string, limit?: number) {
    if (!routeId) return CustomersDB.searchCustomer(value, limit);
    return [];
  }

  return (
    <CustomSearch
      key={routeId}
      path={{ name: 'user.name', value: 'id' }}
      formFor="customer"
      addCallback={CustomersDB.addCustomers}
      dbSearchById={CustomersDB.getCustomer}
      serverCallback={get_customer}
      dbSearchCallback={searchCustomerDB}
      {...props}
    />
  );
}

export default CustomerSearchByRoute;
