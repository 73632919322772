import { find_to_use_NPR } from '@/store/localstorage/preferences';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter, numberDecimalFormatter } from '@/utils/numberFormatter';

export const tableExportColumns1 = [
  {
    label: 'Product ID',
    dataIndex: 'product_id',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Product',
    dataIndex: 'name',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'SKU',
    dataIndex: 'sku',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Supplier',
    dataIndex: 'vendor_name',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Reference No.',
    dataIndex: 'reference_number',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Date',
    dataIndex: 'date',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Quantity',
    dataIndex: 'quantity',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Unit',
    dataIndex: 'unit_name',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Unit Purchase Price',
    dataIndex: 'unit_price',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Subtotal',
    dataIndex: 'total',
    render: (text: string) => <div className="text-center">{text}</div>
  }
];

export const tableExportColumns2 = [
  {
    label: 'Product ID',
    dataIndex: 'product_id',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Name',
    dataIndex: 'product_name',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Location',
    dataIndex: 'location_name',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Sold',
    dataIndex: 'qty_sold',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Sold Avg Price',
    dataIndex: 'qty_sold_price',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Returned Sell',
    dataIndex: 'qty_returned_sell',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Returned Sell Avg Price',
    dataIndex: 'qty_returned_sell_price',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Purchased',
    dataIndex: 'qty_purchased',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Purchased Avg Price',
    dataIndex: 'qty_purchased_price',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Returned Purchased',
    dataIndex: 'qty_returned_purchase',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Returned Purchased Avg Price',
    dataIndex: 'qty_returned_purchase_price',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Date',
    dataIndex: 'date',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Transferred',
    dataIndex: 'qty_transferred',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Transferred Avg Price',
    dataIndex: 'qty_transferred_price',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Transferred Out',
    dataIndex: 'qty_transferred_out',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Transferred Out Avg Price',
    dataIndex: 'qty_transferred_out_price',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Qty Adjusted',
    dataIndex: 'qty_adjusted',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Qty Adjusted Avg Price',
    dataIndex: 'qty_adjusted_price',
    render: (text: string) => <div className="text-center">{text}</div>
  }
];

export const tableExportColumns3 = [
  {
    label: 'Product ID',
    dataIndex: 'product_id',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Name',
    dataIndex: 'product_name',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Location',
    dataIndex: 'location_name',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Sold',
    dataIndex: 'qty_sold',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Returned Sell',
    dataIndex: 'qty_returned_sell',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Purchased',
    dataIndex: 'qty_purchased',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Returned Purchased',
    dataIndex: 'qty_returned_purchase',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Transferred',
    dataIndex: 'qty_transferred',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Transferred Out',
    dataIndex: 'qty_transferred_out',
    render: (text: string) => <div className="text-center">{text}</div>
  },
  {
    label: 'Qty Adjusted',
    dataIndex: 'qty_adjusted',
    render: (text: string) => <div className="text-center">{text}</div>
  }
];

export const excelExportColumns1 = tableExportColumns1.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export const excelExportColumns2 = tableExportColumns2.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export const excelExportColumns3 = tableExportColumns3.map((column) => ({
  title: column.label,
  dataIndex: column.dataIndex
}));

export function getUpdatedData1(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      date: record.date ? convertUTCStringtoLocalString(record.date) : '',
      quantity: numberDecimalFormatter(record.quantity),
      unit_price: nepaliNumberFormatter(record.unit_price),
      total: nepaliNumberFormatter(record.total)
    };
  }) as any;
}

export function getUpdatedData2(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      qty_sold: `${numberDecimalFormatter(record.qty_sold)} ${record.unit}`,
      qty_sold_price: numberDecimalFormatter(record.qty_sold_price),
      qty_returned_sell: `${numberDecimalFormatter(record.qty_returned_sell)} ${record.unit}`,
      qty_returned_sell_price: numberDecimalFormatter(record.qty_returned_sell_price),
      qty_purchased: `${numberDecimalFormatter(record.qty_purchased)} ${record.unit}`,
      qty_purchased_price: numberDecimalFormatter(record.qty_purchased_price),
      qty_returned_purchase: `${numberDecimalFormatter(record.qty_returned_purchase)} ${
        record.unit
      }`,
      qty_returned_purchase_price: numberDecimalFormatter(record.qty_returned_purchase_price),
      date: record.date ? convertUTCStringtoLocalString(record.date) : '',
      qty_transferred: `${numberDecimalFormatter(record.qty_transferred)} ${record.unit}`,
      qty_transferred_price: numberDecimalFormatter(record.qty_transferred_price),
      qty_transferred_out: `${numberDecimalFormatter(record.qty_transferred_out)} ${record.unit}`,
      qty_transferred_out_price: numberDecimalFormatter(record.qty_transferred_out_price),
      qty_adjusted: `${numberDecimalFormatter(record.qty_adjusted)} ${record.unit}`,
      qty_adjusted_price: numberDecimalFormatter(record.qty_adjusted_price)
    };
  }) as any;
}

export function getUpdatedData3(data: any[]) {
  return data.map((record: any) => {
    return {
      ...record,
      date: record.date ? convertUTCStringtoLocalString(record.date) : '',
      qty_sold: `${numberDecimalFormatter(record.qty_sold)} ${record.unit}`,
      qty_returned_sell: `${numberDecimalFormatter(record.qty_returned_sell)} ${record.unit}`,
      qty_purchased: `${numberDecimalFormatter(record.qty_purchased)} ${record.unit}`,
      qty_returned_purchase: `${numberDecimalFormatter(record.qty_returned_purchase)} ${
        record.unit
      }`,
      qty_transferred: `${numberDecimalFormatter(record.qty_transferred)} ${record.unit}`,
      qty_transferred_out: `${numberDecimalFormatter(record.qty_transferred_out)} ${record.unit}`,
      qty_adjusted: `${numberDecimalFormatter(record.qty_adjusted)} ${record.unit}`
    };
  }) as any;
}
