import dayjs from 'dayjs';
import { get_notification_list } from './../../services/notifications/queries';
import { useContext, useEffect, useState } from 'react';
import {
  IEventType,
  INotifications,
  IReportAddedEventType,
  IReportEventType,
  IReportFailedEventType,
  IVehicleReminderEvent
} from '@/services/notifications/types';
import { Badge, Button, Dropdown, Menu, message, Spin } from 'antd';
import getErrorMessage from '@/utils/getError';
import { ReportQueueEvents } from '@/services/report/enums';
import { InfoCircleFilled, NotificationOutlined } from '@ant-design/icons';
import { NotificationEvents } from '@/services/notifications/enums';
import { getNotificationLinkAndMessage } from '@/services/notifications/constants';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { update_read_notification } from '@/services/notifications/mutation';
import { WebSocketContext } from '@/contexts/websocket.context';
import { SocketEvents } from '@/constants/websocketConfig';
import { get_image } from '@/components/Common/FetchImage/FetchImage';
import { API_URL } from '@/constants/config';
import { ReportsDB } from './../../store/localstorage/ReportsDB';
import { CustomModal } from '@/components/Common/CustomModal';
import { DownloadReportModal } from '@/components/Common/CustomModal/DownloadReportModal';
import VehicleReminderModal from '@/components/Common/CustomModal/VehicleReminderModal';

const DEFAULT_NOTIFICATION_COUNT = 10;

function NotificationV2() {
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [openVehicleModal, setOpenVehicleModal] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [notifications, setNotifications] = useState<INotifications[]>([]);
  const [selectedNotification, setSelectedNotification] = useState<INotifications>();

  const navigate = useNavigate();
  const createReadMutation = useMutation(update_read_notification);
  const [downloadData, setDownloadData] = useState<{ event: IReportEventType; ids: number[] }>(
    {} as { event: IReportEventType; ids: number[] }
  );

  const { socket } = useContext(WebSocketContext);

  const closeMessage = () => message.destroy('downloading');

  useEffect(() => {
    if (!socket?.connected) {
      socket?.on('reconnect', () => fetchNotifications(0, DEFAULT_NOTIFICATION_COUNT));
    }

    socket?.on(SocketEvents.USER_NOTIFICATION, async (data: INotifications) => {
      try {
        if (data.title === ReportQueueEvents.REPORT_QUEUE_COMPLETED) {
          closeMessage();

          const parseData = JSON.parse(data.event as string) as IReportEventType;
          const isReportName = parseData.source.query.customReportQueueName ? true : false;

          message.loading({
            key: 'downloading',
            content: `${
              isReportName ? parseData.source.query.customReportQueueName : 'Report'
            } Downloading`
          });

          const downloadData = await get_image(`${API_URL}storage/media/${parseData.mediaId}`);
          window.open(downloadData, '_blank');
          closeMessage();
          message.success(
            isReportName
              ? `${parseData.source.query.customReportQueueName} Downloaded`
              : 'Report Downloaded Succesfully'
          );
        } else if (data.title === ReportQueueEvents.REPORT_QUEUE_FAILED) {
          throw Error('Failed to generate');
        }
      } catch (e) {
        closeMessage();
        message.error('Something Went Wrong, Please Try Again');
      }
    });

    return () => {
      socket?.off(SocketEvents.USER_NOTIFICATION);
    };
  }, [socket?.connected]);

  useEffect(() => {
    if (dropdownVisible && notifications.length === 0) {
      fetchNotifications(0, DEFAULT_NOTIFICATION_COUNT);
    }
  }, [dropdownVisible]);

  async function fetchNotifications(skip: number, count = DEFAULT_NOTIFICATION_COUNT) {
    try {
      setIsLoading(true);
      const data = await get_notification_list(skip, count);
      const parseData = await parsedNotifications(data.results);
      setNotifications((prevNotifications) => [...prevNotifications, ...parseData]);
      setHasMore(data.total > skip + parseData.length);
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const handleLoadMore = (event: React.MouseEvent) => {
    event.preventDefault();
    const newSkip = skip + DEFAULT_NOTIFICATION_COUNT;

    setSkip(newSkip);
    fetchNotifications(newSkip, DEFAULT_NOTIFICATION_COUNT);
  };

  const handleDropdownVisibility = (visible: boolean) => {
    setDropdownVisible(visible);
  };

  async function parsedNotifications(notifications: INotifications[]) {
    const parseData: INotifications[] = [];
    for (const notification of notifications) {
      if (Object.values(ReportQueueEvents).find((value) => value === notification.title)) {
        if (notification.title === ReportQueueEvents.REPORT_QUEUE_COMPLETED) {
          const JsonEvent = JSON.parse(notification.event as string) as IReportEventType;
          if (JsonEvent.source) {
            parseData.push({
              ...notification,
              header: `${notification.title} (${JsonEvent.source.query.customReportQueueName})`,
              event: JsonEvent
            });
          } else {
            parseData.push({
              ...notification,
              event: JSON.parse(notification.event as string) as IEventType
            });
          }
        } else if (notification.title === ReportQueueEvents.REPORT_QUEUE_ADDED) {
          const JsonEvent = JSON.parse(notification.event as string) as IReportAddedEventType;
          if (JsonEvent.data) {
            let customReportQueueName = JsonEvent.data.query.customReportQueueName;
            if (!customReportQueueName) {
              const reportDetails = await ReportsDB.getReportById(JsonEvent.data.sourceId);
              if (reportDetails) customReportQueueName = reportDetails.name;
            }

            const header = customReportQueueName
              ? `${notification.title} (${customReportQueueName})`
              : notification.title;

            parseData.push({ ...notification, header, event: JsonEvent });
          } else {
            parseData.push({
              ...notification,
              event: JSON.parse(notification.event as string) as IEventType
            });
          }
        } else if (notification.title === ReportQueueEvents.REPORT_QUEUE_FAILED) {
          const JsonEvent = JSON.parse(notification.event as string) as IReportFailedEventType;
          if (JsonEvent.query) {
            parseData.push({
              ...notification,
              header: `${notification.title} (${JsonEvent.query.customReportQueueName})`,
              event: JsonEvent
            });
          } else {
            parseData.push({
              ...notification,
              event: JSON.parse(notification.event as string) as IEventType
            });
          }
        } else {
          parseData.push({
            ...notification,
            event: JSON.parse(notification.event as string) as IEventType
          });
        }
      } else {
        parseData.push({
          ...notification,
          event: JSON.parse(notification.event as string) as IEventType
        });
      }
    }

    return parseData;
  }

  const viewHandle = async (val: INotifications, info: any) => {
    setSelectedNotification(val);
    setDropdownVisible(false);
    if (val.title === ReportQueueEvents.REPORT_QUEUE_COMPLETED) {
      if (typeof val.event === 'object' && 'mediaId' in val.event) {
        const queueId = val.event.source.queueId;
        const allIds = new Set<number>();
        notifications.forEach((value) => {
          if (typeof value.event === 'object' && 'data' in value.event) {
            if (value.event.data.queueId === queueId) allIds.add(value.id);
          }
        });
        setDownloadData({ event: val.event, ids: [val.id, ...allIds] });
      }

      setOpenDownloadModal(true);
    } else if (val.title === ReportQueueEvents.REPORT_QUEUE_FAILED) {
      if (typeof val.event === 'object' && 'queueId' in val.event) {
        const queueId = val.event.queueId;
        const allIds = new Set<number>();
        notifications.forEach((value) => {
          if (typeof value.event === 'object' && 'data' in value.event) {
            if (value.event.data.queueId === queueId) allIds.add(value.id);
          }
        });
        await createReadMutation.mutateAsync([...allIds], {
          onSuccess: async () => {
            setNotifications((prev) => prev.filter((value) => ![...allIds].includes(value.id)));
          }
        });
      }
    } else {
      if (val.title === NotificationEvents.VEHICLE_REMINDERS) {
        setOpenVehicleModal(true);
      }

      await createReadMutation.mutateAsync([val.id], {
        onSuccess: async () => {
          setNotifications((prev) => prev.filter((value) => value.id != val.id));

          if (typeof val.event === 'object') {
            if ('channelId' in val.event) {
              if (val.event?.type == 'MESSAGE_RECEIVED') {
                navigate(`${info.link}/?id=${val.event?.channelId}`);
              } else if (val.event.type == 'LOT_MERGED' || val.event.type == 'LOT_SPLIT') {
                navigate(`${info.link}`);
              } else {
                navigate(`${info.link}/${val.event?.id}`);
              }
            }
          }
        }
      });
    }
  };

  function getFormattedInfo(notification: INotifications) {
    let info = { link: '', message: '' };
    if (
      typeof notification.event === 'object' &&
      Object.values(ReportQueueEvents).find((value) => value === notification.title)
    ) {
      info.message = notification.header ? notification.header : notification.title;
    } else {
      if (typeof notification.event === 'object') {
        // VEHICLE_REMINDERS
        if (notification.event?.type === NotificationEvents.VEHICLE_REMINDERS) {
          const event = notification.event as IVehicleReminderEvent;
          const totalVehicles = event.notifications.length;
          info.message = `${totalVehicles} ${totalVehicles > 1 ? 'Vehicles' : 'Vehicle'} Reminder`;
        } else {
          info = getNotificationLinkAndMessage(notification.event.type);
        }
      }
    }

    return info;
  }

  const handleDownloadReportModalClose = async (ids: number[]) => {
    await createReadMutation.mutateAsync([...ids], {
      onSuccess: async () => {
        setNotifications((prev) => prev.filter((value) => !ids.includes(value.id)));
      }
    });

    setOpenDownloadModal(false);
  };

  const menu = (
    <Menu>
      <Spin spinning={isLoading}>
        {notifications.length === 0 ? (
          <div
            style={{ minWidth: 340, minHeight: notifications.length === 0 ? 200 : 500 }}
            className="py-2 px-1.5">
            <div className="h-full">
              {isLoading ? 'Fetching Notifications' : 'No Notifications'}
            </div>
          </div>
        ) : (
          notifications.map((notification, index: number) => {
            const info = getFormattedInfo(notification);
            return (
              <Menu.Item
                className="min-w-[300px]"
                key={`notification-${index}`}
                onClick={() => viewHandle(notification, info)}>
                <div className="flex flex-col py-2">
                  <div className="flex gap-2 items-center justify-between px-3">
                    <div>
                      <InfoCircleFilled style={{ color: '#1890ff', transform: 'scale(1.3)' }} />
                    </div>
                    <div>
                      <div className="font-bold col-span-2">{info?.message}</div>
                      <div className="text-sm text-slate-400 flex flex-row-reverse">
                        ({dayjs(notification.createdAt).format('hh:mm a MM/DD')})
                      </div>
                    </div>
                  </div>
                </div>
              </Menu.Item>
            );
          })
        )}
        {hasMore && (
          <Menu.Item className="text-right !cursor-default">
            <Button disabled={isLoading} onClick={handleLoadMore} type="link">
              Load More
            </Button>
          </Menu.Item>
        )}
      </Spin>
    </Menu>
  );

  return (
    <>
      <CustomModal
        width={'50%'}
        footer={false}
        maskClosable={false}
        isModalOpen={openDownloadModal}
        setIsModalOpen={setOpenDownloadModal}
        isSticky={true}
        title="Download Report">
        <DownloadReportModal
          data={downloadData.event}
          ids={downloadData.ids}
          handleModalClose={handleDownloadReportModalClose}
        />
      </CustomModal>

      <VehicleReminderModal
        isOpen={openVehicleModal}
        setIsOpen={setOpenVehicleModal}
        notification={selectedNotification}
      />

      <Dropdown
        overlay={menu}
        trigger={['click']}
        visible={dropdownVisible}
        onVisibleChange={handleDropdownVisibility}
        overlayStyle={{ maxHeight: '500px', overflow: 'auto' }}>
        <Badge dot={notifications.length > 0 ? true : false}>
          <NotificationOutlined style={{ cursor: 'pointer' }} />
        </Badge>
      </Dropdown>
    </>
  );
}

export default NotificationV2;
