import { useMutation } from '@tanstack/react-query';
import { Button, Card, Form, Input, message, PageHeader } from 'antd';
import React, { useState, useEffect } from 'react';
import { create_users_tax_mutation } from '../../../../../services/users/mutations';
import { useNavigate, useParams } from 'react-router-dom';
import AppContent from '../../../../../components/Common/Content/Content';
import './index.css';
import GoBackButton from '../../../../../components/Common/GoBackButton/GoBackButton';

interface ITaxValuesType {
  taxNumber: string;
  taxType: string;
  userId: number;
}

const TaxCreate: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const createTaxMutation = useMutation(create_users_tax_mutation, {
    onError: (e: any) => {
      message.error(`${e.response.data.message}`, 5);
      setIsloading(false);
    },
    onSuccess: (data: any) => {
      if (data) {
        message.success('Tax added successfully');
        setIsloading(false);
        navigate(`/users/${id}/tax`);
      }
    }
  });

  const onFinish = (values: ITaxValuesType) => {
    setIsloading(true);
    values.userId = parseInt(id as string);
    createTaxMutation.mutate(values);
  };
  const breadcrumbItems = [
    {
      label: 'Users',
      link: '/users'
    },
    {
      label: 'Tax',
      link: `/users/${id}/tax`
    },
    {
      label: 'Create',
      link: `/users/${id}/tax/new`
    }
  ];

  return (
    <>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        backgroundWhite={true}
        button={<GoBackButton onClick={() => navigate(`/users/${id}/tax`)} />}>
        <Card style={{ margin: '15px 0px', borderRadius: '9px' }}>
          <Form
            form={form}
            initialValues={{}}
            onFinish={onFinish}
            disabled={isloading}
            layout="vertical"
            validateTrigger={'onChange'}
            autoComplete="off">
            <PageHeader
              title="Tax Information"
              style={{
                padding: '8px 0px'
              }}
            />
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
              <Form.Item
                name="taxNumber"
                label="Tax Number"
                rules={[{ required: true, message: 'Please add number!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name="taxType"
                label="Tax Type"
                rules={[{ required: true, message: 'Please add type!' }]}>
                <Input />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isloading}
                  style={{
                    borderRadius: '5px',
                    color: 'white',
                    backgroundColor: '#1890ff',
                    marginTop: '30px'
                  }}>
                  Add
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Card>
      </AppContent>
    </>
  );
};

export default TaxCreate;
