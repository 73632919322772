import { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { IMenuList } from '../../../services/settings/types';
import MenuDB from '../../../store/localstorage/MenuDB';
import { Button, Divider, Form, Select, message } from 'antd';
import { get_menu_items } from '../../../services/settings/queries';
import { MenuTypes } from '../../../services/settings/enums';

interface MenuTypeSearchProps {
  formData?: {
    formName: string | (string | number)[];
    label: string;
  };
  isAll?: boolean;
  required?: boolean;
  disabled?: boolean;
  onSelect?: (value: number) => void;
}

const { Option } = Select;

export const MenuTypeSearch: React.FC<MenuTypeSearchProps> = ({
  formData,
  isAll,
  required,
  disabled,
  onSelect
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchMenuTypeIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const [menuTypeSearch, setMenuTypeSearch] = useState<IMenuList[]>([]);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);

  const searchMenuTypeIndexDB = async (value: string) => {
    try {
      const response = await MenuDB.searchMenuByName(value, 100);
      if (!response || response.length == 0) {
        message.info('Cannot find any menu with that name in cache, searching in server...');
        searchMenuType(value);
      } else {
        setMenuTypeSearch(response);
        setIsCacheResponse(true);
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  const searchMenuType = async (value: string) => {
    let response;
    try {
      response = await get_menu_items();

      //console.log('response', response);
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find menu item with that name!');
        setMenuTypeSearch([]);
      } else {
        if (value) {
          response.data.results = response.data.results.filter((val) => val.name.includes(value));
        }
        setMenuTypeSearch(response.data.results);
        MenuDB.addMenuItem(response.data.results);

        //console.log('response', response);
      }
    } catch (error) {
      console.error(error);
    }
    setIsCacheResponse(false);
  };

  const options = menuTypeSearch
    .filter((val) => val.type === MenuTypes.PARENT)
    .map((value) => (
      <Option key={value.id} value={value.id}>
        {`${value.name}`}
      </Option>
    ));

  return (
    <>
      {formData ? (
        <Form.Item
          name={formData.formName}
          label={formData.label}
          rules={[
            {
              required: required,
              message: 'Please add a meny item!'
            }
          ]}>
          <Select
            showSearch
            allowClear
            disabled={disabled}
            placeholder="Select a menu item"
            defaultActiveFirstOption={false}
            showArrow={false}
            autoClearSearchValue={true}
            filterOption={false}
            dropdownMatchSelectWidth={false}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            notFoundContent={null}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                {isCacheResponse ? (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    <Button
                      type="text"
                      style={{
                        color: 'blue',
                        width: '100%'
                      }}
                      onClick={() => {
                        searchMenuType(searchValue);
                      }}>
                      {'Pull More & Sync'}
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center'
                      }}>
                      No more data...
                    </div>
                  </div>
                )}
              </>
            )}>
            {isAll ? <Option value="">All</Option> : null}
            {options}
          </Select>
        </Form.Item>
      ) : (
        <Select
          showSearch
          allowClear
          disabled={disabled}
          placeholder="Select an account type"
          defaultActiveFirstOption={false}
          showArrow={false}
          autoClearSearchValue={true}
          filterOption={false}
          dropdownMatchSelectWidth={false}
          onSearch={(val) => {
            setSearchValue(val);
          }}
          notFoundContent={null}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              {isCacheResponse ? (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  <Button
                    type="text"
                    style={{
                      color: 'blue',
                      width: '100%'
                    }}
                    onClick={() => {
                      searchMenuType(searchValue);
                    }}>
                    {'Pull More & Sync'}
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center'
                    }}>
                    No more data...
                  </div>
                </div>
              )}
            </>
          )}>
          {isAll ? <Option value="">All</Option> : null}
          {options}
        </Select>
      )}
    </>
  );
};
