import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PrinterOutlined } from '@ant-design/icons';

import { SorterResult } from 'antd/lib/table/interface';
import { Button, PageHeader, Spin, Tooltip } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import AccountsDB from '@/store/localstorage/AccountsDB';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { PaymentAgainst } from '@/services/payments/enums';
import AppContent from '@/components/Common/Content/Content';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { get_invoices_list } from '@/services/settings/queries';
import { get_payment_details } from '@/services/payments/queries';
import { getDateFromDateTime } from '@/utils/getDateFromDateTime';
import { ICreateInvoiceResponse } from '@/services/settings/types';
import { get_location_details } from '@/services/locations/queries';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { get_sell_return_details_by_id } from '@/services/sell/queries';
import { AccountRulesEvent, redirectData } from '@/services/accounts/enums';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { get_purchase_reutrn_details_by_id } from '@/services/purchases/queries';
import JournalInvoice from '@/components/Common/CustomResuableInvoice/JournalInvoice';
import { IJournalListResponseData, IJournalViewResponseTableData } from '@/services/accounts/types';

import {
  get_account_detail_by_ids,
  get_account_journal_lines_credit_debit_sum,
  get_account_latest_history,
  get_journal_details,
  get_journal_lines_details
} from '@/services/accounts/queries';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const JournalView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [lines, setLines] = useState<IJournalViewResponseTableData[]>([]);
  const [journalDetails, setJournalDetails] = useState<IJournalListResponseData>();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [invoiceLists, setInvoiceLists] = useState<ICreateInvoiceResponse[]>([]);
  const [openModalForPrint, setOpenModalForPrint] = useState<boolean>(false);
  const [openingBalance, setOpeningBalance] = useState<string>('--');
  const [redirect, setRedirect] = useState<string | null>(null);

  const [searchParams] = useSearchParams();

  //const [allAccounts, setAllAccounts] = useState<IAccountTypeResponseData[]>([]);
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  useQuery(['invoiceLayouts'], async () => {
    const response = await get_invoices_list();
    setInvoiceLists(
      response.data.filter((curr: any) => {
        const content = JSON.parse(curr.content);
        return content.design == 'classic';
      })
    );
    return response;
  });

  const breadcrumbItems = [
    {
      label: 'Journal',
      link: '/accounts/journal'
    },
    {
      label: 'View'
    }
  ];

  useQuery(['journalDetails', id], async () => {
    try {
      setIsLoading(true);
      await getJournalDetails();
      await getData();
      const isPrintOk = searchParams.get('print') === '1';
      setOpenModalForPrint(isPrintOk);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  });

  let description: string;

  const getJournalDetails = async () => {
    setIsLoading(true);
    const response = await get_journal_details(parseInt(id as string));
    // console.log('journalResponse', response);
    description = response.description;
    // console.log('description', description);
    if (response) {
      const location = response.locationId;
      response.locationName = 'N/A';

      if (location) {
        let locationDetails = await LocationsDB.getLocation(response.locationId);
        if (!locationDetails) {
          const allLocations = await get_location_details(response.locationId);
          await LocationsDB.addLocations([allLocations]);
          locationDetails = await LocationsDB.getLocation(response.locationId);
        }

        response.locationName = locationDetails.name;
      }

      // redirect
      const redirect = redirectData.find((val) => val.key === response.type);
      if (redirect) {
        setRedirect(redirect.link);

        // set referenceId
        switch (response.type) {
          case AccountRulesEvent.SELL_PAYMENT_CASH:
          case AccountRulesEvent.SELL_PAYMENT_BANK:
          case AccountRulesEvent.SELL_PAYMENT_OTHER: {
            const paymentDetails = await get_payment_details(response.referenceId);
            if (paymentDetails.against === PaymentAgainst.Sell)
              response.redirectId = paymentDetails.againstId;
            break;
          }
          case AccountRulesEvent.SELL_RETURN: {
            const returnDetails = await get_sell_return_details_by_id(response.referenceId);
            response.redirectId = returnDetails.sellId;
            break;
          }
          case AccountRulesEvent.PURCHASE_PAYMENT_CASH:
          case AccountRulesEvent.PURCHASE_PAYMENT_USER:
          case AccountRulesEvent.PURCHASE_PAYMENT_OTHER: {
            const paymentDetails = await get_payment_details(response.referenceId);
            if (paymentDetails.against === PaymentAgainst.Purchase)
              response.redirectId = paymentDetails.againstId;
            break;
          }
          case AccountRulesEvent.PURCHASE_RETURN: {
            const returnDetails = await get_purchase_reutrn_details_by_id(response.referenceId);
            response.redirectId = returnDetails.purchaseId;
            break;
          }
          default: {
            response.redirectId = response.referenceId;
          }
        }
      } else {
        setRedirect(null);
      }
    }
    setJournalDetails(response);
    setIsLoading(false);
  };

  const getData = async () => {
    setIsLoading(true);
    const response = await get_journal_lines_details(parseInt(id as string));

    const tableData: IJournalViewResponseTableData[] = [];
    let opBal = '--';
    const accountsId: number[] = response.map((data) => {
      return data.accountId;
    });

    const endDate = new Date(response[0].journalDate);
    const startDate = getDateFromDateTime(response[0].journalDate);

    const journalDebit = await get_account_journal_lines_credit_debit_sum({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      ids: accountsId
    });

    const momentDate = getDateFromDateTime(response[0].journalDate);

    const newRunningBalance = await get_account_latest_history(
      momentDate.toISOString(),
      accountsId
    );
    for (let ind = 0; ind < response.length; ind++) {
      let filteredAcc = await AccountsDB.getAccount(response[ind].accountId);
      if (!filteredAcc) {
        const allAccounts = await get_account_detail_by_ids([
          ...new Set(
            response.map((value) => {
              return value.accountId;
            })
          )
        ]);
        await AccountsDB.addAccounts(allAccounts.results);
        filteredAcc = await AccountsDB.getAccount(response[ind].accountId);
      }
      const accName = typeof filteredAcc === 'object' ? filteredAcc?.name : '';

      // if (typeof filteredAcc === 'object' && filteredAcc?.type !== 'USER') {
      //   response[ind].runningBalance = null;
      // } else {
      //   response[ind].runningBalance = newRunningBalance.results[0].balance;
      // }
      // if()
      let currentBalance = newRunningBalance.results.find(
        (val) => val.accountId === response[ind].accountId
      )?.balance;

      const currentJournalDebit = journalDebit.results.find(
        (val) => val.accountId === response[ind].accountId
      );

      if (currentJournalDebit) {
        if (!currentBalance) {
          currentBalance =
            Number(currentJournalDebit.totalDebit) - Number(currentJournalDebit.totalCredit);
        } else {
          currentBalance =
            currentBalance +
            Number(currentJournalDebit.totalDebit) -
            Number(currentJournalDebit.totalCredit);
        }
      }

      tableData.push({
        ...response[ind],
        journalName: description,
        accountName: accName,
        runningBalance: currentBalance ? currentBalance : 0
      });
    }

    if (response.length > 0) opBal = nepaliNumberFormatter(response[0].runningBalance || 0);
    setLines(tableData);
    setOpeningBalance(opBal);
    setIsLoading(false);
  };

  const columns: ColumnsType<IJournalViewResponseTableData> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 5,
      render: (a, b, c) => <TableCell>{c + 1}.</TableCell>
    },
    {
      title: 'Account',
      key: 'accountId',
      width: 15,
      sorter: (a, b) =>
        a.accountName && b.accountName ? a.accountName.localeCompare(b.accountName) : 0,
      sortOrder: sortedInfo.columnKey === 'accountId' ? sortedInfo.order : null,
      render: (a, record) => (
        <TableCell>
          <Link to={'/accounts/journal-lines?accountId=' + record.accountId} target="_blank">
            {record.accountName}
          </Link>
        </TableCell>
      )
    },
    {
      title: 'Description',
      key: 'description',
      width: 15,
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortOrder: sortedInfo.columnKey === 'description' ? sortedInfo.order : null,
      render: (a, record) => <TableCell>{record.description}</TableCell>
    },
    {
      title: 'Running Balance',
      key: 'runningBalance',
      width: 15,
      sorter: (a, b) => {
        if (a.runningBalance && b.runningBalance) {
          return a.runningBalance - b.runningBalance;
        }
        return 0;
      },
      sortOrder: sortedInfo.columnKey === 'runningBalance' ? sortedInfo.order : null,
      render: (a, record) => (
        <TableCell>
          {record.runningBalance ? nepaliNumberFormatter(record.runningBalance) : ''}
        </TableCell>
      )
    },
    {
      title: 'Debit',
      key: 'debit',
      width: 15,
      sorter: (a, b) => a.debit - b.debit,
      sortOrder: sortedInfo.columnKey === 'debit' ? sortedInfo.order : null,
      render: (a, record) => <TableCell>{nepaliNumberFormatter(record.debit)}</TableCell>
    },
    {
      title: 'Credit',
      key: 'credit',
      width: 15,
      sorter: (a, b) => a.credit - b.credit,
      sortOrder: sortedInfo.columnKey === 'credit' ? sortedInfo.order : null,
      render: (a, record) => <TableCell>{nepaliNumberFormatter(record.credit)}</TableCell>
    }
  ];

  const data: any = lines;

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Spin spinning={isLoading}>
          {lines && (
            <JournalInvoice
              openModal={openModalForPrint}
              journalData={journalDetails}
              setOpenModal={setOpenModalForPrint}
              modalData={data}
            />
          )}
          <div className="grid grid-cols-1 gap-5 mb-5">
            <div>
              <PageHeader
                subTitle="Journal"
                style={{
                  padding: '8px 0px'
                }}
              />
              <div>Location : {journalDetails?.locationName}</div>
              <div>
                Description :{' '}
                {redirect ? (
                  <Link
                    to={`${redirect}${journalDetails?.redirectId}${
                      journalDetails?.type === 'TRANSFER_FROM'
                        ? '?type=out'
                        : journalDetails?.type === 'TRANSFER_TO'
                        ? '?type=in'
                        : ''
                    }`}>
                    {`${journalDetails?.description} (ID: ${
                      journalDetails?.referenceId ? journalDetails?.referenceId : ''
                    })`}
                  </Link>
                ) : (
                  `${journalDetails?.description} (ID: ${
                    journalDetails?.referenceId ? journalDetails?.referenceId : ''
                  })`
                )}
              </div>
              <div>Cheque Reference : {journalDetails?.chequeRef}</div>
              <div>Reference Number : {journalDetails?.referenceNumber}</div>
              <div>
                Debit : {journalDetails?.debit ? nepaliNumberFormatter(journalDetails.debit) : ''}
              </div>
              <div>
                Credit :{' '}
                {journalDetails?.credit ? nepaliNumberFormatter(journalDetails.credit) : ''}
              </div>
              <div>
                Date :{' '}
                {journalDetails?.date
                  ? convertUTCStringtoLocalString(journalDetails.date, DEFAULT_DATE_FORMAT)
                  : ''}
              </div>
            </div>
          </div>
          <CustomizeTable
            columns={columns}
            notshowPagination={true}
            data={lines}
            toSort={handleChange}
          />
          <div className="flex justify-end items-center gap-5 mt-5">
            <div>
              <Tooltip title="Print" color="blue">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setOpenModalForPrint(true);
                  }}>
                  <PrinterOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                </div>
              </Tooltip>
            </div>
            <Button type="default" onClick={() => navigate('/accounts/journal')}>
              Back
            </Button>
          </div>
        </Spin>
      </AppContent>
    </div>
  );
};

export default JournalView;
