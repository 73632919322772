import { shortNameHiearchy } from '../../../../pages/sell/sell-order/view';
import {
  get_adjustment_details,
  get_adjustment_line_details
} from '../../../../services/adjustments/queries';
import { IPurchaseInvoice } from '../../../../services/invoice/types';
import { get_location_details } from '../../../../services/locations/queries';
import {
  get_product_details,
  get_product_list_ids,
  get_units_list
} from '../../../../services/products/queries';
import {
  get_purchase_details,
  get_purchase_order_details,
  get_purchase_order_lines_details
} from '../../../../services/purchases/queries';
import { get_user_details, get_vendor_details } from '../../../../services/users/queries';
import LocationsDB from '../../../../store/localstorage/LocationsDB';
import ProductsDB from '../../../../store/localstorage/ProductsDB';
import UnitsDB from '../../../../store/localstorage/UnitsDB';
import UsersDB from '../../../../store/localstorage/UsersDB';
import { getPrintLocation } from '../BillPrint/services';

export const getPurchasePrintData = async (purchaseId: number, locationId: number, curr: any) => {
  const ResponseAndVendor = await getLinesForPurchaseInvoice(purchaseId);
  const response = ResponseAndVendor.response;
  const vendorDetails = ResponseAndVendor.vendorDetails;

  // Fetch Location Details
  const locationDetails = await getLocationDetails(locationId);

  const printLocation = await getPrintLocation();
  return {
    purchaseDetails: {
      ...response,
      payments: response.payments,
      printLocation: printLocation
    },
    vendorDetails: {
      ...vendorDetails.user.user
    },
    locationDetails: locationDetails,
    invoiceLayouts: curr,
    lines: response.lines
  } as IPurchaseInvoice;
};

export const getPurchaseOrderPrintData = async (
  purchaseOrderId: number,
  locationId: number,
  curr: any
) => {
  const response: any = (await get_purchase_order_details(purchaseOrderId)).data;

  const vendorDetails = await getVendorDetails(response.vendor.userId);
  if (response.agentId) {
    const agentInfo = await UsersDB.getUser(response.agentId);
    if (agentInfo) {
      response.agentName = agentInfo.name;
      response.agentPhone = agentInfo.phone;
    }
  }

  // Fetch Location Details
  const locationDetails = await getLocationDetails(locationId);

  const linesResponse = (await get_purchase_order_lines_details(purchaseOrderId)).data;

  // Taxable, Non Taxable and Vat
  let totalVat = 0;
  let totalTaxable = 0;
  let totalNonTaxable = 0;
  for (let index = 0; index < linesResponse.length; index++) {
    let product: any = await ProductsDB.getProduct(linesResponse[index].productId);
    if (!product) {
      product = await get_product_details(linesResponse[index].productId);
      await ProductsDB.addProducts([product]);
    }
    linesResponse[index].productName = product.name;

    let findUnit = await UnitsDB.getUnit(linesResponse[index].unitId);
    if (!findUnit) {
      const allUnits = await get_units_list();
      await UnitsDB.addUnits(allUnits);
      findUnit = await UnitsDB.getUnit(linesResponse[index].unitId);
    }
    if (typeof findUnit === 'object') {
      linesResponse[
        index
      ].quantityWithUnit = `${linesResponse[index].quantity} ${findUnit.shortName}`;
    }

    totalVat += linesResponse[index].vat;
    if (linesResponse[index].vat > 0) {
      totalTaxable += linesResponse[index].totalAmount;
    } else {
      totalNonTaxable += linesResponse[index].totalAmount;
    }
  }

  const printLocation = await getPrintLocation();

  return {
    purchaseDetails: {
      ...response,
      purchase: {
        ...response,
        totalVat: totalVat,
        totalTaxable: totalTaxable,
        totalNonTaxable: totalNonTaxable
      },
      payments: [],
      printLocation: printLocation
    },
    vendorDetails: {
      ...vendorDetails
    },
    locationDetails: locationDetails,
    invoiceLayouts: curr,
    lines: linesResponse
  } as IPurchaseInvoice;
};

const getVendorDetails = async (vendorUserId: number) => {
  let vendorDetails = await UsersDB.getUser(vendorUserId);
  if (!vendorDetails) {
    vendorDetails = (await get_user_details(vendorUserId)).user;
    await UsersDB.addUsers([vendorDetails]);
  }

  return { ...vendorDetails };
};

const getLinesForPurchaseInvoice = async (purchaseId: number) => {
  const response = await get_purchase_details(purchaseId);

  for (let index = 0; index < response.lines.length; index++) {
    let product: any = await ProductsDB.getProduct(response.lines[index].productId);
    if (!product) {
      product = await get_product_details(response.lines[index].productId);
      await ProductsDB.addProducts([product]);
    }
    response.lines[index].productName = product.name;

    let findUnit = await UnitsDB.getUnit(response.lines[index].unitId);
    if (!findUnit) {
      const allUnits = await get_units_list();
      await UnitsDB.addUnits(allUnits);
      findUnit = await UnitsDB.getUnit(response.lines[index].unitId);
    }
    if (typeof findUnit === 'object') {
      response.lines[
        index
      ].quantityWithUnit = `${response.lines[index].quantity} ${findUnit.shortName}`;
    }
  }

  const venData = await get_vendor_details(response?.purchase.vendorId);
  if (response?.purchase?.agentId) {
    const agentInfo = await UsersDB.getUser(response?.purchase.agentId);
    if (agentInfo) {
      response.purchase.agentName = agentInfo.name;
      response.purchase.agentPhone = agentInfo.phone;
    }
  }

  return { response: { ...response }, vendorDetails: venData };
};

const getLocationDetails = async (locationId: number) => {
  let locationDetails = await LocationsDB.getLocation(locationId);
  if (!locationDetails) {
    locationDetails = await get_location_details(locationId);
    await LocationsDB.addLocations([locationDetails]);
  }
  return locationDetails;
};
