import { USER_INFO } from '@/constants/config';
import { getUserData } from '../utils/auth.utils';
import { useNavigate } from 'react-router-dom';

export const hasPermission = (permission: unknown) => {
  const userData = getUserData();
  return !!userData?.perms?.includes(permission);
};

export const isAdmin = () => {
  return hasPermission('ADMIN');
};

export const isSuperAdmin = () => {
  return hasPermission('SUPER_ADMIN');
};

export const get_create_price_analysis_perms = () => {
  const isSuperUser = isAdmin() || isSuperAdmin() || hasPermission(ACLPerms.CREATE_PRICE_ANALYSIS);
  if (isSuperUser) return 'b2b-outlet';

  const isB2B = hasPermission(ACLPerms.CREATE_B2B_PRICE_ANALYSIS);
  const isOutlet = hasPermission(ACLPerms.CREATE_OUTLET_PRICE_ANALYSIS);

  if (isB2B && isOutlet) return 'b2b-outlet';
  if (isB2B) return 'b2b';
  if (isOutlet) return 'outlet';
};

export const get_update_price_analysis_perms = () => {
  const isSuperUser = isAdmin() || isSuperAdmin() || hasPermission(ACLPerms.UPDATE_PRICE_ANALYSIS);
  if (isSuperUser) return 'b2b-outlet';

  const isB2B = hasPermission(ACLPerms.UPDATE_B2B_PRICE_ANALYSIS);
  const isOutlet = hasPermission(ACLPerms.UPDATE_OUTLET_PRICE_ANALYSIS);

  if (isB2B && isOutlet) return 'b2b-outlet';
  if (isB2B) return 'b2b';
  if (isOutlet) return 'outlet';
};

export const get_read_price_analysis_perms = () => {
  const isSuperUser = isAdmin() || isSuperAdmin() || hasPermission(ACLPerms.READ_PRICE_ANALYSIS);
  if (isSuperUser) return 'b2b-outlet';

  const isB2B = hasPermission(ACLPerms.READ_B2B_PRICE_ANALYSIS);
  const isOutlet = hasPermission(ACLPerms.READ_OUTLET_PRICE_ANALYSIS);

  if (isB2B && isOutlet) return 'b2b-outlet';
  if (isB2B) return 'b2b';
  if (isOutlet) return 'outlet';
};

export const update_user_permissions = (perms: string[]) => {
  const userData = getUserData();
  if (!userData) return;
  userData.perms = perms;
  localStorage.setItem(USER_INFO, JSON.stringify(userData));
};

export const update_global_preferences = (global: { id: number; content: string }) => {
  const userData = getUserData();
  if (!userData) return;

  userData.global = global;
  localStorage.setItem(USER_INFO, JSON.stringify(userData));
};

export const checkAccess = (type: ACLTypes) => {
  const userData = getUserData();
  if (!userData || !userData.perms) {
    return false;
  }
  const perms: any = userData.perms;
  const found = perms.find((element: any) => {
    if (element == 'ADMIN') return element;
    if (element == type) return element;
  });
  if (found) return true;
  return false;
};
export const checkAccessAndReturn = (type: ACLTypes) => {
  const userData = getUserData();
  if (!userData || !userData.perms) {
    return false;
  } else {
    const perms: any = userData.perms;
    // console.log('PERms', perms);
    const found = perms.find((element: any) => {
      if (element == 'ADMIN') return element;
      if (element == type) return element;
    });

    if (!found) {
      return false;
    }

    return true;
  }
};

export const checkAccessForPos = () => {
  const navigate = useNavigate();
  const userData = getUserData();
  if (!userData || !userData.perms) {
    navigate('/404');
  } else {
    const perms: any = userData.perms;
    //console.log('PERms', perms);
    const found = perms.find((element: any) => {
      if (element == 'ADMIN') return element;
      if (element == 'CREATE_POS') return 'CREATE_POS';
    });

    if (found == 'CREATE_POS') navigate('/sell/pos-create-v2');
  }
};
export const ACLPerms = {
  ADMIN: 'ADMIN',
  CREATE_SELL: 'CREATE_SELL',
  READ_SELL: 'READ_SELL',
  CREATE_SELL_PAYMENT: 'CREATE_SELL_PAYMENT',
  CREATE_SELL_RETURN: 'CREATE_SELL_RETURN',
  READ_SELL_RETURN: 'READ_SELL_RETURN',
  READ_ROUTE_PRINT: 'READ_ROUTE_PRINT',
  CREATE_SELL_ORDER: 'CREATE_SELL_ORDER',
  READ_SELL_ORDER: 'READ_SELL_ORDER',
  UPDATE_SELL_ORDER: 'UPDATE_SELL_ORDER',
  DELETE_SELL_ORDER: 'DELETE_SELL_ORDER',
  CREATE_PURCHASE: 'CREATE_PURCHASE',
  READ_PURCHASE: 'READ_PURCHASE',
  CREATE_PURCHASE_ORDER: 'CREATE_PURCHASE_ORDER',
  READ_PURCHASE_ORDER: 'READ_PURCHASE_ORDER',
  UPDATE_PURCHASE_ORDER: 'UPDATE_PURCHASE_ORDER',
  DELETE_PURCHASE_ORDER: 'DELETE_PURCHASE_ORDER',
  CREATE_ADJUSTMENT: 'CREATE_ADJUSTMENT',
  READ_ADJUSTMENT: 'READ_ADJUSTMENT',
  CREATE_REASON: 'CREATE_REASON',
  READ_REASON: 'READ_REASON',
  UPDATE_REASON: 'UPDATE_REASON',
  CREATE_TRANSFER: 'CREATE_TRANSFER',
  READ_TRANSFER: 'READ_TRANSFER',
  ACKNOWLEDGE_TRANSFER: 'ACKNOWLEDGE_TRANSFER',
  APPROVE_TRANSFER: 'APPROVE_TRANSFER',
  CREATE_TRANSFER_ORDER: 'CREATE_TRANSFER_ORDER',
  READ_TRANSFER_ORDER: 'READ_TRANSFER_ORDER',
  UPDATE_TRANSFER_ORDER: 'UPDATE_TRANSFER_ORDER',
  DELETE_TRANSFER_ORDER: 'DELETE_TRANSFER_ORDER',
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  READ_CUSTOMER: 'READ_CUSTOMER',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  CUSTOMER_ENABLE_CREDIT: 'CUSTOMER_ENABLE_CREDIT',
  CREATE_VENDOR: 'CREATE_VENDOR',
  READ_VENDOR: 'READ_VENDOR',
  UPDATE_VENDOR: 'UPDATE_VENDOR',
  CREATE_USER: 'CREATE_USER',
  READ_USER: 'READ_USER',
  UPDATE_USER: 'UPDATE_USER',
  CREATE_TAX_INFORMATION: 'CREATE_TAX_INFORMATION',
  READ_TAX_INFORMATION: 'READ_TAX_INFORMATION',
  UPDATE_TAX_INFORMATION: 'UPDATE_TAX_INFORMATION',
  DELETE_TAX_INFORMATION: 'DELETE_TAX_INFORMATION',
  CREATE_ADDRESS: 'CREATE_ADDRESS',
  READ_ADDRESS: 'READ_ADDRESS',
  UPDATE_ADDRESS: 'UPDATE_ADDRESS',
  DELETE_ADDRESS: 'DELETE_ADDRESS',
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  READ_PRODUCT: 'READ_PRODUCT',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  CREATE_PRODUCT_CATEGORY: 'CREATE_PRODUCT_CATEGORY',
  READ_PRODUCT_CATEGORY: 'READ_PRODUCT_CATEGORY',
  UPDATE_PRODUCT_CATEGORY: 'UPDATE_PRODUCT_CATEGORY',
  DELETE_PRODUCT_CATEGORY: 'DELETE_PRODUCT_CATEGORY',
  READ_PERMISSIONS_ENUMS: 'READ_PERMISSIONS_ENUMS',
  UPDATE_USER_GROUP: 'UPDATE_USER_GROUP',
  CREATE_USER_GROUP: 'CREATE_USER_GROUP',
  READ_USER_GROUP: 'READ_USER_GROUP',
  UPDATE_GROUP_PERMISSIONS: 'UPDATE_GROUP_PERMISSIONS',
  CREATE_CHANNEL: 'CREATE_CHANNEL',
  READ_CHANNEL: 'READ_CHANNEL',
  UPDATE_CHANNEL: 'UPDATE_CHANNEL',
  SEND_MESSAGE: 'SEND_MESSAGE',
  CREATE_LOCATION: 'CREATE_LOCATION',
  READ_LOCATION: 'READ_LOCATION',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  DELETE_LOCATION: 'DELETE_LOCATION',
  CREATE_OFFER: 'CREATE_OFFER',
  READ_OFFER: 'READ_OFFER',
  UPDATE_OFFER: 'UPDATE_OFFER',
  DELETE_OFFER: 'DELETE_OFFER',
  CREATE_PRODUCT_ORDER: 'CREATE_PRODUCT_ORDER',
  READ_PRODUCT_ORDER: 'READ_PRODUCT_ORDER',
  UPDATE_PRODUCT_ORDER: 'UPDATE_PRODUCT_ORDER',
  CREATE_PRICE: 'CREATE_PRICE',
  READ_PRICE: 'READ_PRICE',
  UPDATE_PRICE: 'UPDATE_PRICE',
  DELETE_PRICE: 'DELETE_PRICE',
  CREATE_PRICE_GROUP: 'CREATE_PRICE_GROUP',
  READ_PRICE_GROUP: 'READ_PRICE_GROUP',
  UPDATE_PRICE_GROUP: 'UPDATE_PRICE_GROUP',
  DELETE_PRICE_GROUP: 'DELETE_PRICE_GROUP',
  READ_PRODUCT_LOTS: 'READ_PRODUCT_LOTS',
  MERGE_LOTS: 'MERGE_LOTS',
  DISTRIBUTE_LOTS: 'DISTRIBUTE_LOTS',
  CREATE_ROUTE: 'CREATE_ROUTE',
  READ_ROUTE: 'READ_ROUTE',
  UPDATE_ROUTE: 'UPDATE_ROUTE',
  DELETE_ROUTE: 'DELETE_ROUTE',
  CREATE_OPENING_STOCK: 'CREATE_OPENING_STOCK',
  READ_DASHBOARD: 'READ_DASHBOARD',
  CREATE_DASHBOARD: 'CREATE_DASHBOARD',
  UPDATE_DASHBOARD: 'UPDATE_DASHBOARD',
  UPDATE_CREDIT_LIMIT: 'UPDATE_CREDIT_LIMIT',
  DISABLE_USER: 'DISABLE_USER',
  RECEIVE_CRATE: 'RECEIVE_CRATE',
  CREATE_VEHICLE: 'CREATE_VEHICLE',
  READ_VEHICLE: 'READ_VEHICLE',
  UPDATE_VEHICLE: 'UPDATE_VEHICLE',
  DELETE_VEHICLE: 'DELETE_VEHICLE',
  TRACK_VEHICLE: 'TRACK_VEHICLE',
  CREATE_GLOBAL_SETTINGS: 'CREATE_GLOBAL_SETTINGS',
  UPDATE_ADJUSTMENT_STATUS: 'UPDATE_ADJUSTMENT_STATUS',
  READ_WALLET: 'READ_WALLET',
  CREATE_WALLET: 'CREATE_WALLET',
  CREATE_EXPENSE: 'CREATE_EXPENSE',
  READ_EXPENSE: 'READ_EXPENSE',
  UPDATE_EXPENSE: 'UPDATE_EXPENSE',
  DELETE_EXPENSE: 'DELETE_EXPENSE',
  CREATE_EXPENSE_CATEGORY: 'CREATE_EXPENSE_CATEGORY',
  READ_EXPENSE_CATEGORY: 'READ_EXPENSE_CATEGORY',
  UPDATE_EXPENSE_CATEGORY: 'UPDATE_EXPENSE_CATEGORY',
  DELETE_EXPENSE_CATEGORY: 'DELETE_EXPENSE_CATEGORY',
  READ_LOGS: 'READ_LOGS',
  CONVERT_LOTS: 'CONVERT_LOTS',
  UPDATE_VENDOR_TYPE: 'UPDATE_VENDOR_TYPE',
  CREATE_VENDOR_TYPE: 'CREATE_VENDOR_TYPE',
  READ_VENDOR_TYPE: 'READ_VENDOR_TYPE',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  READ_ACCOUNT: 'READ_ACCOUNT',
  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  CREATE_POS: 'CREATE_POS',
  B2B: 'B2B',
  CREATE_BANK: 'CREATE_BANK',
  READ_BANK: 'READ_BANK',
  UPDATE_BANK: 'UPDATE_BANK',
  CREATE_CUSTOMER_GROUP: 'CREATE_CUSTOMER_GROUP',
  READ_CUSTOMER_GROUP: 'READ_CUSTOMER_GROUP',
  UPDATE_CUSTOMER_GROUP: 'UPDATE_CUSTOMER_GROUP',
  CREATE_DISCOUNT: 'CREATE_DISCOUNT',
  READ_DISCOUNT: 'READ_DISCOUNT',
  UPDATE_DISCOUNT: 'UPDATE_DISCOUNT',
  CREATE_PRICE_ANALYSIS: 'CREATE_PRICE_ANALYSIS',
  READ_PRICE_ANALYSIS: 'READ_PRICE_ANALYSIS',
  UPDATE_PRICE_ANALYSIS: 'UPDATE_PRICE_ANALYSIS',
  CREATE_OUTLET_PRICE_ANALYSIS: 'CREATE_OUTLET_PRICE_ANALYSIS',
  CREATE_B2B_PRICE_ANALYSIS: 'CREATE_B2B_PRICE_ANALYSIS',
  UPDATE_OUTLET_PRICE_ANALYSIS: 'UPDATE_OUTLET_PRICE_ANALYSIS',
  UPDATE_B2B_PRICE_ANALYSIS: 'UPDATE_B2B_PRICE_ANALYSIS',
  READ_OUTLET_PRICE_ANALYSIS: 'READ_OUTLET_PRICE_ANALYSIS',
  READ_B2B_PRICE_ANALYSIS: 'READ_B2B_PRICE_ANALYSIS',
  PRICE_ANALYSIS_UPDATE_PRICE_GROUP_DAILY_RATE: 'PRICE_ANALYSIS_UPDATE_PRICE_GROUP_DAILY_RATE'
};
export type ACLTypes = keyof typeof ACLPerms;
