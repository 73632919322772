import moment from 'moment';
import AppContent from '@/components/Common/Content/Content';
import TableFilter from '@/components/FliterTable';
import { Button, Form, Input, Menu, message, Spin, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import getErrorMessage from '@/utils/getError';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { ISalesRegisterListItemWise } from '@/services/ird/sales/types';
import { useReactToPrint } from 'react-to-print';
import { CustomModal } from '@/components/Common/CustomModal';
import Table from './Table';
import handlePagination from '@/utils/handlePagination';
import { ConvertObjectToURL } from '@/utils/converturl';
import { get_sale_lines_filter } from '@/services/sell/queries';
import { get_sales_register_list } from '@/services/ird/sales/queries';
import CustomersDB from '@/store/localstorage/CustomerDB';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { getCustomer, getProductById, getUnit } from '@/services';
import { get_user_pan_ids } from '@/services/users/queries';
import { convert_string_to_nepali_date_string } from '@/utils/nepaliDateConverter';
import { columnsforPrint, excelColumns } from './column.export';
import { exportRegisterItemWiseExcel } from '../../exportRegister';
import CustomTableForIRDItemWise from '@/components/Common/CustomizeTable/CustomTableForIrdItemWise';

function ItemWiseSalesRegisterList() {
  const [form] = Form.useForm();
  const printPDFRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 100 });

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  const [openModalForPdfExport, setOpenModalForPdfExport] = useState(false);
  const [allSalesList, setAllSalesList] = useState({
    total: 0,
    results: [] as ISalesRegisterListItemWise[],
    qtySold: 0
  });

  const [exportData, setExportData] = useState({ from: '', to: '' });

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPagination({ page: 1, size: 100 });
  };

  async function getInfo(filter: string) {
    try {
      setIsLoading(true);
      const [sellLines, sellLists] = await Promise.all([
        get_sale_lines_filter(filter),
        get_sales_register_list(filter)
      ]);

      const productIds = sellLines.map((item) => item.productId);
      const uniqueProductIds = [...new Set(productIds)];

      const customerIds = sellLists.results.map((item) => item.customerId);
      const uniqueCustomerIds = [...new Set(customerIds)];

      const [customers] = await Promise.all([
        CustomersDB.addCustomersIfAbsent(uniqueCustomerIds),
        ProductsDB.addProductsIfAbsent(uniqueProductIds)
      ]);

      const customerUserIds = customers
        .map((customer) => customer.userId)
        .filter((id) => id) as number[];

      const uniqueCustomerUserIds = [...new Set(customerUserIds)];
      const { data: userPanList } = await get_user_pan_ids(uniqueCustomerUserIds);

      const sellListsPromises = sellLists.results.map(async (sell) => {
        const customer = await getCustomer(sell.customerId);
        const customerPan =
          userPanList.find((user) => user.userId === customer.userId)?.taxNumber || undefined;

        const numberPAN = Number(customerPan);
        const customerDetails = {
          buyersName: customer.user.name,
          customerId: customer.id,
          customerUserId: customer.userId,
          customerPan: isNaN(numberPAN) ? customerPan : numberPAN
        };

        const allLines = sellLines.filter((line) => line.identifierId === sell.id);
        const sellLinesUpdated: ISalesRegisterListItemWise[] = [];

        const date = moment(sell.createdAt).format('YYYY/MM/DD');
        const miti = convert_string_to_nepali_date_string(date).replaceAll('-', '.');
        const time = moment(sell.createdAt).format('hh:mm a');
        if (allLines.length > 0) {
          const sellLinesPromises = allLines.map(async (line) => {
            const product = await getProductById(line.productId);
            const unit = await getUnit(line.unitId);

            const vat = line.vat || 0;
            const taxableAmount = vat > 0 ? line.totalAmount - vat : 0;
            const nonTaxableAmount = vat > 0 ? 0 : line.totalAmount;

            return {
              ...customerDetails,
              date,
              miti,
              time,
              financialReference: sell.financialReference,
              productName: product.name,
              quantity: line.quantity,
              unit: unit?.name || '',
              vat: line.vat,
              taxableAmount: parseFloat(taxableAmount.toFixed(2)),
              nonTaxableAmount,
              grossSalesAmount: line.totalAmount,
              totalSale: parseFloat((taxableAmount + nonTaxableAmount).toFixed(2))
            } as ISalesRegisterListItemWise;
          });

          const updatedLines = await Promise.all(sellLinesPromises);
          updatedLines.forEach((line) => sellLinesUpdated.push(line));
        }

        return sellLinesUpdated;
      });

      const updatedSellLists = await Promise.all(sellListsPromises);
      const flattenSellLists = updatedSellLists.flat();

      // set year and date
      const startDate = form.getFieldValue(['startDate']);
      const endDate = form.getFieldValue(['endDate']);

      const stringStartDate = moment(startDate).format('YYYY/MM/DD');
      const stringEndDate = moment(endDate).format('YYYY/MM/DD');

      const nepaliStartDate = convert_string_to_nepali_date_string(stringStartDate);
      const nepaliEndDate = convert_string_to_nepali_date_string(stringEndDate);

      const fromDate = `${stringStartDate} (${nepaliStartDate.replaceAll('-', '.')})`;
      const toDate = `${stringEndDate} (${nepaliEndDate.replaceAll('-', '.')})`;

      setAllSalesList({
        total: sellLists.total,
        results: flattenSellLists,
        qtySold: sellLists.qtySold
      });
      setExportData({ from: fromDate, to: toDate });
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  function handleExcelExportDynamically() {
    if (allSalesList.results.length === 0) {
      message.error('No Data to Export');
      return;
    }

    exportRegisterItemWiseExcel({
      data: allSalesList.results,
      columns: excelColumns,
      fileName: 'Item Wise Sales Register',
      exportYear: exportData,
      title: 'Item Wise Sales Register'
    });
  }

  const onPagination = async (page = 1, size = 10, isSize = false) => {
    const currentFormValues = form.getFieldsValue();

    const formValues = {
      balanceType: currentFormValues.balanceType,
      dateSort: currentFormValues.dateSort,
      count: currentFormValues.count,
      skip: currentFormValues.skip
    };

    const { values, ...pagination } = handlePagination({ formValues, page, size, isSize });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    await getInfo(url);
  };

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        width="90%"
        footer={false}
        isModalOpen={openModalForPdfExport}
        setIsModalOpen={setOpenModalForPdfExport}
        title="PDF print">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTableForIRDItemWise
            columns={columnsforPrint}
            data={allSalesList.results}
            title="Sales Register Item Wise"
            subTitle={'Related With Rule 23 SubRule (1) Part (H)'}
            reff={printPDFRef}
            exportYear={exportData}
            highlightHeader
          />
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={[
          { label: 'Item Wise Sales Register', link: '/ird/sales-register-item-wise' }
        ]}
        withfilter={true}
        button={
          <>
            <div>
              {
                <TableFilter
                  defaultValues={{
                    dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                    value: '',
                    skip: 0,
                    count: 100,
                    locationId: ''
                  }}
                  initial={true}
                  onSubmit={onSubmitFilter}
                  styleforbuttons={'flex justify-end items-center'}
                  style={
                    'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                  }
                  form={form}>
                  <LocationSearchV2 hasParentFormItem={false} name="locationId" showAll />
                  <Form.Item name="value" label="Search">
                    <Input placeholder="Search" />
                  </Form.Item>
                </TableFilter>
              }
            </div>
            {/* </div> */}
          </>
        }>
        <div className="flex justify-end mb-2">
          <div>
            <ActionDropdown
              button={true}
              menu={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <Tooltip title="Export Excel" color="blue">
                          <div className="text-center">Excel</div>
                        </Tooltip>
                      ),
                      onClick: handleExcelExportDynamically
                    },
                    {
                      key: '2',
                      label: (
                        <Tooltip title="Export PDF" color="blue">
                          <div className="text-center">PDF</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        try {
                          if (allSalesList.results.length === 0) {
                            message.error('No Data to Export');
                            setIsLoading(false);
                            return;
                          }
                          setOpenModalForPdfExport(true);
                        } catch (err) {
                          getErrorMessage(err, true);
                        }
                      }
                    }
                  ]}
                />
              }
            />
          </div>
        </div>

        <Table data={allSalesList} pagination={pagination} onPagination={onPagination} />
      </AppContent>
    </Spin>
  );
}

export default ItemWiseSalesRegisterList;
