import { WithReportPaginationProps } from '@/components/Common/Report/withReportPagination';
import { ICustomItemWiseSellReport } from '@/services/report/types';
import { ExtendedInitialValues } from '.';
import { useState } from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import { Table, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { excelExportColumns, getUpdatedData, tableExportColumns } from './column.export';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ExportAllData from '@/components/Common/ExportAll';
import ExportCurrent from '@/components/Common/ExportCurrent';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

interface Props
  extends Pick<WithReportPaginationProps, 'onPagination' | 'onChange' | 'pagination'> {
  data: { total: number; data: ICustomItemWiseSellReport[] };
  onSearch: (values: ExtendedInitialValues) => void;
  onSearchAll: () => Promise<ICustomItemWiseSellReport[]>;
}

const defaultValue = {
  ['SALE QTY']: 0,
  ['RETURN QTY']: 0,
  ['NET QTY']: 0,
  ['GROSS VALUE']: 0,
  DISCOUNT: 0,
  ['NET VALUE']: 0,
  TAXABLE: 0,
  'NON TAXABLE': 0,
  VAT: 0,
  'NET AMOUNT': 0
};

function TableData({ data, pagination, ...handlers }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const total = data.data.reduce((acc, curr) => {
    acc['SALE QTY'] += parseFloat(curr['SALE QTY']);
    acc['RETURN QTY'] += parseFloat(curr['RETURN QTY']);
    acc['NET QTY'] += parseFloat(curr['NET QTY']);
    acc['GROSS VALUE'] += parseFloat(curr['GROSS VALUE']);
    acc.DISCOUNT += parseFloat(curr.DISCOUNT);
    acc['NET VALUE'] += parseFloat(curr['NET VALUE']);
    acc.TAXABLE += parseFloat(curr.TAXABLE);
    acc['NON TAXABLE'] += parseFloat(curr['NON TAXABLE']);
    acc.VAT += parseFloat(curr.VAT);
    acc['NET AMOUNT'] += parseFloat(curr['NET AMOUNT']);

    return acc;
  }, defaultValue);

  const columns: ColumnsType<ICustomItemWiseSellReport> = [
    {
      title: 'SN',
      key: 'SN',
      width: 40,
      render: (text, record, index) => {
        return <TableCell>{(pagination.page - 1) * pagination.size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'ITEM CODE',
      key: 'ITEM_CODE',
      width: 80,
      sorter: (a, b) => {
        const aValue = a['ITEM CODE'] || '';
        const bValue = b['ITEM CODE'] || '';
        return aValue.localeCompare(bValue);
      },
      sortOrder: sortedInfo.columnKey === 'ITEM_CODE' ? sortedInfo.order : null,
      render: (record: ICustomItemWiseSellReport) => <TableCell>{record['ITEM CODE']}</TableCell>
    },
    {
      title: 'ITEM NAME',
      key: 'ITEM_NAME',
      width: 160,
      sorter: (a, b) => {
        const aValue = a['ITEM NAME'] || '';
        const bValue = b['ITEM NAME'] || '';
        return aValue.localeCompare(bValue);
      },
      sortOrder: sortedInfo.columnKey === 'ITEM_NAME' ? sortedInfo.order : null,
      render: (record: ICustomItemWiseSellReport) => <TableCell>{record['ITEM NAME']}</TableCell>
    },
    {
      title: 'UNIT',
      dataIndex: 'UNIT',
      key: 'UNIT',
      width: 60,
      sorter: (a, b) => a.UNIT.localeCompare(b.UNIT),
      sortOrder: sortedInfo.columnKey === 'UNIT' ? sortedInfo.order : null
    },
    {
      title: 'SALE QTY',
      key: 'SALE_QTY',
      width: 80,
      sorter: (a, b) => parseFloat(a['SALE QTY']) - parseFloat(b['SALE QTY']),
      sortOrder: sortedInfo.columnKey === 'SALE_QTY' ? sortedInfo.order : null,
      render: (record: ICustomItemWiseSellReport) => (
        <TableCell>{nepaliNumberFormatter(parseFloat(record['SALE QTY']))}</TableCell>
      )
    },
    {
      title: 'RETURN QTY',
      key: 'RETURN_QTY',
      width: 80,
      sorter: (a, b) => parseFloat(a['RETURN QTY']) - parseFloat(b['RETURN QTY']),
      sortOrder: sortedInfo.columnKey === 'RETURN_QTY' ? sortedInfo.order : null,
      render: (record: ICustomItemWiseSellReport) => (
        <TableCell>{nepaliNumberFormatter(parseFloat(record['RETURN QTY']))}</TableCell>
      )
    },
    {
      title: 'NET QTY',
      key: 'NET_QTY',
      width: 80,
      sorter: (a, b) => parseFloat(a['NET QTY']) - parseFloat(b['NET QTY']),
      sortOrder: sortedInfo.columnKey === 'NET_QTY' ? sortedInfo.order : null,
      render: (record: ICustomItemWiseSellReport) => (
        <TableCell>{nepaliNumberFormatter(parseFloat(record['NET QTY']))}</TableCell>
      )
    },
    {
      title: 'GROSS VALUE',
      key: 'GROSS_VALUE',
      width: 80,
      sorter: (a, b) => parseFloat(a['GROSS VALUE']) - parseFloat(b['GROSS VALUE']),
      sortOrder: sortedInfo.columnKey === 'GROSS_VALUE' ? sortedInfo.order : null,
      render: (record: ICustomItemWiseSellReport) => (
        <TableCell>{nepaliNumberFormatter(parseFloat(record['GROSS VALUE']))}</TableCell>
      )
    },
    {
      title: 'DISCOUNT',
      dataIndex: 'DISCOUNT',
      key: 'DISCOUNT',
      width: 80,
      sorter: (a, b) => parseFloat(a.DISCOUNT) - parseFloat(b.DISCOUNT),
      sortOrder: sortedInfo.columnKey === 'DISCOUNT' ? sortedInfo.order : null,
      render: (value: string) => <TableCell>{nepaliNumberFormatter(parseFloat(value))}</TableCell>
    },
    {
      title: 'NET VALUE',
      key: 'NET_SALE',
      width: 80,
      sorter: (a, b) => parseFloat(a['NET VALUE']) - parseFloat(b['NET VALUE']),
      sortOrder: sortedInfo.columnKey === 'NET_SALE' ? sortedInfo.order : null,
      render: (record: ICustomItemWiseSellReport) => (
        <TableCell>{nepaliNumberFormatter(parseFloat(record['NET VALUE']))}</TableCell>
      )
    },
    {
      title: 'TAXABLE',
      key: 'TAXABLE',
      width: 80,
      sorter: (a, b) => parseFloat(a['TAXABLE']) - parseFloat(b['TAXABLE']),
      sortOrder: sortedInfo.columnKey === 'TAXABLE' ? sortedInfo.order : null,
      render: (record: ICustomItemWiseSellReport) => (
        <TableCell>{nepaliNumberFormatter(parseFloat(record['TAXABLE']))}</TableCell>
      )
    },
    {
      title: 'NON TAXABLE',
      key: 'NON_TAXABLE',
      width: 80,
      sorter: (a, b) => parseFloat(a['NON TAXABLE']) - parseFloat(b['NON TAXABLE']),
      sortOrder: sortedInfo.columnKey === 'NON_TAXABLE' ? sortedInfo.order : null,
      render: (record: ICustomItemWiseSellReport) => (
        <TableCell>{nepaliNumberFormatter(parseFloat(record['NON TAXABLE']))}</TableCell>
      )
    },
    {
      title: 'VAT',
      key: 'VAT',
      width: 80,
      sorter: (a, b) => parseFloat(a['VAT']) - parseFloat(b['VAT']),
      sortOrder: sortedInfo.columnKey === 'VAT' ? sortedInfo.order : null,
      render: (record: ICustomItemWiseSellReport) => (
        <TableCell>{nepaliNumberFormatter(parseFloat(record['VAT']))}</TableCell>
      )
    },
    {
      title: 'NET AMOUNT',
      key: 'NET_AMOUNT',
      width: 80,
      sorter: (a, b) => parseFloat(a['NET AMOUNT']) - parseFloat(b['NET AMOUNT']),
      sortOrder: sortedInfo.columnKey === 'NET_AMOUNT' ? sortedInfo.order : null,
      render: (record: ICustomItemWiseSellReport) => (
        <TableCell>{nepaliNumberFormatter(parseFloat(record['NET AMOUNT']))}</TableCell>
      )
    },
    {
      title: 'LOCATION',
      dataIndex: 'LOCATION',
      key: 'LOCATION',
      width: 120,
      sorter: (a, b) => a.LOCATION.localeCompare(b.LOCATION),
      sortOrder: sortedInfo.columnKey === 'LOCATION' ? sortedInfo.order : null
    },
    {
      title: 'CUSTOMER',
      dataIndex: 'CUSTOMER',
      key: 'CUSTOMER',
      width: 150,
      sorter: (a, b) => a.CUSTOMER.localeCompare(b.CUSTOMER),
      sortOrder: sortedInfo.columnKey === 'CUSTOMER' ? sortedInfo.order : null
    }
  ];

  const updatedData = getUpdatedData(data.data);

  return (
    <div>
      <CustomizeTable
        columns={columns}
        data={data.data}
        notshowPagination={true}
        tableSummary={
          <Table.Summary fixed={true}>
            <Table.Summary.Row>
              <Table.Summary.Cell className="text-center" index={0} colSpan={4}>
                TOTAL
              </Table.Summary.Cell>

              <Table.Summary.Cell index={5}>
                {nepaliNumberFormatter(total['SALE QTY'])}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
                {nepaliNumberFormatter(total['RETURN QTY'])}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                {nepaliNumberFormatter(total['NET QTY'])}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                {nepaliNumberFormatter(total['GROSS VALUE'])}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9}>
                {nepaliNumberFormatter(total.DISCOUNT)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={10}>
                {nepaliNumberFormatter(total['NET VALUE'])}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={11}>
                {nepaliNumberFormatter(total['TAXABLE'])}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={12}>
                {nepaliNumberFormatter(total['NON TAXABLE'])}
              </Table.Summary.Cell>

              <Table.Summary.Cell index={13}>{nepaliNumberFormatter(total.VAT)}</Table.Summary.Cell>
              <Table.Summary.Cell index={14}>
                {nepaliNumberFormatter(total['NET AMOUNT'])}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        }
        toSort={handleChange}
        customScroll={{ x: 2400, y: '75vh' }}
        buttons={
          <>
            <ExportAllData
              title="Item Wise Sell Report (All)"
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              getInfo={handlers.onSearchAll}
            />

            <ExportCurrent
              data={{ total: data.total, results: updatedData }}
              columns={tableExportColumns}
              excelColumns={excelExportColumns}
              title="Item Wise Sell Report"
            />
          </>
        }
        paginationDatas={{
          page: pagination.page,
          total: data.total,
          size: pagination.size,
          onPagination: (pageNo, currentSize) => {
            currentSize == pagination.size
              ? handlers.onPagination(handlers.onSearch, pageNo)
              : handlers.onPagination(handlers.onSearch, pageNo, currentSize);
          }
        }}
      />
    </div>
  );
}

export default TableData;
