import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { INotificationResponse, INotifications, INotificationsList } from './types';

export const get_notificaton_list_by_locationId = async (locationId: number | string) => {
  const response: AxiosResponse<INotificationsList[]> = await http.get(
    `notification/group/${locationId}`
  );
  return response;
};

export const get_all_notification = async () => {
  const response: AxiosResponse<INotifications[]> = await http.get('notification/');
  return response;
};

export const get_notification_list = async (skip = 0, count = 10) => {
  const response: AxiosResponse<INotificationResponse> = await http.get('notification/', {
    skip,
    count
  });

  return response.data;
};
